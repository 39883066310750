<mat-tab-group mat-stretch-tabs>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">person_add</mat-icon>
            <span>Nuevo cliente</span>
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">
                    <form class="row mt-3" [formGroup]="customerFormGroup" (ngSubmit)="createCustomer()">
                        <h5 class="col-12 pb-2">Datos del cliente</h5>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Nombre(s)</mat-label>
                            <input matInput type="text" #name formControlName="name" (blur)="checkField(name)" required>
                            <mat-icon matSuffix>draw</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Apellido paterno</mat-label>
                            <input matInput type="text" #firstLastName formControlName="firstLastName"
                                (blur)="checkField(firstLastName)" required>
                            <mat-icon matSuffix>draw</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Apellido materno</mat-label>
                            <input matInput type="text" #secondLastName formControlName="secondLastName"
                                (blur)="checkField(secondLastName)" required>
                            <mat-icon matSuffix>draw</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Género</mat-label>
                            <mat-select formControlName="gender" required>
                                <mat-option value="M">
                                    Masculino
                                </mat-option>
                                <mat-option value="F">
                                    Femenino
                                </mat-option>
                            </mat-select>
                            <mat-icon matSuffix>female</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Teléfono fijo</mat-label>
                            <input matInput type="number" formControlName="telephone">
                            <mat-icon matSuffix>call</mat-icon>
                            <mat-hint align="end">Opcional</mat-hint>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Celular</mat-label>
                            <input matInput type="number" formControlName="mobile" required>
                            <mat-icon matSuffix>phone_iphone</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Correo electrónico</mat-label>
                            <input matInput type="email" formControlName="email" required>
                            <mat-icon matSuffix>email</mat-icon>
                        </mat-form-field>

                        <h5 class="col-12 pb-2">Información personal</h5>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Fecha de nacimiento</mat-label>
                            <input matInput type="date" formControlName="birthDate" required>
                            <mat-icon matSuffix>calendar_month</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Lugar de nacimiento</mat-label>
                            <input matInput type="text" #birthPlace formControlName="birthPlace"
                                (blur)="checkField(birthPlace)" required>
                            <mat-icon matSuffix>pin_drop</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Nacionalidad</mat-label>
                            <input matInput type="text" #nationality (input)="onNationalityChange(nationality.value)"
                                aria-label="Número" formControlName="nationality" [matAutocomplete]="auto">
                            <mat-icon matSuffix>flag</mat-icon>
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredCountries" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>CURP</mat-label>
                            <input matInput type="text" formControlName="CURP" #CURP (blur)="toUpperCase(CURP)" maxlength="18" minlength="18"
                                required>
                            <mat-hint align="end">{{CURP.value.length}} / 18</mat-hint>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Clave de elector</mat-label>
                            <input matInput type="text" formControlName="voterKey" #voterKey (blur)="toUpperCase(voterKey)" maxlength="18"
                                minlength="18" required>
                            <mat-icon matSuffix>badge</mat-icon>
                            <mat-hint align="end">{{voterKey.value.length}} / 18</mat-hint>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Ocupación</mat-label>
                            <input matInput type="text" #occupation formControlName="occupation"
                                (blur)="checkField(occupation)" required>
                            <mat-icon matSuffix>work</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Estado civil</mat-label>
                            <mat-select formControlName="civilStatus" required>
                                <mat-option *ngFor="let status of civil_status" [value]="status.description">
                                    {{status.description}}
                                </mat-option>
                            </mat-select>
                            <mat-icon matSuffix>settings_accessibility</mat-icon>
                        </mat-form-field>

                        <h5 class="col-12 pb-2">Domicilio</h5>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Código postal</mat-label>
                            <input matInput #PC type="number" (keyup)="getPCData(PC.value)" formControlName="postalCode"
                                required>
                        </mat-form-field>

                        <div #AddFields>
                            <mat-form-field appearance="outline" class="col-md-4">
                                <mat-label>Dirección</mat-label>
                                <input matInput type="text" formControlName="address" required>
                                <mat-icon matSuffix>home</mat-icon>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="col-md-3">
                                <mat-label>Colonia o Localidad</mat-label>
                                <input matInput type="text" #Neighborhood (focus)="onNeighborhoodChange(Neighborhood.value)" (input)="onNeighborhoodChange(Neighborhood.value)"
                                    aria-label="Número" formControlName="localidad" [matAutocomplete]="hood">
                                <mat-icon matSuffix>flag</mat-icon>
                                <mat-autocomplete autoActiveFirstOption #hood="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredHoods" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <!-- <mat-form-field appearance="outline" class="col-md-4">
                                <mat-label>Colonia o localidad</mat-label>
                                <input matInput type="text" #Neighborhood formControlName="localidad" required>
                            </mat-form-field> -->

                            <mat-form-field appearance="outline" class="col-md-4">
                                <mat-label>Municipio</mat-label>
                                <input matInput type="text" #Municipality formControlName="municipality" required>
                                <mat-icon matSuffix>push_pin</mat-icon>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="col-md-4">
                                <mat-label>Estado</mat-label>
                                <input matInput type="text" #State formControlName="state" required>
                                <mat-icon matSuffix>map</mat-icon>
                            </mat-form-field>
                        </div>

                        <h5 class="col-12 pb-2">Información de facturación</h5>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>¿Factura?</mat-label>
                            <mat-select #billing formControlName="billing" required>
                                <mat-option [value]="1">Si</mat-option>
                                <mat-option [value]="0">No</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4" *ngIf="billing.value == 1">
                            <mat-label>RFC</mat-label>
                            <input matInput type="text" formControlName="RFC" #RFC (blur)="toUpperCase(RFC)" maxlength="13" minlength="13">
                            <mat-hint align="end">{{RFC.value.length}} / 13</mat-hint>
                        </mat-form-field>

                        <div class="col-12 text-right mt-3">
                            <button class="btn btn-m19" type="submit" [disabled]="!customerFormGroup.valid">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>

                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">person_search</mat-icon>
            Consultar clientes
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">

                    <mat-form-field appearance="outline" class="filter">
                        <mat-label>Buscar cliente</mat-label>
                        <input matInput type="text" placeholder="Ingresar el nombre del cliente"
                            [(ngModel)]="filterCustomer" name="filterCustomer">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>

                    <table class="table table-striped mt-3 animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr>
                                <th scope="col">Nombre completo</th>
                                <th scope="col">Celular</th>
                                <th scope="col">Correo electrónico</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let customer of customers | filterCustomer: filterCustomer">
                                <td>{{customer.name}} {{customer.firstLastName}} {{customer.secondLastName}}</td>
                                <td>{{customer.mobile}}</td>
                                <td>{{customer.email}}</td>
                                <td class="button-row">
                                    <button class="btn btn-m19-table" mat-icon-button title="Ver detalles"
                                        (click)="selectCustomer(customer.idUser)">
                                        <mat-icon>visibility</mat-icon>
                                    </button>
                                    <button class="btn btn-m19-table" mat-icon-button title="Editar"
                                        (click)="updateCustomer(customer.idUser)">
                                        <mat-icon>create</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>

</mat-tab-group>