import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterRecessions'
})
export class FilterRecessionsPipe implements PipeTransform {

  transform(value: any, arg: any): any {

    const resultPosts = [];

    for (const post of value) {
      if (post.fk_property.toUpperCase().indexOf(arg.toUpperCase()) > -1) {
        resultPosts.push(post);
      };
    };
    return resultPosts;
  }

}
