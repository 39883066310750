import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, finalize } from 'rxjs/operators';
import { Negotiation } from '../models/Negotiation';
import { NegotiationUnit } from '../models/NegotiationUnit';

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {
  isLoading = false;

  constructor(private http: HttpClient) { }
  getAllRequestOnApart(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/administration/getAllRequestOnApart/${id}`);
  }
  getAllRequestOnCustomer(): Observable<any> {
    return this.http.get(`${environment.URLServer}/administration/getAllRequestOnCustomer`);
  }
  getSele(id: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/administration/getSele/${id}`);
  }
  getAllStatus(): Observable<any> {
    return this.http.get(`${environment.URLServer}/administration/getAllStatus`);
  }
  getAllSalesCatalog(): Observable<any> {
    return this.http.get(`${environment.URLServer}/administration/getAllSalesCatalog`);
  }
  updateCatalogAndStatus(fk_salesCatalog: number, fk_status: number, codeProperty: string): Observable<any> {
    return this.http.post(`${environment.URLServer}/administration/updateCatalogAndStatus/`, { fk_salesCatalog, fk_status, codeProperty })
  }
  updateCatalog(fk_salesCatalog: any, codeProperty: any): Observable<any> {
    return this.http.post(`${environment.URLServer}/administration/updateCatalog/`, { fk_salesCatalog, codeProperty })
  }
  updateStatus(fk_status: any, codeProperty: any): Observable<any> {
    return this.http.post(`${environment.URLServer}/administration/updateStatus/`, { fk_status, codeProperty })
  }
  updateContractDate(property: any, month: number): Observable<any> {
    return this.http.post(`${environment.URLServer}/administration/updateContractDate/${property}/${month}`, []);
  }
  updateSaleStatus(fk_status: any, codeProperty: any): Observable<any> {
    return this.http.post(`${environment.URLServer}/administration/updateSaleStatus/`, { fk_status, codeProperty })
  }
  setComplete(prop: String): Observable<any> {
    return this.http.put(`${environment.URLServer}/administration/setComplete/${prop}`, []);
  }
  getSalesWithContract(): Observable<any> {
    return this.http.get(`${environment.URLServer}/administration/getSalesWithContract`);
  }
  getSalesRatificated(): Observable<any> {
    return this.http.get(`${environment.URLServer}/administration/getSalesRatificated`);
  }
  sendRatification(property: string): Observable<any> {
    return this.http.put(`${environment.URLServer}/administration/sendRatification/${property}`, []);
  }
  receiveRatification(property: string): Observable<any> {
    return this.http.put(`${environment.URLServer}/administration/receiveRatification/${property}`, []);
  }
  getRatificatedCustomers(): Observable<any> {
    return this.http.get(`${environment.URLServer}/administration/getRatificatedCustomers`);
  }
  getContractsByProject(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/administration/getContractsByProject/${id}`);
  }
  getCompleteContractsByProject(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/administration/getCompleteContractsByProject/${id}`);
  }

  getAllSalesList(): Observable<any> {
    this.isLoading = true;
    return this.http.get(`${environment.URLServer}/administration/getAllSalesList`)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }

  saveNegotiation(neg: Negotiation): Observable<any> {
    this.isLoading = true;
    return this.http.post(`${environment.URLServer}/administration/saveNegotiation`, neg)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }

  addRepresentativeNegotiation(fkn: number, representative: string): Observable<any> {
    this.isLoading = true;
    return this.http.post(`${environment.URLServer}/administration/addRepresentativeNegotiation`, { "FKNegotiation": fkn, "FullName": representative })
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        }
        )
      );
  }

  addUnitsToNegotiation(units: NegotiationUnit): Observable<any> {
    this.isLoading = true;
    return this.http.post(`${environment.URLServer}/administration/addUnitsToNegotiation`, units)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        }
        )
      );
  }

  getCustomersToNegotiate(): Observable<any> {
    this.isLoading = true;
    return this.http.get(`${environment.URLServer}/administration/getCustomersToNegotiate`)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        }
        )
      );
  }

  getCustomerSalesWithoutContract(idCustomer: number): Observable<any> {
    this.isLoading = true;
    return this.http.get(`${environment.URLServer}/administration/getCustomerSalesWithoutContract/${idCustomer}`)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }

  getCustomerSalesWithContract(idContract: string): Observable<any> {
    this.isLoading = true;
    return this.http.get(`${environment.URLServer}/administration/getCustomerSalesWithContract/${idContract}`)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }

  getCustomerSalesData(customerID: number, propertyID: string): Observable<any> {
    this.isLoading = true;
    return this.http.get(`${environment.URLServer}/administration/getCustomerSalesData/${customerID}/${propertyID}`)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }

  getFolioForContract(property: string, responsible: number): Observable<any> {
    this.isLoading = true;
    return this.http.post(`${environment.URLServer}/administration/getFolioForContract`, [{ "properties": property, "responsible": responsible }])
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }

  getCustomerWithContractData(idContract: string): Observable<any> {
    this.isLoading = true;
    return this.http.get(`${environment.URLServer}/administration/getCustomerWithContractData/${idContract}`)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }

  getOpenNegotiations(): Observable<any> {
    this.isLoading = true;
    return this.http.get(`${environment.URLServer}/administration/getOpenNegotiations`)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }

  getScheduleNegotiations(): Observable<any> {
    this.isLoading = true;
    return this.http.get(`${environment.URLServer}/administration/getScheduledNegotiations`)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }

  getNegotiationDetail(PKNegotiation: number): Observable<any> {
    this.isLoading = true;
    return this.http.get(`${environment.URLServer}/administration/getNegotiationDetail/${PKNegotiation}`)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }

  makePaybackRecord(PKNegotiation: number, FKPaybackScheduled: number, PaybackAmount: number, PaybackResponsible: number): Observable<any> {
    this.isLoading = true;
    return this.http.get(`${environment.URLServer}/administration/makePaybackRecord/${PKNegotiation}/${FKPaybackScheduled}/${PaybackAmount}/${PaybackResponsible}`)
    .pipe(
      catchError(error => {
        console.error('Error en la solicitud: ', error);
        return throwError('Ha ocurrido un error en la solicitud');
      }),
      finalize(() => {
        this.isLoading = false;
      })
    );
  }

  getDevolutionReceiptData(PKNegotiation): Observable<any> {
    this.isLoading = true;
    return this.http.get(`${environment.URLServer}/administration/getDevolutionReceiptData/${PKNegotiation}`)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }

  getNegotiationByPK(PKNegotiation: number): Observable<any> {
    this.isLoading = true;
    return this.http.get(`${environment.URLServer}/administration/getNegotiationByPK/${PKNegotiation}`)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }

  getNegotiationPaybacks(PKNegotiation: number): Observable<any> {
    this.isLoading = true;
    return this.http.get(`${environment.URLServer}/administration/getNegotiationPaybacks/${PKNegotiation}`)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }

  sendCommentForNegotiation(comment: any): Observable<any> {
    this.isLoading = true;
    return this.http.post(`${environment.URLServer}/administration/sendCommentForNegotiation`, comment)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud.');
        }),
        finalize(() => {
          this.isLoading = false;
        }
        )
      );
  }

  deleteCommentByNegotiation(PKComment: number): Observable<any> {
    this.isLoading = true;
    return this.http.put(`${environment.URLServer}/administration/deleteCommentByNegotiation/${PKComment}`, [])
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }

  getCommentsByNegotiations(PKNegotiation: number): Observable<any> {
    this.isLoading = true;
    return this.http.get(`${environment.URLServer}/administration/getCommentsByNegotiations/${PKNegotiation}`)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }

  schedulePayback(schedule: any): Observable<any> {
    this.isLoading = true;
    return this.http.post(`${environment.URLServer}/administration/schedulePayback`, schedule)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }

  getRescissionAgreementData(idSale: string): Observable<any> {
    this.isLoading = true;
    return this.http.get(`${environment.URLServer}/administration/getRescissionAgreementData/${idSale}`)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }
  _getRescissionAgreementData(customer: string, codeProperty: string): Observable<any> {
    this.isLoading = true;
    return this.http.get(`${environment.URLServer}/administration/_getRescissionAgreementData/${customer}/${codeProperty}`)
      .pipe(
        catchError(error => {
          console.error('Error en la solicitud: ', error);
          return throwError('Ha ocurrido un error en la solicitud');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      );
  }
}
