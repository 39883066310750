<mat-dialog-content style="height: 100%; max-height: 90vh;">
    <div class="commentsContainer">
        <span class="commentsContainer__commentTitle">
            <mat-icon class="actionBtn" (click)="closeComments()">arrow_back_ios</mat-icon>
            <span><b>Comentarios</b></span>
        </span>

        <span class="commentsContainer__commentSection">
            <div class="commentsList" *ngIf="comments.length>0; Else NoComments">
                <div class="comment" *ngFor="let c of comments">
                    <div class="commentPicture">
                        <img [src]="c.picture" alt="Imagen de perfil">
                    </div>
                    <ng-template #Letter>

                    </ng-template>
                    <div class="commentDetail">
                        <span><b>{{c.Author}}</b></span>
                        <p>{{c.Content}}</p>
                    </div>

                    <mat-icon *ngIf="c.FKAuthor == getUser()" class="actionBtn delete" (click)="deleteComment(c.PKNegotiationComment)">delete</mat-icon>
                </div>
            </div>

            <ng-template #NoComments>
                <div class="commentsContainer__noComments">
                    <h5>Sin comentarios</h5>
                </div>
            </ng-template>

            <div class="commentField">
                <textarea #comment matInput cdkTextareaAutosize placeholder="Dejar un comentario..." cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="3"></textarea>
                <button class="actionBtn" (click)="sendComment(comment.value)" [disabled]="comment.value == ''">
                    <mat-icon>send</mat-icon>
                </button>
            </div>
        </span>
    </div>
</mat-dialog-content>