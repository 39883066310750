import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ReportService } from '../../services/report.service';
import { ChartData, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import Swal from 'sweetalert2';
import { element } from 'protractor';
import { data } from 'jquery';
import { toBase64String } from '@angular/compiler/src/output/source_map';


@Component({
  selector: 'app-flow-report',
  templateUrl: './flow-report.component.html',
  styleUrls: ['./flow-report.component.scss']
})
export class FlowReportComponent implements OnInit {

  selecMes = "Actual"
  tMes = ""

  //Manejo de información res API, arreglos con carga de datos de las consultas a los servicios que conectan con la API
  flujo: any = []
  ingreso: any = []
  flujoDiario: any = []
  flujoTotal: any = []
  flujoTotalAnual: any = []
  ingresoTotal: any = []
  Flujo: any = []
  FlujoTotal: any = []
  FlujoTotalAnual: any = []
  FlujoTotalAnualPasado: any = []
  FlujoRango: any = []
  FlujoT: any = []
  FlujoDiario: any = []
  mesesFlujo: any = ["Actual", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

  valordeMes = "";
  valordeAnio = ""
  reporteDiario: any = [];
  barChartData: ChartDataSets[] = []   

  barChartPromedio: ChartDataSets[] = []
  mesesFlujo2: any = [
    { valor: "01", mes: "Enero" },
    { valor: "02", mes: "Febrero" },
    { valor: "03", mes: "Marzo" },
    { valor: "04", mes: "Abril" },
    { valor: "05", mes: "Mayo" },
    { valor: "06", mes: "Junio" },
    { valor: "07", mes: "Julio" },
    { valor: "08", mes: "Agosto" },
    { valor: "09", mes: "Septiembre" },
    { valor: "10", mes: "Octubre" },
    { valor: "11", mes: "Noviembre" },
    { valor: "12", mes: "Diciembre" }]

  aniosOperacion = ["2019", "2020", "2021", "2022"]
  dias = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
  carteraR: any = []
  carteraA: any = []
  carteraV: any = []
  carteraT: any = []
  carteraG: any = []
  cartera: any = []
  reportListBack: any = []
  reportDevBack: any = []

  //Nomenclatura inmueble
  M = "Manzana No."
  L = "Lote No."
  T = "Torre No."
  D = "Departamento No."


  //Grafico y control de pagos
  graficoActual = true
  graficoAnual = false
  limite = -4
  tolerancia = -1
  tolerancia2 = -2
  tolerancia3 = -3

  limiteRe = 4
  toleranciaRe = 1
  tolerancia2Re = 2
  tolerancia3Re = 3

  // Configuraciones del grafico
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
      display: true,
      fullWidth: true
    },
    showLines: true,
    tooltips: {
      enabled: true,
      caretSize: 0,
      displayColors: true
    },
    spanGaps: true,
    plugins: {
      datalabels: {
        formatter: (value: any, ctx: any) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  public piePruebaOptions: ChartDataSets = {
    showLine: true
  }
  public pieChartLabels: Label[] = [['Recesión'], ['Emergente 2-3 atrasos'], ['Un solo atraso']];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgb(211, 25, 16 )', 'rgb(242, 228, 12)', 'rgb(39, 162, 11 )'],
    },
  ];

  //barras
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    },
    tooltips: {
      enabled: true,
      titleFontSize: 20,
      bodyFontSize: 20
    }
  };
  public barChartLabels: Label[] = ['Flujo actual'];
  public barchartLabelDiario: Label[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
  public barchartLAbelAnual: Label[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  ////////////////////////GRAFICO DE FLUJO HECHO POR GERARDO ///////////////////////////////////////
  public barChartOptions2: ChartOptions = {
    responsive: true,
  };
  barChartLabels2: Label[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
  barChartType2: ChartType = 'bar';
  barChartLegend2 = true;
  barChartPlugins2 = [];
  barChartData2: ChartDataSets[] = [
    { data: this.flujoDiario, label: 'Flujo promedio' } 

  ]

  constructor(private reportService: ReportService) { }

  //Metodo de carga de metodos al momento de la ejecución
  ngOnInit(): void {
    this.flujos();
  }
 
  //Valor de mes Seleccionado
  async asignacionMes(ev: any) {
    this.valordeMes = ev.value
  }

  async asignacionAnio(ev: any) {
    this.valordeAnio = ev.source.selected.viewValue
  }

  iniciaBusqueda() {
    this.reporteDiario = [];
    this.barChartData = [];

    if (this.valordeAnio == "" || this.valordeAnio == null) {
      Swal.fire("Valor invalido", "No has seleccionado ningun año por favor vuelva a intentarlo", "error")
    } else if (this.valordeMes == "" || this.valordeMes == null) {
      Swal.fire("Valor invalido", "No has seleccionado ningun año por favor vuelva a intentarlo", "error")
    } else {

      //console.log("fecha → " , this.valordeMes + " " + this.valordeAnio )
      this.reportService.flujoMensualService(this.valordeAnio, this.valordeMes).subscribe(
        res => {
          this.reporteDiario = res;
        // console.log("Reporte diario → ", this.reporteDiario)

          this.reasignarArreglo();  
        //console.log("Bar char data → " , this.barChartData)

          console.log("Bar char data → " , this.barChartData)
          for( const index in this.reporteDiario){
            this.barChartData.push( { data: this.reporteDiario[index] , label: "Flujo diario"}  )
            //this.barChartPromedio.push({data: this.reporteDiario[index].flujo , label:"Flujo diario"} )
          }
          //console.log("barChartData → " , this.barChartData )

        
        }
      )

    }

  }


  public async reasignarArreglo() {
    let i = 0;
    console.log("Tamaño de arreglo → " , this.reporteDiario.length);  

    for ( i = 0; i < this.reporteDiario.length; i++) {                                                            
       
        if (this.reporteDiario[i].fechaPago.substring(8 , 10).includes(this.dias)) {
        } else {
          this.reporteDiario.push({ fechaPago: this.valordeAnio + "" +  this.valordeMes +  "" + this.dias, abono: 0, flujo: 0 });
          console.log("Iteracion → " , this.reporteDiario[i])
        }

        console.log("Valor de reporte diario → " , this.reporteDiario)

    } //for i
    console.log("Reasignacion final → ", this.reporteDiario)
    
  }

  //Metodos de obtención de informacion de flujo por mes
  tituloMes() {
    this.tMes = this.selecMes
    return this.tMes;
  }
  seleccionMes() {
    if (this.selecMes == "Actual") {
      this.flujos();
      this.flujosTotal();
    }
    if (this.selecMes == "Enero") {
      this.FlujosDesEn();
      this.FlujosTEn();
    }
    if (this.selecMes == "Febrero") {
      this.FlujosDesFe();
      this.FlujosTFe();
    }
    if (this.selecMes == "Marzo") {
      this.FlujosDesMar();
      this.FlujosTMar();
    }
    if (this.selecMes == "Abril") {
      this.FlujosDesAbr();
      this.FlujosTAbr();
    }
    if (this.selecMes == "Mayo") {
      this.FlujosDesMay();
      this.FlujosTMay();
    }
    if (this.selecMes == "Junio") {
      this.FlujosDesJun();
      this.FlujosTJun();
    }
    if (this.selecMes == "Julio") {
      this.FlujosDesJul();
      this.FlujosTJul();
    }
    if (this.selecMes == "Agosto") {
      this.FlujosDesAg();
      this.FlujosTAg();
    }
    if (this.selecMes == "Septiembre") {
      this.FlujosDesSep();
      this.FlujosTSep();
    }
    if (this.selecMes == "Octubre") {
      this.FlujosDesOct();
      this.FlujosTOct();
    }
    if (this.selecMes == "Noviembre") {
      this.FlujosDesNov();
      this.FlujosTNov();
    }
    if (this.selecMes == "Diciembre") {
      this.FlujosDesDic();
      this.FlujosTDic();
    }
  }

  //Obtención de selección de mes y cambio
  onChange(centroId: any) {
    console.log(centroId.event.target.value);
    this.seleccionMes();
  }
  ShowSelected(cod: any) {
    cod = document.getElementById("mes");
    return cod;
  }

  //Consulta de datos desde el servicio que consulta a la API
  FlujosDesEn() {
    this.reportService.reporteFlujoMenEnDes().subscribe(
      res => {
        this.flujo = res;
      },
      err => console.log(err)
    );
  }
  FlujosTEn() {
    this.reportService.reporteFlujoMenEnT().subscribe(
      res => {

        this.flujoTotal = res;
      },
      err => console.log(err)
    );
  }
  FlujosDesFe() {
    this.reportService.reporteFlujoMenFebDes().subscribe(
      res => {

        this.flujo = res;
      },
      err => console.log(err)
    );
  }
  FlujosTFe() {
    this.reportService.reporteFlujoMenFebT().subscribe(
      res => {

        this.flujoTotal = res;
      },
      err => console.log(err)
    );
  }
  FlujosDesMar() {
    this.reportService.reporteFlujoMenMarDes().subscribe(
      res => {

        this.flujo = res;
      },
      err => console.log(err)
    );
  }
  FlujosTMar() {
    this.reportService.reporteFlujoMenMarT().subscribe(
      res => {

        this.flujoTotal = res;
      },
      err => console.log(err)
    );
  }
  FlujosDesAbr() {
    this.reportService.reporteFlujoMenAbrDes().subscribe(
      res => {

        this.flujo = res;
      },
      err => console.log(err)
    );
  }
  FlujosTAbr() {
    this.reportService.reporteFlujoMenAbrT().subscribe(
      res => {

        this.flujoTotal = res;
      },
      err => console.log(err)
    );
  }
  FlujosDesMay() {
    this.reportService.reporteFlujoMenMayDes().subscribe(
      res => {

        this.flujo = res;
      },
      err => console.log(err)
    );
  }
  FlujosTMay() {
    this.reportService.reporteFlujoMenMayT().subscribe(
      res => {

        this.flujoTotal = res;
      },
      err => console.log(err)
    );
  }
  FlujosDesJun() {
    this.reportService.reporteFlujoMenJunDes().subscribe(
      res => {

        this.flujo = res;
      },
      err => console.log(err)
    );
  }
  FlujosTJun() {
    this.reportService.reporteFlujoMenJunT().subscribe(
      res => {

        this.flujoTotal = res;
      },
      err => console.log(err)
    );
  }

  FlujosDesJul() {
    this.reportService.reporteFlujoMenJulDes().subscribe(
      res => {

        this.flujo = res;
      },
      err => console.log(err)
    );
  }
  FlujosTJul() {
    this.reportService.reporteFlujoMenJulT().subscribe(
      res => {

        this.flujoTotal = res;
      },
      err => console.log(err)
    );
  }
  FlujosDesAg() {
    this.reportService.reporteFlujoMenAgDes().subscribe(
      res => {

        this.flujo = res;
      },
      err => console.log(err)
    );
  }
  FlujosTAg() {
    this.reportService.reporteFlujoMenAgbT().subscribe(
      res => {

        this.flujoTotal = res;
      },
      err => console.log(err)
    );
  }
  FlujosDesSep() {
    this.reportService.reporteFlujoMenSepDes().subscribe(
      res => {

        this.flujo = res;
      },
      err => console.log(err)
    );
  }
  FlujosTSep() {
    this.reportService.reporteFlujoMenSepT().subscribe(
      res => {

        this.flujoTotal = res;
      },
      err => console.log(err)
    );
  }
  FlujosDesOct() {
    this.reportService.reporteFlujoMenOctDes().subscribe(
      res => {

        this.flujo = res;
      },
      err => console.log(err)
    );
  }
  FlujosTOct() {
    this.reportService.reporteFlujoMenOctT().subscribe(
      res => {

        this.flujoTotal = res;
      },
      err => console.log(err)
    );
  }
  FlujosDesNov() {
    this.reportService.reporteFlujoMenNovDes().subscribe(
      res => {

        this.flujo = res;
      },
      err => console.log(err)
    );
  }
  FlujosTNov() {
    this.reportService.reporteFlujoMenNovT().subscribe(
      res => {

        this.flujoTotal = res;
      },
      err => console.log(err)
    );
  }
  FlujosDesDic() {
    this.reportService.reporteFlujoMenDicDes().subscribe(
      res => {

        this.flujo = res;
      },
      err => console.log(err)
    );
  }
  FlujosTDic() {
    this.reportService.reporteFlujoMenDicT().subscribe(
      res => {

        this.flujoTotal = res;
      },
      err => console.log(err)
    );
  }




  flujos() {
    this.reportService.reporteFlujo().subscribe(
      res => {

        this.flujo = res;
       
      },
      err => console.log(err)
    );
  }
  flujosTotal() {
    this.reportService.reporteFlujoTotal().subscribe(
      res => {

        this.flujoTotal = res;
      },
      err => console.log(err)
    );
  }
  flujosDiarios() {
    this.reportService.reporteFlujoDiario().subscribe(
      res => {

        this.flujoDiario = res;
        //console.log("jers → "  , this.flujoDiario)
      },
      err => console.log(err)
    );
  }
  flujosTotalAnual() {
    this.reportService.reporteFlujoAnual().subscribe(
      res => {

        this.flujoTotalAnual = res;
      },
      err => console.log(err)
    );
  }

}