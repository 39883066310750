import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { PaymentService } from 'src/app/services/payment.service';
import { ReportService } from 'src/app/services/report.service';
import { TotalPagadosService } from 'src/app/services/totalPagados.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { DialogWalletComponent } from '../../wallet/dialog-wallet/dialog-wallet.component';
import { DialogNotesComponent } from '../../wallet/dialog-notes/dialog-notes.component';
import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';
import { BlockLogService } from 'src/app/services/block-log.service';
import { RecessionsService } from 'src/app/services/recessions.service';
import decode from 'jwt-decode'
import Swal from 'sweetalert2';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';

import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';
import { DatePipe } from '@angular/common';

import { ProjectService } from 'src/app/services/project.service';
import { SalesService } from 'src/app/services/sales.service';
import { CreditPaymentsComponent } from '../../search/report/credit-payments/credit-payments.component';

import { WalletV2Service } from 'src/app/services/walletV2Service';
import { BlockSaleDialogComponent } from '../../graficos/block-sale-dialog/block-sale-dialog.component';

import { loader } from 'src/app/ui/loader/loader.model';

@Component({
  selector: 'app-walletv2',
  templateUrl: './walletv2.component.html',
  styleUrls: ['./walletv2.component.scss']
})
export class Walletv2Component implements OnInit {

  loader: loader = {
    message: "Generando reporte..."
  }

  loading = 1;

  constructor(
    public modal: NgbModal,
    private reportService: ReportService,
    private paymentService: PaymentService,
    private totalPagados: TotalPagadosService,
    public projectService: ProjectService,
    public dialog: MatDialog,
    private salesServices: SalesService,
    private walletService: WalletV2Service,
    private blockLogService: BlockLogService,
    public sweet: SweetAlertComponent,
    private recessionsService: RecessionsService,

  ) {
    this.recessionsService.getRecessionsType().subscribe(res => { this.catalogoRecision = res; this.loading = 0; });
    this.decode = decode(localStorage.getItem("token") || "");
  }

  bannerTitle: BannerTitle = {
    title: "Cartera",
    icon: "account_balance_wallet",
    position: "center",
    background: "var(--M19Secundary)"
  }

  //variables
  carteraG: any = [];
  carteraR: any = [];
  carteraA: any = [];
  carteraV: any = [];
  carteraT: any = [];
  reportDevBack: any = [];
  coordListado: any = [];
  cartera: any = [];
  carteraListado: any = [];
  payments: any = [];
  carteraInmu: any = [];
  reportDevBackList: any = []
  montosListado: any = [];
  proyecto: any = []
  catalogoRecision: any = [] //Catalogo de motivos de bloqueo para bloqueo v2

  salesCoo: any = [];
  creditPayments: any = [];
  decode: any = [];

  notesReport: any = [];

  //Manejo de fechas
  n = new Date();
  //Año
  y = this.n.getFullYear();
  //Mes
  m = this.n.getMonth() + 1;
  //Día
  d = this.n.getDate();

  h = this.n.toLocaleTimeString()


  //BANDERAS
  TarCar = true
  TarEst = false
  ListM = false
  ListG = true
  VP = false
  X = false

  //Notas
  active_note = '';
  notes: any = [];

  //Grafico y control de pagos
  graficoActual = true
  graficoAnual = false
  limite = -4
  tolerancia = -1
  tolerancia2 = -2
  tolerancia3 = -3

  limiteRe = 4
  toleranciaRe = 1
  tolerancia2Re = 2
  tolerancia3Re = 3

  // Configuraciones de graficos
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
      display: true,
      fullWidth: true
    },
    showLines: true,
    tooltips: {
      enabled: true,
      caretSize: 0,
      displayColors: true,
      titleFontSize: 20,
      bodyFontSize: 20
    },
    spanGaps: true,
    plugins: {
      datalabels: {
        formatter: (value: any, ctx: any) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  public piePruebaOptions: ChartDataSets = {
    showLine: true
  }
  public pieChartLabels: Label[] = [['Rescisión'], ['Emergente 2-3 atrasos'], ['Un solo atraso']];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgb(211, 25, 16 )', 'rgb(242, 228, 12)', 'rgb(39, 162, 11 )'],
    },
  ];
  //Ventana reporte pagos

  //barras
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = ['Flujo actual'];
  public barchartLAbelAnual: Label[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octumbre', 'Noviembre', 'Diciembre'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;


  //////////////////
  // reporte cartera vencida
  /////////////////

  projects: any = [];
  currentProject: any = [];
  pagosPorCliente: any = [];
  //notes : any = [];
  personalInformation = {
    RFC: null,
    abonado: 0,
    advancePayment: 0,
    clientes: "",
    codeProperty: "",
    email: "",
    fechaDato: "",
    firstPayDate: null,
    fiscalAddress: "",
    fiscalFolio: "",
    fiscalRegime: "",
    fiscalpc: "",
    fk_invoiceType: "",
    fkinvoiceType: null,
    mPagadas: 0,
    meses: 0,
    mobile: "",
    monthlyPayment: 0,
    name: "",
    retraso: 0,
    saldo: 0,
    usuarios: "",
    valor: 0,
    vendedores: null
  };

  dataCS: any = [];

  counterColors = {
    blue: 0,
    green: 0,
    yellow: 0,
    red: 0
  }

  counterColorsV2 = {
    purple: 0,
    blue: 0,
    green: 0,
    yellow: 0,
    red: 0,
    ninguno: 0
  }



  banderasV2 = {
    general: false,
    personal: false
  }

  anticipoC: any = 0;
  mensualidadC: any = 0;
  anticipoNR: any = 0;
  mensualidadNR: any = 0;

  //Variables para usar fechas en horario Local es-MX
  pipe = new DatePipe('es-MX');
  currentDate = new Date();

  // Configuraciones de graficos
  public pieChartOption: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
      display: false,
      fullWidth: true,

    },
    showLines: true,
    tooltips: {
      enabled: true,
      caretSize: 0,
      displayColors: true,
      titleFontSize: 5,
      bodyFontSize: 20
    },
    spanGaps: true,
    plugins: {
      datalabels: {
        formatter: (value: any, ctx: any) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };

  public pieChartLabel: Label[] = [`Al Corriente`, `1 Atraso`, `2 a 4 Atrasos`, `+4 Atrasos`];
  public pieChartTypes: ChartType = 'pie';
  public pieChartLegends = true;
  public pieChartPlugin = [pluginDataLabels];
  public pieChartColor = [{ backgroundColor: ['#04868A', '#22D003', '#F6CE06', '#FF0202',] }];
  public pieChartWidth = [{}];

  ngOnInit(): void {
    registerLocaleData(localeEsMx, 'es-MX'); //REGISTRAR LOCAL PARA ESPAÑOL this.currentDate.toISOString()

    this.getProjects();
    this.reporteCarteraListado()
    this.selectTotalesListado();
    this.selectAtrasosDes();
    this.selectAtrasosDesList();
    this.reporteCarteraProyecto();
    this.listCoordinador();


    this.filterByProjectAndType = ['Sin seleccionar'];
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                                     GRAFICO DE CARTERA VENCIDA
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  desCar() {
    return this.TarEst = true, this.TarCar = false
  }
  desEst() {
    return this.TarEst = false, this.TarCar = true
  }
  desG() {
    return this.VP = false, this.X = true
  }
  desX() {
    return this.X = false, this.VP = true, this.ListG = false, this.ListM = true
  }
  inmu() {
    return this.X = false, this.VP = true
  }

  carteraGrafico() {
    this.reportService.carteraG().subscribe(
      res => {

        this.carteraG = res;
      },
      err => console.log(err)
    );
  }

  carteraTotal() {
    this.reportService.carteraT().subscribe(
      res => {

        this.carteraT = res;
      },
      err => console.log(err)
    );
  }

  carteraRoja() {
    this.reportService.carteraR().subscribe(
      res => {

        this.carteraR = res;
      },
      err => console.log(err)
    );
  }
  carteraAmarilla() {
    this.reportService.carteraA().subscribe(
      res => {

        this.carteraA = res;
      },
      err => console.log(err)
    );
  }
  carteraVerde() {
    this.reportService.carteraV().subscribe(
      res => {

        this.carteraV = res;
      },
      err => console.log(err)
    );
  }

  //Cartera por coordinador
  reporteCarteraCoordinador(coordinadorCartera: string) {
    this.reportService.reporteCarteraCoordinador(coordinadorCartera).subscribe(
      res => {
        this.carteraListado = res;
      },
      err => console.log(err)
    );
  }

  slectPayments(cliente: string) {
    this.paymentService.selectPayments(cliente).subscribe(
      res => {
        this.active_note = cliente
        localStorage.setItem('property', this.active_note)
        this.payments = res;
      },
      err => console.log(err)
    );
  }

  reporteInmuCartera(carInmu: string) {
    this.reportService.reporteCarteraInmueble(carInmu).subscribe(
      res => {
        this.carteraInmu = res;
      },
      err => console.log(err)
    );
  }

  selectNotes(codeProperty: any) {
    this.reportService.notes(codeProperty).subscribe(
      res => {
        this.notes = res
        localStorage.setItem('property', codeProperty)
        this.dialog.open(DialogWalletComponent, {
          width: '700px',
          data: res
        })
      },
      err => console.log(err)
    )
  }

  selectPropertyNote(codeProperty: any, user: number) {
    //localStorage.setItem('property note',codeProperty)
    this.dialog.open(DialogNotesComponent, {
      width: '500px',
      data: codeProperty
    });
  }

  //////////////////
  reporteCarteraListado() {
    this.reportService.reporteCartera().subscribe(
      res => {

        this.carteraListado = res;
      },
      err => console.log(err)
    );
  }

  selectTotalesListado() {
    this.totalPagados.totalPagadosListado().subscribe(
      res => {

        this.montosListado = res;
      },
      err => console.log(err)
    );
  }

  selectAtrasosDes() {
    this.reportService.reporteDesAtra().subscribe(
      res => {

        this.reportDevBack = res;

      },
      err => console.log(err)
    );
  }
  selectAtrasosDesList() {
    this.reportService.reporteDesarrolloAtrasosCartera().subscribe(
      res => {

        this.reportDevBackList = res;

      },
      err => console.log(err)
    );
  }

  reporteCarteraProyecto() {
    this.reportService.reporteProyecto().subscribe(
      res => {

        this.proyecto = res;
      },
      err => console.log(err)
    );
  }

  //Listado coordinadores
  listCoordinador() {
    this.reportService.listadoCoordinadorP().subscribe(
      res => {
        this.coordListado = res;
      },
      err => console.log(err)
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                                     REPORTE DE CARTERA VENCIDA
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  async getProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      }
    );
  }

  async selectPayments(codeProperty: string) {
    this.paymentService.selectPayments(codeProperty).subscribe(
      res => {
        this.pagosPorCliente = res;
        console.log(this.pagosPorCliente);
      }
    )
  }

  async mueveData(codeProperty: string) {
    this.reportService.carteraInmueblePersonalService(codeProperty).subscribe(
      res => {
        this.dataCS = res;
        this.banderasV2.general = false;
        this.banderasV2.personal = true;
      }
    )
  }

  selectSalesCoo(codInmuebleCoo: any) {
    this.salesServices.selectSalesCoo(codInmuebleCoo).subscribe(
      res => {
        this.salesCoo = res;

      },
      err => console.log(err)
    );
  }

  async getCreditPayments(inmueble: String) {
    this.anticipoC = 0;
    this.mensualidadC = 0;

    this.paymentService.getCreditPayments(inmueble).subscribe(
      res => {
        this.anticipoC = res.Anticipo;
        this.mensualidadC = res.Mensualidad;
      }
    );
  }

  async getIndividualCreditPayments(inmueble: string, cliente: string, cordinator: string) {
    this.paymentService.getIndividualCreditPayments(inmueble).subscribe(
      res => {
        let data = { "payments": res, "customer": cliente, "cordinator": cordinator };
        this.dialog.open(CreditPaymentsComponent, {
          width: '100%',
          data: data
        });
      }
    );
  }

  permissions() {
    const token = localStorage.getItem('token') || "";

    let decodetoken: any = {};
    decodetoken = decode(token);

    return decodetoken.permissions;
  }


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                                     Cartera de juegos
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Configuraciones de graficos
  public pieChartOptionV2: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
      display: false,
      fullWidth: true,

    },
    showLines: true,
    tooltips: {
      enabled: true,
      caretSize: 0,
      displayColors: true,
      titleFontSize: 5,
      bodyFontSize: 20
    },
    spanGaps: true,
    plugins: {
      datalabels: {
        formatter: (value: any, ctx: any) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };

  public pieChartLabelV2: Label[] = [`Al Corriente`, `1 Atraso`, `2 a 4 Atrasos`, `+4 Atrasos`];
  public pieChartTypesV2: ChartType = 'pie';
  public pieChartLegendsV2 = true;
  public pieChartPluginV2 = [pluginDataLabels];
  public pieChartColorV2 = [{ backgroundColor: ['#0259bd', '#02bd54', '#F8BA43', '#D94555',] }];
  public pieChartWidthV2 = [{}];

  filterByProjectAndType: any = [];
  filterWalletByProjects(nameProject: string) {
    this.currentProject = nameProject
    this.banderasV2.personal = false;

    this.walletService.getWalletPerProyectService(nameProject).subscribe(
      res => {
        this.filterByProjectAndType = res;
        this.filterByProjectAndType.length == 0 ? this.banderasV2.general = false : this.banderasV2.general = true;
        this.typeAssignmentV2();
      }
    )
  }

  async typeAssignmentV2() {
    this.counterColorsV2 = {
      purple: 0,
      blue: 0,
      green: 0,
      yellow: 0,
      red: 0,
      ninguno: 0
    }

    for (let i = 0; i < this.filterByProjectAndType.length; i++) {
      if (this.filterByProjectAndType[i].agreement == 1) {
        this.counterColorsV2.purple += 1;
      }

      if (this.filterByProjectAndType[i].monthRecession == 4) {

        if (this.filterByProjectAndType[i].retraso >= 0) {
          this.counterColorsV2.blue += 1
        } else if (this.filterByProjectAndType[i].retraso >= -1 && this.filterByProjectAndType[i].retraso < 0) {
          this.counterColorsV2.green += 1
        } else if (this.filterByProjectAndType[i].retraso > -4 && this.filterByProjectAndType[i].retraso < -1) {
          this.counterColorsV2.yellow += 1
        } else if (this.filterByProjectAndType[i].retraso <= -4) {
          this.counterColorsV2.red += 1
        }

      } else if (this.filterByProjectAndType[i].monthRecession == 3) {

        if (this.filterByProjectAndType[i].retraso >= 0) {
          this.counterColorsV2.blue += 1
        }
        else if (this.filterByProjectAndType[i].retraso >= -1 && this.filterByProjectAndType[i].retraso < 0) {
          this.counterColorsV2.green += 1
        } else if (this.filterByProjectAndType[i].retraso > -3 && this.filterByProjectAndType[i].retraso < -1) {
          this.counterColorsV2.yellow += 1
        } else if (this.filterByProjectAndType[i].retraso <= -3) {
          this.counterColorsV2.red += 1
        }

      } else if (this.filterByProjectAndType[i].monthRecession == 2) {

        if (this.filterByProjectAndType[i].retraso >= 0) {
          this.counterColorsV2.blue += 1
        }
        else if (this.filterByProjectAndType[i].retraso > -1 && this.filterByProjectAndType[i].retraso < 0) {
          this.counterColorsV2.green += 1
        } else if (this.filterByProjectAndType[i].retraso > -2 && this.filterByProjectAndType[i].retraso <= -1) {
          this.counterColorsV2.yellow += 1
        } else if (this.filterByProjectAndType[i].retraso <= -2) {
          this.counterColorsV2.red += 1
        }

      } else if (this.filterByProjectAndType[i].monthRecession == null) {
        this.counterColorsV2.ninguno += 1
      }

    }

    this.orderByCode();
  }

  async orderByCode() {
    for (let i = 0; i < this.filterByProjectAndType.length; i++) {

      if (this.filterByProjectAndType[i].monthRecession == 4) {

        if (this.filterByProjectAndType[i].retraso.toFixed(2) >= 0) {
          this.filterByProjectAndType[i].color = "AA"
        }
        else if (this.filterByProjectAndType[i].retraso.toFixed(2) > -2 && this.filterByProjectAndType[i].retraso.toFixed(2) < 0) {
          this.filterByProjectAndType[i].color = "BB"
        }
        else if (this.filterByProjectAndType[i].retraso.toFixed(2) > -4 && this.filterByProjectAndType[i].retraso.toFixed(2) <= -2) {
          this.filterByProjectAndType[i].color = "CC"
        }
        else if (this.filterByProjectAndType[i].retraso.toFixed(2) <= -4) {
          this.filterByProjectAndType[i].color = "DD"
        }

      }
      else if (this.filterByProjectAndType[i].monthRecession == 3) {

        if (this.filterByProjectAndType[i].retraso.toFixed(2) >= 0) {
          this.filterByProjectAndType[i].color = "AA"
        }
        else if (this.filterByProjectAndType[i].retraso.toFixed(2) > -2 && this.filterByProjectAndType[i].retraso.toFixed(2) < 0) {
          this.filterByProjectAndType[i].color = "BB"
        }
        else if (this.filterByProjectAndType[i].retraso.toFixed(2) > -3 && this.filterByProjectAndType[i].retraso.toFixed(2) <= -2) {
          this.filterByProjectAndType[i].color = "CC"
        }
        else if (this.filterByProjectAndType[i].retraso.toFixed(2) <= -3) {
          this.filterByProjectAndType[i].color = "DD"
        }

      }
      else if (this.filterByProjectAndType[i].monthRecession == 2) {

        if (this.filterByProjectAndType[i].retraso.toFixed(2) >= 0) {
          this.filterByProjectAndType[i].color = "AA"
        }
        else if (this.filterByProjectAndType[i].retraso.toFixed(2) > -1 && this.filterByProjectAndType[i].retraso.toFixed(2) < 0) {
          this.filterByProjectAndType[i].color = "BB"
        }
        else if (this.filterByProjectAndType[i].retraso.toFixed(2) > -2 && this.filterByProjectAndType[i].retraso.toFixed(2) <= -1) {
          this.filterByProjectAndType[i].color = "CC"
        }
        else if (this.filterByProjectAndType[i].retraso.toFixed(2) <= -2) {
          this.filterByProjectAndType[i].color = "DD"
        }

      }

      else { this.filterByProjectAndType[i].color = "EE" }

    }
    this.orderAsc(this.filterByProjectAndType, "color")
  }

  orderAsc(p_array_json, p_key) {
    p_array_json.sort(function (a, b) {
      if (a[p_key] == b[p_key]) {
        return 0
      }
      if (a[p_key] > b[p_key]) {
        return -1
      }

      return 1;

    });

  }

  blockSale(inmu: string, retraso: number) {
    this.dialog.open(BlockSaleDialogComponent, {
      data: { "inmueble": inmu, "retraso": retraso },
      width: "100%"
    });
  }

  //Este es el botón V2 de bloqueo de cliente con historial de bloqueos, a partir de aquí empieza el cochinero SOLO PARA BLOQUEO
  async initBloq(cus: any) {
    let blockLog = {
      fk_idUser: cus.idUser,
      fk_property: cus.codeProperty,
      fk_blockReasonOld: null,
      fk_blockReasonNew: null,
      fk_idstatusOld: null,
      fk_idstatusNew: null,
      fk_idsales_catalogOld: null,
      fk_idsales_catalogNew: null,
      reasonDescription: null,
      fk_idEmployeeOld: null,
      fk_idEmployeeNew: null,
      dateLogOld: null
    }
    this.blockLogService.getSalesLog(blockLog.fk_idUser, blockLog.fk_property).subscribe(
      bls => {
        console.log('Registro de información actual extraída del cliente', bls);
        blockLog.fk_blockReasonOld = bls.fk_blockReason;
        blockLog.fk_idstatusOld = bls.fk_status;
        blockLog.fk_idsales_catalogOld = bls.fk_salesCatalog;
        blockLog.fk_idEmployeeOld = bls.fk_blockUser;
        blockLog.fk_idEmployeeNew = this.decode.fkuser;
        blockLog.fk_idEmployeeOld = bls.fk_blockUser;
        (bls.blockDate != null && bls.blockDate != undefined) ? blockLog.dateLogOld = (bls.blockDate).slice(0, 19).replace('T', ' ') : blockLog.dateLogOld = '1969-01-01 00:00:00'
        blockLog.fk_idsales_catalogNew = 8;
        blockLog.fk_idstatusNew = 2;
        console.log('Objeto de historial de bloqueo 50%:', blockLog);
        this.customBlock(cus, blockLog);
      }
    )
  }
  //DESPLIEGA OPCIONES DE BLOQUEO ASUMIENDO QUE EL INMUEBLE ESTÁ ACTIVO Y DESBLOQUEADO
  async customBlock(cus: any, blockLog: any) {

    //=======================SI YA ESTA BLOQUEADO=====================
    if (blockLog.fk_idstatusOld == 2 || blockLog.fk_idsales_catalogOld == 8) {
      this.sweet.AlertTime("center", "warning", `Error de integridad de datos (Este cliente se encuentra parcialmente bloqueado, validar estado de proceso de venta y de inmueble en interfaz de disponibilidad de inmueble o continuar ignorando la advertencia)`, true, 120000);
    }

    let listRecession: any = {}
    for (let cr of this.catalogoRecision) {
      console.log(cr);
      listRecession[cr.idtype_recession] = cr.description;
    }
    const { value: MBloqueo } = await Swal.fire({
      title: 'Motivo de bloqueo',
      html: "Inmueble: <b>" + blockLog.fk_property + "</b><br>" +
        "Cliente: <b>" + cus.clientes + "</b><br>" +
        "Para bloquear este inmueble, selecciona un motivo",
      input: 'select',
      inputOptions: listRecession,
      inputPlaceholder: 'Seleccione motivo de bloqueo',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dd6b55',
      confirmButtonText: 'Guardar',
      confirmButtonColor: '#4c9141',
      buttonsStyling: false,
      customClass: {
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn",
        actions: "sweetActions"
      },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === '') {
            resolve('Debes elegir una opción de la lista o hacer clic en cancelar')
          } else {
            console.log(value);
            resolve('');
          }
        })
      }
    })
    if (MBloqueo) {
      console.log('mbloqueo', MBloqueo);
      blockLog.fk_blockReasonNew = MBloqueo;
      console.log('Objeto de registro:', blockLog)
      this.confirmationLog(listRecession, cus, blockLog);
    } else {
      this.filterWalletByProjects(this.currentProject);
    }
  }

  //Escribir motivos de bloqueo y ejecutar servicios
  async confirmationLog(listRecession: any, cus: any, blockLog: any) {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'Escribe algunos detalles relacionados al motivo de BLOQUEO',
      inputLabel: 'Detalles de bloqueo de inmueble',
      html: "El inmueble: <b>" + blockLog.fk_property + "</b><br>" +
        "Cliente: <b>" + cus.clientes + "</b><br>" +
        "Motivo de bloqueo: <b>" + this.catalogoRecision[blockLog.fk_blockReasonNew - 1].description + "</b><br><br>" +
        "Usuario que autoriza el bloqueo: <b>" + this.decode.cliente + "</b><br>" +
        "Para continuar con el bloqueo, escriba los detalles y presione 'Continuar'",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      customClass: {
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn",
        actions: "sweetActions"
      },
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === '') {
            resolve('Escribe los detalles para continuar')
          } else {
            resolve('');
          }
        })
      },
      showCancelButton: true
    })
    if (text) {
      blockLog.reasonDescription = text;
      this.blockLogService.blockLocationSales(blockLog.fk_idUser, blockLog.fk_property, blockLog).subscribe(
        res => {
          console.log('BlocklocationSales', res);
          this.blockLogService.blockLocationProperties(blockLog.fk_property).subscribe(
            a => {
              console.log('blockLocationProperties', a);
              console.log('Se ha bloqueado inmueble ', blockLog.fk_property);
              this.blockLogService.insertLog(blockLog).subscribe(
                Res => {
                  console.log('Log insertado', blockLog);
                  this.sweet.AlertTime("center", "success", `El cliente ha sido bloqueado correctamente`, false, 2000);

                  this.filterWalletByProjects(this.currentProject);
                }
              )
            }
          )
        }
      )
    } else {
      this.filterWalletByProjects(this.currentProject);
    }
  }

  async setAgreement(code: string, user: number) {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'El cliente informa que habló con...',
      inputLabel: 'Describe el convenio que el cliente de ' + code + ' dice tener',
      html: 'Hola <b style="color: #D33E3E;">' + this.getUser() + '</b>',
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      customClass: {
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn",
        actions: "sweetActions"
      },
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === '') {
            resolve('Escribe los detalles para continuar')
          } else {
            resolve('');
          }
        })
      },
      showCancelButton: true
    })
    if (text) {
      const note = {
        note: text,
        responsable: this.getUser(),
        user: user
      };
      this.walletService.setAgreement(code, note).subscribe(
        res => {
          this.filterWalletByProjects(this.currentProject);
          this.sweet.AlertTime("center", "success", "Nota registrada exitosamente", false, 2000);
        }
      );
    }
  }

  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.cliente
  }

  async getNotesReport() {
    this.loading = 1;
    try {
      const res = await this.walletService.getNotesReport().toPromise();
      this.notesReport = res;

      setTimeout(() => {
        this.loading = 0;
        this.printNotesReport();
      }, 2000);

    } catch (error) {
      console.log(error);
      this.loading = 0;
    }
  }

  async printNotesReport() {
    let printContents, popupWin;
    printContents = document.getElementById('htmlNotes')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
    <html>
      <head>
        <title>Reporte de Cobranza ${this.pipe.transform(this.currentDate, 'longDate')}</title>
      
        <style>
          @media print{@page {size: landscape}}

          * {
          -webkit-print-color-adjust: exact;
          color-adjust: exact;
          font-size: 0.9rem;
          }

          body {
            font-family: "Montserrat", sans-serif;
          }

          table {
            border-collapse: collapse;
          }

          td {
            page-break-inside: avoid;
          }

          .titleBelt {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: solid #D33E3E 1.5px;
            padding: 3px;
          }

          .m19Table {
            width: 100%;
          }
          
          .m19Table thead {
            background-color: #D33E3E;
            vertical-align: bottom;
          }
          
          .m19Table thead * {
            padding: 0.3em;
          }
          
          .m19Table thead th {
            color: white;
            font-weight: bold;
          }
          
          .m19Table tbody tr {
            height: 20px;
          }
          
          .m19Table tbody tr td {
            padding: 0.3em;
          }
          
          .m19Table tbody tr:nth-child(even) {
            background-color: #e8ecf0;
          }
          
         
        </style>
      </head>
      <body onload="window.print();window.close()">${printContents}</body>
    </html>`
    );
    popupWin!.document.close();
  }
}