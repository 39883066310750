<div class="container-fluid mt-3">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">

            <div class="projectButtons">
                <button *ngFor="let p of projects" class="m19AltBtn" (click)="getModulesByProject(p.idProject, p.name)">
                    {{p.name}}
                    <mat-icon>apartment</mat-icon>
                </button>
            </div>

            <div class="moduleButtons">
                <button *ngFor="let m of modules" class="m19Btn" (click)="filterByModule(m.idprojectdet)">
                    Modulo: {{m.towernumber}}
                    <mat-icon>home</mat-icon>
                </button>
            </div>

            <div class="weekButtons">
                <button *ngFor="let w of weeks" class="m19InvBtn" (click)="setWeek(w.week, tower)">
                    Semana # {{w.week}}
                    <mat-icon>date_range</mat-icon>
                </button>
            </div>

            <br>

            <h4 *ngIf="pieceworks.length==0">Sin destajos para mostrar, ingrese un número de semana para buscar.</h4>

            <div class="cuadriculaReq" *ngIf="pieceworks.length!=0">

                <div class="tarjeta" *ngFor="let piecework of pieceworks">
                    <p style="text-align: center; font-size: 1rem;"><b>Papeleta de modulo: </b>
                        {{piecework.towernumber}}</p>
                    <p style="text-align: center; font-size: 0.9rem;"> <b>Semana: </b>#{{piecework.week}}
                        <br> <b>Periodo: </b><br>{{piecework.initweek | date: 'dd-MMM-yyyy'}} - {{piecework.endweek |
                        date: 'dd-MMM-yyyy'}}
                    </p>
                    <div class="info">
                        <!-- Botón para visualizar el desgloce de partidas de una requisición -->
                        <button class="btn btn-m19-info" title="Ver detalle" mat-icon-button
                            (click)="selectPiecework(piecework.idPiecework)">
                            <mat-icon>info</mat-icon>
                        </button>
                        <!-- Botón para eliminar un presupuesto -->
                        <button class="btn btn-m19-table" title="Eliminar" mat-icon-button
                            (click)="deletePiecework(piecework.iddeparpiece)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>