import { Component, OnInit } from '@angular/core';
import { SalesService } from 'src/app/services/sales.service';
import { PaymentService } from 'src/app/services/payment.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from '../../../services/report.service';
import { comissionReportService } from 'src/app/services/comission.service';
import decode from 'jwt-decode'

@Component({
  selector: 'app-coordinator-status',
  templateUrl: './coordinator-status.component.html',
  styleUrls: ['./coordinator-status.component.scss']
})
export class CoordinatorStatusComponent implements OnInit {

  //Nomenclatura inmueble
  M = "Manzana"
  L = "Lote"
  T = "Torre"
  D = "Departamento"

  creditPayments: any = [];

  anticipoC: any = 0;
  mensualidadC: any = 0;
  anticipoNR: any = 0;
  mensualidadNR: any = 0;


  //Manejo de fechas
  n = new Date();
  //Año
  y = this.n.getFullYear();
  //Mes
  m = this.n.getMonth() + 1;
  //Día
  d = this.n.getDate();
  //Hora
  h = this.n.toLocaleTimeString()
  salida = ""
  anios() {
    return this.y
  }
  mess() {
    return this.m
  }
  dias() {
    return this.d
  }
  salidaFecha() {
    if (this.m < 9 && this.d < 9) {
      this.salida = this.y + '-' + '0' + this.m + '-' + '0' + this.d + ' ' + this.h;
    } else if (this.d < 9 && this.m > 9) {
      this.salida = this.y + '-' + this.m + '-' + '0' + this.d + ' ' + this.h;
    } else if (this.d > 9 && this.m < 9) {
      this.salida = this.y + '-' + '0' + this.m + '-' + this.d + ' ' + this.h;
    } else {
      this.salida = this.y + '-' + this.m + '-' + this.d + ' ' + this.h;
    }
    return this.salida
  }

  //Grafico y control de pagos
  limiteC = -4
  toleranciaC = -1
  tolerancia2C = -2
  tolerancia3C = -3

  limite = -4
  tolerancia = -1
  tolerancia2 = -2
  tolerancia3 = -3

  //Manejo de información res API, arreglos con carga de datos de las consultas a los servicios que conectan con la API
  pagos: any = [];
  coordEmail: any = [];
  sales: any = []
  totales: any = [];
  carteraInmu: any = [];
  payments: any = [];
  pago: any = [];


  constructor(public modal: NgbModal, private toastr: ToastrService, private reportServices: ReportService, private salesServices: SalesService, private reportService: PaymentService, private router: Router, private comissions: comissionReportService) { }

  //Metodo de carga de metodos al momento de la ejecución
  ngOnInit(): void {
    const token = localStorage.getItem('token') || "";

    let decodetoken: any = {};
    decodetoken = decode(token);

    this.selectCoordEmail(decodetoken.email);
  }

  //Consulta de datos desde el servicio que consulta a la API
  selectCoordEmail(email: string) {
    this.comissions.reporteCoordEmail(email).subscribe(
      res => {
        this.coordEmail = res;
      },
      err => console.log(err)
    );
  }

  reporteInmuCartera(carInmu: string) {
    this.reportServices.reporteCarteraInmueble(carInmu).subscribe(
      res => {

        console.log(res)
        this.carteraInmu = res;
      },
      err => console.log(err)
    );
  }

  slectPayments(cliente: string) {
    this.comissions.reportePaymentSales(cliente).subscribe(
      res => {
        this.payments = res;
      },
      err => console.log(err)
    );
  }
  slectPayment(cliente: string) {
    this.reportService.selectPayments(cliente).subscribe(
      res => {
        this.pago = res;
      },
      err => console.log(err)
    );
  }

  selectSales(codInmueble: any) {
    this.salesServices.selectSales(codInmueble).subscribe(
      res => {
        this.sales = res;
      },
      err => console.log(err)
    );
  }
  sumTotales(code: string) {
    this.reportService.sumTotal(code).subscribe(
      data => {
        this.totales = data;
      },
      err => console.log(err)
    );

  }

  async getCreditPaymentsList(inmueble: string) {
    this.reportService.getIndividualCreditPayments(inmueble).subscribe(
      res => {
        this.creditPayments = res;
      }
    );
  }

  async getCreditPayments(inmueble: String) {
    this.reportService.getCreditPayments(inmueble).subscribe(
      res => {
        this.anticipoC = res.Anticipo;
        this.mensualidadC = res.Mensualidad;
      }
    );
  }

  async getCreditNRPayments(inmueble: String) {
    this.reportService.getCreditNRPayments(inmueble).subscribe(
      res => {
        this.anticipoNR = res.Anticipo;
        this.mensualidadNR = res.Mensualidad;
      }
    );
  }

}