<div class="reqContenedor" (scroll)="onScroll()">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">

            <div class="buttonsBelt" style="justify-content: space-between;">
                <button class="btnOpc white" (click)="getReqWithFilter('approve')">Todas</button>
                <button class="btnOpc blue" (click)="getReqWithFilter('NA')">En espera</button>
                <button class="btnOpc green" (click)="getReqWithFilter('SI')">Aprobadas</button>
                <button class="btnOpc red" (click)="getReqWithFilter('NO')">Rechazadas</button>
            </div>

            <div class="cuadriculaReq">
                <div class="tarjeta" *ngFor="let requisition of requisitions">
                    <span class="reqDate">{{pipe.transform(requisition.requisitiondate, "longDate")}}</span>
                    <span class="reqHeader"><b>{{requisition.idProject}}</b><b style="font-size: 1rem; color: var(--M19Primary);">#{{requisition.requisitionnumber}}</b></span>
                    <span><b>Modulo </b> {{requisition.towernumber}}</span>
                    <br>
                    <span><b style="color: var(--M19Primary);">Registró</b> {{requisition.responsable}}</span>
                    <div class="info">
                        <!-- Botón para visualizar el desgloce de partidas de una requisición -->
                        <button class="actionAltBtn" title="Ver detalle" mat-icon-button
                            (click)="selectDeparture(requisition.idrequisition)">
                            <mat-icon>info</mat-icon>
                        </button>
                        <!-- Botón para visualizar el desgloce de partidas de una requisición -->
                        <button class="actionAltBtn" title="Nota de remisión"
                            mat-icon-button (click)="selectDelivery(requisition.idrequisition)">
                            <mat-icon>receipt_long</mat-icon>
                        </button>
                    </div>
                    <div class="approve">
                        <mat-icon title="APROBADO" style="color:var(--primaryGreen)" *ngIf="requisition.approve=='SI'">
                            verified</mat-icon>
                        <mat-icon title="RECHAZADO" style="color:var(--primaryRed)" *ngIf="requisition.approve=='NO'">
                            cancel</mat-icon>
                        <!-- Botón para aprobar la requisición -->
                        <button *ngIf="requisition.approve=='NA'" class="m19IconBtn positiveBtn" title="Aprobar" mat-icon-button
                            (click)="changeStatus(requisition.idrequisition, 'approve')">
                            <mat-icon>thumb_up</mat-icon>
                        </button>
                        <!-- Botón para rechazar la requisición -->
                        <button *ngIf="requisition.approve=='NA'" class="m19IconBtn negativeBtn" title="Rechazar" mat-icon-button
                            (click)="changeStatus(requisition.idrequisition, 'reject')">
                            <mat-icon>thumb_down</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <button [ngClass]="{'goTopBtn--show': showButton, 'goTopBtn--hide': !showButton}"
            (click)="scrollToTop()"><mat-icon>expand_less</mat-icon></button>
        </mat-card-content>
    </mat-card>
</div>