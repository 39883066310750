import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, _closeDialogVia } from '@angular/material/dialog';
import { DialogRegisteruserComponent } from '../dialog-registeruser/dialog-registeruser.component';
import { DialogSelecUserComponent } from '../dialog-selec-user/dialog-selec-user.component';

@Component({
  selector: 'app-dialog-selected-type-user',
  templateUrl: './dialog-selected-type-user.component.html',
  styleUrls: ['./dialog-selected-type-user.component.scss']
})
export class DialogSelectedTypeUserComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DialogSelectedTypeUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  ClienteYet() {
    const dialog2 = this.dialog.open(DialogSelecUserComponent, {
      width: '800px',
      height: '1200px'
    });

    this.dialogRef.close();
  }

  ClienteNot() {
    const dialog2 = this.dialog.open(DialogRegisteruserComponent, {
      width: '800px',
      height: '1200px'
    });

    this.dialogRef.close();
  }
}