<table class="table table-striped animated fadeIn faster">
    <thead class="thead-m19">
        <tr>
            <th scope="col">Nombre del proyecto</th>
            <th scope="col">Localidad</th>
            <th scope="col">Nomenclatura</th>
            <th scope="col">Direccion</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let proyectDetail of proyectsDetails">
            <td>{{proyectDetail.name}}</td>
            <td>{{proyectDetail.municipality}}</td>
            <td>{{proyectDetail.nomenclature}}</td>
            <td>{{proyectDetail.address}}</td>
        </tr>
    </tbody>
</table>