import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdministrationService } from 'src/app/services/administration.service';
import Swal from 'sweetalert2';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SharedServiceService } from 'src/app/shared/shared-service.service';
import { propertieData } from 'src/app/models/Administration.model';

import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { forkJoin, from } from 'rxjs';
import { loader } from 'src/app/ui/loader/loader.model';

@Component({
  selector: 'app-rescission-agreement',
  templateUrl: './rescission-agreement.component.html',
  styleUrls: ['./rescission-agreement.component.scss']
})
export class RescissionAgreementComponent implements OnInit {
  loader: loader = {
    message: "Preparando Información"
  }

  folio: string = "C";

  today: Date = new Date()

  propertiesData: propertieData[] = [];

  agreementData = {
    contractDate: "Cargando...",
    codeProperty: "Cargando...",
    creditBalance: 0,
    customerName: "Cargando...",
    gender: "Cargando...",
    voterKey: "Cargando...",
    projectName: "Cargando...",
    monthRecession: "Cargando..."
  }

  paymentData = {
    penalizedBalance: 0, //Saldo con el que se penalizará al cliente
    bankAccount: "0145 6060 6263 845039", //Cuenta clabe de banco a la que se le va a depositar, deben ser 18 dígitos (no debe incluir espacios)
    frequency: "mensuales", //Frecuencia de pagos
    memo: 5000, //Cuanto se le va a dar al cliente por periodo
    firstPay: 15, //Cuando debe ser el primer pago en días hábiles a partir de la firma
    reason: 0, //Motivo de recisión
    legalRepresentative: "", //Nombre del representante o a quien se le va a dar el dinero
  }

  monthName = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE"
  ];

  constructor(
    private dialogRef: MatDialogRef<RescissionAgreementComponent>,
    public adminService: AdministrationService,
    private sharedService: SharedServiceService,
    public sweet: SweetAlertComponent,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.paymentData = this.data;
    this.getRescInfo(this.data.customers, this.data.codeProperties);
  }

  getRescInfo(idCustomers: string, codeProperties: string) {
    this.adminService._getRescissionAgreementData(idCustomers, codeProperties).subscribe(
      async res => {
        console.log(res);
        this.propertiesData = res.map(item => ({
          ...item,
          contractDate: new Date(item.contractDate),
          fechaDato: new Date(item.fechaDato)
        }));

        this.agreementData = {
          contractDate: Array.from(new Set(this.propertiesData.map(item => item.contractDate.getDate() + 1 + ' ' + this.convertNumberToText((item.contractDate.getDate() + 1).toString()).toLowerCase() + ' del mes de ' + this.monthName[item.contractDate.getMonth()].toLowerCase() + ' del año ' + item.contractDate.getFullYear() + ' ' + this.convertNumberToText((item.contractDate.getFullYear()).toString()).toLowerCase()))).join(", "),
          codeProperty: Array.from(new Set(this.propertiesData.map(item => item.codeProperty))).join(","),
          creditBalance: this.propertiesData.reduce((total, item) => total + (item.creditnote) + (item.cantidadAbono), 0) - this.paymentData.penalizedBalance,
          customerName: Array.from(new Set(this.propertiesData.map(item => item.clientName.toUpperCase().trim()))).join(","),
          gender: Array.from(new Set(this.propertiesData.map(item => item.gender.toUpperCase()))).join(","),
          voterKey: Array.from(new Set(this.propertiesData.map(item => item.voterKey.toUpperCase()))).join(","),
          projectName: Array.from(new Set(this.propertiesData.map(item => item.projectName.toUpperCase()))).join(","),
          monthRecession: Array.from(new Set(this.propertiesData.map(item => item.monthRecession))).join(","),
        }

        let result = "";
        let cambioManzana = true;
        if (res.length === 1) {
          const property = res[0];
          result = `el bien inmueble ubicado en el ${property.codeProperty[5] === 'L' ? 'lote' : 'departamento'} identificado económicamente con el número ${property.codeProperty.substring(6, 8)}, de la ${property.codeProperty[2] === 'M' ? 'manzana' : 'torre'} ${property.codeProperty.substring(3, 5)}`;
        } else {
          result = res.reduce((acc, property, index) => {
            const currentCode = property.codeProperty.substring(3, 5);
            const nextCode = index < res.length - 1 ? res[index + 1].codeProperty.substring(3, 5) : null;
            const prevCode = index > 0 ? res[index - 1].codeProperty.substring(3, 5) : null;

            if (nextCode && currentCode === nextCode && cambioManzana) {
              if (acc.length > 0) acc += " y ";
              acc += `los bienes inmuebles ubicados en los ${property.codeProperty[5] === 'L' ? 'lotes' : 'departamentos'} identificados económicamente con los números ${property.codeProperty.substring(6, 8)}`;
              cambioManzana = false;
            } else if (nextCode && currentCode === nextCode && nextCode === res[index + 2]?.codeProperty.substring(3, 5)) {
              acc += `, ${property.codeProperty.substring(6, 8)}`;
            } else if (prevCode && currentCode === prevCode) {
              if (nextCode && currentCode === nextCode) {
                acc += `, ${property.codeProperty.substring(6, 8)}`;
              } else {
                acc += ` y ${property.codeProperty.substring(6, 8)}, de la ${property.codeProperty[2] === 'M' ? 'manzana' : 'torre'} ${property.codeProperty.substring(3, 5)}`;
                cambioManzana = true;
              }
            } else {
              if (acc.length > 0) acc += " y el ";
              acc += `${property.codeProperty[5] === 'L' ? 'lote' : 'departamento'} identificado económicamente con el número ${property.codeProperty.substring(6, 8)}, de la ${property.codeProperty[2] === 'M' ? 'manzana' : 'torre'} ${property.codeProperty.substring(3, 5)}`;
              cambioManzana = true;
            }

            return acc;
          }, "");
        }

        console.log(result);

        this.agreementData.codeProperty = result;
      }
    );
  }


  convertNumberToText(num: string) {
    let number = Math.round(Number(num));

    return (this.convertThousandsToString(number));
  }

  //Funciones que deberían ser librerías xd
  convertThousandsToString(num: number) {
    var thousands = Math.floor(num / 1000);
    var hundreds = num - thousands * 1000;

    var letras = '';

    if (thousands > 0) {
      if (thousands > 1) {
        letras = this.convertHundredsToString(thousands) + ' ' + 'MIL';
      } else {
        letras = 'MIL'
      }
    }

    if (hundreds > 0) {
      letras += '';
    }

    var strCentenas = this.convertHundredsToString(hundreds);

    if (letras === '') {
      return strCentenas;
    }

    return (letras + ' ' + strCentenas);
  }

  convertHundredsToString(num: number) {
    var hundreds = Math.floor(num / 100);
    var dozens = num - hundreds * 100;

    switch (hundreds) {
      case 1:
        if (dozens > 0) {
          return 'CIENTO ' + this.convertDozensToString(dozens);
        }
        return 'CIEN';
      case 2:
        return 'DOSCIENTOS ' + this.convertDozensToString(dozens);
      case 3:
        return 'TRESCIENTOS ' + this.convertDozensToString(dozens);
      case 4:
        return 'CUATROCIENTOS ' + this.convertDozensToString(dozens);
      case 5:
        return 'QUINIENTOS ' + this.convertDozensToString(dozens);
      case 6:
        return 'SEISCIENTOS ' + this.convertDozensToString(dozens);
      case 7:
        return 'SETECIENTOS ' + this.convertDozensToString(dozens);
      case 8:
        return 'OCHOCIENTOS ' + this.convertDozensToString(dozens);
      case 9:
        return 'NOVECIENTOS ' + this.convertDozensToString(dozens);
      default:
        return this.convertDozensToString(dozens);
    }
  }

  convertDozensToString(num: number) {
    var dozens = Math.floor(num / 10);
    var units = num - dozens * 10;

    switch (dozens) {
      case 1:
        switch (units) {
          case 0:
            return 'DIEZ';
          case 1:
            return 'ONCE';
          case 2:
            return 'DOCE';
          case 3:
            return 'TRECE';
          case 4:
            return 'CATORCE';
          case 5:
            return 'QUINCE';
          default:
            return 'DIECI' + this.convertDigitToString(units);
        }
      case 2:
        switch (units) {
          case 0:
            return 'VEINTE';
          default:
            return 'VEINTI' + this.convertDigitToString(units);
        }
      case 3:
        return this.convertTeensToString('TREINTA', units);
      case 4:
        return this.convertTeensToString('CUARENTA', units);
      case 5:
        return this.convertTeensToString('CINCUENTA', units);
      case 6:
        return this.convertTeensToString('SESENTA', units);
      case 7:
        return this.convertTeensToString('SETENTA', units);
      case 8:
        return this.convertTeensToString('OCHENTA', units);
      case 9:
        return this.convertTeensToString('NOVENTA', units);
      case 0:
        return this.convertDigitToString(units);
      default:
        return '';
    }
  }

  convertDigitToString(num: number) {
    switch (num) {
      case 1:
        return 'UN';
      case 2:
        return 'DOS';
      case 3:
        return 'TRES';
      case 4:
        return 'CUATRO';
      case 5:
        return 'CINCO';
      case 6:
        return 'SEIS';
      case 7:
        return 'SIETE';
      case 8:
        return 'OCHO';
      case 9:
        return 'NUEVE';
      default:
        return '';
    }
  }

  convertTeensToString(ten: string, num: number) {
    if (num > 0) {
      return ten + ' Y ' + this.convertDigitToString(num);
    }

    return ten;
  }

  pruebaImpresion() {
    console.log('Pressed');
    this.saveNegotiation();
  }

  saveNegotiation() {
    this.changeLoadingMessage("Guardando Negociación");

    this.adminService.saveNegotiation(this.data.negotiationData).subscribe(
      res => {
        const observables = [];

        this.folio = "C" + res.data;

        if (this.data.representative != '') {
          this.changeLoadingMessage("Guardando Representante Legal");
          observables.push(from(this.adminService.addRepresentativeNegotiation(res.data, this.data.representative)));
        }

        this.changeLoadingMessage("Guardando Unidades de Negociación");
        for (let n of this.data.toNegotiate) {
          n.FKNegotiation = res.data;
          observables.push(from(this.adminService.addUnitsToNegotiation(n)));
        }

        if (observables.length > 0) {
          forkJoin(observables).subscribe(
            () => {
              this.sweet.AlertTime("center", "success", "Registro realizado con éxito.", false, 2000);
              setTimeout(() => {
                this.printContract(this.propertiesData);
              }, 1500);
            }, error => {
              this.sweet.AlertTime("center", "success", "Ha ocurrido un error, informe al área de sistemas: " + error, false, 2000);
            }
          );
        }
      }
    );
  }

  printContract(sale: any) {
    let backgroundLogo = this.sharedService.projectLogos.filter(item => { return item.projectName == sale.projectName });

    let printContents, popupWin;
    printContents = document.getElementById('rescissionFormat')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
      <html>
        <head>
          <title>Convenio</title>
          <style>
          * {
          margin: 0;
          padding: 0;
          -webkit-print-color-adjust: exact;
          color-adjust: exact;
          font-family: Verdana;
          }

          @page {
            size: letter;
            margin: 2.2cm 2.7cm 2.2cm 2.7cm;
          }

          .rescissionFormat {
            font-family: Verdana;
            font-size: 12.5pt;
          }

          .rescissionFormat h1 {
            font-size: 13.5pt;
            text-align: center;
          }

          .rescissionFormat p {
              text-align: justify;
          }

          .rescissionFormat .custom-list {
            list-style-type: disc;
            list-style-position: outside;
          }

          .rescissionFormat .signs {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
          }

          .rescissionFormat .signs > p {
            flex-grow: 1;
            flex-basis: 0;
            max-width: 260px;
            text-align: center;
            font-size: 11pt;
            border-top: solid 1px black;
          }

          .rescissionFormat {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;
          }

          .rescissionFormat{
            flex-grow: 1;
            flex-basis: 0;
          }

          body::after {
              content: "";
              width: 100%;
              height: 100%;
              background-image: url('../../../../../assets/imagotipo_bn.png');
              background-size: contain;
              background-repeat: no-repeat;
              opacity: 0.8;
              position: fixed;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -35%);
              z-index: -1;
          }
          </style>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );

    popupWin!.document.close();
    //this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['negotiation']));
  }

  changeLoadingMessage(msg: string) {
    this.loader = {
      ...this.loader,
      message: msg
    };
  }
}

