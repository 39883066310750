import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import decode from 'jwt-decode';
import { PieceworkService } from 'src/app/services/piecework.service';

//=========================================================================================================
//Importación de librerías para componentes visuales
//=========================================================================================================
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-piecework',
  templateUrl: './edit-piecework.component.html',
  styleUrls: ['./edit-piecework.component.scss']
})
export class EditPieceworkComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<EditPieceworkComponent>,

    private pieceworkService: PieceworkService,

    //===================================================
    //Declaración de complementos visuales
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  decode: any = {};               //Datos de acceso

  //==========================================================================================================
  //Objeto constructor del formulario utilizado en la pantalla de requisición, se agrega el código
  //", Validators.required" para señalar que es un valor que no debe estar vacío
  //==========================================================================================================
  pieceworkFormGroup: FormGroup = this._formBuilder.group({
    idpiecework_catalog: [this.data.idpiecework_catalog],
    description: [, Validators.required],
    unit: [, Validators.required],
    cost: [, Validators.required],
    useremail: [this.getUser()]
  });

  ngOnInit(): void {
    console.log(this.data)
    this.pieceworkFormGroup.controls['description'].setValue(this.data.description);
    this.pieceworkFormGroup.controls['unit'].setValue(this.data.unit);
    this.pieceworkFormGroup.controls['cost'].setValue(this.data.cost);
  }

  async updatePiecework() {
    Swal.fire({
      title: '¿Quieres guardar tus cambios ahora?',
      text: "¡Este destajo se guardara como está actualmente!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, no guardar',
      confirmButtonText: 'Sí, guardar!'
    }).then((result) => {
      console.log(this.pieceworkFormGroup.value)
      if (result.isConfirmed) {
        this.pieceworkService.updatePieceworkCatalog(this.pieceworkFormGroup.value).subscribe(
          res => {
            this.sweet.AlertTime("center", "success", "Destajo actualizada con éxito.", false, 2000);
            this.dialogRef.close();
          }
        );
      }
    })
  }

  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.email
  }

}
