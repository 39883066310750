//MODULES
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarModule } from 'ng-sidebar';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';

//COMPONENTS
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HomeHeaderComponent } from './components/commons/home/home-header/home-header.component';
import { HomePageComponent } from './components/commons/home/home-page/home-page.component';
import { SidebarContentComponent } from './components/commons/sidebar-content/sidebar-content.component';
import { ProjectComponent } from './components/project/project.component';
import { UserComponent } from './components/user/user.component';
import { CustomerComponent } from './components/customer/customer.component';
import { ReportComponent } from './components/search/report/report.component';
import { SettingComponent } from './components/setting/setting.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { GraficosComponent } from './components/graficos/graficos.component';
import { SaleComponent } from './components/sale/sale.component';
import { DialogCustomerComponent } from './components/customer/dialog-customer/dialog-customer.component';
import { DialogUserComponent } from './components/user/dialog-user/dialog-user.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { CoordinatorPaymentComponent } from './components/commission/coordinator-payment/coordinator-payment.component';
import { CoordinatorStatusComponent } from './components/commission/coordinator-status/coordinator-status.component';
import { WalletByWeekComponent } from './components/wallet-by-week/wallet-by-week.component';
import { DialogSaleComponent } from './components/sale/dialog-sale/dialog-sale.component';
import { DialogRecessionsComponent } from './components/sale/dialog-recessions/dialog-recessions.component';
import { DetailRecessionsComponent } from './components/sale/recession/detail-recessions/detail-recessions.component';
import { RecessionComponent } from './components/sale/recession/recession.component';
import { EntryComponent } from './components/entry/entry.component';
import { FacturaGlobalComponent } from './components/invoice/factura-global/factura-global.component';
import { FacturasComponent } from './components/invoice/facturas/facturas.component';
import { FlowReportComponent } from './components/flow-report/flow-report.component';
import { SearchComponent } from './components/search/search.component';
import { WalletComponent } from './components/wallet/wallet.component';
import { AccountstatusComponent } from './components/accountstatus/accountstatus.component';
import { CancelledSearchComponent } from './components/search/cancelled-search/cancelled-search.component';
import { ConsultaFacturaComponent } from './components/invoice/consulta-factura/consulta-factura.component';

//SERVICES
import { UserService } from './services/user.service';
import { ProjectService } from './services/project.service';
import { PaymentService } from './services/payment.service';

//MATERIAL
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MAT_MENU_DEFAULT_OPTIONS, MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AccountstatuscoordadminComponent } from './components/accountstatuscoordadmin/accountstatuscoordadmin.component';

import { RequestComponent } from './components/request/request.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SweetAlertComponent } from '../app/utils/sweet-alert/sweet-alert.component';
import { PdfMakerComponent } from './utils/pdf-maker/pdf-maker.component';

// Import pdfmake-wrapper and the fonts to use
import { PdfMakeWrapper } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { PostalCodeGeneratorComponent } from './utils/postal-code-generator/postal-code-generator.component'; // fonts provided for pdfmake

//funtes por defecto que se cargan
PdfMakeWrapper.setFonts(pdfFonts);

import { PaymentMethodComponent } from './components/setting/payment-method/payment-method.component';
import { TypeUserComponent } from './components/setting/type-user/type-user.component';
import { DialogUserFormComponent } from './components/user/dialog-user-form/dialog-user-form.component';
import { GlobalInvoiceComponent } from './components/invoice/global-invoice/global-invoice.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { CommissionComponent } from './components/commission/commission.component';
import { PermissionComponent } from './components/setting/permission/permission.component';
import { DialogWalletComponent } from './components/wallet/dialog-wallet/dialog-wallet.component';
import { DialogNotesComponent } from './components/wallet/dialog-notes/dialog-notes.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FilterEmployeePipe } from './pipes/filter-employee.pipe';
import { FilterPropertyPipe } from './pipes/filter-property.pipe';
import { FilterRecessionsPipe } from './pipes/filter-recessions.pipe';
import { DialogCustomerFormComponent } from './components/customer/dialog-customer-form/dialog-customer-form.component';
import { DialogPropertyComponent } from './components/project/dialog-property/dialog-property.component';
import { DialogPropertyFormComponent } from './components/project/dialog-property-form/dialog-property-form.component';
import { RequisitionregisterComponent } from './components/requisition/requisitionregister/requisitionregister.component';
import { RequisitionconsultComponent } from './components/requisition/requisitionconsult/requisitionconsult.component';
import { MapsGenerateComponent } from './components/maps/maps-generate/maps-generate.component';
import { MapsEditComponent } from './components/maps/maps-edit/maps-edit.component';
import { MapsConsultComponent } from './components/maps/maps-consult/maps-consult.component';
import { DialogMapsSelectComponent } from './components/maps/dialog-maps-select/dialog-maps-select.component';
import { DialogMapsNotRegisterComponent } from './components/maps/dialog-maps-not-register/dialog-maps-not-register.component';
import { UpdateMapToFirebaseComponent } from './components/maps/update-map-to-firebase/update-map-to-firebase.component';
import { DialogRegisteruserComponent } from './components/maps/dialog-registeruser/dialog-registeruser.component';
import { DialoGenerateSalesComponent } from './components/maps/dialo-generate-sales/dialo-generate-sales.component';
import { DialogSelectedTypeUserComponent } from './components/maps/dialog-selected-type-user/dialog-selected-type-user.component';
import { DialogSelecUserComponent } from './components/maps/dialog-selec-user/dialog-selec-user.component';
import { FilterCustomerPipe } from './pipes/filter-customer.pipe';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';

import { AngularFireStorageModule} from '@angular/fire/storage';
import { SketchMapsComponent } from './components/maps/sketch-maps/sketch-maps.component';
import { ProyectsDetailsComponent } from './components/proyects-details/proyects-details.component';
import { DialogDigitalCustomerComponent } from './components/maps/dialog-digital-customer/dialog-digital-customer.component';
import { DialogSketchmapVisualComponent } from './components/maps/dialog-sketchmap-visual/dialog-sketchmap-visual.component'
import { DialogRequisitionComponent } from './components/requisition/requisitionconsult/dialog-requisition/dialog-requisition.component';
import { RequisitioncontrolComponent } from './components/requisition/requisitioncontrol/requisitioncontrol.component';
import { DialogDeliveryComponent } from './components/requisition/requisitionconsult/dialog-delivery/dialog-delivery.component';

import { DialogSaleConfirmComponent } from './components/maps/dialog-sale-confirm/dialog-sale-confirm.component';
import { RequestDOCComponent } from './utils/request-doc/request-doc.component';
import { PieceworkconsultComponent } from './components/piecework/pieceworkconsult/pieceworkconsult.component';
import { PieceworkcontrolComponent } from './components/piecework/pieceworkcontrol/pieceworkcontrol.component';
import { PieceworkregisterComponent } from './components/piecework/pieceworkregister/pieceworkregister.component';
import { DialogPieceworksComponent } from './components/piecework/pieceworkconsult/dialog-pieceworks/dialog-pieceworks.component';
import { DialogInvoiceComponent } from './components/requisition/requisitionconsult/dialog-invoice/dialog-invoice.component';
import { DialogInsertproductsComponent } from './components/requisition/requisitionregister/dialog-insertproducts/dialog-insertproducts.component';
import { DialogSaleFormComponent } from './components/sale/dialog-sale-form/dialog-sale-form.component';
import { MapsforSalesComponent } from './components/maps/mapsfor-sales/mapsfor-sales.component';
import { EditSalesComponent } from './components/maps/edit-sales/edit-sales.component';
import { FinishedSaleComponent } from './components/maps/finished-sale/finished-sale.component';
import { ViewRequestComponent } from './components/administration/view-request/view-request.component';
import { GeneralRequestComponent } from './components/administration/general-request/general-request.component';
import { DialogRequestViewComponent } from './components/administration/dialog-request-view/dialog-request-view.component';
import { ViewComissionComponent } from './components/controlComissions/view-comission/view-comission.component';
import { DialogUpdateViewComponent } from './components/controlComissions/dialogUpdate/dialog-update-view/dialog-update-view.component';
import { DialogUpdateRequisitionComponent } from './components/requisition/requisitionconsult/dialog-update-requisition/dialog-update-requisition.component';
import { ControlRequestComponent } from './components/administration/control-request/control-request.component';
import { RequisitionapproveComponent } from './components/requisition/requisitionapprove/requisitionapprove.component';
import { DialogInsertsupplierComponent } from './components/requisition/requisitionregister/dialog-insertsupplier/dialog-insertsupplier.component';
import { DepartureUpdateComponent } from './components/requisition/requisitioncontrol/departure-update/departure-update.component';
import { DialogEditDeliveryComponent } from './components/requisition/requisitionconsult/dialog-delivery/dialog-edit-delivery/dialog-edit-delivery.component';
import { DeparturecontrolComponent } from './components/requisition/departurecontrol/departurecontrol.component';
import { MaterialUpdateComponent } from './components/requisition/requisitioncontrol/material-update/material-update.component';
import { SupplierUpdateComponent } from './components/requisition/requisitioncontrol/supplier-update/supplier-update.component';
import { EditPieceworkComponent } from './components/piecework/pieceworkcontrol/edit-piecework/edit-piecework.component';
import { ConceptUpdateComponent } from './components/requisition/requisitioncontrol/concept-update/concept-update.component';
import { BudgetregisterComponent } from './components/budget/budgetregister/budgetregister.component';
import { BudgetconsultComponent } from './components/budget/budgetconsult/budgetconsult.component';
import { BudgetdialogComponent } from './components/budget/budgetconsult/budgetdialog/budgetdialog.component';
import { BudgetupdateconsultComponent } from './components/budget/budgetconsult/budgetupdateconsult/budgetupdateconsult.component';
import { PieceworkUpdateComponent } from './components/piecework/pieceworkconsult/piecework-update/piecework-update.component';
import { BudgetapproveComponent } from './components/budget/budgetapprove/budgetapprove.component';
import { ConsumerTokenRegisterComponent } from './components/consumerToken/consumer-token-register/consumer-token-register.component';
import { ConsumerTokenConsultMainComponent } from './components/consumerToken/consumerTokenConsult/consumer-token-consult-main/consumer-token-consult-main.component';
import { ConsumerTokenConsultInfoDialogComponent } from './components/consumerToken/consumerTokenConsult/consumer-token-consult-info-dialog/consumer-token-consult-info-dialog.component';
import { ConsumerTokenModifyMainComponent } from './components/consumerToken/consumerTokenModify/consumer-token-modify-main/consumer-token-modify-main.component';
import { ConsumerTokenModifyInfoDialogComponent } from './components/consumerToken/consumerTokenModify/consumer-token-modify-info-dialog/consumer-token-modify-info-dialog.component';
import { ConsumerTokenModifyEditDialogComponent } from './components/consumerToken/consumerTokenModify/consumer-token-modify-edit-dialog/consumer-token-modify-edit-dialog.component';
import { StatusPaymentsComponent } from './components/invoice/status-payments/status-payments.component';
import { DialogEditInvoiceComponent } from './components/requisition/requisitionconsult/dialog-invoice/dialog-edit-invoice/dialog-edit-invoice.component';
import { PieceworkreportComponent } from './components/piecework/pieceworkreport/pieceworkreport.component';
import { DetailOverageComponent } from './components/piecework/pieceworkreport/detail-overage/detail-overage.component';
import { GenerateAndCalculateCommisionsComponent } from './components/controlComissions/generate-and-calculate-commisions/generate-and-calculate-commisions.component';
import { InvoicereportComponent } from './components/requisition/reports/invoicereport/invoicereport.component';
import { BudgetreportComponent } from './components/budget/budgetreport/budgetreport.component';
import { ConsumerTokenReportMainComponent } from './components/consumerToken/consumerTokenReport/consumer-token-report-main/consumer-token-report-main.component';
import { DialogCoordinatorPaymentComponent } from './components/controlComissions/dialogCoordinadorPayment/dialog-coordinator-payment/dialog-coordinator-payment.component';
import { DialogBudgetRegisterComponent } from './components/controlComissions/dialogBudgetRegister/dialog-budget-register/dialog-budget-register.component';
import { DialogBudgetUpdateComponent } from './components/controlComissions/dialogBudgetUpdate/dialog-budget-update/dialog-budget-update.component';
import { ReportStatusCustomerComponent } from './components/controlComissions/ReportStatusCustomer/report-status-customer/report-status-customer.component';
import { DialogEntryProjectSelectedComponent } from './components/controlComissions/DialogEntryProjectSelected/dialog-entry-project-selected/dialog-entry-project-selected.component';

import { RecessionsConsultComponent } from './components/recessions/recessions-consult/recessions-consult.component';
import { MakeRecessionsComponent } from './components/recessions/make-recessions/make-recessions.component';
import { FilterDeparturePipe } from './pipes/filter-departure.pipe';
import { ProfileComponent } from './components/user/profile/profile.component';
import { InfbudgetpieceComponent } from './components/budget_piecework/budgetconsultpiece/infbudgetpiece/infbudgetpiece.component';
import { BudgetupdatepieceComponent } from './components/budget_piecework/budgetconsultpiece/budgetupdatepiece/budgetupdatepiece.component';
import { BudgetreportPieceComponent } from './components/budget_piecework/budgetreport-piece/budgetreport-piece.component';
import { CustomerStatusComponent } from './components/administration/customer-status/customer-status.component';
import { CashCutComponent } from './components/controlComissions/cashCut/cash-cut/cash-cut.component';
import { GeneralViewComponent } from './components/generalReport/general-view/general-view.component';
import { CreditPaymentsComponent } from './components/search/report/credit-payments/credit-payments.component';
import { BlockSaleDialogComponent } from './components/graficos/block-sale-dialog/block-sale-dialog.component';
import { RecessionsRequestComponent } from './components/administration/recessions-request/recessions-request.component';
import { GenerateRecessionRequestComponent } from './components/administration/recessions-request/generate-recession-request/generate-recession-request.component';
import { RecessionsStatusComponent } from './components/recessions/recessions-status/recessions-status.component';
import { EditInvoiceMethodComponent } from './components/invoice/status-payments/edit-invoice-method/edit-invoice-method.component';
import { UiModule } from './ui/ui.module';
import { RecessionFileComponent } from './components/recessions/recession-file/recession-file.component';
import { DialogViewBlockedComponent } from './components/generalReport/dialogViewBlocked/dialog-view-blocked/dialog-view-blocked.component';
import { FlowReportV2Component } from './components/flowReportV2/flow-report-v2/flow-report-v2.component';
import { InvoiceV2Module } from './components/invoice-v2/invoice-v2.module';
import { LeadsControlComponent } from './components/leads-control/leads-control.component';
import { PrincipalComponent } from './components/leads/principal/principal.component';
import { LeadsAssignedComponent } from './components/leads/leadsAssigned/leads-assigned/leads-assigned.component';
import { GenerateCommentComponent } from './components/leads/generate-comment/generate-comment.component';
import { LeadTimeLineComponent } from './components/leads/lead-time-line/lead-time-line.component';
import { AllRegisteredComponent } from './components/leads/generalControl/all-registered/all-registered.component';
import { TicketOptionsComponent } from './components/controlComissions/DialogTicketOptions/ticket-options/ticket-options.component';
import { MailVerifierComponent } from './components/mailVerifier/mail-verifier/mail-verifier.component';
import { Walletv2Component } from './components/walletV2/walletv2/walletv2.component';
import { PieceworkeditComponent } from './components/piecework/pieceworkedit/pieceworkedit.component';
import { PieceworkapproveComponent } from './components/piecework/pieceworkapprove/pieceworkapprove.component';
import { CashCutAndCashFlowComponent } from './components/cashCutAndCashFlow/cash-cut-and-cash-flow/cash-cut-and-cash-flow.component';
import { CLogbookRegisterComponent } from './components/c_logbook/c-logbook-register/c-logbook-register.component';
import { LeadExtensionComponent } from './components/leads/lead-extension/lead-extension.component';
import { LeadsReassignComponent } from './components/leads/leads-reassign/leads-reassign.component';
import { PaymentCommissionsComponent } from './components/paymentCommissions/payment-commissions/payment-commissions.component';
import { FilterRequisitionsPipe } from './pipes/filter-requisitions.pipe';
import { CLogbookReportComponent } from './components/c_logbook/c-logbook-report/c-logbook-report.component';
import { UnmadeInvoiceComponent } from './components/accounting/invoices/unmade-invoice/unmade-invoice.component';
import { PaymentHistoryComponent } from './components/payments/payment-history/payment-history.component';
import { FilterInvoicesPipe } from './pipes/filter-invoices.pipe';
import { QuotationFormComponent } from './components/sale/quotation-form/quotation-form.component';
import { InvoiceStatusComponent } from './components/accounting/invoices/invoice-status/invoice-status.component';
import { AccountBalanceComponent } from './components/commons/account-balance/account-balance.component';
import { TransferValidateComponent } from './components/accounting/transfer-validate/transfer-validate.component';
import { DevolutionsComponent } from './components/administration/devolutions/devolutions.component';
import { NegotiationComponent } from './components/administration/devolutions/negotiation/negotiation.component';
import { PaybackSchedulingComponent } from './components/administration/devolutions/payback-scheduling/payback-scheduling.component';
import { PaybackRegisterComponent } from './components/administration/devolutions/payback-register/payback-register.component';
import { FilterUsersPipe } from './pipes/filter-users.pipe';
import { CommentsViewComponent } from './components/administration/devolutions/comments-view/comments-view.component';
import { NegotiationDetailComponent } from './components/administration/devolutions/negotiation-detail/negotiation-detail.component';
import { RescissionAgreementComponent } from './components/administration/devolutions/rescission-agreement/rescission-agreement.component';
import { ScheduleViewComponent } from './components/administration/devolutions/payback-scheduling/schedule-view/schedule-view.component';
import { FilterNegotiationsPipe } from './pipes/filter-negotiations.pipe';

@NgModule({
  declarations: [
    //COMPONENTS
    AppComponent,
    LoginComponent,
    HomeHeaderComponent,
    HomePageComponent,
    SidebarContentComponent,
    ProjectComponent,
    UserComponent,
    CustomerComponent,
    ReportComponent,
    SettingComponent,
    PaymentsComponent,
    GraficosComponent,
    SaleComponent,
    DialogSaleConfirmComponent,
    AccountstatusComponent,
    CancelledSearchComponent,
    ConsultaFacturaComponent,
    EntryComponent,
    FacturaGlobalComponent,
    FacturasComponent,
    FlowReportComponent,
    SearchComponent,
    WalletComponent,
    DialogCustomerComponent,
    DialogUserComponent,
    DialogSaleComponent,
    DialogRecessionsComponent,
    DetailRecessionsComponent,
    EmployeeComponent,
    CoordinatorPaymentComponent,
    CoordinatorStatusComponent,
    WalletByWeekComponent,
    AccountstatuscoordadminComponent,
    WalletByWeekComponent,
    RequestComponent,
    SweetAlertComponent,
    PdfMakerComponent,
    PostalCodeGeneratorComponent,
    RecessionComponent,
    PaymentMethodComponent,
    TypeUserComponent,
    DialogUserFormComponent,
    GlobalInvoiceComponent,
    InvoiceComponent,
    CommissionComponent,
    MapsGenerateComponent,
    MapsEditComponent,
    MapsConsultComponent,
    DialogMapsSelectComponent,
    DialogMapsNotRegisterComponent,
    UpdateMapToFirebaseComponent,
    PermissionComponent,
    DialogWalletComponent,
    DialogNotesComponent,
    FilterCustomerPipe,
    FilterEmployeePipe,
    FilterPropertyPipe,
    FilterRecessionsPipe,
    DialogCustomerFormComponent,
    DialogPropertyComponent,
    DialogPropertyFormComponent,
    DialogRegisteruserComponent,
    DialoGenerateSalesComponent,
    DialogSelectedTypeUserComponent,
    DialogSelecUserComponent,
    FilterCustomerPipe,
    SketchMapsComponent,
    DialogSketchmapVisualComponent,
    ProyectsDetailsComponent,
    DialogDigitalCustomerComponent,
    RequisitionregisterComponent,
    RequisitionconsultComponent,
    DialogRequisitionComponent,
    RequisitioncontrolComponent,
    DialogDeliveryComponent,
    RequestDOCComponent,
    PieceworkconsultComponent,
    PieceworkcontrolComponent,
    PieceworkregisterComponent,
    DialogPieceworksComponent,
    DialogInvoiceComponent,
    DialogInsertproductsComponent,
    DialogUpdateRequisitionComponent,
    RequisitionapproveComponent,
    DialogInsertsupplierComponent,
    DepartureUpdateComponent,
    DialogEditDeliveryComponent,
    DeparturecontrolComponent,
    MaterialUpdateComponent,
    SupplierUpdateComponent,
    EditPieceworkComponent,
    ConceptUpdateComponent,
    DialogSaleFormComponent,
    MapsforSalesComponent,
    EditSalesComponent,
    FinishedSaleComponent,
    ViewRequestComponent,
    GeneralRequestComponent,
    DialogRequestViewComponent,
    ViewComissionComponent,
    DialogUpdateViewComponent,
    DialogUpdateRequisitionComponent,
    ControlRequestComponent,
    BudgetregisterComponent,
    BudgetconsultComponent,
    BudgetdialogComponent,
    BudgetupdateconsultComponent,
    PieceworkUpdateComponent,
    BudgetapproveComponent,
    RequisitionapproveComponent,
    DialogInsertsupplierComponent,
    ConsumerTokenRegisterComponent,
    ConsumerTokenConsultMainComponent,
    ConsumerTokenConsultInfoDialogComponent,
    ConsumerTokenModifyMainComponent,
    ConsumerTokenModifyInfoDialogComponent,
    ConsumerTokenModifyEditDialogComponent,
    StatusPaymentsComponent,
    DialogEditInvoiceComponent,
    PieceworkreportComponent,
    DetailOverageComponent,
    GenerateAndCalculateCommisionsComponent,
    InvoicereportComponent,
    BudgetreportComponent,
    ConsumerTokenReportMainComponent,
    DialogCoordinatorPaymentComponent,
    DialogBudgetRegisterComponent,
    DialogBudgetUpdateComponent,
    ReportStatusCustomerComponent,
    DialogEntryProjectSelectedComponent,
    RecessionsConsultComponent,
    MakeRecessionsComponent,
    FilterDeparturePipe,
    ProfileComponent,
    //BudgetregisterpieceworkComponent,
    //BudgetconsultpieceComponent,
    InfbudgetpieceComponent,
    BudgetupdatepieceComponent,
    BudgetreportPieceComponent,
    CustomerStatusComponent,
    CashCutComponent,
    GeneralViewComponent,
    CreditPaymentsComponent,
    BlockSaleDialogComponent,
    RecessionsRequestComponent,
    GenerateRecessionRequestComponent,
    RecessionsStatusComponent,
    EditInvoiceMethodComponent,
    DialogViewBlockedComponent,
    LeadsControlComponent,
    PieceworkeditComponent,
    PieceworkapproveComponent,
    FlowReportV2Component,
    RecessionFileComponent,
    DialogViewBlockedComponent,
    PrincipalComponent,
    LeadsAssignedComponent,
    GenerateCommentComponent,
    LeadTimeLineComponent,
    AllRegisteredComponent,
    TicketOptionsComponent,
    MailVerifierComponent,
    Walletv2Component,
    PieceworkeditComponent,
    CashCutAndCashFlowComponent,
    CLogbookRegisterComponent,
    LeadExtensionComponent,
    LeadsReassignComponent,
    PaymentCommissionsComponent,
    FilterRequisitionsPipe,
    CLogbookReportComponent,
    UnmadeInvoiceComponent,
    PaymentHistoryComponent,
    FilterInvoicesPipe,
    QuotationFormComponent,
    InvoiceStatusComponent,
    AccountBalanceComponent,
    TransferValidateComponent,
    DevolutionsComponent,
    NegotiationComponent,
    PaybackSchedulingComponent,
    PaybackRegisterComponent,
    FilterUsersPipe,
    CommentsViewComponent,
    NegotiationDetailComponent,
    RescissionAgreementComponent,
    ScheduleViewComponent,
    FilterNegotiationsPipe
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    SidebarModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ChartsModule,
    MatListModule,
    ReactiveFormsModule,
    NgbModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    AutocompleteLibModule,
    CommonModule,
    BrowserModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      progressBar: true,
      progressAnimation: 'increasing',
      preventDuplicates: true
    }),

    //MATERIAL
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatDividerModule,
    MatCheckboxModule,
    MatStepperModule,
    MatChipsModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatRadioModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,

    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    UiModule,
    InvoiceV2Module

  ],
  providers: [
    //SERVICES
    UserService,
    PaymentService,
    ProjectService,
    SweetAlertComponent,
    JwtHelperService,
    {provide: MAT_MENU_DEFAULT_OPTIONS, useValue: { overlayPanelClass: 'cdk-overlay-pane' }},
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: JWT_OPTIONS, useValue: JWT_OPTIONS},
    MatDatepickerModule,
    MatNativeDateModule,
    PdfMakerComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
