<!-- Información de partidas y material asignado -->
<mat-dialog-content>
    <div class="printButtons">
        <button class="printButton" (click)="printSheet()">
            <mat-icon>article</mat-icon>Papeleta de destajo
        </button>
        <button class="printButton" (click)="printAttendance()">
            <mat-icon>fact_check</mat-icon>Lista de asistencia
        </button>
    </div>

    <div id="htmlSheet">
        <table class="principalTable">
            <tr>
                <th><img src="../assets/isotipo.png" alt="M19 Imagotipo" width="150px"></th>
                <th colspan="2">PAPELETA DE DESTAJO</th>
                <th><img [src]="cbrLogo" alt="CBR Logo" width="150px"></th>
            </tr>
            <tr>
                <td><b>OBRA:</b></td>
                <td>{{pieceInfo.name}}</td>
                <td><b>MODULO:</b></td>
                <td>{{pieceInfo.towernumber}}</td>
            </tr>
            <tr>
                <td><b>SEMANA:</b></td>
                <td>#{{pieceInfo.week}}</td>
                <td colspan="2"><b>DEL: </b>{{pipe.transform(pieceInfo.initweek,
                    'longDate')}}<b> AL: </b>{{pipe.transform(pieceInfo.endweek,
                    'longDate')}}</td>
            </tr>
            <tr>
                <td><b>NOMBRE DEL TRABAJADOR:</b></td>
                <td colspan="3">{{employeesList}}</td>
            </tr>
        </table>

        <table class="contentHeader">
            <tr>
                <td width="61%"><b>CONCEPTO</b></td>
                <td width="13%"><b>CANTIDAD</b></td>
                <td width="13%"><b>P.U.</b></td>
                <td width="13%"><b>IMPORTE</b></td>
            </tr>
        </table>
        <table class="contentTable" *ngFor="let dep of pieceDeparture">
            <tr>
                <td width="61%"><b>PARTIDA NÚMERO {{dep.departurenumber}} {{dep.descriptions}}</b></td>
                <td width="13%"></td>
                <td width="13%"></td>
                <td width="13%"></td>
            </tr>
            <tr *ngFor="let con of pieceConcepts;">
                <td width="61%" *ngIf="con.departurenumber == dep.departurenumber">{{con.descriptions}}</td>
                <td width="13%" *ngIf="con.departurenumber == dep.departurenumber">{{con.amount}}</td>
                <td width="13%" *ngIf="con.departurenumber == dep.departurenumber">{{con.unitcost | currency:'CAD' : '$
                    '}}
                </td>
                <td width="13%" *ngIf="con.departurenumber == dep.departurenumber">{{con.amount * con.unitcost |
                    currency:'CAD' : '$ '}}
                </td>
            </tr>
        </table>
        <table class="contentTable">
            <tr>
                <td width="61%"></td>
                <td width="13%"></td>
                <td width="13%"><b>SUMA</b></td>
                <td width="13%"><b>{{totalConceptos | currency:'CAD' : '$ '}}</b></td>
            </tr>
        </table>
        <table class="employeeTable">
            <tr *ngFor="let emp of pieceEmployee;">
                <td width="61%">{{emp.employeename}}</td>
                <td width="13%">{{emp.employeerange}}</td>
                <td width="13%">{{emp.employeesalary | currency:'CAD' : '$ '}}</td>
                <td width="13%"></td>
            </tr>
            <tr>
                <td width="61%"></td>
                <td width="13%"><b>SUMA</b></td>
                <td width="13%"><b>{{totalEmpleados | currency:'CAD' : '$ '}}</b></td>
                <td width="13%"></td>
            </tr>
        </table>
        <table class="signsTable">
            <tr>
                <td>AUTORIZA</td>
                <td>REVISÓ</td>
                <td>ELABORÓ</td>
            </tr>
        </table>
    </div>

    <div id="htmlAttendance" [hidden]="true">
        <table class="attendanceHeader">
            <tr>
                <td rowspan="2"><img src="../assets/isotipo.png" width="150px"></td>
                <td colspan="8"><b>LISTA DE ASISTENCIA DEL PERSONAL</b></td>
                <td rowspan="2"><img [src]="cbrLogo" width="150px"></td>
            </tr>
            <tr>
                <td><b>OBRA:</b><br> {{pieceInfo.name}} <br> {{pieceInfo.towernumber}}</td>
                <td><b>FECHA:</b><br> {{pipe.transform(fechaHoy, 'longDate')}}</td>
                <td><b>SEMANA:</b><br> #{{pieceInfo.week}}</td>
                <td><b>PERIODO:</b><br> {{pipe.transform(pieceInfo.initweek,
                    'longDate')}} <br> al <br> {{pipe.transform(pieceInfo.endweek,
                    'longDate')}}</td>
            </tr>
        </table>
        <table class="attendanceContent">
            <tr>
                <td><b>NOMBRE</b></td>
                <td width="5%"><b>CAT.</b></td>
                <td colspan="8"><b>ASISTENCIA</b></td>
                <td width="5%"><b>SUBTOTAL</b></td>
                <td width="5%"><b>INFONAVIT</b></td>
                <td width="15%"><b>TOTAL</b></td>
                <td width="25%"><b>OBSERVACIONES</b></td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td><b>L</b></td>
                <td><b>M</b></td>
                <td><b>M</b></td>
                <td><b>J</b></td>
                <td><b>V</b></td>
                <td><b>S</b></td>
                <td><b>D</b></td>
                <td><b>ASIST.</b></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr *ngFor="let emp of pieceEmployee; index as i;">
                <td>{{emp.employeename}}</td>
                <td>{{emp.employeerange}}</td>
                <td>{{emp.attendanceLD.split("-")[0]==1?"✔":"✘"}}</td>
                <td>{{emp.attendanceLD.split("-")[1]==1?"✔":"✘"}}</td>
                <td>{{emp.attendanceLD.split("-")[2]==1?"✔":"✘"}}</td>
                <td>{{emp.attendanceLD.split("-")[3]==1?"✔":"✘"}}</td>
                <td>{{emp.attendanceLD.split("-")[4]==1?"✔":"✘"}}</td>
                <td>{{emp.attendanceLD.split("-")[5]==1?"✔":"✘"}}</td>
                <td>{{emp.attendanceLD.split("-")[6]==1?"✔":"✘"}}</td>
                <td>{{getDaysWorked(i)}}</td>
                <td></td>
                <td></td>
                <td>{{emp.employeesalary | currency:'CAD' : '$ '}}</td>
                <td>{{emp.observations}}</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><b>TOTAL</b></td>
                <td></td>
                <td></td>
                <td>{{totalEmpleados | currency:'CAD' : '$ '}}</td>
                <td></td>
            </tr>
        </table>
    </div>
</mat-dialog-content>

<!-- Botón para cerrar el dialog -->
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>