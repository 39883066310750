//=========================================================================================================
//Importación de componentes necesarios para esta pantalla
//=========================================================================================================
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//=========================================================================================================
//Importación de los servicios necesarios para esta pantalla
//=========================================================================================================
import { RequisitionService } from 'src/app/services/requisition.service';
import { LogosService } from 'src/app/services/logos.service';
import Swal from 'sweetalert2';
import { loader } from 'src/app/ui/loader/loader.model';

@Component({
  selector: 'app-dialog-requisition',
  templateUrl: './dialog-requisition.component.html',
  styleUrls: ['./dialog-requisition.component.scss']
})
export class DialogRequisitionComponent implements OnInit {
  loader: loader = {
    message: "Cargando información..."
  }

  loading = 1;

  //Arreglos para almacenar los datos de visualización de la interfaz
  departures: any = [];
  products: any = [];

  //Arreglo para almacenar la información de la requisición para imprimir
  printReq: any = [{ "projectname": "", "towernumber": "", "requisitionnumber": "", "requisitiondate": "", "responsable": "", "auth": "", "departurenumber": "", "departurename": "", "productname": "", "amount": 0, "unit": "", "notes": "", }];

  logo: any = [{ "UrlLogo": "" }];
  cbrLogo: any = "https://firebasestorage.googleapis.com/v0/b/m19inmobiliaria-f6068.appspot.com/o/logos%2FConstructora%2FASESORIA%2C%20SUPERVISI%C3%93N%20Y%20CONTRUCCI%C3%93N.png?alt=media&token=356f1765-4f7e-48af-9fb9-bc6584b0b09a";

  constructor(
    private dialogRef: MatDialogRef<DialogRequisitionComponent>,
    //=======================================================================================================
    //Declaración de los servicios utilizados
    public requisitionService: RequisitionService,
    public logosService: LogosService,

    @Inject(MAT_DIALOG_DATA) public data: number
  ) {
    this.getDeparturesFromRequisition();
  }

  ngOnInit(): void { }

  //=========================================================================================================
  //Metodo para obtener las partidas de la requisicion
  //=========================================================================================================
  async getDeparturesFromRequisition() {
    this.requisitionService.getDeparturesFromRequisition(this.data).subscribe(
      res => {
        //Se recupera el valor retornado por la consulta que esta función ejecuta que contiene las partidas de la requisición
        this.departures = res;

        this.loading = 0;

        this.getLogos(this.departures[0].idProject);
      }
    );
  }

  //=========================================================================================================
  //Metodo para obtener los productos asignados con al menos una partida
  //=========================================================================================================
  async getProductsFromDeparture(idr: number, idd: number, idc: number) {
    this.products = [];
    this.loading = 1;
    this.requisitionService.getProductsFromDeparture(idr, idd, idc).subscribe(
      res => {
        //El resultado de la consulta se asigna a la variable products
        this.products = res;
        this.loading = 0;
      },
      err => console.log(err)
    )
  }

  //=========================================================================================================
  //Metodo para obtener el logo del proyecto al que pertenece la requisicion
  //=========================================================================================================
  async getLogos(id: number) {
    this.logosService.selectLogo(id).subscribe(
      res => {
        this.logo = res;
      }
    );
  }

  //=========================================================================================================
  //Metodo para imprimir la requisicion
  //=========================================================================================================
  getRequisitionToPrint(idreq: number): void {
    this.requisitionService.getRequisitionsToPrint(idreq).subscribe(
      res => {
        this.printReq = res;
        this.printRequisition();
      }
    );
  }

  printRequisition(): void {
    Swal.fire({
      title: 'Ingresa un nombre para el archivo',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Generar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        let printContents, popupWin;
        printContents = document.getElementById('htmlData')!.innerHTML;
        popupWin = window.open('', '', '');
        popupWin!.document.open();
        popupWin!.document.write(`
  <html>
    <head>
      <title>${result.value}</title>
      <style>
      * {
        margin: 0;
        padding: 0;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
      }
      body {
        font-family: Roboto, "Helvetica Neue", sans-serif;
      }
      img {
        border: none;
      }
      
      .requisitionHeader {
          display: flex;
          flex-direction: column;
          width: 100%;
          border: solid #d33e3e 0 5px 5px 0;
      }
      
      .titles {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          border: solid #d33e3e 5px;
          border-radius: 30px 30px 0 0;
      }
      
      .main {
          display: flex;
          flex-direction: column;
          width: 80%;
      }
      
      .main .header {
          margin: 0;
          padding: 0;
          background-color: #d33e3e;
          color: white;
          border-radius: 30px 0 30px 0;
          font-weight: bold;
          text-align: center;
      }
      
      .logos {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
          padding: 10px 0 10px 0;
      }
      
      .requisitionInfo {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          background-color: #d33e3e;
          color: white;
          font-size: 0.8rem;
      }
      
      .projectData {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 20%;
      }
      
      .projectData p {
          margin: 0;
          padding: 0;
          text-align: center;
      }

      .projectName {
        background-color: #d33e3e;
        color: white;
        border-radius: 0 30px 0 0;
        font-weight: bold;
      }
      
      table {
          width: 100%;
          border-collapse: collapse;
      }
      
      thead {
          background-color: #5E4F4F;
          border: solid #5E4F4F 5px;
          color: white;
          font-weight: bold;
          font-size: 1rem;
          text-align: center;
      }
      
      tbody {
          font-weight: bold;
          text-align: center;
          font-size: 0.8rem;
          border: solid #5E4F4F 5px;
      }
      
      tbody tr:nth-child(even) {
          background-color: #ebebeb;
      }
      
      td {
          padding: 10px;
      }
      
      .firmas {
          display: flex;
          justify-content: space-between;
          text-align: center;
          margin-top: 50px;
      }
      </style>
    </head>
<body onload="window.print();window.close()">${printContents}</body>
  </html>`
        );
        popupWin!.document.close();
      }
    })
  }
}