<div class="balanceCont">
    <div class="balanceCont__gralInfo">
        <span class="balanceCont__gralInfo__searchBar">
            <div class="ng-autocomplete">
                <ng-autocomplete name="busqueda" placeholder="Inmueble/Nombre cliente" [data]="sal"
                    [searchKeyword]="keyword" (selected)="selectCustomer($event)" [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate">
                </ng-autocomplete>
                <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.name"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                    <div class="notFound">
                        <span>No se encontraron coincidencias</span>
                        <mat-icon>sentiment_dissatisfied</mat-icon>
                    </div>
                </ng-template>
            </div>
        </span>

        <span class="balanceCont__gralInfo__saleData">
            <div class="balanceCont__gralInfo__saleData__propData">
                <div class="proyImage">
                    <img src="https://firebasestorage.googleapis.com/v0/b/m19inmobiliaria-f6068.appspot.com/o/logos%2FXalta%2FXalta.png?alt=media&token=1bfef708-e62e-44fc-942f-8ae094f76c99"
                        alt="Logo del Proyecto">
                </div>
                <div class="custInfo">
                    <span><b>Xalta | XLT10D01</b></span>
                    <span><b>Diego Asael Rubio Hernandez</b></span>
                    <span>
                        <p><b>3111234567</b></p>
                        <p><b>diego.hernandez@m19.com</b></p>
                    </span>
                </div>
            </div>

            <hr>

            <div class="balanceCont__gralInfo__saleData__saleDetail">
                <span class="employeeData">
                    <div class="employeeCard">
                        <div class="empImage">
                            <img src="https://firebasestorage.googleapis.com/v0/b/m19inmobiliaria-f6068.appspot.com/o/fotos%2FHenoc%20Alaniz%20Viera.png?alt=media&token=0ea20355-5a00-4731-bf3a-d097b54b93b3"
                                alt="Imagen de Empleado">
                        </div>
                        <div class="empInfo">
                            <span><b>Coordinador</b></span>
                            <span>Henoc Alaniz Viera</span>
                        </div>
                    </div>

                    <div class="employeeCard">
                        <div class="empImage">
                            <img src="https://firebasestorage.googleapis.com/v0/b/m19inmobiliaria-f6068.appspot.com/o/fotos%2FAlex%20Saul%20Rodriguez%20Beltran.png?alt=media&token=08c850c6-53a7-4c34-8532-235491bc6a31"
                                alt="Imagen de Empleado">
                        </div>
                        <div class="empInfo">
                            <span><b>Vendedor</b></span>
                            <span>Alex Saul Rodriguez Beltran</span>
                        </div>
                    </div>
                </span>


                <span class="datesData">
                    <div class="dateCard">
                        <span><b>Fecha de Contrato</b></span>
                        <span>17 de febrero del 2020</span>
                    </div>

                    <div class="dateCard">
                        <span><b>Fecha de Primer Pago</b></span>
                        <span>17 de marzo del 2020</span>
                    </div>
                </span>
            </div>
        </span>

        <span class="balanceCont__gralInfo__finanData">
            <h6><b>Financiamiento</b></h6>
            <div class="balanceCont__gralInfo__finanData__dataCont">
                <div class="graphicCont">
                    <canvas id="comparativeChart"></canvas>
                </div>
                <table class="financialDetail">
                    <tbody>
                        <tr>
                            <td><b>Pagado</b></td>
                            <td><b>Anticipo</b></td>
                        </tr>
                        <tr>
                            <td>$ 190,000</td>
                            <td>$ 75,000</td>
                        </tr>
                        <tr>
                            <td><b>Restante</b></td>
                            <td><b>Mensualidad</b></td>
                        </tr>
                        <tr>
                            <td>$ 100,000</td>
                            <td>$ 2,000</td>
                        </tr>
                        <tr>
                            <td><b>Tiempo Restante</b></td>
                            <td><b>Anualidad</b></td>
                        </tr>
                        <tr>
                            <td>7 Años y 11 Meses</td>
                            <td>$ 5,000</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </span>

        <span class="balanceCont__gralInfo__actions">
            <h6><b>Acciones</b></h6>
            <div class="actionsBelt">
                <button class="m19Btn">
                    <mat-icon>print</mat-icon>
                    <span>Imprimir</span>
                </button>
                <button class="m19Btn">
                    <mat-icon>cancel</mat-icon>
                    <span>Cancelar Pago</span>
                </button>
                <button class="m19Btn">
                    <mat-icon>note</mat-icon>
                    <span>Dejar Nota</span>
                </button>
            </div>
        </span>
    </div>

    <span class="balanceCont__payInfo">
        <div class="balanceCont__payInfo__monthScheme">
            <div class="balanceCont__payInfo__monthScheme__paidResume">
                <span class="moneyCard">
                    <div class="icon">
                        <mat-icon>attach_money</mat-icon>
                    </div>
                    <div class="detail">
                        <span><b>Abonado</b></span>
                        <span>$ 110,000</span>
                    </div>
                </span>
                <span class="moneyCard">
                    <div class="icon">
                        <mat-icon>library_books</mat-icon>
                    </div>
                    <div class="detail">
                        <span><b>Notas de Crédito</b></span>
                        <span>$ 80,000</span>
                    </div>
                </span>
                <span class="moneyCard">
                    <div class="icon">
                        <mat-icon>support_agent</mat-icon>
                    </div>
                    <div class="detail">
                        <span><b>Gastos de Cobranza</b></span>
                        <span>$ 800</span>
                    </div>
                </span>
            </div>

            <div class="balanceCont__payInfo__monthScheme__balance">
                <table>
                    <tbody>
                        <tr>
                            <td></td>
                            <td colspan="6">Meses</td>
                        </tr>
                        <tr>
                            <td>
                                Valor Total
                                <br>
                                $ 290,000
                            </td>
                            <td>
                                Totales
                                <br>
                                90
                            </td>
                            <td>
                                Anualidades
                                <br>
                                3
                            </td>
                            <td>
                                Transcurridos
                                <br>
                                11
                            </td>
                            <td>
                                Pagados
                                <br>
                                9
                            </td>
                            <td>
                                Balance
                                <br>
                                -2
                            </td>
                            <td>
                                Limite
                                <br>
                                -4
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="balanceCont__payInfo__payHist">
            <h6><b>Historial de Pagos</b></h6>
            <span class="balanceCont__payInfo__payHist__payList">
                <div class="paymentCard">
                    <span class="paymentIcon">
                        <mat-icon>payments</mat-icon>
                    </span>
                    <div class="paymentDetail">
                        <span class="detRow">
                            <b class="concept">Anticipo</b>
                            <div class="options">
                                <mat-icon class="actionAltBtn">receipt</mat-icon>
                                <mat-icon class="actionAltBtn">delete</mat-icon>
                                <mat-icon>adjust</mat-icon>
                            </div>
                        </span>
                        <span class="detRow">
                            <b>Efectivo</b>
                            <b style="color: var(--primaryGreen);">+ $ 3,000.00</b>
                        </span>
                        <span class="detRow">
                            29 jun. 2020
                            <b style="color: gray;">Folio: e2345</b>
                        </span>
                    </div>
                </div>

                <h4 style="font-size: 0.8rem; color: gray; text-align: center;">Parece que llegamos al final</h4>
            </span>
        </div>
    </span>
</div>