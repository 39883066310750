<!-- Información de partidas y material asignado -->
<mat-dialog-content>
    <!-- ngFor para recorrer todas las partidas conseguidas -->
    <h2 style="text-align: center;">Detalle de exceso de destajo</h2>
    <mat-accordion>
        <mat-expansion-panel *ngFor="let piece of info" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <b class="tituloExpansion">{{"Proyecto: "+piece.name}} - {{"Modulo: "+piece.towernumber}}</b>
                </mat-panel-title>
                <mat-panel-description><b class="tituloExpansion">
                        Semana: #{{piece.week}}
                    </b></mat-panel-description>
            </mat-expansion-panel-header>
            <h5><b>Destajos</b></h5>
            <hr>
            <div class="detailsContent">
                <div class="infoGral">
                    <p><b>Nombre de Partida:</b> {{piece.descriptions}}</p>
                    <p><b>Fecha del registro:</b> {{piece.creationdate | date: 'dd-MMM-yyyy'}}</p>
                </div>
                <div class="infoBasic">
                    <p><b>Destajo:</b> {{piece.typepw}}</p>
                    <p><b>Cantidad:</b> {{piece.amount}}</p>
                    <p><b>Precio unitario:</b> ${{piece.unitcost}}</p>
                    <p><b>Costo esperado:</b> ${{piece.amount * piece.unitcost}}</p>
                </div>
                <div class="infoCost">
                    <p><b>Pagado:</b> ${{piece.total}}</p>
                    <p><b>Diferencia:</b> ${{piece.total-(piece.amount * piece.unitcost)}}</p>
                    <p><b>Razón del pago extra:</b> {{piece.reason}}</p>
                    <p><b>Empleado:</b> {{piece.employee}}</p>
                </div>
            </div>
            <hr>
        </mat-expansion-panel>
    </mat-accordion>
</mat-dialog-content>

<!-- Botón para cerrar el dialog -->
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>