import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


//=========================================================================================================
//Importación de los servicios necesarios para esta pantalla
//=========================================================================================================
import { RequisitionService } from 'src/app/services/requisition.service';
import { DepaproService } from 'src/app/services/depapro.service';
import { BudgetService } from '../../../../services/budget.service';
import { BudgetPieceworkService } from 'src/app/services/budget-piecework.service';


@Component({
  selector: 'app-infbudgetpiece',
  templateUrl: './infbudgetpiece.component.html',
  styleUrls: ['./infbudgetpiece.component.scss']
})
export class InfbudgetpieceComponent implements OnInit {
 //Objeto para almacenar partidas
 departures: any = [];

 //Objeto para almacenar destajos
 pieceworks: any = [];

 //Variable para almacenar la fecha del presupuesto
 date: any;

 //Variable para almacenar el módulo del presupuesto
 module: any;

 //Variable para guardar el número de presupuesto consultado
 budgetNum: any;

 //Variables para guardar la información del proyecto

 //Variable para el nombre del proyecto
 projectName: any;

 //Variable para almacenar el proyecto
 project: any;

 //Variable para almacenar el total del presupuesto
 totalbudget: any;

  constructor(private dialogRef: MatDialogRef<InfbudgetpieceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    //=======================================================================================================
    //Declaración de los servicios utilizados
    //=======================================================================================================
    public depaprodService: DepaproService,
    public requisitionService: RequisitionService,
    public budgetService: BudgetService,
    public budgetpieceworkService: BudgetPieceworkService,
  ) { }
  ngOnInit(): void {
     //Obtener la lista de la relación de partidas con conceptos
     this.selectListDepaCon(this.data);
     //Obtener información de los productos
     this.selectProducts();
     //Obtener información del presupuesto
     this.getBudgetInfo();
   }
 
   //=======================================================================================================
   //Obtener información especifica del presupuesto para plasmarlo en un principio
   //=======================================================================================================
   async getBudgetInfo() {
     this.budgetpieceworkService.getBudgetInfo(this.data).subscribe(
       res => {
         this.date = res.budgetdate;
         this.project = res.name;
         this.budgetNum = res.idbudgetpiece;
         this.module = res.towernumber;
         this.totalbudget = res.total;
       }
     );
   }
   
 //=======================================================================================================
 //Obtener la lista de los conceptos relacionados con las partidas
 //=======================================================================================================
   async selectListDepaCon(id: number) {
     this.budgetpieceworkService.getListDepaPiece(id).subscribe(
       res => {
         this.departures = res;
       }
     );
   }
 
   //=========================================================================================================
   //Metodo para obtener los destajos asignados con al menos una partida
   //=========================================================================================================
   async selectProducts() {
     this.budgetpieceworkService.getPieceInBudget(this.data).subscribe(
       res => {
         //El resultado de la consulta se asigna a la variable pieceworks
         this.pieceworks = res;
         console.log("Estos son los destajos perrones"+this.pieceworks)
       },
       err => console.log(err)
     )
   }
 }
