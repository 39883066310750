<mat-card class="example-card">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      <mat-card-title>{{data.idinmueble}}</mat-card-title>
      <mat-card-subtitle>{{data.proyecto}}</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image src="{{link.sketchMap}}" >
    <mat-card-content>
      <p>
          Favor de confirmar que el croquis de la ubicacion mostrada corresponda a el inmueble {{data.idinmueble}}
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button>Cerrar</button>
    </mat-card-actions>
  </mat-card>

