<body>
    <div class="tabAndForm">
        <form [formGroup]="consumerTokenFormGroup" (ngSubmit)="insert()"
            onKeyPress="if(event.keyCode == 13) event.returnValue = false;" class="formulario">
            <h5 style="text-align: left; width: 100%;">Información sobre el vale de consumo</h5>
            <hr size="8px" noshade="noshade" color="#d33e3e" style="width: 100%; margin-top: 0%;">
            <div style="display: flex; flex-direction: row; width: 100%; gap: 10px; margin-bottom: 0%;">
                <mat-form-field appearance="outline" style="width: 50%;">
                    <mat-label>Proyecto <mat-icon>business</mat-icon></mat-label>
                    <mat-select [(value)]="selectProjects" formControlName="idProject"
                        (valueChange)="selectProject($event)" required>
                        <mat-option *ngFor="let project of projects" [value]="project.idProject">
                            {{project.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 50%;">
                    <mat-label>Modulo <mat-icon>location_city</mat-icon></mat-label>
                    <mat-select formControlName="fk_idprojectDet" required>
                        <mat-option *ngFor="let projectDet of projectDetailByID" [value]="projectDet.idprojectdet">
                            Modulo {{projectDet.towernumber}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div style="display: flex; width: 100%; gap: 10px; margin-top: -15px;">
                <mat-form-field appearance="outline" style="width: 50%;">
                    <mat-label>Fecha</mat-label>
                    <input matInput type="date" formControlName="tokenDate" required>
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 50%;">
                    <mat-label>Folio</mat-label>
                    <input matInput type="number" formControlName="folio" minlength="2" maxlength="6">
                </mat-form-field>
            </div>

            <mat-form-field appearance="outline" style="width: 100%; margin-top: -15px;">
                <mat-label>Área que requiere</mat-label>
                <input matInput type="text" formControlName="requiredArea" required>
            </mat-form-field>

            <h5 style="text-align: left; width: 100%;">Ingrese material</h5>
            <hr size="8px" noshade="noshade" color="#d33e3e" style="width: 100%; margin-top: 0%;">
            <div class="ng-autocomplete">
                <ng-autocomplete formControlName="idproduct" placeholder="Seleccione material"
                    (selected)='materialSeleccionado($event)' [data]="products" [searchKeyword]="productname"
                    [itemTemplate]="productTemplate" [notFoundTemplate]="notFoundTemplateP">
                </ng-autocomplete>
                <ng-template #productTemplate let-item>
                    <a [innerHTML]=item.productname></a>
                </ng-template>
                <ng-template #notFoundTemplateP let-notFound>
                    <div>No hay coincidencias de vales</div>
                </ng-template>
            </div>

            <div style="display: flex; width: 100%; gap: 10px; margin-top: 2%;">
                <mat-form-field appearance="outline" style="width: 50%;">
                    <mat-label>Cantidad</mat-label>
                    <input matInput type="number" formControlName="amount" min="1">
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 50%;">
                    <mat-label>Unidad</mat-label>
                    <input matInput type="text" formControlName="unit" readonly>
                </mat-form-field>
            </div>

            <!----------------------------------------------------------------->

            <div (click)="addMaterial()" class="addMaterial">
                <p class="boton"> Cargar material
                    <mat-icon style="color:white;">arrow_right_alt</mat-icon>
                </p>
            </div>

            <button class="addMaterial" style="margin-top: 10px; width: 100%;" type="submit"
                [disabled]="this.flagSubmit">
                <p class="boton">Guardar vale de consumo <mat-icon>save</mat-icon>
                </p>
            </button>
        </form>

        <div class="tabla">
            <h5 style="text-align: left; width: 100%;">Material seleccionado para el vale de consumo
            </h5>
            <hr size="8px" noshade="noshade" color="#d33e3e" style="width: 100%; margin-top: 0%;">
            <div class="onlyTable">
                <table class="table table-striped mt-3 animated fadeIn faster">
                    <thead class="thead-m19">
                        <tr>
                            <th scope="col">Nombre de producto</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Unidad</th>
                            <th scope="col">Eliminar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let mat of materiales; index as i">
                            <td>{{mat.split("~")[2]}}</td>
                            <td>{{mat.split("~")[3]}}</td>
                            <td>{{mat.split("~")[4]}}</td>
                            <td class="button-row">
                                <button class="btn btn-m19-table" mat-icon-button (click)="deleteMaterials(i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</body>