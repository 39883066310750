import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCustomer'
})

export class FilterCustomerPipe implements PipeTransform {

  transform(value: any, arg: any): any {

    const resultPosts = [];

    for(const post of value){

      let nombrecompleto = post.name+" "+post.firstLastName+" "+post.secondLastName

    
      if(nombrecompleto.toUpperCase().indexOf(arg.toUpperCase()) > -1){
         resultPosts.push(post);
      };
    };
    return resultPosts;
  }

}
