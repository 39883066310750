import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/User';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  private url = 'http://localhost:3000/api';


  constructor(private http: HttpClient) { }

  //Seleccionar todos los usuarios
  selectUsers() {
    return this.http.get(`${environment.URLServer}/users`);
  }

  getCustomers() {
    return this.http.get(`${environment.URLServer}/users/getCustomers`);
  }

  //Consultar un usuario en específico
  selectUser(id: number) {
    return this.http.get(`${environment.URLServer}/users/${id}`);
  }

  //Registrar usuario
  createUser(user: User) {
    return this.http.post(`${environment.URLServer}/users`, user);
  }

  //Modificar usuario
  updateUser(id: number | string, updateUser: User): Observable<User> {
    return this.http.put(`${environment.URLServer}/users/${id}`, updateUser);
  }

  //Eliminar usuario
  deleteUser(id: number) {
    return this.http.delete(`${environment.URLServer}/users/${id}`);
  }

  loginUser(username: string, password: string): Observable<any> {
    return this.http.post<User>(`${environment.URLServer}/login`, { username, password });
  }

  getEmployeeInfo(id: any): Observable<any> {
    return this.http.get(`${environment.URLServer}/users/getEmployeeInfo/${id}`);
  }

  getUserPicture(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/users/getUserPicture/${id}`);
  }

  getUserInfo(id: any): Observable<any> {
    return this.http.get(`${environment.URLServer}/users/getUserInfo/${id}`);
  }

  getUserFileStatus(user: any): Observable<any> {
    return this.http.get(`${environment.URLServer}/users/getUserFileStatus/${user}`);
  }

  putExpedient(idUser: number, ExpedienteInicial: any): Observable<any> {
    return this.http.put(`${environment.URLServer}/users/putExpedient/${idUser}`, ExpedienteInicial);
  }
}