import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-invoice',
  templateUrl: './global-invoice.component.html',
  styleUrls: ['./global-invoice.component.scss']
})
export class GlobalInvoiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
