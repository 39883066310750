<mat-accordion>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header (click)="carteraGrafico(); carteraTotal(); carteraRoja(); carteraAmarilla(); carteraVerde();">
            <mat-panel-title>
                <span>Gráfico de cartera vencida</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="TarCar">
            <div class="row _card">
                <div class="col-md-4">
                    <mat-card>
                        <mat-card-title>
                            <h3>M19 Inmobiliaria</h3>
                        </mat-card-title>
                        <mat-card-content *ngFor="let carterasG of carteraG">
                            <canvas baseChart width="325" height="465"
                                [data]="[carterasG.rojos,carterasG.amarillos,carterasG.verdes]"
                                [labels]="pieChartLabels" [chartType]="pieChartType" [options]="pieChartOptions"
                                ([plugins])="pieChartPlugins" [colors]="pieChartColors"
                                [legend]="pieChartLegend"></canvas>

                            <table class="table table-striped animated fadeIn faster _reports">
                                <thead>
                                    <tr align="center">
                                        <th scope="col" style="color: rgb(240, 46, 23)">Rescisión</th>
                                        <th scope="col" style="color: rgb(255, 87, 51)">En riesgo</th>
                                        <th scope="col" style="color: rgb(39, 162, 11)">Un solo atraso</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr align="center">
                                        <td *ngFor="let carterasR of carteraR">{{carterasR.rojos}}</td>
                                        <td *ngFor="let carterasA of carteraA">{{carterasA.amarillos}}</td>
                                        <td *ngFor="let carterasV of carteraV">{{carterasV.verdes}}</td>
                                    </tr>
                                    <tr class="mat-footer-row">
                                        <td colspan="2">Total</td>
                                        <td *ngFor="let carterasT of carteraT">{{carterasT.totales}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-card-content>
                    </mat-card>
                </div>

                <div class="col-md-8">
                    <mat-card>
                        <mat-card-content>
                            <table class="table table-striped animated fadeIn faster _reports">
                                <thead class="thead-m19">
                                    <tr align="center">
                                        <th colspan="5">
                                            <h5>Atrasos por desarrollo</h5>
                                        </th>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr align="center">
                                        <th scope="col">Desarrollo</th>
                                        <th scope="col">Atrasos</th>
                                        <th scope="col" style="color: rgb(240, 46, 23)">Rescisión</th>
                                        <th scope="col" style="color: rgb(255, 87, 51)">En riesgo</th>
                                        <th scope="col" style="color: rgb(39, 162, 11)">Un solo atraso</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lisAtrDes of reportDevBack" align="center">
                                        <th scope="row" style="color: brown;">{{lisAtrDes.name}}</th>
                                        <td>{{lisAtrDes.totalAtrasos}}</td>
                                        <td>{{lisAtrDes.rojoDes}}</td>
                                        <td>{{lisAtrDes.amarilloDes}}</td>
                                        <td>{{lisAtrDes.verdeDes}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>

            <label for=""> <b>Seleccione filtro de coordinador: </b> </label>

            <button mat-button [matMenuTriggerFor]="menu" class="btn btn-m19"> Coordinador </button>
            <mat-menu #menu="matMenu"> 
            <button mat-menu-item  (click)="reporteCarteraCoordinador(coordLis.name)" *ngFor="let coordLis of coordListado">{{coordLis.name}}</button>
            </mat-menu>

            <table class="table table-striped animated fadeIn faster">
                <thead class="thead-m19" align="center">
                    <tr>
                        <th scope="col">Inmueble</th>
                        <th scope="col">Cliente</th>
                        <th scope="col">Vendedor</th>
                        <th scope="col" width="12%">Contrato</th>
                        <th scope="col" width="12%">Saldo</th>
                        <th scope="col">Pagos atrasados</th>
                        <th scope="col">Notas</th>
                    </tr>
                </thead>
                <tbody align="center">
                    <tr *ngFor="let carteraLis of carteraListado">
                        <th scope="row" style="color: brown;">
                            <button class="form-select btn btn-dark" (click)="desCar();slectPayments(carteraLis.codeProperty);reporteInmuCartera(carteraLis.codeProperty)">{{carteraLis.codeProperty}}</button>
                        </th>
                        <td>{{carteraLis.clientes}}</td>
                        <td>{{carteraLis.usuarios}}</td>
                        <td>{{carteraLis.fechaDato | date: 'dd-MM-yyyy'}}</td>
                        <td>{{carteraLis.saldo | currency:'CAD' : '$ '}}</td>
                        <td>
                            <div>
                                {{carteraLis.retraso.toFixed(1)}}
                            </div>
                            <div *ngIf="carteraLis.retraso>=0;">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteraLis.retraso < 0 && carteraLis.retraso > tolerancia2" ngElse="warning2">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteraLis.retraso <= tolerancia2 && carteraLis.retraso >tolerancia3" ngElse="warning3">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteraLis.retraso <= tolerancia3 && carteraLis.retraso >limite" ngElse="danger">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteraLis.retraso <=limite">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <button class="btn btn-m19-table" mat-icon-button (click)="selectNotes(carteraLis.codeProperty)">
                                <mat-icon>speaker_notes</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="row _card" *ngIf="TarEst">
            <div class="col-md-4">
                <mat-card>
                    <mat-card-content *ngFor="let venta of carteraInmu">
                        <button class="btn btn-primary" style="width: 100%;" (click)="desEst();">Cartera</button>
                        <p></p>
                        <table>
                            <thead>
                                <tr>
                                    <h5>Desarrollo:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.name}}</h5>
                                </tr>
                            </tbody>

                            <thead>
                                <tr>
                                    <h5>Cliente:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.clientes}}</h5>
                                </tr>
                            </tbody>

                            <!--thead *ngFor="let ventas of salesCoo">
                                <tr>
                                    <h5 *ngIf="ventas.coopropietario!=null">Coopropietario:</h5>
                                </tr>
                            </thead>
                            <tbody *ngFor="let ventas of salesCoo">
                                <tr>
                                    <h5 class="_h5" *ngIf="ventas.coopropietario!=null">{{ventas.coopropietario}}</h5>
                                </tr>
                            </tbody-->

                            <thead>
                                <tr>
                                    <h5>Coordinador de ventas:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.usuarios}}</h5>
                                </tr>
                            </tbody>

                            <thead *ngIf="venta.vendedores != null">
                                <tr>
                                    <h5>Vendedor:</h5>
                                </tr>
                            </thead>
                            <tbody *ngIf="venta.vendedores != null">
                                <tr>
                                    <h5 class="_h5">{{venta.vendedores}}</h5>
                                </tr>
                            </tbody>

                            <thead>
                                <tr>
                                    <h5>Fecha de contrato:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.fechaDato | date: 'dd-MM-yyyy'}}</h5>
                                </tr>
                            </tbody>

                            <thead>
                                <tr>
                                    <h5 *ngIf="venta.mobile!=null">Celular:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.mobile}}</h5>
                                </tr>
                            </tbody>

                            <thead>
                                <tr>
                                    <h5>Correo electrónico:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.email}}</h5>
                                </tr>
                            </tbody>
                        </table>

                        <table *ngIf="venta.codeProperty.substr(2,1)=='M'">
                            <tr>
                                <th>
                                    <h5>{{M}}</h5>
                                </th>
                                <th>
                                    <h5 class="_h5">{{venta.codeProperty.substr(3,2)}}</h5>
                                </th>
                                <th></th>
                                <th>
                                    <h5>{{L}}</h5>
                                </th>
                                <th>
                                    <h5 class="_h5">{{venta.codeProperty.substr(6,2)}}</h5>
                                </th>
                            </tr>
                        </table>

                        <table *ngIf="venta.codeProperty.substr(2,1)=='T'">
                            <tr>
                                <th>
                                    <h5>{{T}}</h5>
                                </th>
                                <th>
                                    <h5 class="_h5">{{venta.codeProperty.substr(3,2)}}</h5>
                                </th>
                                <th></th>
                                <th>
                                    <h5>{{D}}</h5>
                                </th>
                                <th>
                                    <h5 class="_h5">{{venta.codeProperty.substr(6,2)}}</h5>
                                </th>
                            </tr>
                        </table>

                        <table>
                            <tr>
                                <th>
                                    <h5>Valor total:</h5>
                                </th>
                                <th>
                                    <h5 class="_h5"> {{venta.valor | currency:'CAD' : '$ '}}</h5>
                                </th>
                            </tr>
                        </table>

                        <div class="row mt-2" style="padding-left: .4cm;">
                            <div style="padding-right: 1cm;">
                                <span class="input-group-text" id="entradaPagado" style="background-color: brown; border-radius:.3cm; color: aliceblue; height: 1cm; width: auto;"><b> Abonado</b></span>
                                <div>
                                    <h5> {{venta.abonado | currency:'CAD' : '$ '}} </h5>
                                </div>
                            </div>
                            <div>
                                <span class="input-group-text" id="entradaPagado" style="background-color: brown; border-radius:.3cm; color: aliceblue; height: 1cm; width: auto;"><b>Adeudo</b></span>
                                <div>
                                    <h5> {{venta.valor-venta.abonado | currency:'CAD' : '$ '}} </h5>
                                </div>
                            </div>
                        </div>


                        <table class="table table-hover mt-3">
                            <thead>
                                <tr align="center">
                                    <td>Pagos realizados</td>
                                    <td>Pagos esperados</td>
                                    <td>Estado de pagos</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr align="center">
                                    <td> 
                                        <a>{{venta.mPagadas+'/'+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(1)}}</a>
                                    </td>
                                    <td>
                                        <a>
                                            <div>
                                                {{venta.meses+'/'+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(1)}}
                                            </div>
                                            <div *ngIf="venta.retraso>=0;">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="venta.retraso < 0 && venta.retraso > tolerancia2" ngElse="warning2">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="venta.retraso <= tolerancia2 && venta.retraso >tolerancia3" ngElse="warning3">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="venta.retraso <= tolerancia3 && venta.retraso >limite" ngElse="danger">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="venta.retraso <=limite">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                        </a>
                                    </td>
                                    <td>
                                        {{venta.retraso.toFixed(1)}}
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                        <button class="btn btn-info" (click)="selectPropertyNote(venta.codeProperty)">
                            <b>Nueva nota</b>
                        </button>
                    </mat-card-content>

                </mat-card>
            </div>

            <div class="col-md-8">
                <mat-card>
                    <mat-card-content>
                        <table class="table table-striped animated fadeIn faster">
                            <thead class="thead-m19">
                                <tr>
                                    <th scope="col">No.</th>
                                    <th scope="col">Folio</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Cantidad</th>
                                    <th scope="col">Método de pago</th>
                                    <th scope="col">Concepto</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let payment of payments; let i=index;">
                                    <th>{{i+1}}</th>
                                    <th>{{payment.folioPago}}</th>
                                    <td>{{payment.fechaPago | date: 'dd-MM-yyyy'}}</td>
                                    <td>{{(payment.cantidadAbono | currency:'CAD' : '$ ')}}</td>
                                    <td>{{payment.metodoPago}}</td>
                                    <td>{{payment.concepto}}</td>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <span>Reporte de cartera vencida</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row _card" *ngIf="ListG">
            <table class="table table-wallet table-striped animated fadeIn faster">
                <thead>
                    <tr align="center">
                        <th scope="col">Desarrollo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr align="center" *ngFor="let morita of proyecto">
                        <td>
                            <button class="form-select btn btn-dark" (click)='desX();reporteCartera(morita.name);selectListadoAtrasos(morita.name)'>{{morita.name}}</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="container-fluid mt-3" *ngIf="ListM">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">
                    <h6>Seleccione desarrollo que desea consultar</h6>
                    <mat-button-toggle-group>
                        <mat-button-toggle *ngFor="let morita of proyecto"
                            (click)='inmu();reporteCartera(morita.name);selectListadoAtrasos(morita.name)'>
                            {{morita.name}}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="row _card" *ngIf="VP">
            <div class="col-md-3">
                <mat-card>
                    <mat-card-title *ngFor="let lisAtr of reportListBack">
                        <h3>{{lisAtr.name}}</h3>
                    </mat-card-title>
                    <mat-card-content *ngFor="let lisAtr of reportListBack">
                        <canvas baseChart width="325" height="465"
                            [data]="[lisAtr.rojoDes,lisAtr.amarilloDes,lisAtr.verdeDes]" [labels]="pieChartLabels"
                            [chartType]="pieChartType" [options]="pieChartOptions" ([plugins])="pieChartPlugins"
                            [colors]="pieChartColors" [legend]="pieChartLegend"></canvas>

                        <table class="table table-striped animated fadeIn faster">
                            <thead>
                                <tr align="center">
                                    <th scope="col" style="color: rgb(240, 46, 23)">Rescisión</th>
                                    <th scope="col" style="color: rgb(255, 87, 51)">En riesgo</th>
                                    <th scope="col" style="color: rgb(39, 162, 11)">Un solo atraso</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr align="center" *ngFor="let lisAtr of reportListBack">
                                    <td>{{lisAtr.rojoDes}}</td>
                                    <td>{{lisAtr.amarilloDes}}</td>
                                    <td>{{lisAtr.verdeDes}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-md-9">
                <mat-card>
                    <mat-card-content>
                        <table class="table table-striped animated fadeIn faster">
                            <thead class="thead-m19" align="center">
                                <tr>
                                    <th scope="col">Inmueble</th>
                                    <th scope="col">Cliente</th>
                                    <th scope="col">Vendedor</th>
                                    <th scope="col" width="14%">Contrato</th>
                                    <th scope="col" width="15%">Saldo</th>
                                    <th scope="col">Pagos atrasados</th>
                                    <th scope="col">Notas</th>
                                </tr>
                            </thead>
                            <tbody align="center">
                                <tr *ngFor="let carteras of cartera">
                                    <th scope="row" style="color: brown;">
                                        <button class="form-select btn btn-dark" (click)="desG();slectPayments(carteras.codeProperty);reporteInmuCartera(carteras.codeProperty)">{{carteras.codeProperty}}</button>
                                    </th>
                                    <td>{{carteras.clientes}}</td>
                                    <td>{{carteras.usuarios}}</td>
                                    <td>{{carteras.fechaDato | date: 'dd-MM-yyyy'}}</td>
                                    <td>{{carteras.saldo | currency:'CAD' : '$ '}}</td>
                                    <td>
                                        <div>
                                            {{carteras.retraso.toFixed(1)}}
                                        </div>
                                        <div *ngIf="carteras.retraso>=0;">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 100%"></div>
                                            </div>
                                        </div>
                                        <div *ngIf="carteras.retraso < 0 && carteras.retraso > tolerancia2" ngElse="warning2">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 100%"></div>
                                            </div>
                                        </div>
                                        <div *ngIf="carteras.retraso <= tolerancia2 && carteras.retraso >tolerancia3" ngElse="warning3">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                            </div>
                                        </div>
                                        <div *ngIf="carteras.retraso <= tolerancia3 && carteras.retraso >limite" ngElse="danger">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                            </div>
                                        </div>
                                        <div *ngIf="carteras.retraso <=limite">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <button class="btn btn-m19-table" mat-icon-button (click)="selectNotes(carteras.codeProperty)">
                                            <mat-icon>speaker_notes</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>



        <div class="row _card" *ngIf="X">
            <div class="col-md-4">
                <mat-card>
                    <mat-card-content *ngFor="let venta of carteraInmu">
                        <table style="width: 50%;">
                            <thead>
                                <tr>
                                    <h5>Desarrollo:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.name}}</h5>
                                </tr>
                            </tbody>

                            <thead>
                                <tr>
                                    <h5>Cliente:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.clientes}}</h5>
                                </tr>
                            </tbody>

                            <!--thead *ngFor="let ventas of salesCoo">
                                    <tr>
                                        <h5 *ngIf="ventas.coopropietario!=null">Coopropietario:</h5>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let ventas of salesCoo">
                                    <tr>
                                        <h5 class="_h5" *ngIf="ventas.coopropietario!=null">{{ventas.coopropietario}}</h5>
                                    </tr>
                                </tbody-->

                            <thead>
                                <tr>
                                    <h5>Coordinador de ventas:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.usuarios}}</h5>
                                </tr>
                            </tbody>

                            <thead *ngIf="venta.vendedores != null">
                                <tr>
                                    <h5>Vendedor:</h5>
                                </tr>
                            </thead>
                            <tbody *ngIf="venta.vendedores != null">
                                <tr>
                                    <h5 class="_h5">{{venta.vendedores}}</h5>
                                </tr>
                            </tbody>

                            <thead>
                                <tr>
                                    <h5>Fecha de contrato:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.fechaDato | date: 'dd-MM-yyyy'}}</h5>
                                </tr>
                            </tbody>

                            <thead>
                                <tr>
                                    <h5 *ngIf="venta.mobile!=null">Celular:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.mobile}}</h5>
                                </tr>
                            </tbody>

                            <thead>
                                <tr>
                                    <h5>Correo electrónico:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.email}}</h5>
                                </tr>
                            </tbody>
                        </table>

                        <table *ngIf="venta.codeProperty.substr(2,1)=='M'">
                            <tr>
                                <th>
                                    <h5>{{M}}</h5>
                                </th>
                                <th>
                                    <h5 class="_h5">{{venta.codeProperty.substr(3,2)}}</h5>
                                </th>
                                <th></th>
                                <th>
                                    <h5>{{L}}</h5>
                                </th>
                                <th>
                                    <h5 class="_h5">{{venta.codeProperty.substr(6,2)}}</h5>
                                </th>
                            </tr>
                        </table>

                        <table *ngIf="venta.codeProperty.substr(2,1)=='T'">
                            <tr>
                                <th>
                                    <h5>{{T}}</h5>
                                </th>
                                <th>
                                    <h5 class="_h5">{{venta.codeProperty.substr(3,2)}}</h5>
                                </th>
                                <th></th>
                                <th>
                                    <h5>{{D}}</h5>
                                </th>
                                <th>
                                    <h5 class="_h5">{{venta.codeProperty.substr(6,2)}}</h5>
                                </th>
                            </tr>
                        </table>

                        <table>
                            <tr>
                                <th>
                                    <h5>Valor total:</h5>
                                </th>
                                <th>
                                    <h5 class="_h5"> {{venta.valor | currency:'CAD' : '$ '}}</h5>
                                </th>
                            </tr>
                        </table>

                        <div class="row mt-2" style="padding-left: .4cm;">
                            <div style="padding-right: 1cm;">
                                <span class="input-group-text" id="entradaPagado"
                                    style="background-color: brown; border-radius:.3cm; color: aliceblue; height: 1cm; width: auto;">
                                    <b> Abonado</b>
                                </span>
                                <div>
                                    <h5> {{venta.abonado | currency:'CAD' : '$ '}} </h5>
                                </div>
                            </div>
                            <div>
                                <span class="input-group-text" id="entradaPagado"
                                    style="background-color: brown; border-radius:.3cm; color: aliceblue; height: 1cm; width: auto;"><b>Adeudo</b></span>
                                <div>
                                    <h5> {{venta.valor-venta.abonado | currency:'CAD' : '$ '}} </h5>
                                </div>
                            </div>
                        </div>


                        <table class="table table-hover mt-3">
                            <thead>
                                <tr align="center">
                                    <td>Pagos realizados</td>
                                    <td>Pagos esperados</td>
                                    <td>Estado de pagos</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr align="center">
                                    <td> <a>{{venta.mPagadas+'/'+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(1)}}</a>
                                    </td>

                                    <td>
                                        <a>
                                            <div>
                                                {{venta.meses+'/'+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(1)}}
                                            </div>
                                            <div *ngIf="venta.retraso>=0;">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                        role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="venta.retraso < 0 && venta.retraso > tolerancia2"
                                                ngElse="warning2">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                                        role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="venta.retraso <= tolerancia2 && venta.retraso >tolerancia3"
                                                ngElse="warning3">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                                        role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="venta.retraso <= tolerancia3 && venta.retraso >limite"
                                                ngElse="danger">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                                        role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="venta.retraso <=limite">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger"
                                                        role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                        </a>
                                    </td>
                                    <td>
                                        {{venta.retraso.toFixed(1)}}
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                        <button class="btn btn-info"  (click)="selectPropertyNote(venta.codeProperty)">
                            <b>Nueva nota</b>
                        </button>
                    </mat-card-content>

                </mat-card>
            </div>

            <div class="col-md-8">
                <mat-card>
                    <mat-card-content>
                        <table class="table table-striped animated fadeIn faster">
                            <thead class="thead-m19">
                                <tr>
                                    <th scope="col">No.</th>
                                    <th scope="col">Folio</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Cantidad</th>
                                    <th scope="col">Método de pago</th>
                                    <th scope="col">Concepto</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let payment of payments; let i=index;">
                                    <th>{{i+1}}</th>
                                    <th>{{payment.folioPago}}</th>
                                    <td>{{payment.fechaPago | date: 'dd-MM-yyyy'}}</td>
                                    <td>{{(payment.cantidadAbono | currency:'CAD' : '$ ')}}</td>
                                    <td>{{payment.metodoPago}}</td>
                                    <td>{{payment.concepto}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

    </mat-expansion-panel>
</mat-accordion>