<div class="container-fluid mt-3">
    <mat-card class="card-form">
        <mat-card-title class="col-md-4">
            <ng-autocomplete placeholder="Folio cancelado" [data]="folioCa" [searchKeyword]="keyword"
            (selected)="onKeypressEventFolioCancelado($event)" [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
            <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
            </ng-template>
        </mat-card-title>

        <mat-card-actions class="col-12 text-right mt-3">
            <button type="button" class="btn btn-m19" (click)="slectListadoCancelados()">Listado folios cancelados</button>
        </mat-card-actions>

        <mat-card-content class="justify-align-center">
            <table class="table table-striped animated fadeIn faster">
                <thead class="thead-m19">
                    <tr>
                        <th scope="col">No.</th>
                        <th scope="col">Inmueble</th>
                        <th scope="col">Folio</th>
                        <th scope="col">Fecha de pago</th>                        
                        <th scope="col">Cantidad ($):</th>
                        <th scope="col">Método de pago</th>
                        <th scope="col">Estado</th>
                        <th scope="col">Fecha de cancelación</th>
                        <th scope="col">Motivo de cancelación</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cancelado of cancelados; let i=index; let a">
                        <th>{{i+1}}</th>
                        <td>{{cancelado.fkCliente}}</td>
                        <th scope="row">{{cancelado.folioPago}}</th>
                        <td>{{cancelado.registrationDate | date: 'dd-MM-yyyy'}}</td>                        
                        <td>{{cancelado.cantidadAbono | currency:'CAD' : '$ '}}</td>
                        <td>{{cancelado.metodoPago}}</td>                        
                        <td>{{cancelado.estadoPago}}</td>
                        <td>{{cancelado.modificationDate | date: 'dd-MM-yyyy'}}</td>
                        <td>{{cancelado.reason}}</td>
                    </tr>
                </tbody>
            </table>
        </mat-card-content>

    </mat-card>
</div>