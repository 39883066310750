import { Component, Inject, OnInit } from '@angular/core';

//=========================================================================================================
//Importación de servicios necesarios para esta página
//=========================================================================================================
import { RequisitionService } from 'src/app/services/requisition.service';
import { DepartureService } from 'src/app/services/departure.service';
import { BudgetService } from 'src/app/services/budget.service';
import { ProductcatalogService } from 'src/app/services/productcatalog.service';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';
import { ConceptsService } from 'src/app/services/concepts.service';

//=========================================================================================================
//Importación de librerías para componentes visuales
//=========================================================================================================
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { DatePipe } from '@angular/common';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-budgetupdateconsult',
  templateUrl: './budgetupdateconsult.component.html',
  styleUrls: ['./budgetupdateconsult.component.scss']
})
export class BudgetupdateconsultComponent implements OnInit {
  //Variable que guarda al usuario ya descifrado
  decode: any = [];

  // Variable para guardar los materiales 
  materials: any = [];

  //Variable que contiene las partidas
  departures: any = [];

  //Variable que contiene la información de las torres
  towers: any;

  //Variable que contiene el subtotal del presupuesto incializado en cero
  subtotal: number = 0;

  //Variable que almacena la suma de los subtotales
  totalp: number = 0;

  //Variable que almacena el total del presupuesto
  totalf: any = [];

  //Variable que almacena información de un producto
  product: any = [];

  //Variable que contiene información del presupuesto
  budget: any = [];

  //Variable que guarda el numero de presupuesto
  budgetnumber: any;

  //Variable que almacena la fecha del presupuesto
  budgetdate: any;

  //Variable que guarda el identificador del proyecto
  idprojectdet: any;

  //Variable para almacenar el identificador del concepto
  idconcept: any;

  //Variable que contiene la descripción de la partida
  descriptions: any;

 //Variable que almacena la modificación 
  modification: any = false;

  //Variable que almacena el identificador del presupuesto
  budgetID: any;

  //Variable que almacena la información una vez editado el presupuesto
  editing: any = "";

  //Variable que almacena la información del responsable encargado de realizar el presupuesto
  responsable: any = [];

  //Variable que indica el formato de la fecha
  pipe = new DatePipe('en-US');

  //Objeto para almacenar los materiales registrados en la BD
  products: any = [];

  //Objeto que almacena la lista de los materiales pertenecientes a un presupuesto
  materialList: any = [];

  //Variable que almacena la información de los conceptos
  concepts: any = [];

  //Palabras claves que se utilizan a la hora de buscar información sobre las partidas, productos y conceptos
  keywordP = 'departurename';
  keywordM = 'productname';
  keywordC = 'descriptions';
  todayWithPipe: any;
  constructor(
    //===================================================
    //Declaración de servicios
    private dialogRef: MatDialogRef<BudgetupdateconsultComponent>,
    public budgetService: BudgetService,
    public requisitionService: RequisitionService,
    public departureService: DepartureService,
    public productCatalogService: ProductcatalogService,
    public projectDetailService: ProjectdetailsService,
    public concetpsService: ConceptsService,
    
    //===================================================
    //Declaración de complementos visuales
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    //Obtener todas las partidas
    this.selectDepartures();
    //Obtener todos los modulos
    this.getModules();
    //Obtener los productos
    this.selectProducts();
    //Calcular el total del presupuesto
    this.recalculateTotal();
    //Obtener el usuario que está haciendo uso de la interfaz
    this.getUser();
    //Tranformar la fecha del presupuesto con el formato que se requiere
    this.todayWithPipe = this.pipe.transform(this.budget.budgetdate, 'dd-MM-yyyy');
    //Obtener la información del presupuesto
    this.getBudgetInfo();
    //Obtener los productos de cada partida
    this.getDepartureProducts();
    //Insertar el responsable del presupuesto a través del correo del usuario que está empleando la interfaz
    this.insertResponsable();
  }
  //==========================================================================================================
  //Objeto constructor del formulario utilizado en la pantalla de presupuesto, se agrega el código 
  //", Validators.required" para señalar que es un valor que no debe estar vacío
  //==========================================================================================================
  budgetFormGroup: FormGroup = this._formBuilder.group({
    idbudget: [],
    idProject: [],
    idprojectdet: [, Validators.required],
    budgetdate: [, Validators.required],
    idconcept: [],
    descriptions: [],
    idproduct: [],
    productname: [],
    unit: [],
    unitcost: [],
    amount: [],
    product: [],
    total: [],
    totalp: [],
    reason: [],
    analyst: [, Validators.required],
    responsable: [],
    approved: [],
    useremail: [this.getUser()],
    departure: [],
    concept: [],
    iddeparture: [],
    departurename: [],
    departurenumber: [],
    idpiecework:[],
    typepw:[]
  });

  //==========================================================================================================
  //Función para actualizar un presupuesto 
  //==========================================================================================================

  async updateBudget() {
    this.budgetService.updateBudget(this.budgetFormGroup.value).subscribe(
      res => {
        //Se recuperan todos los productos del presupuesto
        this.updateBudgetProduct();
      },
      err => console.log(err)
    );
  }
  

  //==========================================================================================================
  //Función para agregar materiales de presupuesto a una lista temporal
  //==========================================================================================================
  async updateBudgetProduct() {
    this.budgetService.deleteAllByBud(this.budgetID).subscribe(
      res => {
        for (let p of this.materialList) {
          let prod = p.split("~");
          this.budgetService.insertProductsBudget(this.budgetID, prod[7], prod[1], prod[8], prod[3], prod[5], prod[6], this.decode.email).subscribe(
            //"INSERT INTO budget_products (idbudget,iddeparture,idconcept,idproduct,amount,unitcost,total,useremail,creationdate) VALUES (36,'2','undefined','1','LT','8','20','brandakarimeburgara@gmail.com', now())"
            //0.-mat.departurenumber + '~' +1.-mat.idconcept+ '~'+2.-mat.productname+ '~'+ 3.-mat.amount+ '~' +4.-mat.unit  + '~'+ 5.-mat.unitcost + '~' +6.-mat.productTotal+ '~' + 7.-mat.iddeparture+ '~' +8.-mat.idproduct +'~' + 9.-mat.departurename + '~' + 10.-mat.descriptions
            res => {
              this.sweet.AlertTime("center", "success", "Presupuesto actualizado con éxito", false, 2000);
              this.dialogRef.close();
            }
          );
        }
        if (this.materialList.length == 0) {
          this.sweet.AlertTime("center", "success", "Presupuesto actualizado con éxito", false, 2000);
          this.dialogRef.close();
        }
      }
    );
  }

  //==========================================================================================================
  //Función para agregar materiales de presupuesto a una lista temporal
  //==========================================================================================================
  async addMaterial() {
    if (this.budgetFormGroup.controls['iddeparture'].value == null || this.budgetFormGroup.controls['iddeparture'].value == ''
      || this.budgetFormGroup.controls['productname'].value == null || this.budgetFormGroup.controls['productname'].value == ''
      || this.budgetFormGroup.controls['amount'].value == null || this.budgetFormGroup.controls['amount'].value == ''
      || this.budgetFormGroup.controls['unit'].value == null || this.budgetFormGroup.controls['unit'].value == ''
      || this.budgetFormGroup.controls['unitcost'].value == null || this.budgetFormGroup.controls['unitcost'].value == ''
      || this.budgetFormGroup.controls['totalp'].value == null || this.budgetFormGroup.controls['totalp'].value == '') {
      this.sweet.AlertTime("center", "error", "Por favor complete los campos de partida, producto y cantidad correctamente.", false, 2000);
    } else {
      this.modification = true;
      let addMaterial = this.budgetFormGroup.value;
      if (addMaterial !== '') {

        this.materialList.push(addMaterial.departurenumber + '~' + addMaterial.idconcept + '~' + addMaterial.productname + '~' + addMaterial.amount + '~' + addMaterial.unit + '~' + addMaterial.unitcost + '~' + addMaterial.totalp + '~' + addMaterial.iddeparture + '~' + addMaterial.idproduct + '~' + addMaterial.departurename + '~' + addMaterial.descriptions);
        console.log(this.materialList);
        this.subtotal = this.subtotal + parseInt(addMaterial.totalp);
        this.budgetFormGroup.controls['total'].setValue(this.subtotal);
        //Se resetea la información de los campos de texto para productos
        this.budgetFormGroup.controls['iddeparture'].setValue('');
        this.budgetFormGroup.controls['departurename'].setValue('');
        this.budgetFormGroup.controls['departurenumber'].setValue('');
        this.budgetFormGroup.controls['departure'].setValue('');
        this.budgetFormGroup.controls['idproduct'].setValue('');
        this.budgetFormGroup.controls['productname'].setValue('');
        this.budgetFormGroup.controls['product'].setValue('');
        this.budgetFormGroup.controls['amount'].setValue('');
        this.budgetFormGroup.controls['unit'].setValue('');
        this.budgetFormGroup.controls['unitcost'].setValue('');
        this.budgetFormGroup.controls['totalp'].setValue('');
        this.budgetFormGroup.controls['idconcept'].setValue('');
        this.budgetFormGroup.controls['descriptions'].setValue('');
        this.budgetFormGroup.controls['concept'].setValue('');
        this.editing = "";

      }
    }
  }
  
  //==========================================================================================================
  //Función para comparar los datos propuestos en el formulario comparados con las variables
  //==========================================================================================================
  async compareValues() {
    if (this.budgetFormGroup.controls['idprojectdet'].value != this.idprojectdet
      || this.budgetFormGroup.controls['budgetdate'].value != this.budgetdate) {
      this.modification = true;
    } else {
      this.modification = false;
    }
  }
  //==========================================================================================================
  //Función para recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.email
  }

  //==========================================================================================================
  //Función para buscar las partidas registradas en la base de datos
  //==========================================================================================================
  async selectDepartures() {
    this.departureService.getDepartureList().subscribe(
      res => {
        //Se recuperan todas las partidas en la variable departures
        this.departures = res;
      },
      err => console.log(err)
    );
  }
  //==========================================================================================================
  //Función para seleccionar información del formulario relacionado con las partidas
  //==========================================================================================================
  async selectDeparture(obj: any) {
    let info = obj.departurename.split('~');
    this.budgetFormGroup.controls['iddeparture'].setValue(obj.iddeparture);
    this.budgetFormGroup.controls['departurename'].setValue(info[1]);
    this.budgetFormGroup.controls['departurenumber'].setValue(info[0]);
  }
  //==========================================================================================================
  //Función para buscar los materiales registrados en el catalogo de la BD
  //==========================================================================================================
  async selectProducts() {
    this.productCatalogService.getNamesOfProducts()
      .subscribe(
        res => {
          //Se recuperan los productos en la variable products
          this.products = res;
        },
        err => console.log(err)
      );
  }
  //==========================================================================================================
  //Función para buscar los conceptos
  //==========================================================================================================
  async selectConcepts(id: any) {
    this.concetpsService.selectConcepts(id.iddeparture).subscribe(
      res => {
        this.concepts = res;
      }
    );
  }
  //==========================================================================================================
  //Función para seleccionar información del formulario relacionado con los conceptos 
  //==========================================================================================================
  async selectConcept(obj:any){
    this.budgetFormGroup.controls['idconcept'].setValue(obj.idconcept);
    this.budgetFormGroup.controls['description'].setValue(obj.descriptions);
  }

  //==========================================================================================================
  //Función para buscar un material por ID según la selección del select del formulario
  //==========================================================================================================
  async selectProduct(id: any) {
    //El objeto select tiene de valor el ID y el nombre separados por coma
    //idproduct , productname

    let ide = id.productname.split("~");

    this.budgetFormGroup.controls["idproduct"].setValue(ide[0]);
    this.budgetFormGroup.controls["productname"].setValue(ide[1]);


    this.productCatalogService.selectProduct(parseInt(ide[0]))
      .subscribe(
        res => {
          //Recuperamos el valor en la variable product
          this.product = res;

          //Asignamos al objeto del formulario con controlName "unit" el valor recuperado de la consulta
          this.budgetFormGroup.controls['unit'].setValue(this.product.unit);
        }
      );
  }

  //==========================================================================================================
  //Recalcular el total del presupuesto
  //==========================================================================================================
  async recalculateTotal() {
    this.budgetFormGroup.controls['totalp'].setValue(this.budgetFormGroup.controls['unitcost'].value * this.budgetFormGroup.controls['amount'].value);
    this.totalp = this.budgetFormGroup.controls['totalp'].value;
  }

  //==========================================================================================================
  //Función para eliminar un producto o material del presupuesto
  //==========================================================================================================
  deleteProduct(i: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, elimínalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'El producto se quitó de la requisición.',
          'success'
        )

        //Se crea una variable temporal para almacenar los materiales que permanecerían en la lista
        const materialsT = Array();
        let indice = 0;

        //Se recorre la lista de materiales con un ciclo for
        for (const mat of this.materialList) {
          //Se compara el indice que se quiere eliminar con el de la lista y si no coincide se almacena en la lista
          //temporal
          if (indice !== i) {
            materialsT.push(mat);
          }

          if(indice == i) {
            this.subtotal = this.subtotal - mat.split('~')[6];
            this.budgetFormGroup.controls['total'].setValue(this.subtotal);
          }

          indice++;
        }
        //Se pasa el valor de la lista temporal a la lista original de materiales
        this.materialList = materialsT;
        this.modification = true;
      }
    })
  }

  //==========================================================================================================
  //Función para editar algún material
  //==========================================================================================================
  async editProduct(i: number) {
    if (this.editing != "") {
      Swal.fire({
        title: '¿Estás seguro de este movimiento?',
        text: "Ya estás editando un material y no has guardado, si continuas se perderá",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, continúa',
        cancelButtonText: 'No, espera',
      }).then((result) => {
        if (result.isConfirmed) {
          //Se crea una variable temporal para almacenar los materiales que permanecerían en la lista
          const materialsT = Array();
          let indice = 0;

          //Se recorre la lista de materiales con un ciclo for
          for (const mat of this.materialList) {
            //Se compara el indice que se quiere eliminar con el de la lista y si no coincide se almacena en la lista
            //temporal
            if (indice !== i) {
              materialsT.push(mat);
            }

            if (indice == i) {
              this.editing = mat;
              this.subtotal = this.subtotal - mat.split('~')[6];
              this.budgetFormGroup.controls['total'].setValue(this.subtotal);
            }
            indice++;
          }
          //Se pasa el valor de la lista temporal a la lista original de materiales
          this.materialList = materialsT;
          // 0.- mat.iddeparture+"~"+1.- mat.departurename+"~"+2.- mat.idconcept+"~"+3.- mat.idproduct+"~"+4.- mat.productname+"~"+5.- mat.amount+"~"+6.- mat.unit+"~"+7.- mat.unitcost+"~"+8.- mat.productTotal+"~"+9.- mat.departurenumber+"~"+10.-mat.descriptions
          let info = this.editing.split("~");
          this.budgetFormGroup.controls['departure'].setValue(info[0] + " ~ " + info[9]);
          this.budgetFormGroup.controls['iddeparture'].setValue(info[7]);
          this.budgetFormGroup.controls['departurenumber'].setValue(info[0]);
          this.budgetFormGroup.controls['departurename'].setValue(info[9]);
          this.budgetFormGroup.controls['product'].setValue(info[8] + " ~ " + info[2]);
          this.budgetFormGroup.controls['idproduct'].setValue(info[8]);
          this.budgetFormGroup.controls['productname'].setValue(info[2]);
          this.budgetFormGroup.controls['amount'].setValue(info[3]);
          this.budgetFormGroup.controls['unit'].setValue(info[4]);
          this.budgetFormGroup.controls['unitcost'].setValue(info[5]);
          this.budgetFormGroup.controls['totalp'].setValue(info[6]);
          this.budgetFormGroup.controls['idconcept'].setValue(info[1]);
          this.budgetFormGroup.controls['descriptions'].setValue(info[10]);
          this.budgetFormGroup.controls['concept'].setValue(info[1] + " ~ " + info[10]);
        }
      })
      return;
    }
    //Se crea una variable temporal para almacenar los materiales que permanecerían en la lista
    const materialsT = Array();
    let indice = 0;

    //Se recorre la lista de materiales con un ciclo for
    for (const mat of this.materialList) {
      //Se compara el indice que se quiere eliminar con el de la lista y si no coincide se almacena en la lista
      //temporal
      if (indice !== i) {
        materialsT.push(mat);
      }

      if (indice == i) {
        this.editing = mat;
        this.subtotal = this.subtotal - mat.split('~')[6];
        this.budgetFormGroup.controls['total'].setValue(this.subtotal);
      }
      indice++;
    }
    //Se pasa el valor de la lista temporal a la lista original de materiales
    this.materialList = materialsT;
    let info = this.editing.split("~");
    this.budgetFormGroup.controls['departure'].setValue(info[0] + " ~ " + info[9]);
    this.budgetFormGroup.controls['iddeparture'].setValue(info[7]);
    this.budgetFormGroup.controls['departurenumber'].setValue(info[0]);
    this.budgetFormGroup.controls['departurename'].setValue(info[9]);
    this.budgetFormGroup.controls['product'].setValue(info[8] + " ~ " + info[2]);
    this.budgetFormGroup.controls['idproduct'].setValue(info[8]);
    this.budgetFormGroup.controls['productname'].setValue(info[2]);
    this.budgetFormGroup.controls['amount'].setValue(info[3]);
    this.budgetFormGroup.controls['unit'].setValue(info[4]);
    this.budgetFormGroup.controls['unitcost'].setValue(info[5]);
    this.budgetFormGroup.controls['totalp'].setValue(info[6]);
    this.budgetFormGroup.controls['idconcept'].setValue(info[1]);
    this.budgetFormGroup.controls['descriptions'].setValue(info[10]);
    this.budgetFormGroup.controls['concept'].setValue(info[1] + " ~ " + info[10]);
  }

  //Función para seleccionar la información del presupuesto
  async getBudgetInfo() {
    this.budgetService.getBudgetInfo(this.data.idbudget).subscribe(
      res => {
        this.budget = res;
        console.log(this.budget)
        this.todayWithPipe = this.pipe.transform(this.budget.budgetdate, 'yyyy-MM-dd');
        this.budgetFormGroup.controls['idprojectdet'].setValue(this.budget.idprojectdet);
        this.budgetFormGroup.controls['idbudget'].setValue(this.budget.idbudget);
        this.budgetFormGroup.controls['budgetdate'].setValue(this.todayWithPipe);
        this.budgetFormGroup.controls['analyst'].setValue(this.budget.analyst);
        this.budgetFormGroup.controls['total'].setValue(this.budget.total);

        this.budgetID = this.budget.idbudget;
        this.idprojectdet = this.budget.idprojectdet;
        this.budgetdate = this.todayWithPipe;
        console.log(this.budget.analyst);
      }
    );
  }

  //==========================================================================================================
  //Función para obtener los módulos 
  //==========================================================================================================
  async getModules() {
    this.projectDetailService.selectDetailsById(this.data.idProject).subscribe(
      res => {
        this.towers = res;
      }
    );
  }
  //==========================================================================================================
  //Función para obtener los productos pertenecientes a las partidas
  //==========================================================================================================
 async getDepartureProducts() {
    this.budgetService.getProdInBudget(this.data.idbudget).subscribe(
      res => {
        this.materials = res;
        this.generateList();
      }
    );
  }

  //==========================================================================================================
  //Función para recalcular el total del presupuesto
  //==========================================================================================================
  async recalculateTotalSuma() {
    this.subtotal = parseInt(this.budgetFormGroup.controls['totalp'].value) + this.subtotal;
    this.budgetFormGroup.controls['total'].setValue(this.subtotal);
  }

  //==========================================================================================================
  //Función para generar una lista que contiene la información del presupuesto
  //==========================================================================================================
  async generateList() {
    for (let mat of this.materials) {
      this.subtotal = this.subtotal + mat.productTotal;
      this.materialList.push(mat.departurenumber + '~' + mat.idconcept + '~' + mat.productname + '~' + mat.amount + '~' + mat.unit + '~' + mat.unitcost + '~' + mat.productTotal + '~' + mat.iddeparture + '~' + mat.idproduct + '~' + mat.departurename + '~' + mat.descriptions);
      //(mat.departurenumber + '~' +mat.idconcept+ '~'+mat.productname+ '~'+ mat.amount+ '~' +mat.unit  + '~'+ mat.unitcost + '~' +mat.productTotal+ '~' + mat.iddeparture+ '~' +mat.idproduct +'~' + mat.departurename + '~' + mat.descriptions); 
    }
    this.budgetFormGroup.controls['total'].setValue(this.subtotal);
  }

  //==========================================================================================================
  //Función para insertar un responsable en la tabla presupuestos
  //==========================================================================================================
  async insertResponsable() {
    //Se manda a llamar el servicio de requisition
    this.budgetService.getRegister(this.getUser())
      .subscribe(
        res => {
          this.responsable = res
          console.log(this.responsable)
          this.budgetFormGroup.controls['responsable'].setValue(this.responsable.creator);
        },
        err => console.error(err)
      );
  }


}


