import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';

import { DeliveryInvoice } from '../../../../models/DeliveryInvoice';

import { DatePipe } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL

import { DeliveryinvoiceService } from 'src/app/services/deliveryinvoice.service';
import Swal from 'sweetalert2';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { DialogEditInvoiceComponent } from './dialog-edit-invoice/dialog-edit-invoice.component';
import { loader } from 'src/app/ui/loader/loader.model';

@Component({
  selector: 'app-dialog-invoice',
  templateUrl: './dialog-invoice.component.html',
  styleUrls: ['./dialog-invoice.component.scss']
})
export class DialogInvoiceComponent {
  loader: loader = {
    message: "Cargando información..."
  }

  loading = 1;

  deliveryfolio: any = [];
  iddelinvoice: any;

  invoiceList: any = [];

  pipe = new DatePipe('es-MX');

  //Objeto para almacenar las notas de remisión registradas para la requisición seleccionada
  invoiceInfo: any = [];

  //Objeto para almacenar los productos registrados para la requisición seleccionada
  invoiceProducts: any = [];

  constructor(
    private dialogRef: MatDialogRef<DialogInvoiceComponent>,

    // =========================================================
    // Declaración del servicio de nota de remisión
    public invoiceService: DeliveryinvoiceService,

    public sweet: SweetAlertComponent,

    // ======================================================================
    // Declaración del componente gráfico utilizado para cuadro de dialogo
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) {
    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
    this.invoiceService.selectInvoice(this.data).subscribe(
      res => {
        this.invoiceList = res;
        this.loading = 0;
      }
    );
  }

  async getInvoiceInfo(id: String) {
    this.invoiceService.getInvoiceInfo(id).subscribe(
      res => {
        this.invoiceInfo = res;
        this.iddelinvoice = res.iddelinvoice;
      }
    );
  }

  //Función para buscar todos los productos registrados para una requisición
  async getInvoiceProducts(id: number) {
    this.loading = 1;
    this.invoiceService.selectInvoiceProducts(id).subscribe(
      res => {
        this.invoiceProducts = res;
        this.loading = 0;
      }
    );
  }

  async editInvoice(folio: String) {
    this.dialog.open(DialogEditInvoiceComponent, {
      width: "100%",
      data: folio
    });
  }

  //Función para eliminar una factura
  async deleteInvoice() {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, mejor no',
      confirmButtonText: 'Sí, elimínala!',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'm19AltBtn',
        cancelButton: 'm19Btn',
        actions: 'sweetActions'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.invoiceService.deleteInvoice(this.iddelinvoice).subscribe(
          res => {
            this.sweet.AlertTime("center", "success", "Factura eliminada con éxito", false, 2000);
            this.dialogRef.close();
          }
        );
      }
    })
  }
}