//=========================================================================================================
//Importación de componentes necesarios para esta pantalla
//=========================================================================================================
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';

import { ProductcatalogService } from 'src/app/services/productcatalog.service';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { DeliveryinvoiceService } from 'src/app/services/deliveryinvoice.service';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-edit-invoice',
  templateUrl: './dialog-edit-invoice.component.html',
  styleUrls: ['./dialog-edit-invoice.component.scss']
})
export class DialogEditInvoiceComponent implements OnInit {
  suppliers: any = [];
  products: any = [];
  product: any = [];

  subt: any = 0;
  iva: any = 0.16;
  total: any = 0;

  idsupplier: any;
  invoicenumber: any;
  invoicedate: any;

  materials: any = [];
  materialList: any = [];

  modification: any = false;
  editing: any = "";

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  pipe = new DatePipe('en-US');
  todayWithPipe: any;
  keywordM = 'productname';

  constructor(
    private dialogRef: MatDialogRef<DialogEditInvoiceComponent>,
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,

    private productService: ProductcatalogService,
    private supplierService: SuppliersService,
    private invoiceService: DeliveryinvoiceService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  //==========================================================================================================
  //Objeto constructor del formulario utilizado en la pantalla de remisión, se agrega el código 
  //", Validators.required" para señalar que es un valor que no debe estar vacío
  //==========================================================================================================
  invoiceFormGroup: FormGroup = this._formBuilder.group({
    iddelinvoice: [],
    deliveryfolio: [],
    idsupplier: [, Validators.required],
    invoicenumber: [, Validators.required],
    invoicedate: [, Validators.required],
    subtotal: [],
    iva: [0.16],
    total: [],
    idproduct: [],
    product: [],
    productname: [],
    amount: [],
    unit: [],
    unitcost: [],
    reason: [],
    useremail: [this.getUser()]
  });

  ngOnInit(): void {
    this.selectProducts();
    this.selectSuppliers();
    this.getInvoiceInfo(this.data);
    this.getInvoiceProducts();
  }

  async getInvoiceInfo(id: String) {
    this.invoiceService.getInvoiceInfo(id).subscribe(
      res => {
        console.log(res);
        this.invoiceFormGroup.controls['deliveryfolio'].setValue(res.deliveryfolio);
        this.invoiceFormGroup.controls['idsupplier'].setValue(res.idsupplier);
        this.idsupplier = res.idsupplier;

        this.invoiceFormGroup.controls['invoicenumber'].setValue(res.invoicenumber);
        this.invoicenumber = res.invoicenumber;

        this.todayWithPipe = this.pipe.transform(res.invoicedate, 'yyyy-MM-dd');
        this.invoiceFormGroup.controls['invoicedate'].setValue(this.todayWithPipe);
        this.invoicedate = this.todayWithPipe;

        this.invoiceFormGroup.controls['subtotal'].setValue(res.subtotal);
        this.invoiceFormGroup.controls['total'].setValue(res.total);
      }
    );
  }

  async getInvoiceProducts() {
    this.invoiceService.selectInvoiceProducts(this.data).subscribe(
      res => {
        this.materials = res;
        this.genMaterialList();
      }
    );
  }

  async selectProducts() {
    this.productService.getNamesOfProducts().subscribe(
      res => {
        this.products = res;
      }
    );
  }

  async selectSuppliers() {
    this.supplierService.selectSuppliers().subscribe(
      res => {
        this.suppliers = res;
      }
    );
  }

  async genMaterialList() {
    for (let m of this.materials) {
      this.materialList.push({ "idproduct": m.idproduct, "productname": m.productname, "amount": m.amount, "unit": m.unit, "unitcost": m.unitcost });
    }
  }

  async selectProduct(o: any) {
    this.invoiceFormGroup.controls["idproduct"].setValue(o.idProduct);
    this.invoiceFormGroup.controls["productname"].setValue(o.productname.split('~')[1]);

    this.productService.selectProduct(o.idProduct)
      .subscribe(
        res => {
          //Recuperamos el valor en la variable product
          this.product = res;


          //Asignamos al objeto del formulario con controlName "unit" el valor recuperado de la consulta
          this.invoiceFormGroup.controls['unit'].setValue(this.product.unit);
        }
      );
  }

  async compareValues() {
    if (this.invoiceFormGroup.controls['idsupplier'].value != this.idsupplier
      || this.invoiceFormGroup.controls['invoicenumber'].value != this.invoicenumber
      || this.invoiceFormGroup.controls['invoicedate'].value != this.invoicedate) {
      this.modification = true;
    } else {
      this.modification = false;
    }
  }

  async addMaterial() {
    if (this.invoiceFormGroup.controls['productname'].value == null || this.invoiceFormGroup.controls['productname'].value == ''
      || this.invoiceFormGroup.controls['amount'].value == null || this.invoiceFormGroup.controls['amount'].value == ''
      || this.invoiceFormGroup.controls['unit'].value == null || this.invoiceFormGroup.controls['unit'].value == '') {
      this.sweet.AlertTime("center", "error", "Por favor complete los campos de producto y cantidad correctamente.", false, 2000);
    } else {
      this.modification = true;
      let addMaterial = this.invoiceFormGroup.value;
      if (addMaterial !== '') {
        this.materialList.push({ "idproduct": addMaterial.idproduct, "productname": addMaterial.productname, "amount": addMaterial.amount, "unit": addMaterial.unit, "unitcost": addMaterial.unitcost });

        //Se calcula el subtotal de la factura
        this.subt = this.subt + (addMaterial.unitcost * addMaterial.amount);

        //Se calcula el total de la factura
        this.total = this.subt + (this.subt * this.iva);

        //Se resetea la información de los campos de texto para productos
        this.invoiceFormGroup.controls['idproduct'].setValue('');
        this.invoiceFormGroup.controls['product'].setValue('');
        this.invoiceFormGroup.controls['productname'].setValue('');
        this.invoiceFormGroup.controls['amount'].setValue('');
        this.invoiceFormGroup.controls['unit'].setValue('');
        this.invoiceFormGroup.controls['unitcost'].setValue('');

        //Se coloca los valores de subtotal y total en los campos correspondientes
        this.invoiceFormGroup.controls['subtotal'].setValue(this.subt);
        this.invoiceFormGroup.controls['total'].setValue(this.total);

        this.editing = "";
      }
    }
  }

  async deleteMaterials(i: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, mejor no',
      confirmButtonText: 'Sí, elimínalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.materialList.splice(i, 1);
      }
    })
  }

  async editProduct(i: number) {
    if (this.editing != "") {
      Swal.fire({
        title: '¿Estás seguro de este movimiento?',
        text: "Ya estás editando un material y no has guardado, si continuas se perderá",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, continúa',
        cancelButtonText: 'No, espera',
      }).then((result) => {
        if (result.isConfirmed) {
          //Se crea una variable temporal para almacenar los materiales que permanecerían en la lista
          const materialsT = Array();
          let indice = 0;

          //Se recorre la lista de materiales con un ciclo for
          for (const mat of this.materialList) {
            //Se compara el indice que se quiere eliminar con el de la lista y si no coincide se almacena en la lista
            //temporal
            if (indice !== i) {
              materialsT.push(mat);
            }

            if (indice == i) {
              this.editing = mat;
              this.subt = this.subt - (mat.amount * mat.unitcost);
              this.invoiceFormGroup.controls['subtotal'].setValue(this.subt);

              this.total = this.subt + (this.subt * this.iva);
              this.invoiceFormGroup.controls['total'].setValue(this.total);
            }
            indice++;
          }
          //Se pasa el valor de la lista temporal a la lista original de materiales
          this.materialList = materialsT;

          this.invoiceFormGroup.controls['product'].setValue(this.editing.idproduct + " ~ " + this.editing.productname);
          this.invoiceFormGroup.controls['idproduct'].setValue(this.editing.idproduct);
          this.invoiceFormGroup.controls['productname'].setValue(this.editing.productname);
          this.invoiceFormGroup.controls['amount'].setValue(this.editing.amount);
          this.invoiceFormGroup.controls['unit'].setValue(this.editing.unit);
          this.invoiceFormGroup.controls['unitcost'].setValue(this.editing.unitcost);
        }
      })
      return;
    }
    //Se crea una variable temporal para almacenar los materiales que permanecerían en la lista
    const materialsT = Array();
    let indice = 0;

    //Se recorre la lista de materiales con un ciclo for
    for (const mat of this.materialList) {
      //Se compara el indice que se quiere eliminar con el de la lista y si no coincide se almacena en la lista
      //temporal
      if (indice !== i) {
        materialsT.push(mat);
      }

      if (indice == i) {
        this.editing = mat;
      }
      indice++;
    }
    //Se pasa el valor de la lista temporal a la lista original de materiales
    this.materialList = materialsT;

    this.invoiceFormGroup.controls['product'].setValue(this.editing.idproduct + " ~ " + this.editing.productname);
    this.invoiceFormGroup.controls['idproduct'].setValue(this.editing.idproduct);
    this.invoiceFormGroup.controls['productname'].setValue(this.editing.productname);
    this.invoiceFormGroup.controls['amount'].setValue(this.editing.amount);
    this.invoiceFormGroup.controls['unit'].setValue(this.editing.unit);
    this.invoiceFormGroup.controls['unitcost'].setValue(this.editing.unitcost);
  }

  async updateInvoice() {
    if (this.modification == true && (this.invoiceFormGroup.controls['reason'].value == null || this.invoiceFormGroup.controls['reason'].value == '')) {
      this.sweet.AlertTime("center", "error", "Por favor capture una razón de modificación.", false, 2000);
    } else if (this.modification == false) {
      this.sweet.AlertTime("center", "warning", "No se detectó ninguna modificación", false, 2000);
      this.dialogRef.close();
    } else {
      Swal.fire({
        title: '¿Quieres guardar tus cambios ahora?',
        text: "¡Esta factura se guardara como está actualmente!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No, espera',
        confirmButtonText: 'Sí, guárdala!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.invoiceService.updateInvoice(this.data, this.invoiceFormGroup.value).subscribe(
            res => {
              alert("Se actualizo factura");
              this.updateProducts();
            }
          );
        }
      })
    }
  }

  async updateProducts() {
    this.invoiceService.deleteAllProductsById(this.data).subscribe(
      res => {
        for (let p of this.materialList) {
          this.invoiceService.insertInvoiceProducts(this.data, p.idproduct, p.amount, p.unitcost, this.getUser()).subscribe(
            res => {
              this.sweet.AlertTime("center", "success", "Factura actualizada con éxito", false, 2000);
              this.dialogRef.close();
            }
          );
        }

        if (this.materialList.length == 0) {
          this.sweet.AlertTime("center", "success", "Factura actualizada con éxito", false, 2000);
          this.dialogRef.close();
        }
      }
    );
  }

  //==========================================================================================================
  //Recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.email
  }

}
