<mat-dialog-content>
    <h1 style="text-align: center; border-bottom: solid rgb(117, 0, 0) 2px; margin-bottom: 30px;">Lista de destajos</h1>
    <form [formGroup]="pieceworkFormGroup" (ngSubmit)="updatePiecework()">
        <div class="registerRow" *ngIf="statusemployee=='nonulo' else se">
            <mat-form-field appearance="outline" [hidden]="true">
                <mat-label>idprojectdet</mat-label>
                <input matInput type="number" formControlName="idprojectdet" required readonly>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Semana</mat-label>
                <input matInput type="number" formControlName="week" min="1" max="53" (input)="compareValues()" required readonly>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Asignado a:</mat-label>
                <input matInput type="text" formControlName="employee" (input)="compareValues()">
            </mat-form-field>
        </div>

        <ng-template #se class="registerRow">
            <mat-form-field appearance="outline" [hidden]="true">
                <mat-label>idprojectdet</mat-label>
                <input matInput type="number" formControlName="idprojectdet" required readonly>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Semana</mat-label>
                <input matInput type="number" formControlName="week" min="1" max="53" (input)="compareValues()" required readonly>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Asignado a:</mat-label>
                <input matInput type="text" formControlName="employee" (input)="compareValues()" readonly>
            </mat-form-field>
        </ng-template>



        <div class="registerRow">
            <mat-form-field appearance="outline" [hidden]="true">
                <mat-label>iddeparture</mat-label>
                <input matInput type="number" formControlName="iddeparture" readonly>
            </mat-form-field>
            <mat-form-field appearance="outline" [hidden]="true">
                <mat-label>departurename</mat-label>
                <input matInput type="text" formControlName="departurename" readonly>
            </mat-form-field>
            <mat-form-field appearance="outline" [hidden]="true">
                <mat-label>departurenumber</mat-label>
                <input matInput type="text" formControlName="departurenumber" readonly>
            </mat-form-field>
            <div class="ng-autocomplete">
                <ng-autocomplete formControlName="departure" name="busquedaP" placeholder="id/Partida"
                    [data]="departures" [searchKeyword]="keywordP" [itemTemplate]="itemTemplateP"
                    (selected)='selectDeparture($event)' [notFoundTemplate]="notFoundTemplateP">
                </ng-autocomplete>
                <ng-template #itemTemplateP let-itemP>
                    <a [innerHTML]="itemP.departurename"></a>
                </ng-template>
                <ng-template #notFoundTemplateP let-notFound>
                    <div [innerHTML]="notFound"></div>
                </ng-template>
            </div>

            <mat-form-field appearance="outline" [hidden]="true">
                <mat-label>idpiecework</mat-label>
                <input matInput type="number" formControlName="idpiecework" readonly>
            </mat-form-field>

            <mat-form-field appearance="outline" [hidden]="true">
                <mat-label>pieceworktype</mat-label>
                <input matInput type="text" formControlName="typepw" readonly>
            </mat-form-field>

            <div class="ng-autocomplete">
                <ng-autocomplete formControlName="piecework" name="busquedaPi" placeholder="Destajo"
                    [data]="namepiecework" [searchKeyword]="keywordPi" (selected)='selectUnitPiece($event)'
                    [itemTemplate]="itemTemplatePi" [notFoundTemplate]="notFoundTemplatePi">
                </ng-autocomplete>
                <ng-template #itemTemplatePi let-itemPi>
                    <a [innerHTML]="itemPi.pieceworkname"></a>
                </ng-template>
                <ng-template #notFoundTemplatePi let-notFound>
                    <div>No hay coincidencias de destajos</div>
                </ng-template>
            </div>

        </div>


        <div class="registerRow">
            <mat-form-field appearance="outline">
                <mat-label>Cantidad</mat-label>
                <input matInput type="number" formControlName="amount" min="1" (input)="compareValues()">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Unidad</mat-label>
                <input matInput type="text" formControlName="unit" readonly>
            </mat-form-field>
        </div>

        <div class="registerRow">
            <mat-form-field appearance="outline">
                <mat-label>Costo unitario</mat-label>
                <input matInput type="number" formControlName="unitcost" (input)="recalculateTotal()"
                    (input)="compareValues()">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Total</mat-label>
                <input matInput type="number" formControlName="total" readonly>
            </mat-form-field>
        </div>

        <div class="registerRow">
            <mat-form-field *ngIf="modification==true" appearance="outline">
                <mat-label>Razón de modificación</mat-label>
                <textarea matInput cdkTextareaAutosize formControlName="reasonMod" cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="3"></textarea>
            </mat-form-field>

            <button class="m19AltBtn" type="submit" [disabled]="!pieceworkFormGroup.valid">
                Guardar destajo
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </form>
</mat-dialog-content>