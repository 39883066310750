import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-insertsupplier',
  templateUrl: './dialog-insertsupplier.component.html',
  styleUrls: ['./dialog-insertsupplier.component.scss']
})
export class DialogInsertsupplierComponent implements OnInit {

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  constructor(
    private dialogRef: MatDialogRef<DialogInsertsupplierComponent>,
    private _formBuilder: FormBuilder,
    public supplierService: SuppliersService,
    
    public sweet: SweetAlertComponent
  ) { }

  ngOnInit(): void {
  }

  //Objeto de formulario de proveedores
  suppliersFormGroup: FormGroup = this._formBuilder.group({
    idproduct: [],
    suppliername: ['', Validators.required],
    RFC: ['', Validators.required],
    direction: ['', Validators.required],
    phone: ['', Validators.required],
    useremail: [this.getUser()]
  });

  //=======================================================================================
  //Función para insertar un nuevo proveedor al catalogo de proveedores
  //=======================================================================================
  async insertSupplier() {
    if (this.suppliersFormGroup.invalid) {
      return;
    } else {
      Swal.fire({
        title: '¿Quieres guardar tus cambios ahora?',
        text: "¡Este producto se guardara como está actualmente!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, guárdalo!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.supplierService.insertSupplier(this.suppliersFormGroup.value).subscribe(
            res => {
              this.sweet.AlertTime("center", "success", "Proveedor registrado con éxito", false, 2000);
              this.dialogRef.close();
            }
          );
        }
      })
    }
  }

  //==========================================================================================================
  //Recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.email
  }

}
