import { Component, OnInit } from '@angular/core';

import { ProjectService } from 'src/app/services/project.service';
import { PropertyService } from 'src/app/services/property.service';
import { LogosService } from 'src/app/services/logos.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-quotation-form',
  templateUrl: './quotation-form.component.html',
  styleUrls: ['./quotation-form.component.scss']
})
export class QuotationFormComponent implements OnInit {
  projects: any = [];
  unities: any = [];
  details: any = { "idProject": 0, "name": "NA", "codeProperty": "NA", "amount": 0, "advancePayment": 0, "monthlyPayment": 0, "annualityPayment": 0, "fk_financialType": 0 };
  logo: any = {};

  type: number = 0;
  interval: number = 0;
  paid: number = 0;
  debt: number = 0;
  iterations: any = [];
  generate: Boolean = false;

  project: string = "";
  unity: string = "";
  inAnticipo: number = 0;
  inMensual: number = 0;
  // inAnual: number = 0;
  customer: string = "";

  startDate: Date = new Date();
  itDate: Date = new Date();

  constructor(
    private projectService: ProjectService,
    private propertyService: PropertyService,
    private logoService: LogosService
  ) {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      }
    );
  }

  ngOnInit(): void {
  }

  selectProject(id: number) {
    this.inAnticipo = 0;
    this.inMensual = 0;
    this.generate = false;
    this.propertyService.getPropertiesList(id).subscribe(
      res => {
        this.unities = res;
        if(this.unities.length==0){
          Swal.fire(
            "Sin Disponibilidad",
            "Actualmente No Hay Unidades Libres En Este Proyecto"
          );
        }
        this.logoService.selectLogo(id).subscribe(
          res => {
            this.logo = res.UrlLogo
          }
        );
      }
    );
  }

  selectUnity(id: number) {
    this.inAnticipo = 0;
    this.inMensual = 0;
    this.generate = false;
    this.propertyService.getPropertyData(id).subscribe(
      res => {
        this.details = res;
        console.log("aqui", this.details);
        this.type = this.details.fk_financialType;
        this.inAnticipo = this.details.advancePayment;
        this.inMensual = this.details.monthlyPayment;
      }
    );
  }

  generateQuotation() {
    if (this.project == '' || this.unity == '' || this.inAnticipo == 0 || this.inMensual == 0 || this.customer == '') {
      Swal.fire(
        'Datos invalidos',
        'Por favor complete todos los campos para generar una corrida financiera',
        'error'
      )
    } else {
      this.generate = true;
      this.debt = this.details.amount - this.inAnticipo;
      this.paid = this.inAnticipo;
      this.iterations = [];
      this.itDate = new Date();

      var mes = 0;
      let m = 1;

      while (this.debt > 0) {
        if (this.debt < this.inMensual) {
          this.paid += this.debt;
          this.iterations.push({ "inDate": this.itDate.setMonth(this.itDate.getMonth() + 1), "pay": this.debt, "paid": this.paid, "debt": 0 });
          this.debt = 0;
        } else {
          if (m % 12 === 0 && this.debt > (this.inMensual * 2) && this.type == 1) {
            this.paid += (this.inMensual * 2);
            this.debt -= (this.inMensual * 2);
            this.iterations.push({ "inDate": this.itDate.setMonth(this.itDate.getMonth() + 1), "pay": this.inMensual * 2, "paid": this.paid, "debt": this.debt });
          } else if (m % 12 === 0 && this.debt > 12000 && this.type == 2){
            this.paid += 12000;
            this.debt -= 12000;
            this.iterations.push({ "inDate": this.itDate.setMonth(this.itDate.getMonth() + 1), "pay": 12000, "paid": this.paid, "debt": this.debt });
          } else if (m  === 42 && this.debt > 55000 && this.type == 2) {
            this.paid += 55000;
            this.debt -= 55000;
            this.iterations.push({ "inDate": this.itDate.setMonth(this.itDate.getMonth() + 1), "pay": 55000, "paid": this.paid, "debt": this.debt });
          } else {
            this.paid += this.inMensual;
            this.debt -= this.inMensual;
            this.iterations.push({ "inDate": this.itDate.setMonth(this.itDate.getMonth() + 1), "pay": this.inMensual, "paid": this.paid, "debt": this.debt });
          }
        }

        //Promo Xalter
        // if( this.debt < 3000) {
        //   this.paid += this.debt;
        //   this.iterations.push({ "inDate": this.itDate.setMonth(this.itDate.getMonth() + 1), "pay": this.debt, "paid": this.paid, "debt": 0 });
        //   this.debt = 0;
        // } else {
        //   if(m < 60){
        //     mes = 2000;
        //   } else {
        //     mes = 3000;
        //   }
        //   if (m % 12 === 0 && this.debt > (mes*2)) {
        //     this.paid += (mes*2);
        //     this.debt -= (mes*2);
        //     this.iterations.push({ "inDate": this.itDate.setMonth(this.itDate.getMonth() + 1), "pay": (mes*2), "paid": this.paid, "debt": this.debt });
        //   } else {
        //     this.paid += mes;
        //     this.debt -= mes;
        //     this.iterations.push({ "inDate": this.itDate.setMonth(this.itDate.getMonth() + 1), "pay": mes, "paid": this.paid, "debt": this.debt });
        //   }
        // }
        m++;
      }
    }
  }

  diffDate() {
    let diff = Math.abs(this.itDate.getTime() - this.startDate.getTime());
    let years = Math.floor(diff / (1000 * 60 * 60 * 24 * 356.25));
    let months = Math.floor((diff % (1000 * 60 * 60 * 24 * 365.25)) / (1000 * 60 * 60 * 24 * 30.44));
    return `${years} años y ${months} meses`
  }

  printQuotation(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlData')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
  <html>
    <head>
      <title>Cotización de ${this.customer}</title>
      <style>
      * {
        margin: 0;
        padding: 0;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
      }
      body {
        font-family: Roboto, "Helvetica Neue", sans-serif;
      }
      img {
        border: none;
      }

      .quotationCont {
        padding: 30px;
        background-color: white;
      }
      
      .quotationCont p {
        margin: 0;
        padding: 0;
      }
      
      .quotationCont__doc {
        display: flex;
        flex-direction: column;
      }
      
      .quotationCont__doc__header {
        display: flex;
        flex-direction: column;
      }
      
      .quotationCont__doc__header .logos,
      .quotationCont__doc__header .details,
      .quotationCont__doc__header .information {
        display: flex;
        justify-content: space-between;
      }
      
      .quotationCont__doc__header .logos .imgWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
      }
      
      .quotationCont__doc__header .logos .imgWrap img {
        width: 100%;
      }

      .m19Table {
        width: 100%;
        margin: 0.3em;
      }
      
      .m19Table thead {
        background-color: #D33E3E;
        vertical-align: bottom;
      }
      
      .m19Table thead * {
        padding: 0.8em;
      }
      
      .m19Table thead th {
        color: white;
        font-weight: bold;
      }
      
      .m19Table tbody tr {
        height: 50px;
      }
      
      .m19Table tbody tr td {
        padding: 0.5em;
      }
      
      .m19Table tbody tr:nth-child(odd) {
        background-color: #e8ecf0;
      } 

      td {
        page-break-inside: avoid;
      }
      </style>
    </head>
<body onload="window.print();window.close()">${printContents}</body>
  </html>`
    );
    popupWin!.document.close();
  }
}