import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, Form } from '@angular/forms';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { MatDialogRef } from '@angular/material/dialog';
import { ControComissionsService } from 'src/app/services/contro-comissions.service';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import jwtDecode from 'jwt-decode';
import { TouchSequence } from 'selenium-webdriver';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dialog-budget-update',
  templateUrl: './dialog-budget-update.component.html',
  styleUrls: ['./dialog-budget-update.component.scss']
})
export class DialogBudgetUpdateComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DialogBudgetUpdateComponent>,
    private controlCommission: ControComissionsService,
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent
  ) { }

  ngOnInit(): void {
    this.getAllBudgetCommisions();
    this.getUser();
  }


  BudgetFormGroup: FormGroup = this._formBuilder.group({
    idBudgetComission: [null,],
    registrationDate: [, Validators.required],
    quantityPVP: [null],
    quantityPXL: [null],
    quantityPAL: [null],
    quantityPCL: [null],
    quantityPCB: [null],
    quantityPLI: [null],
    quantityPAM: [null],
    quantityPGA: [null],
    quantityPBE: [null],
    quantityPBS: [null],
    quantityPVL: [null],
    userModification: [this.getUser(), Validators.required],
    reasonModification: [null],
    dateModificacion: []
  });

  budgetCommissions: any = [];
  decode: any = [];
  bandera = true;
  dataForUpdate: any = "";
  pipe = new DatePipe('en');
  //Calculo de fecha
  currentDate = new Date();
  hoursMinutesAndSeconds = this.currentDate.toLocaleTimeString()

  getUser() {
    this.decode = jwtDecode(localStorage.getItem("token") || "")
    return this.decode['cliente']
  }

  async getAllBudgetCommisions() {
    this.controlCommission.getAllBudgetCommissions().subscribe(
      res => {
        this.budgetCommissions = res
        console.log("Data → ", this.budgetCommissions)
      }
    )
  }

  async clickInformation(id: number) {

    this.controlCommission.getOneRegisterForUpdateService(id).subscribe(
      res => {
        this.dataForUpdate = res;
        //  console.log("Información para modificar → " , this.dataForUpdate)
        this.bandera = false;
        this.BudgetFormGroup.controls['quantityPAL'].setValue(this.dataForUpdate.quantityPAL)
        this.BudgetFormGroup.controls['quantityPAM'].setValue(this.dataForUpdate.quantityPAM)
        this.BudgetFormGroup.controls['quantityPBE'].setValue(this.dataForUpdate.quantityPBE)
        this.BudgetFormGroup.controls['quantityPBS'].setValue(this.dataForUpdate.quantityPBS)
        this.BudgetFormGroup.controls['quantityPCB'].setValue(this.dataForUpdate.quantityPCB)

        this.BudgetFormGroup.controls['quantityPCL'].setValue(this.dataForUpdate.quantityPCL)
        this.BudgetFormGroup.controls['quantityPGA'].setValue(this.dataForUpdate.quantityPGA)
        this.BudgetFormGroup.controls['quantityPLI'].setValue(this.dataForUpdate.quantityPLI)
        this.BudgetFormGroup.controls['quantityPVP'].setValue(this.dataForUpdate.quantityPVP)
        this.BudgetFormGroup.controls['quantityPVL'].setValue(this.dataForUpdate.quantityPVL)
        this.BudgetFormGroup.controls['quantityPXL'].setValue(this.dataForUpdate.quantityPXL)
        this.BudgetFormGroup.controls['dateModificacion'].setValue(this.pipe.transform(this.currentDate.toISOString(), ('yyyy-MM-dd hh:mm:ss')))
       
      }

    )
    // console.log("Valor que usara como consulta para mostrar → " , id)
  }


  async updateBudgetCommission() {
    if (this.BudgetFormGroup.invalid) {
      return;
    } else {
      Swal.fire({
        title: '¿Quieres guardar tus cambios ahora?',
        text: "¡El presupuesto sera Modificado!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Actualizar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.controlCommission.insertBudgetCommission(this.BudgetFormGroup.value).subscribe(
            res => {
              this.sweet.AlertTime("center", "success", "Presupuesto registrado con éxito", false, 2000);
              this.dialogRef.close();
            }
          );
        }
      })
    }
  }


}
