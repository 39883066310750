import { Component, OnInit } from '@angular/core';
import { Img, PdfMakeWrapper, Txt, Table, Cell, Canvas, Line, Rect } from 'pdfmake-wrapper';
import decode from "jwt-decode";

export interface allProjects_disponibility {
  abonado: number;
  amount: number;
  codeProperty: string;
  customerName: string;
  estInmueble: string;
  estVenta: string;
  fk_salesCatalog: number;
  fk_status: number;
  idProject: number;
  idProperty: number;
  name: string;
  porcentaje: number;
}

@Component({
  selector: 'app-pdf-maker',
  templateUrl: './pdf-maker.component.html',
  styleUrls: ['./pdf-maker.component.scss'],
})
export class PdfMakerComponent implements OnInit {

  usertoken: any = {};

  logos = {
    M19: "https://i.ibb.co/4Wb6bnB/Imagen1.jpg",
    CasaBlanca: "https://i.ibb.co/1XLSPXp/Imagen2.jpg",
    Loteprueba: "https://i.ibb.co/61fVPHt/1.png",
    estrellaIcono: "../assets/estrellaIcon.png",
    isotipo_transparencia: "../assets/isotipo_transparencia.png"
  }
  ticket = {
    Ticket1: "https://i.ibb.co/NSd6VYR/Ticket-modificado.png"
  }

  proyect = 'Proyecto: ';
  urlImage: any

  today: Date = new Date();

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  constructor(
  ) { }

  ngOnInit(): void {
  }

  async genereteRequestAllComponents(
    fecha: String, representante: String,
    desarrollo: String, manzana: String,
    lote: String, nacionalidad: String,
    fullName: String, estadocivil: String,
    fechanacimiento: String, ocupacion: String,
    lugarnacimiento: String, domicilio: String,
    codigopostal: String, colonia: String,
    estado: String, municipio: String,
    celular: String, clavelector: String,
    curp: String, correo: String,
    rfc: String,
    inmueble: String, importetotal: String, anticipo: String,
    mensualidades: String, fechaimportetotal: String, fechaanticipo: String,
    fechaapartirde: String,
    colindancia1: String,
    colindancia2: String,
    colindancia3: String,
    colindancia4: String,
    loteIMG: string
  ) {
    const request = new PdfMakeWrapper();

    //tamaño de la pagina
    request.pageSize({
      width: 595,
      height: 'auto'
    })

    //icono M19
    request.header(
      await new Img(this.logos.CasaBlanca)
        .relativePosition(0, 0)
        .fit([50, 50])


        .build().then(img => {
          request.add(img)
        })

    )

    //icono casa blanca
    request.header(
      await new Img(this.logos.M19)
        .relativePosition(490, 0)
        .fit([50, 50])

        .build().then(img => {
          request.add(img)
        })

    )


    //ENCABEZADO
    request.add(
      new Table([
        ['SOLICITUD DE ELABORACION DE CONTRATO']
      ])
        //tamño de la columna
        .widths('*')
        //posicion
        .relativePosition(0, 60)
        .alignment('center')
        .end
    )

    //FECHA
    request.add(
      new Table([
        ['FECHA: ' + fecha]
      ])
        //tamaño de la columna
        .widths(['*', '390'])
        //posicion
        .relativePosition(0, 90)
        .alignment('left')
        .end
    )


    //PRESTANOMBRES
    request.add(
      new Txt([
        'Por conducto de representante:'
      ])
        //posicion
        .relativePosition(150, 93)
        .alignment('left')
        .end
    )

    //SI
    request.add(
      new Txt([
        'Si'
      ])
        //posicion
        .relativePosition(350, 93)
        .alignment('left')
        .end
    )

    //VACIO
    request.add(
      new Table([
        ['_____']
      ])
        //tamaño de la columna
        .widths(['*', '1000'])
        //posicion
        .relativePosition(365, 90)
        .alignment('left')
        .end
    )

    //NO
    request.add(
      new Txt([
        'No'
      ])
        //posicion
        .relativePosition(444, 93)
        .alignment('left')
        .end
    )

    //VACIO
    request.add(
      new Table([
        ['_____']
      ])
        //tamaño de la columna
        .widths(['*', '1000'])
        //posicion
        .relativePosition(465, 90)
        .alignment('left')
        .end
    )

    //Datos del inmueble
    request.add(
      new Table([
        ['DATOS DEL INMUEBLE']
      ])
        //tamño de la columna
        .widths('*')
        //posicion
        .relativePosition(0, 120)
        .alignment('center')
        .end
    )

    //Datos del inmueble 2 para sombra mayor
    request.add(
      new Table([
        ['DATOS DEL INMUEBLE']
      ])
        //tamño de la columna
        .widths('*')
        //posicion
        .relativePosition(0, 120)
        .alignment('center')
        .end
    )

    //Desarrollo, Manzana, Lote.
    request.add(
      new Table([
        ['DESARROLLO', desarrollo],
        ['MANZANA', manzana],
        ['LOTE', lote]
      ])
        //tamño de la columna
        .widths('*')
        //posicion
        .relativePosition(0, 140)
        .alignment('center')
        .end
    )



    //Datos del cliente
    request.add(
      new Table([
        ['DATOS DEL CLIENTE']
      ])
        //tamño de la columna
        .widths('*')
        //posicion
        .relativePosition(0, 210)
        .alignment('center')
        .end
    )

    //Datos del cliente 2 para una sombra mayor
    request.add(
      new Table([
        ['DATOS DEL CLIENTE']
      ])
        //tamño de la columna
        .widths('*')
        //posicion
        .relativePosition(0, 210)
        .alignment('center')
        .end
    )


    //Nombre completo
    request.add(

      new Table([
        ['NOMBRE COMPLETO']
      ])
        //tamño de la columna
        .width('*')
        //posicion
        .relativePosition(0, 230)
        .alignment('left')
        .end
    )


    //Obtencion del formulario
    request.add(
      new Table([
        [fullName]
      ])
        //tamño de la columna
        .widths(['*', '115'])
        //posicion
        .relativePosition(124, 230)
        .alignment('left')
        .end
    )


    //Nacionalidad
    request.add(

      new Table([
        ['NACIONALIDAD']
      ])
        //tamño de la columna
        .widths(['*', '410'])
        //posicion
        .relativePosition(0, 250)
        .alignment('left')
        .end
    )


    //Obtencion del formulario
    request.add(
      new Table([
        [nacionalidad]
      ])
        //tamño de la columna
        .widths(['*', '343'])
        //posicion
        .relativePosition(95, 250)
        .alignment('center')
        .end
    )

    //ESTADO CIVIL
    request.add(

      new Table([
        ['ESTADO CIVIL']
      ])
        //tamño de la columna
        .widths(['*', '410'])
        //posicion
        .relativePosition(258, 250)
        .alignment('left')
        .end
    )


    //Obtencion del formulario
    request.add(
      new Table([
        [estadocivil]
      ])
        //tamño de la columna
        .widths(['*', '344'])
        //posicion
        .relativePosition(353, 250)
        .alignment('center')
        .end
    )


    //FECHA DE NACIMIENTO
    request.add(
      new Table([
        ['FECHA DE NACIMIENTO']
      ]).noWrap()
        //tamño de la columna
        .width('*')
        //posicion
        .relativePosition(0, 270)
        .alignment('left')
        .end
    )


    //Obtencion del formulario
    request.add(
      new Table([
        [fechanacimiento]
      ])
        //tamño de la columna
        .widths(['*', '387'])
        //posicion
        .relativePosition(139, 270)
        .alignment('center')
        .end
    )

    //OCUPACION
    request.add(

      new Table([
        ['OCUPACION']
      ])
        //tamño de la columna
        .widths(['*', '410'])
        //posicion
        .relativePosition(258, 270)
        .alignment('left')
        .end
    )


    //Obtencion del formulario
    request.add(
      new Table([
        [ocupacion]
      ])
        //tamño de la columna
        .widths(['*', '344'])
        //posicion

        .relativePosition(353, 270)
        .alignment('center')
        .end
    )



    //FECHA DE NACIMIENTO
    request.add(
      new Table([
        ['LUGAR DE NACIMIENTO']
      ])
        //tamño de la columna
        .width('*')
        //posicion
        .relativePosition(0, 289)
        .alignment('left')
        .end
    )


    //Obtencion del formulario
    request.add(
      new Table([
        [lugarnacimiento]
      ])
        //tamño de la columna
        .widths(['*', '130'])
        //posicion
        .relativePosition(139, 289)
        .alignment('center')
        .end
    )


    //DOMICILIO, COLONIA Y MUNICIPIO
    request.add(
      new Table([
        ['DOMICILIO'],
        ['COLONIA'],
        ['MUNICIPIO']
      ]).noWrap()
        //tamño de la columna
        .width('*')
        //posicion
        .relativePosition(0, 308)
        .alignment('left')
        .end
    )

    //CONSULTA DE LA BASE
    request.add(
      new Table([
        [domicilio],
        [colonia],
        [municipio]
      ]).noWrap()
        //tamño de la columna
        .widths(['*', '318'])
        //posicion
        .relativePosition(70, 308)
        .alignment('left')
        .end
    )

    //DOMICILIO, COLONIA Y MUNICIPIO
    request.add(
      new Table([
        ['C.P.'],
        ['ESTADO'],
        ['CELULAR']
      ]).noWrap()
        //tamño de la columna
        .width('*')
        //posicion
        .relativePosition(258, 308)
        .alignment('left')
        .end
    )

    //CONSULTA DE LA BASE
    request.add(
      new Table([
        [codigopostal],
        [codigopostal],
        [celular]
      ]).noWrap()
        //tamño de la columna
        .widths(['*', '308'])
        //posicion
        .relativePosition(317, 308)
        .alignment('left')
        .end
    )


    //Nacionalidad
    request.add(

      new Table([
        ['CLAVE DE ELECTOR']
      ])
        .noWrap()
        //tamño de la columna
        .widths(['*', '410'])
        //posicion
        .relativePosition(0, 365)
        .alignment('left')
        .end
    )


    //Obtencion del formulario
    request.add(
      new Table([
        [clavelector]
      ])
        //tamño de la columna
        .widths(['*', '364'])
        //posicion
        .relativePosition(116, 365)
        .alignment('center')
        .end
    )

    //CURP
    request.add(
      new Table([
        ['CURP']
      ]).noWrap()
        //tamño de la columna
        .widths(['*', '446'])
        //posicion
        .relativePosition(258, 365)
        .alignment('left')
        .end
    )

    //CONSULTA DE LA BASE
    request.add(
      new Table([
        [curp]
      ]).noWrap()
        //tamño de la columna
        .widths(['*', '308'])
        //posicion
        .relativePosition(317, 365)
        .alignment('left')
        .end
    )

    //CORREO
    request.add(

      new Table([
        ['CORREO']
      ])
        .noWrap()
        //tamño de la columna
        .widths(['*', '389'])
        //posicion
        .relativePosition(0, 384)
        .alignment('left')
        .end
    )


    //Obtencion del formulario
    request.add(
      new Table([
        [correo]
      ])
        //tamño de la columna
        .widths(['*', '107'])
        //posicion
        .relativePosition(116, 384)
        .alignment('center')
        .end
    )

    //RFC
    request.add(

      new Table([
        ['RFC']
      ])
        .noWrap()
        //tamño de la columna
        .widths(['*', '389'])
        //posicion
        .relativePosition(0, 403)
        .alignment('left')
        .end
    )


    //Obtencion del formulario
    request.add(
      new Table([
        [rfc]
      ])
        //tamño de la columna
        .widths(['*', '107'])
        //posicion
        .relativePosition(116, 403)
        .alignment('center')
        .end
    )

    //Datos del CONTRATO
    request.add(
      new Table([
        ['DATOS DEL CONTRATO']
      ])
        //tamño de la columna
        .widths('*')
        //posicion
        .relativePosition(0, 440)
        .alignment('center')
        .end
    )

    //Datos del CONTRATO 2 para una sombra mayor
    request.add(
      new Table([
        ['DATOS DEL CONTRATO']
      ])
        //tamño de la columna
        .widths('*')
        //posicion
        .relativePosition(0, 440)
        .alignment('center')
        .end
    )

    //IMPORTES
    request.add(
      new Table([
        ['IMPORTES']
      ])
        //tamño de la columna
        .widths('*')
        //posicion
        .relativePosition(0, 460)
        .alignment('center')
        .end
    )


    //INMUEBLE
    request.add(

      new Table([
        ['INMUEBLE 01']
      ])
        //tamño de la columna
        .widths(['*', '389'])
        //posicion
        .relativePosition(0, 480)
        .alignment('left')
        .end
    )


    //Obtencion del formulario
    request.add(
      new Table([
        [inmueble]
      ])
        //tamño de la columna
        .widths(['*', '107'])
        //posicion
        .relativePosition(116, 480)
        .alignment('left')
        .end
    )

    //IMPORTE TOTAL, ANTICIPO, MENSUALIDADES
    request.add(

      new Table([
        ['IMPORTE TOTAL'],
        ['ANTICIPO'],
        ['MENSUALIDADES']
      ])
        .noWrap()
        //tamño de la columna
        .widths(['*', '410'])
        //posicion
        .relativePosition(0, 500)
        .alignment('left')
        .end
    )


    //Obtencion del formulario
    request.add(
      new Table([
        [importetotal],
        [anticipo],
        [mensualidades]
      ])
        //tamño de la columna
        .widths(['*', '353'])
        //posicion
        .relativePosition(105, 500)
        .alignment('center')
        .end
    )

    //FECHA, FECHA, A PARTIR DE
    request.add(

      new Table([
        ['FECHA'],
        [''],
        ['A PARTIR DE']
      ])
        .noWrap()
        //tamño de la columna
        .widths(['*', '410'])
        //posicion
        .relativePosition(257, 500)
        .alignment('left')
        .end
    )


    //Obtencion del formulario
    request.add(
      new Table([
        [fecha],
        [''],
        [fechaapartirde]
      ])
        //tamño de la columna
        .widths(['*', '343'])
        //posicion
        .relativePosition(352, 500)
        .alignment('center')
        .end
    )

    //COLINDANCIAS
    request.add(
      new Table([
        ['COLINDANCIAS']
      ])
        //tamño de la columna
        .widths('*')
        //posicion
        .relativePosition(0, 570)
        .alignment('center')
        .end
    )

    //DESCRIPCION DE LA COLINDANCIA
    request.add(
      new Table([
        [colindancia1],
        [colindancia2],
        [colindancia3],
        [colindancia4],

      ])
        //tamño de la columna
        .widths(['*', '252'])
        //posicion
        .relativePosition(0, 590)
        .alignment('center')
        .end
    )

    /* con imagenes */
    var myImage = new Image();
    myImage.src = "https://firebasestorage.googleapis.com/v0/b/m19inmobiliaria-f6068.appspot.com/o/Lotificaciones%2FCasa%20Blanca%2Flot_CBM01L01?alt=media&token=89326fe3-9e6b-45e2-9f25-cf407a73dff4"

    request.images({
      picture1: await new Img(myImage.src).build(),
    }
    )
    /*
        request.add(
          await new Img('picture1', true)
            .relativePosition(320, 595)
            .fit([150, 200])
            .build()
        )
    */
    /* con canvas */
    request.add(
      new Canvas([


        // .relativePosition(320, 595)
        // .fit([150, 200])

      ]).end



    )

    request.add(
      new Canvas([
        new Rect([254, 590], [261, 133]).end,
        new Line([100, 790], [430, 790]).end
      ]).end
    );

    request.add(
      new Table([
        ['NOMBRE Y FIRMA DEL SOLICITANTE']
      ])
        //tamño de la columna
        .widths(['*', '252'])
        //posicion
        .relativePosition(140, 15)
        .layout('noBorders')
        .alignment('center')
        .end
    )





    request.create().open();
  }

  async ticketGeneratorBeta1(fecha: string, vendedor: string, folio: string, manzana: string,
    lote: string, concepto: string, ForPago: string, valTot: string, abonado: string, adeudo: string,
    PRealizados: string, PTotales: string, atendido: string, proyec: string) {
    const request = new PdfMakeWrapper();

    /*NOTAS:
    -6 PIXELES SON SUFICIENTES PARA UN ESPACIO
    -19 pixeles para un salto entre renglón
    -Cada renglón comienza en el pixel 119

    */
    request.pageMargins([40, 60, 40, 60]);

    request.defaultStyle({
      //bold: true,
      fontSize: 15
    });

    /*request.pageSize({
      width: '0',
      height: '0'
    })*/

    request.background(request.add(
      await new Img(this.ticket.Ticket1)
        .fit([800, 780])
        .alignment('center')
        .build().then(img => {
          request.add(img)
        })));

    request.add( //Fecha y hora
      new Txt([
        fecha
      ])
        .absolutePosition(119, 262)
        .alignment('left')
        .end)

    request.add( //Fecha y hora
      new Txt([
        atendido
      ])
        .absolutePosition(216, 281)
        .alignment('left')
        .end)

    request.add( //Vendedor
      new Txt([
        vendedor
      ])
        .absolutePosition(196, 300)
        .alignment('left')
        .end)

    request.add( //Folio
      new Txt([
        folio
      ])
        .absolutePosition(161, 319)
        .alignment('left')
        .end)

    request.add( //Manzana
      new Txt([
        manzana
      ])
        .absolutePosition(213, 339.5)
        .alignment('left')
        .end)

    request.add( //Lote
      new Txt([
        lote
      ])
        .absolutePosition(306, 339.5)
        .alignment('left')
        .end)

    this.compararZona(proyec)

    request.add(
      new Txt([
        this.proyect
      ]).absolutePosition(119, 358.5)
        .alignment('left')
        .end)

    request.add( //concepto
      new Txt([
        concepto
      ])
        .absolutePosition(194, 420)
        .alignment('left')
        .end)

    request.add( //FORMA DE PAGO
      new Txt([
        ForPago
      ])
        .absolutePosition(228, 439)
        .alignment('left')
        .end)

    request.add( //VALOR TOTAL
      new Txt([
        valTot
      ])
        .absolutePosition(207, 478)
        .alignment('left')
        .end)

    request.add( //ABONADO
      new Txt([
        abonado
      ])
        .absolutePosition(217, 499)
        .alignment('left')
        .bold()
        .fontSize(18)
        .end)

    request.add( //Saldo pendiente
      new Txt([
        adeudo
      ])
        .absolutePosition(246, 547)
        .alignment('left')
        .end)

    request.add( //pagos realizados
      new Txt([

      ])
        .absolutePosition(241, 646)
        .alignment('left')
        .end)

    request.create().open();
  }

  async compararZona(Zona: string) { //Utilizado para rellenar el proyecto al que pertenece el ticket
    switch (Zona) {
      case 'AL':
        this.proyect = 'Altares'
        break;
      case 'XL':
        this.proyect = 'Xalta'
        break;
      case 'CB':
        this.proyect = 'Casa Blanca'
        break;
      case 'CL':
        this.proyect = 'Colomos'
        break;
      case 'LI':
        this.proyect = 'Liverti'
        break;
      case 'VP':
        this.proyect = 'Valle de las palomas'
        break;
      case 'AM':
        this.proyect = 'Altamar'
        break;
      case 'GA':
        this.proyect = 'Gamta'
        break;
      case 'BE':
        this.proyect = 'Benanta'
        break;
      case 'BS':
        this.proyect = 'Bosco'
        break;
      case 'VL':
        this.proyect = 'Valle de las palomas 2'
        break;
      case 'AT':
        this.proyect = 'Altares Departamentos'
        break;
      case 'XR':
        this.proyect = 'Xalter'
        break;
      default:
        window.alert("Advertencia: PROYECTO NO REGISTRADO")
        break;
    }
  }

  async ticketGeneratorComissiones(date: string, invoice: string, project: string, block: string, aLot: string,
    me: string, receivedFrom: string, amount: string, concept: string) {

    const request = new PdfMakeWrapper();

    request.pageMargins([10, 10, 10, 10]);

    //tamño del documento del ticket
    request.pageSize({
      width: 210,
      height: 380
    })

    //estilo por defecto
    request.defaultStyle({
      fontSize: 15
    });


    request.add(
      new Txt([
        "Recibo de comisiones"
      ])
        //posicion
        .relativePosition(0, 0)
        .alignment('center')
        .fontSize(15)
        .end
    )

    request.add(
      await new Img(this.logos.M19)
        .relativePosition(0, 30)
        .fit([40, 40])
        .build().then(img => {
          request.add(img)
        })
    )

    request.add(
      new Txt([
        "Fecha: "
      ])
        //posicion
        .relativePosition(80, 35)
        .alignment('left')
        .fontSize(11)
        .end
    )

    request.add(
      new Txt([
        date
      ])
        //posicion
        .relativePosition(0, 35)
        .alignment('right')
        .fontSize(11)
        .end
    )

    request.add(
      new Txt([
        "Folio: "
      ])
        //posicion
        .relativePosition(80, 55)
        .alignment('left')
        .fontSize(11)
        .end
    )

    request.add(
      new Txt([
        invoice
      ])
        //posicion
        .relativePosition(0, 55)
        .alignment('right')
        .fontSize(11)
        .end
    )

    request.add(
      new Txt([
        "Proyecto:_____________________________"
      ])
        //posicion
        .relativePosition(0, 95)
        .alignment('left')
        .fontSize(11)
        .end
    )

    request.add(
      new Txt([
        project
      ])
        //posicion
        .relativePosition(70, 94)
        .alignment('left')
        .fontSize(11)
        .end
    )

    request.add(
      new Txt([
        "Manzana:__________"
      ])
        //posicion
        .relativePosition(0, 120)
        .alignment('left')
        .fontSize(11)
        .end
    )

    request.add(
      new Txt([
        block
      ])
        //posicion
        .relativePosition(67, 119)
        .alignment('left')
        .fontSize(11)
        .end
    )

    request.add(
      new Txt([
        "Lote:_____________"
      ])
        //posicion
        .relativePosition(101, 120)
        .alignment('left')
        .fontSize(11)
        .end
    )

    request.add(
      new Txt([
        aLot
      ])
        //posicion
        .relativePosition(150, 119)
        .alignment('left')
        .fontSize(11)
        .end
    )

    request.add(
      new Txt([
        "Yo:___________________________________"
      ])
        //posicion
        .relativePosition(0, 140)
        .alignment('left')
        .fontSize(11)
        .end
    )

    request.add(
      new Txt([
        me
      ])
        //posicion
        .relativePosition(20, 139)
        .alignment('left')
        .fontSize(10)
        .end
    )

    request.add(
      new Txt([
        "Recibi de:____________________________"
      ])
        //posicion
        .relativePosition(0, 160)
        .alignment('left')
        .fontSize(11)
        .end
    )

    request.add(
      new Txt([
        receivedFrom
      ])
        //posicion
        .relativePosition(50, 159)
        .alignment('left')
        .fontSize(10)
        .end
    )

    request.add(
      new Txt([
        "La cantidad de:_______________________"
      ])
        //posicion
        .relativePosition(0, 185)
        .alignment('left')
        .fontSize(11)
        .end
    )

    request.add(
      new Txt([
        amount
      ])
        //posicion
        .relativePosition(100, 184)
        .alignment('left')
        .fontSize(11)
        .end
    )

    request.add(
      new Txt([
        "Por motivo del pago de mis comisiones con concepto de "
      ])
        //posicion
        .relativePosition(0, 205)
        .alignment('center')
        .fontSize(9)
        .bold()
        .italics()
        .end
    )


    request.add(
      new Txt([
        concept
      ])
        //posicion
        .relativePosition(0, 250)
        .alignment('center')
        .fontSize(11)
        .bold()
        .italics()
        .end
    )


    request.add(
      new Table([
        [' X ']
      ])
        //tamaño de la columna
        //.widths(['auto', '10000'])
        .width(10)
        //posicion
        .relativePosition(130, 247)
        .alignment('center')
        .fontSize(10)
        .bold()
        .italics()
        .end
    )


    request.add(
      new Txt([
        "____________________"
      ])
        //posicion
        .relativePosition(0, 290)
        .alignment('left')
        .fontSize(11)
        .bold()
        .italics()
        .end
    )


    request.add(
      new Txt([
        "Entregue Conforme"
      ])
        //posicion
        .relativePosition(0, 305)
        .alignment('left')
        .fontSize(11)
        .bold()
        .italics()
        .end
    )


    request.add(
      new Txt([
        "_________________"
      ])
        //posicion
        .relativePosition(0, 290)
        .alignment('right')
        .fontSize(11)
        .bold()
        .italics()
        .end
    )

    request.add(
      new Txt([
        "Recibí conforme"
      ])
        //posicion
        .relativePosition(0, 305)
        .alignment('right')
        .fontSize(11)
        .bold()
        .italics()
        .end
    )

    request.create().print();

  }

  async ticketGeneradorCashOutFlows(folio: string, date: string, cashManager: string, quantity: string, requester: string, reason: string, date2: string) {
    /*NOTAS:
   -6 PIXELES SON SUFICIENTES PARA UN ESPACIO
   -19 pixeles para un salto entre renglón
   -Cada renglón comienza en el pixel 119

   */

    const request = new PdfMakeWrapper();

    request.pageMargins([10, 10, 10, 10]);

    //tamño del documento del ticket
    request.pageSize({
      width: 210,
      height: 380
    })

    //estilo por defecto
    request.defaultStyle({
      fontSize: 15
    });

    //tamño en pixeles
    request.pageSize({
      width: 210,
      height: 850
    });

    request.add(
      new Txt([
        "Comprobante de solicitud de efectivo"
      ])
        //posicion
        .relativePosition(0, 0)
        .alignment('center')
        .fontSize(14)
        .bold()
        .end
    )

    request.add(
      await new Img(this.logos.M19)
        .relativePosition(0, 30)
        .fit([40, 40])
        .build().then(img => {
          request.add(img)
        })
    )

    request.add(
      new Txt([
        "Fecha salida: "
      ])
        //posicion
        .relativePosition(45, 55)
        .alignment('left')
        .fontSize(13)
        .bold()
        .end
    )

    request.add(
      new Txt([
        date
      ])
        //posicion
        .relativePosition(0, 55)
        .alignment('right')
        .fontSize(13)
        .end
    )

    request.add(
      new Txt([
        "Fecha de impresión: "
      ])
        //posicion
        .relativePosition(5, 75)
        .alignment('left')
        .fontSize(13)
        .bold()
        .end
    )

    request.add(
      new Txt([
        date2
      ])
        //posicion
        .relativePosition(0, 75)
        .alignment('right')
        .fontSize(13)
        .end
    )

    /*
        request.add(
          new Txt([
            "Folio: "
          ])
            //posicion
            .relativePosition(80, 75)
            .alignment('left')
            .fontSize(13)
            .bold()
            .end
        )

        request.add(
          new Txt([
            idTicket
          ])
            //posicion
            .relativePosition(0, 75)
            .alignment('right')
            .fontSize(13)
            .end
        )
    */

    request.add(
      new Txt([
        "Yo: "
      ])
        .relativePosition(0, 105)
        .alignment("left")
        .fontSize(13)
        .end
    )

    request.add(
      new Txt([
        cashManager
      ])
        .relativePosition(25, 105)
        .alignment("left")
        .fontSize(12)
        .bold()
        .end
    )

    request.add(
      new Txt([
        "_____________________________"
      ])
        .relativePosition(20, 108)
        .alignment("left")
        .fontSize(13)
        .end
    )

    request.add(
      new Txt([
        "Entrego la cantidad de: "
      ])
        .relativePosition(0, 125)
        .alignment("left")
        .fontSize(13)
        .end
    )

    request.add(
      new Txt([
        "$" + quantity
      ])
        .relativePosition(-5, 125)
        .alignment("right")
        .fontSize(12)
        .bold()
        .end
    )

    request.add(
      new Txt([
        "_________"
      ])
        .relativePosition(0, 126)
        .alignment("right")
        .fontSize(13)
        .bold()
        .end
    )

    request.add(
      new Txt([
        "A: "
      ])
        .relativePosition(0, 145)
        .alignment("left")
        .fontSize(13)
        .end
    )

    request.add(
      new Txt([
        requester
      ])
        .relativePosition(20, 144)
        .alignment("left")
        .fontSize(12)
        .bold()
        .end
    )

    request.add(
      new Txt([
        "______________________________"
      ])
        .relativePosition(15, 145)
        .alignment("left")
        .fontSize(13)
        .bold()
        .end
    )

    request.add(
      new Txt([
        "$" + quantity
      ])
        .relativePosition(-5, 125)
        .alignment("right")
        .fontSize(12)
        .bold()
        .end
    )

    request.add(
      new Txt([
        "_________"
      ])
        .relativePosition(0, 126)
        .alignment("right")
        .fontSize(13)
        .bold()
        .end
    )

    request.add(
      new Txt([
        "A: "
      ])
        .relativePosition(0, 145)
        .alignment("left")
        .fontSize(13)
        .end
    )

    request.add(
      new Txt([
        requester
      ])
        .relativePosition(20, 144)
        .alignment("left")
        .fontSize(12)
        .bold()
        .end
    )

    request.add(
      new Txt([
        "______________________________"
      ])
        .relativePosition(15, 145)
        .alignment("left")
        .fontSize(13)
        .bold()
        .end
    )

    request.add(
      new Txt([
        "Por el concepto de: "
      ])
        .relativePosition(0, 170)
        .alignment("left")
        .fontSize(13)
        .bold()
        .end
    )

    request.add(
      new Txt([
        reason
      ])
        .relativePosition(0, 185)
        .alignment("left")
        .fontSize(12)
        .end
    )

    request.add(
      new Txt([
        "Entrega"
      ])
        //posicion
        .relativePosition(25, 305)
        .alignment('left')
        .fontSize(11)
        .bold()
        .italics()
        .end
    )

    request.add(
      new Txt([
        cashManager
      ])
        //posicion
        .relativePosition(5, 320)
        .alignment('left')
        .fontSize(7)
        .bold()
        .italics()
        .end
    )

    request.add(
      new Txt([
        "___________________"
      ])
        //posicion
        .relativePosition(0, 290)
        .alignment('left')
        .fontSize(11)
        .bold()
        .italics()
        .end
    )


    request.add(
      new Txt([
        "Recibe"
      ])
        //posicion
        .relativePosition(-25, 305)
        .alignment('right')
        .fontSize(11)
        .bold()
        .italics()
        .end
    )

    request.add(
      new Txt([
        "_________________"
      ])
        //posicion
        .relativePosition(0, 290)
        .alignment('right')
        .fontSize(11)
        .bold()
        .italics()
        .end
    )

    request.add(
      new Txt([
        requester
      ])
        //posicion
        .relativePosition(5, 320)
        .alignment('right')
        .fontSize(7)
        .bold()
        .italics()
        .end
    )

    request.create().print();
  }

  async ticketReimpresionCashFlows(date: string, cashManager: string, quantity: string, requester: string, reason: string, date2: string) {
    /*NOTAS:
   -6 PIXELES SON SUFICIENTES PARA UN ESPACIO
   -19 pixeles para un salto entre renglón
   -Cada renglón comienza en el pixel 119

   */

    const request = new PdfMakeWrapper();

    request.pageMargins([10, 10, 10, 10]);

    //tamño del documento del ticket
    request.pageSize({
      width: 210,
      height: 380
    })

    //estilo por defecto
    request.defaultStyle({
      fontSize: 15
    });

    //tamño en pixeles
    request.pageSize({
      width: 210,
      height: 850
    });

    request.add(
      new Txt([
        "Comprobante de solicitud de efectivo"
      ])
        //posicion
        .relativePosition(0, 0)
        .alignment('center')
        .fontSize(14)
        .bold()
        .end
    )

    request.add(
      await new Img(this.logos.M19)
        .relativePosition(0, 30)
        .fit([40, 40])
        .build().then(img => {
          request.add(img)
        })
    )

    request.add(
      new Txt([
        "Fecha salida: "
      ])
        //posicion
        .relativePosition(45, 55)
        .alignment('left')
        .fontSize(13)
        .bold()
        .end
    )

    request.add(
      new Txt([
        date
      ])
        //posicion
        .relativePosition(0, 55)
        .alignment('right')
        .fontSize(13)
        .end
    )

    request.add(
      new Txt([
        "Fecha reimpresión: "
      ])
        //posicion
        .relativePosition(5, 75)
        .alignment('left')
        .fontSize(13)
        .bold()
        .end
    )

    request.add(
      new Txt([
        date2
      ])
        //posicion
        .relativePosition(0, 75)
        .alignment('right')
        .fontSize(13)
        .end
    )

    /*
        request.add(
          new Txt([
            "Folio: "
          ])
            //posicion
            .relativePosition(80, 75)
            .alignment('left')
            .fontSize(13)
            .bold()
            .end
        )

        request.add(
          new Txt([
            idTicket
          ])
            //posicion
            .relativePosition(0, 75)
            .alignment('right')
            .fontSize(13)
            .end
        )
    */

    request.add(
      new Txt([
        "Yo: "
      ])
        .relativePosition(0, 105)
        .alignment("left")
        .fontSize(13)
        .end
    )

    request.add(
      new Txt([
        cashManager
      ])
        .relativePosition(25, 105)
        .alignment("left")
        .fontSize(12)
        .bold()
        .end
    )

    request.add(
      new Txt([
        "_____________________________"
      ])
        .relativePosition(20, 108)
        .alignment("left")
        .fontSize(13)
        .end
    )

    request.add(
      new Txt([
        "Entrego la cantidad de: "
      ])
        .relativePosition(0, 125)
        .alignment("left")
        .fontSize(13)
        .end
    )

    request.add(
      new Txt([
        "$" + quantity
      ])
        .relativePosition(-5, 125)
        .alignment("right")
        .fontSize(12)
        .bold()
        .end
    )

    request.add(
      new Txt([
        "_________"
      ])
        .relativePosition(0, 126)
        .alignment("right")
        .fontSize(13)
        .bold()
        .end
    )

    request.add(
      new Txt([
        "A: "
      ])
        .relativePosition(0, 145)
        .alignment("left")
        .fontSize(13)
        .end
    )

    request.add(
      new Txt([
        requester
      ])
        .relativePosition(20, 144)
        .alignment("left")
        .fontSize(12)
        .bold()
        .end
    )

    request.add(
      new Txt([
        "______________________________"
      ])
        .relativePosition(15, 145)
        .alignment("left")
        .fontSize(13)
        .bold()
        .end
    )

    request.add(
      new Txt([
        "Por el concepto de: "
      ])
        .relativePosition(0, 170)
        .alignment("left")
        .fontSize(13)
        .bold()
        .end
    )

    request.add(
      new Txt([
        reason
      ])
        .relativePosition(0, 185)
        .alignment("left")
        .fontSize(12)
        .end
    )

    request.add(
      new Txt([
        "Entregó"
      ])
        //posicion
        .relativePosition(25, 305)
        .alignment('left')
        .fontSize(11)
        .bold()
        .italics()
        .end
    )

    request.add(
      new Txt([
        cashManager
      ])
        //posicion
        .relativePosition(5, 320)
        .alignment('left')
        .fontSize(7)
        .bold()
        .italics()
        .end
    )

    request.add(
      new Txt([
        "___________________"
      ])
        //posicion
        .relativePosition(0, 290)
        .alignment('left')
        .fontSize(11)
        .bold()
        .italics()
        .end
    )


    request.add(
      new Txt([
        "Recibió"
      ])
        //posicion
        .relativePosition(-25, 305)
        .alignment('right')
        .fontSize(11)
        .bold()
        .italics()
        .end
    )

    request.add(
      new Txt([
        "_________________"
      ])
        //posicion
        .relativePosition(0, 290)
        .alignment('right')
        .fontSize(11)
        .bold()
        .italics()
        .end
    )

    request.add(
      new Txt([
        requester
      ])
        //posicion
        .relativePosition(5, 320)
        .alignment('right')
        .fontSize(7)
        .bold()
        .italics()
        .end
    )

    request.create().print();
  }

  async ticketGenerator(fecha: string, vendedor: string, folio: string, tipoA: string, tipoB: string, manzana: string,
    lote: string, concepto: string, ForPago: string, valTot: number, abonado: number, adeudo: number,
    PRealizados: string, PTotales: string, proyec: string, cliente: string, Responsable: string, ncTotal: number, ncnrTotal: number,
    abTot: number, desfase: any, proxPay: any) {
    const request = new PdfMakeWrapper();
    request.info({
      title: proyec + tipoA + manzana + tipoB + lote + " " + cliente + " " + (fecha.split("-")[2] + "-" + fecha.split("-")[1] + "-" + fecha.split("-")[0]),
      author: 'M19 Inmobiliaria'
    });
    /*NOTAS:
    -6 PIXELES SON SUFICIENTES PARA UN ESPACIO
    -19 pixeles para un salto entre renglón
    -Cada renglón comienza en el pixel 119

    */
    request.pageMargins([10, 10, 10, 10]);

    //estilo por defecto
    request.defaultStyle({
      fontSize: 15
    });

    //tamño en pixeles
    request.pageSize({
      width: 210,
      height: 'auto'
    });

    var line = 0;
    
    //marca de awa
    request.add(
      await new Img(this.logos.isotipo_transparencia)
        .relativePosition(0, 130)
        .fit([184, 184])
        .build().then(img => {
          request.add(img)
        })
    )

    request.add( //M19
      new Txt([
        "M19"
      ])
        .relativePosition(0, line)
        .alignment("center")
        .fontSize(30)
        .end)

        line += 30;

    request.add( //inmobiliaria
      new Txt([
        "INMOBILIARIA"
      ])
        .relativePosition(0, line)
        .alignment("center")
        .fontSize(12)
        .end)

        line += 30;

    request.add( //inmobiliaria
      new Txt([
        "PORTUGAL 67A, CD DEL VALLE"
      ])
        .relativePosition(0, line)
        .alignment("center")
        .fontSize(8)
        .end)

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        "63157 TEPIC, NAY. MEXICO"
      ])
        .relativePosition(0, line)
        .alignment("center")
        .fontSize(8)
        .end)

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        "TEL: 311 300 5622"
      ])
        .relativePosition(0, line)
        .alignment("center")
        .fontSize(8)
        .end)

      line += 20;

    request.add( //inmobiliaria
      new Txt([
        "FECHA: "
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        fecha
      ])
        .relativePosition(30, line)
        .alignment("left")
        .fontSize(8)
        .end)

      line += 10;

    request.add( //inmobiliaria
      new Txt([
        Responsable === null ? "REIMPRESO POR: " : "ATENDIDO POR: "
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        Responsable === null ? this.getUser() : Responsable
      ])
        .relativePosition(60, line)
        .alignment("left")
        .fontSize(8)
        .end)

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        tipoA == "M" ? "MANZANA: " : tipoA == "T" ? "TORRE: " : "MANZANA: "
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        manzana
      ])
        .relativePosition(45, line)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        tipoB == "L" ? "LOTE: " : tipoB == "D" ? "DPTO: " : "LOTE: "
      ])
        .relativePosition(80, line)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        lote
      ])
        .relativePosition(105, line)
        .alignment("left")
        .fontSize(8)
        .end)

    this.compararZona(proyec)

    line += 10;

    request.add( //inmobiliaria
      new Txt([
        "PROYECTO: "
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        this.proyect.toUpperCase()
      ])
        .relativePosition(45, line)
        .alignment("left")
        .fontSize(8)
        .end)

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        "-----------------------------------------------------------------------------------"
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        "CONCEPTO: "
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        concepto.toUpperCase()
      ])
        .relativePosition(45, line)
        .alignment("left")
        .fontSize(8)
        .end)

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        "FORMA DE PAGO: "
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        ForPago.toUpperCase()
      ])
        .relativePosition(65, line)
        .alignment("left")
        .fontSize(8)
        .end)

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        "FOLIO DEL TICKET: "
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        folio
      ])
        .relativePosition(70, line)
        .alignment("left")
        .fontSize(8)
        .end)

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        "-----------------------------------------------------------------------------------"
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        "VENDEDOR:"
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        vendedor.toUpperCase()
      ])
        .relativePosition(45, line)
        .alignment("left")
        .fontSize(8)
        .end)

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        "CLIENTE:"
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        cliente.toUpperCase()
      ])
        .relativePosition(45, line)
        .alignment("left")
        .fontSize(8)
        .end)

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        "-----------------------------------------------------------------------------------"
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        "ABONADO:"
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        '$ ' + (Math.trunc(abonado)).toLocaleString("en") + '' + ((abonado - Math.trunc(abonado)).toFixed(2)).substring(1)
      ])
      .relativePosition(-68, line)
      .alignment("right")
      .fontSize(8)
      .end)

      line += 10;

    request.add( //inmobiliaria
      new Txt([
        "-----------------------------------------------------------------------------------"
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        "VALOR TOTAL:"
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add(
      new Txt([
        "$"
      ])
        .relativePosition(70, line)
        .alignment("left")
        .fontSize(8)
        .end
    )

    request.add( //inmobiliaria
      new Txt([
        (Math.trunc(valTot)).toLocaleString("en") + '' + ((valTot - Math.trunc(valTot)).toFixed(2)).substring(1)
      ])
        .relativePosition(-68, line)
        .alignment("right")
        .fontSize(8)
        .end)

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        "ABONADO TOTAL:"
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add(
      new Txt([
        "$"
      ])
        .relativePosition(70, line)
        .alignment("left")
        .fontSize(8)
        .end
    )

    request.add( //inmobiliaria
      new Txt([
        (Math.trunc(abTot + ncTotal + ncnrTotal)).toLocaleString("en") + '' + (((abTot + ncTotal + ncnrTotal) - Math.trunc(abTot + ncTotal + ncnrTotal)).toFixed(2)).substring(1)
      ])
        .relativePosition(-68, line)
        .alignment("right")
        .fontSize(8)
        .end)

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        "RESTANTE:"
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add(
      new Txt([
        "$"
      ])
        .relativePosition(70, line)
        .alignment("left")
        .fontSize(8)
        .end
    )

    request.add( //inmobiliaria
      new Txt([
        (Math.trunc(adeudo - (ncTotal + ncnrTotal))).toLocaleString("en") + '' + (((adeudo - (ncTotal + ncnrTotal)) - Math.trunc(adeudo - (ncTotal + ncnrTotal))).toFixed(2)).substring(1)
      ])
        .relativePosition(-68, line)
        .alignment("right")
        .fontSize(8)
        .end)

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        "-----------------------------------------------------------------------------------"
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)


        line += 10;

    request.add( //inmobiliaria
      new Txt([
        "PAGOS REALIZADOS:   "
      ])
        .relativePosition(0, line)
        .alignment("left")
        .fontSize(8)
        .end)
      console.log("realizado", PRealizados);
    request.add( //inmobiliaria
      new Txt([
        (Math.floor(parseFloat(PRealizados)*100)/100)+""
      ])
        .relativePosition(80, line)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "DE:   "
      ])
        .relativePosition(105, line)
        .alignment("left")
        .fontSize(8)
        .end)
    request.add( //inmobiliaria
      new Txt([
        parseFloat(PTotales).toFixed(2)
      ])
        .relativePosition(125, line)
        .alignment("left")
        .fontSize(8)
        .end)

    // request.add( //inmobiliaria
    //   new Txt([
    //     "ESTADO AL PAGO:"
    //   ])
    //     .relativePosition(0, 290)
    //     .alignment("left")
    //     .fontSize(8)
    //     .end)

    // request.add( //inmobiliaria
    //   new Txt([
    //     desfase
    //   ])
    //     .relativePosition(105, 290)
    //     .alignment("left")
    //     .fontSize(8)
    //     .end)

    /*    request.add( //inmobiliaria
      new Txt([
        PRealizados==PTotales?"FINIQUITADO":"PRÓXIMA FECHA DE PAGO:"
      ])
        .relativePosition(0, 300)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        PRealizados==PTotales?"":proxPay
      ])
        .relativePosition(105, 300)
        .alignment("left")
        .fontSize(8)
        .end)*/

        line += 30;

    request.add( //inmobiliaria
      new Txt([
        '"Tu hogar, nuestro sueño"'
      ])
        .relativePosition(0, line)
        .alignment("center")
        .fontSize(8)
        .italics()
        .end)

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        'Solicite consultar su estado de cuenta en línea'
      ])
        .relativePosition(0, line)
        .alignment("center")
        .fontSize(7)
        .end);

        line += 10;

    request.add( //inmobiliaria
      new Txt([
        'Conoce todos nuestros desarrollos en www.m19inmobiliaria.com'
      ])
        .relativePosition(0, line)
        .alignment("center")
        .fontSize(7)
        .end)




    if (((this.today.getMonth() + 1) == 12 && this.today.getDate() >= 15) || ((this.today.getMonth() + 1) == 1 && this.today.getDate() <= 10)) {
      line += 20;

      request.add(
        await new Img(this.logos.estrellaIcono)
          .relativePosition(80, line)
          .fit([25, 25])
          .build().then(img => {
            request.add(img)
          })
      )

      line += 30;

      request.add( //inmobiliaria
        new Txt([
          'Te deseamos felices fiestas de parte de todo el equipo de M19 Inmobiliaria'
        ])
          .relativePosition(0, line)
          .alignment("center")
          .fontSize(8)
          .end)
    }
    
    request.add(
      new Canvas([
        new Line([0, 260], [125, 260]).end
      ]).end
    );
    request.create().download(proyec + tipoA + manzana + tipoB + lote + " " + cliente + " " + (fecha.split("-")[2] + "-" + fecha.split("-")[1] + "-" + fecha.split("-")[0]));
  }

  async investmentTicket() {
    const request = new PdfMakeWrapper();
    // request.info({
    //   title: proyec + tipoA + manzana + tipoB + lote + " " + cliente + " " + (fecha.split("-")[2] + "-" + fecha.split("-")[1] + "-" + fecha.split("-")[0]),
    //   author: 'M19 Inmobiliaria'
    // });
    /*NOTAS:
    -6 PIXELES SON SUFICIENTES PARA UN ESPACIO
    -19 pixeles para un salto entre renglón
    -Cada renglón comienza en el pixel 119

    */
    request.pageMargins([10, 0, 10, 10]);

    //estilo por defecto
    request.defaultStyle({
      fontSize: 15
    });

    //tamño en pixeles
    request.pageSize({
      width: 210,
      height: 850
    });

    request.add( //inmobiliaria
      new Txt([
        "RECIBO DE RETORNO DE INVERSION"
      ])
        .relativePosition(0, 15)
        .alignment("center")
        .fontSize(12)
        .end)

    request.add(
      await new Img(this.logos.M19)
        .relativePosition(0, 50)
        .fit([40, 40])
        .build().then(img => {
          request.add(img)
        })
    )

    request.add( //inmobiliaria
      new Txt([
        "M19 INMOBILIARIA"
      ])
        .relativePosition(45, 50)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "GENOVA 28A, CD DEL VALLE"
      ])
        .relativePosition(45, 60)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "63157 TEPIC, NAY. MEXICO"
      ])
        .relativePosition(45, 70)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "TEL: 311 300 5622"
      ])
        .relativePosition(45, 80)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "---------------------------------------------------------------------"
      ])
        .relativePosition(0, 100)
        .alignment("left")
        .fontSize(8).bold()
        .end)

    request.add( //inmobiliaria
      new Txt([
        "DATOS DE LA INVERSION"
      ])
        .relativePosition(0, 110)
        .alignment("center")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "---------------------------------------------------------------------"
      ])
        .relativePosition(0, 120)
        .alignment("left")
        .fontSize(8).bold()
        .end)

    request.add( //inmobiliaria
      new Txt([
        "INVERSOR:"
      ])
        .relativePosition(0, 130)
        .alignment("left")
        .fontSize(8).bold()
        .end)

    request.add( //inmobiliaria
      new Txt([
        "MARIA ISABEL AGUIRRE TIRADO"
      ])
        .relativePosition(40, 130)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "MONTO INVERTIDO:"
      ])
        .relativePosition(0, 140)
        .alignment("left")
        .fontSize(8).bold()
        .end)

    request.add( //inmobiliaria
      new Txt([
        "$250,000.00"
      ])
        .relativePosition(75, 140)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "TASA DE RENDIMIENTO:"
      ])
        .relativePosition(0, 150)
        .alignment("left")
        .fontSize(8).bold()
        .end)

    request.add( //inmobiliaria
      new Txt([
        "50%"
      ])
        .relativePosition(90, 150)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "PERIODO:"
      ])
        .relativePosition(0, 160)
        .alignment("left")
        .fontSize(8).bold()
        .end)

    request.add( //inmobiliaria
      new Txt([
        "18 MESES"
      ])
        .relativePosition(35, 160)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "---------------------------------------------------------------------"
      ])
        .relativePosition(0, 170)
        .alignment("left")
        .fontSize(8).bold()
        .end)

    request.add( //inmobiliaria
      new Txt([
        "17/02/2023"
      ])
        .relativePosition(0, 180)
        .alignment("center")
        .fontSize(8).bold()
        .end)

    request.add( //inmobiliaria
      new Txt([
        "RENDIMIENTO"
      ])
        .relativePosition(-50, 190)
        .alignment("center")
        .fontSize(8).bold()
        .end)

    request.add( //inmobiliaria
      new Txt([
        "$20,833.00"
      ])
        .relativePosition(-50, 200)
        .alignment("center")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "R1"
      ])
        .relativePosition(0, 190)
        .alignment("center")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "1 DE 18"
      ])
        .relativePosition(0, 200)
        .alignment("center")
        .fontSize(8).bold()
        .end)

    request.add( //inmobiliaria
      new Txt([
        "REND. TOTAL"
      ])
        .relativePosition(50, 190)
        .alignment("center")
        .fontSize(8).bold()
        .end)

    request.add( //inmobiliaria
      new Txt([
        "$ 20,833"
      ])
        .relativePosition(50, 200)
        .alignment("center")
        .fontSize(8).bold()
        .end)

    request.add(
      new Txt([
        "____________________"
      ])
        //posicion
        .relativePosition(-50, 235)
        .alignment('center')
        .fontSize(11)
        .bold()
        .italics()
        .end
    )


    request.add(
      new Txt([
        "ENTREGUE CONFORME"
      ])
        //posicion
        .relativePosition(-50, 250)
        .alignment('center')
        .fontSize(8)
        .bold()
        .italics()
        .end
    )


    request.add(
      new Txt([
        "_________________"
      ])
        //posicion
        .relativePosition(50, 235)
        .alignment('center')
        .fontSize(11)
        .bold()
        .italics()
        .end
    )

    request.add(
      new Txt([
        "RECIBI CONFORME"
      ])
        //posicion
        .relativePosition(50, 250)
        .alignment('center')
        .fontSize(8)
        .bold()
        .italics()
        .end
    )

    request.create().print();
  }

  async ticketNCGenerator(fecha: string, vendedor: string, folio: string, tipoA: string, tipoB: string, manzana: string,
    lote: string, concepto: string, ForPago: string, abonado: string, proyec: string,
    cliente: string, Responsable: string, abTot1: any, abTot2: any) {
    const request = new PdfMakeWrapper();
    const fechaHoy = new Date();

    request.pageMargins([10, 10, 10, 10]);

    //estilo por defecto
    request.defaultStyle({
      fontSize: 15
    });

    //tamño en pixeles
    request.pageSize({
      width: 210,
      height: 850
    });

    request.add( //M19
      new Txt([
        "M19"
      ])
        .relativePosition(0, 0)
        .alignment("center")
        .fontSize(30)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "INMOBILIARIA"
      ])
        .relativePosition(0, 30)
        .alignment("center")
        .fontSize(12)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "GENOVA 28A, CD DEL VALLE"
      ])
        .relativePosition(0, 60)
        .alignment("center")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "63157 TEPIC, NAY. MEXICO"
      ])
        .relativePosition(0, 70)
        .alignment("center")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "TEL: 311 300 5622"
      ])
        .relativePosition(0, 80)
        .alignment("center")
        .fontSize(8)
        .end)


    request.add( //inmobiliaria
      new Txt([
        "FECHA DE IMPRESIÓN: "
      ])
        .relativePosition(0, 100)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        fechaHoy.toLocaleDateString()
      ])
        .relativePosition(85, 100)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "FECHA DE PAGO: "
      ])
        .relativePosition(0, 110)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        fecha
      ])
        .relativePosition(65, 110)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        Responsable === null ? "REIMPRESO POR: " : "ATENDIDO POR: "
      ])
        .relativePosition(0, 120)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        Responsable === null ? this.getUser() : Responsable
      ])
        .relativePosition(65, 120)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        tipoA == "M" ? "MANZANA: " : tipoA == "T" ? "TORRE: " : "MANZANA: "
      ])
        .relativePosition(0, 130)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        manzana
      ])
        .relativePosition(45, 130)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        tipoB == "L" ? "LOTE: " : tipoB == "D" ? "DPTO: " : "LOTE: "
      ])
        .relativePosition(80, 130)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        lote
      ])
        .relativePosition(105, 130)
        .alignment("left")
        .fontSize(8)
        .end)

    this.compararZona(proyec)


    request.add( //inmobiliaria
      new Txt([
        "PROYECTO: "
      ])
        .relativePosition(0, 140)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        this.proyect
      ])
        .relativePosition(45, 140)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "-----------------------------------------------------------------------------------"
      ])
        .relativePosition(0, 150)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "CONCEPTO: "
      ])
        .relativePosition(0, 160)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        concepto
      ])
        .relativePosition(45, 160)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "FORMA DE PAGO: "
      ])
        .relativePosition(0, 170)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        ForPago
      ])
        .relativePosition(65, 170)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "FOLIO DEL TICKET: "
      ])
        .relativePosition(0, 180)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        folio
      ])
        .relativePosition(70, 180)
        .alignment("left")
        .fontSize(8)
        .end)



    request.add( //inmobiliaria
      new Txt([
        "-----------------------------------------------------------------------------------"
      ])
        .relativePosition(0, 190)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "VENDEDOR:"
      ])
        .relativePosition(0, 200)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        vendedor
      ])
        .relativePosition(50, 200)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "CLIENTE:"
      ])
        .relativePosition(0, 210)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        cliente
      ])
        .relativePosition(50, 210)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "-----------------------------------------------------------------------------------"
      ])
        .relativePosition(0, 220)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "ABONADO:   "
      ])
        .relativePosition(0, 230)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "$ " + abonado
      ])
        .relativePosition(70, 230)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "-----------------------------------------------------------------------------------"
      ])
        .relativePosition(0, 240)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "TOTAL EN NOTAS DE CREDITO"
      ])
        .relativePosition(0, 250)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "$ " + abTot1
      ])
        .relativePosition(0, 260)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "TOTAL EN NOTAS DE CREDITO NO REEMBOLSABLE"
      ])
        .relativePosition(0, 270)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "$ " + abTot2
      ])
        .relativePosition(0, 280)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        "-----------------------------------------------------------------------------------"
      ])
        .relativePosition(0, 290)
        .alignment("left")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        '"Tu hogar, nuestro sueño"'
      ])
        .relativePosition(0, 310)
        .alignment("center")
        .fontSize(8)
        .end)

    request.add( //inmobiliaria
      new Txt([
        'https://app.m19inmobiliaria.com/'
      ])
        .relativePosition(0, 320)
        .alignment("center")
        .fontSize(8)
        .end)

    request.create().print();
  }

  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.cliente
  }

  //con todo el ticket

  /*AL=Altares
  XL Xalta
  CB CasaBlanca
  CL Colomos
  LI Liberti
  VP Valle de las palomas*/
}
