import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DeliveryInvoice } from '../models/DeliveryInvoice';

@Injectable({
  providedIn: 'root'
})
export class DeliveryinvoiceService {

  constructor(private http: HttpClient) { }

  selectAllInvoice(): Observable<any>{
    return this.http.get(`${environment.URLServer}/deliveryinvoice/selectAllinvoice`);
  }

  getInvoiceInfo(id: String): Observable<any>{
    return this.http.get(`${environment.URLServer}/deliveryinvoice/getInvoiceInfo/${id}`);
  }

  insertInvoice(delin: DeliveryInvoice): Observable<any>{
    return this.http.post(`${environment.URLServer}/deliveryinvoice/insertInvoice`,delin);
  }

  insertInvoiceProducts(id:Number,idp:Number,am:Number,uni:Number,usr:String): Observable<any>{
    return this.http.post(`${environment.URLServer}/deliveryinvoice/insertInvoiceProducts`,[id,idp,am,uni,usr]);
  }

  selectInvoice(id:Number): Observable<any>{
    return this.http.get(`${environment.URLServer}/deliveryinvoice/selectInvoice/`+id);
  }

  selectInvoiceProducts(id:Number): Observable<any>{
    return this.http.get(`${environment.URLServer}/deliveryinvoice/selectInvoiceProducts/`+id);
  }

  deleteInvoice(id:number): Observable<any>{
    return this.http.delete(`${environment.URLServer}/deliveryinvoice/deleteInvoice/${id}`);
  }

  updateInvoice(id:number, di: DeliveryInvoice): Observable<any>{
    return this.http.put(`${environment.URLServer}/deliveryinvoice/updateInvoice/${id}`, di);
  }

  deleteAllProductsById(id: number): Observable<any>{
    return this.http.delete(`${environment.URLServer}/deliveryinvoice/deleteAllProductsById/${id}`);
  }

  getTotalProjects(): Observable<any>{
    return this.http.get(`${environment.URLServer}/deliveryinvoice/getTotalProjects`);
  }
  getListProducts(id:number): Observable<any>{
    return this.http.get(`${environment.URLServer}/deliveryinvoice/getListProducts/${id}`);
  }

  getListModulebyProject(id:number): Observable<any>{
    return this.http.get(`${environment.URLServer}/deliveryinvoice/getListModulebyProject/${id}`);
  }
}