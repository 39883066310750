import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { DialogCustomerComponent } from '../../customer/dialog-customer/dialog-customer.component';
import { DialogDigitalCustomerComponent } from '../dialog-digital-customer/dialog-digital-customer.component';


@Component({
  selector: 'app-dialog-selec-user',
  templateUrl: './dialog-selec-user.component.html',
  styleUrls: ['./dialog-selec-user.component.scss']
})
export class DialogSelecUserComponent implements OnInit {
 
  filterCustomer = '';
  customers: any = [];
  customer: any = [];
  civil_status: any = [];

  constructor(
    private customerService: CustomerService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public sweet: SweetAlertComponent,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.selectCustomers();
    this.selectCivilStatus();
  }

  
  //Consultar todos los clientes registrados
  selectCustomers() {
    this.customerService.selectCustomers().subscribe(
      res => {
        this.customers = res;
      },
      err => console.log(err)
    );
  }

  //Consultar cliente especifico
  selectCustomer(id: number) {
    this.customerService.selectCustomer(id).subscribe(
      res => {
        this.customer = res;
        this.dialog.open(DialogDigitalCustomerComponent, {
          width: '600px',
          data: res
        });
      },
      err => console.log(err)
    )
  }

    //Consultar estado civil
    selectCivilStatus() {
      this.customerService.selectCivilStatus().subscribe(
        res => {
          this.civil_status = res;
        },
        err => console.log(err)
      );
    }
  


}
