<div class="container-fluid mt-3">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">
            <div>
                <div class="projectButtons">
                    <button *ngFor="let p of projects" class="m19AltBtn" (click)="getModulesOfProject(p.idProject)">
                        {{p.name}}
                        <mat-icon>apartment</mat-icon>
                    </button>
                </div>
                <div class="moduleButtons">
                    <button *ngFor="let m of modules" class="m19Btn" (click)="getHistory(m.idprojectdet)">
                        Modulo: {{m.towernumber}}
                        <mat-icon>home</mat-icon>
                    </button>
                </div>
                <div class="departureSelector" *ngIf="idprojectdet!=''">
                    <div class="closed">
                        <h4>Lista de partidas</h4>
                        <input type="text" placeholder="Ingresar el número de la partida..."
                            [(ngModel)]="filterDeparture" name="filterDeparture" class="filterDeparture">
                        <table class="depaTable">
                            <thead>
                                <tr>
                                    <th>Número</th>
                                    <th>Descripción</th>
                                    <th>Habilitar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let d of departures | filterDeparture : filterDeparture">
                                    <td>{{d.departurenumber}}</td>
                                    <td>{{d.descriptions}}</td>
                                    <td>
                                        <button class="btn btn-m19-table" title="Habilitar" mat-icon-button
                                            (click)="enableDeparture(d.iddeparture)">
                                            <mat-icon>thumb_up</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="opened">
                        <h4>Partidas Habilitadas</h4>
                        <table class="depaTable">
                            <tr>
                                <th>Número</th>
                                <th>Descripción</th>
                                <th>Deshabilitar</th>
                            </tr>
                            <tr *ngFor="let d of departuresActive">
                                <ng-container *ngIf="d.status=='ON'">
                                    <td>{{d.departurenumber}}</td>
                                    <td>{{d.descriptions}}</td>
                                    <td>
                                        <button class="btn btn-m19-table" title="Deshabilitar" mat-icon-button
                                            (click)="disableDeparture(d.iddeparture)">
                                            <mat-icon>thumb_down</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>