import { Component, ComponentFactoryResolver, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { comissionReportService } from 'src/app/services/comission.service';
import { ControComissionsService } from 'src/app/services/contro-comissions.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ControlComission } from 'src/app/models/ControlComission';
import { PdfMakerComponent } from 'src/app/utils/pdf-maker/pdf-maker.component';
import { DatePipe } from '@angular/common';

import decode from 'jwt-decode'

@Component({
  selector: 'app-dialog-coordinator-payment',
  templateUrl: './dialog-coordinator-payment.component.html',
  styleUrls: ['./dialog-coordinator-payment.component.scss']
})
export class DialogCoordinatorPaymentComponent implements OnInit {
  //Manejo de información res API, arreglos con carga de datos de las consultas a los servicios que conectan con la API
  sal: any = [];
  codInm: any = [];
  generatedFolio: any = [];

  //Variables locales
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  codigoInm: string | any = null
  vendedorCod: number | any = null
  codCoord: any;
  conInmueble: any;

  //Bandera tarjeta inserccion consulta
  nuevoSaldo = false
  creaPago = false
  ocultaEntrada = true
  formularioActivo = true
  formularioApagado = false

  //API cancelacion de pagos
  review_btn = false;

  //Calculo de fecha
  currentDate = new Date();
  hoursMinutesAndSeconds = this.currentDate.toLocaleTimeString()


  //Autocompletado libreria, variables requeridas
  keyword = 'name';
  palClav = 'fk_property';
  cadenaInmueble: any = "";
  fkCliente: any | string = ""
  sales: any = [];
  saleCoord: string | any = [];
  infoSeller: any = [];
  pipe = new DatePipe('en');

  //Validaciones formulario pagos
  cantidadDefault: number = 1000;
  saleCodeM: number = 0;

  paymentComFormGroup: FormGroup = this._formBuilder.group({
    folio: [null,],
    paymentDate: [null, Validators.required],
    paymentConcept: ["", Validators.required],
    paymentAmount: [null, [Validators.required, Validators.min(0)]],
    paymentStatus: ['Aprobado', Validators.required],
    fk_salesCoordinator: [null, Validators.required],
    fk_payer: [this.getUser(), Validators.required],
    registrationDate: [this.registrationDate(), Validators.required],
    fk_property: [this.infoSeller.inmueble, Validators.required],

  })

  Concepto = [
    { tipo: 'Mensualidad' },
    { tipo: 'Anticipo' }
  ];


  constructor(
    private dialogRef: MatDialogRef<DialogCoordinatorPaymentComponent>,
    private router: Router,
    private ticketComissiones: PdfMakerComponent,
    private _formBuilder: FormBuilder,
    private comissions: comissionReportService,
    @Inject(MAT_DIALOG_DATA) public data: comissionReportService,
    private controlComissions: ControComissionsService,
    public sweet: SweetAlertComponent
  ) {

    this.dialogRef.disableClose = true;
    this.infoSeller = data;
  }

  ngOnInit(): void {
    this.salecodeMejorado();
    this.folioGenerator();
    this.getUser();
    this.paymentComFormGroup.controls['paymentDate'].setValue(this.pipe.transform(this.currentDate.toISOString(), ('yyyy-MM-dd')));
    this.paymentComFormGroup.controls['paymentConcept'].setValue(this.Concepto[0].tipo);

  }

  resetInputs() {
    this.paymentComFormGroup.controls['folio'].setValue("");
    this.paymentComFormGroup.controls['paymentDate'].setValue("");
    this.paymentComFormGroup.controls['paymentConcept'].setValue("");
    this.paymentComFormGroup.controls['paymentAmount'].setValue("");
    this.paymentComFormGroup.controls['paymentStatus'].setValue("");
    this.paymentComFormGroup.controls['fk_salesCoordinator'].setValue("");
    this.paymentComFormGroup.controls['fk_payer'].setValue("");
    this.paymentComFormGroup.controls['registrationDate'].setValue("");
    this.paymentComFormGroup.controls['fk_property'].setValue("");

  }

  folioGenerator() {
    this.controlComissions.getLastFolio().subscribe(
      res => {
        this.generatedFolio = res;
        this.generatedFolio.folio = this.generatedFolio.folio + 1;
      },
      err => console.log(err)
    );
  }

  getUser() {
    const token = localStorage.getItem('token') || "";
    let decodetoken: any = {};
    decodetoken = decode(token);
    return decodetoken.fkuser;
  }

  //Remplaza la funcion que tenia mendez haciendola en una sola linea sin tantas comparaciones
  registrationDate() {
    return this.pipe.transform(this.currentDate.toISOString(), ('yyyy-MM-dd '))
  }

  salecodeMejorado() {
    let arrayDataInfo;

    for (let i = 0; i < 30; i++) {
      arrayDataInfo = this.infoSeller.nameEmployee.split(" ");
    }

    console.log("array data → ", arrayDataInfo)
    this.saleCodeM = parseInt(arrayDataInfo[0], 10);

    return this.saleCodeM
  }

  //Creación de pagos de comisión.
  createComissionPayments() {
    this.comissions.createComissionPayment(this.paymentComFormGroup.value).subscribe(
      res => {
        //this.sweet.AlertTime("center", "success", "Pago registrado con éxito", false, 2000);
        if (res.code == 'ER_DUP_ENTRY') {
          this.sweet.Toast("error", "Folio duplicado \n [ verifique la información y registre nuevamente ]")
          //this.resetInputs();
          //this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['GenerateAndCalculateCommisionsComponent']));
        } else {

          this.sweet.Toast("success", "Pago registrado con éxito")
          //  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['GenerateAndCalculateCommisionsComponent']));

          this.ticketComissiones.ticketGeneratorComissiones(
            this.paymentComFormGroup.value.paymentDate,
            this.paymentComFormGroup.value.folio,
            this.infoSeller.project,
            this.infoSeller.inmueble.substring(3, 5), //Manzana
            this.infoSeller.inmueble.substring(6, this.infoSeller.inmueble.length), //Lote
            this.infoSeller.nameEmployee.substring(7, this.infoSeller.nameEmployee.length),
            "Marco Antonio Flores Rivas",
            this.paymentComFormGroup.value.paymentAmount,
            this.paymentComFormGroup.value.paymentConcept
          );

          this.closeDialogPayments();
          //  console.log("Que contiene " , this.paymentComFormGroup.value)

        }

      },
      err => {
        console.error(err)
      }
    )
  }

  async closeDialogPayments() {
    this.dialogRef.close()
  }

}