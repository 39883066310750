import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sweet-alert',
  templateUrl: './sweet-alert.component.html',
  styleUrls: ['./sweet-alert.component.scss']
})
export class SweetAlertComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  AlertIcon(icon: any, title: any, text: any, footer: any) {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
      footer: footer
    })
  }

  AlertTime(position: any, icon: any, title: any, confirmButton: boolean, time: number) {
    Swal.fire({
      position: position,
      icon: icon,
      title: title,
      showConfirmButton: confirmButton,
      timer: time
    })
  }

  AlertDeleteConfirm() {
    Swal.fire({
      title: '¿Está seguro?',
      text: '¡No podrá revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, bórrarlo!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          '¡Eliminado!',
          'El registro ha sido eliminado',
          'success'
        )
      }
    })
  }

  Toast(icon: any, title: any){
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    
    Toast.fire({
      icon: icon,
      title: title
    })
  }

  CloseTimer() {
    let timerInterval: any
    Swal.fire({
      title: 'Cargando información',
      html: 'Disponible en <b></b> milisegundos',
      timer: 500,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
        const b = Swal.getHtmlContainer()!.querySelector('b')
        timerInterval = setInterval(() => { b!.textContent = Swal.getTimerLeft() + '' }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer')
      }
    })
  }

}
