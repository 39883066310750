import { Component, OnInit } from '@angular/core';
import { Payment } from '../../models/Payment';
import { SalesService } from 'src/app/services/sales.service';
import { PaymentService } from 'src/app/services/payment.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfMakerComponent } from 'src/app/utils/pdf-maker/pdf-maker.component';

import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { DatePipe } from '@angular/common';

import { ReportService } from '../../services/report.service';
import { MatDialog } from '@angular/material/dialog';
import { CreditPaymentsComponent } from '../search/report/credit-payments/credit-payments.component';
import decode from 'jwt-decode'

@Component({
  selector: 'app-accountstatus',
  templateUrl: './accountstatus.component.html',
  styleUrls: ['./accountstatus.component.scss']
})
export class AccountstatusComponent implements OnInit {
  //Nomenclatura inmueble
  M = "Manzana"
  L = "Lote"
  T = "Torre"
  D = "Departamento"

  pipe = new DatePipe('es-MX');

  creditPayments: any = [];

  anticipoC: any = 0;
  mensualidadC: any = 0;
  anticipoNR: any = 0;
  mensualidadNR: any = 0;

  today: Date = new Date();

  //Manejo de fechas
  n = new Date();
  //Año
  y = this.n.getFullYear();
  //Mes
  m = this.n.getMonth() + 1;
  //Día
  d = this.n.getDate();

  h = this.n.toLocaleTimeString()
  salida = ""
  anios() {
    return this.y
  }
  mess() {
    return this.m
  }
  dias() {
    return this.d
  }
  salidaFecha() {
    if (this.m < 9 && this.d < 9) {
      this.salida = this.y + '-' + '0' + this.m + '-' + '0' + this.d + ' ' + this.h;
    } else if (this.d < 9 && this.m > 9) {
      this.salida = this.y + '-' + this.m + '-' + '0' + this.d + ' ' + this.h;
    } else if (this.d > 9 && this.m < 9) {
      this.salida = this.y + '-' + '0' + this.m + '-' + this.d + ' ' + this.h;
    } else {
      this.salida = this.y + '-' + this.m + '-' + this.d + ' ' + this.h;
    }
    return this.salida
  }

  //Grafico y control de pagos (Barras de progreso).
  limiteC = -4
  toleranciaC = -1
  tolerancia2C = -2
  tolerancia3C = -3

  limite = -4
  tolerancia = -1
  tolerancia2 = -2
  tolerancia3 = -3

  //Manejo de información res API, arreglos con carga de datos de las consultas a los servicios que conectan con la API
  info: any = [];
  carteraInmu: any = [];
  payments: any = [];
  cuentas: any = [];
  projects: any = [];
  pagos: any = [];
  montos: any = [];
  montosListado: any = [];
  sales: any = []

  //Objeto de servicio pagos
  payment: Payment = {
    id: 0,
    fechaPago: '',
    cantidadAbono: 0,
    estadoPago: '',
    metodoPago: '',
    folioPago: 0,
    concepto: '',
    fkCliente: ''
  };

  //Ticket
  ticketget: any | undefined;

  //Objeto de servicio totales
  totales: any = [];
  total: Payment = {
    Abonado: 0
  };

  constructor(
    public modal: NgbModal, 
    public dialog: MatDialog, 
    private toastr: ToastrService, 
    private reportServices: ReportService,
    private paymentService: PaymentService,
    private salesServices: SalesService, 
    private reportService: PaymentService,
    public pdfTicket: PdfMakerComponent,
    private router: Router) { }

  //Metodo de carga de metodos al momento de la ejecución
  ngOnInit(): void {
    const token = localStorage.getItem('token') || "";
    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
    let decodetoken: any = {};
    decodetoken = decode(token);
    this.infoCustomer(decodetoken.email);
    console.log(this.today.getUTCDate());
  }
  //Consulta de datos desde el servicio que consulta a la API

  paymetCustomer(correo: string) {
    this.salesServices.paymentCustomer(correo).subscribe(
      res => {
        this.pagos = res;
      },
      err => console.log(err)
    );
  }

  abs(num: number) {
    return Math.abs(num).toLocaleString('es-ES');;
  }

  async getCreditPaymentsList(inmueble: string) {
    this.reportService.getIndividualCreditPayments(inmueble).subscribe(
      res => {
        this.creditPayments = res;
      }
    );
  }

  async getIndividualCreditPayments(inmueble: string, cliente: string, cordinator: string) {
    this.reportService.getIndividualCreditPayments(inmueble).subscribe(
      res => {
        let data = { "payments": res, "customer": cliente, "cordinator": cordinator };
        this.dialog.open(CreditPaymentsComponent, {
          width: '100%',
          data: data
        });
      }
    );
  }

  async getCreditPayments(inmueble: String) {
    this.reportService.getCreditPayments(inmueble).subscribe(
      res => {
        this.anticipoC = res.Anticipo;
        this.mensualidadC = res.Mensualidad;
      }
    );
  }

  async getCreditNRPayments(inmueble: String) {
    this.reportService.getCreditNRPayments(inmueble).subscribe(
      res => {
        this.anticipoNR = res.Anticipo;
        this.mensualidadNR = res.Mensualidad;
      }
    );
  }

  reporteInmuCartera(carInmu: string) {
    this.reportServices.reporteCarteraInmueble(carInmu).subscribe(
      res => {
        this.carteraInmu = res;
      },
      err => console.log(err)
    );
  }

  infoCustomer(correoCu: string) {
    this.salesServices.infoCustomer(correoCu).subscribe(
      data => {
        console.log("aqui", data)
        this.info = data;
      },
      err => console.log(err)
    );

  }
  slectPayments(cliente: string) {
    this.reportService.selectPayments(cliente).subscribe(
      res => {
        this.payments = res;
      },
      err => console.log(err)
    );
  }

  selectSales(codInmueble: any) {
    this.salesServices.selectSales(codInmueble).subscribe(
      res => {
        this.sales = res;
      },
      err => console.log(err)
    );
  }
  sumTotales(code: string) {
    this.reportService.sumTotal(code).subscribe(
      data => {
        this.totales = data;
      },
      err => console.log(err)
    );

  }

  rePrintTicket(ticket: any) {
    console.log("ticket: ", ticket)

    this.paymentService.getTicket(ticket).subscribe(data => {
      this.ticketget = data;
      console.log("pago obtenido", data)
      this.paymentService.calculateRest(this.ticketget.Inmueble, this.ticketget.Fecha).subscribe(
        res => {
          this.paymentService.getTotalsNCPayments(this.ticketget.Inmueble).subscribe(
            res2 => {
              this.pdfTicket.ticketGenerator(
                this.ticketget.Fecha,
                this.ticketget.Venderdor,
                ticket,
                this.ticketget.Inmueble.substring(2, 3),
                this.ticketget.Inmueble.substring(5, 6),
                this.ticketget.Inmueble.substring(3, 5),
                this.ticketget.Inmueble.substring(6, 8),
                this.ticketget.Concepto,
                this.ticketget.Formadepago,
                this.ticketget.Valortotalinmueble,
                this.ticketget.Abono,
                //this.ticketget.Restante,
                res[0].restante,
                res[0].pagosRealizados, //Pagos realizados
                this.ticketget.Mensualidadesapagar,
                this.ticketget.Inmueble.substring(0, 2),
                this.ticketget.Cliente,
                this.ticketget.useremail,
                res2.NC,
                res2.NCNR,
                this.ticketget.Valortotalinmueble - res[0].restante,
                (res[0].desfase > 0 ? (res[0].desfase + ' pendiente(s)') : 'Al corriente'),
                res[0].proxPay
              );
            }
          );
        }
      )
    }
    )
  }
}