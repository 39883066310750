import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SweetAlertComponent } from '../../../utils/sweet-alert/sweet-alert.component';
import { MatListModule } from '@angular/material/list';
import decode from 'jwt-decode';
import Swal from 'sweetalert2';
//----IMPORTACION DE SERVICIOS
import { PieceworkService } from 'src/app/services/piecework.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';
import { ConceptsService } from 'src/app/services/concepts.service';
import { DeparturerunService } from 'src/app/services/departurerun.service';
import { BudgetPieceworkService } from 'src/app/services/budget-piecework.service';
//----IMPORTACION DEL PIPE PARA FECHAS
import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-pieceworkregister',
  templateUrl: './pieceworkregister.component.html',
  styleUrls: ['./pieceworkregister.component.scss']
})

export class PieceworkregisterComponent implements OnInit {

  constructor(
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    public list: MatListModule,
    public dialog: MatDialog,
    //-------DECLARACION DE OBJETOS DE SERVICIOS
    private pieceworkService: PieceworkService,
    private projectService: ProjectService,
    private projectDetailService: ProjectdetailsService,
    private concetpsService: ConceptsService,
    private departurerunService: DeparturerunService,
    private budgetPieceworkService: BudgetPieceworkService
  ) { }

  //VARIABLES DE INSERCION A BASE DE DATOS
  //Formulario principal a validar
  pieceworkFormGroup: FormGroup = this._formBuilder.group({
    fk_idprojectdet: [, Validators.required],
    initweek: [, Validators.required],
    endweek: [, Validators.required],
    week: [, Validators.required],
    status: true,
    //--------PROPIEDADES NO PRINCIPALES
    idProject: [, Validators.required],
    iddeparture: [],
    fk_idpiecework_catalog: [],
    amount: [],
    unit: [],
    unitcost: [],
    total: [],
    reason: []
  });

  piecework_table_content: any = [];    //Información que se mostrará en la tabla
  piecework_employees: any = []         //Información de empleados que será cargado a base de datos

  //VARIABLES PARA CARGA A BASE DE DATOS
  piecework: any = {
    fk_idprojectdet: null,
    initweek: null,
    endweek: null,
    week: null,
    status: true,
  };//Aquí se mapean los datos necesarios para insertar en la tabla de piecework


  piecework_log: any = {
    fk_idPiecework: null,
    useremail: null,
    description: null,
    logtype: 'Created'
  }
  piecework_content: any = [];
  piecework_content_Temp: any = { //Variable para recopilar de forma temporal los datos actuales deldestajo que se está capturando, cuando se termine de recopilar, se mapea en un indice de piecework_content
    fk_idPiecework: null,
    fk_iddeparture: null,
    fk_idpiecework_catalog: null,
    unitcost: null,
    amount: null,
    reason: null
  }

  //VARIABLES
  pipe = new DatePipe('es-MX');
  requisitions: any = []           //recupera el catalogo de requisiciones
  departures: any = []             //Recupera el catalogo de partidas presupuestales existentes
  piecework_catalog: any = []      //Recupera el catalogo de destajos existentes
  projects: any = []               //Recupera los proyectos existentes
  projectDetailByID: any = []                 //Torres del proyecto recuperadas
  concepts: any = []               //conceptos perrones
  decode: any = {};               //Datos de acceso
  keywordP = 'departurename';
  keywordD = 'concept';
  originalCost = 0;              //Almacena el costo original del destajo para posteriormente detectar el cambio si es que existe alguno
  changeCost = false;
  employeeRange = ['OF', '1/2 OF', 'AY']; //Rango de los empleados
  pieceworkID = 0;                  //ID de la papeleta
  totalPapeleta: any = 0;         //Total a pagar a destajistas
  covered: number = 0;                  //Cantidad en dinero que ya se encuentra justificada
  outstanding: any = 0;              //Cantidad de dinero que falta por justificar
  pieceworkChecked = false;         //Indica si la cabecera de la papeleta es correcta y si está marcada como completa
  fecResI: any = '';                   //Fecha de incio que se mostrará en el resumen
  fecResE: any = '';                   //Fecha de fin que se mostrará en el resumen
  detMod: any = {                      //Nombre de proyecto y módulo mostrados en el resumen
    project: '',
    module: ''
  }
  pieceworkCompleted = false;        //Indica si se ha terminado de capturar los destajos




  ngOnInit(): void {
    this.loadInitials();
    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
  }

  async loadInitials() {
    this.budgetPieceworkService.getDepCon(0).subscribe(
      res => {
        this.piecework_catalog = res;
        console.log('ADV', res);
        console.log('filtro:', this.piecework_catalog.filter((filtro: { fk_idconcept: any; }) => filtro.fk_idconcept == 57));
      }
    )
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
        console.log('proyectos ', res)
      },
      err => console.log(err)
    );
  }

  async loadUnitPieceworkCatalog(id: any) {
    var dataConcept = this.piecework_catalog.filter((filtro: { fk_idconcept: any; }) => filtro.fk_idconcept == id.fk_idconcept);
    this.pieceworkFormGroup.controls['amount'].setValue(1);
    this.pieceworkFormGroup.controls['total'].setValue(null);
    this.pieceworkFormGroup.controls['unit'].setValue('UD');//dataConcept[0].unit
    this.pieceworkFormGroup.controls['unitcost'].setValue((dataConcept[0].unitcost).toFixed(2));
    this.pieceworkFormGroup.controls['reason'].setValue(null);
    this.originalCost = dataConcept[0].unitcost;
    this.changeEvent();
    this.piecework_content_Temp.fk_idpiecework_catalog = id.fk_idconcept;
  }

  async loadOpenDeparture(projectDetail: any) {
    this.departurerunService.selectHistory(projectDetail).subscribe(
      res => {
        //El servicio obtiene todas las partidas de ese módulo, pero el filter solo retorna las que están habilitadas
        this.departures = [];
        for (let dp of res.filter((res: { status: string; }) => res.status == 'ON')) {
          dp.departurename = 'Partida ' + dp.departurenumber + ': ' + dp.descriptions;
          this.departures.push(dp);

        }
      }
    )
  }

  async addPiecework() {
    if ((this.changeCost && this.pieceworkFormGroup.controls['reason'].value == '') || (this.changeCost && this.pieceworkFormGroup.controls['reason'].value == null) || (this.changeCost && this.pieceworkFormGroup.controls['reason'].value == undefined)) {
      this.sweet.AlertTime("center", "error", "Se detectó un cambio en el precio unitario pero no un motivo, por favor ingrese el motivo", true, 100000);
      console.log('A');
      return;
    }
    if (this.pieceworkFormGroup.controls['total'].value==null||this.pieceworkFormGroup.controls['total'].value==0||this.pieceworkFormGroup.controls['total'].value==undefined) {
      this.sweet.AlertTime("center", "error", "No está permitido que el total sea cero, por favor ingrese una cantidad total", true, 100000);
      console.log('A');
      return;
    }
    if (this.pieceworkFormGroup.controls['iddeparture'].value == null || this.pieceworkFormGroup.controls['fk_idpiecework_catalog'].value == null) {
      this.sweet.AlertTime("center", "error", "La partida presupuestal, el concepto de destajo o el volumen no está ingresado correctamente", true, 100000);
      console.log('B');
    } else {
      if (this.pieceworkFormGroup.controls['iddeparture'].value.departurename == null || this.pieceworkFormGroup.controls['iddeparture'].value.departurename == '' || this.pieceworkFormGroup.controls['iddeparture'].value.departurename == undefined
        || this.pieceworkFormGroup.controls['fk_idpiecework_catalog'].value.concept == null || this.pieceworkFormGroup.controls['fk_idpiecework_catalog'].value.concept == '' || this.pieceworkFormGroup.controls['fk_idpiecework_catalog'].value.concept == undefined
        || this.pieceworkFormGroup.controls['amount'].value == null || this.pieceworkFormGroup.controls['amount'].value == '' || this.pieceworkFormGroup.controls['amount'].value == 0) {
        this.sweet.AlertTime("center", "error", "La partida presupuestal, el concepto de destajo o el volumen no está ingresado correctamente", true, 100000);
        console.log('C',this.pieceworkFormGroup.controls['iddeparture'].value.departurename,this.pieceworkFormGroup.controls['fk_idpiecework_catalog'].value.concept,this.pieceworkFormGroup.controls['amount'].value);
      } else {
        this.piecework_content_Temp.amount = this.pieceworkFormGroup.controls['amount'].value; //El valor solo se carga al temporal si se modifica en el elemento html o se carga el default 1 si se agrega a lista y es valido
        this.piecework_content_Temp.unitcost = this.pieceworkFormGroup.controls['unitcost'].value;
        this.piecework_table_content.push({
          partida: this.pieceworkFormGroup.controls['iddeparture'].value.departurename,
          destajo: this.pieceworkFormGroup.controls['fk_idpiecework_catalog'].value.concept,
          unidad: this.pieceworkFormGroup.controls['unit'].value,
          volumen: this.pieceworkFormGroup.controls['amount'].value,
          precioUnit: this.pieceworkFormGroup.controls['unitcost'].value,
          costoTot: this.pieceworkFormGroup.controls['total'].value
        });
        console.log('OBJETO TEMPORAL MAPEADO', this.piecework_content_Temp);
        this.piecework_content.push({
          fk_idPiecework: null,
          fk_iddeparture: this.piecework_content_Temp.fk_iddeparture,
          fk_idpiecework_catalog: this.piecework_content_Temp.fk_idpiecework_catalog,
          unitcost: this.piecework_content_Temp.unitcost,
          amount: this.piecework_content_Temp.amount,
          reason: this.piecework_content_Temp.reason
        });
        console.log('OBJETO PERMANENTE MAPEADO', this.piecework_content);
        this.adjustTotalEmployee();
        this.pieceworkFormGroup.controls['amount'].setValue(1);
        this.pieceworkFormGroup.controls['unit'].setValue(null);
        this.pieceworkFormGroup.controls['unitcost'].setValue(null);
        this.pieceworkFormGroup.controls['total'].setValue(null);
        this.pieceworkFormGroup.controls['fk_idpiecework_catalog'].setValue(null);
        this.pieceworkFormGroup.controls['reason'].setValue(null);
        this.piecework_content_Temp.reason = null;
        this.adjustOutstanding();
      }
    }
  }

  async adjustTotalEmployee() {
    this.totalPapeleta = 0;
    for (let p of this.piecework_table_content) {
      this.totalPapeleta = this.totalPapeleta + p.costoTot;
    }
    Intl.NumberFormat(this.totalPapeleta)
  }

  async adjustOutstanding() {
    this.covered = 0;
    this.outstanding = 0;
    for (let p of this.piecework_employees) {
      this.covered = this.covered + (p.employeesalary - 0)
    }
    this.outstanding = this.totalPapeleta - this.covered;
    Intl.NumberFormat(this.outstanding);
    console.log(this.covered)
  }

  async deletePiecework(i: Number) {
    this.piecework_table_content.splice(i, 1);
    this.piecework_content.splice(i, 1);
    this.adjustTotalEmployee();
    this.adjustOutstanding();
  }

  async insert() {
    if (this.piecework_table_content.length <= 0) {
      this.sweet.AlertTime("center", "error", "Debe ingresar por lo menos un concepto de destajo a la tabla", true, 100000);
      return;
    }
    if (this.outstanding != 0) {
      this.sweet.AlertTime("center", "error", "Se debe justificar el total del costo de papeleta", true, 100000);
      return;
    }
    let validador = false;
    for (let p of this.piecework_employees) {
      if (p.employeename == '' || p.employeename == null || p.employeename == undefined) {
        validador = true;
      }
      if (p.attendanceLD == '0-0-0-0-0-0-0') {
        validador = true;
      }
    }
    if (validador) {
      this.sweet.AlertTime("center", "error", "Todos los empleados deben tener un nombre y asistencias", true, 100000);
      return;
    }
    if (this.pieceworkFormGroup.invalid) {
      this.sweet.AlertTime("center", "error", "Ingrese el módulo, fechas de semana y número de semana válidos", true, 100000);
    } else {
      console.log('piecework_employees: ', this.piecework_employees)
      console.log('Piecework content ',this.piecework_content)
      Swal.fire({
        title: '¿Quieres guardar tus cambios ahora?',
        text: "¡Esta lista de destajos se guardara como está actualmente y no podrás modificar el costo total más adelante!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No, cancelar',
        confirmButtonText: 'Sí, guardar!'
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('piecework_employees: ', this.piecework_employees)
          this.piecework = {
            fk_idprojectdet: this.pieceworkFormGroup.controls['fk_idprojectdet'].value,
            initweek: this.pieceworkFormGroup.controls['initweek'].value,
            endweek: this.pieceworkFormGroup.controls['endweek'].value,
            week: this.pieceworkFormGroup.controls['week'].value,
            status: true,
          }
          this.piecework_log = {
            fk_idPiecework: null,
            useremail: this.getUser(),
            description: 'Creación por registro',
            logtype: 'Created'
          }
          console.log('piecewotk:', this.piecework);
          console.log('piecework_content: ', this.piecework_content)
          console.log('piecework_log:', this.piecework_log);
          try {
            this.pieceworkService.insertPieceworkHeader(this.piecework).subscribe(
              res => {
                console.log('PAPELETA REGISTRADA: ', res)
                let pieceworkID = res;
                this.piecework_log.fk_idPiecework = pieceworkID;
                for (let i = 0; i < this.piecework_content.length; i++) {
                  this.piecework_content[i].fk_idPiecework = pieceworkID;
                }
                for (let i = 0; i < this.piecework_employees.length; i++) {
                  this.piecework_employees[i].fk_idPiecework = pieceworkID;
                }
                //-----
                this.pieceworkService.insertPieceworkLog(this.piecework_log).subscribe(
                  res => {
                    console.log('LOGDATE INSERTADO', res);
                    //------
                    for (let i = 0; i < this.piecework_employees.length; i++) {
                      this.pieceworkService.insertPieceworkEmployee(this.piecework_employees[i]).subscribe(
                        res => {
                          console.log('EMPLEADO INSERTADO', res);

                        }
                      )
                    }
                    console.log('ANTES DE INSERTAR EL CONTENIDO: ', this.piecework_content)
                    for (let i = 0; i < this.piecework_content.length; i++) {
                      this.pieceworkService.insertPieceworkContent(this.piecework_content[i]).subscribe(
                        res => {
                          console.log('CONTENIDO REGISTRADO', res);
                          if (i == this.piecework_content.length - 1) {
                            let timerInterval: any;
                            Swal.fire({
                              title: 'Papeleta guardada con éxito :)',
                              html: 'Preparando pantalla para ingresar nueva papeleta...',
                              timer: 7000,
                              timerProgressBar: true,
                              willClose: () => {
                                clearInterval(timerInterval)
                              }
                            }).then((result) => {

                                location.reload();

                            })
                          }
                        }
                      )
                    }

                  }
                )
              }
            )
          } catch {
            this.sweet.AlertTime("center", "error", "Algo no ha salido como se esperaba, por favor contacte al desarrollador", true, 100000);
          }
        }
      })
    }
  }


  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.email
  }

  async getProjectDetail(id: any) {
    console.log('evento getProjectDetail: ', id)
    this.pieceworkFormGroup.controls['fk_idprojectdet'].setValue('');
    this.projectDetailService.selectDetailsById(id).subscribe(
      res => {
        this.projectDetailByID = res;
        console.log('getProjectDet. ', res)
      }
    );
  }

  async selectConcepts(id: any) {
    this.concetpsService.selectConcepts(id.iddeparture).subscribe(
      res => {
        this.concepts = res;
        console.log('old concepts', res);
        this.piecework_content_Temp.fk_iddeparture = id.iddeparture
        console.log(this.piecework_content_Temp);
        this.getDepCon(id);
      }
    );
  }

  async getDepCon(idprojectdet: any) {
    this.budgetPieceworkService.getDepCon(idprojectdet).subscribe(
      res => {
        console.log('new concepts', res)
      }
    )
  }


  async fillData(i: number) {
    this.piecework_employees[i].employeename = (document.getElementById('employeename' + i) as HTMLInputElement).value
    this.piecework_employees[i].employeesalary = (document.getElementById('employeesalary' + i) as HTMLInputElement).value
    this.piecework_employees[i].observations = (document.getElementById('observations' + i) as HTMLInputElement).value
    this.adjustOutstanding();
  }

  async checkAll(i: number) {
    if ((document.getElementById('CA' + i) as HTMLInputElement).checked) {
      (document.getElementById('L' + i) as HTMLInputElement).checked = true;
      (document.getElementById('M' + i) as HTMLInputElement).checked = true;
      (document.getElementById('X' + i) as HTMLInputElement).checked = true;
      (document.getElementById('J' + i) as HTMLInputElement).checked = true;
      (document.getElementById('V' + i) as HTMLInputElement).checked = true;
      (document.getElementById('S' + i) as HTMLInputElement).checked = true;
      (document.getElementById('D' + i) as HTMLInputElement).checked = true;
      this.piecework_employees[i].attendanceLD = '1-1-1-1-1-1-1';
    } else {
      (document.getElementById('L' + i) as HTMLInputElement).checked = false;
      (document.getElementById('M' + i) as HTMLInputElement).checked = false;
      (document.getElementById('X' + i) as HTMLInputElement).checked = false;
      (document.getElementById('J' + i) as HTMLInputElement).checked = false;
      (document.getElementById('V' + i) as HTMLInputElement).checked = false;
      (document.getElementById('S' + i) as HTMLInputElement).checked = false;
      (document.getElementById('D' + i) as HTMLInputElement).checked = false;
      this.piecework_employees[i].attendanceLD = '0-0-0-0-0-0-0';
    }
    console.log(this.piecework_employees);
  }

  async updateAttendence(i: number,event:any) {
    let temp = this.piecework_employees[i].attendanceLD.split('-');
    ((document.getElementById('L' + i) as HTMLInputElement).checked) ? temp[0] = 1 : temp[0] = 0;
    ((document.getElementById('M' + i) as HTMLInputElement).checked) ? temp[1] = 1 : temp[1] = 0;
    ((document.getElementById('X' + i) as HTMLInputElement).checked) ? temp[2] = 1 : temp[2] = 0;
    ((document.getElementById('J' + i) as HTMLInputElement).checked) ? temp[3] = 1 : temp[3] = 0;
    ((document.getElementById('V' + i) as HTMLInputElement).checked) ? temp[4] = 1 : temp[4] = 0;
    ((document.getElementById('S' + i) as HTMLInputElement).checked) ? temp[5] = 1 : temp[5] = 0;
    ((document.getElementById('D' + i) as HTMLInputElement).checked) ? temp[6] = 1 : temp[6] = 0;
    let str = ''
    for (let i: number = 0; i < temp.length; i++) {
      str = str + temp[i] + '-';
    }
    this.piecework_employees[i].attendanceLD = str.substring(0, str.length - 1);
    this.piecework_employees[i].employeerange=event;
    console.log(this.piecework_employees[i].employeerange+'');
  }

  async changeEvent() {
    if (this.pieceworkFormGroup.controls['unitcost'].value != this.originalCost) {
      this.changeCost = true;
    } else {
      this.changeCost = false;
      this.pieceworkFormGroup.controls['reason'].setValue(null);
    }
    this.pieceworkFormGroup.controls['total'].setValue(this.pieceworkFormGroup.controls['amount'].value * this.pieceworkFormGroup.controls['unitcost'].value)
    this.piecework_content_Temp.unitcost = this.pieceworkFormGroup.controls['unitcost'].value;
  }

  async inputAmount() {
    this.pieceworkFormGroup.controls['total'].setValue(this.pieceworkFormGroup.controls['amount'].value * this.pieceworkFormGroup.controls['unitcost'].value);
    this.piecework_content_Temp.amount = this.pieceworkFormGroup.controls['amount'].value;
    this.piecework_content_Temp.unitcost = this.pieceworkFormGroup.controls['unitcost'].value;
  }

  async pieceworkCheck() {
    if (this.pieceworkFormGroup.invalid) {
      this.sweet.AlertTime("center", "error", "Al menos un dato no es válido, por favor ingrese datos válidos", true, 100000);
    } else if (this.pieceworkFormGroup.controls['week'].value <= 0) {
      this.sweet.AlertTime("center", "error", "No se ha ingresado un numero de semana válido, por favor ingrese un número válido", true, 100000)
    }
    else {
      this.pieceworkChecked = true;
      this.fecResI = this.pipe.transform(this.pieceworkFormGroup.controls['initweek'].value, 'longDate')
      this.fecResE = this.pipe.transform(this.pieceworkFormGroup.controls['endweek'].value, 'longDate')
      console.log('Id del proyecto: ', this.pieceworkFormGroup.controls['idProject'].value);
      this.detMod.project = this.projects.find((res: { idProject: number; }) => res.idProject == this.pieceworkFormGroup.controls['idProject'].value).name;
      this.detMod.module = this.projectDetailByID.find((res: { idprojectdet: number; }) => res.idprojectdet == this.pieceworkFormGroup.controls['fk_idprojectdet'].value).towernumber;
    }
  }
  async completePiecework() {
    this.pieceworkCompleted = true;
  }

  async changeTotal() {
    if (this.pieceworkFormGroup.controls['amount'].value == 0 || this.pieceworkFormGroup.controls['amount'].value == null || this.pieceworkFormGroup.controls['amount'].value == undefined) {
      return;
    }
    this.pieceworkFormGroup.controls['unitcost'].setValue(((this.pieceworkFormGroup.controls['total'].value / this.pieceworkFormGroup.controls['amount'].value) + 0).toFixed(2));
    if (this.pieceworkFormGroup.controls['unitcost'].value != this.originalCost) {
      this.changeCost = true;
    } else {
      this.changeCost = false;
      this.pieceworkFormGroup.controls['reason'].setValue(null);
    }
  }
}
