<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title> registro de correo </title>

    <style>
        .mainContainer {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: center;
            background-color: white;

        }

        .tarjetaContainer {
            display: flex;
            width: 50%;
            justify-content: center;
            background-color: black;
            flex-direction: column;
        }

        .Encabezado {
            color: var(--M19Primary);
            background-color: #000;
            text-align: center;
        }

        .EncabezadoTexto {
            display: flex;
            width: 100%;
            height: 100px;
            color: white;
            background-color: var(--M19Secundary);
            text-align: center;
            align-items: center;
            justify-content: center;
        }

        .codigo {
            font-weight: bold;
            color: red;
            background-color: white;
            width: 40%;
            height: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

        }

        .texto {
            display: flex;
            flex-direction: column;
            color: white;
            padding-left: 80px;
            padding-right: 50px;
            flex-wrap: wrap;
            padding-top: 30px
        }

        .footer {
            display: flex;
            flex-direction: column;
            text-align: center;
            color: white;
            background-color: #000;
        }
    </style>

</head>

<body>

    <!-- <mat-card-title>
        <app-banner-title [banner]="mailVefifier"></app-banner-title>
    </mat-card-title> -->

    <div style="width: 100%; justify-content: space-evenly; display: flex; background-color: transparent;">

        <button class="m19InvBtn" (click)="activaCorreo()"> Correo </button>

        <button class="m19InvBtn" (click)="activaCodigo()"> Codigo </button>

    </div>

    <div class="containerGeneralForMail" *ngIf="this.banderas.ingresarCorreo ">

            <div class="containerGeneralForMail__containerMailInputFormat">

                <div class="containerGeneralForMail__containerMailInputFormat__containerMailInput">
                    
                    <div class="containerGeneralForMail__containerMailInputFormat__containerIMG">
                        <img  src="../../../../assets/iconoM.png"  alt="Logo M19">
                    </div>

                    <h2 style="color: white; text-align: center; margin-top: 20px; justify-content: space-evenly;"> Verificador de e-mails </h2>
        
                        <form [formGroup]="" style="margin-top: 50px;">
                            <mat-form-field appearance="outline" class="col-md-12 inputMail" style="margin-bottom: 20px;">
                                <mat-label> e-mail </mat-label>
                                <input matInput type="email" placeholder="Ingresa tu email">
                                <mat-icon matSuffix> mail </mat-icon>
                            </mat-form-field>
        
                            <mat-form-field appearance="outline" class="col-md-12 inputMail">
                                <mat-label> Codigo de verificación </mat-label>
                                <input matInput type="password" placeholder="Ingrese su codifo de 5 digitos">
                                <mat-icon matSuffix> password </mat-icon>
                            </mat-form-field>
                        </form>
        
                        <div class="containerGeneralForMail__containerMailInputFormat__hipervinculos" >
                            <a href="http://localhost:4200/mailVerify"> Olvidé mi contraseña </a>
                        </div>
        
                        <div class="containerGeneralForMail__containerMailInputFormat__buttonPosition">
                            <button > Enviar </button>
                        </div>

                        <div class="containerGeneralForMail__containerMailInputFormat__buttonPosition">
                            <button > Verificar </button>
                        </div>

                </div>

            </div>

    </div>

    <div *ngIf="this.banderas.generarCodigo ">
        <h3> Primer contenedor contenido en styles con clases </h3>
        <div>
            <div class="containerGeneralForCodeAccess">

                <div class="containerCode">
                    <div class="containerIMG">
                        <img src="../../../../assets/iconoM.png" style="width:25%;">
                    </div>

                    <div class="titleTop">
                        <h1> CÓDIGO DE ACCESO ÚNICO </h1>
                    </div>

                    <div
                        style="display: flex; flex-direction: column; color: white; padding-left: 80px; padding-right: 50px; flex-wrap: wrap; padding-top: 30px">
                        <p> Estimado [USUARIO]: </p>
                        <p> En M19 inmobiliaria nos preocupamos por tu información por lo que para poder acceder a tu
                            cuenta
                            te pedimos que verifiques tu correo electronico, usando el código que se muestra a
                            continuación.
                        </p>
                    </div>

                    <div
                        style=" display: flex; width: 100%; height: 80px; color: white; background-color: #5E4F4F; text-align: center; align-items: center; justify-content: center;">
                        <h3
                            style=" font-weight: bold; color: red; background-color: white; width: 40%; height: 50%; display: flex; justify-content: center; align-items: center;">
                            {{generador}}
                        </h3>
                    </div>

                    <div
                        style="display: flex; flex-direction: column; color: white; padding-left: 80px; padding-right: 50px; flex-wrap: wrap; padding-top: 30px">
                        <p> No compartas este código con nadie más, tendra un lapso de 15 minutos antes de que el código
                            caduque y tenga que solicitar uno nuevo. </p>
                    </div>

                    <div
                        style=" display: flex; flex-direction: column; text-align: center; color: white; background-color: #000;">

                        <div style="justify-content: center; direction: flex;">
                            <img style="align-items: center" src="../../../../assets/isotipo.png" style="width:25%;">
                        </div>

                        <p> <a style="color: red;" href="https://app.m19inmobiliaria.com/">
                                https://app.m19inmobiliaria.com/
                            </a> </p>


                        GENOVA 28A, CD DEL VALLE
                        63157 TEPIC, NAY. MEXICO
                        <p> TEL: 311 300 5622 </p>

                        <p> "Tu hogar, nuestro sueño" </p>

                    </div>



                </div>

            </div>
        </div>

        <h3> Segundo contenedor contenido en styles sin clases </h3>
        <div
            style=" display: flex; width: 100%; flex-direction: row; justify-content: center; background-color: white;">

            <div
                style=" display: flex; width: 50%; justify-content: center; background-color: black; flex-direction: column;">
                <div style=" background-color: #000; text-align: center; margin-top: 20px; margin-bottom: 20px;">
                    <img src="../../../../assets/iconoM.png" style="width:25%;">
                </div>

                <div
                    style=" display: flex; width: 100%; height: 100px; color: white; background-color: #5E4F4F; text-align: center; align-items: center; justify-content: center;">
                    <h1> CÓDIGO DE ACCESO ÚNICO </h1>
                </div>

                <div
                    style="display: flex; flex-direction: column; color: white; padding-left: 80px; padding-right: 50px; flex-wrap: wrap; padding-top: 30px">
                    <p> Estimado [USUARIO]: </p>
                    <p> En M19 inmobiliaria nos preocupamos por tu información por lo que para poder acceder a tu cuenta
                        te
                        pedimos que verifiques tu correo electronico, usando el código que se muestra a continuación.
                    </p>
                </div>

                <div
                    style=" display: flex; width: 100%; height: 80px; color: white; background-color: #5E4F4F; text-align: center; align-items: center; justify-content: center;">
                    <h3
                        style="font-weight: bold; color: red; background-color: white; width: 40%; height: 50%; display: flex; justify-content: center; align-items: center;">
                        {{generador}}
                    </h3>
                </div>

                <div
                    style="display: flex; flex-direction: column; color: white; padding-left: 80px; padding-right: 50px; flex-wrap: wrap; padding-top: 30px">
                    <p> No compartas este código con nadie más, tendra un lapso de 15 minutos antes de que el código
                        caduque
                        y tenga que solicitar uno nuevo. </p>
                </div>

                <div
                    style=" display: flex; flex-direction: column; text-align: center; color: white; background-color: #000;">

                    <div style="justify-content: center; direction: flex;">
                        <img style="align-items: center"
                            src="../../../../assets/isotipo.png"
                            style="width:25%;">
                    </div>

                    <p> <a style="color: red;" href="https://app.m19inmobiliaria.com/"> https://app.m19inmobiliaria.com/
                        </a> </p>


                    GENOVA 28A, CD DEL VALLE
                    63157 TEPIC, NAY. MEXICO
                    <p> TEL: 311 300 5622 </p>

                    <p> "Tu hogar, nuestro sueño" </p>

                </div>



            </div>

        </div>

    </div>



</body>

</html>