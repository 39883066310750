//=========================================================================================================
//Importación de componentes necesarios para esta pantalla
//=========================================================================================================
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LeadsService } from 'src/app/services/leads.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import Swal from 'sweetalert2';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';

@Component({
  selector: 'app-leads-reassign',
  templateUrl: './leads-reassign.component.html',
  styleUrls: ['./leads-reassign.component.scss']
})
export class LeadsReassignComponent implements OnInit {

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  coordsList: any = [];

  constructor(
    private dialogRef: MatDialogRef<LeadsReassignComponent>,
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    private leadService: LeadsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getCoordinators();
    console.log(this.data);
  }

  reassignFormGroup: FormGroup = this._formBuilder.group({
    idlead: [],
    secRes: [],
    reason: [],
    useremail: [this.getUser()]
  });

  async getCoordinators() {
    this.leadService.getCoordinators().subscribe(
      res => {
        this.coordsList = res;
      }
    );
  }

  async reassignLead() {
    Swal.fire({
      title: '¿Seguro que quieres reasignar a este prospecto?',
      text: "¡Esto no podrá modificarse despues!",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'No, espera',
      confirmButtonText: 'Sí, continúa!',
      buttonsStyling: false,
      customClass: {
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn",
        actions: "sweetActions"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        let args = {
          "idlead": this.data.idlead,
          "secRes": this.data.idEmployee
        }

        this.leadService.leadUnassign(args).subscribe(
          res => {
            let log = {
              "action": "Reasignado",
              "responsable": this.getUser(),
              "secondResponsable": this.reassignFormGroup.controls["secRes"].value,
              "comments": this.reassignFormGroup.controls["reason"].value,
              "fk_leads": this.data.idlead
            }

            this.leadService.leadsLogWrite(log).subscribe(
              res2 => {
                this.sweet.AlertTime("center", "success", "Este lead se ha reasignado exitosamente", false, 2500);
                this.dialogRef.close();
              }
            );
          }
        );
      }
    })
  }

  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.fkuser
  }
}
