import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConceptsService } from 'src/app/services/concepts.service';
import { DepartureService } from 'src/app/services/departure.service';

//=========================================================================================================
//Importación de librerías para componentes visuales
//=========================================================================================================
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import Swal from 'sweetalert2';
import decode from 'jwt-decode';

@Component({
  selector: 'app-concept-update',
  templateUrl: './concept-update.component.html',
  styleUrls: ['./concept-update.component.scss']
})
export class ConceptUpdateComponent implements OnInit {

  departures: any = [];

  keywordP = 'departurename';

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  constructor(
    private dialogRef: MatDialogRef<ConceptUpdateComponent>,
    private conceptService: ConceptsService,
    private departureService: DepartureService,

    //===================================================
    //Declaración de complementos visuales
    private _formBuilder: FormBuilder,
    private sweet: SweetAlertComponent,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  //==========================================================================================================
  //Objeto constructor del formulario utilizado en la pantalla de requisición, se agrega el código 
  //", Validators.required" para señalar que es un valor que no debe estar vacío
  //==========================================================================================================
  conceptFormGroup: FormGroup = this._formBuilder.group({
    idconcept: [],
    conceptKey: [],
    descriptions: [, Validators.required],
    useremail: []
  });

  ngOnInit(): void {
    this.getDepartures();
    this.conceptService.searchConcept(this.data).subscribe(
      res => {
        this.conceptFormGroup.controls['conceptKey'].setValue(res.conceptKey);
        this.conceptFormGroup.controls['descriptions'].setValue(res.descriptions);
      }
    );
  }

  async setIDDeparture(a:any) {
    this.conceptFormGroup.controls['iddeparture'].setValue(a.iddeparture);
  }

  async getDepartures(){
    this.departureService.getDepartureList().subscribe(
      res =>{
        this.departures = res;
      }
    );
  }

  async updateConcept(){
    Swal.fire({
      title: '¿Quieres guardar tus cambios ahora?',
      text: "¡Este concepto se guardara como está actualmente!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, espera',
      confirmButtonText: 'Sí, guárdalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.conceptService.updateConcept(this.data, this.conceptFormGroup.value).subscribe(
          res => {
            this.sweet.AlertTime("center", "success", "Concepto actualizada con éxito.", false, 2000);
            this.dialogRef.close();
          }
        );
      }
    })
  }

  //==========================================================================================================
  //Recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.email
  }

}
