import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AdministrationService } from 'src/app/services/administration.service';
import { MapsService } from 'src/app/services/maps.service';

import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';

@Component({
  selector: 'app-edit-sales',
  templateUrl: './edit-sales.component.html',
  styleUrls: ['./edit-sales.component.scss']
})
export class EditSalesComponent implements OnInit {
  editSaleBanner: BannerTitle = {
    title: "Edición de disponibilidad",
    icon: "design_services",
    position: "center",
    background: "var(--M19Secundary)"
  }

  projects: any
  selected_project = { name: '', nomenclature: '' };
  status: any = [];
  catalog: any = [];
  changeStatus = '';
  changeCatalog = '';
  changeProperty: any = [];
  filterProperty = '';
  properties: any = [];


  constructor(
    private mapsService: MapsService,
    private adminService: AdministrationService,
    private toastr: ToastrService



  ) { }

  ngOnInit(): void {
    this.getProjects();
    this.selectStatus();
    this.selectSalesCatalog();
  }


  getAllPropertys() {
    this.mapsService.getAllPropertys(this.selected_project.nomenclature).subscribe(
      res => {
        this.properties = res
        console.log(res)
      }
    )
  }

  getProjects() {
    this.mapsService.selectAllProjects().subscribe(
      res => {
        this.projects = res;
      },
      err => console.log(err)
    )
  }


  //Obtener estatus de inmuebles (combo)
  selectStatus() {
    this.adminService.getAllStatus().subscribe(
      res => {
        this.status = res;
      },
      err => console.log(err)
    );
  }

  //Obtener catalogo de venta de inmuebles (combo)
  selectSalesCatalog() {
    this.adminService.getAllSalesCatalog().subscribe(
      res => {
        this.catalog = res;
      },
      err => console.log(err)
    );
  }

  changeCatalogfun(inmueble: any, event: any) {
    console.log("cambiaCatalog?", inmueble, event)
    this.changeProperty.push(inmueble)
    this.changeCatalog = event.value;
  }

  changeStatusfun(inmueble: any, event: any) {
    console.log("cambiaStatus?", event.value)
    this.changeProperty.push(inmueble)
    this.changeStatus = event.value;
  }

  updateCatalog(catalog: any, inmueble: any) {
    this.changeProperty.pop(inmueble)
    console.log("pop: ", inmueble)

    this.adminService.updateCatalog(catalog, inmueble).subscribe(
      res => {
        this.toastr.success('Inmueble modificado de disponibilidad con exito')
      },
      err => {
        this.toastr.success('Inmueble no modificado')
      }
    )
  }

  updateStatus(status: any, inmueble: any) {
    this.changeProperty.pop(inmueble)
    console.log("pop: ", inmueble)

    this.adminService.updateCatalog(status, inmueble).subscribe(
      res => {
        this.toastr.success('Inmueble modificado de status con exito')
      },
      err => {
        this.toastr.success('Inmueble no modificado')
      }
    )
  }

  //estado de disponibilidad, (fk_salesCatalog)
  updateCatalogAndStatus(changeCatalog: any, changeStatus: any, codeProperty: any) {
    this.changeProperty.pop(codeProperty)
    this.changeProperty.pop(codeProperty)

    console.log("changeCatalog :", changeCatalog, "changeStatus :", changeStatus, "codeProperty: ", codeProperty)
    this.adminService.updateCatalogAndStatus(changeCatalog, changeStatus, codeProperty).subscribe(
      res => {
        this.toastr.success('Inmueble modificado de estatus y disponibilidad con exito')
      },
      err => {
        this.toastr.success('Inmueble no modificado')
      }
    )
  }


  update(changeCatalog: any, changeStatus: any, codeProperty: any) {
    console.log("lo que llego a update: change catalog", changeCatalog, "change status: ", changeStatus, "codigo de propiedad: ", codeProperty)

    if (changeCatalog == '') {
      this.updateStatus(changeStatus, codeProperty)
    }
    if (changeStatus == '') {
      this.updateCatalog(changeCatalog, codeProperty)
    }
    if(changeCatalog != '' && changeStatus != '') {
      this.updateCatalogAndStatus(changeCatalog, changeStatus, codeProperty)
    }

  }





}
