import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ProjectService } from 'src/app/services/project.service';
import { CLogbookService } from 'src/app/services/c-logbook.service';
import { UtilsService } from 'src/app/services/utils.service';

//Pipes de fechas
import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { DatePipe } from '@angular/common';

import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-c-logbook-report',
  templateUrl: './c-logbook-report.component.html',
  styleUrls: ['./c-logbook-report.component.scss']
})

export class CLogbookReportComponent implements OnInit {
  pipe = new DatePipe('es-MX');
  projects: any = [];
  project: any = { id: 0, name: "" };
  months: any = [{ "num": "01", "name": "Enero" }, { "num": "02", "name": "Febrero" }, { "num": "03", "name": "Marzo" }, { "num": "04", "name": "Abril" }, { "num": "05", "name": "Mayo" }, { "num": "06", "name": "Junio" }, { "num": "07", "name": "Julio" }, { "num": "08", "name": "Agosto" }, { "num": "09", "name": "Septiembre" }, { "num": "10", "name": "Octubre" }, { "num": "1", "name": "Noviembre" }, { "num": "12", "name": "Diciembre" }];


  evidenceRep: any = [];
  evidenceIMG: any = [];
  inv = false
  arr: any = [];
  log: any = [];
  element = [];

  horalocal: any = [];

  public formRange = new FormGroup({
    start: new FormControl('', Validators.required),
    end: new FormControl('', Validators.required)
  });

  constructor(
    private projectService: ProjectService,
    private clogService: CLogbookService,
    private utilService: UtilsService,
    private router: Router
  ) {
    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
    this.utilService.getDate().subscribe(res => { this.horalocal = this.pipe.transform(res, "dd/MM/yyyy"); })
    this.projectService.selectProjects().subscribe(res => { this.projects = res; }, err => console.log(err));
  }

  coord = {
    latitude: 0,
    longitude: 0
  }

  ngOnInit(): void {
    this.selectProjects();
    this.prueba();
  }

  async selectProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
        //console.log("Esto me esta devolviendo los proyectos → ", this.projects)
      },
      err => console.log(err)
    );
  }

  async onDateChange() {

    if (this.formRange.value.start != null && this.formRange.value.end != null) {

      this.formRange.value.end != null ? this.inv = true : this.inv = false;

      this.clogService.getHistoryReportService(this.pipe.transform(this.formRange.value.start, "yyyy-MM-dd"), this.pipe.transform(this.formRange.value.end, "yyyy-MM-dd"), this.project.name).subscribe(
        res => {

          this.log = res;

          for (let index = 0; index < this.log.length; index++) {
            this.element[index] = this.log[index].idclogbook;

          }

          let result = this.element.filter((item, index) => {
            return this.element.indexOf(item) === index;
          })



          this.evidenceIMG = [];

          for (let j = 0; j < result.length; j++) {
            this.clogService.getHistoryReporImagesService(result[j]).subscribe(
              resp => {
                this.evidenceIMG = resp;
                for (let a of this.evidenceIMG) { this.arr.push(a) }

              }
            )
            console.log("imagenes → ", this.arr);
          }


          //   this.divisor();

        }

      )


    } else {
      return;
    }

  }

  uwu: any = [];
  async divisor() {

    //console.log("Divisor → " , this.log)

    let resu2 = this.log.filter((item, index) => {
      // console.log("Esto imprime  → ", this.log[index].remarks.indexOf(item.remarks[index]) === index)
      // console.log("esto print 2 → ", item.remarks)


      // if(this.log[index].remarks.indexOf(item.remarks[index]) === index){
      //   this.uwu.push(item.remarks)
      // }else{

      // }

      return this.log[index].remarks.indexOf(item.remarks) === index;
    })

    console.log("resu → ", resu2)
    //   console.log("resu 2 → " , this.uwu)



  }


  printReport() {

    Swal.fire({
      title: 'Ingresa un nombre para el archivo',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Generar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {

        let printContents, popupWin;
        printContents = document.getElementById('logbookDoc')!.innerHTML;
        popupWin = window.open('', '', '');
        popupWin!.document.open();
        popupWin!.document.write(`
      <html>
        <head>
          <title> ${result.value}</title>
          <style>
          @media print{@page {size: landscape}}
          * {
            margin: 0;
            padding: 0;
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
          }
          body {
            font-family: Roboto, "Helvetica Neue", sans-serif;
          }
          .controlPDF {
            width: 100%;
            border-collapse: collapse;
          }
        
          .controlPDF td {
            border: 1px solid black;
            padding: 5px;
            text-align: center; 
            font-weight: bold;
          }
        
          .signArea {
            width: 100%;
            border-collapse: collapse;
          }
        
          .signArea td {
            border: 1px solid black;
            page-break-inside: avoid;
            text-align: center; 
            font-weight: bolder;
            height: 50px;
          }
    
          .td1{
            max-width: 550px; 
            font-weight: 300;
          }
    
          .td2{
            max-width: 250px; 
            text-align: center;
            font-weight: 300;
          }
        
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
        );
        popupWin!.document.close();
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['CLogbookReport']));

      }
    })




  }

  shwButton = false;

  onTopScroll() {
    const container = document.querySelector(".contedor-principal");
    container!.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  onScroll() {
    const altScro = document.querySelector(".contedor-principal");
    altScro.scrollTop > 100 ? this.shwButton = true : this.shwButton = false;
  }

  prueba() {

    let valueDisplays = document.querySelectorAll(".counter");
    let interval = 5;

    valueDisplays.forEach((valueDisplay) => {

      let startValue = 0;
      let endValue = parseInt(valueDisplay.getAttribute("data-value"))

      let duration = Math.floor(2);

      let counter = setInterval(() => {
        startValue += 1;
        valueDisplay.textContent = startValue + "";

        if (startValue === endValue) {
          clearInterval(counter)
        }
      }, 0);


      // console.log("Estos obtuvo → " , endValue)
    })

  }

  getCoords() {

    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.coord.latitude = position.coords.latitude;
        this.coord.longitude = position.coords.longitude;
        Swal.fire({
          title: "Tus coordenadas son las siguientes:",
          html: `<h3> x → ${this.coord.latitude}  </h3>
                 <br>
                 <h3> y → ${this.coord.longitude} </h3>`
        })
      },
      (error) => {
        console.log("Error al obtener la ubicación: " + error.message);
      }
    );
  }

}
