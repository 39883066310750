
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ControComissionsService } from 'src/app/services/contro-comissions.service';
import { MapsService } from 'src/app/services/maps.service';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-dialog-entry-project-selected',
  templateUrl: './dialog-entry-project-selected.component.html',
  styleUrls: ['./dialog-entry-project-selected.component.scss']
})
export class DialogEntryProjectSelectedComponent implements OnInit {

  public projects: any = [];
  public nameProject: any = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public projectService: ProjectService
    ) { }


  ngOnInit(): void {
    this.getProject(); 
    localStorage.setItem('projectSelected', "");
  }


  //consultar todos los poryectos
  getProject() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      },
      err => console.log(err)
    )
  }

  selectProject(){
            
            this.nameProject 
            localStorage.setItem('projectSelected', this.nameProject);
      
  }

}
