import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterProperty'
}) 
export class FilterPropertyPipe implements PipeTransform {

  transform(value: any, args: any): any {

    const result = [];

    for (const post of value) {
      let property = post.codeProperty;
      if (property.toUpperCase().indexOf(args.toUpperCase()) > -1) {
        result.push(post);
      }
    }
    return result;
  }

}
