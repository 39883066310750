import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Comission } from '../models/comission';

@Injectable({
  providedIn: 'root'
})
export class comissionReportService{

    private url = 'http://localhost:3000/api';


    constructor(private http: HttpClient) { }

      createComissionPayment(comRep: Comission): Observable<any> {
       // console.log("Servicio " , comRep)
        return this.http.post(`${environment.URLServer}/comissions/`, comRep);
      }
      coordList(): Observable<any>{
        return this.http.get(`${environment.URLServer}/comissions/coord`);
      }
        //Consultar un inmueble en específico
      reporteCoordSales(fk:number): Observable<any>{
        return this.http.get(`${environment.URLServer}/comissions/comission/${fk}`);
      }
      reportePaymentSales(propertyPay:string): Observable<any>{
        return this.http.get(`${environment.URLServer}/comissions/salePayment/${propertyPay}`);
      }
      reporteCoordEmail(eMail:string): Observable<any>{
        return this.http.get(`${environment.URLServer}/comissions/email/${eMail}`);
      }
}