import { Component, OnInit } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { ControComissionsService } from 'src/app/services/contro-comissions.service';
import { LogosService } from 'src/app/services/logos.service';
import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { GeneralReportService } from 'src/app/services/generalReport.service';
import { ProjectService } from 'src/app/services/project.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { LabelOptions } from 'chartjs-plugin-datalabels/types/options';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogViewBlockedComponent } from '../dialogViewBlocked/dialog-view-blocked/dialog-view-blocked.component';
import { TouchSequence } from 'selenium-webdriver';
import { PaymentService } from 'src/app/services/payment.service';
import { DialogWalletComponent } from '../../wallet/dialog-wallet/dialog-wallet.component';
import { ReportService } from 'src/app/services/report.service';
import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';
import { DialogNotesComponent } from '../../wallet/dialog-notes/dialog-notes.component';

@Component({
  selector: 'app-general-view',
  templateUrl: './general-view.component.html',
  styleUrls: ['./general-view.component.scss']
})
export class GeneralViewComponent implements OnInit {
  private _formBuilder: any;

  bannerTitle: BannerTitle ={
    title: "Reporte de Cobranza diegugu",
    icon: "linear_scale",
    position: "center",
    background : "var(--M19Secundary)"
  }

  constructor(
    private logosServ: LogosService,
    private cashCutService: ControComissionsService,
    private reportGeneralService: GeneralReportService,
    private reportService: ReportService,
    public projectService: ProjectService,
    private reportServices: PaymentService,
    private _formBuilder2: FormBuilder,
    public sweet: SweetAlertComponent,


    public dialog: MatDialog,
    //private dialogRef: MatDialogRef<DialogViewBlockedComponent>,
  ) { }


  arrayLogos: any = []; //Almacena el logo que se muestra en pantalla

  decode: any = [];//Variable para el usuario logueago

  //Variables para usar fechas en horario Local es-MX
  pipe = new DatePipe('es-MX');
  currentDate = new Date();

  fechaActual: any | null;
  fechaActualTickets: any | null;

  //------------- banderas para el estado de los menus
  flagStatus = false;
  flagCashCut = false;
  flagProces = false;
  flagCommissions = false;
  flagBlocked = false;
  flagCollections = false;


  //Variables para la extracion de los pagos realizados
  dataPaymets: any = [];
  paymentaToday: any = [];
  salCashToday: any = [];
  typePayment = "";
  totalIngresos = 0;

  //variables para las salidas de efectivo
  salCash: any = [];
  totalEgresos = 0;
  totPayIng = 0;
  totPayEgre = 0;

  //Guardara el total vendido de cada metodo de pago
  cashTotal: any = []
  depositTotal: any = []
  transferTotal: any = []
  creditCardTotal: any = []
  debitCardTotal: any = []
  checkTotal: any = []

  //variables para guardar las sumas totales de cada metodo de pago
  totCash = 0;
  totDeposit = 0;
  totTranser = 0;
  totCreditCard = 0;
  totDebitCard = 0;
  totCheck = 0;
  totNC = 0;
  totNCNR = 0;


  //Variables para usar fechas en horario Local es-MX
  //----------------------------------- Comisiones ---------------------------------
  projects: any = [];

  querysDate: FormGroup = this._formBuilder2.group({
    dateRCC: [,],
    dateComm: [,],
    dateEnd: [,],
    outputs: [,]

  });

  fecSel: any = String || null;
  projSel = "";
  nomenclature = "Ninguno";
  comipaga: any = [];
  comipagaperProject: any = [];

  totAmount = 0;

  //--------------------------------- PROCESO DE VENTA -----------------------------
  filterByProject: any = [];

  //--------------------------------- COBRANZA / FLUJOS ----------------------------
  currentProject: any = [];
  pagosPorCliente: any = [];
  notes : any = [];
  personalInformation = {
    RFC :  null,
    abonado: 0 ,
    advancePayment : 0,
    clientes : "",
    codeProperty : "",
    email : "",
    fechaDato : "",
    firstPayDate  :  null,
    fiscalAddress : "",
    fiscalFolio :  "",
    fiscalRegime: "",
    fiscalpc : "",
    fk_invoiceType : "",
    fkinvoiceType : null,
    mPagadas : 0,
    meses : 0,
    mobile : "",
    monthlyPayment :  0,
    name : "",
    retraso : 0,
    saldo : 0,
    usuarios : "",
    valor : 0 ,
    vendedores : null
  };

  dataCS : any = [];

  counterColors = {
    blue: 0,
    green: 0,
    yellow: 0,
    red: 0
  }

  banderas = {
    general: false,
    personal: false
  }


  //----------------------------------- BLOQUEADOS ---------------------------------
  nameProjectforBlocked = "";
  nomenclatureProjectforBlocked = "";


  volunteersBlocked: any = [];
  breachContractBlocked: any = [];
  errorSaleBlocked: any = [];
  relocationBlocked: any = [];
  direcionBlocked: any = [];

  quantityVolunteersBlocked = 0;

  ngOnInit(): void {
    this.getLogo();
    registerLocaleData(localeEsMx, 'es-MX'); //REGISTRAR LOCAL PARA ESPAÑOL this.currentDate.toISOString()
    this.fechaActual = this.pipe.transform(this.currentDate, ('yyyy-MM-dd'))
    this.querysDate.controls['dateRCC'].setValue(this.pipe.transform(this.currentDate, ('yyyy-MM-dd')));
    this.querysDate.controls['dateComm'].setValue(this.pipe.transform("2021-10-06", ('yyyy-MM-dd')));

    this.fecSel = this.pipe.transform("2021-10-06", ('yyyy-MM-dd'));

    this.getProjects();
    this.getAllPaymentsByCashAndDate()
    this.getAllPaymentsByDepositAndDate();
    this.getAllPaymentsByTransferAndDate();
    this.getAllPaymentsByTDCAndDate();
    this.getAllPaymentsByTDDAndDate();
    this.getAllPaymentsByCheckAndDate();

    this.obtenComisiones();


  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlData')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
        <html>
        <title > ${this.pipe.transform(this.currentDate, 'longDate')!.toUpperCase()} </title>
          
        <head>
            <style>
          
            * {
            margin: 15px;
            padding: 0;
            border: solid  black 2px;
          }
        
          table {
            width: 100%;
          }
        
        
          h4 {
            border-bottom: solid black 1.5px;
          }
        
        
        
          h1{
            text-align:center;
            border-bottom: solid black 3px
          }
        
        
        .information-Header{
            display:flex;
            flex-direction: column-reverse;
            justify-content: space-between;
            
          }
        
        .formatTextDate{
            text-align:left;
          }
          
        .formatTextDate2{
            text-align:right;
        }
        
        .formatTableBorders {
            border: solid black 1.5px;
            border-collapse: collapse;
        }
        
        
        .tableTextHeader{
          text-align:center;
          border: solid black 1.5px;
          padding: 5px;
        }
        
        .tableDataText1{
          text-align:center;
          border: solid black 1.5px;
          padding: 10px;
        }
        
        .tableDataText2{
          text-align:left;
          border: solid black 1.5px;
          padding: 10px;
        }
        
        .tableDataText3{
          text-align:right;
          border: solid black 1.5px;
          padding: 10px;
        }
        
        
        
          .shared-Container{
            display:flex;
            flex-direction:row;
            justify-content:center;
            flex-wrap:wrap;
            width:98%;
            justify-content:space-around;
            border-bottom: solid black 3px;
            margin:1%;
          }
        
          .information-Delivery{
            display:flex;
            flex-direction:column;
            text-align: center;
            font-style: oblique;
            font-weight: bold;
            margin:1%;
            justify-content: center;
          }
        
          .information-Receive{
            display:flex;
            flex-direction:column;
            text-align: center;
            font-style: oblique;
            font-weight: bold;
            margin:1%;
            justify-content: center;
        
          }
        
          .separacion{
            display:flex;
            border-top: solid black 3px;
            border-bottom: solid black 3px;
          }
        
  
            </style>
  
          </head>
  
  
      <body onload="window.print();window.close()">${printContents}</body>
  
        </html>`
    );
    popupWin!.document.close();
  }


  //Obtiene quien es el usuario logueado en el sistema
  getUser() {
    this.decode = jwtDecode(localStorage.getItem("token") || "")
    return this.decode['cliente']
  }

  //Obtiene los logotipos de M19 
  async getLogo() {
    this.logosServ.getAllImagesService().subscribe(
      res => {
        this.arrayLogos = res;
      }
    )
  }

  //------------------------------------------- CAJA --------------------------------------------------------

  dateSelector(event: any) {
    this.fecSel = (event.target.value);

    this.projSel = ""
    this.nomenclature = "Ninguno"

    this.obtenComisiones();
    this.getAllPaymentsByCashAndDate()
    this.getAllPaymentsByDepositAndDate();
    this.getAllPaymentsByTransferAndDate();
    this.getAllPaymentsByTDCAndDate();
    this.getAllPaymentsByTDDAndDate();
    this.getAllPaymentsByCheckAndDate();
  }

  //Efectivo
  async getAllPaymentsByCashAndDate() {

    this.cashCutService.getPaymentsByCashService(this.querysDate.value.dateRCC).subscribe(
      res => {
        this.cashTotal = res;

        this.sumPaymentsByCashAndDate()
      }
    )
  }

  async sumPaymentsByCashAndDate() {
    this.totCash = 0;
    for (let i = 0; i < this.cashTotal.length; i++)
      this.totCash += this.cashTotal[i].cantidadAbono;

    return this.totCash;
  }

  // Deposito
  async getAllPaymentsByDepositAndDate() {

    this.cashCutService.getPaymentsByDepositService(this.querysDate.value.dateRCC).subscribe(
      res => {
        this.depositTotal = res;

        this.sumPaymentsByDepositAndDate();
      }
    )
  }

  async sumPaymentsByDepositAndDate() {
    this.totDeposit = 0;
    for (let i = 0; i < this.depositTotal.length; i++)
      this.totDeposit += this.depositTotal[i].cantidadAbono;

    return this.totDeposit;
  }

  //Transferencia
  async getAllPaymentsByTransferAndDate() {

    this.cashCutService.getPaymentsByTranserService(this.querysDate.value.dateRCC).subscribe(
      res => {
        this.transferTotal = res;
        this.sumPaymentsByTransferAndDate();
      }
    )
  }

  async sumPaymentsByTransferAndDate() {
    this.totTranser = 0;
    for (let i = 0; i < this.transferTotal.length; i++)
      this.totTranser += this.transferTotal[i].cantidadAbono;

    return this.totTranser;
  }

  //TDC
  async getAllPaymentsByTDCAndDate() {

    this.cashCutService.getPaymentsByCreditCardService(this.querysDate.value.dateRCC).subscribe(
      res => {
        this.creditCardTotal = res;
        this.sumPaymentsByTDCAndDate();
      }
    )
  }

  async sumPaymentsByTDCAndDate() {
    this.totCreditCard = 0;
    for (let i = 0; i < this.creditCardTotal.length; i++)
      this.totCreditCard += this.creditCardTotal[i].cantidadAbono;

    return this.totCreditCard;
  }

  //TDD
  async getAllPaymentsByTDDAndDate() {

    this.cashCutService.getPaymentsByDebitCardService(this.querysDate.value.dateRCC).subscribe(
      res => {
        this.debitCardTotal = res;
        this.sumPaymentsByTDDAndDate();
      }
    )
  }

  async sumPaymentsByTDDAndDate() {
    this.totDebitCard = 0;
    for (let i = 0; i < this.debitCardTotal.length; i++)
      this.totDebitCard += this.debitCardTotal[i].cantidadAbono;

    return this.totDebitCard;
  }

  //Cheques
  async getAllPaymentsByCheckAndDate() {

    this.cashCutService.getPaymentsByCheckService(this.querysDate.value.dateRCC).subscribe(
      res => {
        this.checkTotal = res;
        this.sumPaymentsByCheckAndDate();
      }
    )
  }

  async sumPaymentsByCheckAndDate() {
    this.totCheck = 0;
    for (let i = 0; i < this.checkTotal.length; i++)
      this.totCheck += this.checkTotal[i].cantidadAbono;

    return this.totCheck;
  }

  async abrirCorteDeCaja() {
    this.flagCashCut = false;
    this.flagCommissions = false;
    this.flagStatus = false;
    this.flagProces = false;
    this.flagBlocked = false;
    this.flagCollections = false;

    if (this.flagCashCut == false) {

      this.flagCashCut = true;

      //Encabezado
      document.getElementById("containerHead")!.style.width = '0%';
      document.getElementById("containerHead")!.style.height = '0%';
      document.getElementById("containerHead")!.style.transitionDuration = "1s";

      //Imagen de encabezado
      document.getElementById("imgResizable")!.style.width = '0px';
      document.getElementById("imgResizable")!.style.height = '0px';
      document.getElementById("imgResizable")!.style.transitionDuration = "1s";

      //Contenedor de botones
      document.getElementById("menuResizable")!.style.width = '15%';
      document.getElementById("menuResizable")!.style.height = '76vh';
      document.getElementById("menuResizable")!.style.overflow = "scroll";
      document.getElementById("menuResizable")!.style.transitionDuration = "2s";

      //Botones
      document.getElementById("btnCaja")!.style.width = '100%';
      document.getElementById("btnComisiones")!.style.width = '100%';
      document.getElementById("btnProcesoVenta")!.style.width = '100%';
      document.getElementById("btnCobranza")!.style.width = '100%';
      document.getElementById("btnFlujos")!.style.width = '100%';
      document.getElementById("btnBloqueados")!.style.width = '100%';
      document.getElementById("btnVentasMensuales")!.style.width = '100%';
      document.getElementById("btnEjecucionPresupuesto")!.style.width = '100%';
      document.getElementById("btnDisponibilidad")!.style.width = '100%';

      //Menu contenedor
      document.getElementById("windowCashCut")!.style.width = '85%';
      document.getElementById("windowCashCut")!.style.height = '76vh';
      document.getElementById("windowCashCut")!.style.transitionDuration = "1s";
      document.getElementById("windowCashCut")!.style.animationDelay = "1s";

    } else {

    }
  }

  async cerrarCashCut() {

    this.flagCashCut = false;
    this.flagCommissions = false;
    this.flagStatus = false;
    this.flagProces = false;
    this.flagBlocked = false;
    this.flagCollections = false;

    //Contenedor de encabezado
    document.getElementById("containerHead")!.style.width = '100%';
    document.getElementById("containerHead")!.style.height = 'auto';
    document.getElementById("containerHead")!.style.transitionDuration = "2s";
    //document.getElementById("containerHead")!.style.animationDelay = "2s";

    //Imagen de encabezado
    document.getElementById("imgResizable")!.style.width = '400px';
    document.getElementById("imgResizable")!.style.height = '300px';
    document.getElementById("imgResizable")!.style.transitionDuration = "2s";

    //Menu de botones (opciones)
    document.getElementById("menuResizable")!.style.width = '100%';
    document.getElementById("menuResizable")!.style.height = 'auto';
    document.getElementById("menuResizable")!.style.transitionDuration = "1.5s";

    //Boton Caja
    document.getElementById("btnCaja")!.style.width = '25%';
    document.getElementById("btnCaja")!.style.transitionDuration = "1s";

    //Boton Comisiones
    document.getElementById("btnComisiones")!.style.width = '25%';
    document.getElementById("btnComisiones")!.style.transitionDuration = "1s";

    //Boton Proceso de venta
    document.getElementById("btnProcesoVenta")!.style.width = '25%';
    document.getElementById("btnProcesoVenta")!.style.transitionDuration = "1s";

    //Boton cobranz
    document.getElementById("btnCobranza")!.style.width = '25%';
    document.getElementById("btnCobranza")!.style.transitionDuration = "1s";

    //Boton flujos
    document.getElementById("btnFlujos")!.style.width = '25%';
    document.getElementById("btnFlujos")!.style.transitionDuration = "1s";

    //Boton bloqueados
    document.getElementById("btnBloqueados")!.style.width = '25%';
    document.getElementById("btnBloqueados")!.style.transitionDuration = "1s";

    //Boton ventas mensuales
    document.getElementById("btnVentasMensuales")!.style.width = '25%';
    document.getElementById("btnVentasMensuales")!.style.transitionDuration = "1s";

    //Boton ejecucion de presupuesto
    document.getElementById("btnEjecucionPresupuesto")!.style.width = '25%';
    document.getElementById("btnEjecucionPresupuesto")!.style.transitionDuration = "1s";

    //Boton disponibilidad
    document.getElementById("btnDisponibilidad")!.style.width = '25%';
    document.getElementById("btnDisponibilidad")!.style.transitionDuration = "1s";


    //Menu contenedor 
    document.getElementById("windowCashCut")!.style.width = '0';

  }

  //------------------------------------------- COMISIONES --------------------------------------------------------

  async getProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      }
    );
  }

  async filterByProjectAndDate(nom: string, name: string) {
    this.projSel = name;
    this.nomenclature = nom;

    this.reportGeneralService.getTotalperProjectService(this.nomenclature, this.querysDate.value.dateComm).subscribe(
      res => {
        this.comipagaperProject = res;

        this.sumProject();

      }
    );
  }

  async sumProject() {
    this.totAmount = 0;

    for (let uwu = 0; uwu < this.comipagaperProject.length; uwu++) {
      this.totAmount += this.comipagaperProject[uwu].paymentAmount;
    }

    return this.totAmount
  }

  async obtenComisiones() {
    this.comipaga = [];

    this.projSel = ""
    this.nomenclature = ""


    this.reportGeneralService.getTotalService(this.querysDate.value.dateComm).subscribe(
      res => {
        this.comipaga = res;

      }
    )
  }

  // Configuraciones de graficos
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
      display: true,
      fullWidth: true
    },
    showLines: true,
    tooltips: {
      enabled: true,
      caretSize: 0,
      displayColors: true,
      titleFontSize: 5,
      bodyFontSize: 20
    },
    spanGaps: true,
    plugins: {
      datalabels: {
        formatter: (value: any, ctx: any) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };

  public pieChartLabels: Label[] = [`Efectivo`, `Depositos`, `Tranferencias`, `TDC`, `TDD`, `Cheque`, 'Nota de credito', 'N de C no reembolsable'];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [{ backgroundColor: ['#22D003', '#F6CE06', '#E78604', '#FF9402', '#FF0202', '#2B0161', '#050778', '#04868A'] }];
  public piePruebaOptions: ChartDataSets = { showLine: true }

  async abrirComisiones() {
    this.flagCashCut = false;
    this.flagCommissions = false;
    this.flagStatus = false;
    this.flagProces = false;
    this.flagBlocked = false;
    this.flagCollections = false;

    if (this.flagCommissions == false) {

      this.flagCommissions = true;

      //Encabezado
      document.getElementById("containerHead")!.style.width = '0%';
      document.getElementById("containerHead")!.style.height = '0%';
      document.getElementById("containerHead")!.style.transitionDuration = "1s";

      //Imagen de encabezado
      document.getElementById("imgResizable")!.style.width = '0px';
      document.getElementById("imgResizable")!.style.height = '0px';
      document.getElementById("imgResizable")!.style.transitionDuration = "1s";

      //Contenedor de botones
      document.getElementById("menuResizable")!.style.width = '15%';
      document.getElementById("menuResizable")!.style.height = '76vh';
      document.getElementById("menuResizable")!.style.overflow = "scroll";
      document.getElementById("menuResizable")!.style.transitionDuration = "2s";

      //Botones
      document.getElementById("btnCaja")!.style.width = '100%';
      document.getElementById("btnComisiones")!.style.width = '100%';
      document.getElementById("btnProcesoVenta")!.style.width = '100%';
      document.getElementById("btnCobranza")!.style.width = '100%';
      document.getElementById("btnFlujos")!.style.width = '100%';
      document.getElementById("btnBloqueados")!.style.width = '100%';
      document.getElementById("btnVentasMensuales")!.style.width = '100%';
      document.getElementById("btnEjecucionPresupuesto")!.style.width = '100%';
      document.getElementById("btnDisponibilidad")!.style.width = '100%';

      //Menu contenedor
      document.getElementById("windowComisiones")!.style.width = '85%';
      document.getElementById("windowComisiones")!.style.height = '76vh';
      document.getElementById("windowComisiones")!.style.transitionDuration = "2s";

    } else {

    }
  }

  async cerrarComisiones() {
    this.flagCashCut = false;
    this.flagCommissions = false;
    this.flagStatus = false;
    this.flagProces = false;
    this.flagBlocked = false;
    this.flagCollections = false;

    //Contenedor de encabezado
    document.getElementById("containerHead")!.style.width = '100%';
    document.getElementById("containerHead")!.style.height = 'auto';
    document.getElementById("containerHead")!.style.transitionDuration = "2s";
    //document.getElementById("containerHead")!.style.animationDelay = "2s";

    //Imagen de encabezado
    document.getElementById("imgResizable")!.style.width = '400px';
    document.getElementById("imgResizable")!.style.height = '300px';
    document.getElementById("imgResizable")!.style.transitionDuration = "2s";

    //Menu de botones (opciones)
    document.getElementById("menuResizable")!.style.width = '100%';
    document.getElementById("menuResizable")!.style.height = 'auto';
    document.getElementById("menuResizable")!.style.transitionDuration = "1.5s";

    //Boton Caja
    document.getElementById("btnCaja")!.style.width = '25%';
    document.getElementById("btnCaja")!.style.transitionDuration = "1s";

    //Boton Comisiones
    document.getElementById("btnComisiones")!.style.width = '25%';
    document.getElementById("btnComisiones")!.style.transitionDuration = "1s";

    //Boton Proceso de venta
    document.getElementById("btnProcesoVenta")!.style.width = '25%';
    document.getElementById("btnProcesoVenta")!.style.transitionDuration = "1s";

    //Boton cobranz
    document.getElementById("btnCobranza")!.style.width = '25%';
    document.getElementById("btnCobranza")!.style.transitionDuration = "1s";

    //Boton flujos
    document.getElementById("btnFlujos")!.style.width = '25%';
    document.getElementById("btnFlujos")!.style.transitionDuration = "1s";

    //Boton bloqueados
    document.getElementById("btnBloqueados")!.style.width = '25%';
    document.getElementById("btnBloqueados")!.style.transitionDuration = "1s";

    //Boton ventas mensuales
    document.getElementById("btnVentasMensuales")!.style.width = '25%';
    document.getElementById("btnVentasMensuales")!.style.transitionDuration = "1s";

    //Boton ejecucion de presupuesto
    document.getElementById("btnEjecucionPresupuesto")!.style.width = '25%';
    document.getElementById("btnEjecucionPresupuesto")!.style.transitionDuration = "1s";

    //Boton disponibilidad
    document.getElementById("btnDisponibilidad")!.style.width = '25%';
    document.getElementById("btnDisponibilidad")!.style.transitionDuration = "1s";

    //Menu contenedor 
    document.getElementById("windowComisiones")!.style.width = '0';

  }

  //------------------------------------------- PROCESO DE VENTA --------------------------------------------------
  abrirProcesoVenta() {
    this.flagCashCut = false;
    this.flagCommissions = false;
    this.flagStatus = false;
    this.flagProces = false;
    this.flagBlocked = false;
    this.flagCollections = false;

    if (this.flagProces == false) {

      this.flagProces = true;

      //Encabezado
      document.getElementById("containerHead")!.style.width = '0%';
      document.getElementById("containerHead")!.style.height = '0%';
      document.getElementById("containerHead")!.style.transitionDuration = "1s";

      //Imagen de encabezado
      document.getElementById("imgResizable")!.style.width = '0px';
      document.getElementById("imgResizable")!.style.height = '0px';
      document.getElementById("imgResizable")!.style.transitionDuration = "1s";

      //Contenedor de botones
      document.getElementById("menuResizable")!.style.width = '15%';
      document.getElementById("menuResizable")!.style.height = '76vh';
      document.getElementById("menuResizable")!.style.overflow = "scroll";
      document.getElementById("menuResizable")!.style.transitionDuration = "2s";

      //Botones
      document.getElementById("btnCaja")!.style.width = '100%';
      document.getElementById("btnComisiones")!.style.width = '100%';
      document.getElementById("btnProcesoVenta")!.style.width = '100%';
      document.getElementById("btnCobranza")!.style.width = '100%';
      document.getElementById("btnFlujos")!.style.width = '100%';
      document.getElementById("btnBloqueados")!.style.width = '100%';
      document.getElementById("btnVentasMensuales")!.style.width = '100%';
      document.getElementById("btnEjecucionPresupuesto")!.style.width = '100%';
      document.getElementById("btnDisponibilidad")!.style.width = '100%';

      //Menu contenedor
      document.getElementById("windowProcesoVenta")!.style.width = '85%';
      document.getElementById("windowProcesoVenta")!.style.height = '76vh';
      document.getElementById("windowProcesoVenta")!.style.transitionDuration = "2s";
    } else {

    }
  }

  cerrarProcesoVenta() {
    this.flagCashCut = false;
    this.flagCommissions = false;
    this.flagStatus = false;
    this.flagProces = false;
    this.flagBlocked = false;
    this.flagCollections = false;

    //Contenedor de encabezado
    document.getElementById("containerHead")!.style.width = '100%';
    document.getElementById("containerHead")!.style.height = 'auto';
    document.getElementById("containerHead")!.style.transitionDuration = "2s";
    //document.getElementById("containerHead")!.style.animationDelay = "2s";

    //Imagen de encabezado
    document.getElementById("imgResizable")!.style.width = '400px';
    document.getElementById("imgResizable")!.style.height = '300px';
    document.getElementById("imgResizable")!.style.transitionDuration = "2s";

    //Menu de botones (opciones)
    document.getElementById("menuResizable")!.style.width = '100%';
    document.getElementById("menuResizable")!.style.height = 'auto';
    document.getElementById("menuResizable")!.style.transitionDuration = "1.5s";

    //Boton Caja
    document.getElementById("btnCaja")!.style.width = '25%';
    document.getElementById("btnCaja")!.style.transitionDuration = "1s";

    //Boton Comisiones
    document.getElementById("btnComisiones")!.style.width = '25%';
    document.getElementById("btnComisiones")!.style.transitionDuration = "1s";

    //Boton Proceso de venta
    document.getElementById("btnProcesoVenta")!.style.width = '25%';
    document.getElementById("btnProcesoVenta")!.style.transitionDuration = "1s";

    //Boton cobranz
    document.getElementById("btnCobranza")!.style.width = '25%';
    document.getElementById("btnCobranza")!.style.transitionDuration = "1s";

    //Boton flujos
    document.getElementById("btnFlujos")!.style.width = '25%';
    document.getElementById("btnFlujos")!.style.transitionDuration = "1s";

    //Boton bloqueados
    document.getElementById("btnBloqueados")!.style.width = '25%';
    document.getElementById("btnBloqueados")!.style.transitionDuration = "1s";

    //Boton ventas mensuales
    document.getElementById("btnVentasMensuales")!.style.width = '25%';
    document.getElementById("btnVentasMensuales")!.style.transitionDuration = "1s";

    //Boton ejecucion de presupuesto
    document.getElementById("btnEjecucionPresupuesto")!.style.width = '25%';
    document.getElementById("btnEjecucionPresupuesto")!.style.transitionDuration = "1s";

    //Boton disponibilidad
    document.getElementById("btnDisponibilidad")!.style.width = '25%';
    document.getElementById("btnDisponibilidad")!.style.transitionDuration = "1s";

    //Menu contenedor 
    document.getElementById("windowProcesoVenta")!.style.width = '0';
  }

  //-------------------------------------------- COBRANZA  --------------------------------------------------------
  abrirCobranza() {
    this.flagCashCut = false;
    this.flagCommissions = false;
    this.flagStatus = false;
    this.flagProces = false;
    this.flagBlocked = false;
    this.flagCollections = false;

    if (this.flagCollections == false) {

      this.flagCollections = true;

      //Encabezado
      document.getElementById("containerHead")!.style.width = '0%';
      document.getElementById("containerHead")!.style.height = '0%';
      document.getElementById("containerHead")!.style.transitionDuration = "1s";

      //Imagen de encabezado
      document.getElementById("imgResizable")!.style.width = '0px';
      document.getElementById("imgResizable")!.style.height = '0px';
      document.getElementById("imgResizable")!.style.transitionDuration = "1s";

      //Contenedor de botones
      document.getElementById("menuResizable")!.style.width = '15%';
      document.getElementById("menuResizable")!.style.height = '76vh';
      document.getElementById("menuResizable")!.style.overflow = "scroll";
      document.getElementById("menuResizable")!.style.transitionDuration = "2s";

      //Botones
      document.getElementById("btnCaja")!.style.width = '100%';
      document.getElementById("btnComisiones")!.style.width = '100%';
      document.getElementById("btnProcesoVenta")!.style.width = '100%';
      document.getElementById("btnCobranza")!.style.width = '100%';
      document.getElementById("btnFlujos")!.style.width = '100%';
      document.getElementById("btnBloqueados")!.style.width = '100%';
      document.getElementById("btnVentasMensuales")!.style.width = '100%';
      document.getElementById("btnEjecucionPresupuesto")!.style.width = '100%';
      document.getElementById("btnDisponibilidad")!.style.width = '100%';

      //Menu contenedor
      document.getElementById("windowCobranza")!.style.width = '85%';
      document.getElementById("windowCobranza")!.style.height = '76vh';
      document.getElementById("windowCobranza")!.style.transitionDuration = "1s";
      document.getElementById("windowCobranza")!.style.animationDelay = "1s";

    } else {

    }
  }

  async cerrarCobranza() {

    this.flagCashCut = false;
    this.flagCommissions = false;
    this.flagStatus = false;
    this.flagProces = false;
    this.flagBlocked = false;
    this.flagCollections = false;

    //Contenedor de encabezado
    document.getElementById("containerHead")!.style.width = '100%';
    document.getElementById("containerHead")!.style.height = 'auto';
    document.getElementById("containerHead")!.style.transitionDuration = "2s";
    //document.getElementById("containerHead")!.style.animationDelay = "2s";

    //Imagen de encabezado
    document.getElementById("imgResizable")!.style.width = '400px';
    document.getElementById("imgResizable")!.style.height = '300px';
    document.getElementById("imgResizable")!.style.transitionDuration = "2s";

    //Menu de botones (opciones)
    document.getElementById("menuResizable")!.style.width = '100%';
    document.getElementById("menuResizable")!.style.height = 'auto';
    document.getElementById("menuResizable")!.style.transitionDuration = "1.5s";

    //Boton Caja
    document.getElementById("btnCaja")!.style.width = '25%';
    document.getElementById("btnCaja")!.style.transitionDuration = "1s";

    //Boton Comisiones
    document.getElementById("btnComisiones")!.style.width = '25%';
    document.getElementById("btnComisiones")!.style.transitionDuration = "1s";

    //Boton Proceso de venta
    document.getElementById("btnProcesoVenta")!.style.width = '25%';
    document.getElementById("btnProcesoVenta")!.style.transitionDuration = "1s";

    //Boton cobranz
    document.getElementById("btnCobranza")!.style.width = '25%';
    document.getElementById("btnCobranza")!.style.transitionDuration = "1s";

    //Boton flujos
    document.getElementById("btnFlujos")!.style.width = '25%';
    document.getElementById("btnFlujos")!.style.transitionDuration = "1s";

    //Boton bloqueados
    document.getElementById("btnBloqueados")!.style.width = '25%';
    document.getElementById("btnBloqueados")!.style.transitionDuration = "1s";

    //Boton ventas mensuales
    document.getElementById("btnVentasMensuales")!.style.width = '25%';
    document.getElementById("btnVentasMensuales")!.style.transitionDuration = "1s";

    //Boton ejecucion de presupuesto
    document.getElementById("btnEjecucionPresupuesto")!.style.width = '25%';
    document.getElementById("btnEjecucionPresupuesto")!.style.transitionDuration = "1s";

    //Boton disponibilidad
    document.getElementById("btnDisponibilidad")!.style.width = '25%';
    document.getElementById("btnDisponibilidad")!.style.transitionDuration = "1s";


    //Menu contenedor 
    document.getElementById("windowCobranza")!.style.width = '0';

  }

  async filterWalletByProject(namePro: string) {
    this.currentProject = namePro
    this.banderas.general = true;
    this.banderas.personal = false;


    this.reportGeneralService.getReportWalletByProjectService(namePro).subscribe(
      res => {
        this.filterByProject = res;
        this.typeAssignment();


      }
    )
  }

  async mueveData(codeProperty: string) {
    
    this.reportService.carteraInmueblePersonalService(codeProperty).subscribe(
      res => {
            this.dataCS = res;
                      
            this.banderas.general = false;
            this.banderas.personal = true; 

      }
    )

  }

  async selectPayments(codeProperty: string){
    this.reportServices.selectPayments(codeProperty).subscribe(
      res => {
        this.pagosPorCliente = res;     
      
      }
    )
  }

  selectPropertyNote(codeProperty: any){
    this.reportService.notes(codeProperty).subscribe(
      res => {
        this.notes = res;
        localStorage.setItem('property',codeProperty)
        this.dialog.open(DialogNotesComponent, {
          width: '500px',
          data: res
        });
      },
      err => console.log(err)
    )
  }

  selectNotes(codeProperty : string){
    this.reportService.notes(codeProperty).subscribe(
      res => {
        localStorage.setItem('property', codeProperty) 
        this.notes = res
        this.dialog.open(DialogWalletComponent, {
          width: '700px',
          data: res
        })
      },
      err => console.log(err)
    )
  }

  async typeAssignment() {
    this.counterColors = {
      blue: 0,
      green: 0,
      yellow: 0,
      red: 0
    }

    for (let i = 0; i < this.filterByProject.length; i++) {

      if (this.filterByProject[i].retraso >= 0) {
        this.counterColors.blue += 1
      }
      else if (this.filterByProject[i].retraso < 0 && this.filterByProject[i].retraso > -2) {
        this.counterColors.green += 1
      } else if (this.filterByProject[i].retraso <= -2 && this.filterByProject[i].retraso > -4) {
        this.counterColors.yellow += 1
      } else if (this.filterByProject[i].retraso <= -4) {
        this.counterColors.red += 1
      }
    }

  }

  // Configuraciones de graficos
  public pieChartOption: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
      display: false,
      fullWidth: true,

    },
    showLines: true,
    tooltips: {
      enabled: true,
      caretSize: 0,
      displayColors: true,
      titleFontSize: 5,
      bodyFontSize: 20
    },
    spanGaps: true,
    plugins: {
      datalabels: {
        formatter: (value: any, ctx: any) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };

  public pieChartLabel: Label[] = [`Al Corriente`, `1 Atraso`, `2 a 4 Atrasos`, `+4 Atrasos`];
  public pieChartTypes: ChartType = 'pie';
  public pieChartLegends = true;
  public pieChartPlugin = [pluginDataLabels];
  public pieChartColor = [{ backgroundColor: ['#04868A', '#22D003', '#F6CE06', '#FF0202',] }];
  public pieChartWidth = [{}];


  //------------------------------------------- BLOQUEADOS --------------------------------------------------------

  async filterBlockedByProject(nameProject: string, nomenclature: string) {
    this.nameProjectforBlocked = nameProject;
    this.nomenclatureProjectforBlocked = nomenclature;


    this.reportGeneralService.getVoluntaryBlockedService(nomenclature).subscribe(
      res => {
        this.volunteersBlocked = res;

      }
    )

    this.reportGeneralService.getBreachContractService(nomenclature).subscribe(
      res => {
        this.breachContractBlocked = res;

      }
    )

    this.reportGeneralService.getSalesErrorBlockedService(nomenclature).subscribe(
      res => {
        this.errorSaleBlocked = res;

      }
    )

    this.reportGeneralService.getRelocationBlockedService(nomenclature).subscribe(
      res => {
        this.relocationBlocked = res;

      }
    )

    this.reportGeneralService.getGeneralManagementBlockedService(nomenclature).subscribe(
      res => {
        this.direcionBlocked = res;
      }
    )

  }


  async totVolunteers() {
    this.quantityVolunteersBlocked = 0;

    this.quantityVolunteersBlocked = this.volunteersBlocked.length;

  }

  clientesBloqueados: any = [];
  dataExtra: any = [];
  reason = "";

  async captacionData(data2: any, reason: string) {

    this.clientesBloqueados = [];
    this.reason = "";
    this.clientesBloqueados = data2;
    this.reason = reason;

    this.openDialogOptionProject();
  }

  async openDialogOptionProject() {
    this.dataExtra = { proje: this.nameProjectforBlocked, nomen: this.nomenclatureProjectforBlocked, block: this.clientesBloqueados, reason: this.reason }

    this.dialog.open(DialogViewBlockedComponent
      , {
        width: '90%',
        data: this.dataExtra

      });

    // dialogo1.afterClosed();
  }

  async abrirBloqueados() {
    this.flagCashCut = false;
    this.flagCommissions = false;
    this.flagStatus = false;
    this.flagProces = false;
    this.flagBlocked = false;
    this.flagCollections = false;

    if (this.flagBlocked == false) {

      this.flagBlocked = true;

      //Encabezado
      document.getElementById("containerHead")!.style.width = '0%';
      document.getElementById("containerHead")!.style.height = '0%';
      document.getElementById("containerHead")!.style.transitionDuration = "1s";

      //Imagen de encabezado
      document.getElementById("imgResizable")!.style.width = '0px';
      document.getElementById("imgResizable")!.style.height = '0px';
      document.getElementById("imgResizable")!.style.transitionDuration = "1s";

      //Contenedor de botones
      document.getElementById("menuResizable")!.style.width = '15%';
      document.getElementById("menuResizable")!.style.height = '76vh';
      document.getElementById("menuResizable")!.style.overflow = "scroll";
      document.getElementById("menuResizable")!.style.transitionDuration = "2s";

      //Botones
      document.getElementById("btnCaja")!.style.width = '100%';
      document.getElementById("btnComisiones")!.style.width = '100%';
      document.getElementById("btnProcesoVenta")!.style.width = '100%';
      document.getElementById("btnCobranza")!.style.width = '100%';
      document.getElementById("btnFlujos")!.style.width = '100%';
      document.getElementById("btnBloqueados")!.style.width = '100%';
      document.getElementById("btnVentasMensuales")!.style.width = '100%';
      document.getElementById("btnEjecucionPresupuesto")!.style.width = '100%';
      document.getElementById("btnDisponibilidad")!.style.width = '100%';

      //Menu contenedor
      document.getElementById("windowBloqueados")!.style.width = '85%';
      document.getElementById("windowBloqueados")!.style.height = '76vh';
      document.getElementById("windowBloqueados")!.style.transitionDuration = "2s";
    } else {

    }
  }

  async cerrarBloqueados() {
    this.flagCashCut = false;
    this.flagCommissions = false;
    this.flagStatus = false;
    this.flagProces = false;
    this.flagBlocked = false;
    this.flagCollections = false;

    //Contenedor de encabezado
    document.getElementById("containerHead")!.style.width = '100%';
    document.getElementById("containerHead")!.style.height = 'auto';
    document.getElementById("containerHead")!.style.transitionDuration = "2s";
    //document.getElementById("containerHead")!.style.animationDelay = "2s";

    //Imagen de encabezado
    document.getElementById("imgResizable")!.style.width = '400px';
    document.getElementById("imgResizable")!.style.height = '300px';
    document.getElementById("imgResizable")!.style.transitionDuration = "2s";

    //Menu de botones (opciones)
    document.getElementById("menuResizable")!.style.width = '100%';
    document.getElementById("menuResizable")!.style.height = 'auto';
    document.getElementById("menuResizable")!.style.transitionDuration = "1.5s";

    //Boton Caja
    document.getElementById("btnCaja")!.style.width = '25%';
    document.getElementById("btnCaja")!.style.transitionDuration = "1s";

    //Boton Comisiones
    document.getElementById("btnComisiones")!.style.width = '25%';
    document.getElementById("btnComisiones")!.style.transitionDuration = "1s";

    //Boton Proceso de venta
    document.getElementById("btnProcesoVenta")!.style.width = '25%';
    document.getElementById("btnProcesoVenta")!.style.transitionDuration = "1s";

    //Boton cobranz
    document.getElementById("btnCobranza")!.style.width = '25%';
    document.getElementById("btnCobranza")!.style.transitionDuration = "1s";

    //Boton flujos
    document.getElementById("btnFlujos")!.style.width = '25%';
    document.getElementById("btnFlujos")!.style.transitionDuration = "1s";

    //Boton bloqueados
    document.getElementById("btnBloqueados")!.style.width = '25%';
    document.getElementById("btnBloqueados")!.style.transitionDuration = "1s";

    //Boton ventas mensuales
    document.getElementById("btnVentasMensuales")!.style.width = '25%';
    document.getElementById("btnVentasMensuales")!.style.transitionDuration = "1s";

    //Boton ejecucion de presupuesto
    document.getElementById("btnEjecucionPresupuesto")!.style.width = '25%';
    document.getElementById("btnEjecucionPresupuesto")!.style.transitionDuration = "1s";

    //Boton disponibilidad
    document.getElementById("btnDisponibilidad")!.style.width = '25%';
    document.getElementById("btnDisponibilidad")!.style.transitionDuration = "1s";

    //Menu contenedor 
    document.getElementById("windowBloqueados")!.style.width = '0';

  }

  //---------------------------------------------  VENTAS MENSUALES --------------------------------------------------------

  async abrirVentasMensuales() {
    this.flagCashCut = false;
    this.flagCommissions = false;
    this.flagStatus = false;
    this.flagProces = false;
    this.flagBlocked = false;
    this.flagCollections = false;

    if (this.flagBlocked == false) {

      this.flagBlocked = true;

      //Encabezado
      document.getElementById("containerHead")!.style.width = '0%';
      document.getElementById("containerHead")!.style.height = '0%';
      document.getElementById("containerHead")!.style.transitionDuration = "1s";

      //Imagen de encabezado
      document.getElementById("imgResizable")!.style.width = '0px';
      document.getElementById("imgResizable")!.style.height = '0px';
      document.getElementById("imgResizable")!.style.transitionDuration = "1s";

      //Contenedor de botones
      document.getElementById("menuResizable")!.style.width = '15%';
      document.getElementById("menuResizable")!.style.height = '76vh';
      document.getElementById("menuResizable")!.style.overflow = "scroll";
      document.getElementById("menuResizable")!.style.transitionDuration = "2s";

      //Botones
      document.getElementById("btnCaja")!.style.width = '100%';
      document.getElementById("btnComisiones")!.style.width = '100%';
      document.getElementById("btnProcesoVenta")!.style.width = '100%';
      document.getElementById("btnCobranza")!.style.width = '100%';
      document.getElementById("btnFlujos")!.style.width = '100%';
      document.getElementById("btnBloqueados")!.style.width = '100%';
      document.getElementById("btnVentasMensuales")!.style.width = '100%';
      document.getElementById("btnEjecucionPresupuesto")!.style.width = '100%';
      document.getElementById("btnDisponibilidad")!.style.width = '100%';

      //Menu contenedor
      document.getElementById("windowBloqueados")!.style.width = '85%';
      document.getElementById("windowBloqueados")!.style.height = '76vh';
      document.getElementById("windowBloqueados")!.style.transitionDuration = "2s";
    } else {

    }
  }


  async cerrarVentasMensuales() {
    this.flagCashCut = false;
    this.flagCommissions = false;
    this.flagStatus = false;
    this.flagProces = false;
    this.flagBlocked = false;
    this.flagCollections = false;

    //Contenedor de encabezado
    document.getElementById("containerHead")!.style.width = '100%';
    document.getElementById("containerHead")!.style.height = 'auto';
    document.getElementById("containerHead")!.style.transitionDuration = "2s";
    //document.getElementById("containerHead")!.style.animationDelay = "2s";

    //Imagen de encabezado
    document.getElementById("imgResizable")!.style.width = '400px';
    document.getElementById("imgResizable")!.style.height = '300px';
    document.getElementById("imgResizable")!.style.transitionDuration = "2s";

    //Menu de botones (opciones)
    document.getElementById("menuResizable")!.style.width = '100%';
    document.getElementById("menuResizable")!.style.height = 'auto';
    document.getElementById("menuResizable")!.style.transitionDuration = "1.5s";

    //Boton Caja
    document.getElementById("btnCaja")!.style.width = '25%';
    document.getElementById("btnCaja")!.style.transitionDuration = "1s";

    //Boton Comisiones
    document.getElementById("btnComisiones")!.style.width = '25%';
    document.getElementById("btnComisiones")!.style.transitionDuration = "1s";

    //Boton Proceso de venta
    document.getElementById("btnProcesoVenta")!.style.width = '25%';
    document.getElementById("btnProcesoVenta")!.style.transitionDuration = "1s";

    //Boton cobranz
    document.getElementById("btnCobranza")!.style.width = '25%';
    document.getElementById("btnCobranza")!.style.transitionDuration = "1s";

    //Boton flujos
    document.getElementById("btnFlujos")!.style.width = '25%';
    document.getElementById("btnFlujos")!.style.transitionDuration = "1s";

    //Boton bloqueados
    document.getElementById("btnBloqueados")!.style.width = '25%';
    document.getElementById("btnBloqueados")!.style.transitionDuration = "1s";

    //Boton ventas mensuales
    document.getElementById("btnVentasMensuales")!.style.width = '25%';
    document.getElementById("btnVentasMensuales")!.style.transitionDuration = "1s";

    //Boton ejecucion de presupuesto
    document.getElementById("btnEjecucionPresupuesto")!.style.width = '25%';
    document.getElementById("btnEjecucionPresupuesto")!.style.transitionDuration = "1s";

    //Boton disponibilidad
    document.getElementById("btnDisponibilidad")!.style.width = '25%';
    document.getElementById("btnDisponibilidad")!.style.transitionDuration = "1s";

    //Menu contenedor 
    document.getElementById("windowBloqueados")!.style.width = '0';

  }



  //---------------------------------------------  GENERICOS --------------------------------------------------------
  modicarWidth() {
    this.flagCashCut = false;
    this.flagCommissions = false;
    this.flagStatus = false;
    this.flagProces = false;
    this.flagBlocked = false;
    this.flagCollections = false;

    if (this.flagStatus == false) {
      this.flagStatus = true;
      //Encabezado
      document.getElementById("containerHead")!.style.width = '0%';
      document.getElementById("containerHead")!.style.height = '0%';
      document.getElementById("containerHead")!.style.transitionDuration = "2s, 5s";
      document.getElementById("containerHead")!.style.animationDelay = "2s";

      //Imagen de encabezado
      document.getElementById("imgResizable")!.style.width = '0px';
      document.getElementById("imgResizable")!.style.height = '0px';
      document.getElementById("imgResizable")!.style.animationDelay = "2s";
      document.getElementById("imgResizable")!.style.transitionDuration = "2s, 5s";

      //Menu de botones
      document.getElementById("menuResizable")!.style.width = '15%';
      document.getElementById("menuResizable")!.style.height = '76vh';
      document.getElementById("menuResizable")!.style.overflow = "scroll";
      document.getElementById("menuResizable")!.style.transitionDuration = "2s, 5s";
      document.getElementById("menuResizable")!.style.animationDelay = "2s";

      //Boton caja
      document.getElementById("btnCaja")!.style.width = '100%';
      document.getElementById("btnCaja")!.style.transitionDuration = "3s, 5s";
      document.getElementById("btnCaja")!.style.animationDelay = "2s";
      //Boton comisiones
      document.getElementById("btnComisiones")!.style.width = '100%';
      document.getElementById("btnComisiones")!.style.transitionDuration = "3s, 5s";
      document.getElementById("btnComisiones")!.style.animationDelay = "2s";
      //Boton proceso de venta
      document.getElementById("btnProcesoVenta")!.style.width = '100%';
      document.getElementById("btnProcesoVenta")!.style.transitionDuration = "3s, 5s";
      document.getElementById("btnProcesoVenta")!.style.animationDelay = "2s";
      //Boton cobranza
      document.getElementById("btnCobranza")!.style.width = '100%';
      document.getElementById("btnCobranza")!.style.transitionDuration = "3s, 5s";
      document.getElementById("btnCobranza")!.style.animationDelay = "2s";
      //Boton flujos
      document.getElementById("btnFlujos")!.style.width = '100%';
      document.getElementById("btnFlujos")!.style.transitionDuration = "3s, 5s";
      document.getElementById("btnFlujos")!.style.animationDelay = "2s";
      //Boton bloqueados
      document.getElementById("btnBloqueados")!.style.width = '100%';
      document.getElementById("btnBloqueados")!.style.transitionDuration = "3s, 5s";
      document.getElementById("btnBloqueados")!.style.animationDelay = "2s";
      //Boton ventas mensuales
      document.getElementById("btnVentasMensuales")!.style.width = '100%';
      document.getElementById("btnVentasMensuales")!.style.transitionDuration = "3s, 5s";
      document.getElementById("btnVentasMensuales")!.style.animationDelay = "2s";
      //Boton ejecucion de presupuesto
      document.getElementById("btnEjecucionPresupuesto")!.style.width = '100%';
      document.getElementById("btnEjecucionPresupuesto")!.style.transitionDuration = "3s, 5s";
      document.getElementById("btnEjecucionPresupuesto")!.style.animationDelay = "2s";
      //Boton disponibilidad
      document.getElementById("btnDisponibilidad")!.style.width = '100%';
      document.getElementById("btnDisponibilidad")!.style.transitionDuration = "3s, 5s";
      document.getElementById("btnDisponibilidad")!.style.animationDelay = "2s";

      //Menu contenedor
      document.getElementById("windowResizable")!.style.width = '85%';
      document.getElementById("windowResizable")!.style.height = '76vh';
      document.getElementById("windowResizable")!.style.animationDelay = "2s";
      document.getElementById("windowResizable")!.style.transitionDuration = "2s, 5s";
    } else {

    }
  }

  modicarWidth2() {
    this.flagCashCut = false;
    this.flagCommissions = false;
    this.flagStatus = false;
    this.flagProces = false;
    this.flagBlocked = false;
    this.flagCollections = false;


    document.getElementById("menuResizable")!.style.width = '100%';
    document.getElementById("menuResizable")!.style.height = 'auto';
    document.getElementById("menuResizable")!.style.justifyContent = 'space-evently';
    document.getElementById("menuResizable")!.style.animationDelay = "1s";
    document.getElementById("menuResizable")!.style.transitionDuration = "1s";


    document.getElementById("containerHead")!.style.width = '100%';
    document.getElementById("containerHead")!.style.height = 'auto';
    document.getElementById("containerHead")!.style.transitionDuration = "2s, 5s";
    document.getElementById("containerHead")!.style.animationDelay = "2s";

    document.getElementById("btnCaja")!.style.width = '25%';
    document.getElementById("btnCaja")!.style.transitionDuration = "2s";
    document.getElementById("btnCaja")!.style.animationDelay = "2s";

    document.getElementById("btnCobranza")!.style.width = '25%';
    document.getElementById("btnCobranza")!.style.transitionDuration = "2s";
    document.getElementById("btnCobranza")!.style.animationDelay = "2s";

    document.getElementById("btnEstatus")!.style.width = '25%';
    document.getElementById("btnEstatus")!.style.transitionDuration = "2s";
    document.getElementById("btnEstatus")!.style.animationDelay = "2s";

    document.getElementById("btnFlujos")!.style.width = '25%';
    document.getElementById("btnFlujos")!.style.transitionDuration = "2s";
    document.getElementById("btnFlujos")!.style.animationDelay = "2s";

    document.getElementById("btnComisiones")!.style.width = '25%';
    document.getElementById("btnComisiones")!.style.transitionDuration = "2s";
    document.getElementById("btnComisiones")!.style.animationDelay = "2s";

    document.getElementById("btnDisponibilidad")!.style.width = '25%';
    document.getElementById("btnDisponibilidad")!.style.transitionDuration = "2s";
    document.getElementById("btnDisponibilidad")!.style.animationDelay = "2s";

    document.getElementById("btnVentasMensuales")!.style.width = '25%';
    document.getElementById("btnVentasMensuales")!.style.transitionDuration = "2s";
    document.getElementById("btnVentasMensuales")!.style.animationDelay = "2s";

    document.getElementById("btnProcesoVenta")!.style.width = '25%';
    document.getElementById("btnProcesoVenta")!.style.transitionDuration = "2s";
    document.getElementById("btnProcesoVenta")!.style.animationDelay = "2s";

    document.getElementById("btnEjecucionPresupuesto")!.style.width = '25%';
    document.getElementById("btnEjecucionPresupuesto")!.style.transitionDuration = "2s";
    document.getElementById("btnEjecucionPresupuesto")!.style.animationDelay = "2s";

    document.getElementById("btnBloqueados")!.style.width = '25%';
    document.getElementById("btnBloqueados")!.style.transitionDuration = "2s";
    document.getElementById("btnBloqueados")!.style.animationDelay = "2s";

    document.getElementById("imgResizable")!.style.width = '300px';
    document.getElementById("imgResizable")!.style.height = '200px';
    document.getElementById("imgResizable")!.style.animationDelay = "1s";
    document.getElementById("imgResizable")!.style.transitionDuration = "2s, 5s";


    document.getElementById("windowResizable")!.style.width = '0%';
    document.getElementById("windowResizable")!.style.height = 'auto';
    document.getElementById("windowResizable")!.style.transitionDuration = "2s, 5s";
    document.getElementById("windowResizable")!.style.animationDelay = "2s";
    // document.getElementById("imgResizable")!.hidden = false;
  }


}//GeneralViewComponent
