<mat-dialog-content>
    <h5 class="titleText" style="text-align: center;">Historial de lead</h5>
    <div class="timelineContainer">
        <div class="timelineContainer__timeStamp" *ngFor="let a of actions; index as i;">
            <div *ngIf="a.action=='Registro'" class="timelineContainer__timeStamp__action">
                <mat-icon>add_circle</mat-icon>
                <span class="timelineContainer__timeStamp__action__message">
                    <b>Registrado por {{a.responsable}}</b>
                    el {{pipe.transform(a.registrationDate,'fullDate')}}
                </span>
            </div>
            <div *ngIf="a.action=='Asignado'" class="timelineContainer__timeStamp__action">
                <mat-icon>person_add</mat-icon>
                <span class="timelineContainer__timeStamp__action__message">
                    <b>Asignado a {{a.secondResponsable}}</b>
                    por {{a.responsable}}<br>
                    el {{pipe.transform(a.registrationDate,'fullDate')}}
                </span>
            </div>
            <div *ngIf="a.action=='Comentario'" class="timelineContainer__timeStamp__action">
                <mat-icon>chat</mat-icon>
                <span class="timelineContainer__timeStamp__action__message">
                    <b>Comentario de {{a.responsable}}</b>
                    el {{pipe.transform(a.registrationDate,'fullDate')}}<br>
                    <b>{{a.comments}}</b>
                </span>
            </div>
            <div *ngIf="a.action=='Llamada'" class="timelineContainer__timeStamp__action">
                <mat-icon>call</mat-icon>
                <span class="timelineContainer__timeStamp__action__message">
                    <b>{{a.responsable}} realizó la llamada</b>
                    el {{pipe.transform(a.registrationDate,'fullDate')}}<br>
                </span>
            </div>
            <div *ngIf="a.action=='Prórroga'" class="timelineContainer__timeStamp__action">
                <mat-icon>timer</mat-icon>
                <span class="timelineContainer__timeStamp__action__message">
                    <b>{{a.responsable}} otorgó una prórroga</b>
                    el {{pipe.transform(a.registrationDate,'fullDate')}}<br>
                    <b>El mótivo fue el siguiente:</b>"{{a.comments}}"
                </span>
            </div>
            <div *ngIf="a.action=='Reasignado'" class="timelineContainer__timeStamp__action">
                <mat-icon>change_circle</mat-icon>
                <span class="timelineContainer__timeStamp__action__message">
                    <b>Reasignado a {{a.secondResponsable}}</b>
                    por {{a.responsable}}<br>
                    el {{pipe.transform(a.registrationDate,'fullDate')}}
                    <b>Comentarios:</b>
                    "{{a.comments}}"
                </span>
            </div>
            <span *ngIf="i!=(actions.length-1)" class="timelineContainer__timeStamp__connection">
                <mat-icon>keyboard_double_arrow_down</mat-icon>
            </span>
        </div>
    </div>
</mat-dialog-content>