<mat-dialog-content>
    <form class="row mt-3" [formGroup]="suppliersFormGroup" (ngSubmit)="insertSupplier()">

        <h5 class="col-12 pb-2">Nuevo proveedor</h5>

        <mat-form-field appearance="outline" class="col-md-12">
            <mat-label>Nombre</mat-label>
            <input matInput type="text" formControlName="suppliername" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-12">
            <mat-label>RFC</mat-label>
            <input matInput type="text" formControlName="RFC" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-12">
            <mat-label>Dirección</mat-label>
            <input matInput type="text" formControlName="direction" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-12">
            <mat-label>Telefono</mat-label>
            <input matInput type="text" formControlName="phone" required>
        </mat-form-field>

        <div class="col-12 text-right mt-3">
            <button class="btn btn-m19" type="submit" [disabled]="!suppliersFormGroup.valid">
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </form>
</mat-dialog-content>