<mat-dialog-content [formGroup]="saleFormGroup">
    <h1 style="text-align:center">Confirmacion de venta</h1>

    <h2>Favor de revisar que los datos del cliente, el inmueble y la solicitud sean correctos: </h2>

    <mat-accordion>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <b>
                        Solicitud
                    </b>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="container">
                <div class="row">
                    <div id="htmlData">

                        <img style=" display: block; margin-left: auto;   margin-right: auto;  width: 10%;"
                            class="center" alt="Logo" [src]="logo">

                        <div style="width: 100%; border: solid 1px black; text-align: center; font-size: 0.8rem;">
                            SOLICITUD DE ELABORACION DE CONTRATO
                        </div>

                        <br>

                        <div style="display: flex; justify-content: space-between; height: 20px; font-size: 0.8rem;">
                            <div style="width: 200px; border: solid black 1px; vertical-align: center;">
                                FECHA: {{todayWithPipe}}
                            </div>
                            <p style="margin-top: -2px;">
                                Por conducto de representante
                            </p>
                            <div style="width: 100px; border: solid black 1px; ">
                                SI
                            </div>
                            <div style="width: 100px; border: solid black 1px; ">
                                NO
                            </div>
                        </div>

                        <br>

                        <table
                            style="border: 1px solid black; border-collapse: collapse; width: 100%; font-size: 0.8rem;">
                            <tr style="text-align: center;">
                                <td colspan="2">DATOS DEL INMUEBLE</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black; border-collapse: collapse;">DESARROLLO</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">{{propertyInfo.name}}
                                </td>
                            </tr>

                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">MANZANA</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{propertyInfo.codeProperty.substring(3, 5)}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black; border-collapse: collapse;">LOTE</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{propertyInfo.codeProperty.substring(6, 8)}}</td>
                            </tr>
                        </table>


                        <br>


                        <table
                            style="border: 1px solid black; border-collapse: collapse; width: 100%; font-size: 0.8rem;">
                            <tr style="text-align: center;">
                                <td colspan="4">DATOS DEL CLIENTE</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black; border-collapse: collapse;">NOMBRE COMPLETO</td>
                                <td colspan="3" style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.complete_name}}
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">NACIONALIDAD</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.nationality}}</td>
                                <td style="border: 1px solid black; border-collapse: collapse;">ESTADO CIVIL</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.civilStatus}}</td>
                            </tr>

                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">FECHA DE NACIMIENTO
                                </td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.birthDate}}</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">OCUPACIÓN
                                </td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.occupation}}</td>
                            </tr>

                            <tr>
                                <td style="border: 1px solid black; border-collapse: collapse;">LUGAR DE NACIMIENTO</td>
                                <td colspan="3" style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.birthPlace}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">DOMICILIO</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.address}}</td>
                                <td style="border: 1px solid black; border-collapse: collapse;">C.P.</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.postalCode}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">COLONIA</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.localidad}}</td>
                                <td style="border: 1px solid black; border-collapse: collapse;">ESTADO</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">{{CustomerInfo.state}}
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">MUNICIPIO</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.municipality}}</td>
                                <td style="border: 1px solid black; border-collapse: collapse;">CELULAR</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">{{CustomerInfo.mobile}}
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">CLAVE DE ELECTOR</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.voterKey}}</td>
                                <td style="border: 1px solid black; border-collapse: collapse;">CURP</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">{{CustomerInfo.CURP}}
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">CORREO</td>
                                <td colspan="3" style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.email}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">RFC</td>
                                <td colspan="3" style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.RFC}}
                                </td>
                            </tr>
                        </table>


                        <br>


                        <table
                            style="border: 1px solid black; border-collapse: collapse; width: 100%; font-size: 0.8rem;">
                            <tr style="text-align: center;">
                                <td colspan="4" style="border: 1px solid black; border-collapse: collapse;">DATOS DEL
                                    CONTRATO</td>
                            </tr>
                            <tr style="text-align: center;">
                                <td colspan="4" style="border: 1px solid black; border-collapse: collapse;">IMPORTES
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black; border-collapse: collapse;">INMUEBLE</td>
                                <td colspan="1" style="border: 1px solid black;  border-collapse: collapse;">
                                    {{propertyInfo.codeProperty}}</td>
                                <td style="border: 1px solid black; border-collapse: collapse;">SUPERFICIE</td>
                                <td colspan="1" style="border: 1px solid black;  border-collapse: collapse;">
                                    {{propertyInfo.area}}m2</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">IMPORTE TOTAL</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{propertyInfo.amount.toFixed(0)}}
                                </td>
                                <td style="border: 1px solid black; border-collapse: collapse;">FECHA</td>
                                <td formControlName="fechaDato"
                                    style="border: 1px solid black;  border-collapse: collapse;">{{todayWithPipe}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">ANTICIPO</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{propertyInfo.advancePayment}}</td>
                                <td style="border: 1px solid black; border-collapse: collapse;">REQUIERE FACTURA</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.billing==0?"NO":CustomerInfo.billing==1?"SI":"NA"}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">MENSUALIDADES</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{propertyInfo.monthlyPayment}}</td>
                                <td formControlName="contractDate"
                                    style="border: 1px solid black; border-collapse: collapse;">A PARTIR DE LA FECHA
                                </td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{dateApartirdeWithPipe}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">ANUALIDAD</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{propertyInfo.annualityPayment}}</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>

                        <br>

                        <table
                            style="border: 1px solid black; border-collapse: collapse; width: 100%; font-size: 0.8rem;">
                            <tr style="text-align: center;">
                                <td style="border: 1px solid black; border-collapse: collapse;" colspan="2">COLINDANCIAS
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black; border-collapse: collapse; font-size: 0.8rem;">
                                    {{propertyInfo.northeasBorder}}</td>
                                <td style="width: 30%" rowspan="4">
                                    <img style=" display: block; width: 80%;" alt="Logo"
                                        src="{{propertyInfo.sketchMap}}">
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse; font-size: 0.8rem;">
                                    {{propertyInfo.northwestBorder}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black; border-collapse: collapse; font-size: 0.8rem;">
                                    {{propertyInfo.southeastBorder}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black; border-collapse: collapse; font-size: 0.8rem;">
                                    {{propertyInfo.southwestBorder}}</td>
                            </tr>
                        </table>
                        <div style="border: solid 1px black;">
                            <p style="font-size: 0.8rem;">A PARTIR DE LA FIRMA DE ESTA SOLICITUD SE DA POR ENTERADO QUE
                                LOS DATOS QUE AQUI SE
                                PLASMAN SON CORRECTOS. EN CASO DE CANCELAR SU PARTICIPACION EN EL PROYECTO ANTES DE
                                HABER REALIZADO LA FIRMA DE SU CONTRATO EL VALOR ENTREGADO COMO ENGANCHE NO SERÁ
                                REEMBOLSADO.</p>
                        </div>
                        <div style="display: flex; width: 100%; padding-top: 40px;">
                            <p style="width: 50%; text-align: center;">{{CustomerInfo.complete_name}}<br><b>Cliente</b>
                            </p>
                            <p style="width: 50%; text-align: center;">{{sellerName}}<br><b>Vendedor</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <br>

    <h4> Recuerda que el area administrativa requiere la solicitud firmada por el cliente, no olvides
        imprimirla. </h4>

    <br>

    <div style="align-items: center;margin-left: auto;   margin-right: auto;">
        <button style="text-align: center;" (click)="print()">DESCARGAR SOLICITUD</button>
    </div>

    <br>

    <h6>Si todos los datos mostrados anteriormente son correctos, favor de confirmar la venta a continuacion</h6>

    <mat-dialog-actions align="end">
        <button mat-button (click)="imprimirForm()">Confirmar</button>
        <button mat-button mat-dialog-close>Cancelar</button>
    </mat-dialog-actions>
</mat-dialog-content>