<!------------------------------------ PRESUPUESTO DE DESTAJOS ---------------------------->
    <div class="container-fluid mt-3">
        <mat-card class="card-form">
            <mat-card-content class="justify-align-center">

                <div style="display: flex; justify-content: space-between;">
                    <div style="width: 45%; height: 60vh; overflow: scroll;">

                        <table class="table table-striped mt-3 animated fadeIn faster">
                            <thead class="thead-m19">
                                <tr style="text-align:center;">
                                    <!-- Se definen los encabezados de cada columna de la tabla -->
                                    <th scope="col">Proyecto</th>
                                    <th scope="col">Total</th>

                                </tr>
                            </thead>
                            <tbody>
                                <!-- ngFor para recorrer todos los presupuestos obtenidos -->
                                <!-- con esto podemos controlar la generación de componentes -->
                                <!-- en base a la cantidad de presupuestos -->
                                <tr *ngFor="let t of total" style="text-align:center; font-weight:900;">
                                    <!-- Se define con qué datos se llenará cada columna de la tabla -->
                                    <td>{{t.name}}</td>
                                    <td>{{t.totalProyecto.toFixed(2) | currency:'CAD':'$ '}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="width: 45%;">
                        <h5 style="text-align:center; border-bottom: solid rgb(71, 71, 71) 1.5px;">Comparativa de
                            totales
                        </h5>
                        <canvas id="ProjectsChart"></canvas>
                    </div>
                </div>

                <div class="btnProject">
                    <button *ngFor="let p of projectspiece" (click)="getTotalProjectPiece(p.idProject)">
                        {{p.name}}
                        <mat-icon>apartment</mat-icon>
                    </button>
                </div>

                <div class="infoProject" *ngIf="infoPp.idProject!==''">
                    <p><b>PROYECTO:</b> {{infoPp.name}}</p>
                    <p><b>TOTAL GASTADO:</b> {{infoPp.TotalPagado.toFixed(2) | currency:'CAD':'$ '}} </p>
                    <p>Gráfica de presupuestos por proyecto: <button (click)="desgloceDatosPiece()" class="btnDetail">
                            <mat-icon *ngIf="!projectDetp">arrow_drop_down</mat-icon>
                            <mat-icon *ngIf="projectDetp">arrow_drop_up</mat-icon>
                        </button></p>
                </div>

                <div [hidden]=!projectDetp>
                    <canvas id="budgetChartPiece"></canvas>
                </div>
                <div class="btnModule">
                    <button *ngFor="let m of modulespiece" (click)="getTotalModulePiece(m.idprojectdet)">
                        {{m.towernumber}}
                        <mat-icon>apartment</mat-icon>
                    </button>
                </div>

                <div class="infoModule" *ngIf="infoMp.idprojectdet!==''">
                    <p><b>MODULO:</b> {{infoMp.towernumber}}</p>
                    <p><b>TOTAL GASTADO:</b> {{infoMp.TotalPagado.toFixed(2) | currency:'CAD':'$ '}} </p>
                    <p>Gráfica por módulos: <button (click)="desgloceDatosModulePiece()" class="btnDetail">
                            <mat-icon *ngIf="!moduleDetp">arrow_drop_down</mat-icon>
                            <mat-icon *ngIf="moduleDetp">arrow_drop_up</mat-icon>
                        </button></p>
                </div>

                <div [hidden]=!moduleDetp>
                    <canvas id="moduleChart"></canvas>
                </div>

                <div class="btnDeparture">
                    <button *ngFor="let d of departurespiece" (click)="getTotalDeparturePiece(d.departurenumber)">
                        {{d.departurenumber}}
                        <mat-icon>class</mat-icon>
                    </button>
                </div>

                <div class="infoDeparture" *ngIf="infoDp.departurenumber!==''">
                    <p><b>PARTIDA:</b> {{infoDp.departurenumber}}</p>
                    <p><b>TOTAL GASTADO:</b> {{infoDp.totalPartida}}</p>
                    <p>Gráfica por partidas: <button (click)="desgloceDatosDeparturePiece()" class="btnDetail">
                            <mat-icon *ngIf="!departureDetp">arrow_drop_down</mat-icon>
                            <mat-icon *ngIf="departureDetp">arrow_drop_up</mat-icon>
                        </button></p>
                </div>

                <div [hidden]=!departureDetp>
                    <canvas id="departureChart"></canvas>
                </div>

            </mat-card-content>
        </mat-card>
    </div>
