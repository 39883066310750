<div class="container-fluid mt-3">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">

            <!--ESTADO DE CUENTA DESPLEGABLE-->
            <ng-template #contenido let-modal>
                <div class="modal-header">
                    <h5 class="modal-title" *ngFor="let venta of carteraInmu"
                        style="width: 100%; margin: 0; text-align: center; gap: 10px;"> <b
                            style="color: var(--M19Primary);">{{venta.name}}<br>{{venta.codeProperty}}</b> </h5>
                    <button type="button" class="close" aria-label="close" (click)="modal.close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div style="display: flex; flex-direction: column; align-items: center;"
                        *ngFor="let venta of carteraInmu">

                        <div class="dataRow">
                            <div class="customerData">
                                <mat-icon class="iconData">person</mat-icon>
                                <div class="infoBlock">
                                    <span style="font-size: 1.1rem;"><b>Cliente</b></span>
                                    <span
                                        style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.clientes}}</b></span>
                                </div>
                            </div>
                            <div class="customerData">
                                <mat-icon class="iconData">alternate_email</mat-icon>
                                <div class="infoBlock">
                                    <span style="font-size: 1.1rem;"><b>Correo</b></span>
                                    <span
                                        style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.email}}</b></span>
                                </div>
                            </div>
                        </div>

                        <div class="dataRow">
                            <div class="customerData">
                                <mat-icon class="iconData">call</mat-icon>
                                <div class="infoBlock">
                                    <span style="font-size: 1.1rem;"><b>Teléfono</b></span>
                                    <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.mobile==null?"Sin
                                            especificar":venta.mobile}}</b></span>
                                </div>
                            </div>
                            <div class="customerData">
                                <mat-icon class="iconData">cake</mat-icon>
                                <div class="infoBlock">
                                    <span style="font-size: 1.1rem;"><b>Fecha de nacimiento</b></span>
                                    <span
                                        style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.birthDate}}</b></span>
                                </div>
                            </div>
                        </div>

                        <div class="dataRow">
                            <div class="customerData">
                                <mat-icon class="iconData">support_agent</mat-icon>
                                <div class="infoBlock">
                                    <span style="font-size: 1.1rem;"><b>Coordinador</b></span>
                                    <span
                                        style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.usuarios}}</b></span>
                                </div>
                            </div>
                            <div class="customerData">
                                <mat-icon class="iconData">support_agent</mat-icon>
                                <div class="infoBlock">
                                    <span style="font-size: 1.1rem;"><b>Vendedor</b></span>
                                    <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.vendedores==null?"Sin
                                            especificar":venta.vendedores}}</b></span>
                                </div>
                            </div>
                        </div>

                        <div class="dataRow">
                            <div class="customerData">
                                <mat-icon class="iconData">event</mat-icon>
                                <div class="infoBlock">
                                    <span style="font-size: 1.1rem;"><b>Fecha de contrato</b></span>
                                    <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.fechaDato
                                            |
                                            date :
                                            'dd-MM-YYYY'}}</b></span>
                                </div>
                            </div>
                            <div class="customerData">
                                <mat-icon class="iconData">update</mat-icon>
                                <div class="infoBlock">
                                    <span style="font-size: 1.1rem;"><b>Fecha de primer pago</b></span>
                                    <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.firstPayDate
                                            !=
                                            null? (venta.firstPayDate | date:
                                            'dd-MM-yyyy') : "Sin especificar"}}</b></span>
                                </div>
                            </div>
                        </div>

                        <br>
                        <h3>Balance</h3>

                        <div class="moneyBalance">
                            <div class="balanceRow">
                                <span class="balanceTitle">Valor total</span>
                                <span class="balanceData">{{venta.valor | currency:'CAD' : '$ '}}</span>
                            </div>
                            <div class="balanceRow">
                                <span class="balanceSubtitle">Pagos Normales</span>
                                <span class="balanceData">{{venta.abonado | currency:'CAD' : '$ '}}</span>
                            </div>
                            <div class="balanceRow">
                                <span class="balanceSubtitle">Nota de Crédito</span>
                                <span class="balanceData">{{(anticipoC + mensualidadC) | currency:'CAD' : '$ '}}</span>
                            </div>
                            <div class="balanceRow">
                                <span class="balanceSubtitle">Nota de Crédito No Reembolsable</span>
                                <span class="balanceData">{{(anticipoNR + mensualidadNR) | currency:'CAD' : '$
                                    '}}</span>
                            </div>
                            <div class="balanceRow">
                                <span class="balanceTitle">Adeudo</span>
                                <span
                                    class="balanceData">{{venta.valor-venta.abonado-(mensualidadC+mensualidadNR)-(anticipoC+anticipoNR)
                                    | currency:'CAD' : '$ '}}</span>
                            </div>
                        </div>

                        <!-- <button class="m19AltBtn"
                            (click)="getIndividualCreditPayments(venta.codeProperty, venta.clientes, venta.usuarios)">Ver
                            recibos de crédito <mat-icon>receipt</mat-icon></button> -->

                        <table class="table table-hover mt-3">
                            <thead>
                                <tr align="center">
                                    <td>Pagos realizados</td>
                                    <td>Pagos esperados</td>
                                    <td>Estado de pagos</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr align="center">
                                    <td> <a>{{venta.mPagadas+' de
                                            '+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(0)}}</a>
                                    </td>

                                    <td>
                                        <div style="display: flex; flex-direction: column;">
                                            <div>
                                                {{venta.meses+' de
                                                '+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(0)}}
                                            </div>
                                            <div *ngIf="venta.retraso >= 0;"
                                                style="width: 100%; height: 10px; border-radius: 5px; background-color: var(--primaryBlue);">
                                            </div>
                                            <div *ngIf="venta.retraso < 0 && venta.retraso > -2"
                                                style="width: 100%; height: 10px; border-radius: 5px; background-color: var(--primaryGreen);">
                                            </div>
                                            <div *ngIf="venta.retraso <= -2 && venta.retraso > -4"
                                                style="width: 100%; height: 10px; border-radius: 5px; background-color: var(--primaryYellow);">
                                            </div>
                                            <div *ngIf="venta.retraso <= -4"
                                                style="width: 100%; height: 10px; border-radius: 5px; background-color: var(--primaryRed);">
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {{venta.retraso.toFixed(2)}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <!-- <div style="width: 100%; overflow: scroll;">
                            <table class="m19Table">
                                <thead>
                                    <tr>
                                        <th style="text-align: right;">#</th>
                                        <th style="text-align: right">Folio</th>
                                        <th style="text-align: center;">Fecha</th>
                                        <th style="text-align: right">Cantidad</th>
                                        <th>Método</th>
                                        <th>Concepto</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let payment of payments; let i=index;">
                                        <th style="text-align: right;">{{i+1}}</th>
                                        <th style="text-align: right">{{payment.folioPago}}</th>
                                        <td style="text-align: center;">{{pipe.transform(payment.fechaPago,'longDate')}}
                                        </td>
                                        <td style="text-align: right">{{(payment.cantidadAbono | currency:'CAD' : '$
                                            ')}}</td>
                                        <td>{{payment.metodoPago}}</td>
                                        <td>{{payment.concepto}}</td>
                                        <td class="button-row" style="text-align: center;">
                                            <button class="btn btn-m19-table" *ngIf="(payment.folioPago).includes('e')"
                                                mat-icon-button (click)="rePrintTicket(payment.folioPago)">
                                                <mat-icon>receipt</mat-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> -->

                        <div class="paymentContainer">
                            <div class="paymentCard" *ngFor="let payment of payments; let i=index;">
                                <span class="paymentIcon">
                                    <mat-icon *ngIf="payment.metodoPago == 'Efectivo'">payments</mat-icon>
                                    <mat-icon *ngIf="payment.metodoPago == 'Cheque'">account_balance_wallet</mat-icon>
                                    <mat-icon *ngIf="payment.metodoPago == 'Deposito'">monetization_on</mat-icon>
                                    <mat-icon
                                        *ngIf="payment.metodoPago == 'Transferencia'">swap_horizontal_circle</mat-icon>
                                    <mat-icon *ngIf="payment.metodoPago == 'Deposito en garantía'">local_atm</mat-icon>
                                    <mat-icon
                                        *ngIf="payment.metodoPago == 'Tarjeta de debito'  || payment.metodoPago == 'Tarjeta de credito'">credit_card</mat-icon>
                                </span>
                                <div class="paymentDetail">
                                    <span style="display: flex; justify-content: space-between;"><b
                                            style="color: var(--M19Primary);">{{payment.concepto}}</b>
                                        <mat-icon *ngIf="(payment.folioPago).includes('e')"
                                            (click)="rePrintTicket(payment.folioPago)"
                                            style="cursor: pointer;">receipt</mat-icon></span>
                                    <span
                                        style="display: flex; justify-content: space-between;"><b>{{payment.metodoPago}}</b>
                                        <b style="color: var(--primaryGreen);">+ {{payment.cantidadAbono | currency :
                                            'CAD' : '$ '}}</b></span>
                                    <span
                                        style="display: flex; justify-content: space-between;">{{pipe.transform(payment.fechaPago,'mediumDate')}}
                                        <b style="color: gray;">Folio: {{payment.folioPago}}</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" (click)="modal.close()">De acuerdo</button>
                </div>
            </ng-template>
            <!--==========================-->

            <div class="propertiesContainer">
                <div class="propertyCard" *ngFor="let payment of info"
                    (click)="slectPayments(payment.codeProperty);selectSales(payment.codeProperty);sumTotales(payment.codeProperty);modal.open(contenido);reporteInmuCartera(payment.codeProperty);getCreditPayments(payment.codeProperty);getCreditNRPayments(payment.codeProperty);getCreditPaymentsList(payment.codeProperty);">
                    <div class="cardTitle">
                        <span>
                            <img [src]="payment.UrlLogo" alt="Logo de Proyecto">
                            <b>{{payment.name}}</b> {{payment.codeProperty}}
                        </span>
                        <mat-icon>arrow_forward_ios</mat-icon>
                    </div>
                    <div class="cardRow">
                        <p style="text-align: center; width: calc(100%/3);"><b
                                style="color: var(--M19Primary);">Valor</b><br><b>{{(payment.valor |
                                currency:'CAD' : '$ ')}}</b></p>
                        <p style="text-align: center; width: calc(100%/3);"><b
                                style="color: var(--M19Primary);">Abonado</b><br><b>{{(payment.abonado |
                                currency:'CAD' : '$ ')}}</b></p>
                        <p style="text-align: center; width: calc(100%/3);"><b
                                style="color: var(--M19Primary);">Restante</b><br><b>{{(payment.valor-payment.abonado
                                | currency:'CAD' : '$ ')}}</b></p>
                    </div>
                    <div class="cardRow">
                        <p style="text-align: center; width: calc(100%/3);"><b style="color: var(--M19Primary);">Pagos
                                realizados</b><br><b>{{payment.mPagadas}}</b></p>
                        <p style="text-align: center; width: calc(100%/3);"><b style="color: var(--M19Primary);">Pagos
                                esperados</b><br><b>{{payment.meses+'/'+((payment.valor-payment.advancePayment)/payment.monthlyPayment).toFixed(0)}}</b>
                        </p>
                    </div>
                    <p style="text-align: center; font-weight: bold;padding: 15px;">{{payment.saldo < 0? 'Mi adeudo es de $'
                            +abs(payment.saldo):payment.saldo> 0? 'Mi saldo a favor es de $'+abs(payment.saldo):'Voy al
                            corriente'}}</p>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>