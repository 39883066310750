<div class="container-fluid mt-3">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">

            <div class="contenedor">
                <h4 *ngIf="leads.length<=0" style="text-align: center; margin-top: 10px;">Sin leads asignados <br>
                    <mat-icon>sentiment_dissatisfied</mat-icon>
                </h4>

                <div class="visorContainer" *ngIf="leads.length>0">
                    <div class="visorContainer__leads">
                        <h1 class="subtitleText">PROSPECTOS ASIGNADOS</h1>
                        <div class="visorContainer__leads__scroll">
                            <div *ngFor="let p of leads" class="visorContainer__leads__card"
                                (click)="leadSelected(p.idlead, (p.name+' '+p.firstLastName+' '+p.secondLastName), p.interest, p.mobile, p.email, p.notes)">
                                <p style="color: var(--subtitleTextColor);"><b
                                        style="color: var(--M19Primary);">Prospecto:</b> {{p.name}}
                                    {{p.firstLastName}}
                                    {{p.secondLastName}}</p>
                                <p style="color: var(--subtitleTextColor);"><b
                                        style="color: var(--M19Primary);">Interesado
                                        en</b> {{p.interest}}</p>
                                <p style="color: var(--subtitleTextColor);" *ngIf="p.status==1"><b>Asignado el día
                                        {{p.assignDate | date:
                                        "dd-MM-YYYY"}}</b></p> 
                                <p style="color: var(--M19Primary);" *ngIf="p.status==0"><b>Este prospecto fue reasignado a otro coordinador, para más información consulta el historial del lead</b></p>
                                <p style="color: var(--subtitleTextColor);"><b
                                        style="color: var(--M19Primary);">Registrado
                                        por</b> {{p.responsable}} <b style="color: var(--M19Primary);">el día</b>
                                    {{p.registrationdate | date: "dd-MM-YYYY"}}</p>
                                <p style="color: var(--subtitleTextColor);"><b style="color: var(--M19Primary);">Fecha
                                        límite de atención:</b> {{p.limitAttention | date: "dd-MM-YYYY"}}</p>
                                <p style="color: var(--subtitleTextColor);" *ngIf="p.countDownLimit < 0"><b
                                        style="color: var(--M19Primary);">Lead vencido, solicite más tiempo dejando un
                                        comentario!</b></p>
                                <p style="color: var(--subtitleTextColor);"
                                    *ngIf='p.countDownLimit >= 0 && p.countDownLimit <= 5'><b
                                        style="color: var(--M19Primary);">Lead a punto de vencer, quedan
                                        {{p.countDownLimit}} días solicite más tiempo dejando un comentario en caso de
                                        necesitarlo</b></p>
                            </div>
                        </div>
                    </div>

                    <div class="visorContainer__control" *ngIf="leadSelection.idlead != null">
                        <p class="titleText">{{leadSelection.prospect}}</p>
                        <p style="margin-top: -20px; color: var(--subtitleTextColor); font-size: 1rem;">Interesado en
                            {{leadSelection.interest}}</p>
                        <span class="visorContainer__control__contactData">
                            <p *ngIf="leadSelection.mobile!=''">
                                <mat-icon style="color: var(--M19Primary); margin-right: 5px;">call</mat-icon>
                                <b>{{leadSelection.mobile}}</b>
                            </p>
                            <p *ngIf="leadSelection.email!=''">
                                <mat-icon style="color: var(--M19Primary); margin-right: 5px;">alternate_email
                                </mat-icon><b>{{leadSelection.email}}</b>
                            </p>
                        </span>
                        <p><b>Notas:</b><br>{{leadSelection.notes}}</p>
                        <div style="display: flex; gap: 20px;">
                            <button class="m19Btn" (click)="addComment(leadSelection.idlead)">Comentar</button>
                            <button class="m19AltBtn" (click)="seeTimeline(leadSelection.idlead)">Historia</button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>