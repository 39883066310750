import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';
import { ConsumerTokenService } from 'src/app/services/consumer-token.service';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';
import { Chart, ChartOptions } from 'chart.js';
import decode from 'jwt-decode'

@Component({
  selector: 'app-consumer-token-report-main',
  templateUrl: './consumer-token-report-main.component.html',
  styleUrls: ['./consumer-token-report-main.component.scss']
})
export class ConsumerTokenReportMainComponent implements OnInit {

  //Variables
  projects: any = []       //Almacena los proyectos existentes, se rellena en ngOnInit
  matT: any = []           //Almacena los materiales Totales (Se rellena en calculateMaterialByProject o en calculateMaterialByMod)
  matC: any = []           //Almacena los materiales Consumidos (Se rellena en calculateMaterialByProject o en calculateMaterialByMod)
  matF: any = []           //Almacena la lista de materiales resultados (Se rellena en calculateMaterialByProject o en calculateMaterialByMod)
  matG = [0.00, 0.00, 0.00]          //índice 0=Material solicitado (Nota de remision), índice 1=Material consumido, ínidce 2=Material restante
  projectsDetail: any = [] //Una vez elegido el proyecto, se rellena con los módulos que contiene dicho proyecto
  infoGeneral = {
    nombreDelProyecto: 'Sin seleccionar',
    TorreDelProyecto: 0,
    PorcentajeMaterialRestante: 0.00,
    PorcentajeMaterialConsumido: 0.00,
  }
  app:any=document.getElementById('btnP');
  //----------------------------COPIAS DE VARIABLES PARA VISUALIZACION POR PROYECTO
  matTP: any = []           //Almacena los materiales Totales (Se rellena en calculateMaterialByProject o en calculateMaterialByMod)
  matCP: any = []           //Almacena los materiales Consumidos (Se rellena en calculateMaterialByProject o en calculateMaterialByMod)
  matFP: any = []           //Almacena la lista de materiales resultados (Se rellena en calculateMaterialByProject o en calculateMaterialByMod)
  matGP = [0.00, 0.00, 0.00]          //índice 0=Material solicitado (Nota de remision), índice 1=Material consumido, ínidce 2=Material restante
  infoGeneralP = {
    nombreDelProyecto: 'Cargando...',
    TorreDelProyecto: 0,
    PorcentajeMaterialRestante: 0.00,
    PorcentajeMaterialConsumido: 0.00,
  }
  validProject=true;      //Bandera para indicar si hay un proyecto válido seleccionado (En true si no es válido)
  validModule=true;          //Bandera para indica si hay un módulo válido seleccionado (En true si no es válido)
  showInfoProject=true;   //Si no hay información que mostrar respecto al proyecto, esta banera se encuentra encendida
  showInfoMod=true;       //Si no hay información que mostrar respecto al módulo, esta banera se encuentra encendida
  modProject=true;        //Si el proyecto es válido pero no contiene módulos su valor será true.

  constructor(
    public projectService: ProjectService,
    public consumerTokenService: ConsumerTokenService,
    public projectdetailsService: ProjectdetailsService,
  ) { }


  ngOnInit(): void {
    this.selectProjects();
  }

  //Recupera la lista de proyectos de la tabla projects
  async selectProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
        console.log('Projects recuperados: ')
        console.log(res);
      },
      err => console.log(err)
    );
  };


  //Esta función recupera los datos del projectDetail del proyecto seleccionado
  async getProjectDetail(id: number, projectName: string) {
    this.validProject=false;  //Se ha seleccionado un proyecto válido
    this.validModule=true;    //Aún no se selecciona un módulo válido
    this.infoGeneral.TorreDelProyecto=0;
    this.projectdetailsService.selectDetailsById(id).subscribe(
      res => {
        (res != null || res != [] || res.length != 0) ? this.validModule=true:this.validModule=false;//Si el proyecto tiene o no tiene módulos, se ve reflejado en la bandera de módulo válido 
        this.infoGeneral.nombreDelProyecto = projectName;
        console.log('\nActualización a infoGeneral, nombreDelProyecto: ' + this.infoGeneral.nombreDelProyecto);
        this.projectsDetail = res;
        console.log('\nProjects detail del proyecto ' + id);
        console.log(this.projectsDetail);
        this.calculateMaterialByProject(id);
      }
    );
  }

  async calculateMaterialByMod(id: number, towernumber: number) {
    console.log('\nID del projectDet: ' + id);
    this.consumerTokenService.getMaterialTotalByMod(id).subscribe(
      res => {
        console.log('\nMaterial total por módulo: ' + towernumber + ', ID de ProjectDetail: ' + id);
        console.log(res);
        //Reiniciliazación de variables para que no afecte tras cambiar de opción de visualización en el front
        this.matG = [0, 0, 0];                 //Arreglo para datos estadísticos, mas info en su declaración
        this.infoGeneral.TorreDelProyecto = 0;
        this.infoGeneral.PorcentajeMaterialRestante = 0.00;
        this.infoGeneral.PorcentajeMaterialConsumido = 0.00;
        this.matT = [];
        this.matC = [];
        this.matF = [];
        //FIN DE REINICIALIZACIÓN DE VARIABLES
        this.infoGeneral.TorreDelProyecto = towernumber;
        console.log('\nActualización a infoGeneral, TorreDelProyecto: ' + this.infoGeneral.TorreDelProyecto);
        (res == null || res == [] || res.length == 0) ? this.showInfoMod = true : this.showInfoMod = false //Si no hay material del módulo que mostrar, entonces se inidca mediante la variable showInfoMod (Si no hay material, tiene valor true)
        this.matT = res;
        console.log('\n 1 Materiales totales despues de resta:');
        console.log(this.matT);
        this.consumerTokenService.getConsumedMaterialByMod(id).subscribe(
          res => {
            this.matC = res;
            console.log('\nMateriales consumidos por módulo:')
            console.log(res);
            //----PROCESO DE RESTA
            console.log('\n 2 Materiales totales despues de resta:')
            console.log(this.matT);
            this.matF = this.matT;              //Se iguala el material final (la variable que contiene el material resultante) con el material total (El material que fue recibido por medio de notas de remisión)
            for (let i of this.matT) {          //Se recorren los materiales totales con indice i
              this.matG[0] = this.matG[0] + i.amount; //acumulador para total de materiales
              this.matF[(this.matF).indexOf(i)].colorBack = "rgb(" + (Math.random() * 255).toFixed(0) + "," + (Math.random() * 255).toFixed(0) + "," + (Math.random() * 255).toFixed(0) + ")"
              for (let j of this.matC) {        //Se recorren los materiales consumidos con indice j
                if (parseInt(i.idproduct) == parseInt(j.idproduct)) { //Cada vez que el ID de producto sea igual, se resta la cantidad de ese producto (Total - consumido)
                  //Hay un bug, al modificar el valor de matF se modifica el valor de matT, sabrá dios como es que sigue funcionando bien: La linea que lo provoca es la que sigue a continuación, si se borra, el módulo funncionará de forma incorrecta
                  this.matF[(this.matF).indexOf(i)].amount = i.amount - j.amount //El indexOf recupera el indice del arreglo matF donde su contenido sea igual que i (iteración de matI), ese indice se requiere para indicar a que json del arreglo matF se le debe actualizar la propiedad amount
                  this.matG[1] = this.matG[1] + j.amount; //Acumulador para materiales consumidos
                  break;                        //Si el material coincide, se aplica un break y sale del for para ahorrar algo de procesamiento
                }                               //Si el material no coincide, la propiedad amount de matF queda sin modificar dando a entender que no hay consumo de material
              }
            }
            this.matG[2] = this.matG[0] - this.matG[1];
            console.log('\nMateriales finales despues de resta:')
            console.log(this.matF);

            console.log('\n 3 Materiales totales despues de resta:')
            console.log(this.matT);

            console.log('\nMateriales Totales/Consumidos/restantes:')
            console.log(this.matG);
            //-----FIN DEL PROCESO DE RESTA    
            //Calculo de porcentajes, se sobreescribe matG
            this.infoGeneral.PorcentajeMaterialRestante = 100 / this.matG[0] * this.matG[2];
            this.infoGeneral.PorcentajeMaterialConsumido = 100 / this.matG[0] * this.matG[1];
            this.chartModulo();
          }//2DA SUSCRIPCIÓN
        )
      }// 1ERA SUSCRIPCIÓN
    )
  }

  async chartModulo() {
    //Referencia al objeto canva HTML al que se carga el gráfico
    const cTRCanvas = document.getElementById('cTRCanvasModulo') as HTMLCanvasElement;


    //Configuración de fuente de la gráfica
    Chart.defaults.global.defaultFontFamily = "Arial";
    Chart.defaults.global.defaultFontSize = 8;
    let labelss: any = [], datas: any = [], backgroundColors = [], datasTotal: any = [], backgroundColorsTotal = [],datasConsumido:any=[],backgroundConsumido=[];
    for (let m of this.matF) {
      labelss.push(m.productname)
      datas.push(m.amount)
      //backgroundColors.push(m.colorBack)
      backgroundColors.push('rgb(80,110,230)');
      datasTotal.push(m.totalAmount);
      backgroundColorsTotal.push('rgb(227,37,43)');
      datasConsumido.push(m.totalAmount-m.amount);
      backgroundConsumido.push('rgb(80,230,80)')
    }


    const cTRData = {
      labels: labelss,
      datasets: [{
        label: 'Material solicitado: ',
        data: datasTotal,
        backgroundColor: 'rgb(190,190,250)',
        borderColor: 'rgb(30,30,110)',
        borderWidth: 1
      },{
        label: 'Material consumido: ',
        data: datasConsumido,
        backgroundColor: 'rgb(250,190,190)',
        borderColor: 'rgb(110,30,30)',
        borderWidth: 1
      },{
        label: 'Material Disponible: ',
        data: datas,
        backgroundColor: 'rgb(190,250,190)',
        borderColor: 'rgb(30,110,30)',
        borderWidth: 1
      }]
    };


    //Configuración básica de gráfico
    const chartOptions: ChartOptions = {
      legend: {
        display: true,   //Muestra los labels en la parte superior
        position: 'left',
        labels: {
          boxWidth: 10,
          fontColor: 'black'
        }
      }
    };

    //Generación del gráfico
    const lineChart = new Chart(cTRCanvas, {
      type: 'bar',
      data: cTRData,
      options: chartOptions
    });
  }
  //--------------------------------------------------------------------------------------

  async calculateMaterialByProject(id: number) {
    console.log('\n====================================\n====================================\n====================================\n====================================\n')
    console.log('\nID del projectDet: ' + id);
    this.consumerTokenService.getMaterialTotalByProject(id).subscribe(
      res => {
        console.log(res);
        //Reiniciliazación de variables para que no afecte tras cambiar de opción de visualización en el front
        this.matGP = [0, 0, 0];                 //Arreglo para datos estadísticos, mas info en su declaración
        this.infoGeneralP.TorreDelProyecto = 0;
        this.infoGeneralP.PorcentajeMaterialRestante = 0.00;
        this.infoGeneralP.PorcentajeMaterialConsumido = 0.00;
        this.matTP = [];
        this.matCP = [];
        this.matFP = [];
        //FIN DE REINICIALIZACIÓN DE VARIABLES
        console.log('\nActualización a infoGeneral, TorreDelProyecto: ' + this.infoGeneralP.TorreDelProyecto);
        (res == null || res == [] || res.length == 0) ? this.showInfoProject = true : this.showInfoProject = false //Si el proyecto tiene o no tiene material, se indica por medio de showInfoProject, si no tiene material, tendrá valor true
        this.matTP = res;
        console.log('Material total del proyecto (MatP):');
        console.log(this.matTP);
        this.consumerTokenService.getConsumedMaterialByProject(id).subscribe(
          res => {
            this.matCP = res;
            console.log('\nMateriales consumidos por proyecto:')
            console.log(res);
            //----PROCESO DE RESTA
            this.matFP = this.matTP;              //Se iguala el material final (la variable que contiene el material resultante) con el material total (El material que fue recibido por medio de notas de remisión)
            for (let i of this.matTP) {          //Se recorren los materiales totales con indice i
              this.matGP[0] = this.matGP[0] + i.amount; //acumulador para total de materiales
              this.matFP[(this.matFP).indexOf(i)].colorBack = "rgb(" + (Math.random() * 255).toFixed(0) + "," + (Math.random() * 255).toFixed(0) + "," + (Math.random() * 255).toFixed(0) + ")"
              for (let j of this.matCP) {        //Se recorren los materiales consumidos con indice j
                if (parseInt(i.idproduct) == parseInt(j.idproduct)) { //Cada vez que el ID de producto sea igual, se resta la cantidad de ese producto (Total - consumido)
                  //Hay un bug, al modificar el valor de matF se modifica el valor de matT, sabrá dios como es que sigue funcionando bien: La linea que lo provoca es la que sigue a continuación, si se borra, el módulo funncionará de forma incorrecta
                  this.matFP[(this.matFP).indexOf(i)].amount = i.amount - j.amount //El indexOf recupera el indice del arreglo matF donde su contenido sea igual que i (iteración de matI), ese indice se requiere para indicar a que json del arreglo matF se le debe actualizar la propiedad amount
                  this.matGP[1] = this.matGP[1] + j.amount; //Acumulador para materiales consumidos
                  break;                        //Si el material coincide, se aplica un break y sale del for para ahorrar algo de procesamiento
                }                               //Si el material no coincide, la propiedad amount de matF queda sin modificar dando a entender que no hay consumo de material
              }
            }
            this.matGP[2] = this.matGP[0] - this.matGP[1];
            console.log('\nMateriales finales despues de resta:')
            console.log(this.matFP);

            console.log('\n 3 Materiales totales despues de resta:')
            console.log(this.matTP);

            console.log('\nMateriales Totales/Consumidos/restantes:')
            console.log(this.matGP);
            //-----FIN DEL PROCESO DE RESTA    
            //Calculo de porcentajes, se sobreescribe matG
            this.infoGeneralP.PorcentajeMaterialRestante = 100 / this.matGP[0] * this.matGP[2];
            this.infoGeneralP.PorcentajeMaterialConsumido = 100 / this.matGP[0] * this.matGP[1];
            this.chartProject();
          }//2DA SUSCRIPCIÓN
        )
      }// 1ERA SUSCRIPCIÓN
    )
  }
  async chartProject() {
    //Referencia al objeto canva HTML al que se carga el gráfico
    const cTRCanvas = document.getElementById('cTRCanvasProyecto') as HTMLCanvasElement;


    //Configuración de fuente de la gráfica
    Chart.defaults.global.defaultFontFamily = "Arial";
    Chart.defaults.global.defaultFontSize = 8;
    let labelss: any = [], datas: any = [], backgroundColors = [], datasTotal: any = [], backgroundColorsTotal = [],datasConsumido:any=[],backgroundConsumido=[];
    for (let m of this.matFP) {
      labelss.push(m.productname)
      datas.push(m.amount)
      //backgroundColors.push(m.colorBack)
      backgroundColors.push('rgb(80,110,230)');
      datasTotal.push(m.totalAmount);
      backgroundColorsTotal.push('rgb(227,37,43)');
      datasConsumido.push(m.totalAmount-m.amount);
      backgroundConsumido.push('rgb(80,230,80)')
    }


    const cTRData = {
      labels: labelss,
      datasets: [{
        label: 'Material solicitado: ',
        data: datasTotal,
        backgroundColor: 'rgb(190,190,250)',
        borderColor: 'rgb(30,30,110)',
        borderWidth: 1
      },{
        label: 'Material consumido: ',
        data: datasConsumido,
        backgroundColor: 'rgb(250,190,190)',
        borderColor: 'rgb(110,30,30)',
        borderWidth: 1
      },{
        label: 'Material Disponible: ',
        data: datas,
        backgroundColor: 'rgb(190,250,190)',
        borderColor: 'rgb(30,110,30)',
        borderWidth: 1
      }]
    };


    //Configuración básica de gráfico
    const chartOptions: ChartOptions = {
      legend: {
        display: true,   //Muestra los labels en la parte superior
        position: 'left',
        labels: {
          boxWidth: 10,
          fontColor: 'black'
        }
      }
    };

    //Generación del gráfico
    const lineChart = new Chart(cTRCanvas, {
      type: 'bar',
      data: cTRData,
      options: chartOptions
    });
  }
  //Para obtener los permisos del usuario y verificar que tenga autorización
  permissions() {
    const token = localStorage.getItem('token') || "";
    let decodetoken: any = {};
    decodetoken = decode(token);
    return decodetoken.permissions;
  }
}


/*Existen 3 casos de uso durante el reporte:
1-. Se consumió menos material del que hay disponible en total: Esto es lo normal y es dificl y poco probable que haya 
corrupción en base de datos.
2-. Se consumió mas material del que hay disponible en material que si está disponible para consumir: 
Esto indica corrupción en la base de datos o ingreso erroneo manual de datos a la base, se reflejará como números 
negativos en la GUI.
3-. Se consumió mas material del que hay disponible en material pero de materiales que no están disponibles 
para su uso: En este caso el sistema no arrojará ningún error pero si se tratará de un bug (Producido en las 
funciones que calculan el material restante). 
 Esto puede pasar por dos cosas:
 1-. Se manipuló incorrectamente la base de datos ó 
 2-. Existe un bug que permite captura de materiales inválidos en la interfaz de registro o edición de vales de consumo.*/

/*Para detectar errores del 3er caso de uso, se diseñó la función detectBugsMaterial, no se implementa de forma 
rutinaria porque requiere procesamiento que no es necesario normalmente*/
