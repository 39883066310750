import { Component, OnInit } from '@angular/core';

import { invoiceReportService } from 'src/app/services/invoice.service';
import { SalesService } from 'src/app/services/sales.service';
import { ReportService } from 'src/app/services/report.service';
import { PaymentMetodeService } from 'src/app/services/paymentMetode.service';

import Swal from 'sweetalert2';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';

import { EditInvoiceMethodComponent } from '../../../invoice/status-payments/edit-invoice-method/edit-invoice-method.component';

import decode from 'jwt-decode';
import { PaymentService } from 'src/app/services/payment.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-invoice-status',
  templateUrl: './invoice-status.component.html',
  styleUrls: ['./invoice-status.component.scss']
})
export class InvoiceStatusComponent implements OnInit {

  constructor(
    private invoiceService: invoiceReportService,
    private saleService: SalesService,
    private reportService: ReportService,
    public sweet: SweetAlertComponent,
    public dialog: MatDialog,
    private paymentService: PaymentService
  ) { }

  //Autocompletado
  keyword = 'name';

  saving: number = 0;

  carteraInmu: any = [];
  sal: any = [];
  codigoInm = "";

  selectedCustomer: any;

  totales: any = [];

  decode: any = [];
  //Manejo de ventas
  sales: any = []
  salesCoo: any = []
  conInmueble: any;
  payAfter: any = [];
  payBefore: any = [];
  payments: any = [];

  ngOnInit(): void {
    this.decode = decode(localStorage.getItem("token") || "");
    this.saleCode();
  }

  permissions() {
    const token = localStorage.getItem('token') || "";

    let decodetoken: any = {};
    decodetoken = decode(token);

    return decodetoken.permissions;
  }

  editInvoiceMethod(type: number, fiscalFolio: string, property: string) {
    let data = { "fkType": type, "fiscalFolio": fiscalFolio, "property": property };
    let edit = this.dialog.open(EditInvoiceMethodComponent, {
      data: data,
      width: "40%"
    });

    edit.afterClosed().subscribe(
      res => {
        this.reportService.reporteCarteraInmueble(property).subscribe(
          res => {
            this.carteraInmu = res;
          },
          err => console.log(err)
        );
      }
    );
  }

  onKeypressEvent(event: any) {
    this.payAfter = []
    this.payBefore = []
    this.conInmueble = event

    let cad: any = {};
    cad = this.conInmueble.name;

    if (event == ' ' || event == null || event == "") {
      console.log("Se requiere consultar cliente");
    } else {
      this.codigoInm = cad.split(" ")[0];
      this.reporteInmuCartera(this.codigoInm, this.codigoInm);
      this.sumTotales(this.codigoInm);
      this.selectSalesCoo(this.codigoInm);
    }
  }

  sumTotales(code: string) {
    this.paymentService.sumTotal(code).subscribe(
      data => {
        this.totales = data;
      },
      err => console.log(err)
    );
  }

  saleCode() {
    this.invoiceService.getCustomerToInvoice().subscribe(
      res => {
        this.sal = res;
      },
      err => console.log(err)
    );
  }

  reporteInmuCartera(carInmu: string, cad: any) {
    this.reportService.reporteCarteraInmueble(carInmu).subscribe(
      res => {
        this.carteraInmu = res;
        this.slectPayments(cad.split(" ")[0]);
      },
      err => console.log(err)
    );
  }

  slectPayments(cliente: string) {
    this.invoiceService.getPaymentsToInvoice(cliente).subscribe(
      res => {
        this.selectedCustomer = cliente;
        this.payments = res;
        this.valuesFromInvoice()
      },
      err => console.log(err)
    );
  }

  valuesFromInvoice() {
    this.payAfter = []
    this.payBefore = []

    let valorTotal = this.carteraInmu[0].valor;

    for (let i of this.payments) {
      this.payAfter.push(valorTotal);
      valorTotal = valorTotal - i.cantidadAbono;
      this.payBefore.push(valorTotal);
    }
  }

  async selectSalesCoo(codInmuebleCoo: any) {
    this.saleService.selectSalesCoo(codInmuebleCoo).subscribe(
      res => {
        this.salesCoo = res;
      },
      err => console.log(err)
    );
  }

  async setWaiting(idp: number, st: number) {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'Escribe algunos detalles relacionados al motivo de esta acción',
      inputLabel: 'Motivo de la cancelación de este movimiento',
      html: "<b>¿Está seguro de querer deshacer el marcado como realizado?</b><br>" +
        "Este movimiento marcará como pendiente esta factura <br>" +
        "Usuario que solicita la cancelación: <b>" + this.decode.cliente + "</b><br>" +
        "Para continuar con la cancelación, escriba los detalles y presione 'Continuar'",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      customClass: {
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn",
        actions: "sweetActions"
      },
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === '') {
            resolve('Escribe los detalles para continuar')
          } else {
            resolve('');
          }
        })
      },
      showCancelButton: true
    })
    if (text) {
      this.saving = 1;
      this.invoiceService.setInvoiced(idp, st).subscribe(
        res => {
          const log = {
            "action": 1,
            "notes": text,
            "payment": idp,
            "responsable": this.decode.fkuser
          };

          this.paymentService.writePaymentLog(log).subscribe(
            res => {
              this.sweet.AlertTime("center", "success", "Pago desmarcado como facturado", false, 2000);
              this.slectPayments(this.selectedCustomer);
              this.saving = 0;
            }
          );
        }
      );
    } else {
      this.sweet.AlertTime("center", "error", "Por favor capture un motivo para realizar esta acción", false, 2000);
    }
  }
}