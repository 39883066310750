<mat-accordion>

    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <b> Reporte de Flujo </b>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div style="display: flex; gap: 10px;">
            <mat-form-field appearance="outline">
                <mat-label>Proyecto</mat-label>
                <mat-select [(value)]="selectProjects" [(ngModel)]="projectRange" (ngModelChange)="setRange()" multiple>
                    <mat-option *ngFor="let project of projects" [value]="project.nomenclature" selected="true">
                        {{project.name}}
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix>map</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Mostrar hasta</mat-label>
                <input matInput type="month" [(ngModel)]="searchMonth" [max]="getLimit()" (change)="setNewLimit()">
                <mat-icon matSuffix>date_range</mat-icon>
            </mat-form-field>
        </div>

        <br>

        <div class="weeklyReportCont" *ngIf="verifyDate(1)">
            <ng-container>
                <h5>Semana del 01 al 08</h5>
                <table mat-table [dataSource]="dataSourceWeek1" class="m19Tbl table-hover">
                    <ng-container matColumnDef="activos">
                        <th mat-header-cell *matHeaderCellDef> Contratos Activos </th>
                        <td mat-cell *matCellDef="let element"> {{element.activos}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="flujoEsp">
                        <th mat-header-cell *matHeaderCellDef> Flujo Esperado </th>
                        <td mat-cell *matCellDef="let element"> {{element.flujoEsp | currency:'CAD' : '$ '}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="contratosPago">
                        <th mat-header-cell *matHeaderCellDef> Contratos con Pago </th>
                        <td mat-cell *matCellDef="let element"> {{element.contratosPago}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="ingresoFlujo">
                        <th mat-header-cell *matHeaderCellDef> Ingreso de Flujo </th>
                        <td mat-cell *matCellDef="let element"> {{element.ingresoFlujo | currency:'CAD' : '$ '}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="avance">
                        <th mat-header-cell *matHeaderCellDef> Porcentaje </th>
                        <td mat-cell *matCellDef="let element"> {{element.avance.toFixed(2)+' %'}} </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </ng-container>

            <ng-container *ngIf="verifyDate(9)">
                <h5>Semana del 09 al 21</h5>
                <table mat-table [dataSource]="dataSourceWeek2" class="m19Tbl table-hover">
                    <ng-container matColumnDef="activos">
                        <th mat-header-cell *matHeaderCellDef> Contratos Activos </th>
                        <td mat-cell *matCellDef="let element"> {{element.activos}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="flujoEsp">
                        <th mat-header-cell *matHeaderCellDef> Flujo Esperado </th>
                        <td mat-cell *matCellDef="let element"> {{element.flujoEsp | currency:'CAD' : '$ '}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="contratosPago">
                        <th mat-header-cell *matHeaderCellDef> Contratos con Pago </th>
                        <td mat-cell *matCellDef="let element"> {{element.contratosPago}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="ingresoFlujo">
                        <th mat-header-cell *matHeaderCellDef> Ingreso de Flujo </th>
                        <td mat-cell *matCellDef="let element"> {{element.ingresoFlujo | currency:'CAD' : '$ '}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="avance">
                        <th mat-header-cell *matHeaderCellDef> Porcentaje </th>
                        <td mat-cell *matCellDef="let element"> {{element.avance.toFixed(2)+' %'}} </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </ng-container>

            <ng-container *ngIf="verifyDate(16)">
                <h5>Semana del 16 al 22</h5>
                <table mat-table [dataSource]="dataSourceWeek3" class="m19Tbl table-hover">
                    <ng-container matColumnDef="activos">
                        <th mat-header-cell *matHeaderCellDef> Contratos Activos </th>
                        <td mat-cell *matCellDef="let element"> {{element.activos}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="flujoEsp">
                        <th mat-header-cell *matHeaderCellDef> Flujo Esperado </th>
                        <td mat-cell *matCellDef="let element"> {{element.flujoEsp | currency:'CAD' : '$ '}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="contratosPago">
                        <th mat-header-cell *matHeaderCellDef> Contratos con Pago </th>
                        <td mat-cell *matCellDef="let element"> {{element.contratosPago}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="ingresoFlujo">
                        <th mat-header-cell *matHeaderCellDef> Ingreso de Flujo </th>
                        <td mat-cell *matCellDef="let element"> {{element.ingresoFlujo | currency:'CAD' : '$ '}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="avance">
                        <th mat-header-cell *matHeaderCellDef> Porcentaje </th>
                        <td mat-cell *matCellDef="let element"> {{element.avance.toFixed(2)+' %'}} </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </ng-container>

            <ng-container *ngIf="verifyDate(23)">
                <h5>Semana del 23 al Último</h5>
                <table mat-table [dataSource]="dataSourceWeek4" class="m19Tbl table-hover">
                    <ng-container matColumnDef="activos">
                        <th mat-header-cell *matHeaderCellDef> Contratos Activos </th>
                        <td mat-cell *matCellDef="let element"> {{element.activos}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="flujoEsp">
                        <th mat-header-cell *matHeaderCellDef> Flujo Esperado </th>
                        <td mat-cell *matCellDef="let element"> {{element.flujoEsp | currency:'CAD' : '$ '}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="contratosPago">
                        <th mat-header-cell *matHeaderCellDef> Contratos con Pago </th>
                        <td mat-cell *matCellDef="let element"> {{element.contratosPago}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="ingresoFlujo">
                        <th mat-header-cell *matHeaderCellDef> Ingreso de Flujo </th>
                        <td mat-cell *matCellDef="let element"> {{element.ingresoFlujo | currency:'CAD' : '$ '}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="avance">
                        <th mat-header-cell *matHeaderCellDef> Porcentaje </th>
                        <td mat-cell *matCellDef="let element"> {{element.avance.toFixed(2)+' %'}} </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </ng-container>
        </div>
    </mat-expansion-panel>

    <!-- <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <b> Gráfico de flujo diario </b>
            </mat-panel-title>
        </mat-expansion-panel-header>


    </mat-expansion-panel>

    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <b> Gráfico de flujo anual </b>
            </mat-panel-title>
        </mat-expansion-panel-header>
    </mat-expansion-panel> -->


</mat-accordion>