import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogosService {

  constructor(private http: HttpClient) { }

  //Consultar todos los logos de todos los proyectos
  getAllIcons(): Observable<any> {
    return this.http.get(`${environment.URLServer}/logos/getAllIcons`);
  }

  //Consultar todas las imagenes
  getAllImagesService(): Observable<any> {
    return this.http.get(`${environment.URLServer}/logos/getAllImages`);
  }

  //Consultar todos los logos por id de proyecto
  selectLogo(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/logos/search/${id}`);
  }
  
  //Consultar todos los logos por id de proyecto
  selectIcon(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/logos/searchIcon/${id}`);
  }

  getProjectLogo(project: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/logos/getProjectLogo/${project}`);
  }
  
}
