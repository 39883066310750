import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/services/report.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit {

  //Manejo de información res API, arreglos con carga de datos de las consultas a los servicios que conectan con la API
  ingreso:any=[]  
  ingresoTotal:any=[]
  ingresoTotalAnual:any=[]
  ingresoTotalAnualPasado:any=[]
  ingresoRango:any=[]
  ingresoDes:any=[]
  ingresoT:any=[]
  ingresoDiario:any=[]
  mesesIngreso:any=["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"]
  selecMes=""
  tMes=""
  fechaRp:string="";
  fechaRu:string="";
  fechaRango:string="";

  //Configuracion de graficos

  public barChartOptions: ChartOptions = {
    
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    tooltips: {   
      enabled:true, 
      titleFontSize:20,
      bodyFontSize:20
    }    
  };  
  public barchartLabelDiario: Label[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11','12', '13','14', '15', '16', '17', '18', '19', '20', '21', '22', '23','24', '25', '26', '27', '28', '29', '30', '31'];
  public barChartLabels: Label[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  constructor(private reportService: ReportService, public sweet: SweetAlertComponent) { 
    
  }
  selectOrganization(id:any) {
    
}

  ngOnInit(): void {
  
  }

  //Cambio de tipo de grafico
  public randomize(): void {
    this.barChartType = this.barChartType === 'bar' ? 'line' : 'bar';
  }
  //Impresión de nombre del mes
  tituloMes(){
    this.tMes=this.selecMes
    return this.tMes;
  }

  //Seleccion del mes
  seleccionMes(){
    if(this.selecMes=="Enero"){
      this.ingresosDesEn();
      this.ingresosTEn();
    }
    if(this.selecMes=="Febrero"){
      this.ingresosDesFe();
      this.ingresosTFe();
    }
    if(this.selecMes=="Marzo"){
      this.ingresosDesMar();
      this.ingresosTMar();
    }
    if(this.selecMes=="Abril"){
      this.ingresosDesAbr();
      this.ingresosTAbr();
    }
    if(this.selecMes=="Mayo"){
      this.ingresosDesMay();
      this.ingresosTMay();
    }
    if(this.selecMes=="Junio"){
      this.ingresosDesJun();
      this.ingresosTJun();
    }
    if(this.selecMes=="Julio"){
      this.ingresosDesJul();
      this.ingresosTJul();
    }
    if(this.selecMes=="Agosto"){
      this.ingresosDesAg();
      this.ingresosTAg();
    }
    if(this.selecMes=="Septiembre"){
      this.ingresosDesSep();
      this.ingresosTSep();
    }
    if(this.selecMes=="Octubre"){
      this.ingresosDesOct();
      this.ingresosTOct();
    }
    if(this.selecMes=="Noviembre"){
      this.ingresosDesNov();
      this.ingresosTNov();
    }
    if(this.selecMes=="Diciembre"){
      this.ingresosDesDic();
      this.ingresosTDic();
    }
  }

  //Obtención de selección de mes y cambio
  onChange(centroId:any) {
    console.log(centroId.event.target.value);    
    this.seleccionMes();
  }
  ShowSelected(cod: any) {
    cod = document.getElementById("mes");
    return cod;
  }
  
  fechaRan(){
    return this.fechaRango="'"+this.fechaRp+"'"+"and"+"'"+this.fechaRu+"'" ;
  }

   // metodos de graficos
   public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  prueba:string="";
  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    
  }

  //Consulta de datos desde el servicio que consulta a la API
  ingresos() {
    this.reportService.reporteIngreso().subscribe(
      res => {
        
        this.ingreso = res;        
      },
      err => console.log(err)
    );
  }
  ingresosRango(rango:any) {
    this.reportService.reporteIngresoRango(rango).subscribe(
      res => {
        
        this.ingresoRango = res;
      },
      err => console.log(err)
    );
  }
  ingresosTotal() {
    this.reportService.reporteIngresoTotal().subscribe(
      res => {
        
        this.ingresoTotal = res;
      },
      err => console.log(err)
    );
  }
  ingresosDiarios() {
    this.reportService.reporteIngresoDiario().subscribe(
      res => {

        this.ingresoDiario = res;
      },
      err => console.log(err)
    );
  }
  ingresosTotalAnual() {
    this.reportService.reporteIngresoAnual().subscribe(
      res => {
        
        this.ingresoTotalAnual = res;
      },
      err => console.log(err)
    );
  }

  ingresosDesEn() {
    this.reportService.reporteIngresoMenEnDes().subscribe(
      res => {        
        this.ingresoDes = res;
      },
      err => console.log(err)
    );
  }
  ingresosTEn() {
    this.reportService.reporteIngresoMenEnT().subscribe(
      res => {
        
        this.ingresoT = res;
      },
      err => console.log(err)
    );
  }
  ingresosDesFe() {
    this.reportService.reporteIngresoMenFebDes().subscribe(
      res => {
        
        this.ingresoDes = res;
      },
      err => console.log(err)
    );
  }
  ingresosTFe() {
    this.reportService.reporteIngresoMenFebT().subscribe(
      res => {
        
        this.ingresoT = res;
      },
      err => console.log(err)
    );
  }
  ingresosDesMar() {
    this.reportService.reporteIngresoMenMarDes().subscribe(
      res => {
        
        this.ingresoDes = res;
      },
      err => console.log(err)
    );
  }
  ingresosTMar() {
    this.reportService.reporteIngresoMenMarT().subscribe(
      res => {
        
        this.ingresoT = res;
      },
      err => console.log(err)
    );
  }
  ingresosDesAbr() {
    this.reportService.reporteIngresoMenAbrDes().subscribe(
      res => {
        
        this.ingresoDes = res;
      },
      err => console.log(err)
    );
  }
  ingresosTAbr() {
    this.reportService.reporteIngresoMenAbrT().subscribe(
      res => {
        
        this.ingresoT = res;
      },
      err => console.log(err)
    );
  }
  ingresosDesMay() {
    this.reportService.reporteIngresoMenMayDes().subscribe(
      res => {
        
        this.ingresoDes = res;
      },
      err => console.log(err)
    );
  }
  ingresosTMay() {
    this.reportService.reporteIngresoMenMayT().subscribe(
      res => {
        
        this.ingresoT = res;
      },
      err => console.log(err)
    );
  }
  ingresosDesJun() {
    this.reportService.reporteIngresoMenJunDes().subscribe(
      res => {
        
        this.ingresoDes = res;
      },
      err => console.log(err)
    );
  }
  ingresosTJun() {
    this.reportService.reporteIngresoMenJunT().subscribe(
      res => {
        
        this.ingresoT = res;
      },
      err => console.log(err)
    );
  }

  ingresosDesJul() {
    this.reportService.reporteIngresoMenJulDes().subscribe(
      res => {
        
        this.ingresoDes = res;
      },
      err => console.log(err)
    );
  }
  ingresosTJul() {
    this.reportService.reporteIngresoMenJulT().subscribe(
      res => {
        
        this.ingresoT = res;
      },
      err => console.log(err)
    );
  }
  ingresosDesAg() {
    this.reportService.reporteIngresoMenAgDes().subscribe(
      res => {
        
        this.ingresoDes = res;
      },
      err => console.log(err)
    );
  }
  ingresosTAg() {
    this.reportService.reporteIngresoMenAgbT().subscribe(
      res => {
        
        this.ingresoT = res;
      },
      err => console.log(err)
    );
  }
  ingresosDesSep() {
    this.reportService.reporteIngresoMenSepDes().subscribe(
      res => {
        
        this.ingresoDes = res;
      },
      err => console.log(err)
    );
  }
  ingresosTSep() {
    this.reportService.reporteIngresoMenSepT().subscribe(
      res => {
        
        this.ingresoT = res;
      },
      err => console.log(err)
    );
  }
  ingresosDesOct() {
    this.reportService.reporteIngresoMenOctDes().subscribe(
      res => {
        
        this.ingresoDes = res;
      },
      err => console.log(err)
    );
  }
  ingresosTOct() {
    this.reportService.reporteIngresoMenOctT().subscribe(
      res => {
        
        this.ingresoT = res;
      },
      err => console.log(err)
    );
  }
  ingresosDesNov() {
    this.reportService.reporteIngresoMenNovDes().subscribe(
      res => {
        
        this.ingresoDes = res;
      },
      err => console.log(err)
    );
  }
  ingresosTNov() {
    this.reportService.reporteIngresoMenNovT().subscribe(
      res => {
        
        this.ingresoT = res;
      },
      err => console.log(err)
    );
  }
  ingresosDesDic() {
    this.reportService.reporteIngresoMenDicDes().subscribe(
      res => {
        
        this.ingresoDes = res;
      },
      err => console.log(err)
    );
  }
  ingresosTDic() {
    this.reportService.reporteIngresoMenDicT().subscribe(
      res => {
        
        this.ingresoT = res;
      },
      err => console.log(err)
    );
  }
 

  ingresosTotalAnualPasado() {
    this.reportService.reporteIngresoAnualPasado().subscribe(
      res => {
        
        this.ingresoTotalAnualPasado = res;
      },
      err => console.log(err)
    );
  }

  onKeypressEvent() {
    this.ingresosDesEn();
    this.ingresosTEn();
  }

  alert(){
    this.sweet.CloseTimer();
  }

}