<mat-tab-group mat-stretch-tabs>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">monetization_on</mat-icon>
            <span>Partidas presupuestales</span>
        </ng-template>
        <div class="row _card">
            <div class="col-md-4">
                <mat-card>
                    <mat-card-content>
                        <form class="row mt-3" [formGroup]="departuresFormGroup" (ngSubmit)="insertDeparture()">
        
                            <h5 class="col-12 pb-2">Nueva partida presupuestal</h5>
        
                            <mat-form-field appearance="outline" class="col-md-12">
                                <mat-label>Número de partida</mat-label>
                                <input matInput type="text" formControlName="departurenumber" required>
                            </mat-form-field>
        
                            <mat-form-field appearance="outline" class="col-md-12">
                                <mat-label>Descripción</mat-label>
                                <input matInput type="text" formControlName="descriptions" required>
                            </mat-form-field>
        
                            <div class="col-12 text-right mt-3">
                                <button class="btn btn-m19" type="submit" [disabled]="!departuresFormGroup.valid">
                                    <mat-icon>save</mat-icon>
                                </button>
                            </div>
        
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
        
            <div class="col-md-8">
                <mat-card>
                    <mat-card-content class="controlTable">
                        <table class="table table-striped animated fadeIn faster">
                            <thead class="thead-m19">
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Número</th>
                                    <th scope="col">Descripción</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let departure of departures">
                                    <td>{{departure.iddeparture}}</td>
                                    <td>{{departure.departurenumber}}</td>
                                    <td>{{departure.descriptions}}</td>
                                    <td class="button-row">
                                        <button class="btn btn-m19-table" mat-icon-button (click)="editDeparture(departure.iddeparture)">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button class="btn btn-m19-table" (click)="deleteDeparture(departure.iddeparture)" mat-icon-button>
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">segment</mat-icon>
            <span>Conceptos</span>
        </ng-template>
        <div class="row _card">
            <div class="col-md-4">
                <mat-card>
                    <mat-card-content>
                        <form class="row mt-3" [formGroup]="conceptFormGroup" (ngSubmit)="insertConcept()" style="display: flex; flex-direction: column; align-items: center;">
        
                            <h5 class="col-12 pb-2">Nuevo concepto</h5>

                            <mat-form-field appearance="outline" class="col-md-12">
                                <mat-label>Clave de concepto</mat-label>
                                <input matInput type="text" formControlName="conceptKey" required>
                            </mat-form-field>
        
                            <mat-form-field appearance="outline" class="col-md-12">
                                <mat-label>Descripción</mat-label>
                                <input matInput type="text" formControlName="descriptions" required>
                            </mat-form-field>
        
                            <div class="col-12 text-right mt-3">
                                <button class="btn btn-m19" type="submit" [disabled]="!conceptFormGroup.valid">
                                    <mat-icon>save</mat-icon>
                                </button>
                            </div>
        
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
        
            <div class="col-md-8">
                <mat-card>
                    <mat-card-content class="controlTable">
                        <table class="table table-striped animated fadeIn faster">
                            <thead class="thead-m19">
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Clave</th>
                                    <th scope="col">Descripción</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let concept of concepts">
                                    <td>{{concept.idconcept}}</td>
                                    <td>{{concept.conceptKey}}</td>
                                    <td style="max-width: 300px;">{{concept.descriptions}}</td>
                                    <td class="button-row">
                                        <button class="btn btn-m19-table" (click)="editConcept(concept.idconcept)" mat-icon-button>
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button class="btn btn-m19-table" (click)="deleteConcept(concept.idconcept)" mat-icon-button>
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">list_alt</mat-icon>
            <span>Material</span>
        </ng-template>
        <div class="row _card">
            <div class="col-md-4">
                <mat-card>
                    <mat-card-content>
                        <form class="row mt-3" [formGroup]="productsFormGroup" (ngSubmit)="insertProduct()">
        
                            <h5 class="col-12 pb-2">Nuevo material</h5>
        
                            <mat-form-field appearance="outline" class="col-md-12">
                                <mat-label>Nombre</mat-label>
                                <input matInput type="text" formControlName="productname" required>
                            </mat-form-field>
        
                            <mat-form-field appearance="outline" class="col-md-12">
                                <mat-label>Unidad de medida</mat-label>
                                <input matInput type="text" formControlName="unit" required>
                            </mat-form-field>
        
                            <div class="col-12 text-right mt-3">
                                <button class="btn btn-m19" type="submit" [disabled]="!productsFormGroup.valid">
                                    <mat-icon>save</mat-icon>
                                </button>
                            </div>
        
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
        
            <div class="col-md-8">
                <mat-card>
                    <mat-card-content class="controlTable">
                        <table class="table table-striped animated fadeIn faster">
                            <thead class="thead-m19">
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Unidad de medida</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let product of products">
                                    <td>{{product.idproduct}}</td>
                                    <td style="max-width: 300px;">{{product.productname}}</td>
                                    <td>{{product.unit}}</td>
                                    <td class="button-row">
                                        <button class="btn btn-m19-table" (click)="editMaterial(product.idproduct)" mat-icon-button>
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button class="btn btn-m19-table" (click)="deleteProduct(product.idproduct)" mat-icon-button>
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">person_pin</mat-icon>
            <span>Proveedores</span>
        </ng-template>
        <div class="row _card">
            <div class="col-md-4">
                <mat-card>
                    <mat-card-content>
                        <form class="row mt-3" [formGroup]="suppliersFormGroup" (ngSubmit)="insertSupplier()">
        
                            <h5 class="col-12 pb-2">Nuevo proveedor</h5>
        
                            <mat-form-field appearance="outline" class="col-md-12">
                                <mat-label>Nombre</mat-label>
                                <input matInput type="text" formControlName="suppliername" required>
                            </mat-form-field>
        
                            <mat-form-field appearance="outline" class="col-md-12">
                                <mat-label>RFC</mat-label>
                                <input matInput type="text" formControlName="RFC" required>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="col-md-12">
                                <mat-label>Dirección</mat-label>
                                <input matInput type="text" formControlName="direction" required>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="col-md-12">
                                <mat-label>Telefono</mat-label>
                                <input matInput type="text" formControlName="phone" required>
                            </mat-form-field>
        
                            <div class="col-12 text-right mt-3">
                                <button class="btn btn-m19" type="submit" [disabled]="!suppliersFormGroup.valid">
                                    <mat-icon>save</mat-icon>
                                </button>
                            </div>
        
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
        
            <div class="col-md-8">
                <mat-card>
                    <mat-card-content class="controlTable">
                        <table class="table table-striped animated fadeIn faster">
                            <thead class="thead-m19">
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">RFC</th>
                                    <th scope="col">Dirección</th>
                                    <th scope="col">Teléfono</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let supplier of suppliers">
                                    <td>{{supplier.idsupplier}}</td>
                                    <td>{{supplier.suppliername}}</td>
                                    <td>{{supplier.RFC}}</td>
                                    <td style="max-width: 200px;">{{supplier.direction}}</td>
                                    <td>{{supplier.phone}}</td>
                                    <td class="button-row">
                                        <button class="btn btn-m19-table" (click)="editSupplier(supplier.idsupplier)" mat-icon-button>
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button class="btn btn-m19-table" (click)="deleteSupplier(supplier.idsupplier)" mat-icon-button>
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>