<div class="custStCont">
  <div class="searchBar">
    <div class="ng-autocomplete">
      <ng-autocomplete name="busqueda" placeholder="Inmueble/Nombre cliente" [data]="customers"
        [searchKeyword]="keyword" (selected)="selectCustomer($event)" [itemTemplate]="itemTemplate"
        [notFoundTemplate]="notFoundTemplate">
      </ng-autocomplete>

      <ng-template #itemTemplate let-item>
        <div class="customer-item">
          <div class="image-container">
            <img [src]="item.logo" alt="projectImage">
          </div>

          <div class="details-container">
            <div class="name" [innerHTML]="item.name"></div>
            <div class="status"
              [ngStyle]="{'color': item.status=='ACTIVO'? 'VAR(--primaryGreen)' : 'VAR(--primaryRed)'}">
              <b>{{item.status}}</b>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #notFoundTemplate let-notFound>
        <div>Lo siento, no se encontraron resultados</div>
      </ng-template>
    </div>

    <button *ngIf="saleInfo != ''" (click)="print()" class="printButton">
      IMPRIMIR ESTADO DE CUENTA<mat-icon>print</mat-icon>
    </button>
  </div>

  <div id="htmlData" class="statementContainer" [hidden]="true">
    <div *ngIf="saleInfo != ''">
      <div class="statementHeader">
        <div class="m19infoContainer">
          <img src="./assets/iconoM.png" alt="M19Logo">
          <p><b>M19 INMOBILIARIA <br> TU HOGAR, NUESTRO SUEÑO.</b></p>
          <p><b>CALLE PORTUGAL #67 A <br> CD. DEL VALLE, TEPIC NAYARIT, 63157.</b></p>
        </div>
        <div class="principalInfo">
          <p style="border-bottom: solid black 3px;"><b>ESTADO DE CUENTA</b></p>

          <p><b>INMUEBLE:</b> {{saleInfo.codeProperty}}</p>

          <p><b>CLIENTE:</b> {{saleInfo.cliente.toUpperCase()}}</p>

          <p><b>CORREO:</b> {{saleInfo.email}}</p>

          <p *ngIf="saleInfo.mobile!=null"><b>CELULAR:</b> {{saleInfo.mobile}}</p>

          <p><b>RFC:</b> {{saleInfo.RFC==null?'SIN REGISTRAR':saleInfo.RFC}}</p>

          <p><b>FECHA DE EMISIÓN:</b> {{pipe.transform(fechaHoy,
            'longDate')!.toUpperCase()}}</p>
        </div>
      </div>

      <br>

      <h4>INFORMACIÓN DE LA VENTA</h4>
      <div class="financialStatus saleStatus">
        <div class="aditionalInfo">
          <p>
            <b>FECHA DE CONTRATO:</b><br>{{pipe.transform(saleInfo.fechaDato,
            'longDate')!.toUpperCase()}}
          </p>
          <p>
            <b>DESARROLLO:</b><br>{{saleInfo.name.toUpperCase()}}
          </p>
          <p>
            <b>COORDINADOR DE VENTA:</b><br>{{saleInfo.coordinador.toUpperCase()}}
          </p>
          <p>
            <b>VENDEDOR:</b><br>
            {{saleInfo.vendedor==null?'SIN ASIGNAR':saleInfo.vendedor.toUpperCase()}}
          </p>
        </div>
      </div>

      <br>

      <h4>INFORMACIÓN FINANCIERA</h4>
      <div class="financialStatus">
        <div class="debtInfo">
          <p style="border-bottom: solid black 3px;"><b>INFORMACION ORDINAL</b></p>

          <div class="infoColumn">
            <p><b>VALOR TOTAL:</b> {{saleInfo.valor | currency:'CAD' : '$ '}}</p>
            <p><b>MENSUALIDAD:</b> {{saleInfo.monthlyPayment | currency: 'CAD' : '$'}}</p>

            <p><b>PAGOS REALIZADOS:</b>
              {{saleInfo.mPagadas+'/'+((saleInfo.valor-saleInfo.advancePayment)/saleInfo.monthlyPayment).toFixed(0)}}
            </p>

            <p><b>PAGOS ESPERADOS:</b>
              {{saleInfo.meses+'/'+((saleInfo.valor-saleInfo.advancePayment)/saleInfo.monthlyPayment).toFixed(0)}}
            </p>

            <p><b>BALANCE DE PAGOS:</b> {{saleInfo.retraso.toFixed(2)}}</p>
          </div>
        </div>

        <div class="creditInfo">
          <p style="border-bottom: solid black 3px;"><b>INFORMACION DE CRÉDITO</b></p>

          <div class="infoColumn">
            <p><b>ANTICIPO DE CRÉDITO:</b> {{anticipoC | currency: 'CAD' : '$'}}</p>
            <p><b>MENSUALIDADES DE CRÉDITO:</b> {{mensualidadC | currency: 'CAD' : '$'}}</p>

            <p><b>ANTICIPO NO REEMBOLSABLE:</b> {{anticipoNR | currency: 'CAD' : '$'}}</p>
            <p><b>MENSUALIDADES NO REEMBOLSABLES:</b> {{mensualidadNR | currency: 'CAD' : '$'}}</p>
          </div>
        </div>
      </div>
      <div class="totalAmount">
        <p><b>TOTAL ABONADO:</b> {{saleInfo.abonado + (anticipoC + anticipoNR + mensualidadC +
          mensualidadNR) | currency:'CAD' : '$ '}}</p>
        <p><b>TOTAL ADEUDO:</b> {{saleInfo.valor-saleInfo.abonado - (anticipoC + anticipoNR +
          mensualidadC + mensualidadNR) | currency:'CAD' : '$ '}}</p>
      </div>

      <br>

      <h4 class="paymentsSection">HISTORIAL DE PAGOS ORDINALES</h4>
      <table class="paymentsInfo">
        <thead>
          <tr>
            <th>#</th>
            <th>Fecha</th>
            <th>Concepto</th>
            <th>Cantidad</th>
            <th>Método de pago</th>
            <th>Folio</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let payment of payments; let i=index;">
            <td>{{i+1}}</td>
            <td>{{payment.fechaPago | date: 'dd-MM-yyyy'}}</td>
            <td>{{payment.concepto}}</td>
            <td>{{(payment.cantidadAbono | currency:'CAD' : '$ ')}}</td>
            <td>{{payment.metodoPago}}</td>
            <td>{{payment.folioPago}}</td>
          </tr>
        </tbody>
      </table>

      <br>
      <br *ngIf="payments.length>=23"><br *ngIf="payments.length>=23"><br *ngIf="payments.length>=23">

      <h4 *ngIf="creditPayments.length > 0">HISTORIAL DE PAGOS DE CRÉDITO</h4>
      <table *ngIf="creditPayments.length > 0" class="paymentsInfo">
        <thead>
          <tr>
            <th>#</th>
            <th>Fecha</th>
            <th>Concepto</th>
            <th>Cantidad</th>
            <th>Método de pago</th>
            <th>Folio</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let payment of creditPayments; let i=index;">
            <td>{{i+1}}</td>
            <td>{{payment.fechaPago | date: 'dd-MM-yyyy'}}</td>
            <td>{{payment.concepto}}</td>
            <td>{{(payment.cantidadAbono | currency:'CAD' : '$ ')}}</td>
            <td>{{payment.metodoPago}}</td>
            <td>{{payment.folioPago}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <br>

  <p *ngIf="saleInfo == ''" style="text-align: center;"><img class="isotipoLogo" src="assets/isotipo.png"
      alt="M19 Isotipo"></p>

  <div class="statusCard" [ngClass]="{
          'blue': saleInfo.retraso >= 0 && (saleInfo.description != 'No disponible'&&this.saleInfo.value!='No Disponible'),
           'green': saleInfo.retraso < 0 && saleInfo.retraso > -2 && (saleInfo.description != 'No disponible'&&this.saleInfo.value!='No Disponible'),
            'yellow': saleInfo.retraso <= -2 && saleInfo.retraso > -4 && (saleInfo.description != 'No disponible'&&this.saleInfo.value!='No Disponible'),
             'red': saleInfo.retraso <= -4 || (saleInfo.description == 'No disponible'||this.saleInfo.value=='No Disponible'),
              'liq': saleInfo.value == 'Liquidado'&&(saleInfo.description != 'No disponible'&&this.saleInfo.value!='No Disponible'),
               'res': status === 'RESCINDIDO'}" *ngIf="saleInfo != ''">

    <div class="customerStatus" *ngIf="status === 'ACTIVO'">
      <div class="headerContainer red"
        *ngIf="(saleInfo.description == 'No disponible'||this.saleInfo.value=='No Disponible')">
        <b>Cliente BLOQUEADO con {{+((saleInfo.retraso*-1).toFixed(2))<0? (saleInfo.retraso).toFixed(2)+' pagos a
            favor': +((saleInfo.retraso*-1).toFixed(2))==0? 'pagos al corriente' :(saleInfo.retraso*-1).toFixed(2)+'
            pago(s) no reportados'}}.</b>
      </div>
      <div class="headerContainer liq" *ngIf="saleInfo.value == 'Liquidado';">
        <b>Liquidado.</b>
      </div>
      <div class="headerContainer blue"
        *ngIf="saleInfo.retraso >= 0 && saleInfo.value != 'Liquidado' && (saleInfo.description != 'No disponible'&&this.saleInfo.value!='No Disponible')">
        <b>Al corriente con {{saleInfo.retraso.toFixed(2)}} pago(s) adelantados.</b>
      </div>
      <div class="headerContainer green"
        *ngIf="saleInfo.retraso < 0 && saleInfo.retraso > -2 && saleInfo.value != 'Liquidado' && (saleInfo.description != 'No disponible'&&this.saleInfo.value!='No Disponible')">
        <b>Atrasado con {{(saleInfo.retraso*-1).toFixed(2)}} pago(s) atrasados.</b>
      </div>
      <div class="headerContainer yellow"
        *ngIf="saleInfo.retraso <= -2 && saleInfo.retraso > -3 && saleInfo.value != 'Liquidado' && (saleInfo.description != 'No disponible'&&this.saleInfo.value!='No Disponible')">
        <b>Atraso nivel 2 con {{(saleInfo.retraso*-1).toFixed(2)}} pagos atrasados.</b>
      </div>
      <div class="headerContainer yellow"
        *ngIf="saleInfo.retraso <= -3 && saleInfo.retraso > -4 && saleInfo.value != 'Liquidado' && (saleInfo.description != 'No disponible'&&this.saleInfo.value!='No Disponible')">
        <b>Atraso nivel 3: Peligro de rescisión con {{(saleInfo.retraso*-1).toFixed(2)}} pagos
          atrasados.</b>
      </div>
      <div class="headerContainer red"
        *ngIf="saleInfo.retraso <= -4 && saleInfo.value != 'Liquidado' && (saleInfo.description != 'No disponible'&&this.saleInfo.value!='No Disponible')">
        <b>Atraso nivel 4: Recisión recomendada con
          {{(saleInfo.retraso*-1).toFixed(2)}} pagos
          atrasados.</b>
      </div>
    </div>

    <div class="customerStatus" *ngIf="status === 'RESCINDIDO'">
      <div class="headerContainer res">
        <b *ngIf="saleInfo.retraso.toFixed(2)<0">Rescindido con {{saleInfo.retraso.toFixed(2)*-1}}
          pago(s) en deuda.</b>
        <b *ngIf="saleInfo.retraso.toFixed(2)>=0">Rescindido con {{saleInfo.retraso.toFixed(2)}} pago(s)
          a favor.</b>
      </div>
    </div>

    <div class="infoContainer">
      <div class="projectInfo">
        <img [src]="searchLogo(saleInfo.idProject)" alt="Logo de {{saleInfo.name}}">
        <h4>{{saleInfo.name}}</h4>

        <div *ngIf="saleInfo.codeProperty.substr(2,1)=='M'">
          <p>Manzana {{saleInfo.codeProperty.substr(3,2)}}<br> Lote {{saleInfo.codeProperty.substr(6,2)}}
          </p>
        </div>

        <div *ngIf="saleInfo.codeProperty.substr(2,1)=='T'">
          <p>Torre {{saleInfo.codeProperty.substr(3,2)}}<br> Departamento {{saleInfo.codeProperty.substr(6,2)}}
          </p>
        </div>
      </div>

      <div class="infoWrapper">
        <div class="customerInfo">
          <div class="infoRow">
            <div class="icon">
              <mat-icon>person</mat-icon>
            </div>

            <div class="data">
              <span>Cliente</span>
              <span>{{saleInfo.cliente}}</span>
            </div>
          </div>

          <div class="infoRow">
            <div class="icon">
              <mat-icon>smartphone</mat-icon>
            </div>

            <div class="data">
              <span>Celular</span>
              <span>{{saleInfo.mobile!=null?saleInfo.mobile:'Sin teléfono registrado'}}</span>
            </div>
          </div>

          <div class="infoRow">
            <div class="icon">
              <mat-icon>mail</mat-icon>
            </div>

            <div class="data">
              <span>Correo</span>
              <span>{{saleInfo.email}}</span>
            </div>
          </div>

          <div class="infoRow">
            <div class="icon">
              <mat-icon>paid</mat-icon>
            </div>

            <div class="data">
              <span>Abonado</span>
              <span>{{saleInfo.abonado | currency:'CAD' : '$ '}}</span>
            </div>
          </div>

          <div class="infoRow">
            <div class="icon">
              <mat-icon>attach_money</mat-icon>
            </div>

            <div class="data">
              <span>Adeudo</span>
              <span>{{saleInfo.valor-saleInfo.abonado | currency:'CAD' : '$ '}}</span>
            </div>
          </div>
        </div>

        <div class="saleInfo">
          <div class="infoRow">
            <div class="icon">
              <mat-icon>groups</mat-icon>
            </div>

            <div class="data">
              <span>Coordinador</span>
              <span>{{saleInfo.coordinador}}</span>
            </div>
          </div>

          <div class="infoRow">
            <div class="icon">
              <mat-icon>face_6</mat-icon>
            </div>

            <div class="data">
              <span>Vendedor</span>
              <span>{{saleInfo.vendedor != null? saleInfo.vendedor : 'Sin vendedor
                registrado'}}</span>
            </div>
          </div>

          <div class="infoRow">
            <div class="icon">
              <mat-icon>event</mat-icon>
            </div>

            <div class="data">
              <span>Fecha de Contrato</span>
              <span>{{saleInfo.fechaDato | date: 'dd-MM-yyyy'}}</span>
            </div>
          </div>

          <div class="infoRow" *ngIf="status === 'RESCINDIDO'">
            <div class="icon">
              <mat-icon>event</mat-icon>
            </div>

            <div class="data">
              <span>Fecha de Rescisión</span>
              <span>{{saleInfo.dateRecession | date:
                'dd-MM-yyyy'}}</span>
            </div>
          </div>
          
          <div class="infoRow" *ngIf="status === 'RESCINDIDO'">
            <div class="icon">
              <mat-icon>format_quote</mat-icon>
            </div>

            <div class="data">
              <span>Motivo de Rescisión</span>
              <span>{{saleInfo.reasonRecession}}</span>
            </div>
          </div>

          <div class="infoRow">
            <div class="icon">
              <mat-icon>sell</mat-icon>
            </div>

            <div class="data">
              <span>Valor de la unidad</span>
              <span>{{saleInfo.valor | currency:'CAD' : '$ '}}</span>
            </div>
          </div>

          <button *ngIf="blockButton.show && permissions().match('446576656c6f706572')"
            [disabled]="blockButton.disabled" [class]="blockButton.class" style="margin: 15px auto;"
            (click)="initBloq(blockLog.fk_idUser,codeProperty)">
            {{blockButton.text}} <mat-icon style='color:white'>
              {{blockButton.icon}}</mat-icon></button>
        </div>
      </div>
    </div>
  </div>
</div>