//=========================================================================================================
//Importaciones de servicios necesitados
//=========================================================================================================
import { RequisitionService } from 'src/app/services/requisition.service';
import { DeliverynoteService } from 'src/app/services/deliverynote.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';

import { DatePipe } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL

//=========================================================================================================
//Importaciones de los componentes necesitados
//=========================================================================================================
import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogRequisitionComponent } from './dialog-requisition/dialog-requisition.component';
import { DialogDeliveryComponent } from './dialog-delivery/dialog-delivery.component';
import { DialogUpdateRequisitionComponent } from './dialog-update-requisition/dialog-update-requisition.component';
import Swal from 'sweetalert2';
import decode from 'jwt-decode';

import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';

@Component({
  selector: 'app-requisitionconsult',
  templateUrl: './requisitionconsult.component.html',
  styleUrls: ['./requisitionconsult.component.scss']
})
export class RequisitionconsultComponent implements OnInit {
  requisitionTitle: BannerTitle = {
    title: "Consulta de requisiciones",
    icon: "construction",
    position: "center",
    background: "var(--M19Secundary)"
  }

  showButton: Boolean = false;

  pipe = new DatePipe('es-MX');

  filterRequisitions = '';

  projectSelected: number;
  moduleSelected: number;

  projectName: string = '';
  module: string = '';

  //Objeto que contiene las requisiciones encontradas en la BD
  requisitions: any = ['Sin seleccionar'];

  //Objeto que contiene las partidas encontradas al seleccionar una requisición
  departures: any = [];

  delivery: any = [];

  projects: any = [];
  modules: any = [];

  reqSKU: any;
  invSKU: any;

  //Bandera que decide entre requisiciones con NR o sin NR
  DeliveryFlag: number = 0;

  constructor(
    //======================================================================
    //Declaraciones de los servicios utilizados
    private requisitionService: RequisitionService,
    private deliveryNoteService: DeliverynoteService,
    private projectService: ProjectService,
    private projectDetService: ProjectdetailsService,

    private el: ElementRef,

    //======================================================================
    //Declaración del componente gráfico utilizado para cuadro de dialogo
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getProjects();
    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL

    this.buttonSelector();
  }

  buttonSelector() {
    const projectButtons = document.querySelectorAll('.projectBtn');

    projectButtons.forEach(b => {
      b.addEventListener('click', function () { 
        projectButtons.forEach(btn => {
          btn.classList.remove('m19SelectedBtn');
        });

        this.classList.add('m19SelectedBtn');
      });
    });
  }

  async getProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      }
    );
  }

  async filterByProject(id: number) {
    this.projectDetService.selectDetailsById(id).subscribe(
      res => {
        this.modules = res;
      }
    );
  }

  async filterByModule(id: number) {
    this.requisitionService.selectRequisitionByModule(id).subscribe(
      res => {
        this.requisitions = res;
      }
    );
  }

  //=========================================================================================================
  //Función para elegir las partidas de una requisición por su ID
  //=========================================================================================================
  async selectDeparture(idreq: number) {
    //Se apertura un cuadro de dialogo para visualizar los datos detallados de la requisición y partidas
    this.dialog.open(DialogRequisitionComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '100%',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: idreq
    });
  }

  permissions() {
    const token = localStorage.getItem('token') || "";

    let decodetoken: any = {};
    decodetoken = decode(token);

    return decodetoken.permissions;
  }

  //=========================================================================================================
  //Función que llama un cuadro de dialogo de confirmación para borrar requisición por su ID
  //=========================================================================================================
  async deleteRequisition(id: number) {
    Swal.fire({
      title: '¿Estás seguro de eliminar esta requisición?',
      text: "Esto también eliminará las remisiones y facturas vinculadas con esta requisición. \n ¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, espera',
      confirmButtonText: 'Sí, elimínala!',
      buttonsStyling: false,
      customClass: {
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn",
        actions: "sweetActions"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Requisición eliminada con éxito!',
          'Esta requisición fue eliminada junto con sus facturas y notas de remisión.',
          'success'
        )
        this.requisitionService.deleteRequisition(id).subscribe(
          res => {
            this.filterByModule(this.moduleSelected);
          }
        )
      }
    })
  }

  selectDelivery(id: Number) {
    this.dialog.open(DialogDeliveryComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '100%',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: id
    });
  }

  async filtrarSinRemision() {
    this.DeliveryFlag = 1;
    this.requisitionService.getRequisitionNoDelivery().subscribe(
      res => {
        this.requisitions = res;
      }
    );
  }

  async updateRequisition(idr: any, idp: any) {
    let info = { 'idrequisition': idr, 'idproject': idp };
    let dialogRef = this.dialog.open(DialogUpdateRequisitionComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '100%',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: info
    });

    dialogRef.afterClosed().subscribe(
      res => {
        this.filterByModule(this.moduleSelected);
      }
    );
  }

  scrollToTop() {
    const container = document.querySelector('.reqContenedor');
    container!.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  onScroll() {
    const element = this.el.nativeElement.querySelector('.reqContenedor');
    if (element.scrollTop > 100) {
      this.showButton = true;
    } else {
      this.showButton = false;
    }
  }
}