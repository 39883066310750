import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterRequisitions'
})
export class FilterRequisitionsPipe implements PipeTransform {

  transform(value: any, arg: any): any {

    const resultPosts = [];

    for (const post of value) {
      if (post.requisitionnumber.indexOf(arg) > -1) {
        resultPosts.push(post);
      };
    };

    return resultPosts;
  }

}
