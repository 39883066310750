import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlockLogService {

  constructor(private http: HttpClient) { }

  insertLog(blocklog: any): Observable<any> {
    return this.http.post(`${environment.URLServer}/blockLog/insertLog`, blocklog);
  }

  getLastLog(idUser:number,codeProperty:string): Observable<any> {
    return this.http.get(`${environment.URLServer}/blockLog/getLastLog/${idUser}/${codeProperty}`);
  }

  getSalesLog(idUser:number,codeProperty:string): Observable<any> {
    return this.http.get(`${environment.URLServer}/blockLog/getSalesLog/${idUser}/${codeProperty}`);
  }

  blockLocationSales(idUser:number,codeProperty:string,blockLog:any): Observable<any> {
    return this.http.put(`${environment.URLServer}/blockLog/blockLocationSales/${idUser}/${codeProperty}`,blockLog);
  }

  blockLocationProperties(codeProperty:string): Observable<any> {
    return this.http.put(`${environment.URLServer}/blockLog/blockLocationProperties/${codeProperty}`,{});
  }

  unlockProperties(unlockDetails:any): Observable<any> {
    return this.http.put(`${environment.URLServer}/blockLog/unlockProperties`,unlockDetails);
  }
}
