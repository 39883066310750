import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Recessions } from 'src/app/models/Recessions';
import { SaleService } from 'src/app/services/sale.service';

@Component({
  selector: 'app-detail-recessions',
  templateUrl: './detail-recessions.component.html',
  styleUrls: ['./detail-recessions.component.scss']
})
export class DetailRecessionsComponent implements OnInit {

  recessionInfo: any = [];

  constructor(
    private saleService: SaleService,
    private dialogRef: MatDialogRef<DetailRecessionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.saleService.getRecessionInfo(this.data.property, this.data.customer).subscribe(
      res => {
        this.recessionInfo = res;
      }
    );
  }
}