import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PaymentMetodeService } from 'src/app/services/paymentMetode.service';
import { SweetAlertComponent } from '../../../utils/sweet-alert/sweet-alert.component';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {

  payment_method: any = [];

  paymentMethodFormGroup: FormGroup = this._formBuilder.group({
    id: [],
    formaPago: ['', Validators.required]
  })

  constructor(
    private paymentMetodeService: PaymentMetodeService,
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.selectPaymentsMethods();
  }

  selectPaymentsMethods() {
    this.paymentMetodeService.selectMetodePayments().subscribe(
      res => {
        this.payment_method = res;
      },
      err => console.log(err)
    );
  }

  createPaymentMethod() {
    if (this.paymentMethodFormGroup.invalid) {
      return;
    }
    else {
      this.paymentMetodeService.createMetodePayment(this.paymentMethodFormGroup.value)
        .subscribe(
          res => {
            console.log(res);
            this.sweet.AlertTime("center", "success", "Método de pago registrado con éxito", false, 2000);
            this.selectPaymentsMethods();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['setting']));
          },
          err => {
            console.error(err);
            this.sweet.AlertTime("center", "error", "Método de pago no registrado", false, 2000);
          }
        );
    }
  }

  deletePaymentMethod(id: number){
    this.paymentMetodeService.deleteMetodePayment(id).subscribe(
      res => {
        console.log(res);
        this.sweet.AlertTime("center", "success", "Método de pago eliminado con éxito", false, 2000);
        this.selectPaymentsMethods();
      },
      err => {
        console.error(err);
        this.sweet.AlertTime("center", "error", "No se puede eliminar el método de pago", false, 2000);
      }
    )
  }

}
