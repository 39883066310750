import { Component, OnInit } from '@angular/core';

import { ClientNegotiation } from 'src/app/models/ClientNegotiation';
import { Negotiation } from 'src/app/models/Negotiation';
import { NegotiationUnit } from 'src/app/models/NegotiationUnit';

import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { DatePipe, registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL

import { AdministrationService } from 'src/app/services/administration.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UserService } from 'src/app/services/user.service';

import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import Swal from 'sweetalert2';
import decode from 'jwt-decode';
import { loader } from 'src/app/ui/loader/loader.model';
import { MatDialog } from '@angular/material/dialog';
import { RescissionAgreementComponent } from '../rescission-agreement/rescission-agreement.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-negotiation',
  templateUrl: './negotiation.component.html',
  styleUrls: ['./negotiation.component.scss']
})
export class NegotiationComponent implements OnInit {
  loader: loader = {
    message: "Preparando Información"
  }

  loading: number = 0;

  decode: any = {};
  pipe = new DatePipe('es-MX');
  mode: string = "filter";

  customers: ClientNegotiation[] = [];
  responsables: any = [];


  filterCustomers = '';
  fullname = 'fullname';
  negotiator = "";
  negotiating: boolean = false;

  totalSanction: number = 0;
  negotiationDate: Date;
  initialPayment: number = 0;
  installments: number = 0;
  frequency: string = "";
  firstPayDate: number = 0;
  clabeNumber: number = 0;
  reason: number = 0;

  representative: string = "";

  toNegotiate: NegotiationUnit[] = [];
  payments: any = [];

  customerSelected: number = 0;
  propertySelected: string = "";
  balance: number = 0;
  sanctionAmount: number = 0;
  propertyAmount: number = 0;
  annomaly: string = "";

  totalDevolution: number = 0;

  constructor(
    public adminService: AdministrationService,
    public paymentService: PaymentService,
    public userService: UserService,
    private dialog: MatDialog,
    public sweet: SweetAlertComponent,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getResponsable();
    this.getCustomersList();
    this.loadCustomers();
    registerLocaleData(localeEsMx, 'es-MX');
  }

  getCustomersList() {
    this.userService.getCustomers().subscribe(
      res => {
        this.responsables = res;
      }
    );
  }

  loadCustomers(): void {
    this.adminService.getCustomersToNegotiate().subscribe(
      customers => {
        this.customers = customers.data;
        console.log("this", this.customers);
      },
      error => {
        console.error('Error al cargar clientes:', error);
      }
    );
  }

  selectCustomer(e: any) {
    this.customerSelected = e.idUser;
  }

  async getUnitDetails(client: ClientNegotiation) {
    console.log(client);
    if (!this.negotiating) {
      this.sweet.AlertTime("center", "error", "Necesitas iniciar una negociación nueva primero.", false, 2000);
      return;
    }

    const exist = this.toNegotiate.find(item => item.FKCustomer === client.idUser && item.FKProperty === client.property);
    if (exist) {
      this.sweet.AlertTime("center", "error", "Este cliente ya está en la negociación.", false, 2000);
    } else {

      // Existencia de anomalías
      // if ((typeof client.state === "number" && (client.monthRecession*-1) < client.state-1)) {
      //   const { value: MBloqueo } = await Swal.fire({
      //     title: 'Advertencia',
      //     html: "Inmueble: <b>" + client.property + "</b><br>" +
      //       "Cliente: <b>" + client.customer + "</b><br>" +
      //       "Este caso tiene algunas anomalías",
      //     showCancelButton: true,
      //     cancelButtonText: 'Volver',
      //     confirmButtonText: 'Guardar',
      //     buttonsStyling: false,
      //     customClass: {
      //       confirmButton: "m19AltBtn",
      //       cancelButton: "m19Btn",
      //       actions: "sweetActions"
      //     }
      //   })
      //   if (MBloqueo) {

      //   } else {

      //   }
      // }

      this.balance = 0;
      this.propertyAmount = 0;
      if (client.state == "Rescindido") {
        this.paymentService.getPaymentTotalRes(client.property, client.idUser).subscribe(
          total => {
            this.mode = "sanction";
            this.customerSelected = client.idUser;
            this.propertySelected = client.property;
            this.balance = total.paymentTotal;
            this.propertyAmount = total.amount;
            this.sanctionAmount = this.propertyAmount * 0.10 > this.balance ? this.balance : this.propertyAmount * 0.10;
          }
        );
      } else {
        this.paymentService.getPaymentTotal(client.property).subscribe(
          total => {
            this.paymentService.getCreditNoteTotal(client.property).subscribe(
              creditTotal => {
                this.mode = "sanction";
                this.customerSelected = client.idUser;
                this.propertySelected = client.property;
                this.balance = total.paymentTotal + creditTotal.ncPaymentTotal;
                this.propertyAmount = total.amount;
                this.sanctionAmount = this.propertyAmount * 0.10 > this.balance ? this.balance : this.propertyAmount * 0.10;

                this.paymentService.getAllPayments(client.property).subscribe(
                  res => {
                    this.payments = res;
                  }
                );
              }
            );
          }
        );
      }
    }
  }

  cleanAddition() {
    this.mode = "filter";
    this.balance = 0;
    this.customerSelected = 0;
    this.propertySelected = "";
    this.propertyAmount = 0;
    this.sanctionAmount = 0;
    this.payments = [];
    this.annomaly = "";
  }

  resetProcess() {
    this.cleanAddition();
    this.totalDevolution = 0;
    this.toNegotiate = [];
    this.representative = "";
    this.initialPayment = 0;
    this.installments = 0;
    this.frequency = "";
    this.mode = "filter";
    this.negotiating = false;
  }

  addUnitToNegotiation() {
    const unit = { "FKNegotiation": 0, "FKCustomer": this.customerSelected, "FKProperty": this.propertySelected, "SanctionAmount": this.sanctionAmount, "DevolutionAmount": (this.balance - this.sanctionAmount) }
    this.toNegotiate.push(unit);
    this.totalDevolution += (this.balance - this.sanctionAmount);
    this.totalSanction += this.sanctionAmount;
    console.log(this.toNegotiate);
    this.cleanAddition();
  }

  dropUnitFromNegotiation(elem: number) {
    Swal.fire({
      title: '¿Quieres eliminar este cliente de la negociación?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'No, espera',
      confirmButtonText: 'Sí, elimínalo!',
      buttonsStyling: false,
      customClass: {
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn",
        actions: "sweetActions"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.totalDevolution -= this.toNegotiate[elem].DevolutionAmount;
        this.totalSanction -= this.toNegotiate[elem].SanctionAmount;
        this.toNegotiate.splice(elem, 1);
      }
    })
  }

  deleteRepresentative() {
    Swal.fire({
      title: '¿Quieres eliminar este apoderado?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'No, espera',
      confirmButtonText: 'Sí, elimínalo!',
      buttonsStyling: false,
      customClass: {
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn",
        actions: "sweetActions"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.representative = "";
      }
    })
  }

  async saveNegotiationIntoBD() {
    this.changeLoadingMessage("Validando información");
    if (this.toNegotiate.length == 0) {
      this.loading = 0;
      this.sweet.AlertTime("center", "error", "Esta negociación no tiene unidades en la lista.", false, 2000);
      return;
    }

    if (this.customerSelected == 0 || this.frequency == '') {
      this.loading = 0;
      this.sweet.AlertTime("center", "error", "Se necesita elegir un cliente y un periodo de devolución.", false, 2000);
      return;
    } /* else if (this.initialPayment == 0 && this.installments == 0) {
      this.loading = 0;
      this.sweet.AlertTime("center", "error", "El pago inicial y el monto de parcialidades no pueden ser 0 a la vez.", false, 2000);
      return;
    }*/

    let negotiation: Negotiation = {
      FKEmployee: this.getResponsable(),
      FKCustomer: this.customerSelected,
      NegotiationDate: this.negotiationDate,
      InitialPayment: this.initialPayment,
      FirstPayDays: this.firstPayDate,
      Installments: this.installments,
      Frequency: this.frequency,
      CLABE: this.clabeNumber + ""
    };

    let units = [];

    for (let n of this.toNegotiate) {
      units.push({ "customer": n.FKCustomer, "property": n.FKProperty });
    }

    let customers = ""
    let codeProperties = ""
    console.log(units);
    units.forEach(item => {
      customers += item.customer + ",";
      codeProperties += item.property + ",";
    });
    console.log(customers, codeProperties);

    const dialogRef = this.dialog.open(RescissionAgreementComponent, {
      width: '1000px',
      height: '90%',
      data: {
        "customers": customers,
        "codeProperties": codeProperties,
        "penalizedBalance": this.totalSanction,
        "bankAccount": this.clabeNumber,
        "frequency": this.frequency,
        "memo": this.installments,
        "firstPay": this.firstPayDate,
        "reason": this.reason,
        "legalRepresentative": "",
        "negotiationData": negotiation,
        "representative": this.representative,
        "toNegotiate": this.toNegotiate
      }
    })
  }

  getResponsable() {
    this.decode = decode(localStorage.getItem("token") || "")
    this.negotiator = this.decode.cliente;
    return this.decode.fkuser;
  }

  changeLoadingMessage(msg: string) {
    this.loader = {
      ...this.loader,
      message: msg
    };
  }
}
