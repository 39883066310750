
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ProjectService } from 'src/app/services/project.service';
import { PaymentsCommissionsService } from 'src/app/services/paymentsCommissions.service';


@Component({
  selector: 'app-payment-commissions',
  templateUrl: './payment-commissions.component.html',
  styleUrls: ['./payment-commissions.component.scss']
})
export class PaymentCommissionsComponent implements OnInit {
  namePro: any = [];
  projects: any = [];
  type : number ;


  paymentsByProject : any = [];
  paymentsCommissionsByProject : any = [];

  unionByProject : any = [];

  constructor(
    public projectService: ProjectService,
    public paymentsCommissionsService: PaymentsCommissionsService
  ) {
    this.projectService.selectProjects().subscribe(res => { this.projects = res; }, err => console.log(err));
    this.getCommissionsForProject("Xalta");
    this.getCommissionsPaid("Xalta");
    this.type = 0;
  }

  ngOnInit(): void { }

  getCommissionsForProject(project: string) {
    this.paymentsCommissionsService.getCommissionsService(project).subscribe(
      res => {
        this.paymentsByProject = res;
        this.paymentsByProject.length == 0 ? this.type = 400 : this.type = this.paymentsByProject[0].tipocomision
      }
    )
  }

  async getCommissionsPaid(project: string){
      this.paymentsCommissionsService.getCommissionsPaidService(project).subscribe(
        res => {
          this.paymentsCommissionsByProject = res;
        }
      )
  }

  mezclatipos(){
    console.log("Pagos " , this.paymentsByProject)
    console.log("comisiones " , this.paymentsCommissionsByProject)
    
    for(let i = 0; i < this.paymentsCommissionsByProject.length; i++){
        for(let k = 0; k < this.paymentsByProject.length; k++){
          if(this.paymentsCommissionsByProject[i].fk_property == this.paymentsByProject[k].depa){
            console.log("Comision" , this.paymentsCommissionsByProject[i].fk_property , " " , this.paymentsByProject[k].depa , " " , "Pagos")
            this.unionByProject.push(this.paymentsByProject[i] )
          }else{
            this.unionByProject.push(this.paymentsByProject[k] , 0, 0 )
          }

        }
    }


    console.log("Arreglo completo" , this.unionByProject)

  } 



}
