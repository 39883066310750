import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { invoiceReportService } from 'src/app/services/invoice.service';

@Component({
  selector: 'app-dialog-invoice',
  templateUrl: './dialog-invoice.component.html',
  styleUrls: ['./dialog-invoice.component.scss']
})
export class DialogInvoiceComponent implements OnInit {

  public invoiceData: any;
  public isLoading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DialogInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: invoiceReportService
  ) {
  }

  ngOnInit(): void {
    console.log(
      this.data
    )
    this.getInvoiceData();
  }


  private getInvoiceData() {

    this.service.getInvoiceData(this.data).subscribe(
      res => {
        this.invoiceData = res
        this.isLoading = true;
        console.log("Datos completos de la factura", this.invoiceData)
      },
      err => {
        console.log("no se pudo recuperar la factura")
      }
    )
  }


}
