//=========================================================================================================
//Importaciones de servicios necesitados
//=========================================================================================================
import { RequisitionService } from 'src/app/services/requisition.service';
import { PieceworkService } from 'src/app/services/piecework.service';
import { DepaproService } from 'src/app/services/depapro.service';
import { DeliverynoteService } from 'src/app/services/deliverynote.service';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';
import { ProjectService } from 'src/app/services/project.service';
import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';


import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import Swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DepaPieceService } from 'src/app/services/depa-piece.service';
import { DialogPieceworksComponent } from '../../piecework/pieceworkconsult/dialog-pieceworks/dialog-pieceworks.component';



@Component({
  selector: 'app-pieceworkapprove',
  templateUrl: './pieceworkapprove.component.html',
  styleUrls: ['./pieceworkapprove.component.scss']
})
export class PieceworkapproveComponent implements OnInit {
  bannerTitle: BannerTitle = {
    title: "Aprobar destajos",
    icon: "handyman",
    position: "center",
    background: "var(--M19Secundary)"
  }

//Objeto que contiene las partidas encontradas al seleccionar una requisición
departures: any = [];

auth: any ='';
watching: string = 'Ninguno';
//Objeto que contiene los destajos encontradas al seleccionar una requisición
pieceworks: any = [];
idproject: number=0;
project: string='Ninguno';
projects: any=[];
modules: any=[];

//Bandera que decide entre requisiciones con NR o sin NR
DeliveryFlag: number = 0;
delivery: any = [];
view: any = false;
type: any = true;

  constructor(
     //======================================================================
    //Declaraciones de los servicios utilizados
    public requisitionService: RequisitionService,
    public departureproService: DepaproService,
    public deliveryNoteService: DeliverynoteService,
    public pieceworksService: DepaPieceService,
    public pieceworkService: PieceworkService,
    public projectDetailService: ProjectdetailsService,
    public projectService: ProjectService,
    public sweet: SweetAlertComponent,

    //======================================================================
    //Declaración del componente gráfico utilizado para cuadro de dialogo
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
        //Se manda a llamar en el arranque a la función que obtiene los destajos en espera
        //this.selectAwait();
        //Se manda a llamar la función para obtener todos los proyectos
        this.getProjects();
  }

  print(): void {
    if (this.auth == '') {
      this.sweet.AlertTime("center", "warning", "Se necesita indicar un responsable.", false, 2000);
      return;
    }
    Swal.fire({
      title: 'Ingresa un nombre para el archivo',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Generar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        let printContents, popupWin;
        console.log(result.value);
        this.auth = result.value;
        printContents = document.getElementById('htmlData')!.innerHTML;
        popupWin = window.open('', '', '');
        popupWin!.document.open();
        popupWin!.document.write(`
      <html>
        <head>
          <title>Nomina Destajos</title>
          <style>
            table {
              width: 100%;
              margin-bottom: 20px;
            }
            table, th, tr, td {
              border-collapse: collapse;
              border: solid black 2px;
            }
            td {
              font-size: 0.8rem;
            }
            img {
              margin: auto;
            }
            .datos {
              text-align: left;
            }
            .firmas {
              display: flex;
              width: 100%;
              border: solid black 2px;
              padding-top: 70px;
            }
            .firmas .elabora {
              width: 50%;
              text-align: center;
            }
            .firmas .aprueba {
              width: 50%;
              text-align: center;
            }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
        );
        popupWin!.document.close();
      }
    })

  }
  //=========================================================================================================
  //Función para obtener todas las requisiciones de la base de datos
  //=========================================================================================================
  async getAll() {
    this.DeliveryFlag = 0;
    this.pieceworkService.getAll(this.idproject).subscribe(
      res => {
        this.pieceworks = res;
        this.watching='Todos';
      },
      err => console.log(err)
    )
  }

  //=========================================================================================================
  //Función para obtener todas las requisiciones con destajos
  //=========================================================================================================
  async getWithPieces() {
    this.requisitionService.selectRequisitionWithPiece().subscribe(
      res => {
        this.pieceworks = res;
      },
      err => console.log(err)
    )
  }


  //=========================================================================================================
  //Función para elegir los destajos de una requisición por su ID
  //=========================================================================================================
  async selectPiecework(num: number) {
    this.dialog.open(DialogPieceworksComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '1000px',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: num
    });
  }

  //=========================================================================================================
  //Función para alternar entre la información a visualizar
  //=========================================================================================================
  async alternarInfo(){
  this.type = !this.type;
  if (this.type) {
    this.selectAwait();
  } else if (!this.type) {
    //this.selectAwaitPiece();
  }
}

  //=========================================================================================================
  //Función para aprobar una requisición
  //=========================================================================================================
  async approvePiece(id: number){
  Swal.fire({
    title: '¿Estás seguro?',
    text: "Aprobarás esta requisición y no se podrá revertir",
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Sí, lo apruebo',
    cancelButtonText: 'No, espera'
  }).then((result) => {
    if (result.isConfirmed) {
      this.pieceworkService.approvePiece(id).subscribe(
        res => {
          Swal.fire(
            '¡Aprobado!',
            'La requisición se aprobó.',
            'success'
          )
          this.selectAwait();
        }
      );
    }
  })
}

  //=========================================================================================================
  //Función para rechazar una requisición
  //=========================================================================================================
  async rejectPiece(id: number){
  Swal.fire({
    title: '¿Estás seguro?',
    text: "Rechazarás esta requisición y no se podrá revertir",
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Sí, la rechazo',
    cancelButtonText: 'No, espera'
  }).then((result) => {
    if (result.isConfirmed) {
      this.pieceworkService.rejectPiece(id).subscribe(
        res => {
          Swal.fire(
            '¡Rechazado!',
            'La requisición se rechazó.',
            'success'
          )
          this.selectAwait();
        }
      );
    }
  })
}

  //=========================================================================================================
  //Función para visualizar las requisiciones aprobadas
  //=========================================================================================================
  async selectApproved(){
  this.pieceworkService.selectApproved(this.idproject).subscribe(
    res => {
      this.pieceworks = res;
      this.watching='Aprobados';
    }
  );
}

  //=========================================================================================================
  //Funcion para visualizar las requisiciones rechazadas
  //=========================================================================================================
  async selectReject(){
  this.pieceworkService.selectReject(this.idproject).subscribe(
    res => {
      this.pieceworks = res;
      this.watching='Rechazados';
    }
  );
}

  //=========================================================================================================
  //Función para visualizar las requisiciones sin validación
  //=========================================================================================================
  async selectAwait(){
  this.pieceworkService.selectAwait(this.idproject).subscribe(
    res => {
      this.pieceworks = res;
      this.watching='En espera';
    }
  );
}

// Proyectos 
async getProjects() {
  this.projectService.selectProjects().subscribe(
    res => {
      this.projects = res;
    }
  );
}


// Proyectos por modulo
getModulesByProject(id: any, name: any) {
  this.idproject=id;
  this.project = name;
  this.pieceworks = [];
  this.watching="Ninguno";
}

}

