<app-loader [loader]="loader" *ngIf="negotiationService.isLoading; else content"></app-loader>

<ng-template #content>
    <mat-dialog-content>
        <div class="detailContainer">
            <span class="detailContainer__detailTitle">
                <span><b>Negociación de {{negotiation.NegotiationResponsible}}</b></span>
            </span>

            <span class="detailContainer__detailData">
                <div class="detailContainer__detailData__negotiationResume">
                    <span><b>Fecha de convenio:</b> {{pipe.transform(negotiation.NegotiationDate, 'longDate')}}</span>
                    <span><b>Pago inicial:</b> {{negotiation.InitialPayment | currency: '$ '}}</span>
                    <span><b>Días hábiles para primer pago:</b> {{negotiation.FirstPayDays}} días</span>
                    <span><b>Parcialidades:</b> {{negotiation.Installments | currency: '$ '}}</span>
                    <span><b>Frecuencia:</b> {{negotiation.Frequency}}</span>
                    <span><b>Apoderado:</b> {{negotiation.Representative}}</span>
                    <br>
                    <span><b>Devolución Total:</b> {{negotiation.DevolutionTotal | currency: '$ '}}</span>
                    <span style="display: flex;">
                        <b>Devuelto hasta la fecha:</b> {{negotiation.PaymentTotal | currency: '$ '}}
                        <ng-container *ngIf="negotiation.Remaining == 0">
                            <span class="material-symbols-outlined" style="color: var(--primaryBlue);"
                                data-title="Liquidado">
                                equal
                            </span>
                        </ng-container>

                        <ng-container *ngIf="negotiation.Remaining < 0">
                            <span class="material-symbols-outlined" style="color: var(--primaryRed);"
                                data-title="Excedente pagado">
                                arrow_drop_up
                            </span>
                        </ng-container>

                        <ng-container *ngIf="negotiation.Remaining > 0">
                            <span class="material-symbols-outlined" style="color: var(--primaryYellow);"
                                data-title="Debajo del total">
                                arrow_drop_down
                            </span>
                        </ng-container>
                    </span>
                    <span><b>Último pago:</b> {{pipe.transform(negotiation.LastPayment, 'longDate') || 'N/A'}}</span>
                </div>

                <div class="detailContainer__detailData__negotiationUnits">
                    <ng-container *ngFor="let unit of unitsList; last as isLast">
                        <div class="unitCard">
                            <span><b>{{unit.FKProperty}}</b></span>
                            <span><b>Sanción:</b> {{unit.SanctionAmount | currency: '$ '}}</span>
                            <span><b>Devolución:</b> {{unit.DevolutionAmount | currency: '$ '}}</span>
                        </div>

                        <mat-divider *ngIf="!isLast"></mat-divider>
                    </ng-container>
                </div>
            </span>

            <div class="detailContainer__negotiationPaybacks">
                <span><b>Listado de devoluciones</b></span>

                <ng-container *ngIf="paybacks.length > 0; Else NoPayments;">
                    <div class="detailContainer__negotiationPaybacks__paybacksContainer">
                        <ng-container *ngFor="let p of paybacks; index as i;">
                            <div class="paybackCard" (click)="printReceipt(p.PKPaybackRecord)">
                                {{i+1}}
                                <div class="paybackDetail">
                                    <span style="display: flex; justify-content: space-between;">
                                        <span><b>{{pipe.transform(p.PaybackDate, 'fullDate')}}</b></span>
                                        <span><b style="color: gray;">Folio: {{p.PaybackFolio}}</b></span>
                                    </span>
                                    <span style="display: flex; justify-content: space-between;">
                                        <span>{{p.PaybackResponsible}}</span>
                                        <span><b style="color: var(--primaryRed);">{{p.PaybackAmount | currency: '$
                                                '}}</b></span>
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-template #NoPayments>
                    <div>
                        <span>Sin devoluciones registradas</span>
                    </div>
                </ng-template>

            </div>
        </div>
    </mat-dialog-content>
</ng-template>