<div class="dateRange">
    <mat-form-field appearance="fill">
        <mat-label>Ingresa el rango de fechas </mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Fecha de inicio incorrecta</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fecha de finalizacion incorrecta</mat-error>
    </mat-form-field>

    <button class="btn btn-m19-info" mat-icon-button [disabled]="range.invalid" (click)="generateReport()">
        <mat-label>Generar Reporte</mat-label>
        <mat-icon class="dateRange__icon">cloud_download</mat-icon>
    </button>
</div>

<div class="menuSelector">
    <div class="menuSelector__info">
        Informacion
    </div>
    <div class="selectedInfoDiv">
        <div class="selectedInfoDiv__segment">
            <span class="selectedInfoDiv__segment__title">{{range.value.start | date:'shortDate'}}</span>
            <span class="selectedInfoDiv__segment__subtitle">Fecha Inicio</span>
        </div>
        <div class="selectedInfoDiv__segment">
            <span class="selectedInfoDiv__segment__title">{{range.value.end | date:'shortDate'}}</span>
            <span class="selectedInfoDiv__segment__subtitle">Fecha Fin</span>
        </div>
    </div>
</div>

<div class="FBAccions">
    <div class="FBAccions__info">
        Acciones
    </div>
    <button [disabled]="range.invalid" style="margin-left: 17px;" class="btn btn-m19-info FBAccions__btnFBA" mat-icon-button (click)= "print()">
        <mat-label>Guardar</mat-label>
        <mat-icon class="dateRange__icon">save_alt</mat-icon>
    </button>
    <button [disabled]="range.invalid" class="btn btn-m19-info FBAccions__btnFBA" mat-icon-button>
        <mat-label>Enviar</mat-label>
        <mat-icon class="dateRange__icon">send</mat-icon>
    </button>
</div>

<mat-tab-group mat-align-tabs="center">
    <mat-tab label="Facturas pendientes">
        <app-report-tab [grafic]="pendientes"></app-report-tab>
    </mat-tab>
    <mat-tab label="Facturas revisadas">
        <app-report-tab [grafic]="revisadas"></app-report-tab>
    </mat-tab>
    <mat-tab label="Facturas emitidas">
        <app-report-tab [grafic]="emitidas"></app-report-tab>
    </mat-tab>
</mat-tab-group>

<div id="htmlData" class="statementContainer" [hidden]="true">
    <div>
        <div class="statementHeader">
            <div class="m19infoContainer">
                <img src="./assets/iconoM.png" alt="M19Logo">
                <p><b>M19 INMOBILIARIA <br> TU HOGAR, NUESTRO SUEÑO.</b></p>
                <p><b>CALLE GENOVA #28 A <br> CD. DEL VALLE, TEPIC NAYARIT, 63157.</b></p>
            </div>
            <div class="principalInfo">
                <p style="border-bottom: solid black 3px;"><b>REPORTE DE FACTURACION</b></p>

                <p><b>FECHA DE INICIO:</b> {{range.value.start |  date: 'dd-MM-yyyy'}}</p>

                <p><b>FECHA FINAL:</b> {{range.value.end |  date: 'dd-MM-yyyy'}}</p>

                <p><b>REALIZADO:</b> {{user}}</p>

                <p><b>FECHA DE EMISIÓN:</b> {{pipe.transform(fechaHoy,
                    'longDate')!.toUpperCase()}}</p>
            </div>
        </div>

        <br>

        <h4 class="">FACTURAS REALIZADAS</h4>
        <table class="paymentsInfo">
            <thead>
                <tr>
                    <th>Inmueble</th>
                    <th>Cliente</th>
                    <th>Fecha</th>
                    <th>Método de pago</th>
                    <th>Cantidad</th>
                    <th>Folio</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let payment of emitidas; let i=index;">
                    <td>{{payment.fkProperty}}</td>
                    <td>{{payment.UserName}}</td>
                    <td>{{payment.registrationDate | date: 'dd-MM-yyyy'}}</td>
                    <td>{{payment.paymentType}}</td>
                    <td>{{(payment.amountInvoice | currency:'CAD' : '$ ')}}</td>
                    <td>{{payment.folInvoice}}</td>
                </tr>
            </tbody>
        </table>

        <br>
        <br *ngIf="emitidas.length>=23"><br *ngIf="emitidas.length>=23"><br *ngIf="emitidas.length>=23">

        <h4 class="">FACTURAS REVISADAS</h4>
        <table class="paymentsInfo">
            <thead>
                <tr>
                    <th>Inmueble</th>
                    <th>Cliente</th>
                    <th>Fecha</th>
                    <th>Método de pago</th>
                    <th>Cantidad</th>
                    <th>Folio</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let payment of revisadas; let i=index;">
                    <td>{{payment.fkProperty}}</td>
                    <td>{{payment.UserName}}</td>
                    <td>{{payment.fechaPago | date: 'dd-MM-yyyy'}}</td>
                    <td>{{payment.paymentType}}</td>
                    <td>{{(payment.amountInvoice | currency:'CAD' : '$ ')}}</td>
                    <td>{{payment.folInvoice}}</td>
                </tr>
            </tbody>
        </table>

        <br>
        <br *ngIf="emitidas.length>=23"><br *ngIf="emitidas.length>=23"><br *ngIf="emitidas.length>=23">

        <h4 class="">FACTURAS PENDIENTES</h4>
        <table class="paymentsInfo">
            <thead>
                <tr>
                    <th>Inmueble</th>
                    <th>Cliente</th>
                    <th>Fecha</th>
                    <th>Método de pago</th>
                    <th>Cantidad</th>
                    <th>Folio</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let payment of pendientes; let i=index;">
                    <td>{{payment.fkProperty}}</td>
                    <td>{{payment.UserName}}</td>
                    <td>{{payment.registrationDate | date: 'dd-MM-yyyy'}}</td>
                    <td>{{payment.paymentType}}</td>
                    <td>{{(payment.amountInvoice | currency:'CAD' : '$ ')}}</td>
                    <td>{{payment.folInvoice}}</td>
                </tr>
            </tbody>
        </table>

        <br>
        <br *ngIf="emitidas.length>=23"><br *ngIf="emitidas.length>=23"><br *ngIf="emitidas.length>=23">

        <br>
        <br>
        <br>
        <br>
        <br>

        <div class="firmas">
            <div class="firma">
                <span>
                    <p>{{user}}</p>
                </span>
                <span>
                    Elaborado por: 
                </span>
            </div>
            <div class="firma">

                <span>
                    Revisado por: 
                </span>
            </div>
            <div class="firma">

                <span>
                    Autorizado por: 
                </span>
            </div>
        </div>

    </div>
</div>