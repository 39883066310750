import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Concept } from '../models/Concept';

@Injectable({
  providedIn: 'root'
})
export class ConceptsService {

  constructor(private http: HttpClient) { }

  //Insertar un concepto nuevo al catalogo
  insertConcept(con: Concept): Observable<any> {
    return this.http.post(`${environment.URLServer}/concepts/insertConcept`, con);
  }

  //Consultar todos los conceptos activos del catalogo
  selectAllConcepts(): Observable<any> {
    return this.http.get(`${environment.URLServer}/concepts/getAllConcepts`);
  }

  //Consultar todos los conceptos del catalogo
  selectConcepts(id: any): Observable<any> {
    return this.http.get(`${environment.URLServer}/concepts/getConcepts/`+id);
  }

  //Consultar un concepto
  searchConcept(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/concepts/searchConcept/${id}`);
  }

  //Eliminar concepto por ID
  deleteConcept(id: any): Observable<any> {
    return this.http.put(`${environment.URLServer}/concepts/deleteConcept/${id}`,[]);
  }

  //Actualizar concepto por ID
  updateConcept(id: number,con: Concept): Observable<any> {
    return this.http.put(`${environment.URLServer}/concepts/updateConcept/${id}`, con);
  }
}
