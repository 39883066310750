<mat-tab-group mat-stretch-tabs>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">payment</mat-icon>
            <span>Método de pago</span>
        </ng-template>
        <app-payment-method></app-payment-method>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">supervisor_account</mat-icon>
            <span>Tipo de usuario</span>
        </ng-template>
        <app-type-user></app-type-user>
    </mat-tab>

</mat-tab-group>