<br>




<br>























<div class="container-fluid mt-3">
  <mat-card class="card-form">

    <mat-card-content class="justify-align-center">

      <mat-vertical-stepper [linear]="isLinear" #stepper labelPosition="bottom">
        <mat-step >

          <form class="row mt-3">

            <ng-template matStepLabel>Proyecto a modificar</ng-template>

            <h4>Selecciona un proyecto: </h4>
            <mat-form-field appearance="fill">
              <mat-label>Proyecto</mat-label>
              <mat-select #projectSelected [(value)]="selected_project" (selectionChange)="setProject();" >
                <mat-option *ngFor="let project of projects" [value]="project" required>
                  {{project.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>


            <div class="col-12 text-right mt-3">
              <button class="btn btn-m19"  [disabled]="selected_project.name==''"  matStepperNext>Siguiente</button>
            </div>

          </form>
        </mat-step>

        <mat-step>
          <form class="row mt-3">

            <ng-template matStepLabel>Proyecto seleccionado</ng-template>
            <mat-card class="example-card">
              <mat-card-header>
                <div mat-card-avatar class="example-header-image"></div>
                <mat-card-title>{{selected_project.name}}</mat-card-title>
                <mat-card-subtitle>{{selected_project.nomenclature}}</mat-card-subtitle>
              </mat-card-header>
              <img mat-card-image src="{{this.linkMap}}">
              <mat-card-content>
                <p>
                  Recuerda que el mapa tiene que estar en formato PNG y la resolucion tiene que ser la establecida para
                  los
                  mapas la cual es: 7200x10800 pixeles, proporcionada por AUTOCAD en momento de exportar el mapa en
                  formato PNG.
                </p>
              </mat-card-content>
              <mat-card-actions>

              </mat-card-actions>
            </mat-card>

            <div class="col-12 text-right mt-3">
              <button class="btn btn-m19" type="submit" matStepperNext>Siguiente</button>
            </div>

          </form>
        </mat-step>


        <mat-step>
          <form class="row mt-3">

            <ng-template matStepLabel>Edficion del mapa</ng-template>

            <div>

              <div>
                <h5>Seleccionar el nuevo mapa a subir: </h5>
                <br>
                <input type='file' accept=".png, .jpg" (change)="onUpload($event)" required>
              </div>

              <br>


              <div class="progress">
                <div class="progress-bar progress-bar-striped bg-success" role="progressbar"
                  [style.width]="(uploadPercent | async )+'%'">
                </div>
              </div> 

              <input type='hidden' #linkdelmapa [value]="urlImage | async">

            </div>


            <div class="col-12 text-right mt-3">
              <button class="btn btn-m19" (click)="insertMap();" [disabled]="!(urlImage | async)"  type="submit">Guardar</button>
            </div>


          </form>
        </mat-step>




      </mat-vertical-stepper>
    </mat-card-content>
  </mat-card>
</div>