<app-loader [loader]="loader" *ngIf="loading==1"></app-loader>
<div class="selectCont">
  <span class="selectCont__info">
    <p class="selectCont__info__welcome"><b style="color: var(--M19Primary);">Bienvenido</b> {{user}}!</p>
    <p class="selectCont__info__instruction">Selecciona un proyecto para iniciar: </p>
  </span>

  <mat-form-field appearance="outline">
    <mat-label>Proyecto</mat-label>
    <mat-select #projectSelected [(value)]="selected_project">
      <ng-container *ngFor="let project of projects">
        <mat-option
          *ngIf="project.idProject == sellerInfo.AL || project.idProject == sellerInfo.AT || project.idProject == sellerInfo.BS || project.idProject == sellerInfo.CL || project.idProject == sellerInfo.LI || project.idProject == sellerInfo.VL || project.idProject == sellerInfo.VP || project.idProject == sellerInfo.XL || project.idProject == sellerInfo.XR || project.idProject == sellerInfo.OT || project.idProject == sellerInfo.OH"
          [value]="project">
          <div class="optionCont">
            <div class="image">
              <img [src]="project.UrlLogo" alt="Logo de Proyecto">
            </div>
            <p class="name">{{project.name}}</p>
          </div>
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <button class="m19Btn" [mat-dialog-close]="true" (click)="setProject();"
    [disabled]="selected_project.name==''">Continuar</button>
</div>