import { Component, OnInit } from '@angular/core';

import { LeadsService } from 'src/app/services/leads.service';

import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';

import { GenerateCommentComponent } from '../../generate-comment/generate-comment.component';

import { MatDialog } from '@angular/material/dialog';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';
import { LeadTimeLineComponent } from '../../lead-time-line/lead-time-line.component';

@Component({
  selector: 'app-leads-assigned',
  templateUrl: './leads-assigned.component.html',
  styleUrls: ['./leads-assigned.component.scss']
})
export class LeadsAssignedComponent implements OnInit {

  bannerTitle: BannerTitle = {
    title: "Visualizador de leads",
    icon: "manage_search",
    position: "center",
    background: "var(--M19Secundary)"
  }

  leadSelection: any = {
    idlead: null,
    prospect: null,
    interest: null,
    mobile: null,
    email: null,
    notes: null
  }

  leads: any = [];

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  constructor(
    private leadService: LeadsService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getLeads();
  }

  getLeads() {
    this.leadService.leadAssigned(this.getUser()).subscribe(
      res => {
        this.leads = res;
        console.log(this.leads);
      }
    );
  }

  leadSelected(id: number, pro: string, inte: string, mob: string, ema: string, not: string) {
    this.leadSelection = {
      idlead: null,
      prospect: null,
      interest: null,
      mobile: null,
      email: null,
      notes: null
    }

    this.leadSelection = {
      idlead: id,
      prospect: pro,
      interest: inte,
      mobile: mob,
      email: ema,
      notes: not
    }
  }

  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.fkuser;
  }

  addComment(id: number) {
    let comment = this.dialog.open(GenerateCommentComponent, {
      data: id,
      width: '100%'
    });
  }

  seeTimeline(id: number) {
    this.dialog.open(LeadTimeLineComponent, {
      data: id,
      width: "100%"
    });
  }
}