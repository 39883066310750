import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maps-edit',
  templateUrl: './maps-edit.component.html',
  styleUrls: ['./maps-edit.component.scss']
})
export class MapsEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
