<mat-dialog-content class="matFath formsComponent">
  <mat-card>
    <mat-card-title class="tittleCard">Estado de expediente</mat-card-title>
    <mat-card-content class="contentCard">
      <div class="divRow" style="gap: 10px;">
        <div class="divColumn subtitleText infoClient" style="flex-grow: 0.3;">
          <span style="font-size:1.6rem;"><b>Información del cliente</b></span>
          <span>{{customer.fk_customer}}<br><i style="font-size:1rem;">(Cliente)</i> </span>
          <span>{{customer.fk_property}}<br><i style="font-size:1rem;">(Inmueble)</i></span>
          <span>{{(customer.status=="RECESSIONS"?'Rescindido':'Bloqueado')}}<br><i
              style="font-size:1rem;">(Estado)</i></span>
          <ng-container *ngIf="customer.status=='RECESSIONS'; else NoRes">
            <!--Muestra esto si se cumple la condición-->
            <span>{{customer.recissionDescription}}<br><i style="font-size:1rem;">(Motivo de recision)</i></span>
          </ng-container>
          <ng-template #NoRes>
            <!--O muestra esto si NO se cumple la condición-->
            <span>{{customer.blockDescription}}<br><i style="font-size:1rem;">(Motivo de bloqueo)</i></span>
          </ng-template>
        </div>
        <div class="divColumn"
          style="flex-grow:0.7; gap:0.7rem; justify-content: center;align-items: center; font-weight: bolder;">
          <div class="divRowComponent" style="font-size: 1.3rem;">
            <span style="width: 35%;">Requisito</span>
            <span style="width: 16%;">Estado</span>
            <span style="width: 49%;">Accion</span>
          </div>
          <!--======================================================
          EXPEDIENTE INICIAL========================================
          ==========================================================-->
          <div class="divRowComponent">
            <span style="width:35%;">Expediente inicial</span>
            <div style="font-size: 2rem;width:16%;">
              <mat-icon [style.color]='statusValidator[0].color' data-title="{{statusValidator[0].dataTitle}}"
                style="cursor: help;">
                {{statusValidator[0].status}}</mat-icon>
            </div>
            <div *ngIf="true" style="width:49%">
              <mat-form-field appearance="outline">
                <mat-label>Sin expediente</mat-label>
                <mat-select multiple (valueChange)="expInitial($event)" [(value)]="customer.statusExp">
                  <mat-option *ngFor="let exp of subExpInit;" [value]="exp.index">
                    {{exp.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <!--======================================================
          SOLICITUD DE RECISION=====================================
          ==========================================================
          <div class="divRowComponent">
            <span style="width:35%;">Solicitud de recision</span>
            <div style="font-size: 2rem;width:16%;">
              <mat-icon [style.color]='statusValidator[1].color' data-title="{{statusValidator[1].dataTitle}}"
                style="cursor: help;">
                {{statusValidator[1].status}}</mat-icon>
            </div>
            <div style="width:49%;display: flex; gap:1rem;">
              <button mat-button data-title="Solicitud sin elaborar, haga click para generarla"
                style="background-color: #02bd54; cursor: pointer; color:white; flex-grow: 1;"
                (click)="genSolicitud(customer.idUser)">
                cancelar solicitud</button>
              <button mat-button data-title="Solicitud sin elaborar, haga click para generarla"
                style="background-color: #02bd54; cursor: pointer; color:white; flex-grow: 1;"
                (click)="genSolicitud(customer.idUser)">
                Convenio elaborado</button>
            </div>
          </div>-->
          <!--====================================================
          CONTRATOS ORIGINALES====================================
          ========================================================-->
          <div class="divRowComponent">
            <span style="width:35%;">Contratos originales </span>
            <div style="font-size: 2rem;width:16%;">
              <mat-icon [style.color]='statusValidator[3].color' data-title="{{statusValidator[3].dataTitle}}"
                style="cursor: help;">
                {{statusValidator[3].status}}</mat-icon>
            </div>
            <mat-form-field appearance="outline" style="width:49%;">
              <mat-label>Número de contratos</mat-label>
              <mat-select [(value)]="contractsDefault" (valueChange)="expContractsValidator($event)">
                <mat-option [value]="contract.numberOfContracts" *ngFor="let contract of contracts">{{
                  contract.contractsDescription }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!--====================================================
          RECIBOS=================================================
          ========================================================-->
          <div class="divRowComponent">
            <span style="width:35%;">Recibos </span>
            <div style="font-size: 2rem;width:16%;">
              <mat-icon [style.color]='statusValidator[4].color' data-title="{{statusValidator[4].dataTitle}}"
                style="cursor: help;">
                {{statusValidator[4].status}}</mat-icon>
            </div>
            <mat-form-field appearance="outline" style="width:49%">
              <mat-label>Recibos {{(NumTickets.value)==""?0:NumTickets.value}} / {{customer.tickets}}</mat-label>
              <input matInput #NumTickets type="number" min="0" max="{{customer.tickets}}"
                (keyup)="nTickets(NumTickets.value)" (onchange)="nTickets(NumTickets.value)"
                value="{{customer.expTickets}}">
              <mat-hint align="end"> </mat-hint>
            </mat-form-field>
          </div>
          <!--====================================================
          CONVENIO DE RECISION====================================
          ========================================================-->
          <div class="divRowComponent">
            <span style="width:35%;">Convenio de recisión</span>
            <div style="font-size: 2rem;width:16%;">
              <mat-icon [style.color]='statusValidator[2].color' data-title="{{statusValidator[2].dataTitle}}"
                style="cursor: help;">
                {{statusValidator[2].status}}</mat-icon>
            </div>
            <div style="width:49%;display: flex; gap:1rem;">
              <button mat-button *ngIf="statusValidator[2].status=='error'"
                data-title="convenio sin entregar a administración, haga click para marcarlo como entregado"
                style="background-color: #02bd54; cursor: pointer; color:white; flex-grow: 1;"
                (click)="recessionDocument(customer)">
                Entregar convenio</button>
              <button mat-button *ngIf="statusValidator[2].status=='verified'"
              data-title="Convenio ya entregado, haga click para cancelarlo"
                style="background-color: #D94555; cursor: pointer; color:white; flex-grow: 1;"
                (click)="cancelAgreement(customer)">
                Cancelar convenio</button>
            </div>
          </div>
          <!--====================================================
          BOTONES DE GUARDADO=====================================
          ========================================================-->
          <div class="divRowComponent">
            <button style="width: 40%;" mat-button (click)="saveButton()">Guardar cambios</button>
            <button style="width: 30%;"mat-button (click)="recessionComplete()">Finalizar recisión</button>
            <button style="width: 30%;"mat-button (click)="cancel()">Cancelar</button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
