import { Component, OnInit } from '@angular/core';
import { ControlRequestService } from 'src/app/services/control-request.service';

@Component({
  selector: 'app-control-request',
  templateUrl: './control-request.component.html',
  styleUrls: ['./control-request.component.scss']
})
export class ControlRequestComponent implements OnInit {

  allProjects: any = []
  

  constructor(public controlReqService: ControlRequestService) { }

  ngOnInit(): void {
    this.getConcepts()
  }

  getConcepts(){ 
    this.controlReqService.selectAllProjects().subscribe(
      res =>  {
        this.allProjects = res;
        console.log(this.allProjects)
       },
      err =>  { console.log("ERROR no trajo nada")}
    )
  }

}
