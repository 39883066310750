import { Component, OnInit } from '@angular/core';
import { Chart, ChartOptions } from 'chart.js';

import { ProjectService } from 'src/app/services/project.service';
import { RequisitionService } from 'src/app/services/requisition.service';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';
import { DeliveryinvoiceService } from 'src/app/services/deliveryinvoice.service';

@Component({
  selector: 'app-invoicereport',
  templateUrl: './invoicereport.component.html',
  styleUrls: ['./invoicereport.component.scss']
})
export class InvoicereportComponent implements OnInit {
  //Arreglos para almacenar datos traídos de la BD
  projects: any = [];
  modules: any = [];

  modulesdetail: any = [];
  productsdetail: any = [];
  totals: any;
  materials: any = [];

  //Arreglos para valores de configuración del gráfico
  valuesOfP: any = [];
  labelsOfP: any = [];
  colorsOfP: any = [];

  //Arreglo de los datos de pago por proyecto
  infoP: any = { idProject: '', name: '', TotalPagado: '' };

  //Arreglo de los datos de pago por modulo
  infoM: any = { idprojectdet: '', towernumber: 0, Total: '' };

  //Bandera para saber si se debe desplegar la gráfica o no
  projectDet: any = false;

  //Bandera para saber si se debe desplegar la gráfica o no
  moduleDet: any = false;
  

  constructor(
    //Declaración de servicios utilizados
    private projectService: ProjectService,
    private requisitionService: RequisitionService,
    private projectdetailService: ProjectdetailsService,
    private invoiceService: DeliveryinvoiceService
  ) { }

  ngOnInit(): void {
    this.getProjects();
    this.getTotalProjects();
  }

  async getTotalProjects() {
    this.invoiceService.getTotalProjects().subscribe(
      res => {
        this.totals = res;
        let values = [];
        let labels = [];
        let colors = [];
        for (let t of this.totals) {
          console.log(this.totals);
          values.push(t.Total);
          labels.push(t.name)
          colors.push(t.projectColor);
        }
        this.chartConfig("comparativeChart", "doughnut", values, labels, colors);
      }
    );
  }

  //Función para traer el catálogo de proyectos registrados en la BD
  async getProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      }
    );
  }

  //Función para traer los modulos de determinado proyecto
  async getModules(id: number) {
    this.projectdetailService.selectDetailsById(id).subscribe(
      res => {
        this.modules = res;
      }
    );
  }

  //Función para traer los totales de gastos por proyecto
  async getTotalProject(id: number) {
    this.requisitionService.getTotalInvoiceByProject(id).subscribe(
      res => {
        if (res === null) {
          this.infoP = { idproject: '', name: '', TotalPagado: '' };
          return;
        }
        this.infoP = res;
      }
    );
  }

  //Función para traer los totales de gasto por modulo
  async getTotalModule(id: number) {
    this.requisitionService.selectTotalInvoiceByModule(id).subscribe(
      res => {
        if (res === null) {
          this.infoM = { idprojectdet: '', towernumber: '', Total: '' };
          return;
        }
        this.infoM = res;
      }
    );
  }

  //Función de configuración del gráfico
  async chartConfig(canva: string, type: string, values: any, labels: any, colors: any) {
    //Referencia al objeto canva HTML al que se carga el gráfico
    const canvas = document.getElementById(canva) as HTMLCanvasElement;

    //Configuración de fuente de la gráfica
    Chart.defaults.global.defaultFontFamily = "Arial";
    Chart.defaults.global.defaultFontSize = 14;

    //Asignación de información
    const graphData = {
      labels: labels,
      datasets: [{
        data: values,
        backgroundColor: colors,
        hoverBackgroundColor: 'rgb(211, 62, 62)',
        borderColor: '#A8A8A8',
        borderWidth: 2
      }]
    };

    //Configuración básica de gráfico
    const chartOptions: ChartOptions = {
      responsive: true,
      legend: {
        display: false
      }
    };

    //Generación del gráfico
    const graph = new Chart(canvas, {
      type: type,
      data: graphData,
      options: chartOptions
    });
  }

  //Función para alternar entre desplegar o no la gráfica de facturación por módulo
  async desgloceDatos(id:number) {
    this.projectDet = !this.projectDet;
    this.invoiceService.getListModulebyProject(id).subscribe(
      res => {
        this.modulesdetail = res;
        console.log(this.modulesdetail);
        if (this.projectDet==true) {
          let values = [];
          let labels = [];
          for (let m of this.modulesdetail) {
            values.push(m.Total);
            labels.push("Módulo "+m.towernumber);
          }
          this.chartConfig("moduleChart", "horizontalBar", values, labels, "rgb(255, 147, 147, 0.5)");
        }
      }
    );
  }

   //Función para alternar entre desplegar o no la gráfica de facturación por productos
   async productsDatos(id:number) {
    this.moduleDet = !this.moduleDet;
    this.invoiceService.getListProducts(id).subscribe(
      res => {
        this.productsdetail = res;
        console.log(this.productsdetail);
        if (this.moduleDet==true) {
          let values = [];
          let labels = [];
          for (let m of this.productsdetail) {
            values.push(m.Total);
            labels.push(m.productname);
          }
          this.chartConfig("materialChart", "horizontalBar", values, labels, "rgb(255, 147, 147, 0.5)");
        }
      }
    );
  }

}
