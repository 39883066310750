import { Component, ElementRef, OnInit } from '@angular/core';

import { invoiceReportService } from 'src/app/services/invoice.service';
import { PaymentService } from 'src/app/services/payment.service';

import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';
import { PaymentHistoryComponent } from 'src/app/components/payments/payment-history/payment-history.component';

import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { DatePipe } from '@angular/common';

import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import decode from 'jwt-decode';
import { loader } from 'src/app/ui/loader/loader.model';

@Component({
  selector: 'app-unmade-invoice',
  templateUrl: './unmade-invoice.component.html',
  styleUrls: ['./unmade-invoice.component.scss']
})
export class UnmadeInvoiceComponent implements OnInit {

  bannerTitle: BannerTitle = {
    title: "Facturas Pendientes",
    icon: "pending_actions",
    position: "center",
    background: "var(--M19Secundary)"
  }

  invList: any = [];
  invRevList: any = [];
  states: any = [];
  stateShown: string = "Pendientes";

  filterInvoices = '';

  loader: loader = {
    message: "Cargando información..."
  }

  saving: number = 0;

  pipe = new DatePipe('es-MX');
  showButton: Boolean = false;
  decode: any = [];

  constructor(
    private invoiceService: invoiceReportService,
    private paymentService: PaymentService,

    public sweet: SweetAlertComponent,
    public dialog: MatDialog,
    private el: ElementRef
  ) {
    this.saving = 1;

    this.invoiceService.getInvoiceList("1").subscribe(
      res => {
        this.invList = res;
        this.invoiceService.getInvoiceRevList().subscribe(
          res2 => {
            this.invRevList = res2;
            this.saving = 0;
          }
        );
      }
    );


    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
    this.decode = decode(localStorage.getItem("token") || "");
  }

  ngOnInit(): void {
  }

  async getInvoiceList(state: any) {
    this.saving = 1;
    this.invoiceService.getInvoiceList(state).subscribe(
      res => {
        this.invList = res;
        this.invoiceService.getInvoiceRevList().subscribe(
          res2 => {
            this.invRevList = res2;
            this.saving = 0;
          }
        );
      }
    );
  }

  async getHistory(idp: number) {
    this.dialog.open(PaymentHistoryComponent, {
      width: "100%",
      data: idp
    });
  }

  async setInvoiceMade(idp: number, state: number) {
    Swal.fire({
      title: '¿Estás seguro de realizar este movimiento?',
      text: "Se marcará este pago como facturado",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, adelante',
      cancelButtonText: 'No, espera',
      buttonsStyling: false,
      customClass: {
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn",
        actions: "sweetActions"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.saving = 1;
        this.invoiceService.setInvoiced(idp, state).subscribe(
          res => {
            const log = {
              "action": 2,
              "notes": null,
              "payment": idp,
              "responsable": this.decode.fkuser
            };

            this.paymentService.writePaymentLog(log).subscribe(
              res => {
                this.sweet.AlertTime("center", "success", "Pago marcado como facturado", false, 2000);
                this.getInvoiceList("1");
                this.saving = 0;
              }
            );
          }
        );
      }
    })
  }

  async setInvoiceUnmade(idp: number, state: number) {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'Escribe algunos detalles relacionados al motivo de esta acción',
      inputLabel: 'Motivo de la cancelación de este movimiento',
      html: "<b>¿Está seguro de querer deshacer el marcado como realizado?</b><br>" +
        "Este movimiento marcará como pendiente esta factura <br>" +
        "Usuario que solicita la cancelación: <b>" + this.decode.cliente + "</b><br>" +
        "Para continuar con la cancelación, escriba los detalles y presione 'Continuar'",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      customClass: {
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn",
        actions: "sweetActions"
      },
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === '') {
            resolve('Escribe los detalles para continuar')
          } else {
            resolve('');
          }
        })
      },
      showCancelButton: true
    })
    if (text) {
      this.saving = 1;
      this.invoiceService.setInvoiced(idp, state).subscribe(
        res => {
          const log = {
            "action": 1,
            "notes": text,
            "payment": idp,
            "responsable": this.decode.fkuser
          };

          this.paymentService.writePaymentLog(log).subscribe(
            res => {
              this.sweet.AlertTime("center", "success", "Pago desmarcado como facturado", false, 2000);
              this.getInvoiceList("2");
              this.saving = 0;
            }
          );
        }
      );
    } else {
      this.sweet.AlertTime("center", "error", "Por favor capture un motivo para realizar esta acción", false, 2000);
    }
  }

  async setInvoiceCancel(idp: number, state: number) {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'Escribe algunos detalles relacionados al motivo de esta acción',
      inputLabel: 'Motivo de la anulación de esta factura',
      html: "<b>¿Está seguro de querer anular esta factura?</b><br>" +
        "Este movimiento marcará como anulada esta factura <br>" +
        "Usuario que solicita anular la factura: <b>" + this.decode.cliente + "</b><br>" +
        "Para continuar con este movimiento, escriba los detalles y presione 'Continuar'",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      customClass: {
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn",
        actions: "sweetActions"
      },
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === '') {
            resolve('Escribe los detalles para continuar')
          } else {
            resolve('');
          }
        })
      },
      showCancelButton: true
    })
    if (text) {
      this.saving = 1;
      this.invoiceService.setInvoiced(idp, state).subscribe(
        res => {
          const log = {
            "action": 3,
            "notes": text,
            "payment": idp,
            "responsable": this.decode.fkuser
          };

          this.paymentService.writePaymentLog(log).subscribe(
            res => {
              this.sweet.AlertTime("center", "success", "Factura anulada exitosamente.", false, 2000);
              this.getInvoiceList("2");
              this.saving = 0;
            }
          );
        }
      );
    } else {
      this.sweet.AlertTime("center", "error", "Por favor capture un motivo para realizar esta acción", false, 2000);
    }
  }

  scrollToTop() {
    const container = document.querySelector('.invContainer');
    container!.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  onScroll() {
    const element = this.el.nativeElement.querySelector('.invContainer');
    if (element.scrollTop > 100) {
      this.showButton = true;
    } else {
      this.showButton = false;
    }
  }
}