<div class="container-fluid mt-3">

        <mat-card-content class="justify-align-center">
            <form class="row mt-3" [formGroup]="customerFormGroup" (ngSubmit)="createCustomer()">

                <h5 class="col-12 pb-2">Información general</h5>

                <mat-form-field appearance="outline" class="col-md-4">
                    <mat-label>Nombre(s)</mat-label>
                    <input matInput type="text" formControlName="name" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-4">
                    <mat-label>Apellido paterno</mat-label>
                    <input matInput type="text" formControlName="firstLastName" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-4">
                    <mat-label>Apellido materno</mat-label>
                    <input matInput type="text" formControlName="secondLastName" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-2">
                    <mat-label>Teléfono fijo</mat-label>
                    <input matInput type="tel" formControlName="telephone">
                    <mat-icon matSuffix>call</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-2">
                    <mat-label>Celular</mat-label>
                    <input matInput type="tel" formControlName="mobile" required>
                    <mat-icon matSuffix>phone_iphone</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-4">
                    <mat-label>Correo electrónico</mat-label>
                    <input matInput type="email" formControlName="email" required>
                    <mat-icon matSuffix>email</mat-icon>
                </mat-form-field>

                <h5 class="col-12 pb-2">Información personal</h5>

                <mat-form-field appearance="outline" class="col-md-3">
                    <mat-label>Fecha de nacimiento</mat-label>
                    <input matInput type="date" formControlName="birthDate" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-3">
                    <mat-label>Lugar de nacimiento</mat-label>
                    <input matInput type="text" formControlName="birthPlace" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-3">
                    <mat-label>Nacionalidad</mat-label>
                    <input matInput type="text" formControlName="nationality" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-3">
                    <mat-label>CURP</mat-label>
                    <input matInput type="text" formControlName="CURP" #CURP maxlength="18" minlength="18" required>
                    <mat-hint align="end">{{CURP.value.length}} / 18</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-3">
                    <mat-label>Clave de elector</mat-label>
                    <input matInput type="text" formControlName="voterKey" #voterKey maxlength="18" minlength="18" required>
                    <mat-hint align="end">{{voterKey.value.length}} / 18</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-3">
                    <mat-label>Ocupación</mat-label>
                    <input matInput type="text" formControlName="occupation" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-3">
                    <mat-label>Estado civil</mat-label>
                    <mat-select formControlName="civilStatus" required>
                        <mat-option *ngFor="let status of civil_status" [value]="status.description">
                            {{status.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <h5 class="col-12 pb-2">Domicilio</h5>

                <mat-form-field appearance="outline" class="col-md-4">
                    <mat-label>Dirección</mat-label>
                    <input matInput type="text" formControlName="address" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-4">
                    <mat-label>Colonia o localidad</mat-label>
                    <input matInput type="text" formControlName="localidad" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-4">
                    <mat-label>Municipio</mat-label>
                    <input matInput type="text" formControlName="municipality" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-4">
                    <mat-label>Estado</mat-label>
                    <input matInput type="text" formControlName="state" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-2">
                    <mat-label>Código postal</mat-label>
                    <input matInput type="number" formControlName="postalCode" required>
                </mat-form-field>

                <h5 class="col-12 pb-2">Información de facturación</h5>

                <mat-form-field appearance="outline" class="col-md-4">
                    <mat-label>RFC</mat-label>
                    <input matInput type="text" formControlName="RFC" #RFC maxlength="13" minlength="13">
                    <mat-hint align="end">{{RFC.value.length}} / 13</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-3">
                    <mat-label>¿Requiere facturación?</mat-label>
                    <mat-select formControlName="billing" required>
                        <mat-option [value]="1">Si</mat-option>
                        <mat-option [value]="0">No</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="col-12 text-right mt-3">
                    <button [mat-dialog-close]="true" class="btn btn-m19" type="submit" [disabled]="!customerFormGroup.valid">
                        <mat-icon>save</mat-icon>
                    </button>
                </div>

            </form>
        </mat-card-content>
</div>