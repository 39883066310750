import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
}) 
export class AuthService {
  //Login en clever 04/07/2023 debe ser https
  private URL = "https://auth.stardevs.com.mx/"

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService) { }

  singin(user: any) {
    return this.http.post(`${this.URL}login/singin`, user);
  }

  isAuth(): boolean {
    const token = localStorage.getItem('token') || undefined;
    if (this.jwtHelper.isTokenExpired(token) || !localStorage.getItem('token')) {
      return false;
    }
    return true;
  }

  logOut() {
    localStorage.removeItem('token');
  }
}