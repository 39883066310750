import { Component, HostListener, ViewChild } from '@angular/core';
import { MatSidenav, MatDrawerMode } from '@angular/material/sidenav';
import firebase from 'firebase';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'M19 Inmobiliaria';

  @ViewChild('sidenav') sideNav!: MatSidenav;
  opened = false;
  mode: MatDrawerMode = "side";
  smallScreen: boolean = false;
  sizeBoolean: boolean = false;
  closeOnClickOutside: boolean = false;

  currentZoomFactor: number = 1;

  ngOnInit() {
    this.configureSideNav();
    firebase.initializeApp(environment.firebaseConfig, "general");
    firebase.initializeApp(environment.firebaseCBooklog, "clogbook");
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    const zoomFactor = window.innerWidth / window.outerWidth;
    if (zoomFactor !== this.currentZoomFactor) {
      this.currentZoomFactor = zoomFactor;
      this.configureSideNav();
    }
  }

  configureSideNav() {
    this.smallScreen = window.innerWidth < 1080;
    this.closeOnClickOutside = this.smallScreen;
    this.opened = !this.smallScreen;
    this.sizeBoolean = this.smallScreen;
    this.mode = this.smallScreen ? 'over' : 'side';
  }

  onMenu(event : boolean) {
    this.sideNav.toggle(event);
  }

  closeMenu() {
    if (this.smallScreen) {
      this.opened = false;
    }
  }
}