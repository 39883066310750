<div class="container-fluid mt-3">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">

            <div class="contenedor">
                <div class="projectButtons">
                    <button *ngFor="let p of projects" class="m19AltBtn" (click)="filterByProject(p.nomenclature,p.idProject)">
                        {{p.name}}
                        <mat-icon>apartment</mat-icon>
                    </button>
                </div>

                <div *ngIf="customers.length==0 || customers=='Sin seleccionar'"
                    style="display: flex; flex-direction: column; height: 40vh; justify-content: center; align-items: center;">
                    <h2 *ngIf="customers == 'Sin seleccionar'">Seleccione un
                        proyecto para visualizar sus rescisiones en proceso</h2>
                    <p *ngIf="customers == 'Sin seleccionar'">
                        <mat-icon style="font-size: 2rem;">search</mat-icon>
                    </p>
                    <h2 *ngIf="customers.length==0">Este proyecto no tiene
                        rescisiones en proceso</h2>
                    <p *ngIf="customers.length==0">
                        <mat-icon style="font-size: 2rem;">sentiment_very_satisfied</mat-icon>
                    </p>
                </div>

                <div *ngIf="customers != 'Sin seleccionar' && customers.length>0"
                    style="color: #5E4F4F; font-size: 1rem; display: flex; justify-content: space-between; font-weight: bold;">
                    <p>{{customers[0].name.toUpperCase()}} <mat-icon style="color:#D33E3E;">apartment</mat-icon>
                    </p>
                    <p style="text-align: right;">UNIDADES BLOQUEADAS: {{customers.length}} <mat-icon
                            style="color:#D33E3E;">sell</mat-icon>
                    </p>
                </div>

                <table style="width: 100%;" class="tableHeader"
                    *ngIf="customers != 'Sin seleccionar' && customers.length>0">
                    <tbody>
                        <tr>
                            <th style="width: 22%">Cliente</th>
                            <th style="width: 10%;">Inmueble</th>
                            <th style="width: 10%;">Vendido</th>
                            <th style="width: 10%;">Bloqueado</th>
                            <th style="width: 8%;">Motivo de bloqueo</th>
                            <th style="width: 8%;">Solicitud</th>
                            <th style="width: 8%;">Motivo de rescisión</th>
                            <th style="width: 8%;">Convenio Rescisorio</th>
                            <th style="width: 8%;">Expediente</th>
                            <th style="width: 8%;">Estatus</th>
                        </tr>
                    </tbody>
                </table>

                <table style="width: 100%;">
                    <tbody>
                        <!-- let customersGroup of customersGroups; -->
                        <tr *ngFor="let c of customers">
                            <td style="width: 22%">
                                {{c.fk_customer}}
                            </td>

                            <td style="width: 10%;">
                                {{c.fk_property}}
                            </td>

                            <td style="width: 10%;">
                                {{pipe.transform(c.fechaDato,'mediumDate')}}
                            </td>

                            <td style="width: 10%;">
                                {{c.blockDate == null? "Sin registro":pipe.transform(c.blockDate,'mediumDate')}}
                            </td>

                            <!--///////////////////////////////////////////  MOTIVO DE BLOQUEO    //////////////////////////////////////////////////-->
                            <td style="width: 8%;" (click)="editBlockReason(c)">
                                <mat-icon data-title="SIN REGISTRO" *ngIf="c.fk_blockReason == null"
                                    style="color: #666666; cursor: help;">help</mat-icon>
                                <mat-icon data-title="VOLUNTARIO" *ngIf="c.fk_blockReason == 1"
                                    style="color: #02bd54; cursor: help;">settings_accessibility</mat-icon>
                                <mat-icon data-title="FALTA DE PAGO" *ngIf="c.fk_blockReason == 2"
                                    style="color: #D94555; cursor: help;">money_off</mat-icon>
                                <mat-icon data-title="REUBICACION" *ngIf="c.fk_blockReason == 5"
                                    style="color: #F8BA43; cursor: help;">edit_location</mat-icon>
                                <mat-icon data-title="DIRECCION" *ngIf="c.fk_blockReason == 6"
                                    style="color: #0263bd; cursor: help;">work</mat-icon>
                                <mat-icon data-title="STATUS EN BASE DE DATOS, PERO NO EN BACK-END" *ngIf="c.fk_blockReason > 6"
                                    style="color: #a00000; cursor: help;">error</mat-icon>
                            </td>

                            <!--//////////////////////////////////////////// SOLICITUD //////////////////////////////////////////////////-->

                            <td style="width: 8%;">
                                <mat-icon data-title="Solicitud sin elaborar" *ngIf="c.recessionRequestDate === null"
                                    style="color: #D94555; cursor: help;" (click)="genSolicitud(c.idUser)">
                                    do_not_disturb_on</mat-icon>
                                <mat-icon
                                    data-title="Solicitud elaborada el dia {{pipe.transform(c.recessionRequestDate,'mediumDate')}}"
                                    *ngIf="c.recessionRequestDate !== null" style="color: #02bd54; cursor: help;">
                                    verified</mat-icon>
                            </td>

                            <!--//////////////////////////////////// MOTIVO DE RESCISIÓN ////////////////////////////////////////////-->

                            <td style="width: 8%;" (click)="displayRecessionReason(c)">
                                <mat-icon data-title="SIN REGISTRO" *ngIf="c.fk_recessionReason == null"
                                    style="color: #666666; cursor: help;">help</mat-icon>
                                <mat-icon data-title="VOLUNTARIO" *ngIf="c.fk_recessionReason == 1"
                                    style="color: #02bd54; cursor: help;">settings_accessibility</mat-icon>
                                <mat-icon data-title="FALTA DE PAGO" *ngIf="c.fk_recessionReason == 2"
                                    style="color: #D94555; cursor: help;">money_off</mat-icon>
                                <mat-icon data-title="REUBICACION" *ngIf="c.fk_recessionReason == 5"
                                    style="color: #F8BA43; cursor: help;">edit_location</mat-icon>
                                <mat-icon data-title="DIRECCION" *ngIf="c.fk_recessionReason == 6"
                                    style="color: #0263bd; cursor: help;">work</mat-icon>
                            </td>

                            <!--//////////////////////////////////////// CONVENIO RESCISORIO ////////////////////////////////////////////////-->

                            <td style="width: 8%;" (click)="recessionDocument(c)">
                                <mat-icon *ngIf="c.recessionCreateDate == null " data-title="Convenio sin elaborar"
                                    style="color: #D94555; cursor: help;">
                                    do_not_disturb_on
                                </mat-icon>

                                <mat-icon *ngIf="c.recessionCreateDate != null "
                                    data-title="Convenio elaborado el dia {{c.recessionCreateDate}}"
                                    style="color: #02bd54; cursor: help;">
                                    verified
                                </mat-icon>
                            </td>

                            <!--///////////////////////////////////////// EXPEDIENTE //////////////////////////////////////////-->
                            <td style="width: 8%;" (click)="launchRecissionFileDialog(c)">
                                <mat-icon *ngIf="c.customerFileStatus == 'NaN'"
                                    data-title="No se ha recolectado el expediente"
                                    style="color: #D94555; cursor: help;">
                                    do_not_disturb_on
                                </mat-icon>

                                <mat-icon *ngIf="c.customerFileStatus == 'Enviado'"
                                    data-title="El expediente se ha enviado" style="color: #F8BA43; cursor: help;">
                                    hourglass_bottom
                                </mat-icon>

                                <mat-icon *ngIf="c.customerFileStatus == 'Recibido'"
                                    data-title="El expediente se ha recibido en juridico"
                                    style="color: #02bd54; cursor: help;">
                                    verified
                                </mat-icon>
                            </td>

                            <!--///////////////////////////////////////// ESTATUS //////////////////////////////////////////-->
                            <td style="width: 8%;">
                                <mat-icon
                                    *ngIf="c.status == 'RECESSIONS' && (c.recessionRequestDate == null || c.fk_recessionReason == null || c.recessionCreateDate == null || c.customerFileStatus == 'NaN' || c.customerFileStatus == 'Enviado'); else recession"
                                    data-title="Cliente rescindido pendiente" style="color: #F8BA43; cursor: help;">
                                    pending
                                </mat-icon>
                                <ng-template #recession>
                                    <mat-icon *ngIf="c.status == 'RECESSIONS'" data-title="Cliente rescindido"
                                        style="color: #D94555; cursor: help;">
                                        person_off
                                    </mat-icon>
                                </ng-template>
                                <mat-icon *ngIf="c.status == 'SALES'" data-title="Cliente activo"
                                    style="color: #02bd54; cursor: help;">
                                    person
                                </mat-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </mat-card-content>
    </mat-card>
</div>
