import { Component, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SaleService } from '../../services/sale.service';
import { PropertyService } from '../../services/property.service';
import { Property } from '../../models/Property';
import { CustomerService } from 'src/app/services/customer.service';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogSaleComponent } from './dialog-sale/dialog-sale.component';
import { DialogRecessionsComponent } from './dialog-recessions/dialog-recessions.component';
import { SweetAlertComponent } from '../../utils/sweet-alert/sweet-alert.component';
import { Router } from '@angular/router';
import { DialogSaleFormComponent } from './dialog-sale-form/dialog-sale-form.component';

import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.scss']
})
export class SaleComponent implements OnInit {
  sellBanner: BannerTitle = {
    title: "Registro de venta",
    icon: "sell",
    position: "center",
    background: "var(--M19Secundary)"
  }

  sellConsultBanner: BannerTitle = {
    title: "Consulta de venta",
    icon: "manage_search",
    position: "center",
    background: "var(--M19Secundary)"
  }

  today = new Date();

  filterProperty = '';
  filterCustomer = '';
  selected_customer = '';
  selected_coowner = '';
  selected_seller = '';
  selected_user = '';
  selected_project = '';
  selected: any = '';
  property: any = '';
  projects: any = [];
  properties: any = [];
  sales: any = [];
  sale: any = [];
  customer: any = [];
  customers: any = [];
  coowner: any = [];
  seller: any = [];
  sellers: any = [];
  user: any = [];
  users: any = [];
  recession: any = [];
  contractDate: any
  
  saleFormGroup: FormGroup = this._formBuilder.group({
    idSale: [],
    IdContract: [],
    fk_property: [, Validators.required],
    fk_customer: [, Validators.required],
    coowner: [],
    fk_seller: [],
    fk_user: [, Validators.required],
    contractDate: [(localStorage.getItem('contractDate'))],
    fechaDato: [Date, Validators.required]
  });

  constructor(
    private customerService: CustomerService,
    private userService: UserService,
    private saleService: SaleService,
    private propertyService: PropertyService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public sweet: SweetAlertComponent,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.selectCustomers();
    this.selectSellers();
    this.selectUsers();
    this.selectProjects();
    this.selectSales();
  }

  changes(): void {
    localStorage.removeItem('contractDate')
    this.contractDate = ((document.getElementById('fechaDato') as HTMLInputElement).value).replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3-$2-$1');
    localStorage.setItem('contractDate',this.contractDate)
  }

  //MOSTRAR TODOS LOS VENDEDORES
  selectSellers() {
    this.userService.selectUsers().subscribe(
      res => {
        this.sellers = res;
      },
      err => console.log(err)
    );
  }

  selectUsers() {
    this.userService.selectUsers().subscribe(
      res => {
        this.users = res;
      },
      err => console.log(err)
    );
  }

  //SELECCIONAR VENDEDOR EN ESPECIFICO
  selectSeller(id: number) {
    this.userService.selectUser(id).subscribe(
      res => {
        this.seller = res;
      },
      err => console.log(err)
    );
  }

  selectUser(id: number) {
    this.userService.selectUser(id).subscribe(
      res => {
        this.user = res;
      },
      err => console.log(err)
    );
  }

  //MOSTRAR TODOS LOS CLIENTES
  selectCustomers() {
    this.customerService.selectCustomers().subscribe(
      res => {
        this.customers = res;
      },
      err => console.log(err)
    );
  }

  //SELECCIONAR CLIENTE EN ESPECIFICO
  selectCustomer(id: number) {
    this.customerService.selectCustomer(id).subscribe(
      res => {
        this.customer = res;
      },
      err => console.log(err)
    );
  }

  //Seleccionar co-propietario
  selectCoowner(id: number) {
    this.customerService.selectCustomer(id).subscribe(
      res => {
        this.coowner = res;
      },
      err => console.log(err)
    );
  }

  //Consultar todos los proyectos
  selectProjects() {
    this.saleService.selectProjects().subscribe(
      res => {
        this.projects = res;
      },
      err => console.log(err)
    );
  }

  //Consultar los inmuebles del proyecto especificado
  selectPropertiesProject(codeProperty: string) {
    this.saleService.selectPropertiesProject(codeProperty).subscribe(
      res => {
        this.properties = res;
      },
      err => console.log(err)
    );
  }

  //CONSULTAR INMUEBLE EN ESPECIFICO
  selectProperty(codeProperty: string) {
    this.propertyService.selectProperty(codeProperty).subscribe(
      res => {
        this.property = res;
      },
      err => console.log(err)
    );
  }

  //MODIFICAR INMUEBLE
  updateProperty(codeProperty: string, update: Property) {
    this.propertyService.updateProperty(codeProperty, update).subscribe(
      res => {
        console.log(res);
      },
      err => console.log(err)
    );
  }

  //Consultar todas las ventas
  selectSales() {
    this.saleService.selectSales().subscribe(
      res => {
        this.sales = res;
      },
      err => console.log(err)
    );
  }

  //Consultar la venta especificada
  selectSale(codeProperty: string) {
    this.saleService.selectSale(codeProperty).subscribe(
      res => {
        this.sale = res;
        this.dialog.open(DialogSaleComponent, {
          width: '50%',
          data: res
        });
      },
      err => console.log(err)
    )
  }

  //Modificar estatus del inmueble al registrar venta
  updatePropertyStatus(codeProperty: string){
    this.propertyService.updatePropertyStatus(codeProperty, this.property).subscribe(
      res => {
        this.property.fk_status = 3,
        this.property.modificationDate = new Date();
      },
      err => console.error(err)
    )
  }

  //Registrar ventas
  createSale() {
    if (this.saleFormGroup.invalid) {
      return;
    }
    else {
      this.saleService.createSale(this.saleFormGroup.value).subscribe(
        res => {
          this.updatePropertyStatus(this.property.codeProperty);
          this.sweet.AlertTime("center", "success", "Venta registrada con éxito", false, 2000);
          localStorage.removeItem('contractDate');
          this.selectProjects();
          this.selectSales();
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['sales']));
        },
        err => console.error(err)
      );
      console.log(this.saleFormGroup.value);
    }
  }

  //Consultar la venta especificada
  updateSale(codeProperty: string) {
    this.saleService.selectSale(codeProperty).subscribe(
      res => {
        this.sale = res;
        console.log(this.sale)
        this.dialog.open(DialogSaleFormComponent, {
          width: '70%',
          data: res
        });
      },
      err => console.log(err)
    )
  }

  //Registrar rescisión de contrato
  rescindContract(codeProperty: string) {
    this.saleService.selectSale(codeProperty).subscribe(
      res => {
        this.recession = res;
        this.dialog.open(DialogRecessionsComponent, {
          width: '50%',
          data: res
        })
      },
      err => console.log(err)
    )
  }

}