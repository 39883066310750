<h1>{{data.idUser}}. {{data.name}} {{data.firstLastName}} {{data.secondLastName}} </h1>

<mat-divider></mat-divider>

<mat-dialog-content>
    <form class="row mt-3" [formGroup]="customerFormGroup">

        <h5 class="col-12 pb-2">Información general</h5>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Nombre(s)</mat-label>
            <input matInput type="text" formControlName="name" [(ngModel)]="customer.name" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Apellido paterno</mat-label>
            <input matInput type="text" formControlName="firstLastName" [(ngModel)]="customer.firstLastName" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Apellido materno</mat-label>
            <input matInput type="text" formControlName="secondLastName" [(ngModel)]="customer.secondLastName" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>Teléfono fijo</mat-label>
            <input matInput type="tel" formControlName="telephone" [(ngModel)]="customer.telephone">
            <mat-icon matSuffix>call</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>Celular</mat-label>
            <input matInput type="tel" formControlName="mobile" [(ngModel)]="customer.mobile" required>
            <mat-icon matSuffix>phone_iphone</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-5">
            <mat-label>Correo electrónico</mat-label>
            <input matInput type="email" formControlName="email" [(ngModel)]="customer.email" required>
            <mat-icon matSuffix>email</mat-icon>
        </mat-form-field>

        <h5 class="col-12 pb-2">Información personal</h5>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Fecha de nacimiento</mat-label>
            <input matInput type="date" formControlName="birthDate" [(ngModel)]="customer._birthDate" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Lugar de nacimiento</mat-label>
            <input matInput type="text" formControlName="birthPlace" [(ngModel)]="customer.birthPlace" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Nacionalidad</mat-label>
            <input matInput type="text" formControlName="nationality" [(ngModel)]="customer.nationality" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>CURP</mat-label>
            <input matInput type="text" formControlName="CURP" [(ngModel)]="customer.CURP" #CURP maxlength="18" minlength="18" required>
            <mat-hint align="end">{{CURP.value.length}} / 18</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Clave de elector</mat-label>
            <input matInput type="text" formControlName="voterKey" [(ngModel)]="customer.voterKey" #voterKey maxlength="18" minlength="18" required>
            <mat-hint align="end">{{voterKey.value.length}} / 18</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Ocupación</mat-label>
            <input matInput type="text" formControlName="occupation" [(ngModel)]="customer.occupation" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>Estado civil</mat-label>
            <mat-select formControlName="civilStatus" [(ngModel)]="customer.civilStatus"  required>
                <mat-option *ngFor="let status of civil_status" [value]="status.description">
                    {{status.description}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <h5 class="col-12 pb-2">Domicilio</h5>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Dirección</mat-label>
            <input matInput type="text" formControlName="address" [(ngModel)]="customer.address" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Colonia o localidad</mat-label>
            <input matInput type="text" formControlName="localidad" [(ngModel)]="customer.localidad" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Municipio</mat-label>
            <input matInput type="text" formControlName="municipality" [(ngModel)]="customer.municipality" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Estado</mat-label>
            <input matInput type="text" formControlName="state" [(ngModel)]="customer.state" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>Código postal</mat-label>
            <input matInput type="number" formControlName="postalCode" [(ngModel)]="customer.postalCode" required>
        </mat-form-field>

        <h5 class="col-12 pb-2">Información de facturación</h5>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>RFC</mat-label>
            <input matInput type="text" formControlName="RFC" [(ngModel)]="customer.RFC" #RFC maxlength="13" minlength="13">
            <mat-hint align="end">{{RFC.value.length}} / 13</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>¿Requiere facturación?</mat-label>
            <mat-select formControlName="billing" [(ngModel)]="customer.billing" required>
                <mat-option [value]="1">Si</mat-option>
                <mat-option [value]="0">No</mat-option>
            </mat-select>
        </mat-form-field>

    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
    <button mat-button class="btn btn-m19" (click)="updateCustomer(customer.idUser,customer)">Guardar</button>
</mat-dialog-actions>