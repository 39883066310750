<div class="container-fluid mt-3">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">

            <div style="display: flex; justify-content: space-between;">
                <button (click)="view=!view" class="buttonAlt">
                    <div *ngIf="view" style="display: flex; justify-content: center;">
                        <p>Cambiar a Tarjetas</p>
                        <mat-icon>drag_indicator</mat-icon>
                    </div>
                    <div *ngIf="!view" style="display: flex; justify-content: center;">
                        <p>Cambiar a Lista</p>
                        <mat-icon>toc</mat-icon>
                    </div>
                </button>
            </div>

            <div *ngIf="type">
                <h2 style="text-align: center;">Lista de presupuestos</h2>

                <table class="table table-striped animated fadeIn faster">
                    <thead>
                        <tr align="center">
                            <td>
                                <button class="btnOpc blue" (click)="getAll()">Todas</button>
                            </td>
                            <td>
                                <button class="btnOpc yellow" (click)="selectAwait()">En espera</button>
                            </td>
                            <td>
                                <button class="btnOpc green" (click)="selectApproved()">Aprobadas</button>
                            </td>
                            <td>
                                <button class="btnOpc red" (click)="selectReject()">Rechazadas</button>
                            </td>
                        </tr>
                    </thead>
                </table>

                <div *ngIf="!view" class="cuadriculaReq">
                    <div class="tarjeta" *ngFor="let budget of budgets">
                        <p style="text-align: center; font-size: 1rem;"><b>Número de presupuesto: {{budget.idbudget}}</b></p>
                        <p style="text-align: center; font-size: 0.9rem;">
                            Fecha: {{budget.budgetdate | date: 'dd-MMM-yyyy'}}
                        </p>
                        <div class="info">
                            <!-- Botón para visualizar el desgloce de presupuestos -->
                            <button class="btn btn-m19-info" title="Ver detalle" mat-icon-button
                                (click)="selectBudget(budget.idbudget)">
                                <mat-icon>info</mat-icon>
                            </button>
                        </div>
                        <div class="approve">
                            <mat-icon title="APROBADO" style="color:rgb(0, 155, 64)" *ngIf="budget.approved=='SI'">
                                verified</mat-icon>
                            <mat-icon title="RECHAZADO" style="color:rgb(185, 1, 1)" *ngIf="budget.approved=='NO'">
                                cancel</mat-icon>
                            <!-- Botón para aprobar el presupuesto -->
                            <button *ngIf="budget.approved=='NA'" class="approve" title="Aprobar"
                                mat-icon-button (click)="approveBudget(budget.idbudget)">
                                <mat-icon>thumb_up</mat-icon>
                            </button>
                            <!-- Botón para rechazar el presupuesto -->
                            <button *ngIf="budget.approved=='NA'" class="reject" title="Rechazar"
                                mat-icon-button (click)="rejectBudget(budget.idbudget)">
                                <mat-icon>thumb_down</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <div *ngIf="view">
                    <table class="table table-striped mt-3 animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr style="text-align:center;">
                                <!-- Se definen los encabezados de cada columna de la tabla -->
                                <th scope="col">Número de presupuesto</th>
                                <th scope="col">Fecha de requisición</th>
                                <th scope="col">Total</th>
                                <th scope="col">Analista</th>
                                <th scope="col">Responsable</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- ngFor para recorrer todas los presupuestos obtenidos -->
                            <!-- con esto podemos controlar la generación de componentes -->
                            <!-- en base a la cantidad de presupuestos -->
                            <tr *ngFor="let budget of budgets" style="text-align:center;">
                                <!-- Se define con qué datos se llenará cada columna de la tabla -->
                                <td>{{budget.idbudget}}</td>
                                <td>{{budget.budgetdate}}</td>
                                <td>$ {{budget.total}}</td>
                                <td>{{budget.analyst}}</td>
                                <td>{{budget.responsable}}</td>
                                <td class="button-row">
                                    <!-- Botón para visualizar el desgloce de presupuestos -->
                                    <button class="btn btn-m19-table" title="Ver detalle" mat-icon-button
                                        (click)="selectBudget(budget.idrequisition)">
                                        <mat-icon>info</mat-icon>
                                    </button>
                                    <mat-icon title="APROBADO" style="color:rgb(0, 155, 64)"
                                        *ngIf="budget.approved=='SI'">verified</mat-icon>
                                    <mat-icon title="RECHAZADO" style="color:rgb(185, 1, 1)"
                                        *ngIf="budget.approved=='NO'">cancel</mat-icon>
                                    <!-- Botón para aprobar el presupuesto -->
                                    <button *ngIf="budget.approved=='NA'" class="btn btn-m19-table" title="Aprobar"
                                        mat-icon-button (click)="approveBudget(budget.idrequisition)">
                                        <mat-icon>thumb_up</mat-icon>
                                    </button>
                                    <!-- Botón para rechazar el presupuesto -->
                                    <button *ngIf="budget.approved=='NA'" class="btn btn-m19-table" title="Rechazar"
                                        mat-icon-button (click)="rejectBudget(budget.idrequisition)">
                                        <mat-icon>thumb_down</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>