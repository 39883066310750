<mat-dialog-content>
    <div class="tableTitle">
        <h3>Bloqueo de cliente</h3>
        <mat-icon style="scale: 1.5;">lock</mat-icon>
    </div>

    <div class="container">
        <img [src]="projectLogo" alt="Icono de {{customerInfo.project}}">
        <div class="customerData">
            <p><b>Cliente:</b> {{customerInfo.fk_customer}}</p>
            <p><b>Unidad:</b> {{customerInfo.fk_property}}</p>
        </div>

        <div class="saleData">
            <p><b>Fecha de venta:</b> {{customerInfo.fechaDato | date: 'dd-MM-yyyy'}}</p>
            <p><b>Coordinador encargado:</b> {{customerInfo.fk_coord}}</p>
        </div>
    </div>

    <div class="payBalance"
        [ngStyle]="{ 'background-color' : ((data.retraso>-2 && data.retraso<0)? '#02bd54' : (data.retraso>-4 && data.retraso<=-2)? '#F8BA43' : (data.retraso<=-4)? '#D94555' : '#0259bd')}">
        <p>TIENE {{data.retraso>=0?data.retraso:data.retraso*-1}} PAGOS {{data.retraso>=0?"A FAVOR":"EN DEUDA"}}</p>
    </div>

    <div class="blockRow">
        <button class="btnOption volunteer" (click)="blockUser(customerInfo.fk_property, 1)">Voluntario<mat-icon>
                settings_accessibility</mat-icon></button>
        <button class="btnOption payoff" (click)="blockUser(customerInfo.fk_property, 2)">Falta de pago<mat-icon>
                money_off</mat-icon></button>
        <button class="btnOption relocate" (click)="blockUser(customerInfo.fk_property, 5)">Reubicación<mat-icon>
                edit_location</mat-icon></button>
        <button class="btnOption direction" (click)="blockUser(customerInfo.fk_property, 6)">Dirección<mat-icon>
                work</mat-icon></button>
    </div>
</mat-dialog-content>