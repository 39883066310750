<mat-sidenav-container class="content" fullscreen>

    <mat-sidenav #sidenav [(opened)]="opened" [mode]="mode" position="start">
        <app-sidebar-content (closeMenu)="closeMenu()"></app-sidebar-content>
    </mat-sidenav>

    <mat-sidenav-content>
        <app-home-header (onMenu)="onMenu($event)" [size]="sizeBoolean"></app-home-header>
        <div class="main-content">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>