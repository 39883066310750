<table class="table table-striped mt-3 animated fadeIn faster">
    <thead class="thead-m19">
        <tr>
            <th scope="col">Recibo</th>
            <th scope="col">Inmueble</th>
            <th scope="col">Cliente</th>
            <th scope="col">Fecha</th>
            <th scope="col">Monto</th>
            <th scope="col">Acciones</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let fact of grafic">
            <td>{{fact.folInvoice}}</td>
            <td>{{fact.fkProperty}}</td>
            <td>{{fact.UserName}}</td>
            <td>{{fact.registrationDate | date:'dd-MMM-yyyy'}}</td>
            <td>{{fact.amountInvoice | currency:'CAD' : '$ '}}</td>
            <td class="button-row">
                <button class="btn btn-m19-table" mat-icon-button title="Ver detalles" (click)="selectInvoice(fact.folInvoice,fact.fkProperty)">
                    <mat-icon>visibility</mat-icon>
                </button>
            </td>
        </tr>
    </tbody>
</table>