import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Sales } from 'src/app/models/Sales';

@Component({
  selector: 'app-dialog-recessions',
  templateUrl: './dialog-recessions.component.html',
  styleUrls: ['./dialog-recessions.component.scss']
})
export class DialogRecessionsComponent implements OnInit {

  recessionFormGroup: FormGroup = this._formBuilder.group({
    idRecession: [],
    contractDate: [],
    fechaDato:[],
    fk_property: [],
    customer: [],
    seller: [],
    Saldo: [],
    dateRecession: [ , Validators.required],
    reasonRecession: [ , Validators.required],
    notesRecession: []
  })

  constructor(
    private dialogRef: MatDialogRef<DialogRecessionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Sales,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  rescindContract(codeProperty: string) {
    
  }

}
