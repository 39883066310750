import { Component, OnInit } from '@angular/core';

import { LeadsService } from 'src/app/services/leads.service';
import { MatDialog } from '@angular/material/dialog';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';

import { loader } from 'src/app/ui/loader/loader.model';

import { LeadExtensionComponent } from '../../lead-extension/lead-extension.component';
import { LeadsReassignComponent } from '../../leads-reassign/leads-reassign.component';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';
import Swal from 'sweetalert2';

import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { DatePipe } from '@angular/common';

import { GenerateCommentComponent } from '../../generate-comment/generate-comment.component';
import { LeadTimeLineComponent } from '../../lead-time-line/lead-time-line.component';

@Component({
  selector: 'app-all-registered',
  templateUrl: './all-registered.component.html',
  styleUrls: ['./all-registered.component.scss']
})
export class AllRegisteredComponent implements OnInit {
  prospects: any = [];
  lead: any = [];

  loader: loader = {
    message: "Registrando prospecto..."
  }

  searching = 0;

  coordsList: any = [];

  pipe = new DatePipe('es-MX');
  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  constructor(
    private leadsService: LeadsService,
    public sweet: SweetAlertComponent,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.searching = 1;
    this.selectAllLeads();
    this. getCoordinators();
  }

  async details(idlead: number, status: String) {
    this.leadsService.getLeadInformation(idlead).subscribe(
      res => {
        this.lead = res;
        this.prepareModal(idlead, status);
      }
    );
  }

  async getCoordinators() {
    this.leadsService.getCoordinators().subscribe(
      res => {
        console.log(res);
        for (let c of res) {
          this.coordsList[c.idcoord] = c.coordName;
        }
      }
    );
  }

  addComment(id: number) {
    this.dialog.open(GenerateCommentComponent, {
      data: id,
      width: '100%'
    });
  }

  seeTimeline(id: number) {
    this.dialog.open(LeadTimeLineComponent, {
      data: id,
      width: "100%"
    });
  }

  async giveMoreTime(cas: any) {
    let dialog = this.dialog.open(LeadExtensionComponent, {
      width: "70%",
      data: cas
    });

    dialog.afterClosed().subscribe(
      res => {
        this.selectAllLeads();
      }
    );
  }

  async reassignLead(cas: any) {
    let reas = this.dialog.open(LeadsReassignComponent, {
      width: "70%",
      data: cas
    });

    reas.afterClosed().subscribe(
      res => {
        this.selectAllLeads();
      }
    );
  }

  async prepareModal(idlead: number, status: any) {
    if (status == null) {
      const { value: Assign } = await Swal.fire({
        title: 'Información de prospecto',
        width: '80%',
        html: "Prospecto  <b>" + this.lead.name + " " + (this.lead.firstLastName == null ? "" : this.lead.firstLastName) + " " + (this.lead.secondLastName == null ? "" : this.lead.secondLastName) + "</b> con interés en <b>" + this.lead.interest + ".</b><br><br>"
          + "Fue registrado por <b>" + this.lead.responsable + "</b> el día <b>" + this.lead.registrationdate + "</b> y dejó como información de contacto " + (this.lead.mobile == null ? "" : "el número de teléfono <b>" + this.lead.mobile + "</b>") + (this.lead.mobile != null ? this.lead.email != null ? " y " : "" : "") + (this.lead.email == null ? ".<br>" : "el correo <b>" + this.lead.email + ".</b><br>")
          + (this.lead.secondResponsable == null ? "" : "<br>Fue asignado a <b>" + this.lead.secondResponsable + "</b> el día <b>" + this.lead.assignationDate + ".</b>") + "<br><br>"
          + (this.lead.notes == null ? "" : "Se agregó el siguiente comentario: <br><b>" + this.lead.notes + ".</b>"),
        input: 'select',
        inputOptions: this.coordsList,
        inputPlaceholder: 'Seleccione un coordinador para asignarle este lead',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Guardar',
        buttonsStyling: false,
        customClass: {
          confirmButton: "m19AltBtn",
          cancelButton: "m19Btn",
          actions: "sweetActions"
        },
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value === '') {
              resolve('Debes elegir una opción de la lista o hacer clic en cancelar')
            } else {
              resolve('');
            }
          })
        }
      })

      if (Assign) {
        let lead = {
          "action": "Asignado",
          "responsable": this.getUser(),
          "secondResponsable": Assign,
          "fk_leads": idlead
        }

        this.leadsService.leadsLogWrite(lead).subscribe(
          res => {
            Swal.fire({
              icon: 'success',
              title: 'Guardado',
              html: "Se ha asignado este lead.",
              showConfirmButton: true,
              timer: 10000
            })
            this.selectAllLeads();
          }
        )
      }

    } else {
      const { value: Assign } = await Swal.fire({
        title: 'Información de prospecto',
        width: '80%',
        html: "Prospecto  <b>" + this.lead.name + " " + (this.lead.firstLastName == null ? "" : this.lead.firstLastName) + " " + (this.lead.secondLastName == null ? "" : this.lead.secondLastName) + "</b> con interés en <b>" + this.lead.interest + ".</b><br><br>"
          + "Fue registrado por <b>" + this.lead.responsable + "</b> el día <b>" + this.lead.registrationdate + "</b> y dejó como información de contacto " + (this.lead.mobile == null ? "" : "el número de teléfono <b>" + this.lead.mobile + "</b>") + (this.lead.mobile != null ? this.lead.email != null ? " y " : "" : "") + (this.lead.email == null ? ".<br>" : "el correo <b>" + this.lead.email + ".</b><br>")
          + (this.lead.secondResponsable == null ? "" : "<br>Fue asignado a <b>" + this.lead.secondResponsable + "</b> el día <b>" + this.lead.assignationDate + ".</b>") + "<br><br>"
          + (this.lead.notes == null ? "" : "Se agregó el siguiente comentario: <br><b>" + this.lead.notes + "</b>")
      })
    }
  }

  selectAllLeads(){
    this.leadsService.getAllLeads().subscribe(
      res => {
        this.prospects = res;
        this.searching = 0;
      }
    );
  }

  setLeadCall(id: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡Marcarás este lead como llamado!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, estoy seguro!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.leadsService.setLeadCall(id).subscribe(
          res => {
            let lead = {
              "action": "Llamada",
              "responsable": this.getUser(),
              "secondResponsable": null,
              "fk_leads": id
            }

            this.leadsService.leadsLogWrite(lead).subscribe(
              res => {
                Swal.fire({
                  icon: 'success',
                  title: 'Guardado',
                  html: "Se ha registrado la llamada a este prospecto.",
                  timer: 1000
                })

                this.selectAllLeads();
              }
            );
          }
        );
      }
    })
  }

  //==========================================================================================================
  //Recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.fkuser
  }

}
