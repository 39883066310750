import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';
import { RecessionsService } from 'src/app/services/recessions.service';//
import { MatDialog } from '@angular/material/dialog';
import { GenerateRecessionRequestComponent } from '../../administration/recessions-request/generate-recession-request/generate-recession-request.component';
import Swal from 'sweetalert2';
import { SaleService } from 'src/app/services/sale.service';
import { UserService } from 'src/app/services/user.service';

import { DatePipe } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { RecessionFileComponent } from '../recession-file/recession-file.component';

@Component({
  selector: 'app-recessions-status',
  templateUrl: './recessions-status.component.html',
  styleUrls: ['./recessions-status.component.scss']
})
export class RecessionsStatusComponent implements OnInit {

  //Variables
  projects: any = [];
  customers: any = "Sin seleccionar";
  pipe = new DatePipe('es-MX');
  project: String = '';
  idProject: number = 0;

  catalogoRecision: any; //Catalogo de recision

  constructor(
    private projectService: ProjectService,
    private recessionService: RecessionsService,
    public saleService: SaleService,
    public dialog: MatDialog,
    private userService: UserService
  ) {
    this.recessionService.getRecessionsType().subscribe(
      res => {
        this.catalogoRecision = res;
      }
    )
  }

  ngOnInit(): void {
    registerLocaleData(localeEsMx, 'es-MX'); //REGISTRAR LOCAL PARA ESPAÑOL
    this.getProjects();

    /*this.recessionService.getCustomersWithBlock().subscribe(
      res => {
        console.log("idUser es lo que recibe el dialog", res);
      }
    );*/
  }

  //Recupera la lista de proyectos de la tabla projects
  async getProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        console.log('Projects', res)
        this.projects = res;
      },
      err => console.log(err)
    );
  };

  async genSolicitud(data: number) {
    let info = {
      "user": 199,
      "project": 2
    };
    const dialogo = this.dialog.open(GenerateRecessionRequestComponent, {
      width: '100%',
      data: info
    });

    dialogo.afterClosed().subscribe(result => {
      this.ngOnInit();
      this.filterByProject(this.project, this.idProject);
    })
  }

  async editBlockReason(customer: any) {
    if (customer.recessionRequestDate === null) {
      console.log(customer);
      let listRecession: any = {}
      for (let cr of this.catalogoRecision) {
        console.log(cr);
        listRecession[cr.idtype_recession] = cr.description;
      }
      const { value: MBloqueo } = await Swal.fire({
        title: 'Motivo de bloqueo',
        html: "Inmueble: <b>" + customer.fk_property + "</b><br>" +
          "Cliente: <b>" + customer.fk_customer + "</b><br>" +
          "Estado de bloqueo actual: <b>" + customer.blockDescription + "</b><br>" +
          "Si desea cambiar el motivo, seleccione el nuevo motivo y haga click en guardar",
        input: 'select',
        inputOptions: listRecession,
        inputPlaceholder: 'Ingrese un motivo de recisión',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#dd6b55',
        confirmButtonText: 'Guardar',
        confirmButtonColor: '#4c9141',
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value === '') {
              resolve('Debes elegir una opción de la lista o hacer clic en cancelar')
            } else {
              console.log(value);
              resolve('');
            }
          })
        }
      })
      if (MBloqueo) {
        if (customer.status == "SALES") {
          this.saleService.changeSalesReasonBlock(MBloqueo, customer.id).subscribe(
            res => {
              Swal.fire({
                icon: 'success',
                title: 'Guardado',
                html: "Se ha establecido el nuevo motivo de bloqueo como:<br><h2>" + listRecession[MBloqueo] + "</h2>",
                showConfirmButton: true,
                timer: 10000
              })
              this.filterByProject(this.project, this.idProject);
            }
          )
        } else {
          this.recessionService.changRecessionsReasonBlock(MBloqueo, customer.id).subscribe(
            res => {
              Swal.fire({
                icon: 'success',
                title: 'Guardado',
                html: "Se ha establecido el nuevo motivo de bloqueo como:<br><h2>" + listRecession[MBloqueo] + "</h2>",
                showConfirmButton: true,
                timer: 10000
              })
              this.filterByProject(this.project, this.idProject);
            }
          )
        }
      }
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Atención',
        html: 'Ya existe una solicitud de recisión para ' + customer.fk_property + ': ' + customer.fk_customer + '. No es posible cambiar el motivo de bloqueo.<br><br>Motivo de bloqueo actual: <h4>' + customer.blockDescription + '</h4>',
        showConfirmButton: false,
        timer: 5000
      })
    }
  }

  async displayRecessionReason(customer: any) {
    Swal.fire({
      icon: 'info',
      title: 'Atención',
      html: 'Inmueble: ' + customer.fk_property + '<br>Cliente: ' + customer.fk_customer + '. <br><br>Motivo de recisión actual: <h4>' + customer.recissionDescription + '</h4>',
      showConfirmButton: false,
      timer: 5000
    })
  }

  async recessionDocument(customer: any) {
    Swal.fire({
      icon: 'warning',
      title: 'ADVERTENCIA',
      html: 'Inmueble: ' + customer.fk_property + '.<br>Cliente: ' + customer.fk_customer + '.<br>Fecha de elaboración de solicitud de recisión: ' + customer.recessionRequestDate + ' <br><br><h4>Usted está a punto de marcar como generado y entregado el convenio rescisorio, ¿Desea continuar?</h4><br> Se recomienda que solo personal autorizado por área jurídica modifique esta propiedad, el uso incorrecto de esta función puede causar inconsistencia en los datos',
      showDenyButton: true,
      denyButtonText: `No, no continuar`,
      confirmButtonText: 'Lo entiendo y deseo continuar',
      confirmButtonColor: '#4c9141',
    }).then((result) => {
      if (result.isConfirmed) {
        if (customer.status == "SALES") {
          this.saleService.setRecessionAgreement(customer.id).subscribe(
            res => {
              console.log('Realizado en sales', customer);
              this.ngOnInit();
              this.filterByProject(this.project, this.idProject);
            }
          )
        } else {
          this.recessionService.setRecessionAgreement(customer.id).subscribe(
            res => {
              console.log('Realizado en recessions', customer);
              this.ngOnInit();
              this.filterByProject(this.project, this.idProject);
            }
          )
        }
        Swal.fire('¡Guardado!', '', 'success')
      } else if (result.isDenied) {
        Swal.fire({
          icon: 'info',
          title: 'No se realizaron cambios',
          showConfirmButton: false,
          timer: 3000
        })
      }
    })
  }

  async launchRecissionFileDialog(customer: any) {
    this.saleService.salesPayN(customer.fk_property).subscribe(
      sales => {
        console.log('numero de recibos de tabla de sales', sales[0])
        this.recessionService.recessionPayN(customer.idUser).subscribe(
          recessions => {
            console.log('Número de recibos de la tabla de recisiones', recessions[0])
            if (customer.status == "RECESSIONS") {
              customer.tickets = recessions[0].tickets
            } else {
              customer.tickets = sales[0].tickets
            }
            console.log('customer de base de datos')
            customer.statusExp = [];
            this.userService.getUserFileStatus(customer.idUser).subscribe(
              res => {
                console.log(res);
                if (res[0].expBirth == 1) { customer.statusExp.push(0) }
                if (res[0].expCURP == 1) { customer.statusExp.push(1) }
                if (res[0].expAddress == 1) { customer.statusExp.push(2) }
                if (res[0].exptaxStatus == 1) { customer.statusExp.push(3) }
                console.log('Inicial de expediente inicial', customer.statusExp)

                const dialogo = this.dialog.open(RecessionFileComponent, {
                  width: '100%',
                  data: customer
                });

                dialogo.afterClosed().subscribe(result => {
                  this.ngOnInit();
                  this.filterByProject(this.project, this.idProject);
                })
              }
            )
          }
        )
      }
    )
  }



  async filterByProject(project: String, idProject: number) {
    this.recessionService.getBlockStatusUnfinished(project).subscribe(
      res => {
        this.customers = res;
        this.project = project;
        this.idProject = idProject;
        console.log('Esto es lo que genera este modulo: ', res)
      }
    );
  }
}
