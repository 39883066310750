<div class="container-fluid mt-3">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">
            <div>
                <app-banner-title [banner]="editSaleBanner"></app-banner-title>
                <br>
                <mat-form-field appearance="fill">

                    <mat-label>Proyecto</mat-label>

                    <mat-select #projectSelected [(value)]="selected_project" (selectionChange)="getAllPropertys();">
                        <mat-option *ngFor="let project of projects" [value]="project">
                            {{project.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="filter">
                    <mat-label>Buscar inmueble</mat-label>
                    <input matInput type="text" placeholder="Ingresar el código de inmueble" [(ngModel)]="filterProperty" name="filterProperty">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>


            </div>
            <table class="table table-striped mt-3 animated fadeIn faster">
                <thead class="thead-m19">
                    <tr>
                        <th scope="col">Codigo del inmueble</th>
                        <th scope="col">Estatus de proceso de venta</th>
                        <th scope="col">Estatus de inmueble</th>
                        <th scope="col">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let property of properties  | filterProperty: filterProperty">
                        <td> {{property.codeProperty}}</td>
                        <td>
                            <mat-select (selectionChange)="changeCatalogfun(property.codeProperty,$event)"
                                [(ngModel)]="property.idsales_catalog">
                                <mat-option *ngFor="let catalog of catalog" [value]="catalog.idsales_catalog">
                                    {{catalog.value}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td>
                            <mat-select (selectionChange)="changeStatusfun(property.codeProperty,$event)"
                                [(ngModel)]="property.fk_status">
                                <mat-option *ngFor="let status of status" [value]="status.idStatus">
                                    {{status.description}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td>
                            <button [disabled]="!changeProperty.includes(property.codeProperty)"
                                (click)="update(changeCatalog,changeStatus,property.codeProperty)"
                                class="btn btn-m19-table" mat-icon-button>
                                <mat-icon>check</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </mat-card-content>
    </mat-card>
</div>
