
    <ng-template mat-tab-label>
        <mat-icon class="tab-icon">add_circle</mat-icon>
        <span>Registro de una nueva solicitud</span>
    </ng-template>


<div class="container-fluid mt-3">
    <mat-card class="card-form">

        <mat-card-content class="justify-align-center">

            <mat-vertical-stepper [linear]="isLinear" #stepper labelPosition="bottom">
                <mat-step [stepControl]="personalInformationGroup">

                    <form class="row mt-3" [formGroup]="personalInformationGroup" (ngSubmit)="createProspect()">

                        <ng-template matStepLabel>Información personal</ng-template>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Nombre(s)</mat-label>
                            <input matInput type="text" formControlName="firstName" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Apellido paterno</mat-label>
                            <input matInput type="text" formControlName="FirstLastName" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Apellido materno</mat-label>
                            <input matInput type="text" formControlName="SecondLastName" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Nacionalidad</mat-label>
                            <input matInput type="text" formControlName="nationality" required>
                        </mat-form-field>

                    
                        <mat-form-field appearance="fill" class="example-form-field">
                            <mat-label>Fecha de nacimiento</mat-label>
                            <input matInput [matDatepicker]="datepicker" formControlName="birthDate">
                            <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                            <mat-datepicker #datepicker>
                              <mat-datepicker-actions>
                                <button mat-button matDatepickerCancel>Cancel</button>
                                <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                              </mat-datepicker-actions>
                            </mat-datepicker>
                          </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Lugar de nacimiento</mat-label>
                            <input matInput type="text" formControlName="birthPlace" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Estado civil</mat-label>
                            <input matInput type="text" formControlName="maritalStatus" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Ocupacion</mat-label>
                            <input matInput type="text" formControlName="occupation" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>CURP</mat-label>
                            <input matInput type="text" formControlName="CURP" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Clave de elector</mat-label>
                            <input matInput type="text" formControlName="electorKey" required>
                        </mat-form-field>


                        <div class="col-12 text-right mt-3">
                            <button class="btn btn-m19" type="submit" [disabled]="!personalInformationGroup.valid"
                                matStepperNext>Siguiente</button>
                        </div>

                    </form>
                </mat-step>

                <mat-step [stepControl]="residenceGroup">
                    <form class="row mt-3" [formGroup]="residenceGroup" (ngSubmit)="createProspect()">

                        <ng-template matStepLabel>Información de residencia</ng-template>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Direccion</mat-label>
                            <input matInput type="text" formControlName="address" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Colonia</mat-label>
                            <input matInput type="text" formControlName="location" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Codigo postal</mat-label>
                            <input matInput type="number" formControlName="CP" required>
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Estado</mat-label>
                            <input matInput type="text" formControlName="state" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Municipio</mat-label>
                            <input matInput type="text" formControlName="municipality" required>
                        </mat-form-field>

                        <div class="col-12 text-right mt-3">
                            <button class="btn btn-m19" type="submit"  [disabled]="!residenceGroup.valid">Siguiente</button>
                        </div>

                    </form>
                </mat-step>

                <mat-step [stepControl]="contactData">
                    <form class="row mt-3" [formGroup]="contactData" (ngSubmit)="createProspect()">

                        <ng-template matStepLabel>Información de contacto</ng-template>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Numero de celular</mat-label>
                            <input matInput type="number" formControlName="mobileNumber" required>
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Correo Electronico</mat-label>
                            <input matInput type="text" formControlName="email" required>
                        </mat-form-field>

                        <div class="col-12 text-right mt-3">
                            <button class="btn btn-m19" type="submit">siguiente</button>
                        </div>

                    </form>
                </mat-step>

                <mat-step [stepControl]="propertyData">
                    <form class="row mt-3" [formGroup]="propertyData" (ngSubmit)="createProspect()">

                        <ng-template matStepLabel>Informacion del inmueble</ng-template>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Proyecto</mat-label>
                            <input matInput type="number" formControlName="idProject" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Manzana</mat-label>
                            <input matInput type="number" formControlName="block" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Lote</mat-label>
                            <input matInput type="number" formControlName="batch" required>
                        </mat-form-field>
                        


                        <div class="col-12 text-right mt-3">
                            <button class="btn btn-m19" type="submit">Guardar</button>
                        </div>

                    </form>
                </mat-step>


            </mat-vertical-stepper>
        </mat-card-content>
    </mat-card>   
</div>
