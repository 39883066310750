<div class="quotationCont">
    <div class="quotationCont__controls">
        <mat-form-field appearance="outline">
            <mat-label>Proyecto</mat-label>
            <mat-select (valueChange)="selectProject($event)" [(ngModel)]="project" required>
                <mat-option value="3">Altares</mat-option>
                <mat-option value="13">Altares Departamentos</mat-option>
                <mat-option value="11">Bosco</mat-option>
                <mat-option value="4">Los Colomos</mat-option>
                <mat-option value="2">Xalta</mat-option>
                <mat-option value="15">Xalter</mat-option>
                <mat-option value="17">Oasis Residencial</mat-option>
            </mat-select>
            <mat-icon matSuffix>map</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Unidad</mat-label>
            <mat-select (valueChange)="selectUnity($event);" [(ngModel)]="unity" required>
                <mat-option *ngFor="let u of unities" [value]="u.idProperty">
                    {{u.codeProperty}}
                </mat-option>
            </mat-select>
            <mat-icon matSuffix>apartment</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Anticipo</mat-label>
            <input [(ngModel)]="inAnticipo" matInput type="number" min="1"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)" required>
            <mat-icon matSuffix>numbers</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Mensualidad</mat-label>
            <input [(ngModel)]="inMensual" matInput type="number" min="1"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)" required>
            <mat-icon matSuffix>numbers</mat-icon>
        </mat-form-field>
        
        <!-- <mat-form-field appearance="outline">
            <mat-label>Anualidad</mat-label>
            <input [(ngModel)]="inAnual" matInput type="number" min="1"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)" required>
            <mat-icon matSuffix>numbers</mat-icon>
        </mat-form-field> -->

        <mat-form-field appearance="outline">
            <mat-label>Cliente</mat-label>
            <input matInput type="text" [(ngModel)]="customer" required>
        </mat-form-field>

        <button class="m19AltBtn" (click)="generateQuotation()">
            Generar
            <mat-icon>add</mat-icon>
        </button>

        <button class="m19AltBtn" (click)="printQuotation()" *ngIf="generate">
            Imprimir
            <mat-icon>print</mat-icon>
        </button>
    </div>

    <br>

    <div class="quotationCont__doc" id="htmlData" *ngIf="generate">
        <span class="quotationCont__doc__header">
            <div class="logos">
                <div class="imgWrap">
                    <img src="../../../../assets/imagotipo.png" alt="Logo de M19 Inmobiliaria">
                </div>
                <div class="imgWrap">
                    <img [src]="logo" alt="Logo de Proyecto">
                </div>
            </div>
            <p style="text-align: center; font-size: 1.5rem; padding: 10px;">Corrida Financiera</p>
            <div class="details" style="padding: 10px;">
                <p><b style="color: #D33E3E;">Proyecto:</b> {{details.name}}</p>
                <p><b style="color: #D33E3E;">{{details.codeProperty.substring(2, 3) == 'M'? 'Manzana:':'Torre:'}}</b> {{details.codeProperty.substring(3, 5)}} <b
                    style="color: #D33E3E;">{{details.codeProperty.substring(5, 6) == 'L'? 'Lote:':'Dpto:'}}</b> {{details.codeProperty.substring(6, 8)}}</p>
                <p><b style="color: #D33E3E;">Costo:</b> {{details.amount | currency: '$'}}</p>
            </div>
            <div class="information" style="padding: 10px;">
                <p><b>Apreciable {{customer}}.</b></p>
                <p><b style="color: #D33E3E;">Plazo:</b> {{diffDate()}}</p>
            </div>
            <p style="text-align: center; border: solid 1px black;">La información contenida en esta cotización es solo de carácter informativo y no representa ningún acuerdo o relación contractual ante la entidad que la emite.</p>
            <table class="m19Table">
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>No. de Mensualidad</th>
                        <th>Importe</th>
                        <th>Acumulado</th>
                        <th>Saldo a Pagar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{startDate | date: 'dd-MM-YYYY'}}</td>
                        <td>Enganche</td>
                        <td>{{inAnticipo | currency: '$'}}</td>
                        <td>{{inAnticipo | currency: '$'}}</td>
                        <td>{{(details.amount - inAnticipo) | currency: '$'}}</td>
                    </tr>
                    <ng-container *ngFor="let i of iterations; index as n;">
                        <tr [ngStyle]="{'background-color':(n+1)%12==0? details.fk_financialType==1?'#d2ffd2':'':'', 'color':(n+1)%12==0? details.fk_financialType==1?'green':'':'', 'opacity':(n+1)%12==0? details.fk_financialType==1?'0.8':'':'','background':(n+1)%12==0? details.fk_financialType==1?'repeating-linear-gradient(-45deg, rgb(105 255 105), rgb(98 255 98) 2px, rgb(210, 255, 210) 1px, rgb(210, 255, 210) 10px)':'':''}">
                            <td>{{i.inDate | date: 'dd-MM-YYYY'}}</td>
                            <td>Mensualidad {{n+1}}</td>
                            <td>{{i.pay | currency: '$'}}</td>
                            <td>{{i.paid | currency: '$'}}</td>
                            <td>{{i.debt | currency: '$'}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </span>
    </div>
</div>