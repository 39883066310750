<mat-card>
    <mat-card-content>
        <form class="row mt-3" [formGroup]="materialFormGroup" (ngSubmit)="updateMaterial()" onKeyPress="if(event.keyCode == 13) event.returnValue = false;" style="display: flex; flex-direction: column;">
            <h5 class="col-12 pb-2">Modificar Material</h5>

            <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Descripción:</mat-label>
                <input matInput type="text" formControlName="productname" min="1" onkeypress="return (event.charCode >= 48 && event.charCode <= 57)" required>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Unidad de medida:</mat-label>
                <input matInput type="text" formControlName="unit" required>
            </mat-form-field>

            <div class="col-12 text-right mt-3">
                <button class="btn btn-m19" type="submit" [disabled]="!materialFormGroup.valid">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>