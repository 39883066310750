<div class="container-fluid mt-3">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">
            <div *ngIf="type">
                <div style="overflow:scroll;">
                    <div class="projectButtons">
                        <button *ngFor="let p of projects" class="m19AltBtn" (click)="getModulesByProject(p.idProject, p.name)">
                            {{p.name}}
                            <mat-icon>apartment</mat-icon>
                        </button>
                    </div>
                    <div style="background-color:#d33e3e; text-align: center; font-size: 1rem; text-shadow: 1rem; color:antiquewhite; display: flex; justify-content: space-between; padding: 15px; border-radius: 5px;">
                        <p style="margin-bottom: 0px;"><b>Proyecto  <mat-icon>apartment</mat-icon> :</b> {{project}} </p> 
                        <p style="margin-bottom: 0px;"><b>Viendo <mat-icon>visibility</mat-icon> :</b> {{watching}}</p></div>
                    <table class="table table-striped animated fadeIn faster">
                        <thead>
                            <tr align="center">
                                <td>
                                    <button class="btnOpc blue" (click)="getAll()">Todos</button>
                                </td>
                                <td>
                                    <button class="btnOpc yellow" (click)="selectAwait()">En espera</button>
                                </td>
                                <td>
                                    <button class="btnOpc green" (click)="selectApproved()">Aprobados</button>
                                </td>
                                <td>
                                    <button class="btnOpc red" (click)="selectReject()">Rechazados</button>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>

                <div *ngIf="!view" class="cuadriculaReq">
                    <div class="tarjeta" *ngFor="let piecework of pieceworks">
                        <p style="text-align: center; font-size: 1rem;"><b>{{piecework.idProject}} Modulo:
                                {{piecework.towernumber}}</b></p>
                        <p style="text-align: center; font-size: 0.9rem;"><b>Destajo: No.</b>
                            {{piecework.idPiecework}} 
                           <br>
                            <b>Periodo:</b>
                            <br>
                            {{piecework.initweek | date:'dd-MMM-yyyy'}} - {{piecework.endweek | date:'dd-MMM-yyyy'}}</p>
                             
                        <div class="info">
                            <!-- Botón para visualizar el desgloce de partidas de una requisición -->
                            <button class="btn btn-m19-info" title="Ver detalle" mat-icon-button
                                (click)="selectPiecework(piecework.idPiecework)">
                                <mat-icon>info</mat-icon>
                            </button>
                        </div>
                        <div class="approve">
                            <mat-icon title="APROBADO" style="color:rgb(0, 155, 64)" *ngIf="piecework.approve=='SI'">
                                verified</mat-icon>
                            <mat-icon title="RECHAZADO" style="color:rgb(185, 1, 1)" *ngIf="piecework.approve=='NO'">
                                cancel</mat-icon>
                            <!-- Botón para aprobar la requisición -->
                            <button *ngIf="piecework.approve=='NA'" class="approveB" title="Aprobar"
                                mat-icon-button (click)="approvePiece(piecework.idPiecework)">
                                <mat-icon>thumb_up</mat-icon>
                            </button>
                            <!-- Botón para rechazar la requisición -->
                            <button *ngIf="piecework.approve=='NA'" class="reject" title="Rechazar"
                                mat-icon-button (click)="rejectPiece(piecework.idPiecework)">
                                <mat-icon>thumb_down</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <div *ngIf="view"  style="overflow:scroll;">
                    <table class="table table-striped mt-3 animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr style="text-align:center;">
                                <!-- Se definen los encabezados de cada columna de la tabla -->
                                <th scope="col">Proyecto</th>
                                <th scope="col">Modulo</th>
                                <th scope="col">Número de destajo</th>
                                <th scope="col">Periodo</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- ngFor para recorrer todas las requisiciones obtenidas -->
                            <!-- con esto podemos controlar la generación de componentes -->
                            <!-- en base a la cantidad de requisiciones -->
                            <tr *ngFor="let piecework of pieceworks" style="text-align:center;">
                                <!-- Se define con qué datos se llenará cada columna de la tabla -->
                                <td>{{piecework.idProject}}</td>
                                <td>{{piecework.towernumber}}</td>
                                <td>{{piecework.idPiecework}}</td>
                                <td>{{piecework.initweek | date: 'dd-MMM-yyyy'}}- {{piecework.endweek | date: 'dd-MMM-yyyy'}}</td>
                                <td class="button-row">
                                    <!-- Botón para visualizar el desgloce de partidas de una requisición -->
                                    <button class="btn btn-m19-info" title="Ver detalle" mat-icon-button
                                        (click)="selectPiecework(piecework.idPiecework)">
                                        <mat-icon>info</mat-icon>
                                    </button>
                                    <mat-icon title="APROBADO" style="color:rgb(0, 155, 64)"
                                        *ngIf="piecework.approve=='SI'">verified</mat-icon>
                                    <mat-icon title="RECHAZADO" style="color:rgb(185, 1, 1)"
                                        *ngIf="piecework.approve=='NO'">cancel</mat-icon>
                                    <!-- Botón para aprobar la requisición -->
                                    <button *ngIf="piecework.approve=='NA'" class="approveB" title="Aprobar"
                                        mat-icon-button (click)="approvePiece(piecework.idPiecework)">
                                        <mat-icon>thumb_up</mat-icon>
                                    </button>
                                    <!-- Botón para rechazar la requisición -->
                                    <button *ngIf="piecework.approve=='NA'" class="reject" title="Rechazar"
                                        mat-icon-button (click)="rejectPiece(piecework.idPiecework)">
                                        <mat-icon>thumb_down</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </mat-card-content>
    </mat-card>
</div>