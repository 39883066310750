import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ControComissionsService } from 'src/app/services/contro-comissions.service';

import { PaymentService } from 'src/app/services/payment.service';

import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { DatePipe } from '@angular/common';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import jwtDecode from 'jwt-decode';
import { PdfMakerComponent } from 'src/app/utils/pdf-maker/pdf-maker.component';
import Swal from 'sweetalert2';
import { auto } from '@popperjs/core';
import { CashCutService } from 'src/app/services/cashCut.service';
import { ProjectService } from 'src/app/services/project.service';
import { _MatRadioGroupBase } from '@angular/material/radio';
import { UtilsService } from 'src/app/services/utils.service';
import { loader } from 'src/app/ui/loader/loader.model';

@Component({
  selector: 'app-cash-cut-and-cash-flow',
  templateUrl: './cash-cut-and-cash-flow.component.html',
  styleUrls: ['./cash-cut-and-cash-flow.component.scss']
})
export class CashCutAndCashFlowComponent implements OnInit {
  router: any;

  public getScreenWidth: any;
  public getScreenHeight: any;

  loader: loader = {
    message: "Calculando ingresos"
  }

  loading = 1;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

  constructor(
    private contrlCommission: ControComissionsService,
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    private ticketOutputs: PdfMakerComponent,
    private cashCutService: CashCutService,
    private paymentService: PaymentService,
    public projectService: ProjectService,
    public utilSevice: UtilsService,

  ) {

    this.utilSevice.getDate().subscribe(res => this.serverDate = this.pipe.transform(res, 'dd-MM-yyyy'));
    this.comparacionFechas();

  }

  serverDate: string = "CARGANDO FECHA..."

  //Variables para el ngAutoComplete
  employ: any = [];
  keyword = 'empleado';  //Hace referencia al campo que usa como busqueda

  //Variable para el usuario logueago
  decode: any = [];

  //Variable para guardar el nombre del solicitante de efectivo
  solicitante = "";
  fechaActualTickets: any | null;


  ///////////////////////// Consulta de Ingresos y egresos (UNA FECHA)////////////////////////////////////

  idCashOut = "";

  ///////////////////////// Consulta de Ingresos y egresos (RANGO) ////////////////////////////////////

  arrayDataTicket: any = [];
  fechaActual = ""

  ngOnInit(): void {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    registerLocaleData(localeEsMx, 'es-MX'); //REGISTRAR LOCAL PARA ESPAÑOL this.currentDate.toISOString()
    const date = new Date();
    this.CashOutflowsFormGroup.controls['registrationDate'].setValue(date.getFullYear()+"-"+(date.getMonth()+1).toString().padStart(2, '0')+"-"+date.getDate().toString().padStart(2, '0'));
    this.getAllCurrentPayments();

    this.getProjects();
    this.getUser();
    this.employeesDataInformation();

    this.fechaActualTickets = this.pipe.transform(this.currentDate, ('dd-MM-yyyy'));
  }

  //////////////////////////////////////////////////// CORTE DE CAJA (NUEVO)////////////////////////////////////////////////////////////////////////////
  //Variables para usar fechas en horario Local es-MX
  pipe = new DatePipe('es-MX');
  currentDate = new Date();

  dataLastCashCut: any = [];
  fechaSeleccionada = "0000-00-00";
  ratifPay = [];
  totratifPay = 0;
  inputType = "Efectivo";
  inputType2 = "Efectivo";
  inputType3 = "Efectivo";

  partialCashGroup: FormGroup = this._formBuilder.group({
    registrationDate: [], //lista
    responsiblePartial: [this.getUser()], //lista
    quantityPartial: [], //lista
  });

  closeCashGroup: FormGroup = this._formBuilder.group({
    id: [],
    resposibleCashCut: [this.getUser()],
    total: [,],
    outputs: [,],

  });

  cashCutEntries = {
    all: [],
    cash: [],
    deposit: [],
    transfer: [],
    tdc: [],
    tdd: [],
    check: [],
    others: [],
    escrowDeposit: [],

    cashout: [],
    cobranza: []
  }

  cashCutTotal = {
    alltotal: 0,
    cashtotal: 0,
    depositTotal: 0,
    escrowDepositTotal: 0,
    transferTotal: 0,
    tdcTotal: 0,
    tddTotal: 0,
    checkTotal: 0,
    othersTotal: 0,
    cashOutTotal: 0,
    gastosCobranzaEf: 0
  }

  cashCutFlags = {
    cash: true,
    deposit: false,
    tranfer: false,
    tdc: false,
    tdd: false,
    check: false,
    all: false
  }

  transferCheck = {
    approve: 0,
    waiting: 0,
    refuse: 0
  }

  inputTypeChange(event: any) {
    this.inputType = event.target.firstChild.data;

    if (this.inputType == "Efectivo") {
      this.cashCutFlags = {
        cash: true,
        deposit: false,
        tranfer: false,
        tdc: false,
        tdd: false,
        check: false,
        all: false
      }
    } else if (this.inputType == "Transferencia") {
      this.cashCutFlags = {
        cash: false,
        deposit: false,
        tranfer: true,
        tdc: false,
        tdd: false,
        check: false,
        all: false
      }
    } else if (this.inputType == "Deposito") {
      this.cashCutFlags = {
        cash: false,
        deposit: true,
        tranfer: false,
        tdc: false,
        tdd: false,
        check: false,
        all: false
      }
    } else if (this.inputType == "Tarjeta de credito") {
      this.cashCutFlags = {
        cash: false,
        deposit: false,
        tranfer: false,
        tdc: true,
        tdd: false,
        check: false,
        all: false
      }
    } else if (this.inputType == "Tarjeta de debito") {
      this.cashCutFlags = {
        cash: false,
        deposit: false,
        tranfer: false,
        tdc: false,
        tdd: true,
        check: false,
        all: false
      }
    } else if (this.inputType == "Cheque") {
      this.cashCutFlags = {
        cash: false,
        deposit: false,
        tranfer: false,
        tdc: false,
        tdd: false,
        check: true,
        all: false
      }
    } else if (this.inputType == "Todos") {
      this.cashCutFlags = {
        cash: false,
        deposit: false,
        tranfer: false,
        tdc: false,
        tdd: false,
        check: false,
        all: true
      }
    } else {

    }

  }

  cobranzaDelDia() {
    this.changeLoadingMessage("Calculando Gastos de Cobranza");
    this.cashCutService.collectionCostsService().subscribe(
      res => {
        this.cashCutEntries.cobranza = res;

        this.sumaCobranza();
        this.getAllCurrentCashOutflows();
      }
    )
  }

  changeLoadingMessage(msg: string) {
    this.loader = {
      ...this.loader,
      message: msg
    };
  }

  sumaCobranza() {
    this.cashCutTotal.gastosCobranzaEf = 0;
    for (let t1 of this.cashCutEntries.cobranza) { this.cashCutTotal.gastosCobranzaEf += t1.cantidadAbono }

  }

  cobranzaPorFecha() {
    this.changeLoadingMessage("Calculando Gastos de Cobranza");
    this.cashCutService.collectionCostsByDateService(this.formDateSelector.value.dateSimple).subscribe(
      res => {
        this.cashCutEntriesByDate.cobranza = res;
        this.sumaCobranzaPorFecha();

        this.loading = 0;
      }
    )
  }

  sumaCobranzaPorFecha() {
    this.cashCutTotalByDate.cobranzaTotal = 0;
    for (let t1 of this.cashCutEntriesByDate.cobranza) { this.cashCutTotalByDate.cobranzaTotal += t1.cantidadAbono }

  }

  getAllCurrentPayments() {
    this.cashCutEntries = {
      all: [],
      cash: [],
      deposit: [],
      transfer: [],
      tdc: [],
      tdd: [],
      check: [],
      others: [],
      escrowDeposit: [],
      cashout: [],
      cobranza: []
    }
    this.cashCutService.getCurrentPaymentsService(this.pipe.transform(this.currentDate, "yyyy-MM-dd")).subscribe(
      res => {
        this.cashCutEntries.all = res;

        for (const entry of this.cashCutEntries.all) {
          if (entry.metodoPago == "Efectivo") {
            this.cashCutEntries.cash.push(entry)
          } else if (entry.metodoPago == "Deposito") {
            this.cashCutEntries.deposit.push(entry)
          } else if (entry.metodoPago == "Transferencia") {
            this.cashCutEntries.transfer.push(entry)
          } else if (entry.metodoPago == "Tarjeta de credito") {
            this.cashCutEntries.tdc.push(entry)
          } else if (entry.metodoPago == "Tarjeta de debito") {
            this.cashCutEntries.tdd.push(entry)
          } else if (entry.metodoPago == "Cheque") {
            this.cashCutEntries.check.push(entry)
          } else if (entry.metodoPago == "Deposito en garantía") {
            this.cashCutEntries.escrowDeposit.push(entry)
          } else if (entry.metodoPago == "Gasto de cobranza") {
            this.cashCutEntries.cobranza.push(entry)
          } else { this.cashCutEntries.others.push(entry) }

          this.sumofPayments();

        }

        this.cobranzaDelDia();
      }
    )

  }

  async getAllCurrentCashOutflows() {
    this.cashCutTotal.cashOutTotal = 0;
    this.changeLoadingMessage("Calculando salidas de efectivo");
    this.cashCutService.getCurrentCashOutflowsService(this.pipe.transform(this.currentDate, "yyyy-MM-dd")).subscribe(
      res => {
        this.cashCutEntries.cashout = res;
        for (let sal of this.cashCutEntries.cashout) { this.cashCutTotal.cashOutTotal += sal.quantity }
        this.getAllRatificationPayments(this.currentDate.toString());
      }
    )
  }

  getAllRatificationPayments(date: string) {
    this.changeLoadingMessage("Calculando pagos extras");
    this.cashCutService.getRatificationPayments(this.pipe.transform(this.currentDate, "yyyy-MM-dd")).subscribe(
      res => {
        this.ratifPay = res;
        for (let p of this.ratifPay) { this.totratifPay += p.cantidadAbono }
        this.loading = 0;
      }
    )
  }

  async sumofPayments() {
    let t1: any;
    this.cashCutTotal = {
      alltotal: 0,
      cashtotal: 0,
      depositTotal: 0,
      transferTotal: 0,
      tdcTotal: 0,
      tddTotal: 0,
      checkTotal: 0,
      othersTotal: 0,
      cashOutTotal: 0,
      gastosCobranzaEf: 0,
      escrowDepositTotal: 0
    }
    for (t1 of this.cashCutEntries.all) { this.cashCutTotal.alltotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntries.cash) { this.cashCutTotal.cashtotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntries.deposit) { this.cashCutTotal.depositTotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntries.transfer) { this.cashCutTotal.transferTotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntries.tdc) { this.cashCutTotal.tdcTotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntries.tdd) { this.cashCutTotal.tddTotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntries.check) { this.cashCutTotal.checkTotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntries.escrowDeposit) { this.cashCutTotal.escrowDepositTotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntries.others) { this.cashCutTotal.othersTotal += t1.cantidadAbono }


  }

  async partialCashCut() {

    if (this.getScreenWidth <= 660) {
      const value = await Swal.fire({
        title: '  <FONT COLOR="#000000"> <strong> Corte parcial del dia </strong> </FONT> ',
        width: "100%",
        showConfirmButton: true,
        showCancelButton: true,

        html: 'Ingrese la cantidad de efectivo que sera ' +
          '<br>entregada al siguiente responsable, en caja. ' +
          '<br>',

        confirmButtonText: 'Continuar',
        cancelButtonText: `Cancelar`,
        buttonsStyling: false, //Permite customizar los botones

        inputPlaceholder: 'Ingrese cantidad',
        input: 'number',
        inputAttributes: {
          min: "0"
        },


        customClass: {
          actions: 'sweetActions',
          confirmButton: 'm19AltBtn',
          cancelButton: 'm19InvBtn',
          input: 'sweetInput',

        },

        inputValidator: (valor) => {
          return new Promise((resolve) => {
            valor === "" ? resolve("Debes Ingresar una cantidad mayor o igual a 0") :
              this.partialCashGroup.controls["quantityPartial"].setValue(valor);
            this.partialCashGroup.controls["registrationDate"].setValue(this.pipe.transform(this.currentDate, ('yyyy-MM-dd')))
            resolve('');
          })
        }

      }).then((result) => {
        this.loading = 1;
        this.changeLoadingMessage("Registrando Corte Parcial");
        this.contrlCommission.getAllEmployeesService().subscribe(
          res => {
            if (result.isConfirmed) {
              this.cashCutService.InsertCashCutService(this.partialCashGroup.value).subscribe(
                res => {
                  this.comparacionFechas();
                  this.loading = 0;
                }
              );

              Swal.fire("Se hizo registro del corte parcial", "Ha sido asignado un nuevo responsable", "success")
              result.dismiss
              res;
            } else if (result.isDismissed) { result.dismiss }
          }
        )
      })
    } else {
      const value = await Swal.fire({
        title: '  <FONT COLOR="#000000"> <strong> Corte parcial del dia </strong> </FONT> ',
        width: "35%",
        showConfirmButton: true,
        showCancelButton: true,

        html: 'Ingrese la cantidad de efectivo que sera ' +
          '<br>entregada al siguiente responsable, en caja. ' +
          '<br>',

        confirmButtonText: 'Continuar',
        cancelButtonText: `Cancelar`,
        buttonsStyling: false, //Permite customizar los botones

        inputPlaceholder: 'Ingrese cantidad',
        input: 'number',
        inputAttributes: {
          min: "0"
        },


        customClass: {
          actions: 'sweetActions',
          confirmButton: 'm19AltBtn',
          cancelButton: 'm19InvBtn',
          input: 'sweetInput',

        },

        inputValidator: (valor) => {
          return new Promise((resolve) => {
            valor === "" ? resolve("Debes Ingresar una cantidad mayor o igual a 0") :
              this.partialCashGroup.controls["quantityPartial"].setValue(valor);
            this.partialCashGroup.controls["registrationDate"].setValue(this.pipe.transform(this.currentDate, ('yyyy-MM-dd')))
            resolve('');
          })
        }

      }).then((result) => {
        this.loading = 1;
        this.changeLoadingMessage("Registrando Corte Parcial");
        this.contrlCommission.getAllEmployeesService().subscribe(
          res => {
            if (result.isConfirmed) {
              this.cashCutService.InsertCashCutService(this.partialCashGroup.value).subscribe(
                res => {
                  this.comparacionFechas();
                  this.loading = 0;
                }
              );

              Swal.fire("Se hizo registro del corte parcial", "Ha sido asignado un nuevo responsable", "success")
              result.dismiss
              res;
            } else if (result.isDismissed) { result.dismiss }
          }
        )
      })
    }

  }

  banderaBoton = {
    partial: true,
    cashCut: false
  }

  comparacionFechas() {

    this.dataLastCashCut = [];
    this.cashCutService.getLastCashCutService().subscribe(
      res => {
        this.dataLastCashCut = res;

        let newDate = "";
        let curr = this.serverDate;

        this.dataLastCashCut == null ? newDate = "00-00-0000" : newDate = this.pipe.transform(this.dataLastCashCut.registrationDate, ("dd-MM-yyyy"));

        if (curr == newDate) {

          if (this.dataLastCashCut.resposibleCashCut == 'PENDIENTE') {
            this.banderaBoton = {
              partial: false,
              cashCut: true
            }
          } else {
            if (this.dataLastCashCut.resposibleCashCut == "" || this.dataLastCashCut.resposibleCashCut == null) {
              Swal.fire("Tabla vacia", " Sin registros previos", "warning")
            } else {
              Swal.fire("Ya se realizo el corte de caja", " Una vez hecho el corte ya no se puede volver a realizar hasta el dia siguiente ", "warning")
            }

          }

        } else {

        }

      }
    )
  }

  //////////////////////////////////////// INGRESOS Y EGRESOS (NUEVO) ///////////////////////////////////////////////////////////////////////////////////
  //------------------------------------------- SIMPLE ----------------------------------------
  // public formRange = new FormGroup({
  //   start: new FormControl('', Validators.required),  Otra forma de declarar
  //   end: new FormControl('', Validators.required)     un form group mas bonito
  // });

  formDateSelector: FormGroup = this._formBuilder.group({
    currentDate: ['', Validators.required],
    dateSimple: ['', Validators.required],
    dateStart: ['', Validators.required],
    dateEnd: ['', Validators.required],
  });

  flagsView = {
    simple: true,
    range: false,
  }

  cashCutEntriesByDate = {
    all: [],
    cash: [],
    deposit: [],
    transfer: [],
    tdc: [],
    tdd: [],
    check: [],
    cobranza: [],
    escrowDeposit: [],
    cashout: []
  }

  cashCutTotalByDate = {
    alltotal: 0,
    cashtotal: 0,
    depositTotal: 0,
    transferTotal: 0,
    tdcTotal: 0,
    tddTotal: 0,
    checkTotal: 0,
    cobranzaTotal: 0,
    escrowDepositTotal: 0,

    cashOutTotal: 0,
  }

  cashCutFlagsByDate = {
    cash: true,
    deposit: false,
    tranfer: false,
    tdc: false,
    tdd: false,
    check: false,
    all: false
  }

  async changeInput(event: boolean) {

    if (event == true) {
      this.flagsView.simple = false;
      this.flagsView.range = true;
    } else {
      this.flagsView.simple = true;
      this.flagsView.range = false;
    }

  }

  inputTypeChangeByDate(event: any) {
    this.inputType2 = event.target.firstChild.data;

    if (this.inputType2 == "Efectivo") {
      this.cashCutFlagsByDate = {
        cash: true,
        deposit: false,
        tranfer: false,
        tdc: false,
        tdd: false,
        check: false,
        all: false
      }

    } if (this.inputType2 == "Transferencia") {
      this.cashCutFlagsByDate = {
        cash: false,
        deposit: false,
        tranfer: true,
        tdc: false,
        tdd: false,
        check: false,
        all: false
      }
    } if (this.inputType2 == "Deposito") {
      this.cashCutFlagsByDate = {
        cash: false,
        deposit: true,
        tranfer: false,
        tdc: false,
        tdd: false,
        check: false,
        all: false
      }
    } if (this.inputType2 == "Tarjeta de credito") {
      this.cashCutFlagsByDate = {
        cash: false,
        deposit: false,
        tranfer: false,
        tdc: true,
        tdd: false,
        check: false,
        all: false
      }
    } if (this.inputType2 == "Tarjeta de debito") {
      this.cashCutFlagsByDate = {
        cash: false,
        deposit: false,
        tranfer: false,
        tdc: false,
        tdd: true,
        check: false,
        all: false
      }
    } if (this.inputType2 == "Cheque") {
      this.cashCutFlagsByDate = {
        cash: false,
        deposit: false,
        tranfer: false,
        tdc: false,
        tdd: false,
        check: true,
        all: false
      }
    } if (this.inputType2 == "Todos") {
      this.cashCutFlagsByDate = {
        cash: false,
        deposit: false,
        tranfer: false,
        tdc: false,
        tdd: false,
        check: false,
        all: true
      }
    } else {

    }

  }

  async ObtainByDateChange() {
    this.loading = 1;
    this.fechaSeleccionada = this.formDateSelector.value.dateSimple

    this.cashCutEntriesByDate = {
      all: [],
      cash: [],
      deposit: [],
      transfer: [],
      tdc: [],
      tdd: [],
      check: [],
      cobranza: [],
      escrowDeposit: [],
      cashout: []
    }

    this.cashCutTotalByDate = {
      alltotal: 0,
      cashtotal: 0,
      depositTotal: 0,
      transferTotal: 0,
      tdcTotal: 0,
      tddTotal: 0,
      checkTotal: 0,
      cobranzaTotal: 0,
      cashOutTotal: 0,
      escrowDepositTotal: 0
    }

    this.transferCheck = {
      approve: 0,
      waiting: 0,
      refuse: 0
    }

    this.getAllPaymentsByDateFront();
  }

  async transferCheckDaily() {
    this.changeLoadingMessage("Calculando Transferencias Validadas");
    this.paymentService.getTransferDaily(this.formDateSelector.value.dateSimple).subscribe(
      res => {
        this.transferCheck = {
          approve: res.approved,
          waiting: res.waiting,
          refuse: res.refuse
        }

        this.getAllCashOutflowsByDate();
      }
    );
  }

  async getAllPaymentsByDateFront() {
    this.changeLoadingMessage("Calculando Datos de Ingresos");
    this.cashCutService.getAllPaymentsByDateService(this.formDateSelector.value.dateSimple).subscribe(
      res => {
        this.cashCutEntriesByDate.all = res;

        for (let entry of this.cashCutEntriesByDate.all) {
          if (entry.metodoPago == "Efectivo") {
            this.cashCutEntriesByDate.cash.push(entry)
          } else if (entry.metodoPago == "Deposito") {
            this.cashCutEntriesByDate.deposit.push(entry)
          } else if (entry.metodoPago == "Transferencia") {
            this.cashCutEntriesByDate.transfer.push(entry)
          } else if (entry.metodoPago == "Tarjeta de credito") {
            this.cashCutEntriesByDate.tdc.push(entry)
          } else if (entry.metodoPago == "Tarjeta de debito") {
            this.cashCutEntriesByDate.tdd.push(entry)
          } else if (entry.metodoPago == "Cheque") {
            this.cashCutEntriesByDate.check.push(entry)
          } else if (entry.metodoPago == "Deposito en garantía") {
            this.cashCutEntriesByDate.escrowDeposit.push(entry)
          } else { }

        }

        this.sumofPaymentsByDate();
        this.transferCheckDaily();
      }
    )
  }

  async getAllCashOutflowsByDate() {
    this.changeLoadingMessage("Calculando Salidas de Efectivo");
    this.cashCutService.getAllCashOutflowsByDateService(this.formDateSelector.value.dateSimple).subscribe(
      res => {
        this.cashCutEntriesByDate.cashout = res;
        for (let sal of this.cashCutEntriesByDate.cashout) { this.cashCutTotalByDate.cashOutTotal += sal.quantity }
        this.cobranzaPorFecha();
      }
    )
  }

  async sumofPaymentsByDate() {
    let t1: any;
    for (t1 of this.cashCutEntriesByDate.all) { this.cashCutTotalByDate.alltotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntriesByDate.cash) { this.cashCutTotalByDate.cashtotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntriesByDate.deposit) { this.cashCutTotalByDate.depositTotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntriesByDate.transfer) { this.cashCutTotalByDate.transferTotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntriesByDate.tdc) { this.cashCutTotalByDate.tdcTotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntriesByDate.tdd) { this.cashCutTotalByDate.tddTotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntriesByDate.check) { this.cashCutTotalByDate.checkTotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntriesByDate.escrowDeposit) { this.cashCutTotalByDate.escrowDepositTotal += t1.cantidadAbono }
  }

  async summary() {
    if (this.getScreenWidth <= 660) {
      Swal.fire({
        title: ' <FONT COLOR="#000000"> <strong> Resumen de ingresos </strong> </FONT>  ',
        width: "90%",
        showConfirmButton: true,
        confirmButtonText: "Cerrar",

        html:
          `<div style="display: flex; flex-direction: column; gap: 7px; flex-wrap: wrap; width: 100%; margin-bottom: 10px;">
        <div style=" display: flex; width: 100%; justify-content: center;">
            <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Deposito </span>
            <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + this.cashCutTotal.depositTotal.toFixed(2)} </span>
        </div>
        <div style=" display: flex; width: 100%; justify-content: center;">
            <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Deposito en garantia </span>
            <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + this.cashCutTotal.escrowDepositTotal.toFixed(2)} </span>
        </div>
        <div style=" display: flex; width: 100%; justify-content: center;">
            <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Transferencia </span>
            <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + this.cashCutTotal.transferTotal.toFixed(2)} </span>
        </div>
        <div style=" display: flex; width: 100%; justify-content: center;">
            <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> TDC  </span>
            <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + this.cashCutTotal.tdcTotal.toFixed(2)} </span>
        </div>
        <div style=" display: flex; width: 100%; justify-content: center;">
            <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> TDD </span>
            <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + this.cashCutTotal.tddTotal.toFixed(2)} </span>
        </div>
        <div style=" display: flex; width: 100%; justify-content: center;">
            <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Cheque </span>
            <span
                style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + this.cashCutTotal.checkTotal.toFixed(2)} </span>
        </div>

        <br>

        <div style=" display: flex; width: 100%; justify-content: center;">
            <span style=" width: 55%; padding: 5px; background-color: var(--M19Primary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;">
            Total Bruto
            </span>
            <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + (this.cashCutTotal.alltotal + this.cashCutTotal.gastosCobranzaEf).toFixed(2)} </span>
        </div>
        <div style=" display: flex; width: 100%; justify-content: center;">
            <span style=" width: 55%; padding: 5px; background-color: var(--M19Primary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;">
            Total Neto:
            </span>
            <span
                style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + ((this.cashCutTotal.alltotal + this.cashCutTotal.gastosCobranzaEf) - this.cashCutTotal.cashOutTotal).toFixed(2)} </span>
        </div>
   </div> ` ,


        buttonsStyling: false, //Permite customizar los botones
        customClass: {
          actions: 'sweetActions',
          confirmButton: 'm19InvBtn',
          input: 'sweetInput'
        },
      })
    } else {
      Swal.fire({
        title: ' <FONT COLOR="#000000"> <strong> <h1> Resumen de ingresos </h1> </strong> </FONT>  ',
        width: "40%",
        showConfirmButton: true,
        confirmButtonText: "Cerrar",

        html:
          `
        <div style="display: flex; flex-direction: column; gap: 7px; flex-wrap: wrap; width: 100%; margin-bottom: 10px;">
                <div style=" display: flex; width: 100%; justify-content: center;">
                    <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Deposito </span>
                    <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + (this.cashCutTotal.depositTotal).toFixed(2)} </span>
                </div>
                <div style=" display: flex; width: 100%; justify-content: center;">
                    <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Deposito en garantia </span>
                    <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + this.cashCutTotal.escrowDepositTotal.toFixed(2)} </span>
                </div>
                <div style=" display: flex; width: 100%; justify-content: center;">
                    <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Transferencia </span>
                    <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + (this.cashCutTotal.transferTotal).toFixed(2)} </span>
                </div>
                <div style=" display: flex; width: 100%; justify-content: center;">
                    <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> TDC  </span>
                    <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + (this.cashCutTotal.tdcTotal).toFixed(2)} </span>
                </div>
                <div style=" display: flex; width: 100%; justify-content: center;">
                    <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> TDD </span>
                    <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + (this.cashCutTotal.tddTotal).toFixed(2)} </span>
                </div>
                <div style=" display: flex; width: 100%; justify-content: center;">
                    <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Cheque </span>
                    <span
                        style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + (this.cashCutTotal.checkTotal).toFixed(2)} </span>
                </div>
            <br>

              <div style=" display: flex; width: 100%; justify-content: center;">
                  <span style=" width: 55%; padding: 5px; background-color: var(--M19Primary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;">
                  Total Bruto
                  </span>
                  <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + (this.cashCutTotal.alltotal + this.cashCutTotal.gastosCobranzaEf).toFixed(2)} </span>
              </div>
              <div style=" display: flex; width: 100%; justify-content: center;">
                  <span style=" width: 55%; padding: 5px; background-color: var(--M19Primary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;">
                  Total Neto:
                  </span>
                  <span
                      style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + ((this.cashCutTotal.alltotal + this.cashCutTotal.gastosCobranzaEf) - this.cashCutTotal.cashOutTotal).toFixed(2)} </span>
              </div>
         </div> ` ,


        buttonsStyling: false, //Permite customizar los botones
        customClass: {
          actions: 'sweetActions',
          confirmButton: 'm19InvBtn',
          input: 'sweetInput'
        },
      })
    }

  }

  async summaryByOneDate() {
    if (this.getScreenWidth <= 660) {
      Swal.fire({
        title: ' <FONT COLOR="#000000"> <strong> Resumen de ingresos </strong> </FONT>  ',
        width: "90%",
        showConfirmButton: true,
        confirmButtonText: "Cerrar",

        html:
          `<div style="display: flex; flex-direction: column; gap: 7px; flex-wrap: wrap; width: 100%; margin-bottom: 10px;">
        <div style=" display: flex; width: 100%; justify-content: center;">
            <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Deposito </span>
            <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + this.cashCutTotalByDate.depositTotal.toFixed(2)} </span>
        </div>
        <div style=" display: flex; width: 100%; justify-content: center;">
            <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Deposito en garantia </span>
            <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + this.cashCutTotalByDate.escrowDepositTotal.toFixed(2)} </span>
        </div>
        <div style=" display: flex; width: 100%; justify-content: center;">
            <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Transferencia </span>
            <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + this.cashCutTotalByDate.transferTotal.toFixed(2)} </span>
        </div>
        <div style=" display: flex; width: 100%; justify-content: center;">
            <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> TDC  </span>
            <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + this.cashCutTotalByDate.tdcTotal.toFixed(2)} </span>
        </div>
        <div style=" display: flex; width: 100%; justify-content: center;">
            <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> TDD </span>
            <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + this.cashCutTotalByDate.tddTotal.toFixed(2)} </span>
        </div>
        <div style=" display: flex; width: 100%; justify-content: center;">
            <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Cheque </span>
            <span
                style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + this.cashCutTotalByDate.checkTotal.toFixed(2)} </span>
        </div>

        <br>

        <div style=" display: flex; width: 100%; justify-content: center;">
            <span style=" width: 55%; padding: 5px; background-color: var(--M19Primary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;">
            Total Bruto
            </span>
            <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + (this.cashCutTotalByDate.alltotal + this.cashCutTotalByDate.cobranzaTotal).toFixed(2)} </span>
        </div>
        <div style=" display: flex; width: 100%; justify-content: center;">
            <span style=" width: 55%; padding: 5px; background-color: var(--M19Primary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;">
            Total Neto:
            </span>
            <span
                style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + ((this.cashCutTotalByDate.alltotal + this.cashCutTotalByDate.cobranzaTotal) - this.cashCutTotalByDate.cashOutTotal).toFixed(2)} </span>
        </div>
   </div> ` ,


        buttonsStyling: false, //Permite customizar los botones
        customClass: {
          actions: 'sweetActions',
          confirmButton: 'm19InvBtn',
          input: 'sweetInput'
        },
      })
    } else {
      Swal.fire({
        title: ' <FONT COLOR="#000000"> <strong> <h1> Resumen de ingresos </h1> </strong> </FONT>  ',
        width: "40%",
        showConfirmButton: true,
        confirmButtonText: "Cerrar",

        html:
          `
        <div style="display: flex; flex-direction: column; gap: 7px; flex-wrap: wrap; width: 100%; margin-bottom: 10px;">
                <div style=" display: flex; width: 100%; justify-content: center;">
                    <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Deposito </span>
                    <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + (this.cashCutTotalByDate.depositTotal).toFixed(2)} </span>
                </div>
                <div style=" display: flex; width: 100%; justify-content: center;">
                    <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Deposito en garantia </span>
                    <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + (this.cashCutTotalByDate.escrowDepositTotal).toFixed(2)} </span>
                </div>
                <div style=" display: flex; width: 100%; justify-content: center;">
                    <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Transferencia </span>
                    <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + (this.cashCutTotalByDate.transferTotal).toFixed(2)} </span>
                </div>
                <div style=" display: flex; width: 100%; justify-content: center;">
                    <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> TDC  </span>
                    <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + (this.cashCutTotalByDate.tdcTotal).toFixed(2)} </span>
                </div>
                <div style=" display: flex; width: 100%; justify-content: center;">
                    <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> TDD </span>
                    <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + (this.cashCutTotalByDate.tddTotal).toFixed(2)} </span>
                </div>
                <div style=" display: flex; width: 100%; justify-content: center;">
                    <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Cheque </span>
                    <span
                        style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + (this.cashCutTotalByDate.checkTotal).toFixed(2)} </span>
                </div>
            <br>

              <div style=" display: flex; width: 100%; justify-content: center;">
                  <span style=" width: 55%; padding: 5px; background-color: var(--M19Primary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;">
                  Total Bruto
                  </span>
                  <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + (this.cashCutTotalByDate.alltotal + this.cashCutTotalByDate.cobranzaTotal).toFixed(2)} </span>
              </div>
              <div style=" display: flex; width: 100%; justify-content: center;">
                  <span style=" width: 55%; padding: 5px; background-color: var(--M19Primary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;">
                  Total Neto:
                  </span>
                  <span
                      style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + ((this.cashCutTotalByDate.alltotal + this.cashCutTotalByDate.cobranzaTotal) - this.cashCutTotalByDate.cashOutTotal).toFixed(2)} </span>
              </div>
         </div> ` ,


        buttonsStyling: false, //Permite customizar los botones
        customClass: {
          actions: 'sweetActions',
          confirmButton: 'm19InvBtn',
          input: 'sweetInput'
        },
      })
    }
  }

  //------------------------------------------- RANGO ----------------------------------------
  inicio = "";
  fin = "";

  cashCutEntriesByRangeDate = {
    all: [],
    cash: [],
    deposit: [],
    transfer: [],
    tdc: [],
    tdd: [],
    check: [],

    cashout: []
  }

  cashCutTotalByRangeDate = {
    alltotal: 0,
    cashtotal: 0,
    depositTotal: 0,
    transferTotal: 0,
    tdcTotal: 0,
    tddTotal: 0,
    checkTotal: 0,

    cashOutTotal: 0,
  }

  cashCutFlagsByRangeDate = {
    cash: true,
    deposit: false,
    tranfer: false,
    tdc: false,
    tdd: false,
    check: false,
    all: false
  }

  async inputTypeChangeByRangeDate(event: any) {
    this.inputType3 = event.target.firstChild.data;

    if (this.inputType3 == "Efectivo") {
      this.cashCutFlagsByRangeDate = {
        cash: true,
        deposit: false,
        tranfer: false,
        tdc: false,
        tdd: false,
        check: false,
        all: false
      }
    } if (this.inputType3 == "Transferencia") {
      this.cashCutFlagsByRangeDate = {
        cash: false,
        deposit: false,
        tranfer: true,
        tdc: false,
        tdd: false,
        check: false,
        all: false
      }
    } if (this.inputType3 == "Deposito") {
      this.cashCutFlagsByRangeDate = {
        cash: false,
        deposit: true,
        tranfer: false,
        tdc: false,
        tdd: false,
        check: false,
        all: false
      }
    } if (this.inputType3 == "Tarjeta de credito") {
      this.cashCutFlagsByRangeDate = {
        cash: false,
        deposit: false,
        tranfer: false,
        tdc: true,
        tdd: false,
        check: false,
        all: false
      }
    } if (this.inputType3 == "Tarjeta de debito") {
      this.cashCutFlagsByRangeDate = {
        cash: false,
        deposit: false,
        tranfer: false,
        tdc: false,
        tdd: true,
        check: false,
        all: false
      }
    } if (this.inputType3 == "Cheque") {
      this.cashCutFlagsByRangeDate = {
        cash: false,
        deposit: false,
        tranfer: false,
        tdc: false,
        tdd: false,
        check: true,
        all: false
      }
    } if (this.inputType3 == "Todos") {
      this.cashCutFlagsByRangeDate = {
        cash: false,
        deposit: false,
        tranfer: false,
        tdc: false,
        tdd: false,
        check: false,
        all: true
      }
    } else { }

  }

  async ObtainByRangeDateChange() {
    this.loading = 1;
    this.transferCheck = {
      approve: 0,
      waiting: 0,
      refuse: 0
    }

    this.inicio = this.pipe.transform(this.formDateSelector.value.dateStart, 'dd-MM-yyyy')
    this.fin = this.pipe.transform(this.formDateSelector.value.dateEnd, 'dd-MM-yyyy')

    this.getAllPaymentsByRangeDate();
  }

  async transferCheckRange(init: string, end: string) {
    this.changeLoadingMessage("Calculando Transferencias Validadas");
    this.paymentService.getTransferRange(init, end).subscribe(
      res => {
        this.transferCheck = {
          approve: res.approved,
          waiting: res.waiting,
          refuse: res.refuse
        }

        this.getAllCashOutflowsByRangeDate();
      }
    );
  }

  async getAllPaymentsByRangeDate() {
    this.changeLoadingMessage("Calculando Datos de Ingresos");
    this.cashCutTotalByRangeDate = {
      alltotal: 0,
      cashtotal: 0,
      depositTotal: 0,
      transferTotal: 0,
      tdcTotal: 0,
      tddTotal: 0,
      checkTotal: 0,

      cashOutTotal: 0,
    }

    this.cashCutEntriesByRangeDate = {
      all: [],
      cash: [],
      deposit: [],
      transfer: [],
      tdc: [],
      tdd: [],
      check: [],

      cashout: []
    }

    this.cashCutService.getAllPaymentsByRangeDateService(this.pipe.transform(this.formDateSelector.value.dateStart, 'yyyy-MM-dd'), this.pipe.transform(this.formDateSelector.value.dateEnd, "yyyy-MM-dd")).subscribe(
      res => {
        this.cashCutEntriesByRangeDate.all = res;

        for (let entry of this.cashCutEntriesByRangeDate.all) {
          if (entry.metodoPago == "Efectivo") {
            this.cashCutEntriesByRangeDate.cash.push(entry)
          } else if (entry.metodoPago == "Deposito") {
            this.cashCutEntriesByRangeDate.deposit.push(entry)
          } else if (entry.metodoPago == "Transferencia") {
            this.cashCutEntriesByRangeDate.transfer.push(entry)
          } else if (entry.metodoPago == "Tarjeta de credito") {
            this.cashCutEntriesByRangeDate.tdc.push(entry)
          } else if (entry.metodoPago == "Tarjeta de debito") {
            this.cashCutEntriesByRangeDate.tdd.push(entry)
          } else if (entry.metodoPago == "Cheque") {
            this.cashCutEntriesByRangeDate.check.push(entry)
          } else { }

        }

        this.sumofPaymentsByRangeDate();

        this.transferCheckRange(this.pipe.transform(this.formDateSelector.value.dateStart, 'yyyy-MM-dd'), this.pipe.transform(this.formDateSelector.value.dateEnd, 'yyyy-MM-dd'));
      }
    )
  }

  async getAllCashOutflowsByRangeDate() {
    this.changeLoadingMessage("Calculando Salidas de Efectivo");
    this.cashCutService.getAllCashOutflowsByRangeDateService(this.pipe.transform(this.formDateSelector.value.dateStart, 'yyyy-MM-dd'), this.pipe.transform(this.formDateSelector.value.dateEnd, "yyyy-MM-dd")).subscribe(
      res => {
        this.cashCutEntriesByRangeDate.cashout = res;
        for (let sal of this.cashCutEntriesByRangeDate.cashout) { this.cashCutTotalByRangeDate.cashOutTotal += sal.quantity }
        this.loading = 0;
      }
    )
  }

  async sumofPaymentsByRangeDate() {

    let t1: any;
    for (t1 of this.cashCutEntriesByRangeDate.all) { this.cashCutTotalByRangeDate.alltotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntriesByRangeDate.cash) { this.cashCutTotalByRangeDate.cashtotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntriesByRangeDate.deposit) { this.cashCutTotalByRangeDate.depositTotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntriesByRangeDate.transfer) { this.cashCutTotalByRangeDate.transferTotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntriesByRangeDate.tdc) { this.cashCutTotalByRangeDate.tdcTotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntriesByRangeDate.tdd) { this.cashCutTotalByRangeDate.tddTotal += t1.cantidadAbono }
    for (t1 of this.cashCutEntriesByRangeDate.check) { this.cashCutTotalByRangeDate.checkTotal += t1.cantidadAbono }
  }

  async summaryByRangeDate() {
    if (this.getScreenWidth <= 660) {
      Swal.fire({
        title: ' <FONT COLOR="#000000"> <strong> Resumen de ingresos </strong> </FONT>  ',
        width: "90%",
        showConfirmButton: true,
        confirmButtonText: "Cerrar",

        html:
          `<div style="display: flex; flex-direction: column; gap: 7px; flex-wrap: wrap; width: 100%; margin-bottom: 10px;">
      <div style=" display: flex; width: 100%; justify-content: center;">
          <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Deposito </span>
          <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + this.cashCutTotalByRangeDate.depositTotal.toFixed(2)} </span>
      </div>
      <div style=" display: flex; width: 100%; justify-content: center;">
          <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Transferencia </span>
          <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + this.cashCutTotalByRangeDate.transferTotal.toFixed(2)} </span>
      </div>
      <div style=" display: flex; width: 100%; justify-content: center;">
          <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> TDC  </span>
          <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + this.cashCutTotalByRangeDate.tdcTotal.toFixed(2)} </span>
      </div>
      <div style=" display: flex; width: 100%; justify-content: center;">
          <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> TDD </span>
          <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + this.cashCutTotalByRangeDate.tddTotal.toFixed(2)} </span>
      </div>
      <div style=" display: flex; width: 100%; justify-content: center;">
          <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Cheque </span>
          <span
              style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + this.cashCutTotalByRangeDate.checkTotal.toFixed(2)} </span>
      </div>

      <br>

      <div style=" display: flex; width: 100%; justify-content: center;">
          <span style=" width: 55%; padding: 5px; background-color: var(--M19Primary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;">
          Total Bruto
          </span>
          <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + this.cashCutTotalByRangeDate.alltotal.toFixed(2)} </span>
      </div>
      <div style=" display: flex; width: 100%; justify-content: center;">
          <span style=" width: 55%; padding: 5px; background-color: var(--M19Primary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;">
          Total Neto:
          </span>
          <span
              style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + (this.cashCutTotalByRangeDate.alltotal - this.cashCutTotalByRangeDate.cashOutTotal).toFixed(2)} </span>
      </div>
 </div> ` ,


        buttonsStyling: false, //Permite customizar los botones
        customClass: {
          actions: 'sweetActions',
          confirmButton: 'm19InvBtn',
          input: 'sweetInput'
        },
      })
    } else {
      Swal.fire({
        title: ' <FONT COLOR="#000000"> <strong> <h1> Resumen de ingresos </h1> </strong> </FONT>  ',
        width: "40%",
        showConfirmButton: true,
        confirmButtonText: "Cerrar",

        html:
          `
      <div style="display: flex; flex-direction: column; gap: 7px; flex-wrap: wrap; width: 100%; margin-bottom: 10px;">
              <div style=" display: flex; width: 100%; justify-content: center;">
                  <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Deposito </span>
                  <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + (this.cashCutTotalByRangeDate.depositTotal).toFixed(2)} </span>
              </div>
              <div style=" display: flex; width: 100%; justify-content: center;">
                  <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Transferencia </span>
                  <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + (this.cashCutTotalByRangeDate.transferTotal).toFixed(2)} </span>
              </div>
              <div style=" display: flex; width: 100%; justify-content: center;">
                  <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> TDC  </span>
                  <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + (this.cashCutTotalByRangeDate.tdcTotal).toFixed(2)} </span>
              </div>
              <div style=" display: flex; width: 100%; justify-content: center;">
                  <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> TDD </span>
                  <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + (this.cashCutTotalByRangeDate.tddTotal).toFixed(2)} </span>
              </div>
              <div style=" display: flex; width: 100%; justify-content: center;">
                  <span style=" width: 55%; padding: 5px; background-color: var(--M19Secundary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;"> Cheque </span>
                  <span
                      style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + (this.cashCutTotalByRangeDate.checkTotal).toFixed(2)} </span>
              </div>
          <br>

            <div style=" display: flex; width: 100%; justify-content: center;">
                <span style=" width: 55%; padding: 5px; background-color: var(--M19Primary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;">
                Total Bruto
                </span>
                <span style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;"> ${"$" + this.cashCutTotalByRangeDate.alltotal.toFixed(2)} </span>
            </div>
            <div style=" display: flex; width: 100%; justify-content: center;">
                <span style=" width: 55%; padding: 5px; background-color: var(--M19Primary); color: white; font-size: 1rem; font-weight: bold; border-radius: 10px 0 0 10px;">
                Total Neto:
                </span>
                <span
                    style="display: flex; justify-content: flex-end; align-items: center; width: 48%; padding: 5px; background: rgba(236, 240, 245, 1); font-size: 1rem; font-weight: bold; border-radius: 0 10px 10px 0;">  ${"$" + (this.cashCutTotalByRangeDate.alltotal - this.cashCutTotalByRangeDate.cashOutTotal).toFixed(2)} </span>
            </div>
       </div> ` ,


        buttonsStyling: false, //Permite customizar los botones
        customClass: {
          actions: 'sweetActions',
          confirmButton: 'm19InvBtn',
          input: 'sweetInput'
        },
      })
    }
  }

  CashOutflowsFormGroup: FormGroup = this._formBuilder.group({
    quantity: [null,],
    reason: [null],
    requestor: [null],
    cashManager: [this.getUser()],
    registrationDate: [null]

  });

  async getProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      }
    );
  }

  //////////////////////////////////////// BITACORA PENDIENTES ///////////////////////////////////////////////

  projects: any = [];

  ////////////////////////////////////////   CORTE DE CAJA  //////////////////////////////////////////////////

  async corteCaja() {

    const value = await Swal.fire({
      title: '  <FONT COLOR="#000000"> <strong> ¡Cierre del caja! </strong> </FONT> ',
      icon: "info",
      iconColor: "#0259bd",
      width: auto,
      showConfirmButton: true,
      showCancelButton: true,


      inputPlaceholder: 'Seleccione a la persona que se le entregara el dinero',
      input: 'select',
      inputOptions: this.responsables,

      html: 'Seleccione en la parte inferior al responsable ' +
        '<br>que se le hara la entrega de <strong> <u>efectivo</u></strong>.' +
        '<br><br><b>  Seleccione una opcion de la lista y presione continuar. </b>',

      confirmButtonText: 'Continuar',
      cancelButtonText: `Cancelar`,
      buttonsStyling: false, //Permite customizar los botones

      customClass: {
        actions: 'sweetActions',
        confirmButton: 'm19AltBtn',
        cancelButton: 'm19InvBtn',
        input: 'sweetInput'
      },

      inputValidator: (value) => {
        return new Promise((resolve) => { value === "" ? resolve("Debes elegir una opción de la lista") : this.assign = this.responsables[value]; resolve(''); })
      }

    }).then((result) => {
      this.loading = 1;
      this.changeLoadingMessage("Registrando Cierre de Caja");
      this.contrlCommission.getAllEmployeesService().subscribe(
        res => {
          if (result.isConfirmed) {
            this.getAllRatificationPayments(this.pipe.transform(this.currentDate, "yyyy-MM-dd"));
            this.closeCashGroup.controls["id"].setValue(this.dataLastCashCut.idcashCut)
            this.closeCashGroup.controls["total"].setValue((this.cashCutTotal.cashtotal + this.cashCutTotal.gastosCobranzaEf) - this.cashCutTotal.cashOutTotal)
            this.closeCashGroup.controls["outputs"].setValue(this.cashCutTotal.cashOutTotal)
            this.cashCutService.closeCashCutService(this.closeCashGroup.value).subscribe(
              res => {
                this.print()
                this.loading = 0;
                Swal.fire("Corte realizado", "Caja cerrada ya puedes irte a descansar :)", "success")
                result.dismiss
              }
            );
          } else if (result.isDismissed) { result.dismiss }
        }
      )
    })

  }

  //-------------------------------------------- SALIDAS DE EFECTIVO ------------------------------------------

  async employeesDataInformation() {
    this.cashCutService.getAllEmployeesForCashoutFlowService().subscribe(
      res => {
        this.employ = res;
        console.log(this.employ);
      }
    )
  }

  async nameAssignment(event: any) {
    this.CashOutflowsFormGroup.controls["requestor"].setValue(event.empleado)
  }

  async registerOutCash() {
    this.loading = 1;
    this.changeLoadingMessage("Registrando Salida de Efectivo");
    this.cashCutService.InsertCashOutService(this.CashOutflowsFormGroup.value).subscribe(
      res => {
        this.sweet.AlertTime("center", "success", "Pago registrado con éxito.", false, 2000);

        this.ticketOutputs.ticketGeneradorCashOutFlows(
          "10",
          this.pipe.transform(this.CashOutflowsFormGroup.value.registrationDate, ('dd-MM-yyyy'))!,
          //this.CashOutflowsFormGroup.value.folio,
          this.CashOutflowsFormGroup.value.cashManager,
          this.CashOutflowsFormGroup.value.quantity,
          this.CashOutflowsFormGroup.value.requestor,
          this.CashOutflowsFormGroup.value.reason,
          this.fechaActualTickets
        );
        this.getAllCurrentPayments();
        this.camposReset();
      }

    );

  }

  //-------------------------------------------- CONSULTA DE INGRESOS Y EGRESOS  ------------------------------------------

  // con una sola fecha
  async cambioVista(event: boolean) {

    if (event == true) {
      this.flagsView.simple = false;
      this.flagsView.range = true;


    } else {
      this.flagsView.simple = true;
      this.flagsView.range = false;
    }
  }

  async reimpresionTicket(idTicket: string, cashManager: string, quantity: string, requestor: string, reason: string, date: string) {

    this.ticketOutputs.ticketReimpresionCashFlows(

      this.pipe.transform(date, ('dd-MM-yyyy'))!,

      cashManager,
      quantity,
      requestor,
      reason,
      this.fechaActualTickets
    );
  }

  async eliminarTicket(idTicket: string) {

    this.idCashOut = idTicket;

    Swal.fire({
      title: '  <FONT COLOR="#000000"> <strong> ¡Estas a punto de eliminar este ticket! </strong> </FONT> ',
      width: auto,
      icon: 'warning',

      showConfirmButton: true,
      confirmButtonColor: '#3085d6',

      showCancelButton: true,
      cancelButtonColor: " #D33E3E",

      html: 'Al eliminar este ticket no podras volver a recuperarlo' +
        '<br><br><b> Seleccione una opción para continuar </b>',

      confirmButtonText: 'Si, eliminalo!!',

      cancelButtonText: `No, cancelar`
    }).then((result) => {
      if (result.isConfirmed) {

        this.cashCutService.cancelCashoutFlowService(this.idCashOut).subscribe(
          res => {
            Swal.fire({ title: 'Eliminación correcta', text: 'El ticket fue eliminado', icon: 'success', timer: 2000 })

            this.getAllCurrentPayments();
            this.getAllCurrentCashOutflows();
            this.cobranzaDelDia()


            if (this.formDateSelector.value.dateSimple == '' || this.formDateSelector.value.dateSimple == null) {

            } else {
              this.ObtainByDateChange()
            }

            if (this.formDateSelector.value.dateStart == '' || this.formDateSelector.value.dateEnd == '' || this.formDateSelector.value.dateStart == null || this.formDateSelector.value.dateEnd == null) {

            } else {
              this.getAllPaymentsByRangeDate();
              this.getAllCashOutflowsByRangeDate();
            }

            res;
          }
        )

      } else {
        result.isDismissed
      }
    })
  }

  //------------------------------------ FORMATO DE DOCUMENTOS PARA IMPRIMIR --------------------------------

  //Cierre de caja
  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('cashCuthtml')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
          <html>
          <title > ${this.pipe.transform(this.currentDate, 'longDate')!.toUpperCase()} </title>

          <head>
              <style>

              * {
                margin: 0;
                padding: 0;
              }

              table {
                width: 100%;
                border-collapse: collapse;
                border: solid #5E4F4F 5px;
              }

              .formatTextDate{
                text-align:left;
              }

              h1{
                text-align:center;
                width:100%;
              }
              h4 {
                padding: 10px;
                background-color: #D33E3E;
                color: white;
                font-weight: bold;
                text-align: center;
                border-radius: 20px 20px 0 0;
                margin-bottom: 0;
            }


            .information-Header{
                display:flex;
                flex-direction: column-reverse;
                justiify-content: space-between;

              }

              .format-Table{
                width: 100%;
                border-collapse: collapse;
                border: solid #5E4F4F 5px;
                }

                thead {
                background-color: #5E4F4F;
                border: solid #5E4F4F 5px;
                color: white;
                font-weight: bold;
                font-size: 1rem;
                text-align: center;
              }

              tbody {
                text-align: center;
                font-size: 1rem;
                border: solid #5E4F4F 5px;
              }

              tbody tr:nth-child(even) {
                background-color: #ebebeb;
              }

              td {
                padding: 10px;
              }

              .td-text-left{
                  text-align:left;
                  padding-left: 30px;
              }

            .formatTextDate{
                text-align:left;
              }

            .formatTextDate2{
                text-align:right;
            }

            .formatTableBorders {
                border: solid black 1.5px;
                border-collapse: collapse;
            }


            .tableTextHeader{
              text-align:center;
              border: solid black 1.5px;
              padding: 5px;
            }

            .tableDataText1{
              text-align:center;
              border: solid black 1.5px;
              padding: 10px;
            }

            .tableDataText2{
              text-align:left;
              border: solid black 1.5px;
              padding: 10px;
            }

            .tableDataText3{
              text-align:right;
              border: solid black 1.5px;
              padding: 10px;
            }

              .shared-Container{
                display:flex;
                flex-direction:row;
                justify-content:center;
                flex-wrap:wrap;
                width:98%;
                justify-content:space-around;
                border-buttom: solid black 3px;
                margin:1%;
              }

              .information-Delivery{
                display:flex;
                flex-direction:column;
                text-align: center;
                font-style: oblique;
                font-weight: bold;
                margin:1%;
                justify-content: center;
              }

              .information-Receive{
                display:flex;
                flex-direction:column;
                text-align: center;
                font-style: oblique;
                font-weight: bold;
                margin:1%;
                justify-content: center;

              }

              .separacion{
                display-flex;
                border-top: solid black 3px;
                border-bottom: solid black 3px;
              }

              .infoColumn {
                display: flex;
                flex-direction: row;
                color: black;
                font-weight: bold;
                justify-content: space-evenly;
                background-color: white;
                align-items: center;
                border-radius: 0 0 20px 20px ;
              }

              .texto-salida{
                display:flex;
                text-align:center;
                flex-direction:column;
                font-size:large;
              }


              .salida-neta{
                color: #D33E3E;
                font-size:x-large;
              }

              .encabezado{
                border-radius: 5px;
                background-color: white;
                flex-direction:column;
                margin:1%
              }

                .tiempo-impresion{
                  display:flex;
                  text-align:right;
                  width:100%;
                  justify-content: flex-end;
                  background-color: transparent;
                  padding-right:15px;
                }

                .margen{
                  margin: 1%;
                  flex-direction:row;
                  padding-bottom:10px;
                  display:flex;
                  width:100%
                }


              .totalSeccion{
                display: flex;
                flex-direction: column;
                width: 100%;
                break-before:page;
              }

               .continueSeccion{
                display: flex;
                flex-direction: column;
                width: 100%;
              }

              </style>

            </head>


        <body onload="window.print();window.close()">${printContents}</body>

          </html>`
    );
    popupWin!.document.close();
  }

  //Reporte de ingresos Solo EFECTIVO
  ReporteIOEfectivo(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlSummaryCashIO')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
          <html>
          <title >Ingresos en efectivo - ${this.pipe.transform(this.currentDate, 'longDate')!.toUpperCase()} </title>

          <head>
              <style>

              * {
                margin: 0;
                padding: 0;
                -webkit-print-color-adjust: exact;
                color-adjust: exact;
              }

              .Contenedor-Impresion-Report-Efectivo{
                text-align: center;
                margin: 10px;
                text-align: center;
              }

              .encabezado{
                border-radius: 5px;
                background-color: #ebdfdf;
                flex-direction:column;
                margin:1%
              }

                .tiempo-impresion{
                  display:flex;
                  text-align:right;
                  width:100%;
                  justify-content: flex-end;
                  background-color: transparent;
                  padding-right:15px;
                }

                .margen{
                  margin: 1%;
                  flex-direction:row;
                  padding-bottom:10px;
                  display:flex;
                  width:100%
                }

                h2 {
                  padding: 10px;
                  color: BLACK;
                  font-weight: bold;
                  text-align: center;
                  margin-bottom: 0;
                }

                h4 {
                  padding: 10px;
                  background-color: #D33E3E;
                  color: white;
                  font-weight: bold;
                  text-align: center;
                  border-radius: 20px 20px 0 0;
                  margin-bottom: 0;
              }

                .format-Table{
                width: 100%;
                border-collapse: collapse;
                border: solid #5E4F4F 5px;
                }

                thead {
                background-color: #5E4F4F;
                border: solid #5E4F4F 5px;
                color: white;
                font-weight: bold;
                font-size: 1rem;
                text-align: center;
              }

              tbody {
                text-align: center;
                font-size: 1rem;
                border: solid #5E4F4F 5px;
              }

              tbody tr:nth-child(even) {
                background-color: #ebebeb;
              }

              td {
                padding: 10px;
              }

              .td-text-left{
                  text-align:left;
                  padding-left: 30px;
              }

              .infoColumn {
                display: flex;
                flex-direction: row;
                color: black;
                font-weight: bold;
                justify-content: space-evenly;
                background-color: white;
                align-items: center;
                border-radius: 0 0 20px 20px ;
              }

              .texto-salida{
                display:flex;
                text-align:center;
                flex-direction:column;
                font-size:large;
              }


              .salida-neta{
                color: #D33E3E;
                font-size:x-large;
              }

              .totalSeccion{
                display: flex;
                flex-direction: column;
                width: 100%;
                break-before:page;
              }

              .continueSeccion{
                display: flex;
                flex-direction: column;
                width: 100%;
              }

              .screenShared{
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                width: 50%;
                text-align: center;
                align-items: center;

              }

              .shared-Container{
                display:flex;
                flex-direction:row;
                justify-content:center;
                flex-wrap:wrap;
                width:98%;
                justify-content:space-around;
                border-buttom: solid black 3px;
                margin:1%;
              }

              .information-Delivery{
                display:flex;
                flex-direction:column;
                text-align: center;
                font-style: oblique;
                font-weight: bold;
                margin:1%;
                justify-content: center;
              }

              .information-Receive{
                display:flex;
                flex-direction:column;
                text-align: center;
                font-style: oblique;
                font-weight: bold;
                margin:1%;
                justify-content: center;

              }


              </style>

            </head>

            <body onload="window.print();window.close()">${printContents}</body>
          </html>`
    );
    popupWin!.document.close();
  }

  //Reporte de ingresos TODAS LAS ENTRADAS
  ReporteIOEntradasTotales(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlSummaryGeneralIO')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
          <html>
          <title > Ingresos totales - ${this.pipe.transform(this.currentDate, 'longDate')!.toUpperCase()} </title>

          <head>
              <style>

              * {
                margin: 0;
                padding: 0;
                -webkit-print-color-adjust: exact;
                color-adjust: exact;
              }

              body {
                font-family: Roboto, "Helvetica Neue", sans-serif;
              }

              .Contenedor-Impresion-Report-Efectivo{
                 text-align: center;
                 margin: 10px;
                 text-align: center;
               }

               .encabezado{
                border-radius: 5px;
                background-color: #ebdfdf;
                flex-direction:column;
                margin:1%
               }

                .tiempo-impresion{
                  display:flex;
                  text-align:right;
                  width:100%;
                  justify-content: flex-end;
                  background-color: transparent;
                  padding-right:15px;
                }

                .margen{
                  margin: 1%;
                  flex-direction:row;
                  padding-bottom:10px;
                  display:flex;
                  width:100%
                }

                h2 {
                  padding: 10px;
                  color: BLACK;
                  font-weight: bold;
                  text-align: center;
                  margin-bottom: 0;
                }

                h4 {
                  padding: 10px;
                  background-color: #D33E3E;
                  color: white;
                  font-weight: bold;
                  text-align: center;
                  margin-bottom: 0;
              }

                .format-Table{
                 width: 100%;
                 border-collapse: collapse;
                 border: solid #5E4F4F 5px;
                }

                thead {
                 background-color: #5E4F4F;
                 border: solid #5E4F4F 5px;
                 color: white;
                 font-weight: bold;
                 font-size: 1rem;
                 text-align: center;
               }

               tbody {
                 text-align: center;
                 font-size: 1rem;
                 border: solid #5E4F4F 5px;
               }

               tbody tr:nth-child(even) {
                 background-color: #ebebeb;
               }

               td {
                 padding: 10px;
               }

               .td-text-left{
                  text-align:left;
                  padding-left: 30px;
               }

               .infoColumn {
                 display: flex;
                 flex-direction: row;
                 color: black;
                 font-weight: bold;
                 justify-content: space-evenly;
                 background-color: white;
                 align-items: center;
                 border-radius: 0 0 20px 20px ;
               }

               .transferCount {
                display: flex;
                flex-direction: column;
              }

              .transfTitle {
                background-color: #D33E3E;
                color: white;
                text-align: center;
              }

              .transfCounter {
                display: flex;
                justify-content: space-between;
                padding: 0 20px 0 20px;
              }

               .texto-salida{
                display:flex;
                text-align:center;
                flex-direction:column;
                font-size:large;
               }


               .salida-neta{
                color: #D33E3E;
                font-size:x-large;
               }

               .continueSeccion{
                display: flex;
                flex-direction: column;
                width: 100%;
              }

              </style>

            </head>

            <body onload="window.print();window.close()">${printContents}</body>
          </html>`
    );
    popupWin!.document.close();
  }

  //Reporte de ingresos por RANGOS Solo EFECTIVO
  ReporteRangoIOEfectivo(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlSummaryCashRangeIO')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
              <html>
              <title >Ingresos en efectivo - ${this.pipe.transform(this.currentDate, 'longDate')!.toUpperCase()} </title>

              <head>
                  <style>

                  * {
                    margin: 0;
                    padding: 0;
                    -webkit-print-color-adjust: exact;
                    color-adjust: exact;
                  }

                  .Contenedor-Impresion-Report-Efectivo{
                    text-align: center;
                    margin: 10px;
                    text-align: center;
                  }

                  .encabezado{
                    border-radius: 5px;
                    background-color: #ebdfdf;
                    flex-direction:column;
                    margin:1%
                  }

                    .tiempo-impresion{
                      display:flex;
                      text-align:right;
                      width:100%;
                      justify-content: flex-end;
                      background-color: transparent;
                      padding-right:15px;
                    }

                    .margen{
                      margin: 1%;
                      flex-direction:row;
                      padding-bottom:10px;
                      display:flex;
                      width:100%
                    }

                    h2 {
                      padding: 10px;
                      color: BLACK;
                      font-weight: bold;
                      text-align: center;
                      margin-bottom: 0;
                    }

                    h4 {
                      padding: 10px;
                      background-color: #D33E3E;
                      color: white;
                      font-weight: bold;
                      text-align: center;
                      border-radius: 20px 20px 0 0;
                      margin-bottom: 0;
                  }

                    .format-Table{
                    width: 100%;
                    border-collapse: collapse;
                    border: solid #5E4F4F 5px;
                    }

                    thead {
                    background-color: #5E4F4F;
                    border: solid #5E4F4F 5px;
                    color: white;
                    font-weight: bold;
                    font-size: 1rem;
                    text-align: center;
                  }

                  tbody {
                    text-align: center;
                    font-size: 1rem;
                    border: solid #5E4F4F 5px;
                  }

                  tbody tr:nth-child(even) {
                    background-color: #ebebeb;
                  }

                  td {
                    padding: 10px;
                    max-width:300px;
                    text-align:left;
                  }

                  .td-text-left{
                      text-align:left;
                      padding-left: 30px;
                  }

                  .infoColumn {
                    display: flex;
                    flex-direction: row;
                    color: black;
                    font-weight: bold;
                    justify-content: space-evenly;
                    background-color: white;
                    align-items: center;
                    border-radius: 0 0 20px 20px ;
                  }

                  .texto-salida{
                    display:flex;
                    text-align:center;
                    flex-direction:column;
                    font-size:large;
                  }


                  .salida-neta{
                    color: #D33E3E;
                    font-size:x-large;
                  }

                  </style>

                </head>

                <body onload="window.print();window.close()">${printContents}</body>
              </html>`
    );
    popupWin!.document.close();
  }

  //Reporte de ingresos por RANGOS TODAS LAS ENTRADAS
  ReporteRangoIO(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlSummaryRangeGeneralIO')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
              <html>
              <title >Ingresos en efectivo - ${this.pipe.transform(this.currentDate, 'longDate')!.toUpperCase()} </title>

              <head>
                  <style>

                  * {
                  margin: 0;
                  padding: 0;
                  -webkit-print-color-adjust: exact;
                  color-adjust: exact;
                }

                body {
                  font-family: Roboto, "Helvetica Neue", sans-serif;
                }

                  .Contenedor-Impresion-Report-Efectivo{
                    text-align: center;
                    margin: 10px;
                    text-align: center;
                  }

                  .encabezado{
                    border-radius: 5px;
                    background-color: #ebdfdf;
                    flex-direction:column;
                    margin:1%
                  }

                    .tiempo-impresion{
                      display:flex;
                      text-align:right;
                      width:100%;
                      justify-content: flex-end;
                      background-color: transparent;
                      padding-right:15px;
                    }

                    .margen{
                      margin: 1%;
                      flex-direction:row;
                      padding-bottom:10px;
                      display:flex;
                      width:100%
                    }

                    h2 {
                      padding: 10px;
                      color: BLACK;
                      font-weight: bold;
                      text-align: center;
                      margin-bottom: 0;
                    }

                    h4 {
                      padding: 10px;
                      background-color: #D33E3E;
                      color: white;
                      font-weight: bold;
                      text-align: center;
                      margin-bottom: 0;
                  }

                    .format-Table{
                    width: 100%;
                    border-collapse: collapse;
                    border: solid #5E4F4F 5px;
                    }

                    thead {
                    background-color: #5E4F4F;
                    border: solid #5E4F4F 5px;
                    color: white;
                    font-weight: bold;
                    font-size: 1rem;
                    text-align: center;
                  }

                  tbody {
                    text-align: center;
                    font-size: 1rem;
                    border: solid #5E4F4F 5px;
                  }

                  tbody tr:nth-child(even) {
                    background-color: #ebebeb;
                  }

                  td {
                    padding: 10px;
                    max-width: 400px;
                    text-align:left;

                  }

                  .td-text-left{
                      text-align:left;
                      padding-left: 30px;
                  }

                  .infoColumn {
                    display: flex;
                    flex-direction: row;
                    color: black;
                    font-weight: bold;
                    justify-content: space-evenly;
                    background-color: white;
                    align-items: center;
                    border-radius: 0 0 20px 20px ;
                  }

                  .transferCount {
                    display: flex;
                    flex-direction: column;
                  }

                  .transfTitle {
                    background-color: #D33E3E;
                    color: white;
                    text-align: center;
                  }

                  .transfCounter {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 20px 0 20px;
                  }

                  .texto-salida{
                    display:flex;
                    text-align:center;
                    flex-direction:column;
                    font-size:large;
                  }


                  .salida-neta{
                    color: #D33E3E;
                    font-size:x-large;
                  }

                  </style>

                </head>

                <body onload="window.print();window.close()">${printContents}</body>
              </html>`
    );
    popupWin!.document.close();
  }

  responsables = ["Marco Antonio Flores Rivas", "Carlos Betancourt Rodriguez","Fernando Monroy Aleman"];
  assign = "";

  async printOptions() {

    let value = await Swal.fire({
      title: '  <FONT COLOR="#000000"> <strong> ¡Seleccione un responsable para entrega del dinero en caja! </strong> </FONT> ',
      width: auto,
      showConfirmButton: true,
      showDenyButton: true,
      showCancelButton: true,


      inputPlaceholder: 'Seleccione un responsable',
      input: 'select',
      inputOptions: this.responsables,

      html: 'Seleccione un responsable y un tipo de reporte ' +
        '<br>se le redireccionará al <strong> <u>reporte</u> </strong> deseado.' +
        '<br><br><b>  De click en alguna opción para continuar </b>',

      confirmButtonText: 'EFECTIVO',
      denyButtonText: `TODAS`,
      cancelButtonText: `CANCELAR`,
      buttonsStyling: false, //Permite customizar los botones

      customClass: {
        actions: 'sweetActions',
        confirmButton: 'm19Btn',
        cancelButton: 'm19AltBtn',
        denyButton: 'm19InvBtn',
        input: 'sweetInput'
      },

      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === '') {
            resolve('Debes elegir una opción de la lista')
          } else {
            this.assign = this.responsables[value];
            this.getAllRatificationPayments(this.formDateSelector.value.dateSimple);
            resolve('');
          }

        })

      }

    }).then((result) => {

      this.contrlCommission.getAllEmployeesService().subscribe(
        res => {
          if (result.isConfirmed) {
            this.ReporteIOEfectivo();
            result.dismiss
          } else if (result.isDenied) {
            this.ReporteIOEntradasTotales();
            result.dismiss
          } else if (result.isDismissed) { result.dismiss }
        }
      )

    })
    //console.log(value)


  }

  async printOptionsRange() {
    Swal.fire({
      title: '  <FONT COLOR="#000000"> <strong> ¡Guardar reporte! </strong> </FONT> ',
      width: auto,
      icon: 'info',
      iconColor: '#000',

      focusConfirm: false,
      showConfirmButton: true,
      showDenyButton: true,
      showCancelButton: true,

      buttonsStyling: false, //Permite customizar los botones

      customClass: {
        actions: 'sweetActions',
        confirmButton: 'm19Btn',
        cancelButton: 'm19AltBtn',
        denyButton: 'm19InvBtn',
        input: 'sweetInput'
      },


      html: 'Al clickear en una de las opciones en la parte inferior' +
        '<br>se le redireccionará al <strong> <u>reporte</u> </strong> deseado.' +
        '<br><br><b>  De click en alguna opción para continuar </b>',

      confirmButtonText: 'Solo EFECTIVO',
      denyButtonText: `TODAS las entradas`,
      cancelButtonText: `Cancelar`
    }).then((result) => {
      if (result.isConfirmed) {
        this.ReporteRangoIOEfectivo();
      } else if (result.isDenied) {
        this.ReporteRangoIO();
      } else if (result.isDismissed) {
      }
    })
  }

  //--------------------------------------------- METODOS UTILES --------------------------------------------
  camposReset() {
    this.CashOutflowsFormGroup.controls['quantity'].setValue(null)
    this.CashOutflowsFormGroup.controls['registrationDate'].setValue(null)
    this.CashOutflowsFormGroup.controls['reason'].setValue(null)
    this.CashOutflowsFormGroup.controls['requestor'].setValue(null)
  }

  getUser() {
    this.decode = jwtDecode(localStorage.getItem("token") || "")
    return this.decode['cliente']
  }



}
