<mat-card>
    <mat-card-content>
        <form class="row mt-3" [formGroup]="supplierFormGroup" (ngSubmit)="updateSupplier()" onKeyPress="if(event.keyCode == 13) event.returnValue = false;" style="display: flex; flex-direction: column;">
            <h5 class="col-12 pb-2">Modificar Proveedor</h5>

            <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Nombre</mat-label>
                <input matInput type="text" formControlName="suppliername" min="1" required>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>RFC</mat-label>
                <input matInput type="text" formControlName="RFC" min="1" required>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Dirección</mat-label>
                <input matInput type="text" formControlName="direction" min="1" required>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Teléfono</mat-label>
                <input matInput type="text" formControlName="phone" min="1" required>
            </mat-form-field>

            <div class="col-12 text-right mt-3">
                <button class="btn btn-m19" type="submit" [disabled]="!supplierFormGroup.valid">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>