<!-- Información de partidas y material asignado -->
<mat-dialog-content>
    <!-- Condicional, si el número de partidas es mayor a cero se imprime la información -->
    <html>
        <div *ngIf="departures.length>0">
            <table style=" width: 100%;">
                <thead style="text-align: center; font-size: 1.5rem;">
                    <tr>
                        <th colspan="2" style="border-bottom: solid black; align-content: center; ">RESÚMEN DE PRESUPUESTO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style="text-align: center; height: 50px;">
                        <td>FECHA: {{date | date: 'dd-MMM-yyyy'}}.</td>
                        <td>PRESUPUESTO: No. {{budgetNum}}.</td>
                    </tr>
                    <tr style="text-align: center;">
                        <td>OBRA: {{project}}.</td>
                        <td>MODULO: {{module}}.</td>
                        <td style="color: green; ">TOTAL: ${{totalbudget}}.</td> 
                    </tr>
                </tbody>
            </table>
    
            <br>
    
             <!-- ngFor para recorrer todas las partidas conseguidas -->
            <mat-accordion>
                <mat-expansion-panel *ngFor="let departure of departures" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title><b>
                            {{"Partida número " + departure.departurenumber + ": " +departure.departurename}}
                        </b></mat-panel-title>
                        <mat-panel-description><b>
                            {{departure.descriptions}}
                        </b></mat-panel-description>
                    </mat-expansion-panel-header>
                    <table class="table table-striped mt-3 animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr style="text-align: center;">
                                <th scope="col">Cantidad</th>
                                <th scope="col">Unidad</th>
                                <th scope="col">Descripción del destajo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style="text-align: center;" *ngFor="let piecework of pieceworks">
                                <td *ngIf="piecework.iddeparture == departure.iddeparture && piecework.descriptions == departure.descriptions">{{piecework.amount}}</td>
                                <td *ngIf="piecework.iddeparture == departure.iddeparture && piecework.descriptions == departure.descriptions">{{piecework.unit}}</td>
                                <td *ngIf="piecework.iddeparture == departure.iddeparture && piecework.descriptions == departure.descriptions">{{piecework.typepw}}</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </html>
    </mat-dialog-content>
    
    <!-- Si el número de partidas es nulo entonces no se tienen partidas registradas de ese presupuesto-->
    <h3 *ngIf="departures.length==0">{{"Este presupuesto no tiene partidas ni pieceworks registrados"}}.</h3>
    
    <!-- Botón para cerrar el dialog -->
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cerrar</button>
    </mat-dialog-actions>