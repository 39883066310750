import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapsService } from 'src/app/services/maps.service';

@Component({
  selector: 'app-dialog-sketchmap-visual',
  templateUrl: './dialog-sketchmap-visual.component.html',
  styleUrls: ['./dialog-sketchmap-visual.component.scss']
})
export class DialogSketchmapVisualComponent implements OnInit {

  link:any


  constructor(
    private dialogRef: MatDialogRef<DialogSketchmapVisualComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private maps: MapsService
  ) { }

  ngOnInit(): void {
    console.log("Dentro del dialog",this.data)
      this.selectLinkSketchmap()

  }

      //Consultar estado civil
      async selectLinkSketchmap() {
        this.maps.getSketchmapLink(this.data.idinmueble).subscribe(
          res => {
            this.link = res;
            console.log(this.link)
          },
          err => console.log(err)
        );
      }
    





}
 