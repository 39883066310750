<div class="container-fluid mt-3">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">

            <div class="projectButtons">
                <button class="m19AltBtn" (click)="getAllRecessions()">
                    Todos
                    <mat-icon>apps</mat-icon>
                </button>
                <button *ngFor="let p of projects" class="m19AltBtn" (click)="filterByProject(p.idProject)">
                    {{p.name}}
                    <mat-icon>apartment</mat-icon>
                </button>
            </div>

            <div *ngIf="recessions != 'Sin seleccionar' && recessions.length>0" style="color: #5E4F4F; font-size: 1rem; display: flex; justify-content: space-between; font-weight: bold;">
                <p>{{recessions[0].name.toUpperCase()}} <mat-icon style="color:#D33E3E;">apartment</mat-icon></p>
                <p style="text-align: right;">VENTAS RESCINDIDAS: {{recessions.length}} <mat-icon style="color:#D33E3E;">sell</mat-icon></p>
            </div>

            <div style="width: 100%; display: flex; justify-content: center;">
                <mat-form-field appearance="outline" class="filter" *ngIf="recessions!='Sin seleccionar'">
                    <mat-label>Buscar inmueble/cliente</mat-label>
                    <input matInput type="text" placeholder="Ingresar el código de inmueble rescindido" [(ngModel)]="filterRecessions" name="filterRecessions">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>

            <div *ngIf="recessions.length==0 || recessions=='Sin seleccionar'"
                style="display: flex; flex-direction: column; height: 40vh; justify-content: center; align-items: center;">
                <h2 *ngIf="recessions == 'Sin seleccionar'">Seleccione un
                    proyecto para visualizar sus rescisiones</h2>
                <p *ngIf="recessions == 'Sin seleccionar'">
                    <mat-icon style="font-size: 2rem;">search</mat-icon>
                </p>
                <h2 *ngIf="recessions.length==0">Este proyecto no tiene
                    resciciones registradas</h2>
                <p *ngIf="recessions.length==0">
                    <mat-icon style="font-size: 2rem;">sentiment_very_satisfied</mat-icon>
                </p>
            </div>

            <div class="cuadriculaReq" *ngIf="recessions!='Sin seleccionar'">
                <div class="tarjeta" *ngFor="let recession of recessions | filterRecessions: filterRecessions">
                    <div class="title">
                        <b>{{recession.fk_property.split("-")[0]}}</b>
                    </div>
                    <p style="text-align: center; font-size: 0.9rem;"><b>ID de Contrato: </b>{{recession.idContract}}
                    </p>
                    <p style="text-align: center; font-size: 0.9rem;"><b>Cliente: </b>{{recession.fk_customer}}</p>
                    <p style="text-align: center; font-size: 0.9rem;"><b>Coordinador de venta:
                        </b>{{recession.fk_coordinator}}</p>
                    <p style="text-align: center; font-size: 0.9rem;"><b>Fecha de rescisión: </b>{{recession.fechaDato}}
                    </p>
                    <div class="info">
                        <button class="btn btn-m19-info" title="Ver detalle" mat-icon-button (click)="selectRecession(recession.fk_property.split('-')[0], recession.idUser)">
                            <mat-icon>info</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>