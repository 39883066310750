import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { ReportService } from 'src/app/services/report.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from 'src/app/services/payment.service';
import { TotalPagadosService } from 'src/app/services/totalPagados.service';


@Component({
  selector: 'app-wallet-by-week',
  templateUrl: './wallet-by-week.component.html',
  styleUrls: ['./wallet-by-week.component.scss']
})
export class WalletByWeekComponent implements OnInit {

  //Manejo de información res API, arreglos con carga de datos de las consultas a los servicios que conectan con la API
  desarrolloReporteHis:any=[]
  desarrollo: any;
  fechaCarHis:any=[]
  proyectoCartera:any=[]
  dedHisRecuperacion:any=[]
  carteraHisR:any=[]
  carteraHisA:any=[]
  carteraHisV:any=[]
  carteraHisAz:any=[]
  conteoReporteHis:any=[]
  montos:any=[];
  montosListado:any=[];
  carteraInmu:any=[]
  conteoReporteHisGen:any=[]
  graficoRecuperacion:any=[]
  ReporteHis:any=[]
  ReporteHisCol:any=[]
  tabRecSem:any=[]
  payments: any = [];

  //Variables boleanas para ocultar modulos
  TarCar=true
  TarEst=false
  ListM = false
  ListG = true
  VP = false
  RH=true
  X = false
  Tv=false

 //Banderas de impresión de datos particulares de acuerdo al modulo
  desEst(){
    return this.TarEst=false,this.TarCar=true
  }
  desG() {
    return this.VP = false, this.X = true
  }
  desGHis() {
    return this.RH = false, this.Tv = true
  }
  desGHisV() {
    return this.RH = true, this.Tv = false
  }
  desX() {
    return this.X = false, this.VP = true, this.ListG=false, this.ListM=true
  }
  inmu() {
    return this.X = false,this.VP=true
  }

  //Nomenclatura inmueble
  M="Manzana No."
  L="Lote No."
  T="Torre No."
  D="Departamento No."
 
  //Grafico y control de pagos
  graficoActual=true
  graficoAnual=false
  limite = -4
  tolerancia = -1
  tolerancia2 = -2
  tolerancia3 = -3

  limiteRe = 4
  toleranciaRe = 1
  tolerancia2Re = 2
  tolerancia3Re = 3

  // Configuraciones de graficos
    public pieChartOptions: ChartOptions = {
      responsive: true,
      legend: {
        position: 'top',
        display:true,
        fullWidth:true
      },
      showLines:true,
      tooltips:{
        enabled:true,
        caretSize:0,
        displayColors:true,
        titleFontSize:20,
        bodyFontSize:20
      },
      spanGaps:true,
      plugins: {
        datalabels: {
          formatter: (value: any, ctx: any) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            return label;
          },
        },
      }
    };
    public piePruebaOptions: ChartDataSets={
      showLine:true
    }
    public pieChartLabels: Label[] = [['Rescisión'], ['Emergente 2-3 atrasos'],['Un solo atraso'],['Sin atraso']];    
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = true;
    public pieChartPlugins = [pluginDataLabels];
    public barChartPlugins = [pluginDataLabels];
    public pieChartColors = [
      {
        backgroundColor: ['rgb(211, 25, 16 )', 'rgb(242, 228, 12)','rgb(39, 162, 11 )','rgb(11, 114, 162)'],
      },
    ];
    public barChartColors = [
      {
        backgroundColor: ['#60371E', '#A6341B','#F9C743','#C7C8C4'],
      },
    ];

  //barras
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;



  constructor(public modal:NgbModal,private toastr: ToastrService,private reportService: ReportService, private paymentService: PaymentService, private totalPagados: TotalPagadosService) { }

  //Metodo de carga de metodos al momento de la ejecución
  ngOnInit(): void {
   this.proyectosCar();
   this.FechasCarHistorico();
   this.reporteHistorico();
   this.reporteCarteraHistoricoRecuperacion();
   this.reporteCarteraHistoricoConteo();
   this.reporteCarteraHistoricoGraficoSumRecuperacion();
  }

  //Consulta de datos desde el servicio que consulta a la API
  reporteInmuCartera(carInmu:string) {
    this.reportService.reporteCarteraInmueble(carInmu).subscribe(
      res => {
        
        
        this.carteraInmu = res;
      },
      err => console.log(err)
    );
  }


  //Manejo de reporte de pagos 
  async slectPayments(cliente:string) {
    this.paymentService.selectPayments(cliente).subscribe(
      res => {
        
        this.payments = res;
      },
      err => console.log(err)
    );
  }
  selectTotales() {
    this.totalPagados.totalPagados().subscribe(
      res => {
        
        this.montos = res;
      },
      err => console.log(err)
    );
  }
  //Tabulado de recuperación semanal
  tabuladoRecuperacionS1() {
    this.reportService.tabuladoRecuperacionS1().subscribe(
      res => {
        
        this.tabRecSem = res;
      },
      err => console.log(err)
    );
  }
  tabuladoRecuperacionS2() {
    this.reportService.tabuladoRecuperacionS2().subscribe(
      res => {
        
        this.tabRecSem = res;
      },
      err => console.log(err)
    );
  }
  tabuladoRecuperacionS3() {
    this.reportService.tabuladoRecuperacionS3().subscribe(
      res => {
        
        this.tabRecSem = res;
      },
      err => console.log(err)
    );
  }
  tabuladoRecuperacionS4() {
    this.reportService.tabuladoRecuperacionS4().subscribe(
      res => {
        
        this.tabRecSem = res;
      },
      err => console.log(err)
    );
  }

  //Fechas y listado de proyectos dinamico

  FechasCarHistorico() {
    this.reportService.FechasCarteraHistorico().subscribe(
      res => {
        
        this.fechaCarHis = res;
      },
      err => console.log(err)
    );
  }
  proyectosCar() {
    this.reportService.reporteProyecto().subscribe(
      res => {                
        this.proyectoCartera = res;
      },
      err => console.log(err)
    );
  }  
  //Manejo de reporte de historico cartera por desarrollo
  reporteHistoricoDesarrollo(carHisDes:string) {
    this.reportService.reporteCarteraHistoricoDesarrollo(carHisDes).subscribe(
      res => {
        this.desarrolloReporteHis = res;
        this.desarrollo = this.desarrolloReporteHis[0].name;
      },
      err => console.log(err)
    );
  }  
  reporteCarteraHistoricoRecuperacionDesarrollo(carHisRecDes:string) {
    this.reportService.reporteCarteraHistoricoRecuperacionDesarrollo(carHisRecDes).subscribe(
      res => {
        this.desarrolloReporteHis = res;        
      },
      err => console.log(err)
    );
  }

  reporteCarteraHistoricoRecuperacionAcDesarrollo(carHisRecDesAc:string) {
    this.reportService.reporteCarteraHistoricoRecuperacionAcDesarrollo(carHisRecDesAc).subscribe(
      res => {
        this.desarrolloReporteHis = res;        
      },      
      err => console.log(err)
    );
  }
  reporteCarteraHistoricoConteoDesarrollo(carHisRecDesCont:string) {
    this.reportService.reporteCarteraHistoricoConteoDesarrollo(carHisRecDesCont).subscribe(
      res => {
        this.conteoReporteHis = res;
      },
      err => console.log(err)
    );
  }
  reporteCarteraHistoricoDesarrolloRojo(carHisDesRojo:string) {
    this.reportService.reporteCarteraHistoricoDesarrolloRojo(carHisDesRojo).subscribe(
      res => {
        this.desarrolloReporteHis = res;
      },
      err => console.log(err)
    );
  }
  reporteCarteraHistoricoDesarrolloAmarillo(carHisDesAmarillo:string) {
    this.reportService.reporteCarteraHistoricoDesarrolloAmarillo(carHisDesAmarillo).subscribe(
      res => {
        this.desarrolloReporteHis = res;
      },
      err => console.log(err)
    );
  }
  reporteCarteraHistoricoDesarrolloVerde(carHisDesVerde:string) {
    this.reportService.reporteCarteraHistoricoDesarrolloVerde(carHisDesVerde).subscribe(
      res => {
        this.desarrolloReporteHis = res;
      },
      err => console.log(err)
    );
  }
  reporteCarteraHistoricoDesarrolloAzul(carHisDesAzul:string) {
    this.reportService.reporteCarteraHistoricoDesarrolloAzul(carHisDesAzul).subscribe(
      res => {
        this.desarrolloReporteHis = res;
      },
      err => console.log(err)
    );
  }

  //Manejo de reporte de historico cartera general
  reporteHistorico() {
    this.reportService.reporteCarteraHistorico().subscribe(
      res => {
        this.ReporteHis = res;
      },
      err => console.log(err)
    );
  }  
  reporteCarteraHistoricoRecuperacion() {
    this.reportService.reporteCarteraHistoricoRecuperacion().subscribe(
      res => {
        this.ReporteHis = res;
      },
      err => console.log(err)
    );
  }
  reporteCarteraHistoricoRecuperacionAc() {
    this.reportService.reporteCarteraHistoricoRecuperacionAc().subscribe(
      res => {
        this.ReporteHis = res;
      },
      err => console.log(err)
    );
  }
  reporteCarteraHistoricoConteo() {
    this.reportService.reporteCarteraHistoricoConteo().subscribe(
      res => {
        this.conteoReporteHisGen = res;
      },
      err => console.log(err)
    );
  }
  reporteCarteraHistoricoRojo() {
    this.reportService.reporteCarteraHistoricoRojo().subscribe(
      res => {
        this.ReporteHis = res;
      },
      err => console.log(err)
    );
  }
  reporteCarteraHistoricoAmarillo() {
    this.reportService.reporteCarteraHistoricoAmarillo().subscribe(
      res => {
        this.ReporteHis = res;
      },
      err => console.log(err)
    );
  }
  reporteCarteraHistoricoVerde() {
    this.reportService.reporteCarteraHistoricoVerde().subscribe(
      res => {
        this.ReporteHis = res;
      },
      err => console.log(err)
    );
  }
  reporteCarteraHistoricoAzul() {
    this.reportService.reporteCarteraHistoricoAzul().subscribe(
      res => {
        this.ReporteHis = res;
      },
      err => console.log(err)
    );
  }
  reporteCarteraHistoricoGraficoSumRecuperacion() {
    this.reportService.reporteCarteraHistoricoGraficoSumRecuperacion().subscribe(
      res => {
        this.graficoRecuperacion = res;
      },
      err => console.log(err)
    );
  }  
  reporteRecuperacionColores() {
    this.reportService.reporteRecuperacionColores().subscribe(
      res => {
        this.ReporteHisCol = res;
      },
      err => console.log(err)
    );
  }

}