//=========================================================================================================
//Importación de componentes necesarios para esta pantalla
//=========================================================================================================
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PaymentService } from 'src/app/services/payment.service';
import { PdfMakerComponent } from 'src/app/utils/pdf-maker/pdf-maker.component';

@Component({
  selector: 'app-credit-payments',
  templateUrl: './credit-payments.component.html',
  styleUrls: ['./credit-payments.component.scss']
})
export class CreditPaymentsComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<CreditPaymentsComponent>,
    private paymentService: PaymentService,
    public ticket: PdfMakerComponent,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  async printTicket(fkCliente: any, folioPago: any, fechaPago: any, cantidadAbono: any, metodoPago: any, concepto: any, useremail: any) {
    this.paymentService.getTotalsNCPayments(fkCliente).subscribe(
      res => {
        this.paymentService.getTotalsNCPayments(fkCliente).subscribe(
          res => {
            this.ticket.ticketNCGenerator(fechaPago, this.data.cordinator, folioPago, fkCliente.substring(2, 3), fkCliente.substring(5, 6), fkCliente.substring(3, 5), fkCliente.substring(6, 8), concepto, metodoPago, cantidadAbono, fkCliente.substring(0, 2), this.data.customer, useremail, res.NC, res.NCNR);
          }
        );
      }
    );
  }

}