import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Property } from 'src/app/models/Property';

@Component({
  selector: 'app-dialog-property',
  templateUrl: './dialog-property.component.html',
  styleUrls: ['./dialog-property.component.scss']
})
export class DialogPropertyComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DialogPropertyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Property
  ) { }

  ngOnInit(): void {
  }

}
