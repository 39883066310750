import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
//import internal from 'stream';

@Injectable({
  providedIn: 'root'
})
export class DepaPieceService {

  private url = 'http://localhost:3000/api';

  constructor(private http: HttpClient) { }

  //Registrar relación de destajos
  insertLinks(week: Number, dep: Number, pie: Number, prd: Number, emp: String, amo: Number, uni: Number, tot: Number, use: String, mon:String,tue:String,wed:String,thu:String,fri:String,sat:String,sun:String ): Observable<any> {
    console.log(mon,tue,wed,thu,fri,sat,sun)
    return this.http.post(`${environment.URLServer}/depapiece/insert`, [week, dep, pie, prd, emp, amo, uni, tot, use, mon, tue,wed,thu,fri,sat,sun]);
  }

  getPerWeek(week: any): Observable<any> {
    return this.http.get(`${environment.URLServer}/depapiece/getPerWeek/${week}`);
  }

  getPieceworksDetail(id: number, idpc:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/depapiece/getPieceworksDetail/${id}/${idpc}`);
  }

  //Consultar todos los links entre destajos y partidas
  selectPieceworks(): Observable<any> {
    return this.http.get(`${environment.URLServer}/depapiece/selectLinks`);
  }

  selectDepasWithPieces(id: Number): Observable<any> {
    return this.http.get(`${environment.URLServer}/depapiece/selectDepasWithPieces/` + id);
  }

  selectPieceworksByReq(id: Number): Observable<any> {
    return this.http.get(`${environment.URLServer}/depapiece/searchLinksByReq/` + id);
  }

  //Consultar un destajo
  getPieceInfo(): Observable<any> {
    return this.http.get(`${environment.URLServer}/depapiece/getPieceInfo`);
  }

  //Actualizar un destajo
  updateDepaPiece(id: any, p: any): Observable<any> {
    return this.http.put(`${environment.URLServer}/depapiece/updateDepaPiece/${id}`, p);
  }

  searchByWeek(id: Number): Observable<any> {
    return this.http.get(`${environment.URLServer}/depapiece/searchByWeek/` + id);
  }

  searchByModule(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/depapiece/searchByModule/${id}`);
  }

  getWeeks(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/depapiece/getWeeks/${id}`);
  }
}