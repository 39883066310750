import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Project } from '../models/Project';
import { Project_property } from '../models/Project_property';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {


  private url = 'http://localhost:3000/api';


  constructor(private http: HttpClient) { }

  /*PROJECTS*/
  //Consultar todos los proyectos
  selectProjects() {
    return this.http.get(`${environment.URLServer}/projects/`);
  }

  //Consultar el proyecto especificado
  selectProject(nomenclature: string) : Observable <any> {
    return this.http.get(`${environment.URLServer}/projects/select_one/${nomenclature}`);
  } 

  //Registrar proyecto
  createProject(project: Project) {
    return this.http.post(`${environment.URLServer}/projects`, project);
  }

  //Modificar proyecto
  updateProject(nomenclature: string, update: Project): Observable<Project> {
    return this.http.put(`${environment.URLServer}/projects/${nomenclature}`, update);
  }

  //Eliminar inmueble
  deleteProject(nomenclature: string) {
    return this.http.delete(`${environment.URLServer}/projects/${nomenclature}`);
  }

  /*PROJECTS PROPERTIES*/
  select_projects_properties(){
    return this.http.get(`${environment.URLServer}/projects/projects_properties`);
  }

  create_projects_properties(project_property: Project_property) {
    return this.http.post(`${environment.URLServer}/projects/projects_properties`, project_property);
  }

  /*STATUS PROJECTS*/
  //Consultar todos los tipos de status de un proyecto
  select_status(){
    return this.http.get(`${environment.URLServer}/projects/status/`);
  }
}
