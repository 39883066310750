<mat-dialog-content>
    <h2>Pagos en crédito</h2>
    <table class="table table-striped animated fadeIn faster">
        <thead class="thead-m19">
            <tr>
                <th scope="col">No.</th>
                <th scope="col">Folio</th>
                <th scope="col">Fecha</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Método de pago</th>
                <th scope="col">Concepto</th>
                <th scope="col" style="text-align: center;">Comprobante</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let payment of data.payments; let i=index;">
                <th>{{i+1}}</th>
                <th>{{payment.folioPago}}</th>
                <td>{{payment.fechaPago | date: 'dd-MM-yyyy'}}</td>
                <td>{{(payment.cantidadAbono | currency:'CAD' : '$ ')}}</td>
                <td>{{payment.metodoPago}}</td>
                <td>{{payment.concepto}}</td>
                <td style="text-align: center;"><button class="btnPrint" (click)="printTicket(payment.fkCliente, payment.folioPago, payment.fechaPago, payment.cantidadAbono, payment.metodoPago, payment.concepto, payment.useremail)"><mat-icon>receipt</mat-icon></button></td>
            </tr>
        </tbody>
    </table>
</mat-dialog-content>

<!-- Botón para cerrar el dialog -->
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>