import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Departure } from '../models/Departure';

@Injectable({
  providedIn: 'root'
})
export class DepartureService {

  private url = 'http://localhost:3000/api';

  constructor(private http: HttpClient) { }

  //Consultar partidas por número de partida
  selectDepartureByID(num:number): Observable<any>{
    return this.http.get(`${environment.URLServer}/departures/searchbyid/`+num);
  }

   //Consultar partidas por número de partida
   searchDepartureByNumber(num:number): Observable<any>{
    return this.http.get(`${environment.URLServer}/departures/searchbynumber/`+num);
  }

  //Insertar partida
  insertDeparture(dep: Departure) : Observable<any>{
    return this.http.post(`${environment.URLServer}/departures/insert`, dep);
  }

  //Buscar todas las partidas registradas
  selectDepartures(): Observable<any>{
    return this.http.get(`${environment.URLServer}/departures/selectAll`);
  }

  //Consultar nombres de las partidas activas por modulo
  getNamesOfDepartures(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/departures/getNamesOfDepartures/${id}`);
  }

  //Consultar nombres de las partidas activas
  getDepartureList(): Observable<any> {
    return this.http.get(`${environment.URLServer}/departures/getDepartureList`);
  }

  //Eliminar una partida de la base de datos
  deleteDeparture(id: Number): Observable<any>{
    return this.http.put(`${environment.URLServer}/departures/deletedeparture`,[id]);
  }

  //Actualizar una partida
  updateDeparture(id: number, dep: Departure): Observable<any>{
    return this.http.put(`${environment.URLServer}/departures/updatedeparture/${id}`,dep);
  }

}