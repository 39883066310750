import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//=========================================================================================================
//Importación de librerías para componentes visuales
//=========================================================================================================
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SweetAlertComponent } from '../../../utils/sweet-alert/sweet-alert.component';

import { LeadsService } from 'src/app/services/leads.service';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-generate-comment',
  templateUrl: './generate-comment.component.html',
  styleUrls: ['./generate-comment.component.scss']
})
export class GenerateCommentComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<GenerateCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private leadsService: LeadsService,
    //===================================================
    //Declaración de complementos visuales
    private _formBuilder: FormBuilder,
    private sweet: SweetAlertComponent,
  ) { }

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  //==========================================================================================================
  //Objeto constructor del formulario utilizado en la pantalla de requisición, se agrega el código 
  //", Validators.required" para señalar que es un valor que no debe estar vacío
  //==========================================================================================================
  commentsFormGroup: FormGroup = this._formBuilder.group({
    idaction: [],
    action: ["Comentario"],
    responsable: [this.getUser()],
    fk_leads: [this.data],
    comments: [, Validators.required]
  });

  ngOnInit(): void {  }

  writeComment() {
    this.leadsService.leadsLogWrite(this.commentsFormGroup.value).subscribe(
      res => {
        this.sweet.AlertTime("center", "success", "Comentario realizado con éxito!", false, 2000);
        this.dialogRef.close();
      }
    );
  }

  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return(this.decode.fkuser);
  }
}