<h1>{{data.codeProperty}}</h1>

<mat-divider></mat-divider>
<div>

    <mat-dialog-content class="mt-4">

        <form class="row mt-3" [formGroup]="recessionFormGroup">
            <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Fecha de rescisión</mat-label>
                <input matInput type="date" formControlName="dateRecession" required>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Motivo de rescisión</mat-label>
                <input matInput #note maxlength="100" type="text" formControlName="reasonRecession" placeholder="Incumplimiento de contrato" required>
                <mat-hint align="end">{{note.value.length}} / 100</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Nota de rescisión</mat-label>
                <input matInput #note maxlength="100" type="text" formControlName="notesRecession">
                <mat-hint align="end">{{note.value.length}} / 100</mat-hint>
            </mat-form-field>
        </form>

    </mat-dialog-content>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
    <button mat-button class="btn btn-m19" (click)="rescindContract(data.codeProperty)">Rescindir contrato</button>
</mat-dialog-actions>