<h1>{{data.codeProperty}}</h1>

<mat-divider></mat-divider>

<mat-dialog-content>
    <form class="row mt-3" [formGroup]="propertyFormGroup">

        <mat-form-field appearance="outline" class="col-md-2">
            <mat-label>Valor total</mat-label>
            <input matInput type="number" formControlName="amount" [(ngModel)]="property.amount" >
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-2" *ngIf="data.codeProperty.substring(0,2)=='LI' && getUser()==1020">
            <mat-label>Anticipo</mat-label>
            <input matInput type="number" formControlName="advancePayment" [(ngModel)]="property.advancePayment" >
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-2" *ngIf="data.codeProperty.substring(0,2)=='LI' && getUser()==1020">
            <mat-label>Pago mensual</mat-label>
            <input matInput type="number" formControlName="monthlyPayment" [(ngModel)]="property.monthlyPayment">
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-2" *ngIf="data.codeProperty.substring(0,2)!='LI' || getUser()!=1020">
            <mat-label>Anticipo</mat-label>
            <input matInput type="number" formControlName="advancePayment" [(ngModel)]="property.advancePayment"  readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-2" *ngIf="data.codeProperty.substring(0,2)!='LI' || getUser()!=1020">
            <mat-label>Pago mensual</mat-label>
            <input matInput type="number" formControlName="monthlyPayment" [(ngModel)]="property.monthlyPayment" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>Superficie (m<sup>2</sup>)</mat-label>
            <input matInput type="text" formControlName="area" [(ngModel)]="property.area" >
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>Estatus</mat-label>
            <mat-select formControlName="fk_status" [(ngModel)]="property.fk_status" >
                <mat-option *ngFor="let status of status" [value]="status.idStatus">
                    {{status.description}}
                </mat-option>
            </mat-select>
        </mat-form-field>
 
        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Nota</mat-label>
            <input matInput type="text" formControlName="description" [(ngModel)]="property.description">
            <mat-icon matSuffix>notes</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>Coordenada X</mat-label>
            <input matInput type="text" formControlName="coordinateX" [(ngModel)]="property.coordinateX" >
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>Coordenada Y</mat-label>
            <input matInput type="text" formControlName="coordinateY" [(ngModel)]="property.coordinateY" >
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Colindancia noreste</mat-label>
            <input matInput #colindancia_noreste maxlength="100" type="text"
                formControlName="northeasBorder" [(ngModel)]="property.northeasBorder" >
            <mat-hint align="end">{{colindancia_noreste.value.length}} / 100</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Colindancia suroeste</mat-label>
            <input matInput #colindancia_suroeste maxlength="100" type="text"
                formControlName="southwestBorder" [(ngModel)]="property.southwestBorder" >
            <mat-hint align="end">{{colindancia_suroeste.value.length}} / 100</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Colindancia noroeste</mat-label>
            <input matInput #colindancia_noroeste maxlength="100" type="text"
                formControlName="northwestBorder" [(ngModel)]="property.northwestBorder" >
            <mat-hint align="end">{{colindancia_noroeste.value.length}} / 100</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Colindancia sureste</mat-label>
            <input matInput #colindancia_sureste maxlength="100" type="text"
                formControlName="southeastBorder" [(ngModel)]="property.southeastBorder" >
            <mat-hint align="end">{{colindancia_sureste.value.length}} / 100</mat-hint>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
    <button mat-button class="btn btn-m19" (click)="updateProperty(property.codeProperty,property)">Guardar</button>
</mat-dialog-actions>