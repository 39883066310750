import { Component, OnInit } from '@angular/core';
import { Chart, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-account-balance',
  templateUrl: './account-balance.component.html',
  styleUrls: ['./account-balance.component.scss']
})
export class AccountBalanceComponent implements OnInit {

  constructor() { }

  //Autocompletado
  keyword = 'name';

  sal: any = [];

  property: string = '';

  ngOnInit(): void {
    let values = [100, 200,300];
    let labels = ["diego", "asael", "hernandez"];
    let colors = ["#101d3d","#101d3d","#101d3d"];
    this.chartConfig("comparativeChart", "doughnut", values, labels, colors);
  }

  selectCustomer(e: any) {
    this.property = e;
    console.log(this.property);
  }

  async chartConfig(canva: string, type: string, values: any, labels: any, colors: any) {
    //Referencia al objeto canva HTML al que se carga el gráfico
    const canvas = document.getElementById(canva) as HTMLCanvasElement;

    //Configuración de fuente de la gráfica
    Chart.defaults.global.defaultFontFamily = "Arial";
    Chart.defaults.global.defaultFontSize = 14;

    //Asignación de información
    const graphData = {
      labels: labels,
      datasets: [{
        data: values,
        backgroundColor: colors,
        hoverBackgroundColor: 'rgb(211, 62, 62)',
        borderColor: '#A8A8A8',
        borderWidth: 2
      }]
    };

    //Configuración básica de gráfico
    const chartOptions: ChartOptions = {
      responsive: true,
      legend: {
        display: false
      }
    };

    //Generación del gráfico
    const graph = new Chart(canvas, {
      type: type,
      data: graphData,
      options: chartOptions
    });
  }

}
