import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { noteReport } from '../models/Note';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService{

    constructor(private http: HttpClient) { }

    flujoMensualService(year: string, mes: string): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/flujoMensualroute/${year}/${mes}/`);
    }

    carteraInmueblePersonalService(inmu:string): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/cartera/${inmu}`);
    }

    //////////////////////////////////////////////////////////////////////////////

    createNotes(notes:noteReport){
      return this.http.post(`${environment.URLServer}/report`,notes);
    }

    getOneNote(id: any) {
      return this.http.get(`${environment.URLServer}/report/getOneNote/${id}`);
    }

    /*createNote(note:noteReport): Observable<any>{
        return this.http.post(`${environment.URLServer}/report`,note);
    }*/
    noteFirst(fkInm:string): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/noteFirst/${fkInm}`);
    }
    noteNew(fkCus:string): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/noteNew/${fkCus}`);
    }
    notes(codeProperty: string): Observable<any> {
      return this.http.get(`${environment.URLServer}/report/notes/${codeProperty}`);
    }
    getAgreement(code: string): Observable<any> {
      return this.http.get(`${environment.URLServer}/report/getAgreement/${code}`);
    }
    reporteFlujoDiario(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/flujodiario/`);
    }
    reporteIngresoDiario(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/ingresodiario/`);
    }

    reporteIngresoAnual(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/ingresoTotalAnual/`);
    }
    reporteIngresoRango(rango:string): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/ingresoTotalRango/${rango}`);
    }
    reporteIngresoAnualPasado(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/ingresoTotalAnualPasado/`);
    }
    reporteFlujoAnual(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/flujoAnual/`);
    }

    //Cartera por coordinador

    reporteCarteraCoordinador(coordinadorCartera:string): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/coordinadorCar/${coordinadorCartera}`);
    }
    listadoCoordinadorP(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/coordinador/`);
    }

    folio(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/folioAp/`);
    }
    folioCancelado(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/folioCan/`);
    }

    selectFolioCancelado(folCan:number): Observable<any>{
        return this.http.get(`${environment.URLServer}/report/cancelados/${folCan}`);
    }

    listadoCancelados(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/cancelados/`);
  }

    //--------------------------

    reporteIngresoMenEnDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoEnD/`);
    }
    reporteIngresoMenEnT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoEnT/`);
    }
    reporteIngresoMenFebDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoFeD/`);
    }
    reporteIngresoMenFebT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoFeT/`);
    }
    reporteIngresoMenMarDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoMaD/`);
    }
    reporteIngresoMenMarT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoMaT/`);
    }
    reporteIngresoMenAbrDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoAbD/`);
    }
    reporteIngresoMenAbrT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoAbT/`);
    }
    reporteIngresoMenMayDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoMayD/`);
    }
    reporteIngresoMenMayT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoMayT/`);
    }
    reporteIngresoMenJunDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoJunD/`);
    }
    reporteIngresoMenJunT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoJunT/`);
    }

    reporteIngresoMenJulDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoJulD/`);
    }
    reporteIngresoMenJulT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoJulT/`);
    }
    reporteIngresoMenAgDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoAgD/`);
    }
    reporteIngresoMenAgbT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoAgT/`);
    }
    reporteIngresoMenSepDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoSeD/`);
    }
    reporteIngresoMenSepT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoSeT/`);
    }
    reporteIngresoMenOctDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoOcD/`);
    }
    reporteIngresoMenOctT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoOcT/`);
    }
    reporteIngresoMenNovDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoNoD/`);
    }
    reporteIngresoMenNovT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoNoT/`);
    }
    reporteIngresoMenDicDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoDiD/`);
    }
    reporteIngresoMenDicT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteIngresoDiT/`);
    }

    reporteFlujoMenEnDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoEnD/`);
    }
    reporteFlujoMenEnT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoEnT/`);
    }
    reporteFlujoMenFebDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoFeD/`);
    }
    reporteFlujoMenFebT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoFeT/`);
    }
    reporteFlujoMenMarDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoMaD/`);
    }
    reporteFlujoMenMarT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoMaT/`);
    }
    reporteFlujoMenAbrDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoAbD/`);
    }
    reporteFlujoMenAbrT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoAbT/`);
    }
    reporteFlujoMenMayDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoMayD/`);
    }
    reporteFlujoMenMayT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoMayT/`);
    }
    reporteFlujoMenJunDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoJunD/`);
    }
    reporteFlujoMenJunT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoJunT/`);
    }

    reporteFlujoMenJulDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoJulD/`);
    }
    reporteFlujoMenJulT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoJulT/`);
    }
    reporteFlujoMenAgDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoAgD/`);
    }
    reporteFlujoMenAgbT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoAgT/`);
    }
    reporteFlujoMenSepDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoSeD/`);
    }
    reporteFlujoMenSepT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoSeT/`);
    }
    reporteFlujoMenOctDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoOcD/`);
    }
    reporteFlujoMenOctT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoOcT/`);
    }
    reporteFlujoMenNovDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoNoD/`);
    }
    reporteFlujoMenNovT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoNoT/`);
    }
    reporteFlujoMenDicDes(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoDiD/`);
    }
    reporteFlujoMenDicT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/reporteFlujoDiT/`);
    }

    reporteFlujo(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/flujo/`);
    }
    reporteIngreso(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/Ingreso/`);
    }   
    reporteFlujoTotal(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/flujoTotal/`);
    }
    reporteIngresoTotal(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/IngresoTotal/`);
    }  



    /*Cartera listado y por inmueble*/
    reporteCartera(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/cartera`);
    } 
    reporteCarteraInmueble(inmu:string): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/cartera/${inmu}`);
    }
    reporteCarteraDesarrollo(carDes:string): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/cartera/des/${carDes}`);
    }
    reporteProyecto(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/proyectoCartera/`);
    } 



    carteraR(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/carteraR/`);
    }
    carteraA(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/carteraA/`);
    }
    carteraV(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/carteraV/`);
    }
    carteraT(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/carteraT/`);
    }
    carteraG(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/carteraG/`);
    }
    saleCode(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/sales/`);
    }

    reporteDesListAtrasos(repDesCar:string): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/desReportAtr/${repDesCar}`);
    }
    reporteDesarrolloAtrasosCartera(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/desReportAtrList/`);
    }
    reporteDesAtra(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/atrasosDes/`);
    }


    reporteCarteraHistoricoDesarrollo(carHisDes:string): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repCarHisDes/${carHisDes}`);
    }
    reporteCarteraHistoricoRecuperacionDesarrollo(carHisRecDes:string): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repCarHisRecDes/${carHisRecDes}`);
    }
    reporteCarteraHistoricoRecuperacionAcDesarrollo(carHisRecDesAc:string): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repCarHisRecDesAc/${carHisRecDesAc}`);
    }
    reporteCarteraHistoricoConteoDesarrollo(carHisRecDesCont:string): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repCarHisConDes/${carHisRecDesCont}`);
    }
    reporteCarteraHistoricoDesarrolloRojo(carHisDesRojo:string): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repCarHisDesRo/${carHisDesRojo}`);
    }
    reporteCarteraHistoricoDesarrolloAmarillo(carHisDesAmarillo:string): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repCarHisDesAma/${carHisDesAmarillo}`);
    }
    reporteCarteraHistoricoDesarrolloVerde(carHisDesVerde:string): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repCarHisDesVer/${carHisDesVerde}`);
    }
    reporteCarteraHistoricoDesarrolloAzul(carHisDesAzul:string): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repCarHisDesAzu/${carHisDesAzul}`);
    }
    FechasCarteraHistorico(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/fechCarHis/`);
    }

    reporteCarteraHistorico(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repCarHis/`);
    }
    reporteCarteraHistoricoRecuperacion(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repCarHisRec/`);
    }
    reporteCarteraHistoricoRecuperacionAc(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repCarHisRecAc/`);
    }
    reporteCarteraHistoricoConteo(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repCarHisCon/`);
    }
    reporteCarteraHistoricoRojo(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repCarHiRojoGeneral/`);
    }
    reporteCarteraHistoricoAmarillo(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repCarHiAmaGeneral/`);
    }
    reporteCarteraHistoricoVerde(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repCarHiVerGeneral/`);
    }
    reporteCarteraHistoricoAzul(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repCarHiAzuGeneral/`);
    }
    reporteCarteraHistoricoGraficoSumRecuperacion(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repCarHiGraSumRec/`);
    }
    reporteRecuperacionColores(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/repRecCol/`);
    }
    tabuladoRecuperacionS1(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/tabRecS1/`);
    }
    tabuladoRecuperacionS2(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/tabRecS2/`);
    }
    tabuladoRecuperacionS3(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/tabRecS3/`);
    }
    tabuladoRecuperacionS4(): Observable<any>{
      return this.http.get(`${environment.URLServer}/report/tabRecS4/`);
    }

    //METODOS PARA EL ESTADO DEL CLIENTE INTERFAZ DE ADMINISTRACION
    selectCustomers(): Observable<any> {
      return this.http.get(`${environment.URLServer}/report/selectCustomers`);
    }
    selectSaleInfo(codeProperty: String): Observable<any> {
      return this.http.get(`${environment.URLServer}/report/selectSaleInfo/${codeProperty}`);
    }

    selectAllCustomers(): Observable<any> {
      return this.http.get(`${environment.URLServer}/report/selectAllCustomers`);
    }

    // RESCINDIDOS
    selectCustomersR(): Observable<any> {
      return this.http.get(`${environment.URLServer}/report/selectCustomersR`);
    }
    selectRecessionInfo(codeProperty: String, customer: number): Observable<any> {
      return this.http.get(`${environment.URLServer}/report/selectRecessionInfo/${codeProperty}/${customer}`);
    }

    getWeekReport(data: any): Observable<any> {
      let params = new HttpParams({fromObject: data});
      return this.http.get(`${environment.URLServer}/reportV2/getWeekReport`, {params});
    }
}