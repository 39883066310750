  <form class="panelPadre" [formGroup]="pieceworkFormGroup" (ngSubmit)="insert()"
    onKeyPress="if(event.keyCode == 13) event.returnValue = false;">
    <div class="columnaAjustable">
      <div class="panelColumna" style="flex-grow: 1;  min-width: 435px;width: 45%;" [hidden]="pieceworkCompleted">
        <div class="tarjeta" [hidden]="pieceworkChecked">
          <h5 style="text-align: left; width: 100%;">Datos de la papeleta</h5>
          <hr size="8px" noshade="noshade" color="#d33e3e" style="width: 100%; margin-top: 5px;">
          <div class="renglonAjustable">
            <mat-form-field appearance="outline" style="min-width: 150px; flex-grow: 1;">
              <mat-label>Proyecto <mat-icon>business</mat-icon>
              </mat-label>
              <mat-select [(value)]="loadInitials" formControlName="idProject" (valueChange)="getProjectDetail($event)"
                required>
                <mat-option *ngFor="let project of projects" [value]="project.idProject">
                  {{project.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" style="min-width: 150px; flex-grow: 1;">
              <mat-label>Modulo <mat-icon>location_city</mat-icon>
              </mat-label>
              <mat-select formControlName="fk_idprojectdet" (valueChange)="loadOpenDeparture($event)" required>
                <mat-option *ngFor="let projectDet of projectDetailByID" [value]="projectDet.idprojectdet">
                  Modulo {{projectDet.towernumber}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="renglonAjustable">
            <mat-form-field appearance="outline" style="min-width: 200px; flex-grow: 1;">
              <mat-label>Fecha inicial</mat-label>
              <input matInput type="date" formControlName="initweek" required>
            </mat-form-field>
            <mat-form-field appearance="outline" style="min-width: 200px; flex-grow: 1;">
              <mat-label>Fecha final</mat-label>
              <input matInput type="date" formControlName="endweek" required>
            </mat-form-field>
          </div>
          <div class="renglonAjustable">
            <mat-form-field appearance="outline" class="formfielddynamic" style="min-width: 200px; flex-grow: 1;">
              <mat-label>Semana</mat-label>
              <input matInput type="number" formControlName="week" min="1" max="53" required>
            </mat-form-field>
            <div class="roundedButtonAccept" (click)="pieceworkCheck()">
              <mat-icon>check</mat-icon>
            </div>
          </div>
        </div>
        <div class="tarjeta columnaAjustable" [hidden]="!pieceworkChecked">
          <div class="panelColumna">
            <div class="renglonAjustable">
              <p>Proyecto {{detMod.project}} en módulo {{detMod.module}}
            </div>
            <div class="renglonAjustable">
              <p>Semana: {{pieceworkFormGroup.controls['week'].value}},<br>desde {{fecResI}} hasta {{fecResE}}.</p>
            </div>
          </div>
          <div class="panelColumna" style="justify-content: flex-end; align-items: flex-end;">
            <div class="roundedButtonEdit" (click)="this.pieceworkChecked=false">
              <mat-icon>edit</mat-icon>
            </div>
          </div>
        </div>
        <div class="tarjeta" style="margin-top: 15px;" [hidden]="!pieceworkChecked">
          <h5 style="text-align: left; width: 100%;">Información de la partida</h5>
          <hr size="8px" noshade="noshade" color="#d33e3e" style="width: 100%; margin-top: 5px; margin-bottom: 2px;">
          <div class="renglonAjustable">
            <div class="ng-autocomplete" style="min-width:300px; flex-grow: 1;">
              <ng-autocomplete formControlName="iddeparture" name="busquedaP" placeholder="Id/Partida"
                (selected)='selectConcepts($event)' [data]="departures" [searchKeyword]="keywordP"
                [itemTemplate]="itemTemplateP" [notFoundTemplate]="notFoundTemplateP">
              </ng-autocomplete>
              <ng-template #itemTemplateP let-itemP>
                <a [innerHTML]="itemP.departurename"></a>
              </ng-template>
              <ng-template #notFoundTemplateP let-notFound>
                <div>No hay coincidencias de partidas o no se ha elegido un módulo</div>
              </ng-template>
            </div>
            <div class="ng-autocomplete" style="min-width:300px; flex-grow: 1;">
              <ng-autocomplete formControlName="fk_idpiecework_catalog" name="busquedaD" placeholder="Id/Destajo"
                [data]="piecework_catalog" (selected)="loadUnitPieceworkCatalog($event)" [searchKeyword]="keywordD"
                [itemTemplate]="itemTemplateD" [notFoundTemplate]="notFoundTemplateD">
              </ng-autocomplete>
              <ng-template #itemTemplateD let-itemD>
                <a [innerHTML]="itemD.concept"></a>
              </ng-template>
              <ng-template #notFoundTemplateD let-notFound>
                <div>No hay coincidencias de partidas</div>
              </ng-template>
            </div>
            <mat-form-field appearance="outline" style="min-width: 200px; flex-grow: 1;">
              <mat-label>Volumen</mat-label>
              <input matInput type="number" formControlName="amount" min="1" (input)="inputAmount()"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)">
            </mat-form-field>
            <mat-form-field appearance="outline" style="min-width: 200px; flex-grow: 1;">
              <mat-label>Unidad trabajada</mat-label>
              <input matInput type="text" formControlName="unit" readonly>
            </mat-form-field>
            <mat-form-field appearance="outline" class="formfielddynamic" style="min-width: 200px; flex-grow: 1;">
              <mat-label>A pagar unitario</mat-label>
              <input matInput type="number" id="costo" formControlName="unitcost" min="1" (input)="changeEvent()"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57  || event.charCode == 46)">
            </mat-form-field>
            <mat-form-field appearance="outline" class="formfielddynamic" style="min-width: 200px; flex-grow: 1;">
              <mat-label>A pagar total</mat-label>
              <input matInput type="number" id="costo" formControlName="total" min="1" (input)="changeTotal()"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57  || event.charCode == 46)">
            </mat-form-field>
            <mat-form-field *ngIf="changeCost==true" style="min-width: 200px; flex-grow: 1;">
              <mat-label>Razón de modificación de costo</mat-label>
              <textarea matInput cdkTextareaAutosize formControlName="reason" cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                (keyup)="this.piecework_content_Temp.reason=this.pieceworkFormGroup.controls['reason'].value"></textarea>
            </mat-form-field>
          </div>
          <div class="renglonAjustable addButton" (click)="addPiecework()">
            <p style="width:100%;text-align: center;font-size: larger; font-weight: bolder;">
              <mat-icon>add</mat-icon> Agregar a la lista
            </p>
          </div>
        </div>
        <div class="tarjeta" style="margin-top: 15px; height: 230px; display: flex;
        flex-direction: column;
        align-items: center;" [hidden]="pieceworkChecked">
          <p class="warning"
            style="height: 100%; text-align:center; justify-content: center; font-weight: bolder; font-size: larger;">
            Ingrese
            los datos de la papeleta para continuar</p>
        </div>
      </div>
      <!---->
      <div class="panelColumna" style="flex-grow: 1;  min-width: 435px;width: 45%;" [hidden]="!pieceworkCompleted">
        <div class="tarjeta" style="display: flex;
                flex-direction: column;
                align-items: center;" [hidden]="!pieceworkCompleted">
          <h5 style="text-align: left; width: 90%; min-width: 100px;">Empleados asignados</h5>
          <hr size="8px" noshade="noshade" color="#d33e3e" style="width: 100%; margin-top: 0%;">
          <div class="renglonAjustable">
            <p style="font-size: 15px; font-weight: bolder; margin-top: 5px; flex-grow: 1;">
              Restante por justificar: <b style="font-size:18px">${{((this.outstanding<0)?'Excedido '+
                  'por: '+this.outstanding:this.outstanding)}}</b><br>
              Justificado: <b style="font-size:18px">${{(this.covered)}}</b><br>
              Total por justificar: <b style="font-size:18px">${{(this.totalPapeleta)}}</b><br>
            </p>
            <div
              style="display: flex; flex-direction:column; flex-grow: 1; justify-content: center; align-items: center; gap: 5px;">
              <div class="addEmployee"
                (click)="this.piecework_employees.push({fk_idPiecework:0,employeename: '',employeesalary: 0.0,observations: '',attendanceLD:' 0-0-0-0-0-0-0',employeerange:'NA'})">
                  <mat-icon>add</mat-icon> Agregar empleado.
          </div>
          <div class="edButton" (click)="pieceworkCompleted=false">
            <mat-icon>edit</mat-icon> Editar destajos.
          </div>
        </div>
      </div>
      <table class="table table-striped mt-3 animated fadeIn faster" [hidden]="piecework_employees.length==0">
        <thead class="thead-m19">
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Total asignado</th>
            <th scope="col">Observaciones</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let le of this.piecework_employees; index as i">
            <td style="width: 170px;">
              <textarea style="width: 100%;" id="{{'employeename'+i}}" (keyup)="fillData(i)"></textarea>
            </td>
            <td style="width: 80px;">$ <input style="width: 80%;" type="number" id="{{'employeesalary'+i}}" min="0"
                [defaultValue]="0" pattern="^[0-9]+" (keyup)="fillData(i)"></td>
            <td style="width: 150px;"><textarea style="width:100%;" type="text" id="{{'observations'+i}}"
                (keyup)="fillData(i)" maxlength="500"></textarea></td>
            <td style="width: 50px;" class="button-row">
              <div class="btn btn-m19-table" mat-icon-button
                (click)="this.piecework_employees.splice(i,1);this.adjustOutstanding();">
                <mat-icon>delete</mat-icon>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tarjeta" style="margin-top:15px;" [hidden]="piecework_employees.length==0">
      <h5 style="text-align: left; width: 90%; min-width: 100px;">Registro de asistencia</h5>
      <hr size="8px" noshade="noshade" color="#d33e3e" style="width: 100%; margin-top: 0%;">
      <table class="table table-striped mt-3 animated fadeIn faster" style="width: 100%;">
        <thead class="thead-m19">
          <tr>
            <th style="width:170px" scope="col">Nombre</th>
            <th style="width:10px" scope="col">L</th>
            <th style="width:10px" scope="col">M</th>
            <th style="width:10px" scope="col">X</th>
            <th style="width:10px" scope="col">J</th>
            <th style="width:10px" scope="col">V</th>
            <th style="width:10px" scope="col">S</th>
            <th style="width:10px" scope="col">D</th>
            <th style="text-align: center; width: 30px;" scope="col">Marcar todo</th>
            <th style="width:100px" scope="col">Rango</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let le of this.piecework_employees; index as i">
            <td style="word-wrap: break-word;">{{this.piecework_employees[i].employeename}}</td>
            <td><input type="checkbox" id="{{'L'+i}}" (change)="updateAttendence(i,$event)"></td>
            <td><input type="checkbox" id="{{'M'+i}}" (change)="updateAttendence(i,$event)"></td>
            <td><input type="checkbox" id="{{'X'+i}}" (change)="updateAttendence(i,$event)"></td>
            <td><input type="checkbox" id="{{'J'+i}}" (change)="updateAttendence(i,$event)"></td>
            <td><input type="checkbox" id="{{'V'+i}}" (change)="updateAttendence(i,$event)"></td>
            <td><input type="checkbox" id="{{'S'+i}}" (change)="updateAttendence(i,$event)"></td>
            <td><input type="checkbox" id="{{'D'+i}}" (change)="updateAttendence(i,$event)"></td>
            <td align="center"><input type="checkbox" id="{{'CA'+i}}" (change)="checkAll(i)"></td>
            <td>
              <mat-form-field appearance="outline" style="width:100px; margin:0; height: 20px;">
                <mat-label>Rango<mat-icon>label_important</mat-icon>
                </mat-label>
                <mat-select id="{{'R'+i}}" (valueChange)="updateAttendence(i,$event)" required>
                  <mat-option *ngFor="let er of employeeRange" [value]="er">
                    {{er}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="renglonAjustable" style="justify-content: center;"
      [hidden]="(!pieceworkCompleted)||(covered!=totalPapeleta)">
      <div style="flex-grow: 1; display: flex; justify-content: center;">
        <button class="btnProject" type="submit">
          <mat-icon>save</mat-icon>
          Finalizar y guardar papeleta
        </button>
      </div>
    </div>
    </div>
    <!--------------------------------------------------->
    <div class="panelColumna" style="flex-grow: 1; min-width: 400px; width:55%">
      <div class="tarjeta" style="display: flex;
        flex-direction: column;
        align-items: center;">
        <h5 style="text-align: left; width: 100%;">Lista de destajos</h5>
        <hr size="8px" noshade="noshade" color="#d33e3e" style="width: 100%; margin-top: 0%;">
        <p class="warning" [hidden]="piecework_table_content.length!=0">Aún no se ha ingresado
          ningún concepto a la papeleta</p>
        <div [hidden]="piecework_table_content.length==0" class="onlyTable">
          <table class="table table-striped mt-3 animated fadeIn faster" style="width: 100%;">
            <thead class="thead-m19">
              <tr>
                <th style="width: 20px;" scope="col">#</th>
                <th scope="col">Partida Presupuestal</th>
                <th scope="col">Concepto de Destajo</th>
                <th scope="col">Unidad</th>
                <th style="width:80px;" scope="col">Costo unitario</th>
                <th scope="col">Volumen</th>
                <th scope="col">Subtotal</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let mat of piecework_table_content; index as i">
                <td>{{i+1}}</td>
                <td>{{mat.partida}}</td>
                <td>{{mat.destajo}}</td>
                <td>{{mat.unidad}}</td>
                <td>{{mat.precioUnit}}</td>
                <td>{{mat.volumen}}</td>
                <td>{{mat.costoTot}}</td>
                <td class="button-row">
                  <button class="btn btn-m19-table" mat-icon-button (click)="deletePiecework(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="renglonAjustable addButton" (click)="completePiecework()"
          [hidden]="piecework_table_content.length==0||pieceworkCompleted">
          <p style="width:100%;text-align: center;font-size: larger; font-weight: bolder;">
            <mat-icon>supervisor_account</mat-icon> Destajos completos, continuar.
          </p>
        </div>
      </div>
    </div>
    </div>
  </form>
