<mat-tab-group mat-stretch-tabs>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">workspaces_outline</mat-icon>
            Factura global
        </ng-template>

        <div class="container-fluid div-central mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center" method="post">
                    <form class="row mt-3" (ngSubmit)="createInvoiceReport()">
                        <div class="ng-autocomplete" style="padding-bottom: 1cm; padding-left: .45cm;">
                            <ng-autocomplete name="busqueda" placeholder="Inmueble/Nombre cliente/RFC" [data]="listUsers"
                                [searchKeyword]="keyword" (selected)="onKeypressEvent($event)"
                                [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>
                            <ng-template #itemTemplate let-item>
                                <a [innerHTML]="item.name"></a>
                            </ng-template>
                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div>
                        <p></p>
                            <mat-form-field appearance="outline" class="col-md-3">
                                <mat-label>Folio</mat-label>
                                <input matInput id="FolioId" name="Folio" type="number" required [(ngModel)]="invReport.accountingFolio">
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="col-md-3">
                                <mat-label>Cantidad</mat-label>
                                <input matInput id="abono" name="prueba" type="number" required [(ngModel)]="invReport.amountInvoice">
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="col-md-4">
                                <mat-label>Inmueble</mat-label>
                                <input matInput id="inmuebleCodigo" name="inmueble" type="text" required readonly [(ngModel)]="codigoInm" [(ngModel)]="invReport.fkProperty">
                                <mat-icon matSuffix>roofing</mat-icon>
                            </mat-form-field>

                            <div class="col-12 text-right mt-3">
                                <button class="btn btn-m19" type="submit" [disabled]='invReport.amountInvoice==0 || invReport.amountInvoice==0' (click)="createInvoiceReport()">Agregar</button>
                            </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>
</mat-tab-group>