<div>
    <h1>{{recessionInfo.fk_property}}</h1>
    <mat-dialog-content>
        <table class="table table-striped mt-3 animated fadeIn faster">
            <tr>
                <th>Cliente</th>
                <td>{{recessionInfo.fk_customer}}</td>
            </tr>
            <tr>
                <th>Vendedor</th>
                <td>{{recessionInfo.fk_coordinator}}</td>
            </tr>
            <tr>
                <th>Fecha de contrato</th>
                <td>{{recessionInfo.fechaDato | date:'dd-MMM-yyyy'}}</td>
            </tr>
            <tr>
                <th>Saldo</th>
                <td>{{recessionInfo.saldo | currency:'CAD':'$ '}}</td>
            </tr>
            <tr>
                <th>Fecha de rescisión</th>
                <td *ngIf="recessionInfo.dateRecession != null">{{recessionInfo.dateRecession | date:'dd-MMM-yyyy'}}</td>
                <td *ngIf="recessionInfo.dateRecession == null">Sin fecha registrada</td>
            </tr>
            <tr>
                <th>Motivo de rescisión</th>
                <td>{{recessionInfo.reasonRecession}}</td>
            </tr>
            <tr>
                <th>Notas de rescisión</th>
                <td>{{recessionInfo.notesRecession}}</td>
            </tr>
        </table>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cerrar</button>
    </mat-dialog-actions>

</div>