<html>

<body>
    <div class="contenedor">
        <div class="allTableContainer">
            <div class="divTabla" [hidden]="infoGeneral.nombreDelProyecto=='Sin seleccionar'||matFP.length==0">
                <h2 class="tableTittle">Material total de
                    {{infoGeneral.nombreDelProyecto}}</h2>
                <div class="onlyTable">
                    <table class="table table-striped mt-3 animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr class="tableHead">
                                <th>#</th>
                                <th>Material</th>
                                <th>Solicitado total</th>
                                <th>Consumido total</th>
                                <th>Restante</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let mFp of matFP; index as i" class="tableHead">
                                <td>{{i+1}}</td>
                                <td>{{mFp.productname}}</td>
                                <td>{{mFp.totalAmount}} {{mFp.unit}}S</td>
                                <td>{{mFp.totalAmount-mFp.amount}} {{mFp.unit}}S</td>
                                <td>{{mFp.amount}} {{mFp.unit}}S</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div [hidden]="!(matFP.length==0 && infoGeneral.nombreDelProyecto!='Sin seleccionar')"
                class="noMaterialWarning">
                <h5><b><i>{{infoGeneral.nombreDelProyecto}} no tiene material registrado aún, ingrese el material como
                            nota de remisión o seleccione otro proyecto</i></b></h5>
            </div>
            <div class="divTabla" [hidden]="infoGeneral.TorreDelProyecto==0||matF.length==0">
                <h2 class="tableTittle">Material del módulo
                    {{(infoGeneral.TorreDelProyecto==0)?'Sin seleccionar':infoGeneral.TorreDelProyecto}}</h2>
                <div class="onlyTable">
                    <table class="table table-striped mt-3 animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr class="tableHead">
                                <th>#</th>
                                <th>Material</th>
                                <th>Solicitado total</th>
                                <th>Consumido total</th>
                                <th>Restante</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let mF of matF; index as i" class="tableHead">
                                <td>{{i+1}}</td>
                                <td>{{mF.productname}}</td>
                                <td>{{mF.totalAmount}} {{mF.unit}}S</td>
                                <td>{{mF.totalAmount-mF.amount}} {{mF.unit}}S</td>
                                <td>{{mF.amount}} {{mF.unit}}S</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div [hidden]="!(matF.length==0 && infoGeneral.nombreDelProyecto!='Sin seleccionar' && infoGeneral.TorreDelProyecto!=0)"
                class="noMaterialWarning">
                <h5><b><i>El módulo {{infoGeneral.TorreDelProyecto}} del proyecto {{infoGeneral.nombreDelProyecto}} no
                            tiene material registrado aún, ingrese el material como nota de remisión o seleccione otro
                            proyecto</i></b></h5>
            </div>
        </div>
        <div class="divGraphics" [hidden]="matF.length==0||infoGeneral.TorreDelProyecto==0">
            <h3 style="text-align: center;"> <b>Resumen de material en el módulo {{infoGeneral.TorreDelProyecto}}: </b>
            </h3>
            <canvas id="cTRCanvasModulo"></canvas>
        </div>
        <div class="divGraphics" [hidden]="matFP.length==0||infoGeneral.nombreDelProyecto=='Sin seleccionar'">
            <h3 style="text-align: center;"> <b>Resumen de material en {{infoGeneral.nombreDelProyecto}}: </b></h3>
            <canvas id="cTRCanvasProyecto"></canvas>
        </div>
    </div>

    <!--========================================INFORMACIÓN GENERAL LATERAL========================================-->
    <div class="infGeneral">
        <div class="infoContainer">
            <p class="infoText">
                <mat-icon>info</mat-icon> Mas detalles
            </p>
        </div>
        <div class="infoAdjust">
            <h4 style="text-align: center;">Información general</h4>
            <p><b style="color:brown;">PROYECTO:</b> {{this.infoGeneral.nombreDelProyecto}}</p>
            <p><b style="color:brown;">MODULO:</b> {{(infoGeneral.TorreDelProyecto==0)?'Sin seleccionar':infoGeneral.TorreDelProyecto}}</p>
            <div class="infoEst">
                <br>
                <div
                    [hidden]="matF.length==0 || infoGeneral.TorreDelProyecto==0">
                    <h5 style="text-align: center;">Estadísticas del módulo <b>{{this.infoGeneral.TorreDelProyecto}}</b>:
                        {{this.infoGeneral.nombreDelProyecto}}</h5>
                    <p><b style="color:brown;">PROMEDIO MATERIAL CONSUMIDO: <br>
                        </b>{{this.infoGeneral.PorcentajeMaterialConsumido.toFixed(2)}}%
                        ({{this.matG[1]}} U.)</p>
                    <p><b style="color:brown;">PROMEDIO MATERIAL RESTANTE: <br>
                        </b>{{this.infoGeneral.PorcentajeMaterialRestante.toFixed(2)}}%
                        ({{this.matG[2]}} U.)</p>
                    <hr width="100%" color="brown">
                </div>
                <div [hidden]="matFP.length==0">
                    <h5 style="text-align: center;">Estadísticas del proyecto: {{this.infoGeneral.nombreDelProyecto}}
                    </h5>
                    <p><b style="color:brown;">PROMEDIO MATERIAL CONSUMIDO:
                        </b>{{this.infoGeneralP.PorcentajeMaterialConsumido.toFixed(2)}}%
                        ({{this.matG[1]}} U.)</p>
                    <p><b style="color:brown;">PROMEDIO MATERIAL RESTANTE:
                        </b>{{this.infoGeneralP.PorcentajeMaterialRestante.toFixed(2)}}%
                        ({{this.matG[2]}} U.)</p>
                </div>
            </div>
            <button class="btn btn-m19"
                *ngIf="permissions().match('ConsumerTokenConsultMainComponent') || permissions().match('446576656c6f706572')"
                onclick="location.href='/ConsumerTokenConsultMainComponent'">
                <mat-icon>inventory</mat-icon>
                <span> Ir a consulta de vales de consumo</span>
            </button>
        </div>
    </div>
    <!--===========================================================================================================-->

    <!--=======================================SELECCIONADOR PROYECTO/MODULO=======================================-->
    <div class="menuSelector">
        <div class="selectedInfoDiv">
            
            <p style="text-align: center; line-height: 13px; font-size: 20px;"><b> {{infoGeneral.nombreDelProyecto}}<br></b>
            <i style="font-size: 10px;">Proyecto</i></p>
            <hr size="8px" noshade="noshade" color="white" style="position: absolute; top: 33px; width: 200px;">
            <p style="text-align: center; line-height: 13px; font-size: 20px;"><b> {{(infoGeneral.TorreDelProyecto==0)?'Sin seleccionar':infoGeneral.TorreDelProyecto}}<br></b>
            <i style="font-size: 10px;">Módulo </i> </p>
        </div>
        <div class="projAndMod">
            <div class="btnProject">
                <button *ngFor="let p of projects;" (click)="getProjectDetail(p.idProject,p.name)">
                    {{p.name}}
                    <mat-icon>apartment</mat-icon>
                </button>
            </div>
            <div class="btnModule">
                <button *ngFor="let pd of projectsDetail"
                    (click)="calculateMaterialByMod(pd.idprojectdet,pd.towernumber)">
                    {{pd.towernumber}}
                    <mat-icon>apartment</mat-icon>
                </button>
            </div>
                <p class="adMenuSelector" [hidden]="infoGeneral.nombreDelProyecto!='Sin seleccionar'">Al seleccionar un proyecto, se mostrarán sus módulos</p>
                <p class="adMenuSelector" [hidden]="!(infoGeneral.nombreDelProyecto!='Sin seleccionar'&& projectsDetail.length==0)">Este proyecto no tiene módulos, por favor, seleccione otro</p>
        </div>
    </div>
    <!--===========================================================================================================-->

    <div class="backgroundText" [hidden]="infoGeneral.nombreDelProyecto!='Sin seleccionar'">
        <h2 style="text-align: center;"><i> <b> Seleccione un proyecto y un módulo </b> </i> </h2>
    </div>

</body>

</html>