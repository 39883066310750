import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DepartureService } from 'src/app/services/departure.service';

//=========================================================================================================
//Importación de librerías para componentes visuales
//=========================================================================================================
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-departure-update',
  templateUrl: './departure-update.component.html',
  styleUrls: ['./departure-update.component.scss']
})
export class DepartureUpdateComponent implements OnInit {

  departure: any;

  constructor(
    private dialogRef: MatDialogRef<DepartureUpdateComponent>,
    private departureService: DepartureService,
        
    //===================================================
    //Declaración de complementos visuales
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  //==========================================================================================================
  //Objeto constructor del formulario utilizado en la pantalla de requisición, se agrega el código 
  //", Validators.required" para señalar que es un valor que no debe estar vacío
  //==========================================================================================================
  departureFormGroup: FormGroup = this._formBuilder.group({
    iddeparture: [],
    departurenumber: [, Validators.required],
    descriptions: [, Validators.required],
    useremail: []
  });

  ngOnInit(): void {
    this.departureService.selectDepartureByID(this.data).subscribe(
      res => {
        this.departure = res;
        this.departureFormGroup.controls['departurenumber'].setValue(this.departure.departurenumber);
        this.departureFormGroup.controls['descriptions'].setValue(this.departure.descriptions);
      }
    );
  }

  async updateDeparture(){
    Swal.fire({
      title: '¿Quieres guardar tus cambios ahora?',
      text: "¡Esta partida se guardara como está actualmente!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, espera',
      confirmButtonText: 'Sí, guárdala!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.departureService.updateDeparture(this.data, this.departureFormGroup.value).subscribe(
          res => {
            this.sweet.AlertTime("center", "success", "Partida actualizada con éxito.", false, 2000);
            this.dialogRef.close();
          }
        );
      }
    })
  }

}
