import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transfer-validate',
  templateUrl: './transfer-validate.component.html',
  styleUrls: ['./transfer-validate.component.scss']
})
export class TransferValidateComponent implements OnInit {

  //Autocompletado
  keyword = 'name';

  sal: any = [];

  constructor() { }

  ngOnInit(): void {
  }

  onKeypressEvent(e: any) {

  }
}
