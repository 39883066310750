import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralReportsComponent } from './general-reports/general-reports.component';
import { ReportTabComponent } from './general-reports/report-tab/report-tab.component';
import { PendingInvoicesComponent } from './pending-invoices/pending-invoices.component';
import { UiModule } from 'src/app/ui/ui.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {  MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogInvoiceComponent } from './dialog-invoice/dialog-invoice.component';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [
    GeneralReportsComponent,
    ReportTabComponent,
    PendingInvoicesComponent,
    DialogInvoiceComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatDialogModule 
  ]
})
export class InvoiceV2Module { }
