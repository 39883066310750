import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterInvoices'
})
export class FilterInvoicesPipe implements PipeTransform {

  transform(value: any, arg: any): any {

    const resultPosts = [];

    for (const post of value) {
      if (post.customer.toUpperCase().indexOf(arg.toUpperCase()) > -1) {
        resultPosts.push(post);
      };
    };

    return resultPosts;
  }

}
