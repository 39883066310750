//=========================================================================================================
//Importaciones de servicios necesitados
//=========================================================================================================
import { RequisitionService } from 'src/app/services/requisition.service';
import { DepaproService } from 'src/app/services/depapro.service';
import { DeliverynoteService } from 'src/app/services/deliverynote.service';

import { DialogRequisitionComponent } from '../requisitionconsult/dialog-requisition/dialog-requisition.component';
import { DialogDeliveryComponent } from '../requisitionconsult/dialog-delivery/dialog-delivery.component';

import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';
import Swal from 'sweetalert2';

import { DatePipe } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL

import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DepaPieceService } from 'src/app/services/depa-piece.service';

@Component({
  selector: 'app-requisitionapprove',
  templateUrl: './requisitionapprove.component.html',
  styleUrls: ['./requisitionapprove.component.scss']
})
export class RequisitionapproveComponent implements OnInit {

  requisitionTitle: BannerTitle = {
    title: "Aprobación de requisiciones",
    icon: "verified_user",
    position: "center",
    background: "var(--M19Secundary)"
  }

  pipe = new DatePipe('es-MX');

  //Objeto que contiene las requisiciones encontradas en la BD
  requisitions: any = [];

  showButton: Boolean = false;

  //Objeto que contiene las partidas encontradas al seleccionar una requisición
  departures: any = [];

  view: any = false;
  type: any = true;

  constructor(
    //======================================================================
    //Declaraciones de los servicios utilizados
    public requisitionService: RequisitionService,
    public departureproService: DepaproService,
    public deliveryNoteService: DeliverynoteService,
    public pieceworksService: DepaPieceService,

    private el: ElementRef,

    //======================================================================
    //Declaración del componente gráfico utilizado para cuadro de dialogo
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    //Se manda a llamar en el arranque a la función que obtiene todas las requisiciones
    this.getReqWithFilter('NA');
    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
  }

  async getReqWithFilter(status: string) {
    this.requisitionService.getReqWithFilter(status).subscribe(
      res => {
        this.requisitions = res;
      }
    );
  }

  //=========================================================================================================
  //Función para elegir las partidas de una requisición por su ID
  //=========================================================================================================
  async selectDeparture(id: number) {
    //Se apertura un cuadro de dialogo para visualizar los datos detallados de la requisición y partidas
    this.dialog.open(DialogRequisitionComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '100%',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: id
    });
  }

  //=========================================================================================================
  //Función para abrir ventana modal para información de nota de remisión
  //=========================================================================================================
  async selectDelivery(id: Number) {
    this.dialog.open(DialogDeliveryComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '1800px',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: id
    });
  }

  //=========================================================================================================
  //Función para aprobar una requisición
  //=========================================================================================================
  async changeStatus(id: number, st: string) {
    Swal.fire({
      title: '¿Estás seguro de realizar este movimiento?',
      text: (st == 'approve' ? "Aprobarás" : "Rechazaráz") + " esta requisición y no se podrá revertir",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, adelante',
      cancelButtonText: 'No, espera'
    }).then((result) => {
      if (result.isConfirmed) {
        this.requisitionService.approveRequisition(id).subscribe(
          res => {
            Swal.fire(
              (st == 'approve' ? '¡Esta requisición se aprobó correctamente!' : '¡Esta requisición se rechazó correctamente!'),
              'success'
            )
            this.getReqWithFilter("NA");
          }
        );
      }
    })
  }

  scrollToTop() {
    const container = document.querySelector('.reqContenedor');
    container!.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  onScroll() {
    const element = this.el.nativeElement.querySelector('.reqContenedor');
    if (element.scrollTop > 100) {
      this.showButton = true;
    } else {
      this.showButton = false;
    }
  }
}