<mat-card class="homeContainer">
    <span>
        <h2>
            <p class="firstText">¡Hola!</p>
            <p class="secondText"> Bienvenido/a {{user.split(" ")[0]}}.</p>
        </h2>
        <h5 style="font-weight: bold;">Esta es una versión en fase de pruebas del sistema de <p class="firstText">M19
                Inmobiliaria.</p>
        </h5>
        <h5 style="font-weight: bold;">En caso de necesitar ayuda o encontrar algún error en la aplicación por favor
            contacte al departamento de
            sistemas de la empresa.</h5>
    </span>

    <div style="display: flex; flex-direction: column; align-items: center;">
        <img class="m19logo" src="../assets/imagotipo.png" alt="Isotipo de M19 Inmobiliaria">
    </div>
</mat-card>

<!-- <div class="a"><span class="b"></span></div> -->