import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/models/User';
import { UserService } from 'src/app/services/user.service';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';

@Component({
  selector: 'app-dialog-user-form',
  templateUrl: './dialog-user-form.component.html',
  styleUrls: ['./dialog-user-form.component.scss']
})
export class DialogUserFormComponent implements OnInit {

  employeeFormGroup: FormGroup = this._formBuilder.group({
    idEmployee: [],
    name: ['', Validators.required],
    firstLastName: ['', Validators.required],
    secondLastName: ['', Validators.required],
    telephone: [''],
    mobile: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    address: [, Validators.required],
    localidad: [, Validators.required],
    municipality: [, Validators.required],
    state: [, Validators.required],
    postalCode: [, Validators.required],
    fk_employment: [1],
    estatus: [],
    description: [],
    fk_typeUser: [2]
  });

  

  constructor(
    private dialogRef: MatDialogRef<DialogUserFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User,
    private employeeService: UserService,
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent
  ) {
    this.dialogRef.disableClose = true;
    this.employee = data;
  }

  employee: any = [];

  ngOnInit(): void { }

  updateUser(id: number, employee: any) {
    if (this.employeeFormGroup.invalid) {
      return;
    } 
    else {
      employee = this.employeeFormGroup.value
      this.employeeService.updateUser(id,employee).subscribe(
        res => {
          this.sweet.AlertTime("center", "success", "Usuario modificado con éxito", false, 2000);
          this.dialogRef.close();
        },
        err => {
          console.error(err);
          this.sweet.AlertTime("center", "error", "Usuario no modificado", false, 2000);
        }
      );
    }
  }

}
