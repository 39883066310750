<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Reporte General para el patron </title>
</head>

<body>

    <div class="container-fluid">
        <mat-card class="card-form">
            <mat-card-content class="justify-align-center">
                <div class="container-view-Report">

                    <div id="containerHead" class="container__header__view__general">

                        <div *ngFor="let arrlo of arrayLogos">
                            <img id="imgResizable" [src]="arrlo.UrlLogo"
                                style="width: 400px; height:300px; justify-content: center; flex-direction: row; align-items: center;">
                        </div>

                    </div>


                    <div class="container__general">

                        <div id="menuResizable" class="container_butons" [hidden]="false">
                            <button class="optionButtons" id="btnCaja" (click)="abrirCorteDeCaja()"> Caja <mat-icon>
                                    move_to_inbox</mat-icon> </button>
                            <button class="optionButtons" id="btnComisiones" (click)="abrirComisiones()"> Comisiones
                                <mat-icon>move_to_inbox</mat-icon>
                            </button>
                            <button class="optionButtons" id="btnProcesoVenta" (click)="abrirProcesoVenta()"> Proceso de
                                venta <mat-icon>move_to_inbox</mat-icon> </button>
                            <button class="optionButtons" id="btnCobranza" (click)="abrirCobranza()"> Cobranza
                                <mat-icon>
                                    move_to_inbox</mat-icon>
                            </button>


                            <button class="optionButtons" id="btnFlujos"> Flujos <mat-icon>
                                    move_to_inbox</mat-icon>
                            </button>


                            <button class="optionButtons" id="btnBloqueados" (click)="abrirBloqueados()"> Bloqueados
                                <mat-icon>move_to_inbox</mat-icon>
                            </button>
                            <button class="optionButtons" id="btnVentasMensuales" (click)="modicarWidth()"> Ventas
                                mensuales <mat-icon>move_to_inbox</mat-icon> </button>
                            <button class="optionButtons" id="btnEjecucionPresupuesto" (click)="modicarWidth()">
                                Ejecucion de presupuesto <mat-icon>move_to_inbox</mat-icon> </button>
                            <button class="optionButtons" id="btnDisponibilidad" (click)="modicarWidth()">
                                Disponibilidad por proyectos <mat-icon>move_to_inbox</mat-icon> </button>

                        </div>

                        <div id="windowCashCut" class="container__screens" [hidden]="flagCashCut == false">

                            <div class="contenedorReporteCaja">
                                <div class="tituloGral">
                                    <h3> Reporte de Caja </h3>
                                    <mat-icon style="scale: 1.2;">move_to_inbox</mat-icon>
                                </div>

                                <div
                                    style="display: flex; flex-direction:row; justify-content:center; text-align:center; width:100%;">
                                    <h3 style="font-weight: bold;"> Selecciones una fecha: </h3>

                                    <form [formGroup]="querysDate">

                                        <input matInput type="date" formControlName="dateRCC"
                                            style="color: black; border: 2px solid black; height: 40px; border-radius: 10px; margin-left: 50px; text-align: center; font-weight: bolder;"
                                            (change)="dateSelector($event)" required>

                                    </form>


                                </div>

                                <div style="display:flex ; flex-direction: row;">

                                    <div
                                        style="display:flex; flex-direction: column;width: 100%;  height: 48vh; align-items: center; justify-content: center;">

                                        <canvas baseChart width="100%" height="80%"
                                            [data]="[totCash, totDeposit, totTranser, totCreditCard, totDebitCard, totCheck, totNC, totNCNR]"
                                            [labels]="pieChartLabels" [chartType]="pieChartType"
                                            [options]="pieChartOptions" (plugins)="pieChartPlugins"
                                            [colors]="pieChartColors" [legend]="pieChartLegend">
                                        </canvas>

                                    </div>



                                    <div
                                        style="display:flex; width: 20%;  justify-content: center; flex-direction: column;">

                                        <h5 style="color: #1eb803; font-weight: bold;  text-align: center;"> Entrada
                                            totales:</h5>
                                        <h5
                                            style="margin-left: 10px; font-weight: bolder; color: black; text-align: center;">
                                            $ {{(totCash + totDeposit + totTranser + totCreditCard + totDebitCard +
                                            totCheck + totNC + totNCNR)}}
                                        </h5>

                                    </div>

                                </div>

                                <button (click)="cerrarCashCut()"> Cerrar </button>

                            </div>

                        </div>

                        <div id="windowComisiones" class="container__screens" [hidden]="flagCommissions == false">

                            <div class="contenedorReporteComisiones">
                                <div class="tituloGral">
                                    <h3> Reporte de Comisiones </h3>
                                    <mat-icon style="scale: 1.5;"> money </mat-icon>
                                </div>



                                <div
                                    style="display: flex; flex-direction:row; justify-content:space-between; text-align:center; width:100%;">

                                    <div style="display:flex; margin-left: 10px;">
                                        <h3> Selecciones una fecha: </h3>

                                        <form [formGroup]="querysDate">

                                            <input matInput type="date" formControlName="dateComm"
                                                style="color: black; border: 2px solid black; height: 40px; border-radius: 10px; margin-left: 10px; text-align: center; font-weight: bolder;"
                                                (change)="dateSelector($event)" required>

                                        </form>

                                    </div>

                                    <div style="display:flex; margin-right: 10px; justify-items: center;">
                                        <h3 style="margin-left:50px ;"> Total pagado: </h3>

                                        <ng-container *ngIf="comipaga.totalcom != []; then first else second">
                                        </ng-container>
                                        <ng-template #first>
                                            <h5
                                                style="display:flex; color: #d33e3e; margin-left: 8px; align-items: center;">
                                                ${{comipaga.totalcom}} </h5>
                                        </ng-template>
                                        <ng-template #second>
                                            <h5
                                                style="display:flex; color: #d33e3e; margin-left: 8px; align-items: center;">
                                                $0</h5>
                                        </ng-template>

                                    </div>

                                </div>

                                <div
                                    style="display: flex; flex-direction:row; justify-content:space-around; text-align:center; width:100%; padding-top: 15px;">

                                    <button class="botonesHeader" *ngFor="let p of projects" [value]="p.name"
                                        (click)="filterByProjectAndDate(p.nomenclature, p.name )">
                                        {{p.name}}

                                    </button>

                                </div>


                                <div
                                    style="padding-top:15px ; margin-left: 15px; margin-right: 15px; display: flex; flex-direction:row ; justify-content: space-between;">

                                    <div style="display:flex ; flex-direction:row">
                                        <h5> Proyecto: </h5>
                                        <h5 style="color: #d33e3e; margin-left: 8px;" *ngIf="projSel != 'Ninguno'"> {{
                                            projSel }} </h5>
                                        <mat-icon *ngIf="projSel != ''" style="color: #d33e3e;">apartment</mat-icon>


                                    </div>

                                    <div style="display:flex ; flex-direction:row">

                                        <h5> Nomenclatura: </h5>
                                        <h5 style="color: #d33e3e; margin-left: 8px;" *ngIf="projSel != 'Ninguno'">
                                            {{nomenclature}} </h5>

                                    </div>

                                    <div style="display:flex ; flex-direction:row">

                                        <h5> Cantidad pagada por projecto: </h5>
                                        <ng-container *ngIf="comipagaperProject.length > 0 then prim else segu;">
                                        </ng-container>
                                        <ng-template #prim>
                                            <h5
                                                style="display:flex; color: #d33e3e; margin-left: 8px; align-items: center;">
                                                ${{totAmount}} </h5>
                                        </ng-template>
                                        <ng-template #segu>
                                            <h5
                                                style="display:flex; color: #d33e3e; margin-left: 8px; align-items: center;">
                                                $ 0 </h5>
                                        </ng-template>

                                    </div>
                                </div>

                                <div *ngIf="totAmount > 0" style="height: 50vh; overflow:scroll;">
                                    <table class="table table-striped mt-3 animated fadeIn faster">
                                        <thead class="thead-m19">
                                            <tr style="text-align:left; ">
                                                <th> folio </th>
                                                <th> Propiedad </th>
                                                <th> Concepto </th>
                                                <th> Fecha </th>
                                                <th> Fecha2 </th>
                                                <th> Cantidad </th>
                                                <th> Estatus </th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            <tr *ngFor="let cash of comipagaperProject"
                                                style="text-align: left; overflow: scroll; ">

                                                <td>

                                                    {{cash.folio}}
                                                </td>

                                                <td>

                                                    {{cash.fk_property}}
                                                </td>

                                                <td>

                                                    {{cash.paymentConcept}}
                                                </td>

                                                <td>

                                                    {{cash.paymentDate | date: 'yyyy-MM-dd'}}
                                                </td>

                                                <td>

                                                    {{cash.registrationDate | date: 'yyyy-MM-dd' }}
                                                </td>

                                                <td>

                                                    {{cash.paymentAmount | currency: 'CAD': '$'}}
                                                </td>

                                                <td>

                                                    {{cash.paymentStatus}}
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div *ngIf="totAmount == 0"
                                    style="display:flex; justify-content:center; align-items:center; flex-direction: column; color: lightgray; height: 50vh;">

                                    <div style="display: flex;">
                                        <mat-icon style="scale: 1.6;"> sentiment_very_dissatisfied</mat-icon>
                                        <h3 style="margin-left: 20px ;">No hay pagos del proyecto: {{projSel}} </h3>
                                    </div>

                                    <div style="display: flex;">
                                        <h3> con fecha de {{pipe.transform(fecSel ,'EEEE')!}}
                                            {{pipe.transform(fecSel,'longDate')!}} </h3>
                                        <mat-icon style="scale: 1.6; margin-left: 20px;"> sentiment_very_dissatisfied
                                        </mat-icon>
                                    </div>

                                </div>

                                <button (click)="cerrarComisiones()"> Cerrar </button>
                            </div>



                        </div>

                        <div id="windowProcesoVenta" class="container__screens" [hidden]="flagProces == false">

                            <div class="contenedorReporteComisiones">
                                <div class="tituloGral">
                                    <h3> Reporte del proceso de ventas </h3>
                                    <mat-icon style="scale: 1.5;"> linear_scale </mat-icon>
                                </div>

                                <button (click)="cerrarProcesoVenta()"> Cerrar </button>

                            </div>




                        </div>

                        <div id="windowBloqueados" class="container__screens" [hidden]="flagBlocked == false">

                            <div class="contenedorReporteCaja">
                                <div class="tituloGral">
                                    <h3> Reporte de Clientes Bloqueados </h3>
                                    <mat-icon style="scale: 1.2;"> block </mat-icon>
                                </div>

                                <div
                                    style="display: flex; flex-direction:row; justify-content:space-around; text-align:center; width:100%; padding-top: 15px;">

                                    <button class="botonesHeader" *ngFor="let p of projects" [value]="p.name"
                                        (click)="filterBlockedByProject(p.name, p.nomenclature)">
                                        {{p.name}}
                                    </button>

                                </div>



                                <div
                                    style="padding-top: 15px; margin-left: 15px; margin-right: 15px; display: flex; flex-direction:row ; justify-content: space-between;">

                                    <div style="display:flex ; flex-direction:row">
                                        <h5> Proyecto: </h5>
                                        <h5 style="color: #d33e3e; margin-left: 8px;"
                                            *ngIf="nameProjectforBlocked != ''"> {{
                                            nameProjectforBlocked }} </h5>
                                        <mat-icon *ngIf="nameProjectforBlocked != ''" style="color: #d33e3e;">apartment
                                        </mat-icon>


                                    </div>


                                    <div style="display:flex ; flex-direction:row">

                                        <h5> Nomenclatura: </h5>
                                        <h5 style="color: #d33e3e; margin-left: 8px;"
                                            *ngIf="nomenclatureProjectforBlocked != ''">
                                            {{nomenclatureProjectforBlocked}} </h5>

                                    </div>

                                    <div style="display:flex ; flex-direction:row">

                                        <h5> Total bloquedos: </h5>

                                        <h5 style="display:flex; color: #d33e3e; margin-left: 8px; align-items: center;"
                                            *ngIf="nameProjectforBlocked != ''">
                                            {{(volunteersBlocked.length + breachContractBlocked.length +
                                            errorSaleBlocked.length + relocationBlocked.length + direcionBlocked.length
                                            )}} </h5>


                                    </div>
                                </div>

                                <div class="contenedorTarjetas">

                                    <div class="tarjeta">
                                        <h5> Voluntario </h5>
                                        <mat-icon *ngIf="volunteersBlocked.length != 0;"> sentiment_satisfied_alt
                                        </mat-icon>



                                        <ng-container *ngIf="volunteersBlocked.length != 0; then vol else noti">
                                        </ng-container>
                                        <ng-template #vol>

                                            <h5> cantidad </h5>
                                            <p style="text-align: center; font-size: 1.9rem; color: darkblue;">
                                                {{volunteersBlocked.length}} </p>
                                            <mat-icon class="icon-position" title="Ver"
                                                (click)="captacionData(volunteersBlocked, 'Voluntario')"> remove_red_eye
                                            </mat-icon>

                                        </ng-template>

                                        <ng-template #noti>
                                            <h5 style="display:flex; color: #c0b0b0;">
                                                No hay bloqueados por este metodo </h5>
                                            <mat-icon> mood </mat-icon>

                                        </ng-template>


                                    </div>

                                    <div class="tarjeta">
                                        <h5> Incumplimiento de contrato </h5>
                                        <mat-icon> assignment </mat-icon>
                                        <h5 *ngIf="breachContractBlocked.length != 0"> cantidad </h5>
                                        <p *ngIf="breachContractBlocked.length != 0"
                                            style="text-align: center; font-size: 1.9rem; color: darkblue;">
                                            {{breachContractBlocked.length}}</p>
                                        <mat-icon *ngIf="breachContractBlocked.length != 0" class="icon-position"
                                            title="Ver"
                                            (click)="captacionData(breachContractBlocked, 'Incumplimiento de contrato')">
                                            remove_red_eye </mat-icon>
                                    </div>

                                    <div class="tarjeta">
                                        <h5> Error de venta </h5>
                                        <mat-icon> cancel </mat-icon>
                                        <h5 *ngIf="errorSaleBlocked.length != 0"> cantidad </h5>
                                        <p *ngIf="errorSaleBlocked.length != 0"
                                            style="text-align: center; font-size: 1.9rem; color: darkblue;">
                                            {{errorSaleBlocked.length}}</p>
                                        <mat-icon *ngIf="errorSaleBlocked.length != 0" class="icon-position" title="Ver"
                                            (click)="captacionData(errorSaleBlocked, 'Error de venta')"> remove_red_eye
                                        </mat-icon>
                                    </div>

                                    <div class="tarjeta">
                                        <h5> Reubicación </h5>
                                        <mat-icon> edit_location </mat-icon>
                                        <h5 *ngIf="relocationBlocked.length != 0"> cantidad </h5>
                                        <p *ngIf="relocationBlocked.length != 0"
                                            style="text-align: center; font-size: 1.9rem; color: darkblue;">
                                            {{relocationBlocked.length}}</p>
                                        <mat-icon *ngIf="relocationBlocked.length != 0" class="icon-position"
                                            title="Ver" (click)="captacionData(relocationBlocked, 'Reubicación')">
                                            remove_red_eye </mat-icon>
                                    </div>

                                    <div class="tarjeta">
                                        <h5> Dirección </h5>
                                        <mat-icon> work </mat-icon>
                                        <h5 *ngIf="direcionBlocked.length != 0"> cantidad </h5>
                                        <p *ngIf="direcionBlocked.length != 0"
                                            style="text-align: center; font-size: 1.9rem; color: darkblue;">
                                            {{direcionBlocked.length}}</p>
                                        <mat-icon *ngIf="direcionBlocked.length != 0" class="icon-position" title="Ver"
                                            (click)="captacionData(direcionBlocked , 'Dirección')"> remove_red_eye
                                        </mat-icon>
                                    </div>

                                </div>

                                <div
                                    style="display: flex; flex-direction: row; align-items:center; justify-content:space-evenly;">

                                    <button class="optionButtons"> Todos </button>
                                    <button class="optionButtons" (click)="cerrarBloqueados()"> Cerrar </button>
                                </div>


                            </div>

                        </div>

                        <div id="windowCobranza" class="container__screens" [hidden]="flagCollections == false">
                            <div class="contenedorReporteComisiones">
                                <div>
                                    <app-banner-title [banner]="bannerTitle"></app-banner-title>
                                </div>

                                <div style="display: flex; flex-direction:row; justify-content: space-evenly;">
                                    <button *ngFor="let p of projects" [value]="p.name"
                                        (click)="filterWalletByProject(p.name); ">
                                        {{p.name}}
                                    </button>
                                </div>

                                <!-- <div
                                    style="padding-top:15px ; margin-left: 15px; margin-right: 15px; display: flex; flex-direction:row ; justify-content: space-between;">

                                    <div style="display:flex ; flex-direction:row; align-items: center  ;">
                                        <h5> Proyecto: </h5>
                                        <h5 style="color: #d33e3e; margin-left: 8px;" *ngIf="projSel != 'Ninguno'">
                                            {{ projSel }}
                                        </h5>
                                        <mat-icon *ngIf="projSel != ''" style="color: #d33e3e;">apartment</mat-icon>

                                    </div>

                                    <div>
                                        <button class="m19AltBtn"> Al corriente </button>
                                    </div>

                                    <div>
                                        <button class="m19AltBtn"> 1 mes de atraso </button>
                                    </div>

                                    <div>
                                        <button class="m19AltBtn"> 2 a 4 meses de atraso </button>
                                    </div>

                                    <div>
                                        <button class="m19AltBtn"> Atraso mayor a 4 meses </button>
                                    </div>


                                    <div>
                                        <button class="m19Btn"> Todos </button>
                                    </div>


                                    <div>
                  
                                    </div>
                                </div> -->

                                <div *ngIf="this.banderas.personal == true"
                                    style="Display: flex; flex-direction:row ; margin-top:20px;">
                                    <div class="col-md-4">
                                        <mat-card>
                                            <mat-card-content *ngFor="let dc of dataCS" style="display:flex; flex-direction:column; width: 100%;">
                                                <h5> Desarrollo: </h5>
                                                <h5 style="color:#d33e3e"> {{dc.name}} </h5>

                                                <h5> Cliente: </h5>
                                                <h5 style="color:#d33e3e"> {{dc.clientes}} </h5>

                                                <h5> Coordinador de ventas: </h5>
                                                <h5 style="color:#d33e3e"> {{dc.usuarios}} </h5>

                                                <h5 *ngIf="dc.vendedores != null"> vendedor: </h5>
                                                <h5 *ngIf="dc.vendedores != null" style="color:#d33e3e"> {{dc.vendedores}} </h5>

                                                <h5> Fecha de contrato: </h5>
                                                <h5 style="color:#d33e3e"> {{dc.fechaDato | date:
                                                    'dd-MM-yyyy'}} </h5>

                                                <h5> Fecha de primer pago: </h5>
                                                <h5 style="color:#d33e3e"> {{dc.firstPayDate | date:
                                                    'dd-MM-yyyy'}} </h5>

                                                <h5> Celular: </h5>
                                                <h5 style="color:#d33e3e"> {{dc.mobile}} </h5>


                                                <h5> Correo electrónico: </h5>
                                                <h5 style="color:#d33e3e"> {{dc.email}} </h5>

                                                <div style="display: flex;  flex-direction: row;"
                                                    *ngIf="dc.codeProperty.substring(2,3)=='M'">
                                                    <h5> Manzana: <b style="color:#d33e3e; "> {{
                                                        dc.codeProperty.substring(3,5) }} </b>
                                                    </h5>
                                                    <h5 style="margin-left: 15px ;"> Lote: <b style="color:#d33e3e ;">
                                                            {{ dc.codeProperty.substring(6,8) }} </b>
                                                    </h5>
                                                </div>

                                                <div style="display: flex; flex-direction: row; "
                                                    *ngIf="dc.codeProperty.substring(2,3)=='T'">
                                                    <h5> Torre: <b style="color:#d33e3e ;"> {{
                                                        dc.codeProperty.substring(3,5) }} </b>
                                                    </h5>
                                                    <h5 style="margin-left: 15px ;"> Departamento: <b
                                                            style="color:#d33e3e ;"> {{
                                                                dc.codeProperty.substring(6,8) }} </b>
                                                    </h5>
                                                </div>

                                                <h5 style="color:black"> Valor Total: <b style="color:#d33e3e">
                                                        {{(dc.valor).toFixed(2) | currency:'CAD' : '$'}} </b> </h5>

                                                <div style="width: 100%; display:flex; flex-direction: row;">


                                                    <div style="width: 50%;">
                                                        <h5>
                                                            <span class="input-group-text"
                                                                style="background-color: #d33e3e; border-radius:.3cm; color: white; height: 1cm;  text-align: center; justify-content: center;"><b>
                                                                    Abonado</b>
                                                            </span>
                                                        </h5>

                                                        <h5 style="text-align:center ;">
                                                            {{(dc.abonado) | currency: 'CAD': '$' }} </h5>
                                                    </div>

                                                    <div style="width: 50%;">

                                                        <h5>
                                                            <span class="input-group-text"
                                                                style="background-color: #d33e3e; border-radius:.3cm; color: white; height: 1cm;  text-align: center; justify-content: center;"><b>
                                                                    Adeudo</b>
                                                            </span>
                                                        </h5>

                                                        <h5 style="text-align:center ;"> {{
                                                            ((dc.valor) - (dc.abonado)) | currency: 'CAD': '$' }} </h5>
                                                    </div>

                                                </div>

                                                <table class="table table-hover mt-3">
                                                    <thead>
                                                        <tr align="center">
                                                            <td>Pagos realizados</td>
                                                            <td>Pagos esperados</td>
                                                            <td>Estado de pagos</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                {{dc.mPagadas+'/'+((dc.valor-dc.advancePayment)/dc.monthlyPayment).toFixed(1)}}
                                                            </td>

                                                            <td>
                                                                {{dc.meses+'/'+((dc.valor-dc.advancePayment)/dc.monthlyPayment).toFixed(1)}}
                                                                <ng-container
                                                                    *ngIf="dc.retraso >= 0 ; else tipo1">
                                                                    <div class="progress">
                                                                        <div class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                                                                            role="progressbar" style="width: 100%">
                                                                        </div>
                                                                    </div>

                                                                </ng-container>
                                                                <ng-template #tipo1>
                                                                    <ng-container
                                                                        *ngIf="  0 < dc.retraso || dc.retraso > -2; else tipo2">
                                                                        <div class="progress">
                                                                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                                                                role="progressbar" style="width: 100%">
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-template>

                                                                <ng-template #tipo2>
                                                                    <ng-container
                                                                        *ngIf=" -2 <= dc.retraso  || dc.retraso > -4  ; else tipo3">
                                                                        <div class="progress">
                                                                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                                                                role="progressbar" style="width: 100%">
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-template>

                                                                <ng-template #tipo3>
                                                                    <ng-container
                                                                        *ngIf=" dc.retraso <= -4; else tipo4">
                                                                        <div class="progress">
                                                                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger"
                                                                                role="progressbar" style="width: 100%">
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-template>

                                                                <ng-template #tipo4>
                                                                    <ng-container
                                                                        *ngIf=" dc.retraso is null;">
                                                                        Pagado
                                                                        <div class="progress">
                                                                            <div class="progress-bar progress-bar-striped progress-bar-animated "
                                                                                role="progressbar"
                                                                                style="width: 100%; background-color: #0000;">
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-template>
                                                            </td>

                                                            <td>
                                                                {{dc.retraso.toFixed(2) }}
                                                            </td>
                                                        </tr>
                                                    </thead>

                                                    <tbody>

                                                    </tbody>

                                                </table>

                                                <button class="btn btn-info"  (click)="selectPropertyNote(dc.codeProperty)">
                                                    <b>Nueva nota</b>
                                                </button>

                                            </mat-card-content>

                                        </mat-card>
                                    </div>

                                    <div class="col-md-8">
                                        <mat-card>
                                            <mat-card-content style="width: 100%;">
                                                <table class="table table-striped mt-3 animated fadeIn faster">
                                                    <thead class="thead-m19">
                                                        <tr>
                                                            <th scope="col">Folio</th>
                                                            <th scope="col">Fecha</th>
                                                            <th scope="col">Cantidad</th>
                                                            <th scope="col">Método de pago</th>
                                                            <th scope="col">Concepto</th>
                                                        </tr>

                                                    </thead>

                                                    <tbody>
                                                        <tr *ngFor="let p of pagosPorCliente">

                                                            <td>
                                                                {{p.id}}
                                                            </td>


                                                            <td>
                                                                {{p.fechaPago | date: 'yyyy-MM-dd'}}
                                                            </td>

                                                            <td>
                                                                {{p.cantidadAbono | currency:'CAD' : '$ ' }}
                                                            </td>

                                                            <td>
                                                                {{p.metodoPago}}
                                                            </td>

                                                            <td>
                                                                {{p.concepto}}
                                                            </td>

                                                        </tr>

                                                    </tbody>
                                                </table>

                                            </mat-card-content>

                                        </mat-card>

                                    </div>

                                </div>

                                <div *ngIf="this.banderas.general == true" class="col-md-12">

                                    <mat-card style="margin: 2px;">

                                        <div class="col-md-3"
                                            style="display:flex; flex-direction: column; row-gap: 10px;">


                                            <mat-card style="margin-left:-30px;" [hidden]="filterByProject.length == 0">

                                                <mat-card-content
                                                    style="display: flex; flex-direction:column; width: 100%;">

                                                    <h3 style="text-align:center; "> {{currentProject}} </h3>

                                                    <canvas baseChart width="100%" height="100%"
                                                        [data]="[this.counterColors.blue, this.counterColors.green, this.counterColors.yellow, this.counterColors.red]"
                                                        [labels]="pieChartLabel" [chartType]="pieChartTypes"
                                                        [options]="pieChartOption" (plugins)="pieChartPlugin"
                                                        [colors]="pieChartColor" [legend]="pieChartLegends">
                                                    </canvas>



                                                    <mat-card [hidden]="filterByProject.length == 0">
                                                        <mat-card-content style=" flex-direction:column;width: 100%;">

                                                            <h5 style="color:#d33e3e;"> Al corriente: <b
                                                                    style="color: #04868A; font-weight: bold;">
                                                                    {{this.counterColors.blue}} </b> </h5>
                                                            <h5 style="color:#d33e3e;"> Con 1 Atraso: <b
                                                                    style="color: #22D003; font-weight: bold;">
                                                                    {{this.counterColors.green}} </b> </h5>
                                                            <h5 style="color:#d33e3e;"> En riesgo: <b
                                                                    style="color: #F6CE06; font-weight: bold;">
                                                                    {{this.counterColors.yellow}} </b> </h5>
                                                            <h5 style="color:#d33e3e;"> Rescisión: <b
                                                                    style="color: #FF0202; font-weight: bold;">
                                                                    {{this.counterColors.red}} </b> </h5>

                                                            <h5
                                                                style="text-align:center; color:#d33e3e; border-top: 2px solid black; width: 100%;">
                                                                Clientes totales: <b style="color:black;">
                                                                    {{filterByProject.length}} </b> </h5>

                                                        </mat-card-content>
                                                    </mat-card>


                                                </mat-card-content>
                                            </mat-card>


                                        </div>

                                        <div class="col-md-9">
                                            <mat-card style="margin-left:-20px;">
                                                <mat-card-content style="width: 100%;">
                                                    <table class="tableHeader">
                                                        <tbody>
                                                            <tr style="width: 100%;">
                                                                <th style="width: 15%"> Inmueble </th>
                                                                <th style="width: 20%"> Cliente </th>
                                                                <th style="width: 20%"> Vendedor </th>
                                                                <th style="width: 10%"> Contrato </th>
                                                                <th style="width: 15%"> Adeudo </th>
                                                                <th style="width: 10%"> Atraso </th>
                                                                <th style="width: 10%"> Notas </th>

                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <table style="width: 100%;" *ngFor="let cus of filterByProject">
                                                        <tbody>

                                                            <tr style="text-align: left; overflow: scroll; ">

                                                                <td style="width: 15%; text-align:center">
                                                                    <button class="form-select btn btn-dark"
                                                                        (click)="mueveData(cus.codeProperty); selectPayments(cus.codeProperty)">
                                                                        {{cus.codeProperty}}
                                                                    </button>
                                                                </td>

                                                                <td style="width: 20%; text-align:center">
                                                                    {{cus.clientes}}
                                                                </td>


                                                                <td style="width: 20%; text-align:center">
                                                                    {{cus.vendedor}}
                                                                </td>

                                                                <td style="width: 10% ;text-align:center">

                                                                    {{pipe.transform(cus.fechaDato,'mediumDate')}}
                                                                </td>

                                                                <td style="width: 15%; text-align:center">
                                                                    {{cus.saldo | currency: 'CAD': '$' }}

                                                                </td>

                                                                <td style="width: 10%; text-align:center;">
                                                                    {{cus.retraso}}

                                                                    <ng-container *ngIf="cus.retraso >= 0 ; else tipo1">
                                                                        <div class="progress">
                                                                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                                                                                role="progressbar" style="width: 100%">
                                                                            </div>
                                                                        </div>

                                                                    </ng-container>
                                                                    <ng-template #tipo1>
                                                                        <ng-container
                                                                            *ngIf="  0 < cus.retraso || cus.retraso > -2; else tipo2">
                                                                            <div class="progress">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                                                                    role="progressbar"
                                                                                    style="width: 100%">
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-template>

                                                                    <ng-template #tipo2>
                                                                        <ng-container
                                                                            *ngIf=" -2 <= cus.retraso  || cus.retraso > -4  ; else tipo3">
                                                                            <div class="progress">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                                                                    role="progressbar"
                                                                                    style="width: 100%">
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-template>

                                                                    <ng-template #tipo3>
                                                                        <ng-container
                                                                            *ngIf=" cus.retraso <= -4; else tipo4">
                                                                            <div class="progress">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger"
                                                                                    role="progressbar"
                                                                                    style="width: 100%">
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-template>

                                                                    <ng-template #tipo4>
                                                                        <ng-container *ngIf=" cus.retraso is null;">
                                                                            Pagado
                                                                            <div class="progress">
                                                                                <div class="progress-bar progress-bar-striped progress-bar-animated "
                                                                                    role="progressbar"
                                                                                    style="width: 100%; background-color: #0000;">
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-template>

                                                                </td>

                                                                <td style="width: 10%; text-align:center;">
                                                                    <button class="btn btn-m19-table" mat-icon-button>
                                                                        <mat-icon
                                                                            (click)="selectNotes(cus.codeProperty)">speaker_notes</mat-icon>
                                                                    </button>
                                                                </td>

                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </mat-card-content>

                                            </mat-card>



                                        </div>


                                    </mat-card>
                                    <button class="m19Btn" (click)="cerrarCobranza()"> Cerrar </button>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </mat-card-content>
        </mat-card>

    </div>
</body>

</html>