import { DatePipe } from '@angular/common';
import { Component, forwardRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MapsService } from 'src/app/services/maps.service';
import { LogosService } from 'src/app/services/logos.service';
import { PropertyService } from 'src/app/services/property.service';
import { SaleService } from 'src/app/services/sale.service';
import { PdfMakerComponent } from 'src/app/utils/pdf-maker/pdf-maker.component';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { DialogSaleConfirmComponent } from '../dialog-sale-confirm/dialog-sale-confirm.component';
import decode from 'jwt-decode'
import { Router } from '@angular/router';

@Component({
  selector: 'app-finished-sale',
  templateUrl: './finished-sale.component.html',
  styleUrls: ['./finished-sale.component.scss'],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FinishedSaleComponent),
    }
  ]
  
})



export class FinishedSaleComponent implements OnInit {

  filterProperty = '';
  filterCustomer = '';
  selected_customer = '';
  selected_coowner = '';
  selected_seller = '';
  selected_user = '';
  selected_project = '';
  selected: any = '';
  property: any = '';
  projects: any = [];
  properties: any = [];
  sales: any = [];
  sale: any = [];
  customer: any = [];
  customers: any = [];
  coowner: any = [];
  seller: any = [];
  sellers: any = [];
  user: any = [];
  users: any = [];
  recession: any = [];
  contractDate: any;
  decodetoken: any;

  logo: string = "";

  sellerName: any = '';

  decode: any = {};

  propertyy = localStorage.getItem('InmuebleDigital')
  customerr = localStorage.getItem('Venta digital: ')
  CustomerInfo: any = [{CURP: "", RFC: "", address: "", birthDate: "", birthPlace: "", civilStatus: "", complete_name: "", email: "", localidad: "", mobile: "", municipality: "", nationality: "", occupation: "", postalCode: "", state: "", voterKey: ""}];
  propertyInfo: any = [{advancePayment: "", amount: "", codeProperty: "", monthlyPayment: "", name: "", northeasBorder: "", northwestBorder: "", sketchMap: "", southeastBorder: "", southwestBorder: ""}];
  fechaApartir: any;
  datenow = new Date();
  today: Date = new Date();
  sellerId: any = "";

  dateApartirdeWithPipe = localStorage.getItem('dateApartirdeWithPipe')
  dateApartirde = localStorage.getItem('dateApartirde')
  todayWithPipe = localStorage.getItem('todayWithPipe')



  constructor(
    private saleService: SaleService,
    public _formBuilder: FormBuilder,
    private propertyService: PropertyService,
    private mapsService: MapsService,
    private logoService: LogosService,
    public sweet: SweetAlertComponent,
    public pdfmaker: PdfMakerComponent,
    private dialogRef: MatDialogRef<DialogSaleConfirmComponent>,
    private router: Router


  ) { }

  ngOnInit(
  ): void {
    this.getSeller();
    this.propertyInfos()
    this.CustomerInfos()
    this.selectProperty(this.propertyy || '')
    console.log();
    console.log(this.dateApartirde);
  }

  saleFormGroup: FormGroup = this._formBuilder.group({
    idSale: [],
    IdContract: [],
    fk_property: [this.propertyy, Validators.required],
    fk_customer: [this.customerr, Validators.required],
    coowner: [],
    fk_seller: [(localStorage.getItem('sellerId'))],
    fk_user: [(localStorage.getItem('coordId'))],
    contractDate: [(this.datenow.getDate()<10?"0"+this.datenow.getDate():this.datenow.getDate())+"-"+((this.datenow.getMonth()+1)<10?"0"+(this.datenow.getMonth()+1):this.datenow.getMonth()+1)+"-"+this.datenow.getFullYear()],
    fechaDato: [this.datenow, Validators.required],
    firstPayDate: [this.dateApartirde, Validators.required],
    requestFinished: [0]
  });

  async propertyInfos() {
    this.mapsService.getPropertyInfo(this.propertyy || "").subscribe(
      propertyget => {
        this.propertyInfo = propertyget
        this.getLogo(this.propertyInfo.name);
        console.log("propertyInfo",this.propertyInfo)
      }
    )
  }

  async CustomerInfos() {
    this.mapsService.getCustomerInfo(this.customerr || "").subscribe(
      customerGet => {
        this.CustomerInfo = customerGet
        console.log("customerInfo",this.CustomerInfo)

      }
    )
  }

  imprimirForm() {
    console.log(this.saleFormGroup.value)
    this.createSale();
    this.router.navigate(['home'])
  }


  //Registrar ventas
  createSale() {
    if (this.saleFormGroup.invalid) {
      return;
    }
    else {
      this.saleService.createSale(this.saleFormGroup.value).subscribe(
        res => {

          console.log(res)

          if(res.code == 'ER_DUP_ENTRY'){
            this.sweet.AlertTime("center", "success", "Venta no registrada, se intenta vender una unidad, vendida", false, 2000);
          }else{
            this.updatePropertyStatus(this.property.codeProperty);
            this.updatePropertySalesCatalog(this.property.codeProperty);
            this.sweet.AlertTime("center", "success", "Venta registrada con éxito", false, 2000);
            this.dialogRef.close();
            localStorage.removeItem('contractDate');
          }
        },
        err => console.error(err)
      );
      console.log(this.saleFormGroup.value);
    }
  }

  //CONSULTAR INMUEBLE EN ESPECIFICO
  selectProperty(codeProperty: string) {
    this.propertyService.selectProperty(codeProperty).subscribe(
      res => {
        this.property = res;
      },
      err => console.log(err)
    );
  }

  updatePropertyStatus(codeProperty: string) {
    this.propertyService.updatePropertyStatus(codeProperty, this.property).subscribe(
      res => {
        this.property.fk_status = 3,
          this.property.modificationDate = new Date();
      },
      err => console.error(err)
    )
  }

  updatePropertySalesCatalog(codeProperty: string) {
    this.mapsService.propertyMapstatus(codeProperty).subscribe(
      res => {
        console.log(res)
      },
      err => console.error(err)
    )
  }

  async generateRequest() {
    //const hoy = moment().format("L"); 
    this.pdfmaker.genereteRequestAllComponents("hoy", "X", this.propertyInfo.name, this.propertyInfo.codeProperty.substring(3, 5),
      this.propertyInfo.codeProperty.substring(6, 8), this.CustomerInfo.nationality, this.CustomerInfo.complete_name,
      this.CustomerInfo.civilStatus, this.CustomerInfo.birthDate, this.CustomerInfo.occupation, this.CustomerInfo.brithPlace,
      this.CustomerInfo.address, this.CustomerInfo.postalCode, this.CustomerInfo.localidad, this.CustomerInfo.state,
      this.CustomerInfo.municipality, this.CustomerInfo.mobile, this.CustomerInfo.voterKey, this.CustomerInfo.CURP,
      this.CustomerInfo.email, this.CustomerInfo.RFC, this.propertyInfo.codeProperty, this.propertyInfo.amount,
      this.propertyInfo.advancePayment, this.propertyInfo.monthlyPayment, "", "", this.fechaApartir,
      this.propertyInfo.northeasBorder, this.propertyInfo.northwestBorder, this.propertyInfo.southeastBorder,
      this.propertyInfo.southwestBorder, this.propertyInfo.sketchMap)

  }

  printer() {
    const printContent = document.getElementById("htmlData");
    const WindowPrt = window.open('', '', '');
    WindowPrt!.document.write(printContent!.innerHTML);
    WindowPrt!.document.close();
    WindowPrt!.focus();
    WindowPrt!.print();
    WindowPrt!.close();
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlData')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
      <html>
        <head>
          <title>${this.propertyInfo.codeProperty} - ${this.CustomerInfo.complete_name}</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin!.document.close();
  }

  //==========================================================================================================
  //Recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getSeller() {
    this.decode = decode(localStorage.getItem("token") || "")
    this.sellerName = this.decode.cliente;
  }

  getLogo(project: string) {
    this.logoService.getProjectLogo(project).subscribe(
      res => {
        console.log("Data: ", res.UrlLogo);
        this.logo = res.UrlLogo;
      }
    );
  }
}