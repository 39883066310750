<mat-dialog-content>
    <div class="scheduleContainer">
        <span class="detailContainer__detailTitle">
            <span><b>Agendando pago de {{data.Customer}}</b></span>
        </span>

        <form [formGroup]="scheduleFormGroup" (ngSubmit)="schedulePayback()"
            onKeyPress="if(event.keyCode == 13) event.returnValue = false;">

            <div class="registerRow">
                <mat-form-field appearance="outline">
                    <mat-label>Fecha de devolución</mat-label>
                    <input matInput type="date" formControlName="PaybackDate" required>
                    <mat-icon matSuffix>calendar_month</mat-icon>
                </mat-form-field>
                
                <mat-form-field appearance="outline">
                    <mat-label>Monto a devolver</mat-label>
                    <input matInput type="number" formControlName="PaybackAmount" min="1" required>
                    <mat-icon matSuffix>numbers</mat-icon>
                </mat-form-field>
            </div>

            <button type="submit" class="m19Btn" [disabled]="!scheduleFormGroup.valid">
                <mat-icon>save</mat-icon>
            </button>
        </form>

    </div>
</mat-dialog-content>