import { Component, OnInit } from '@angular/core';
import { LogosService } from 'src/app/services/logos.service';
import { BannerTitleComponent } from 'src/app/ui/banner-title/banner-title.component';
import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';

@Component({
  selector: 'app-mail-verifier',
  templateUrl: './mail-verifier.component.html',
  styleUrls: ['./mail-verifier.component.scss']
})
export class MailVerifierComponent implements OnInit {

  constructor(
    private logosServ: LogosService
  ) { }

  mailVefifier :  BannerTitle = {
    title: "Pantalla de prueba",
    icon: "mail",
    background: "var(--M19Secundary)",
    position: "center"

  }

  arrayLogos: any = []; //Almacena el logo que se muestra en pantalla

  generador : any = 0;

  mensaje : any;

  banderas = {
    ingresarCorreo : false,
    generarCodigo : true
  }

  ngOnInit(): void {
    this.generador = Math.floor(Math.random() * 99999)
  }

    //Obtiene los logotipos de M19 
    async getLogo() {
      this.logosServ.getAllImagesService().subscribe(
        res => {
          this.arrayLogos = res;
        }
      )
    }

    activaCodigo(){
      this.banderas.generarCodigo = true;
      this.banderas.ingresarCorreo = false;
    }

    activaCorreo(){
      this.banderas.ingresarCorreo = true;
      this.banderas.generarCodigo = false;
    }

    

}
