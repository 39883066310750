<div class="row _card">
    <div class="col-md-4">
        <mat-card>
            <mat-card-content>
                <form class="row mt-3" [formGroup]="paymentMethodFormGroup" (ngSubmit)="createPaymentMethod()">

                    <h5 class="col-12 pb-2">Nuevo método de pago</h5>

                    <mat-form-field appearance="outline" class="col-md-12">
                        <mat-label>Descripción</mat-label>
                        <input matInput type="text" formControlName="formaPago" required>
                    </mat-form-field>

                    <div class="col-12 text-right mt-3">
                        <button class="btn btn-m19" type="submit" [disabled]="!paymentMethodFormGroup.valid">
                            <mat-icon>save</mat-icon>
                        </button>
                    </div>

                </form>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="col-md-8">
        <mat-card>
            <mat-card-content>
                <table class="table table-striped animated fadeIn faster">
                    <thead class="thead-m19">
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Descripción</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let method of payment_method">
                            <td>{{method.id}}</td>
                            <td>{{method.formaPago}}</td>
                            <td class="button-row">
                                <button class="btn btn-m19-table" mat-icon-button>
                                    <mat-icon>create</mat-icon>
                                </button>
                                <button class="btn btn-m19-table" (click)="deletePaymentMethod(method.id)" mat-icon-button>
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-card-content>
        </mat-card>
    </div>
</div>