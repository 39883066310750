import { Component, OnInit } from '@angular/core';

import { ProjectService } from 'src/app/services/project.service';
import { LeadsService } from 'src/app/services/leads.service';

import { LeadExtensionComponent } from '../lead-extension/lead-extension.component';
import { LeadsReassignComponent } from '../leads-reassign/leads-reassign.component';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { MatDialog } from '@angular/material/dialog';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';
import Swal from 'sweetalert2';
import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';
import { GenerateCommentComponent } from '../generate-comment/generate-comment.component';
import { LeadTimeLineComponent } from '../lead-time-line/lead-time-line.component';
import { AllRegisteredComponent } from '../generalControl/all-registered/all-registered.component';

import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss']
})
export class PrincipalComponent implements OnInit {

  bannerTitle: BannerTitle = {
    title: "Control de Leads",
    icon: "manage_search",
    position: "center",
    background: "var(--M19Secundary)"
  }

  //Objeto para almacenar los proyectos
  projects: any = [];

  prospects: any = [];

  pipe = new DatePipe('es-MX');

  lead: any = [];

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  coordsList: any = [];

  constructor(
    public projectService: ProjectService,
    private leadsService: LeadsService,
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    public dialog: MatDialog
  ) { }

  //==========================================================================================================
  //Objeto constructor del formulario utilizado en la pantalla de requisición, se agrega el código 
  //", Validators.required" para señalar que es un valor que no debe estar vacío
  //==========================================================================================================
  leadsFormGroup: FormGroup = this._formBuilder.group({
    idlead: [],
    fk_interest: [, Validators.required],
    mobile: [null],
    email: [null],
    name: [, Validators.required],
    firstLastName: [null],
    secondLastName: [null],
    notes: [null],
    useremail: [this.getUser()]
  });

  ngOnInit(): void {
    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
    this.selectProjects();
    this.selectLastLeads();
    this.getCoordinators();
  }

  //==========================================================================================================
  //Función para buscar los proyectos registrados en la base de datos
  //==========================================================================================================
  async selectProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        //Se recuperan todos los proyectos en la variable projects
        this.projects = res;
      },
      err => console.log(err)
    );
  }
  //==========================================================================================================
  //Recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.fkuser
  }

  //==========================================================================================================
  //Función para insertar una requisición en la base de datos
  //==========================================================================================================
  async insert() {
    this.cleanWhitespace();
    if (!this.leadsFormGroup.valid) {
      this.sweet.AlertTime("center", "error", "Los campos de interés y nombre son necesarios, por favor confirme que ambos fueron capturados correctamente", false, 2500);
    } else {
      if ((this.leadsFormGroup.controls["mobile"].value != null || this.leadsFormGroup.controls["mobile"].value != undefined) && (this.leadsFormGroup.controls["email"].value != null || this.leadsFormGroup.controls["email"].value != undefined) ||
        (this.leadsFormGroup.controls["mobile"].value != null || this.leadsFormGroup.controls["mobile"].value != undefined) && (this.leadsFormGroup.controls["email"].value == null || this.leadsFormGroup.controls["email"].value == undefined) ||
        (this.leadsFormGroup.controls["mobile"].value == null || this.leadsFormGroup.controls["mobile"].value == undefined) && (this.leadsFormGroup.controls["email"].value != null || this.leadsFormGroup.controls["email"].value != undefined)) {
        Swal.fire({
          title: '¿Estás seguro que quieres guardar ahora?',
          text: "¡Este prospecto se registrará con la información capturada hasta ahora!",
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'No, espera',
          confirmButtonText: 'Sí, registralo!',
          buttonsStyling: false,
          customClass: {
            confirmButton: "m19AltBtn",
            cancelButton: "m19Btn",
            actions: "sweetActions"
          }
        }).then((result) => {
          if (result.isConfirmed) {
            // this.leadsService.leadVerification(this.leadsFormGroup.controls["email"].value, this.leadsFormGroup.controls["mobile"].value, this.leadsFormGroup.controls["fk_interest"].value).subscribe(
            //   res => {
            //     if (res.length > 0) {
            //       this.sweet.AlertTime("center", "error", "Ya existe un usuario registrado con ese correo o teléfono interesado en este proyecto.", false, 2500);
            //     } else {
            this.leadsService.leadRegister(this.leadsFormGroup.value).subscribe(
              res1 => {
                let lead = {
                  "action": "Registro",
                  "responsable": this.getUser(),
                  "secondResponsable": null,
                  "fk_leads": res1
                }
                this.leadsService.leadsLogWrite(lead).subscribe(
                  res2 => {
                    this.sweet.AlertTime("center", "success", "Registro realizado con éxito.", false, 2500);
                    this.selectLastLeads();
                    this.leadsFormGroup.controls["mobile"].setValue("");
                    this.leadsFormGroup.controls["email"].setValue("");
                    this.leadsFormGroup.controls["name"].setValue("");
                    this.leadsFormGroup.controls["firstLastName"].setValue("");
                    this.leadsFormGroup.controls["secondLastName"].setValue("");
                    this.leadsFormGroup.controls["notes"].setValue("");
                  }
                );
              }
            );
          }
        })
      } else {
        this.sweet.AlertTime("center", "error", "Por favor registre al menos una de las dos opciones entre teléfono y correo.", false, 2500);
      }
    }
  }

  async selectLastLeads() {
    this.leadsService.getLastLeads().subscribe(
      res => {
        this.prospects = res;
        console.log(this.prospects);
      }
    );
  }

  async giveMoreTime(cas: any) {
    let dialog = this.dialog.open(LeadExtensionComponent, {
      width: "70%",
      data: cas
    });

    dialog.afterClosed().subscribe(
      res => {
        this.selectLastLeads();
      }
    );
  }

  async reassignLead(cas: any) {
    let reas = this.dialog.open(LeadsReassignComponent, {
      width: "70%",
      data: cas
    });

    reas.afterClosed().subscribe(
      res => {
        this.selectLastLeads();
      }
    );
  }

  async details(idlead: number, status: String) {
    this.leadsService.getLeadInformation(idlead).subscribe(
      res => {
        this.lead = res;
        this.prepareModal(idlead, status);
      }
    );
  }

  async cleanWhitespace() {
    this.leadsFormGroup.controls["mobile"].setValue(this.leadsFormGroup.controls["mobile"].value == null ? this.leadsFormGroup.controls["mobile"].value : this.leadsFormGroup.controls["mobile"].value.trim());
    this.leadsFormGroup.controls["email"].setValue(this.leadsFormGroup.controls["email"].value == null ? this.leadsFormGroup.controls["email"].value : this.leadsFormGroup.controls["email"].value.trim());
    this.leadsFormGroup.controls["name"].setValue(this.leadsFormGroup.controls["name"].value == null ? this.leadsFormGroup.controls["name"].value : this.leadsFormGroup.controls["name"].value.trim());
    this.leadsFormGroup.controls["firstLastName"].setValue(this.leadsFormGroup.controls["firstLastName"].value == null ? this.leadsFormGroup.controls["firstLastName"].value : this.leadsFormGroup.controls["firstLastName"].value.trim());
    this.leadsFormGroup.controls["secondLastName"].setValue(this.leadsFormGroup.controls["secondLastName"].value == null ? this.leadsFormGroup.controls["secondLastName"].value : this.leadsFormGroup.controls["secondLastName"].value.trim());
    this.leadsFormGroup.controls["notes"].setValue(this.leadsFormGroup.controls["notes"].value == null ? this.leadsFormGroup.controls["notes"].value : this.leadsFormGroup.controls["notes"].value.trim());
  }

  async prepareModal(idlead: number, status: any) {
    if (status == null) {
      const { value: Assign } = await Swal.fire({
        title: 'Información de prospecto',
        width: '80%',
        html: "Prospecto  <b>" + this.lead.name + " " + ((this.lead.firstLastName == null || this.lead.firstLastName == '') ? '' : this.lead.firstLastName) + " " + ((this.lead.secondLastName == null || this.lead.secondLastName == '') ? '' : this.lead.secondLastName) + "</b> con interés en <b>" + this.lead.interest + ".</b><br><br>"
          + "Fue registrado por <b>" + this.lead.responsable + "</b> el día <b>" + this.pipe.transform(this.lead.registrationdate, "longDate") + "</b> y dejó como información de contacto " + ((this.lead.mobile == null || this.lead.mobile == '') ? '' : "el número de teléfono <b>" + this.lead.mobile + "</b>") + (((this.lead.mobile != null && this.lead.mobile != '') && (this.lead.email != null && this.lead.mobile != '')) ? ' y ' : '') + ((this.lead.email == null || this.lead.email == "") ? ".<br>" : "el correo <b>" + this.lead.email + ".</b><br>")
          + (this.lead.secondResponsable == null ? "" : "<br>Fue asignado a <b>" + this.lead.secondResponsable + "</b> el día <b>" + this.pipe.transform(this.lead.assignationDate, "longDate") + ".</b>") + "<br><br>"
          + (this.lead.notes == null ? '' : this.lead.notes == '' ? '' : "Se agregó el siguiente comentario: <br><b>" + this.lead.notes + "</b>"),
        input: 'select',
        inputOptions: this.coordsList,
        inputPlaceholder: 'Seleccione un coordinador para asignarle este lead',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Guardar',
        customClass: {
          actions: 'sweetActions',
          confirmButton: 'm19AltBtn',
          cancelButton: 'm19Btn',
          input: 'sweetInput'
        },
        buttonsStyling: false,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value === '') {
              resolve('Debes elegir una opción de la lista o hacer clic en cancelar')
            } else {
              resolve('');
            }
          })
        }
      })

      if (Assign) {
        let lead = {
          "action": "Asignado",
          "responsable": this.getUser(),
          "secondResponsable": Assign,
          "fk_leads": idlead
        }

        this.leadsService.leadsLogWrite(lead).subscribe(
          res => {
            Swal.fire({
              icon: 'success',
              title: 'Guardado',
              html: "Se ha asignado este lead.",
              showConfirmButton: true,
              timer: 10000
            })
            this.selectLastLeads();
          }
        )
      }

    } else {
      const { value: Assign } = await Swal.fire({
        title: 'Información de prospecto',
        width: '80%',
        confirmButtonText: 'Entendido',
        customClass: {
          confirmButton: 'm19Btn'
        },
        buttonsStyling: false,
        html: "Prospecto  <b>" + this.lead.name + " " + ((this.lead.firstLastName == null || this.lead.firstLastName == '') ? '' : this.lead.firstLastName) + " " + ((this.lead.secondLastName == null || this.lead.secondLastName == '') ? '' : this.lead.secondLastName) + "</b> con interés en <b>" + this.lead.interest + ".</b><br><br>"
          + "Fue registrado por <b>" + this.lead.responsable + "</b> el día <b>" + this.pipe.transform(this.lead.registrationdate, "longDate") + "</b> y dejó como información de contacto " + ((this.lead.mobile == null || this.lead.mobile == '') ? '' : "el número de teléfono <b>" + this.lead.mobile + "</b>") + (((this.lead.mobile != null && this.lead.mobile != '') && (this.lead.email != null && this.lead.mobile != '')) ? ' y ' : '') + ((this.lead.email == null || this.lead.email == "") ? ".<br>" : "el correo <b>" + this.lead.email + ".</b><br>")
          + (this.lead.secondResponsable == null ? "" : "<br>Fue asignado a <b>" + this.lead.secondResponsable + "</b> el día <b>" + this.pipe.transform(this.lead.assignationDate, "longDate") + ".</b>") + "<br><br>"
          + (this.lead.notes == null ? '' : this.lead.notes == '' ? '' : "Se agregó el siguiente comentario: <br><b>" + this.lead.notes + "</b>")
      })
    }
  }

  async getCoordinators() {
    this.leadsService.getCoordinators().subscribe(
      res => {
        console.log(res);
        for (let c of res) {
          this.coordsList[c.idcoord] = c.coordName;
        }
      }
    );
  }

  addComment(id: number) {
    this.dialog.open(GenerateCommentComponent, {
      data: id,
      width: '100%'
    });
  }

  seeTimeline(id: number) {
    this.dialog.open(LeadTimeLineComponent, {
      data: id,
      width: "100%"
    });
  }

  setLeadCall(id: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡Marcarás este lead como llamado!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, estoy seguro!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.leadsService.setLeadCall(id).subscribe(
          res => {
            let lead = {
              "action": "Llamada",
              "responsable": this.getUser(),
              "secondResponsable": null,
              "fk_leads": id
            }

            this.leadsService.leadsLogWrite(lead).subscribe(
              res => {
                Swal.fire({
                  icon: 'success',
                  title: 'Guardado',
                  html: "Se ha registrado la llamada a este prospecto.",
                  timer: 1000
                })

                this.selectLastLeads();
              }
            );
          }
        );
      }
    })
  }

  seeAll() {
    this.dialog.open(AllRegisteredComponent, {
      width: '100%'
    });
  }
}