//Importaciones de componentes
import { Component, OnInit } from '@angular/core';
import { Chart, ChartOptions } from 'chart.js';
import { MatDialog } from '@angular/material/dialog';

//Importación de servicios
import { ProjectService } from 'src/app/services/project.service';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';
import { BudgetService } from '../../../services/budget.service';
import { BudgetPieceworkService } from 'src/app/services/budget-piecework.service';

@Component({
  selector: 'app-budgetreport',
  templateUrl: './budgetreport.component.html',
  styleUrls: ['./budgetreport.component.scss']
})
export class BudgetreportComponent implements OnInit {
  //------------------------VARIABLES PARA EL REPORTE DE PRESUPUESTO DE MATERIALES-----------------------------
  //Arreglos para almacenar datos traídos de la BD
  //Objeto para almacenar los proyectos
  projects: any = [];
  //Objeto para almacenar los modulos
  modules: any = [];
  //Objeto para almacenar un modulo 
  module: any = [];
  //Objeto para almacenar las partidas
  departures: any = [];
  //Objeto para almacenar los presupuestos
  budgets: any = [];
  //Objeto para almacenar el total
  total: any = [];

  //Arreglos para valores de configuración del gráfico
  valuesOfP: any = [];
  labelsOfP: any = [];
  colorsOfP: any = [];

  //Arreglo de los datos de pago por proyecto
  infoP: any = { idProject: '', name: '', TotalPagado: '', Excedente: '' };

  //Arreglo de los datos de pago por modulo
  infoM: any = { idprojectdet: '', towernumber: '', TotalPagado: '', Excedente: '' };

  //Arreglo de los datos de pago por partida
  infoD: any = { iddeparture: '', departurenumber: '', nombrePartida: '', totalPartida: '' };

  //Banderas para saber si se debe desplegar la gráfica o no
  projectDet: any = false;
  moduleDet: any = false;
  departureDet: any = false;
  

  constructor(
    //Declaración de servicios utilizados
    private projectService: ProjectService,
    private projectdetailService: ProjectdetailsService,
    private budgetService: BudgetService,
    private budgetpieceworkService: BudgetPieceworkService,

    //======================================================================
    //Declaración del componente gráfico utilizado para cuadro de dialogo
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getProjects();
    this.getTotalProjects();

  }
  //-----------------------------REPORTE DE PRESUPUESTO DE MATERIALES---------------------------------------//
   //*******************************Gráfico general de proyectos***********************************/
  //Función de configuración del gráfico
  async chartConfigProjects() {
    //Referencia al objeto canva HTML al que se carga el gráfico
    const budgetCanvas = document.getElementById('ProjectsChart') as HTMLCanvasElement;

    //Reseteo de arreglos de atributos
    this.valuesOfP = [];
    this.labelsOfP = [];
    this.colorsOfP = [];

    //Configuración de fuente de la gráfica
    Chart.defaults.global.defaultFontFamily = "Arial";
    Chart.defaults.global.defaultFontSize = 14;

    //Recuperación de los atributos para la tabla
    for (let t of this.total) {
      this.valuesOfP.push(t.totalProyecto);
      this.labelsOfP.push(t.name);
      this.colorsOfP.push('rgb(255, 100, 100)');
    }

    //Asignación de información
    const budgetData = {
      labels: this.labelsOfP,
      datasets: [{
        label: "Gasto de presupuesto por proyectos",
        data: this.valuesOfP,
        backgroundColor: this.colorsOfP
      }]
    };

    //Configuración básica de gráfico
    const chartOptions: ChartOptions = {
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxWidth: 80,
          fontColor: 'black'
        }
      }
    };

    //Generación del gráfico
    const lineChart = new Chart(budgetCanvas, {
      type: 'doughnut',
      data: budgetData,
      options: chartOptions
    });
  }

  //Función de configuración del gráfico
  async chartConfig() {
    //Referencia al objeto canva HTML al que se carga el gráfico
    const budgetCanvas = document.getElementById('budgetChart') as HTMLCanvasElement;

    //Reseteo de arreglos de atributos
    this.valuesOfP = [];
    this.labelsOfP = [];
    this.colorsOfP = [];

    //Configuración de fuente de la gráfica
    Chart.defaults.global.defaultFontFamily = "Arial";
    Chart.defaults.global.defaultFontSize = 14;

    //Recuperación de los atributos para la tabla
    for (let b of this.budgets) {
      this.valuesOfP.push(b.TotalPagado);
      this.labelsOfP.push("Presupuesto número: " + b.idbudget);
      this.colorsOfP.push('rgb(211, 62, 62)');
    }

    //Asignación de información
    const budgetData = {
      labels: this.labelsOfP,
      datasets: [{
        label: "Gasto por presupuesto",
        data: this.valuesOfP,
        backgroundColor: this.colorsOfP
      }]
    };

    //Configuración básica de gráfico
    const chartOptions: ChartOptions = {
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxWidth: 80,
          fontColor: 'black'
        }
      }
    };

    //Generación del gráfico
    const lineChart = new Chart(budgetCanvas, {
      type: 'bar',
      data: budgetData,
      options: chartOptions
    });
  }

  //****************************Segundo gráfico***********************************/
  //Función de configuración del gráfico
  async chartConfigModule() {
    //Referencia al objeto canva HTML al que se carga el gráfico
    const budgetCanvas = document.getElementById('moduleChart') as HTMLCanvasElement;

    //Reseteo de arreglos de atributos
    this.valuesOfP = [];
    this.labelsOfP = [];
    this.colorsOfP = [];

    //Configuración de fuente de la gráfica
    Chart.defaults.global.defaultFontFamily = "Arial";
    Chart.defaults.global.defaultFontSize = 14;

    //Recuperación de los atributos para la tabla
    for (let m of this.module) {
      this.valuesOfP.push(m.TotalPagado);
      this.labelsOfP.push("Módulo " + m.towernumber);
      this.colorsOfP.push('rgb(211, 62, 62)');
    }

    //Asignación de información
    const budgetData = {
      labels: this.labelsOfP,
      datasets: [{
        label: "Gasto de presupuesto por módulos",
        data: this.valuesOfP,
        backgroundColor: this.colorsOfP
      }]
    };

    //Configuración básica de gráfico
    const chartOptions: ChartOptions = {
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxWidth: 80,
          fontColor: 'black'
        }
      }
    };

    //Generación del gráfico
    const lineChart = new Chart(budgetCanvas, {
      type: 'bar',
      data: budgetData,
      options: chartOptions
    });
  }

  //****************************Tercer gráfico***********************************/
  //Función de configuración del gráfico
  async chartConfigDeparture() {
    //Referencia al objeto canva HTML al que se carga el gráfico
    const budgetCanvas = document.getElementById('departureChart') as HTMLCanvasElement;

    //Reseteo de arreglos de atributos
    this.valuesOfP = [];
    this.labelsOfP = [];
    this.colorsOfP = [];

    //Configuración de fuente de la gráfica
    Chart.defaults.global.defaultFontFamily = "Arial";
    Chart.defaults.global.defaultFontSize = 14;

    //Recuperación de los atributos para la tabla
    for (let d of this.departures) {
      this.valuesOfP.push(d.totalPartida);
      this.labelsOfP.push(d.nombrePartida);
      this.colorsOfP.push('rgb(211, 62, 62)');
    }

    //Asignación de información
    const budgetData = {
      labels: this.labelsOfP,
      datasets: [{
        label: "Gasto de presupuesto por partida",
        data: this.valuesOfP,
        backgroundColor: this.colorsOfP
      }]
    };

    //Configuración básica de gráfico
    const chartOptions: ChartOptions = {
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxWidth: 80,
          fontColor: 'black'
        }
      }
    };

    //Generación del gráfico
    const lineChart = new Chart(budgetCanvas, {
      type: 'bar',
      data: budgetData,
      options: chartOptions
    });
  }

  //A-1 Función para traer el catálogo de proyectos registrados en la BD
  async getProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      }
    );
  }

  //A-2 Función para traer los totales de gastos por proyecto
  async getTotalProject(id: number) {
    this.projectDet = false;
    this.modules = [];
    this.departures = [];
    this.infoM = { idprojectdet: '', towernumber: '', TotalPagado: '', Excedente: '' };
    this.infoD = { iddeparture: '', departurenumber: '', nombrePartida: '', totalPartida: '' };
    this.budgetService.getTotalProject(id).subscribe(
      res => {
        if (res === null) {
          this.infoP = { idProject: '', name: '', TotalPagado: '', Excedente: '' };
          return;
        }
        this.infoP = res;
        this.getModules(this.infoP.idProject);
        this.getModule(this.infoP.idProject);
        this.getDeparture(this.infoP.idProject);
        this.getTotalProjectByBudget(this.infoP.idProject);
      }
    );
  }

  //B-1 Función para traer los totales desglozados por presupuesto
  async getTotalProjectByBudget(id: number) {
    this.budgetService.getTotalProjectBudget(id).subscribe(
      res => {
        this.budgets = res;
      }
    );
  }


  //D-1 Función para traer los modulos de determinado proyecto
  async getModules(id: number) {
    this.projectdetailService.selectDetailsById(id).subscribe(
      res => {
        this.modules = res;
      }
    );
  }

  //D-2 Función para traer los totales de gasto por modulo
  async getTotalModule(id: number) {
    this.budgetService.getTotalModule(id).subscribe(
      res => {
        if (res === null) {
          this.infoM = { idprojectdet: '', towernumber: '', TotalPagado: '', Excedente: '' };
          return;
        }
        this.infoM = res;
      }
    );
  }

  //C-1 Función para traer los totales de partida
  async getTotalDeparture(id: number) {
    this.budgetService.getTotalDeparture(id).subscribe(
      res => {
        if (res === null) {
          this.infoD = { iddeparture: '', departurenumber: '', nombrePartida: '', totalPartida: '' };
          return;
        }
        this.infoD = res;
      }
    );
  }

  //C-1 Función para traer los totales de partida
  async getModule(id: number) {
    this.budgetService.getModule(id).subscribe(
      res => {
        this.module = res;
      }
    );
  }

  //D-1 Función para traer las partidas de determinado proyecto
  async getDeparture(id: number) {
    this.budgetService.getDeparture(id).subscribe(
      res => {
        this.departures = res;
      }
    );
  }

  //Función para alternar entre desplegar o no la gráfica
  async desgloceDatos() {
    this.projectDet = !this.projectDet;
    if (this.projectDet) {
      this.chartConfig();
    }
  }

  //Función para alternar entre desplegar o no la gráfica
  async desgloceDatosModule() {
    this.moduleDet = !this.moduleDet;
    if (this.moduleDet) {
      this.chartConfigModule();
    }
  }

  //Función para alternar entre desplegar o no la gráfica
  async desgloceDatosDeparture() {
    this.departureDet = !this.departureDet;
    if (this.departureDet) {
      this.chartConfigDeparture();
    }
  }

  //A-1 Función para traer el catálogo de proyectos registrados en la BD
  async getTotalProjects() {
    this.budgetService.getTotalProjects().subscribe(
      res => {
        this.total = res;
        console.log(this.total);
        this.chartConfigProjects();
      }
    );
  }

}
