// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //API 10/JULIO/2023
  URLServer: "https://api.stardevs.com.mx/api",
  __URLServer: "http://192.168.1.76:3000/api",
  _URLServer: "http://localhost:3000/api",

  firebaseConfig: {
    apiKey: "AIzaSyACEO1buJ-0RIK7RWIryqQOXW6Er9oxLzY",
    authDomain: "m19inmobiliaria-f6068.firebaseapp.com",
    projectId: "m19inmobiliaria-f6068",
    storageBucket: "m19inmobiliaria-f6068.appspot.com",
    messagingSenderId: "178223678014",
    appId: "1:178223678014:web:46f51215a29dcdd446876f"
  },
  firebaseCBooklog: {
    apiKey: "AIzaSyCsYFyetJrFIH9NA82tz1aUdxK3GBOxut0",
    authDomain: "m19-logbook.firebaseapp.com",
    projectId: "m19-logbook",
    storageBucket: "m19-logbook.appspot.com",
    messagingSenderId: "669028837862",
    appId: "1:669028837862:web:0ba4dbbc4d714056fc0e27",
    measurementId: "G-YHKZ1EJ768"
  }
};
