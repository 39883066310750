//=========================================================================================================
//Importaciones de servicios necesitados
//=========================================================================================================
import { PieceworkService } from 'src/app/services/piecework.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';

//=========================================================================================================
//Importaciones de los componentes necesitados
//=========================================================================================================
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPieceworksComponent } from '../pieceworkconsult/dialog-pieceworks/dialog-pieceworks.component';
import { PieceworkUpdateComponent } from '../pieceworkconsult/piecework-update/piecework-update.component';

import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';

import { SweetAlertComponent } from '../../../utils/sweet-alert/sweet-alert.component';
import Swal from 'sweetalert2';
import decode from 'jwt-decode';

@Component({
  selector: 'app-pieceworkedit',
  templateUrl: './pieceworkedit.component.html',
  styleUrls: ['./pieceworkedit.component.scss']
})
export class PieceworkeditComponent implements OnInit {
  bannerTitle: BannerTitle = {
    title: "Actualizar destajos",
    icon: "handyman",
    position: "center",
    background: "var(--M19Secundary)"
  }

  cbrLogo: any = "https://firebasestorage.googleapis.com/v0/b/m19inmobiliaria-f6068.appspot.com/o/logos%2FConstructora%2FASESORIA%2C%20SUPERVISI%C3%93N%20Y%20CONTRUCCI%C3%93N.png?alt=media&token=356f1765-4f7e-48af-9fb9-bc6584b0b09a";

  projects: any = [];
  towers: any = [];
  weeks: any = [];

  project: any = '';
  tower: any = 0;
  week: any = 0;
  maker: any = '';
  auth: any = '';
  lastpiecework: any ;

  //Objeto que contiene las partidas encontradas al seleccionar una requisición
  pieceworks: any = [];
  piece: any = [];

  modules: any = [];

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};
  constructor(  
    
    //======================================================================
  //Declaraciones de los servicios utilizados
  public pieceworkService: PieceworkService,
  public projectService: ProjectService,
  public projectDetailService: ProjectdetailsService,



  public sweet: SweetAlertComponent,
  //======================================================================
  //Declaración del componente gráfico utilizado para cuadro de dialogo
  public dialog: MatDialog) { 
  }

  ngOnInit(): void {
    this.getProjects();
    this.getlastPiecework();
  }

  setAuth(o: any) {
    if (o.data !== null) {
      this.auth = this.auth + o.data;
    }
    if (o.data == null) {
      this.auth = this.auth.substring(0, this.auth.length - 1);
    }
    console.log(this.auth);
  }

  getModulesByProject(id: any, name: any) {
    this.project = name;
    this.pieceworks = [];
    this.projectDetailService.selectDetailsById(id).subscribe(
      res => {
        this.modules = res;
      }
    );
  }

  filterByModule(id: any) {
    this.tower = id;
    this.pieceworks = [];
    this.pieceworkService.getWeeks(id).subscribe(
      res => {
        this.weeks = res;
      }
    );
  }

  setWeek(w: number, t: number) {
    this.pieceworks = [];
    this.pieceworkService.searchWeeks(w, t).subscribe(
      res => {
        this.pieceworks = res;
        console.log(this.pieceworks);
        this.week = w;
      }
    );
  }

  async getProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      }
    );
  }

  //=========================================================================================================
  //Función para elegir los destajos de una requisición por su ID
  //=========================================================================================================
  async selectPiecework(num: number) {
    this.dialog.open(DialogPieceworksComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '100%',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: num
    });
  }

  async editPiecework(week: any, idp: any, idP: any, idPi:any) {
      let info = { 'week': week, 'idprojectdet': idp, 'idpiecework_content': idP, 'idPiecework':idPi };
      if (idPi == this.lastpiecework.last) {
      let ud = this.dialog.open(PieceworkUpdateComponent, {
        width: '100%',
        data: info
      });

      ud.afterClosed().subscribe(
        res => {
          this.filterByModule(this.tower);
        }
      );
    } else {
      Swal.fire(
        'Error',
        'Pasó el tiempo de edición, este destajo no puede ser editado.',
        'warning'
      )
    }
  }

  //=========================================================================================================
  //Función que llama un cuadro de dialogo de confirmación para borrar presupuesto por su ID
  //=========================================================================================================
  async deletePiecework(id: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, mejor no',
      confirmButtonText: 'Sí, elimínalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'Este destajo fue eliminado.',
          'success'
        )
        /*this.pieceworkService.deletePiece(id).subscribe(
          res => {
            this.setWeek(this.week);
          }
        )*/
      }
    })
  }

  async getlastPiecework() {
    this.pieceworkService.getLastPieceworks().subscribe(
      res => {
        this.lastpiecework = res;
        console.log('Numero',this.lastpiecework);
      },
      err => console.log(err)
    );
  }
}