import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeneralReportService } from 'src/app/services/generalReport.service';

@Component({
  selector: 'app-dialog-view-blocked',
  templateUrl: './dialog-view-blocked.component.html',
  styleUrls: ['./dialog-view-blocked.component.scss']
})
export class DialogViewBlockedComponent implements OnInit {

  customersBlocked: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {

    this.customersBlocked = data;

  }

  ngOnInit(): void {
    console.log(this.customersBlocked)
  }

}
