<mat-dialog-content>
    <h5 class="titleText" style="text-align: center;">Historial de pago</h5>
    <div class="timelineContainer">
        <div class="timelineContainer__timeStamp" *ngFor="let a of actions; index as i;">
            <div *ngIf="a.action==2" class="timelineContainer__timeStamp__action">
                <mat-icon>fact_check</mat-icon>
                <span class="timelineContainer__timeStamp__action__message">
                    <b>Pago marcado como facturado por {{a.responsable}}</b>
                    el {{pipe.transform(a.actionDate,'fullDate')}}
                </span>
            </div>
            <div *ngIf="a.action==3" class="timelineContainer__timeStamp__action">
                <mat-icon>layers_clear</mat-icon>
                <span class="timelineContainer__timeStamp__action__message">
                    <b>Factura Anulada por {{a.responsable}}</b>
                    el {{pipe.transform(a.actionDate,'fullDate')}}<br>
                    Motivo: {{a.notes}}
                </span>
            </div>
            <div *ngIf="a.action==4" class="timelineContainer__timeStamp__action">
                <mat-icon>add_circle</mat-icon>
                <span class="timelineContainer__timeStamp__action__message">
                    <b>Pago registrado por {{a.responsable}}</b>
                    el {{pipe.transform(a.actionDate,'fullDate')}}<br>
                </span>
            </div>
            <div *ngIf="a.action==5" class="timelineContainer__timeStamp__action">
                <mat-icon>cancel</mat-icon>
                <span class="timelineContainer__timeStamp__action__message">
                    <b>Pago cancelado por {{a.responsable}}</b>
                    el {{pipe.transform(a.actionDate,'fullDate')}}<br>
                    Motivo: {{a.notes}}
                </span>
            </div>
            <div *ngIf="a.action==6" class="timelineContainer__timeStamp__action">
                <mat-icon>chat</mat-icon>
                <span class="timelineContainer__timeStamp__action__message">
                    <b>Pago desmarcado como facturado por {{a.responsable}}</b>
                    el {{pipe.transform(a.actionDate,'fullDate')}}<br>
                    Motivo: {{a.notes}}
                </span>
            </div>            
            <span *ngIf="i!=(actions.length-1)" class="timelineContainer__timeStamp__connection">
                <mat-icon>keyboard_double_arrow_down</mat-icon>
            </span>
        </div>
    </div>
</mat-dialog-content>