<ng-template #contenido let-modal>

    <mat-tab-group mat-stretch-tabs>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">assignment</mat-icon>
                <span>Estado de cuenta cliente</span>
            </ng-template>
    
            <div class="container-fluid mt-3">
                <mat-card class="card-form">

                    <div class="modal-header">
                        <h1 class="modal-title" *ngFor="let venta of carteraInmu"> <b>{{venta.codeProperty}}</b> </h1>
                        <button type="button" class="close" aria-label="close" (click)="modal.close()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div style="padding-left: 1cm; height: auto; width: auto; padding-bottom: 2cm;"
                            *ngFor="let venta of carteraInmu">
                
                            <div class="row mt-2">
                                <h3 style="padding-left: .4cm;">Desarrollo:</h3>
                                <h3 class="card-title" style="color: brown; padding-left: .4cm;">{{venta.name}}</h3>
                
                            </div>
                            <div class="row mt-2">
                                <h3 style="padding-left: .4cm;">Cliente:</h3>
                                <h3 class="card-title" style="color: brown; padding-left: .4cm;">{{venta.clientes}}</h3>
                
                            </div>
                            <div class="row mt-2">
                                <h3 style="padding-left: .4cm;">Fecha contrato:</h3>
                                <h3 class="card-title" style="color: brown; padding-left: .4cm;">{{venta.fechaDato | date:
                                    'dd/MM/yyyy'}}</h3>
                
                            </div>
                            <div class="row mt-2">
                                <h3 style="padding-left: .4cm;">Celular:</h3>
                                <h3 class="card-title" style="color: brown; padding-left: .4cm;">{{venta.mobile}}</h3>
                            </div>
                            <div class="row mt-2">
                                <h3 style="padding-left: .4cm;">Correo:</h3>
                                <h3 class="card-title" style="color: brown; padding-left: .4cm;">{{venta.email}}</h3>
                            </div>
                
                            <div class="row mt-2">
                
                                <div *ngIf="venta.codeProperty.substr(2,1)=='M'">
                                    <div class="row mt-2" style="padding-left: .4cm;">
                                        <h3 style="padding-left: .4cm;">{{M+":"}}</h3>
                                        <h3 class="card-title" style="color: brown;">{{venta.codeProperty.substr(3,2)}}</h3>
                
                                        <h3 style="padding-left: .4cm;">{{L+":"}}</h3>
                                        <h3 class="card-title" style="color: brown;">{{venta.codeProperty.substr(6,2)}}</h3>
                                    </div>
                                </div>
                                <div *ngIf="venta.codeProperty.substr(2,1)=='T'">
                                    <div class="row mt-2" style="padding-left: .4cm;">
                                        <h3 style="padding-left: .4cm;">{{T+":"}}</h3>
                                        <h3 class="card-title" style="color: brown;">{{venta.codeProperty.substr(3,2)}}</h3>
                
                                        <h3 style="padding-left: .4cm;">{{D+":"}}</h3>
                                        <h3 class="card-title" style="color: brown;">{{venta.codeProperty.substr(6,2)}}</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <h3 style="padding-left: .4cm;">Valor:</h3>
                                <h3 style="color: brown;"> {{venta.valor | currency:'CAD' : '$ '}}</h3>
                            </div>
                            <div class="row mt-2" style="padding-left: .4cm;">
                                <div style="padding-right: 1cm;">
                                    <span class="input-group-text" id="entradaPagado"
                                        style="background-color: brown; border-radius:.3cm; color: aliceblue; height: 1cm; width: auto;">
                                        <b> Abonado</b></span>
                
                                    <div>
                                        <h3> {{venta.abonado | currency:'CAD' : '$ '}} </h3>
                                    </div>
                                </div>
                                <div>
                                    <span class="input-group-text" id="entradaPagado"
                                        style="background-color: brown; border-radius:.3cm; color: aliceblue; height: 1cm; width: auto;"><b>Adeudo</b></span>
                                    <div>
                                        <h3> {{venta.valor-venta.abonado | currency:'CAD' : '$ '}} </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="cards" style="width: 19rem;">
                                <table class="table table-hover mt-3">
                                    <thead>
                                        <tr>
                                            <td>Pagos realizados</td>
                                            <td>Pagos esperados</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> <a>{{venta.mPagadas}}</a>
                                            </td>
                
                                            <td>
                                                <div>
                                                    {{venta.meses+'/'+(venta.valor-venta.advancePayment)/venta.monthlyPayment}}
                                                </div>
                                                <div *ngIf="venta.retraso>=0;">
                                                    <div class="progress">
                
                                                        <div class="progress-bar progress-bar-striped " role="progressbar"
                                                            style="width: 100%" aria-valuenow=bien aria-valuemin="0" aria-valuemax=100>
                                                        </div>
                                                    </div>
                
                                                </div>
                                                <div *ngIf="venta.retraso == tolerancia" ngElse="warning2">
                                                    <div class="progress">
                
                                                        <div class="progress-bar progress-bar-striped bg-success" role="progressbar"
                                                            style="width: 100%" aria-valuenow=rojo aria-valuemin="0" aria-valuemax=100>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="venta.retraso == tolerancia2" ngElse="warning3">
                                                    <div class="progress">
                                                        <div class="progress-bar progress-bar-striped bg-warning" role="progressbar"
                                                            style="width: 100%" aria-valuenow=rojo aria-valuemin="0" aria-valuemax=100>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="venta.retraso == tolerancia3" ngElse="danger">
                                                    <div class="progress">
                                                        <div class="progress-bar progress-bar-striped bg-warning" role="progressbar"
                                                            style="width: 100%" aria-valuenow=rojo aria-valuemin="0" aria-valuemax=100>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="venta.retraso <=limite">
                                                    <div class="progress">
                                                        <div class="progress-bar progress-bar-striped bg-danger" role="progressbar"
                                                            style="width: 100%" aria-valuenow=mal aria-valuemin="0" aria-valuemax=100>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                
                                    </tbody>
                                </table>
                                <label style="text-align: center; padding-top: .5cm; padding-left: 2cm; color: brown;">
                                    {{salida}}
                                </label>
                                <table class="table table-hover mt-3">
                                    <thead>
                                        <tr>
                                            <th scope="col">No.</th>
                                            <th scope="col">Folio</th>
                                            <th scope="col">Fecha</th>
                                            <th scope="col">Cantidad</th>
                                            <th scope="col">Concepto</th>
                    
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let pagos of pago; let i=index;">
                                            <th>{{i+1}}</th>
                                            <th scope="row">{{pagos.folioPago}}</th>
                                            <td>{{pagos.registrationDate | date: 'dd/MM/yyyy'}}</td>
                                            <td>{{(pagos.cantidadAbono | currency:'CAD' : '$ ')}}</td>
                                            <td>{{pagos.concepto}}</td>
                    
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </mat-card>
            
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">done</mat-icon>
                <span>Pagos comisiones</span>
            </ng-template>
    
    
            <div class="modal-header">
                <h1 class="modal-title" *ngFor="let venta of carteraInmu"> <b>{{venta.codeProperty}}</b> </h1>
                <button type="button" class="close" aria-label="close" (click)="modal.close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="container-fluid mt-3">
                <mat-card class="card-form">

                    <table class="table table-hover mt-3">
                        <thead>
                            <tr>
                                <th scope="col">No.</th>
                                <th scope="col">Folio</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Cantidad</th>
                                <th scope="col">Concepto</th>
        
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pay of payments; let i=index;">
                                <th>{{i+1}}</th>
                                <th scope="row"><button class="btn btn-success">{{pay.folio}}</button></th>
                                <td>{{pay.paymentDate | date: 'dd/MM/yyyy'}}</td>
                                <td>{{(pay.paymentAmount | currency:'CAD' : '$ ')}}</td>
                                <td>{{pay.paymentConcept}}</td>
        
                            </tr>
                        </tbody>
                    </table>


                </mat-card>
            </div>
        </mat-tab>
    </mat-tab-group>




    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="modal.close()">Ok!</button>
    </div>





</ng-template>

<div class="propertiesContainer">
    <div class="propertyCard" *ngFor="let payment of coordEmail"
        (click)="slectPayments(payment.codeProperty);selectSales(payment.codeProperty);sumTotales(payment.codeProperty);modal.open(contenido);reporteInmuCartera(payment.codeProperty);getCreditPayments(payment.codeProperty);getCreditNRPayments(payment.codeProperty);getCreditPaymentsList(payment.codeProperty);">
        <p
            style="background-color: var(--M19Primary); font-size: 1rem; color: white; text-align: center; font-weight: bold; padding: 6px; border-radius: 10px 10px 0 0;">
            {{payment.name}} - {{payment.codeProperty}}</p>
        <p style="text-align: center;"><b>Vendido el {{payment.fechaDato | date: 'dd-MM-yyyy' }} por
                {{payment.usuarios}}</b></p>
        <div class="cardRow">
            <p style="text-align: center; width: calc(100%/3);"><b style="color: var(--M19Primary);">Valor
                    total</b><br><b>{{(payment.valor |
                    currency:'CAD' : '$ ')}}</b></p>
            <p style="text-align: center; width: calc(100%/3);"><b
                    style="color: var(--M19Primary);">Abonado</b><br><b>{{(payment.abonado |
                    currency:'CAD' : '$ ')}}</b></p>
            <p style="text-align: center; width: calc(100%/3);"><b
                    style="color: var(--M19Primary);">Restante</b><br><b>{{(payment.valor-payment.abonado
                    | currency:'CAD' : '$ ')}}</b></p>
        </div>
        <div class="cardRow">
            <p style="text-align: center; width: calc(100%/3);"><b style="color: var(--M19Primary);">Pagos
                    realizados</b><br><b>{{payment.mPagadas}}</b></p>
            <p style="text-align: center; width: calc(100%/3);"><b style="color: var(--M19Primary);">Pagos
                    esperados</b><br><b>{{payment.meses+'/'+((payment.valor-payment.advancePayment)/payment.monthlyPayment).toFixed(0)}}</b>
            </p>
            <p style="text-align: center; width: calc(100%/3);"><b
                    style="color: var(--M19Primary);">Estado</b><br><b>{{payment.retraso}}</b></p>
        </div>
    </div>
</div>
<div>
    <table class="table table-striped animated fadeIn faster">
        <thead>
            <tr align="center">
                <th scope="col">Inmueble</th>
                <th scope="col">Desarrollo</th>
                <th scope="col">Cliente</th>
                <th scope="col">Fecha de contrato</th>
                <th scope="col">Valor total</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let payment of coordEmail" align="center">
                <th>
                    <button class="btn btn-info" (click)="slectPayments(payment.codeProperty);selectSales(payment.codeProperty);sumTotales(payment.codeProperty);modal.open(contenido);reporteInmuCartera(payment.codeProperty)">
                        {{payment.codeProperty}}
                    </button>
                </th>
                <th>{{payment.name}}</th>
                <td>{{payment.clientes}}</td>
                <td>{{payment.fechaDato | date: 'dd-MM-yyyy' }}</td>
                <td>{{(payment.valor | currency:'CAD' : '$ ')}}</td>
            </tr>
        </tbody>
    </table>
</div>