import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ControComissionsService } from 'src/app/services/contro-comissions.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { ProjectService } from 'src/app/services/project.service';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import Swal from 'sweetalert2';
import { DialogBudgetRegisterComponent } from '../../controlComissions/dialogBudgetRegister/dialog-budget-register/dialog-budget-register.component';
import { DialogBudgetUpdateComponent } from '../dialogBudgetUpdate/dialog-budget-update/dialog-budget-update.component';
import { DialogCoordinatorPaymentComponent } from '../dialogCoordinadorPayment/dialog-coordinator-payment/dialog-coordinator-payment.component';
import { DialogEntryProjectSelectedComponent } from '../DialogEntryProjectSelected/dialog-entry-project-selected/dialog-entry-project-selected.component';
import { TicketOptionsComponent } from '../DialogTicketOptions/ticket-options/ticket-options.component';

@Component({
  selector: 'app-generate-and-calculate-commisions',
  templateUrl: './generate-and-calculate-commisions.component.html',
  styleUrls: ['./generate-and-calculate-commisions.component.scss']
})

export class GenerateAndCalculateCommisionsComponent implements OnInit {

  nameProject: string = '';
  searchGeneralProject: any = "";

  nameProjectForMenu: String = "";
  nameEmployee: string = '';

  employees: any = [];  //Recupera todos los empleados de M19
  projects: any = [];  // Recupera todos los proyectos existentes 
  projectsT: any = [];  // Recupera todos los proyectos existentes 

  getAll: any = []; //Se Guardan los datos de los clientes → Proyecto, depa y nombre del cliente 
  dateofPC: any = []; //Guardas las fechas en que se pagaron comisiones y tambien las fechas en que los clientes dieron su pago
  dateofPayCustomers: any = []; //Guardas las fechas en que se pagaron comisiones y tambien las fechas en que los clientes dieron su pago

  arrayCompletePC: any = []; //Se usa para obtener las fechas obtenidas para pagos de comisiones completado con campos nulos
  arrayCompletePCustomers: any = []; //Se usa para obtener las fechas obtenidas para pagos de los clientes completado con campos nulos
  unionPComiAndPCusto: any = []; //Arreglo que se le concatenan los arreglos arrayCompletePC y arrayCompletePCustomers

  contadorPagos: any = [];

  pruebitadefuego: any;
  loadingCircle = false;

  selected_project = {
    name: '',
    nomenclature: 'Ninguna'
  };

  counter = {
    occupied: 0,
    blocked: 0,
  }

  xd: any;
  flagSearch = false;
  dataExtra: any = [];
  type = 1

  constructor(
    public dialog: MatDialog,
    public sweet: SweetAlertComponent,
    public projectService: ProjectService,
    public employeeService: EmployeeService,
    public controlComissionService: ControComissionsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    //this.pruebita();
    this.selectSellers();
    this.selectProjects();
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////// OBTENCION DE INFORMACION  /////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Consultar todos los vendedores
  async selectSellers() {
    this.controlComissionService.getAllCoords().subscribe(
      res => {
        this.employees = res;
      },
      err => console.log(err)
    );
  }

  //Consultar todos los proyectos
  async selectProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
        this.projectsT = res;
      },
      err => console.log(err)
    );
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////// METODOS DE INTERACCIÓN  /////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  async setFlag(event: boolean) {
    event == true ? this.flagSearch = true : this.flagSearch = false;
  }  



  public pruebitA(event: any) {
    console.log("Evento → ", event)
    this.nameProject == "Bosco" ? this.type = 2 : this.nameProject == "Xalta" || this.nameProject == "Altares Departamentos" ? this.type = 3 : this.type = 1
    this.loadingCircle = false;
    this.arrayCompletePCustomers = [];
    this.unionPComiAndPCusto = [];
    this.nameProject = event;
  }

  public pruebitB(event: any) {
    console.log("Evento → ", event)
    this.searchGeneralProject == "Bosco" ? this.type = 2 : this.searchGeneralProject == "Xalta" || this.searchGeneralProject == "Altares Departamentos" ? this.type = 3 : this.type = 1
    this.loadingCircle = false;
    this.arrayCompletePCustomers = [];
    this.unionPComiAndPCusto = [];
    this.nameProject = event;
  }


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////// BUSQUEDA POR PROYECTO Y COORDINADOR ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////// CLASIFICACIÓN DE TIPO /////////////////////////////////////////////////////////////////////
  async mergePaymentsComissionOnProperties() {

    if (this.nameProject == "Bosco") {
      this.mezclaPagosClientesTipo2(this.nameProject, this.nameEmployee)
    } else if (this.nameProject == "Xalta" || this.nameProject == "Altares Departamentos") {
      this.mezclaPagosClientesTipo3(this.nameProject, this.nameEmployee)
    } else {
      this.mezclaPagosClientesTipo1(this.nameProject, this.nameEmployee)
    }


    //this.nameProject == 'Bosco'? this.selectInmuByProjectAndEmployeeE(this.nameProject, this.nameEmployee): this.selectInmuByProjectAndEmployee(this.nameProject, this.nameEmployee);
  }

  ///////////////////////////////////////////////////////////// TIPO 1 (1 ANTICIPO 9 MENSUALIDADES)////////////////////////////////////////////////////////////

  // Se completa la informacion de los pagos de comisiones dentro del arreglp [arrayCompletePC]
  async mezclaPagosClientesTipo1(namepro: string, nom: string) {

    if (this.nameEmployee == "" && this.nameProject == "") {
      this.sweet.Toast("error", " Seleccione un [Proyecto] y un [Coordinador] \n\nPara obtener resultados")
      return;
    }
    else if (this.nameProject == "") {
      this.sweet.Toast("warning", " Seleccione un proyecto para poder continuar con su consulta")
      return;

    } else if (this.nameEmployee == "") {
      this.sweet.Toast("warning", " Seleccione un Coordinador para poder continuar con su consulta")
      return;

    } else {
      this.arrayCompletePC = []; //Se vacia Cada vez que se hace una consulta
      this.loadingCircle = true
      this.controlComissionService.selectByProjectAndId(namepro, nom).subscribe(
        ress => {

          this.getAll = ress; //Guarda el nombre del proyecto, el nombre del inmueble y el nombre del cliente en esta variable

          for (let i in this.getAll) {

            this.controlComissionService.selectPayByFornitureComissions(this.getAll[i].depa).subscribe(

              res => {

                this.dateofPC = res; //Busca todos los pagos de comisiones que se han dado de un inmueble con este formato 0000-00-00T0:00:00.000Z
                let ArrayDatePcComplete = [];

                for (let fechasPagosComisiones of this.dateofPC) {
                  ArrayDatePcComplete.push((fechasPagosComisiones.fechapago.substring(0, 10))); //Con el for se extrae solo la fecha con el formato dd/mm/yyyy (primeros 10 simbolos)
                }

                for (let i = this.dateofPC.length; i < 10; i++) {
                  ArrayDatePcComplete.push("NULL"); //Se rellena cuando las fechas de pagos no son 10 con campos NULL
                }

                this.arrayCompletePC.push([this.getAll[i].depa, ArrayDatePcComplete]); //Se crea un arreglo con el inmueble y un arreglo con las fechas completas [ya rellenadas con los campos NULL]    
              },

              err => console.log(err)
            ) //Aqui termina el subcribe donde se buscan las fechas de comisiones pagadas por inmuebles

          }//Fin del for donde se guardan en res todas las fechas de pagos de comisiones de cada inmueble

          this.mezclaPagosComisionesTipo1(); //Llamo a la otra funcion para que se ejecute despues de esta para guardar los pagos de los ahora clientes
        },
        err => console.log(err)
      )
    }

  }

  // Se completa la informacion de los pagos de comisiones dentro del arreglp [arrayCompletePCustomers]
  async mezclaPagosComisionesTipo1() {

    this.arrayCompletePCustomers = [];

    for (let i in this.getAll) {
      this.controlComissionService.selectPayByForniturePayments(this.getAll[i].depa).subscribe(

        res => {

          this.dateofPayCustomers = res;
          let arrayDatePayCustomer = [];

          for (let iter of this.dateofPayCustomers) {
            arrayDatePayCustomer.push((iter.fechapago.substring(0, 10)));
          }

          for (let i = this.dateofPayCustomers.length; i < 10; i++) {
            arrayDatePayCustomer.push("NULL");
          }

          this.arrayCompletePCustomers.push([this.getAll[i].depa, arrayDatePayCustomer])

        },
        err => console.log(err)
      )

    }
    this.deathTime();
  }

  ///////////////////////////////////////////////////////////// TIPO 2 (1 ANTICIPO 7 MENSUALIDADES)////////////////////////////////////////////////////////////

  // Se completa la informacion de los pagos de comisiones dentro del arreglp [arrayCompletePC] para el caso de bosco en especial
  async mezclaPagosClientesTipo2(namepro: string, nom: string) {

    if (this.nameProject == "") {
      this.sweet.Toast("warning", " Seleccione el un proyecto para poder continuar con su consulta")
      return;

    } else if (this.nameEmployee == "") {
      this.sweet.Toast("warning", " Seleccione un Coordinador para poder continuar con su consulta")
      return;

    } else {
      this.arrayCompletePC = []; //Se vacia Cada vez que se hace una consulta
      this.loadingCircle = true
      this.controlComissionService.selectByProjectAndId(namepro, nom).subscribe(
        ress => {

          this.getAll = ress; //Guarda el nombre del proyecto, el nombre del inmueble y el nombre del cliente en esta variable

          //console.log("Get all → " , this.getAll)

          for (let i in this.getAll) {

            this.controlComissionService.selectPayByFornitureComissions(this.getAll[i].depa).subscribe(

              res => {

                this.dateofPC = res; //Busca todos los pagos de comisiones que se han dado de un inmueble con este formato 0000-00-00T0:00:00.000Z
                let ArrayDatePcComplete = [];

                for (let fechasPagosComisiones of this.dateofPC) {
                  ArrayDatePcComplete.push((fechasPagosComisiones.fechapago.substring(0, 10))); //Con el for se extrae solo la fecha con el formato dd/mm/yyyy (primeros 10 simbolos)
                }

                for (let i = this.dateofPC.length; i < 8; i++) {
                  ArrayDatePcComplete.push("NULL"); //Se rellena cuando las fechas de pagos no son 10 con campos NULL
                }

                this.arrayCompletePC.push([this.getAll[i].depa, ArrayDatePcComplete]); //Se crea un arreglo con el inmueble y un arreglo con las fechas completas [ya rellenadas con los campos NULL]    

              },

              err => console.log(err)
            ) //Aqui termina el subcribe donde se buscan las fechas de comisiones pagadas por inmuebles

          }//Fin del for donde se guardan en res todas las fechas de pagos de comisiones de cada inmueble
          
          this.mezclaPagosComisionesTipo2(); //Llamo a la otra funcion para que se ejecute despues de esta para guardar los pagos de los ahora clientes
        },
        err => console.log(err)
      )
    }


  }

  // Se completa la informacion de los pagos de comisiones dentro del arreglp [arrayCompletePCustomers] para el caso de bosco en especial
  async mezclaPagosComisionesTipo2() {

    this.arrayCompletePCustomers = [];

    for (let i in this.getAll) {
      this.controlComissionService.selectPayByForniturePayments(this.getAll[i].depa).subscribe(

        res => {

          this.dateofPayCustomers = res;
          let arrayDatePayCustomer = [];

          for (let iter of this.dateofPayCustomers) {
            arrayDatePayCustomer.push((iter.fechapago.substring(0, 10)));
          }

          for (let i = this.dateofPayCustomers.length; i < 8; i++) {
            arrayDatePayCustomer.push("NULL");
          }

          this.arrayCompletePCustomers.push([this.getAll[i].depa, arrayDatePayCustomer])
          //console.log("arreglo ahora mas completo → " , this.arrayCompletePCustomers)
        },
        err => console.log(err)
      )

    }
    this.deathTime();
  }

  ////////////////////////////////////////////////////////////////// TIPO 3 (4 ANTICIPO) //////////////////////////////////////////////////////////////////////

  async mezclaPagosClientesTipo3(namepro: string, nom: string) {

    if (this.nameProject == "") {
      this.sweet.Toast("warning", " Seleccione el un proyecto para poder continuar con su consulta")
      return;

    } else if (this.nameEmployee == "") {
      this.sweet.Toast("warning", " Seleccione un Coordinador para poder continuar con su consulta")
      return;

    } else {
      this.arrayCompletePC = []; //Se vacia Cada vez que se hace una consulta
      this.loadingCircle = true
      this.controlComissionService.selectByProjectAndId(namepro, nom).subscribe(
        ress => {

          this.getAll = ress; //Guarda el nombre del proyecto, el nombre del inmueble y el nombre del cliente en esta variable

          for (let i in this.getAll) {

            this.controlComissionService.selectPayByFornitureComissions(this.getAll[i].depa).subscribe(

              res => {

                this.dateofPC = res; //Busca todos los pagos de comisiones que se han dado de un inmueble con este formato 0000-00-00T0:00:00.000Z
                let ArrayDatePcComplete = [];

                for (let fechasPagosComisiones of this.dateofPC) {
                  ArrayDatePcComplete.push((fechasPagosComisiones.fechapago.substring(0, 10))); //Con el for se extrae solo la fecha con el formato dd/mm/yyyy (primeros 10 simbolos)
                }

                for (let i = this.dateofPC.length; i < 4; i++) {
                  ArrayDatePcComplete.push("NULL"); //Se rellena cuando las fechas de pagos no son 10 con campos NULL
                }
                this.arrayCompletePC.push([this.getAll[i].depa, ArrayDatePcComplete]); //Se crea un arreglo con el inmueble y un arreglo con las fechas completas [ya rellenadas con los campos NULL]    
              },

              err => console.log(err)
            ) //Aqui termina el subcribe donde se buscan las fechas de comisiones pagadas por inmuebles

          }//Fin del for donde se guardan en res todas las fechas de pagos de comisiones de cada inmueble
          //console.log("Arreglo completo con sus datos anexados " , this.arrayCompletePC)
          this.mezclaPagosComisionesTipo3(); //Llamo a la otra funcion para que se ejecute despues de esta para guardar los pagos de los ahora clientes
        },
        err => console.log(err)
      )
    }
  }

  // Se completa la informacion de los pagos de comisiones dentro del arreglp [arrayCompletePCustomers] para el caso de bosco en especial
  async mezclaPagosComisionesTipo3() {

    this.arrayCompletePCustomers = [];

    for (let i in this.getAll) {
      this.controlComissionService.selectPayByForniturePayments(this.getAll[i].depa).subscribe(

        res => {

          this.dateofPayCustomers = res;
          let arrayDatePayCustomer = [];

          for (let iter of this.dateofPayCustomers) {
            arrayDatePayCustomer.push((iter.fechapago.substring(0, 10)));
          }

          for (let i = this.dateofPayCustomers.length; i < 4; i++) {
            arrayDatePayCustomer.push("NULL");
          }

          this.arrayCompletePCustomers.push([this.getAll[i].depa, arrayDatePayCustomer])
        },
        err => console.log(err)
      )
    }
    this.deathTime();
  }

  ///////////////////////////////////////////////////////////////// UNION DE TODA LA INFORMACIÓN //////////////////////////////////////////////////////////////
  //Despues de terminar de ejecutar los subcribes de las otras consultas hace un merge para mezclar ambas fechas con los respectivos inmuebles

  //Funciona como un retardante para que cuando se terminen de ejecutar los subcribe ya pueda hacer la mezcla de la informacion
  deathTime() {
    this.employeeService.selectEmployeeWorker().subscribe(
      res => {
        this.mergeInformation();
        this.loadingCircle = false;
      }
    );
  }

  mergeInformation() {
    this.unionPComiAndPCusto = [];

    for (let i = 0; i < this.arrayCompletePC.length; i++) {
      for (let k = 0; k < this.arrayCompletePCustomers.length; k++) {
        if (this.arrayCompletePC[i][0] == this.arrayCompletePCustomers[k][0]) {
          this.unionPComiAndPCusto.push([this.arrayCompletePC[i][0], this.arrayCompletePC[i][1], this.arrayCompletePCustomers[k][1]])
        }
      }
    }
    console.log("Union de información → ", this.unionPComiAndPCusto);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////// BUSQUEDA GENERAL POR PROYECTO ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////// CLASIFICACIÓN DE TIPO /////////////////////////////////////////////////////////////////////
  async mergePaymentsComissionOnPropertiesGeneral() {
    this.searchGeneralProject == 'Bosco' ? this.selectAllImnuByProjectGeneralE(this.searchGeneralProject) : this.searchGeneralProject == "Xalta" || this.searchGeneralProject == "Altares Departamentos" ? this.tipo3General(this.searchGeneralProject):  this.selectAllImnuByProjectGeneral(this.nameProject);
  }
  ///////////////////////////////////////////////////////////// TIPO 1 (1 ANTICIPO 9 MENSUALIDADES)////////////////////////////////////////////////////////////
  async selectAllImnuByProjectGeneral(namePro: string) {
    if (this.searchGeneralProject == "") {
      this.sweet.Toast("error", " Seleccione el un proyecto para obtener  resultados")
      return;
    } else {

      this.arrayCompletePC = []; //Se vaciara el arreglo cada vez que se hace una consulta
      this.loadingCircle = true

      this.controlComissionService.selectByProjectGeneral(namePro).subscribe(
        res => {

          this.getAll = res; //Guarda el nombre del proyecto, el nombre del inmueble y el nombre del cliente en esta variable

          for (let i in this.getAll) {

            this.controlComissionService.selectPayByFornitureComissions(this.getAll[i].depa).subscribe(
              ress => {

                this.dateofPC = ress; //Busca todos los pagos de comisiones que se han dado de un inmueble con este formato 0000-00-00T0:00:00.000Z
                let ArrayDatePcComplete = [];

                for (let fechasPagosComisiones of this.dateofPC) {
                  ArrayDatePcComplete.push((fechasPagosComisiones.fechapago.substring(0, 10))); //Con el for se extrae solo la fecha con el formato dd/mm/yyyy (primeros 10 simbolos)
                }

                for (let i = this.dateofPC.length; i < 10; i++) {
                  ArrayDatePcComplete.push("NULL"); //Se rellena cuando las fechas de pagos no son 10 con campos NULL
                }

                this.arrayCompletePC.push([this.getAll[i].depa, ArrayDatePcComplete]); //Se crea un arreglo con el inmueble y un arreglo con las fechas completas [ya rellenadas con los campos NULL]    

              },
              err => console.log(err)

            )//Fin del subscribe interno

          }//Fin del for donde se guardan en res todas las fechas de pagos de comisiones de cada inmueble

          this.mergePaymentsCustomersOnPropertiesGeneral(); //Llamo a la otra funcion para que se ejecute despues de esta para guardar los pagos de los ahora clientes

        },

        err => console.log(err)

      )//Fin del subscribe 

    }

  }

  // Se completa la informacion de los pagos de comisiones dentro del arreglo [arrayCompletePCustomers]
  async mergePaymentsCustomersOnPropertiesGeneral() {

    this.arrayCompletePCustomers = [];

    for (let i in this.getAll) {
      this.controlComissionService.selectPayByForniturePayments(this.getAll[i].depa).subscribe(

        res => {

          this.dateofPayCustomers = res;
          let arrayDatePayCustomer = [];

          for (let iter of this.dateofPayCustomers) {
            arrayDatePayCustomer.push((iter.fechapago.substring(0, 10)));
          }

          for (let i = this.dateofPayCustomers.length; i < 10; i++) {
            arrayDatePayCustomer.push("NULL");
          }

          this.arrayCompletePCustomers.push([this.getAll[i].depa, arrayDatePayCustomer])

        },
        err => console.log(err)
      )

    }
    this.deathTime2();

  }


  ///////////////////////////////////////////////////////////// TIPO 2 (1 ANTICIPO 7 MENSUALIDADES)////////////////////////////////////////////////////////////
  async selectAllImnuByProjectGeneralE(namePro: string) {
    if (this.searchGeneralProject == "") {
      this.sweet.Toast("error", " Seleccione el un proyecto para obtener  resultados")
      return;
    } 
    else {
      this.arrayCompletePC = []; //Se vaciara el arreglo cada vez que se hace una consulta
      this.loadingCircle = true;
      this.controlComissionService.selectByProjectGeneral(namePro).subscribe(
        res => {
          this.getAll = res; //Guarda el nombre del proyecto, el nombre del inmueble y el nombre del cliente en esta variable

          // console.log("Variable getAll → " , this.getAll)

          for (let i in this.getAll) {

            this.controlComissionService.selectPayByFornitureComissions(this.getAll[i].depa).subscribe(

              ress => {

                this.dateofPC = ress; //Busca todos los pagos de comisiones que se han dado de un inmueble con este formato 0000-00-00T0:00:00.000Z
                let ArrayDatePcComplete = [];

                // console.log("Variable dateofPC → " , this.dateofPC)

                for (let fechasPagosComisiones of this.dateofPC) {
                  ArrayDatePcComplete.push((fechasPagosComisiones.fechapago.substring(0, 10))); //Con el for se extrae solo la fecha con el formato dd/mm/yyyy (primeros 10 simbolos)
                }

                for (let i = this.dateofPC.length; i < 8; i++) {
                  ArrayDatePcComplete.push("NULL"); //Se rellena cuando las fechas de pagos no son 10 con campos NULL
                }

                this.arrayCompletePC.push([this.getAll[i].depa, ArrayDatePcComplete, this.getAll[i].cliente]); //Se crea un arreglo con el inmueble y un arreglo con las fechas completas [ya rellenadas con los campos NULL]    


              },
              err => console.log(err)

            )//Fin del subscribe interno

          }//Fin del for donde se guardan en res todas las fechas de pagos de comisiones de cada inmueble

          // console.log("Variable arrayCompletePC → " , this.arrayCompletePC )

          this.mergePaymentsCustomersOnPropertiesGeneralE(); //Llamo a la otra funcion para que se ejecute despues de esta para guardar los pagos de los ahora clientes

        },

        err => console.log(err)

      )//Fin del subscribe 

    }
  }

  // Se completa la informacion de los pagos de comisiones dentro del arreglo [arrayCompletePCustomers]
  async mergePaymentsCustomersOnPropertiesGeneralE() {

    this.arrayCompletePCustomers = [];

    for (let i in this.getAll) {
      this.controlComissionService.selectPayByForniturePayments(this.getAll[i].depa).subscribe(

        res => {

          this.dateofPayCustomers = res;
          //console.log("variable arrayCompletePCustomers → " ,  this.dateofPayCustomers)
          let arrayDatePayCustomer = [];

          for (let iter of this.dateofPayCustomers) {
            arrayDatePayCustomer.push((iter.fechapago.substring(0, 10)));
          }

          for (let i = this.dateofPayCustomers.length; i < 8; i++) {
            arrayDatePayCustomer.push("NULL");
          }

          this.arrayCompletePCustomers.push([this.getAll[i].depa, arrayDatePayCustomer])

        },
        err => console.log(err)
      )

    }

    // console.log("variable arrayCompletePCustomers → " ,  this.dateofPayCustomers)
    this.deathTime2();
  }

  async tipo3General(namepro: string) {

    if (this.searchGeneralProject == "") {
      this.sweet.Toast("error", " Seleccione el un proyecto para obtener  resultados")
      return;
    } 
    else {
      this.arrayCompletePC = []; //Se vaciara el arreglo cada vez que se hace una consulta
      this.loadingCircle = true;
      this.controlComissionService.selectByProjectGeneral(namepro).subscribe(
        res => {
          this.getAll = res; //Guarda el nombre del proyecto, el nombre del inmueble y el nombre del cliente en esta variable

          for (let i in this.getAll) {

            this.controlComissionService.selectPayByFornitureComissions(this.getAll[i].depa).subscribe(

              ress => {

                this.dateofPC = ress; //Busca todos los pagos de comisiones que se han dado de un inmueble con este formato 0000-00-00T0:00:00.000Z
                let ArrayDatePcComplete = [];

                // console.log("Variable dateofPC → " , this.dateofPC)

                for (let fechasPagosComisiones of this.dateofPC) {
                  ArrayDatePcComplete.push((fechasPagosComisiones.fechapago.substring(0, 10))); //Con el for se extrae solo la fecha con el formato dd/mm/yyyy (primeros 10 simbolos)
                }

                for (let i = this.dateofPC.length; i < 4; i++) {
                  ArrayDatePcComplete.push("NULL"); //Se rellena cuando las fechas de pagos no son 10 con campos NULL
                }

                this.arrayCompletePC.push([this.getAll[i].depa, ArrayDatePcComplete, this.getAll[i].cliente]); //Se crea un arreglo con el inmueble y un arreglo con las fechas completas [ya rellenadas con los campos NULL]    


              },
              err => console.log(err)

            )//Fin del subscribe interno

          }//Fin del for donde se guardan en res todas las fechas de pagos de comisiones de cada inmueble

          // console.log("Variable arrayCompletePC → " , this.arrayCompletePC )

          this.mezclaconPagosClientes(); //Llamo a la otra funcion para que se ejecute despues de esta para guardar los pagos de los ahora clientes

        },

        err => console.log(err)

      )//Fin del subscribe 

    }
  }

  async mezclaconPagosClientes() {

    this.arrayCompletePCustomers = [];

    for (let i in this.getAll) {
      this.controlComissionService.selectPayByForniturePayments(this.getAll[i].depa).subscribe(

        res => {

          this.dateofPayCustomers = res;
          //console.log("variable arrayCompletePCustomers → " ,  this.dateofPayCustomers)
          let arrayDatePayCustomer = [];

          for (let iter of this.dateofPayCustomers) {
            arrayDatePayCustomer.push((iter.fechapago.substring(0, 10)));
          }

          for (let i = this.dateofPayCustomers.length; i < 4; i++) {
            arrayDatePayCustomer.push("NULL");
          }

          this.arrayCompletePCustomers.push([this.getAll[i].depa, arrayDatePayCustomer])

        },
        err => console.log(err)
      )

    }

    // console.log("variable arrayCompletePCustomers → " ,  this.dateofPayCustomers)
    this.deathTime2();
  }

  //Funciona como un retardante para que cuando se terminen de ejecutar los subcribe ya pueda hacer la mezcla de la informacion
  deathTime2() {
    this.employeeService.selectEmployeeWorker().subscribe(
      res => {
        this.mergeInformationGeneral();
        this.loadingCircle = false;
      }
    );
  }

  ////////////////////////////////////////////////////////////////// TIPO 3 (4 ANTICIPO) //////////////////////////////////////////////////////////////////////


  ///////////////////////////////////////////////////////////////// UNION DE TODA LA INFORMACIÓN //////////////////////////////////////////////////////////////

  mergeInformationGeneral() {

    this.unionPComiAndPCusto = [];

    for (let i = 0; i < this.arrayCompletePC.length; i++) {
      for (let k = 0; k < this.arrayCompletePCustomers.length; k++) {
        if (this.arrayCompletePC[i][0] == this.arrayCompletePCustomers[k][0]) {
          this.unionPComiAndPCusto.push([this.arrayCompletePC[i][0], this.arrayCompletePC[i][1], this.arrayCompletePCustomers[k][1]])
        }
        //  console.log("k:",k);
      }
      //console.log("i:",i);

    }
    //console.log("Union de información → ", this.unionPComiAndPCusto);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////// COMPONENTES PARA TICKETS  /////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  async openTicketOptionPrint(obj: any) {
    this.dataExtra = { propiedad: obj, option: "reimprimir", proyecto: this.nameProject, vendedor: this.nameEmployee }

    this.dialog.open(TicketOptionsComponent,
      {
        width: '90%',
        height: '70%',
        data: this.dataExtra
      });
  }

  async openTicketOptionCancel(obj: any) {
    this.dataExtra = { propiedad: obj, option: "cancelar", proyecto: this.nameProject, vendedor: this.nameEmployee }

    this.dialog.open(TicketOptionsComponent,
      {
        width: '90%',
        height: '70%',
        data: this.dataExtra
      });


      this.dialog.afterAllClosed.subscribe(
        res => {
          this.mergePaymentsComissionOnProperties();
        }
      )
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////// METODOS EXTRA SIN CLASIFICAR //////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Apartado de los dialogs que sirven como pantallas para realizar distitas tareas 
  async dialogBudgetUpdate() {
    this.dialog.open(DialogBudgetUpdateComponent, {
      width: '100%',
      height: '80%',

    });
  }

  async dialogBudgetRegister() {
    this.dialog.open(DialogBudgetRegisterComponent, {
      width: '50%',
      height: '90%',

    });
  }

  async openDialogOptionProject() {
    let proj = { 'nameEmployee': this.nameProject };
    const dialogo1 = this.dialog.open(DialogEntryProjectSelectedComponent
      , {
        width: '30%',
        data: proj

      });

    dialogo1.afterClosed().subscribe(result => {
      console.log("result: ", result);
      if (result) {


      }

    }
    );
  }

  async openDialogPayments(idProperty: string) {
    let info = { 'nameEmployee': this.nameEmployee, 'inmueble': idProperty, 'project': this.nameProject };
    const dialogo2 = this.dialog.open(DialogCoordinatorPaymentComponent, {
      width: '70%',
      height: 'auto',
      data: info
    });

    dialogo2.afterClosed().subscribe(result => {

      setTimeout(() => {
        this.mergePaymentsComissionOnProperties()

      }, 500);

    }
    );

  }

}//Fin de la clase 