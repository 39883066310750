<div class="container-fluid mt-3">
  <mat-card class="card-form">
    <app-loader [loader]="loader" *ngIf="loading==1"></app-loader>

    <div class="options">
      <button class="m19Btn" (click)="getNotesReport()">
        Generar Reporte de Notas <mat-icon>print</mat-icon>
      </button>
    </div>

    <div class="notesReport" id="htmlNotes" [hidden]="true">
      <div class="titleBelt">
        <img src="../../../../assets/iconoM.png" style="width: 60px; height: 60px;">
        <p style="font-size: 1.3rem;">Reporte de Cobranza <br> Últimas notas hasta el día
          {{this.pipe.transform(currentDate, 'longDate')}}</p>
      </div>
      <br>
      <span class="pageNumber"></span>
      <table class="m19Table">
        <thead>
          <th>Inmueble</th>
          <th>Meses</th>
          <th>Cliente</th>
          <th>Vendedor</th>
          <th>Contrato</th>
          <th>Adeudo</th>
          <th>Comentario</th>
        </thead>
        <tbody>
          <tr *ngFor="let n of notesReport">
            <td style="text-align: center; font-weight: bold;">{{n.codeProperty}}</td>
            <td style="text-align: center;">{{n.monthRecession}}</td>
            <td>{{n.clientes}}</td>
            <td>{{n.vendedor}}</td>
            <td style="text-align: center; white-space: nowrap;">{{this.pipe.transform(n.fechaDato, 'longDate')}}</td>
            <td style="text-align: right; white-space: nowrap; color: #D94555;">{{n.saldo | currency:'CAD' : '$ '}}</td>
            <td>{{n.nota}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <mat-accordion>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header
          (click)="carteraGrafico(); carteraTotal(); carteraRoja(); carteraAmarilla(); carteraVerde();">
          <mat-panel-title>
            <span> <b> Gráfico de Cartera Vencida </b> </span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="TarCar">
          <div class="row _card">
            <div class="col-md-4">
              <mat-card>
                <mat-card-title>
                  <h3>M19 Inmobiliaria</h3>
                </mat-card-title>
                <mat-card-content *ngFor="let carterasG of carteraG">
                  <canvas baseChart width="325" height="465"
                    [data]="[carterasG.rojos,carterasG.amarillos,carterasG.verdes]" [labels]="pieChartLabels"
                    [chartType]="pieChartType" [options]="pieChartOptions" ([plugins])="pieChartPlugins"
                    [colors]="pieChartColors" [legend]="pieChartLegend"></canvas>

                  <table class="table table-striped animated fadeIn faster _reports">
                    <thead>
                      <tr align="center">
                        <th scope="col" style="color: rgb(240, 46, 23)">Rescisión</th>
                        <th scope="col" style="color: rgb(255, 87, 51)">En riesgo</th>
                        <th scope="col" style="color: rgb(39, 162, 11)">Un solo atraso</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr align="center">
                        <td *ngFor="let carterasR of carteraR">{{carterasR.rojos}}</td>
                        <td *ngFor="let carterasA of carteraA">{{carterasA.amarillos}}</td>
                        <td *ngFor="let carterasV of carteraV">{{carterasV.verdes}}</td>
                      </tr>
                      <tr class="mat-footer-row">
                        <td colspan="2">Total</td>
                        <td *ngFor="let carterasT of carteraT">{{carterasT.totales}}</td>
                      </tr>
                    </tbody>
                  </table>
                </mat-card-content>
              </mat-card>
            </div>

            <div class="col-md-8">
              <mat-card>
                <mat-card-content>
                  <table class="table table-striped animated fadeIn faster _reports">
                    <thead class="thead-m19">
                      <tr align="center">
                        <th colspan="5">
                          <h5>Atrasos por desarrollo</h5>
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      <tr align="center">
                        <th scope="col">Desarrollo</th>
                        <th scope="col">Atrasos</th>
                        <th scope="col" style="color: rgb(240, 46, 23)">Rescisión</th>
                        <th scope="col" style="color: rgb(255, 87, 51)">En riesgo</th>
                        <th scope="col" style="color: rgb(39, 162, 11)">Un solo atraso</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let lisAtrDes of reportDevBack" align="center">
                        <th scope="row" style="color: brown;">{{lisAtrDes.name}}</th>
                        <td>{{lisAtrDes.totalAtrasos}}</td>
                        <td>{{lisAtrDes.rojoDes}}</td>
                        <td>{{lisAtrDes.amarilloDes}}</td>
                        <td>{{lisAtrDes.verdeDes}}</td>
                      </tr>
                    </tbody>
                  </table>
                </mat-card-content>
              </mat-card>
            </div>
          </div>

          <label for=""> <b>Seleccione filtro de coordinador: </b> </label>

          <button mat-button [matMenuTriggerFor]="menu" class="btn btn-m19"> Coordinador </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="reporteCarteraCoordinador(coordLis.name)"
              *ngFor="let coordLis of coordListado">{{coordLis.name}}</button>
          </mat-menu>

          <table class="table table-striped animated fadeIn faster">
            <thead class="thead-m19" align="center">
              <tr>
                <th scope="col">Inmueble</th>
                <th scope="col">Cliente</th>
                <th scope="col">Vendedor</th>
                <th scope="col" width="12%">Contrato</th>
                <th scope="col" width="12%">Saldo</th>
                <th scope="col">Pagos atrasados</th>
                <th scope="col">Notas</th>
              </tr>
            </thead>
            <tbody align="center">
              <tr *ngFor="let carteraLis of carteraListado">
                <th scope="row" style="color: brown;">
                  <button class="form-select btn btn-dark"
                    (click)="desCar();slectPayments(carteraLis.codeProperty);reporteInmuCartera(carteraLis.codeProperty)">{{carteraLis.codeProperty}}</button>
                </th>
                <td>{{carteraLis.clientes}}</td>
                <td>{{carteraLis.usuarios}}</td>
                <td>{{carteraLis.fechaDato | date: 'dd-MM-yyyy'}}</td>
                <td>{{carteraLis.saldo | currency:'CAD' : '$ '}}</td>
                <td>
                  <div>
                    {{carteraLis.retraso}}
                  </div>
                  <div *ngIf="carteraLis.retraso>=0;">
                    <div class="progress">
                      <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 100%"></div>
                    </div>
                  </div>
                  <div *ngIf="carteraLis.retraso < 0 && carteraLis.retraso > tolerancia2" ngElse="warning2">
                    <div class="progress">
                      <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 100%">
                      </div>
                    </div>
                  </div>
                  <div *ngIf="carteraLis.retraso <= tolerancia2 && carteraLis.retraso >tolerancia3" ngElse="warning3">
                    <div class="progress">
                      <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%">
                      </div>
                    </div>
                  </div>
                  <div *ngIf="carteraLis.retraso <= tolerancia3 && carteraLis.retraso >limite" ngElse="danger">
                    <div class="progress">
                      <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%">
                      </div>
                    </div>
                  </div>
                  <div *ngIf="carteraLis.retraso <=limite">
                    <div class="progress">
                      <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%">
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <mat-icon class="actionAltBtn" (click)="selectNotes(carteraLis.codeProperty)">speaker_notes</mat-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>


        <div class="accountStatus" *ngIf="TarEst" style="Display: flex; flex-direction:row ; margin-top:20px;">

          <div class="saleDescription">

            <div style="width: 100%; margin-bottom: 30px;">
              <button class="btn btn-primary" style="width: 100%;" (click)="desEst();">Cartera</button>
            </div>

            <div *ngFor="let venta of carteraInmu" class="leftContainer">

              <div
                style="width:100%; display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start;">
                  <h5
                    style="width: 100%; margin: 0; padding: 2px 10px 2px 10px; color: white; text-align: center; background-color: var(--M19Primary); border-radius: 10px 10px 0 0;">
                    <mat-icon>apartment</mat-icon>Desarrollo
                  </h5>
                  <h5 style="width: 100%; margin: 0; text-align: center;"> {{venta.name}} -
                    {{venta.codeProperty}}</h5>
                </div>
              </div>

              <div class="dataRow">
                <mat-icon class="iconData">person</mat-icon>
                <div class="infoBlock">
                  <span style="font-size: 1.1rem;"><b>Cliente</b></span>
                  <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.clientes}}</b></span>
                </div>
              </div>

              <div class="dataRow">
                <mat-icon class="iconData">alternate_email</mat-icon>
                <div class="infoBlock">
                  <span style="font-size: 1.1rem;"><b>Correo</b></span>
                  <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.email}}</b></span>
                </div>
              </div>

              <div class="dataRow">
                <mat-icon class="iconData">call</mat-icon>
                <div class="infoBlock">
                  <span style="font-size: 1.1rem;"><b>Teléfono</b></span>
                  <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.mobile==null?"Sin
                      especificar":venta.mobile}}</b></span>
                </div>
              </div>

              <div class="dataRow" *ngFor="let ventas of salesCoo" ngIf="ventas.coopropietario!=null">
                <mat-icon class="iconData">person</mat-icon>
                <div class="infoBlock">
                  <span style="font-size: 1.1rem;"><b>Coopropietario</b></span>
                  <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{ventas.coopropietario}}</b></span>
                </div>
              </div>

              <div class="dataRow">
                <mat-icon class="iconData">support_agent</mat-icon>
                <div class="infoBlock">
                  <span style="font-size: 1.1rem;"><b>Coordinador</b></span>
                  <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.usuarios}}</b></span>
                </div>
              </div>

              <div class="dataRow">
                <mat-icon class="iconData">support_agent</mat-icon>
                <div class="infoBlock">
                  <span style="font-size: 1.1rem;"><b>Vendedor</b></span>
                  <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.vendedores==null?"Sin
                      especificar":venta.vendedores}}</b></span>
                </div>
              </div>

              <div class="dataRow">
                <mat-icon class="iconData">event</mat-icon>
                <div class="infoBlock">
                  <span style="font-size: 1.1rem;"><b>Fecha de contrato</b></span>
                  <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.fechaDato | date :
                      'dd-MM-YYYY'}}</b></span>
                </div>
              </div>

              <div class="dataRow">
                <mat-icon class="iconData">update</mat-icon>
                <div class="infoBlock">
                  <span style="font-size: 1.1rem;"><b>Fecha de primer pago</b></span>
                  <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.firstPayDate !=
                      null? (venta.firstPayDate | date:
                      'dd-MM-yyyy') : "Sin especificar"}}</b></span>
                </div>
              </div>

              <h3>Balance</h3>

              <div class="moneyBalance">
                <div class="balanceRow">
                  <span class="balanceTitle">Valor total</span>
                  <span class="balanceData">{{venta.valor | currency:'CAD' : '$ '}}</span>
                </div>
                <div class="balanceRow">
                  <span class="balanceSubtitle">Pagos Normales</span>
                  <span class="balanceData">{{venta.abonado | currency:'CAD' : '$ '}}</span>
                </div>
                <div class="balanceRow">
                  <span class="balanceSubtitle">Nota de Crédito</span>
                  <span class="balanceData">{{(anticipoC + mensualidadC) | currency:'CAD' : '$ '}}</span>
                </div>
                <div class="balanceRow">
                  <span class="balanceSubtitle">Nota de Crédito No Reembolsable</span>
                  <span class="balanceData">{{(anticipoNR + mensualidadNR) | currency:'CAD' : '$ '}}</span>
                </div>
                <div class="balanceRow">
                  <span class="balanceTitle">Adeudo</span>
                  <span
                    class="balanceData">{{venta.valor-venta.abonado-(mensualidadC+mensualidadNR)-(anticipoC+anticipoNR)
                    | currency:'CAD' : '$ '}}</span>
                </div>
              </div>

              <button class="m19AltBtn"
                (click)="getIndividualCreditPayments(venta.codeProperty, venta.clientes, venta.usuarios)">Ver
                recibos de crédito <mat-icon>receipt</mat-icon>
              </button>

              <table class="table table-hover mt-3">
                <thead>
                  <tr align="center">
                    <td>Pagos realizados</td>
                    <td>Pagos esperados</td>
                    <td>Estado de pagos</td>
                  </tr>
                </thead>
                <tbody>
                  <tr align="center">
                    <td>
                      <a>{{venta.mPagadas+' de ' +
                        ((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(2)}}</a>
                    </td>

                    <td>
                      <div style="display: flex; flex-direction: column;">
                        <div>
                          {{venta.meses+' de '+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(0)}}
                        </div>
                        <div *ngIf="venta.retraso >= 0;"
                          style="width: 100%; height: 10px; border-radius: 5px; background-color: var(--primaryBlue);">
                        </div>
                        <div *ngIf="venta.retraso < 0 && venta.retraso > -2"
                          style="width: 100%; height: 10px; border-radius: 5px; background-color: var(--primaryGreen);">
                        </div>
                        <div *ngIf="venta.retraso <= -2 && venta.retraso > -4"
                          style="width: 100%; height: 10px; border-radius: 5px; background-color: var(--primaryYellow);">
                        </div>
                        <div *ngIf="venta.retraso <= -4"
                          style="width: 100%; height: 10px; border-radius: 5px; background-color: var(--primaryRed);">
                        </div>
                      </div>
                    </td>
                    <td>
                      {{venta.retraso.toFixed(2)}}
                    </td>
                  </tr>
                </tbody>
              </table>

              <button class="m19Btn" style="width:100%; "
                (click)="selectPropertyNote(venta.codeProperty, venta.idUser)">
                Nueva nota <mat-icon>post_add</mat-icon>
              </button>

            </div>

          </div>

          <div style="width: 65%;">
            <table class="m19Table">
              <thead>
                <tr>
                  <th style="text-align: right;">#</th>
                  <th scope="col">Folio</th>
                  <th scope="col">Fecha</th>
                  <th scope="col">Cantidad</th>
                  <th scope="col">Método de pago</th>
                  <th scope="col">Concepto</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let p of payments; let i=index;">
                  <td> {{ i + 1 }} </td>
                  <td> {{p.folioPago}} </td>
                  <td style="text-align: center;">{{pipe.transform(p.fechaPago, "longDate")}}</td>
                  <td> {{p.cantidadAbono | currency:'CAD' : '$ ' }} </td>
                  <td> {{p.metodoPago}} </td>
                  <td> {{p.concepto}} </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>

      </mat-expansion-panel>


      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span> <b>Reporte de Cartera Vencida </b> </span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="projectButtons">
          <button class="m19AltBtn" *ngFor="let p of projects" [value]="p.name"
            (click)="filterWalletByProjects(p.name);">
            {{p.name}} <mat-icon>apartment</mat-icon>
          </button>
        </div>

        <div *ngIf="filterByProjectAndType.length==0 || filterByProjectAndType[0]=='Sin seleccionar'"
          style="display: flex; flex-direction: column; height: 40vh; justify-content: center; align-items: center;">
          <h2 *ngIf="filterByProjectAndType[0] == 'Sin seleccionar'">Seleccione un
            proyecto para visualizar la cartera </h2>
          <p *ngIf="filterByProjectAndType[0] == 'Sin seleccionar'">
            <mat-icon style="font-size: 2rem;">search</mat-icon>
          </p>
          <h2 *ngIf="filterByProjectAndType.length==0"> {{this.currentProject}} aun no cuenta con registro en cartera
          </h2>
          <p *ngIf="filterByProjectAndType.length==0">
            <mat-icon style="font-size: 2rem;">sentiment_very_dissatisfied</mat-icon>
          </p>
        </div>

        <div class="accountStatus" *ngIf="this.banderasV2.personal == true"
          style="Display: flex; flex-direction:row ; margin-top:20px;">
          <div class="saleDescription">

            <div *ngFor="let dc of dataCS" class="leftContainer">
              <div
                style="width:100%; display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start;">
                  <h5
                    style="width: 100%; margin: 0; padding: 2px 10px 2px 10px; color: white; text-align: center; background-color: var(--M19Primary); border-radius: 10px 10px 0 0;">
                    <mat-icon>apartment</mat-icon>Desarrollo
                  </h5>
                  <h5 style="width: 100%; margin: 0; text-align: center;"> {{dc.name}} -
                    {{dc.codeProperty}}</h5>
                </div>
              </div>

              <div class="dataRow">
                <mat-icon class="iconData">person</mat-icon>
                <div class="infoBlock">
                  <span style="font-size: 1.1rem;"><b>Cliente</b></span>
                  <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{dc.clientes}}</b></span>
                </div>
              </div>

              <div class="dataRow">
                <mat-icon class="iconData">alternate_email</mat-icon>
                <div class="infoBlock">
                  <span style="font-size: 1.1rem;"><b>Correo</b></span>
                  <span
                    style="font-size: 1.1rem; color: var(--M19Primary); word-break: break-all;"><b>{{dc.email}}</b></span>
                </div>
              </div>

              <div class="dataRow">
                <mat-icon class="iconData">call</mat-icon>
                <div class="infoBlock">
                  <span style="font-size: 1.1rem;"><b>Teléfono</b></span>
                  <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{dc.mobile==null?"Sin
                      especificar":dc.mobile}}</b></span>
                </div>
              </div>

              <div class="dataRow" *ngFor="let ventas of salesCoo" ngIf="ventas.coopropietario!=null">
                <mat-icon class="iconData">person</mat-icon>
                <div class="infoBlock">
                  <span style="font-size: 1.1rem;"><b>Coopropietario</b></span>
                  <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{ventas.coopropietario}}</b></span>
                </div>
              </div>

              <div class="dataRow">
                <mat-icon class="iconData">support_agent</mat-icon>
                <div class="infoBlock">
                  <span style="font-size: 1.1rem;"><b>Coordinador</b></span>
                  <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{dc.usuarios}}</b></span>
                </div>
              </div>

              <div class="dataRow">
                <mat-icon class="iconData">support_agent</mat-icon>
                <div class="infoBlock">
                  <span style="font-size: 1.1rem;"><b>Vendedor</b></span>
                  <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{dc.vendedores==null?"Sin
                      especificar":dc.vendedores}}</b></span>
                </div>
              </div>

              <div class="dataRow">
                <mat-icon class="iconData">event</mat-icon>
                <div class="infoBlock">
                  <span style="font-size: 1.1rem;"><b>Fecha de contrato</b></span>
                  <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{dc.fechaDato | date :
                      'dd-MM-YYYY'}}</b></span>
                </div>
              </div>

              <div class="dataRow">
                <mat-icon class="iconData">update</mat-icon>
                <div class="infoBlock">
                  <span style="font-size: 1.1rem;"><b>Fecha de primer pago</b></span>
                  <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{dc.firstPayDate !=
                      null? (dc.firstPayDate | date:
                      'dd-MM-yyyy') : "Sin especificar"}}</b></span>
                </div>
              </div>

              <h3>Balance</h3>

              <div class="moneyBalance">
                <div class="balanceRow">
                  <span class="balanceTitle">Valor total</span>
                  <span class="balanceData">{{dc.valor | currency:'CAD' : '$ '}}</span>
                </div>
                <div class="balanceRow">
                  <span class="balanceSubtitle">Pagos Normales</span>
                  <span class="balanceData">{{dc.abonado | currency:'CAD' : '$ '}}</span>
                </div>
                <div class="balanceRow">
                  <span class="balanceSubtitle">Nota de Crédito</span>
                  <span class="balanceData">{{(anticipoC + mensualidadC) | currency:'CAD' : '$ '}}</span>
                </div>
                <div class="balanceRow">
                  <span class="balanceSubtitle">Nota de Crédito No Reembolsable</span>
                  <span class="balanceData">{{(anticipoNR + mensualidadNR) | currency:'CAD' : '$
                    '}}</span>
                </div>
                <div class="balanceRow">
                  <span class="balanceTitle">Adeudo</span>
                  <span class="balanceData">{{dc.valor-dc.abonado-(mensualidadC+mensualidadNR)-(anticipoC+anticipoNR)
                    | currency:'CAD' : '$ '}}</span>
                </div>
              </div>

              <button class="m19AltBtn"
                (click)="getIndividualCreditPayments(dc.codeProperty, dc.clientes, dc.usuarios)">Ver
                recibos de crédito <mat-icon>receipt</mat-icon>
              </button>

              <table class="table table-hover mt-3">
                <thead>
                  <tr align="center">
                    <td>Pagos realizados</td>
                    <td>Pagos esperados</td>
                    <td>Estado de pagos</td>
                  </tr>
                </thead>
                <tbody>
                  <tr align="center">
                    <td> <a>{{dc.mPagadas+' de ' +
                        ((dc.valor-dc.advancePayment)/dc.monthlyPayment).toFixed(2)}}</a>
                    </td>

                    <td>
                      <div style="display: flex; flex-direction: column;">
                        <div>
                          {{dc.meses+' de '+((dc.valor-dc.advancePayment)/dc.monthlyPayment).toFixed(0)}}
                        </div>
                        <div *ngIf="dc.retraso >= 0;"
                          style="width: 100%; height: 10px; border-radius: 5px; background-color: var(--primaryBlue);">
                        </div>
                        <div *ngIf="dc.retraso < 0 && dc.retraso > -2"
                          style="width: 100%; height: 10px; border-radius: 5px; background-color: var(--primaryGreen);">
                        </div>
                        <div *ngIf="dc.retraso <= -2 && dc.retraso > -4"
                          style="width: 100%; height: 10px; border-radius: 5px; background-color: var(--primaryYellow);">
                        </div>
                        <div *ngIf="dc.retraso <= -4"
                          style="width: 100%; height: 10px; border-radius: 5px; background-color: var(--primaryRed);">
                        </div>
                      </div>
                    </td>
                    <td>
                      {{dc.retraso.toFixed(2)}}
                    </td>
                  </tr>
                </tbody>
              </table>

              <button class="m19Btn" style="width:100%; " (click)="selectPropertyNote(dc.codeProperty, dc.idUser)">
                Nueva nota <mat-icon>post_add</mat-icon>
              </button>

            </div>

          </div>

          <div style="width: 65%;">
            <table class="m19Table">
              <thead>
                <tr>
                  <th style="text-align: center;">#</th>
                  <th style="text-align: left;">Folio</th>
                  <th style="text-align: center;">Fecha</th>
                  <th style="text-align: right;">Cantidad</th>
                  <th style="text-align: left;">Método de pago</th>
                  <th style="text-align: left;">Concepto</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let p of pagosPorCliente let i=index;">
                  <td style="text-align: right;"><b>{{ i + 1 }}</b></td>
                  <td style="text-align: right;"><b>{{p.folioPago}}</b></td>
                  <td style="text-align: center;">{{pipe.transform(p.fechaPago, "longDate")}}</td>
                  <td style="text-align: right;">{{p.cantidadAbono | currency:'CAD' : '$ ' }}</td>
                  <td style="text-align: left;">{{p.metodoPago}}</td>
                  <td style="text-align: left;">{{p.concepto}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div [hidden]="this.banderasV2.general == false" class="accountStatus">
          <div class="chartCont">
            <h3 style="text-align:center; color: white; text-shadow: 1px 0px 0px black,  0px 1px 0px black,  -1px 0px 0px black,  0px -1px 0px black;"> <b> {{currentProject}} </b> </h3>

            <canvas baseChart
              [data]="[this.counterColorsV2.blue, this.counterColorsV2.green, this.counterColorsV2.yellow, this.counterColorsV2.red]"
              [labels]="pieChartLabelV2" [chartType]="pieChartTypesV2" [options]="pieChartOptionV2"
              (plugins)="pieChartPluginV2" [colors]="pieChartColorV2" [legend]="pieChartLegendsV2">
            </canvas>

            <div style="width: 100%; display: flex; ">
              <h5
                style="width: 100%; margin: 0; padding: 2px 10px 2px 10px; color: white; text-align: center; background-color: var(--M19Primary); border-radius: 10px 10px 10px 10px; margin-top: 30px; margin-bottom:30px">
                En cartera
              </h5>
            </div>

            <div class="moneyBalance">
              <div class="balanceRow">
                <span class="balanceSubtitle"> Con Acuerdo: </span>
                <span class="balanceData"> <b style="color: var(--primaryPurple); font-weight: bold;">
                    {{this.counterColorsV2.purple}} </b> </span>
              </div>
              <div class="balanceRow">
                <span class="balanceSubtitle"> Al corriente: </span>
                <span class="balanceData"> <b style="color: var(--primaryBlue); font-weight: bold;">
                    {{this.counterColorsV2.blue}} </b> </span>
              </div>
              <div class="balanceRow">
                <span class="balanceSubtitle"> Con 1 Atraso: </span>
                <span class="balanceData"> <b style="color: var(--primaryGreen); font-weight: bold;">
                    {{this.counterColorsV2.green}} </b> </span>
              </div>
              <div class="balanceRow">
                <span class="balanceSubtitle"> En riesgo: </span>
                <span class="balanceData"> <b style="color: var(--primaryYellow); font-weight: bold;">
                    {{this.counterColorsV2.yellow}} </b> </span>
              </div>
              <div class="balanceRow">
                <span class="balanceSubtitle"> Rescisión: </span>
                <span class="balanceData"> <b style="color: var(--primaryRed); font-weight: bold;">
                    {{this.counterColorsV2.red}} </b> </span>
              </div>

              <div class="balanceRow" *ngIf="this.counterColorsV2.ninguno != 0">
                <span class="balanceSubtitle"> Sin asignar: </span>
                <span class="balanceData"> <b style="color: var(--primaryRed); font-weight: bold;">
                    {{this.counterColorsV2.ninguno}} </b> </span>
              </div>

              <div class="balanceRow">
                <span class="balanceTitle"> Clientes totales: </span>
                <span class="balanceData"> {{filterByProjectAndType.length}} </span>
              </div>
            </div>
          </div>

          <div class="tableCont">
            <table class="m19Table">
              <thead>
                <tr>
                  <th style="width: 15%; text-align: center;">Inmueble</th>
                  <th style="width: 20%; ">Cliente</th>
                  <th style="width: 20%; ">Vendedor</th>
                  <th style="width: 9%;text-align: center;">Contrato</th>
                  <th style="width: 8%;">Adeudo</th>
                  <th style="width: 8%;">Atraso</th>
                  <th style="width: 6%;"> Notas </th>
                  <th>¿Convenio?</th>
                  <!-- <th style="width: 4%;"> Bloquear </th> -->
                  <th style="width: 4%;" *ngIf="permissions().match('446576656c6f706572')"> Bloquear </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cus of this.filterByProjectAndType;">

                  <td style="width: 15%; text-align:center">
                    <button class="m19InvBtn" (click)="mueveData(cus.codeProperty); selectPayments(cus.codeProperty)">
                      {{cus.codeProperty}}
                    </button>
                  </td>

                  <td style="width: 20%;">{{cus.clientes}}</td>

                  <td style="width: 20%;">{{cus.usuarios}} </td>

                  <td style="width: 11%; white-space: nowrap; text-align: center;">
                    <p>{{pipe.transform(cus.fechaDato,'longDate')}}</p>
                  </td>

                  <td style="width:8; text-align: right;">{{cus.saldo | currency: 'CAD': '$' }} </td>

                  <td style="width:8%; justify-content: center; align-items: center;">
                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                      <mat-icon *ngIf="cus.agreement==1" style="color: blueviolet; text-align: center;"
                        data-title="Con Convenio">handshake</mat-icon>

                      <div *ngIf="cus.monthRecession == 4">
                        <ng-container *ngIf="cus.retraso >= 0 ; else tipo1">
                          <div
                            style="background-color: var(--primaryBlue); color: white; width: 100%; height: 100%; font-weight: bold; text-align: center; padding: 5px; border-radius: 5px;">
                            {{cus.retraso.toFixed(2)}}<br>de {{cus.monthRecession}}
                          </div>
                        </ng-container>

                        <ng-template #tipo1>
                          <ng-container *ngIf="  cus.retraso >= -1 && cus.retraso < 0  ; else tipo2">
                            <div
                              style="background-color: var(--primaryGreen); color: white; width: 100%; height: 100%; font-weight: bold; text-align: center; padding: 5px; border-radius: 5px;">
                              {{cus.retraso.toFixed(2)}}<br>de {{cus.monthRecession}}
                            </div>
                          </ng-container>
                        </ng-template>

                        <ng-template #tipo2>
                          <ng-container *ngIf=" cus.retraso > -4 && cus.retraso < -1; else tipo3">
                            <div
                              style="background-color: var(--primaryYellow); color: white; width: 100%; height: 100%; font-weight: bold; text-align: center; padding: 5px; border-radius: 5px;">
                              {{cus.retraso.toFixed(2)}}<br>de {{cus.monthRecession}}
                            </div>
                          </ng-container>
                        </ng-template>

                        <ng-template #tipo3>
                          <ng-container *ngIf=" cus.retraso <= -4; else tipo4">
                            <div
                              style="background-color: var(--primaryRed); color: white; width: 100%; height: 100%; font-weight: bold; text-align: center; padding: 5px; border-radius: 5px;">
                              {{cus.retraso.toFixed(2)}}<br>de {{cus.monthRecession}}
                            </div>
                          </ng-container>
                        </ng-template>

                        <ng-template #tipo4>
                          <ng-container *ngIf=" cus.retraso is null;">
                            {{cus.retraso.toFixed(2)}}<br>de {{cus.monthRecession}}
                            <div class="progress">
                              <div class="progress-bar progress-bar-striped progress-bar-animated " role="progressbar"
                                style="width: 100%; background-color: #0000; color: white;">
                              </div>
                            </div>
                          </ng-container>
                        </ng-template>
                      </div>

                      <div *ngIf="cus.monthRecession == 3">
                        <ng-container *ngIf="cus.retraso >= 0 ; else tipo1">
                          <div
                            style="background-color: var(--primaryBlue); color: white; width: 100%; height: 100%; font-weight: bold; text-align: center; padding: 5px; border-radius: 5px;">
                            {{cus.retraso.toFixed(2)}}<br>de {{cus.monthRecession}}
                          </div>
                        </ng-container>

                        <ng-template #tipo1>
                          <ng-container *ngIf=" cus.retraso >= -1 && cus.retraso < 0 ; else tipo2">
                            <div
                              style="background-color: var(--primaryGreen); color: white; width: 100%; height: 100%; font-weight: bold; text-align: center; padding: 5px; border-radius: 5px;">
                              {{cus.retraso.toFixed(2)}}<br>de {{cus.monthRecession}}
                            </div>
                          </ng-container>
                        </ng-template>

                        <ng-template #tipo2>
                          <ng-container *ngIf=" cus.retraso > -3 && cus.retraso < -1  ; else tipo3">
                            <div
                              style="background-color: var(--primaryYellow); color: white; width: 100%; height: 100%; font-weight: bold; text-align: center; padding: 5px; border-radius: 5px;">
                              {{cus.retraso.toFixed(2)}}<br>de {{cus.monthRecession}}
                            </div>
                          </ng-container>
                        </ng-template>

                        <ng-template #tipo3>
                          <ng-container *ngIf=" cus.retraso <= -3; else tipo4">
                            <div
                              style="background-color: var(--primaryRed); color: white; width: 100%; height: 100%; font-weight: bold; text-align: center; padding: 5px; border-radius: 5px;">
                              {{cus.retraso.toFixed(2)}}<br>de {{cus.monthRecession}}
                            </div>
                          </ng-container>
                        </ng-template>

                        <ng-template #tipo4>
                          <ng-container *ngIf=" cus.retraso == null;">
                            3 meses
                            <div class="progress">
                              <div class="progress-bar progress-bar-striped progress-bar-animated " role="progressbar"
                                style="width: 100%; background-color: #0000;">
                              </div>
                            </div>
                          </ng-container>
                        </ng-template>
                      </div>

                      <div *ngIf="cus.monthRecession == 2">
                        <ng-container *ngIf="cus.retraso >= 0 ; else tipo1">
                          <div
                            style="background-color: var(--primaryBlue); color: white; width: 100%; height: 100%; font-weight: bold; text-align: center; padding: 5px; border-radius: 5px;">
                            {{cus.retraso.toFixed(2)}}<br>de {{cus.monthRecession}}
                          </div>
                        </ng-container>

                        <ng-template #tipo1>
                          <ng-container *ngIf=" cus.retraso > -1 && cus.retraso < 0 ; else tipo2">
                            <div
                              style="background-color: var(--primaryGreen); color: white; width: 100%; height: 100%; font-weight: bold; text-align: center; padding: 5px; border-radius: 5px;">
                              {{cus.retraso.toFixed(2)}}<br>de {{cus.monthRecession}}
                            </div>
                          </ng-container>
                        </ng-template>

                        <ng-template #tipo2>
                          <ng-container *ngIf=" cus.retraso > -2 && cus.retraso <= -1; else tipo3">
                            <div
                              style="background-color: var(--primaryYellow); color: white; width: 100%; height: 100%; font-weight: bold; text-align: center; padding: 5px; border-radius: 5px;">
                              {{cus.retraso.toFixed(2)}}<br>de {{cus.monthRecession}}
                            </div>
                          </ng-container>
                        </ng-template>

                        <ng-template #tipo3>
                          <ng-container *ngIf=" cus.retraso <= -2; else tipo4">
                            <div
                              style="background-color: var(--primaryRed); color: white; width: 100%; height: 100%; font-weight: bold; text-align: center; padding: 5px; border-radius: 5px;">
                              {{cus.retraso.toFixed(2)}}<br>de {{cus.monthRecession}}
                            </div>
                          </ng-container>
                        </ng-template>

                        <ng-template #tipo4>
                          <ng-container *ngIf=" cus.retraso == null;">
                            2 meses
                            <div class="progress">
                              <div class="progress-bar progress-bar-striped progress-bar-animated " role="progressbar"
                                style="width: 100%; background-color: #0000;">
                              </div>
                            </div>
                          </ng-container>
                        </ng-template>
                      </div>
                    </div>
                  </td>

                  <td style="width: 6%;text-align:center;">
                    <mat-icon class="bntNotes" (click)="selectNotes(cus.codeProperty)">
                      speaker_notes
                    </mat-icon>
                  </td>

                  <td style="width: 4%;text-align:center;">
                    <mat-icon class="actionBtn" (click)="setAgreement(cus.codeProperty, cus.idUser)"
                      *ngIf="cus.agreement==0">report</mat-icon>
                  </td>

                  <td style="width: 4%;text-align:center;" *ngIf="permissions().match('446576656c6f706572')">
                    <mat-icon class="actionBtn" (click)="initBloq(cus)">lock</mat-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>