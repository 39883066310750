<app-loader [loader]="loader" *ngIf="loading==1"></app-loader>
<mat-tab-group mat-stretch-tabs>
    <!-- CORTE DE CAJA NUEVO-->
    <mat-tab>
        <ng-template mat-tab-label>
            Corte de Caja <mat-icon>move_to_inbox</mat-icon>
        </ng-template>

        <div class="containerGeneralCashCut">

            <div class="containerGeneralCashCut__containerHeader">
                <h1> DÍA: {{pipe.transform(this.currentDate ,'EEEE d MMMM y')!.toUpperCase()}} </h1>
            </div>

            <div class="containerGeneralCashCut__containerSummary">
                <div class="moneyBalance">
                    <div class="balanceRow">
                        <span class="balanceTitle"> Ingresos </span>
                        <span class="balanceData"> {{(this.cashCutTotal.cashtotal).toFixed(2) | currency: 'CAD': '$'}}
                        </span>
                    </div>
                    <div class="balanceRow">
                        <span class="balanceTitle"> Gastos de cobranza </span>
                        <span class="balanceData"> {{(this.cashCutTotal.gastosCobranzaEf).toFixed(2) | currency: 'CAD': '$'}}
                        </span>
                    </div>
                    <div class="balanceRow">
                        <span class="balanceTitle"> Salidas </span>
                        <span class="balanceData"> {{(this.cashCutTotal.cashOutTotal).toFixed(2) | currency: 'CAD':'$'}}
                        </span>
                    </div>
                    <div class="balanceRow">
                        <span class="balanceSubtitle"> Efectivo total </span>
                        <span class="balanceData"> {{((this.cashCutTotal.cashtotal + this.cashCutTotal.gastosCobranzaEf) -
                            this.cashCutTotal.cashOutTotal).toFixed(2) | currency: '$'}} </span>
                    </div>
                </div>

                <div class="containerGeneralCashCut__containerSummary__containerButtons">
                    <button class="m19Btn" style="margin-bottom: 10px;" (click)="summary()">
                        VER RESUMEN
                    </button>

                    <button class="m19Btn" (click)="corteCaja();" [hidden]="banderaBoton.cashCut == false">
                        CIERRE DE CAJA
                    </button>

                    <button class="m19Btn" style="margin-top: 10px;" (click)="partialCashCut();"
                        [hidden]="banderaBoton.partial == false">
                        CORTE PARCIAL
                    </button>

                </div>

            </div>

            <div class="buttonsBelt">

                <button class="btnColor" (click)="inputTypeChange($event);">Efectivo</button>
                <button class="btnColor" (click)="inputTypeChange($event);">Deposito</button>
                <button class="btnColor" (click)="inputTypeChange($event);">Transferencia</button>
                <button class="btnColor" (click)="inputTypeChange($event);">Tarjeta de credito</button>
                <button class="btnColor" (click)="inputTypeChange($event);">Tarjeta de debito</button>
                <button class="btnColor" (click)="inputTypeChange($event);">Cheque</button>
                <button class="btnColor" (click)="inputTypeChange($event);">Todos</button>

            </div>

            <div class="containerGeneralCashCut__containerTables">

                <div class="containertbl">
                    <h3>
                        {{this.inputType}}
                    </h3>
                    <table class="m19TableUwu">
                        <thead>
                            <tr style="text-align:left; ">
                                <th style="text-align:center;"> id </th>
                                <th> Nombre </th>
                                <th>Propiedad</th>
                                <th style="text-align:right; padding-right: 10px;">Cantidad</th>
                            </tr>
                        </thead>

                        <tbody [hidden]="this.cashCutFlags.cash == false">
                            <tr *ngFor="let cash of this.cashCutEntries.cash"
                                style="text-align: left; overflow: scroll; ">
                                <td>
                                    {{cash.folioPago}}
                                </td>
                                <td>
                                    {{cash.cliente}}
                                </td>
                                <td>
                                    {{cash.fkCliente}}
                                </td>
                                <td style="text-align:right; padding-right: 10px;">
                                    {{cash.cantidadAbono | currency: 'CAD': '$'}}
                                </td>
                            </tr>
                        </tbody>

                        <tbody [hidden]="this.cashCutFlags.deposit == false">
                            <tr *ngFor="let dep of this.cashCutEntries.deposit"
                                style="text-align: left; overflow: scroll; ">
                                <td>
                                    {{dep.folioPago}}
                                </td>
                                <td>
                                    {{dep.cliente}}
                                </td>
                                <td>
                                    {{dep.fkCliente}}
                                </td>
                                <td style="text-align:right; padding-right: 10px;">
                                    {{dep.cantidadAbono | currency: 'CAD': '$'}}
                                </td>
                            </tr>
                        </tbody>

                        <tbody [hidden]="this.cashCutFlags.tranfer == false">
                            <tr *ngFor="let trans of this.cashCutEntries.transfer"
                                style="text-align: left; overflow: scroll; ">
                                <td>
                                    {{trans.folioPago}}
                                </td>
                                <td>
                                    {{trans.cliente}}
                                </td>
                                <td>
                                    {{trans.fkCliente}}
                                </td>
                                <td style="text-align:right; padding-right: 10px;">
                                    {{trans.cantidadAbono | currency: 'CAD': '$'}}
                                </td>
                            </tr>
                        </tbody>

                        <tbody [hidden]="this.cashCutFlags.tdc == false">
                            <tr *ngFor="let tarjcre of this.cashCutEntries.tdc"
                                style="text-align: left; overflow: scroll; ">
                                <td>
                                    {{tarjcre.folioPago}}
                                </td>
                                <td>
                                    {{tarjcre.cliente}}
                                </td>
                                <td>
                                    {{tarjcre.fkCliente}}
                                </td>
                                <td style="text-align:right; padding-right: 10px;">
                                    {{tarjcre.cantidadAbono | currency: 'CAD': '$'}}
                                </td>
                            </tr>
                        </tbody>

                        <tbody [hidden]="this.cashCutFlags.tdd == false">
                            <tr *ngFor="let tarjDeb of this.cashCutEntries.tdd"
                                style="text-align: left; overflow: scroll; ">
                                <td>
                                    {{tarjDeb.folioPago}}
                                </td>
                                <td>
                                    {{tarjDeb.cliente}}
                                </td>
                                <td>
                                    {{tarjDeb.fkCliente}}
                                </td>
                                <td style="text-align:right; padding-right: 10px;">
                                    {{tarjDeb.cantidadAbono | currency: 'CAD': '$'}}
                                </td>
                            </tr>
                        </tbody>

                        <tbody [hidden]="this.cashCutFlags.check == false">
                            <tr *ngFor="let che of this.cashCutEntries.check"
                                style="text-align: left; overflow: scroll; ">
                                <td>
                                    {{che.folioPago}}
                                </td>
                                <td>
                                    {{che.cliente}}
                                </td>
                                <td>
                                    {{che.fkCliente}}
                                </td>
                                <td style="text-align:right; padding-right: 10px;">
                                    {{che.cantidadAbono | currency: 'CAD': '$'}}
                                </td>
                            </tr>
                        </tbody>

                        <tbody [hidden]="this.cashCutFlags.all == false">
                            <tr *ngFor="let all of this.cashCutEntries.all">
                                <td>
                                    {{all.folioPago}}
                                </td>
                                <td>
                                    {{all.cliente}}
                                </td>
                                <td>
                                    {{all.fkCliente}}
                                </td>
                                <td style="text-align:right; padding-right: 10px;">
                                    {{all.cantidadAbono | currency: 'CAD': '$'}}
                                </td>
                            </tr>
                        </tbody>


                    </table>
                </div>

                <div class="containertbl">
                    <h3>
                        Salidas
                    </h3>
                    <table class="m19TableUwu">
                        <thead>
                            <tr style="text-align: left; ">
                                <th style="text-align:center; width: 50%;"> Concepto </th>
                                <th style="text-align:center; width: 30%;"> Solicitó </th>
                                <th style="text-align:center; width: 20%;"> Cantidad </th>
                        </thead>

                        <tbody>
                            <tr *ngFor="let out of this.cashCutEntries.cashout;"
                                style="text-align: left; overflow: scroll;">
                                <td style="padding-left: 10px;"> {{out.reason}}</td>
                                <td> {{out.requestor}}</td>
                                <td style="text-align:right; padding-right: 10px;"> {{(out.quantity | currency: 'CAD':
                                    '$')}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

        </div>


        <div id="cashCuthtml" [hidden]="true">


            <div class="encabezado">
                <h1> Cierre de caja </h1>
                <br>

                <div class="tiempo-impresion">
                    <p>
                        <b style=" padding-right: 5px;"> FECHA DE CORTE : </b>
                        {{pipe.transform(currentDate,'EEEE')!.toLowerCase()}} {{pipe.transform(currentDate
                        ,'longDate')!.toLowerCase()}}
                    </p>
                </div>
                <br>

                <div class="margen">
                    <b style=" padding-right: 5px;"> FECHA DE IMPRESIÓN: </b>
                    <p> {{currentDate | date: 'dd/MM/yyyy' }} </p>
                </div>

            </div>

            <br>
            <h4 *ngIf="cashCutEntries.cash.length > 0" > EFECTIVO RECIBIDO </h4>

            <table class="formatTableBorders" *ngIf="cashCutEntries.cash.length > 0">
                <thead>
                    <tr class="tableTextHeader">
                        <th class="tableTextHeader">#</th>
                        <th class="tableTextHeader">Id</th>
                        <th class="tableTextHeader">Nombre</th>
                        <th class="tableTextHeader">Propiedad </th>
                        <th class="tableTextHeader">Cantidad</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let payTo of cashCutEntries.cash; let i=index;">
                        <td class="tableDataText1">{{i+1}}</td>
                        <td class="tableDataText1"> {{payTo.folioPago }} </td>
                        <td class="tableDataText2"> {{payTo.cliente}} </td>
                        <td class="tableDataText1"> {{payTo.fkCliente}} </td>
                        <td class="tableDataText1" style="text-align: right;"> {{(payTo.cantidadAbono | currency:
                            'CAD': '$')}}</td>
                    </tr>

                    <!-- <tr *ngFor="let check of cashCutEntries.check; let i=index;">
                        <td class="tableDataText1">{{i+1}}</td>
                        <td class="tableDataText1"> {{check.folioPago }} </td>
                        <td class="tableDataText2"> {{check.cliente}} </td>
                        <td class="tableDataText1"> {{check.fkCliente}} </td>
                        <td class="tableDataText1" style="text-align: right;"> {{(check.cantidadAbono | currency: 'CAD': '$')}}</td>
                    </tr> -->

                </tbody>
            </table>

            <div class="continueSeccion" *ngIf="cashCutEntries.cobranza.length > 0">
                <br>
                <h4> GASTOS DE COBRANZA </h4>

                <table class="formatTableBorders" >
                    <thead>
                        <tr class="tableTextHeader">
                            <th class="tableTextHeader">#</th>
                            <th class="tableTextHeader">Id</th>
                            <th class="tableTextHeader">Nombre</th>
                            <th class="tableTextHeader">Propiedad </th>
                            <th class="tableTextHeader">Cantidad</th>
                        </tr>
                    </thead>
    
                    <tbody>
                        <tr *ngFor="let payTo of cashCutEntries.cobranza; let i=index;">
                            <td class="tableDataText1">{{i+1}}</td>
                            <td class="tableDataText1"> {{payTo.folioPago }} </td>
                            <td class="tableDataText2"> {{payTo.cliente}} </td>
                            <td class="tableDataText1"> {{payTo.fkCliente}} </td>
                            <td class="tableDataText1" style="text-align: right;"> {{(payTo.cantidadAbono | currency: 'CAD': '$')}}</td>
                        </tr>    
                    </tbody>
                </table>

            </div>

            <div class="totalSeccion">
                <br>
                <br>
                <h4 *ngIf="cashCutEntries.cashout.length > 0"> SALIDAS DE EFECTIVO </h4>

                <table class="formatTableBorders" *ngIf="cashCutEntries.cashout.length > 0 ">
                    <thead>
                        <tr class="tableTextHeader">

                            <th class="tableTextHeader"> # </th>
                            <th class="tableTextHeader"> Concepto </th>
                            <th class="tableTextHeader"> Quien Solicito el Dinero </th>
                            <th class="tableTextHeader"> Cantidad </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let out of cashCutEntries.cashout;">

                            <td class="tableDataText1"> {{out.idCashOut }}</td>
                            <td class="tableDataText2"> {{out.reason}}</td>
                            <td class="tableDataText2"> {{out.requestor}}</td>
                            <td class="tableDataText1" style="text-align: right;"> {{(out.quantity | currency:
                                'CAD':'$')}}</td>

                        </tr>
                    </tbody>
                </table>

                <br>
                <br>

                <h4> GASTOS DE COBRANZA </h4>

                <div class="infoColumn">
                    <div class="texto-salida">
                        <p> {{(this.cashCutTotal.gastosCobranzaEf).toFixed(2) | currency: 'CAD':'$'}} </p>
                    </div>

                </div>
                <br>

                <h4> TOTALES </h4>

                <div class="infoColumn">
                    <div class="texto-salida">
                        <p> <b> ENTRADAS: </b> </p>
                        <p> {{(cashCutTotal.cashtotal  + cashCutTotal.gastosCobranzaEf ).toFixed(2) | currency: 'CAD':'$'}} </p>

                    </div>

                    <div class="texto-salida">
                        <p> <b> SALIDAS: </b> </p>
                        <p> {{(cashCutTotal.cashOutTotal).toFixed(2) | currency: 'CAD':'$'}} </p>
                    </div>


                    <div class="texto-salida">
                        <p> <b> TOTAL: </b> </p>
                        <p class="salida-neta"> {{((cashCutTotal.cashtotal + cashCutTotal.gastosCobranzaEf ) - cashCutTotal.cashOutTotal).toFixed(2) | currency: 'CAD':'$'}} </p>
                    </div>


                </div>

                <br>
                <br>
                <br>

                <div class="shared-Container">

                    <div class="information-Delivery">
                        <h3> ENTREGA </h3>
                        <br>
                        <br>
                        <br>
                        <br>
                        <h3> ___________________________________ </h3>
                        <br>
                        <h3> {{getUser().toUpperCase()}} </h3>
                        <br>

                    </div>

                    <div class="information-Receive">
                        <h3> RECIBE </h3>
                        <br>
                        <br>
                        <br>
                        <br>
                        <h3> ___________________________________ </h3>
                        <br>
                        <h3> {{this.assign.toUpperCase()}} </h3>
                        <br>
                    </div>

                </div>

            </div>



        </div>

    </mat-tab>

    <!-- REGISTRO DE SALIDAS DE EFECTIVO -->
    <mat-tab>

        <ng-template mat-tab-label>
            Salidas de Efectivo <mat-icon> attach_money</mat-icon>
        </ng-template>

        <div class="container-fluid">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">

                    <div class="contenedorRegistro">
                        <div class="registerFields">
                            <form [formGroup]="CashOutflowsFormGroup" (ngSubmit)="registerOutCash();">
                                <h5>Registro de salida de efectivo</h5>
                                <span style="border-top: solid var(--M19Primary) 2px; width: 100%;"> </span>

                                <div class="ng-autocomplete">
                                    <ng-autocomplete [data]="employ" [searchKeyword]="keyword"
                                        placeholder="Quien Solicita el dinero" (selected)='nameAssignment($event)'
                                        [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" required>

                                    </ng-autocomplete>
                                    <ng-template #itemTemplate let-item>
                                        <div class="responsableCont">
                                            <div class="image" *ngIf="item.picture != null else nopicture">
                                                <img [src] = "item.picture" alt="Foto de Perfil">
                                            </div>
                                            <ng-template #nopicture>
                                                <p class="letter">{{item.initial}}</p>
                                            </ng-template>
                                            <p class="name" [innerHTML]="item.empleado"></p>
                                        </div>
                                    </ng-template>
                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Cantidad</mat-label>
                                        <input matInput type="number" formControlName="quantity" required>
                                        <mat-icon matSuffix> attach_money</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Usuario que entrega el efectivo </mat-label>
                                        <input matInput type="text" formControlName="cashManager" readonly>
                                        <mat-icon matSuffix> person_pin</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Fecha</mat-label>
                                        <input matInput type="date" formControlName="registrationDate" required>
                                        <mat-icon matSuffix>calendar_month</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label> Concepto </mat-label>
                                        <input matInput type="text" formControlName="reason" required>
                                        <mat-icon matSuffix>text_rotation_none</mat-icon>
                                    </mat-form-field>
                                </div>

                                <button class="m19Btn" type="submit" [disabled]="!CashOutflowsFormGroup.valid">
                                    Registrar Salida
                                    <mat-icon>exit_to_app</mat-icon>
                                </button>

                            </form>

                        </div>

                    </div>

                </mat-card-content>

            </mat-card>

        </div>

    </mat-tab>

    <!-- iNGRESOS Y AGRESOS-->
    <mat-tab>

        <ng-template mat-tab-label>
            Consulta de ingresos y egresos <mat-icon>search</mat-icon>
        </ng-template>

        <div class="containerGeneralInputsAndOutputs">

            <div class="containerGeneralInputsAndOutputs__containerHeader">
                <h5 [hidden]="flagsView.simple==false"> Seleccione una fecha para consultar </h5>
                <h5 [hidden]="flagsView.range==false"> Seleccione el rango de fechas a consultar </h5>

                <div class="containerGeneralInputsAndOutputs__containerHeaderForDates">

                    <div class="containerGeneralInputsAndOutputs__containerHeaderForDates__containerTypeSearch">
                        <mat-icon style="color:var(--M19Primary)"> change_circle </mat-icon>
                        <mat-checkbox (change)="changeInput($event.checked)" style="margin-top: 1%; padding-left: 4px;">
                        </mat-checkbox>
                    </div>

                    <div [hidden]="flagsView.simple==false">
                        <form [formGroup]="formDateSelector" class="col-md-12" style="margin-top: 6%;">
                            <mat-form-field appearance="outline">
                                <mat-label>Fecha</mat-label>
                                <input matInput type="date" formControlName="dateSimple" (change)="ObtainByDateChange()"
                                    required>
                            </mat-form-field>
                        </form>
                    </div>

                    <div [hidden]="flagsView.range==false">
                        <mat-form-field appearance="outline"
                            style="margin-top: 6%; width:80%; margin-left: 15px; margin-right:15px">
                            <mat-label>Seleccione inicio a fin </mat-label>
                            <mat-date-range-input [formGroup]="formDateSelector" [rangePicker]="picker">
                                <input matStartDate formControlName="dateStart" placeholder="Inicio">
                                <input matEndDate formControlName="dateEnd" placeholder="Fin">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>

                            <mat-error *ngIf="formDateSelector.controls.dateStart.hasError('matStartDateInvalid')">Fecha
                                de inicio incorrecta</mat-error>
                            <mat-error *ngIf="formDateSelector.controls.dateEnd.hasError('matEndDateInvalid')">Fecha de
                                finalizacion incorrecta</mat-error>
                        </mat-form-field>
                    </div>

                    <div [hidden]="flagsView.range==false"
                        style="display:flex; justify-content: center; align-items: center; margin-top: -6px;">
                        <button class="m19Btn" style="margin-left: -30px;" (click)="ObtainByRangeDateChange()">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>

                </div>

            </div>

            <div [hidden]="flagsView.simple==false">
                <div class="containerGeneralCashCut__containerSummary">
                    <div class="moneyBalance">
                        <div class="balanceRow">
                            <span class="balanceTitle"> Ingresos </span>
                            <span class="balanceData"> {{(this.cashCutTotalByDate.cashtotal).toFixed(2) | currency: '$'}} </span>
                        </div>
                        <div class="balanceRow">
                            <span class="balanceTitle"> Gastos de cobranza </span>
                            <span class="balanceData"> {{(this.cashCutTotalByDate.cobranzaTotal).toFixed(2) | currency: '$'}} </span>
                        </div>
                        <div class="balanceRow">
                            <span class="balanceTitle"> Salidas </span>
                            <span class="balanceData"> {{(this.cashCutTotalByDate.cashOutTotal).toFixed(2) | currency: '$'}} </span>
                        </div>
                        <div class="balanceRow">
                            <span class="balanceSubtitle"> Efectivo total </span>
                            <span class="balanceData"> {{((this.cashCutTotalByDate.cashtotal + this.cashCutTotalByDate.cobranzaTotal) -
                                this.cashCutTotalByDate.cashOutTotal).toFixed(2)}} </span>
                        </div>
                    </div>

                    <div class="containerGeneralCashCut__containerSummary__containerButtons">
                        <button class="m19Btn" style="margin-bottom: 10px;" (click)="summaryByOneDate()">
                            MÁS ENTRADAS
                        </button>

                        <button class="m19Btn" style="margin-bottom: 10px;" (click)="printOptions();">
                            IMPRIMIR REPORTE
                        </button>
                    </div>

                </div>

                <div class="containerGeneralCashCut__containerButtonsTypeEntry">

                    <button class="btnColor" (click)="inputTypeChangeByDate($event);">Efectivo</button>
                    <button class="btnColor" (click)="inputTypeChangeByDate($event);">Deposito</button>
                    <button class="btnColor" (click)="inputTypeChangeByDate($event);">Transferencia</button>
                    <button class="btnColor" (click)="inputTypeChangeByDate($event);">Tarjeta de credito</button>
                    <button class="btnColor" (click)="inputTypeChangeByDate($event);">Tarjeta de debito</button>
                    <button class="btnColor" (click)="inputTypeChangeByDate($event);">Cheque</button>
                    <button class="btnColor" (click)="inputTypeChangeByDate($event);">Todos</button>

                </div>

                <div class="containerGeneralCashCut__containerTables">

                    <div class="containertbl">
                        <h3>
                            {{this.inputType2}}
                        </h3>
                        <table class="m19TableUwu">
                            <thead>
                                <tr style="text-align:left; ">
                                    <th style="text-align:center;"> id </th>
                                    <th> Nombre </th>
                                    <th>Propiedad</th>
                                    <th style="text-align:right; padding-right: 10px;">Cantidad</th>
                                </tr>
                            </thead>

                            <tbody [hidden]="this.cashCutFlagsByDate.cash == false">
                                <tr *ngFor="let cash of this.cashCutEntriesByDate.cash"
                                    style="text-align: left; overflow: scroll; ">
                                    <td>
                                        {{cash.folioPago}}
                                    </td>
                                    <td>
                                        {{cash.cliente}}
                                    </td>
                                    <td>
                                        {{cash.fkCliente}}
                                    </td>
                                    <td style="text-align:right; padding-right: 10px;">
                                        {{cash.cantidadAbono | currency: 'CAD': '$'}}
                                    </td>
                                </tr>
                            </tbody>

                            <tbody [hidden]="this.cashCutFlagsByDate.deposit == false">
                                <tr *ngFor="let dep of this.cashCutEntriesByDate.deposit"
                                    style="text-align: left; overflow: scroll; ">
                                    <td>
                                        {{dep.folioPago}}
                                    </td>
                                    <td>
                                        {{dep.cliente}}
                                    </td>
                                    <td>
                                        {{dep.fkCliente}}
                                    </td>
                                    <td style="text-align:right; padding-right: 10px;">
                                        {{dep.cantidadAbono | currency: 'CAD': '$'}}
                                    </td>
                                </tr>
                            </tbody>

                            <tbody [hidden]="this.cashCutFlagsByDate.tranfer == false">
                                <tr *ngFor="let trans of this.cashCutEntriesByDate.transfer"
                                    style="text-align: left; overflow: scroll; ">
                                    <td>
                                        {{trans.folioPago}}
                                    </td>
                                    <td>
                                        {{trans.cliente}}
                                    </td>
                                    <td>
                                        {{trans.fkCliente}}
                                    </td>
                                    <td style="text-align:right; padding-right: 10px;">
                                        {{trans.cantidadAbono | currency: 'CAD': '$'}}
                                    </td>
                                </tr>
                            </tbody>

                            <tbody [hidden]="this.cashCutFlagsByDate.tdc == false">
                                <tr *ngFor="let tarjcre of this.cashCutEntriesByDate.tdc"
                                    style="text-align: left; overflow: scroll; ">
                                    <td>
                                        {{tarjcre.folioPago}}
                                    </td>
                                    <td>
                                        {{tarjcre.cliente}}
                                    </td>
                                    <td>
                                        {{tarjcre.fkCliente}}
                                    </td>
                                    <td style="text-align:right; padding-right: 10px;">
                                        {{tarjcre.cantidadAbono | currency: 'CAD': '$'}}
                                    </td>
                                </tr>
                            </tbody>

                            <tbody [hidden]="this.cashCutFlagsByDate.tdd == false">
                                <tr *ngFor="let tarjDeb of this.cashCutEntriesByDate.tdd"
                                    style="text-align: left; overflow: scroll; ">
                                    <td>
                                        {{tarjDeb.folioPago}}
                                    </td>
                                    <td>
                                        {{tarjDeb.cliente}}
                                    </td>
                                    <td>
                                        {{tarjDeb.fkCliente}}
                                    </td>
                                    <td style="text-align:right; padding-right: 10px;">
                                        {{tarjDeb.cantidadAbono | currency: 'CAD': '$'}}
                                    </td>
                                </tr>
                            </tbody>

                            <tbody [hidden]="this.cashCutFlagsByDate.check == false">
                                <tr *ngFor="let che of this.cashCutEntriesByDate.check"
                                    style="text-align: left; overflow: scroll; ">
                                    <td>
                                        {{che.folioPago}}
                                    </td>
                                    <td>
                                        {{che.cliente}}
                                    </td>
                                    <td>
                                        {{che.fkCliente}}
                                    </td>
                                    <td style="text-align:right; padding-right: 10px;">
                                        {{che.cantidadAbono | currency: 'CAD': '$'}}
                                    </td>
                                </tr>
                            </tbody>

                            <tbody [hidden]="this.cashCutFlagsByDate.all == false">
                                <tr *ngFor="let all of this.cashCutEntriesByDate.all"
                                    style="text-align: left; overflow: scroll; ">
                                    <td>
                                        {{all.folioPago}}
                                    </td>
                                    <td>
                                        {{all.cliente}}
                                    </td>
                                    <td>
                                        {{all.fkCliente}}
                                    </td>
                                    <td style="text-align:right; padding-right: 10px;">
                                        {{all.cantidadAbono | currency: 'CAD': '$'}}
                                    </td>
                                </tr>
                            </tbody>


                        </table>
                    </div>

                    <div class="containertbl">
                        <h3>
                            Salidas
                        </h3>
                        <table class="m19TableUwu">
                            <thead>
                                <tr style="text-align: left; ">
                                    <th style="text-align:center; width: 40%;"> Concepto </th>
                                    <th style="text-align:center; width: 30%"> Solicitó </th>
                                    <th style="text-align:center; width: 16%"> Cantidad </th>
                                    <th style="text-align:center; width: 14%"> Acción </th>
                            </thead>

                            <tbody>
                                <tr *ngFor="let out of this.cashCutEntriesByDate.cashout;"
                                    style="text-align: left; overflow: scroll;">
                                    <td style="padding-left: 10px;"> {{out.reason}}</td>
                                    <td> {{out.requestor}}</td>
                                    <td style="text-align:right ;"> {{(out.quantity | currency: 'CAD': '$')}}</td>
                                    <td style="text-align:center">
                                        <mat-icon class="icon__reprint" data-title="Reimprimir ticket"
                                            (click)="reimpresionTicket(out.idCashOut, out.cashManager ,out.quantity, out.requestor, out.reason, out.registrationDate)">
                                            receipt </mat-icon>

                                        <mat-icon class="icon__trash" data-title="Eliminar ticket"
                                            (click)="eliminarTicket(out.idCashOut)"> delete_forever </mat-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

            <div [hidden]="flagsView.range==false">
                <div class="containerGeneralCashCut__containerSummary">
                    <div class="moneyBalance">
                        <div class="balanceRow">
                            <span class="balanceTitle"> Ingresos </span>
                            <span class="balanceData"> ${{(this.cashCutTotalByRangeDate.cashtotal).toFixed(2)}} </span>
                        </div>
                        <div class="balanceRow">
                            <span class="balanceTitle"> Salidas </span>
                            <span class="balanceData"> ${{(this.cashCutTotalByRangeDate.cashOutTotal).toFixed(2)}}
                            </span>
                        </div>
                        <div class="balanceRow">
                            <span class="balanceSubtitle"> Efectivo total </span>
                            <span class="balanceData"> ${{(this.cashCutTotalByRangeDate.cashtotal -
                                this.cashCutTotalByRangeDate.cashOutTotal).toFixed(2) }} </span>
                        </div>
                    </div>

                    <div class="containerGeneralCashCut__containerSummary__containerButtons">
                        <button class="m19Btn" style="margin-bottom: 10px;" (click)="summaryByRangeDate()">
                            OTROS INGRESOS
                        </button>

                        <button class="m19Btn" style="margin-bottom: 10px;" (click)="printOptionsRange();">
                            IMPRIMIR REPORTE
                        </button>
                    </div>

                </div>

                <div class="containerGeneralCashCut__containerButtonsTypeEntry">

                    <button class="btnColor" (click)="inputTypeChangeByRangeDate($event);">Efectivo</button>
                    <button class="btnColor" (click)="inputTypeChangeByRangeDate($event);">Deposito</button>
                    <button class="btnColor" (click)="inputTypeChangeByRangeDate($event);">Transferencia</button>
                    <button class="btnColor" (click)="inputTypeChangeByRangeDate($event);">Tarjeta de credito</button>
                    <button class="btnColor" (click)="inputTypeChangeByRangeDate($event);">Tarjeta de debito</button>
                    <button class="btnColor" (click)="inputTypeChangeByRangeDate($event);">Cheque</button>
                    <button class="btnColor" (click)="inputTypeChangeByRangeDate($event);">Todos</button>

                </div>

                <div class="containerGeneralCashCut__containerTables">

                    <div class="containertbl">
                        <h3>
                            {{this.inputType3}}
                        </h3>
                        <table class="m19TableUwu">
                            <thead>
                                <tr style="text-align:left; ">
                                    <th style="text-align:center;"> id </th>
                                    <th> Nombre </th>
                                    <th>Propiedad</th>
                                    <th style="text-align:Right ;">Cantidad</th>
                                </tr>
                            </thead>

                            <tbody [hidden]="this.cashCutFlagsByRangeDate.cash == false">
                                <tr *ngFor="let cash of this.cashCutEntriesByRangeDate.cash"
                                    style="text-align: left; overflow: scroll; ">
                                    <td>
                                        {{cash.folioPago}}
                                    </td>
                                    <td>
                                        {{cash.cliente}}
                                    </td>
                                    <td>
                                        {{cash.fkCliente}}
                                    </td>
                                    <td style="text-align:Right ;">
                                        {{cash.cantidadAbono | currency: 'CAD': '$'}}
                                    </td>
                                </tr>
                            </tbody>

                            <tbody [hidden]="this.cashCutFlagsByRangeDate.deposit == false">
                                <tr *ngFor="let dep of this.cashCutEntriesByRangeDate.deposit"
                                    style="text-align: left; overflow: scroll; ">
                                    <td>
                                        {{dep.folioPago}}
                                    </td>
                                    <td>
                                        {{dep.cliente}}
                                    </td>
                                    <td>
                                        {{dep.fkCliente}}
                                    </td>
                                    <td style="text-align:Right ;">
                                        {{dep.cantidadAbono | currency: 'CAD': '$'}}
                                    </td>
                                </tr>
                            </tbody>

                            <tbody [hidden]="this.cashCutFlagsByRangeDate.tranfer == false">
                                <tr *ngFor="let trans of this.cashCutEntriesByRangeDate.transfer"
                                    style="text-align: left; overflow: scroll; ">
                                    <td>
                                        {{trans.folioPago}}
                                    </td>
                                    <td>
                                        {{trans.cliente}}
                                    </td>
                                    <td>
                                        {{trans.fkCliente}}
                                    </td>
                                    <td style="text-align:Right ;">
                                        {{trans.cantidadAbono | currency: 'CAD': '$'}}
                                    </td>
                                </tr>
                            </tbody>

                            <tbody [hidden]="this.cashCutFlagsByRangeDate.tdc == false">
                                <tr *ngFor="let tarjcre of this.cashCutEntriesByRangeDate.tdc"
                                    style="text-align: left; overflow: scroll; ">
                                    <td>
                                        {{tarjcre.folioPago}}
                                    </td>
                                    <td>
                                        {{tarjcre.cliente}}
                                    </td>
                                    <td>
                                        {{tarjcre.fkCliente}}
                                    </td>
                                    <td style="text-align:Right ;">
                                        {{tarjcre.cantidadAbono | currency: 'CAD': '$'}}
                                    </td>
                                </tr>
                            </tbody>

                            <tbody [hidden]="this.cashCutFlagsByRangeDate.tdd == false">
                                <tr *ngFor="let tarjDeb of this.cashCutEntriesByRangeDate.tdd"
                                    style="text-align: left; overflow: scroll; ">
                                    <td>
                                        {{tarjDeb.folioPago}}
                                    </td>
                                    <td>
                                        {{tarjDeb.cliente}}
                                    </td>
                                    <td>
                                        {{tarjDeb.fkCliente}}
                                    </td>
                                    <td style="text-align:Right ;">
                                        {{tarjDeb.cantidadAbono | currency: 'CAD': '$'}}
                                    </td>
                                </tr>
                            </tbody>

                            <tbody [hidden]="this.cashCutFlagsByRangeDate.check == false">
                                <tr *ngFor="let che of this.cashCutEntriesByRangeDate.check"
                                    style="text-align: left; overflow: scroll; ">
                                    <td>
                                        {{che.folioPago}}
                                    </td>
                                    <td>
                                        {{che.cliente}}
                                    </td>
                                    <td>
                                        {{che.fkCliente}}
                                    </td>
                                    <td style="text-align:Right ;">
                                        {{che.cantidadAbono | currency: 'CAD': '$'}}
                                    </td>
                                </tr>
                            </tbody>

                            <tbody [hidden]="this.cashCutFlagsByRangeDate.all == false">
                                <tr *ngFor="let all of this.cashCutEntriesByRangeDate.all"
                                    style="text-align: left; overflow: scroll; ">
                                    <td>
                                        {{all.folioPago}}
                                    </td>
                                    <td>
                                        {{all.cliente}}
                                    </td>
                                    <td>
                                        {{all.fkCliente}}
                                    </td>
                                    <td style="text-align:Right ;">
                                        {{all.cantidadAbono | currency: 'CAD': '$'}}
                                    </td>
                                </tr>
                            </tbody>


                        </table>
                    </div>

                    <div class="containertbl">
                        <h3>
                            Salidas
                        </h3>
                        <table class="m19TableUwu">
                            <thead>
                                <tr style="text-align: left; ">
                                    <th style="text-align:center"> Concepto </th>
                                    <th style="text-align:center"> Solicitó </th>
                                    <th style="text-align:center ;"> Cantidad </th>
                                    <th style="text-align:center ;"> Acción </th>
                            </thead>

                            <tbody>
                                <tr *ngFor="let out of cashCutEntriesByRangeDate.cashout;"
                                    style="text-align: left; overflow: scroll;">
                                    <td> {{out.reason}}</td>
                                    <td> {{out.requestor}}</td>
                                    <td style="text-align:center ;"> {{(out.quantity | currency: 'CAD': '$')}}</td>
                                    <td style="text-align:center">
                                        <mat-icon class="icon__reprint" data-title="Reimprimir ticket"
                                            (click)="reimpresionTicket(out.idCashOut, out.cashManager ,out.quantity, out.requestor, out.reason, out.registrationDate)">
                                            receipt </mat-icon>

                                        <mat-icon class="icon__trash" data-title="Eliminar ticket"
                                            (click)="eliminarTicket(out.idCashOut)"> delete_forever </mat-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

        </div>


        <div id="htmlSummaryCashIO" [hidden]="true">

            <div class="encabezado">
                <h2> Resumen de ingresos y salidas de efectivo </h2>

                <div class="tiempo-impresion">
                    <b style=" padding-right: 5px;">Fecha de impresión: </b>
                    <p style="text-align: right; padding-right: 10px;"> {{pipe.transform(currentDate
                        ,'EEEE')!.toLowerCase()}} {{pipe.transform(currentDate ,'longDate')!.toLowerCase()}} </p>
                </div>

                <div class="margen">
                    <b style=" padding-right: 5px;"> Fecha de corte: </b>
                    <p>
                        {{pipe.transform(this.fechaSeleccionada,'EEEE')!}} {{pipe.transform(fechaSeleccionada
                        ,'longDate')!.toLowerCase()}}

                    </p>
                </div>

                <div class="margen">
                    <b>Consultó: </b>
                    <p style=" padding-left: 5px;"> {{getUser()}} </p>
                </div>
            </div>

            <h4 *ngIf="cashCutEntriesByDate.cash.length > 0"> ENTRADAS </h4>

            <table class="format-Table" *ngIf="cashCutEntriesByDate.cash.length > 0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th>Nombre</th>
                        <th>Propiedad </th>
                        <th>Cantidad</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let totCashIO of cashCutEntriesByDate.cash;  let i=index;">
                        <td>{{i+1}}</td>
                        <th> {{totCashIO.folioPago }} </th>
                        <td> {{totCashIO.cliente}} </td>
                        <th> {{totCashIO.fkCliente}} </th>
                        <td style="text-align: right; padding-right: 20px;"> {{(totCashIO.cantidadAbono |
                            currency:'CAD': '$')}}</td>
                    </tr>
                    <!-- <tr *ngFor="let totCashIO of cashCutEntriesByDate.check;  let i=index;">
                        <td>{{i+1}}</td>
                        <th> {{totCashIO.folioPago }} </th>
                        <td> {{totCashIO.cliente}} </td>
                        <th> {{totCashIO.fkCliente}} </th>
                        <td style="text-align: right; padding-right: 20px;"> {{(totCashIO.cantidadAbono |
                            currency:'CAD': '$')}}</td>
                    </tr> -->

                </tbody>
            </table>

            <div class="continueSeccion" *ngIf="cashCutEntriesByDate.cobranza.length > 0">
                <br>
                <h4> GASTOS DE COBRANZA </h4>

                <table class="format-Table" >
                    <thead>
                        <tr class="tableTextHeader">
                            <th class="tableTextHeader">#</th>
                            <th class="tableTextHeader">Id</th>
                            <th class="tableTextHeader">Nombre</th>
                            <th class="tableTextHeader">Propiedad </th>
                            <th class="tableTextHeader">Cantidad</th>
                        </tr>
                    </thead>
    
                    <tbody>
                        <tr *ngFor="let payTo of ratifPay; let i=index;">
                            <td class="tableDataText1">{{i+1}}</td>
                            <td class="tableDataText1"> {{payTo.folioPago }} </td>
                            <td class="tableDataText2"> {{payTo.cliente}} </td>
                            <td class="tableDataText1"> {{payTo.fkCliente}} </td>
                            <td class="tableDataText1" style="text-align: right;"> {{(payTo.cantidadAbono | currency: 'CAD': '$')}}</td>
                        </tr>    
                    </tbody>
                </table>

            </div>

            <div class="totalSeccion">

                <br>
                <h4 *ngIf="cashCutEntriesByDate.cashout.length > 0"> SALIDAS DE EFECTIVO </h4>

                <table class="format-Table" *ngIf="cashCutEntriesByDate.cashout.length > 0">
                    <thead>
                        <tr class="tableTextHeader">
                            <th> # </th>
                            <th> Id </th>
                            <th> Concepto </th>
                            <th> Solicitó </th>
                            <th> Cantidad </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let out of cashCutEntriesByDate.cashout; let i = index">
                            <td> {{i+1}}</td>
                            <td> {{out.idCashOut }}</td>
                            <td style="text-align: left;"> {{out.reason}}</td>
                            <td style="text-align: left;"> {{out.requestor}}</td>
                            <td style="text-align: right; padding-right: 20px;"> {{(out.quantity | currency:
                                'CAD':'$')}}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br>
                <h4> GASTOS DE COBRANZA </h4>

                <div class="infoColumn">
                    <div class="texto-salida">
                        <p> {{(this.cashCutTotalByDate.cobranzaTotal).toFixed(2) | currency: 'CAD':'$'}} </p>
                    </div>

                </div>
                <br>

                <br>
                <h4> TOTALES </h4>

                <div class="infoColumn">
                    <div class="texto-salida">
                        <p> <b> BRUTO: </b> </p>
                        <p> {{((cashCutTotalByDate.cashtotal  + cashCutTotalByDate.cobranzaTotal).toFixed(2)) | currency: 'CAD':'$'}} </p>

                    </div>

                    <div class="texto-salida">
                        <p> <b> SALIDAS: </b> </p>
                        <p> {{((cashCutTotalByDate.cashOutTotal).toFixed(2)) | currency: 'CAD':'$'}} </p>
                    </div>


                    <div class="texto-salida">
                        <p> <b> NETO: </b> </p>
                        <p class="salida-neta"> {{((cashCutTotalByDate.cashtotal + cashCutTotalByDate.cobranzaTotal) -
                            cashCutTotalByDate.cashOutTotal).toFixed(2) | currency: 'CAD':'$' }} </p>
                    </div>


                </div>

                <br>
                <br>
                <br>

                <div class="shared-Container">

                    <div class="information-Delivery">
                        <h3> ENTREGA </h3>
                        <br>
                        <br>
                        <br>
                        <br>
                        <h3> ___________________________________ </h3>
                        <br>
                        <h3> {{getUser().toUpperCase()}} </h3>
                        <br>

                    </div>

                    <div class="information-Receive">
                        <h3> RECIBE </h3>
                        <br>
                        <br>
                        <br>
                        <br>
                        <h3> ___________________________________ </h3>
                        <br>
                        <h3> {{this.assign.toUpperCase()}} </h3>
                        <br>
                    </div>

                </div>

            </div>

        </div>

        <div id="htmlSummaryGeneralIO" [hidden]="true">

            <div class="encabezado">
                <h2> Resumen general </h2>

                <div class="tiempo-impresion">
                    <b style=" padding-right: 5px;">Fecha de impresion: </b>
                    <p style="text-align: right; padding-right: 10px;"> {{pipe.transform(currentDate
                        ,'EEEE')!.toLowerCase()}} {{pipe.transform(currentDate ,'longDate')!.toLowerCase()}} </p>
                </div>

                <div class="margen">
                    <b style=" padding-right: 5px;"> Fecha de corte: </b>
                    <p> {{fechaSeleccionada | date: 'dd/MM/yyyy' }} </p>
                </div>

                <div class="margen">
                    <b>Consultó: </b>
                    <p style=" padding-left: 5px;"> {{getUser()}} </p>
                </div>
            </div>

            <h4 *ngIf="cashCutEntriesByDate.all.length > 0"> ENTRADAS </h4>

            <table class="format-Table" *ngIf="cashCutEntriesByDate.all.length > 0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th>Método</th>
                        <th>Nombre</th>
                        <th>Propiedad </th>
                        <th>Cantidad</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let evIO of cashCutEntriesByDate.all;  let i=index;" style="break-inside: avoid;">
                        <td>{{i+1}}</td>
                        <th> {{evIO.folioPago }} </th>
                        <th> {{evIO.metodoPago}}</th>
                        <td class="td-text-left"> {{evIO.cliente}} </td>
                        <th class="tableDataText1"> {{evIO.fkCliente}} </th>
                        <td style="text-align: right; padding-right: 20px;"> {{(evIO.cantidadAbono | currency:'CAD':
                            '$')}}</td>
                        <td><div *ngIf="evIO.metodoPago=='Transferencia'" style="width: 10px; height: 10px; border-radius: 100%;" [ngStyle]="{'background-color': evIO.validate=='NO'?'#D94555':evIO.validate=='SI'?'#02bd54':'#0259bd'}"></div></td>
                    </tr>

                </tbody>
            </table>

            <div class="continueSeccion" *ngIf="cashCutEntriesByDate.cobranza.length > 0">
                <br>
                <h4> GASTOS DE COBRANZA </h4>

                <table class="format-Table" >
                    <thead>
                        <tr class="tableTextHeader">
                            <th class="tableTextHeader">#</th>
                            <th class="tableTextHeader">Id</th>
                            <th class="tableTextHeader">Nombre</th>
                            <th class="tableTextHeader">Propiedad </th>
                            <th class="tableTextHeader">Cantidad</th>
                        </tr>
                    </thead>
    
                    <tbody>
                        <tr *ngFor="let payTo of cashCutEntriesByDate.cobranza; let i=index;">
                            <td class="tableDataText1">{{i+1}}</td>
                            <td class="tableDataText1"> {{payTo.folioPago }} </td>
                            <td class="tableDataText2"> {{payTo.cliente}} </td>
                            <td class="tableDataText1"> {{payTo.fkCliente}} </td>
                            <td class="tableDataText1" style="text-align: right;"> {{(payTo.cantidadAbono | currency: 'CAD': '$')}}</td>
                        </tr>    
                    </tbody>
                </table>

            </div>

            <br>

            <h4 *ngIf="cashCutEntriesByDate.cashout.length > 0" style="page-break-before:always;"> SALIDAS DE EFECTIVO </h4>

            <table class="format-Table" *ngIf="cashCutEntriesByDate.cashout.length > 0 ">
                <thead>
                    <tr class="tableTextHeader">

                        <th> # </th>
                        <th> Concepto </th>
                        <th> Solicitó </th>
                        <th> Cantidad </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let out of cashCutEntriesByDate.cashout;">
                        <td> {{out.idCashOut }}</td>
                        <th class="td-text-left"> {{out.reason}}</th>
                        <td class="td-text-left"> {{out.requestor}}</td>
                        <td style="text-align: right; padding-right: 20px;"> {{(out.quantity | currency: 'CAD':'$')}}
                        </td>
                    </tr>
                </tbody>
            </table>

            <br>

            <h4> INGRESOS PARCIALES </h4>

            <div class="infoColumn">


                <div class="texto-salida">
                    <p> <b> EFECTIVO: </b> </p>
                    <p> {{(cashCutTotalByDate.cashtotal).toFixed(2) | currency: 'CAD':'$' }} </p>

                </div>

                <div class="texto-salida">
                    <p> <b> DEPOSITOS: </b> </p>
                    <p> {{(cashCutTotalByDate.depositTotal).toFixed(2) | currency: 'CAD':'$'}} </p>
                </div>


                <div class="texto-salida">
                    <p> <b> TRANSFERENCIAS: </b> </p>
                    <p> {{(cashCutTotalByDate.transferTotal).toFixed(2) | currency: 'CAD':'$'}} </p>
                </div>
            </div>

            <br>

            <div class="infoColumn">
                <div class="texto-salida">
                    <p> <b> TDC: </b> </p>
                    <p> {{(cashCutTotalByDate.tdcTotal).toFixed(2) | currency: 'CAD':'$'}} </p>

                </div>

                <div class="texto-salida">
                    <p> <b> TDD: </b> </p>
                    <p> {{(cashCutTotalByDate.tddTotal).toFixed(2) | currency: 'CAD':'$'}} </p>
                </div>

                <div class="texto-salida">
                    <p> <b> CHEQUES: </b> </p>
                    <p> {{(cashCutTotalByDate.checkTotal).toFixed(2) | currency: 'CAD':'$'}} </p>
                </div>

                <div class="texto-salida">
                    <p> <b> DEPOSITO EN GARANTÍA: </b> </p>
                    <p> {{(cashCutTotalByDate.escrowDepositTotal).toFixed(2) | currency: 'CAD':'$'}} </p>
                </div>
            </div>

            <br>
            <h4> GASTOS DE COBRANZA </h4>

            <div class="infoColumn">
                <div class="texto-salida">
                    <p> {{(this.cashCutTotalByDate.cobranzaTotal).toFixed(2) | currency: 'CAD':'$'}} </p>
                </div>

            </div>
            
            <br>

            <span class="transferCount">
                <div class="transfTitle">
                    <h4>VALIDACION DE TRANSFERENCIAS</h4>
                </div>
                <div class="transfCounter">
                    <div style="display: flex; flex-direction: column; text-align: center;">
                        <span><b>Aprobadas</b></span>
                        <span>{{transferCheck.approve}}</span>
                    </div>
                    <div style="display: flex; flex-direction: column; text-align: center;">
                        <span><b>Pendientes</b></span>
                        <span>{{transferCheck.waiting}}</span>
                    </div>
                    <div style="display: flex; flex-direction: column; text-align: center;">
                        <span><b>Rechazadas</b></span>
                        <span>{{transferCheck.refuse}}</span>
                    </div>
                </div>
            </span>

            <br>

            <br>
            <br>
            <br>

            <h4> TOTALES </h4>

            <div class="infoColumn">
                <div class="texto-salida">
                    <p> <b> BRUTO: </b> </p>
                    <p> {{(cashCutTotalByDate.alltotal + cashCutTotalByDate.cobranzaTotal).toFixed(2) | currency: 'CAD':'$'}} </p>

                </div>

                <div class="texto-salida">
                    <p> <b> SALIDAS: </b> </p>
                    <p> {{(cashCutTotalByDate.cashOutTotal).toFixed(2) | currency: 'CAD':'$'}} </p>
                </div>


                <div class="texto-salida">
                    <p> <b> NETO: </b> </p>
                    <p class="salida-neta"> {{((cashCutTotalByDate.alltotal + cashCutTotalByDate.cobranzaTotal) - cashCutTotalByDate.cashOutTotal).toFixed(2)
                        | currency: 'CAD':'$'}} </p>
                </div>

            </div>

        </div>

        <div id="htmlSummaryCashRangeIO" [hidden]="true">

            <div class="encabezado">
                <h2> Resumen general </h2>

                <div class="tiempo-impresion">
                    <b style=" padding-right: 5px;">Fecha de impresión: </b>
                    <p style="text-align: right; padding-right: 10px;"> {{pipe.transform(currentDate
                        ,'EEEE')!.toLowerCase()}} {{pipe.transform(currentDate ,'longDate')!.toLowerCase()}} </p>
                </div>

                <div class="margen">
                    <b style=" padding-right: 5px;"> Fecha de inicio: {{inicio}} </b>
                </div>

                <div class="margen">
                    <b style=" padding-right: 5px;"> Fecha de fin: {{fin}}</b>
                </div>

                <div class="margen">
                    <b>Consultó: </b>
                    <p style=" padding-left: 5px;"> {{getUser()}} </p>
                </div>
            </div>

            <h4 *ngIf="cashCutEntriesByRangeDate.cash.length > 0"> ENTRADAS </h4>

            <table class="format-Table" *ngIf="cashCutEntriesByRangeDate.cash.length > 0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th> Fecha</th>
                        <th>Nombre</th>
                        <th>Propiedad </th>
                        <th>Cantidad</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let totCashIO of cashCutEntriesByRangeDate.cash;  let i=index;">
                        <td>{{i+1}}</td>
                        <th> {{totCashIO.folioPago }} </th>
                        <td style="text-align: center;"> {{totCashIO.fechaPago | date: "dd-MM-yyyy"}} </td>
                        <td class="td-text-left"> {{totCashIO.cliente}} </td>
                        <th class="tableDataText1"> {{totCashIO.fkCliente}} </th>
                        <td style="text-align: right; padding-right: 20px;"> {{(totCashIO.cantidadAbono |
                            currency:'CAD': '$')}}</td>
                    </tr>

                </tbody>
            </table>

            <br>
            <h4 *ngIf="cashCutEntriesByRangeDate.cashout.length > 0"> SALIDAS DE EFECTIVO </h4>

            <table class="format-Table" *ngIf="cashCutEntriesByRangeDate.cashout.length > 0 ">
                <thead>
                    <tr class="tableTextHeader">
                        <th> # </th>
                        <th> Id </th>
                        <th> Fecha </th>
                        <th> Concepto </th>
                        <th> Solicitó </th>
                        <th> Cantidad </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let out of cashCutEntriesByRangeDate.cashout; let i=index;">
                        <td> {{ i+1 }}</td>
                        <td> {{out.idCashOut }}</td>
                        <td style="text-align: center;"> {{out.registrationDate | date: "dd-MM-yyyy"}}</td>
                        <th class="td-text-left"> {{out.reason}}</th>
                        <td class="td-text-left"> {{out.requestor}}</td>
                        <td style="text-align: right; padding-right: 20px;"> {{(out.quantity | currency: 'CAD':'$')}}
                        </td>
                    </tr>
                </tbody>
            </table>

            <br>

            <h4> TOTALES </h4>

            <div class="infoColumn">
                <div class="texto-salida">
                    <p> <b> BRUTO: </b> </p>
                    <p> {{(cashCutTotalByRangeDate.cashtotal).toFixed(2) | currency: 'CAD':'$'}} </p>

                </div>

                <div class="texto-salida">
                    <p> <b> SALIDAS: </b> </p>
                    <p> {{(cashCutTotalByRangeDate.cashOutTotal).toFixed(2) | currency: 'CAD':'$'}} </p>
                </div>


                <div class="texto-salida">
                    <p> <b> NETO: </b> </p>
                    <p class="salida-neta"> {{((cashCutTotalByRangeDate.cashtotal) -
                        cashCutTotalByRangeDate.cashOutTotal).toFixed(2) | currency: 'CAD':'$'}} </p>
                </div>


            </div>

        </div>

        <div id="htmlSummaryRangeGeneralIO" [hidden]="true">

            <div class="encabezado">
                <h2> Resumen general </h2>

                <div class="tiempo-impresion">
                    <b style=" padding-right: 5px;">Fecha de impresión: </b>
                    <p style="text-align: right; padding-right: 10px;"> {{pipe.transform(currentDate
                        ,'EEEE')!.toLowerCase()}} {{pipe.transform(currentDate ,'longDate')!.toLowerCase()}} </p>
                </div>

                <div class="margen">
                    <b style=" padding-right: 5px;"> Fecha de inicio: {{inicio}} </b>
                    <!-- <p> {{fecIni | date: 'dd/MM/yyyy' }} </p> -->
                </div>

                <div class="margen">
                    <b style=" padding-right: 5px;"> Fecha de fin: {{fin}} </b>
                    <!-- <p> {{fecFin | date: 'dd/MM/yyyy' }} </p> -->
                </div>

                <div class="margen">
                    <b>Consultó: </b>
                    <p style=" padding-left: 5px;"> {{getUser()}} </p>
                </div>
            </div>

            <h4 *ngIf="cashCutEntriesByRangeDate.all.length > 0"> ENTRADAS </h4>

            <table class="format-Table" *ngIf="cashCutEntriesByRangeDate.all.length > 0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th>Metodo</th>
                        <th>Fecha </th>
                        <th>Nombre</th>
                        <th>Propiedad </th>
                        <th>Cantidad</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let totCashIO of cashCutEntriesByRangeDate.all;  let i=index;">
                        <td>{{i+1}}</td>
                        <th> {{totCashIO.folioPago }} </th>
                        <th> {{totCashIO.metodoPago }} </th>
                        <td style="text-align: center;"> {{totCashIO.fechaPago | date: 'dd-MM-yyyy'}}</td>
                        <td style="width: 30%;"> {{totCashIO.cliente}} </td>
                        <td class="tableDataText1"> {{totCashIO.fkCliente}} </td>
                        <th style="text-align: right; padding-right: 10px;"> {{(totCashIO.cantidadAbono).toFixed(2) |
                            currency: 'CAD': '$'}}</th>
                        <td><div *ngIf="totCashIO.metodoPago=='Transferencia'" style="width: 10px; height: 10px; border-radius: 100%;" [ngStyle]="{'background-color': totCashIO.validate=='NO'?'#D94555':totCashIO.validate=='SI'?'#02bd54':'#0259bd'}"></div></td>
                    </tr>

                </tbody>
            </table>

            <br>
            <h4 *ngIf="cashCutEntriesByRangeDate.cashout.length > 0"> SALIDAS DE EFECTIVO </h4>

            <table class="format-Table" *ngIf="cashCutEntriesByRangeDate.cashout.length > 0 ">
                <thead>
                    <tr class="tableTextHeader">

                        <th> # </th>
                        <th> Fecha </th>
                        <th> Concepto </th>
                        <th> Solicitó </th>
                        <th> Cantidad </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let out of cashCutEntriesByRangeDate.cashout;">
                        <td> {{out.idCashOut }}</td>
                        <td style="text-align: center; padding-left: 15px;"> {{out.registrationDate | date: 'dd-MM-yyyy'
                            }}</td>
                        <td> {{out.reason}}</td>
                        <td class="td-text-left"> {{out.requestor}}</td>
                        <th style="text-align: right; padding-right: 20px;"> {{(out.quantity).toFixed(2) | currency:
                            'CAD': '$'}}
                        </th>
                    </tr>
                </tbody>
            </table>

            <br>
            <br>

            <h4> INGRESOS PARCIALES </h4>

            <div class="infoColumn">


                <div class="texto-salida">
                    <p> <b> EFECTIVO: </b> </p>
                    <p> {{(cashCutTotalByRangeDate.cashtotal).toFixed(2) | currency: 'CAD': '$'}} </p>

                </div>

                <div class="texto-salida">
                    <p> <b> DEPOSITOS: </b> </p>
                    <p> {{(cashCutTotalByRangeDate.depositTotal).toFixed(2) | currency: 'CAD': '$'}} </p>
                </div>


                <div class="texto-salida">
                    <p> <b> TRANSFERENCIAS: </b> </p>
                    <p> {{(cashCutTotalByRangeDate.transferTotal).toFixed(2) | currency: 'CAD': '$'}} </p>
                </div>



                <div class="texto-salida">
                    <p> <b> TDC: </b> </p>
                    <p> {{(cashCutTotalByRangeDate.tdcTotal).toFixed(2) | currency: 'CAD': '$'}} </p>

                </div>

                <div class="texto-salida">
                    <p> <b> TDD: </b> </p>
                    <p> {{(cashCutTotalByRangeDate.tddTotal).toFixed(2) | currency: 'CAD': '$'}} </p>
                </div>


                <div class="texto-salida">
                    <p> <b> CHEQUES: </b> </p>
                    <p> {{(cashCutTotalByRangeDate.checkTotal).toFixed(2) | currency: 'CAD': '$'}} </p>
                </div>


            </div>

            <br>
            <br>

            <span class="transferCount">
                <div class="transfTitle">
                    <h4>VALIDACION DE TRANSFERENCIAS</h4>
                </div>
                <div class="transfCounter">
                    <div style="display: flex; flex-direction: column; text-align: center;">
                        <span><b>Aprobadas</b></span>
                        <span>{{transferCheck.approve}}</span>
                    </div>
                    <div style="display: flex; flex-direction: column; text-align: center;">
                        <span><b>Pendientes</b></span>
                        <span>{{transferCheck.waiting}}</span>
                    </div>
                    <div style="display: flex; flex-direction: column; text-align: center;">
                        <span><b>Rechazadas</b></span>
                        <span>{{transferCheck.refuse}}</span>
                    </div>
                </div>
            </span>

            <br>
            <br>

            <h4> TOTALES </h4>

            <div class="infoColumn">
                <div class="texto-salida">
                    <p> <b> BRUTO: </b> </p>
                    <p> {{(cashCutTotalByRangeDate.alltotal).toFixed(2) | currency: 'CAD': '$'}} </p>

                </div>

                <div class="texto-salida">
                    <p> <b> SALIDAS: </b> </p>
                    <p> {{(cashCutTotalByRangeDate.cashOutTotal).toFixed(2) | currency: 'CAD': '$'}} </p>
                </div>


                <div class="texto-salida">
                    <p> <b> NETO: </b> </p>
                    <p class="salida-neta"> {{((cashCutTotalByRangeDate.alltotal) -
                        cashCutTotalByRangeDate.cashOutTotal).toFixed(2) | currency: 'CAD': '$'}} </p>
                </div>


            </div>

        </div>

    </mat-tab>

</mat-tab-group>