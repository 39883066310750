import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';
import { PropertyService } from '../../services/property.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SweetAlertComponent } from '../../utils/sweet-alert/sweet-alert.component';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { DialogPropertyComponent } from './dialog-property/dialog-property.component';
import { DialogPropertyFormComponent } from './dialog-property-form/dialog-property-form.component';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';
import { LogosService } from 'src/app/services/logos.service';

import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';

import decode from 'jwt-decode';
@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})

export class ProjectComponent implements OnInit {
  //Objeto para almacenar los datos decodificados del usuario logeado
  decode: any = [];
  isLinear = true;

  projectBanner: BannerTitle = {
    title: "Registro de proyecto",
    icon: "pin_drop",
    position: "center",
    background: "var(--M19Secundary)"
  }

  moduleBanner: BannerTitle = {
    title: "Registro de modulos",
    icon: "apartment",
    position: "center",
    background: "var(--M19Secundary)"
  }

  unitBanner: BannerTitle = {
    title: "Registro de inmuebles",
    icon: "home",
    position: "center",
    background: "var(--M19Secundary)"
  }

  searchBanner: BannerTitle = {
    title: "Consultar proyectos",
    icon: "search",
    position: "center",
    background: "var(--M19Secundary)"
  }

  selected: any = '';
  filterProperty = '';
  status: any = [];
  projects: any = [];

  logos: any = [];

  projectFormGroup: FormGroup = this._formBuilder.group({
    idProject: [],
    name: ['', Validators.required],
    nomenclature: ['', Validators.required],
    municipality: ['', Validators.required],
    state: ['', Validators.required],
    fk_user: [],
    registrationDate: [Date]
  });

  properties: any = [];
  property: any = [];
  propertyFormGroup: FormGroup = this._formBuilder.group({
    idProperty: [],
    codeProperty: ['', Validators.required], //VPM01L01
    amount: [, [Validators.required, Validators.min(0)]],
    advancePayment: [, [Validators.required, Validators.min(0)]],
    monthlyPayment: [, [Validators.required, Validators.min(0)]],
    area: [, [Validators.required, Validators.min(0)]],
    coordinateX: ['', Validators.required],
    coordinateY: ['', Validators.required],
    northeasBorder: ['', [Validators.required, Validators.max(100)]],
    southwestBorder: ['', [Validators.required, Validators.max(100)]],
    northwestBorder: ['', [Validators.required, Validators.max(100)]],
    southeastBorder: ['', [Validators.required, Validators.max(100)]],
    fk_status: [1, Validators.required],
    description: [''],
    fk_project: [, Validators.required]
  });
  //================================================================================
  //Objeto de formulario para el detalle de proyecto
  //================================================================================
  projectDetFormGroup: FormGroup = this._formBuilder.group({
    idprojectdet: [],
    idProject: [, Validators.required],
    towernumber: [, Validators.required],
    floorsnumber: [, Validators.required],
    departmentsperfloor: [, Validators.required],
    useremail: [this.getUser()]
  });
  type_area: any = [];

  fileUrl: any = '';

  constructor(
    private projectService: ProjectService,
    private propertyService: PropertyService,
    private projectDetailService: ProjectdetailsService,
    private logoService: LogosService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public sweet: SweetAlertComponent,
    private router: Router,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.selectProjects();
    this.selectStatus();
    this.download();
    this.getLogos();
  }

  getLogos() {
    this.logoService.getAllIcons().subscribe(
      res => {
        this.logos = res;
      }
    );
  }

  searchLogo(name: number) {
    try {
      let res = (this.logos.find((logo: { fk_projects: number; }) => logo.fk_projects == name)).UrlLogo;
      return res;
    } catch (error) {
      return '';
    }
  }

  //Generar plantilla CSV
  download() {
    const data = 'idProperty, codeProperty, amount, advancePayment, monthlyPayment, area, coordinateX, coordinateY, northeasBorder, southwestBorder, northwestBorder, southeastBorder, description, fk_project, fk_status, registrationDate, modificationDate';
    const blob = new Blob([data], { type: 'application/octet-stream' });

    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      window.URL.createObjectURL(blob)
    );
  }

  //Consultar todos los proyectos
  selectProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
        console.log(this.projects);
      },
      err => console.log(err)
    );
  }

  //Consultar todos los inmuebles
  selectProperties() {
    this.propertyService.selectProperties().subscribe(
      res => {
        this.properties = res;
        console.log('properties ',res);
      },
      err => console.log(err)
    );
  }

  //Consultar inmueble especifico
  selectProperty(codeProperty: string) {
    this.propertyService._selectProperty(codeProperty).subscribe(
      res => {
        this.property = res;
        console.log(res)
        this.dialog.open(DialogPropertyComponent, {
          width: '50%',
          data: res
        });
      },
      err => console.log(err)
    )
  }

  //Modificar inmueble (dialog)
  updateProperty(codeProperty: string) {
    this.propertyService.selectProperty(codeProperty).subscribe(
      res => {
        this.property = res;
        this.dialog.open(DialogPropertyFormComponent, {
          width: '70%',
          data: res
        });
      },
      err => console.log(err)
    )
  }

  //Consultar los inmuebles del proyecto especificado
  selectPropertiesProject(fk_project: string) {
    this.propertyService.selectPropertiesProject(fk_project).subscribe(
      res => {
        this.properties = res;
        console.log('Properties sec: ',this.properties)
      },
      err => console.log(err)
    );
  }

  //Registrar proyecto
  createProject() {
    if (this.projectFormGroup.invalid) {
      return;
    }
    else {
      this.projectService.createProject(this.projectFormGroup.value).subscribe(
        res => {
          console.log(res);
          this.sweet.AlertTime("center", "success", "Proyecto registrado con éxito", false, 2000);
          this.selectProjects();
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['projects']));
        },
        err => console.error(err)
      );
      console.log(this.projectFormGroup.value);
    }
  }

  //Registrar inmueble
  createProperty() {
    if (this.propertyFormGroup.invalid) {
      return;
    }
    else {
      this.propertyService.createProperty(this.propertyFormGroup.value).subscribe(
        res => {
          console.log(res);
          this.sweet.AlertTime("center", "success", "Propiedad registrada con éxito", false, 2000);
          this.selectProperties();
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['projects']));
        },
        err => console.error(err)
      );
    }
    console.log(this.propertyFormGroup.value);
  }//createProperty

  /*STATUS PROJECTS*/
  selectStatus() {
    this.projectService.select_status().subscribe(
      res => {
        this.status = res;
      },
      err => console.log(err)
    );
  }
  //================================================================================
  //Función para insertar un nuevo detalle de proyecto
  //================================================================================
  async insertProjectDet() {
    this.projectDetailService.insertDetail(this.projectDetFormGroup.value).subscribe(
      res => {
        this.sweet.AlertTime("center", "success", "Modulo registrado con éxito", false, 2000);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['projects']));
      }
    );
  }

  //==========================================================================================================
  //Recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.email
  }
}
