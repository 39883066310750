import { Component, OnInit } from '@angular/core';
import { Payment } from '../../models/Payment';
import { PaymentService } from '../../services/payment.service';
import { paymentMetode } from '../../models/paymentMetode';
import { PaymentMetodeService } from '../../services/paymentMetode.service';
import { Router } from '@angular/router';
import { ReportService } from 'src/app/services/report.service';
import { invoiceReport } from 'src/app/models/invoiceReport';
import { invoiceReportService } from 'src/app/services/invoice.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SalesService } from 'src/app/services/sales.service';
import { FolioInt } from 'src/app/models/FolioInt'
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { PdfMakerComponent } from 'src/app/utils/pdf-maker/pdf-maker.component';
import { TicketService } from 'src/app/services/ticket.service';

import { loader } from 'src/app/ui/loader/loader.model';

import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { DatePipe } from '@angular/common';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  loader: loader = {
    message: "Registrando pago..."
  }

  saving: number = 0;

  pipe = new DatePipe('es-MX');
  fechaHoy = new Date();

  //Variables locales
  folioGenerate: any = [];
  abonoPago: number = 0
  codigoInm: string | any = '';
  verifyPay: number = 0; //Variable que almacena si el pago que intenta introducirse es un duplicado o no (0 si es nuevo, 1 si es duplicado)
  property: any = {};  //clave del inmueble

  saleInfo: any = { idSale: '0', name: '', fk_property: '', customer: '' };


  //Bandera tarjeta inserccion consulta
  nuevoSaldo = false
  creaPago = false
  ocultaEntrada = true
  formularioActivo = true
  formularioApagado = false
  showFolio = false;//ASRB: Bandera que muestra/oculta el campo para ingresar folio de transferencia/depósito

  activarFormulario() {
    return this.formularioActivo = true, this.formularioApagado = false;

  }
  consultaAbono() {
    return this.ocultaEntrada = false, this.creaPago = true;
  }
  nuevoAbono() {
    location.reload();
    return this.ocultaEntrada = true, this.creaPago = false
  }

  //Control estado de pagos
  situacion = ''
  pagEsp = 20
  pagRea = 21
  limite = -4
  tolerancia = -1
  tolerancia2 = -2
  tolerancia3 = -3

  review_btn = false;
  M = "Manzana No."
  L = "Lote No."
  T = "Torre No."
  D = "Departamento No."

  //OPCION DE METODOS DE OPTENCION DE DATOS [DOCUMENTADO PARA FUTURA REFERENCIA]
  //const form = document.querySelector('.new-item-form') as HTMLFormElement;
  //tofrom = document.getElementById('#tofrom');


  //Calculo de fecha
  n = new Date();
  //Año
  y = this.n.getFullYear();
  //Mes
  m = this.n.getMonth() + 1;
  //Día
  d = this.n.getDate();

  h = this.n.toLocaleTimeString()

  salida = ""
  salidaFecha() {
    if (this.m < 9 && this.d < 9) {
      this.salida = this.y + '-' + '0' + this.m + '-' + '0' + this.d + ' ' + this.h;
    } else if (this.d < 9 && this.m > 9) {
      this.salida = this.y + '-' + this.m + '-' + '0' + this.d + ' ' + this.h;
    } else if (this.d > 9 && this.m < 9) {
      this.salida = this.y + '-' + '0' + this.m + '-' + this.d + ' ' + this.h;
    } else {
      this.salida = this.y + '-' + this.m + '-' + this.d + ' ' + this.h;
    }
    //console.log(this.salida);
    return this.salida
  }

  //Validaciones formulario pagos

  paymentFormGroup: FormGroup = this._formBuilder.group({
    id: [],
    fechaPago: [null, Validators.required],
    registrationDate: [this.salidaFecha(), Validators.required],
    cantidadAbono: [null, [Validators.required, Validators.min(0)]],
    estadoPago: ['Aprobado', Validators.required],
    metodoPago: ['', Validators.required],
    folioPago: [null], //Se rellena en la función que hace la inserción
    concepto: ['', Validators.required],
    fkCliente: [this.codigoInm, Validators.required],
    paymentType: ['', Validators.required],
    folio: [null, null],
    useremail: [this.getUser()]
  })

  recuperacion = [
    { descripcion: 'Administración', tipo: 'Administracion' },
    { descripcion: 'Recuperación', tipo: 'Recuperacion' },
    { descripcion: 'Aclaración', tipo: 'Aclaracion' }
  ];
  concepto = [
    { tipo: 'Mensualidad' },
    { tipo: 'Anticipo' },
    { tipo: 'Ratificacion' },
    { tipo: 'Gasto de cobranza' }
  ];

  payment: Payment = {
    estadoPago: 'Aprobado'
  };

  resume: any = {
    lastPay: "",
    balance: 0,
    monthlyPayment: 0
  }

  //Autocompletado
  keyword = 'name';

  sal: any = [];
  salesCoo: any = [];
  cadenaInmueble: any = "";

  saleCode() {
    this.reportServ.saleCode().subscribe(
      res => {

        this.sal = res;
      },
      err => console.log(err)
    );
  }

  async onKeypressEvent(event: any) {
    this.codigoInm = event;

    this.getResume(this.codigoInm.name.substring(0,8));
    let cad: any = {};
    cad = this.codigoInm.name;
    localStorage.setItem("Eventochange", cad)
    if (event == ' ' || event == null || event == "") {
      console.log("Se requiere consultar cliente")
    } else {
      this.codigoInm = cad.substr(0, 8);
      this.salesServices.getSaleInfo(this.codigoInm).subscribe(
        res => {
          this.saleInfo = res;
          console.log(this.saleInfo);
        }
      );
      console.log(this.codigoInm.substring(5, 6));
      this.invReport.fkProperty = cad.substr(0, 8);;
      this.invReport.RFC = cad.substr(-13);
      this.invReport.UserName = cad.substr(9, 22);
      this.reporteInmuCartera(cad.substr(0, 8));
      this.totalAbonado();
    }
  }

  async getResume(prop: string) {
    this.paymentService.getResume(prop).subscribe(
      res => {
        this.resume = res;
      }
    );
  }

  //Consulta API calculo de residuo
  residuos: any = [];
  residuo: Payment | undefined;
  fkCliente: any | string = ""

  sales: any = [];
  //Manejo tabla Pagos
  carteraInmu: any = [];
  payments: any = [];

  payList: any = [];

  totalAbonados: any = [];

  invReport: invoiceReport = {
    id: 0,
    RFC: '',
    UserName: null,
    invoiceType: 'Complemento',
    registrationDate: this.salidaFecha(),
    modificationDate: null,
    paymentType: 'Efectivo',
    invoiceStatus: 'Pendiente',
    folInvoice: null,
    amountInvoice: null,
    fkProperty: '',
  };

  //Consulta API contador de pagos
  totales: any = [];
  total: Payment = {
    Abonado: 0
  };

  //Consulta tabla metodo_pago
  metode: any = [];

  PaymentMetode: paymentMetode = {
    id: 0,
    formaPago: ''
  };

  //Constructor y metodos principales


  constructor(private reportServ: ReportService, private toastr: ToastrService, private invoiceService: invoiceReportService, private salesServices: SalesService,
    private paymentService: PaymentService, private router: Router, private paymentMetodeService: PaymentMetodeService, public _formBuilder: FormBuilder,
    public folio: TicketService, public sweet: SweetAlertComponent, public ticket: PdfMakerComponent) {
  }

  //Metodo de carga de metodos al momento de la ejecución

  ngOnInit(): void {
    console.log(this.getUser());
    this.slectMetodePayments();
    this.saleCode();
    this.salidaFecha();
    this.paymentFormGroup.controls['folio'].disable();
    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
    /*setInterval(() => {
      this.lastFol()
    }, 100)*/

  }

  //Alertas de aprobación de pago
  showToastr() {
    this.toastr.success('Nota agregada con exito')
  }
  showToastrDan() {
    this.toastr.error('Error: Pago no agregado')
  }
  //Consulta de datos desde el servicio que consulta a la API
  selectSalesCoo(codInmuebleCoo: any) {
    this.salesServices.selectSalesCoo(codInmuebleCoo).subscribe(
      res => {
        this.salesCoo = res;
      },
      err => console.log(err)
    );
  }
  slectPayments(cliente: any) {
    this.paymentService.selectPayments(cliente).subscribe(
      res => {
        this.payments = res;
      },
      err => console.log(err)
    );
  }
  slectMetodePayments() {
    this.paymentMetodeService.selectMetodePayments().subscribe(
      res => {

        this.metode = res;
      },
      err => console.log(err)
    );
  }
  sumTotales(code: any) {
    this.paymentService.sumTotal(code).subscribe(
      data => {
        this.totales = data;
      },
      err => console.log(err)
    );

  }

  async folioRequired(formPago: string) { //ASRB: Función para habilitar o deshabilitar el campo para ingresar folio según metodo de pago
    if (formPago == 'Deposito' || formPago == 'Transferencia') {
      this.paymentFormGroup.controls['folio'].enable();
      this.paymentFormGroup.controls['folio'].setValidators([Validators.required]);
      this.showFolio = true;
    } else {
      this.paymentFormGroup.controls['folio'].disable();
      this.paymentFormGroup.controls['folio'].setValidators(null);
      this.paymentFormGroup.controls['folio'].setValue("");
      this.showFolio = false;
    }
  }

  async createPayment() {
    this.saving = 1;
    if (this.paymentFormGroup.controls['concepto'].value == 'Ratificacion') {
      this.createRatPayment();
    } else if (this.paymentFormGroup.controls['concepto'].value == 'Gasto de cobranza' || this.paymentFormGroup.controls['metodoPago'].value == 'Nota de credito no reembolsable' || this.paymentFormGroup.controls['metodoPago'].value == 'Nota de credito') {
      this.createNoCountPayment();
    } else {
      this.proveFolio(); //Verifica si ya existe o no el pago que se intenta registrar y guarda la respuesta en verifyPay
    }
  }

  async createNoCountPayment() {
    this.paymentService.createRatPayment(this.paymentFormGroup.value).subscribe(
      res => {
        const folio = "ec" + res;
        this.paymentService.getTotalsNCPayments(this.paymentFormGroup.controls["fkCliente"].value).subscribe(
          res => {
            this.ticket.ticketNCGenerator(this.paymentFormGroup.controls["fechaPago"].value, this.carteraInmu[0].usuarios, folio, this.paymentFormGroup.controls["fkCliente"].value.substring(2, 3), this.paymentFormGroup.controls["fkCliente"].value.substring(5, 6), this.paymentFormGroup.controls["fkCliente"].value.substring(3, 5), this.paymentFormGroup.controls["fkCliente"].value.substring(6, 8), this.paymentFormGroup.controls["concepto"].value, this.paymentFormGroup.controls["metodoPago"].value, this.paymentFormGroup.controls["cantidadAbono"].value, this.paymentFormGroup.controls["fkCliente"].value.substring(0, 2), this.saleInfo.customer, this.getUser(), res.NC, res.NCNR);
          }
        );

        this.paymentService.setFolioPaymentNC(res, "ec" + res).subscribe(
          res => {
            this.saving = 0;
            this.sweet.AlertTime("center", "success", "Pago registrado con éxito.", false, 2000);
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['payments']));
          }
        );
      }
    );
  }

  async createRatPayment() {
    this.paymentService.createRatPayment(this.paymentFormGroup.value).subscribe(
      res => {
        this.print();
        this.saving = 0;
        this.sweet.AlertTime("center", "success", "Pago de ratificación registrado con éxito", false, 2000);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['payments']));
      }
    );
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlData')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
      <html>
        <head>
          <title>COMPROBANTE DE RATIFICACION</title>
          <style>
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin!.document.close();
  }

  createInvoiceReport() {
    delete this.invReport.id;
    this.invoiceService.createInvoiceReport(this.invReport)
      .subscribe(
        res => {
          this.invReport.fkProperty = this.codigoInm

        },
        err => {
          this.showToastrDan()
          this.router.navigate(['payments'])
        }
      );
  }

  async proveFolio() {//ASRB: Esta función recupera una consulta duplicada, si existe retorna un número igual o mayor a 1 (Las veces que ya existe)
    this.paymentService.verifyPayment(this.paymentFormGroup.value.fechaPago, this.paymentFormGroup.value.cantidadAbono,
      this.paymentFormGroup.value.folio, this.paymentFormGroup.value.fkCliente).subscribe(
        res => {
          console.log("valor del servicio: " + res.cuenta);
          this.verifyPay = res.cuenta;
          if (this.verifyPay == 0) {//ASRB: El siguiente proceso se realiza solo cuando no haya un pago en transferencia/depósito con el mismo folio
            (this.folio.newFolio()).subscribe(//ASRB: Recupera el ultimo folio y lo alamacena en LocalStorage UNA VEZ POR SUBMIT
              (res: FolioInt) => {
                this.folioGenerate = res;
                this.invReport.folInvoice = 'e' + res.folio;
                this.paymentFormGroup.controls['folioPago'].setValue("e" + res.folio);

                let obj = this.paymentFormGroup.value;
                this.paymentService.createPayment(this.paymentFormGroup.value).subscribe(res => {//ASRB: Una vez que ya está el folio en LocalStorage, se inserta el pago
                  this.sumTotales(this.codigoInm);
                  this.slectPayments(this.codigoInm);
                  this.reporteInmuCartera(this.codigoInm);
                  this.selectSalesCoo(this.codigoInm);
                  this.saving=0;
                  this.sweet.AlertTime("center", "success", "Pago registrado con éxito", false, 2000);
                  this.consultaAbono();
                  this.invReport.paymentType = this.paymentFormGroup.value.metodoPago;
                  this.createInvoiceReport();
                  this.paymentFormGroup.reset({ estadoPago: 'Aprobado', registrationDate: this.salidaFecha() });
                  
                  const log = {
                    "action" : 4,
                    "notes": null,
                    "payment": res,
                    "responsable": this.getResponsable()
                  };
                  
                  this.paymentService.writePaymentLog(log).subscribe(res => {});
                },
                  err => {
                    this.showToastrDan()
                    this.router.navigate(['payments'])
                  })
                this.generarTicketElectronico(obj);
                this.verifyPay = 0;//Se reestablece la variable para evitar un caso en el que se intente registrar un duplicado seguido de un válido
              }
            )
          } else {
            this.saving=0;
            this.sweet.AlertTime('center', 'error', 'Atención: Pago duplicado', false, 2000);
            console.log("SE INTENTÓ REGISTRAR UN PAGO REPETIDO");
          }
        }
      )
    console.log("Fecha de pago: " + this.paymentFormGroup.value.fechaPago);
    console.log("Cantidad de abono: " + this.paymentFormGroup.value.cantidadAbono);
    console.log("Folio: " + this.paymentFormGroup.value.folio);
    console.log("fkCliente: " + this.paymentFormGroup.value.fkCliente);
    console.log("Valor de verify pay: " + this.verifyPay);
  }

  //Consulta informacion cliente
  reporteInmuCartera(carInmu: string) {
    this.reportServ.reporteCarteraInmueble(carInmu).subscribe(
      res => {
        this.carteraInmu = res;
        localStorage.setItem("cartera", JSON.stringify(res[0]))
      },
      err => console.log(err)
    );
  }

  //obtener el ultimo folio
  lastFol(): Promise<any> { //ASRB: 10-MAR-2022 DESACTIVADA LA VERIFICACIÓN CONTINUA DE ULTIMO FOLIO PARA EVITAR DUPLICADOS DESDE DOS CLIENTES DISTINTOS INSERTANDO EN BDD
    (this.folio.getFolio()).subscribe(
      (res: FolioInt) => {
        this.folioGenerate = res
        console.log("desde el click", this.folioGenerate.folio)
        localStorage.setItem("folio", this.folioGenerate.folio)
      }
    )
    return this.folioGenerate
  }


  totalAbonado() {
    this.property = localStorage.getItem("Eventochange")?.substring(0, 8)
    console.log(this.property);
    this.folio.getTotalAbonado(this.property).subscribe(
      res => {
        this.totalAbonados = res
      }
    )
  }

  //Obtención de datos desde el local storage, conversion de datos a json y finalmente se forza a el tipo de dato que se requiere
  //para las distintas operaciones

  generarTicketElectronico(obj: any) {
    let inmu = JSON.parse(localStorage.getItem("cartera") || "")
    var valortotal = inmu.valor
    var valortotalint: number = +valortotal;
    //mensualidades
    var mensual = inmu.monthlyPayment
    //mensualidad
    var mensual = inmu.monthlyPayment
    var mensuals: number = +mensual
    //anticipo
    var anticip = inmu.advancePayment
    var anticipnumber: number = +anticip
    //pagosesperados
    var pagototalesperado = (valortotalint - anticipnumber) / mensuals
    this.paymentService.calculateRest(obj.fkCliente, obj.fechaPago).subscribe(
      res => {
        let PReali = (((inmu.valor - res[0].restante + Number(obj.cantidadAbono)) - res[0].anticipo) / res[0].mensualidad);
        console.log("\nPagos relaizados: " + PReali);
        let fechaCont = [res[0].feContr.substring(0, 4), res[0].feContr.substring(5, 7), res[0].feContr.substring(8, 10)]
        console.log('\nVariable descompuesta: ' + fechaCont)
        for (let i = 0; i <= PReali; i++) {
          fechaCont[1]++;
          if (fechaCont[1] == 13) { fechaCont[1] = 1; fechaCont[0]++ }
          console.log('Al final de la iteración ' + i + ': ' + fechaCont)
        }
        if (fechaCont[1].toString().length == 1) { fechaCont[1] = "0" + fechaCont[1].toString() };
        console.log('\nValor de fecha de salida y del formulario de nuevo')
        console.log(fechaCont);
        console.log(obj.value);
        this.paymentService.getTotalsNCPayments(obj.fkCliente).subscribe(
          res2 => {
            this.ticket.ticketGenerator(
              obj.fechaPago,      //fecha
              inmu.usuarios,      //vendedor
              obj.folioPago,       //folio
              obj.fkCliente.substring(2, 3),       //TIPO M|T
              obj.fkCliente.substring(5, 6),      //TIPO L|D
              obj.fkCliente.substring(3, 5),       //manzana
              obj.fkCliente.substring(6, 8),      //lote
              obj.concepto,       //concepto
              obj.metodoPago,       //forma de pago
              inmu.valor,       //valor total
              obj.cantidadAbono,      //abonado
              (Number(res[0].restante) - Number(obj.cantidadAbono)),      //adeudo
              PReali.toString(), //Pagos realizados
              "" + pagototalesperado,       //pagos totales
              obj.fkCliente.substring(0, 2),      //proyecto
              inmu.clientes,      //nombre del cliente
              this.getUser(),
              res2.NC,
              res2.NCNR,
              inmu.valor - res[0].restante + Number(obj.cantidadAbono), //Total abonado
              (res[0].desfase > 0 ? (res[0].desfase + ' pendiente(s)') : 'Al corriente'),
              fechaCont[0] + '-' + fechaCont[1] + '-' + fechaCont[2]
            )
          }
        );
      }
    )
  }

  //==========================================================================================================
  //Recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.cliente
  }

  getResponsable() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.fkuser;
  }
}
