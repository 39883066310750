<mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Favorite food</mat-label>
    <input matInput placeholder="Ex. Pizza" value="Sushi">
  </mat-form-field>


<table class="table table-striped mt-3 animated fadeIn faster">
    <thead class="thead-m19">
        <tr>
            <th scope="col">Nombre del proyecto</th>
            <th scope="col">nomenclatura</th>
        </tr>
    </thead>
    <tbody> 
        <tr *ngFor="let allProject of allProjects">
            <td>{{allProject.name}}</td>
            <td>{{allProject.nomenclature}}</td>
        </tr>
    </tbody>
</table>
