import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NegotiationComment } from 'src/app/models/NegotiationComment';

import { AdministrationService } from 'src/app/services/administration.service';

import decode from 'jwt-decode';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-comments-view',
  templateUrl: './comments-view.component.html',
  styleUrls: ['./comments-view.component.scss']
})
export class CommentsViewComponent implements OnInit {

  constructor(
    private negotiationService: AdministrationService,

    private dialogRef: MatDialogRef<CommentsViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) { }

  @ViewChild('comment') commentInput: ElementRef;

  decode: any = {};

  comments: NegotiationComment[] = [];

  newComments: any[] = [];

  ngOnInit(): void {
    this.getNegotiationComments(this.data);
  }

  getNegotiationComments(PKNegotiation: number) {
    this.negotiationService.getCommentsByNegotiations(PKNegotiation).subscribe(
      res => {
        this.comments = res.data;
      }
    );
  }

  sendComment(content: string) {
    let comment = {
      FKNegotiation: this.data,
      Content: content,
      Author: this.getUser()
    };

    this.negotiationService.sendCommentForNegotiation(comment).subscribe(
      res => {
        this.getNegotiationComments(this.data);
        this.newComments.push(comment);
        this.commentInput.nativeElement.value = "";
      }
    );
  }

  deleteComment(PKComment: number) {
    Swal.fire({
      title: '¿Estás seguro de que quieres borrar este comentario?',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Volver',
      buttonsStyling: false,
      customClass: {
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn",
        actions: "sweetActions"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.negotiationService.deleteCommentByNegotiation(PKComment).subscribe(
          res => {
            this.getNegotiationComments(this.data);
          }
        );
      }
    })
  }

  closeComments() {
    this.dialogRef.close();
  }

  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.fkuser;
  }

}