import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDeparture'
})
export class FilterDeparturePipe implements PipeTransform {

  transform(value: any, arg: any): any {
    
    const res = [];

    for(const o of value){ 
      if(o.departurenumber.toString().indexOf(arg) > -1){
         res.push(o);
      };
    };
    return res;
  }
}