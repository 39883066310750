<!------------------------------------ PRESUPUESTO DE MATERIALES ---------------------------->
<mat-tab-group mat-stretch-tabs>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">note_add</mat-icon>
            <span>Consultar presupuestos de materiales</span>
        </ng-template>
        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">

                    <div class="projectButtons">
                        <button *ngFor="let p of projects" class="m19AltBtn" (click)="filterByProject(p.idProject)">
                            {{p.name}}
                            <mat-icon>apartment</mat-icon>
                        </button>
                    </div>

                    <div *ngIf="budgets === 'vacio'"
                        style="display: flex; flex-direction: column; align-items: center; width: 100%;">
                        <h3>Elige un proyecto para visualizar sus presupuestos.</h3>
                        <p>
                            <mat-icon style="font-size: 2rem;">search</mat-icon>
                        </p>
                    </div>

                    <div *ngIf="budgets.length === 0"
                        style="display: flex; flex-direction: column; align-items: center; width: 100%;">
                        <h3>Este proyecto no tiene presupuestos registrados.</h3>
                        <p>
                            <mat-icon style="font-size: 2rem;">sentiment_very_dissatisfied</mat-icon>
                        </p>
                    </div>

                    <table class="table table-striped mt-3 animated fadeIn faster"
                        *ngIf="budgets.length > 0 && budgets != 'vacio'">
                        <thead class="thead-m19">
                            <tr style="text-align:center;">
                                <!-- Se definen los encabezados de cada columna de la tabla -->
                                <th scope="col">Número de presupuesto</th>
                                <th scope="col">Fecha de presupuesto</th>
                                <th scope="col">Analista</th>
                                <th scope="col">Responsable</th>
                                <th scope="col">¿Aprobado?</th>
                                <th scope="col">Acciones</th>

                            </tr>
                        </thead>
                        <tbody>
                            <!-- ngFor para recorrer todos los presupuestos obtenidos -->
                            <!-- con esto podemos controlar la generación de componentes -->
                            <!-- en base a la cantidad de presupuestos -->
                            <tr *ngFor="let budget of budgets" style="text-align:center;">
                                <!-- Se define con qué datos se llenará cada columna de la tabla -->
                                <td>{{budget.idbudget}}</td>
                                <td>{{budget.budgetdate | date: 'dd-MMM-yyyy'}}</td>
                                <td>{{budget.analyst}}</td>
                                <td>{{budget.responsable}}</td>
                                <td>
                                    <mat-icon data-title="Aprobada" style="color: darkgreen;"
                                        *ngIf="budget.approved=='SI'">verified</mat-icon>
                                    <mat-icon data-title="Rechazada" style="color: red;"
                                        *ngIf="budget.approved=='NO'">cancel</mat-icon>
                                    <mat-icon data-title="Pendiente" style="color: rgb(219, 219, 10);"
                                        *ngIf="budget.approved=='NA'">hourglass_empty</mat-icon>
                                </td>
                                <td class="button-row">

                                    <!-- Botón para visualizar el desgloce de presupuestos -->
                                    <button class="btn btn-m19-table" data-title="Ver detalle" mat-icon-button
                                        (click)="selectBudgetUnique(budget.idbudget)">
                                        <mat-icon>info</mat-icon>
                                    </button>
                                    <!-- Botón para editar un presupuesto -->
                                    <button class="btn btn-m19-table" data-title="Editar" mat-icon-button
                                        (click)="updateBudget(budget.idbudget,budget.idprojectdet,budget.idProject)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <!-- Botón para eliminar un presupuesto -->
                                    <button class="btn btn-m19-table" data-title="Eliminar" mat-icon-button
                                        (click)="deleteBudget(budget.idbudget)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>
    <!------------------------------------ PRESUPUESTO DE DESTAJOS ---------------------------->
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">engineering</mat-icon>
            <span>Consultar presupuestos de destajos</span>
        </ng-template>
        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">

                    <div class="projectButtons">
                        <button *ngFor="let p of projects" class="m19AltBtn" (click)="pfilterByProject(p.idProject)">
                            {{p.name}}
                            <mat-icon>apartment</mat-icon>
                        </button>
                    </div>

                    <table class="table table-striped mt-3 animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr style="text-align:center;">
                                <!-- Se definen los encabezados de cada columna de la tabla -->
                                <th scope="col">Número de presupuesto</th>
                                <th scope="col">Fecha de presupuesto</th>
                                <th scope="col">Total</th>
                                <th scope="col">Analista</th>
                                <th scope="col">Responsable</th>
                                <th scope="col">¿Aprobado?</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- ngFor para recorrer todos los presupuestos obtenidos -->
                            <!-- con esto podemos controlar la generación de componentes -->
                            <!-- en base a la cantidad de presupuestos -->
                            <tr *ngFor="let budget of pbudgets" style="text-align:center;">
                                <!-- Se define con qué datos se llenará cada columna de la tabla -->
                                <td>{{budget.idbudgetpiece}}</td>
                                <td>{{budget.budgetdate | date: 'dd-MMM-yyyy'}}</td>
                                <td>${{budget.total}}</td>
                                <td>{{budget.analyst}}</td>
                                <td>{{budget.responsable}}</td>
                                <td>{{budget.approved=='NA'?"PENDIENTE":budget.approved}}</td>
                                <td class="button-row">
                                    <!-- Botón para visualizar el desgloce de presupuestos -->
                                    <button class="btn btn-m19-table" data-title="Ver detalle" mat-icon-button
                                        (click)="pselectBudgetUnique(budget.idbudgetpiece)">
                                        <mat-icon>info</mat-icon>
                                    </button>
                                    <!-- Botón para editar un presupuesto -->
                                    <button class="btn btn-m19-table" title="Editar" mat-icon-button
                                        (click)="updateBudgetPiece(budget.idbudgetpiece,budget.idprojectdet,budget.idProject)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <!-- Botón para eliminar un presupuesto -->
                                    <button class="btn btn-m19-table" data-title="Eliminar" mat-icon-button
                                        (click)="pdeleteBudget(budget.idbudgetpiece)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>
</mat-tab-group>