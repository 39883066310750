//=========================================================================================================
//Importación de componentes necesarios para esta pantalla
//=========================================================================================================
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DepartureService } from 'src/app/services/departure.service';
import { PieceworkService } from 'src/app/services/piecework.service';
import { DepaPieceService } from 'src/app/services/depa-piece.service';
import { ReasonpieceworkService } from 'src/app/services/reasonpiecework.service';
import { ConceptsService } from 'src/app/services/concepts.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-piecework-update',
  templateUrl: './piecework-update.component.html',
  styleUrls: ['./piecework-update.component.scss']
})
export class PieceworkUpdateComponent implements OnInit {

  pieceworks: any = [];
  departures: any = [];
  towers: any = [];
  piece: any = [];
  p:any="";
  actualCost: any = [];
  namepiecework: any = [];
  statusemployee: any='nonulo';
  pieceworkList: any = [];
  pieceworkInfo: any = [];
  pieces: any = [];
  piecework: any=[];
  iddeparture: any;
  idpiecework: any;
  week: any = '';
  employee: any = '';
  amount: any = '';
  unitcost: any="";

  subtotal: number = 0;
  total: number = 0;
  editing: any = "";
  modification: any = false;

  keywordP = 'departurename';
  keywordD = 'type';
  keywordPi = 'pieceworkname';

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  constructor(
    private dialogRef: MatDialogRef<PieceworkUpdateComponent>,

    private departureService: DepartureService,
    private pieceworkService: PieceworkService,
    private depapieceService: DepaPieceService,
    public projectDetailService: ProjectdetailsService,
    public reasonPieceService: ReasonpieceworkService,
    public conceptService: ConceptsService,

    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getPieceInfo();
    this.getModules();
    this.getPieceworkList();
    this.selectPieceworks();
    this.selectDepartures();
    this.recalculateTotal();
    this.searchPieceworks();
    
    console.log("Data: ", this.data);
  }

  pieceworkFormGroup: FormGroup = this._formBuilder.group({
    iddeparpiece: [],
    idProject: [],
    idprojectdet: [],
    week: [],
    reasonMod: [, Validators.required],
    amount: [],
    unit: [],
    unitcost: [],
    total: [],
    idpiecework: [],
    typepw: [],
    piecework: [],
    employee: [],
    iddeparture: [],
    departurename: [],
    departurenumber: [],
    departure: [],
    useremail: [this.getUser()]
  });

  //==========================================================================================================
  // Función para buscar las partidas registradas en la base de datos
  //==========================================================================================================
  async selectDepartures() {
    this.departureService.getDepartureList().subscribe(
      res => {
        //Se recuperan todas las partidas en la variable departures
        this.departures = res;
      },
      err => console.log(err)
    );
  }

  //=========================================================================================================
  //Función para buscar los destajos del catalogo
  //=========================================================================================================
  async selectPieceworks() {
    this.pieceworkService.selectPieceworks().subscribe(
      res => {
        this.pieceworks = res;
      }
    );
  }

  //==========================================================================================================
  // Obtener información del destajo. (Modulo y semana)
  //==========================================================================================================
  async getPieceInfo() {
    this.depapieceService.getPieceworksDetail(this.data.week, this.data.idpiecework_content).subscribe(
      res => {
        this.pieceworkInfo = res;
        this.pieceworkFormGroup.controls['idprojectdet'].setValue(this.pieceworkInfo.idprojectdet);
        this.pieceworkFormGroup.controls['week'].setValue(this.pieceworkInfo.week);
        this.pieceworkFormGroup.controls['employee'].setValue(this.pieceworkInfo.employeename);
        this.pieceworkFormGroup.controls['amount'].setValue(this.pieceworkInfo.amount);
        this.pieceworkFormGroup.controls['unitcost'].setValue(this.pieceworkInfo.unitcost);
        this.pieceworkFormGroup.controls['idpiecework'].setValue(this.pieceworkInfo.idconcept);

        this.week = this.pieceworkInfo.week;
        this.idpiecework = this.pieceworkInfo.idconcept;
        this.employee = this.pieceworkInfo.employeename;
        this.amount = this.pieceworkInfo.amount;
        this.unitcost = this.pieceworkInfo.unitcost;
      }
    );
  }

  //==========================================================================================================
  // Función para buscar los destajos registrados en esta semana
  //==========================================================================================================
  async getPieceworkList() {
    this.depapieceService.getPieceworksDetail(this.data.week, this.data.idpiecework_content).subscribe(
      res => {
        console.log(res);
        this.pieceworkFormGroup.controls['iddeparture'].setValue(res.iddeparture);
        this.iddeparture = res.iddeparture;
        this.pieceworkFormGroup.controls['departurenumber'].setValue(res.departurenumber);
        this.pieceworkFormGroup.controls['departurename'].setValue(res.descriptions);
        this.pieceworkFormGroup.controls['departure'].setValue(res.departurenumber + "~" + res.descriptions);

        this.pieceworkFormGroup.controls['idpiecework'].setValue(res.idconcept);
        this.idpiecework = res.idpiecework;
        this.pieceworkFormGroup.controls['typepw'].setValue(res.pieceworkname);
        this.pieceworkFormGroup.controls['piecework'].setValue(res.idconcept + "~" + res.pieceworkname);

        //this.pieceworkFormGroup.controls['employee'].setValue(res.employeename);
        this.pieceworkFormGroup.controls['amount'].setValue(res.amount);
        this.pieceworkFormGroup.controls['unit'].setValue(res.unit);
        this.pieceworkFormGroup.controls['unitcost'].setValue(res.unitcost);
        this.pieceworkFormGroup.controls['total'].setValue(res.total);
        this.status();
      }
    );
  }

  //==========================================================================================================
  // Función para buscar los modulos registrados en la BD
  //==========================================================================================================
  async getModules() {
    this.projectDetailService.selectDetailsById(this.data.idProject).subscribe(
      res => {
        this.towers = res;
      }
    );
  }


  //==========================================================================================================
  // Comparar valores en busca de cambios que necesiten una justificación
  //==========================================================================================================
  async compareValues() {
    if (this.pieceworkFormGroup.controls['week'].value != this.week
      || this.pieceworkFormGroup.controls['employee'].value != this.employee
      || this.pieceworkFormGroup.controls['amount'].value != this.amount
      || this.pieceworkFormGroup.controls['unitcost'].value != this.unitcost
      || this.pieceworkFormGroup.controls['iddeparture'].value != this.iddeparture
      || this.pieceworkFormGroup.controls['idpiecework'].value != this.idpiecework
      ) {
      this.modification = true;
      this.recalculateTotal();
    } else {
      this.modification = false;
    }
  }

  //=========================================================================================================
  //Función para buscar y seleccionar un destajo del combobox del formulario
  //=========================================================================================================
  async selectUnitPiece(id: any) {
    //El objeto select tiene de valor el ID y el nombre separados por coma
    //idpiecework ~ typepw

    /*this.pieceworkService.selectPieceworkById(id.idpiecework).subscribe(
      res => {
        this.piece = res;
        //Asignamos al objeto del formulario con controlName "unit" el valor recuperado de la consulta
        this.pieceworkFormGroup.controls['unit'].setValue(this.piece.unit);

        this.pieceworkFormGroup.controls['unitcost'].setValue(this.piece.cost);

        this.pieceworkFormGroup.controls['total'].setValue((this.pieceworkFormGroup.controls['unitcost'].value * this.pieceworkFormGroup.controls['amount'].value));

        this.actualCost = this.pieceworkFormGroup.controls['total'].value;

        this.selectPiecework(id);

      }
    );*/

     //El objeto select tiene de valor el ID y el nombre separados por coma
    //
    let ide = id.pieceworkname.split("-")
    console.log("Objeto del ide"+ide);
    this.pieceworkService.selectPiecework(parseInt(ide[0])).subscribe(
        res => {
          //Recuperamos el valor en la variable product
          this.piecework = res;
          console.log(this.piecework);
          //Asignamos al objeto del formulario con controlName "unit" el valor recuperado de la consulta
          this.pieceworkFormGroup.controls['idpiecework'].setValue(this.piecework.idconcept);
          this.pieceworkFormGroup.controls['unit'].setValue(this.piecework.unit);
        }
      );
  }

  
  //Recalcular el total
  async recalculateTotal() {
    this.pieceworkFormGroup.controls['total'].setValue(this.pieceworkFormGroup.controls['unitcost'].value * this.pieceworkFormGroup.controls['amount'].value);
    this.total = this.pieceworkFormGroup.controls['total'].value;
  }

  async selectDeparture(obj: any) {
    let info = obj.departurename.split('~');
    console.log("perro mas perro"+info)
    this.pieceworkFormGroup.controls['iddeparture'].setValue(obj.iddeparture);
    this.pieceworkFormGroup.controls['departurename'].setValue(info[1]);
    this.pieceworkFormGroup.controls['departurenumber'].setValue(info[0]);

    this.compareValues();
  }

  async selectPiecework(obj: any) {
    this.pieceworkFormGroup.controls['idpiecework'].setValue(obj.idpiecework);
    this.pieceworkFormGroup.controls['typepw'].setValue(obj.typepw);

    this.compareValues();
  }

  async updatePiecework() {
    if(this.modification == true && (this.pieceworkFormGroup.controls['reasonMod'].value == null || this.pieceworkFormGroup.controls['reasonMod'].value == '')){
      this.sweet.AlertTime("center", "warning", "Por favor capture una razón de modificación!", false, 2000);
    } else if (this.modification == false) {
      this.sweet.AlertTime("center", "warning", "No hay cambios que guardar, asegurate de cambiar todo lo necesario!", false, 2000);
    } else {
      Swal.fire({
        title: '¿Quieres guardar tus cambios ahora?',
        text: "¡Este destajo se guardara como está actualmente!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No, espera',
        confirmButtonText: 'Sí, guárdalo!'
      }).then((result) => {
        if (result.isConfirmed && this.statusemployee=="nonulo") {
          this.pieceworkService.updatePiecework(this.pieceworkInfo.idpiecework_content, this.pieceworkFormGroup.value).subscribe(
            res => {
             this.insertEmployee();
              this.sweet.AlertTime("center", "success", "Destajo actualizado con éxito", false, 2000);
              this.dialogRef.close();
            }
          );
        } else {
          this.pieceworkService.updatePiecework(this.pieceworkInfo.idpiecework_content, this.pieceworkFormGroup.value).subscribe(
            res => {
              this.sweet.AlertTime("center", "success", "Destajo actualizado con éxito", false, 2000);
              this.dialogRef.close();
            }
          );
        }
          
      })
    }
  }

  async insertReason() {
    this.reasonPieceService.insertReason(this.data.week, this.pieceworkFormGroup.controls['reasonMod'].value, this.getUser()).subscribe(
      res => {
        this.sweet.AlertTime("center", "success", "Destajo actualizado con éxito", false, 2000);
        this.dialogRef.close();
      }
    );
  }

  async insertEmployee() {
    console.log(this.pieceworkInfo.idpiecework_employees)
    console.log(this.pieceworkFormGroup.controls['employee'].value)
    this.pieceworkService.updateEmployee(this.pieceworkInfo.idpiecework_employees, this.pieceworkFormGroup.controls['employee'].value).subscribe(
    );
  }

  //==========================================================================================================
  //Recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.email
  }

  //==========================================================================================================
  //Función para buscar los materiales registrados en el catalogo de la BD
  //==========================================================================================================
  async searchPieceworks() {
    this.pieceworkService.getNamesOfPieceworks()
      .subscribe(
        res => {
          //Se recuperan los productos en la variable destajos
          this.namepiecework = res;
          console.log(this.namepiecework);
        },
        err => console.log(err)
      );
      }

  async status() {
     if(this.pieceworkFormGroup.controls['employee'].value == null){
      this.statusemployee='nulo';
     }
  }

}
