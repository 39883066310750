<mat-card>

    <mat-card-content>
        <mat-form-field appearance="outline" class="col-md-3 ">
            <mat-label> Proyectos </mat-label>
            <mat-select (valueChange)="getCommissionsForProject($event); getCommissionsPaid($event)" required>
                <mat-option *ngFor="let project of projects" [value]="project.name">
                    {{project.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card-content>

</mat-card>

<!-- ESQUEMA TIPO 3 -->
<mat-card *ngIf="type == 3">
    <mat-card-content class="justify-align-center">
        <div>
            <table class="table table-striped mt-3 animated fadeIn faster m19table"
                style="text-align:center; height: 100; align-content: border= 1 ;">
                <thead class="thead-m19">
                    <tr>
                        <th scope="col" align="center"> inmueble </th>
                        <th scope="col" style="width: 8%;"> Anticipo 1 </th>
                        <th scope="col" align="center"> Anticipo 2 </th>
                        <th scope="col" align="center"> Anticipo 3 </th>
                        <th scope="col" align="center"> Anticipo 4 </th>
                        <th> Acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let res of paymentsByProject " >
                        <td> <button class="m19InvBtn" > {{res.depa}} </button>  </td>
<td>
    <div style="width: 100%;">
        <ng-container *ngIf="res.valorsinanticipo > 0 ; else tipo1">
            <div
                style="background-color: var(--primaryGreen); color: white; width: 100%; height: 100%; font-weight: bold; text-align: center; padding: 5px; border-radius: 5px;">
                Pagado {{res.valorsinanticipo}}
            </div>
        </ng-container>

        <ng-template #tipo1>
            <ng-container *ngIf=" res.valorsinanticipo <= 0">
                <div
                    style="background-color: var(--M19Primary); color: white; width: 100%; height: 100%; font-weight: bold; text-align: center; padding: 5px; border-radius: 5px;">
                    No pagado {{res.valorsinanticipo}}
                </div>
            </ng-container>
        </ng-template>
    </div>
</td>

                        <td> Pagado </td>
                        <td> Pagado </td>
                        <td> Pagado </td>
                        <td> <mat-icon class="icons__print" data-title="Reimprimir ticket"> description </mat-icon>
                            <mat-icon class="icons__delete" data-title="Eliminar ticket"> delete </mat-icon> </td>
                    </tr>
                </tbody>

            </table>

        </div>
    </mat-card-content>
</mat-card>

<!-- ESQUEMA TIPO 2 -->
<mat-card *ngIf="type == 2">
    <mat-card-content class="justify-align-center">
        <div>
            <table class="table table-striped mt-3 animated fadeIn faster"
                style="text-align:center; height: 100; align-content: border= 1 ;">
                <thead class="thead-m19">
                    <tr>
                        <th scope="col" align="center">inmueble</th>
                        <th scope="col" align="center">
                            Anticipo</th>
                        <th scope="col" align="center"> Pago 1
                        </th>
                        <th scope="col" align="center"> Pago 2
                        </th>
                        <th scope="col" align="center"> Pago 3
                        </th>
                        <th scope="col" align="center"> Pago 4
                        </th>
                        <th scope="col" align="center"> Pago 5
                        </th>
                        <th scope="col" align="center"> Pago 6
                        </th>
                        <th scope="col" align="center"> Pago 7
                        </th>
                        <th scope="col" align="center"> Acción
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th> Inmueble </th>
                        <th> Anticipo </th>
                        <th> Mes 1 </th>
                        <th> Mes 2 </th>
                        <th> Mes 3 </th>
                        <th> Mes 4 </th>
                        <th> Mes 5 </th>
                        <th> Mes 6 </th>
                        <th> Mes 7 </th>
                        <th> <mat-icon data-title="Reimprimir ticket"> description </mat-icon> <mat-icon
                                data-title="Eliminar ticket"> delete </mat-icon> </th>
                    </tr>
                </tbody>

            </table>

        </div>
    </mat-card-content>
</mat-card>

<!-- ESQUEMA TIPO 1 -->
<mat-card *ngIf="type == 1">
    <mat-card-content class="justify-align-center">
        <div>
            <table class="table table-striped mt-3 animated fadeIn faster"
                style="text-align:center; height: 100; align-content: border= 1 ;">
                <thead class="thead-m19">
                    <tr>
                        <th scope="col" align="center">inmueble</th>
                        <th scope="col" align="center">
                            Anticipo</th>
                        <th scope="col" align="center"> Pago 1
                        </th>
                        <th scope="col" align="center"> Pago 2
                        </th>
                        <th scope="col" align="center"> Pago 3
                        </th>
                        <th scope="col" align="center"> Pago 4
                        </th>
                        <th scope="col" align="center"> Pago 5
                        </th>
                        <th scope="col" align="center"> Pago 6
                        </th>
                        <th scope="col" align="center"> Pago 7
                        </th>
                        <th scope="col" align="center"> Pago 8
                        </th>
                        <th scope="col" align="center"> Pago 9
                        </th>
                        <th scope="col" align="center"> Acción
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th> Inmueble </th>
                        <th> Anticipo </th>
                        <th> Mes 1 </th>
                        <th> Mes 2 </th>
                        <th> Mes 3 </th>
                        <th> Mes 4 </th>
                        <th> Mes 5 </th>
                        <th> Mes 6 </th>
                        <th> Mes 7 </th>
                        <th> Mes 8 </th>
                        <th> Mes 9 </th>
                        <th> <mat-icon data-title="Reimprimir ticket"> description </mat-icon> <mat-icon
                                data-title="Eliminar ticket"> delete </mat-icon> </th>
                    </tr>
                </tbody>

            </table>

        </div>
    </mat-card-content>
</mat-card>

<!-- <mat-card>


    <mat-card-content>

        <table mat-table [dataSource]="dataSource">

            <ng-container *ngFor="let ty of types" matColumnDef="{{ty.name}}">
                <th *matHeaderCellDef class="m19table"
                    style="width: 8%; height: 10px ;text-align: center; background-color: #D33E3E; color: white; padding: 10px;">
                    <b> {{ty.title}} </b> </th>
                <td mat-cell *matCellDef="let element"> {{element[ty.name]}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="text-align: center;"></tr>
        </table>


    </mat-card-content>

</mat-card> -->

<mat-card *ngIf="type == 0">
    <mat-card-content>
        <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
            <h1 style="color: #5E4F4F"> <b> Realice su busqueda para obtener resultados </b> </h1>
            <mat-icon style="scale: 2; margin-top:30px; color: #5E4F4F"> search </mat-icon>
        </div>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="type == 400">
    <mat-card-content>
        <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
            <h1 style="color: #5E4F4F"> <b> Sin Resultados de esta busqueda </b> </h1>
            <mat-icon style="scale: 2; margin-top:30px; color: #5E4F4F"> sentiment_dissatisfied </mat-icon>
        </div>
    </mat-card-content>
</mat-card>