import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private http: HttpClient) { }

  getDate(): Observable<any> {
    return this.http.get(`${environment.URLServer}/utils/getDate`);
  }

  getPCData(pc: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/utils/getPCData/${pc}`);
  }

  getCountries(): Observable<any> {
    return this.http.get('https://restcountries.com/v3.1/all?fields=translations').pipe(
      map((countries: any[]) => countries.map(country => country.translations.spa.common).sort())
    );
  }
}