<mat-tab-group mat-stretch-tabs>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">add_circle</mat-icon>
            <span>Nuevo venta</span>
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">
                    <form class="row mt-3" [formGroup]="saleFormGroup" (ngSubmit)="createSale()">

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Folio de contrato</mat-label>
                            <input matInput type="text" formControlName="IdContract" #idContrat maxlength="5" minlength="5" placeholder="CB000">
                            <mat-hint align="end">{{idContrat.value.length}} / 5</mat-hint>
                        </mat-form-field>

                        <br>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Fecha de contrato</mat-label>
                            <input matInput type="date" formControlName="contractDate">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Fecha de contrato</mat-label>
                            <input matInput type="date" formControlName="fechaDato">
                        </mat-form-field>

                        <h5 class="col-12 pb-2">Datos del cliente</h5>

                        <!--mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Nombre del cliente</mat-label>
                            <input matInput type="text" placeholder="Ingresar el nombre del cliente" [(ngModel)]="filterCustomer" name="filterCustomer">
                        </mat-form-field-->

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Nombre del cliente</mat-label>
                            <mat-select formControlName="fk_customer" [(value)]="selected_customer"
                                (valueChange)="selectCustomer($event)" required>
                                <mat-option *ngFor="let customer of customers | filterCustomer: filterCustomer" [value]="customer.idUser">
                                    {{customer.name}} {{customer.firstLastName}} {{customer.secondLastName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Correo electrónico</mat-label>
                            <input matInput readonly value="{{customer.email}}">
                            <mat-icon matSuffix>email</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Celular</mat-label>
                            <input matInput readonly value="{{customer.mobile}}">
                            <mat-icon matSuffix>phone_iphone</mat-icon>
                        </mat-form-field>

                        <h5 class="col-12 pb-2">Datos del co-propietario</h5>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Nombre del co-propietario</mat-label>
                            <mat-select formControlName="coowner" [(value)]="selected_coowner"
                                (valueChange)="selectCoowner($event)">
                                <mat-option *ngFor="let coowner of customers" [value]="coowner.idUser">
                                    {{coowner.name}} {{coowner.firstLastName}} {{coowner.secondLastName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Correo electrónico</mat-label>
                            <input matInput readonly value="{{coowner.email}}">
                            <mat-icon matSuffix>email</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Celular</mat-label>
                            <input matInput readonly value="{{coowner.mobile}}">
                            <mat-icon matSuffix>phone_iphone</mat-icon>
                        </mat-form-field>

                        <h5 class="col-12 pb-2">Datos del coordinador de ventas</h5>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Nombre del coordinador</mat-label>
                            <mat-select formControlName="fk_user" [(value)]="selected_user"
                                (valueChange)="selectUser($event)" required>
                                <mat-option *ngFor="let user of users" [value]="user.idEmployee">
                                    {{user.firstLastName}} {{user.secondLastName}} {{user.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <h5 class="col-12 pb-2">Datos del vendedor</h5>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Nombre del vendedor</mat-label>
                            <mat-select formControlName="fk_seller" [(value)]="selected_seller" (valueChange)="selectSeller($event)">
                                <mat-option *ngFor="let seller of sellers" [value]="seller.idEmployee">
                                    {{seller.firstLastName}} {{seller.secondLastName}} {{seller.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <h5 class="col-12 pb-2">Datos del inmueble</h5>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Proyecto</mat-label>
                            <mat-select [(value)]="selected_project" (valueChange)="selectPropertiesProject($event)"
                                required>
                                <mat-option *ngFor="let project of projects" [value]="project.nomenclature">
                                    {{project.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Inmueble</mat-label>
                            <mat-select formControlName="fk_property" [(value)]="selected"
                                (valueChange)="selectProperty($event)" required>
                                <mat-option *ngFor="let property of properties" [value]="property.codeProperty">
                                    {{property.codeProperty}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2" *ngIf="selected">
                            <mat-label>Valor total</mat-label>
                            <input matInput readonly value="{{property.amount | currency:'CAD':'$ '}}">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2" *ngIf="selected">
                            <mat-label>Anticipo</mat-label>
                            <input matInput readonly value="{{property.advancePayment | currency:'CAD':'$ '}}">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2" *ngIf="selected">
                            <mat-label>Mensualidad</mat-label>
                            <input matInput readonly value="{{property.monthlyPayment | currency:'CAD':'$ '}}">
                        </mat-form-field>

                        <div class="col-12 text-right mt-3">
                            <button class="btn btn-m19" type="submit" [disabled]="!saleFormGroup.valid">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>

                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">search</mat-icon>
            Consultar ventas
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">

                    <mat-form-field appearance="outline" class="filter">
                        <mat-label>Buscar inmueble</mat-label>
                        <input matInput type="text" placeholder="Ingresar el código de inmueble" [(ngModel)]="filterProperty" name="filterProperty">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>

                    <table class="table table-striped mt-3 animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr>
                                <th scope="col">Inmueble</th>
                                <th scope="col">Fecha de venta</th>
                                <th scope="col">Cliente</th>
                                <th scope="col">Coordinador de ventas</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let sale of sales | filterProperty: filterProperty">
                                <td>{{sale.codeProperty}}</td>
                                <td>{{sale.fechaDato | date:'dd-MMM-yyyy'}}</td>
                                <td>{{sale.clientes}}</td>
                                <td>{{sale.vendedores}}</td>
                                <td class="button-row">
                                    <button class="btn btn-m19-table" mat-icon-button title="Ver detalles" (click)="selectSale(sale.codeProperty)">
                                        <mat-icon>visibility</mat-icon>
                                    </button>
                                    <!--button class="btn btn-m19-table" mat-icon-button title="Editar" (click)="updateSale(sale.codeProperty)">
                                        <mat-icon>create</mat-icon>
                                    </button-->
                                    <button class="btn btn-m19-table" mat-icon-button title="Rescindir" (click)="rescindContract(sale.codeProperty)">
                                        <mat-icon>block</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>

</mat-tab-group>
