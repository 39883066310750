<h4 style="text-align: center; border-bottom: solid #D33E3E 1px;">Edición de método de facturación</h4>

<form [formGroup]="methodFormGroup" onKeyPress="if(event.keyCode == 13) event.returnValue = false;">

    <mat-form-field appearance="outline">
        <mat-label>Método</mat-label>
        <mat-select [(value)]="getAllMethods" formControlName="fk_invoiceType" (valueChange)="checkMethod($event)" required>
            <mat-option *ngFor="let m of methods" [value]="m.idInvoiceType">
                {{m.description}}
            </mat-option>
        </mat-select>
        <mat-icon matSuffix>sort_by_alpha</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline" [ngClass]="{'display': type==4, 'undisplay': type!=4}">
        <mat-label>Fólio fiscal</mat-label>
        <input matInput type="text" formControlName="fiscalFolio">
        <mat-icon matSuffix>vpn_key</mat-icon>
    </mat-form-field>

    <button class="saveBtn" (click)="setMethod()"><mat-icon>save</mat-icon></button>
</form>