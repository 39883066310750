<h2>{{invoiceData[0].RFC}} - {{invoiceData[0].folInvoice}}</h2>

<mat-dialog-content>
    <table class="table table-striped mt-3 animated fadeIn faster">
        <tr>
            <th>Inmueble</th>
            <td>{{invoiceData[0].fkProperty}}</td>
            <th>Nombre</th>
            <td>{{invoiceData[0].UserName}}</td>
        </tr>
        <tr>
            <th>Fecha</th>
            <td>{{invoiceData[0].registrationDate | date:'dd-MMM-yyyy'}}</td>
            <th>Monto</th>
            <td>{{invoiceData[0].amountInvoice | currency:'CAD' : '$ '}}</td>
        </tr>
        <tr>
            <th>Estado</th>
            <td>{{invoiceData[0].invoiceStatus}}</td>
            <th>Tipo de pago</th>
            <td>{{invoiceData[0].paymentType}}</td>
        </tr>
    </table>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>