<mat-tab-group mat-stretch-tabs>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">add_circle</mat-icon>
            <span>Nuevo proyecto</span>
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">
                    <form class="row mt-3" [formGroup]="projectFormGroup" (ngSubmit)="createProject()">

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Nombre del proyecto</mat-label>
                            <input matInput type="text" formControlName="name" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Nomenclatura</mat-label>
                            <input matInput type="text" formControlName="nomenclature" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Municipio</mat-label>
                            <input matInput type="text" formControlName="municipality" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Estado</mat-label>
                            <input matInput type="text" formControlName="state" required>
                        </mat-form-field>

                        <div class="col-12 text-right mt-3">
                            <button class="btn btn-m19" type="submit" [disabled]="!projectFormGroup.valid">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>

                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">location_city</mat-icon>
            <span>Detalle de modulos</span>
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">

                    <form class="row mt-3" [formGroup]="projectDetFormGroup" (ngSubmit)="insertProjectDet()">
                        <div style="display:flex; flex-direction:column; width:100%;">
                            <div>
                                <mat-form-field appearance="outline" class="col-md-3">
                                    <mat-label>Proyecto</mat-label>
                                    <mat-select [(value)]="selectProjects" formControlName="idProject" required>
                                        <mat-option *ngFor="let project of projects" [value]="project.idProject">
                                            {{project.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div>
                                <mat-form-field appearance="outline" class="col-md-4">
                                    <mat-label>Nombre de modulo</mat-label>
                                    <input matInput type="text" formControlName="towernumber" required>
                                    <mat-icon matSuffix>roofing</mat-icon>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="col-md-4">
                                    <mat-label>Número de pisos</mat-label>
                                    <input matInput type="number" min="1" maxlength="2"
                                        oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                                        formControlName="floorsnumber" required>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="col-md-4">
                                    <mat-label>Departamentos por piso</mat-label>
                                    <input matInput type="number" min="0" maxlength="2"
                                        oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                                        formControlName="departmentsperfloor" required>
                                </mat-form-field>
                            </div>

                            <div class="col-12 text-right mt-3">
                                <button class="btn btn-m19" type="submit" [disabled]="!projectDetFormGroup.valid">
                                    <mat-icon>save</mat-icon>
                                </button>
                            </div>
                        </div>

                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">home</mat-icon>
            <span>Detalles de proyecto</span>
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">

                    <div class="col-12 text-right button-row mt-3">
                        <input #fileUpload type="file" accept=".csv" style="display: none">
                        <button class="btn btn-m19-table" (click)="fileUpload.click()" data-toggle="tooltip"
                            title="Cargar archivo .csv">
                            <mat-icon>cloud_upload</mat-icon>
                        </button>

                        <a [href]="fileUrl" download="properties (plantilla).csv">
                            <button class="btn btn-m19-table" data-toggle="tooltip" title="Descargar plantilla">
                                <mat-icon>cloud_download</mat-icon>
                            </button>
                        </a>
                    </div>

                    <form [formGroup]="propertyFormGroup" (ngSubmit)="createProperty()">

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Proyecto</mat-label>
                            <mat-select formControlName="fk_project" required>
                                <mat-option *ngFor="let project of projects" [value]="project.nomenclature">
                                    {{project.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <br>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Nomenclatura</mat-label>
                            <input matInput type="text" formControlName="codeProperty" required>
                            <mat-icon matSuffix>roofing</mat-icon>
                        </mat-form-field>

                        <br>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Valor total</mat-label>
                            <input matInput type="number" formControlName="amount" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Anticipo</mat-label>
                            <input matInput type="number" formControlName="advancePayment" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Pago mensual</mat-label>
                            <input matInput type="number" formControlName="monthlyPayment" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Superficie (m<sup>2</sup>)</mat-label>
                            <input matInput type="text" formControlName="area" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Estatus</mat-label>
                            <mat-select formControlName="fk_status" required>
                                <mat-option *ngFor="let status of status" [value]="status.idStatus">
                                    {{status.description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-6">
                            <mat-label>Nota</mat-label>
                            <input matInput type="text" formControlName="description">
                            <mat-icon matSuffix>notes</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Coordenada X</mat-label>
                            <input matInput type="text" formControlName="coordinateX" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Coordenada Y</mat-label>
                            <input matInput type="text" formControlName="coordinateY" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-6">
                            <mat-label>Colindancia noreste</mat-label>
                            <input matInput #colindancia_noreste maxlength="100" type="text"
                                formControlName="northeasBorder" required>
                            <mat-hint align="end">{{colindancia_noreste.value.length}} / 100</mat-hint>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-6">
                            <mat-label>Colindancia suroeste</mat-label>
                            <input matInput #colindancia_suroeste maxlength="100" type="text"
                                formControlName="southwestBorder" required>
                            <mat-hint align="end">{{colindancia_suroeste.value.length}} / 100</mat-hint>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-6">
                            <mat-label>Colindancia noroeste</mat-label>
                            <input matInput #colindancia_noroeste maxlength="100" type="text"
                                formControlName="northwestBorder" required>
                            <mat-hint align="end">{{colindancia_noroeste.value.length}} / 100</mat-hint>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-6">
                            <mat-label>Colindancia sureste</mat-label>
                            <input matInput #colindancia_sureste maxlength="100" type="text"
                                formControlName="southeastBorder" required>
                            <mat-hint align="end">{{colindancia_sureste.value.length}} / 100</mat-hint>
                        </mat-form-field>

                        <div class="col-12 text-right mt-3">
                            <button class="btn btn-m19" type="submit" [disabled]="!propertyFormGroup.valid">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>

                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">search</mat-icon>
            Consultar proyectos
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form" style="height: 65vh; overflow: scroll;">
                <mat-card-content class="justify-align-center">
                    <div class="cuadriculaProjects">
                        <div *ngFor="let project of projects" class="projectCard"
                            (click)="selected=selectPropertiesProject(project.nomenclature)">
                            <p class="projectName">{{project.nomenclature}} - {{project.name}}</p>
                            <img [src]="searchLogo(project.idProject)" class="projectLogo"
                                alt="Logo de {{project.name}}">
                            <p class="projectInfo"><mat-icon>pin_drop</mat-icon>{{project.municipality}},
                                {{project.state}}</p>
                        </div>
                    </div>

                </mat-card-content>
            </mat-card>
            <br>
            <mat-accordion *ngIf="selected!=0">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span style="font-weight: bold;">Consultar inmuebles</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-card-content class="justify-align-center">

                        <mat-form-field appearance="outline" class="filter">
                            <mat-label>Buscar inmueble</mat-label>
                            <input matInput type="text" placeholder="Ingresar el código de inmueble"
                                [(ngModel)]="filterProperty" name="filterProperty">
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>

                        <table class="m19Table">
                            <thead>
                                <tr>
                                    <th scope="col">Clave</th>
                                    <th scope="col">Cliente</th>
                                    <th scope="col">Valor</th>
                                    <th scope="col">Anticipo</th>
                                    <th scope="col">Mensualidad</th>
                                    <th scope="col">Superficie</th>
                                    <th scope="col">Estatus</th>
                                    <th scope="col">Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let property of properties | filterProperty: filterProperty">
                                    <td>{{property.codeProperty}}</td>
                                    <td>{{property.cliente}}</td>
                                    <td>{{property.amount | currency:'CAD':'$ '}}</td>
                                    <td>{{property.advancePayment | currency:'CAD':'$ '}}</td>
                                    <td>{{property.monthlyPayment | currency:'CAD':'$ '}}</td>
                                    <td>{{property.area}} m<sup>2</sup></td>
                                    <td>{{property.description}}</td>
                                    <td>
                                        <mat-icon class="actionAltBtn" title="Ver detalles"
                                            (click)="selectProperty(property.codeProperty)">visibility</mat-icon>
                                        <mat-icon class="actionBtn" title="Editar"
                                            (click)="updateProperty(property.codeProperty)">create</mat-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-expansion-panel>
            </mat-accordion>

        </div>
    </mat-tab>

</mat-tab-group>