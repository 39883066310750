import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterNegotiations'
})

export class FilterNegotiationsPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    const resultPosts = [];

    for (const post of value) {
      const search = "C" + post.PKNegotiation + " " + post.Customer;
      if (search.toUpperCase().indexOf(arg.toUpperCase()) > -1) {
        resultPosts.push(post);
      };
    };
    return resultPosts;
  }
}