import { Component, HostListener, OnInit } from '@angular/core';

//Servicios
import { UtilsService } from 'src/app/services/utils.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';
import { CLogbookService } from 'src/app/services/c-logbook.service';
import { DeparturerunService } from 'src/app/services/departurerun.service';
import { TypeUserService } from 'src/app/services/type-user.service';

//Pipes de fechas
import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { DatePipe } from '@angular/common';

//Material
//import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';

//Otros
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import decode from 'jwt-decode';
import Swal from 'sweetalert2';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { ToastrService } from 'ngx-toastr';
import { loader } from 'src/app/ui/loader/loader.model';

@Component({
  selector: 'app-c-logbook-register',
  templateUrl: './c-logbook-register.component.html',
  styleUrls: ['./c-logbook-register.component.scss']
})
export class CLogbookRegisterComponent implements OnInit {

  project: any = { id: 0, name: "" };
  year: String = "";
  month: String = "";
  day: number = 0;
  search: String = "";
  months: any = [{ "num": "01", "name": "Enero" }, { "num": "02", "name": "Febrero" }, { "num": "03", "name": "Marzo" }, { "num": "04", "name": "Abril" }, { "num": "05", "name": "Mayo" }, { "num": "06", "name": "Junio" }, { "num": "07", "name": "Julio" }, { "num": "08", "name": "Agosto" }, { "num": "09", "name": "Septiembre" }, { "num": "10", "name": "Octubre" }, { "num": "1", "name": "Noviembre" }, { "num": "12", "name": "Diciembre" }];
  days: any = [];
  colors: any = [];
  printReady: number = 0;
  logbook: any = [];
  evidences: any = [];
  evidenceConcept: any = "";
  printlog: any = [];
  printevid: any = [];

  depList: any = [];

  loader: loader = {
    message: 'Registrando bitácora...'
  }


  public getScreenWidth: any;
  public getScreenHeight: any;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

  //FORMGROUP REGISTRO
  logbookForm: FormGroup = this._formBuilder.group({
    fk_idEmployee: [, Validators.required],
    fk_idProject: [, Validators.required],
    fk_idprojectdet: [null, Validators.required],
    workmanship: [1, Validators.pattern(/^([1-9][0-9]*)$/)],
    remarks: [, Validators.required],
    fk_iddeparture: []
    //useremail: [this.getUser()]
  });

  //FORMGROUP PENDIENTES
  logbookPForm: FormGroup = this._formBuilder.group({
    fk_idProject: [, Validators.required],
    fk_idprojectdet: [null, Validators.required],
    workmanship: [1, Validators.pattern(/^([1-9][0-9]*)$/)],
    remarks: [, Validators.required],
    fk_iddeparture: []
    //useremail: [this.getUser()]
  });

  pipe = new DatePipe('es-MX');
  public selectedDate: boolean = false;//Bandera que indica si se tomó fecha desde el servidor o ingresada por el usuario; true=por usuario; false=por servidor
  public serverDate: string = "CARGANDO FECHA...";
  public decode: any;
  public userName: string = "CARGANDO USUARIO...";
  actualDeparture: any = [];

  //Variables que se llenan con consultas
  public _selectProjects: any;
  public _selectDetailsById: any = [{
    idprojectdet: null,
    towernumber: 'sin proyecto seleccionado'
  }];
  public _selectHistory: any = [];
  public _clogbook_responsable: any[];
  public originDate: string;
  public originDateServer: string//Aqui se almacena una copia de la fecha y hora original, para no alterar el código debido a reajustes solicitados por usuario

  //Variable de previsualización y nombre para mostrar y subir a firebase
  //public img: any[] = [];
  public img64: any[] = [];//Para imagenes
  public pdf64: any[] = [];//Para PDF
  isLoading: boolean = false;//Activa/desactiva pantalla de carga
  isDisabled: boolean = true;//Desactiva los botones hasta haber cargado servicios o terminar cierto flujo
  //public imgarray: any[]=[]; //Array de imagenes

  constructor(
    private cLogbookService: CLogbookService,
    private utilsService: UtilsService,
    private projectService: ProjectService,
    private projectDetailService: ProjectdetailsService,
    private _formBuilder: FormBuilder,
    private sweet: SweetAlertComponent,
    private departurerunService: DeparturerunService,
    private toastr: ToastrService,
    private dateAdapter: DateAdapter<Date>,
    private typeUserService: TypeUserService
  ) {

    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
    this.utilsService.getDate().subscribe(res => { this.serverDate = this.pipe.transform(res, 'fullDate').toUpperCase(); this.originDate = res; this.isDisabled = false; this.originDateServer = res; });
    this.decode = decode(localStorage.getItem("token") || "");
    this.userName = this.decode.cliente.toUpperCase();
    this.typeUserService.clogbook_responsable().subscribe(res => { this._clogbook_responsable = res; })
    this.projectService.selectProjects().subscribe(res => { this._selectProjects = res; this.projects = res });//Ultima asignación a variable para no afectar la funcionalidad
    this.getAllPending();
    //this.getProjects(); este no es necesario ya que ya se ejecuta la misma consulta
    this.dateAdapter.setLocale('es-ES');
  }

  ngOnInit(): void { }

  projects: any = [];
  evidence: any = [];
  pendientes: any = [];
  temporal = {
    idclogbook: 0,
    fkidclogbook_status: 1,
    datelog: "0000-00-10",
    descriptions: "",
    employee: "SIN ASIGNAR",
    clsname: "",
    iddeparture: 0,
    name: "",
    nomenclature: "",
    remarks: "",
    towernumber: "",
    workmanship: 0
  };

  //----------------------------------- PENDIENTES DE CIERRE ---------------------------------
  /*async getProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      }
    );
  }*/

  async getAllPending() {
    this.cLogbookService.getPendingtoCloseService().subscribe(
      res => {
        this.pendientes = res;
      }
    )
  }

  async dataTemporal(data: any) {
    this.temporal = data;
    this.cLogbookService.getPenDepartureList(data.idclogbook).subscribe(
      res => {
        this.depList = res;

        this.logbookPForm.controls["workmanship"].setValue(this.temporal.workmanship)
        this.logbookPForm.controls["remarks"].setValue(this.temporal.remarks)

        this.cLogbookService.getEvidenceService(this.temporal.idclogbook).subscribe(
          res => {
            this.evidence = res;
          })
      }
    );
  }

  async saveLogbook(nolog: number) {

    const value = await Swal.fire({
      title: '  <FONT COLOR="#000000"> <strong> ¿Deseas guardar? </strong> </FONT> ',
      icon: "info",
      iconColor: "#0259bd",
      width: "auto",
      showConfirmButton: true,
      showCancelButton: true,

      html:
        'Estas a punto de actualizar la informacionen la bitacora ' +
        'al dar guardar posteriormente podras volver a  actualizar' +
        '<br>hasta que cierres la bitacora en curso. </b>',

      confirmButtonText: 'Continuar',
      cancelButtonText: `Cancelar`,
      buttonsStyling: false, //Permite customizar los botones

      customClass: {
        actions: 'sweetActions',
        confirmButton: 'm19AltBtn',
        cancelButton: 'm19InvBtn',
        input: 'sweetInput'
      },


    }).then((result) => {
      this.cLogbookService.getPendingtoCloseService().subscribe(
        res => {
          if (result.isConfirmed) {
            this.cLogbookService.updateCLogbookService(this.logbookForm.value.workmanship, this.logbookForm.value.remarks, nolog).subscribe()
            Swal.fire("Informacion actualizada con exito", "De click en ok para continuar", "success")
            this.temporal.employee = 'SIN ASIGNAR'
            this.getAllPending();
          } else if (result.isDismissed) { result.dismiss }
        }
      )
    })


  }

  async deletecLog(data: number) {
    const value = await Swal.fire({
      title: '  <FONT COLOR="#000000"> <strong> ¡ ESTAS A PUNTO DE ELIMINAR ! </strong> </FONT> ',
      icon: "error",
      iconColor: "#D33E3E3",
      width: "auto",
      showConfirmButton: true,
      showCancelButton: true,

      html:
        'Estas a punto de eliminar la bitacora seleccionada ' +
        '<br>Una vez eliminada no podras volver a recuperar ' +
        '<br>ESTAS SEGURO?. </b>',

      confirmButtonText: 'Continuar',
      cancelButtonText: `Cancelar`,
      buttonsStyling: false, //Permite customizar los botones

      customClass: {
        actions: 'sweetActions',
        confirmButton: 'm19AltBtn',
        cancelButton: 'm19Btn',
        input: 'sweetInput'
      },
    }).then((result) => {
      this.cLogbookService.getPendingtoCloseService().subscribe(
        res => {
          if (result.isConfirmed) {
            this.temporal.employee = 'SIN ASIGNAR'
            this.cLogbookService.deleteCLogbookService(data, this.decode.fkuser).subscribe(res => this.temporal.employee = 'SIN ASIGNAR')
            Swal.fire("Bitacora eliminada correctamente", "se elimino de manera exitosa la bitacora seleccionada", "success")
            this.getAllPending();
          } else if (result.isDismissed) { result.dismiss }
        }
      )
    })

  }

  mensaje2() {
    Swal.fire("Picaste el clip", "Muy bien", "success")
  }

  //////////////////////////////////////// BITACORA PENDIENTES ///////////////////////////////////////////////
  title = "";
  async pruebafuncion() {
    // Get the modal
    var modal = document.getElementById("myModal");

    // Get the image and insert it inside the modal - use its "alt" text as a caption
    var img = document.getElementById("myImg");
    var modalImg = document.getElementById("img01");
    var captionText = document.getElementById("caption");


    modal.style.display = "block"
    modalImg.style.display = "block";
    captionText.style.display = "block"

    this.title = img.getAttribute("alt");
    console.log("ALT CAMBIANTE → ", this.title);

    // modal.style
    // img.onclick = function () {
    //   console.log("Hiciste click en la imagen")
    //   modal.style.display = "block";
    //   modalImg.src = this.src;
    //   captionText.innerHTML = this.alt;
    // }

    // // Get the <span> element that closes the modal
    // var span = document.getElementsByClassName("close")[0];

    // // When the user clicks on <span> (x), close the modal
    // span.onclick = function () {
    //   modal.style.display = "none";
    // }


  }

  async closeFunction() {
    var modal2 = document.getElementById("myModal");
    modal2.style.display = "none"
  }
  //----------------------------------- NUEVO REGISTRO -------------------------------------

  async selectedProject(id: number) {
    this._selectHistory = [];
    this.actualDeparture = [];
    this.projectDetailService.selectDetailsById(id).subscribe(
      res => {
        this._selectDetailsById = res;
        //console.log('Existencia de modulos: ', this._selectDetailsById)
        if (this._selectDetailsById.length == 0) {
          this._selectDetailsById = [{
            idprojectdet: null,
            towernumber: 'no existente'
          }];
        }
      }
    );
  }

  loadOpenDeparture(projectDetail: any) {
    this._selectHistory = [];
    this.actualDeparture = [];
    this.departurerunService.selectHistory(projectDetail).subscribe(
      res => {
        //El servicio obtiene todas las partidas de ese módulo, pero el filter solo retorna las que están habilitadas
        this._selectHistory = [];
        for (let dp of res.filter((res: { status: string; }) => res.status == 'ON')) {
          dp.departurename = 'Partida ' + dp.departurenumber + ': ' + dp.descriptions;
          this._selectHistory.push(dp);
        }
      }
    )
  }

  async departureSelected(event: any) {
    this.actualDeparture.push(event);
    this.logbookForm.controls['fk_iddeparture'].setValue('');
  }

  async uploadImages(imageGroup: any[], mode: boolean) {
    let form = this.logbookForm.value;
    form.fk_iddeparture = this.actualDeparture.iddeparture;

    if (!form.fk_idProject || !form.fk_idprojectdet || this.actualDeparture.length == 0 || !form.remarks || !form.fk_idEmployee) {
      return
    }
    //mode indica si la función guardará datos de forma definitiva (true) o como borrador (false)
    if (imageGroup == null || imageGroup == undefined || imageGroup.length == 0) {
      this.sweet.AlertTime("center", "error", `No hay ninguna imagen cargada`, false, 3000);
      return;
    }
    let warning = '';
    if (mode) {
      warning = 'Usted está a punto de finalizar este reporte de bitácora y marcarlo como finalizado definitivamente, los cambios no se pueden modificar ni deshacer';
    } else {
      warning = 'Este reporte de bitácora se guardará como borrador para su posterior edición, se finalizará automáticamente a las 11:59 p.m. del día de hoy';
    }

    Swal.fire({
      title: '¿Continuar?',
      html: warning,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Guardar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.isDisabled = true;
        this.isLoading = true;
        this.originDate = this.originDate.slice(0, 19).replace('T', ' ');
        this.cLogbookService.insertCLogbook(form).subscribe(
          idicl => {
            let idcdepp = []
            for (let icdp of this.actualDeparture) {
              idcdepp.push({
                fk_idclogbook: idicl,
                fk_iddeparture: icdp.iddeparture
              })
            }
            let index = 0;
            let indexpdf = 0;
            console.log('Lo que se va a insertar en logbookDepartures: ', idcdepp)

            this.cLogbookService.uploadImage(this.originDate, this.img64).then(async UrlImage => {
              try {
                for await (let i of UrlImage) {
                  this.cLogbookService.insertCLogbook_Docs({
                    fk_idclogbook: idicl,
                    typedoc: 'img',
                    docurl: i,
                    docname: this.originDate + '_' + index + '_img'
                  }).subscribe();
                  index++
                }
                this.cLogbookService.uploadPdf(this.originDate, this.pdf64).then(async UrlPDF => {
                  try {
                    for await (let ipdf of UrlPDF) {
                      this.cLogbookService.insertCLogbook_Docs({
                        fk_idclogbook: idicl,
                        typedoc: 'pdf',
                        docurl: ipdf,
                        docname: this.originDate + '_' + indexpdf + '_pdf'
                      }).subscribe();
                      indexpdf++
                    }
                    this.cLogbookService.insertCLogbook_Log({
                      fk_idclogbook: idicl,
                      fk_user: this.decode.fkuser,
                      fk_clogbookstatus: (mode) ? 2 : 1,
                      datelog: this.originDate
                    }).subscribe(icll => {
                      let inx = 0;
                      for (let idcdepp_ of idcdepp) {
                        this.cLogbookService.insertclogbookDepartures(idcdepp_).subscribe(iclbdepp => {
                          console.log('Prueba de asincronidad: ', inx++)
                        })
                      }
                      console.log('Prueba finalizada de sincronidad FINALIZADA')
                      this.isLoading = false;
                      if (mode) {
                        this.sweet.AlertTime("center", "success", `El reporte de bitacora ha sido concluído con éxito`, false, 3000);
                      } else {
                        this.sweet.AlertTime("center", "success", `El reporte de bitacora ha sido guardado como borrador`, false, 3000);
                      }
                      this.newLogbook();
                    })
                  } catch (e) {
                    console.log('Error de subida de PDF: ', e)
                  }
                })
              } catch (e) {
                console.log(this.sweet.AlertTime("center", "error", 'Error en el proceso de carga', false, 3000));
              }
            })

          }
        )
      }
    })
  }


  private async newLogbook() {
    this.logbookForm.controls['fk_idEmployee'].setValue(null);
    this.logbookForm.controls['fk_idProject'].setValue('');
    this.logbookForm.controls['fk_idprojectdet'].setValue(null);
    this.logbookForm.controls['workmanship'].setValue(1);
    this.logbookForm.controls['remarks'].setValue('');
    this.logbookForm.controls['fk_iddeparture'].setValue('');
    this.actualDeparture = [];
    this.img64 = [];
    this.pdf64 = [];
    this._selectDetailsById = [{
      idprojectdet: null,
      towernumber: 'sin proyecto seleccionado'
    }];
    this._selectHistory = [];
    this.utilsService.getDate().subscribe(res => {
      this.serverDate = this.pipe.transform(res, 'fullDate').toUpperCase();
      this.originDate = res; this.isDisabled = false;
      this.originDateServer = res; this.selectedDate = false;
      this.getAllPending();
      this.projectService.selectProjects().subscribe(res => {
        this._selectProjects = res;
        this.isDisabled = false;
      });
    });

  }

  public LoadImg(event: any) {
    try {
      let files = event.target.files;
      for (let f of files) {
        let reader = new FileReader();
        reader.readAsDataURL(f);
        if (f.type != "application/pdf") {
          reader.onloadend = () => {
            this.img64.push(reader.result);
          }
        } else {
          reader.onloadend = () => {
            this.pdf64.push(reader.result);
          }
        }
      }
    } catch (e) {
      return null;
    }
  };

  async submitFormLogbook() {
    let form = this.logbookForm.value;
    if (!form.fk_idEmployee) { this.toastr.error('Residente de obra sin seleccionar'); }
    if (!form.fk_idProject) { this.toastr.error('Proyecto sin seleccionar'); }
    if (!form.fk_idprojectdet) { this.toastr.error('Módulo sin seleccionar'); }
    //if (!form.fk_iddeparture) { this.toastr.error('Actividad sin seleccionar'); }
    if (this.actualDeparture == 0) { this.toastr.error('No se han añadido partidas presupuestales'); }
    if (!form.remarks) { this.toastr.error('Sin comentarios ingresados'); }
    /*
    fk_iddeparture: null
    idProject: null
    idprojectdet: null
    remarks: null
    workmanship: 1
    */
  }

  async onDateChange(e: any) {
    this.serverDate = this.pipe.transform(e.value, 'fullDate').toUpperCase();//Renombra variable ya utilizadas con una fecha elegida por usuario en formato texto
    this.originDate = e.value.toISOString() + '';//Lo mismo que arriba, pero esta con el formato ISO para MySQL
    console.log(this.originDate);
    this.selectedDate = true;
  }

  async defaultServerDate() {
    this.serverDate = this.pipe.transform(this.originDateServer, 'fullDate').toUpperCase();
    this.originDate = this.originDateServer;
    console.log(this.originDate);
    this.selectedDate = false;
  }

  // =============================================================
  // PARTE DE HISTORIAL DE BITACORA
  // =============================================================
  async selectProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        //Se recuperan todos los proyectos en la variable projects
        this.projects = res;
      },
      err => console.log(err)
    );
  }

  getColor(n: number) {
    let status: any = [];
    status = this.colors.filter(d => d.dia == n)[0];

    if (status != undefined) {
      return status.estatus;
    } else {
      return 0;
    }

  }

  async checkDate(value: any, type: string) {
    this.printReady = 0;
    this.days = [];
    this.logbook = [];
    if (type == "month") {
      this.month = value;
      this.search = this.year + "-" + this.month + "-" + "01";
    } else if (type == "year") {
      this.year = value;
      this.search = this.year + "-" + this.month + "-" + "01";
    }
    if (this.year != "" && this.month != "") {
      if (this.project.id > 0) {
        this.getDaysInMonth();
      } else {
        console.log(this.project);
        this.sweet.AlertTime("center", "error", `Elija un proyecto para continuar`, false, 2000);
      }
    }
  }

  async getDaysInMonth() {
    this.cLogbookService.getDaysInMonth(this.search).subscribe(
      res => {
        this.days = Array(res.days).fill(1).map((x, i) => i + 1);
        this.cLogbookService.getColors(this.search, this.project.id).subscribe(
          col => {
            this.colors = col;
            console.log(this.colors);
          }
        );
      }
    );
  }

  async getLogbookPerDay(day: number) {
    this.logbook = [];
    this.day = day;

    this.cLogbookService.getLogbookPerDay(this.year + "-" + this.month + "-" + (day < 10 ? "0" + day : day), this.project.id).subscribe(
      res => {
        this.logbook = res;
        this.printReady = 1;
        this.getEvidences();
        console.log(res);
      }
    );
  }

  async getEvidences() {
    this.cLogbookService.getEvidences(this.year + "-" + this.month + "-" + (this.day < 10 ? "0" + this.day : this.day), this.project.id).subscribe(
      res => {
        this.evidences = res;
      }
    );
  }

  async printEvidences(id: number, con: string) {
    this.evidenceConcept = con;
    this.cLogbookService.printEvidences(id).subscribe(
      res => {
        this.printevid = res;

        Swal.fire({
          title: 'Ingresa un nombre para el archivo',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Generar',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            let printContents, popupWin;
            printContents = document.getElementById('evidencesDoc')!.innerHTML;
            popupWin = window.open('', '', '');
            popupWin!.document.open();
            popupWin!.document.write(`
          <html>
            <head>
              <title>${result.value}</title>
              <style>
              @media print{@page {size: landscape}}
              * {
                margin: 0;
                padding: 0;
                -webkit-print-color-adjust: exact;
                color-adjust: exact;
              }
              body {
                font-family: Roboto, "Helvetica Neue", sans-serif;
              }
              .controlPDF {
                width: 100%;
                border-collapse: collapse;
              }

              .controlPDF td {
                border: 1px solid black;
                padding: 5px;
              }

              .signArea {
                width: 100%;
                border-collapse: collapse;
              }

              .signArea td {
                border: 1px solid black;
                page-break-inside: avoid;
              }
              </style>
            </head>
        <body onload="window.print();window.close()">${printContents}</body>
          </html>`
            );
            popupWin!.document.close();
          }
        })
      }
    );
  }

  async printLogbookUnique(id: number) {
    this.cLogbookService.printLogbookUnique(id).subscribe(
      res => {
        this.printlog = res;
        Swal.fire({
          title: 'Ingresa un nombre para el archivo',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Generar',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            let printContents, popupWin;
            printContents = document.getElementById('logbookDoc')!.innerHTML;
            popupWin = window.open('', '', '');
            popupWin!.document.open();
            popupWin!.document.write(`
          <html>
            <head>
              <title>${result.value}</title>
              <style>
              @media print{@page {size: landscape}}
              * {
                margin: 0;
                padding: 0;
                -webkit-print-color-adjust: exact;
                color-adjust: exact;
              }
              body {
                font-family: Roboto, "Helvetica Neue", sans-serif;
              }
              .controlPDF {
                width: 100%;
                border-collapse: collapse;
              }

              .controlPDF td {
                border: 1px solid black;
                padding: 5px;
              }

              .signArea {
                width: 100%;
                border-collapse: collapse;
              }

              .signArea td {
                border: 1px solid black;
                page-break-inside: avoid;
              }
              </style>
            </head>
        <body onload="window.print();window.close()">${printContents}</body>
          </html>`
            );
            popupWin!.document.close();
          }
        })
      }
    );
  }

  async printLogbookAllDay() {
    this.cLogbookService.getLogbookPerDay(this.year + "-" + this.month + "-" + (this.day < 10 ? "0" + this.day : this.day), this.project.id).subscribe(
      res => {
        this.printlog = res;

        Swal.fire({
          title: 'Ingresa un nombre para el archivo',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Generar',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            let printContents, popupWin;
            printContents = document.getElementById('logbookDoc')!.innerHTML;
            popupWin = window.open('', '', '');
            popupWin!.document.open();
            popupWin!.document.write(`
          <html>
            <head>
              <title>${result.value}</title>
              <style>
              @media print{@page {size: landscape}}
              * {
                margin: 0;
                padding: 0;
                -webkit-print-color-adjust: exact;
                color-adjust: exact;
              }
              body {
                font-family: Roboto, "Helvetica Neue", sans-serif;
              }
              .controlPDF {
                width: 100%;
                border-collapse: collapse;
              }

              .controlPDF td {
                border: 1px solid black;
                padding: 5px;
              }

              .signArea {
                width: 100%;
                border-collapse: collapse;
              }

              .signArea td {
                border: 1px solid black;
                page-break-inside: avoid;
              }
              </style>
            </head>
        <body onload="window.print();window.close()">${printContents}</body>
          </html>`
            );
            popupWin!.document.close();
          }
        })
      }
    );
  }

}
