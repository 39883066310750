import { Component, ComponentFactoryResolver, Inject, OnInit, ViewChild } from '@angular/core';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, Form } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ControComissionsService } from 'src/app/services/contro-comissions.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import decode from 'jwt-decode';
import { MatInput } from '@angular/material/input';


@Component({
  selector: 'app-dialog-budget-register',
  templateUrl: './dialog-budget-register.component.html',
  styleUrls: ['./dialog-budget-register.component.scss']
})
export class DialogBudgetRegisterComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DialogBudgetRegisterComponent>,
    private router: Router,
    private _formBuilder: FormBuilder,
    private controlComissions: ControComissionsService,
    public sweet: SweetAlertComponent,
    @Inject(MAT_DIALOG_DATA) data: ControComissionsService
  ) { }

  BudgetFormGroup: FormGroup = this._formBuilder.group({
    registrationDate: [, Validators.required],
    quantityPVP: [null,],
    quantityPXL: [null,],
    quantityPAL: [null,],
    quantityPCL: [null,],
    quantityPCB: [null,],
    quantityPLI: [null,],
    quantityPAM: [null,],
    quantityPGA: [null,],
    quantityPBE: [null,],
    quantityPBS: [null,],
    quantityPVL: [null,],
    userRegistration: [this.getUser(), Validators.required]
  });

  decode: any = [];


  ngOnInit(): void {
    this.getUser();
  }


  @ViewChild('myElement') firstItem: MatInput | undefined;

//Inserta los presupuestos de comisiones
  async insertBudgetCommission() {
   // console.log(this.BudgetFormGroup.value)

    if (this.BudgetFormGroup.invalid) {
      this.sweet.AlertTime("center", "warning", "Ingrese almenos la fecha para poder contiuar", true,100000);
      document.getElementById('fechaInput')?.focus()
      return;
    } else {
      Swal.fire({
        title: '¿Quieres guardar tus cambios ahora?',
        text: "¡Estas por asignar el presupuesto!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Guardar'
      }).then((result) => {
        if (result.isConfirmed) {
          this.controlComissions.insertBudgetCommission(this.BudgetFormGroup.value).subscribe(
            res => {
              this.sweet.AlertTime("center", "success", "Presupuesto registrado correctamente", false ,2000);
              this.dialogRef.close();
            }

          );

        }
      })
    }
  }

  //Obtiene el usuario que esta logueado 
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode['cliente']
  }

}
