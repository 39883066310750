<!-- Si no hay notas de remisión registradas para una requisición se visualiza el siguiente mensaje -->
<app-loader [loader]="loader" *ngIf="loading==1"></app-loader>

<section class="emptyRes" *ngIf="invoiceList.length==0">
    <h3 class="message">No hay facturas registradas para esta nota de remisión</h3>
    <mat-icon>error</mat-icon>
</section>

<!-- Si la cantidad de notas registradas es mayor a 0 se visualiza el siguiente bloque de código -->
<mat-dialog-content>
    <div *ngIf="invoiceList.length!=0">
        <h3 style="text-align: center; color: var(--M19Primary);"><b>Facturas</b></h3>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let i of invoiceList" hideToggle>
                <mat-expansion-panel-header style="height: fit-content; padding: 10px 5px 10px 5px;"
                    (click)="getInvoiceInfo(i.deliveryfolio); getInvoiceProducts(i.deliveryfolio);">
                    <mat-panel-title><span><b>
                                Número de factura: {{i.invoicenumber}}
                            </b></span></mat-panel-title>
                    <mat-panel-description><span><b>
                                Con fecha del día {{pipe.transform(i.invoicedate, 'longDate')}}
                            </b></span></mat-panel-description>
                </mat-expansion-panel-header>
                <br>
                <div style="display: flex; justify-content: space-between;">
                    <h6><b style="color: var(--M19Primary);">Registrada el día</b> {{pipe.transform(invoiceInfo.creationdate, 'longDate')}}</h6>
                    <h6><b style="color: var(--M19Primary);">Proveedor:</b> {{invoiceInfo.suppliername}}</h6>
                    <h6><b style="color: var(--M19Primary);">Teléfono:</b> {{invoiceInfo.phone}}</h6>
                </div>

                <table class="m19Table">
                    <thead>
                        <tr>
                            <!-- Se definen los encabezados de cada columna de la tabla -->
                            <th scope="col">#</th>
                            <th scope="col">Nombre de producto</th>
                            <th style="text-align: center;">Cantidad</th>
                            <th style="text-align: center;">Unidad</th>
                            <th style="text-align: center;">Costo Unitario</th>
                            <th style="text-align: center;">Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let deliveryP of invoiceProducts; index as i">
                            <td>{{i+1}}</td>
                            <td>
                                {{deliveryP.productname}}</td>
                            <td style="text-align: center;">
                                {{deliveryP.amount}}</td>
                            <td style="text-align: center;">
                                {{deliveryP.unit}}</td>
                            <td style="text-align: center;">
                                {{deliveryP.unitcost | currency:'CAD' : '$ '}}</td>
                            <td style="text-align: center;">
                                {{(deliveryP.unitcost * deliveryP.amount).toFixed(2) | currency:'CAD' : '$ '}}</td>
                        </tr>
                    </tbody>
                </table>

                <div style="display: flex; justify-content: space-between;">
                    <h6><b style="color: var(--M19Primary);">Total:</b> {{invoiceInfo.total | currency:'CAD' : '$ '}}</h6>
                    <h6><b style="color: var(--M19Primary);">IVA:</b> {{invoiceInfo.iva | currency:'CAD' : '$ '}}</h6>
                    <h6><b style="color: var(--M19Primary);">Subtotal:</b> {{invoiceInfo.subtotal | currency:'CAD' : '$ '}}</h6>
                </div>

                <div style="display: flex; justify-content: flex-end; gap: 30px;">
                    <div class="actionBtn" (click)="editInvoice(invoiceInfo.deliveryfolio)"
                        style="display:flex; flex-direction:column; align-items:center; cursor:pointer;">
                        <button title="Factura" mat-icon-button>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <mat-label>Modificar</mat-label>
                    </div>
                    <div class="actionBtn" (click)="deleteInvoice()"
                        style="display:flex; flex-direction:column; align-items:center; cursor:pointer;">
                        <button title="Factura" mat-icon-button>
                            <mat-icon>delete</mat-icon>
                        </button>
                        <mat-label>Eliminar</mat-label>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</mat-dialog-content>