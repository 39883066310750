import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';              //Herramientas para grupo de formulario
import decode from 'jwt-decode';                                                  //Codifica/decodifica datos enviados/recibidos cliente-servidor (En este archivo se utiliza para recuperar la cuenta del usuario logeado)
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import Swal from 'sweetalert2';


import { ProjectService } from 'src/app/services/project.service';                //Servicio projects para recuperar lo relacionado a la información de la tabla de proyectos
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';  //Servicio projectDetails para recuperar información relacionada a la relación proyecto-módulo (Módulos que existen y pertenecientes a diferentes proyectos)
import { ProductcatalogService } from 'src/app/services/productcatalog.service';  //Servicio ProductCatalog para recuperar información relacionada al catálogo de productos
import { DepartureService } from 'src/app/services/departure.service';
import { ConsumerTokenService } from 'src/app/services/consumer-token.service';


@Component({
  selector: 'app-consumer-token-register',
  templateUrl: './consumer-token-register.component.html',
  styleUrls: ['./consumer-token-register.component.scss']
})
export class ConsumerTokenRegisterComponent implements OnInit {
  decode: any = {};                   //Almacena el usuario logeado
  projects: any = [];                 //JSON consulta de la tabla projects (Contiene nombres e ID's de proyectos)
  projectDetailByID: any = [];        //JSON consulta de la tabla de projectDetail (Contiene relación torre-proyecto)
  productname: any = 'productname';   //Constante con el nombre de la columna de la tabla "product_catalog" para el ng-autocomplete del HTML5 "idproduct"
  products: any = [];                 //JSON consulta de la tabla productos (ID, descripción, etc)
  idProduct: any = [];                //Almacena el id del ultimo material que se seleccionó 
  descriptions: any = 'departurename' //Constante con el nombre de la columna de la tabla "departure" para el ng.autocomplete del html "fk_iddeparture"
  materiales: any = []                //Almacena la lista de materiales para la tabla
  fk_idConsumerToken: any = []        //Almacena el ID del vale de consumo que se está registrando
  flagSubmit: boolean = false;                    //Bandera para descativar botón y evitar registros duplicados

  constructor(
    public projectService: ProjectService,                //Variable que contiene el servicio de los proyectos
    public projectDetailService: ProjectdetailsService,   //Variable que contiene el servicio de detalles de proyecto
    public ProductcatalogService: ProductcatalogService,  //Variable que contiene el servicio de los materiales del catálogo
    public DepartureService: DepartureService,            //Variable que contiene el servicio de las partidas presupuestales
    public sweet: SweetAlertComponent,                    //Componente sweetAlert para el uso de alertas
    public ConsumerTokenService: ConsumerTokenService,    //Variable que contiene el servicio para el manejo de partidas presupuestales
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initProducts();
    this.selectProjects();
  }
  consumerTokenFormGroup: FormGroup = this._formBuilder.group({     //Formulario principal
    idProject: [, Validators.required],
    fk_idprojectDet: [, Validators.required],
    folio: [],
    requiredArea: [, Validators.required],
    tokenDate: [, Validators.required],
    idproduct: [],
    amount: [],
    unit: [],
    userEmailCre: [this.getUser()]
  });


  async selectProjects() { //Recupera la lista de proyectos de la tabla projects
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      },
      err => console.log(err)
    );
  };

  async selectProject(id: number) {
    this.consumerTokenFormGroup.controls['fk_idprojectDet'].setValue('');
    this.projectDetailService.selectDetailsById(id).subscribe(
      res => {
        this.projectDetailByID = res;
      }
    );
  };

  async initProducts() {
    this.ProductcatalogService.getNamesOfProducts().subscribe(
      res => {
        this.products = res;
      }
    );
  }

  async materialSeleccionado(event: any) {//Recupera el item seleccionado en el ngautocomplete "idproduct", obtiene el ID y lo almacena en variable
    this.idProduct = event.id;
    this.ProductcatalogService.selectProduct(parseInt(event.productname.split('~')[0])).subscribe(
      res => {
        this.consumerTokenFormGroup.controls['unit'].setValue(res.unit);
      }
    )
  }

  async insert() {
    if (!this.consumerTokenFormGroup.valid) {
      this.sweet.AlertTime("center", "error", "Datos del vale de consumo inválidos, por favor rellene toda la información del vale de consumo", true, 10000);
    } else {
      this.ConsumerTokenService.serverDate().subscribe(
        res => {
          console.log('Fecha del servidor: ' + res[0].fecha + '\nFecha del formulario: ' + this.consumerTokenFormGroup.controls['tokenDate'].value)
          if (Date.parse(this.consumerTokenFormGroup.controls['tokenDate'].value) <= Date.parse(res[0].fecha)) {
            this.ConsumerTokenService.folioValidate(this.consumerTokenFormGroup.controls['fk_idprojectDet'].value, this.consumerTokenFormGroup.controls['folio'].value).subscribe(
              res => {
                if (res[0].exist == 0) {
                  console.log('Vale de consumo validado no repetido');
                  if (this.materiales.length == 0) {
                    this.sweet.AlertTime("center", "error", "Asegúrese de que haya por lo menos un material consumido en la tabla", false, 2000);
                    return;
                  }

                  if ((parseInt(this.consumerTokenFormGroup.controls['folio'].value)) == 0) {
                    this.sweet.AlertTime("center", "error", "Ingrese un número de folio", false, 2000);
                    return;
                  }

                  if (this.consumerTokenFormGroup.invalid) {
                    return;
                  } else {
                    Swal.fire({
                      title: '¿Quieres guardar tus cambios ahora?',
                      text: "¡Este vale de consumo está por guardarse!, asegúrate de que todos los materiales consumidos se encuentren en la tabla",
                      icon: 'question',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      cancelButtonText: 'No, espera',
                      confirmButtonText: 'Sí, guárdala!'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.flagSubmit = true;
                        this.ConsumerTokenService.consumerTokenlastFolio().subscribe(
                          res => {
                            if (this.consumerTokenFormGroup.controls['folio'].value == "" || this.consumerTokenFormGroup.controls['folio'].value == null) {
                              this.consumerTokenFormGroup.controls['folio'].setValue(res[0].folio);
                            }
                            this.ConsumerTokenService.insertConsumerToken(this.consumerTokenFormGroup.value)
                              .subscribe(
                                res => {
                                  this.fk_idConsumerToken = res;

                                  this.insertProducts();
                                },
                                err => console.error(err)
                              );
                          }
                        );
                      }
                    })
                  }
                } else {
                  this.sweet.AlertTime("center", "error", "Este folio ya se encuentra registrado, verifique en 'consultar'", false, 2000);
                }
              }
            )
          } else {
            this.sweet.AlertTime("center", "error", "La fecha no puede ser mayor que el día de hoy", false, 2000);
            return;
          }
        }
      )
    }
  };
  async insertProducts() {
    for (let p of this.materiales) {
      let prod = p.split("~");
      this.ConsumerTokenService.insertProductConsumerToken(this.fk_idConsumerToken, prod[1], prod[3])
        .subscribe(
          res => {
            this.sweet.AlertTime("center", "success", "Vale de consumo registrado con éxito con el folio: " + this.consumerTokenFormGroup.controls['folio'].value, true, 10000);
            this.setRestartFields(1);
          },
          err => console.error(err)
        );
    }
  }
  async deleteMaterials(i: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡Estás a punto de eliminar un producto de la lista",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, elimínalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        let materialsT = Array();
        let indice = 0;
        for (const mat of this.materiales) {
          if (indice !== i) {
            materialsT.push(mat);
          }
          indice++;
        }
        this.materiales = materialsT;
        Swal.fire(
          'Eliminado!',
          'Eliminado de la tabla con éxito',
          'success'
        )
      }
    })
  }

  async addMaterial() {
    if (this.consumerTokenFormGroup.controls['idproduct'].value == null || this.consumerTokenFormGroup.controls['idproduct'].value == ''
      || this.consumerTokenFormGroup.controls['amount'].value == null || this.consumerTokenFormGroup.controls['amount'].value == '') {
      this.sweet.AlertTime("center", "error", "Por favor complete los campos de producto y cantidad ingresando un número válido.", false, 2000);
    } else {
      let addMaterial = this.consumerTokenFormGroup.value;
      if (addMaterial !== '') {
        let name = addMaterial.idproduct.productname.split("~");
        console.log(addMaterial.idproduct.productname);
        this.materiales.push('PARAMETRO DE CONTROL' + '~' + name[0] + '~' + name[1] + '~' + addMaterial.amount + '~' + addMaterial.unit + '~');
      }
      this.setRestartFields(0);
    }
  }
  setRestartFields(option: number) {//Ingresa un 0 para reiniciar solo los campos del producto o un 1 para reiniciar todos los campos
    if (option == 0) {
      this.consumerTokenFormGroup.controls['idproduct'].setValue("");
      this.consumerTokenFormGroup.controls['amount'].setValue("");
      this.consumerTokenFormGroup.controls['unit'].setValue("");
    } else if (option == 1) {
      this.consumerTokenFormGroup.controls['idproduct'].setValue("");
      this.consumerTokenFormGroup.controls['amount'].setValue("");
      this.consumerTokenFormGroup.controls['unit'].setValue("");
      this.consumerTokenFormGroup.controls['fk_idprojectDet'].setValue(null);
      this.consumerTokenFormGroup.controls['idProject'].setValue(null);
      this.consumerTokenFormGroup.controls['folio'].setValue("");
      this.consumerTokenFormGroup.controls['tokenDate'].setValue(null);
      this.consumerTokenFormGroup.controls['requiredArea'].setValue("");
      this.projectDetailByID = [];
      this.idProduct = [];
      this.fk_idConsumerToken = [];
      this.materiales = [];
    }
    else {
      let excepcion = 0 / 0;
    }
  }

  getUser() {
    this.decode = decode(localStorage.getItem("token") || "");
    return this.decode.email;
  };

  //=================================VALIDACIONES=================================//
  folioValidate(fk_idprojectDet: number, folio: string): any {

  }


};
