<mat-dialog-content>
    <h3>Notas de {{codeProperty}}</h3>

    <div class="notesContainer">
        <div *ngFor="let n of agreement" class="noteAgreement">
            <span><b>El día {{pipe.transform(n.registrationDate,'longDate')}}</b></span>
            <span><b style="color: blueviolet;">{{n.responsable}}</b> dijo:</span>
            <span style="font-style: italic;">"{{n.noteDes}}"</span>
        </div>
        <div *ngFor="let note of notes" class="noteCard">
            <span><b>El día {{pipe.transform(note.registrationDate,'longDate')}}</b></span>
            <span><b style="color: var(--M19Primary);">{{note.responsable}}</b> dijo:</span>
            <span style="font-style: italic;">"{{note.noteDes}}"</span>
        </div>
    </div>
</mat-dialog-content>