<mat-dialog-content>
    <div class="genRecession">
        <h4 style="text-align:center"><b>Solicitud de rescisión</b> | <span style="color: var(--M19Primary); font-weight: bold;">Total:</span> {{totalDevolution | currency:'CAD':'$ '}}</h4>

        <div class="genRecession__userInfo">
            <div class="genRecession__userInfo__userElement">
                <mat-icon class="genRecession__userInfo__userElement__iconSpace">calendar_month</mat-icon>
                <span>{{today | date: 'dd-MM-yyyy'}}</span>
            </div>
            <div class="genRecession__userInfo__userElement">
                <mat-icon class="genRecession__userInfo__userElement__iconSpace">account_box</mat-icon>
                <span>{{customer}}</span>
            </div>
            <div class="genRecession__userInfo__userElement">
                <mat-icon
                    [ngClass]="{'genRecession__userInfo__userElement__iconSpace': voterKey != null, 'genRecession__userInfo__userElement__iconSpace--error':voterKey == null}">
                    badge
                </mat-icon>
                <span>{{voterKey != null?voterKey:'Sin clave de elector'}}</span>
            </div>
            <div class="genRecession__userInfo__userElement">
                <mat-icon
                    [ngClass]="{'genRecession__userInfo__userElement__iconSpace': mobile != null, 'genRecession__userInfo__userElement__iconSpace--error':mobile == null}">
                    call</mat-icon>
                <span>{{mobile != null? mobile : 'Sin teléfono'}}</span>
            </div>
        </div>

        <div class="genRecession__container">
            <form class="genRecession__container__form" [formGroup]="propertiesFormGroup">
                <div class="genRecession__container__form__row">
                    <mat-form-field appearance="outline">
                        <mat-label>Motivo de rescisión</mat-label>
                        <mat-select formControlName="fkrecessionReason" (valueChange)="selectReason($event)" required>
                            <mat-option *ngFor="let reason of reasons" [value]="reason">
                                {{reason.description}}
                            </mat-option>
                        </mat-select>
                        <mat-icon matSuffix>map</mat-icon>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Unidad</mat-label>
                        <mat-select formControlName="fkproperty" (valueChange)="selectProperty($event)" required>
                            <mat-option *ngFor="let property of properties" [value]="property">
                                {{property.name + "-" + property.fk_property}}
                            </mat-option>
                        </mat-select>
                        <mat-icon matSuffix>map</mat-icon>
                    </mat-form-field>
                </div>

                <div class="genRecession__container__form__row">
                    <mat-form-field appearance="outline">
                        <mat-label>Valor del inmueble</mat-label>
                        <input matInput type="number" formControlName="totalValue" readonly required>
                        <mat-icon matSuffix>numbers</mat-icon>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Devolución</mat-label>
                        <input matInput type="number" formControlName="devolutionAmount" min="1" required>
                        <mat-icon matSuffix>numbers</mat-icon>
                    </mat-form-field>
                </div>

                <mat-form-field appearance="outline" [hidden]="true">
                    <mat-label>Coordinador</mat-label>
                    <input matInput type="text" formControlName="coordinator" readonly required>
                    <mat-icon matSuffix>question_mark</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline" [hidden]="true">
                    <mat-label>Cliente</mat-label>
                    <input matInput type="text" formControlName="customer" readonly required>
                    <mat-icon matSuffix>question_mark</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline" [hidden]="true">
                    <mat-label>Fecha de venta</mat-label>
                    <input matInput type="date" formControlName="fechaDato" readonly required>
                    <mat-icon matSuffix>question_mark</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline" [hidden]="true">
                    <mat-label>Último pago</mat-label>
                    <input matInput type="date" formControlName="lastPay" readonly required>
                    <mat-icon matSuffix>question_mark</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline" [hidden]="true">
                    <mat-label>Abonado</mat-label>
                    <input matInput type="number" formControlName="paid" readonly required>
                    <mat-icon matSuffix>question_mark</mat-icon>
                </mat-form-field>

                <button class="m19AltBtn" (click)="addProperty()">Añadir a la lista
                    <mat-icon>add</mat-icon>
                </button>

                <div class="genRecession__save">
                    <button class="m19Btn" [disabled]="propList.length == 0 || recessionReason == 'Sin seleccionar'" (click)="print()">GENERAR SOLICITUD</button>
                </div>
            </form>

            <div class="genRecession__container__list">
                <table class="m19Table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>UNIDAD</th>
                            <th>DEVOLUCIÓN</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let p of propList; index as i">
                            <td>{{i+1}}</td>
                            <td>{{p.fkproperty}}</td>
                            <td>{{p.devolutionAmount | currency:'CAD':'$ '}}</td>
                            <td>
                                <mat-icon class="actionBtn" (click)="propList.splice(i, 1); totalDevolution = totalDevolution - p.devolutionAmount;">delete</mat-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!--SOLICITUD DE RESCISIÓN-->
        <div id="recessionRequest" class="requestContainer" [hidden]="true">
            <div class="recessionHeader">
                <span><b>SOLICITUD DE RESCISIÓN DE CONTRATO</b></span>
                <img src="../../../../assets/iconoM.png" alt="M19 Icono">
            </div>
            <div class="recessionData">
                <span>FECHA: {{today | date: 'dd-MM-yyyy'}}</span>
            </div>
            <div class="customerData">
                <h5>DATOS DEL CLIENTE</h5>
                <div class="personalInfo">
                    <span>NOMBRE: {{customer}}</span>
                    <span>TELEFONO: {{mobile}}</span>
                </div>
            </div>
            <div class="propertiesContent">
                <h5>DATOS DEL INMUEBLE</h5>
                <div class="unitsContainer">
                    <div *ngFor="let p of propList; index as i;" class="propertyUnit">
                        <span class="identify"><b>INMUEBLE {{i+1}}</b></span>
                        <span class="reference"><b>{{p.fkproperty.substring(2,3) == 'M'? 'MANZANA:' : 'TORRE:'}}</b> {{p.fkproperty.substring(3,5)}} <b>{{p.fkproperty.substring(5,6) == 'L'? 'LOTE:' : 'DEPARTAMENTO:'}}</b> {{p.fkproperty.substring(6,8)}}</span>
                        <span class="financial"><b>VALOR TOTAL: </b> {{p.totalValue | currency:'CAD' : '$ '}} <b>ÚLTIMO PAGO: </b> {{p.lastPay == 'Sin registro'? "Sin pago": p.lastPay | date : 'dd-MM-yyyy'}}</span>
                        <span class="balance"><b>ABONADO: </b> {{p.paid | currency:'CAD' : '$ '}} <b>DEVOLUCIÓN: </b> {{p.devolutionAmount | currency:'CAD' : '$ '}}</span>
                    </div>
                </div>
            </div>
            <div class="reasonContent">
                <span>{{recessionReason=='Voluntaria'?'RESCISIÓN ' + recessionReason.toUpperCase(): 'RESCISIÓN POR ' + recessionReason.toUpperCase()}}</span>
            </div>

            <div class="signamentContent">
                <p>CARLOS BETANCOURT RODRIGUEZ <br> DIRECCIÓN</p>
                <p>{{customer}} <br> CLIENTE</p>
                <p>JESUS ALBERTO BAUTISTA GARCIA <br> DPTO. COBRANZA</p>
                <p>IVETH TADEO DENA <br> ADMINISTRACIÓN</p>
                <p>DIEGO ASAEL RUBIO HERNANDEZ <br> DPTO. SISTEMAS</p>
            </div>
        </div>
    </div>
</mat-dialog-content>