//=========================================================================================================
//Importación de componentes necesarios para esta pantalla
//=========================================================================================================
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { RecessionsService } from 'src/app/services/recessions.service';
import { LogosService } from 'src/app/services/logos.service';

import Swal from 'sweetalert2';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';
import { Router } from '@angular/router';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';

@Component({
  selector: 'app-block-sale-dialog',
  templateUrl: './block-sale-dialog.component.html',
  styleUrls: ['./block-sale-dialog.component.scss']
})
export class BlockSaleDialogComponent implements OnInit {

  customerInfo: any = {project: "Cargando"};
  projectLogo: any;

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  constructor(
    private recessionService: RecessionsService,
    private logoService: LogosService,
    private router: Router,
    private sweet: SweetAlertComponent,

    private dialogRef: MatDialogRef<BlockSaleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.recessionService.getCustomerInfo(this.data.inmueble).subscribe(
      res => {
        this.customerInfo = res;
        console.log(this.customerInfo.project)

        this.logoService.selectIcon(this.customerInfo.idProject).subscribe(
          logo => {
            this.projectLogo = logo.UrlLogo;
          }
        );
      }
    );
  }

  blockUser(property: String, reason: number) {
    Swal.fire({
      title: `¿Quieres bloquear la unidad ${property}?`,
      text: "¡Esta unidad se marcará como bloqueada y no aparecerá en los reportes de cartera, en estado de cuenta ni en la interfaz de registro de pagos!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, espera',
      confirmButtonText: 'Sí, bloquealo!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.recessionService.blockUser(property, reason, this.getUser()).subscribe(
          res => {
            this.sweet.AlertTime("center", "success", `Unidad ${property} bloqueada.`, false, 2000);

            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['graficos']));

            this.dialogRef.close();
          }
        );
      }
    })
  }

  //==========================================================================================================
  //Recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.fkuser;
  }
}
