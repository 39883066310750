<p>budgetupdatepiece works!</p>
<!-- Modificación del presupuesto -->
<mat-dialog-content>
    <h1 style="text-align: center; border-bottom: solid rgb(117, 0, 0) 2px; margin-bottom: 30px;">INFORMACIÓN GENERAL DE
        PRESUPUESTO</h1>
    <form [formGroup]="budgetFormGroup" (ngSubmit)="updateBudget()">
        <div style="display: flex; gap: 30px;">
            <!-- Componentes que se pueden modificar de presupuestos -->
            <mat-form-field appearance="outline" class="col-md-3" style="width: 1300px;">
                <mat-label>Modulo</mat-label>
                <mat-select formControlName="idprojectdet" required>
                    <mat-option *ngFor="let tower of towers" [value]="tower.idprojectdet" (input)="compareValues()">
                        Modulo {{tower.towernumber}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Fecha de presupuesto</mat-label>
                <input matInput type="date" formControlName="budgetdate" (input)="compareValues()" required>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Analista</mat-label>
                <input matInput type="text" formControlName="analyst" (input)="compareValues()" required>
            </mat-form-field>

            <mat-form-field >
                <mat-label>Razón de modificación</mat-label>
                <input matInput type="text" formControlName="reason" >
            </mat-form-field>
        </div>


        <div class="col-12 text-right mt-3">
            <mat-form-field appearance="outline" class="col-md-3" style="width: 110px;">
                <mat-label>Total</mat-label>
                <input matInput type="number" id="costo" formControlName="total" min="1"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57  || event.charCode == 46)"
                    readonly>
            </mat-form-field>
            <button class="btn btn-m19" type="submit" [disabled]="!budgetFormGroup.valid">
                <mat-icon>save</mat-icon>
            </button>
        </div>

        <br>
        <div style="display: flex; flex-direction: column;">
            <div style="display:flex; flex-direction: column;">
                <div style="display: flex;">
                    <mat-form-field appearance="outline" class="col-md-4" [hidden]="true">
                        <mat-label>iddeparture</mat-label>
                        <input matInput type="number" formControlName="iddeparture" readonly>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-4" [hidden]="true">
                        <mat-label>departurename</mat-label>
                        <input matInput type="text" formControlName="departurename" readonly>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-md-4" [hidden]="true">
                        <mat-label>departurenumber</mat-label>
                        <input matInput type="text" formControlName="departurenumber" readonly>
                    </mat-form-field>
                    <div class="ng-autocomplete">
                        <ng-autocomplete formControlName="departure" name="busquedaP" placeholder="id/Partida"
                            [data]="departures" [searchKeyword]="keywordP" [itemTemplate]="itemTemplateP"
                            (selected)='selectConcepts($event)' (selected)='selectDeparture($event)'
                            [notFoundTemplate]="notFoundTemplateP">
                        </ng-autocomplete>
                        <ng-template #itemTemplateP let-itemP>
                            <a [innerHTML]="itemP.departurename"></a>
                        </ng-template>
                        <ng-template #notFoundTemplateP let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>
                    </div>

                   

                    <!--<mat-form-field appearance="outline" class="col-md-4" [hidden]="true">
                        <mat-label>idpiecework</mat-label>
                        <input matInput type="number" formControlName="idpiecework" readonly>
                    </mat-form-field>
                    <div class="ng-autocomplete">
                        <ng-autocomplete formControlName="typepw" name="busquedaPi" placeholder="Destajo"
                            [data]="pieceworks" [searchKeyword]="keywordPi" (selected)='selectPiecework($event)'
                            [itemTemplate]="itemTemplatePi" [notFoundTemplate]="notFoundTemplatePi">
                        </ng-autocomplete>
                        <ng-template #itemTemplatePi let-itemPi>
                            <a [innerHTML]="itemPi.typepw"></a>
                        </ng-template>
                        <ng-template #notFoundTemplatePi let-notFound>
                            <div>No hay coincidencias de destajos</div>
                        </ng-template>
                    </div> -->
                </div>

                <br>

                <div style="display: flex;">
                    <mat-form-field appearance="outline" class="col-md-4" style="max-width: 110px;">
                        <mat-label>Unidad</mat-label>
                        <input matInput type="text" formControlName="unit" readonly>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-3" style="width: 200px;">
                        <mat-label>Costo</mat-label>
                        <input matInput type="number" id="costo" formControlName="unitcost" min="1"
                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57  || event.charCode == 46)"
                            (input)="recalculateTotal()">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-4" style="max-width: 110px;">
                        <mat-label>Cantidad</mat-label>
                        <input matInput type="number" formControlName="amount" min="1"
                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)"
                            (input)="recalculateTotal()">
                    </mat-form-field>

                    <div style="display: inline; margin-right:50px;">
                        <mat-form-field appearance="outline" class="col-md-4" style="max-width: 110px;">
                            <mat-label>Subtotal</mat-label>
                            <input matInput type="number" formControlName="totalp" readonly>
                        </mat-form-field>
                    </div>
            <!-- Botón para agregar un material al presupuesto -->
                    <div (click)="addMaterial()" class="addMaterial">
                        <mat-icon>arrow_downward</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <!-- Tabla que muestr la información de los presupuestos -->
    <table class="table table-striped mt-3 animated fadeIn faster">
        <thead class="thead-m19">
            <tr class="tableHead">
                <th>Número de partida</th>
                <th>Nombre de destajo</th>
                <th>Cantidad</th>
                <th>Unidad</th>
                <th>Costo</th>
                <th>Total</th>
                <th>Editar</th>
                <th>Quitar</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let mat of materialList; index as i" class="tableHead">
                <td>{{mat.split("~")[0]}}</td>
                <td>{{mat.split("~")[2]}}</td>
                <td>{{mat.split("~")[3]}}</td>
                <td>{{mat.split("~")[4]}}</td>
                <td>{{mat.split("~")[5]}}</td>
                <td>{{mat.split("~")[6]}}</td>
                <td>
                    <!-- Botón para editar un presupuesto -->
                    <button class="btn btn-m19-table" title="Editar" mat-icon-button (click)="editProduct(i)"
                        (input)="recalculateTotal()">
                        <mat-icon>arrow_upward</mat-icon>
                    </button>
                </td>
                <td>
                    <!-- Botón para eliminar un presupuesto -->
                    <button class="btn btn-m19-table" title="Quitar" mat-icon-button (click)="deleteProduct(i)">
                        <mat-icon>remove</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</mat-dialog-content>
