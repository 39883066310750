<mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">

  <!--                                   PRIMER VENTANA                                              -->
  <mat-tab>
    <ng-template mat-tab-label>
      <span>Todas las comisiones</span>
      <mat-icon>update</mat-icon>
    </ng-template>

    <div class="container-fluid mt-3">
      <mat-card class="card-form">
        <mat-card-title class="col-4">

          <ng-autocomplete [data]="propertyList" [searchKeyword]="keyword" placeholder="Clave del inmueble"
            (selected)="onKeypressEventSearchByID($event)" [notFoundTemplate]="notFoundTemplate"
            [itemTemplate]="itemTemplate">
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.fk_property"></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>


        </mat-card-title>

        <mat-card-actions class="col-12 text-right ">
          <button type="button" class="btn btn-m19" (click)="getAllcomissions()">Obtener listado</button>
        </mat-card-actions>


        <mat-card-content class="justify-align-center">

          <table class="table table-striped mt-3 animated fadeIn faster">
            <thead class="thead-m19">
              <tr>
                <th scope="col" align="center">Folio</th>
                <th scope="col" align="center">inmueble</th>
                <th scope="col" align="center">Fecha de pago</th>
                <th scope="col" align="center">Concepto de pago</th>
                <th scope="col" align="center">Cantidad</th>
                <th scope="col" align="center">Motivo de modificacion</th>
                <th scope="col" align="center">Accion</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let comiss of comissions ">

                <th> {{comiss.folio}} </th>
                <th>{{comiss.fk_property}} </th>
                <td> {{comiss.paymentDate | date: 'dd/MM/yyyy'}} </td>
                <td>{{comiss.paymentConcept}} </td>
                <td>{{comiss.paymentAmount}} </td>
                <td>{{comiss.modificationNotes}} </td>
                <td>
                  <button class="btn btn-m19-table" mat-icon-button title="Editar"
                    (click)="updateConceptPayments(comiss.folio)">
                    <mat-icon>create</mat-icon>
                  </button>
                </td>

              </tr>
            </tbody>
          </table>

        </mat-card-content>

      </mat-card>

    </div>


  </mat-tab>

  <!--                                   SEGUNDA VENTANA                                              -->

  <mat-tab>

    <ng-template mat-tab-label>

      <span>Comisiones pagadas</span>

      <mat-icon> local_atm</mat-icon>
    </ng-template>

    <div class="container-fluid mt-3">
      <mat-card class="card-form">
        <mat-card-content class="justify-align-center">


          <h5 class="col-12 pb-2">Consulta de información</h5>
          <div style="display:flex; flex-direction:column; width:100%;">
            <div>
              <mat-form-field appearance="outline" class="col-md-3">
                <mat-label>proyectos</mat-label>
                <mat-select [(value)]="selectProjects" [(ngModel)]="opcionSeleccionadoP" required>
                  <mat-option *ngFor="let project of projects" [value]="project.nomenclature">
                    {{project.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!--   Aqui va el segundo select pero primero arregla lo que hiciste -->
              <mat-form-field appearance="outline" class="col-4">
                <mat-label>Vendedores</mat-label>
                <mat-select [(value)]="selectSellers" [(ngModel)]="opcionSeleccionadoE" required>
                  <mat-option *ngFor="let employee of employees" [value]="employee.idEmployee">
                    {{employee.idEmployee}} - {{employee.name}} {{employee.firstLastName}} {{employee.secondLastName}}
                  </mat-option>
                </mat-select>

              </mat-form-field>

              <div class="col-3" style="padding-left: 90%;">
                <button mat-raised-button style="background-color: #d33e3e; color:white"
                  (click)="mergePaymentsComissionOnProperties();">Buscar</button>
              </div>

            </div>


            <mat-card>
              <mat-card-content>

                <div>
                  <mat-card-content class="justify-align-center">



                    <table class="table table-striped mt-3 animated fadeIn faster"
                      style="text-align:center; height: 100; align-content: border= 1 ;">
                      <thead class="thead-m19">
                        <tr>
                          <th scope="col" align="center">inmueble</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Anticipo</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 1</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 2</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 3</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 4</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 5</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 6</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 7</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 8</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 9</th>
                          <!--    <th scope="col" align="center" style="transform: rotate(-45deg);">Total Pagos</th>  -->

                        </tr>
                      </thead>
                      <tbody>


                        <tr *ngFor="let row of dimensiones; let indice=index;">

                          <th>
                            <button mat-raised-button color="warn">
                              {{dimensiones[indice][0]}}
                            </button>
                          </th>

                          <td *ngFor="let value of row[1]; let indivalue=index">
                            <div *ngIf="value != 'NULL'">

                              {{value}}
                              <br>
                              <mat-icon title="Comision Pagada" style="color: rgb(39, 190, 6  );"> verified
                              </mat-icon>
                            </div>
                            <div *ngIf="value == 'NULL'">
                              <mat-icon title="Aun no se ha pagado la comision" style="color :rgb(231, 41, 12 ); ">
                                new_releases
                              </mat-icon>
                            </div>
                          </td>

                        </tr>
                      </tbody>
                    </table>

                  </mat-card-content>

                </div>

              </mat-card-content>
            </mat-card>



          </div>



        </mat-card-content>
      </mat-card>
    </div>

  </mat-tab>


  <!--                                   TERCERA VENTANA                                              -->

  <mat-tab>

    <ng-template mat-tab-label>

      <span>Pagos Clientes</span>

      <mat-icon> local_atm</mat-icon>
    </ng-template>

    <div class="container-fluid mt-3">
      <mat-card class="card-form">
        <mat-card-content class="justify-align-center">


          <h5 class="col-12 pb-2">Consulta de información</h5>
          <div style="display:flex; flex-direction:column; width:100%;">
            <div>
              <mat-form-field appearance="outline" class="col-md-3">
                <mat-label>proyectos</mat-label>
                <mat-select [(value)]="selectProjects" [(ngModel)]="opcionSeleccionadoP" required>
                  <mat-option *ngFor="let project of projects" [value]="project.nomenclature">
                    {{project.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!--   Aqui va el segundo select pero primero arregla lo que hiciste -->
              <mat-form-field appearance="outline" class="col-4">
                <mat-label>Vendedores</mat-label>
                <mat-select [(value)]="selectSellers" [(ngModel)]="opcionSeleccionadoE" required>
                  <mat-option *ngFor="let employee of employees" [value]="employee.idEmployee">
                    {{employee.idEmployee}} - {{employee.name}} {{employee.firstLastName}} {{employee.secondLastName}}
                  </mat-option>
                </mat-select>

              </mat-form-field>

              <div class="col-3" style="padding-left: 90%;">
                <button mat-raised-button style="background-color: #d33e3e; color:white"
                  (click)="mergePaymentsCustomerOnProperties();">Buscar</button>
              </div>

            </div>


            <mat-card>
              <mat-card-content>

                <div>
                  <mat-card-content class="justify-align-center">



                    <table class="table table-striped mt-3 animated fadeIn faster"
                      style="text-align:center; height: 100; align-content: border= 1 ;">
                      <thead class="thead-m19">
                        <tr>
                          <th scope="col" align="center">inmueble</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Anticipo</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 1</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 2</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 3</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 4</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 5</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 6</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 7</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 8</th>
                          <th scope="col" align="center" style="transform: rotate(-45deg);">Pago 9</th>
                          <!--   <th scope="col" align="center" style="transform: rotate(-45deg);">Total Pagos</th> -->

                        </tr>
                      </thead>
                      <tbody>


                        <tr *ngFor="let row of dimensioness; let indice=index;">

                          <th>
                            <button mat-raised-button color="warn">
                              {{dimensioness[indice][0]}}
                            </button>
                          </th>

                          <td *ngFor="let value of row[1]; let indivalue=index">
                            <div *ngIf="value != 'NULL'">

                              {{value}}
                              <br>
                              <mat-icon title="Comision Pagada" style="color: rgb(39, 190, 6  );"> verified
                              </mat-icon>
                            </div>
                            <div *ngIf="value == 'NULL'">
                              <mat-icon title="Aun no se ha pagado la comision" style="color :rgb(231, 41, 12 );">
                                new_releases
                              </mat-icon>
                            </div>
                          </td>

                        </tr>
                      </tbody>
                    </table>

                  </mat-card-content>

                </div>

              </mat-card-content>
            </mat-card>



          </div>



        </mat-card-content>
      </mat-card>
    </div>

  </mat-tab>

</mat-tab-group>