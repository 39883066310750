<div class="transferCont">
    <div class="transferCont__control">
        <span class="transferCont__control__searchBar">
            <div class="ng-autocomplete">
                <ng-autocomplete name="busqueda" placeholder="Clave de Rastreo" [data]="sal"
                    [searchKeyword]="keyword" (selected)="onKeypressEvent($event)" [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate">
                </ng-autocomplete>
                <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.name"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                    <div>Ningún cliente coincide con la busqueda</div>
                </ng-template>
            </div>
        </span>

        <span class="transferCont__control__filters">
            <h4>Filtros</h4>
        </span>

        <span class="transferCont__control__payDetail">
            <h4>Detalles</h4>
        </span>
    </div>

    <span class="transferCont__payList">
        
    </span>
</div>

<!-- JESUS ALBERTO JIMENEZ ARIAS -->