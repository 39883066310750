import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

//Importación para utilizar el modelo de requisición
import { requisition } from '../models/requisitions';

@Injectable({
  providedIn: 'root'
})
export class RequisitionService {

  constructor(private http: HttpClient) { }

  getRequisitionSKU(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/getRequisitionSKU/${id}`);
  }

  getInvoiceSKU(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/getInvoiceSKU/${id}`);
  }
  
  //Consultar informacion general de las requisiciones
  getRegisterData(email:any): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/getRegisterData/`+email);
  }

  //Consultar informacion general de las requisiciones
  selectRequisitionInfo(id:any): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/getInfo/`+id);
  }

  //Consultar todas las requisiciones
  selectRequisitions(): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/getAll`);
  }

  getReqWithFilter(status: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/getReqWithFilter/${status}`);
  }

  getReqListByModule(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/getReqListByModule/${id}`);
  }

  selectRequisitionByProject(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/getRequisitionByProject/${id}`);
  }

  selectRequisitionByModule(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/getRequisitionByModule/${id}`);
  }

  getDeparturesFromRequisition(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/getDeparturesFromRequisition/${id}`);
  }

  getProductsFromDeparture(idr: number, idd: number, idc: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/getProductsFromDeparture/${idr}/${idd}/${idc}`);
  }

  getRequisitionsToPrint(idreq: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/getRequisitionToPrint/${idreq}`);
  }

  selectRequisitionWithPiece(): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/getRequisitionWithPiece`);
  }

  //Consultar requisicion por número de requisición
  selectRequisitionByNum(num:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/searchbynum/`+num);
  }

  //Consultar requisicion por ID
  selectRequisitionById(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/searchbyid/`+id);
  }

  //Consulta requisiciones sin nota de remisión
  selectRequisitionNoDelivery(): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/getRequisitionNoDelivery`);
  }

  //Registrar requisiciones
  insertRequisition(req:requisition): Observable<any> {
    return this.http.post(`${environment.URLServer}/requisitions/insert`,req);
  }

  //Consultar proyecto por ID
  selectProjectID(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/project/`+id);
  }

  getRequisitionNoDelivery(): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/getRequisitionNoDelivery`);
  }

  updateRequisition(req: requisition): Observable<any> {
    return this.http.put(`${environment.URLServer}/requisitions/updateRequisition`,req);
  }

  //Consultar proyecto por ID
  deleteRequisition(id:number): Observable<any> {
    return this.http.delete(`${environment.URLServer}/requisitions/deleterequisition/`+id);
  }

  //Aprobar requisición
  approveRequisition(id:number): Observable<any> {
    return this.http.put(`${environment.URLServer}/requisitions/approveRequisition/${id}`,[]);
  }

  //Rechazar requisición
  rejectRequisition(id:number): Observable<any> {
    return this.http.put(`${environment.URLServer}/requisitions/rejectRequisition/${id}`,[]);
  }

  //Aprobar requisición de destajos
  approveRequiPiece(id:number): Observable<any> {
    return this.http.put(`${environment.URLServer}/requisitions/approveRequiPiece/${id}`,[]);
  }

  //Rechazar requisición de destajos
  rejectRequiPiece(id:number): Observable<any> {
    return this.http.put(`${environment.URLServer}/requisitions/rejectRequiPiece/${id}`,[]);
  }

  //Para filtros de insumos
  //Aprobado
  selectApproved(): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/selectApproved`);
  }

  //Rechazado
  selectReject(): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/selectReject`);
  }

  //Espera
  selectAwait(): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/selectAwait`);
  }

  //Para filtros de destajos
  //Aprobado
  selectApprovedPiece(): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/selectApprovedPiece`);
  }

  //Rechazado
  selectRejectPiece(): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/selectRejectPiece`);
  }

  //Espera
  selectAwaitPiece(): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/selectAwaitPiece`);
  }

  //REPORTES

  //FACTURA
  selectTotalInvoiceByModule(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/getTotalInvoiceByModule/${id}`);
  }

  getTotalInvoiceByProject(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/requisitions/getTotalInvoiceByProject/${id}`);
  }
}