<h1>{{data.idUser}}. {{data.name}} {{data.firstLastName}} {{data.secondLastName}}</h1>

<mat-dialog-content>
    <table class="table table-striped mt-3 animated fadeIn faster">
        <tr>
            <th>CURP</th>
            <td>{{data.CURP}}</td>
        </tr>
        <tr>
            <th>Clave de elector</th>
            <td>{{data.voterKey}}</td>
        </tr>
        <tr *ngIf="data.RFC != null">
            <th>RFC</th>
            <td>{{data.RFC}}</td>
        </tr>
        <tr>
            <th>Celular</th>
            <td>{{data.mobile}}</td>
        </tr>
        <tr *ngIf="data.email != null">
            <th>Correo electrónico</th>
            <td>{{data.email}}</td>
        </tr>
        <tr *ngIf="data.birthDate != null">
            <th>Fecha de nacimiento</th>
            <td>{{data.birthDate | date:'dd-MMM-yyyy'}}</td>
        </tr>
        <tr>
            <th>Lugar de nacimiento</th>
            <td>{{data.birthPlace}}</td>
        </tr>
        <tr>
            <th>Nacionalidad</th>
            <td>{{data.nationality}}</td>
        </tr>
        <tr>
            <th>Ocupación</th>
            <td>{{data.occupation}}</td>
        </tr>
        <tr>
            <th>Estado civil</th>
            <td>{{data.civilStatus}}</td>
        </tr>
        <tr>
            <th>Dirección</th>
            <td>{{data.address}}</td>
        </tr>
        <tr>
            <th>Localidad</th>
            <td>{{data.localidad}}</td>
        </tr>
        <tr>
            <th>Municipio</th>
            <td>{{data.municipality}}</td>
        </tr>
        <tr>
            <th>Estado</th>
            <td>{{data.state}}</td>
        </tr>
        <tr>
            <th>Código postal</th>
            <td>{{data.postalCode}}</td>
        </tr>
    </table> 
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" (click)="OpenDialogConfirm(data.idUser);">Seleccionar</button>
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>


