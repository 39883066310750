<app-loader [loader]="loader" *ngIf="adminService.isLoading; else content"></app-loader>

<ng-template #content>
    <!-- Contenido normal cuando no hay carga -->
    <div class="negotiationCont">
        <div class="negotiationCont__controls">
            <span class="visorMT">
                <ng-container *ngIf="mode == 'filter'">
                    <div class="negotiationHeader">
                        <button class="m19Btn" *ngIf="!negotiating else closeNeg" (click)="negotiating = true">Nueva
                            Negociación</button>

                        <ng-template #closeNeg>

                            <span style="color: var(--M19Secundary);">Negociante: <b
                                    style="color: var(--M19Primary);">{{negotiator}}</b></span>
                            <button class="m19Btn" (click)="mode = 'finish'">Cerrar Negociación</button>
                        </ng-template>
                    </div>
                </ng-container>

                <ng-container *ngIf="mode == 'sanction'">
                    <div class="sanctionMode">
                        <span>El valor de esta unidad es de <b style="color: var(--M19Primary)">{{propertyAmount | currency: 'CAD' : '$ '}}</b></span>
                        <span>Este cliente actualmente tiene un saldo de <b style="color: var(--M19Primary)">{{balance |
                                currency:'CAD' : '$
                                '}}</b></span>
                        <span>Se recomienda una sanción del 10% del valor de la unidad</span>
                        <div class="options">
                            <mat-form-field>
                                <mat-label>Sanción</mat-label>
                                <input matInput type="number" placeholder="Ingresa el monto de sanción"
                                    [(ngModel)]="sanctionAmount" name="sanctionAmount">
                                <mat-icon matSuffix>payments</mat-icon>
                            </mat-form-field>
                            <span class="actions">
                                <button class="m19AltBtn" (click)="addUnitToNegotiation()"
                                    data-title="Agregar"><mat-icon>add</mat-icon></button>
                                <button class="m19Btn" (click)="cleanAddition()"
                                    data-title="Cancelar"><mat-icon>cancel</mat-icon></button>
                            </span>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="mode == 'representative'">
                    <div class="representativeMode">
                        <mat-form-field>
                            <mat-label>Representante</mat-label>
                            <input matInput type="text" placeholder="Ingresa el nombre del representante"
                                [(ngModel)]="representative" name="representative">
                            <mat-icon matSuffix>person</mat-icon>
                        </mat-form-field>

                        <span style="display: flex; gap: 10px;">
                            <button class="m19AltBtn" (click)="mode = 'finish'" style="flex-grow: 1;">Asignar
                                <mat-icon>add</mat-icon></button>
                            <button class="m19Btn" (click)="representative = ''; mode = 'finish';"
                                style="flex-grow: 1;">Eliminar <mat-icon>cancel</mat-icon></button>
                        </span>
                    </div>
                </ng-container>

                <ng-container *ngIf="mode == 'finish'">
                    <div class="finishMode">
                        <div class="details">
                            <span>Detalles de Negociación</span>
                            <div class="detRow">
                                <div class="ng-autocomplete">
                                    <ng-autocomplete name="fullname" placeholder="Cliente a cargo"
                                        (selected)='selectCustomer($event)' [data]="responsables"
                                        [searchKeyword]="fullname" [itemTemplate]="itemTemplateP"
                                        [notFoundTemplate]="notFoundTemplateP">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplateP let-itemP>
                                        <a [innerHTML]="itemP.fullname"></a>
                                    </ng-template>
                                    <ng-template #notFoundTemplateP let-notFound>
                                        <div>No hay coincidencias de clientes</div>
                                    </ng-template>
                                </div>

                                <mat-form-field appearance="outline">
                                    <mat-label>Periodo</mat-label>
                                    <mat-select [(ngModel)]="frequency" name="frequency">
                                        <mat-option value="Semanales">
                                            Semanales
                                        </mat-option>
                                        <mat-option value="Quincenales">
                                            Quincenales
                                        </mat-option>
                                        <mat-option value="Mensuales">
                                            Mensuales
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>schedule</mat-icon>
                                </mat-form-field>
                            </div>

                            <div class="detRow">
                                <mat-form-field appearance="outline">
                                    <mat-label>Parcialidades</mat-label>
                                    <input matInput type="number" placeholder="Ingresa el monto de sanción"
                                        [(ngModel)]="installments" name="installments">
                                    <mat-icon matSuffix>payments</mat-icon>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>Cuenta</mat-label>
                                    <input matInput type="text" placeholder="Ingresa la cuenta clabe"
                                        [(ngModel)]="clabeNumber" name="clabeNumber"
                                        onkeypress="return ((event.charCode >= 48 && event.charCode <= 57))"
                                        maxlength="18">
                                    <mat-icon matSuffix>pin</mat-icon>
                                </mat-form-field>
                            </div>

                            <div class="detRow">
                                <mat-form-field appearance="outline">
                                    <mat-label>Pago Inicial</mat-label>
                                    <input matInput type="number" placeholder="Ingresa el monto"
                                        [(ngModel)]="initialPayment" name="initialPayment">
                                    <mat-icon matSuffix>payments</mat-icon>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>Días para primer pago</mat-label>
                                    <input matInput type="number" placeholder="Ingresa los días para el primer pago"
                                        [(ngModel)]="firstPayDate" name="firstPayDate" maxlength="2">
                                    <mat-icon matSuffix>event</mat-icon>
                                </mat-form-field>
                            </div>

                            <div class="detRow">
                                <mat-form-field appearance="outline">
                                    <mat-label>Fecha de negociación</mat-label>
                                    <input matInput type="date" [(ngModel)]="negotiationDate" required>
                                    <mat-icon matSuffix>calendar_month</mat-icon>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>Motivo de rescisión</mat-label>
                                    <mat-select [(ngModel)]="reason" name="reason">
                                        <mat-option value="2">
                                            Incumplimiento de pago
                                        </mat-option>
                                        <mat-option value="1">
                                            Voluntaria
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>payments</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="finActions">
                            <button class="m19Btn" (click)="mode = 'representative'" [attr.data-title]="representative"
                                *ngIf="representative!='' else addPerson"><mat-icon>verified</mat-icon></button>
                            <ng-template #addPerson>
                                <button class="m19Btn" (click)="mode = 'representative'"
                                    data-title="Agregar Representante"><mat-icon>person_add</mat-icon></button>
                            </ng-template>
                            <button class="m19AltBtn" (click)="saveNegotiationIntoBD()"
                                data-title="Guardar negociación"><mat-icon>save</mat-icon></button>
                        </div>
                    </div>
                </ng-container>
            </span>

            <span class="conditionsList">
                <ng-container *ngIf="mode == 'sanction'; else members">
                    <div class="paymentContainer">
                        <div class="paymentCard" *ngFor="let payment of payments; let i=index;">
                            <span class="paymentIcon">
                                <mat-icon *ngIf="payment.metodoPago == 'Efectivo'">payments</mat-icon>
                                <mat-icon *ngIf="payment.metodoPago == 'Cheque'">account_balance_wallet</mat-icon>
                                <mat-icon *ngIf="payment.metodoPago == 'Deposito'">monetization_on</mat-icon>
                                <mat-icon
                                    *ngIf="payment.metodoPago == 'Transferencia'">swap_horizontal_circle</mat-icon>
                                <mat-icon *ngIf="payment.metodoPago == 'Deposito en garantía'">local_atm</mat-icon>
                                <mat-icon
                                    *ngIf="payment.metodoPago == 'Tarjeta de debito'  || payment.metodoPago == 'Tarjeta de credito'">credit_card</mat-icon>
                                <mat-icon *ngIf="payment.metodoPago == 'Nota de credito'">request_quote</mat-icon>
                            </span>
                            <div class="paymentDetail">
                                <span style="display: flex; justify-content: space-between;"><b
                                        style="color: var(--M19Primary);">{{payment.concepto}}</b>
                                </span>
                                <span
                                    style="display: flex; justify-content: space-between;"><b>{{payment.metodoPago}}</b>
                                    <b style="color: var(--primaryGreen);">+ {{payment.cantidadAbono | currency :
                                        'CAD' : '$ '}}</b>
                                </span>
                                <span
                                    style="display: flex; justify-content: space-between;">{{pipe.transform(payment.fechaPago,'mediumDate')}}
                                    <b style="color: gray;">Folio: {{payment.folioPago}}</b>
                                </span>
                            </div>
                        </div>

                        <h4 style="font-size: 0.8rem; color: gray; text-align: center;">Parece que eso es todo</h4>
                    </div>
                </ng-container>

                <ng-template #members>
                    <div class="memberList">
                        <div class="conditionMembers" *ngFor="let c of toNegotiate; index as i">
                            <span class="count">{{i+1}}</span>
                            <div class="details">
                                <span>{{c.FKProperty}}</span>
                                <span>
                                    Sanción: {{c.SanctionAmount | currency :
                                    'CAD' : '$ '}}
                                    Devolución: {{c.DevolutionAmount | currency :
                                    'CAD' : '$ '}}
                                </span>
                            </div>
                            <mat-icon class="actionBtn" (click)="dropUnitFromNegotiation(i)">delete</mat-icon>
                        </div>
                    </div>

                    <div class="negotiationTotal" *ngIf="negotiating">
                        <h3>Total de devolución: <b style="color: var(--M19Primary);">{{totalDevolution | currency:'CAD'
                                :
                                '$ ' }}</b></h3>
                    </div>
                </ng-template>
            </span>
        </div>

        <div class="negotiationCont__customersList">
            <div class="searchBar">
                <mat-form-field style="width: 100%;">
                    <mat-label>Buscar cliente</mat-label>
                    <input matInput type="text" placeholder="Ingresar el nombre del cliente"
                        [(ngModel)]="filterCustomers" name="filterCustomers">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>

            <div class="cardContainer">
                <span class="customerCard" *ngFor="let c of customers | filterUsers: filterCustomers"
                    (click)="getUnitDetails(c)">
                    <div class="project">
                        <img [src]="c.projectLogo" alt="projectPicture">
                    </div>
                    <div class="details">
                        <span><span>{{c.property}} </span> <span
                                style="color: var(--primaryRed);"><b>{{c.state}}</b></span></span>
                        <span>{{c.customer}}</span>
                        <span>{{c.coordinator}}</span>
                    </div>
                </span>

                <h4 style="font-size: 0.8rem; color: gray; text-align: center;">Parece que llegamos al final</h4>
            </div>
        </div>
    </div>
</ng-template>