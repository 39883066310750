<mat-dialog-content>
    <h3 style="text-align: center; color: var(--M19Primary);"><b>Edición de Factura</b></h3>
    <form [formGroup]="invoiceFormGroup" (ngSubmit)="updateInvoice()">
        <div class="formFieldReq">
            <mat-form-field appearance="outline" class="col-md-3" style="width: 200px;">
                <mat-label>Folio de remisión</mat-label>
                <input matInput type="text" formControlName="deliveryfolio" min="1"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57)" required readonly>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-3">
                <mat-label>Proveedor</mat-label>
                <mat-select [(value)]="selectSuppliers" formControlName="idsupplier" required>
                    <mat-option *ngFor="let s of suppliers" [value]="s.idsupplier" (valueChange)="compareValues()">
                        {{s.suppliername}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-3" style="width: 200px;">
                <mat-label>Número de factura</mat-label>
                <input matInput type="number" formControlName="invoicenumber" min="1" (input)="compareValues()"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57)" required>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-3" style="width: 200px;">
                <mat-label>Fecha de factura</mat-label>
                <input matInput type="date" formControlName="invoicedate" (input)="compareValues()" required>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-3" style="width: 400px;">
                <mat-label>Subtotal</mat-label>
                <input matInput type="text" formControlName="subtotal" readonly>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-3" style="width: 400px;">
                <mat-label>IVA</mat-label>
                <input matInput formControlName="iva" (input)="compareValues()" readonly>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-3" style="width: 400px;">
                <mat-label>Total</mat-label>
                <input matInput formControlName="total" readonly>
            </mat-form-field>
        </div>

        <mat-form-field *ngIf="modification==true" appearance="outline" class="col-md-4">
            <mat-label>Razón de modificación</mat-label>
            <textarea matInput cdkTextareaAutosize formControlName="reason" cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"></textarea>
        </mat-form-field>

        <div class="col-12 text-right mt-3">
            <button class="saveButton" type="submit" [disabled]="!invoiceFormGroup.valid">
                <mat-icon>save</mat-icon>
            </button>
        </div>

        <br>

        <h3 style="margin-bottom: 30px;">Información de productos</h3>
        <div style="display:flex; flex-wrap: wrap;">
            <mat-form-field appearance="outline" class="col-md-4" [hidden]="true">
                <mat-label>idproduct</mat-label>
                <input matInput type="number" formControlName="idproduct" readonly>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-4" [hidden]="true">
                <mat-label>productname</mat-label>
                <input matInput type="text" formControlName="productname" readonly>
            </mat-form-field>
            <div class="ng-autocomplete">
                <ng-autocomplete formControlName="product" name="busquedaM" placeholder="id/Material" [data]="products"
                    [searchKeyword]="keywordM" (selected)='selectProduct($event)' [itemTemplate]="itemTemplateM"
                    [notFoundTemplate]="notFoundTemplateM">
                </ng-autocomplete>
                <ng-template #itemTemplateM let-itemM>
                    <a [innerHTML]="itemM.productname"></a>
                </ng-template>
                <ng-template #notFoundTemplateM let-notFound>
                    <div [innerHTML]="notFound"></div>
                </ng-template>
            </div>

            <mat-form-field appearance="outline" class="col-md-4" style="max-width: 110px;">
                <mat-label>Cantidad</mat-label>
                <input matInput type="number" formControlName="amount" min="1"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)">
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-4" style="max-width: 110px;">
                <mat-label>Unidad</mat-label>
                <input matInput type="text" formControlName="unit" readonly>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-4" style="max-width: 150px;">
                <mat-label>Costo Unitario</mat-label>
                <input matInput type="number" min="1" formControlName="unitcost"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)">
            </mat-form-field>

            <div (click)="addMaterial()" class="addMaterial">
                <mat-icon style="color:white;">arrow_downward</mat-icon>
            </div>
        </div>
    </form>

    <table class="m19Table">
        <thead>
            <tr>
                <th>Descripción</th>
                <th style="text-align: center;">Cantidad</th>
                <th style="text-align: center;">Unidad</th>
                <th style="text-align: center;">Costo unitario</th>
                <th style="text-align: center;">Subtotal</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let mat of materialList; index as i">
                <td>{{mat.productname}}</td>
                <td style="text-align: center;">{{mat.amount}}</td>
                <td style="text-align: center;">{{mat.unit}}</td>
                <td style="text-align: center;">{{mat.unitcost | currency:'CAD' : '$ '}}</td>
                <td style="text-align: center;">{{mat.amount * mat.unitcost | currency:'CAD' : '$ '}}</td>
                <td class="button-row">
                    <button class="actionAltBtn" mat-icon-button (click)="editProduct(i)">
                        <mat-icon>arrow_upward</mat-icon>
                    </button>
                    <button class="actionAltBtn" mat-icon-button (click)="deleteMaterials(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</mat-dialog-content>