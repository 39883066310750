import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TypeUser } from '../models/TypeUser';

@Injectable({
  providedIn: 'root'
})
export class TypeUserService {

  constructor(private http: HttpClient) { }

  selectTypeUser(): Observable<any> {
    return this.http.get(`${environment.URLServer}/type_user`);
  }

  createTypeUser(type: TypeUser): Observable<any> {
    return this.http.post(`${environment.URLServer}/type_user`, type);
  }

  deleteTypeUser(id: number): Observable<any> {
    return this.http.delete(`${environment.URLServer}/type_user/${id}`);
  }

  clogbook_responsable(): Observable<any> {
    return this.http.get(`${environment.URLServer}/type_user/clogbook_responsable`);
  }
}
