export class ConvertNumber {

    numberToText(num: string) {
        let number = Math.round(Number(num));

        return (this.convertThousandsToString(number));
    }

    private convertDigitToString(num: number) {
        switch (num) {
            case 1:
                return 'UN';
            case 2:
                return 'DOS';
            case 3:
                return 'TRES';
            case 4:
                return 'CUATRO';
            case 5:
                return 'CINCO';
            case 6:
                return 'SEIS';
            case 7:
                return 'SIETE';
            case 8:
                return 'OCHO';
            case 9:
                return 'NUEVE';
            default:
                return '';
        }
    }

    private convertTeensToString(ten: string, num: number) {
        if (num > 0) {
            return ten + ' Y ' + this.convertDigitToString(num);
        }

        return ten;
    }

    private convertDozensToString(num: number) {
        var dozens = Math.floor(num / 10);
        var units = num - dozens * 10;

        switch (dozens) {
            case 1:
                switch (units) {
                    case 0:
                        return 'DIEZ';
                    case 1:
                        return 'ONCE';
                    case 2:
                        return 'DOCE';
                    case 3:
                        return 'TRECE';
                    case 4:
                        return 'CATORCE';
                    case 5:
                        return 'QUINCE';
                    default:
                        return 'DIECI' + this.convertDigitToString(units);
                }
            case 2:
                switch (units) {
                    case 0:
                        return 'VEINTE';
                    default:
                        return 'VEINTI' + this.convertDigitToString(units);
                }
            case 3:
                return this.convertTeensToString('TREINTA', units);
            case 4:
                return this.convertTeensToString('CUARENTA', units);
            case 5:
                return this.convertTeensToString('CINCUENTA', units);
            case 6:
                return this.convertTeensToString('SESENTA', units);
            case 7:
                return this.convertTeensToString('SETENTA', units);
            case 8:
                return this.convertTeensToString('OCHENTA', units);
            case 9:
                return this.convertTeensToString('NOVENTA', units);
            case 0:
                return this.convertDigitToString(units);
            default:
                return '';
        }
    }

    private convertHundredsToString(num: number) {
        var hundreds = Math.floor(num / 100);
        var dozens = num - hundreds * 100;

        switch (hundreds) {
            case 1:
                if (dozens > 0) {
                    return 'CIENTO ' + this.convertDozensToString(dozens);
                }
                return 'CIEN';
            case 2:
                return 'DOSCIENTOS ' + this.convertDozensToString(dozens);
            case 3:
                return 'TRESCIENTOS ' + this.convertDozensToString(dozens);
            case 4:
                return 'CUATROCIENTOS ' + this.convertDozensToString(dozens);
            case 5:
                return 'QUINIENTOS ' + this.convertDozensToString(dozens);
            case 6:
                return 'SEISCIENTOS ' + this.convertDozensToString(dozens);
            case 7:
                return 'SETECIENTOS ' + this.convertDozensToString(dozens);
            case 8:
                return 'OCHOCIENTOS ' + this.convertDozensToString(dozens);
            case 9:
                return 'NOVECIENTOS ' + this.convertDozensToString(dozens);
            default:
                return this.convertDozensToString(dozens);
        }
    }

    private convertThousandsToString(num: number) {
        var thousands = Math.floor(num / 1000);
        var hundreds = num - thousands * 1000;

        var letras = '';

        if (thousands > 0) {
            if (thousands > 1) {
                letras = this.convertHundredsToString(thousands) + ' ' + 'MIL';
            } else {
                letras = 'MIL'
            }
        }

        if (hundreds > 0) {
            letras += '';
        }

        var strCentenas = this.convertHundredsToString(hundreds);

        if (letras === '') {
            return strCentenas;
        }

        return (letras + ' ' + strCentenas);
    }
}