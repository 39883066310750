<h4>Selecciona un inmueble: </h4>
<mat-form-field appearance="fill">
  <mat-label>Inmueble</mat-label>
  <mat-select #projectSelected [(value)]="inmueble">
    <mat-option *ngFor="let project of projects" [value]="project">
      {{project.codeProperty}}
    </mat-option>
  </mat-select>
</mat-form-field>

<div style="text-align: right">
    <button [mat-dialog-close]="true" (click) = "setProject(); 
    "[disabled]="inmueble.codeProperty==''" mat-stroked-button color="warn">Ok</button>
</div>


