import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SuppliersService } from 'src/app/services/suppliers.service';

//=========================================================================================================
//Importación de librerías para componentes visuales
//=========================================================================================================
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';

@Component({
  selector: 'app-supplier-update',
  templateUrl: './supplier-update.component.html',
  styleUrls: ['./supplier-update.component.scss']
})
export class SupplierUpdateComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<SupplierUpdateComponent>,
    private supplierService: SuppliersService,

    //===================================================
    //Declaración de complementos visuales
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  //==========================================================================================================
  //Objeto constructor del formulario utilizado en la pantalla de requisición, se agrega el código 
  //", Validators.required" para señalar que es un valor que no debe estar vacío
  //==========================================================================================================
  supplierFormGroup: FormGroup = this._formBuilder.group({
    idsupplier: [],
    suppliername: [, Validators.required],
    RFC: [, Validators.required],
    direction: [, Validators.required],
    phone: [, Validators.required],
    useremail: []
  });

  ngOnInit(): void {
    this.supplierService.searchSupplier(this.data).subscribe(
      res => {
        this.supplierFormGroup.controls['suppliername'].setValue(res.suppliername);
        this.supplierFormGroup.controls['RFC'].setValue(res.RFC);
        this.supplierFormGroup.controls['direction'].setValue(res.direction);
        this.supplierFormGroup.controls['phone'].setValue(res.phone);
      }
    );
  }

  async updateSupplier(){
    Swal.fire({
      title: '¿Quieres guardar tus cambios ahora?',
      text: "¡Este proveedor se guardara como está actualmente!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, espera',
      confirmButtonText: 'Sí, guárdalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.supplierService.updateSupplier(this.data, this.supplierFormGroup.value).subscribe(
          res => {
            this.sweet.AlertTime("center", "success", "Proveedor actualizado con éxito.", false, 2000);
            this.dialogRef.close();
          }
        );
      }
    })
  }

}
