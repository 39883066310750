<mat-dialog-content>
    <h4 style="color: var(--M19Primary); font-weight: bold;">Prórroga de atención de lead</h4>

    <p>Está a punto de otorgar una prórroga de tiempo de atención a <b style="color: var(--M19Primary);">{{data.secondResponsable}}</b> sobre el prospecto <b style="color: var(--M19Primary);">{{data.name+' '+data.firstLastName+' '+data.secondLastName}}</b>, para continuar seleccione la nueva fecha límite y escriba un motivo:</p>

    <form  [formGroup]="extensionFormGroup" (ngSubmit)="giveMoreTime()"
    onKeyPress="if(event.keyCode == 13) event.returnValue = false;" style="display: flex; flex-direction: column; align-items: center;">
        <mat-form-field appearance="outline">
            <mat-label>Fecha límite de atención</mat-label>
            <input matInput type="date" formControlName="newdate" required>
            <mat-icon matSuffix>calendar_month</mat-icon>
        </mat-form-field>
    
        <mat-form-field appearance="outline">
            <mat-label>Motivo de prórroga</mat-label>
            <textarea matInput cdkTextareaAutosize formControlName="reason"
                cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" placeholder="El coordinador argumenta que..."></textarea>
            <mat-icon matSuffix>edit</mat-icon>
        </mat-form-field>

        <button class="m19Btn">Dar prórroga</button>
    </form>
</mat-dialog-content>