<div class="container">

    <br>
    <h3 style="text-align: center;"> Historial de presupuestos </h3>
    <br>

    <div style="display:flex ; flex-direction: row; flex-wrap: wrap; justify-content: center">

        <h6 style="margin-top: 20px; text-align:center ;"> Seleccione la fecha en que se registro el presupuesto </h6>
        <mat-form-field appearance="outline" class="col-md-5">
            <mat-label> Fechas de registro </mat-label>
            <mat-select [(value)]="budgetCommissions.registrationDate" required>
                <mat-option *ngFor="let dataBudget of budgetCommissions" [value]="dataBudget.idBudgetComission">
                    {{dataBudget.idBudgetComission}} - Fecha de registro - [ {{dataBudget.registrationDate | date :
                    'dd-MM-yyyy'}} ]
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button class="btn btn-m19" style="height: 50px; margin-top: 5px;"> Buscar</button>
    </div>

    <div class="table-container">
        <table class="table table-striped mt-3 animated fadeIn faster" style="text-align:center; ">

            <thead class="thead-m19">
                <tr>
                    <th scope="col" align="center">Fecha </th>
                    <th scope="col" align="center" style="transform: rotate(-45deg);"> VP</th>
                    <th scope="col" align="center" style="transform: rotate(-45deg);"> XL
                    </th>
                    <th scope="col" align="center" style="transform: rotate(-45deg);"> AL
                    </th>
                    <th scope="col" align="center" style="transform: rotate(-45deg);"> CL
                    </th>
                    <th scope="col" align="center" style="transform: rotate(-45deg);"> CB
                    </th>
                    <th scope="col" align="center" style="transform: rotate(-45deg);"> LI
                    </th>
                    <th scope="col" align="center" style="transform: rotate(-45deg);"> AM
                    </th>
                    <th scope="col" align="center" style="transform: rotate(-45deg);"> GA
                    </th>
                    <th scope="col" align="center" style="transform: rotate(-45deg);"> BE
                    </th>
                    <th scope="col" align="center" style="transform: rotate(-45deg);"> BS
                    </th>
                    <th scope="col" align="center" style="transform: rotate(-45deg);"> VL
                    </th>
                    <th scope="col" align="center"> Acción
                    </th>

                </tr>
            </thead>
            <tbody *ngFor="let dataBudget of budgetCommissions; let index">

                <tr>

                    <td>
                        {{dataBudget.registrationDate | date: 'dd/MM/yyyy' }}
                    </td>

                    <td *ngIf="dataBudget.quantityPVP != null">
                        ${{dataBudget.quantityPVP }}
                    </td>

                    <td *ngIf="dataBudget.quantityPVP == null">
                        $ 0
                    </td>

                    <td *ngIf="dataBudget.quantityPXL != null">
                        ${{dataBudget.quantityPXL }}
                    </td>

                    <td *ngIf="dataBudget.quantityPXL == null">
                        $ 0
                    </td>

                    <td *ngIf="dataBudget.quantityPAL != null">
                        ${{dataBudget.quantityPAL | currency: 'CAD' : '$' }}
                    </td>

                    <td *ngIf="dataBudget.quantityPAL == null">
                        $ 0
                    </td>

                    <td *ngIf="dataBudget.quantityPCL != null">
                        ${{dataBudget.quantityPCL }}
                    </td>

                    <td *ngIf="dataBudget.quantityPCL == null">
                        $ 0
                    </td>

                    <td *ngIf="dataBudget.quantityPCB != null">
                        ${{dataBudget.quantityPCB }}
                    </td>

                    <td *ngIf="dataBudget.quantityPCB == null">
                        $ 0
                    </td>

                    <td *ngIf="dataBudget.quantityPLI != null">
                        ${{dataBudget.quantityPLI }}
                    </td>

                    <td *ngIf="dataBudget.quantityPLI == null">
                        $ 0
                    </td>

                    <td *ngIf="dataBudget.quantityPAM != null">
                        ${{dataBudget.quantityPAM }}
                    </td>

                    <td *ngIf="dataBudget.quantityPAM == null">
                        $ 0
                    </td>

                    <td *ngIf="dataBudget.quantityPGA != null">
                        ${{dataBudget.quantityPGA }}
                    </td>

                    <td *ngIf="dataBudget.quantityPGA == null">
                        $ 0
                    </td>

                    <td *ngIf="dataBudget.quantityPBE != null">
                        ${{dataBudget.quantityPBE }}
                    </td>

                    <td *ngIf="dataBudget.quantityPBE == null">
                        $ 0
                    </td>

                    <td *ngIf="dataBudget.quantityPBS != null">
                        ${{dataBudget.quantityPBS }}
                    </td>   

                    <td *ngIf="dataBudget.quantityPBS == null">
                        $ 0
                    </td>

                    <td *ngIf="dataBudget.quantityPVL != null">
                        {{dataBudget.quantityPVL | currency: 'CAD' : '$' }}
                    </td>

                    <td *ngIf="dataBudget.quantityPVL == null">
                        $ 0
                    </td>

                    <td>
                        <button mat-mini-fab color="warn" (click)="clickInformation(dataBudget.idBudgetComission)">
                            <mat-icon>arrow_downward</mat-icon>
                        </button>
                    </td>

                </tr>


            </tbody>

        </table>
    </div>


</div>

<br>

<mat-card class="card-form" [hidden]="bandera == true">
    <mat-card-content>
        <form [formGroup]="BudgetFormGroup" (ngSubmit)="updateBudgetCommission()">

            <div class="col-md-12">
                <h5 style="text-align: center;"> Proyectos Existentes </h5>
                <br>
            </div>

            <div class="col-md-12" style="display:flex; flex-direction: row; justify-content: space-between;">
                <mat-form-field appearance="outline" class="col-md-2">
                    <mat-label> Altares </mat-label>
                    <input matInput type="text" formControlName="quantityPAL" placeholder="Altares" required readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-2">
                    <mat-label> Altamar </mat-label>
                    <input matInput type="text" formControlName="quantityPAM" placeholder="Altamar" required readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-2">
                    <mat-label> Benanta </mat-label>
                    <input matInput type="text" formControlName="quantityPBE" placeholder="Benanta" required readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-2">
                    <mat-label> Bosco </mat-label>
                    <input matInput type="text" formControlName="quantityPBS" placeholder="Bosco" required readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-2">
                    <mat-label> Casa Blanca </mat-label>
                    <input matInput type="text" formControlName="quantityPCB" placeholder="Casa Blanca" required
                        readonly>
                </mat-form-field>

            </div>

            <div class="col-md-12" style="display:flex; flex-direction: row; justify-content: space-between;">

                <mat-form-field appearance="outline" class="col-md-2">
                    <mat-label> Los Colomos </mat-label>
                    <input matInput type="text" formControlName="quantityPCL" placeholder="Los Colomos" required
                        readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-2">
                    <mat-label> Gamta </mat-label>
                    <input matInput type="text" formControlName="quantityPGA" placeholder="Gamta" required readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-2">
                    <mat-label> Liverti </mat-label>
                    <input matInput type="text" formControlName="quantityPLI" placeholder="Liverti" required readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-2">
                    <mat-label> Valle de las Palomas </mat-label>
                    <input matInput type="text" formControlName="quantityPVP" placeholder="Valle de las Palomas"
                        required readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-2">
                    <mat-label> Valle las Palosmas 2 </mat-label>
                    <input matInput type="text" formControlName="quantityPVL" placeholder="Valle las Palosmas 2"
                        required readonly>
                </mat-form-field>

            </div>

            <div class="col-md-12" style="display:flex; flex-direction: row; justify-content: space-between;">

                <mat-form-field appearance="outline" class="col-md-2">
                    <mat-label> Xalta </mat-label>
                    <input matInput type="text" formControlName="quantityPXL" placeholder="Xalta" required readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-7">
                    <mat-label> Motivo de modificación </mat-label>
                    <input matInput type="text" formControlName="reasonModification"
                        placeholder="Motivo de modificación" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-md-2">
                    <mat-label> Fecha de modificación </mat-label>
                    <input matInput type="text" formControlName="dateModificacion" placeholder="Fecha de modificación"
                        required readonly>
                </mat-form-field>

            </div>


            <div class="col-md-12" style="display:flex; flex-direction: row-reverse;">
                <button class="btn btn-m19" type="submit" (click)="bandera=true">
                    Actualizar
                </button>
            </div>

        </form>
    </mat-card-content>

</mat-card>