import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MapsService {

  private url = 'http://localhost:3000/api';

  constructor(private http: HttpClient) { }

  selectAllInformation(): Observable<any> {
    return this.http.get(`${environment.URLServer}/maps/`);
  }
  selectAllProjects(): Observable<any> {
    return this.http.get(`${environment.URLServer}/maps/getProjects`);
  }
  selectLink(id: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/maps/getLink/${id}`);
  }
  selectPointOfMap(id: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/maps/getPointOfMap/${id}`);
  }
  selectNotRegister(id: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/maps/getNotRegister/${id}`);
  }
  insertXYMap(Xmap: any, Ymap: any, codeProperty: any): Observable<any> {
    //return this.http.get(`${environment.URLServer}/maps/getNotRegister/${id}`);
    return this.http.post(`${environment.URLServer}/maps/insertXYmap/`,{Xmap,Ymap,codeProperty})
  }
  insertsketchMap(sketchMap: any, codeProperty: any): Observable<any> {
    //return this.http.get(`${environment.URLServer}/maps/getNotRegister/${id}`);
    return this.http.post(`${environment.URLServer}/maps/insertsketchMap/`,{sketchMap,codeProperty})
  }
  insertNewMap(imgMap: any,  nomenclature: any): Observable<any> {
    //return this.http.get(`${environment.URLServer}/maps/getNotRegister/${id}`);
    return this.http.post(`${environment.URLServer}/maps/insertNewMap/`,{imgMap,nomenclature})
  }
  getNotSketchMap(id: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/maps/getNotSketchMap/${id}`);
  }
  getReadySketchMap(id: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/maps/getReadySketchMap/${id}`);
  }
  getSketchmapLink(id: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/maps/getSketchmapLink/${id}`);
  }
  getPropertyInfo(id: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/maps/getPropertyInfo/${id}`);
  }
  getCustomerInfo(id: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/maps/getCustomerInfo/${id}`);
  } 
  getAllPropertys(id: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/maps/getAllPropertys/${id}`);
  } 
  propertyMapstatus(codeProperty: any): Observable<any> {
    return this.http.post(`${environment.URLServer}/maps/propertyMapstatus/`,{codeProperty})
  }
  getSellerId(id: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/maps/getSellerId/${id}`);
  } 
  getSalesCoordinator(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/maps/getSalesCoordinator/${id}`);
  }
  getColorCode(): Observable<any> {
    return this.http.get(`${environment.URLServer}/maps/getColorCode`);
  }
  getAvailableColor(): Observable<any> {
    return this.http.get(`${environment.URLServer}/maps/getAvailableColor`);
  }
  getSellerData(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/maps/getSellerData/${id}`);
  }
}