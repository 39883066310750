import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { invoiceReport } from 'src/app/models/invoiceReport';
import { invoiceReportService } from 'src/app/services/invoice.service';

import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';

@Component({
  selector: 'app-facturas',
  templateUrl: './facturas.component.html',
  styleUrls: ['./facturas.component.scss']
})
export class FacturasComponent implements OnInit {

  //Banderas de impresión de datos particulares de acuerdo al modulo
  formularioActivo = true
  formularioApagado = false

  bannerTitle: BannerTitle = {
    title: "Facturacion Pendiente",
    icon: "pending",
    position: "center",
    background: "var(--M19Secundary)"
  }

  activarFormulario() {
    return this.formularioActivo = true, this.formularioApagado = false;
  }
  apagarFormulario() {
    return this.formularioActivo = false, this.formularioApagado = true
  }

  //Listas de datos de facturas
  facturaID: any = [];
  actualConteo: any = [];
  invoice: any = [];

  //Objeto de servicio de facturas
  invReport: invoiceReport = {
    invoiceStatus: 'Aprobada'
  };


  constructor(private invoiceService: invoiceReportService, private router: Router) { }

  //Metodo de carga de metodos al momento de la ejecución
  ngOnInit(): void {
    this.slectReporteFactura();
  }

  //Consulta de datos desde el servicio que consulta a la API
  slectReporteFactura() {
    this.invoiceService.getPendingInvoice().subscribe(
      res => {

        this.invoice = res;
      },
      err => console.log(err)
    );
  }
  selectID(code: any) {
    this.invoiceService.selectID(code).subscribe(
      data => {
        this.facturaID = data;
      },
      err => console.log(err)
    );
  }
  conID(code: any) {
    this.invoiceService.conCo(code).subscribe(
      data => {
        this.actualConteo = data;
        console.log(data)
      },
      err => console.log(err)
    );
  }

  aprobarFactura(id: any) {
    this.invoiceService.updateInvoiceCanceled(id, this.invReport).subscribe(data => {
      this.slectReporteFactura();
      this.invReport.invoiceStatus = "Aprobada";
      this.invReport.accountingFolio = this.actualConteo.conteoComplementos;
    }, error => {
      console.log(error);
      this.router.navigate(['factura'])
    })
  }

}
