<div class="sidebar-style" *ngIf="menuItem()==true" (click)="userPermissions()">
  <div class="sidebar-title" routerLink="/home" (click)="setPage('Inicio')">
    <img alt="logo" src="../assets/iconoMBlancoFiestas.png" *ngIf="((fecha.getMonth()+1)==11 && fecha.getDate() >= 20) || (fecha.getMonth()+1)==12">
    <img alt="logo" style="border-radius: 5px;" src="../assets/iconoMBlanco.png" *ngIf="(fecha.getMonth()+1)!=6">
    <img alt="logo" style="border-radius: 5px;" [src]="logoPaths[currentLogoIndex]" *ngIf="(fecha.getMonth()+1)==6">
    <div class="info">
      <p *ngIf="(fecha.getMonth() + 1) == 6" class="name">Feliz Aniversario</p>
      <p *ngIf="(fecha.getMonth() + 1) != 6" class="name">M19 Inmobiliaria</p>
      <p class="slogan">Tu hogar, nuestro sueño.</p>
    </div>
  </div>
  <div class="menuContainer">
    <mat-accordion>
      <!--PROYECTOS-->
      <mat-expansion-panel #mep="matExpansionPanel"
        *ngIf="permissions().match('mapsgenerate') || permissions().match('sketchMaps') || permissions().match('updatemap') || permissions().match('projects') || permissions().match('446576656c6f706572')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>business</mat-icon>
            <span>Proyectos</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('projects') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/projects" (click)="setPage('Proyectos')">
          <mat-icon>business</mat-icon>
          <span>Proyectos</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('updatemap') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/updatemap" (click)="setPage('Mapa del Proyecto')">
          <mat-icon>save</mat-icon>
          <span>Mapa del proyecto</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('sketchMaps') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/sketchMaps" (click)="setPage('Lotificaciones')">
          <mat-icon>location_on</mat-icon>
          <span>Lotificaciones</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('mapsgenerate') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/mapsgenerate" (click)="setPage('Generar Mapa')">
          <mat-icon>map</mat-icon>
          <span>Generar Mapa</span>
        </button>
      </mat-expansion-panel>

      <!--ADMINISTRACION-->
      <mat-expansion-panel #mep="matExpansionPanel"
        *ngIf="permissions().match('leadsPrincipal') || permissions().match('leadsAssigned') || permissions().match('446576656c6f706572') || permissions().match('general-request') || permissions().match('view-request') || permissions().match('customerStatus') || permissions().match('cashCut') || permissions().match('recession-request')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>supervised_user_circle</mat-icon>
            <span>Administración</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('general-request') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/general-request" (click)="setPage('Control de Clientes')">
          <mat-icon>format_list_bulleted</mat-icon>
          <span>Control de clientes</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('view-request') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/view-request" (click)="setPage('Solicitudes de Contratos')">
          <mat-icon>format_align_justify</mat-icon>
          <span>Solicitudes de contratos</span>
        </button>
        
        <a style="text-decoration: none;" (click)="mep.expanded = false;"
          *ngIf="permissions().match('customerStatus') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/CustomerStatus" fragment="ayudaDiv" (click)="setPage('Estado de Cliente')">
          <mat-icon>category</mat-icon>
          <span>Estado de cliente</span>
        </a>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('recession-request') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/recession-request" (click)="setPage('Bloqueos')">
          <mat-icon>lock</mat-icon>
          <span>Bloqueos</span>
        </button>

        <button *ngIf=" permissions().match('cashCut')  || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/cashCut" (click)="setPage('Corte de Caja')">
          <mat-icon>move_to_inbox</mat-icon>
          <span>Corte de Caja</span>
        </button>

        <mat-expansion-panel #mep="matExpansionPanel"
          *ngIf="permissions().match('leadsPrincipal') || permissions().match('leadsAssigned') || permissions().match('446576656c6f706572') "
          [expanded]="panelOpenState">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>support_agent</mat-icon>
              <span>Leads</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <button (click)="mep.expanded = false;"
            *ngIf="permissions().match('leadsPrincipal') || permissions().match('446576656c6f706572')" mat-menu-item
            routerLink="/leadsPrincipal" (click)="setPage('Control de Leads')">
            <mat-icon>manage_search</mat-icon>
            <span>Control</span>
          </button>

          <button (click)="mep.expanded = false;"
            *ngIf="permissions().match('leadsAssigned') || permissions().match('446576656c6f706572')" mat-menu-item
            routerLink="/leadsAssigned" (click)="setPage('Leads Asignados')">
            <mat-icon>face_retouching_natural</mat-icon>
            <span>Asignados</span>
          </button>
        </mat-expansion-panel>
        
        <mat-expansion-panel #mep="matExpansionPanel"
        *ngIf="permissions().match('negotiation') || permissions().match('scheduling') || permissions().match('446576656c6f706572') "
        [expanded]="panelOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>handshake</mat-icon>
            <span>Negociaciones</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('negotiation') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/negotiation" (click)="setPage('Control de Leads')">
          <mat-icon>person_add</mat-icon>
          <span>Registro</span>
        </button>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('scheduling') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/scheduling" (click)="setPage('Leads Asignados')">
          <mat-icon>calendar_month</mat-icon>
          <span>Planeación</span>
        </button>
      </mat-expansion-panel>
      </mat-expansion-panel>
      
      <!--CONTABILIDAD-->
      <mat-expansion-panel #mep="matExpansionPanel"
        *ngIf="permissions().match('leadsPrincipal') || permissions().match('leadsAssigned') || permissions().match('446576656c6f706572') || permissions().match('general-request') || permissions().match('view-request') || permissions().match('customerStatus') || permissions().match('cashCut') || permissions().match('recession-request')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>calculate</mat-icon>
            <span>Contabilidad</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('transferValidate') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/transferValidate" (click)="setPage('Control de Transferencias')">
          <mat-icon>format_list_bulleted</mat-icon>
          <span>Control de Transferencias</span>
        </button>
      </mat-expansion-panel>

      <!--CLIENTES-->
      <button *ngIf="permissions().match('customers') || permissions().match('446576656c6f706572')" mat-menu-item
        routerLink="/customers" (click)="setPage('Clientes')">
        <mat-icon>contact_mail</mat-icon>
        <span>Clientes</span>
      </button>

      <!--VENTAS-->
      <mat-expansion-panel #mep="matExpansionPanel"
        *ngIf="permissions().match('editSales') || permissions().match('mapsforsales') || permissions().match('mapsconsult') || permissions().match('request') ||  permissions().match('sales') || permissions().match('446576656c6f706572') || permissions().match('recessions')"
        [expanded]="panelOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>store</mat-icon>
            <span>Ventas</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('sales') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/sales" (click)="setPage('Ventas')">
          <mat-icon>store</mat-icon>
          <span>Ventas</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('mapsforsales') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/mapsforsales" (click)="setPage('Venta Digital')">
          <mat-icon>store</mat-icon>
          <span>Venta Digital</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('mapsforsales') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/quotationForm" (click)="setPage('Cotizador de Pago')">
          <mat-icon>calculate</mat-icon>
          <span>Cotizador</span>
        </button>
        <!-- <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('editSales') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/editSales">
          <mat-icon>store</mat-icon>
          <span>Edicion de Disponibilidad</span>
        </button> -->
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('recessions') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/recessions" (click)="setPage('Rescisiones')">
          <mat-icon>delete_forever</mat-icon>
          <span>Rescisiones</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('request') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/request" (click)="setPage('Registro de Pre-Clientes')">
          <mat-icon>group_add</mat-icon>
          <span>Registro de pre clientes</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('mapsconsult') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/mapsconsult" (click)="setPage('Disponibilidad')">
          <mat-icon>location_searching</mat-icon>
          <span>Disponibilidad</span>
        </button>
      </mat-expansion-panel>

      <!--PAGOS-->
      <button *ngIf="permissions().match('payments') || permissions().match('446576656c6f706572')" mat-menu-item
        routerLink="/payments" (click)="setPage('Registro de Pagos')">
        <mat-icon>paid</mat-icon>
        <span>Pagos</span>
      </button>

      <!--CONSULTAS-->
      <mat-expansion-panel #mep="matExpansionPanel"
        *ngIf="permissions().match('report') || permissions().match('446576656c6f706572') || permissions().match('search') || permissions().match('cancelled')"
        [expanded]="panelOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>search</mat-icon>
            <span>Consultas</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('report') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/report" (click)="setPage('Estado de Cuenta')">
          <mat-icon>people</mat-icon>
          <span>Estado de cuenta</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('search') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/search" (click)="setPage('Consulta de Folios')">
          <mat-icon>done_all</mat-icon>
          <span>Folios</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('cancelled') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/cancelled" (click)="setPage('Folios Cancelados')">
          <mat-icon>remove_done</mat-icon>
          <span>Folios cancelados</span>
        </button>
      </mat-expansion-panel>

      <!--REPORTES-->
      <mat-expansion-panel #mep="matExpansionPanel"
        *ngIf="permissions().match('graficos') || permissions().match('RSC') || permissions().match('446576656c6f706572') || permissions().match('flowReport') || permissions().match('entry') || permissions().match('wallet') || permissions().match('walletV2') || permissions().match('RecessionsStatus')"
        [expanded]="panelOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>poll</mat-icon>
            <span>Reportes</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('graficos') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/graficos" (click)="setPage('Reporte de Inmobiliaria')">
          <mat-icon>groups</mat-icon>
          <span>Inmobiliaria</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('flowReport') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/flowReport" (click)="setPage('Reporte de Flujo')">
          <mat-icon>timeline</mat-icon>
          <span>Flujo</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('entry') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/entry" (click)="setPage('Reporte de Ingresos')">
          <mat-icon>assessment</mat-icon>
          <span>Ingreso</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('wallet') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/wallet" (click)="setPage('Reporte de Cartera')">
          <mat-icon>account_balance_wallet</mat-icon>
          <span>Cartera</span>
        </button>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('walletV2') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/walletV2" (click)="setPage('Reporte de Cartera')">
          <mat-icon>account_balance_wallet</mat-icon>
          <span> Cartera V2 </span>
        </button>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('walletByWeek') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/walletByWeek" (click)="setPage('Reporte de Recuperación')">
          <mat-icon>av_timer</mat-icon>
          <span>Recuperación</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('RSC') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/RSC" (click)="setPage('Estado de Venta')">
          <mat-icon>linear_scale</mat-icon>
          <span> Estado de Venta </span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('RecessionsStatus') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/RecessionsStatus" (click)="setPage('Estado de Rescisión')">
          <mat-icon>route</mat-icon>
          <span> Estado de Rescisión </span>
        </button>
      </mat-expansion-panel>

      <!--COMISIONES-->
      <mat-expansion-panel #mep="matExpansionPanel"
        *ngIf=" permissions().match('GenerateAndCalculateCommisionsComponent') ||  permissions().match('coordinator') || permissions().match('coordStatus') || permissions().match('viewAllComissions') || permissions().match('446576656c6f706572')"
        [expanded]="panelOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>tab</mat-icon>
            <span>Comisiones</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('coordinator') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/coordinator" (click)="setPage('Registro de Comisiones')">
          <mat-icon>payment</mat-icon>
          <span>Pagos coordinador</span>
        </button>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('coordStatus') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/coordStatus" (click)="setPage('Estado de Comisiones')">
          <mat-icon>dns</mat-icon>
          <span>Estado de cuenta coordinador</span>
        </button>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('GenerateAndCalculateCommisionsComponent') || permissions().match('446576656c6f706572') "
          mat-menu-item routerLink="/GenerateAndCalculateCommisionsComponent" (click)="setPage('Generar Comisiones')">
          <mat-icon> history </mat-icon>
          <span>Generar comisiones</span>
        </button>
      </mat-expansion-panel>

      <!--FACTURAS-->
      <mat-expansion-panel #mep="matExpansionPanel"
        *ngIf=" permissions().match('statusinvoice') || permissions().match('factura') || permissions().match('facturaConsulta') || permissions().match('facturaGlobal') || permissions().match('446576656c6f706572')"
        [expanded]="panelOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>ballot</mat-icon>
            <span>Facturas</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('factura') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/factura" (click)="setPage('Facturas Pendientes')">
          <mat-icon>ballot</mat-icon>
          <span>Pendientes</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('factura') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/unmadeInvoice" (click)="setPage('Control de Facturas')">
          <mat-icon>ballot</mat-icon>
          <span>Pendientes 2</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('statusinvoice') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/statuspayments" (click)="setPage('Estado de Facturación')">
          <mat-icon>ballot</mat-icon>
          <span>Estado de facturacion</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('statusinvoice') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/invoiceStatus" (click)="setPage('Estado de Facturación')">
          <mat-icon>ballot</mat-icon>
          <span>Estado de facturacion 2</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('facturaGlobal') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/facturaGlobal" (click)="setPage('Factura Global')">
          <mat-icon>library_add_check</mat-icon>
          <span>Factura global</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('facturaConsulta') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/facturaConsulta" (click)="setPage('Consulta de Facturación')">
          <mat-icon>manage_search</mat-icon>
          <span>Consultar</span>
        </button>
      </mat-expansion-panel>

      <!--FACTURACION 2.0-->
      <mat-expansion-panel #mep="matExpansionPanel"
        *ngIf=" permissions().match('InvoiceReport')|| permissions().match('446576656c6f706572')"
        [expanded]="panelOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>ballot</mat-icon>
            <span>Facturacion 2.0</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('statusinvoice') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/InvoiceSlopeRANGE" (click)="setPage('Reporte de Factura')">
          <mat-icon>ballot</mat-icon>
          <span>Rep. Facturas</span>
        </button>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('statusinvoice') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/pendingInvoice" (click)="setPage('Facturas Pendientes')">
          <mat-icon>ballot</mat-icon>
          <span>Facturas pendientes</span>
        </button>
      </mat-expansion-panel>

      <!--PUSUARIOS-->
      <button *ngIf="permissions().match('users') || permissions().match('446576656c6f706572')" mat-menu-item
        routerLink="/users" (click)="setPage('Empleados')">
        <mat-icon>people</mat-icon>
        <span>Usuarios</span>
      </button>

      <!--CONFIGURACION-->
      <button *ngIf="permissions().match('setting') || permissions().match('446576656c6f706572')" mat-menu-item
        routerLink="/setting" (click)="setPage('Configuración')">
        <mat-icon>settings</mat-icon>
        <span>Configuración</span>
      </button>

      <!--CLIENTES(ESTADO)-->
      <button *ngIf="permissions().match('3') || permissions().match('446576656c6f706572')" mat-menu-item
        routerLink="/account" (click)="setPage('Estado de Cuenta')">
        <mat-icon>people</mat-icon>
        <span>Clientes (estado)</span>
      </button>

      <!--BITACORA DE OBRA-->
      <mat-expansion-panel #mep="matExpansionPanel"
        *ngIf="permissions().match('CLogbookRegister') || permissions().match('446576656c6f706572')"
        [expanded]="panelOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>chrome_reader_mode</mat-icon>
            <span>Bitácora de obra</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('CLogbookRegister') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/CLogbookRegister" (click)="setPage('Control de Bitacora')">
          <mat-icon>add_circle</mat-icon>
          <span>Registrar/Continuar</span>
        </button>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('CLogbookReport') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/CLogbookReport" (click)="setPage('Reporte de Bitacora')">
          <mat-icon>bolt</mat-icon>
          <span>Reporte</span>
        </button>

      </mat-expansion-panel>



      <!--REQUISICIONES-->
      <mat-expansion-panel #mep="matExpansionPanel"
        *ngIf="permissions().match('RequisitionRegister') || permissions().match('RequisitionConsult') || permissions().match('RequisitionControl') || permissions().match('RequisitionApprove') ||permissions().match('RequisitionSign') || permissions().match('446576656c6f706572')"
        [expanded]="panelOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>format_list_numbered_rtl</mat-icon>
            <span>Requisiciones</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('RequisitionRegister') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/RequisitionRegister" (click)="setPage('Registro de Requisiciones')">
          <mat-icon>add_circle</mat-icon>
          <span>Registrar</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('RequisitionConsult') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/RequisitionConsult" (click)="setPage('Consulta de Requisiciones')">
          <mat-icon>inventory</mat-icon>
          <span>Consultar</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('RequisitionControl') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/RequisitionControl" (click)="setPage('Ajuste de Catalogos')">
          <mat-icon>settings</mat-icon>
          <span>Administrar</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('RequisitionApprove') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/RequisitionApprove" (click)="setPage('Validación de Requisiciones')">
          <mat-icon>verified</mat-icon>
          <span>Validación</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('DepartureControl') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/DepartureControl" (click)="setPage('Apertura de Partidas')">
          <mat-icon>published_with_changes</mat-icon>
          <span>Apertura</span>
        </button>

        <mat-expansion-panel *ngIf="permissions().match('InvoiceReport') || permissions().match('446576656c6f706572')">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>insert_chart_outlined</mat-icon>
              <span>Reportes</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <button (click)="mep.expanded = false;"
            *ngIf="permissions().match('InvoiceReport') || permissions().match('446576656c6f706572')" mat-menu-item
            routerLink="/InvoiceReport" (click)="setPage('Reporte de Material Facturado')">
            <mat-icon>price_check</mat-icon>
            <span>Reporte de facturación</span>
          </button>
        </mat-expansion-panel>
      </mat-expansion-panel>

      <!--DESTAJOS-->
      <mat-expansion-panel #mep="matExpansionPanel"
        *ngIf="permissions().match('PieceworkRegister') || permissions().match('PieceworkConsult') || permissions().match('PieceworkControl') || permissions().match('PieceworkReport') || permissions().match('446576656c6f706572')"
        [expanded]="panelOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>engineering</mat-icon>
            <span>Destajos</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('PieceworkRegister') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/PieceworkRegister" (click)="setPage('Registro de Mano de Obra')">
          <mat-icon>add_circle</mat-icon>
          <span>Registrar</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('PieceworkConsult') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/PieceworkConsult" (click)="setPage('Consulta de Destajos')">
          <mat-icon>inventory</mat-icon>
          <span>Consultar</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('PieceworkEdit') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/PieceworkEdit" (click)="setPage('Edición de Destajos')">
          <mat-icon>edit</mat-icon>
          <span>Actualizar</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('PieceworkAprrove') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/PieceworkApprove" (click)="setPage('Validación de Destajos')">
          <mat-icon>verified</mat-icon>
          <span>Aprobar</span>
        </button>
        <!-- <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('PieceworkControl') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/PieceworkControl">
          <mat-icon>settings</mat-icon>
          <span>Administrar</span>
        </button> -->
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('PieceworkReport') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/PieceworkReport" (click)="setPage('Reporte de Mano de Obra')">
          <mat-icon>poll</mat-icon>
          <span>Reporte</span>
        </button>
      </mat-expansion-panel>

      <!--PRESUPUESTOS-->
      <mat-expansion-panel #mep="matExpansionPanel"
        *ngIf="permissions().match('BudgetRegister') || permissions().match('BudgetConsult') ||permissions().match('BudgetApprove') || permissions().match('BudgetReport') ||permissions().match('BudgetReportPiece') || permissions().match('446576656c6f706572')"
        [expanded]="panelOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>insert_chart_outlined</mat-icon>
            <span>Presupuestos</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('BudgetRegister') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/BudgetRegister" (click)="setPage('Registro de Presupuestos')">
          <mat-icon>add_circle</mat-icon>
          <span>Registrar</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('BudgetConsult') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/BudgetConsult" (click)="setPage('Consulta de Presupuestos')">
          <mat-icon>inventory</mat-icon>
          <span>Consultar</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('BudgetApprove') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/BudgetApprove" (click)="setPage('Validación de Presupuestos')">
          <mat-icon>verified</mat-icon>
          <span>Aprobación</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('BudgetReport') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/BudgetReport" (click)="setPage('Reporte de Materiales')">
          <mat-icon>poll</mat-icon>
          <span>Reporte materiales</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('BudgetReportPiece') || permissions().match('446576656c6f706572')" mat-menu-item
          routerLink="/BudgetReportPiece" (click)="setPage('Reporte de Destajos')">
          <mat-icon>poll</mat-icon>
          <span>Reporte destajos</span>
        </button>
      </mat-expansion-panel>

      <!--VALE CONSUMO-->
      <mat-expansion-panel #mep="matExpansionPanel"
        *ngIf="permissions().match('ConsumerTokenRegister') || permissions().match('ConsumerTokenConsultMainComponent') || permissions().match('ConsumerTokenModifyMain') ||permissions().match('ConsumerTokenReportMainComponent') || permissions().match('446576656c6f706572')"
        [expanded]="panelOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>confirmation_number</mat-icon>
            <span>Vale/Consumo</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('ConsumerTokenRegister') || permissions().match('446576656c6f706572')"
          mat-menu-item routerLink="/ConsumerTokenRegister" (click)="setPage('Registro de Vale de Consumo')">
          <mat-icon>add_circle</mat-icon>
          <span>Registrar</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('ConsumerTokenConsultMainComponent') || permissions().match('446576656c6f706572')"
          mat-menu-item routerLink="/ConsumerTokenConsultMainComponent" (click)="setPage('Consulta de Vale de Consumo')">
          <mat-icon>inventory</mat-icon>
          <span>Consultar</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('ConsumerTokenModifyMain') || permissions().match('446576656c6f706572')"
          mat-menu-item routerLink="/ConsumerTokenModifyMain" (click)="setPage('Edición de Vale de Consumo')">
          <mat-icon>settings</mat-icon>
          <span>Modificar</span>
        </button>
        <button (click)="mep.expanded = false;"
          *ngIf="permissions().match('ConsumerTokenReportMainComponent') || permissions().match('446576656c6f706572')"
          mat-menu-item routerLink="/ConsumerTokenReportMainComponent" (click)="setPage('Reporte de Consumo')">
          <mat-icon>poll</mat-icon>
          <span>Reporte</span>
        </button>
      </mat-expansion-panel>
    </mat-accordion>

  </div>
</div>