<mat-tab-group mat-stretch-tabs>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">add_location</mat-icon>
            Agregar ubicacion
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">

                    <h5>Registra la imagen de cada lotificacion del proyecto seleccionado: </h5>

                    <mat-form-field appearance="fill">

                        <mat-label>Proyecto</mat-label>

                        <mat-select #projectSelected [(value)]="selected_project"
                            (selectionChange)="selectProperties();">
                            <mat-option *ngFor="let project of projects" [value]="project">
                                {{project.name}}
                            </mat-option>
                        </mat-select>

                    </mat-form-field>


                    <mat-accordion>
                        <mat-expansion-panel (opened)="resetIDE()" *ngFor="let property of properties" hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <b><span>{{property.codeProperty}}</span>
                                    </b>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <table class="m19Table">
                                <thead>
                                    <tr>

                                        <th scope="col">Estatus de carga</th>
                                        <th scope="col">Imagen seleccionada</th>
                                        <th scope="col">Subir Imagen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped bg-success"
                                                    role="progressbar" [style.width]="(uploadPercent | async )+'%'">
                                                </div>
                                            </div>
                                            <input type='input' id='linkdelmap' #linkdelmapa [value]="urlImage | async "
                                                ([ngModel])="linkUpdate">
                                        </td>
                                        <td class="button-row">
                                            <div>
                                                <h5>Seleccionar el nuevo mapa a subir: </h5>
                                                <br>
                                                <input type='file' accept=".png, .jpg"
                                                    (change)="onUpload($event,selected_project.name,property.codeProperty,'')"
                                                    required>
                                            </div>
                                        </td>
                                        <td>
                                            <button class="actionBtn"
                                                [disabled]="linkUpdate == '' || linkUpdate == null"
                                                (click)="insertMap(property.codeProperty)" mat-icon-button>
                                                <mat-icon>save_alt</mat-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>



                        </mat-expansion-panel>
                    </mat-accordion>










                </mat-card-content>
            </mat-card>
        </div>

    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">location_on</mat-icon>
            visualizar ubicaciones
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">

                    <h5>Inmuebles con lotificacion: </h5>

                    <mat-form-field appearance="fill">

                        <mat-label>Proyecto</mat-label>

                        <mat-select #projectSelected [(value)]="selected_projectYet"
                            (selectionChange)="selectPropertiesReady();">
                            <mat-option *ngFor="let project of projects" [value]="project">
                                {{project.name}}
                            </mat-option>
                        </mat-select>

                    </mat-form-field>

                    <table class="m19Table">
                        <thead>
                            <tr>
                                <th scope="col">Codigo del inmueble</th>
                                <th scope="col">Proyecto</th>
                                <th scope="col">Imagen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let property of propertiesYet">
                                <td>{{property.codeProperty}}</td>
                                <td>{{selected_projectYet.name}}</td>
                                <td>
                                    <mat-icon class="actionBtn"
                                        (click)="visualSkechmap(property.codeProperty,selected_projectYet.name);">remove_red_eye</mat-icon>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">edit_location</mat-icon>
            Editar ubicaciones
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">

                    <h5>Busqueda del inmueble solicitado: </h5>

                    <mat-form-field appearance="fill">

                        <mat-label>Proyecto</mat-label>

                        <mat-select #projectSelected [(value)]="selected_projectUpdate"
                            (selectionChange)="selectPropertiesUpdate();">
                            <mat-option *ngFor="let project of projects" [value]="project">
                                {{project.name}}
                            </mat-option>
                        </mat-select>

                    </mat-form-field>

                    <table class="m19Table">
                        <thead>
                            <tr>
                                <th scope="col">Codigo del inmueble</th>
                                <th scope="col">Estatus de carga</th>
                                <th scope="col">Imagen seleccionada</th>
                                <th scope="col">Subir Imagen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let property of propertiesUpdate">
                                <td>{{property.codeProperty}}</td>
                                <td>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped bg-success" role="progressbar"
                                            [style.width]="(uploadPercent | async )+'%'">
                                        </div>
                                    </div>
                                    <input type='input' #linkdelmapa [value]="urlImage | async">
                                </td>
                                <td class="button-row">
                                    <div>
                                        <h5>Seleccionar el nuevo mapa a subir: </h5>
                                        <br>
                                        <input type='file' accept=".png, .jpg"
                                            (change)="onUpload($event,selected_project.name,property.codeProperty,'')"
                                            required>
                                    </div>
                                </td>
                                <td>
                                    <button class="actionBtn" mat-icon-button>
                                        <mat-icon>save_alt</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </mat-card-content>
            </mat-card>
        </div>

    </mat-tab>



</mat-tab-group>