<!--Principal-->
<div class="row _card" *ngIf="inmobiliaria">
  <div class="col-md-5">
    <mat-card>
      <mat-card-title>
        <h3>M19 Inmobiliaria</h3>
      </mat-card-title>
      <mat-card-content *ngFor="let morita of montos">
        <canvas baseChart width="450" height="550" [data]="[morita.abonado,morita.totales,morita.Retraso]" [labels]="pieChartLabels" [chartType]="pieChartType" [options]="pieChartOptions" (plugins)="pieChartPlugins" [colors]="pieChartColors" [legend]="pieChartLegend"></canvas>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="col-md-7">
    <mat-card>
      <mat-card-content>
        <table class="table table-striped animated fadeIn faster _reports">
          <thead class="thead-m19">
            <tr>
              <th scope="col">Desarrollo</th>
              <th scope="col">Total generado</th>
              <th scope="col">Faltante por concluir</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let morita of montosListado">
              <td>
                <button class="m19InvBtn" (click)='desVP();selectSalesDesarrollo(morita.name);selectTotalesDesarrollo(morita.name);reporteDesCartera(morita.name)'>{{morita.name}}</button>
              </td>
              <td>
                <h5>{{morita.abonado | currency:'CAD' : '$ '}}</h5>
              </td>
              <td>
                <h5>{{morita.totales | currency:'CAD' : '$ '}}</h5>
              </td>
            </tr>
          </tbody>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<!--Selección de un proyecto-->
<div class="row _card" *ngIf="VP">
  <div class="col-md-4">
    <mat-card>
      <mat-card-title *ngFor="let venta of sales; first as primero">
        <h5 *ngIf="primero">{{venta.name}}</h5>
      </mat-card-title>
      <mat-card-content *ngFor="let morita of montos">
        <canvas baseChart width="325" height="465" [data]="[morita.abonado,morita.totales,morita.Retraso]" [labels]="pieChartLabels" [chartType]="pieChartType" [options]="pieChartOptions" ([plugins])="pieChartPlugins" [colors]="pieChartColors" [legend]="pieChartLegend"> </canvas>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="col-md-8">
    <mat-card>
      <mat-card-content>

        <button class="btn btn-success" (click)="inmu();selectTotales()">Inmobiliaria</button>
        <p></p>
        <table class="table table-striped animated fadeIn faster">
          <thead class="thead-m19">
            <tr align="center">
              <th scope="col">Inmueble</th>
              <th scope="col">Cliente</th>
              <th scope="col">Vendedor</th>
              <th scope="col">Pagos atrasados</th>
              <th scope="col">Bloquear</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let venta of cartDes" align="center">
              <td>
                <button class="btn btn-m19" (click)="desX();slectPayments(venta.codeProperty);reporteInmuCartera(venta.codeProperty)" id="global">
                  {{venta.codeProperty}}
                </button>
              </td>
              <td>{{venta.clientes}}</td>
              <td>{{venta.usuarios}}</td>
              <td>
                <div>
                  {{venta.retraso.toFixed(1)}}
                </div>

                <div class="progress" *ngIf="venta.retraso>=0;">
                  <div class="progress-bar progress-bar-striped " role="progressbar" style="width: 100%" aria-valuenow=bien aria-valuemin="0" aria-valuemax=100></div>
                </div>

                <div class="progress" *ngIf="venta.retraso < 0 && venta.retraso > tolerancia2" ngElse="warning2">
                  <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 100%" aria-valuenow=rojo aria-valuemin="0" aria-valuemax=100></div>
                </div>

                <div class="progress" *ngIf="venta.retraso <= tolerancia2 && venta.retraso >tolerancia3" ngElse="warning3">
                  <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%" aria-valuenow=rojo aria-valuemin="0" aria-valuemax=100></div>
                </div>

                <div class="progress" *ngIf="venta.retraso <= tolerancia3 && venta.retraso >limite" ngElse="danger">
                  <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%" aria-valuenow=rojo aria-valuemin="0" aria-valuemax=100></div>
                </div>

                <div class="progress" *ngIf="venta.retraso <=limite">
                  <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%" aria-valuenow=mal aria-valuemin="0" aria-valuemax=100></div>
                </div>
                
              </td>
              <td>
                <mat-icon class="btnBlock" (click)="blockSale(venta.codeProperty, venta.retraso)">lock</mat-icon>
              </td>
            </tr>
          </tbody>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<!--Selección de inmueble en especifico-->
<div *ngIf="X">

  <div class="container-fluid mt-3">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">
          <h6>Seleccione desarrollo que desea consultar</h6>
          <mat-button-toggle-group>
            <mat-button-toggle *ngFor="let morita of montosListado" (click)='desVP();reporteDesCartera(morita.name)'>
              {{morita.name}}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </mat-card-content>
    </mat-card>
  </div>

  <div class="row _card">
    <div class="col-md-4">
        <mat-card>
            <mat-card-content *ngFor="let venta of carteraInmu">
                <table>
                    <thead>
                        <tr>
                            <h5>Desarrollo:</h5>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <h5 class="_h5">{{venta.name}}</h5>
                        </tr>
                    </tbody>

                    <table *ngIf="venta.codeProperty.substr(2,1)=='M'">
                      <tr>
                          <th>
                              <h5>{{M}}</h5>
                          </th>
                          <th>
                              <h5 class="_h5">{{venta.codeProperty.substr(3,2)}}</h5>
                          </th>
                          <th></th>
                          <th>
                              <h5>{{L}}</h5>
                          </th>
                          <th>
                              <h5 class="_h5">{{venta.codeProperty.substr(6,2)}}</h5>
                          </th>
                      </tr>
                  </table>
  
                  <table *ngIf="venta.codeProperty.substr(2,1)=='T'">
                      <tr>
                          <th>
                              <h5>{{T}}</h5>
                          </th>
                          <th> 
                              <h5 class="_h5">{{venta.codeProperty.substr(3,2)}}</h5>
                          </th>
                          <th></th>
                          <th>
                              <h5>{{D}}</h5>
                          </th>
                          <th>
                              <h5 class="_h5">{{venta.codeProperty.substr(6,2)}}</h5>
                          </th>
                      </tr>
                  </table>

                    <thead>
                        <tr>
                            <h5>Cliente:</h5>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <h5 class="_h5">{{venta.clientes}}</h5>
                        </tr>
                    </tbody>

                    <thead>
                        <tr>
                            <h5>Vendedor:</h5>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <h5 class="_h5">{{venta.usuarios}}</h5>
                        </tr>
                    </tbody>

                    <thead>
                        <tr>
                            <h5>Fecha de contrato:</h5>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <h5 class="_h5">{{venta.fechaDato | date: 'dd-MM-yyyy'}}</h5>
                        </tr>
                    </tbody>

                    <thead>
                        <tr>
                            <h5 *ngIf="venta.mobile!=null">Celular:</h5>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <h5 class="_h5">{{venta.mobile}}</h5>
                        </tr>
                    </tbody>

                    <thead>
                        <tr>
                            <h5>Correo electrónico:</h5>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <h5 class="_h5">{{venta.email}}</h5>
                        </tr>
                    </tbody>
                </table>

                <table>
                    <tr>
                        <th>
                            <h5>Valor total:</h5>
                        </th>
                        <th>
                            <h5 class="_h5"> {{venta.valor | currency:'CAD' : '$ '}}</h5>
                        </th>
                    </tr>
                </table>

                <div class="row mt-2" style="padding-left: .4cm;">
                    <div style="padding-right: 1cm;">
                        <span class="input-group-text" id="entradaPagado"
                            style="background-color: brown; border-radius:.3cm; color: aliceblue; height: 1cm; width: auto;">
                            <b> Abonado</b>
                        </span>
                        <div>
                            <h5> {{venta.abonado | currency:'CAD' : '$ '}} </h5>
                        </div>
                    </div>
                    <div>
                        <span class="input-group-text" id="entradaPagado"
                            style="background-color: brown; border-radius:.3cm; color: aliceblue; height: 1cm; width: auto;"><b>Adeudo</b></span>
                        <div>
                            <h5> {{venta.valor-venta.abonado | currency:'CAD' : '$ '}} </h5>
                        </div>
                    </div>
                </div>


                <table class="table table-hover mt-3">
                    <thead>
                        <tr align="center">
                            <td>Pagos realizados</td>
                            <td>Pagos esperados</td>
                            <td>Estado de pagos</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr align="center">
                            <td> 
                              <a>{{venta.mPagadas+'/'+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(1)}}</a>
                            </td>
                            <td>
                              <a>
                                <div>{{venta.meses+'/'+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(1)}}</div>

                                <div *ngIf="venta.retraso>=0;">
                                  <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%"></div>
                                  </div>
                                </div>

                                <div *ngIf="venta.retraso < 0 && venta.retraso > tolerancia2" ngElse="warning2">
                                  <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" style="width: 100%"></div>
                                  </div>
                                </div>

                                <div *ngIf="venta.retraso <= tolerancia2 && venta.retraso >tolerancia3" ngElse="warning3">
                                  <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style="width: 100%"></div>
                                  </div>
                                </div>

                                <div *ngIf="venta.retraso <= tolerancia3 && venta.retraso >limite" ngElse="danger">
                                  <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style="width: 100%"></div>
                                  </div>
                                </div>

                                <div *ngIf="venta.retraso <=limite">
                                  <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" style="width: 100%"></div>
                                  </div>
                                </div>
                              </a>
                            </td>
                            <td>{{venta.retraso.toFixed(1)}}</td>
                        </tr>
                    </tbody>
                </table>
            </mat-card-content>

        </mat-card>
    </div>

    <div class="col-md-8">
        <mat-card>
            <mat-card-content>
                <table class="table table-striped animated fadeIn faster">
                    <thead class="thead-m19">
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">Folio</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Método de pago</th>
                            <th scope="col">Concepto</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let payment of payments; let i=index;">
                            <th>{{i+1}}</th>
                            <th>{{payment.folioPago}}</th>
                            <td>{{payment.fechaPago | date: 'dd-MM-yyyy'}}</td>
                            <td>{{(payment.cantidadAbono | currency:'CAD' : '$ ')}}</td>
                            <td>{{payment.metodoPago}}</td>
                            <td>{{payment.concepto}}</td>
                        </tr>
                    </tbody>
                </table>
            </mat-card-content>
        </mat-card>
    </div>
  </div>
</div>
