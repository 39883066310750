<div class="contedor-principal" (scroll)="onScroll()" >
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">
            <h1 style="text-align: center;">Bitácora por fechas </h1>
            <br>

            <!-- <div style="display: flex; flex-direction: row; justify-content: space-evenly; background-color: #21242b;">
                <div class="container-counter">
                    <mat-icon class="icono"> favorite </mat-icon>
                    <span class="counter" data-value="850"> 000 </span>
                    <span class="texto"> Amor </span>
                </div>
                <div class="container-counter">
                    <mat-icon class="icono"> star </mat-icon>
                    <span class="counter" data-value="50"> 000 </span>
                    <span class="texto"> Estrellas </span>
                </div>
                <div class="container-counter">
                    <mat-icon class="icono"> mood </mat-icon>
                    <span class="counter" data-value="1000"> 000 </span>
                    <span class="texto"> Sonrisas </span>
                </div>
                <div class="container-counter">
                    <mat-icon class="icono"> lock </mat-icon>
                    <span class="counter" data-value="150"> 000 </span>
                    <span class="texto"> Seguridad </span>
                </div>

            </div> -->

            <div class="filters">

                <mat-form-field appearance="outline">
                    <mat-label>Proyecto</mat-label>
                    <mat-select [(value)]="selectProjects"
                        (valueChange)="project.id=($event).split('-')[0]; project.name=($event).split('-')[1];"
                        required>
                        <mat-option *ngFor="let project of projects" [value]="project.idProject+'-'+project.name">
                            {{project.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" *ngIf="project.name != ''">
                    <mat-label>Seleccione inicio a fin </mat-label>
                    <mat-date-range-input [formGroup]="formRange" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Inicio">
                        <input matEndDate formControlName="end" placeholder="Fin" (dateChange)="onDateChange()">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>

                    <mat-error *ngIf="formRange.controls.start.hasError('matStartDateInvalid')">Fecha
                        de inicio incorrecta</mat-error>
                    <mat-error *ngIf="formRange.controls.end.hasError('matEndDateInvalid')">Fecha de
                        finalizacion incorrecta</mat-error>
                </mat-form-field>

                <button *ngIf="inv == true" class="m19Btn" on-click="printReport()"> imprimir reporte </button>

            </div>

            <div id="logbookDoc" [hidden]="false" style="overflow: scroll;">
                <table class="controlPDF">
                    <thead>
                        <tr>
                            <td colspan="1" rowspan="2" style="padding: 10px;"><img
                                    src="../../../../assets/imagotipo.png"
                                    style="display: block; width: 100px; margin: auto;"></td>
                            <td colspan="4" style="text-align: center;"><b>INICIO</b><br> <span
                                    style="font-weight: 100;"> {{this.pipe.transform(formRange.value.start ,
                                    "fullDate")}} </span> </td>
                            <td colspan="1" style="text-align: center; font-weight: bold;">GENERADOR DE CONTROL DE OBRA
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4" style="text-align: center;"><b>FIN</b><br> <span style="font-weight: 100;">
                                    {{this.pipe.transform(formRange.value.end , "fullDate")}} </span> </td>
                            <td colspan="1" style="text-align: center;"><b>PROYECTO </b> <br> <span
                                    style="font-weight: 100;"> {{project.name}} </span> </td>
                        </tr>
                        <tr>
                            <td>CONCEPTO</td>
                            <td>TRAMO</td>
                            <td>M.O.</td>
                            <td>HORA</td>
                            <td>FECHA</td>
                            <td>OBSERVACIONES</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let dat of log" style="page-break-inside: avoid;">
                            <td class="td2">
                                {{dat.descriptions}}
                            </td>
                            <td style="max-width: 200px; text-align: center;font-weight: 300;">
                                {{dat.towernumber}}
                            </td>
                            <td style="white-space: nowrap;font-weight: 300;">
                                {{dat.workmanship}}
                            </td>
                            <td style="font-weight: 300;">
                                {{dat.fechaRegistro.substring(0,10) | date: "dd-MM-yyyy"}}
                            </td>
                            <td style="white-space: nowrap; font-weight: 300;">
                                {{pipe.transform(dat.fechaRegistro , "hh:mm:ss aa")}}
                            </td>
                            <td class="td1">
                                {{dat.remarks}}
                            </td>
                        </tr>
                    </tbody>
                </table>


                <br>
                <br>
                <table class="signArea">
                    <tr>
                        <td>ÁREA TECNICA</td>
                        <td>RESIDENCIA EDIFICACIÓN</td>
                        <td>RESIDENCIA DE URBANIZACIÓN</td>
                        <td>ÁREA DE CONTROL Y SUPERVISIÓN</td>
                    </tr>
                    <tr>
                        <td style="font-weight: 50;">ING. GERMAN LUNA GAMBOA</td>
                        <td style="font-weight: 50;">ING. JUAN CARLOS GONZALEZ ESCOBEDO</td>
                        <td style="font-weight: 50;">ARQ. HECTOR ARELLANO</td>
                        <td style="font-weight: 50;">ING. CARLOS BETANCOURT GONZALEZ</td>
                    </tr>
                </table>

                <br>
                <mat-divider style="background-color: var(--M19Primary); "></mat-divider>
                <br>

                <div >

                    <table class="signArea" style="page-break-before:always;">
                        <thead>
                            <tr>
                                <td colspan="2" rowspan="2" style="padding: 10px;"><img
                                        src="../../../../assets/isotipo.png"
                                        style="display: block; width: 100px; margin: auto;"></td>
                                <td colspan="3" style="text-align: center;"><b>PROYECTO </b> <br> <span
                                        style="font-weight: 100;"> {{project.name}} </span> </td>
                                <td colspan="1" style="text-align: center;"><b>INICIO </b> <br> <span
                                        style="font-weight: 100;"> {{this.pipe.transform(formRange.value.start ,
                                        "dd/MM/yyyy")}} </span> </td>
                                <td colspan="1" style="text-align: center;"><b>FIN</b><br> <span
                                        style="font-weight: 100;"> {{this.pipe.transform(formRange.value.end ,
                                        "dd/MM/yyyy")}} </span> </td>

                            </tr>


                            <tr>
                                <td colspan="4" style="text-align: center;"><b> REPORTE FOTOGRAFICO </b> <br> <span
                                        style="font-weight: 100;"> </span> </td>
                                <!-- <td colspan="1" style="text-align: center;"><b> CONCEPTO </b> <br> <span
                                        style="font-weight: 100;"> </span> </td> -->
                                <td colspan="1" style="text-align: center;"><b> FECHA DE EST </b><br> <span
                                        style="font-weight: 100;"> {{horalocal}} </span> </td>
                            </tr>

                        </thead>

                    </table>

                  
                    
                    <br>
    
                                    <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-evenly; border-top: 1px solid black; border-bottom: 1px solid black; margin-bottom: 20px; margin-top: 20px; page-break-before:void;">
                                        <span *ngFor="let ev of arr" >
                                            <img style="width: 250px; height: 220px; max-width: 350px; max-height: 250px; margin: 10px; margin-top: 20px;" [src]="ev.docurl" [title]="ev.docname">
                                            <h5 style="text-align: center;"> {{ev.docname.substring(0,10) | date: "dd-MM-yyyy"}} </h5>
                                        </span>

                                    </div>

                    <br>

                    <table class="signArea" style=" margin-top: 8px ;">
                        <tr>
                            <td>ÁREA TECNICA</td>
                            <td>RESIDENCIA EDIFICACIÓN</td>
                            <td>RESIDENCIA DE URBANIZACIÓN</td>
                            <td>ÁREA DE CONTROL Y SUPERVISIÓN</td>
                        </tr>
                        <tr>
                            <td style="font-weight: 50;">ING. GERMAN LUNA GAMBOA</td>
                            <td style="font-weight: 50;">ING. JUAN CARLOS GONZALEZ ESCOBEDO</td>
                            <td style="font-weight: 50;">ARQ. HECTOR ARELLANO</td>
                            <td style="font-weight: 50;">ING. CARLOS BETANCOURT GONZALEZ</td>
                        </tr>
                    </table>

                </div>

            </div>


        </mat-card-content>

        <button  class="btnFlotante" (click)="onTopScroll()"  *ngIf="shwButton==true;" title="Ir al inicio" > <b style="transform: rotate(45deg)"> < </b> </button>

    </mat-card>

    
</div>