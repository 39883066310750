import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SweetAlertComponent } from '../../../../utils/sweet-alert/sweet-alert.component';
import { ControComissionsService } from 'src/app/services/contro-comissions.service';
import { ControlComission } from 'src/app/models/ControlComission';
import { data } from 'jquery';
import decode from 'jwt-decode';
import { inject } from '@angular/core/testing';

@Component({
  selector: 'app-dialog-update-view',
  templateUrl: './dialog-update-view.component.html',
  styleUrls: ['./dialog-update-view.component.scss']
})
export class DialogUpdateViewComponent implements OnInit {


  payComFormGroup: FormGroup = this._formBuilder.group({
    idPaymentCommission:[],
    folio: [, Validators.required],
    paymentDate: [, Validators.required],
    paymentConcept: [],
    paymentAmount: [],
    paymentStatus: [],
    fk_salesCoordinator: [],
    fk_payer:[],
    registrationDate: [],
    modificationDate:[],
    modificationNotes: [, Validators.required],
    userModification: [],
    fk_property: [, Validators.required],
  });

  concepto = [
    {tipo: "Anticipo"},
    {tipo: "Mensualidad"}
  ]
    
  
  conc = "Anticipo"

  constructor( 
    private dialogRef: MatDialogRef<DialogUpdateViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ControlComission,
    private _formBuilder: FormBuilder,
    private controlService: ControComissionsService,
    public sweet: SweetAlertComponent

  ) {
    this.dialogRef.disableClose = true;
    this.paydata = data;
    
   }

   paydata : any = [];
   decode: any = {}; 

   decode2: any = ""; 

  ngOnInit(
    
   ): void { 

    this.getUser();
    this.asigName();
    
    
  }



 
  updatePayConcept(id: number, paydata: any){
    if (this.payComFormGroup.invalid){
      return;
    }
    else { 
      paydata = this.payComFormGroup.value
      this.controlService.updateDataConceptByFolio(id,paydata).subscribe(
        res => {
          
          this.sweet.AlertTime("center", "success", "Concepto de pago actualizado con exito", false, 2000);
          this.dialogRef.close();
          
        },
        err => {
          console.error(err);
          console.log(paydata)
          this.sweet.AlertTime("center", "error", "Error al realizar la operación", false, 2000);
        }
      );  
    } //else

  }


  getUser() {
    
    this.decode = decode(localStorage.getItem("token") || "" )
    //let aux = this.decode['cliente']
    //console.log("Soy el cliente con nombre: → " , this.decode.cliente)  forma 1 de obtener el cliente
    //console.log("Soy el cliente con nombre: → " , this.decode['cliente']) 
    // console.log("Soy el cliente con nombre: → " , aux)   → forma 3 de obtener el cliente logueado
    return this.decode['cliente']
  }

  asigName(){
    this.decode2 = this.getUser()
    
    }

}

