<h1>{{data.codeProperty}}</h1>

<mat-divider></mat-divider>

<mat-dialog-content>
    <form class="row mt-3" [formGroup]="saleFormGroup">

        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>Folio de contrato</mat-label>
            <input matInput #idContrat maxlength="5" minlength="5" type="text" formControlName="IdContract" placeholder="CB000" [(ngModel)]="sale.IdContract">
            <mat-hint align="end">{{idContrat.value.length}} / 5</mat-hint>
        </mat-form-field>

        <h5></h5>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Fecha de contrato</mat-label>
            <input matInput type="date" formControlName="contractDate" [(ngModel)]="sale.contractDate">
        </mat-form-field>


    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
    <button mat-button class="btn btn-m19" (click)="updateSale(sale.idSale,sale)">Guardar</button>
</mat-dialog-actions>