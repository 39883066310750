//=========================================================================================================
//Importaciones de servicios necesitados
//=========================================================================================================
import { PieceworkService } from 'src/app/services/piecework.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';

//=========================================================================================================
//Importaciones de los componentes necesitados
//=========================================================================================================
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPieceworksComponent } from './dialog-pieceworks/dialog-pieceworks.component';
import { PieceworkUpdateComponent } from './piecework-update/piecework-update.component';

import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';


import { SweetAlertComponent } from '../../../utils/sweet-alert/sweet-alert.component';
import Swal from 'sweetalert2';
import decode from 'jwt-decode';

@Component({
  selector: 'app-pieceworkconsult',
  templateUrl: './pieceworkconsult.component.html',
  styleUrls: ['./pieceworkconsult.component.scss']
})

export class PieceworkconsultComponent implements OnInit {
  bannerTitle: BannerTitle = {
    title: "Consulta de destajos",
    icon: "handyman",
    position: "center",
    background: "var(--M19Secundary)"
  }

  cbrLogo: any = "https://firebasestorage.googleapis.com/v0/b/m19inmobiliaria-f6068.appspot.com/o/logos%2FConstructora%2FASESORIA%2C%20SUPERVISI%C3%93N%20Y%20CONTRUCCI%C3%93N.png?alt=media&token=356f1765-4f7e-48af-9fb9-bc6584b0b09a";

  projects: any = [];
  towers: any = [];
  weeks: any = [];

  project: any = '';
  tower: any = 0;
  week: any = 0;
  maker: any = '';
  auth: any = '';
  lastpiecework: any ;

  //Objeto que contiene las partidas encontradas al seleccionar una requisición
  pieceworks: any = [];
  piece: any = [];

  modules: any = [];

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  constructor(
    //======================================================================
    //Declaraciones de los servicios utilizados
    public pieceworkService: PieceworkService,
    public projectService: ProjectService,
    public projectDetailService: ProjectdetailsService,



    public sweet: SweetAlertComponent,
    //======================================================================
    //Declaración del componente gráfico utilizado para cuadro de dialogo
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getProjects();
    this.getMaker();
    this.getlastPiecework();
  }

  print(): void {
    if (this.auth == '') {
      this.sweet.AlertTime("center", "warning", "Se necesita indicar un responsable.", false, 2000);
      return;
    }
    Swal.fire({
      title: 'Ingresa un nombre para el archivo',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Generar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        let printContents, popupWin;
        console.log(result.value);
        this.auth = result.value;
        printContents = document.getElementById('htmlData')!.innerHTML;
        popupWin = window.open('', '', '');
        popupWin!.document.open();
        popupWin!.document.write(`
      <html>
        <head>
          <title>Nomina Destajos</title>
          <style>
            table {
              width: 100%;
              margin-bottom: 20px;
            }
            table, th, tr, td {
              border-collapse: collapse;
              border: solid black 2px;
            }
            td {
              font-size: 0.8rem;
            }
            img {
              margin: auto;
            }
            .datos {
              text-align: left;
            }
            .firmas {
              display: flex;
              width: 100%;
              border: solid black 2px;
              padding-top: 70px;
            }
            .firmas .elabora {
              width: 50%;
              text-align: center;
            }
            .firmas .aprueba {
              width: 50%;
              text-align: center;
            }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
        );
        popupWin!.document.close();
      }
    })

  }

  setAuth(o: any) {
    if (o.data !== null) {
      this.auth = this.auth + o.data;
    }
    if (o.data == null) {
      this.auth = this.auth.substring(0, this.auth.length - 1);
    }
    console.log(this.auth);
  }

  getModulesByProject(id: any, name: any) {
    this.project = name;
    this.pieceworks = [];
    this.projectDetailService.selectDetailsById(id).subscribe(
      res => {
        this.modules = res;
      }
    );
  }

  filterByModule(id: any) {
    this.tower = id;
    this.pieceworks = [];
    this.pieceworkService.getWeeks(id).subscribe(
      res => {
        this.weeks = res;
      }
    );
  }

  setWeek(w: number, t: number) {
    this.pieceworks = [];
    this.pieceworkService.searchByWeek(w, t).subscribe(
      res => {
        this.pieceworks = res;
        console.log(this.pieceworks);
        this.week = w;
      }
    );
  }

  async getProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      }
    );
  }

  //=========================================================================================================
  //Función para elegir los destajos de una requisición por su ID
  //=========================================================================================================
  async selectPiecework(num: number) {
    this.dialog.open(DialogPieceworksComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '100%',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: num
    });
  }

  
  //=========================================================================================================
  //Función que llama un cuadro de dialogo de confirmación para borrar presupuesto por su ID
  //=========================================================================================================
  async deletePiecework(id: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, mejor no',
      confirmButtonText: 'Sí, elimínalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'Este destajo fue eliminado.',
          'success'
        )
        /*this.pieceworkService.deletePiece(id).subscribe(
          res => {
            this.setWeek(this.week);
          }
        )*/
      }
    })
  }

  getMaker() {
    this.decode = decode(localStorage.getItem("token") || "")
    this.maker = this.decode.cliente;
  }

  async getlastPiecework() {
    this.pieceworkService.getLastPieceworks().subscribe(
      res => {
        this.lastpiecework = res;
        console.log('Numero',this.lastpiecework.last);
      },
      err => console.log(err)
    );
  }
}
