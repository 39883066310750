<!-- <mat-dialog-container>
    <div style="text-align: center; margin: auto;" class="example-labels">¿El cliente ya se encuentra registrado?</div>

    <div class="example-button-row" style="text-align: center">
        <button mat-flat-button color="primary" (click)="ClienteYet()">Si</button>
        <button mat-flat-button color="primary" (click)="ClienteNot()">No</button>
    </div>
</mat-dialog-container> -->
<mat-divider></mat-divider>
<div style="text-align: center; margin: auto;"  class="example-labels">¿El cliente ya se encuentra registrado?</div>
<div class="example-button-row" style="text-align: center">
    <button mat-flat-button color="primary" (click)= "ClienteYet()">Si</button>
    <button mat-flat-button color="primary" (click)= "ClienteNot()">No</button>
</div>
<mat-divider></mat-divider>