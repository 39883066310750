import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

//Importación para utilizar el modelo de proveedor
import { Supplier } from '../models/supplier';

@Injectable({
  providedIn: 'root'
})
export class SuppliersService {

  constructor(private http: HttpClient) { }

  //Insertar un proveedor
  insertSupplier(sup: Supplier): Observable<any>{
    return this.http.post(`${environment.URLServer}/suppliers/insertSup`,sup);
  }

  //Consultar el catalogo de proveedores
  selectSuppliers(): Observable<any> {
    return this.http.get(`${environment.URLServer}/suppliers/selectSup`);
  }

  //Buscar un proveedor por id
  searchSupplier(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/suppliers/searchSup/${id}`);
  }

  //Actualizar la información de un proveedor
  updateSupplier(id:number, sup: Supplier): Observable<any> {
    return this.http.put(`${environment.URLServer}/suppliers/updateSup/${id}`,sup);
  }

  //Eliminar un proveedor del catalogo
  deleteSupplier(id: Number): Observable<any> {
    return this.http.put(`${environment.URLServer}/suppliers/deleteSup`,[id]);
  }

}