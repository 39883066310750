'@angular/material/radio';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ControComissionsService } from 'src/app/services/contro-comissions.service';
import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { DatePipe } from '@angular/common';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import jwtDecode from 'jwt-decode';
import { PdfMakerComponent } from 'src/app/utils/pdf-maker/pdf-maker.component';
import Swal from 'sweetalert2';
import { auto } from '@popperjs/core';
import { timer } from 'rxjs';

@Component({
  selector: 'app-cash-cut',
  templateUrl: './cash-cut.component.html',
  styleUrls: ['./cash-cut.component.scss']
})
export class CashCutComponent implements OnInit {
  router: any;

  constructor(
    private contrlCommission: ControComissionsService,
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    private ticketOutputs: PdfMakerComponent
  ) { }

  //Variables para usar fechas en horario Local es-MX
  pipe = new DatePipe('es-MX');
  currentDate = new Date();

  //Variable para seleccion de fechas (Busquedas)
  fechaSeleccionada: any | null;

  //Variables para el ngAutoComplete
  employ: any = [];
  keyword = 'empleado';  //Hace referencia al campo que usa como busqueda 


  //Variables Bandera para Activacion de vistas
  simple = true;
  range = false;

  //Variable para el usuario logueago
  decode: any = [];

  //Variable para guardar el nombre del solicitante de efectivo
  solicitante = "";

  //Variables para la extracion de los pagos realizados
  dataPaymets: any = [];
  paymentaToday: any = [];
  salCashToday: any = [];
  typePayment = "";
  totalIngresos = 0;

  //variables para las salidas de efectivo
  salCash: any = [];
  totalEgresos = 0;
  totPayIng = 0;
  totPayEgre = 0;

  //Guardara el total vendido de cada metodo de pago para el CORTE DE CAJA
  cashTotal: any = []
  depositTotal: any = []
  transferTotal: any = []
  creditCardTotal: any = []
  debitCardTotal: any = []
  checkTotal: any = []
  everythingTotal: any = []


  //banderas para activar o desactivar los metodos de pago
  flagCash = false;
  flagDeposit = false;
  flagTransfer = false;
  flagCreditCard = false;
  flagDebitCard = false;
  flagCheck = false;
  flagEverything = false;

  //variables para guardar las sumas totales de cada metodo de pago
  totCash = 0;
  totDeposit = 0;
  totTranser = 0;
  totCreditCard = 0;
  totDebitCard = 0;
  totCheck = 0;
  totEverything = 0;

  fechaActual: any | null;
  fechaActualTickets: any | null;


  ///////////////////////// Consulta de Ingresos y egresos (UNA FECHA)////////////////////////////////////

  //Almacenara los resultados de cada consulta por tipo y una fecha
  cashTotalIO: any = []
  depositTotalIO: any = []
  transferTotalIO: any = []
  creditCardTotalIO: any = []
  debitCardTotalIO: any = []
  checkTotalIO: any = []
  everythingTotalIO: any = []


  //variables para guardar las sumas totales de cada metodo de pago
  totCashIO = 0;
  totDepositIO = 0;
  totTranserIO = 0;
  totCreditCardIO = 0;
  totDebitCardIO = 0;
  totCheckIO = 0;
  totEverythingIO = 0;

  //variables para las salidas de efectivo
  salCashIO: any = [];
  totalEgresosIO = 0;
  totPayIngIO = 0;
  totPayEgreIO = 0;

  idCashOut = "";

  ///////////////////////// Consulta de Ingresos y egresos (RANGO) ////////////////////////////////////

  //Almacenara los resultados de cada consulta por tipo y una fecha
  cashTotalIORange: any = []
  depositTotalIORange: any = []
  transferTotalIORange: any = []
  creditCardTotalIORange: any = []
  debitCardTotalIORange: any = []
  checkTotalIORange: any = []
  everythingTotalIORange: any = []


  //variables para guardar las sumas totales de cada metodo de pago
  totCashIORange = 0;
  totDepositIORange = 0;
  totTranserIORange = 0;
  totCreditCardIORange = 0;
  totDebitCardIORange = 0;
  totCheckIORange = 0;
  totEverythingIORange = 0;

  //variables para las salidas de efectivo
  salCashIORange: any = [];
  totalEgresosIORange = 0;
  totPayIngIORange = 0;

  flagCashRange = true;
  flagDepositRange = false;
  flagTransferRange = false;
  flagCreditCardRange = false;
  flagDebitCardRange = false;
  flagCheckRange = false;
  flagEverythingRange = false;

  fecIni: any = "";
  fecFin: any = String || null;

  //Información para la reimpresion de tickets
  arrayDataTicket: any = [];

  ngOnInit(): void {
    this.getUser();
    this.employeesDataInformation();
    registerLocaleData(localeEsMx, 'es-MX'); //REGISTRAR LOCAL PARA ESPAÑOL this.currentDate.toISOString()

    this.cashCutFormGroup.controls['curDate'].setValue(this.pipe.transform(this.currentDate, ('yyyy-MM-dd')));
    this.querysDate.controls['dateSimple'].setValue(this.pipe.transform(this.currentDate, ('yyyy-MM-dd')));
    this.querysDate.controls['dateEnd'].setValue(this.pipe.transform(this.currentDate, ('yyyy-MM-dd')));


    this.fecFin = this.pipe.transform(this.currentDate, ('yyyy-MM-dd'))

    this.fechaActualTickets = this.pipe.transform(this.currentDate, ('dd-MM-yyyy'));
    this.fechaActual = this.pipe.transform(this.currentDate, ('yyyy-MM-dd'))
    this.fechaSeleccionada = this.pipe.transform(this.currentDate, ('yyyy-MM-dd'))


    //CAJA
    this.getAllPaymentsByDepositAndDate();
    this.getAllPaymentsByTransferAndDate();
    this.getAllPaymentsByTDCAndDate();
    this.getAllPaymentsByTDDAndDate();
    this.getAllPaymentsByCheckAndDate();
    this.getAllPaymentsByCashAndDate();
    this.getAllPaymentsByCashAndDate();
    this.getAllPaymentsByDate();
    this.salidasDiarias();

    //I-O
    this.entradasPorFechaTransferencias();
    this.entradasPorFechaDepositos();
    this.entradasPorFechaTDC();
    this.entradasPorFechaTodo();
    this.entradasPorFechaTDD();
    this.entradasPorFechaCheques();
    this.entradasPorFechaEfectivo();

    this.salidasPorFecha();
    
  }

  querysDate: FormGroup = this._formBuilder.group({
    dateSimple: [,],
    dateStart: [,],
    dateEnd: [,],
    outputs: [,]
  });

  CashOutflowsFormGroup: FormGroup = this._formBuilder.group({
    quantity: [,],
    reason: [,],
    requestor: [null],
    cashManager: [this.getUser()],
    registrationDate: []

  });

  cashCutFormGroup: FormGroup = this._formBuilder.group({
    entries: [this.totPayIng,], //lista
    outputs: [this.totPayEgre,], //lista
    curDate: [,], //lista
    responsible: [this.getUser(),],
    partialCut: [,],
    cachClose: [,],

  });


  ////////////////////////////////////////   CORTE DE CAJA  //////////////////////////////////////////////////

  async corteCaja() {

    const value = await Swal.fire({
      title: '  <FONT COLOR="#000000"> <strong> ¡Cierre del caja! </strong> </FONT> ',
      icon: "info",
      iconColor: "#0259bd",
      width: auto,
      showConfirmButton: true,
      showCancelButton: true,


      inputPlaceholder: 'Seleccione a la persona que se le entregara el dinero',
      input: 'select',
      inputOptions: this.responsables,

      html: 'Seleccione en la parte inferior al responsable ' +
        '<br>que se le hara la entrega de <strong> <u>efectivo</u></strong>.' +
        '<br><br><b>  Seleccione una opcion de la lista y presione continuar. </b>'   ,

      confirmButtonText: 'Continuar',
      cancelButtonText: `Cancelar`,
      buttonsStyling: false, //Permite customizar los botones 

      customClass: {
        actions: 'sweetActions',
        confirmButton: 'm19AltBtn',
        cancelButton: 'm19InvBtn',
        input: 'sweetInput'
      },

      inputValidator: (value) => {
        return new Promise((resolve) => { value === "" ? resolve("Debes elegir una opción de la lista") :  this.assign = this.responsables[value]; resolve(''); })
      }

    }).then((result) => {
      this.contrlCommission.getAllEmployeesService().subscribe(
        res => { if (result.isConfirmed) { this.print() } else if (result.isDismissed) { result.dismiss  } }
      )
    })
    
  }

  async selectInfoOnChange() {
    this.fechaActual = this.currentDate;

    this.getAllPaymentsByDepositAndDate();
    this.getAllPaymentsByTransferAndDate();
    this.getAllPaymentsByTDCAndDate();
    this.getAllPaymentsByTDDAndDate();
    this.getAllPaymentsByCheckAndDate();
    this.getAllPaymentsByCashAndDate();
    this.getAllPaymentsByDate();

    this.salidasDiarias();

  }

  async salidasDiarias() {
    this.salCashToday = [];

    this.contrlCommission.getAllOutputsByDateService(this.cashCutFormGroup.value.curDate).subscribe(
      res => {
        this.salCashToday = res

        this.sumEgresosToday();
      }
    )
  }

  async sumEgresosToday() {
    this.totPayEgre = 0;

    for (let i = 0; i < this.salCashToday.length; i++) {
      this.totPayEgre += this.salCashToday[i].quantity;

    }
    this.cashCutFormGroup.controls['outputs'].setValue(this.totPayEgre);

  }

  async getAllPaymentsByCashAndDate() {

    this.contrlCommission.getPaymentsByCashService(this.cashCutFormGroup.value.curDate).subscribe(
      res => {
        this.cashTotal = res;

        this.flagCash = true;
        this.flagDeposit = false;
        this.flagTransfer = false;
        this.flagCreditCard = false;
        this.flagDebitCard = false;
        this.flagCheck = false;
        this.flagEverything = false;
        this.sumPaymentsByCashAndDate()
      }
    )
  }

  async sumPaymentsByCashAndDate() {
    this.totCash = 0;
    for (let i = 0; i < this.cashTotal.length; i++)
      this.totCash += this.cashTotal[i].cantidadAbono;

    return this.totCash;
  }

  async getAllPaymentsByDepositAndDate() {

    this.contrlCommission.getPaymentsByDepositService(this.cashCutFormGroup.value.curDate).subscribe(
      res => {
        this.depositTotal = res;

        this.flagCash = false;
        this.flagDeposit = true;
        this.flagTransfer = false;
        this.flagCreditCard = false;
        this.flagDebitCard = false;
        this.flagCheck = false;
        this.flagEverything = false;
        this.sumPaymentsByDepositAndDate();
      }
    )
  }

  async sumPaymentsByDepositAndDate() {
    this.totDeposit = 0;
    for (let i = 0; i < this.depositTotal.length; i++)
      this.totDeposit += this.depositTotal[i].cantidadAbono;

    return this.totDeposit;
  }

  async getAllPaymentsByTransferAndDate() {

    this.contrlCommission.getPaymentsByTranserService(this.cashCutFormGroup.value.curDate).subscribe(
      res => {
        this.transferTotal = res;

        this.flagCash = false;
        this.flagDeposit = false;
        this.flagTransfer = true;
        this.flagCreditCard = false;
        this.flagDebitCard = false;
        this.flagCheck = false;
        this.flagEverything = false;
        this.sumPaymentsByTransferAndDate();
      }
    )
  }

  async sumPaymentsByTransferAndDate() {
    this.totTranser = 0;
    for (let i = 0; i < this.transferTotal.length; i++)
      this.totTranser += this.transferTotal[i].cantidadAbono;

    return this.totTranser;
  }

  async getAllPaymentsByTDCAndDate() {

    this.contrlCommission.getPaymentsByCreditCardService(this.cashCutFormGroup.value.curDate).subscribe(
      res => {
        this.creditCardTotal = res;

        this.flagCash = false;
        this.flagDeposit = false;
        this.flagTransfer = false;
        this.flagCreditCard = true;
        this.flagDebitCard = false;
        this.flagCheck = false;
        this.flagEverything = false;
        this.sumPaymentsByTDCAndDate();
      }
    )
  }

  async sumPaymentsByTDCAndDate() {
    this.totCreditCard = 0;
    for (let i = 0; i < this.creditCardTotal.length; i++)
      this.totCreditCard += this.creditCardTotal[i].cantidadAbono;

    return this.totCreditCard;
  }

  async getAllPaymentsByTDDAndDate() {

    this.contrlCommission.getPaymentsByDebitCardService(this.cashCutFormGroup.value.curDate).subscribe(
      res => {
        this.debitCardTotal = res;

        this.flagCash = false;
        this.flagDeposit = false;
        this.flagTransfer = false;
        this.flagCreditCard = false;
        this.flagDebitCard = true;
        this.flagCheck = false;
        this.flagEverything = false;
        this.sumPaymentsByTDDAndDate();
      }
    )
  }

  async sumPaymentsByTDDAndDate() {
    this.totDebitCard = 0;
    for (let i = 0; i < this.debitCardTotal.length; i++)
      this.totDebitCard += this.debitCardTotal[i].cantidadAbono;

    return this.totDebitCard;
  }

  async getAllPaymentsByCheckAndDate() {

    this.contrlCommission.getPaymentsByCheckService(this.cashCutFormGroup.value.curDate).subscribe(
      res => {
        this.checkTotal = res;

        this.flagCash = false;
        this.flagDeposit = false;
        this.flagTransfer = false;
        this.flagCreditCard = false;
        this.flagDebitCard = false;
        this.flagCheck = true;
        this.flagEverything = false;
        this.sumPaymentsByCheckAndDate();
      }
    )
  }

  async sumPaymentsByCheckAndDate() {
    this.totCheck = 0;
    for (let i = 0; i < this.checkTotal.length; i++)
      this.totCheck += this.checkTotal[i].cantidadAbono;

    return this.totCheck;
  }

  async getAllPaymentsByDate() {

    this.contrlCommission.getAllPaymentByDateService(this.cashCutFormGroup.value.curDate).subscribe(
      res => {
        this.everythingTotal = res;
        

        this.flagCash = false;
        this.flagDeposit = false;
        this.flagTransfer = false;
        this.flagCreditCard = false;
        this.flagDebitCard = false;
        this.flagCheck = false;
        this.flagEverything = true;
        this.sumPaymentsByDate();
      }
    )
  }

  async sumPaymentsByDate() {
    this.totEverything = 0;
    for (let i = 0; i < this.everythingTotal.length; i++)
      this.totEverything += this.everythingTotal[i].cantidadAbono;

    return this.totEverything;
  }

  //-------------------------------------------- SALIDAS DE EFECTIVO ------------------------------------------

  async employeesDataInformation() {
    this.contrlCommission.getAllEmployeesService().subscribe(
      res => {
        this.employ = res;
      }
    )
  }

  async nameAssignment(event: any) {
    this.CashOutflowsFormGroup.controls["requestor"].setValue(event.empleado)
  }

  async registerOutCash() {
    
    this.contrlCommission.InsertCashOutService(this.CashOutflowsFormGroup.value).subscribe(
      res => {
        this.sweet.AlertTime("center", "success", "Pago registrado con éxito.", false, 2000);

        this.ticketOutputs.ticketGeneradorCashOutFlows(
          "10",
          this.pipe.transform(this.CashOutflowsFormGroup.value.registrationDate, ('dd-MM-yyyy'))!,
          //this.CashOutflowsFormGroup.value.folio,
          this.CashOutflowsFormGroup.value.cashManager,
          this.CashOutflowsFormGroup.value.quantity,
          this.CashOutflowsFormGroup.value.requestor,
          this.CashOutflowsFormGroup.value.reason,
          this.fechaActualTickets
        );
        this.camposReset();
        this.salidasDiarias(); 
        this.salidasPorFecha();
        
        // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['ReportCashCut']));
      }

    );

  }

  //-------------------------------------------- CONSULTA DE INGRESOS Y EGRESOS  ------------------------------------------

  // con una sola fecha
  async cambioVista(event: boolean) {
    if (event == true) {
      this.simple = false;
      this.range = true;


    } else {
      this.simple = true;
      this.range = false;
    }
  }

  async queryByDate() {
    
    this.fechaSeleccionada = this.querysDate.value.dateSimple;
    this.entradasPorFechaTransferencias();
    this.entradasPorFechaTDC();
    this.entradasPorFechaTodo();
    this.entradasPorFechaTDC();
    this.entradasPorFechaCheques();
    this.entradasPorFechaEfectivo();

    this.salidasPorFecha();
  }

  async entradasPorFechaEfectivo() {

    this.cashTotalIO = [];

    this.contrlCommission.getPaymentsByCashService(this.querysDate.value.dateSimple).subscribe(
      res => {
        this.flagCash = true;
        this.flagDeposit = false;
        this.flagTransfer = false;
        this.flagCreditCard = false;
        this.flagDebitCard = false;
        this.flagCheck = false;
        this.flagEverything = false;
        this.cashTotalIO = res;
        this.sumIngresosPorEfectivo();
      });

  }

  async sumIngresosPorEfectivo() {
    this.totCashIO = 0;

    for (let i = 0; i < this.cashTotalIO.length; i++) {
      this.totCashIO += this.cashTotalIO[i].cantidadAbono;
    }
  }

  async entradasPorFechaTransferencias() {

    this.transferTotalIO = [];

    this.contrlCommission.getPaymentsByTranserService(this.querysDate.value.dateSimple).subscribe(
      res => {

        this.flagCash = false;
        this.flagTransfer = true;
        this.flagDeposit = false;
        this.flagCreditCard = false;
        this.flagDebitCard = false;
        this.flagCheck = false;
        this.flagEverything = false;

        this.transferTotalIO = res;
        
        this.sumIngresosPorTransferencia();
      });

  }

  async sumIngresosPorTransferencia() {
    this.totTranserIO = 0;

    for (let i = 0; i < this.transferTotalIO.length; i++) {
      this.totTranserIO += this.transferTotalIO[i].cantidadAbono;
    }
  }

  async entradasPorFechaDepositos() {

    this.depositTotalIO = [];
    this.contrlCommission.getPaymentsByDepositService(this.querysDate.value.dateSimple).subscribe(
      res => {

        this.flagCash = false;
        this.flagTransfer = false;
        this.flagDeposit = true;
        this.flagCreditCard = false;
        this.flagDebitCard = false;
        this.flagCheck = false;
        this.flagEverything = false;

        this.depositTotalIO = res;
        
        this.sumIngresosPorDepositos();
      });

  }

  async sumIngresosPorDepositos() {
    this.totDepositIO = 0;

    for (let i = 0; i < this.depositTotalIO.length; i++) {
      this.totDepositIO += this.depositTotalIO[i].cantidadAbono;
    }
  }

  async entradasPorFechaTDC() {

    this.creditCardTotalIO = [];
    this.contrlCommission.getPaymentsByCreditCardService(this.querysDate.value.dateSimple).subscribe(
      res => {

        this.flagCash = false;
        this.flagTransfer = false;
        this.flagDeposit = false;
        this.flagCreditCard = true;
        this.flagDebitCard = false;
        this.flagCheck = false;
        this.flagEverything = false;

        this.creditCardTotalIO = res;
        
        this.sumIngresosPorTDC();
      });

  }

  async sumIngresosPorTDC() {
    this.totCreditCardIO = 0;

    for (let i = 0; i < this.creditCardTotalIO.length; i++) {
      this.totCreditCardIO += this.creditCardTotalIO[i].cantidadAbono;
    }
  }

  async entradasPorFechaTDD() {

    this.debitCardTotalIO = [];
    this.contrlCommission.getPaymentsByDebitCardService(this.querysDate.value.dateSimple).subscribe(
      res => {

        this.flagCash = false;
        this.flagTransfer = false;
        this.flagDeposit = false;
        this.flagCreditCard = false;
        this.flagDebitCard = true;
        this.flagCheck = false;
        this.flagEverything = false;

        this.debitCardTotalIO = res;
        
        this.sumIngresosPorTDD();
      });

  }

  async sumIngresosPorTDD() {
    this.totDebitCardIO = 0;

    for (let i = 0; i < this.debitCardTotalIO.length; i++) {
      this.totDebitCardIO += this.debitCardTotalIO[i].cantidadAbono;
    }
  }

  async entradasPorFechaCheques() {

    this.checkTotalIO = [];
    this.contrlCommission.getPaymentsByCheckService(this.querysDate.value.dateSimple).subscribe(
      res => {

        this.flagCash = false;
        this.flagTransfer = false;
        this.flagDeposit = false;
        this.flagCreditCard = false;
        this.flagDebitCard = false;
        this.flagCheck = true;
        this.flagEverything = false;

        this.checkTotalIO = res;
        
        this.sumIngresosPorCheques();
      });

  }

  async sumIngresosPorCheques() {
    this.totCheckIO = 0;

    for (let i = 0; i < this.checkTotalIO.length; i++) {
      this.totCheckIO += this.checkTotalIO[i].cantidadAbono;
    }
  }

  async entradasPorFechaTodo() {

    this.everythingTotalIO = [];
    this.contrlCommission.getAllPaymentByDateService(this.querysDate.value.dateSimple).subscribe(
      res => {

        this.flagCash = false;
        this.flagTransfer = false;
        this.flagDeposit = false;
        this.flagCreditCard = false;
        this.flagDebitCard = false;
        this.flagCheck = false;
        this.flagEverything = true;

        this.everythingTotalIO = res;

        this.sumIngresosTotales();
      });

  }

  async sumIngresosTotales() {
    this.totEverythingIO = 0;

    for (let i = 0; i < this.everythingTotalIO.length; i++) {
      this.totEverythingIO += this.everythingTotalIO[i].cantidadAbono;
    }
  }

  async salidasPorFecha() {
    this.salCash = [];

    this.contrlCommission.getAllOutputsByDateService(this.querysDate.value.dateSimple).subscribe(
      res => {
        this.salCashIO = res

        this.sumEgresosporFecha();
      }
    )
  }

  async sumEgresosporFecha() {
    this.totalEgresosIO = 0;

    for (let i = 0; i < this.salCashIO.length; i++) {
      this.totalEgresosIO += this.salCashIO[i].quantity;

    }
    this.querysDate.controls['outputs'].setValue(this.totalEgresosIO);
  }


  // con un rango de fechas
  async queryByRangeDate() {
    this.fecIni = this.querysDate.value.dateStart;
    this.fecFin = this.querysDate.value.dateEnd

    if (this.querysDate.value.dateStart == "" || this.querysDate.value.dateStart == null) {
      Swal.fire("Seleccione Fecha de inicio", "La fecha de inicio indica el dia en que comenzara la busqueda", "warning")
    } else if (this.querysDate.value.dateEnd == "" || this.querysDate.value.dateEnd == null) {
      Swal.fire("Seleccione Fecha de fin", "La fecha de fin indica el dia en que acabara la busqueda", "warning")
    } else {
      this.entradasPorRangoFechaEfectivo();
      this.entradasPorRangoFechaTransferencia();
      this.entradasPorRangoFechaDeposito();
      this.entradasPorRangoFechaTDC();
      this.entradasPorRangoFechaTDD();
      this.entradasPorRangoFechaCheque();
      this.entradasPorRangoFecha();

      this.salidasPorRangoFecha();
    }



  }

  async entradasPorRangoFechaEfectivo() {

    this.cashTotalIORange = [];

    this.contrlCommission.getEntriesByDateRangeCashService(this.querysDate.value.dateStart, this.querysDate.value.dateEnd).subscribe(
      res => {
        this.flagCashRange = true;
        this.flagDepositRange = false;
        this.flagTransferRange = false;
        this.flagCreditCardRange = false;
        this.flagDebitCardRange = false;
        this.flagCheckRange = false;
        this.flagEverythingRange = false;
        this.cashTotalIORange = res;
        
        this.sumIngresosPorRangoEfectivo();
      });

  }

  async sumIngresosPorRangoEfectivo() {
    this.totCashIORange = 0;

    for (let i = 0; i < this.cashTotalIORange.length; i++) {
      this.totCashIORange += this.cashTotalIORange[i].cantidadAbono;
    }
  }

  async entradasPorRangoFechaTransferencia() {

    this.transferTotalIORange = [];

    this.contrlCommission.getEntriesByDateRangeTransferService(this.querysDate.value.dateStart, this.querysDate.value.dateEnd).subscribe(
      res => {
        this.flagCashRange = false;
        this.flagTransferRange = true;
        this.flagDepositRange = false;
        this.flagCreditCardRange = false;
        this.flagDebitCardRange = false;
        this.flagCheckRange = false;
        this.flagEverythingRange = false;
        this.transferTotalIORange = res;

        this.sumIngresosPorRangoTranferencia();
      });

  }

  async sumIngresosPorRangoTranferencia() {
    this.totTranserIORange = 0;

    for (let i = 0; i < this.transferTotalIORange.length; i++) {
      this.totTranserIORange += this.transferTotalIORange[i].cantidadAbono;
    }
  }

  async entradasPorRangoFechaDeposito() {

    this.depositTotalIORange = [];

    this.contrlCommission.getEntriesByDateRangeDepositService(this.querysDate.value.dateStart, this.querysDate.value.dateEnd).subscribe(
      res => {
        this.flagCashRange = false;
        this.flagTransferRange = false;
        this.flagDepositRange = true;
        this.flagCreditCardRange = false;
        this.flagDebitCardRange = false;
        this.flagCheckRange = false;
        this.flagEverythingRange = false;
        this.depositTotalIORange = res;

        this.sumIngresosPorRangoDeposito();
      });

  }

  async sumIngresosPorRangoDeposito() {
    this.totDepositIORange = 0;

    for (let i = 0; i < this.depositTotalIORange.length; i++) {
      this.totDepositIORange += this.depositTotalIORange[i].cantidadAbono;
    }
  }

  async entradasPorRangoFechaTDC() {

    this.creditCardTotalIORange = [];

    this.contrlCommission.getEntriesByDateRangeTDCService(this.querysDate.value.dateStart, this.querysDate.value.dateEnd).subscribe(
      res => {
        this.flagCashRange = false;
        this.flagTransferRange = false;
        this.flagDepositRange = false;
        this.flagCreditCardRange = true;
        this.flagDebitCardRange = false;
        this.flagCheckRange = false;
        this.flagEverythingRange = false;
        this.creditCardTotalIORange = res;

        this.sumIngresosPorRangoTDC();
      });

  }

  async sumIngresosPorRangoTDC() {
    this.totCreditCardIORange = 0;

    for (let i = 0; i < this.creditCardTotalIORange.length; i++) {
      this.totCreditCardIORange += this.creditCardTotalIORange[i].cantidadAbono;
    }
  }

  async entradasPorRangoFechaTDD() {

    this.debitCardTotalIORange = [];

    this.contrlCommission.getEntriesByDateRangeTDDService(this.querysDate.value.dateStart, this.querysDate.value.dateEnd).subscribe(
      res => {
        this.flagCashRange = false;
        this.flagTransferRange = false;
        this.flagDepositRange = false;
        this.flagCreditCardRange = false;
        this.flagDebitCardRange = true;
        this.flagCheckRange = false;
        this.flagEverythingRange = false;
        this.debitCardTotalIORange = res;

        this.sumIngresosPorRangoTDD();
      });

  }

  async sumIngresosPorRangoTDD() {
    this.totDebitCardIORange = 0;

    for (let i = 0; i < this.debitCardTotalIORange.length; i++) {
      this.totDebitCardIORange += this.debitCardTotalIORange[i].cantidadAbono;
    }
  }

  async entradasPorRangoFechaCheque() {

    this.checkTotalIORange = [];

    this.contrlCommission.getEntriesByDateRangeCheckService(this.querysDate.value.dateStart, this.querysDate.value.dateEnd).subscribe(
      res => {
        this.flagCashRange = false;
        this.flagTransferRange = false;
        this.flagDepositRange = false;
        this.flagCreditCardRange = false;
        this.flagDebitCardRange = false;
        this.flagCheckRange = true;
        this.flagEverythingRange = false;
        this.checkTotalIORange = res;

        this.sumIngresosPorRangoCheque();
      });

  }

  async sumIngresosPorRangoCheque() {
    this.totCheckIORange = 0;

    for (let i = 0; i < this.checkTotalIORange.length; i++) {
      this.totCheckIORange += this.checkTotalIORange[i].cantidadAbono;
    }
  }

  async entradasPorRangoFecha() {

    this.everythingTotalIORange = [];

    this.contrlCommission.getAllEntriesByDateRangeService(this.querysDate.value.dateStart, this.querysDate.value.dateEnd).subscribe(
      res => {
        this.flagCashRange = false;
        this.flagTransferRange = false;
        this.flagDepositRange = false;
        this.flagCreditCardRange = false;
        this.flagDebitCardRange = false;
        this.flagCheckRange = false;
        this.flagEverythingRange = true;
        this.everythingTotalIORange = res;

        this.sumIngresosPorRango();
      });

  }

  async sumIngresosPorRango() {
    this.totEverythingIORange = 0;

    for (let i = 0; i < this.everythingTotalIORange.length; i++) {
      this.totEverythingIORange += this.everythingTotalIORange[i].cantidadAbono;
    }
  }

  async salidasPorRangoFecha() {
    this.salCashIORange = [];

    this.contrlCommission.getAllOutputsByDateRangeService(this.querysDate.value.dateStart, this.querysDate.value.dateEnd).subscribe(
      res => {
        this.salCashIORange = res
        
        this.sumEgresosPorRangoFecha();
      }
    )
  }


  async sumEgresosPorRangoFecha() {
    this.totalEgresosIORange = 0;

    for (let i = 0; i < this.salCashIORange.length; i++) {
      this.totalEgresosIORange += this.salCashIORange[i].quantity;
    }

  }

  async reimpresionTicket(idTicket: string, cashManager: string ,quantity: string, requestor: string, reason: string, date: string) {
   

    this.contrlCommission.getAllBudgetCommissions

    this.ticketOutputs.ticketReimpresionCashFlows(
     
      this.pipe.transform(date, ('dd-MM-yyyy'))!,
      
      cashManager,
      quantity,
      requestor,
      reason,
      this.fechaActualTickets
    );
  }

  async eliminarTicket(idTicket: string) {

    this.idCashOut = idTicket;

    Swal.fire({
      title: '  <FONT COLOR="#000000"> <strong> ¡Estas a punto de eliminar este ticket! </strong> </FONT> ',
      width: auto,
      icon: 'warning',
      

      showConfirmButton: true,
      confirmButtonColor: '#3085d6',

      showCancelButton: true,
      cancelButtonColor: " #D33E3E",


      html: 'Al eliminar este ticket no podras volver a recuperarlo' +
        '<br><br><b> Seleccione una opción para continuar </b>',

      confirmButtonText: 'Si, eliminalo!!',

      cancelButtonText: `No, cancelar`
    }).then((result) => {
      if (result.isConfirmed) {
        
        this.contrlCommission.deleteTicketCashOutService(this.idCashOut).subscribe(
          res => {
            Swal.fire( {title: 'Eliminación correcta', text: 'El ticket fue eliminado', icon:'success', timer:2000 })
            this.selectInfoOnChange();
            this.queryByDate();
   
          }
            
          
        )
      } else {
        result.isDismissed
      }
    })
  }

  //------------------------------------ FORMATO DE DOCUMENTOS PARA IMPRIMIR --------------------------------

  //Cierre de caja
  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlData')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
          <html>
          <title > ${this.pipe.transform(this.currentDate, 'longDate')!.toUpperCase()} </title>
            
          <head>
              <style>
            
              * {
                margin: 0;
                padding: 0;
              }
    
              table {
                width: 100%;
                border-collapse: collapse;
                border: solid #5E4F4F 5px;
              }
    
              .formatTextDate{
                text-align:left;
              }

              h1{
                text-align:center;
                width:100%;
                background-color:#ebdfdf;
                height: auto ;
              }
    
              h4 {
                padding: 10px;
                background-color: #D33E3E;
                color: white;
                font-weight: bold;
                text-align: center;
                border-radius: 20px 20px 0 0;
                margin-bottom: 0;
            }

    
            .information-Header{
                display:flex;
                flex-direction: column-reverse;
                justiify-content: space-between;
                
              }

              .format-Table{
                width: 100%;
                border-collapse: collapse;
                border: solid #5E4F4F 5px;
                }
              
                thead {
                background-color: #5E4F4F;
                border: solid #5E4F4F 5px;
                color: white;
                font-weight: bold;
                font-size: 1rem;
                text-align: center;
              }
              
              tbody {
                text-align: center;
                font-size: 1rem;
                border: solid #5E4F4F 5px;
              }
              
              tbody tr:nth-child(even) {
                background-color: #ebebeb;
              }
              
              td {
                padding: 10px;
              }

              .td-text-left{
                  text-align:left;
                  padding-left: 30px;
              }
    
            .formatTextDate{
                text-align:left;
              }
              
            .formatTextDate2{
                text-align:right;
            }
    
            .formatTableBorders {
                border: solid black 1.5px;
                border-collapse: collapse;
            }
    
    
            .tableTextHeader{
              text-align:center;
              border: solid black 1.5px;
              padding: 5px;
            }
    
            .tableDataText1{
              text-align:center;
              border: solid black 1.5px;
              padding: 10px;
            }
    
            .tableDataText2{
              text-align:left;
              border: solid black 1.5px;
              padding: 10px;
            }

            .tableDataText3{
              text-align:right;
              border: solid black 1.5px;
              padding: 10px;
            }
    
            
    
              .shared-Container{
                display:flex;
                flex-direction:row;
                justify-content:center;
                flex-wrap:wrap;
                width:98%;
                justify-content:space-around;
                border-buttom: solid black 3px;
                margin:1%;
              }
    
              .information-Delivery{
                display:flex;
                flex-direction:column;
                text-align: center;
                font-style: oblique;
                font-weight: bold;
                margin:1%;
                justify-content: center;
              }
    
              .information-Receive{
                display:flex;
                flex-direction:column;
                text-align: center;
                font-style: oblique;
                font-weight: bold;
                margin:1%;
                justify-content: center;
    
              }
    
              .separacion{
                display-flex;
                border-top: solid black 3px;
                border-bottom: solid black 3px;
              }

              .infoColumn {
                display: flex;
                flex-direction: row;
                color: black;
                font-weight: bold;
                justify-content: space-evenly;
                background-color: white;
                align-items: center;
                border-radius: 0 0 20px 20px ;
                border-bottom: solid 3px #5E4F4F;
              }
              
              .texto-salida{
                display:flex;
                text-align:center;
                flex-direction:column;
                font-size:large;
              }

              
              .salida-neta{
                color: #D33E3E;
                font-size:x-large;
              }

              .encabezado{
                border-radius: 5px;
                background-color: white;
                flex-direction:column;
                margin:1%
              }
              
                .tiempo-impresion{       
                  display:flex;
                  text-align:right;
                  width:100%;
                  justify-content: flex-end;
                  background-color: transparent;
                  padding-right:15px;
                }

                .margen{
                  margin: 1%;
                  flex-direction:row;
                  padding-bottom:10px;
                  display:flex;
                  width:100%
                }

                
              .totalSeccion{
                display: flex;
                flex-direction: column;
                width: 100%;
                break-before:page;
              }
    
              </style>
    
            </head>
    
    
        <body onload="window.print();window.close()">${printContents}</body>
    
          </html>`
    );
    popupWin!.document.close();
  }

  //Reporte de ingresos Solo EFECTIVO
  ReporteIOEfectivo(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlSummaryCashIO')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
          <html>
          <title >Ingresos en efectivo - ${this.pipe.transform(this.currentDate, 'longDate')!.toUpperCase()} </title>
            
          <head>
              <style>
            
              * {
                margin: 0;
                padding: 0;
                -webkit-print-color-adjust: exact; 
                color-adjust: exact;
              }
    
              .Contenedor-Impresion-Report-Efectivo{
                text-align: center;
                margin: 10px;
                text-align: center;
              }

              .encabezado{
                border-radius: 5px;
                background-color: #ebdfdf;
                flex-direction:column;
                margin:1%
              }
              
                .tiempo-impresion{       
                  display:flex;
                  text-align:right;
                  width:100%;
                  justify-content: flex-end;
                  background-color: transparent;
                  padding-right:15px;
                }

                .margen{
                  margin: 1%;
                  flex-direction:row;
                  padding-bottom:10px;
                  display:flex;
                  width:100%
                }

                h2 {
                  padding: 10px;
                  color: BLACK;
                  font-weight: bold;
                  text-align: center;
                  margin-bottom: 0;
                }

                h4 {
                  padding: 10px;
                  background-color: #D33E3E;
                  color: white;
                  font-weight: bold;
                  text-align: center;
                  border-radius: 20px 20px 0 0;
                  margin-bottom: 0;
              }
              
                .format-Table{
                width: 100%;
                border-collapse: collapse;
                border: solid #5E4F4F 5px;
                }
              
                thead {
                background-color: #5E4F4F;
                border: solid #5E4F4F 5px;
                color: white;
                font-weight: bold;
                font-size: 1rem;
                text-align: center;
              }
              
              tbody {
                text-align: center;
                font-size: 1rem;
                border: solid #5E4F4F 5px;
              }
              
              tbody tr:nth-child(even) {
                background-color: #ebebeb;
              }
              
              td {
                padding: 10px;
              }

              .td-text-left{
                  text-align:left;
                  padding-left: 30px;
              }
              
              .infoColumn {
                display: flex;
                flex-direction: row;
                color: black;
                font-weight: bold;
                justify-content: space-evenly;
                background-color: white;
                align-items: center;
                border-radius: 0 0 20px 20px ;
                border-bottom: solid 3px #5E4F4F;
              }
              
              .texto-salida{
                display:flex;
                text-align:center;
                flex-direction:column;
                font-size:large;
              }

              
              .salida-neta{
                color: #D33E3E;
                font-size:x-large;
              }

              .totalSeccion{
                display: flex;
                flex-direction: column;
                width: 100%;
                break-before:page;
              }
              
              .screenShared{
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                width: 50%;
                text-align: center;
                align-items: center;
                
              }
              
              .separator{
                margin-top: 30%;
                position: bottom;
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                width: 100%;
              }
              
    
              </style>
    
            </head>
    
            <body onload="window.print();window.close()">${printContents}</body>
          </html>`
    );
    popupWin!.document.close();
  }

  //Reporte de ingresos TODAS LAS ENTRADAS
  ReporteIOEntradasTotales(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlSummaryIO')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
          <html>
          <title > Ingresos totales - ${this.pipe.transform(this.currentDate, 'longDate')!.toUpperCase()} </title>
            
          <head>
              <style>
            
              * {
                margin: 0;
                padding: 0;
                -webkit-print-color-adjust: exact; 
                color-adjust: exact;
              }
    
              .Contenedor-Impresion-Report-Efectivo{
                 text-align: center;
                 margin: 10px;
                 text-align: center;
               }
  
               .encabezado{
                border-radius: 5px;
                background-color: #ebdfdf;
                flex-direction:column;
                margin:1%
               }
               
                .tiempo-impresion{       
                  display:flex;
                  text-align:right;
                  width:100%;
                  justify-content: flex-end;
                  background-color: transparent;
                  padding-right:15px;
                }
  
                .margen{
                  margin: 1%;
                  flex-direction:row;
                  padding-bottom:10px;
                  display:flex;
                  width:100%
                }
  
                h2 {
                  padding: 10px;
                  color: BLACK;
                  font-weight: bold;
                  text-align: center;
                  margin-bottom: 0;
                }
  
                h4 {
                  padding: 10px;
                  background-color: #D33E3E;
                  color: white;
                  font-weight: bold;
                  text-align: center;
                  border-radius: 20px 20px 0 0;
                  margin-bottom: 0;
              }
               
                .format-Table{
                 width: 100%;
                 border-collapse: collapse;
                 border: solid #5E4F4F 5px;
                }
               
                thead {
                 background-color: #5E4F4F;
                 border: solid #5E4F4F 5px;
                 color: white;
                 font-weight: bold;
                 font-size: 1rem;
                 text-align: center;
               }
               
               tbody {
                 text-align: center;
                 font-size: 1rem;
                 border: solid #5E4F4F 5px;
               }
               
               tbody tr:nth-child(even) {
                 background-color: #ebebeb;
               }
               
               td {
                 padding: 10px;
               }
  
               .td-text-left{
                  text-align:left;
                  padding-left: 30px;
               }
               
               .infoColumn {
                 display: flex;
                 flex-direction: row;
                 color: black;
                 font-weight: bold;
                 justify-content: space-evenly;
                 background-color: white;
                 align-items: center;
                 border-radius: 0 0 20px 20px ;
                 border-bottom: solid 3px #5E4F4F;
               }
               
               .texto-salida{
                display:flex;
                text-align:center;
                flex-direction:column;
                font-size:large;
               }
  
               
               .salida-neta{
                color: #D33E3E;
                font-size:x-large;
               }
    
              </style>
    
            </head>
    
            <body onload="window.print();window.close()">${printContents}</body>
          </html>`
    );
    popupWin!.document.close();
  }

  //Reporte de ingresos por RANGOS Solo EFECTIVO
  ReporteRangoIOEfectivo(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlSummaryCashRangeIO')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
              <html>
              <title >Ingresos en efectivo - ${this.pipe.transform(this.currentDate, 'longDate')!.toUpperCase()} </title>
                
              <head>
                  <style>
                
                  * {
                    margin: 0;
                    padding: 0;
                    -webkit-print-color-adjust: exact; 
                    color-adjust: exact;
                  }
        
                  .Contenedor-Impresion-Report-Efectivo{
                    text-align: center;
                    margin: 10px;
                    text-align: center;
                  }
    
                  .encabezado{
                    border-radius: 5px;
                    background-color: #ebdfdf;
                    flex-direction:column;
                    margin:1%
                  }
                  
                    .tiempo-impresion{       
                      display:flex;
                      text-align:right;
                      width:100%;
                      justify-content: flex-end;
                      background-color: transparent;
                      padding-right:15px;
                    }
    
                    .margen{
                      margin: 1%;
                      flex-direction:row;
                      padding-bottom:10px;
                      display:flex;
                      width:100%
                    }
    
                    h2 {
                      padding: 10px;
                      color: BLACK;
                      font-weight: bold;
                      text-align: center;
                      margin-bottom: 0;
                    }
    
                    h4 {
                      padding: 10px;
                      background-color: #D33E3E;
                      color: white;
                      font-weight: bold;
                      text-align: center;
                      border-radius: 20px 20px 0 0;
                      margin-bottom: 0;
                  }
                  
                    .format-Table{
                    width: 100%;
                    border-collapse: collapse;
                    border: solid #5E4F4F 5px;
                    }
                  
                    thead {
                    background-color: #5E4F4F;
                    border: solid #5E4F4F 5px;
                    color: white;
                    font-weight: bold;
                    font-size: 1rem;
                    text-align: center;
                  }
                  
                  tbody {
                    text-align: center;
                    font-size: 1rem;
                    border: solid #5E4F4F 5px;
                  }
                  
                  tbody tr:nth-child(even) {
                    background-color: #ebebeb;
                  }
                  
                  td {
                    padding: 10px;
                    max-width:300px;
                    text-align:left;
                  }
    
                  .td-text-left{
                      text-align:left;
                      padding-left: 30px;
                  }
                  
                  .infoColumn {
                    display: flex;
                    flex-direction: row;
                    color: black;
                    font-weight: bold;
                    justify-content: space-evenly;
                    background-color: white;
                    align-items: center;
                    border-radius: 0 0 20px 20px ;
                    border-bottom: solid 3px #5E4F4F;
                  }
                  
                  .texto-salida{
                    display:flex;
                    text-align:center;
                    flex-direction:column;
                    font-size:large;
                  }
    
                  
                  .salida-neta{
                    color: #D33E3E;
                    font-size:x-large;
                  }
        
                  </style>
        
                </head>
        
                <body onload="window.print();window.close()">${printContents}</body>
              </html>`
    );
    popupWin!.document.close();
  }

  //Reporte de ingresos por RANGOS Solo EFECTIVO
  ReporteRangoIO(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlSummaryRangeIO')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
              <html>
              <title >Ingresos en efectivo - ${this.pipe.transform(this.currentDate, 'longDate')!.toUpperCase()} </title>
                
              <head>
                  <style>
                
                  * {
                    margin: 0;
                    padding: 0;
                    -webkit-print-color-adjust: exact; 
                    color-adjust: exact;  
                  }
        
                  .Contenedor-Impresion-Report-Efectivo{
                    text-align: center;
                    margin: 10px;
                    text-align: center;
                  }
    
                  .encabezado{
                    border-radius: 5px;
                    background-color: #ebdfdf;
                    flex-direction:column;
                    margin:1%
                  }
                  
                    .tiempo-impresion{       
                      display:flex;
                      text-align:right;
                      width:100%;
                      justify-content: flex-end;
                      background-color: transparent;
                      padding-right:15px;
                    }
    
                    .margen{
                      margin: 1%;
                      flex-direction:row;
                      padding-bottom:10px;
                      display:flex;
                      width:100%
                    }
    
                    h2 {
                      padding: 10px;
                      color: BLACK;
                      font-weight: bold;
                      text-align: center;
                      margin-bottom: 0;
                    }
    
                    h4 {
                      padding: 10px;
                      background-color: #D33E3E;
                      color: white;
                      font-weight: bold;
                      text-align: center;
                      border-radius: 20px 20px 0 0;
                      margin-bottom: 0;
                  }
                  
                    .format-Table{
                    width: 100%;
                    border-collapse: collapse;
                    border: solid #5E4F4F 5px;
                    }
                  
                    thead {
                    background-color: #5E4F4F;
                    border: solid #5E4F4F 5px;
                    color: white;
                    font-weight: bold;
                    font-size: 1rem;
                    text-align: center;
                  }
                  
                  tbody {
                    text-align: center;
                    font-size: 1rem;
                    border: solid #5E4F4F 5px;
                  }
                  
                  tbody tr:nth-child(even) {
                    background-color: #ebebeb;
                  }
                  
                  td {
                    padding: 10px;
                    max-width: 400px;
                    text-align:left;
                  
                  }
    
                  .td-text-left{
                      text-align:left;
                      padding-left: 30px;
                  }
                  
                  .infoColumn {
                    display: flex;
                    flex-direction: row;
                    color: black;
                    font-weight: bold;
                    justify-content: space-evenly;
                    background-color: white;
                    align-items: center;
                    border-radius: 0 0 20px 20px ;
                    border-bottom: solid 3px #5E4F4F;
                  }
                  
                  .texto-salida{
                    display:flex;
                    text-align:center;
                    flex-direction:column;
                    font-size:large;
                  }
    
                  
                  .salida-neta{
                    color: #D33E3E;
                    font-size:x-large;
                  }
        
                  </style>
        
                </head>
        
                <body onload="window.print();window.close()">${printContents}</body>
              </html>`
    );
    popupWin!.document.close();
  }

responsables = ["Marco Antonio Flores Rivas" , "Carlos Betancourt Rodriguez" , "Iveth Tadeo Dena"];
assign = "";

  async printOptions() {

    let value = await Swal.fire({
      title: '  <FONT COLOR="#000000"> <strong> ¡Seleccione un responsable para entrega del dinero en caja! </strong> </FONT> ',
      width: auto,
      showConfirmButton: true,
      showDenyButton: true,
      showCancelButton: true,


      inputPlaceholder: 'Seleccione un responsable',
      input: 'select',
      inputOptions: this.responsables,

      html: 'Seleccione un responsable y un tipo de reporte ' +
        '<br>se le redireccionará al <strong> <u>reporte</u> </strong> deseado.' +
        '<br><br><b>  De click en alguna opción para continuar </b>'   ,

      confirmButtonText: 'EFECTIVO',
      denyButtonText: `TODAS`,
      cancelButtonText: `CANCELAR`,
      buttonsStyling: false, //Permite customizar los botones 

      customClass: {
        actions: 'sweetActions',
        confirmButton: 'm19AltBtn',
        cancelButton: 'm19InvBtn',
        denyButton: 'm19Btn',
        input: 'sweetInput'
      },

      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === '') {
            resolve('Debes elegir una opción de la lista')
          } else {
            this.assign = this.responsables[value];
            resolve('');
          }  

        })
       
      }

    }).then((result) => {

      this.contrlCommission.getAllEmployeesService().subscribe(
        res => {
          if (result.isConfirmed) {
            this.ReporteIOEfectivo();
          } else if (result.isDenied) {
            this.ReporteIOEntradasTotales();
          } else if (result.isDismissed) {   }
        }
      )
      
    })
    console.log(value)
    

  }

  async printOptionsRange() {
    Swal.fire({
      title: '  <FONT COLOR="#000000"> <strong> ¡Guardar reporte! </strong> </FONT> ',
      width: auto,
      icon: 'info',
      iconColor: '#0719FA',

      focusConfirm: false,
      showConfirmButton: true,
      confirmButtonColor: '#0719FA',
      showDenyButton: true,
      denyButtonColor: '#11BD06',
      showCancelButton: true,
      cancelButtonColor: " #D33E3E",


      html: 'Al clickear en una de las opciones en la parte inferior' +
        '<br>se le redireccionará al <strong> <u>reporte</u> </strong> deseado.' +
        '<br><br><b>  De click en alguna opción para continuar </b>',

      confirmButtonText: 'Solo EFECTIVO',
      denyButtonText: `TODAS las entradas`,
      cancelButtonText: `Cancelar`
    }).then((result) => {
      if (result.isConfirmed) {
        this.ReporteRangoIOEfectivo();
      } else if (result.isDenied) {
        this.ReporteRangoIO();
        //Swal.fire('Esta pendiente por terminar uwu', '', 'success')
      } else if (result.isDismissed) {
        //Swal.fire('Operación Cancelada', '', 'error')
      }
    })
  }

  //--------------------------------------------- METODOS UTILES --------------------------------------------
  camposReset() {
    this.CashOutflowsFormGroup.controls['quantity'].setValue("")
    this.CashOutflowsFormGroup.controls['registrationDate'].setValue("")
    this.CashOutflowsFormGroup.controls['reason'].setValue("")
    this.CashOutflowsFormGroup.controls['requestor'].setValue("")
    // this.CashOutflowsFormGroup.controls['cashManager'].setValue("")

  }

  getUser() {
    this.decode = jwtDecode(localStorage.getItem("token") || "")
    return this.decode['cliente']
  }

}