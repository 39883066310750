<div style="margin: 1% ; background-color:white">

    <div class="tituloGral">
        <h3> Comisiones </h3>
        <mat-icon style="scale: 1.5;"> attach_money </mat-icon>
    </div>

    <div style="display:flex; flex-direction:column; width:100%;">

        <div [hidden]="flagSearch != false " style="display:flex; flex-wrap: wrap; justify-content:space-around;">

            <mat-form-field appearance="outline" class="col-md-4">
                <mat-label>proyectos</mat-label>
                <mat-select [(value)]="nameProject" (valueChange)="pruebitA($event)" required>
                    <mat-option *ngFor="let project of projects" [value]="project.name">
                        {{project.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-4">
                <mat-label>Vendedores</mat-label>
                <mat-select [(value)]="selectSellers" [(value)]="nameEmployee" required>
                    <mat-option *ngFor="let employee of employees"
                        [value]="employee.idcoord + ' - ' + employee.Coordinador ">
                        {{employee.idcoord}} - {{employee.Coordinador}}
                    </mat-option>
                </mat-select>

            </mat-form-field>

            <div class="col-md-3" style="display:flex; justify-content: center; justify-items:center; flex-wrap: wrap;">
                <button mat-raised-button class="btnSearch" (click)="mergePaymentsComissionOnProperties();"> Buscar
                </button>
            </div>

        </div>

        <div style="display:flex;  flex-direction:column;  justify-content: center; text-align: center;">

            <div style="display:flex;  flex-direction:row; justify-content: center; ">
                <h6 *ngIf="flagSearch == false"> Si desea hacer una busqueda general presione aqui
                    <mat-icon style="transform: rotate(90deg); color:black; scale:1.5em"> pan_tool_alt </mat-icon>
                </h6>
                <h6 *ngIf="flagSearch == true"> Si desea hacer una busqueda especifica quite la palomita</h6>
                <mat-checkbox style="margin-left:15px;" (change)="setFlag($event.checked)"> </mat-checkbox>
            </div>

            <div [hidden]="flagSearch == false">
                <div style="display:flex; flex-direction: row; flex-wrap: wrap; justify-content:center">

                    <mat-form-field appearance="outline" class="col-md-3 ">
                        <mat-label> Proyectos </mat-label>
                        <mat-select [(value)]="searchGeneralProject" (valueChange)="pruebitB($event)" required>
                            <mat-option *ngFor="let proje of projectsT" [value]="proje.name">
                                {{proje.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="col-md-3"
                        style="display:flex; justify-content: center; justify-items:center; flex-wrap: wrap;">
                        <button mat-raised-button class="col-md-4" class="btnSearch"
                            (click)="mergePaymentsComissionOnPropertiesGeneral();"> Buscar
                        </button>
                    </div>
                </div>
            </div>

        </div>

        <div style="overflow:scroll;" [hidden]="unionPComiAndPCusto == 'Sin seleccionar' ">

            <div *ngIf="type == 1 " >

                <div class="informacion-adicional" *ngIf="unionPComiAndPCusto.length != 0" >
                    CLIENTE COMPLETO: <p> {{unionPComiAndPCusto.length}} </p>
                    <mat-icon style="color: #D33E3E;"> how_to_reg </mat-icon>
                </div>

                <div style="margin: 1% ;">

                    <table class="table table-striped mt-3 animated fadeIn faster"
                        style="text-align:center; height: 100; align-content: border= 1 ;">
                        <thead class="thead-m19">
                            <tr>
                                <th scope="col" align="center">inmueble</th>
                                <th scope="col" align="center">
                                    Anticipo</th>
                                <th scope="col" align="center">Pago 1
                                </th>
                                <th scope="col" align="center">Pago 2
                                </th>
                                <th scope="col" align="center">Pago 3
                                </th>
                                <th scope="col" align="center">Pago 4
                                </th>
                                <th scope="col" align="center">Pago 5
                                </th>
                                <th scope="col" align="center">Pago 6
                                </th>
                                <th scope="col" align="center">Pago 7
                                </th>
                                <th scope="col" align="center">Pago 8
                                </th>
                                <th scope="col" align="center">Pago 9
                                </th>
                                <th scope="col" align="center"> Acción
                                </th>
                            </tr>
                        </thead>
                        <tbody>


                            <tr *ngFor="let row of unionPComiAndPCusto;">

                                <th>
                                    <button mat-raised-button color="warn" (click)="openDialogPayments(row[0]);">
                                        {{row[0]}}
                                    </button>
                                </th>

                                <td *ngFor="let value of row[1]; let indice2=index;">
                                    <div>
                                        <p *ngIf="row[1][indice2] != 'NULL' && row[2][indice2] != 'NULL'; ">
                                            <!-- {{row[1][indice2]}} -->
                                            <mat-icon data-title="Pagado completamente" style="color: rgb(39, 190, 6  );">
                                                verified
                                            </mat-icon>
                                        </p>

                                        <p *ngIf="row[1][indice2] == 'NULL' && row[2][indice2] != 'NULL'">
                                            <!-- {{row[1][indice2]}} -->
                                            <button mat-mini-fab color="primary"
                                                style="background-color: white; outline: none;"
                                                (click)="openDialogPayments(row[0]);">
                                                <mat-icon data-title="Cliente Pagado, Comisión Pendiente"
                                                    style="color: rgb(16,33,241);"> warning
                                                </mat-icon>

                                            </button>
                                        </p>

                                        <p *ngIf="row[1][indice2] != 'NULL' && row[2][indice2] == 'NULL'">
                                            <mat-icon data-title="Cliente Pendiente, Comisión Pagada"
                                                style="color: rgb(220,197,5);"> warning
                                            </mat-icon>
                                        </p>

                                    </div>

                                    <div *ngIf="row[1][indice2] == 'NULL' && row[2][indice2] == 'NULL'">
                                        <mat-icon data-title="Ningúno pagado" style="color :rgb(231, 41, 12 );">
                                            new_releases
                                        </mat-icon>
                                    </div>
                                </td>

                                <td >
                    
                                        <mat-icon class="btn-m19-table" data-title="Eliminar ticket"  (click)="openTicketOptionCancel(row[0] )" > delete </mat-icon>
  
                                        <mat-icon class="btn-m19-info" data-title="Reimprimir ticket" (click)="openTicketOptionPrint(row[0] )" > description </mat-icon>

                                    
                                   
                                </td>



                            </tr>

                        </tbody>

                    </table>

                </div>

            </div>

            <div *ngIf="type == 2" >
                <div class="informacion-adicional" *ngIf="unionPComiAndPCusto.length != 0" >
                    CLIENTE COMPLETO: <p> {{unionPComiAndPCusto.length}} </p>
                    <mat-icon style="color: #D33E3E;"> how_to_reg </mat-icon>
                </div>

                <mat-card-content class="justify-align-center">
                    <div class="mat-elevation-z8">
                        <table class="table table-striped mt-3 animated fadeIn faster"
                            style="text-align:center; height: 100; align-content: border= 1 ;">
                            <thead class="thead-m19">
                                <tr>
                                    <th scope="col" align="center">inmueble</th>
                                    <th scope="col" align="center">
                                        Anticipo</th>
                                    <th scope="col" align="center"> Pago 1
                                    </th>
                                    <th scope="col" align="center"> Pago 2
                                    </th>
                                    <th scope="col" align="center"> Pago 3
                                    </th>
                                    <th scope="col" align="center"> Pago 4
                                    </th>
                                    <th scope="col" align="center"> Pago 5
                                    </th>
                                    <th scope="col" align="center"> Pago 6
                                    </th>
                                    <th scope="col" align="center"> Pago 7
                                    </th>
                                    <th scope="col" align="center"> Acción
                                    </th>
                                    <!---     <th scope="col" align="center" style="transform: rotate(-45deg);">Total
                                                    Pagos</th> <!-->

                                </tr>
                            </thead>
                            <tbody>


                                <tr *ngFor="let row of unionPComiAndPCusto;">

                                    <th>
                                        <button mat-raised-button color="warn" (click)="openDialogPayments(row[0]);">
                                            {{row[0]}}
                                        </button>
                                    </th>

                                    <td *ngFor="let value of row[1]; let indice2=index;">
                                        <div>
                                            <p *ngIf="row[1][indice2] != 'NULL' && row[2][indice2] != 'NULL'; ">
                                                <!-- {{row[1][indice2]}} -->
                                                <mat-icon data-title="Pagado completamente"
                                                    style="color: rgb(39, 190, 6  );">
                                                    verified
                                                </mat-icon>
                                            </p>

                                            <p *ngIf="row[1][indice2] == 'NULL' && row[2][indice2] != 'NULL'">
                                                <!-- {{row[1][indice2]}} -->
                                                <button mat-mini-fab color="primary"
                                                    style="background-color: white; outline: none;"
                                                    (click)="openDialogPayments(row[0]);">
                                                    <mat-icon data-title="Cliente Pagado, Comisión Pendiente"
                                                        style="color: rgb(16,33,241);"> warning
                                                    </mat-icon>

                                                </button>
                                            </p>

                                            <p *ngIf="row[1][indice2] != 'NULL' && row[2][indice2] == 'NULL'">
                                                <mat-icon data-title="Cliente Pendiente, Comisión Pagada"
                                                    style="color: rgb(220,197,5);"> warning
                                                </mat-icon>
                                            </p>

                                        </div>

                                        <div *ngIf="row[1][indice2] == 'NULL' && row[2][indice2] == 'NULL'">
                                            <mat-icon data-title="Ningúno pagado" style="color :rgb(231, 41, 12 );">
                                                new_releases
                                            </mat-icon>
                                        </div>
                                    </td>

                                    <td >
                                        <mat-icon class="btn-m19-table" data-title="Eliminar ticket"  (click)="openTicketOptionCancel(row[0] )" > delete </mat-icon>
  
                                        <mat-icon class="btn-m19-info" data-title="Reimprimir ticket" (click)="openTicketOptionPrint(row[0] )" > description </mat-icon>
                                    </td>

                                </tr>

                            </tbody>

                        </table>

                    </div>
                </mat-card-content>
            </div>

            <div *ngIf="type == 3" >
                <div class="informacion-adicional" *ngIf="unionPComiAndPCusto.length != 0" >
                    CLIENTE COMPLETO: <p> {{unionPComiAndPCusto.length}} </p>
                    <mat-icon style="color: #D33E3E;"> how_to_reg </mat-icon>
                </div>

                <mat-card-content class="justify-align-center">
                    <div class="mat-elevation-z8">
                        <table class="table table-striped mt-3 animated fadeIn faster"
                            style="text-align:center; height: 100; align-content: border= 1 ;">
                            <thead class="thead-m19">
                                <tr>
                                    <th scope="col" align="center">inmueble</th>

                                    <th scope="col" align="center"> Anticipo 1
                                    </th>
                                    <th scope="col" align="center"> Anticipo 2
                                    </th>
                                    <th scope="col" align="center"> Anticipo 3
                                    </th>
                                    <th scope="col" align="center"> Anticipo 4
                                    </th>
                                    <th scope="col" align="center"> Acción
                                    </th>
                                </tr>
                            </thead>
                            <tbody>


                                <tr *ngFor="let row of unionPComiAndPCusto;">

                                    <th>
                                        <button mat-raised-button color="warn" (click)="openDialogPayments(row[0]);">
                                            {{row[0]}}
                                        </button>
                                    </th>

                                    <td *ngFor="let value of row[1]; let indice2=index;">
                                        <div>
                                            <p *ngIf="row[1][indice2] != 'NULL' && row[2][indice2] != 'NULL'; ">
                                                <!-- {{row[1][indice2]}} -->
                                                <mat-icon data-title="Pagado completamente"
                                                    style="color: rgb(39, 190, 6  );">
                                                    verified
                                                </mat-icon>
                                            </p>

                                            <p *ngIf="row[1][indice2] == 'NULL' && row[2][indice2] != 'NULL'">
                                                <!-- {{row[1][indice2]}} -->
                                                <button mat-mini-fab color="primary"
                                                    style="background-color: white; outline: none;"
                                                    (click)="openDialogPayments(row[0]);">
                                                    <mat-icon data-title="Cliente Pagado, Comisión Pendiente"
                                                        style="color: rgb(16,33,241);"> warning
                                                    </mat-icon>

                                                </button>
                                            </p>

                                            <p *ngIf="row[1][indice2] != 'NULL' && row[2][indice2] == 'NULL'">
                                                <mat-icon data-title="Cliente Pendiente, Comisión Pagada"
                                                    style="color: rgb(220,197,5);"> warning
                                                </mat-icon>
                                            </p>

                                        </div>

                                        <div *ngIf="row[1][indice2] == 'NULL' && row[2][indice2] == 'NULL'">
                                            <mat-icon data-title="Ningúno pagado" style="color :rgb(231, 41, 12 );">
                                                new_releases
                                            </mat-icon>
                                        </div>
                                    </td>

                                    <td >
                                        <mat-icon class="btn-m19-table" data-title="Eliminar ticket"  (click)="openTicketOptionCancel(row[0] )" > delete </mat-icon>
  
                                        <mat-icon class="btn-m19-info" data-title="Reimprimir ticket" (click)="openTicketOptionPrint(row[0] )" > description </mat-icon>
                                    </td>

                                </tr>

                            </tbody>

                        </table>

                    </div>
                </mat-card-content>
            </div>

        </div>

        <div class="loadingContainer" *ngIf="loadingCircle==true">
            <div class="loading">
                <div class="loader loader--style2">
                    <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" height="100%"
                        viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <path fill="#000"
                            d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
                            <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25"
                                to="360 25 25" dur="0.6s" repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
                <h6> Cargando información...</h6>
                <h6> Por favor espere.</h6>
        
            </div>
        </div>

        <div class="advertisement" *ngIf="unionPComiAndPCusto == 'Sin seleccionar'">

            <h1> Realice su busqueda para obtener resultados </h1>

            <mat-icon style="scale: 2; width: 100%; margin-top:30px"> mood  </mat-icon>

        </div>

        <div class="advertisement" *ngIf="unionPComiAndPCusto.length  == 0 ">

            <h1> No hay resultados para esta busqueda </h1>

            <mat-icon style="scale: 2; width: 100%; margin-top:30px"> sentiment_dissatisfied </mat-icon>

        </div>

    </div>

</div>
