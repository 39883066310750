import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sales } from 'src/app/models/Sales';

@Component({
  selector: 'app-dialog-sale',
  templateUrl: './dialog-sale.component.html',
  styleUrls: ['./dialog-sale.component.scss']
})
export class DialogSaleComponent implements OnInit {

  constructor(
    
    private dialogRef: MatDialogRef<DialogSaleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Sales

  ) { }

  ngOnInit(): void {
  }

}
