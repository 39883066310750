import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogInvoiceComponent } from '../dialog-invoice/dialog-invoice.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogEditDeliveryComponent } from './dialog-edit-delivery/dialog-edit-delivery.component';

import { DatePipe } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL

//Importación del servicio de nota de remisión
import { DeliverynoteService } from 'src/app/services/deliverynote.service';

import Swal from 'sweetalert2';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { loader } from 'src/app/ui/loader/loader.model';

@Component({
  selector: 'app-dialog-delivery',
  templateUrl: './dialog-delivery.component.html',
  styleUrls: ['./dialog-delivery.component.scss']
})
export class DialogDeliveryComponent {
  loader: loader = {
    message: "Cargando información..."
  }

  loading = 1;

  //Objeto para almacenar las notas de remisión registradas para la requisición seleccionada
  deliveryList: any = [];

  deliveryInfo: any = [];

  pipe = new DatePipe('es-MX');

  //Objeto para almacenar los productos registrados para la requisición seleccionada
  deliveryProducts: any = [];

  constructor(
    private dialogRef: MatDialogRef<DialogDeliveryComponent>,

    // =========================================================
    // Declaración del servicio de nota de remisión
    public deliveryService: DeliverynoteService,

    // ======================================================================
    // Declaración del componente gráfico utilizado para cuadro de dialogo
    public dialog: MatDialog,

    public sweet: SweetAlertComponent,

    @Inject(MAT_DIALOG_DATA) public data: number,
  ) {
    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
    this.deliveryService.selectDelivery(data).subscribe(
      res => {
        this.deliveryList = res;
        this.loading = 0;
      }
    );
  }

  async getDeliveryInfo(folio: String) {
    this.deliveryInfo = [];
    this.deliveryService.selectDeliveryInfo(folio).subscribe(
      res => {
        this.deliveryInfo = res;
      }
    );
  }

  async getDeliveryProducts(folio: String) {
    this.loading = 1;
    this.deliveryProducts = [];
    this.deliveryService.selectDeliveryProducts(folio).subscribe(
      res => {
        this.deliveryProducts = res;
        this.loading = 0;
      }
    );
  }

  async selectInvoice(id: Number) {
    this.dialog.open(DialogInvoiceComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '100%',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: id
    });
  }

  async deleteDelivery(id: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, mejor no',
      confirmButtonText: 'Sí, elimínala!',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'm19AltBtn',
        cancelButton: 'm19Btn',
        actions: 'sweetActions'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.deliveryService.deleteDelivery(id).subscribe(
          res => {
            this.sweet.AlertTime("center", "success", "Nota de remisión eliminada con éxito", false, 2000);
            this.dialogRef.close();
          }
        );
      }
    })
  }

  async editDelivery(folio: string) {
    let dialogRef = this.dialog.open(DialogEditDeliveryComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '100%',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: folio
    });

    dialogRef.afterClosed().subscribe(
      res => {
        this.dialogRef.close();
      }
    );
  }
}