import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  private url = 'http://localhost:3000/api';

  constructor(private http: HttpClient) { }

  //Consultar partidas por número de partida
  selectEmployeeWorker(): Observable<any>{
    return this.http.get(`${environment.URLServer}/employee/selectEmployeeWorker`);
  }

    //Consulta todos los empleados de la tabla employee
    selectEmployeeById(id : number): Observable <any>{
      return this.http.get(`${environment.URLServer}/employee/selectEmployeeById/${id}`);
    }



}