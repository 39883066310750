<div id="paybackReceipt" [hidden]="true">
    <div class="receiptContainer">
        <div class="receiptHeader">
            <div class="titles">
                <img [src]="cbrLogo" alt="" width="120px" />
                <img src="./assets/M19_Inmobiliaria.png" alt="" width="150px" />
            </div>

            <div class="details">
                <p class="receiptDate">Tepic, Nayarit a {{today.getDate()}} de
                    {{monthName.getMonthName(today.getMonth() + 1).toLowerCase()}} del año {{today.getFullYear()}}.</p>
                <p class="receiptNumber">Pago número {{receiptData.PaymentsAmount + 1}} <b style="color: gray;">#{{receiptData.ReceiptFolio}}<span id="devolutionFolio"></span></b>
                </p>
            </div>
        </div>

        <div class="receiptBody">
            <p>Recibí del ingeniero <b>CARLOS BETANCOURT RODRIGUEZ</b> la cantidad de <b>{{receiptData.PaybackAmount |
                    currency : '$ '}} ({{convertNumber.numberToText(receiptData.PaybackAmount)}} PESOS
                    00/100 M.N.)</b> por concepto de pago <span>parcial</span> <ng-template>finiquito</ng-template> del convenio de rescisión de fecha
                {{receiptData.NegotiationDate?.getDate()}} de
                {{monthName.getMonthName(receiptData.NegotiationDate?.getMonth() + 1).toLowerCase()}} del
                año {{receiptData.NegotiationDate?.getFullYear()}} correspondiente a 
                
                <ng-container *ngFor="let p of receiptProperties; last as isLast;">
                    <span>el {{p.substring(5,6) == 'L'? 'lote' : 'departamento'}} número {{p.substring(6,8)}} de la {{p.substring(2,3) == 'M'? 'manzana' : 'torre'}} marcada con el número {{p.substring(3,5)}}</span>
                    <span *ngIf="!isLast">, </span>
                </ng-container>

                del proyecto denominado <b>{{receiptData.Project}}</b>.
            </p>

            <p>Al momento de este pago el saldo acumulado es de <b>{{receiptData.TotalPayback + receiptData.PaybackAmount | currency: '$ '}} ({{convertNumber.numberToText(receiptData.TotalPayback + receiptData.PaybackAmount)}} PESOS 00/100
                    M.N.)</b></p>
        </div>

        <div class="signs">
            <p class="customer"><b>RECIBÍ DE CONFORMIDAD</b><br><span
                    *ngIf="receiptData.Representative == 'N/A'; Else Representative">{{receiptData.Customer}}</span><ng-template
                    #Representative>{{receiptData.Representative}}</ng-template></p>
            <p class="representative" *ngIf="receiptData.Representative != 'N/A'"><b>EN REPRESENTACIÓN
                    DE</b><br>{{receiptData.Customer}}</p>
        </div>

        <div class="receiptFooter">
            <span style="background-color: black; flex-grow: 1;"></span>
            <span style="background-color: #996600; flex-grow: 1;"></span>
        </div>
    </div>
</div>

<div class="schedulingCont">
    <span class="schedulingCont__toolbar">
        <div class="schedulingCont__toolbar__tabsOpts">
        </div>
    </span>

    <span class="schedulingCont__scheduleCards">
        <div class="schedulingCont__scheduleCards__card">
            <div class="schedulingCont__scheduleCards__card__title yellow">
                <h5>Por Hacer</h5>
                <mat-form-field style="width: 40%;">
                    <mat-label>Buscar negociación</mat-label>
                    <input matInput type="text"
                        [(ngModel)]="filterNegotiations" name="filterNegotiations">
                </mat-form-field>
            </div>

            <div class="schedulingCont__scheduleCards__card__list">
                <ng-container *ngIf="openNegotiations.length > 0; Else NoNegotiation">
                    <span *ngFor="let n of openNegotiations | filterNegotiations: filterNegotiations" class="schedulingCont__scheduleCards__card__list__element">
                        <div class="customer">
                            <span><b style="color: gray;">#C{{n.PKNegotiation}}</b> {{n.Customer}}</span>
                        </div>
                        <div class="negotiation">
                            <div class="responsable">
                                <span>Registró {{n.Negotiator}}</span>
                                <span>{{pipe.transform(n.NegotiationDate, 'longDate')}}</span>
                            </div>
                            <span class="negotiationUnits">
                                <ng-container *ngFor="let u of negotiations">
                                    <ng-container *ngIf="u.PKNegotiation == n.PKNegotiation">
                                        <div class="unit">
                                            <span>{{u.FKProperty}}</span>
                                            <span class="agreement">{{u.DevolutionAmount | currency : '$ '}}</span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </span>
                        </div>
                        <div class="actions">
                            <span class="m19IconBtn"
                                (click)="openDetailsView(n.PKNegotiation)"><mat-icon>info</mat-icon></span>
                            <span class="m19IconBtn"
                                (click)="openCommentsView(n.PKNegotiation)"><mat-icon>forum</mat-icon>{{n.Comments}}</span>
                            <span class="m19IconBtn"
                                (click)="openScheduleView(n.PKNegotiation, n.Customer)"><mat-icon>today</mat-icon></span>
                        </div>
                    </span>
                </ng-container>

                <ng-template #NoNegotiation>
                    <div class="schedulingCont__scheduleCards__card__list__noNegotiation">
                        <h4>No hay negociaciones por hacer</h4>
                        <mat-icon>mood</mat-icon>
                    </div>
                </ng-template>
            </div>
        </div>

        <div id="scheduledTab" class="schedulingCont__scheduleCards__card">
            <div class="schedulingCont__scheduleCards__card__tab green">
                <mat-icon (click)="showTab('scheduled')">check_box</mat-icon>
            </div>

            <div class="schedulingCont__scheduleCards__card__title green">
                <h5>En Proceso</h5>
                <mat-form-field style="width: 40%;">
                    <mat-label>Buscar negociación</mat-label>
                    <input matInput type="text"
                        [(ngModel)]="filterSchedule" name="filterNegotiations">
                </mat-form-field>
                <!-- <mat-icon class="m19IconBtn altBtn" (click)="hideTab('scheduled')">visibility_off</mat-icon> -->
            </div>

            <div class="schedulingCont__scheduleCards__card__list">
                <ng-container *ngIf="scheduledNegotiations.length > 0; Else NoScheduled">
                    <span *ngFor="let n of scheduledNegotiations | filterNegotiations: filterSchedule"
                        class="schedulingCont__scheduleCards__card__list__element">
                        <span class="schedulingCont__scheduleCards__card__list__element">
                            <div class="customer">
                                <span><b style="color: gray;">#C{{n.FKNegotiation}} </b>{{n.Customer}}</span>
                                <span style="font-size: 0.8rem;">{{n.CLABE}}</span>
                            </div>
                            <div class="negotiation">
                                <div class="responsable">
                                    <span>Agendó {{n.Responsible}}</span>
                                    <span>{{pipe.transform(n.PaybackDate, 'longDate')}}</span>
                                </div>
                                <span class="paybackAmount">{{n.PaybackAmount | currency : '$ '}}</span>
                            </div>
                            <div class="actions">
                                <span class="m19IconBtn"
                                    (click)="openCommentsView(n.FKNegotiation)"><mat-icon>forum</mat-icon>{{n.Comments}}</span>
                                <!-- <span class="m19IconBtn"><mat-icon>history</mat-icon></span> -->
                                <span class="m19IconBtn"
                                    (click)="printPaybackReceipt(n.FKNegotiation, n.PKPaybackScheduled, n.PaybackAmount, n.Customer)"><mat-icon>receipt</mat-icon></span>
                            </div>

                            <span class="scheduled" *ngIf="n.PaybackState == 1 && n.DaysRemaining == 1">
                                <span class="scheduled--nearby">
                                    Agendado para mañana
                                </span>
                            </span>

                            <span class="scheduled" *ngIf="n.PaybackState == 1 && n.DaysRemaining > 1">
                                <span class="scheduled--normal">
                                    Agendado para el {{pipe.transform(n.PaybackDate, 'longDate')}}
                                </span>
                            </span>

                            <span class="scheduled" *ngIf="n.PaybackState == 1 && n.DaysRemaining == 0">
                                <span class="scheduled--today">
                                    Agendado para hoy
                                </span>
                            </span>

                            <span class="scheduled" *ngIf="n.PaybackState == 3">
                                <span class="scheduled--failed">
                                    Pago incumplido
                                </span>
                            </span>
                        </span>
                    </span>
                </ng-container>

                <ng-template #NoScheduled>
                    <div class="schedulingCont__scheduleCards__card__list__noNegotiation">
                        <h4>No hay negociaciones programadas</h4>
                        <mat-icon>mood</mat-icon>
                    </div>
                </ng-template>
            </div>
        </div>

        <div id="endedTab" class="schedulingCont__scheduleCards__card">
            <div class="schedulingCont__scheduleCards__card__tab blue">
                <mat-icon (click)="showTab('ended')">check_box</mat-icon>
            </div>

            <div class="schedulingCont__scheduleCards__card__title blue">
                <h5>Liquidadas</h5>
                <mat-icon class="m19IconBtn altBtn" (click)="hideTab('ended')">visibility_off</mat-icon>
            </div>

            <span class="schedulingCont__scheduleCards__card__list" style="display: flex; justify-content: center; align-items: center;">
                <span>Próximamente</span>
                <mat-icon class="material-symbols-outlined">scheduled</mat-icon>
            </span>
        </div>
    </span>
</div>