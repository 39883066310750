import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MapsService } from 'src/app/services/maps.service';
import { DialogMapsSelectComponent } from '../dialog-maps-select/dialog-maps-select.component';
import { DialogSelectedTypeUserComponent } from '../dialog-selected-type-user/dialog-selected-type-user.component';
import { ProjectService } from 'src/app/services/project.service';
import { Pointofmap } from '../pointofmap';
import { loader } from 'src/app/ui/loader/loader.model';
import * as jsPDF from 'jspdf';

import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-mapsfor-sales',
  templateUrl: './mapsfor-sales.component.html',
  styleUrls: ['./mapsfor-sales.component.scss']
})
export class MapsforSalesComponent implements OnInit {

  //obtener referencia directa a el canvas del html
  @ViewChild('canvasRef', { static: false }) canvasRef: any

  loader: loader = {
    message: "Generando Mapa de Venta"
  }

  loading = 0;

  optionsOpened: boolean = false;

  projectDetail: any = {};

  //genera el contexto para poderse adaptar al navegador en curso. 
  private cx!: CanvasRenderingContext2D;

  //vandera para saber si esta permitido o no dibujar
  public isAvailabe: boolean = false;

  //Variable que renderiza imagen y canvas
  public renderis = 2;

  //Valores dinamicos del tamaño del canvas, siendo los valores obtenidos de la imagen de Autocad.
  public widthOB!: number;
  public heightOB!: number;

  //objeto de tipo punto
  public pointe: Pointofmap = {};

  //arreglo  de tipo pointofmap
  public pointes: Array<Pointofmap> = [];

  //obejto seleccionado 
  public pointSelected: Pointofmap = {};

  widthO = 0;
  heightO = 0;

  iphone = false;

  //contador para el nombre (PRUEBAS)
  public cont = 0;
  letters = '0123456789ABCDEF';
  color = '#';

  availableColor = '';

  //Link para almacenar la imagen seleccionada.
  linkMap = "";


  //Este listener es el encargado de obtener los valores de DONDE SE DA CLICK EL MOUSE  
  @HostListener('click', ['$event'])
  onClick = (e: any) => {
    if (e.target.id === 'canvasId') {
      if (this.isTouched(e) == true && this.isAvilable(e) == true) {
        this.registerNewUser();
      }
    }
  }

  constructor(
    public dialog: MatDialog,
    private MapsService: MapsService,
    private projectService: ProjectService,
    private router: Router,
    private sanitizer: DomSanitizer

  ) { }


  ngAfterContentInit(): void {
    localStorage.removeItem('PointsOfMap');
    localStorage.removeItem('selectednomenclature');
    localStorage.removeItem('selectedName');
    localStorage.removeItem('inmueble');
    localStorage.removeItem('linkMap');

    this.selectSale();
  }

  ngOnInit(): void {
    this.getAvailableColor();
  }

  getProjectDetail() {
    this.projectService.selectProject(localStorage.getItem('selectednomenclature')).subscribe(
      res => {
        this.projectDetail = res;
      }
    );
  }

  changeLoadingMessage(msg: string) {
    this.loader = {
      ...this.loader,
      message: msg
    };
  }

  changeOptionsState() {
    this.optionsOpened = !this.optionsOpened;
  }

  //Registrar al pre cliente.
  registerNewUser() {
    const dialog2 = this.dialog.open(DialogSelectedTypeUserComponent, {
      width: '300px',
      height: '150px'
    })
  }

  async generatePDF() {
    this.loading = 1;

    setTimeout(() => {
      const canvas = document.getElementById('canvasId') as HTMLCanvasElement; // obtiene el canvas
      let imgWidth = canvas.width;
      let imgHeight = canvas.height;
      const imgData = canvas.toDataURL('image/png'); // obtiene la imagen del canvas como base64
      const doc = new jsPDF('l'); // crea una instancia de jsPDF
      let pageWidth = doc.internal.pageSize.getWidth();
      let pageHeight = doc.internal.pageSize.getHeight();
      var widthRatio = pageWidth / imgWidth; // calcula el ratio de ancho
      var heightRatio = pageHeight / imgHeight;
      var ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
      doc.addImage(imgData, 'PNG', 0, 0, imgWidth * ratio, imgHeight * ratio);
      doc.save('Disponibilidad ' + localStorage.getItem("selectedName") + '.pdf');

      this.loading = 0;
    }, 1000);
  }

  openPDF() {
    this.loading = 1;

    setTimeout(() => {
      const canvas = document.getElementById('canvasId') as HTMLCanvasElement;
      let imgWidth = canvas.width;
      let imgHeight = canvas.height;
      const imgData = canvas.toDataURL('image/png');
      const doc = new jsPDF('l');
      let pageWidth = doc.internal.pageSize.getWidth();
      let pageHeight = doc.internal.pageSize.getHeight();
      var widthRatio = pageWidth / imgWidth;
      var heightRatio = pageHeight / imgHeight;
      var ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
      doc.addImage(imgData, 'PNG', 0, 0, imgWidth * ratio, imgHeight * ratio);
      var pdfOutput = doc.output();
      var url = URL.createObjectURL(new Blob([pdfOutput], { type: 'application/pdf' }));
      window.open(url);

      this.loading = 0;
    }, 1000);
  }

  getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  async getAvailableColor() {
    this.MapsService.getAvailableColor().subscribe(
      res => {
        this.availableColor = res.HTMLColors;
      }
    );
  }

  //Seleccionamos el mapa a vizualizar.
  selectSale() {
    const dialogo1 = this.dialog.open(DialogMapsSelectComponent
      , {
        width: '60%',
        height: 'auto',
      });

    dialogo1.afterClosed().subscribe(result => {
      if (result) {
        this.getProjectDetail();
        setTimeout(() => {
          this.inicializar();
        }, 500);
      }
    }
    );
  }

  public inicializar() {
    this.loading = 1;
    setTimeout(() => {
      this.getValues();
    }, 900);
  }


  alternarVista() {
    this.iphone = !this.iphone;
    this.inicializar();
  }

  //funcion que obtiene los valores de la imagen obtenida
  private getValues() {
    this.changeLoadingMessage("Cargando Plano de Lotificación");
    var img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = this.getSafeUrl(localStorage.getItem('linkMap'))['changingThisBreaksApplicationSecurity'] || "";

    img.onload = () => {

      this.widthOB = img.width;
      this.heightOB = img.height;

      if (this.widthOB == 0 || this.heightOB == 0) {
        this.getValues();
      } else {
        this.transforJSONtoPOINT();

        setTimeout(() => {
          this.render();
        }, 900);
      }
    };
  }

  //ocupamos renderizar el nuevo contexto para que obtenga los nuevos valores
  //obtenemos el contexto, le asignamos los valores que necesitamos y mandamos a llamar.
  private render() {
    this.changeLoadingMessage("Renderizando Mapa");
    const canvasEl = this.canvasRef.nativeElement;
    this.cx = canvasEl.getContext('2d');
    canvasEl.width = this.widthOB / this.renderis;
    canvasEl.height = this.heightOB / this.renderis;

    this.cx.lineWidth = 3;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = '#000';

    setTimeout(() => {
      this.mapst(localStorage.getItem('linkMap') || "");
    }, 1000);
  }

  //funcion que pone de fondo el mapa que recibe como parametro (se encuentra estatico en este momento)
  private mapst(link: any) {
    //obtenemos el contexto
    const canvasEl = this.canvasRef.nativeElement;
    var ctx = canvasEl.getContext("2d");

    //renderizar a un tercio la escala de la resolucin maxima
    let x = this.widthOB / this.renderis
    let y = this.heightOB / this.renderis

    //declaramos la variable que almacenara la imagen
    var img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = this.getSafeUrl(link)['changingThisBreaksApplicationSecurity'] + "";

    //cargamos la imagen, como no sabemos si ya se encuentra descargada, usamos onload
    img.onload = function () {
      ctx.drawImage(img, 0, 0, (x), (y));
    }

    setTimeout(() => {
      this.writeCircules();
    }, 2000);
  }


  //refresca la pagina 
  refresh(): void { window.location.reload(); }

  //funcion encargada de evaluar que el contexto exista para pintar sobre el la funcion de drawCircle.
  drawOnCanvas(point: Pointofmap) {
    if (!this.cx) {
      console.log("NO EXISTE EL CONTEXTO")
      return
    }
    this.drawCircle(point);
  }

  //Funcion que dibuja circulos en las cordendas X y Y descritas.
  private drawCircle(point: Pointofmap) {
    this.changeLoadingMessage("Realizando Punteo de Mapa");

    if (point.HTMLColors == this.availableColor) {
      const canvasEl = this.canvasRef.nativeElement;

      if (canvasEl.getContext) {

        var ctx = canvasEl.getContext('2d');

        ctx.beginPath();

        let radius = 0;

        if (this.iphone === true) {
          radius = 2;
        } else {
          radius = 20;
        }

        ctx.arc(point.Xmap, point.Ymap, radius, 0, Math.PI * 2, true); // Círculo externo

        ctx.fillStyle = point.HTMLColors + "80"; //asignar color

        ctx.fill(); //mandar a llamar el color

        this.property(point.Xmap, point.Ymap, point.codeProperty + "")

        this.loading = 0;
      }

      this.loading = 0;
    }

    this.loading = 0;
  }

  private property(x: number, y: number, property: string) {
    const canvasEl = this.canvasRef.nativeElement;

    const ctx = canvasEl.getContext('2d');

    ctx.font = this.iphone === true ? '10px serif' : '14px serif';

    ctx.fillStyle = 'black'
    ctx.fillText(property, x - 30, y + 30, 140);
  }

  private transforJSONtoPOINT() {
    this.pointes = JSON.parse(localStorage.getItem('PointsOfMap') || "")
  }

  //funcion encargada de Dibujar las coordenadas del circulo de un arreglo a su vez evalua y empieza el proceso de pintado
  private writeCircules() {
    this.changeLoadingMessage("Consultando Disponibilidad");
    for (var i = 0; i < this.pointes.length; i++) {
      const prevPost = this.pointes[i]

      if (this.iphone === true) {
        prevPost.Xmap = (2290 * prevPost.Xmap) / this.widthO;
        prevPost.Ymap = (2289 * prevPost.Ymap) / this.heightO;
      }

      this.drawOnCanvas(prevPost)
    }
  }

  // funcion que evalua si estoy dando click a un disponible
  private isAvilable(res: { clientX: number; clientY: number; }): boolean {
    const canvas = this.canvasRef.nativeElement;

    const rect = canvas.getBoundingClientRect();

    const prevPos = {
      x: res.clientX - rect.left,
      y: res.clientY - rect.top
    };

    for (var i = 0; i < this.pointes.length; i++) {
      var dx = Math.abs(prevPos.x - this.pointes[i].Xmap);
      var dy = Math.abs(prevPos.y - this.pointes[i].Ymap);

      var distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
      let radius = 0;

      if (this.iphone === true) {
        radius = 2;
      } else {
        radius = 10;
      }

      if (
        distance <= radius
      ) {
        this.pointSelected = this.pointes[i];
        localStorage.setItem("InmuebleDigital", this.pointSelected.codeProperty || "");
        return true;
      }
    }
    return false;
  }

  // funcion que evalua si estoy dando click o no al punto a travez de matematicas.
  private isTouched(res: { clientX: number; clientY: number; }): boolean {
    const canvas = this.canvasRef.nativeElement;

    const rect = canvas.getBoundingClientRect();

    const prevPos = {
      x: res.clientX - rect.left,
      y: res.clientY - rect.top
    };

    for (var i = 0; i < this.pointes.length; i++) {
      var dx = Math.abs(prevPos.x - this.pointes[i].Xmap);
      var dy = Math.abs(prevPos.y - this.pointes[i].Ymap);

      var distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

      let radius = 0;

      if (this.iphone === true) {
        radius = 2;
      } else {
        radius = 10;
      }

      if (
        distance <= radius
      ) {
        if (this.pointes[i].HTMLColors == this.availableColor) {
          console.log("pointes", this.pointes);
          this.pointSelected = this.pointes[i];
          console.log("punto seleccionado es", this.pointSelected)
          localStorage.setItem("InmuebleDigital", this.pointSelected.codeProperty || "")
          return true;
        }
      }
    }
    return false;
  }
}