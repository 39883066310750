import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecessionsService {

  constructor(private http: HttpClient) { }

  getCustomerInfo(property: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/recessions/getCustomerInfo/${property}`);
  }

  blockUser(property: String, reason: number, user: number): Observable<any> {
    return this.http.put(`${environment.URLServer}/recessions/blockCustomer/${property}`, [reason, user]);
  }

  getBlockStatus(project: String): Observable<any> {
    return this.http.get(`${environment.URLServer}/recessions/getBlockStatus/${project}`);
  }

  getBlockStatusUnfinished(project: String): Observable<any> {
    return this.http.get(`${environment.URLServer}/recessions/getBlockStatusUnfinished/${project}`);
  }

  getCustomersWithBlock(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/recessions/getCustomersWithBlock/${id}`);
  }

  getPropertiesWithBlockByCustomer(user: number, project: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/recessions/getPropertiesWithBlockByCustomer/${user}/${project}`);
  }

  getPropertiesWithBlockByCustomerR(user: number, project: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/recessions/getPropertiesWithBlockByCustomerR/${user}/${project}`);
  }

  getCustomerBlockInfo(user: number, project: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/recessions/getCustomerBlockInfo/${user}/${project}`);
  }

  getCustomerBlockInfoR(user: number, project: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/recessions/getCustomerBlockInfoR/${user}/${project}`);
  }

  getRecessionsType(): Observable<any> {
    return this.http.get(`${environment.URLServer}/recessions/getRecessionsType`);
  }

  makeNegotiation(neg: any): Observable<any> {
    return this.http.post(`${environment.URLServer}/recessions/makeNegotiation`, neg);
  }

  requestRecession(property: String, customer: number): Observable<any> {
    return this.http.put(`${environment.URLServer}/recessions/requestRecession/${property}`, [customer]);
  }

  makeRecession(property: String): Observable<any> {
    return this.http.delete(`${environment.URLServer}/recessions/cancelSale/${property}`);
  }

  getRecessions(): Observable<any> {
    return this.http.get(`${environment.URLServer}/recessions/getRecessions`);
  }

  changRecessionsReasonBlock(fk_blockReason: Number, idRecession: Number): Observable<any> {
    return this.http.put(`${environment.URLServer}/recessions/changRecessionsReasonBlock/${fk_blockReason}/${idRecession}`, {});
  }

  setRecessionAgreement(id: number): Observable<any> {
    return this.http.put(`${environment.URLServer}/recessions/setRecessionAgreement/${id}`, 'ola soy una mala practica');
  }

  updateExpRecessions(contracts: number, id: number): Observable<any> {
    return this.http.put(`${environment.URLServer}/recessions/updateExpRecessions/${contracts}/${id}`, 'ola soy una mala practica x2');
  }

  recessionPayN(idUser: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/recessions/recessionPayN/${idUser}`);
  }

  recessionInsertTicketPay(NTickets: number, id: string): Observable<any> {
    return this.http.put(`${environment.URLServer}/recessions/recessionInsertTicketPay/${NTickets}/${id}`, {});
  }

  deleteRecessionAgreement(id: string): Observable<any> {
    return this.http.put(`${environment.URLServer}/recessions/deleteRecessionAgreement/${id}`, {});
  }

  updateExpedient(id: string): Observable<any> {
    return this.http.put(`${environment.URLServer}/recessions/updateExpedient/${id}`, {});
  }
}
