import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SaleService } from '../../../services/sale.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SweetAlertComponent } from '../../../utils/sweet-alert/sweet-alert.component';
import { Sales } from '../../../models/Sales';

@Component({
  selector: 'app-dialog-sale-form',
  templateUrl: './dialog-sale-form.component.html',
  styleUrls: ['./dialog-sale-form.component.scss']
})
export class DialogSaleFormComponent implements OnInit {

  saleFormGroup: FormGroup = this._formBuilder.group({
    idSale: [],
    IdContract: [],
    fk_property: [, Validators.required],
    fk_customer: [, Validators.required],
    coowner: [],
    fk_seller: [],
    fk_user: [, Validators.required],
    contractDate: [(localStorage.getItem('contractDate'))],
    fechaDato: [Date, Validators.required]
  });
  
  constructor(
    private dialogRef: MatDialogRef<DialogSaleFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Sales,
    private saleService: SaleService,
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent
  ) { 
    this.dialogRef.disableClose = true;
    this.sale = data;
  }

  sale: any = [];

  ngOnInit(): void {
  }

  updateSale(id: number, sale: any) {
    if (this.saleFormGroup.invalid) {
      return;
    }
    else {
      sale = this.saleFormGroup.value
      this.saleService.updateSale(id,sale).subscribe(
        res => {
          this.sweet.AlertTime("center", "success", "Venta modificada con éxito", false, 2000);
        },
        err => {
          console.error(err);
          this.sweet.AlertTime("center", "error", "Venta no modificada", false, 2000);
        }
      );
    }
  }

}
