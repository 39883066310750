<mat-tab-group mat-stretch-tabs>

    <!-- CORTE DE CAJA NUEVO-->
    <mat-tab>
        <ng-template mat-tab-label>
            Corte de Caja <mat-icon>move_to_inbox</mat-icon>
        </ng-template>

        <div class="grid-container-CashCut">

            <div class="grid-title">

                <h2 style="display:flex; align-items: center; color:#d33e3e;  font-weight: bold;">
                    CORTE DEL DIA:
                </h2>
                <h3 style="display:flex; align-items: center; color:black; margin-top: 13px;">
                    {{pipe.transform(fechaActual ,'EEEE')!.toUpperCase()}} {{pipe.transform(fechaActual
                    ,'longDate')!.toUpperCase()}} </h3>

                <form [formGroup]="cashCutFormGroup" [hidden]="true"
                    style="margin-top:30px; margin-bottom:30px; margin-left: 50px;  border-radius: 10px; border: 1px solid rgb(102, 102, 102); ">
                    <!--(ngSubmit)="createPayment();">-->
                    <input matInput type="date" formControlName="curDate" (change)="selectInfoOnChange()"
                        [hidden]="false">
                </form>


            </div>

            <div class="grid-options">
                <button (click)="getAllPaymentsByCashAndDate();"> Efectivo <mat-icon> attach_money</mat-icon> </button>
                <button (click)="getAllPaymentsByDepositAndDate();"> Deposito <mat-icon> money</mat-icon> </button>
                <button (click)="getAllPaymentsByTransferAndDate();"> Transferencia <mat-icon> transit_enterexit
                    </mat-icon> </button>
                <button (click)="getAllPaymentsByTDCAndDate();"> TDC <mat-icon>credit_card</mat-icon> </button>
                <button (click)="getAllPaymentsByTDDAndDate();"> TDD <mat-icon>credit_card</mat-icon> </button>
                <button (click)="getAllPaymentsByCheckAndDate();"> Cheques <mat-icon>edit</mat-icon> </button>
                <button (click)="getAllPaymentsByDate();"> Todos <mat-icon>language</mat-icon> </button>

            </div>


            <div class="grid-entries" *ngIf="flagCash == true;">


                <div class="information-container">
                    <h3>
                        Entradas mediante <b> Efectivo </b>
                    </h3>

                    <table class="table table-striped mt-3 animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr style="text-align:left; ">
                                <th> id </th>

                                <th> Nombre </th>
                                <th>Propiedad</th>
                                <th style="text-align:Right ;">Cantidad</th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr *ngFor="let cash of cashTotal" style="text-align: left; overflow: scroll; ">

                                <td>

                                    {{cash.folioPago}}
                                </td>

                                <td>

                                    {{cash.cliente}}
                                </td>

                                <td>

                                    {{cash.fkCliente}}
                                </td>

                                <td style="text-align:Right ;">

                                    {{cash.cantidadAbono | currency: 'CAD': '$'}}
                                </td>


                            </tr>
                        </tbody>
                    </table>

                </div>


            </div>

            <div class="grid-entries" *ngIf="flagDeposit == true;">


                <div class="information-container">
                    <h3>
                        Entradas mediante <b> Depositos </b>
                    </h3>

                    <table class="table table-striped mt-3 animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr style="text-align:left; ">
                                <th> id </th>

                                <th> Nombre </th>
                                <th>Propiedad</th>
                                <th style="text-align:Right ;">Cantidad</th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr *ngFor="let deptot of depositTotal" style="text-align: left; overflow: scroll; ">

                                <td>

                                    {{deptot.folioPago}}
                                </td>

                                <td>

                                    {{deptot.cliente}}
                                </td>

                                <td>

                                    {{deptot.fkCliente}}
                                </td>

                                <td style="text-align:Right ;">

                                    {{deptot.cantidadAbono | currency: 'CAD': '$'}}
                                </td>


                            </tr>
                        </tbody>
                    </table>

                </div>


            </div>

            <div class="grid-entries" *ngIf="flagTransfer == true;">


                <div class="information-container">
                    <h3>
                        Entradas mediante <b> Tranferencias </b>
                    </h3>

                    <table class="table table-striped mt-3 animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr style="text-align:left; ">
                                <th> id </th>

                                <th> Nombre </th>
                                <th>Propiedad</th>
                                <th style="text-align:Right ;">Cantidad</th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr *ngFor="let transtot of transferTotal" style="text-align: left; overflow: scroll; ">

                                <td>

                                    {{transtot.folioPago}}
                                </td>

                                <td>

                                    {{transtot.cliente}}
                                </td>

                                <td>

                                    {{transtot.fkCliente}}
                                </td>

                                <td style="text-align:Right ;">

                                    {{transtot.cantidadAbono | currency: 'CAD': '$'}}
                                </td>


                            </tr>
                        </tbody>
                    </table>

                </div>


            </div>

            <div class="grid-entries" *ngIf="flagCreditCard == true;">


                <div class="information-container">
                    <h3>
                        Entradas mediante <b> Tarjeta de credito </b>
                    </h3>

                    <table class="table table-striped mt-3 animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr style="text-align:left; ">
                                <th> id </th>

                                <th> Nombre </th>
                                <th>Propiedad</th>
                                <th style="text-align:Right ;">Cantidad</th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr *ngFor="let credCard of creditCardTotal" style="text-align: left; overflow: scroll; ">

                                <td>

                                    {{credCard.folioPago}}
                                </td>

                                <td>

                                    {{credCard.cliente}}
                                </td>

                                <td>

                                    {{credCard.fkCliente}}
                                </td>

                                <td style="text-align:Right ;">

                                    {{credCard.cantidadAbono | currency: 'CAD': '$'}}
                                </td>


                            </tr>
                        </tbody>
                    </table>

                </div>


            </div>

            <div class="grid-entries" *ngIf="flagDebitCard == true;">


                <div class="information-container">
                    <h3>
                        Entradas mediante <b> Tarjeta de debito </b>
                    </h3>

                    <table class="table table-striped mt-3 animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr style="text-align:left; ">
                                <th> id </th>

                                <th> Nombre </th>
                                <th>Propiedad</th>
                                <th style="text-align:Right ;">Cantidad</th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr *ngFor="let debCard of debitCardTotal" style="text-align: left; overflow: scroll; ">

                                <td>

                                    {{debCard.folioPago}}
                                </td>

                                <td>

                                    {{debCard.cliente}}
                                </td>

                                <td>

                                    {{debCard.fkCliente}}
                                </td>

                                <td style="text-align:Right ;">

                                    {{debCard.cantidadAbono | currency: 'CAD': '$'}}
                                </td>


                            </tr>
                        </tbody>
                    </table>

                </div>


            </div>

            <div class="grid-entries" *ngIf="flagCheck == true;">


                <div class="information-container">
                    <h3>
                        Entradas mediante <b> Cheques </b>
                    </h3>

                    <table class="table table-striped mt-3 animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr style="text-align:left; ">
                                <th> id </th>

                                <th> Nombre </th>
                                <th>Propiedad</th>
                                <th style="text-align:Right ;">Cantidad</th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr *ngFor="let check of checkTotal" style="text-align: left; overflow: scroll; ">

                                <td>

                                    {{check.folioPago}}
                                </td>

                                <td>

                                    {{check.cliente}}
                                </td>

                                <td>

                                    {{check.fkCliente}}
                                </td>

                                <td style="text-align:Right ;">

                                    {{check.cantidadAbono | currency: 'CAD': '$'}}
                                </td>


                            </tr>
                        </tbody>
                    </table>

                </div>


            </div>

            <div class="grid-entries" *ngIf="flagEverything == true;">


                <div class="information-container">
                    <h3>
                        Entradas de todo tipo
                    </h3>

                    <table class="table table-striped mt-3 animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr style="text-align:left; ">
                                <th> id </th>
                                <th> Metodo </th>
                                <th> Nombre </th>
                                <th>Propiedad</th>
                                <th style="text-align:Right ;">Cantidad</th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr *ngFor="let all of everythingTotal" style="text-align: left; overflow: scroll; ">

                                <td>

                                    {{all.folioPago}}
                                </td>

                                <td>

                                    {{all.metodoPago}}
                                </td>

                                <td>

                                    {{all.cliente}}
                                </td>

                                <td>

                                    {{all.fkCliente}}
                                </td>

                                <td style="text-align:Right ;">

                                    {{all.cantidadAbono | currency: 'CAD': '$'}}
                                </td>


                            </tr>
                        </tbody>
                    </table>

                </div>


            </div>

            <div class="grid-outputs">

                <div class="information-container">
                    <h3 style="text-align:left ;">
                        Salidas
                    </h3>

                    <table class="table table-striped mt-3 animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr style="text-align: left; ">
                                <th> Concepto </th>
                                <th> Solicitó </th>
                                <th style="text-align:Right ;"> Cantidad </th>

                        </thead>

                        <tbody>

                            <tr *ngFor="let out of salCashToday;" style="text-align: left; overflow: scroll;">

                                <td> {{out.reason}}</td>
                                <td> {{out.requestor}}</td>
                                <td style="text-align:Right ;"> {{(out.quantity | currency: 'CAD': '$')}}</td>

                            </tr>
                        </tbody>
                    </table>

                </div>


            </div>


            <div class="grid-currentInfo">
                <div class="headerSummary">
                    <h3>
                        Resumen del dia
                    </h3>
                </div>

                <h5 style="margin-top: 5px; ">
                    Efectivo
                </h5>

                <div class="info-payment-methods">


                    <div class="row-format">
                        <p> Ingresos:
                            <b style="color:black"> ${{(totCash).toFixed(2)}} </b>
                        </p>
                    </div>

                    <div class="row-format">
                        <p> Salidas:
                            <b style="color:black"> ${{(totPayEgre).toFixed(2)}} </b>
                        </p>
                    </div>

                    <div class="row-format">
                        <p> Total efectivo:
                            <b style="color:black"> ${{(totCash - totPayEgre).toFixed(2)}} </b>
                        </p>
                    </div>


                </div>

                <h6>
                    Otros metodos de pago:
                </h6>


                <div class="info-payment-methods">

                    <div class="row-format">
                        <p> TRANFERENCIA:

                            <b style="color:black"> ${{(totTranser).toFixed(2)}} </b>
                        </p>
                    </div>

                    <div class="row-format">
                        <p> DEPOSITO:
                            <b style="color:black"> ${{(totDeposit).toFixed(2)}} </b>
                        </p>
                    </div>

                    <div class="row-format">
                        <p> TDC:
                            <b style="color:black"> ${{(totCreditCard).toFixed(2)}} </b>
                        </p>
                    </div>

                    <div class="row-format">
                        <p> TDD:
                            <b style="color:black"> ${{(totDebitCard).toFixed(2)}} </b>
                        </p>
                    </div>

                    <div class="row-format">
                        <p> Cheque:
                            <b style="color:black"> ${{(totCheck).toFixed(2)}} </b>
                        </p>
                    </div>

                    <h5 style="border-top: solid 2px black; padding-top: 5px; text-align: center;">
                        Total Bruto: <b style="color:black"> ${{ (totEverything).toFixed(2) }}</b>
                    </h5>

                    <h5 style="text-align: center;">
                        Total Neto: <b style="color:black">
                            ${{ ((totCash + totTranser + totDeposit + totCreditCard +
                            totDebitCard + totCheck) - totPayEgre).toFixed(2)}}
                        </b>
                    </h5>
                </div>

            </div>

            <div class="grid-cashCut">
                <button (click)="corteCaja();"> Cierre de caja <mat-icon> print </mat-icon> </button>
            </div>

            <div class="grid-printCut" id="htmlData" [hidden]="true">


                <div class="encabezado">
                    <h1> Cierre de caja </h1>
                    <br>

                    <div class="tiempo-impresion">
                        <p> 
                            <b style=" padding-right: 5px;"> FECHA DE CORTE : </b>
                                {{pipe.transform(currentDate,'EEEE')!.toLowerCase()}} {{pipe.transform(currentDate ,'longDate')!.toLowerCase()}}
                        </p>
                    </div>
                    <br>

                    <div class="margen">
                        <b style=" padding-right: 5px;"> FECHA DE IMPRESIÓN: </b>
                        <p> {{currentDate | date: 'dd/MM/yyyy' }} </p>
                        <!-- <p> {{pipe.transform(currentDate,'EEEE')!.toLowerCase()}} {{pipe.transform(currentDate ,'longDate')!.toLowerCase()}} </p> -->
                        <!-- {{pipe.transform(currentDate,'EEEE')!.toLowerCase()}} {{pipe.transform(currentDate ,'longDate')!.toLowerCase()}} -->
                    </div>

                </div>

                <br>
                <h4> EFECTIVO RECIBIDO </h4>

                <table class="formatTableBorders">
                    <thead>
                        <tr class="tableTextHeader">
                            <th class="tableTextHeader">#</th>
                            <th class="tableTextHeader">Id</th>
                            <th class="tableTextHeader">Metodo de pago</th>
                            <th class="tableTextHeader">Nombre</th>
                            <th class="tableTextHeader">Propiedad </th>
                            <th class="tableTextHeader">Cantidad</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let payTo of paymentaToday; let cashto of cashTotal;  let i=index;">
                            <td class="tableDataText1">{{i+1}}</td>
                            <td class="tableDataText1"> {{cashto.folioPago }} </td>
                            <td class="tableDataText1"> {{cashto.metodoPago}} </td>
                            <td class="tableDataText2"> {{cashto.cliente}} </td>
                            <td class="tableDataText1"> {{cashto.fkCliente}} </td>
                            <td class="tableDataText1" style="text-align: right;"> {{(cashto.cantidadAbono | currency:
                                'CAD': '$')}}</td>
                        </tr>

                        <tr *ngFor="let check of checkTotal; let i=index;">
                            <td class="tableDataText1">{{i+1}}</td>
                            <td class="tableDataText1"> {{check.folioPago }} </td>
                            <td class="tableDataText1"> {{check.metodoPago}} </td>
                            <td class="tableDataText2"> {{check.cliente}} </td>
                            <td class="tableDataText1"> {{check.fkCliente}} </td>
                            <td class="tableDataText1"> {{(check.cantidadAbono | currency: 'CAD': '$')}}</td>
                        </tr>

                    </tbody>
                </table>

                <div class="totalSeccion">


                    <br>
                    <br>
                    <h4 *ngIf="salCashToday.length > 0"> SALIDAS DE EFECTIVO </h4>

                    <table class="formatTableBorders" *ngIf="salCashToday.length > 0 ">
                        <thead>
                            <tr class="tableTextHeader">

                                <th class="tableTextHeader"> # </th>
                                <th class="tableTextHeader"> Fecha </th>
                                <th class="tableTextHeader"> Concepto </th>
                                <th class="tableTextHeader"> Quien Solicito el Dinero </th>
                                <th class="tableTextHeader"> Cantidad </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let out of salCashToday;">


                                <td class="tableDataText1"> {{out.idCashOut }}</td>
                                <td class="tableDataText1"> {{out.registrationDate | date: 'dd/MM/yyyy'}}</td>
                                <td class="tableDataText2"> {{out.reason}}</td>
                                <td class="tableDataText2"> {{out.requestor}}</td>
                                <td class="tableDataText1" style="text-align: right;"> {{(out.quantity | currency:
                                    'CAD':
                                    '$')}}</td>

                            </tr>
                        </tbody>
                    </table>

                    <br>

                    <h4> TOTALES </h4>

                    <div class="infoColumn">
                        <div class="texto-salida">
                            <p> <b> ENTRADAS: </b> </p>
                            <p> {{(totCash).toFixed(2)}} </p>

                        </div>

                        <div class="texto-salida">
                            <p> <b> SALIDAS: </b> </p>
                            <p> {{(totPayEgre).toFixed(2)}} </p>
                        </div>


                        <div class="texto-salida">
                            <p> <b> TOTAL: </b> </p>
                            <p class="salida-neta"> {{((totCash + totCheck) - totPayEgre).toFixed(2)}} </p>
                        </div>


                    </div>

                    <br>
                    <br>
                    <br>

                    <div class="shared-Container">

                        <div class="information-Delivery">
                            <h3> ENTREGA </h3>
                            <br>
                            <br>
                            <br>
                            <br>
                            <h3> ___________________________________ </h3>
                            <br>
                            <h3> {{getUser().toUpperCase()}} </h3>
                            <br>

                        </div>

                        <div class="information-Receive">
                            <h3> RECIBE </h3>
                            <br>
                            <br>
                            <br>
                            <br>
                            <h3> ___________________________________ </h3>
                            <br>
                            <h3> {{this.assign.toUpperCase()}} </h3>
                            <br>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </mat-tab>

    <!-- REGISTRO DE SALIDAS DE EFECTIVO -->
    <mat-tab>

        <ng-template mat-tab-label>
            Salidas de Efectivo <mat-icon> attach_money</mat-icon>
        </ng-template>

        <div class="container-fluid">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">

                    <div class="contenedorRegistro">
                        <div class="registerFields">
                            <form [formGroup]="CashOutflowsFormGroup" (ngSubmit)="registerOutCash();">
                                <!--(ngSubmit)="createPayment();">-->
                                <h5>Registro de salida de efectivo</h5>

                                <div class="ng-autocomplete">
                                    <ng-autocomplete [data]="employ" [searchKeyword]="keyword"
                                        placeholder="Quien Solicita el dinero" (selected)='nameAssignment($event)'
                                        [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" required>

                                    </ng-autocomplete>
                                    <ng-template #itemTemplate let-item>
                                        <a [innerHTML]="item.empleado"></a>
                                    </ng-template>
                                    <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>


                                <div class="registerRow">

                                    <mat-form-field appearance="outline">
                                        <mat-label>Cantidad</mat-label>
                                        <input matInput type="number" formControlName="quantity" required>
                                        <mat-icon matSuffix> attach_money</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Usuario que entrega el efectivo </mat-label>
                                        <input matInput type="text" formControlName="cashManager" readonly>
                                        <mat-icon matSuffix> person_pin</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Fecha</mat-label>
                                        <input matInput type="date" formControlName="registrationDate" required>

                                    </mat-form-field>


                                </div>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label> Concepto </mat-label>
                                        <input matInput type="text" formControlName="reason" required>
                                        <mat-icon matSuffix>text_rotation_none</mat-icon>
                                    </mat-form-field>
                                </div>


                                <button class="saveButton" type="submit" [disabled]="!CashOutflowsFormGroup.valid">
                                    Registrar Salida
                                    <mat-icon>exit_to_app</mat-icon>
                                </button>

                            </form>

                        </div>

                    </div>

                </mat-card-content>

            </mat-card>

        </div>

    </mat-tab>

    <!-- iNGRESOS Y AGRESOS-->
    <mat-tab>

        <ng-template mat-tab-label>
            Consulta de ingresos y egresos <mat-icon>search</mat-icon>
        </ng-template>

        <div class="contenedor_principal">

            <div class="contenedor-menu-opciones">

                <div class="menu-botones" [hidden]="simple==false">

                    <button class="boton-impresion" (click)="entradasPorFechaEfectivo();"> Efectivo <mat-icon>
                            attach_money </mat-icon> </button>
                    <button class="boton-impresion" (click)="entradasPorFechaTransferencias();"> Transferencia
                        <mat-icon> transit_enterexit</mat-icon>
                    </button>
                    <button class="boton-impresion" (click)="entradasPorFechaDepositos();"> Deposito <mat-icon> money
                        </mat-icon> </button>
                    <button class="boton-impresion" (click)="entradasPorFechaTDC();"> TDC <mat-icon>credit_card
                        </mat-icon> </button>
                    <button class="boton-impresion" (click)="entradasPorFechaTDD();"> TDD <mat-icon>credit_card
                        </mat-icon> </button>
                    <button class="boton-impresion" (click)="entradasPorFechaCheques();"> Cheques <mat-icon>edit
                        </mat-icon> </button>
                    <!-- <button class="boton-impresion" > Nota de credito <mat-icon>speaker_notes</mat-icon> </button> -->
                    <!-- <button class="boton-impresion" > Nota de credito no rembolsable <mat-icon>speaker_notes_off</mat-icon> </button> -->
                    <button class="boton-impresion" (click)="entradasPorFechaTodo();"> Todos <mat-icon>language
                        </mat-icon> </button>
                </div>

                <div class="menu-botones" [hidden]="range==false">

                    <button class="boton-impresion" (click)="entradasPorRangoFechaEfectivo();"> Efectivo <mat-icon>
                            attach_money </mat-icon> </button>
                    <button class="boton-impresion" (click)="entradasPorRangoFechaTransferencia();"> Transferencia
                        <mat-icon> transit_enterexit</mat-icon>
                    </button>
                    <button class="boton-impresion" (click)="entradasPorRangoFechaDeposito();"> Deposito <mat-icon>
                            money
                        </mat-icon> </button>
                    <button class="boton-impresion" (click)="entradasPorRangoFechaTDC();"> TDC <mat-icon>credit_card
                        </mat-icon> </button>
                    <button class="boton-impresion" (click)="entradasPorRangoFechaTDD();"> TDD <mat-icon>credit_card
                        </mat-icon> </button>
                    <button class="boton-impresion" (click)="entradasPorRangoFechaCheque();"> Cheques <mat-icon>edit
                        </mat-icon> </button>
                    <!-- <button class="boton-impresion" > Nota de credito <mat-icon>speaker_notes</mat-icon> </button> -->
                    <!-- <button class="boton-impresion" > Nota de credito no rembolsable <mat-icon>speaker_notes_off</mat-icon> </button> -->
                    <button class="boton-impresion" (click)="entradasPorRangoFecha();"> Todos <mat-icon>language
                        </mat-icon> </button>
                </div>


            </div>

            <div class="contenedor-menu-tablas">

                <div class="contenedor-titulo-tablas">

                    <div class="contenedor-titulo-fechas">


                        <div class="contenedor-selector-tipo-busqueda">

                            <h5 style=" color:#d33e3e; font-weight: bold; "> Cambiar busqueda: </h5>
                            <mat-checkbox (change)="cambioVista($event.checked)" style="margin-top: 1%;">
                            </mat-checkbox>

                        </div>


                        <div class="contenedor-selector-fechas">

                            <div class="selector-fecha-sencilla" [hidden]="simple==false">

                                <mat-label>
                                    <h3 style="color: black;"> Seleccione una fecha: </h3>
                                </mat-label>

                                <form [formGroup]="querysDate">

                                    <input matInput type="date" formControlName="dateSimple"
                                        style="color: black; border: 2px solid black; height: 40px; border-radius: 10px;"
                                        (change)="queryByDate()" required>

                                </form>

                            </div>

                            <div class="selector-fecha-doble" [hidden]="range==false">


                                <form [formGroup]="querysDate"
                                    style="display:flex ; flex-direction:row; justify-content: space-around;">

                                    <input matInput type="text"
                                        style="color: black;  width: 50px; height: 40px; margin-left: 10px; margin-right: 20px;"
                                        value="Inicio" readonly>

                                    <input matInput type="date" formControlName="dateStart"
                                        style="color: black; border: 2px solid black; height: 40px; margin-right: 40px; border-radius: 10px; text-align: center;"
                                        required>

                                    <input matInput type="text"
                                        style="color: black;   width: 50px;  height: 40px; margin-right: 20px;"
                                        value="Fin" readonly>

                                    <input matInput type="date" formControlName="dateEnd"
                                        style="color: black; border: 2px solid black; height: 40px; border-radius: 10px; margin-right: 20px; text-align: center;"
                                        required>

                                </form>

                                <button mat-raised-button color="accent" (click)="queryByRangeDate()">
                                    <mat-icon>search</mat-icon>
                                </button>

                            </div>


                        </div>

                    </div>

                    <div class="contenedor-tablas" [hidden]="simple==false">

                        <div class="tabla-entradas">

                            <div *ngIf="flagCash == true;">

                                <h3 style="color: black;">
                                    Entradas por <b> Efectivo </b>
                                </h3>

                                <table class="table table-striped mt-3 animated fadeIn faster">
                                    <thead class="thead-m19">
                                        <tr style="text-align:left; ">
                                            <th> id </th>
                                            <th> Nombre </th>
                                            <th>Propiedad</th>
                                            <th style="text-align:Right ;">Cantidad</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr *ngFor="let cash of cashTotalIO"
                                            style="text-align: left; overflow: scroll; ">

                                            <td>

                                                {{cash.folioPago}}
                                            </td>

                                            <td>

                                                {{cash.cliente}}
                                            </td>

                                            <td>

                                                {{cash.fkCliente}}
                                            </td>

                                            <td style="text-align:Right ;">

                                                {{cash.cantidadAbono | currency: 'CAD': '$'}}
                                            </td>


                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                            <div *ngIf="flagTransfer == true;">
                                <h3 style="color: black;">
                                    Entradas por <b> Tranferencias </b>
                                </h3>

                                <table class="table table-striped mt-3 animated fadeIn faster">
                                    <thead class="thead-m19">
                                        <tr style="text-align:left; ">
                                            <th> id </th>

                                            <th> Nombre </th>
                                            <th>Propiedad</th>
                                            <th style="text-align:Right ;">Cantidad</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr *ngFor="let transtot of transferTotalIO"
                                            style="text-align: left; overflow: scroll; ">

                                            <td>

                                                {{transtot.folioPago}}
                                            </td>

                                            <td>

                                                {{transtot.cliente}}
                                            </td>

                                            <td>

                                                {{transtot.fkCliente}}
                                            </td>

                                            <td style="text-align:Right ;">

                                                {{transtot.cantidadAbono | currency: 'CAD': '$'}}
                                            </td>


                                        </tr>
                                    </tbody>
                                </table>



                            </div>

                            <div *ngIf="flagDeposit == true;">

                                <h3 style="color: black;">
                                    Entradas por <b> Depositos </b>
                                </h3>

                                <table class="table table-striped mt-3 animated fadeIn faster">
                                    <thead class="thead-m19">
                                        <tr style="text-align:left; ">
                                            <th> id </th>

                                            <th> Nombre </th>
                                            <th>Propiedad</th>
                                            <th style="text-align:Right ;">Cantidad</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr *ngFor="let deptot of depositTotalIO"
                                            style="text-align: left; overflow: scroll; ">

                                            <td>

                                                {{deptot.folioPago}}
                                            </td>

                                            <td>

                                                {{deptot.cliente}}
                                            </td>

                                            <td>

                                                {{deptot.fkCliente}}
                                            </td>

                                            <td style="text-align:Right ;">

                                                {{deptot.cantidadAbono | currency: 'CAD': '$'}}
                                            </td>


                                        </tr>
                                    </tbody>
                                </table>



                            </div>

                            <div *ngIf="flagCreditCard == true;">

                                <h3 style="color: black;">
                                    Entradas por <b> TDC </b>
                                </h3>

                                <table class="table table-striped mt-3 animated fadeIn faster">
                                    <thead class="thead-m19">
                                        <tr style="text-align:left; ">
                                            <th> id </th>

                                            <th> Nombre </th>
                                            <th>Propiedad</th>
                                            <th style="text-align:Right ;">Cantidad</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr *ngFor="let credCard of creditCardTotalIO"
                                            style="text-align: left; overflow: scroll; ">

                                            <td>

                                                {{credCard.folioPago}}
                                            </td>

                                            <td>

                                                {{credCard.cliente}}
                                            </td>

                                            <td>

                                                {{credCard.fkCliente}}
                                            </td>

                                            <td style="text-align:Right ;">

                                                {{credCard.cantidadAbono | currency: 'CAD': '$'}}
                                            </td>


                                        </tr>
                                    </tbody>
                                </table>




                            </div>

                            <div *ngIf="flagDebitCard == true;">
                                <h3 style="color: black;">
                                    Entradas por <b> TDD </b>
                                </h3>

                                <table class="table table-striped mt-3 animated fadeIn faster">
                                    <thead class="thead-m19">
                                        <tr style="text-align:left; ">
                                            <th> id </th>

                                            <th> Nombre </th>
                                            <th>Propiedad</th>
                                            <th style="text-align:Right ;">Cantidad</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr *ngFor="let debCard of debitCardTotalIO"
                                            style="text-align: left; overflow: scroll; ">

                                            <td>

                                                {{debCard.folioPago}}
                                            </td>

                                            <td>

                                                {{debCard.cliente}}
                                            </td>

                                            <td>

                                                {{debCard.fkCliente}}
                                            </td>

                                            <td style="text-align:Right ;">

                                                {{debCard.cantidadAbono | currency: 'CAD': '$'}}
                                            </td>


                                        </tr>
                                    </tbody>
                                </table>



                            </div>

                            <div *ngIf="flagCheck == true;">
                                <h3 style="color: black;">
                                    Entradas por <b> Cheques </b>
                                </h3>

                                <table class="table table-striped mt-3 animated fadeIn faster">
                                    <thead class="thead-m19">
                                        <tr style="text-align:left; ">
                                            <th> id </th>

                                            <th> Nombre </th>
                                            <th>Propiedad</th>
                                            <th style="text-align:Right ;">Cantidad</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr *ngFor="let check of checkTotalIO"
                                            style="text-align: left; overflow: scroll; ">

                                            <td>

                                                {{check.folioPago}}
                                            </td>

                                            <td>

                                                {{check.cliente}}
                                            </td>

                                            <td>

                                                {{check.fkCliente}}
                                            </td>

                                            <td style="text-align:Right ;">

                                                {{check.cantidadAbono | currency: 'CAD': '$'}}
                                            </td>


                                        </tr>
                                    </tbody>
                                </table>



                            </div>

                            <div *ngIf="flagEverything == true;">
                                <h3 style="color: black;">
                                    Entradas por <b> todo tipo </b>
                                </h3>

                                <table class="table table-striped mt-3 animated fadeIn faster">
                                    <thead class="thead-m19">
                                        <tr style="text-align:left; ">
                                            <th> id </th>
                                            <th> metodo </th>
                                            <th> Nombre </th>
                                            <th>Propiedad</th>
                                            <th style="text-align:Right ;">Cantidad</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr *ngFor="let eveTotIO of everythingTotalIO"
                                            style="text-align: left; overflow: scroll; ">


                                            <td>
                                                {{eveTotIO.folioPago}}
                                            </td>

                                            <td>
                                                {{eveTotIO.metodoPago}}
                                            </td>

                                            <td>
                                                {{eveTotIO.cliente}}
                                            </td>

                                            <td>
                                                {{eveTotIO.fkCliente}}
                                            </td>

                                            <td style="text-align:Right ;">
                                                {{eveTotIO.cantidadAbono | currency: 'CAD': '$'}}
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                        </div>

                        <div class="tabla-salidas">

                            <div>
                                <h3 style="color: black;">
                                    Salidas <b> totales </b>
                                </h3>

                                <table class="table table-striped mt-3 animated fadeIn faster">
                                    <thead class="thead-m19">
                                        <tr style="text-align: center; ">
                                            <th> Concepto </th>
                                            <th> Solicitó </th>
                                            <th> Cantidad </th>
                                            <th> Acción </th>
                                        </tr>
                                    </thead>

                                    <tbody style="text-align: left; overflow: scroll;">

                                        <tr *ngFor="let out of salCashIO;" style="text-align: left; overflow: scroll;">
                                            <td> {{out.reason}}</td>
                                            <td> {{out.requestor}}</td>
                                            <td style="text-align:Right ;"> {{(out.quantity | currency: 'CAD': '$')}}
                                            </td>
                                            <td>
                                                <mat-icon class="icon__reprint" data-title="Reimprimir ticket"
                                                    (click)="reimpresionTicket(out.idCashOut, out.cashManager ,out.quantity, out.requestor, out.reason, out.registrationDate)">
                                                    receipt </mat-icon>

                                                <mat-icon class="icon__trash" data-title="Eliminar ticket"
                                                    (click)="eliminarTicket(out.idCashOut)"> delete_forever </mat-icon>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>


                        </div>

                    </div>

                    <div class="contenedor-tablas" [hidden]="range==false">

                        <div class="tabla-entradas">

                            <div *ngIf="flagCashRange == true;">

                                <h3 style="color: black;">
                                    Entradas por <b> Efectivo </b>
                                </h3>

                                <table class="table table-striped mt-3 animated fadeIn faster">
                                    <thead class="thead-m19">
                                        <tr style="text-align:left; ">
                                            <th> id </th>
                                            <th> Nombre </th>
                                            <th>Propiedad</th>
                                            <th style="text-align:Right ;">Cantidad</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr *ngFor="let cashIOran of cashTotalIORange"
                                            style="text-align: left; overflow: scroll; ">

                                            <td>

                                                {{cashIOran.folioPago}}
                                            </td>

                                            <td>

                                                {{cashIOran.cliente}}
                                            </td>

                                            <td>

                                                {{cashIOran.fkCliente}}
                                            </td>

                                            <td style="text-align:Right ;">

                                                {{cashIOran.cantidadAbono | currency: 'CAD': '$'}}
                                            </td>


                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                            <div *ngIf="flagTransferRange == true;">
                                <h3 style="color: black;">
                                    Entradas por <b> Tranferencias </b>
                                </h3>

                                <table class="table table-striped mt-3 animated fadeIn faster">
                                    <thead class="thead-m19">
                                        <tr style="text-align:left; ">
                                            <th> id </th>

                                            <th> Nombre </th>
                                            <th>Propiedad</th>
                                            <th style="text-align:Right ;">Cantidad</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr *ngFor="let transRange of transferTotalIORange"
                                            style="text-align: left; overflow: scroll; ">

                                            <td>

                                                {{transRange.folioPago}}
                                            </td>

                                            <td>

                                                {{transRange.cliente}}
                                            </td>

                                            <td>

                                                {{transRange.fkCliente}}
                                            </td>

                                            <td style="text-align:Right ;">

                                                {{transRange.cantidadAbono | currency: 'CAD': '$'}}
                                            </td>


                                        </tr>
                                    </tbody>
                                </table>



                            </div>

                            <div *ngIf="flagDepositRange == true;">

                                <h3 style="color: black;">
                                    Entradas por <b> Depositos </b>
                                </h3>

                                <table class="table table-striped mt-3 animated fadeIn faster">
                                    <thead class="thead-m19">
                                        <tr style="text-align:left; ">
                                            <th> id </th>

                                            <th> Nombre </th>
                                            <th>Propiedad</th>
                                            <th style="text-align:Right ;">Cantidad</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr *ngFor="let deptotRange of depositTotalIORange"
                                            style="text-align: left; overflow: scroll; ">

                                            <td>

                                                {{deptotRange.folioPago}}
                                            </td>

                                            <td>

                                                {{deptotRange.cliente}}
                                            </td>

                                            <td>

                                                {{deptotRange.fkCliente}}
                                            </td>

                                            <td style="text-align:Right ;">

                                                {{deptotRange.cantidadAbono | currency: 'CAD': '$'}}
                                            </td>


                                        </tr>
                                    </tbody>
                                </table>



                            </div>

                            <div *ngIf="flagCreditCardRange == true;">

                                <h3 style="color: black;">
                                    Entradas por <b> TDC </b>
                                </h3>

                                <table class="table table-striped mt-3 animated fadeIn faster">
                                    <thead class="thead-m19">
                                        <tr style="text-align:left; ">
                                            <th> id </th>

                                            <th> Nombre </th>
                                            <th>Propiedad</th>
                                            <th style="text-align:Right ;">Cantidad</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr *ngFor="let credCardRange of creditCardTotalIORange"
                                            style="text-align: left; overflow: scroll; ">

                                            <td>

                                                {{credCardRange.folioPago}}
                                            </td>

                                            <td>

                                                {{credCardRange.cliente}}
                                            </td>

                                            <td>

                                                {{credCardRange.fkCliente}}
                                            </td>

                                            <td style="text-align:Right ;">

                                                {{credCardRange.cantidadAbono | currency: 'CAD': '$'}}
                                            </td>


                                        </tr>
                                    </tbody>
                                </table>




                            </div>

                            <div *ngIf="flagDebitCardRange == true;">
                                <h3 style="color: black;">
                                    Entradas por <b> TDD </b>
                                </h3>

                                <table class="table table-striped mt-3 animated fadeIn faster">
                                    <thead class="thead-m19">
                                        <tr style="text-align:left; ">
                                            <th> id </th>

                                            <th> Nombre </th>
                                            <th>Propiedad</th>
                                            <th style="text-align:Right ;">Cantidad</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr *ngFor="let debCardRange of debitCardTotalIORange"
                                            style="text-align: left; overflow: scroll; ">

                                            <td>

                                                {{debCardRange.folioPago}}
                                            </td>

                                            <td>

                                                {{debCardRange.cliente}}
                                            </td>

                                            <td>

                                                {{debCardRange.fkCliente}}
                                            </td>

                                            <td style="text-align:Right ;">

                                                {{debCardRange.cantidadAbono | currency: 'CAD': '$'}}
                                            </td>


                                        </tr>
                                    </tbody>
                                </table>



                            </div>

                            <div *ngIf="flagCheckRange == true;">
                                <h3 style="color: black;">
                                    Entradas por <b> Cheques </b>
                                </h3>

                                <table class="table table-striped mt-3 animated fadeIn faster">
                                    <thead class="thead-m19">
                                        <tr style="text-align:left; ">
                                            <th> id </th>

                                            <th> Nombre </th>
                                            <th>Propiedad</th>
                                            <th style="text-align:Right ;">Cantidad</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr *ngFor="let checkRange of checkTotalIORange"
                                            style="text-align: left; overflow: scroll; ">

                                            <td>

                                                {{checkRange.folioPago}}
                                            </td>

                                            <td>

                                                {{checkRange.cliente}}
                                            </td>

                                            <td>

                                                {{checkRange.fkCliente}}
                                            </td>

                                            <td style="text-align:Right ;">

                                                {{checkRange.cantidadAbono | currency: 'CAD': '$'}}
                                            </td>


                                        </tr>
                                    </tbody>
                                </table>



                            </div>

                            <div *ngIf="flagEverythingRange == true;">
                                <h3 style="color: black;">
                                    Entradas por <b> todo tipo </b>
                                </h3>

                                <table class="table table-striped mt-3 animated fadeIn faster">
                                    <thead class="thead-m19">
                                        <tr style="text-align:left; ">
                                            <th> id </th>

                                            <th> Nombre </th>
                                            <th>Propiedad</th>
                                            <th style="text-align:Right ;">Cantidad</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr *ngFor="let eveTotIORange of everythingTotalIORange"
                                            style="text-align: left; overflow: scroll; ">


                                            <td>
                                                {{eveTotIORange.folioPago}}
                                            </td>


                                            <td>
                                                {{eveTotIORange.cliente}}
                                            </td>

                                            <td>
                                                {{eveTotIORange.fkCliente}}
                                            </td>

                                            <td style="text-align:Right ;">
                                                {{eveTotIORange.cantidadAbono | currency: 'CAD': '$'}}
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                        </div>

                        <div class="tabla-salidas">

                            <div>
                                <h3 style="color: black;">
                                    Salidas <b> totales </b>
                                </h3>

                                <table class="table table-striped mt-3 animated fadeIn faster">
                                    <thead class="thead-m19">
                                        <tr style="text-align: center; ">
                                            <th> Concepto </th>
                                            <th> Solicitó </th>
                                            <th> Cantidad </th>
                                        </tr>
                                    </thead>

                                    <tbody style="text-align: left; overflow: scroll;">

                                        <tr *ngFor="let cashORange of salCashIORange;"
                                            style="text-align: left; overflow: scroll;">
                                            <td> {{cashORange.reason}}</td>
                                            <td> {{cashORange.requestor}}</td>
                                            <td style="text-align:Right ;"> {{(cashORange.quantity | currency: 'CAD':
                                                '$')}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>


                        </div>

                    </div>

                </div>


                <div class="contenedor-menu-informacion" [hidden]="simple==false">

                    <div class="opcionesPago">
                        <div class="headerSummary">
                            <h3>
                                Resumen
                            </h3>
                        </div>

                        <h5 style="margin-top: 10px; text-align: center; color: black; ">
                            Efectivo
                        </h5>

                        <div class="info-payment-methods">


                            <div class="row-format">
                                <p> Ingresos:
                                    <b style="color:black"> ${{(totCashIO).toFixed(2)}} </b>
                                </p>
                            </div>

                            <div class="row-format">
                                <p> Salidas:
                                    <b style="color:black"> ${{(totalEgresosIO).toFixed(2)}} </b>
                                </p>
                            </div>

                            <div class="row-format" style="margin-top: -10px;">
                                <p style="color: black;"> Total Efectivo:
                                    <b style="color:black"> ${{(totCashIO - totalEgresosIO).toFixed(2)}} </b>
                                </p>
                            </div>

                        </div>

                        <div class="info-payment-methods">

                            <div class="row-format">
                                <p> TRANFERENCIA:

                                    <b style="color:black"> ${{(totTranserIO).toFixed(2)}} </b>
                                </p>
                            </div>

                            <div class="row-format">
                                <p> DEPOSITO:
                                    <b style="color:black"> ${{(totDepositIO).toFixed(2)}} </b>
                                </p>
                            </div>

                            <div class="row-format">
                                <p> TDC:
                                    <b style="color:black"> ${{(totCreditCardIO).toFixed(2)}} </b>
                                </p>
                            </div>

                            <div class="row-format">
                                <p> TDD:
                                    <b style="color:black"> ${{(totDebitCardIO).toFixed(2)}} </b>
                                </p>
                            </div>

                            <div class="row-format">
                                <p> Cheque:
                                    <b style="color:black"> ${{(totCheckIO).toFixed(2)}} </b>
                                </p>
                            </div>

                        </div>

                        <div class="info-payment-methods">
                            <h5
                                style="border-top: solid 2px black; padding-top: 5px; color: #d33e3e; text-align: center;">
                                Total bruto: <b style="color:black"> ${{ (totEverythingIO).toFixed(2) }}</b>

                                <!--       Total bruto: <b style="color:black"> ${{ totCashIO + totTranserIO  + totDepositIO + totCreditCardIO + totDebitCardIO + totCheckIO}}</b> -->
                            </h5>

                            <h5 style="padding-top: 5px; color: #d33e3e; text-align: center;">
                                <!--   Total neto: <b style="color:black"> ${{ (totCashIO + totTranserIO + totDepositIO + totCreditCardIO + totDebitCardIO + totCheckIO) - totalEgresosIO }}</b> -->

                                Total neto: <b style="color:black"> ${{ (totEverythingIO - totalEgresosIO).toFixed(2)
                                    }}</b>
                            </h5>

                        </div>

                    </div>

                    <div>
                        <button class="boton-impresion" (click)="printOptions();"> Imprimir reporte <mat-icon> print
                            </mat-icon> </button>
                    </div>

                </div>

                <div class="contenedor-menu-informacion" [hidden]="range==false">

                    <div class="opcionesPago">
                        <div class="headerSummary">
                            <h3>
                                Resumen
                            </h3>
                        </div>

                        <h5 style="margin-top: 10px; text-align: center; color: black; ">
                            Efectivo
                        </h5>

                        <div class="info-payment-methods">

                            <div class="row-format">
                                <p> Ingresos:
                                    <b style="color:black"> ${{(totCashIORange).toFixed(2)}} </b>
                                </p>
                            </div>

                            <div class="row-format">
                                <p> Salidas:
                                    <b style="color:black"> ${{(totalEgresosIORange).toFixed(2)}} </b>
                                </p>
                            </div>

                            <div class="row-format" style="margin-top: -10px ;">
                                <p style="color: black;  text-align: center; "> Total Efectivo:
                                    <b style="color:black"> ${{(totCashIORange - totalEgresosIORange).toFixed(2)}} </b>
                                </p>
                            </div>

                        </div>

                        <div class="info-payment-methods">

                            <div class="row-format">
                                <p> TRANFERENCIA:

                                    <b style="color:black"> ${{(totTranserIORange).toFixed(2)}} </b>
                                </p>
                            </div>

                            <div class="row-format">
                                <p> DEPOSITO:
                                    <b style="color:black"> ${{(totDepositIORange).toFixed(2)}} </b>
                                </p>
                            </div>

                            <div class="row-format">
                                <p> TDC:
                                    <b style="color:black"> ${{(totCreditCardIORange).toFixed(2)}} </b>
                                </p>
                            </div>

                            <div class="row-format">
                                <p> TDD:
                                    <b style="color:black"> ${{(totDebitCardIORange).toFixed(2)}} </b>
                                </p>
                            </div>

                            <div class="row-format">
                                <p> Cheque:
                                    <b style="color:black"> ${{(totCheckIORange).toFixed(2)}} </b>
                                </p>
                            </div>

                        </div>

                        <div class="info-payment-methods">
                            <h5
                                style="border-top: solid 2px black; padding-top: 5px; color: #d33e3e; text-align: center;">
                                Total bruto: <b style="color:black"> ${{ (totEverythingIORange ).toFixed(2) }}</b>

                                <!--       Total bruto: <b style="color:black"> ${{ totCashIO + totTranserIO  + totDepositIO + totCreditCardIO + totDebitCardIO + totCheckIO}}</b> -->
                            </h5>

                            <h5 style="padding-top: 5px; color: #d33e3e; text-align: center;">
                                <!--   Total neto: <b style="color:black"> ${{ (totCashIO + totTranserIO + totDepositIO + totCreditCardIO + totDebitCardIO + totCheckIO) - totalEgresosIO }}</b> -->

                                Total neto: <b style="color:black"> ${{ (totEverythingIORange -
                                    totalEgresosIORange).toFixed(2) }}</b>
                            </h5>

                        </div>

                    </div>

                    <div>
                        <button class="boton-impresion" (click)="printOptionsRange();"> Imprimir reporte <mat-icon>
                                print
                            </mat-icon> </button>
                    </div>

                </div>

            </div>

        </div>

        <div class="Contenedor-Impresion" id="htmlSummaryCashIO" [hidden]="true">

            <div class="encabezado">
                <h2> Resumen de ingresos y salidas de efectivo </h2>

                <div class="tiempo-impresion">
                    <b style=" padding-right: 5px;">Fecha Actual: </b>
                    <p style="text-align: right; padding-right: 10px;"> {{pipe.transform(currentDate
                        ,'EEEE')!.toLowerCase()}} {{pipe.transform(currentDate ,'longDate')!.toLowerCase()}} </p>
                </div>

                <div class="margen">
                    <b style=" padding-right: 5px;"> Fecha de Reporte: </b>
                    <p> 
                        {{pipe.transform(fechaSeleccionada,'EEEE')!}} {{pipe.transform(fechaSeleccionada ,'longDate')!.toLowerCase()}}

                    </p>
                </div>

                <div class="margen">
                    <b>Consultó: </b>
                    <p style=" padding-left: 5px;"> {{getUser()}} </p>
                </div>
            </div>

            <h4 *ngIf="cashTotalIO.length > 0"> ENTRADAS </h4>

            <table class="format-Table" *ngIf="cashTotalIO.length > 0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Id</th>

                        <th>Nombre</th>
                        <th>Propiedad </th>
                        <th>Cantidad</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let totCashIO of cashTotalIO;  let i=index;">
                        <td>{{i+1}}</td>
                        <th> {{totCashIO.folioPago }} </th>

                        <td class="td-text-left"> {{totCashIO.cliente}} </td>
                        <th class="tableDataText1"> {{totCashIO.fkCliente}} </th>
                        <td style="text-align: right; padding-right: 20px;"> {{(totCashIO.cantidadAbono |
                            currency:'CAD': '$')}}</td>
                    </tr>

                </tbody>
            </table>

            <div class="totalSeccion">

                <br>
                <h4 *ngIf="salCashIO.length > 0"> SALIDAS DE EFECTIVO </h4>

                <table class="format-Table" *ngIf="salCashIO.length > 0 ">
                    <thead>
                        <tr class="tableTextHeader">

                            <th> # </th>
                            <th> Fecha </th>
                            <th> Concepto </th>
                            <th> Solicitó </th>
                            <th> Cantidad </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let out of salCashIO;">
                            <td> {{out.idCashOut }}</td>
                            <td> {{out.folioPago }} </td>
                            <td> {{out.reason}}</td>
                            <td> {{out.requestor}}</td>
                            <td style="text-align: right; padding-right: 20px;"> {{(out.quantity | currency:
                                'CAD':'$')}}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br>



                <h4> TOTALES </h4>

                <div class="infoColumn">
                    <div class="texto-salida">
                        <p> <b> BRUTO: </b> </p>
                        <p> {{((totCashIO).toFixed(2))}} </p>

                    </div>

                    <div class="texto-salida">
                        <p> <b> SALIDAS: </b> </p>
                        <p> {{(totalEgresosIO).toFixed(2)}} </p>
                    </div>


                    <div class="texto-salida">
                        <p> <b> NETO: </b> </p>
                        <p class="salida-neta"> {{((totCashIO) - totalEgresosIO).toFixed(2)}} </p>
                    </div>


                </div>

                <div class="separator">

                    <div class="screenShared">
                        <h5> {{this.getUser().toUpperCase()}} </h5>
                        <br>
                        <br>
                        <br>
                        <b> ______________________________________ </b>


                    </div>

                    <div class="screenShared">
                        <h5> {{this.assign.toUpperCase()}} </h5>
                        <br>
                        <br>
                        <br>
                        <b> ______________________________________ </b>
                    </div>

                </div>

            </div>

        </div>

        <div class="Contenedor-Impresion" id="htmlSummaryIO" [hidden]="true">

            <div class="encabezado">
                <h2> Resumen general </h2>

                <div class="tiempo-impresion">
                    <b style=" padding-right: 5px;">Fecha: </b>
                    <p style="text-align: right; padding-right: 10px;"> {{pipe.transform(currentDate
                        ,'EEEE')!.toLowerCase()}} {{pipe.transform(currentDate ,'longDate')!.toLowerCase()}} </p>
                </div>

                <div class="margen">
                    <b style=" padding-right: 5px;"> Fecha de Reporte: </b>
                    <p> {{fechaSeleccionada | date: 'dd/MM/yyyy' }} </p>
                </div>

                <div class="margen">
                    <b>Consultó: </b>
                    <p style=" padding-left: 5px;"> {{getUser()}} </p>
                </div>
            </div>

            <h4 *ngIf="everythingTotalIO.length > 0"> ENTRADAS </h4>

            <table class="format-Table" *ngIf="everythingTotalIO.length > 0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th>Método</th>
                        <th>Nombre</th>
                        <th>Propiedad </th>
                        <th>Cantidad</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let evIO of everythingTotalIO;  let i=index;">
                        <td>{{i+1}}</td>
                        <th> {{evIO.folioPago }} </th>
                        <th> {{evIO.metodoPago}} </th>
                        <td class="td-text-left"> {{evIO.cliente}} </td>
                        <th class="tableDataText1"> {{evIO.fkCliente}} </th>
                        <td style="text-align: right; padding-right: 20px;"> {{(evIO.cantidadAbono | currency:'CAD':
                            '$')}}</td>
                    </tr>

                </tbody>
            </table>

            <br>

            <h4 *ngIf="salCashIO.length > 0"> SALIDAS DE EFECTIVO </h4>

            <table class="format-Table" *ngIf="salCashIO.length > 0 ">
                <thead>
                    <tr class="tableTextHeader">

                        <th> # </th>
                        <th> Concepto </th>
                        <th> Solicitó </th>
                        <th> Cantidad </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let out of salCashIO;">
                        <td> {{out.idCashOut }}</td>
                        <th class="td-text-left"> {{out.reason}}</th>
                        <td class="td-text-left"> {{out.requestor}}</td>
                        <td style="text-align: right; padding-right: 20px;"> {{(out.quantity | currency: 'CAD':'$')}}
                        </td>
                    </tr>
                </tbody>
            </table>

            <br>

            <h4> INGRESOS PARCIALES </h4>

            <div class="infoColumn">


                <div class="texto-salida">
                    <p> <b> EFECTIVO: </b> </p>
                    <p> {{(totCashIO).toFixed(2)}} </p>

                </div>

                <div class="texto-salida">
                    <p> <b> DEPOSITOS: </b> </p>
                    <p> {{(totDepositIO).toFixed(2)}} </p>
                </div>


                <div class="texto-salida">
                    <p> <b> TRANSFERENCIAS: </b> </p>
                    <p> {{(totTranserIO).toFixed(2)}} </p>
                </div>



                <div class="texto-salida">
                    <p> <b> TDC: </b> </p>
                    <p> {{(totCreditCardIO).toFixed(2)}} </p>

                </div>

                <div class="texto-salida">
                    <p> <b> TDD: </b> </p>
                    <p> {{(totDebitCardIO).toFixed(2)}} </p>
                </div>


                <div class="texto-salida">
                    <p> <b> CHEQUES: </b> </p>
                    <p> {{(totCheckIO).toFixed(2)}} </p>
                </div>


            </div>

            <br>
            <br>
            <br>

            <h4> TOTALES </h4>

            <div class="infoColumn">
                <div class="texto-salida">
                    <p> <b> BRUTO: </b> </p>
                    <p> {{(totEverythingIO).toFixed(2)}} </p>

                </div>

                <div class="texto-salida">
                    <p> <b> SALIDAS: </b> </p>
                    <p> {{(totalEgresosIO).toFixed(2)}} </p>
                </div>


                <div class="texto-salida">
                    <p> <b> NETO: </b> </p>
                    <p class="salida-neta"> {{((totEverythingIO) - totalEgresosIO).toFixed(2)}} </p>
                </div>

            </div>

        </div>

        <div class="Contenedor-Impresion" id="htmlSummaryCashRangeIO" [hidden]="true">

            <div class="encabezado">
                <h2> Resumen de ingresos y salidas de efectivo </h2>

                <div class="tiempo-impresion">
                    <b style=" padding-right: 5px;">Fecha Actual: </b>
                    <p style="text-align: right; padding-right: 10px;"> {{pipe.transform(currentDate
                        ,'EEEE')!.toLowerCase()}} {{pipe.transform(currentDate ,'longDate')!.toLowerCase()}} </p>
                </div>

                <div class="margen">
                    <b style=" padding-right: 5px;"> Fecha de inicio: </b>
                    <p> {{fecIni | date: 'dd/MM/yyyy' }} </p>
                </div>

                <div class="margen">
                    <b style=" padding-right: 5px;"> Fecha de fin: </b>
                    <p> {{fecFin | date: 'dd/MM/yyyy' }} </p>
                </div>

                <div class="margen">
                    <b>Consultó: </b>
                    <p style=" padding-left: 5px;"> {{getUser()}} </p>
                </div>
            </div>

            <h4 *ngIf="cashTotalIORange.length > 0"> ENTRADAS </h4>

            <table class="format-Table" *ngIf="cashTotalIORange.length > 0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th>Nombre</th>
                        <th>Propiedad </th>
                        <th>Cantidad</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let totCashIO of cashTotalIORange;  let i=index;">
                        <td>{{i+1}}</td>
                        <th> {{totCashIO.folioPago }} </th>

                        <td class="td-text-left"> {{totCashIO.cliente}} </td>
                        <th class="tableDataText1"> {{totCashIO.fkCliente}} </th>
                        <td style="text-align: right; padding-right: 20px;"> {{(totCashIO.cantidadAbono |
                            currency:'CAD': '$')}}</td>
                    </tr>

                </tbody>
            </table>

            <br>
            <h4 *ngIf="salCashIORange.length > 0"> SALIDAS DE EFECTIVO </h4>

            <table class="format-Table" *ngIf="salCashIORange.length > 0 ">
                <thead>
                    <tr class="tableTextHeader">

                        <th> # </th>
                        <th> Concepto </th>
                        <th> Solicitó </th>
                        <th> Cantidad </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let out of salCashIORange;">
                        <td> {{out.idCashOut }}</td>
                        <th class="td-text-left"> {{out.reason}}</th>
                        <td class="td-text-left"> {{out.requestor}}</td>
                        <td style="text-align: right; padding-right: 20px;"> {{(out.quantity | currency: 'CAD':'$')}}
                        </td>
                    </tr>
                </tbody>
            </table>

            <br>

            <h4> TOTALES </h4>

            <div class="infoColumn">
                <div class="texto-salida">
                    <p> <b> BRUTO: </b> </p>
                    <p> {{(totCashIORange).toFixed(2)}} </p>

                </div>

                <div class="texto-salida">
                    <p> <b> SALIDAS: </b> </p>
                    <p> {{(totalEgresosIORange).toFixed(2)}} </p>
                </div>


                <div class="texto-salida">
                    <p> <b> NETO: </b> </p>
                    <p class="salida-neta"> {{((totCashIORange) - totalEgresosIORange).toFixed(2)}} </p>
                </div>


            </div>

        </div>

        <div class="Contenedor-Impresion" id="htmlSummaryRangeIO" [hidden]="true">

            <div class="encabezado">
                <h2> Resumen general </h2>

                <div class="tiempo-impresion">
                    <b style=" padding-right: 5px;">Fecha Actual: </b>
                    <p style="text-align: right; padding-right: 10px;"> {{pipe.transform(currentDate
                        ,'EEEE')!.toLowerCase()}} {{pipe.transform(currentDate ,'longDate')!.toLowerCase()}} </p>
                </div>

                <div class="margen">
                    <b style=" padding-right: 5px;"> Fecha de inicio: </b>
                    <p> {{fecIni | date: 'dd/MM/yyyy' }} </p>
                </div>

                <div class="margen">
                    <b style=" padding-right: 5px;"> Fecha de fin: </b>
                    <p> {{fecFin | date: 'dd/MM/yyyy' }} </p>
                </div>

                <div class="margen">
                    <b>Consultó: </b>
                    <p style=" padding-left: 5px;"> {{getUser()}} </p>
                </div>
            </div>

            <h4 *ngIf="everythingTotalIORange.length > 0"> ENTRADAS </h4>

            <table class="format-Table" *ngIf="everythingTotalIORange.length > 0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th> Fecha </th>
                        <th>Metodo</th>

                        <th>Nombre</th>
                        <th>Propiedad </th>
                        <th>Cantidad</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let totCashIO of everythingTotalIORange;  let i=index;">
                        <td>{{i+1}}</td>
                        <th> {{totCashIO.folioPago }} </th>
                        <td> {{totCashIO.fechaPago | date: 'dd/MM/yyyy'}}</td>
                        <th> {{totCashIO.metodoPago }} </th>
                        <td class="td-text-left"> {{totCashIO.cliente}} </td>
                        <th class="tableDataText1"> {{totCashIO.fkCliente}} </th>
                        <td style="text-align: right; padding-right: 20px;"> {{(totCashIO.cantidadAbono |
                            currency:'CAD': '$')}}</td>
                    </tr>

                </tbody>
            </table>

            <br>
            <h4 *ngIf="salCashIORange.length > 0"> SALIDAS DE EFECTIVO </h4>

            <table class="format-Table" *ngIf="salCashIORange.length > 0 ">
                <thead>
                    <tr class="tableTextHeader">

                        <th> # </th>
                        <th> Fecha </th>
                        <th> Concepto </th>
                        <th> Solicitó </th>
                        <th> Cantidad </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let out of salCashIORange;">
                        <td> {{out.idCashOut }}</td>
                        <td> {{out.registrationDate | date: 'dd/MM/yyyy' }}</td>
                        <td> {{out.reason}}</td>
                        <td class="td-text-left"> {{out.requestor}}</td>
                        <td style="text-align: right; padding-right: 20px;"> {{(out.quantity | currency: 'CAD':'$')}}
                        </td>
                    </tr>
                </tbody>
            </table>

            <br>
            <br>

            <h4> INGRESOS PARCIALES </h4>

            <div class="infoColumn">


                <div class="texto-salida">
                    <p> <b> EFECTIVO: </b> </p>
                    <p> {{(totCashIORange).toFixed(2)}} </p>

                </div>

                <div class="texto-salida">
                    <p> <b> DEPOSITOS: </b> </p>
                    <p> {{(totDepositIORange).toFixed(2)}} </p>
                </div>


                <div class="texto-salida">
                    <p> <b> TRANSFERENCIAS: </b> </p>
                    <p> {{(totTranserIORange).toFixed(2)}} </p>
                </div>



                <div class="texto-salida">
                    <p> <b> TDC: </b> </p>
                    <p> {{(totCreditCardIORange).toFixed(2)}} </p>

                </div>

                <div class="texto-salida">
                    <p> <b> TDD: </b> </p>
                    <p> {{(totDebitCardIORange).toFixed(2)}} </p>
                </div>


                <div class="texto-salida">
                    <p> <b> CHEQUES: </b> </p>
                    <p> {{(totCheckIORange).toFixed(2)}} </p>
                </div>


            </div>

            <br>
            <br>


            <h4> TOTALES </h4>

            <div class="infoColumn">
                <div class="texto-salida">
                    <p> <b> BRUTO: </b> </p>
                    <p> {{(totEverythingIORange).toFixed(2)}} </p>

                </div>

                <div class="texto-salida">
                    <p> <b> SALIDAS: </b> </p>
                    <p> {{(totalEgresosIORange).toFixed(2)}} </p>
                </div>


                <div class="texto-salida">
                    <p> <b> NETO: </b> </p>
                    <p class="salida-neta"> {{((totEverythingIORange) - totalEgresosIORange).toFixed(2)}} </p>
                </div>


            </div>

        </div>

    </mat-tab>

</mat-tab-group>