import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {

  constructor(private http: HttpClient) { }

  getAllLeads(): Observable<any> {
    return this.http.get(`${environment.URLServer}/leads/getAllLeads`);
  }

  getLastLeads(): Observable<any> {
    return this.http.get(`${environment.URLServer}/leads/getLastLeads`);
  }

  getLeadInformation(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/leads/getLeadInformation/${id}`);
  }

  getCoordinators(): Observable<any> {
    return this.http.get(`${environment.URLServer}/leads/getCoordinators`);
  }

  leadVerification(email: string, number: string, interest: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/leads/leadVerification/${email}/${number}/${interest}`,);
  }

  leadRegister(lead: any): Observable<any> {
    return this.http.post(`${environment.URLServer}/leads/leadRegister`, lead);
  }

  leadsLogWrite(leadLog: any): Observable<any> {
    return this.http.post(`${environment.URLServer}/leads/leadsLogWrite`, leadLog);
  }

  leadCancelation(idlead: number): Observable<any> {
    return this.http.put(`${environment.URLServer}/leads/leadCancelation/${idlead}`, []);
  }

  leadAssigned(idcoord: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/leads/leadAssigned/${idcoord}`);
  }

  giveMoreTime(args: any): Observable<any> {
    return this.http.put(`${environment.URLServer}/leads/giveMoreTime`, args);
  }

  leadUnassign(args: any): Observable<any> {
    return this.http.put(`${environment.URLServer}/leads/leadUnassign`, args);
  }

  leadTimeline(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/leads/leadTimeline/${id}`);
  }

  setLeadCall(id: number): Observable<any> {
    return this.http.put(`${environment.URLServer}/leads/setLeadCall/${id}`, []);
  }
}