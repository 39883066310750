<mat-accordion>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header (click)="this.flujos(); this.flujosTotal();">
            <mat-panel-title>
                <span>Gráfico de flujo</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <canvas *ngFor="let fluTotal of flujoTotal" baseChart height="120"
            [datasets]="[{ data: [fluTotal.mensuEspe], label: 'Monto esperado' }, { data: [fluTotal.sumPago], label: 'Monto recaudado'}, { data: [fluTotal.mensuEspe-fluTotal.sumPago], label: 'Monto restante'}, { data: [0], label: '' ,backgroundColor:'rgb(255, 255, 255 )'}]"
            [labels]="barChartLabels" 
            [options]="barChartOptions" 
            [legend]="barChartLegend" 
            [chartType]="barChartType">
        </canvas>

        <mat-form-field appearance="outline" class="form-inline col-md-3">
            <mat-label>Mes a consultar</mat-label>
            <mat-select [(ngModel)]="selecMes" name="mesesF" onchange="onChange()">
                <mat-option *ngFor="let centro of mesesFlujo" value="{{centro}}">
                    {{centro}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button class="btn btn-m19" (click)="seleccionMes();tituloMes()">
            <mat-icon>search</mat-icon>
        </button>
        <br>
        <table class="table table-striped animated fadeIn faster _reports">
            <thead>
                <tr>
                    <th colspan="8">
                        <h5 class="thead">Mes consultado: {{tMes || selecMes}}</h5>
                    </th>
                </tr>
            </thead>
            <thead class="thead-m19">
                <tr align="center">
                    <th scope="col">Desarrollo</th>
                    <th scope="col">Contratos activos</th>
                    <th scope="col" width="12%">Mensualidades esperadas</th>
                    <th scope="col">Contratos con pago</th>
                    <th scope="col" width="12%">Ingreso flujo</th>
                    <th scope="col">Porcentaje</th>
                    <th scope="col">Faltante mensual</th>
                    <th scope="col">Recuperación cobranza</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let flujos of flujo" align="center">
                    <th scope="row" style="color: brown;">{{flujos.name}}</th>
                    <td>{{flujos.cActivos}}</td>
                    <td>{{flujos.mEsp | currency:'CAD' : '$ '}}</td>
                    <td>{{flujos.conConPago}}</td>
                    <td>{{flujos.sPesos | currency:'CAD' : '$ '}}</td>
                    <td>{{flujos.porcentaje.toFixed(2)+' %'}}</td>
                    <td>{{(100-flujos.porcentaje).toFixed(2)+' %'}}</td>
                    <td>{{flujos.porcentajeRec.toFixed(2)+' %'}}</td>
                </tr>
            </tbody>
        </table>
        <br>
        <table class="table table-striped animated fadeIn faster _reports">
            <thead>
                <tr>
                    <th colspan="8">
                        <h5 class="thead">Total</h5>
                    </th>
                </tr>
            </thead>
            <thead class="thead-m19">
                <tr align="center">
                    <th scope="col"></th>
                    <th scope="col">Contratos activos</th>
                    <th scope="col" width="12%">Mensualidades esperadas</th>
                    <th scope="col">Contratos con pago</th>
                    <th scope="col" width="12%">Ingreso flujo</th>
                    <th scope="col">Porcentaje</th>
                    <th scope="col">Faltante mensual</th>
                    <th scope="col">Recuperación cobranza</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let fluTo of flujoTotal" align="center">
                    <th scope="row" style="color: brown;">M19 Inmobiliaria</th>
                    <td>{{fluTo.contActivos}}</td>
                    <td>{{fluTo.mensuEspe | currency:'CAD' : '$ '}}</td>
                    <td>{{fluTo.contConPago}}</td>
                    <td>{{fluTo.sumPago | currency:'CAD' : '$ '}}</td>
                    <td>{{fluTo.sumPorcentaje.toFixed(2)+' %'}}</td>
                    <td>{{(100-fluTo.sumPorcentaje).toFixed(2)+' %'}}</td>
                    <td>{{fluTo.sumPorcentajeRec+' %'}}</td>
                </tr>
            </tbody>
        </table>

    </mat-expansion-panel>

    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header (click)="flujosDiarios()">
            <mat-panel-title>
                <span>Gráfico de flujo diario</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- <div
            style="display:flex; width: 100%; flex-direction:row; align-items: center; justify-content: space-evenly; padding-top: 20px;">


            <mat-form-field appearance="outline">
                <mat-label> Seleccione un mes</mat-label>
                <mat-select (selectionChange)="asignacionMes($event)">
                    <mat-option [value]="months.valor" *ngFor=" let months of mesesFlujo2">
                        {{months.mes}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Seleccione un año </mat-label>
                <mat-select (selectionChange)="asignacionAnio($event)">
                    <mat-option [value]="year" *ngFor="let year of aniosOperacion">
                        {{year}}
                    </mat-option>

                </mat-select>
            </mat-form-field>

            <button class="m19Btn" (click)="iniciaBusqueda()">
                <mat-icon> search </mat-icon>
            </button>

        </div>

        <div   *ngIf="barChartData.length != 0" >
            <canvas baseChart *ngFor="let flujorepdiario of flujoDiario;"
            
                [datasets]="barChartData"
                [labels]="barChartLabels2"
                [options]="barChartOptions2" 
                [plugins]="barChartPlugins2" 
                [legend]="barChartLegend2"
                [chartType]="barChartType2">

            </canvas>
        </div>

        <div   *ngIf="barChartData.length == 0" style="display:flex; flex-direction: column; text-align: center; color: rgb(160, 158, 155); justify-content: center; align-items: center;" >
                <h1> Seleccione un mes y año para ver el flujo mensual </h1>

                <mat-icon> mood </mat-icon>
        </div> -->
 

        <canvas *ngFor="let flujorepdiario of flujoDiario;" baseChart [datasets]="[
                    
                                { data: 
                                 [flujorepdiario.cActivos,flujorepdiario.cActivos,
                                  flujorepdiario.cActivos,flujorepdiario.cActivos,
                                  flujorepdiario.cActivos,flujorepdiario.cActivos,
                                  flujorepdiario.cActivos,flujorepdiario.cActivos,
                                  flujorepdiario.cActivos,flujorepdiario.cActivos,
                                  flujorepdiario.cActivos,flujorepdiario.cActivos,
                                  flujorepdiario.cActivos,flujorepdiario.cActivos,
                                  flujorepdiario.cActivos,flujorepdiario.cActivos,
                                  flujorepdiario.cActivos,flujorepdiario.cActivos,
                                  flujorepdiario.cActivos,flujorepdiario.cActivos,
                                  flujorepdiario.cActivos,flujorepdiario.cActivos,
                                  flujorepdiario.cActivos,flujorepdiario.cActivos,
                                  flujorepdiario.cActivos,flujorepdiario.cActivos,
                                  flujorepdiario.cActivos,flujorepdiario.cActivos,
                                  flujorepdiario.cActivos,flujorepdiario.cActivos,
                                  flujorepdiario.cActivos], label: 'Promedio diario'},

                                { data: [flujorepdiario.d1,flujorepdiario.d2,
                                         flujorepdiario.d3,flujorepdiario.d4,
                                         flujorepdiario.d5,flujorepdiario.d6,
                                         flujorepdiario.d7,flujorepdiario.d8,
                                         flujorepdiario.d9,flujorepdiario.d10,
                                         flujorepdiario.d11,flujorepdiario.d12,
                                         flujorepdiario.d13,flujorepdiario.d14,
                                         flujorepdiario.d15,flujorepdiario.d16,
                                         flujorepdiario.d17,flujorepdiario.d18,
                                         flujorepdiario.d19,flujorepdiario.d20,
                                         flujorepdiario.d21,flujorepdiario.d22,
                                         flujorepdiario.d23,flujorepdiario.d24,
                                         flujorepdiario.d25,flujorepdiario.d26,
                                         flujorepdiario.d27,flujorepdiario.d28,
                                         flujorepdiario.d29,flujorepdiario.d30,
                                         flujorepdiario.d31], label: 'Flujo diario'},
                                         ]" [labels]="barchartLabelDiario"
            [options]="barChartOptions" [legend]="barChartLegend" [chartType]="barChartType">
        </canvas>

    </mat-expansion-panel>

    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header (click)="flujosTotalAnual()">
            <mat-panel-title>
                <span>Gráfico de flujo anual</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <canvas *ngFor="let flujoanual of flujoTotalAnual" baseChart
            [datasets]="[{ data: [flujoanual.enesum,flujoanual.febsum,flujoanual.marsum,flujoanual.abrsum,flujoanual.maysum,flujoanual.junsum,flujoanual.julsum,flujoanual.agosum,flujoanual.sepsum,flujoanual.octsum,flujoanual.novsum,flujoanual.dicsum], label: 'Monto esperado' },
                                { data: [flujoanual.enero,flujoanual.febrero,flujoanual.marzo,flujoanual.abril,flujoanual.mayo,flujoanual.junio,flujoanual.julio,flujoanual.agosto,flujoanual.septiembre,flujoanual.octubre,flujoanual.noviembre,flujoanual.diciembre], label: 'Monto recaudado'},
                                { data: [flujoanual.enesum-flujoanual.enero,flujoanual.febsum-flujoanual.febrero,flujoanual.marsum-flujoanual.marzo,flujoanual.abrsum-flujoanual.abril,flujoanual.maysum-flujoanual.mayo,flujoanual.junsum-flujoanual.junio,flujoanual.julsum-flujoanual.julio,flujoanual.agosum-flujoanual.agosto,flujoanual.sepsum-flujoanual.septiembre,flujoanual.octsum-flujoanual.octubre,flujoanual.novsum-flujoanual.noviembre,flujoanual.dicsum-flujoanual.diciembre], label: 'Monto restante' }]"
            [labels]="barchartLAbelAnual" [options]="barChartOptions" [legend]="barChartLegend"
            [chartType]="barChartType">
        </canvas>

    </mat-expansion-panel>

</mat-accordion>