import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogInvoiceComponent } from '../../dialog-invoice/dialog-invoice.component';

@Component({
  selector: 'app-report-tab',
  templateUrl: './report-tab.component.html',
  styleUrls: ['./report-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ReportTabComponent {

  @Input() grafic: any;

  constructor(public dialog: MatDialog,
  ) {
  }



  selectInvoice(folInvoice: string, fkProperty: string) {
    const dialogo1 = this.dialog.open(DialogInvoiceComponent
      , {
        width: '600px',
        data: {folInvoice,fkProperty}
      }
      );
  }

}
