import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { noteReport } from 'src/app/models/Note';

import { ReportService } from 'src/app/services/report.service';

import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import {registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dialog-wallet',
  templateUrl: './dialog-wallet.component.html',
  styleUrls: ['./dialog-wallet.component.scss']
})
export class DialogWalletComponent implements OnInit {
  notes: any = [];
  agreement: any = [];
  codeProperty = localStorage.getItem('property') || '';

  pipe = new DatePipe('es-MX');

  constructor(
    private reportService: ReportService,
    private dialogRef: MatDialogRef<DialogWalletComponent>,
    @Inject(MAT_DIALOG_DATA) public data: noteReport
  ) { }

  ngOnInit(): void {
    this.notes = this.data;
    this.reportService.getAgreement(this.codeProperty).subscribe(
      res => {
        this.agreement = res;
      }
    );
    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
  }
}
