import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConsumerTokenService } from 'src/app/services/consumer-token.service';
import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-consumer-token-modify-info-dialog',
  templateUrl: './consumer-token-modify-info-dialog.component.html',
  styleUrls: ['./consumer-token-modify-info-dialog.component.scss']
})
export class ConsumerTokenModifyInfoDialogComponent implements OnInit {
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public productsOfConsumerToken: any,
    private consumerTokenService: ConsumerTokenService,
  ) { }

  ngOnInit(): void {
    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
    this.getConsumerToken(this.productsOfConsumerToken[0].fk_idConsumerToken);
    this.auditEditionConsumerToken(this.productsOfConsumerToken[0].fk_idConsumerToken);
  }

  //variables 
  ModifyTdFlag = false;
  pipe = new DatePipe('es-MX');
  consumerToken: any = [{
    creationDate: "2000-01-01T00:00:00.000Z",
    empleado: "Cargando...",
    fk_idprojectDet: 1,
    folio: "000",
    idConsumerToken: 8,
    lastModification: "2000-01-01T00:00:00.000Z",
    name: null,
    noteMod: null,
    projectname: "(Si ve este mensaje por mas de 1 minuto, recargue la página o contacte al desarrollador)...",
    requiredArea: "Cargando...",
    rmaster: null,
    tokenDate: "2000-01-01T00:00:00.000Z",
    towernumber: 4,
    userEmailCre: "Cargando...",
    userEmailMod: "Cargando...",
    warehouseman: null,
    worker: null
  }];

  consumerTokenAudit: any = [{
    empleadoMod: 'Sin modificar',
    lastModification: 'Sin modificar',
    idConsumerToken: 0
  }];

  async getConsumerToken(id: number) {
    this.consumerTokenService.getConsumerToken(id).subscribe(
      res => {
        this.consumerToken = res;
        let fecTemp = this.consumerToken[0].creationDate.split('T');
        this.consumerToken[0].empleado = 'Ingresado a sistema por: ' + res[0].empleado + 'el día ' + new DatePipe('es-MX').transform(fecTemp[0], 'longDate') + ' a las ' + fecTemp[1].substring(0, 5);
      }
    )
  }
  async auditEditionConsumerToken(id: number) {
    this.consumerTokenService.auditEditionConsumerToken(id).subscribe(
      res => {
        try {
          if (res[0].idConsumerToken + 0 == id) {
            this.consumerTokenAudit = res;
            let fecTemp = this.consumerTokenAudit[0].lastModification.split('T');
            this.consumerTokenAudit[0].empleadoMod = 'Modificado por: ' + res[0].empleadoMod + 'el día ' + new DatePipe('es-MX').transform(fecTemp[0], 'longDate') + ' a las ' + fecTemp[1].substring(0, 5);
            this.ModifyTdFlag = true;
          }
          else {
            this.ModifyTdFlag = false
          }
        } catch (e) {
          console.log('Error validado esperado "TypeError", si el error que se muestra después de ":" no es ese, por favor verifique el código fuente: ' + e)
        }
      }
    )
  }
}