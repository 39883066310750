import { Component, OnInit } from '@angular/core';
import { Report } from 'src/app/models/Report';
import { Payment } from '../../../models/Payment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from 'src/app/services/payment.service';
import { ReportService } from 'src/app/services/report.service';
import { Router } from '@angular/router';
import { SalesService } from 'src/app/services/sales.service';
import { invoiceReportService } from 'src/app/services/invoice.service';
import { invoiceReport } from 'src/app/models/invoiceReport';

@Component({
  selector: 'app-consulta-factura',
  templateUrl: './consulta-factura.component.html',
  styleUrls: ['./consulta-factura.component.scss']
})
export class ConsultaFacturaComponent implements OnInit {

  //Variable de obtención de inmueble
  codigoInm = ""
    
  //Nomenclatura inmueble
  M = "Manzana No."
  L = "Lote No."
  T = "Torre No."
  D = "Departamento No."

  //Grafico y control de pagos
  limite = 4
  tolerancia = 1
  tolerancia2 = 2
  tolerancia3 = 3

  //Manejo de información res API, arreglos con carga de datos de las consultas a los servicios que conectan con la API
  carteraInmu: any = []
  numeracion: any = []
  reportes: any = []
  cuentas: any = [];
  complemento:any=[];
  global:any=[];
  invoice:any=[];
  listUsers:any=[]
  sales: any = []
  salesCoo: any = []

  //Manejo de contadores
  totales: any = [];
  total: Payment = {
    Abonado: 0
  };
  ////Objeto de servicio pagos
  estatus:any=["Parcial","Global"];
  invReport: invoiceReport = {
    id: 0,
    RFC: '',
    UserName: '',
    invoiceType: '',
    registrationDate: null,
    modificationDate: null,
    paymentType: 'Efectivo',
    invoiceStatus: '',
    folInvoice: null,
    amountInvoice: null,
    fkProperty: '',
    accountingFolio:null
  };

  constructor(private invoiceService: invoiceReportService,private reportServices: ReportService,public modal:NgbModal,private reportService: PaymentService, private paymentService: PaymentService, private reportServ: ReportService, private router: Router, private salesServices: SalesService) { }

  //Metodo de carga de metodos al momento de la ejecución
  ngOnInit(): void {    
    this.saleCode();    
    this.slectReporteFactura();
    this.selectInvoiceUsers();
  }

  //Consulta de datos desde el servicio que consulta a la API
  selectSales(codInmueble: any) {
    this.salesServices.selectSales(codInmueble).subscribe(
      res => {      
        this.sales = res;
      },
      err => console.log(err)
    );
  }
  selectSalesCoo(codInmuebleCoo: any) {
    this.salesServices.selectSalesCoo(codInmuebleCoo).subscribe(
      res => {      
        this.salesCoo = res;
      },
      err => console.log(err)
    );
  }
  sumTotales(code: string) {
    this.reportService.sumTotal(code).subscribe(
      data => {
        this.totales = data;
      },
      err => console.log(err)
    );

  }

  slectReporteFactura() {
    this.invoiceService.invoiceReport().subscribe(
      res => {

        this.invoice = res;
      },
      err => console.log(err)
    );
  }
  selectCom(code: any) {
    this.invoiceService.selectComple(code).subscribe(
      data => {
        this.complemento = data;
      },
      err => console.log(err)
    );
  }
  reporteInmuCartera(carInmu:string) {
    this.reportServices.reporteCarteraInmueble(carInmu).subscribe(
      res => {
        
        console.log(res)
        this.carteraInmu = res;
      },
      err => console.log(err)
    );
  }
  selectGlo(code: any) {
    this.invoiceService.selectGlo(code).subscribe(
      data => {
        this.global = data;
      },
      err => console.log(err)
    );
  }
  selectInvoiceUsers() {    
    this.invoiceService.invoiceReportList()
      .subscribe(
        res => {                    
          this.listUsers = res;
        },
        err => console.error(err)
      );
  }

    //Configuraciones de autocompletado libreria
    keyword = 'name';

    sal: any = [];
    folioApr: any = [];
    folioCa: any = [];
  
    cadenaInmueble: string = "";
    saleCode() {
      this.reportServ.saleCode().subscribe(
        res => {
  
          this.sal = res;
        },
        err => console.log(err)
      );
    }

  onKeypressEvent(event: any) {
    if(event.target.value==' '||event.target.value==null||event.target.value==""){
      console.log("Se requiere consultar cliente")
    }else{
      this.codigoInm = event.target.value   
      this.selectCom(this.codigoInm.substr(0,8));
      this.selectGlo(this.codigoInm.substr(0,8));
      this.selectSales(this.codigoInm.substr(0,8));
      this.sumTotales(this.codigoInm.substr(0,8));
      this.selectSalesCoo(this.codigoInm.substr(0,8));
      this.reporteInmuCartera(this.codigoInm.substr(0,8));
    }    
  }
  
}