import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductcatalogService } from 'src/app/services/productcatalog.service';

//=========================================================================================================
//Importación de librerías para componentes visuales
//=========================================================================================================
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';

@Component({
  selector: 'app-material-update',
  templateUrl: './material-update.component.html',
  styleUrls: ['./material-update.component.scss']
})
export class MaterialUpdateComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<MaterialUpdateComponent>,
    private productService: ProductcatalogService,

    //===================================================
    //Declaración de complementos visuales
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  //==========================================================================================================
  //Objeto constructor del formulario utilizado en la pantalla de requisición, se agrega el código 
  //", Validators.required" para señalar que es un valor que no debe estar vacío
  //==========================================================================================================
  materialFormGroup: FormGroup = this._formBuilder.group({
    idmaterial: [],
    productname: [, Validators.required],
    unit: [, Validators.required],
    useremail: []
  });

  ngOnInit(): void {
    this.productService.selectProduct(this.data).subscribe(
      res => {
        this.materialFormGroup.controls['productname'].setValue(res.productname);
        this.materialFormGroup.controls['unit'].setValue(res.unit);
      }
    );
  }

  async updateMaterial(){
    Swal.fire({
      title: '¿Quieres guardar tus cambios ahora?',
      text: "¡Este material se guardara como está actualmente!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, espera',
      confirmButtonText: 'Sí, guárdalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.updateProduct(this.data, this.materialFormGroup.value).subscribe(
          res => {
            this.sweet.AlertTime("center", "success", "Material actualizado con éxito.", false, 2000);
            this.dialogRef.close();
          }
        );
      }
    })
  }

}
