//=========================================================================================================
//Importación de componentes necesarios para esta pantalla
//=========================================================================================================
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { RequisitionService } from 'src/app/services/requisition.service';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';
import { DepaproService } from 'src/app/services/depapro.service';
import { ProductcatalogService } from 'src/app/services/productcatalog.service';
import { DepartureService } from 'src/app/services/departure.service';
import { ReasonrequisitionService } from 'src/app/services/reasonrequisition.service';
import { ConceptsService } from 'src/app/services/concepts.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-update-requisition',
  templateUrl: './dialog-update-requisition.component.html',
  styleUrls: ['./dialog-update-requisition.component.scss']
})
export class DialogUpdateRequisitionComponent implements OnInit {

  towers: any;
  requisition: any = [];
  requisitionID: any;
  concepts: any = [];
  conceptName: any = [];

  idprojectdet: any;
  requisitionnumber: any;
  requisitiondate: any;
  requisitionauth: any;

  modification: any = false;

  materials: any = [];
  product: any = [];
  editing: any = "";

  //Objeto para almacenar los materiales registrados en la BD
  products: any = [];

  //Variable para almacenar las partidas de la BD
  departures: any = [];

  pipe = new DatePipe('en-US');
  todayWithPipe: any;

  keywordP = 'departurename';
  keywordM = 'productname';
  keywordC = 'descriptions';

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  constructor(
    private dialogRef: MatDialogRef<DialogUpdateRequisitionComponent>,
    private requisitionService: RequisitionService,
    private projectDetailService: ProjectdetailsService,
    private departureProdService: DepaproService,
    private productCatalogService: ProductcatalogService,
    private conceptsService: ConceptsService,
    private departureService: DepartureService,
    private reasonRequisitionService: ReasonrequisitionService,
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getRequisitionInfo();
    this.getModules();
    this.getDepartureProducts();
    this.selectProducts();
    this.getUser();
    this.todayWithPipe = this.pipe.transform(this.requisition.requisitiondate, 'dd-MM-yyyy');
  }

  requisitionFormGroup: FormGroup = this._formBuilder.group({
    idrequisition: [],
    idprojectdet: [, Validators.required],
    requisitionnumber: [, Validators.pattern(/^((HER)?[0-9]+)$/)],
    requisitiondate: [, Validators.required],
    reason: [],
    amount: [],
    auth: [],
    unit: [],
    notes: [],
    idproduct: [],
    productname: [],
    product: [],
    iddeparture: [],
    idconcept: [],
    concept: [],
    conceptname: [],
    departurename: [],
    departurenumber: [],
    departure: [],
    useremail: [this.getUser()]
  });

  //==========================================================================================================
  //Función para buscar las partidas registradas en la base de datos
  //==========================================================================================================
  async selectDepartures(id: number) {
    this.departureService.getNamesOfDepartures(id).subscribe(
      res => {
        //Se recuperan todas las partidas en la variable departures
        this.departures = res;
      },
      err => console.log(err)
    );
  }

  //==========================================================================================================
  //Función para buscar los materiales registrados en el catalogo de la BD
  //==========================================================================================================
  async selectProducts() {
    this.productCatalogService.getNamesOfProducts()
      .subscribe(
        res => {
          //Se recuperan los productos en la variable products
          this.products = res;
        },
        err => console.log(err)
      );
  }

  async selectConcepts(id: number) {
    this.conceptsService.selectConcepts(id).subscribe(
      res => {
        this.concepts = res;
      }
    );
  }

  async getRequisitionInfo() {
    this.requisitionService.selectRequisitionInfo(this.data.idrequisition).subscribe(
      res => {
        this.requisition = res;
        this.todayWithPipe = this.pipe.transform(this.requisition.requisitiondate, 'yyyy-MM-dd');
        this.requisitionFormGroup.controls['idprojectdet'].setValue(this.requisition.idprojectdet);
        this.selectDepartures(this.requisition.idprojectdet);
        this.requisitionFormGroup.controls['idrequisition'].setValue(this.requisition.idrequisition);
        this.requisitionFormGroup.controls['requisitionnumber'].setValue(this.requisition.requisitionnumber);
        this.requisitionFormGroup.controls['auth'].setValue(this.requisition.auth);
        this.requisitionFormGroup.controls['requisitiondate'].setValue(this.todayWithPipe);

        this.requisitionID = this.requisition.idrequisition;
        this.idprojectdet = this.requisition.idprojectdet;
        this.requisitionnumber = this.requisition.requisitionnumber;
        this.requisitionauth = this.requisition.auth;
        this.requisitiondate = this.todayWithPipe;
      }
    );
  }

  async getDepartureProducts() {
    this.departureProdService.selectProductsByReq(this.data.idrequisition).subscribe(
      res => {
        this.materials = res;
      }
    );
  }

  async getModules() {
    this.projectDetailService.selectDetailsById(this.data.idproject).subscribe(
      res => {
        this.towers = res;
      }
    );
  }

  async updateRequisition() {
    if (this.modification == true && (this.requisitionFormGroup.controls['reason'].value == null || this.requisitionFormGroup.controls['reason'].value == '')) {
      this.sweet.AlertTime("center", "error", "Por favor capture una razón de modificación.", false, 2000);
    } else if (this.modification == false) {
      this.sweet.AlertTime("center", "warning", "No se detectó ninguna modificación", false, 2000);
      this.dialogRef.close();
    } else {
      Swal.fire({
        title: '¿Quieres guardar tus cambios ahora?',
        text: "¡Esta requisición se guardara como está actualmente!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No, espera',
        confirmButtonText: 'Sí, guárdala!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.requisitionService.updateRequisition(this.requisitionFormGroup.value).subscribe(
            res => {
              if (this.modification == true) {
                this.insertReason();
              }
              this.updateDepartureProduct();
            }
          );
        }
      })
    }
  }

  async updateDepartureProduct() {
    this.departureProdService.deleteAllByReq(this.requisitionFormGroup.controls['idrequisition'].value).subscribe(
      res => {
        for (let p of this.materials) {
          this.departureProdService.insertProducts(this.requisitionID, p.iddeparture, p.idconcept, p.idproduct, p.amount, p.notes, this.decode.useremail).subscribe(
            res => {
              this.sweet.AlertTime("center", "success", "Requisición actualizada con éxito", false, 2000);
              this.dialogRef.close();
            }
          );
        }
        if (this.materials.length == 0) {
          this.sweet.AlertTime("center", "success", "Requisición actualizada con éxito", false, 2000);
          this.dialogRef.close();
        }
      }
    );
  }

  async insertReason() {
    this.reasonRequisitionService.insertReason(this.requisitionID, this.requisitionFormGroup.controls['reason'].value, this.decode.email).subscribe(
      res => {

      }
    );
  }

  async compareValues() {
    if (this.requisitionFormGroup.controls['idprojectdet'].value != this.idprojectdet
      || this.requisitionFormGroup.controls['requisitionnumber'].value != this.requisitionnumber
      || this.requisitionFormGroup.controls['requisitiondate'].value != this.requisitiondate
      || this.requisitionFormGroup.controls['auth'].value != this.requisitionauth) {
      this.modification = true;
    } else {
      this.modification = false;
    }
  }

  //==========================================================================================================
  //Función para agregar materiales de requisición a una lista temporal
  //==========================================================================================================
  async addMaterial() {
    if (this.requisitionFormGroup.controls['iddeparture'].value == null || this.requisitionFormGroup.controls['iddeparture'].value == ''
      || this.requisitionFormGroup.controls['productname'].value == null || this.requisitionFormGroup.controls['productname'].value == ''
      || this.requisitionFormGroup.controls['amount'].value == null || this.requisitionFormGroup.controls['amount'].value == ''
      || this.requisitionFormGroup.controls['unit'].value == null || this.requisitionFormGroup.controls['unit'].value == ''
      || this.requisitionFormGroup.controls['idconcept'].value == null || this.requisitionFormGroup.controls['idconcept'].value == ''
      || this.requisitionFormGroup.controls['concept'].value == null || this.requisitionFormGroup.controls['concept'].value == ''
      || this.requisitionFormGroup.controls['conceptname'].value == null || this.requisitionFormGroup.controls['conceptname'].value == '') {
      this.sweet.AlertTime("center", "error", "Por favor complete los campos de partida, producto y cantidad correctamente.", false, 2000);
    } else {
      this.modification = true;
      let addMaterial = this.requisitionFormGroup.value;
      if (addMaterial !== '') {
        let add = {
          "iddeparture": addMaterial.iddeparture,
          "departurenumber": addMaterial.departurenumber,
          "departureName": addMaterial.departurename,
          "idproduct": addMaterial.idproduct,
          "productname": addMaterial.productname,
          "amount": addMaterial.amount,
          "unit": addMaterial.unit,
          "idconcept": addMaterial.idconcept,
          "conceptName": addMaterial.conceptname,
          "notes": addMaterial.notes
        }

        this.materials.push(add);

        //Se resetea la información de los campos de texto para productos
        this.requisitionFormGroup.controls['iddeparture'].setValue('');
        this.requisitionFormGroup.controls['idconcept'].setValue('');
        this.requisitionFormGroup.controls['concept'].setValue('');
        this.requisitionFormGroup.controls['conceptname'].setValue('');
        this.requisitionFormGroup.controls['departurename'].setValue('');
        this.requisitionFormGroup.controls['departurenumber'].setValue('');
        this.requisitionFormGroup.controls['departure'].setValue('');
        this.requisitionFormGroup.controls['idproduct'].setValue('');
        this.requisitionFormGroup.controls['productname'].setValue('');
        this.requisitionFormGroup.controls['product'].setValue('');
        this.requisitionFormGroup.controls['amount'].setValue('');
        this.requisitionFormGroup.controls['unit'].setValue('');
        this.requisitionFormGroup.controls['notes'].setValue('');
        this.editing = "";
      }
    }
  }

  deleteProduct(i: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, elimínalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'El producto se quitó de la requisición.',
          'success'
        )

        //Se crea una variable temporal para almacenar los materiales que permanecerían en la lista
        const materialsT = Array();
        let indice = 0;

        //Se recorre la lista de materiales con un ciclo for
        for (const mat of this.materials) {
          //Se compara el indice que se quiere eliminar con el de la lista y si no coincide se almacena en la lista
          //temporal
          if (indice !== i) {
            materialsT.push(mat);
          }
          indice++;
        }
        //Se pasa el valor de la lista temporal a la lista original de materiales
        this.materials = materialsT;
        this.modification = true;
      }
    })
  }

  async editProduct(i: number) {
    if (this.editing != "") {
      Swal.fire({
        title: '¿Estás seguro de este movimiento?',
        text: "Ya estás editando un material y no has guardado, si continuas se perderá",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, continúa',
        cancelButtonText: 'No, espera',
      }).then((result) => {
        if (result.isConfirmed) {
          //Se crea una variable temporal para almacenar los materiales que permanecerían en la lista
          const materialsT = Array();
          let indice = 0;

          //Se recorre la lista de materiales con un ciclo for
          for (const mat of this.materials) {
            //Se compara el indice que se quiere eliminar con el de la lista y si no coincide se almacena en la lista
            //temporal
            if (indice !== i) {
              materialsT.push(mat);
            }

            if (indice == i) {
              this.editing = mat;
            }
            indice++;
          }
          //Se pasa el valor de la lista temporal a la lista original de materiales
          this.materials = materialsT;

          this.requisitionFormGroup.controls['departure'].setValue(this.editing.departurenumber + " ~ " + this.editing.departureName);
          this.requisitionFormGroup.controls['iddeparture'].setValue(this.editing.iddeparture);
          this.requisitionFormGroup.controls['idconcept'].setValue(this.editing.idconcept);
          this.requisitionFormGroup.controls['concept'].setValue(this.editing.conceptName);
          this.requisitionFormGroup.controls['conceptname'].setValue(this.editing.conceptName);
          this.requisitionFormGroup.controls['departurenumber'].setValue(this.editing.departurenumber);
          this.requisitionFormGroup.controls['departurename'].setValue(this.editing.departureName);
          this.requisitionFormGroup.controls['product'].setValue(this.editing.idproduct + " ~ " + this.editing.productname);
          this.requisitionFormGroup.controls['idproduct'].setValue(this.editing.idproduct);
          this.requisitionFormGroup.controls['productname'].setValue(this.editing.productname);
          this.requisitionFormGroup.controls['amount'].setValue(this.editing.amount);
          this.requisitionFormGroup.controls['unit'].setValue(this.editing.unit);
          this.requisitionFormGroup.controls['notes'].setValue(this.editing.notes);

          this.selectConcepts(this.requisitionFormGroup.controls['iddeparture'].value);
        }
      })
      return;
    }
    //Se crea una variable temporal para almacenar los materiales que permanecerían en la lista
    const materialsT = Array();
    let indice = 0;

    //Se recorre la lista de materiales con un ciclo for
    for (const mat of this.materials) {
      console.log(mat);
      //Se compara el indice que se quiere eliminar con el de la lista y si no coincide se almacena en la lista
      //temporal
      if (indice !== i) {
        materialsT.push(mat);
      }

      if (indice == i) {
        this.editing = mat;
      }
      indice++;
    }
    //Se pasa el valor de la lista temporal a la lista original de materiales
    this.materials = materialsT;

    this.requisitionFormGroup.controls['departure'].setValue(this.editing.departurenumber + " ~ " + this.editing.departureName);
    this.requisitionFormGroup.controls['iddeparture'].setValue(this.editing.iddeparture);
    this.requisitionFormGroup.controls['idconcept'].setValue(this.editing.idconcept);
    this.requisitionFormGroup.controls['concept'].setValue(this.editing.conceptName);
    this.requisitionFormGroup.controls['conceptname'].setValue(this.editing.conceptName);
    this.requisitionFormGroup.controls['departurenumber'].setValue(this.editing.departurenumber);
    this.requisitionFormGroup.controls['departurename'].setValue(this.editing.departureName);
    this.requisitionFormGroup.controls['product'].setValue(this.editing.idproduct + " ~ " + this.editing.productname);
    this.requisitionFormGroup.controls['idproduct'].setValue(this.editing.idproduct);
    this.requisitionFormGroup.controls['productname'].setValue(this.editing.productname);
    this.requisitionFormGroup.controls['amount'].setValue(this.editing.amount);
    this.requisitionFormGroup.controls['unit'].setValue(this.editing.unit);
    this.requisitionFormGroup.controls['notes'].setValue(this.editing.notes);

    this.selectConcepts(this.requisitionFormGroup.controls['iddeparture'].value);
  }

  //==========================================================================================================
  //Función para buscar un material por ID según la selección del select del formulario
  //==========================================================================================================
  async selectProduct(id: any) {
    //El objeto select tiene de valor el ID y el nombre separados por coma
    //idproduct , productname
    let ide = id.productname.split("~");

    this.requisitionFormGroup.controls["idproduct"].setValue(ide[0]);
    this.requisitionFormGroup.controls["productname"].setValue(ide[1]);

    this.productCatalogService.selectProduct(parseInt(ide[0]))
      .subscribe(
        res => {
          //Recuperamos el valor en la variable product
          this.product = res;

          //Asignamos al objeto del formulario con controlName "unit" el valor recuperado de la consulta
          this.requisitionFormGroup.controls['unit'].setValue(this.product.unit);
        }
      );
  }

  async selectConcept(obj: any) {
    this.requisitionFormGroup.controls['idconcept'].setValue(obj.idconcept);
    this.requisitionFormGroup.controls['conceptname'].setValue(obj.descriptions);
  }

  async selectDeparture(obj: any) {
    let info = obj.departurename.split('~');
    this.requisitionFormGroup.controls['iddeparture'].setValue(obj.iddeparture);
    this.requisitionFormGroup.controls['departurename'].setValue(info[1]);
    this.requisitionFormGroup.controls['departurenumber'].setValue(info[0]);
    this.selectConcepts(this.requisitionFormGroup.controls['iddeparture'].value);
  }

  //==========================================================================================================
  //Recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.email
  }
}