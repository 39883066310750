<div class="container-fluid div-central-centro">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">
            <form [formGroup]="paymentComFormGroup" (ngSubmit)="createComissionPayment()">

             <h5 style="text-align: center;"> Registro de comisiones pagadas</h5>
             

                    <div style="padding-bottom: 2%; padding-top: 3%;">

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Folio</mat-label>
                            <input matInput type="number"  formControlName="folio" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Cantidad</mat-label>
                            <input matInput type="number" min="1000" step="1000"   [(ngModel)]="cantidadDefault"  formControlName="paymentAmount" required>
                        </mat-form-field>



                        <mat-form-field appearance="outline" class="col-md-3" >
                            <mat-label>Fecha</mat-label>
                            <input matInput [matDatepicker]="picker1" [formControl]="date" formControlName="paymentDate">
                            <mat-hint>MES/DIA/AÑIO</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                          </mat-form-field>

                        <!-- fewf -->
 

                        <mat-form-field appearance="outline" class="col-md-4" *ngIf="formularioActivo">
                            <mat-label>Concepto de pago</mat-label>
                            <mat-select formControlName="paymentConcept" required [(ngModel)]="conc">
                                <mat-option *ngFor="let conPag of Concepto;" [value]="conPag.tipo">
                                    {{conPag.tipo}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div style="display: flex; flex-direction: row; padding-bottom: 5%;">

                        <div class="col-md-6">
                            <ng-autocomplete name="busqueda" placeholder="id/Nombre coordinador" [data]="sal"
                                [searchKeyword]="keyword" (selected)='onKeypressEvent($event)'
                                [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" >
                            </ng-autocomplete>
                            <ng-template #itemTemplate let-item>
                                <a [innerHTML]="item.name"></a>
                            </ng-template>
                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div>

                        <div class="col-md-6">
                            <ng-autocomplete name="busqueda" placeholder="id/Inmueble" [data]="saleCoord"
                                [searchKeyword]="keyword" (selected)="onKeypressEventSale($event)"
                                [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>
                            <ng-template #itemTemplate let-item>
                                <a [innerHTML]="item.name"></a>
                            </ng-template>

                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div>

                    </div>

                    <div style="display:flex; flex-direction: row-reverse;">

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Inmueble</mat-label>
                            <input matInput type="text" formControlName="fk_property" readonly required
                                [(ngModel)]="codigoInm">
                            <mat-icon matSuffix>roofing</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Código coordinador</mat-label>
                            <input matInput type="number" formControlName="fk_salesCoordinator" readonly required
                                [(ngModel)]="vendedorCod">
                        </mat-form-field>


                    </div>

                    <div class="col-12 text-right mt-3">
                        <button class="btn btn-m19" type="submit"
                            [disabled]="!paymentComFormGroup.valid">Agregar</button>
                    </div>
          
            </form>
        </mat-card-content>
    </mat-card>
</div>