import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogUserComponent } from './dialog-user/dialog-user.component';
import { SweetAlertComponent } from '../../utils//sweet-alert/sweet-alert.component';
import { Router } from '@angular/router';
import { DialogUserFormComponent } from './dialog-user-form/dialog-user-form.component';
import { LoginService } from '../../services/login.service';

import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  userRegisterTitle: BannerTitle = {
    title: "Registro de usuarios",
    icon: "person_add",
    position: "center",
    background: "var(--M19Secundary)"
  }

 userSearchTitle: BannerTitle = {
    title: "Consulta de usuarios",
    icon: "person_search",
    position: "center",
    background: "var(--M19Secundary)"
  }

  filterEmployee = '';
  employees: any = [];
  employee: any = [];
  employeeFormGroup: FormGroup = this._formBuilder.group({
    idEmployee: [],
    name: ['', Validators.required],
    firstLastName: ['', Validators.required],
    secondLastName: ['', Validators.required],
    birthDate: ['', Validators.required],
    telephone: [''],
    mobile: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    address: [, Validators.required],
    localidad: [, Validators.required],
    municipality: [, Validators.required],
    state: [, Validators.required],
    postalCode: [, Validators.required],
    fk_employment: [],
    estatus: [1, Validators.required],
    description: [],
    fk_typeUser: [2]
  });

  constructor(
    private employeeService: UserService,
    private loginService: LoginService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public sweet: SweetAlertComponent,
    private router: Router
  ) { }

  ngOnInit() {
    this.selectUsers();
  }

  //Consultar todos los usuarios (empleados)
  selectUsers() {
    this.employeeService.selectUsers().subscribe(
      res => {
        this.employees = res;
      },
      err => console.log(err)
    );
  }

  selectUser(id: number) {
    this.employeeService.selectUser(id).subscribe(
      res => {
        this.employee = res;
        this.dialog.open(DialogUserComponent, {
          width: '50%',
          data: res
        });
      },
      err => console.log(err)
    )
  }

  createUser() {
    if (this.employeeFormGroup.invalid) {
      return;
    }
    else {
      this.employeeService.createUser(this.employeeFormGroup.value)
        .subscribe(
          res => {
            this.sweet.AlertTime("center", "success", "Usuario registrado con éxito", false, 2000);
            this.selectUsers();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['users']));
          },
          err => {
            console.error(err);
            this.sweet.AlertTime("center", "error", "Usuario no registrado", false, 2000);
          }
        );
    }
  }

  deleteUser(id: number) {
    this.employeeService.deleteUser(id).subscribe(
      res => {
        console.log(res);
        this.sweet.AlertTime("center", "success", "Usuario eliminado con éxito", false, 2000);
        this.selectUsers();
      },
      err => {
        this.sweet.AlertTime("center", "error", "No se puede eliminar el usuario", false, 2000);
        console.error(err);
      }
    )
  }

  updateUser(id: number) {
    this.employeeService.selectUser(id).subscribe(
      res => {
        this.employee = res;
        this.dialog.open(DialogUserFormComponent, {
          width: '70%',
          data: res
        });
      },
      err => console.log(err)
    )
  }

  createLogin() {
    if (this.employeeFormGroup.invalid) {
      return;
    }
    else {
      this.loginService.createLogin(this.employeeFormGroup.value).subscribe(
        res => {
          console.log(res);
        }
      )
    }
  }

  // Retorna un número aleatorio entre min (incluido) y max (excluido)
  getRandomArbitrary(min: number, max: number) {
    return (Math.round(Math.random() * (max - min) + min));
  }

}
