import { Component, OnInit } from '@angular/core';
import { invoiceReportService } from 'src/app/services/invoice.service';
import { SaleService } from 'src/app/services/sale.service';
@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  //Variables locales
  codeProperty: any = "";
  folio: any = "";
  acumulador: number = 0;
  salida: any = "";
  sale: any = [];
  users: any = [];
  keyword = ''; //autocomplete

  //Identificador tipo de inmueble
  M = "Manzana"
  T = "Torre"
  L = "Lote"
  D = " Departamento"

  constructor(
    private saleServices: SaleService,
    private invoiceService: invoiceReportService
  ) { }

  //Metodo de carga de metodos al momento de la ejecución

  ngOnInit(): void {
    this.selectInvoicesCustomer();
  }

  //Consulta de datos desde el servicio que consulta a la API

  selectSales(codeProperty: any) {
    this.saleServices.selectSale(codeProperty).subscribe(
      res => {
        this.sale = res;
      },
      err => console.log(err)
    )
  }

  selectInvoicesCustomer() {
    this.invoiceService.invoiceReportList().subscribe(
      res => {
        this.users = res;
      },
      err => console.error(err)
    )
  }

  onKeypressEvent(event: any) {
    if (event.target.value == ' ' || event.target.value == null || event.target.value == "") {
      console.log("Se requiere consultar cliente")
    } else {
      this.codeProperty = event.target.value
    }
  }

}
