//=========================================================================================================
//Importación de componentes necesarios para esta pantalla
//=========================================================================================================
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LeadsService } from 'src/app/services/leads.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import Swal from 'sweetalert2';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';

@Component({
  selector: 'app-lead-extension',
  templateUrl: './lead-extension.component.html',
  styleUrls: ['./lead-extension.component.scss']
})
export class LeadExtensionComponent implements OnInit {

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  constructor(
    private dialogRef: MatDialogRef<LeadExtensionComponent>,
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    private leadService: LeadsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  extensionFormGroup: FormGroup = this._formBuilder.group({
    idlead: [],
    newdate: [],
    reason: [],
    useremail: [this.getUser()]
  });

  async giveMoreTime() {
    Swal.fire({
      title: '¿Quieres generar la prórroga con la información ingresada?',
      text: "¡Esto no podrá modificarse despues!",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'No, espera',
      confirmButtonText: 'Sí, continúa!',
      buttonsStyling: false,
      customClass: {
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn",
        actions: "sweetActions"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        let args = {
          "idlead": this.data.idlead,
          "newdate": this.extensionFormGroup.controls["newdate"].value
        }
        
        this.leadService.giveMoreTime(args).subscribe(
          res => {
            let lead = {
              "action": "Prórroga",
              "responsable": this.getUser(),
              "secondResponsable": null,
              "comments": this.extensionFormGroup.controls["reason"].value+" | Fecha anterior: "+this.data.limitAttention+" - Fecha nueva: "+args.newdate,
              "fk_leads": this.data.idlead
            }
    
            this.leadService.leadsLogWrite(lead).subscribe(
              res2 => {
                this.sweet.AlertTime("center", "success", "Prórroga establecida con éxito", false, 2500);
                this.dialogRef.close();
              }
            );
          }
        );
      }
    })
  }

  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.fkuser
  }

}
