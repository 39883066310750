<mat-tab-group mat-stretch-tabs>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">person_add</mat-icon>
            <span>Nuevo usuario</span>
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">

                    <form class="row mt-3" [formGroup]="employeeFormGroup" (ngSubmit)="createUser()">

                        <h5 class="col-12 pb-2">Información general</h5>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Nombre(s)</mat-label>
                            <input matInput type="text" formControlName="name" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Apellido paterno</mat-label>
                            <input matInput type="text" formControlName="firstLastName" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Apellido materno</mat-label>
                            <input matInput type="text" formControlName="secondLastName" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Fecha de nacimiento</mat-label>
                            <input matInput type="date" formControlName="birthDate" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Teléfono fijo</mat-label>
                            <input matInput type="tel" formControlName="telephone">
                            <mat-icon matSuffix>call</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Celular</mat-label>
                            <input matInput type="tel" formControlName="mobile" required>
                            <mat-icon matSuffix>phone_iphone</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Correo electrónico</mat-label>
                            <input matInput type="email" formControlName="email" required>
                            <mat-icon matSuffix>email</mat-icon>
                        </mat-form-field>

                        <h5 class="col-12 pb-2">Domicilio</h5>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Dirección</mat-label>
                            <input matInput type="text" formControlName="address" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Colonia o localidad</mat-label>
                            <input matInput type="text" formControlName="localidad" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Municipio</mat-label>
                            <input matInput type="text" formControlName="municipality" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Estado</mat-label>
                            <input matInput type="text" formControlName="state" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Código postal</mat-label>
                            <input matInput type="number" formControlName="postalCode" required>
                        </mat-form-field>

                        <h5 class="col-12 pb-2">Más detalles</h5>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Estatus</mat-label>
                            <mat-select formControlName="estatus" required>
                                <mat-option [value]="1">Activo</mat-option>
                                <mat-option [value]="0">Inactivo</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="col-12 text-right mt-3">
                            <button class="btn btn-m19" type="submit" [disabled]="!employeeFormGroup.valid">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>

                    </form>

                    <!--form class="row mt-3">

                        <h5 class="col-12 pb-2">Permisos de usuario</h5>

                        <mat-slide-toggle>projects</mat-slide-toggle>
                        <mat-slide-toggle>customers</mat-slide-toggle>
                        <mat-slide-toggle></mat-slide-toggle>

                    </form-->
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">person_search</mat-icon>
            <span>Consultar usuarios</span>
        </ng-template>


        <div class="container-fluid mt-3">
            <mat-card class="card-form">

                <mat-form-field appearance="outline" class="filter">
                    <mat-label>Buscar usuario</mat-label>
                    <input matInput type="text" placeholder="Ingresar el nombre del usuario" [(ngModel)]="filterEmployee" name="filterEmployee">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>

                <table class="table table-striped animated fadeIn faster">
                    <thead class="thead-m19">
                        <tr>
                            <th scope="col">Nombre completo</th>
                            <th scope="col">Celular</th>
                            <th scope="col">Correo electrónico</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let employee of employees | filterEmployee: filterEmployee">
                            <td>{{employee.name}} {{employee.firstLastName}} {{employee.secondLastName}}</td>
                            <td>{{employee.mobile}}</td>
                            <td>{{employee.email}}</td>
                            <td class="button-row">
                                <button class="btn btn-m19-table" mat-icon-button title="Ver detalles" (click)="selectUser(employee.idEmployee)">
                                    <mat-icon>visibility</mat-icon>
                                </button>
                                <button class="btn btn-m19-table" mat-icon-button title="Editar" (click)="updateUser(employee.idEmployee)">
                                    <mat-icon>create</mat-icon>
                                </button>
                                <button class="btn btn-m19-table" mat-icon-button title="Borrar" (click)="deleteUser(employee.idEmployee)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-card>
        </div>
    </mat-tab>
</mat-tab-group>