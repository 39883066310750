<div>

    <form [formGroup]="BudgetFormGroup" (ngSubmit)="insertBudgetCommission()">

        <h3 style="text-align: center;"> Registro de presupuesto semanal</h3>
        <br>

        <div style="display:flex; justify-content: center;">
            <mat-form-field appearance="outline" class="col-md-6" id="fechaInput">
                <mat-label> uwu </mat-label>
                <input matInput type="date" placeholder="Seleccione una fecha de registro "
                    formControlName="registrationDate" required>

            </mat-form-field>
        </div>

        <div style="display:flex ; flex-direction: row; justify-content: space-around; flex-wrap: wrap;">

            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label> Altamar </mat-label>
                <input matInput type="number" placeholder="Altamar" formControlName="quantityPAM">
                <mat-icon matSuffix> monetization_on </mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label> Altares </mat-label>
                <input matInput type="number" placeholder="Altares" formControlName="quantityPAL">
                <mat-icon matSuffix> monetization_on </mat-icon>
            </mat-form-field>

        </div>


        <div style="display:flex ; flex-direction: row; justify-content: space-around; flex-wrap: wrap;">

            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label> Benanta </mat-label>
                <input matInput type="number" placeholder="Benanta" formControlName="quantityPBE">
                <mat-icon matSuffix> monetization_on </mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label> Bosco </mat-label>
                <input matInput type="number" placeholder="Bosco" formControlName="quantityPBS">
                <mat-icon matSuffix> monetization_on </mat-icon>
            </mat-form-field>

        </div>

        <div style="display:flex ; flex-direction: row; justify-content: space-around; flex-wrap: wrap;">

            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label> Casa Blaca </mat-label>
                <input matInput type="number" placeholder="Casa Blanca" formControlName="quantityPCB">
                <mat-icon matSuffix> monetization_on </mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label> Gamta </mat-label>
                <input matInput type="number" placeholder="Gamta" formControlName="quantityPGA">
                <mat-icon matSuffix> monetization_on </mat-icon>
            </mat-form-field>

        </div>

        <div style="display:flex ; flex-direction: row; justify-content: space-around; flex-wrap: wrap;">

            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label> Liverti </mat-label>
                <input matInput type="number" placeholder="Liverti" formControlName="quantityPLI">
                <mat-icon matSuffix> monetization_on </mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label> Los Colomos </mat-label>
                <input matInput type="number" placeholder="Los Colomos" formControlName="quantityPCL">
                <mat-icon matSuffix> monetization_on </mat-icon>
            </mat-form-field>

        </div>

        <div style="display:flex ; flex-direction: row; justify-content: space-around; flex-wrap: wrap;">

            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label> Valle de las Palomas </mat-label>
                <input matInput type="number" placeholder="Valle de las Palomas" formControlName="quantityPVP">
                <mat-icon matSuffix> monetization_on </mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label> Valle de las Palomas 2 </mat-label>
                <input matInput type="number" placeholder="Valle de las Palomas" formControlName="quantityPVL">
                <mat-icon matSuffix> monetization_on </mat-icon>
            </mat-form-field>
        </div>

        <div style="display:flex ; flex-direction: row; justify-content: space-around; flex-wrap: wrap;">
            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label> Xalta </mat-label>
                <input matInput type="number" placeholder="Xalta" formControlName="quantityPXL">
                <mat-icon matSuffix> monetization_on </mat-icon>
            </mat-form-field>

        </div>

        <div style="display:flex ; flex-direction: row; justify-content: space-around">

            <mat-form-field appearance="outline" class="col-md-6" hidden>
                <mat-label> Quien Registra </mat-label>
                <input matInput type="text" placeholder="Quien registra" formControlName="userRegistration" readonly
                    required>
            </mat-form-field>
        </div>

        <div class="col-13 text-right text-align:center mt-3" style="display:flex; flex-direction: row-reverse;">
            <button class="btn btn-m19" type="submit"> Registrar presupuesto
            </button>
        </div>





    </form>

</div>