<mat-accordion>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <span>Reporte general de cartera histórica</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="RH">
            <div class="row _card">
                <div class="col-md-5">
                    <mat-card>
                        <mat-card-title>
                            <h3>M19 Inmobiliaria</h3>
                        </mat-card-title>
                        <mat-card-content *ngFor="let lisAtrG of conteoReporteHisGen">
                            <canvas baseChart width="325" height="465" [data]="[lisAtrG.rojoDes,lisAtrG.amarilloDes,lisAtrG.verdeDes,lisAtrG.azulesDes]"
                                    [labels]="pieChartLabels" [chartType]="pieChartType" [options]="pieChartOptions" ([plugins])="pieChartPlugins"
                                    [colors]="pieChartColors" [legend]="pieChartLegend">
                            </canvas>

                            <table class="table table-striped animated fadeIn faster">
                                <thead>
                                    <tr align="center">
                                        <th scope="col" style="color: rgb(206, 41, 23)">Rescisión</th>
                                        <th scope="col" style="color: rgb(250, 166, 9)">En riesgo</th>
                                        <th scope="col" style="color: rgb(39, 162, 11)">Un solo atraso</th>
                                        <th scope="col" style="color: rgb(11, 114, 162)">Muy bien</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr align="center">
                                        <td>{{lisAtrG.rojoDes}}</td>
                                        <td>{{lisAtrG.amarilloDes}}</td>
                                        <td>{{lisAtrG.verdeDes}}</td>
                                        <td>{{lisAtrG.azulesDes}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-card-content>
                    </mat-card>
                </div>

                <div class="col-md-7">
                    <mat-card>
                        <mat-card-content>
                            <mat-expansion-panel (click)="reporteCarteraHistoricoGraficoSumRecuperacion()">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Gráfico de recuperación semanal
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <canvas  *ngFor="let graRec of graficoRecuperacion" baseChart [data]="[graRec.recSem4,graRec.recSem3,graRec.recSem2,graRec.recSem1,0]"
                                        [labels]="[graRec.s4.substr(0,10)+'--->',graRec.s3.substr(0,10)+'--->',graRec.s2.substr(0,10)+'--->',graRec.s1.substr(0,10)+'--->',graRec.s1.substr(0,0)]"
                                        [options]="pieChartOptions" [colors]="barChartColors" ([plugins])="barChartPlugins" [legend]="pieChartLegend"
                                        [chartType]="barChartType">
                                </canvas>
                            </mat-expansion-panel>

                            <mat-expansion-panel (click)="reporteRecuperacionColores()">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Tabulado de recuperación
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <table class="table table-hover mt-3 _reports">
                                    <tbody *ngFor="let recCol of ReporteHisCol">
                                        <tr>
                                            <th scope="col" style="color: rgb(206, 41, 23);;">En rescisión</th>
                                            <td>{{'Se recuperarón '+recCol.rojoRec+' de '+recCol.rojoDes+' que representan'}} <b>{{recCol.totalR | currency:'CAD': '$ '}}</b></td>
                                        </tr>
                                        <tr>
                                            <th scope="col" style="color: rgb(250, 166, 9);">En riesgo</th>
                                            <td>{{'Se recuperarón '+recCol.amaRec+' de '+recCol.amarilloDes+' que representan'}} <b>{{recCol.totalA | currency:'CAD': '$ '}}</b></td>
                                        </tr>
                                        <tr>
                                            <th scope="col" style="color: rgb(39, 162, 11);">Un solo atraso</th>
                                            <td>{{'Se recuperarón '+recCol.verRec+' de '+recCol.verdeDes+' que representan'}} <b>{{recCol.totalV | currency:'CAD': '$ '}}</b></td>
                                        </tr>
                                        <tr>
                                            <th scope="col" style="color: rgb(17, 128, 143);">Recuperación dentro de la misma semana de pago: </th>
                                            <td *ngFor="let graRec of graficoRecuperacion">{{graRec.recSem2 - (recCol.totalR + recCol.totalA + recCol.totalV) | currency:'CAD': '$ '}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col" style="color: rgb(137, 17, 143);">Total: </th>
                                            <td *ngFor="let graRec of graficoRecuperacion">{{graRec.recSem2 | currency:'CAD' : '$'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-expansion-panel>
    
                        </mat-card-content>
                    </mat-card>
                </div>    
            </div>

            

            <table class="table table-striped animated fadeIn faster">
                <thead>
                    <tr>
                        <th colspan="11">
                            <h6 *ngFor="let graRec of graficoRecuperacion">Recuperación de último martes: {{graRec.recSem2 | currency:'CAD' : '$ '}}</h6>
                            <h6 *ngFor="let graRec of graficoRecuperacion">Recuperación actual: {{graRec.recSem1 | currency:'CAD' : '$ '}}</h6>
                        </th>
                    </tr>
                </thead>
                <thead>
                    <tr>
                        <th colspan="11">
                            <mat-chip-list cdkDropListOrientation="horizontal">
                                <mat-chip selected color="#F8F9FA" (click)='reporteHistorico();'>General</mat-chip>
                                <mat-chip selected color="#DC3545" class="text-danger btn-danger" (click)='reporteCarteraHistoricoRojo();'>Rescisión</mat-chip>
                                <mat-chip selected color="#FFC107" class="text-warning btn-warning" (click)='reporteCarteraHistoricoAmarillo();'>En riesgo</mat-chip>
                                <mat-chip selected color="#28A745" class="text-success btn-success" (click)='reporteCarteraHistoricoVerde();'>Un solo atraso</mat-chip>
                                <mat-chip selected color="#17A2B8" class="text-info btn-info" (click)='reporteCarteraHistoricoAzul();'>Muy bien</mat-chip>
                                <mat-chip selected color="#343A40" class="text-dark btn-dark" (click)='reporteCarteraHistoricoRecuperacion();'>Recuperación</mat-chip>
                                <mat-chip selected color="#6C757D" class="text-secondary btn-secondary" (click)='reporteCarteraHistoricoRecuperacionAc();'>Recuperación actual</mat-chip>
                            </mat-chip-list>
                        </th>
                    </tr>
                </thead>
                <thead class="thead-m19">
                    <tr align="center">
                        <th scope="col">Inmueble</th>
                        <th scope="col">Cliente</th>
                        <th scope="col">Vendedor</th>
                        <th scope="col" width="10%" *ngFor="let fechList of fechaCarHis">{{fechList.actual | date: 'dd-MM-yyyy'}}</th>
                        <th scope="col" width="7.5%" *ngFor="let fechList of fechaCarHis">{{fechList.s4 | date: 'dd-MM-yyyy'}}</th>
                        <th scope="col" width="7.5%" *ngFor="let fechList of fechaCarHis">{{fechList.s3 | date: 'dd-MM-yyyy'}}</th>
                        <th scope="col" width="7.5%" *ngFor="let fechList of fechaCarHis">{{fechList.s2 | date: 'dd-MM-yyyy'}}</th>
                        <th scope="col" width="7.5%" *ngFor="let fechList of fechaCarHis">{{fechList.s1 | date: 'dd-MM-yyyy'}}</th>
                        <th scope="col">Recuperación último martes</th>
                        <th scope="col">Recuperación actual</th>
                        <th scope="col" width="7.5%">Último pago</th>
                    </tr>
                </thead>
                <tbody>
                    <tr align="center" *ngFor="let carteras of ReporteHis">
                        <td>
                            <button class="btn btn-dark" (click)="desGHis();slectPayments(carteras.codeProperty);reporteInmuCartera(carteras.codeProperty)">{{carteras.codeProperty}}</button>
                        </td>
                        <td>{{carteras.clientes}}</td>
                        <td>{{carteras.usuarios}}</td>
                        <td>{{carteras.abonado | currency:'CAD' : '$ '}}</td>
                        <td>
                            <div>
                                {{carteras.SemanaCuatro.toFixed(1)}}
                            </div>
                            <div *ngIf="carteras.SemanaCuatro>=0;">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaCuatro < 0 && carteras.SemanaCuatro > tolerancia2" ngElse="warning2">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaCuatro <= tolerancia2 && carteras.SemanaCuatro >tolerancia3" ngElse="warning3">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaCuatro <= tolerancia3 && carteras.SemanaCuatro >limite" ngElse="danger">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaCuatro <=limite">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div>
                                {{carteras.SemanaTres.toFixed(1)}}
                            </div>
                            <div *ngIf="carteras.SemanaTres>=0;">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped " role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaTres < 0 && carteras.SemanaTres > tolerancia2" ngElse="warning2">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaTres <= tolerancia2 && carteras.SemanaTres >tolerancia3" ngElse="warning3">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaTres <= tolerancia3 && carteras.SemanaTres >limite" ngElse="danger">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaTres <=limite">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div>
                                {{carteras.SemanaDos.toFixed(1)}}
                            </div>
                            <div *ngIf="carteras.SemanaDos>=0;">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped " role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaDos < 0 && carteras.SemanaDos > tolerancia2" ngElse="warning2">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaDos <= tolerancia2 && carteras.SemanaDos >tolerancia3" ngElse="warning3">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaDos  <= tolerancia3 && carteras.SemanaDos >limite" ngElse="danger">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaDos <=limite">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div>
                                {{carteras.SemanaUno.toFixed(1)}}
                            </div>
                            <div *ngIf="carteras.SemanaUno>=0;">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped " role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaUno < 0 && carteras.SemanaUno > tolerancia2" ngElse="warning2">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaUno <= tolerancia2 && carteras.SemanaUno >tolerancia3" ngElse="warning3">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaUno  <= tolerancia3 && carteras.SemanaUno >limite" ngElse="danger">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaUno <=limite">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                        </td>
                        <td>{{carteras.RecuperacionUltLun | currency:'CAD' : '$ '}}</td>
                        <td>{{carteras.Recuperacion | currency:'CAD' : '$ '}}</td>
                        <td>{{carteras.fechaRec | date: 'dd-MM-yyyy'}}{{carteras.fechaRecAc | date: 'dd-MM-yyyy'}}</td>                                        
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="row _card" *ngIf="Tv">
            <div class="col-md-4">
                <mat-card>
                    <mat-card-content *ngFor="let venta of carteraInmu">
                        <button class="btn btn-success" style="width: 100%;" (click)="desGHisV()">Reporte general</button>
                        <p></p>
                        <table>
                            <thead>
                                <tr>
                                    <h5>Desarrollo:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.name}}</h5>
                                </tr>
                            </tbody>
        
                            <thead>
                                <tr>
                                    <h5>Cliente:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.clientes}}</h5>
                                </tr>
                            </tbody>
        
                            <thead>
                                <tr>
                                    <h5>Coordinador de ventas:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.usuarios}}</h5>
                                </tr>
                            </tbody>
        
                            <thead *ngIf="venta.vendedores != null">
                                <tr>
                                    <h5>Vendedor:</h5>
                                </tr>
                            </thead>
                            <tbody *ngIf="venta.vendedores != null">
                                <tr>
                                    <h5 class="_h5">{{venta.vendedores}}</h5>
                                </tr>
                            </tbody>
        
                            <thead>
                                <tr>
                                    <h5>Fecha de contrato:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.fechaDato | date: 'dd-MM-yyyy'}}</h5>
                                </tr>
                            </tbody>
        
                            <thead>
                                <tr>
                                    <h5 *ngIf="venta.mobile!=null">Celular:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.mobile}}</h5>
                                </tr>
                            </tbody>
        
                            <thead>
                                <tr>
                                    <h5>Correo electrónico:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.email}}</h5>
                                </tr>
                            </tbody>
                        </table>
        
                        <table *ngIf="venta.codeProperty.substr(2,1)=='M'">
                            <tr>
                                <th>
                                    <h5>{{M}}</h5>
                                </th>
                                <th>
                                    <h5 class="_h5">{{venta.codeProperty.substr(3,2)}}</h5>
                                </th>
                                <th></th>
                                <th>
                                    <h5>{{L}}</h5>
                                </th>
                                <th>
                                    <h5 class="_h5">{{venta.codeProperty.substr(6,2)}}</h5>
                                </th>
                            </tr>
                        </table>
        
                        <table *ngIf="venta.codeProperty.substr(2,1)=='T'">
                            <tr>
                                <th>
                                    <h5>{{T}}</h5>
                                </th>
                                <th> 
                                    <h5 class="_h5">{{venta.codeProperty.substr(3,2)}}</h5>
                                </th>
                                <th></th>
                                <th>
                                    <h5>{{D}}</h5>
                                </th>
                                <th>
                                    <h5 class="_h5">{{venta.codeProperty.substr(6,2)}}</h5>
                                </th>
                            </tr>
                        </table>
        
                        <table>
                            <tr>
                                <th>
                                    <h5>Valor total:</h5>
                                </th>
                                <th>
                                    <h5 class="_h5"> {{venta.valor | currency:'CAD' : '$ '}}</h5>
                                </th>
                            </tr>
                        </table>
        
                        <div class="row mt-2" style="padding-left: .4cm;">
                            <div style="padding-right: 1cm;">
                                <span class="input-group-text" id="entradaPagado"
                                    style="background-color: brown; border-radius:.3cm; color: aliceblue; height: 1cm; width: auto;">
                                    <b> Abonado</b>
                                </span>
                                <div>
                                    <h5> {{venta.abonado | currency:'CAD' : '$ '}} </h5>
                                </div>
                            </div>
                            <div>
                                <span class="input-group-text" id="entradaPagado"
                                    style="background-color: brown; border-radius:.3cm; color: aliceblue; height: 1cm; width: auto;"><b>Adeudo</b></span>
                                <div>
                                    <h5> {{venta.valor-venta.abonado | currency:'CAD' : '$ '}} </h5>
                                </div>
                            </div>
                        </div>
        
        
                        <table class="table table-hover mt-3">
                            <thead>
                                <tr align="center">
                                    <td>Pagos realizados</td>
                                    <td>Pagos esperados</td>
                                    <td>Estado de pagos</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr align="center">
                                    <td> <a>{{venta.mPagadas+'/'+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(0)}}</a>
                                    </td>
        
                                    <td>
                                        <a>
                                            <div>
                                                {{venta.meses+'/'+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(0)}}
                                            </div>
                                            <div *ngIf="venta.retraso>=0;">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="venta.retraso < 0 && venta.retraso > tolerancia2" ngElse="warning2">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="venta.retraso <= tolerancia2 && venta.retraso >tolerancia3" ngElse="warning3">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="venta.retraso <= tolerancia3 && venta.retraso >limite" ngElse="danger">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="venta.retraso <=limite">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                        </a>
                                    </td>
                                    <td>
                                        {{venta.retraso.toFixed(2)}}
                                    </td>
                                </tr>
        
                            </tbody>
                        </table>
                    </mat-card-content>
        
                </mat-card>
            </div>
        
            <div class="col-md-8">
                <mat-card>
                    <mat-card-content>
                        <table class="table table-striped animated fadeIn faster">
                            <thead class="thead-m19">
                                <tr>
                                    <th scope="col">No.</th>
                                    <th scope="col">Folio</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Cantidad</th>
                                    <th scope="col">Método de pago</th>
                                    <th scope="col">Concepto</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let payment of payments; let i=index;">
                                    <th>{{i+1}}</th>
                                    <th>{{payment.folioPago}}</th>
                                    <td>{{payment.fechaPago | date: 'dd-MM-yyyy'}}</td>
                                    <td>{{(payment.cantidadAbono | currency:'CAD' : '$ ')}}</td>
                                    <td>{{payment.metodoPago}}</td>
                                    <td>{{payment.concepto}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

    </mat-expansion-panel>

    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <span>Reporte por desarrollo de cartera histórica</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="container-fluid mt-3" *ngIf="ListG">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">
                  <h6>Seleccione desarrollo que desea consultar</h6>
                  <mat-button-toggle-group>
                    <mat-button-toggle *ngFor="let mora of proyectoCartera" (click)='desX();reporteHistoricoDesarrollo(mora.name);reporteCarteraHistoricoConteoDesarrollo(mora.name)'>
                        {{mora.name}}
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="container-fluid mt-3" *ngIf="ListM">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">
                  <h6>Seleccione desarrollo que desea consultar</h6>
                  <mat-button-toggle-group>
                    <mat-button-toggle *ngFor="let morita of proyectoCartera" (click)='inmu();reporteHistoricoDesarrollo(morita.name);reporteCarteraHistoricoConteoDesarrollo(morita.name)'>
                        {{morita.name}}
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </mat-card-content>
            </mat-card>
        </div>

        <div *ngIf="VP">
            <div class="row _card">
                <div class="col-md-5">
                    <mat-card *ngFor="let lisAtr of conteoReporteHis">
                        <mat-card-title>
                            <h3>{{lisAtr.name}}</h3>
                        </mat-card-title>
                        <mat-card-content>
                            <canvas baseChart width="250" height="350" [data]="[lisAtr.rojoDes,lisAtr.amarilloDes,lisAtr.verdeDes,lisAtr.azulesDes]"
                                    [labels]="pieChartLabels" [chartType]="pieChartType" [options]="pieChartOptions"
                                    ([plugins])="pieChartPlugins" [colors]="pieChartColors" [legend]="pieChartLegend">
                            </canvas>

                            <table class="table table-striped animated fadeIn faster">
                                <thead>
                                    <tr align="center">
                                        <th scope="col" style="color: rgb(206, 41, 23)">Rescisión</th>
                                        <th scope="col" style="color: rgb(250, 166, 9)">En riesgo</th>
                                        <th scope="col" style="color: rgb(39, 162, 11)">Un solo atraso</th>
                                        <th scope="col" style="color: rgb(11, 114, 162)">Muy bien</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr align="center">
                                        <td>{{lisAtr.rojoDes}}</td>
                                        <td>{{lisAtr.amarilloDes}}</td>
                                        <td>{{lisAtr.verdeDes}}</td>
                                        <td>{{lisAtr.azulesDes}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-card-content>
                    </mat-card>
                </div>  
            </div>

            <table class="table table-striped animated fadeIn faster">
                <thead >
                    <tr>
                        <th colspan="11">
                            <mat-chip-list cdkDropListOrientation="horizontal">
                                <mat-chip color="#F8F9FA" (click)='inmu();reporteHistoricoDesarrollo(desarrollo);'>General</mat-chip>
                                <mat-chip color="#DC3545" class="text-danger btn-danger" (click)='inmu();reporteCarteraHistoricoDesarrolloRojo(desarrollo);'>Rescisión</mat-chip>
                                <mat-chip color="#FFC107" class="text-warning btn-warning" (click)='inmu();reporteCarteraHistoricoDesarrolloAmarillo(desarrollo);'>En riesgo</mat-chip>
                                <mat-chip color="#28A745" class="text-success btn-success" (click)='inmu();reporteCarteraHistoricoDesarrolloVerde(desarrollo);'>Un solo atraso</mat-chip>
                                <mat-chip color="#17A2B8" class="text-info btn-info" (click)='inmu();reporteCarteraHistoricoDesarrolloAzul(desarrollo);'>Muy bien</mat-chip>
                                <mat-chip color="#343A40" class="text-dark btn-dark" (click)='inmu();reporteCarteraHistoricoRecuperacionDesarrollo(desarrollo);'>Recuperación</mat-chip>
                                <mat-chip color="#6C757D" class="text-secondary btn-secondary" (click)='inmu();reporteCarteraHistoricoRecuperacionAcDesarrollo(desarrollo);'>Recuperación actual</mat-chip>
                            </mat-chip-list>
                        </th>
                    </tr>
                </thead>
                <thead class="thead-m19">
                    <tr align="center">
                        <th scope="col">Inmueble</th>
                        <th scope="col">Cliente</th>
                        <th scope="col">Vendedor</th>
                        <th scope="col" width="10%" *ngFor="let fechList of fechaCarHis">{{fechList.actual | date: 'dd-MM-yyyy'}}</th>
                        <th scope="col" width="7.5%" *ngFor="let fechList of fechaCarHis">{{fechList.s4 | date: 'dd-MM-yyyy'}}</th>
                        <th scope="col" width="7.5%" *ngFor="let fechList of fechaCarHis">{{fechList.s3 | date: 'dd-MM-yyyy'}}</th>
                        <th scope="col" width="7.5%" *ngFor="let fechList of fechaCarHis">{{fechList.s2 | date: 'dd-MM-yyyy'}}</th>
                        <th scope="col" width="7.5%" *ngFor="let fechList of fechaCarHis">{{fechList.s1 | date: 'dd-MM-yyyy'}}</th>
                        <th scope="col">Recuperación último martes</th>
                        <th scope="col">Recuperación actual</th>
                        <th scope="col">Último pago</th>
                    </tr>
                </thead>
                <tbody>
                    <tr align="center" *ngFor="let carteras of desarrolloReporteHis">
                        <td>
                            <button class="btn btn-dark" (click)="desG();slectPayments(carteras.codeProperty);reporteInmuCartera(carteras.codeProperty)">{{carteras.codeProperty}}</button>
                        </td>
                        <td>{{carteras.clientes}}</td>
                        <td>{{carteras.usuarios}}</td>
                        <td>{{carteras.abonado | currency:'CAD' : '$ '}}</td>
                        <td>
                            <div>
                                {{carteras.SemanaCuatro.toFixed(1)}}
                            </div>
                            <div *ngIf="carteras.SemanaCuatro>=0;">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaCuatro < 0 && carteras.SemanaCuatro > tolerancia2" ngElse="warning2">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaCuatro <= tolerancia2 && carteras.SemanaCuatro >tolerancia3" ngElse="warning3">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaCuatro <= tolerancia3 && carteras.SemanaCuatro >limite" ngElse="danger">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaCuatro <=limite">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div>
                                {{carteras.SemanaTres.toFixed(1)}}
                            </div>
                            <div *ngIf="carteras.SemanaTres>=0;">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped " role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaTres < 0 && carteras.SemanaTres > tolerancia2" ngElse="warning2">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaTres <= tolerancia2 && carteras.SemanaTres >tolerancia3" ngElse="warning3">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaTres <= tolerancia3 && carteras.SemanaTres >limite" ngElse="danger">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaTres <=limite">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div>
                                {{carteras.SemanaDos.toFixed(1)}}
                            </div>
                            <div *ngIf="carteras.SemanaDos>=0;">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped " role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaDos < 0 && carteras.SemanaDos > tolerancia2" ngElse="warning2">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaDos <= tolerancia2 && carteras.SemanaDos >tolerancia3" ngElse="warning3">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaDos  <= tolerancia3 && carteras.SemanaDos >limite" ngElse="danger">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaDos <=limite">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div>
                                {{carteras.SemanaUno.toFixed(1)}}
                            </div>
                            <div *ngIf="carteras.SemanaUno>=0;">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped " role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaUno < 0 && carteras.SemanaUno > tolerancia2" ngElse="warning2">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaUno <= tolerancia2 && carteras.SemanaUno >tolerancia3" ngElse="warning3">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaUno  <= tolerancia3 && carteras.SemanaUno >limite" ngElse="danger">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-warning" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                            <div *ngIf="carteras.SemanaUno <=limite">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%"></div>
                                </div>
                            </div>
                        </td>
                        <td>{{carteras.RecuperacionUltLun | currency:'CAD' : '$ '}}</td>
                        <td>{{carteras.Recuperacion | currency:'CAD' : '$ '}}</td>
                        <td>{{carteras.fechaRec | date: 'dd-MM-yyyy'}}{{carteras.fechaRecAc | date: 'dd-MM-yyyy'}}</td>                                        
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="row _card" *ngIf="X">
            <div class="col-md-4">
                <mat-card>
                    <mat-card-content *ngFor="let venta of carteraInmu">
                        <button class="btn btn-success" style="width: 100%;" (click)="inmu()">Reporte general</button>
                        <p></p>
                        <table>
                            <thead>
                                <tr>
                                    <h5>Desarrollo:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.name}}</h5>
                                </tr>
                            </tbody>
        
                            <thead>
                                <tr>
                                    <h5>Cliente:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.clientes}}</h5>
                                </tr>
                            </tbody>
        
                            <thead>
                                <tr>
                                    <h5>Coordinador de ventas:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.usuarios}}</h5>
                                </tr>
                            </tbody>
        
                            <thead *ngIf="venta.vendedores != null">
                                <tr>
                                    <h5>Vendedor:</h5>
                                </tr>
                            </thead>
                            <tbody *ngIf="venta.vendedores != null">
                                <tr>
                                    <h5 class="_h5">{{venta.vendedores}}</h5>
                                </tr>
                            </tbody>
        
                            <thead>
                                <tr>
                                    <h5>Fecha de contrato:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.fechaDato | date: 'dd-MM-yyyy'}}</h5>
                                </tr>
                            </tbody>
        
                            <thead>
                                <tr>
                                    <h5 *ngIf="venta.mobile!=null">Celular:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.mobile}}</h5>
                                </tr>
                            </tbody>
        
                            <thead>
                                <tr>
                                    <h5>Correo electrónico:</h5>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <h5 class="_h5">{{venta.email}}</h5>
                                </tr>
                            </tbody>
                        </table>
        
                        <table *ngIf="venta.codeProperty.substr(2,1)=='M'">
                            <tr>
                                <th>
                                    <h5>{{M}}</h5>
                                </th>
                                <th>
                                    <h5 class="_h5">{{venta.codeProperty.substr(3,2)}}</h5>
                                </th>
                                <th></th>
                                <th>
                                    <h5>{{L}}</h5>
                                </th>
                                <th>
                                    <h5 class="_h5">{{venta.codeProperty.substr(6,2)}}</h5>
                                </th>
                            </tr>
                        </table>
        
                        <table *ngIf="venta.codeProperty.substr(2,1)=='T'">
                            <tr>
                                <th>
                                    <h5>{{T}}</h5>
                                </th>
                                <th> 
                                    <h5 class="_h5">{{venta.codeProperty.substr(3,2)}}</h5>
                                </th>
                                <th></th>
                                <th>
                                    <h5>{{D}}</h5>
                                </th>
                                <th>
                                    <h5 class="_h5">{{venta.codeProperty.substr(6,2)}}</h5>
                                </th>
                            </tr>
                        </table>
        
                        <table>
                            <tr>
                                <th>
                                    <h5>Valor total:</h5>
                                </th>
                                <th>
                                    <h5 class="_h5"> {{venta.valor | currency:'CAD' : '$ '}}</h5>
                                </th>
                            </tr>
                        </table>
        
                        <div class="row mt-2" style="padding-left: .4cm;">
                            <div style="padding-right: 1cm;">
                                <span class="input-group-text" id="entradaPagado"
                                    style="background-color: brown; border-radius:.3cm; color: aliceblue; height: 1cm; width: auto;">
                                    <b> Abonado</b>
                                </span>
                                <div>
                                    <h5> {{venta.abonado | currency:'CAD' : '$ '}} </h5>
                                </div>
                            </div>
                            <div>
                                <span class="input-group-text" id="entradaPagado"
                                    style="background-color: brown; border-radius:.3cm; color: aliceblue; height: 1cm; width: auto;"><b>Adeudo</b></span>
                                <div>
                                    <h5> {{venta.valor-venta.abonado | currency:'CAD' : '$ '}} </h5>
                                </div>
                            </div>
                        </div>
        
        
                        <table class="table table-hover mt-3">
                            <thead>
                                <tr>
                                    <td>Pagos realizados</td>
                                    <td>Pagos esperados</td>
                                    <td>Estado de pagos</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> <a>{{venta.mPagadas+'/'+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(0)}}</a>
                                    </td>
        
                                    <td><a>
                                            <div>
                                                {{venta.meses+'/'+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(0)}}
                                            </div>
                                            <div *ngIf="venta.retraso>=0;">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="venta.retraso < 0 && venta.retraso > tolerancia2" ngElse="warning2">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="venta.retraso <= tolerancia2 && venta.retraso >tolerancia3" ngElse="warning3">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="venta.retraso <= tolerancia3 && venta.retraso >limite" ngElse="danger">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                            <div *ngIf="venta.retraso <=limite">
                                                <div class="progress">
                                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" style="width: 100%"></div>
                                                </div>
                                            </div>
                                        </a>
                                    </td>
                                    <td>
                                        {{venta.retraso.toFixed(2)}}
                                    </td>
                                </tr>
        
                            </tbody>
                        </table>
                    </mat-card-content>
        
                </mat-card>
            </div>
        
            <div class="col-md-8">
                <mat-card>
                    <mat-card-content>
                        <table class="table table-striped animated fadeIn faster">
                            <thead class="thead-m19">
                                <tr>
                                    <th scope="col">No.</th>
                                    <th scope="col">Folio</th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Cantidad</th>
                                    <th scope="col">Método de pago</th>
                                    <th scope="col">Concepto</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let payment of payments; let i=index;">
                                    <th>{{i+1}}</th>
                                    <th>{{payment.folioPago}}</th>
                                    <td>{{payment.fechaPago | date: 'dd-MM-yyyy'}}</td>
                                    <td>{{(payment.cantidadAbono | currency:'CAD' : '$ ')}}</td>
                                    <td>{{payment.metodoPago}}</td>
                                    <td>{{payment.concepto}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>


    </mat-expansion-panel>
</mat-accordion>