import { Component, OnInit } from '@angular/core';

//=========================================================================================================
//Importación de servicios necesarios para esta página
//=========================================================================================================
import { BudgetService } from 'src/app/services/budget.service';

//=========================================================================================================
//Importación de librerías para componentes visuales
//=========================================================================================================
import { BudgetdialogComponent } from '../budgetconsult/budgetdialog/budgetdialog.component';
import { MatDialog } from '@angular/material/dialog';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import Swal from 'sweetalert2';

@Component({
  selector: 'app-budgetapprove',
  templateUrl: './budgetapprove.component.html',
  styleUrls: ['./budgetapprove.component.scss']
})
export class BudgetapproveComponent implements OnInit {

  budgets: any = [];

  view: any = false;
  type: any = true;

  constructor(
    private budgetService: BudgetService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.selectAwait();
  }

  //=========================================================================================================
  //Función para aprobar un presupuesto
  //=========================================================================================================
  async approveBudget(id: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "Aprobarás esta requisición y no se podrá revertir",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, lo apruebo',
      cancelButtonText: 'No, espera'
    }).then((result) => {
      if (result.isConfirmed) {
        this.budgetService.approveBudget(id).subscribe(
          res => {
            Swal.fire(
              '¡Aprobado!',
              'El presupuesto se aprobó.',
              'success'
            )
            this.selectAwait();
          }
        );
      }
    })
  }

  //=========================================================================================================
  //Función para rechazar un presupuesto
  //=========================================================================================================
  async rejectBudget(id: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "Rechazarás esta requisición y no se podrá revertir",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, la rechazo',
      cancelButtonText: 'No, espera'
    }).then((result) => {
      if (result.isConfirmed) {
        this.budgetService.rejectBudget(id).subscribe(
          res => {
            Swal.fire(
              '¡Rechazado!',
              'El presupuesto se rechazó.',
              'success'
            )
            this.selectAwait();
          }
        );
      }
    })
  }

  //=========================================================================================================
  //Función para obtener todos los presupuestos
  //=========================================================================================================
  async getAll() {
    this.budgetService.selectBudget().subscribe(
      res => {
        this.budgets = res;
      }
    );
  }

  //=========================================================================================================
  //Función para abrir la información de un presupuesto
  //=========================================================================================================
  async selectBudget(id: number) {
    this.dialog.open(BudgetdialogComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '100%',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: id,
    });
  }

  //=========================================================================================================
  //Función para buscar los presupuestos que están en espera de aprobación
  //=========================================================================================================
  async selectAwait() {
    this.budgetService.selectAwaitBudget().subscribe(
      res => {
        this.budgets = res;
      }
    );
  }

  //=========================================================================================================
  //Función para buscar los presupuestos que están aprobados
  //=========================================================================================================

  async selectApproved() {
    this.budgetService.selectApprovedBudget().subscribe(
      res => {
        this.budgets = res;
      }
    );
  }

  //=========================================================================================================
  //Función para buscar los presupuestos que están rechazados
  //=========================================================================================================
  async selectReject() {
    this.budgetService.selectRejectBudget().subscribe(
      res => {
        this.budgets = res;
      }
    );
  }

}
