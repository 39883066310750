import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  private url = 'http://localhost:3000/api';


  constructor(private http: HttpClient) { }

  selectSales(codInmueble: any): Observable<any> {
    return this.http.get(`${environment.URLServer}/sales/property/${codInmueble}`);
  }

  getSaleInfo(property: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/sales/getSaleInfo/${property}`);
  }

  selectSalesCoo(codInmuebleCoo: any): Observable<any> {
    return this.http.get(`${environment.URLServer}/sales/coowner/${codInmuebleCoo}`);
  }
  paymentCustomer(correo: any): Observable<any> {
    return this.http.get(`${environment.URLServer}/sales/pagoCliente/${correo}`);
  }
  infoCustomer(correoCu: any): Observable<any> {
    return this.http.get(`${environment.URLServer}/sales/infoCliente/${correoCu}`);
  }
  selectSalesDesarrollo(des: any): Observable<any> {
    return this.http.get(`${environment.URLServer}/sales/desarrollo/${des}`);
  }
  selectSalesInmobiliaria(): Observable<any> {
    return this.http.get(`${environment.URLServer}/sales/`);
  }
}
