import { Component, OnInit } from '@angular/core';
import { AdministrationService } from 'src/app/services/administration.service';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-general-request',
  templateUrl: './general-request.component.html',
  styleUrls: ['./general-request.component.scss']
})
export class GeneralRequestComponent implements OnInit {

  constructor(
    private adminService: AdministrationService,
    public sweet: SweetAlertComponent
  ) { }

  customersWaiting: any = [];
  customersRatificated: any = [];
  ratCustomers: any = [];

  ngOnInit(): void {
    this.getCustomersAwait();
    this.getCustomersRatificated();
    this.getRatificatedCustomers();
  }

  async getCustomersAwait() {
    this.adminService.getSalesWithContract().subscribe(
      res => {
        this.customersWaiting = res;
      }
    );
  }

  async getCustomersRatificated() {
    this.adminService.getSalesRatificated().subscribe(
      res => {
        this.customersRatificated = res;
      }
    );
  }

  async getRatificatedCustomers() {
    this.adminService.getRatificatedCustomers().subscribe(
      res => {
        this.ratCustomers = res;
      }
    );
  }

  async sendRatification(property: string) {
    Swal.fire({
      title: '¿Estás seguro de que esta venta se enviará a ratificación?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, espera!',
      confirmButtonText: 'Sí, estoy seguro.'
    }).then((result) => {
      if (result.isConfirmed) {
        this.adminService.sendRatification(property).subscribe(
          res => {
            this.sweet.AlertTime("center", "success", `El contrato del inmueble ${property} se envió a ratificación.`, false, 2000);
            this.getCustomersAwait();
            this.getCustomersRatificated();
          }
        );
      }
    })
  }

  async receiveRatification(property: string) {
    Swal.fire({
      title: '¿Estás seguro de que esta ratificación ya se recibió?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, espera!',
      confirmButtonText: 'Sí, ya la recibí.'
    }).then((result) => {
      if (result.isConfirmed) {
        this.adminService.receiveRatification(property).subscribe(
          res => {
            this.adminService.updateSaleStatus(5, property).subscribe(
              res => {
                this.sweet.AlertTime("center", "success", `El inmueble ${property} se ha marcado como ratificado.`, false, 2000);
                this.getCustomersAwait();
                this.getCustomersRatificated();
                this.getRatificatedCustomers();
              }
            );
          }
        );
      }
    })
  }

  async setCompleteCustomer(property: string) {
    Swal.fire({
      title: '¿Estás seguro de que este cliente ya está completo?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, espera!',
      confirmButtonText: 'Sí, el cliente ya firmó.'
    }).then((result) => {
      if (result.isConfirmed) {
        this.adminService.updateSaleStatus(6, property).subscribe(
          res => {
            this.adminService.setComplete(property).subscribe(
              res => {
                this.sweet.AlertTime("center", "success", `El inmueble ${property} se ha marcado como cliente completo.`, false, 2000);
                this.getRatificatedCustomers();
              }
            );
          }
        );
      }
    })
  }
}