import { Component, OnInit } from '@angular/core';
// tslint:disable-next-line:typedef 
//import jsPDF from 'jspdf';


//import html2canvas from 'html2canvas';

@Component({
  selector: 'app-request-doc',
  templateUrl: './request-doc.component.html',
  styleUrls: ['./request-doc.component.scss']
})
export class RequestDOCComponent implements OnInit {
  VIDEOGAMES = [
    {
      id: 1,
      name: "Animal Crossing",
      platform: "Nintendo Switch",
      reference: "1-770-736-8031"
    },
    {
      id: 2,
      name: "The Legend of Zelda: Ocarina of Time CV",
      platform: "Wii U",
      reference: "1-770-736-2323"
    },
    {
      id: 3,
      name: "Metal Gear Solid",
      platform: "Playstation (PSX)",
      reference: "1-4564-736-334"
    },
    {
      id: 4,
      name: "ShenMue",
      platform: "Sega Dreamcast",
      reference: "3-770-736-4532"
    },
    {
      id: 5,
      name: "Rise of the Tomb Raider",
      platform: "Playstation 4",
      reference: "1-324-736-3245"
    },
    {
      id: 6,
      name: "Resident Evil 2",
      platform: "Playstation",
      reference: "1-123-3336-4321"
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }


  printer() {
    const printContent = document.getElementById("htmlData");
    const WindowPrt = window.open('', '', '');
    WindowPrt!.document.write(printContent!.innerHTML);
    WindowPrt!.document.close();
    WindowPrt!.focus();
    WindowPrt!.print();
    WindowPrt!.close();
  }



}
