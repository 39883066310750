<mat-dialog-content>


    <div class="container-fluid div-central-centro">
        <mat-card class="card-form">
            <mat-card-content class="justify-align-center">
                <form [formGroup]="paymentComFormGroup" (ngSubmit)="createComissionPayments()">

                    <h2 style="text-align: center;"> Información de pago de comisión </h2>


                    <div style="padding-top: 3%;">

                        <mat-form-field appearance="outline" class="col-md-2">
                            <!-- value="folioGenerator()"  -->
                            <mat-label>Folio</mat-label>
                            <input matInput type="number" formControlName="folio" [(ngModel)]="generatedFolio.folio"
                                required readonly>
                            <mat-icon matSuffix> format_list_numbered_rtl</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Cantidad</mat-label>
                            <input matInput type="number"  step="1000" formControlName="paymentAmount"
                            [(ngModel)]='cantidadDefault' required>
                            <mat-icon matSuffix>monetization_on</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Fecha</mat-label>
                            <input matInput type="date" formControlName="paymentDate" required>
                        </mat-form-field>


                        <mat-form-field appearance="outline" class="col-md-4" >
                            <mat-label>Concepto de pago</mat-label>
                            <mat-select formControlName="paymentConcept" required>
                                <mat-option *ngFor="let conPag of Concepto;" [value]="conPag.tipo">
                                    {{conPag.tipo}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>


                    <div style="display:flex; flex-direction: row; flex-wrap: wrap; ">

                        <mat-form-field appearance="outline" class="col-md-6">
                            <mat-label>Coordinador</mat-label>
                            <input matInput type="text" [value]='infoSeller.nameEmployee' readonly required>
                            <mat-icon matSuffix> person_pin </mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-6">
                            <mat-label>Inmueble</mat-label>
                            <input matInput type="text" [value]='infoSeller.inmueble' readonly required>
                            <mat-icon matSuffix>roofing</mat-icon>
                        </mat-form-field>

                    </div>

                    <div style="display:flex; flex-direction: row-reverse; flex-wrap: wrap;">

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Inmueble</mat-label>
                            <input matInput type="text" formControlName="fk_property" [(ngModel)]="infoSeller.inmueble"
                                readonly required>
                            <mat-icon matSuffix> roofing</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Código coordinador</mat-label>
                            <input matInput type="number" formControlName="fk_salesCoordinator" [(ngModel)]="saleCodeM"
                                readonly required>
                            <mat-icon matSuffix>code</mat-icon>
                        </mat-form-field>

                    </div>

                    <div class="col-12"
                        style="display:flex; flex-direction: row-reverse;  justify-content: space-between;">

                        <div class="col-13 text-right text-align:center mt-3"
                            style="display:flex; flex-direction: row-reverse;">
                            <button class="btn btn-m19" type="submit" [disabled]="!paymentComFormGroup.valid"> Registrar
                                pago
                            </button>
                        </div>

                    </div>

                </form>


                <div class="col-12" style="display:flex; flex-direction: row; margin-left: 15px;">
                    <button class="btn btn-m19" style="display: flex; flex-direction: column-reverse;"
                        (click)="closeDialogPayments()">Cancelar</button>
                </div>


            </mat-card-content>
        </mat-card>
    </div>

</mat-dialog-content>

<!--
                                                    <div class="col-3 text-left mt-1" >
                                                            
                                                        Pagar la cantidad de: 
                                                        <br>
                                                        
                                                        <div class="progress">
                                                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                                                                style="Display :flex; flex-direction:column ; width: 100%">
                                                            </div>
                                                        </div>
                                                    </div>
                                            -->