import { Component, OnInit } from '@angular/core';

import { SharedServiceService } from 'src/app/shared/shared-service.service';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  user: string = '';

  constructor(private sharedService: SharedServiceService) { }

  ngOnInit(): void {
    this.getUser();
    this.sharedService.page = "Inicio";
    localStorage.setItem("page", this.sharedService.page);
  }

  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    this.user = this.decode.cliente;
  }
}