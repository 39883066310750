import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { data } from 'jquery';
import { LogosService } from 'src/app/services/logos.service';
import { AdministrationService } from 'src/app/services/administration.service';
import { MapsService } from 'src/app/services/maps.service';
import { PropertyService } from 'src/app/services/property.service';

@Component({
  selector: 'app-dialog-request-view',
  templateUrl: './dialog-request-view.component.html',
  styleUrls: ['./dialog-request-view.component.scss']
})
export class DialogRequestViewComponent implements OnInit {

  CustomerInfo: any;
  propertyInfo: any;
  property:any;
  datas:any;
  sale:any;
  logo: string = "";

  seller: any = '';
  customer: any = '';


  constructor(   
    private adminService: AdministrationService,
    private logoService: LogosService,
    private mapsService: MapsService,
    private dialogRef: MatDialogRef<DialogRequestViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

    ) { }

  ngOnInit(): void {
    this.datas = this.data;
    this.propertyInfos(this.datas.inmueble)
    this.CustomerInfos(this.datas.customer)

    this.getSele(this.datas.sale)
  }

  printer() {
    const printContent = document.getElementById("htmlData");
    const WindowPrt = window.open('', '', '');
    WindowPrt!.document.write(printContent!.innerHTML);
    WindowPrt!.document.close();
    WindowPrt!.focus();
    WindowPrt!.print();
    WindowPrt!.close();
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlData')!.innerHTML ;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
      <html>
        <head>
          <title>${this.propertyInfo.codeProperty} - ${this.CustomerInfo.complete_name}</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin!.document.close();
}


  propertyInfos(proper:any) {
    console.log("Property info: ")
    this.mapsService.getPropertyInfo(proper).subscribe(
      propertyget => {
        this.propertyInfo = propertyget
        this.getLogo(this.propertyInfo.name);
        console.log("Property info: ",this.propertyInfo)
      }
    )
  }

  CustomerInfos(custom:any) {
    console.log("Customer info: ")
    this.mapsService.getCustomerInfo(custom).subscribe(
      customerGet => {
        this.CustomerInfo = customerGet;
        
        console.log("Customer info: ",this.CustomerInfo)
      }
    )
  }

  getSele(sal:any){
    this.adminService.getSele(sal).subscribe(
      res=>{
        console.log("venta",res)
        this.sale = res
      }
    )

  }

  getLogo(project: string) {
    this.logoService.getProjectLogo(project).subscribe(
      res => {
        console.log("Data: ", res.UrlLogo);
        this.logo = res.UrlLogo;
      }
    );
  }


}
