import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { piecework, piecework_catalog, piecework_content, piecework_employees, piecework_log } from '../models/Piecework';

@Injectable({
  providedIn: 'root'
})
export class PieceworkService {

  constructor(private http: HttpClient) { }

  insertPieceworkCatalog(piece: piecework_catalog): Observable<any> {
    return this.http.post(`${environment.URLServer}/piecework/insertPieceworkCatalog`, piece);
  }

  updatePieceworkCatalog(piece: any): Observable<any> {
    return this.http.put(`${environment.URLServer}/piecework/updatePieceworkCatalog`, piece);
  }

  getPiecework_catalog(): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/getPiecework_catalog`);
  }

  getPiecework_catalogByID(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/getPiecework_catalogByID/${id}`);
  }

  insertPieceworkHeader(piece: piecework): Observable<any> {
    return this.http.post(`${environment.URLServer}/piecework/insertPieceworkHeader`, piece);
  }

  insertPieceworkLog(piece: piecework_log): Observable<any> {
    return this.http.post(`${environment.URLServer}/piecework/insertPieceworkLog`, piece);
  }

  insertPieceworkContent(piece: piecework_content): Observable<any> {
    return this.http.post(`${environment.URLServer}/piecework/insertPieceworkContent`, piece);
  }

  insertPieceworkEmployee(piece: piecework_employees): Observable<any> {
    return this.http.post(`${environment.URLServer}/piecework/insertPieceworkEmployee`, piece);
  }

  setDisabledPiecework(piece: any): Observable<any> {
    return this.http.put(`${environment.URLServer}/piecework/setDisabledPiecework`, piece);
  }

  getPieceworkHeader(week: Date): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/getPieceworkHeader`);
  }

  getWeeks(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/getWeeks/${id}`);
  }

  searchByWeek(week: number, tower: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/searchByWeek/${week}/${tower}`);
  }
  searchWeeks(week: number, tower: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/searchWeeks/${week}/${tower}`);
  }

  getPieceworkInfo(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/getPieceworkInfo/${id}`);
  }

  getPieceworksDepartures(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/getPieceworksDepartures/${id}`);
  }

  getPieceworkContent(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/getPieceworkContent/${id}`);
  }

  getPieceworkEmployee(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/getPieceworkEmployee/${id}`);
  }

  getTotalByProject(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/getTotalByProject/${id}`);
  }

  TotalProjects(): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/TotalProjects`);
  }

  getTotalByModule(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/getTotalByModule/${id}`);
  }

  getTotalByModules(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/getTotalByModules/${id}`);
  }

  getTotalByPieceworks(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/getTotalByPieceworks/${id}`);
  }

  //Consultar nombres de los destajos
  getNamesOfPieceworks(): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/getNamesOfPieceworks`);
  }

  //Consultar nombres de los destajos
  selectPiecework(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/selectPiecework/${id}`);
  }

  //Consultar todos los destajos hechos por semana
  selectPieceworks(): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/selectPieceworks`);
  }

  //Consultar todos los destajos hechos por semana
  updatePiecework(id: any, p: any): Observable<any> {
    return this.http.put(`${environment.URLServer}/piecework/updatePiecework/${id}`, p);
  }

  //Registrar razón
  updateEmployee(id:number, e:String): Observable<any> {
    console.log("e → " , e)
    return this.http.put(`${environment.URLServer}/piecework/updateEmployee/${id}`,[e]);
  }

  getPieceworks(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/getPieceworks/${id}`);
  }

  getLastPieceworks(): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/getLastPiecework`);
  }

  // Aprobación de presupuestos
  getAll(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/getAll/${id}`);
  }

   //Espera
   selectAwait(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/selectAwait/${id}`);
  }

   // Aprobación de presupuestos
   selectReject(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/selectReject/${id}`);
  }

   //Espera
   selectApproved(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/piecework/selectApproved/${id}`);
  }

  //Aprobar destajo
  approvePiece(id:number): Observable<any> {
    return this.http.put(`${environment.URLServer}/piecework/approvePiece/${id}`,[]);
  }

  //Rechazar destajo
  rejectPiece(id:number): Observable<any> {
    return this.http.put(`${environment.URLServer}/piecework/rejectPiece/${id}`,[]);
  }
}
