<!------------------------------------ PRESUPUESTO DE MATERIALES ---------------------------->
      <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">

                    <div style="display: flex; justify-content: space-between;">
                        <div style="width: 45%; height: 60vh; overflow: scroll;">

                            <table class="table table-striped mt-3 animated fadeIn faster">
                                <thead class="thead-m19">
                                    <tr style="text-align:center;">
                                        <!-- Se definen los encabezados de cada columna de la tabla -->
                                        <th scope="col">Proyecto</th>
                                        <th scope="col">Total</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- ngFor para recorrer todos los presupuestos obtenidos -->
                                    <!-- con esto podemos controlar la generación de componentes -->
                                    <!-- en base a la cantidad de presupuestos -->
                                    <tr *ngFor="let t of total" style="text-align:center; font-weight:900;">
                                        <!-- Se define con qué datos se llenará cada columna de la tabla -->
                                        <td>{{t.name}}</td>
                                        <td>{{t.totalProyecto.toFixed(2) | currency:'CAD':'$ '}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style="width: 45%;">
                            <h5 style="text-align:center; border-bottom: solid rgb(71, 71, 71) 1.5px;">Comparativa de
                                totales
                            </h5>
                            <canvas id="ProjectsChart"></canvas>
                        </div>
                    </div>

                    <div class="btnProject">
                        <button *ngFor="let p of projects" (click)="getTotalProject(p.idProject)">
                            {{p.name}}
                            <mat-icon>apartment</mat-icon>
                        </button>
                    </div>

                    <div class="infoProject" *ngIf="infoP.idProject!==''">
                        <p><b>PROYECTO:</b> {{infoP.name}}</p>
                        <p><b>TOTAL GASTADO:</b> {{infoP.TotalPagado.toFixed(2) | currency:'CAD':'$ '}} </p>
                        <p>Gráfica de presupuestos por proyecto: <button (click)="desgloceDatos()" class="btnDetail">
                                <mat-icon *ngIf="!projectDet">arrow_drop_down</mat-icon>
                                <mat-icon *ngIf="projectDet">arrow_drop_up</mat-icon>
                            </button></p>
                    </div>

                    <div [hidden]=!projectDet>
                        <canvas id="budgetChart"></canvas>
                    </div>
                    <div class="btnModule">
                        <button *ngFor="let m of modules" (click)="getTotalModule(m.idprojectdet)">
                            {{m.towernumber}}
                            <mat-icon>apartment</mat-icon>
                        </button>
                    </div>

                    <div class="infoModule" *ngIf="infoM.idprojectdet!==''">
                        <p><b>MODULO:</b> {{infoM.towernumber}}</p>
                        <p><b>TOTAL GASTADO:</b> {{infoM.TotalPagado.toFixed(2) | currency:'CAD':'$ '}} </p>
                        <p>Gráfica por módulos: <button (click)="desgloceDatosModule()" class="btnDetail">
                                <mat-icon *ngIf="!moduleDet">arrow_drop_down</mat-icon>
                                <mat-icon *ngIf="moduleDet">arrow_drop_up</mat-icon>
                            </button></p>
                    </div>

                    <div [hidden]=!moduleDet>
                        <canvas id="moduleChart"></canvas>
                    </div>

                    <div class="btnDeparture">
                        <button *ngFor="let d of departures" (click)="getTotalDeparture(d.departurenumber)">
                            {{d.departurenumber}}
                            <mat-icon>class</mat-icon>
                        </button>
                    </div>

                    <div class="infoDeparture" *ngIf="infoD.departurenumber!==''">
                        <p><b>PARTIDA:</b> {{infoD.departurenumber}}</p>
                        <p><b>TOTAL GASTADO:</b> {{infoD.totalPartida.toFixed(2) | currency:'CAD':'$ '}}</p>
                        <p>Gráfica por partidas: <button (click)="desgloceDatosDeparture()" class="btnDetail">
                                <mat-icon *ngIf="!departureDet">arrow_drop_down</mat-icon>
                                <mat-icon *ngIf="departureDet">arrow_drop_up</mat-icon>
                            </button></p>
                    </div>

                    <div [hidden]=!departureDet>
                        <canvas id="departureChart"></canvas>
                    </div>

                </mat-card-content>
            </mat-card>
        </div>
 