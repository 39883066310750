export class OrdinalNumber {

    numberToOrdinal(num: number) {
        let number = Math.round(num);

        return (this.convertHundredsToOrdinal(number));
    }

    private convertDigitToOrdinal(num: number) {
        switch (num) {
            case 1:
                return 'primero';
            case 2:
                return 'segundo';
            case 3:
                return 'tercero';
            case 4:
                return 'cuarto';
            case 5:
                return 'quinto';
            case 6:
                return 'sexto';
            case 7:
                return 'septimo';
            case 8:
                return 'octabo';
            case 9:
                return 'noveno';
            default:
                return '';
        }
    }

    private convertDozensToOrdinal(num: number) {
        var dozens = Math.floor(num / 10);
        var units = num - dozens * 10;

        switch (dozens) {
            case 1:
                switch (units) {
                    case 0:
                        return 'décimo';
                    case 1:
                        return 'undécimo';
                    case 2:
                        return 'duodécimo';
                    default:
                        return 'décimo' + this.convertDigitToOrdinal(units);
                }
            case 2:
                switch (units) {
                    case 0:
                        return 'vigésimo';
                    default:
                        return 'vigésimo' + this.convertDigitToOrdinal(units);
                }
            case 3:
                switch (units) {
                    case 0:
                        return 'trigésimo';
                    default:
                        return 'trigésimo' + this.convertDigitToOrdinal(units);
                }
            case 4:
                switch (units) {
                    case 0:
                        return 'cuadragésimo';
                    default:
                        return 'cuadragésimo' + this.convertDigitToOrdinal(units);
                }
            case 5:
                switch (units) {
                    case 0:
                        return 'quincuagésimo';
                    default:
                        return 'quincuagésimo' + this.convertDigitToOrdinal(units);
                }
            case 6:
                switch (units) {
                    case 0:
                        return 'sexagésimo';
                    default:
                        return 'sexagésimo' + this.convertDigitToOrdinal(units);
                }
            case 7:
                switch (units) {
                    case 0:
                        return 'septuagésimo';
                    default:
                        return 'septuagésimo' + this.convertDigitToOrdinal(units);
                }
            case 8:
                switch (units) {
                    case 0:
                        return 'octogésimo';
                    default:
                        return 'octogésimo' + this.convertDigitToOrdinal(units);
                }
            case 9:
                switch (units) {
                    case 0:
                        return 'nonagésimo';
                    default:
                        return 'nonagésimo' + this.convertDigitToOrdinal(units);
                }
            case 0:
                return this.convertDigitToOrdinal(units);
            default:
                return '';
        }
    }

    private convertHundredsToOrdinal(num: number) {
        var hundreds = Math.floor(num / 100);
        var dozens = num - hundreds * 100;

        switch (hundreds) {
            case 1:
                if (dozens > 0) {
                    return 'centesimo ' + this.convertDozensToOrdinal(dozens);
                }
                return 'centésimo';
            case 2:
                return 'duocéntesimo ' + this.convertDozensToOrdinal(dozens);
            case 3:
                return 'tricentésimo ' + this.convertDozensToOrdinal(dozens);
            case 4:
                return 'cuadringentésimo ' + this.convertDozensToOrdinal(dozens);
            case 5:
                return 'quingentésimo ' + this.convertDozensToOrdinal(dozens);
            case 6:
                return 'sexcentésimo ' + this.convertDozensToOrdinal(dozens);
            case 7:
                return 'septingentésimo ' + this.convertDozensToOrdinal(dozens);
            case 8:
                return 'octingentésimo ' + this.convertDozensToOrdinal(dozens);
            case 9:
                return 'noningentesimo ' + this.convertDozensToOrdinal(dozens);
            default:
                return this.convertDozensToOrdinal(dozens);
        }
    }
}