<div class="container-fluid mt-3">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">

            <div class="contenedor">
                <div class="leadsContainer">
                    <div class="leadsContainer__register">
                        <h1 class="subtitleText">REGISTRO DE PROSPECTOS</h1>

                        <form [formGroup]="leadsFormGroup" (ngSubmit)="insert()" style="width: 100%;"
                            onKeyPress="if(event.keyCode == 13) event.returnValue = false;">

                            <mat-form-field appearance="outline">
                                <mat-label>Interés</mat-label>
                                <mat-select [(value)]="selectProjects" formControlName="fk_interest" required>
                                    <mat-option *ngFor="let project of projects" [value]="project.idProject">
                                        {{project.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-icon matSuffix>map</mat-icon>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Teléfono</mat-label>
                                <input matInput type="text" formControlName="mobile">
                                <mat-icon matSuffix>numbers</mat-icon>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Correo</mat-label>
                                <input matInput type="text" formControlName="email">
                                <mat-icon matSuffix>numbers</mat-icon>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Nombre</mat-label>
                                <input matInput type="text" formControlName="name" required>
                                <mat-icon matSuffix>numbers</mat-icon>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Apellido Paterno</mat-label>
                                <input matInput type="text" formControlName="firstLastName">
                                <mat-icon matSuffix>numbers</mat-icon>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Apellido Materno</mat-label>
                                <input matInput type="text" formControlName="secondLastName">
                                <mat-icon matSuffix>numbers</mat-icon>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Notas</mat-label>
                                <input matInput type="text" formControlName="notes">
                                <mat-icon matSuffix>numbers</mat-icon>
                            </mat-form-field>

                            <button type="submit" class="m19Btn" [disabled]="!leadsFormGroup.valid">
                                Guardar <mat-icon>save</mat-icon>
                            </button>
                        </form>
                    </div>

                    <div class="leadsContainer__view">
                        <h1 class="subtitleText">ÚLTIMOS PROSPECTOS <button class="m19Btn" (click)="seeAll()">Ver
                                todos</button></h1>
                        <div class="leadsContainer__view__prospects">
                            <p *ngIf="prospects.length==0" style="text-align: center; font-size: 1.5rem;">Sin registros
                                <mat-icon>sentiment_dissatisfied</mat-icon>
                            </p>
                            <div *ngFor="let case of prospects"
                                [ngClass]="{'leadsContainer__view__prospects__case--assigned': case.secondResponsable != null, 'leadsContainer__view__prospects__case--unassigned': case.secondResponsable == null}">
                                <div (click)="details(case.idlead, case.secondResponsable)" style="cursor: pointer;">
                                    <p style="color: var(--subtitleTextColor);"><b
                                            style="color: var(--M19Primary);">Prospecto:</b> {{case.name}}
                                        {{case.firstLastName}}
                                        {{case.secondLastName}}</p>
                                    <p style="color: var(--subtitleTextColor);"><b
                                            style="color: var(--M19Primary);">Interesado
                                            en</b> {{case.interest}}</p>
                                    <p style="color: var(--subtitleTextColor);"><b>{{case.secondResponsable != null?
                                            'Asignado a ' + case.secondResponsable : 'Sin asignar'}}</b></p>
                                    <p style="color: var(--subtitleTextColor);"><b
                                            style="color: var(--M19Primary);">Registrado
                                            por</b> {{case.responsable}}</p>
                                    <p style="color: var(--subtitleTextColor);"><b style="color: var(--M19Primary);">El
                                            día
                                            {{pipe.transform(case.registrationdate, 'longDate')}}</b></p>
                                    <p style="color: var(--subtitleTextColor);"><b
                                            style="color: var(--M19PrimaryHover); font-weight: bolder;">El limite de
                                            atención es el
                                            día
                                            {{pipe.transform(case.limitAttention, 'longDate')}}</b></p>
                                </div>
                                <div style="display: flex; gap: 20px;">
                                    <button class="m19AltBtn action" (click)="addComment(case.idlead)"><span
                                            class="description">Comentar</span><mat-icon>forum</mat-icon></button>
                                    <button class="m19AltBtn action" (click)="seeTimeline(case.idlead)"><span
                                            class="description">Historia</span><mat-icon>timeline</mat-icon></button>
                                    <button class="m19AltBtn action" *ngIf="case.secondResponsable!=null"
                                        (click)="giveMoreTime(case)"><span
                                            class="description">Prórroga</span><mat-icon>timer</mat-icon></button>
                                    <button class="m19AltBtn action" *ngIf="case.secondResponsable!=null"
                                        (click)="reassignLead(case)"><span
                                            class="description">Reasignar</span><mat-icon>change_circle</mat-icon></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>