import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProjectDetail } from '../models/ProjectDetail';

@Injectable({
  providedIn: 'root'
})
export class ProjectdetailsService {

  constructor(private http: HttpClient) { }

  //Insertar un nuevo detalle de proyecto
  insertDetail(detail: ProjectDetail): Observable<any> {
    return this.http.post(`${environment.URLServer}/projectdetails/insert`,detail);
  }

  //Consultar todos los detalles de todos los proyectos
  selectAllDetails(): Observable<any> {
    return this.http.get(`${environment.URLServer}/projectdetails/getAllDetails`);
  }

  //Consultar todos los detalles de todos los proyectos
  selectDetailsById(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/projectdetails/getDetailById/${id}`);
  }
}