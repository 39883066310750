//=========================================================================================================
//Importación de componentes necesarios para esta pantalla
//=========================================================================================================
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { RecessionsService } from 'src/app/services/recessions.service';
import { LogosService } from 'src/app/services/logos.service';

import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-generate-recession-request',
  templateUrl: './generate-recession-request.component.html',
  styleUrls: ['./generate-recession-request.component.scss']
})
export class GenerateRecessionRequestComponent implements OnInit {

  properties: any = [{ idSale: 0, name: "", fk_property: "", totalValue: 0, fechaDato: "", customer: "", voterKey: "", mobile: "", coordinator: "", paid: 0, lastPay: 0 }];
  customer: string;
  voterKey: string;
  mobile: string;
  reasons: any = [];
  recessionReason: string = "Sin seleccionar";
  negociation: any = [];
  today = new Date();
  propList: any = [];
  totalDevolution: number = 0;

  constructor(
    private dialogRef: MatDialogRef<GenerateRecessionRequestComponent>,
    private recessionService: RecessionsService,
    //===================================================
    //Declaración de complementos visuales
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  //==========================================================================================================
  //Objeto constructor del formulario utilizado en la pantalla de requisición, se agrega el código
  //", Validators.required" para señalar que es un valor que no debe estar vacío
  //==========================================================================================================
  propertiesFormGroup: FormGroup = this._formBuilder.group({
    coordinator: [, Validators.required],
    customer: [],
    fechaDato: [],
    fkproperty: [, Validators.required],
    lastPay: [],
    fkrecessionReason: [, Validators.required],
    paid: [],
    totalValue: [, Validators.required],
    devolutionAmount: [, Validators.required]
  });

  ngOnInit(): void {
    this.getRecessionType();

    if (this.data.type == 'sales') {
      this.getUserToRecession();
    } else if (this.data.type == 'recessions') {
      this.getUserToRecessionPending();
    } else {
      Swal.fire({
        title: `Ocurrió un error notifique al área de sistemas`,
        icon: 'error',
        showConfirmButton: false,
        timer: 2000
      })
      this.dialogRef.close();
    }
  }

  getUserToRecession() {
    this.properties = [];
    this.recessionService.getCustomerBlockInfo(this.data.user, this.data.project).subscribe(
      res => {
        this.properties = res;
        this.customer = res[0].customer;
        this.voterKey = res[0].voterKey;
        this.mobile = res[0].mobile;
      }
    );
  }

  getUserToRecessionPending() {
    this.properties = [];
    this.recessionService.getCustomerBlockInfoR(this.data.user, this.data.project).subscribe(
      res => {
        this.properties = res;
        this.customer = res[0].customer;
        this.voterKey = res[0].voterKey;
        this.mobile = res[0].mobile;
      }
    );
  }

  getRecessionType() {
    this.recessionService.getRecessionsType().subscribe(
      res => {
        this.reasons = res;
      }
    );
  }

  selectProperty(obj: any) {
    this.propertiesFormGroup.controls['coordinator'].setValue(obj.coordinator);
    this.propertiesFormGroup.controls['customer'].setValue(obj.customer);
    this.propertiesFormGroup.controls['fechaDato'].setValue(obj.fechaDato);
    this.propertiesFormGroup.controls['lastPay'].setValue(obj.lastPay);
    this.propertiesFormGroup.controls['paid'].setValue(obj.paid);
    this.propertiesFormGroup.controls['totalValue'].setValue(obj.totalValue);
    this.propertiesFormGroup.controls['devolutionAmount'].setValue(obj.totalValue * 0.85);
    console.log(obj);
  }

  selectReason(obj: any) {
    this.recessionReason = obj.description;
    console.log(obj);
  }

  addProperty() {
    if (this.propertiesFormGroup.controls['fkproperty'].value == "" || this.propertiesFormGroup.controls['fkproperty'].value == null
      || this.propertiesFormGroup.controls['totalValue'].value == "" || this.propertiesFormGroup.controls['totalValue'].value == null
      || this.propertiesFormGroup.controls['devolutionAmount'].value == "" || this.propertiesFormGroup.controls['devolutionAmount'].value == null) {
      this.sweet.AlertTime("center", "error", "Por favor complete los campos de inmueble y devolución correctamente.", false, 2000);
    } else {
      let addProperty = this.propertiesFormGroup.value;

      if (this.propList.find((item) => item.fkproperty == this.propertiesFormGroup.controls['fkproperty'].value.fk_property) != undefined) {
        this.sweet.AlertTime("center", "error", `La unidad ${this.propertiesFormGroup.controls['fkproperty'].value.fk_property} ya está en la lista de la solicitud.`, false, 2000);
        return;
      }

      if (addProperty != '') {
        try {
          this.propList.push({
            "fkproperty": addProperty.fkproperty.fk_property,
            "paid": addProperty.paid,
            "lastPay": addProperty.lastPay,
            "totalValue": addProperty.totalValue,
            "devolutionAmount": addProperty.devolutionAmount
          });

          this.totalDevolution = this.totalDevolution + addProperty.devolutionAmount;

          this.propertiesFormGroup.controls['fkproperty'].setValue('');
          this.propertiesFormGroup.controls['totalValue'].setValue('');
          this.propertiesFormGroup.controls['devolutionAmount'].setValue('');

        } catch {
          this.sweet.AlertTime("center", "error", "Se detectó un error en el formulario de propiedades.", false, 2000);
        }
      }
    }
  }

  print(): void {
    let neg = {

    };

    this.recessionService.makeNegotiation(neg).subscribe(
      res => {

      }
    );

    let printContents, popupWin;
    printContents = document.getElementById('recessionRequest')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
      <html>
        <head>
          <title>Solicitud de rescisión: ${this.customer}</title>
          <style>
          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
          }

          body {
            font-family: Roboto, "Helvetica Neue", sans-serif;
          }

          .requestContainer {
            width: 100%;
          }

          .recessionHeader {
              display: flex;
              justify-content: space-around;
              align-items: center;
              width: 100%;
              font-size: 1.5rem;
              border-radius: 15px 15px 0 0;
              border: solid #D33E3E 2px;
              padding: 10px 0 10px 0;
          }

          .recessionHeader img {
              width: 10%;
              border: none;
          }

          .recessionData {
              display: flex;
              justify-content: space-between;
              padding: 10px;
          }

          .customerData {
              display: flex;
              flex-direction: column;
              align-items: center;
              border: solid #D33E3E 2px;
          }

          .personalInfo {
              display: flex;
              justify-content: space-between;
              width: 100%;
              font-size: 0.8rem;
              padding: 10px;
          }

          .propertiesContent {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              margin-top: 15px;
              border: solid #D33E3E 1px;
          }

          .unitsContainer {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
          }

          .propertyUnit {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 50%;
              gap: 8px;
              font-size: 0.8rem;
          }

          .propertyUnit .identify{
              background-color: #5E4F4F;
              color: white;
              width: 100%;
              text-align: center;
              border-right: solid #5E4F4F 1px;
          }

          h5 {
              width: 100%;
              background-color: #D33E3E;
              color: white;
              font-size: 1rem;
              text-align: center;
              padding: 10px 0 10px 0;
              margin-bottom: 0;
          }

          .signamentContent {
              display: grid;
              justify-content: space-between;
              grid-template-columns: 48% 48%;
              grid-row-gap: 100px;
              margin-top: 100px;
          }

          .signamentContent p {
              text-align: center;
              border-top: solid #5E4F4F 2px;
              font-size: 0.9rem;
          }

          .reasonContent {
            width: 100%;
            background-color: #5E4F4F;
            color: white;
            text-align: center;
            font-weight: bold;
            padding: 10px;
          }
          </style>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin!.document.close();
  }

}
