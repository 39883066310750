<mat-dialog-content>
    <html>
    <div *ngIf="productsOfConsumerToken!=null">
        <table style="width: 100%;">
            <thead style="text-align: center; font-size: 1.5rem;">
                <tr>
                    <th colspan="3" style="border-bottom: solid black;">RESÚMEN DE VALE DE CONSUMO</th>
                </tr>
            </thead>
            <tbody>
                <tr style="height: 50px; text-align: center;">
                    <td class="datos" style="font-weight: bold;">FOLIO: No. {{consumerToken[0].folio}}.</td>
                    <td></td>
                    <td class="datos" >FECHA: {{pipe.transform(consumerToken[0].tokenDate,'longDate')}}.</td>
                </tr>
                <tr style="text-align: center; ">
                    <td class="datos">OBRA: {{consumerToken[0].projectname}}.</td>
                    <td class="datos">MODULO: {{consumerToken[0].towernumber}}.</td>
                    <td class="datos">AREA: {{consumerToken[0].requiredArea}}.</td>
                </tr>
                <tr><td colspan="3" style="border-bottom: solid gray;"> <br> </td></tr>
            </tbody>
        </table>
        <table style="width: 100%;">
            <tbody>
                <tr style="height: 50px;">
                    <td colspan="2" class="datos">{{consumerToken[0].empleado}}.</td>
                    <td rowspan="2" style="text-align: right;" class="datos" [hidden]="!this.ModifyTdFlag">Motivo: {{consumerToken[0].noteMod}}.</td>
                </tr>
                <tr>
                    <td colspan="2" style="text-align: left;" class="datos">{{consumerTokenAudit[0].empleadoMod}}.</td>
                </tr>
            </tbody>
        </table>

        <table class="table table-striped mt-3 animated fadeIn faster">
            <thead class="thead-m19">
                <tr style="text-align: center;">
                    <th scope="col">Producto</th>
                    <th scope="col">Unidad</th>
                    <th scope="col">Cantidad</th>
                </tr>
            </thead>
            <tbody>
                <tr style="text-align: center;" *ngFor="let product of productsOfConsumerToken">
                    <td> {{product.producto}}</td>
                    <td> {{product.unit}}</td>
                    <td> {{product.amount}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    </html>
</mat-dialog-content>

<h3 *ngIf="productsOfConsumerToken==null">{{"Si lee esto, por favor contacte al desarrolador"}}.</h3>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>