<h1>{{data.idEmployee}}. {{data.name}} {{data.firstLastName}} {{data.secondLastName}}</h1>

<mat-divider></mat-divider>

<mat-dialog-content>
    <form class="row mt-3" [formGroup]="employeeFormGroup">

        <h5 class="col-12 pb-2">Información general</h5>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Nombre(s)</mat-label>
            <input matInput type="text" formControlName="name" [(ngModel)]="employee.name" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Apellido paterno</mat-label>
            <input matInput type="text" formControlName="firstLastName" [(ngModel)]="employee.firstLastName" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Apellido materno</mat-label>
            <input matInput type="text" formControlName="secondLastName" [(ngModel)]="employee.secondLastName" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>Teléfono fijo</mat-label>
            <input matInput type="tel" formControlName="telephone" [(ngModel)]="employee.telephone">
            <mat-icon matSuffix>call</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>Celular</mat-label>
            <input matInput type="tel" formControlName="mobile" [(ngModel)]="employee.mobile" required>
            <mat-icon matSuffix>phone_iphone</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Correo electrónico</mat-label>
            <input matInput type="email" formControlName="email" [(ngModel)]="employee.email" required>
            <mat-icon matSuffix>email</mat-icon>
        </mat-form-field>

        <h5 class="col-12 pb-2">Domicilio</h5>

        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Dirección</mat-label>
            <input matInput type="text" formControlName="address" [(ngModel)]="employee.address" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Colonia o localidad</mat-label>
            <input matInput type="text" formControlName="localidad" [(ngModel)]="employee.localidad" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Municipio</mat-label>
            <input matInput type="text" formControlName="municipality" [(ngModel)]="employee.municipality" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Estado</mat-label>
            <input matInput type="text" formControlName="state" [(ngModel)]="employee.state" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>Código postal</mat-label>
            <input matInput type="number" formControlName="postalCode" [(ngModel)]="employee.postalCode" required>
        </mat-form-field>

        <h5 class="col-12 pb-2">Más detalles</h5>

        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>Estatus</mat-label>
            <mat-select formControlName="estatus" [(ngModel)]="employee.estatus" required>
                <mat-option [value]="1">Activo</mat-option>
                <mat-option [value]="0">Inactivo</mat-option>
            </mat-select>
        </mat-form-field>
        
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
    <button mat-button class="btn btn-m19" (click)="updateUser(employee.idEmployee,employee)">Guardar</button>
</mat-dialog-actions>