import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { DatePipe } from '@angular/common';

import decode from 'jwt-decode';
import { FocusTrap } from '@angular/cdk/a11y';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  userInfo: any = {};

  pipe = new DatePipe('es-MX');

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.decode = decode(localStorage.getItem("token") || "");
    this.getUserInfo(this.decode.fkuser);

    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
  }

  async getUserInfo(id: any) {
    if (this.decode.fk_typeUser !== 3) {
      this.userService.getEmployeeInfo(id).subscribe(
        res => {
          this.userInfo = res;
          console.log(this.userInfo);
        }
      );
    } else {
      this.userService.getUserInfo(id).subscribe(
        res => {
          this.userInfo = res;
        }
      );
    }
  }
}