<div>
    <h1>{{data.codeProperty}}</h1>
    <mat-dialog-content>
        <table class="table table-striped mt-3 animated fadeIn faster">
            <tr>
                <th>No. Venta</th>
                <td>{{data.idSale}}</td>
            </tr>
            <tr *ngIf="data.idContract != null">
                <th>Código de contrato</th>
                <td>{{data.idContract}}</td>
            </tr>
            <tr>
                <th>Cliente</th>
                <td>{{data.customer}}</td>
            </tr>
            <tr>
                <th>Vendedor</th>
                <td>{{data.seller}}</td>
            </tr>
            <tr>
                <th>Fecha de contrato</th>
                <td>{{data.fechaDato | date:'dd-MMM-yyyy'}}</td>
            </tr>
            <tr>
                <th>Valor total</th>
                <td>{{data.amount | currency:'CAD':'$ '}}</td>
            </tr>
            <tr>
                <th>Anticipo</th>
                <td>{{data.advancePayment | currency:'CAD':'$ '}}</td>
            </tr>
            <tr>
                <th>Mensualidad</th>
                <td>{{data.monthlyPayment | currency:'CAD':'$ '}}</td>
            </tr>
            <tr>
                <th>Saldo abonado</th>
                <td>{{data.saldo | currency:'CAD':'$ '}}</td>
            </tr>
        </table>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cerrar</button>
    </mat-dialog-actions>

</div>