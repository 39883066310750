<div class="col-12 text-right mt-3">
  <button (click)="ticketGenerator('2022/12/23','Aletz','e2345', 'M', 'L','14','14',
    'Compra de lote','Efectivo',3000,1500,1500,'1',
    '2','XL','Diego','El Jerry',0,0, 123000,'e e e e e e','e e e e e e')">
    Generar de ticket static </button>
</div>

<br>

<div class="col-12 text-right mt-3">
  <button (click)="investmentTicket()">
    Generar ticket inversion</button>
</div>

<br>

<div class="col-12 text-right mt-3">
  <button (click)="ticketNCGenerator('21/10/1998','Silvia Sanchez Aguas','EC98', 'T', 'D','25','21',
    'Mensualidad','Nota de crédito','5000','XL','Diego Asael Rubio Hernandez', 'Iveth Tadeo Dena', '10000', '2000')">
    Generar de ticket NC </button>
</div>

<br>

<div class="col-12 text-right mt-3">
  <button (click)="ticketGeneratorComissiones('13/05/2022' , '16401252' , 'Valle de las palomas 2' , '09' , '09' , 'Luis Gerardo Huizar Martinez' ,
                                                'Aura Citlalli Avila Lara' , '10000' , 'Anticipo' )">
    Tickets pago de comisiones </button>
</div>

<div class="col-12 text-right mt-3">
    <button (click)= "ticketGeneradorCashOutFlows('10','04-02-2022', 'Diego Asael Rubio Hernandez', '85000' , 'Carlos Betancourt Rodriguez', 'Solicito dinero para pagarle todo lo que se le debe al super residente Gerardo Huizar  ♥', '18-08-2022')">
                                                 Ticket salida de efectivo  </button>
</div>
