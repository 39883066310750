import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { EditPieceworkComponent } from './edit-piecework/edit-piecework.component';
import { PieceworkService } from 'src/app/services/piecework.service';
import Swal from 'sweetalert2';
import decode from 'jwt-decode';

@Component({
  selector: 'app-pieceworkcontrol',
  templateUrl: './pieceworkcontrol.component.html',
  styleUrls: ['./pieceworkcontrol.component.scss']
})
export class PieceworkcontrolComponent implements OnInit {
  //Objeto de formulario de destajos
  pieceworkFormGroup: FormGroup = this._formBuilder.group({
    idpiecework_catalog: [],
    description: ['', Validators.required],
    unit: ['', Validators.required],
    cost: ['', Validators.required],
    creationdate: [],
    modificationdate:[],
    useremail: [this.getUser()],
    idstatus: []
  });

  //Objeto que almacena todos los destajos registrados en la BD
  pieceworks: any = [];

  decode: any = {};               //Datos de acceso

  constructor(
    public pieceworkService: PieceworkService,
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    public dialog: MatDialog,
    private router: Router) { }

  ngOnInit(): void {
    //Se obtienen todos los desatajos
    this.selectPiecework();
    this.insertPiecework();
  }

  async selectPiecework() {
    this.pieceworkService.getPiecework_catalog().subscribe(
      res => {
        this.pieceworks = res;
      }
    );
  }

  //=======================================================================================
  //Función para registrar un destajo nuevo en la BD
  //=======================================================================================
  async insertPiecework() {
    if (this.pieceworkFormGroup.invalid) {
      return;
    } else {
      this.pieceworkService.insertPieceworkCatalog(this.pieceworkFormGroup.value).subscribe(
        res => {
          this.sweet.AlertTime("center", "success", "Destajo registrado con éxito", false, 2000);
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['PieceworkControl']));
        },
        err => {
          console.error(err);
        }
      );
    }
  }

  async editPiecework(id: Number) {
    let dialogRef = this.dialog.open(EditPieceworkComponent, {
      width: '500px',
      data: id
    });

    dialogRef.afterClosed().subscribe(
      res => {
        this.selectPiecework();
      }
    );
  }

  //=======================================================================================
  //Función para borrar un destajo de la BD
  //=======================================================================================
  async deletePiecework(id: any) {
    id.useremail=this.getUser();
    Swal.fire({
      title: '¿Eliminar '+id.description+'?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, no eliminar',
      confirmButtonText: 'Sí, eliminar!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'Este destajo fue eliminado del catálogo.',
          'success'
        )
        this.pieceworkService.setDisabledPiecework(id).subscribe(
          res => {
            this.selectPiecework();
          }
        );
      }
    })
  }
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.email
  }
}
