import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Property } from '../../../models/Property';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PropertyService } from '../../../services/property.service';
import { ProjectService } from '../../../services/project.service';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';

@Component({
  selector: 'app-dialog-property-form',
  templateUrl: './dialog-property-form.component.html',
  styleUrls: ['./dialog-property-form.component.scss']
})
export class DialogPropertyFormComponent implements OnInit {

  property: any = [];
  properties: any = [];
  status: any = [];

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  propertyFormGroup: FormGroup = this._formBuilder.group({

    amount: [, [Validators.min(0)]],
    advancePayment: [, [Validators.min(0)]],
    monthlyPayment: [, [Validators.min(0)]],
    area: [, [Validators.min(0)]],
    coordinateX: [''],
    coordinateY: [''],
    northeasBorder: ['', [Validators.max(100)]],
    southwestBorder: ['', [Validators.max(100)]],
    northwestBorder: ['', [Validators.max(100)]],
    southeastBorder: ['', [Validators.max(100)]],
    fk_status: [1],
    description: ['']
  });

  constructor(
    private dialogRef: MatDialogRef<DialogPropertyFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Property,
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    private propertyService: PropertyService,
    private projectService: ProjectService
  ) {
    this.dialogRef.disableClose = true;
    this.property = data;
  }

  ngOnInit(): void {
    this.selectStatus();
    this.getUser();
  }

  //Obtener estatus de inmuebles (combo)
  selectStatus() {
    this.projectService.select_status().subscribe(
      res => {
        this.status = res;
      },
      err => console.log(err)
    );
  }

  //Consultar los inmuebles del proyecto especificado
  selectPropertiesProject(fk_project: string) {
    this.propertyService.selectPropertiesProject(fk_project).subscribe(
      res => {
        this.properties = res;
      },
      err => console.log(err)
    );
  }

  //Actualizar información del inmueble
  updateProperty(codeProperty: string, property: any) {
    if (this.propertyFormGroup.invalid) {
      return;
    }
    else {
      property = this.propertyFormGroup.value
      this.propertyService.updateProperty(codeProperty, property).subscribe(
        res => {
          this.sweet.AlertTime("center", "success", "Inmueble modificado con éxito", false, 2000);
          this.dialogRef.close();
        },
        err => {
          console.error(err);
          this.sweet.AlertTime("center", "error", "Inmueble no modificado", false, 2000);
        }
      );
    }
  }

  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.fkuser
  }

}
