import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/services/report.service';
import { TotalPagadosService } from 'src/app/services/totalPagados.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  constructor(private reportServ: ReportService,private totalPagados: TotalPagadosService) { }

  ngOnInit(): void {
  }
  keyword = 'name';  

  sal: any=[];  
  folioApr:any=[];
  folioCa:any=[];
  codigoInm=""
  folioCli=""
  folio:any=""
  //Manejo de folios
  folios: any = [];

  cadenaInmueble:string="";



  selectFolios(folio:any) {
    this.totalPagados.selectFolio(folio).subscribe(
      res => {
        
        this.folios = res;
      },
      err => console.log(err)
    );
  }
   onKeypressEventFolio(eventF: any){
      
    if(eventF.target.value==' '||eventF.target.value==null||eventF.target.value==""){
      console.log("Se requiere consultar cliente")
    }else{this.codigoInm=eventF.target.value    
    this.selectFolios(this.codigoInm=eventF.target.value );    
    }
  }

}