<mat-card>
    <mat-card-content>
        <form class="row mt-3" [formGroup]="productsFormGroup" (ngSubmit)="insertProduct()">

            <h5 class="col-12 pb-2">Nuevo material</h5>

            <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Nombre</mat-label>
                <input matInput type="text" formControlName="productname" required>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Unidad de medida</mat-label>
                <input matInput type="text" formControlName="unit" required>
            </mat-form-field>

            <div class="col-12 text-right mt-3">
                <button class="btn btn-m19" type="submit" [disabled]="!productsFormGroup.valid">
                    <mat-icon>save</mat-icon>
                </button>
            </div>

        </form>
    </mat-card-content>
</mat-card>