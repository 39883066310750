import { Component, OnInit } from '@angular/core';
import { Chart, ChartOptions } from 'chart.js';
import { MatDialog } from '@angular/material/dialog';

//Importación de servicios
import { ProjectService } from 'src/app/services/project.service';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';
import { BudgetService } from '../../../services/budget.service';
import { BudgetPieceworkService } from 'src/app/services/budget-piecework.service';

@Component({
  selector: 'app-budgetreport-piece',
  templateUrl: './budgetreport-piece.component.html',
  styleUrls: ['./budgetreport-piece.component.scss']
})
export class BudgetreportPieceComponent implements OnInit {
  //------------------------VARIABLES PARA EL REPORTE DE PRESUPUESTO DE DESTAJOS-----------------------------
  //Objeto para almacenar el total
  totalpiece: any = [];
  //Objeto para almacenar los presupuestos
  budgetspiece: any = [];
  //Arreglos para almacenar datos traídos de la BD
  //Objeto para almacenar los proyectos
  projectspiece: any = [];
  //Objeto para almacenar los modulos
  modulespiece: any = [];
  //Objeto para almacenar un modulo 
  modulepiece: any = [];
  //Objeto para almacenar las partidas
  departurespiece: any = [];
  //Objeto para almacenar el total
    total: any = [];

  //Arreglos para valores de configuración del gráfico
  pvaluesOfP: any = [];
  plabelsOfP: any = [];
  pcolorsOfP: any = [];

  //Arreglo de los datos de pago por proyecto
  infoPp: any = { idProject: '', name: '', TotalPagado: '', Excedente: '' };

  //Arreglo de los datos de pago por modulo
  infoMp: any = { idprojectdet: '', towernumber: '', TotalPagado: '', Excedente: '' };

  //Arreglo de los datos de pago por partida
  infoDp: any = { iddeparture: '', departurenumber: '', nombrePartida: '', totalPartida: '' };

  //Banderas para saber si se debe desplegar la gráfica o no
  projectDetp: any = false;
  moduleDetp: any = false;
  departureDetp: any = false;


  constructor(
    //Declaración de servicios utilizados
    private projectService: ProjectService,
    private projectdetailService: ProjectdetailsService,
    private budgetService: BudgetService,
    private budgetpieceworkService: BudgetPieceworkService,

    //======================================================================
    //Declaración del componente gráfico utilizado para cuadro de dialogo
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    //-----------------------------REPORTE DE PRESUPUESTO DE DESTAJOS---------------------------------------//
    this.getTotalProjectsPiece();
    this.getProjectsPiece();
    this.getTotalProjects();
  }
  //-----------------------------REPORTE DE PRESUPUESTO DE DESTAJOS---------------------------------------//
  //*******************************Gráfico general de proyectos***********************************/
  //Función de configuración del gráfico
  async chartConfigProjectsPiece() {
    //Referencia al objeto canva HTML al que se carga el gráfico
    const budgetCanvas = document.getElementById('ProjectsChart') as HTMLCanvasElement;

    //Reseteo de arreglos de atributos
    this.pvaluesOfP = [];
    this.plabelsOfP = [];
    this.pcolorsOfP = [];

    //Configuración de fuente de la gráfica
    Chart.defaults.global.defaultFontFamily = "Arial";
    Chart.defaults.global.defaultFontSize = 14;

    //Recuperación de los atributos para la tabla
    for (let t of this.total) {
      this.pvaluesOfP.push(t.totalProyecto);
      this.plabelsOfP.push(t.name);
      this.pcolorsOfP.push('rgb(255, 100, 100)');
    }

    //Asignación de información
    const budgetData = {
      labels: this.plabelsOfP,
      datasets: [{
        label: "Gasto de presupuesto por proyectos",
        data: this.pvaluesOfP,
        backgroundColor: this.pcolorsOfP
      }]
    };

    //Configuración básica de gráfico
    const chartOptions: ChartOptions = {
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxWidth: 80,
          fontColor: 'black'
        }
      }
    };

    //Generación del gráfico
    const lineChart = new Chart(budgetCanvas, {
      type: 'doughnut',
      data: budgetData,
      options: chartOptions
    });
  }

  //Función de configuración del gráfico
  async chartConfigPiece() {
    //Referencia al objeto canva HTML al que se carga el gráfico
    const budgetCanvas = document.getElementById('budgetChartPiece') as HTMLCanvasElement;

    //Reseteo de arreglos de atributos
    this.pvaluesOfP = [];
    this.plabelsOfP = [];
    this.pcolorsOfP = [];

    //Configuración de fuente de la gráfica
    Chart.defaults.global.defaultFontFamily = "Arial";
    Chart.defaults.global.defaultFontSize = 14;

    //Recuperación de los atributos para la tabla
    for (let b of this.budgetspiece) {
      this.pvaluesOfP.push(b.TotalPagado);
      this.plabelsOfP.push("Presupuesto número: " + b.idbudgetpiece);
      this.pcolorsOfP.push('rgb(211, 62, 62)');
    }

    //Asignación de información
    const budgetData = {
      labels: this.plabelsOfP,
      datasets: [{
        label: "Gasto por presupuesto",
        data: this.pvaluesOfP,
        backgroundColor: this.pcolorsOfP
      }]
    };

    //Configuración básica de gráfico
    const chartOptions: ChartOptions = {
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxWidth: 80,
          fontColor: 'black'
        }
      }
    };

    //Generación del gráfico
    const lineChart = new Chart(budgetCanvas, {
      type: 'bar',
      data: budgetData,
      options: chartOptions
    });
  }

  //****************************Segundo gráfico***********************************/
  //Función de configuración del gráfico
  async chartConfigModulePiece() {
    //Referencia al objeto canva HTML al que se carga el gráfico
    const budgetCanvas = document.getElementById('moduleChart') as HTMLCanvasElement;

    //Reseteo de arreglos de atributos
    this.pvaluesOfP = [];
    this.plabelsOfP = [];
    this.pcolorsOfP = [];

    //Configuración de fuente de la gráfica
    Chart.defaults.global.defaultFontFamily = "Arial";
    Chart.defaults.global.defaultFontSize = 14;

    //Recuperación de los atributos para la tabla
    for (let m of this.modulepiece) {
      this.pvaluesOfP.push(m.TotalPagado);
      this.plabelsOfP.push("Módulo " + m.towernumber);
      this.pcolorsOfP.push('rgb(211, 62, 62)');
    }

    //Asignación de información
    const budgetData = {
      labels: this.plabelsOfP,
      datasets: [{
        label: "Gasto de presupuesto por módulos",
        data: this.pvaluesOfP,
        backgroundColor: this.pcolorsOfP
      }]
    };

    //Configuración básica de gráfico
    const chartOptions: ChartOptions = {
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxWidth: 80,
          fontColor: 'black'
        }
      }
    };

    //Generación del gráfico
    const lineChart = new Chart(budgetCanvas, {
      type: 'bar',
      data: budgetData,
      options: chartOptions
    });
  }

  //****************************Tercer gráfico***********************************/
  //Función de configuración del gráfico
  async chartConfigDeparturePiece() {
    //Referencia al objeto canva HTML al que se carga el gráfico
    const budgetCanvas = document.getElementById('departureChart') as HTMLCanvasElement;

    //Reseteo de arreglos de atributos
    this.pvaluesOfP = [];
    this.plabelsOfP = [];
    this.pcolorsOfP = [];

    //Configuración de fuente de la gráfica
    Chart.defaults.global.defaultFontFamily = "Arial";
    Chart.defaults.global.defaultFontSize = 14;

    //Recuperación de los atributos para la tabla
    for (let d of this.departurespiece) {
      this.pvaluesOfP.push(d.totalPartida);
      this.plabelsOfP.push(d.nombrePartida);
      this.pcolorsOfP.push('rgb(211, 62, 62)');
    }

    //Asignación de información
    const budgetData = {
      labels: this.plabelsOfP,
      datasets: [{
        label: "Gasto de presupuesto por partida",
        data: this.pvaluesOfP,
        backgroundColor: this.pcolorsOfP
      }]
    };

    //Configuración básica de gráfico
    const chartOptions: ChartOptions = {
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxWidth: 80,
          fontColor: 'black'
        }
      }
    };

    //Generación del gráfico
    const lineChart = new Chart(budgetCanvas, {
      type: 'bar',
      data: budgetData,
      options: chartOptions
    });
  }

  //A-1 Función para traer el catálogo de proyectos registrados en la BD
  async getProjectsPiece() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projectspiece = res;
      }
    );
  }

  //A-2 Función para traer los totales de gastos por proyecto
  async getTotalProjectPiece(id: number) {
    this.projectDetp = false;
    this.modulespiece = [];
    this.departurespiece = [];
    this.infoMp = { idprojectdet: '', towernumber: '', TotalPagado: '', Excedente: '' };
    this.infoDp = { iddeparture: '', departurenumber: '', nombrePartida: '', totalPartida: '' };
    this.budgetpieceworkService.getTotalProjectPiece(id).subscribe(
      res => {
        if (res === null) {
          this.infoPp = { idProject: '', name: '', TotalPagado: '', Excedente: '' };
          return;
        }
        this.infoPp = res;
        this.getModules(this.infoPp.idProject);
        this.getModulePiece(this.infoPp.idProject);
        this.getDeparturePiece(this.infoPp.idProject);
        this.getTotalProjectByBudgetPiece(this.infoPp.idProject);
      }
    );
  }

  //B-1 Función para traer los totales desglozados por presupuesto
  async getTotalProjectByBudgetPiece(id: number) {
    this.budgetpieceworkService.getTotalProjectBudget(id).subscribe(
      res => {
        this.budgetspiece = res;
      }
    );
  }

  //D-2 Función para traer los totales de gasto por modulo
  async getTotalModulePiece(id: number) {
    this.budgetpieceworkService.getTotalModule(id).subscribe(
      res => {
        if (res === null) {
          this.infoMp = { idprojectdet: '', towernumber: '', TotalPagado: '', Excedente: '' };
          return;
        }
        this.infoMp = res;
      }
    );
  }

  //C-1 Función para traer los totales de partida
  async getTotalDeparturePiece(id: number) {
    this.budgetService.getTotalDeparture(id).subscribe(
      res => {
        if (res === null) {
          this.infoDp = { iddeparture: '', departurenumber: '', nombrePartida: '', totalPartida: '' };
          return;
        }
        this.infoDp = res;
      }
    );
  }

  //C-1 Función para traer los totales de partida
  async getModulePiece(id: number) {
    this.budgetpieceworkService.getModule(id).subscribe(
      res => {
        this.modulepiece = res;
      }
    );
  }

  //D-1 Función para traer las partidas de determinado proyecto
  async getDeparturePiece(id: number) {
    this.budgetpieceworkService.getDeparture(id).subscribe(
      res => {
        this.departurespiece = res;
      }
    );
  }

  //Función para alternar entre desplegar o no la gráfica
  async desgloceDatosPiece() {
    this.projectDetp = !this.projectDetp;
    if (this.projectDetp) {
      this.chartConfigPiece();
    }
  }

  //Función para alternar entre desplegar o no la gráfica
  async desgloceDatosModulePiece() {
    this.moduleDetp = !this.moduleDetp;
    if (this.moduleDetp) {
      this.chartConfigModulePiece();
    }
  }

  //Función para alternar entre desplegar o no la gráfica
  async desgloceDatosDeparturePiece() {
    this.departureDetp = !this.departureDetp;
    if (this.departureDetp) {
      this.chartConfigDeparturePiece();
    }
  }

  //A-1 Función para traer el catálogo de proyectos registrados en la BD
  async getTotalProjectsPiece() {
    this.budgetpieceworkService.getTotalProjectsPiece().subscribe(
      res => {
        this.totalpiece = res;
        console.log(this.totalpiece);
        this.chartConfigProjectsPiece();
      }
    );
  }

  //D-1 Función para traer los modulos de determinado proyecto
  async getModules(id: number) {
    this.projectdetailService.selectDetailsById(id).subscribe(
      res => {
        this.modulespiece = res;
      }
    );
  }

  //A-1 Función para traer el catálogo de proyectos registrados en la BD
  async getTotalProjects() {
    this.budgetpieceworkService.getTotalProjects().subscribe(
      res => {
        this.total = res;
        console.log(this.total);
        this.chartConfigProjectsPiece();
      }
    );
  }

}
