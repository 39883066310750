import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, _closeDialogVia } from '@angular/material/dialog';
import { MapsService } from 'src/app/services/maps.service';
import { Pointofmap } from '../pointofmap';
import decode from 'jwt-decode'
import { loader } from 'src/app/ui/loader/loader.model';

@Component({
  selector: 'app-dialog-maps-select',
  templateUrl: './dialog-maps-select.component.html',
  styleUrls: ['./dialog-maps-select.component.scss']
})
export class DialogMapsSelectComponent implements OnInit {

  public projects: any = []
  public nameProject: any = ""
  selected_project = { name: '', nomenclature: '' };
  linkMap = "";
  public pointes: Array<Pointofmap> = [];
  user: string = "";

  loader: loader = {
    message: "Cargando Proyectos..."
  }

  loading = 0;

  sellerInfo: any = [];

  constructor(

    private dialogRef: MatDialogRef<DialogMapsSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_DIALOG_DATA) public pointe: Array<Pointofmap>,


    private getProjects: MapsService

  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.getProject();
    this.getUser();
  }

  //consultar todos los poryectos
  getProject() {
    this.loading = 1;
    this.getProjects.getSellerData(this.getUser()).subscribe(
      res => {
        this.sellerInfo = res;
        this.getProjects.selectAllProjects().subscribe(
          res2 => {
            this.projects = res2;
            this.loading = 0;
          },
          err => console.log(err)
        )
      }
    );
  }

  getUser() {
    const token = localStorage.getItem('token') || "";
    let decodetoken: any = {};
    decodetoken = decode(token);
    this.user = decodetoken.cliente;

    return decodetoken.fkuser;
  }

  setProject() {
    localStorage.setItem('selectedName', this.selected_project.name);
    localStorage.setItem('selectednomenclature', this.selected_project.nomenclature);

    this.selectLink(localStorage.getItem('selectedName') || "")
    this.selectPointOfMap(localStorage.getItem('selectednomenclature') || "")
  }

  //SELECCIONAR UN LINK EN ESPECIAL
  selectLink(id: string) {
    this.getProjects.selectLink(id).subscribe(
      res => {
        this.linkMap = res.imgMap;
        localStorage.setItem('linkMap', this.linkMap)
      },
      err => console.log(err)
    );
  }

  //SELECCIONAR UN LINK EN ESPECIAL
  selectPointOfMap(id: string) {
    this.getProjects.selectPointOfMap(id).subscribe(
      res => {
        this.pointes = res;
        this.pointe = this.pointes
        localStorage.setItem('PointsOfMap', JSON.stringify(this.pointes))
      },
      err => console.log(err)
    );
  }
}