import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../../../services/payment.service';
import { ReportService } from '../../../services/report.service';
import { LogosService } from 'src/app/services/logos.service';
import { RecessionsService } from 'src/app/services/recessions.service';
import { BlockLogService } from 'src/app/services/block-log.service';
import { AdministrationService } from 'src/app/services/administration.service';

import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { DatePipe } from '@angular/common';
import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';
import Swal from 'sweetalert2';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import decode from 'jwt-decode';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-customer-status',
  templateUrl: './customer-status.component.html',
  styleUrls: ['./customer-status.component.scss']
})
export class CustomerStatusComponent implements OnInit {

  bannerTitle: BannerTitle = {
    title: "Consulta de clientes",
    icon: "support_agent",
    position: "center",
    background: "var(--M19Secundary)"
  }
  decode: any = [];

  pipe = new DatePipe('es-MX');
  fechaHoy = new Date();

  vista = true;

  codeProperty = ""
  status = "";

  keyword = 'name';
  keywordR = 'name';

  customers: any = [];
  customersR: any = [];
  saleInfo: any = '';

  anticipoC: any = 0;
  mensualidadC: any = 0;
  anticipoNR: any = 0;
  mensualidadNR: any = 0;

  payments: any = [];
  creditPayments: any = [];

  logos: any = [];
  event_reload: any;

  catalogoRecision: any = {};
  user: any = {};
  custmerName: string;

  public _getAllSalesCatalog: any = [];

  blockButton: any = {//Información que rellena las propiedades el botón según si se puede bloquear o desbloquear un cliente
    disabled: true,
    class: 'm19Btn',//Rojo bloqueo; #02bd54//Verde aprobado
    text: 'Bloquear cliente',
    icon: 'lock',
    show: false
  }

  blockLog: any = {
    fk_idUser: null, //ID del cliente
    fk_property: null, //Propiedad
    fk_blockReasonOld: null,//Motivo de bloqueo del cliente
    fk_blockReasonNew: null,//Motivo de bloqueo del cliente
    fk_idstatusOld: null,//Estado de inmueble anterior
    fk_idstatusNew: null,//Estado de inmueble actual
    fk_idsales_catalogOld: null,//Estado de inmueble anterior
    fk_idsales_catalogNew: null,//Estado de inmueble actual
    reasonDescription: null,//Descripción de la situación por la que se va a bloquear/desbloquear
    fk_idEmployeeOld: null, //Usuario que realizó a modificación pasada
    fk_idEmployeeNew: null, //Usuario que realiza la modificación nueva
    dateLogOld: null
  }

  constructor(
    private reportServices: ReportService,
    private reportService: PaymentService,
    private logoService: LogosService,
    private reportServ: ReportService,
    private recessionsService: RecessionsService,
    private blockLogService: BlockLogService,
    public sweet: SweetAlertComponent,
    private adminService: AdministrationService,
  ) {
    this.recessionsService.getRecessionsType().subscribe(
      res => {
        this.catalogoRecision = res;
      }
    )
    this.decode = decode(localStorage.getItem("token") || "");
    this.adminService.getAllSalesCatalog().subscribe(res => {
      this._getAllSalesCatalog = res;
      console.log('Catalogo de sales', this._getAllSalesCatalog);
    });
  }

  ngOnInit(): void {
    this.getAllCustomers();
    this.getLogos();
    registerLocaleData(localeEsMx, 'es-MX'); //REGISTRAR LOCAL PARA ESPAÑOL
  }

  getAllCustomers() {
    this.reportServ.selectAllCustomers().subscribe(
      res => {
        this.customers = res;
        console.log("aqui", this.customers);
      },
      err => console.log(err)
    );
  }

  getLogos() {
    this.logoService.getAllIcons().subscribe(
      res => {
        this.logos = res;
      }
    );
  }

  async initBloq(customer: any, cad: any) {
    this.blockLog.fk_property = this.codeProperty;//Preparando objeto para historial de bloqueo
    this.blockLog.fk_idUser = customer;
    this.blockLog = {
      fk_idUser: customer,
      fk_property: cad.split(" ")[0],
      fk_blockReasonOld: null,
      fk_blockReasonNew: null,
      fk_idstatusOld: null,
      fk_idstatusNew: null,
      fk_idsales_catalogOld: null,
      fk_idsales_catalogNew: null,
      reasonDescription: null,
      fk_idEmployeeOld: null,
      fk_idEmployeeNew: null,
      dateLogOld: null
    }
    this.blockLogService.getSalesLog(this.blockLog.fk_idUser, this.blockLog.fk_property).subscribe(
      bls => {
        console.log('Registro de información actual extraída del cliente', bls);
        this.custmerName = bls.custmerName;
        this.blockLog.fk_blockReasonOld = bls.fk_blockReason;
        this.blockLog.fk_idstatusOld = bls.fk_status;
        this.blockLog.fk_idsales_catalogOld = bls.fk_salesCatalog;
        this.blockLog.fk_idEmployeeOld = bls.fk_blockUser;
        this.blockLog.fk_idEmployeeNew = this.decode.fkuser;
        this.blockLog.fk_idEmployeeOld = bls.fk_blockUser;
        (bls.blockDate != null && bls.blockDate != undefined) ? this.blockLog.dateLogOld = (bls.blockDate).slice(0, 19).replace('T', ' ') : this.blockLog.dateLogOld = '1969-01-01 00:00:00'
        this.blockLog.fk_idsales_catalogNew = 8;
        this.blockLog.fk_idstatusNew = 2;
        console.log('Objeto de historial de bloqueo 50%:', this.blockLog);
        this.customBlock();
      }
    )
  }

  selectCustomer(event: any) {
    this.creditPayments = [];
    this.saleInfo = '';
    this.event_reload = event;
    let cad = event.name;
    let customer = event.fk_customer;
    this.blockLog.fk_idUser = event.fk_customer;
    this.status = event.status;
    if (event == ' ' || event == null || event == "") {
      console.log("Se requiere consultar cliente");
    } else {
      this.codeProperty = cad.split(" ")[0];
      if (event.status === "ACTIVO") {
        this.getSaleInfo(this.codeProperty);
      } else if (event.status === "RESCINDIDO") {
        this.getRecessionInfo(this.codeProperty, customer);
      }

      if (event.status === "ACTIVO") {
        this.getPayments(this.codeProperty);
        this.getCreditPaymentsList(this.codeProperty);
        this.getCreditPayments(this.codeProperty);
        this.getCreditNRPayments(this.codeProperty);
      } else if (event.status === "RESCINDIDO") {
        this.getPaymentsR(this.codeProperty, customer);
      }

    }
  }

  getSaleInfo(carInmu: string) {
    this.reportServices.selectSaleInfo(carInmu).subscribe(
      res => {
        this.saleInfo = res;
        console.log('Info 2', res);
        if (this.saleInfo.description == 'No disponible' || this.saleInfo.value == 'No Disponible') {
          this.blockButton = {
            disabled: false,
            class: 'm19Btn--UL',
            text: 'Desbloquear cliente',
            icon: 'lock_open',
            show: true
          }
        } else {
          this.blockButton = {
            disabled: false,
            class: 'm19Btn',
            text: 'Bloquear cliente',
            icon: 'lock',
            show: true
          }
        }
      },
      err => console.log(err)
    );
  }

  permissions() {
    const token = localStorage.getItem('token') || "";

    let decodetoken: any = {};
    decodetoken = decode(token);

    return decodetoken.permissions;
  }

  getRecessionInfo(carInmu: string, customer: number) {
    this.reportServices.selectRecessionInfo(carInmu, customer).subscribe(
      res => {
        this.saleInfo = res;
        this.blockButton.disabled = true;
        this.blockButton.show = false;
      },
      err => console.log(err)
    );
  }

  getPayments(cliente: string) {
    this.payments = [];
    this.reportService.getPayments(cliente).subscribe(
      res => {
        this.payments = res;
      },
      err => console.log(err)
    );
  }

  async getCreditPayments(inmueble: String) {
    this.anticipoC = 0;
    this.mensualidadC = 0;
    this.reportService.getCreditPayments(inmueble).subscribe(
      res => {
        this.anticipoC = res.Anticipo;
        this.mensualidadC = res.Mensualidad;
      }
    );
  }

  async getCreditNRPayments(inmueble: String) {
    this.anticipoNR = 0;
    this.mensualidadNR = 0;
    this.reportService.getCreditNRPayments(inmueble).subscribe(
      res => {
        this.anticipoNR = res.Anticipo;
        this.mensualidadNR = res.Mensualidad;
      }
    );
  }

  async getCreditPaymentsList(inmueble: string) {
    this.creditPayments = [];
    this.reportService.getIndividualCreditPayments(inmueble).subscribe(
      res => {
        this.creditPayments = res;
      }
    );
  }

  getPaymentsR(inmueble: String, cliente: Number) {
    this.payments = [];
    this.reportService.getPaymentsR(inmueble, cliente).subscribe(
      res => {
        this.payments = res;
      },
      err => console.log(err)
    );
  }

  searchLogo(name: number) {
    try {
      let res = (this.logos.find((logo: { fk_projects: number; }) => logo.fk_projects == name)).UrlLogo;
      return res;
    } catch (error) {
      return '';
    }
  }

  async customBlock() {
    if (this.status == "ACTIVO") {
      //=======================SI YA ESTA BLOQUEADO=====================
      if (this.blockLog.fk_idstatusOld == 2 || this.blockLog.fk_idsales_catalogOld == 8) {
        console.log("El usuario ya se encuentra bloqueado, desplegando interfaz de desbloqueo");
        this.blockLogService.getLastLog(this.blockLog.fk_idUser, this.blockLog.fk_property).subscribe(
          res => {
            console.log(res);
            this.blockLog.fk_blockReasonNew = null;
            if (res) {
              this.blockLog.fk_idsales_catalogNew = res.fk_idsales_catalogOld;
              this.blockLog.fk_idstatusNew = res.fk_idstatusOld;
              this.blockLog.dateLogOld = res.dateLogNew.slice(0, 19).replace('T', ' ');
              this.confirmationLogUnlock();
            } else {
              console.log('False validado, no tiene log registrado');
              this.selectUnlockReason();
            }
          }
        )
        return;
      }
      //===========================SI NO ESTÁ BLOQUEADO=================
      this.blockButton.disabled = true;
      let listRecession: any = {}
      for (let cr of this.catalogoRecision) {
        listRecession[cr.idtype_recession] = cr.description;
      }
      const { value: MBloqueo } = await Swal.fire({
        title: 'Motivo de bloqueo',
        html: "Inmueble: <b>" + this.codeProperty + "</b><br>" +
          "Cliente: <b>" + this.saleInfo.cliente + "</b><br>" +
          "Para bloquear este inmueble, selecciona un motivo",
        input: 'select',
        inputOptions: listRecession,
        inputPlaceholder: 'Seleccione motivo de bloqueo',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#dd6b55',
        confirmButtonText: 'Guardar',
        confirmButtonColor: '#4c9141',
        buttonsStyling: false,
        customClass: {
          confirmButton: "m19AltBtn",
          cancelButton: "m19Btn",
          actions: "sweetActions"
        },
        //allowOutsideClick: false,
        //allowEscapeKey: false,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value === '') {
              resolve('Debes elegir una opción de la lista o hacer clic en cancelar')
            } else {
              console.log(value);
              resolve('');
            }
          })
        }
      })
      if (MBloqueo) {
        this.confirmationLog(listRecession);
        console.log('mbloqueo', MBloqueo);
        this.blockLog.fk_blockReasonNew = MBloqueo;
        console.log('Objeto de registro:', this.blockLog)
      } else {
        this.blockButton.disabled = false;
        this.selectCustomer(this.event_reload);
      }
    } else {
      Swal.fire({ icon: 'error', title: 'Error critico', html: 'Error de validación de datos, por favor contacte a área de sistemas computacionales (Cliente no esta activo)', showConfirmButton: false })
    }
  }

  async selectUnlockReason() {
    let listSalesCatalog: any = {}
    for (let sc of this._getAllSalesCatalog) {
      console.log(sc);
      listSalesCatalog[sc.idsales_catalog] = sc.value;
    }
    const { value: MSalesCatalog } = await Swal.fire({
      title: 'DESBLOQUEO: Estado de venta',
      html: "Inmueble: <b>" + this.codeProperty + "</b><br>" +
        "Cliente: <b>" + this.saleInfo.cliente + "</b><br><br>" +
        "<b>¡ATENCIÓN!</b> Este cliente fue bloqueado de forma <b>IRREGULAR</b> o <b>NO CUENTA CON HISTORIAL</b> de bloqueos. Seleccione un estado de venta nuevo de forma manual para continuar con el desbloqueo de cliente-inmueble",
      input: 'select',
      inputOptions: listSalesCatalog,
      inputPlaceholder: 'Seleccione estado de venta',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#dd6b55',
      confirmButtonText: 'Guardar',
      confirmButtonColor: '#4c9141',
      buttonsStyling: false,
      customClass: {
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn",
        actions: "sweetActions"
      },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === '') {
            resolve('Debes elegir una opción de la lista o hacer clic en cancelar')
          } else {
            console.log(value);
            resolve('');
          }
        })
      }
    })
    if (MSalesCatalog) {
      console.log('mbloqueo', MSalesCatalog);
      this.blockLog.fk_idsales_catalogNew = MSalesCatalog;
      this.blockLog.fk_idstatusNew = 3;
      console.log('Objeto de registro:', this.blockLog)
      this.confirmationLogUnlock();
    } else {
      this.blockButton.disabled = false;
      this.selectCustomer(this.event_reload);
    }
  }

  async confirmationLog(listRecession: any) {
    console.log(listRecession);
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'Escribe algunos detalles relacionados al motivo de BLOQUEO',
      inputLabel: 'Detalles de bloqueo de inmueble',
      html: "El inmueble: <b>" + this.blockLog.fk_property + "</b><br>" +
        "Cliente: <b>" + this.custmerName + "</b><br><br>" +
        "Usuario que solicita el bloqueo: <b>" + this.decode.cliente + "</b><br>" +
        "Para continuar con el bloqueo, escriba los detalles y presione 'Continuar'",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      customClass: {
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn",
        actions: "sweetActions"
      },
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === '') {
            resolve('Escribe los detalles para continuar')
          } else {
            resolve('');
          }
        })
      },
      showCancelButton: true
    })
    if (text) {
      this.blockLog.reasonDescription = text;
      this.blockLogService.blockLocationSales(this.blockLog.fk_idUser, this.blockLog.fk_property, this.blockLog
      ).subscribe(
        res => {
          console.log('BlocklocationSales', res);
          this.blockLogService.blockLocationProperties(this.blockLog.fk_property).subscribe(
            a => {
              console.log('blockLocationProperties', a);
              console.log('Se ha bloqueado inmueble ', this.blockLog.fk_property);
              this.blockLogService.insertLog(this.blockLog).subscribe(
                Res => {
                  console.log('Log insertado', this.blockLog);
                  this.sweet.AlertTime("center", "success", `El cliente ha sido bloqueado correctamente`, false, 2000);
                  this.blockButton.disabled = false;
                  this.selectCustomer(this.event_reload);
                }
              )
            }
          )
        }
      )
    } else {
      this.blockButton.disabled = false;
      this.selectCustomer(this.event_reload);
    }
  }

  async confirmationLogUnlock() {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'Escribe detalles sobre el motivo de DESBLOQUEO del cliente',
      inputLabel: 'Desbloqueo de inmueble',
      html: "El inmueble: <b>" + this.blockLog.fk_property + "</b><br>" +
        "Cliente: <b>" + this.custmerName + "</b><br>" +
        "Será desbloqueado </b><br><br>" +
        "Autoriza: <b>" + this.decode.cliente + "</b><br>" +
        "Para continuar con el desbloqueo, escriba los detalles y presione 'Continuar'",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      customClass: {
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn",
        actions: "sweetActions"
      },
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === '') {
            resolve('Escribe los detalles para continuar')
          } else {
            resolve('');
          }
        })
      },
      showCancelButton: true
    })
    if (text) {
      this.blockLog.reasonDescription = text;
      let update: any = {
        fk_status: this.blockLog.fk_idstatusNew,
        fk_salesCatalog: this.blockLog.fk_idsales_catalogNew,
        fk_property: this.blockLog.fk_property,
        fk_customer: this.blockLog.fk_idUser
      }
      this.blockLogService.unlockProperties(update).subscribe(
        up => {
          console.log('Restaurado este we', up)
          //----
          this.blockLogService.insertLog(this.blockLog).subscribe(
            Res => {
              console.log('Log insertado', this.blockLog);
              this.sweet.AlertTime("center", "success", `El cliente ha sido desbloqueado correctamente`, false, 3000);
              this.blockButton.disabled = false;
              this.selectCustomer(this.event_reload);
            }
          )
        }
      )
    } else {
      this.blockButton.disabled = false;
      this.selectCustomer(this.event_reload);
    }
  }


  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlData')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
      <html>
        <head>
          <title>${this.saleInfo.codeProperty} ${this.saleInfo.cliente}</title>
          <style>
            * {
              margin: 0;
              padding: 0;
              -webkit-print-color-adjust: exact;
              color-adjust: exact;
            }
            body {
              font-family: Roboto, "Helvetica Neue", sans-serif;
            }

            .statementHeader {
              display: flex;
              border: solid #D33E3E 5px;
              padding: 30px;
              border-radius: 20px 20px 0 0;
              justify-content: space-between;
            }

            .m19infoContainer {
                display: flex;
                flex-direction: column;
                width: 45%;
                gap: 10px;
                font-size: 0.9rem;
                margin: 0;
                padding: 0;
            }

            .principalInfo {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 45%;
                font-size: 0.9rem;
                margin: 0;
                padding: 0;
            }

            img {
                width: 30%;
                margin-bottom: 5px;
                border: none;
            }

            h4 {
                padding: 10px;
                background-color: #D33E3E;
                color: white;
                font-weight: bold;
                text-align: center;
                border-radius: 20px 20px 0 0;
                margin-bottom: 0;
            }

            .financialStatus {
              display: flex;
              justify-content: space-between;
              padding: 30px 30px 30px 30px;
              border-top: solid #D33E3E 5px;
              border-left: solid #D33E3E 5px;
              border-right: solid #D33E3E 5px;
            }

            .saleStatus {
              display: flex;
              justify-content: space-between;
              padding: 30px 30px 30px 30px;
              border-top: solid #D33E3E 5px;
              border-left: solid #D33E3E 5px;
              border-right: solid #D33E3E 5px;
              border-bottom: solid #D33E3E 5px;
            }

            .aditionalInfo {
              width: 100%;
              display: flex;
              justify-content: space-between;
              font-size: 0.8rem;
            }

            .debtInfo {
              display: flex;
              flex-direction: column;
              width: 40%;
            }

            .totalAmount {
              display: flex;
              justify-content: space-evenly;
              background-color: #5E4F4F;
              color: white;
              font-size: 1rem;
              font-weight: bold;
              padding: 10px;
            }

            .totalAmount p {
              margin: 0;
            }

            .infoColumn {
              display: flex;
              flex-direction: column;
              gap: 10px;
              margin-top: 10px;
            }

            .paymentsInfo {
                width: 100%;
            }

            .paymentsSection {
              break-before: page;
            }

            table {
              border-collapse: collapse;
              border: solid #5E4F4F 5px;
            }

            thead {
                background-color: #5E4F4F;
                color: white;
                font-weight: bold;
                font-size: 1rem;
                text-align: center;
            }

            tbody {
                text-align: center;
                font-size: 0.8rem;
            }

            tbody tr:nth-child(even) {
                background-color: #ebebeb;
            }

            td, th {
              padding: 10px;
            }
          </style>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin!.document.close();
  }
}