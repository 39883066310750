//=========================================================================================================
//Importación de componentes necesarios para esta pantalla
//=========================================================================================================
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { DeliverynoteService } from 'src/app/services/deliverynote.service';
import { ProductcatalogService } from 'src/app/services/productcatalog.service';

import { SuppliersService } from 'src/app/services/suppliers.service';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-edit-delivery',
  templateUrl: './dialog-edit-delivery.component.html',
  styleUrls: ['./dialog-edit-delivery.component.scss']
})
export class DialogEditDeliveryComponent implements OnInit {
  suppliers: any = [];
  modification: any = false;
  editing: any = "";

  subt: any;
  iva: any;
  total: any;

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  pipe = new DatePipe('en-US');
  todayWithPipe: any;

  idsupplier: any;
  deliverynumber: any;
  deliverydate: any;
  receiver: any;
  notes: any;

  deliproducts: any = [];

  products: any = [];
  product: any;
  materials: any = [];

  keywordM = 'productname';

  constructor(
    private dialogRef: MatDialogRef<DialogEditDeliveryComponent>,
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    public supplierService: SuppliersService,
    public productService: ProductcatalogService,
    public deliveryService: DeliverynoteService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getDeliveryInfo();
    this.selectSuppliers();
    this.selectProducts();
    this.getDeliveryProducts();
  }

  //==========================================================================================================
  //Objeto constructor del formulario utilizado en la pantalla de remisión, se agrega el código 
  //", Validators.required" para señalar que es un valor que no debe estar vacío
  //==========================================================================================================
  deliveryFormGroup: FormGroup = this._formBuilder.group({
    deliveryfolio: [],
    idrequisition: [],
    idsupplier: [, Validators.required],
    deliverynumber: [, Validators.required],
    receiver: [, Validators.required],
    notes: [, Validators.required],
    deliverydate: [, Validators.required],
    idproduct: [],
    product: [],
    productname: [],
    amount: [],
    unit: [],
    unitcost: [],
    reason: [],
    useremail: [this.getUser()]
  });

  async getDeliveryInfo() {
    this.deliveryService.selectDeliveryInfo(this.data).subscribe(
      res => {
        console.log(res);
        this.deliveryFormGroup.controls['idsupplier'].setValue(res.idsupplier);
        this.idsupplier = res.idsupplier;

        this.deliveryFormGroup.controls['deliverynumber'].setValue(res.deliverynumber);
        this.deliverynumber = res.deliverynumber;

        this.todayWithPipe = this.pipe.transform(res.deliverydate, 'yyyy-MM-dd');
        this.deliveryFormGroup.controls['deliverydate'].setValue(this.todayWithPipe);
        this.deliverydate = this.todayWithPipe;

        this.deliveryFormGroup.controls['receiver'].setValue(res.receiver);
        this.receiver = res.receiver;

        this.deliveryFormGroup.controls['notes'].setValue(res.notes);
        this.notes = res.notes;
      }
    );
  }

  async compareValues() {
    if (this.deliveryFormGroup.controls['idsupplier'].value != this.idsupplier
      || this.deliveryFormGroup.controls['deliverynumber'].value != this.deliverynumber
      || this.deliveryFormGroup.controls['deliverydate'].value != this.deliverydate
      || this.deliveryFormGroup.controls['receiver'].value != this.receiver
      || this.deliveryFormGroup.controls['notes'].value != this.notes) {
      this.modification = true;
    } else {
      this.modification = false;
    }
  }

  async selectSuppliers() {
    this.supplierService.selectSuppliers().subscribe(
      res => {
        this.suppliers = res;
      }
    );
  }

  async selectProducts() {
    this.productService.getNamesOfProducts().subscribe(
      res => {
        this.products = res;
      }
    );
  }

  async updateDelivery() {
    if (this.modification == true && (this.deliveryFormGroup.controls['reason'].value == null || this.deliveryFormGroup.controls['reason'].value == '')) {
      this.sweet.AlertTime("center", "error", "Por favor capture una razón de modificación.", false, 2000);
    } else if (this.modification == false) {
      this.sweet.AlertTime("center", "warning", "No se detectó ninguna modificación", false, 2000);
      this.dialogRef.close();
    } else {
      Swal.fire({
        title: '¿Quieres guardar tus cambios ahora?',
        text: "¡Esta requisición se guardara como está actualmente!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No, espera',
        confirmButtonText: 'Sí, guárdala!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deliveryService.updateDelivery(this.data, this.deliveryFormGroup.value).subscribe(
            res => {
              this.updateDeliveryProduct();
            }
          );
        }
      })
    }
  }

  async updateDeliveryProduct() {
    this.deliveryService.deleteProductsByFolio(this.data).subscribe(
      res => {
        for (let p of this.materials) {
          let prod = p.split("~");

          this.deliveryService.insertProducts(this.data, prod[0], prod[2], prod[4], this.getUser()).subscribe(
            res => {
              this.sweet.AlertTime("center", "success", "Remisión actualizada con éxito", false, 2000);
              this.dialogRef.close();
            }
          );
        }
        if (this.materials.length == 0) {
          this.sweet.AlertTime("center", "success", "Remisión actualizada con éxito", false, 2000);
          this.dialogRef.close();
        }
      }
    );
  }

  async addMaterial() {
    if (this.deliveryFormGroup.controls['productname'].value == null || this.deliveryFormGroup.controls['productname'].value == ''
      || this.deliveryFormGroup.controls['amount'].value == null || this.deliveryFormGroup.controls['amount'].value == ''
      || this.deliveryFormGroup.controls['unit'].value == null || this.deliveryFormGroup.controls['unit'].value == '') {
      this.sweet.AlertTime("center", "error", "Por favor complete los campos de producto y cantidad correctamente.", false, 2000);
    } else {
      this.modification = true;
      let addMaterial = this.deliveryFormGroup.value;
      if (addMaterial !== '') {
        this.materials.push(addMaterial.idproduct + '~' + addMaterial.productname + '~' + addMaterial.amount + '~' + addMaterial.unit + '~' + addMaterial.unitcost);

        //Se calcula el subtotal de la factura
        this.subt = this.subt + addMaterial.unitcost * addMaterial.amount;

        //Se calcula el total de la factura
        this.total = this.subt + (this.subt * this.iva);
        //Se resetea la información de los campos de texto para productos
        this.deliveryFormGroup.controls['idproduct'].setValue('');
        this.deliveryFormGroup.controls['product'].setValue('');
        this.deliveryFormGroup.controls['productname'].setValue('');
        this.deliveryFormGroup.controls['amount'].setValue('');
        this.deliveryFormGroup.controls['unit'].setValue('');
        this.deliveryFormGroup.controls['unitcost'].setValue('');

        this.editing = "";
      }
    }
  }

  //==========================================================================================================
  //Función para borrar materiales de remisión de la lista temporal
  //==========================================================================================================
  async deleteMaterials(i: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, elimínalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'El producto se quitó de la requisición.',
          'success'
        )
        //Se crea una variable temporal para almacenar los materiales que permanecerían en la lista
        const materialsT = Array();
        let indice = 0;
        this.modification = true;
        //Se recorre la lista de materiales con un ciclo for
        for (const mat of this.materials) {
          //Se compara el indice que se quiere eliminar con el de la lista y si no coincide se almacena en la lista
          //temporal
          if (indice !== i) {
            materialsT.push(mat);
          }
          indice++;
        }
        //Se pasa el valor de la lista temporal a la lista original de materiales
        this.materials = materialsT;
      }
    })
  }

  //==========================================================================================================
  //Función para buscar un material por ID según la selección del select del formulario
  //==========================================================================================================
  async selectProduct(id: any) {
    //El objeto select tiene de valor el ID y el nombre separados por coma
    //idproduct , productname
    let ide = id.productname.split("~");

    this.deliveryFormGroup.controls["idproduct"].setValue(ide[0]);
    this.deliveryFormGroup.controls["productname"].setValue(ide[1]);

    this.productService.selectProduct(parseInt(ide[0]))
      .subscribe(
        res => {
          //Recuperamos el valor en la variable product
          this.product = res;

          //Asignamos al objeto del formulario con controlName "unit" el valor recuperado de la consulta
          this.deliveryFormGroup.controls['unit'].setValue(this.product.unit);
        }
      );
  }

  async getDeliveryProducts() {
    this.deliveryService.selectDeliveryProducts(this.data).subscribe(
      res => {
        this.deliproducts = res;
        this.generateList();
      }
    );
  }

  async generateList() {
    for (let mat of this.deliproducts) {
      this.materials.push(mat.idproduct + "~" + mat.productname + "~" + mat.amount + "~" + mat.unit + "~" + mat.unitcost);
    }
  }

  async editProduct(i: number) {
    if (this.editing != "") {
      Swal.fire({
        title: '¿Estás seguro de este movimiento?',
        text: "Ya estás editando un material y no has guardado, si continuas se perderá",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, continúa',
        cancelButtonText: 'No, espera',
      }).then((result) => {
        if (result.isConfirmed) {
          //Se crea una variable temporal para almacenar los materiales que permanecerían en la lista
          const materialsT = Array();
          let indice = 0;

          //Se recorre la lista de materiales con un ciclo for
          for (const mat of this.materials) {
            //Se compara el indice que se quiere eliminar con el de la lista y si no coincide se almacena en la lista
            //temporal
            if (indice !== i) {
              materialsT.push(mat);
            }

            if (indice == i) {
              this.editing = mat;
            }
            indice++;
          }
          //Se pasa el valor de la lista temporal a la lista original de materiales
          this.materials = materialsT;

          let info = this.editing.split("~");

          this.deliveryFormGroup.controls['product'].setValue(info[0] + " ~ " + info[1]);
          this.deliveryFormGroup.controls['idproduct'].setValue(info[0]);
          this.deliveryFormGroup.controls['productname'].setValue(info[1]);
          this.deliveryFormGroup.controls['amount'].setValue(info[2]);
          this.deliveryFormGroup.controls['unit'].setValue(info[3]);
          this.deliveryFormGroup.controls['unitcost'].setValue(info[4]);
        }
      })
      return;
    }
    //Se crea una variable temporal para almacenar los materiales que permanecerían en la lista
    const materialsT = Array();
    let indice = 0;

    //Se recorre la lista de materiales con un ciclo for
    for (const mat of this.materials) {
      //Se compara el indice que se quiere eliminar con el de la lista y si no coincide se almacena en la lista
      //temporal
      if (indice !== i) {
        materialsT.push(mat);
      }

      if (indice == i) {
        this.editing = mat;
      }
      indice++;
    }
    //Se pasa el valor de la lista temporal a la lista original de materiales
    this.materials = materialsT;

    let info = this.editing.split("~");

    this.deliveryFormGroup.controls['product'].setValue(info[0] + " ~ " + info[1]);
    this.deliveryFormGroup.controls['idproduct'].setValue(info[0]);
    this.deliveryFormGroup.controls['productname'].setValue(info[1]);
    this.deliveryFormGroup.controls['amount'].setValue(info[2]);
    this.deliveryFormGroup.controls['unit'].setValue(info[3]);
    this.deliveryFormGroup.controls['unitcost'].setValue(info[4]);
  }

  //==========================================================================================================
  //Recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.email
  }
}
