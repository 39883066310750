<div class="row _card">
    <div class="col-md-4">
        <mat-card>
            <mat-card-content>
                <form class="row mt-3" [formGroup]="pieceworkFormGroup" (ngSubmit)="insertPiecework()">

                    <h5 class="col-12 pb-2">Nuevo destajo</h5>

                    <mat-form-field appearance="outline" class="col-md-12">
                        <mat-label>Nombre de destajo</mat-label>
                        <input matInput type="text" formControlName="description" required>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-12">
                        <mat-label>Unidad</mat-label>
                        <input matInput type="text" formControlName="unit" required>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-md-12">
                        <mat-label>Costo</mat-label>
                        <input matInput type="number" formControlName="cost" required>
                    </mat-form-field>

                    <div class="col-12 text-right mt-3">
                        <button class="btn btn-m19" type="submit" [disabled]="!pieceworkFormGroup.valid">
                            <mat-icon>save</mat-icon>
                        </button>
                    </div>

                </form>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="col-md-8">
        <mat-card>
            <mat-card-content class="controlTable">
                <table class="table table-striped animated fadeIn faster">
                    <thead class="thead-m19">
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Tipo de destajo</th>
                            <th scope="col">Unidad</th>
                            <th scope="col">Costo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let piecework of pieceworks">
                            <td>{{piecework.idpiecework_catalog}}</td>
                            <td style="max-width: 300px;">{{piecework.description}}</td>
                            <td>{{piecework.unit}}</td>
                            <td>{{piecework.cost}}</td>
                            <td class="button-row">
                                <button class="btn btn-m19-table" (click)="editPiecework(piecework)" mat-icon-button>
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button class="btn btn-m19-table" (click)="deletePiecework(piecework)" mat-icon-button>
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-card-content>
        </mat-card>
    </div>
</div>
