import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

import decode from 'jwt-decode'
import { SweetAlertComponent } from '../utils/sweet-alert/sweet-alert.component';


@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    public router: Router,
    public sweet: SweetAlertComponent

  ) { }

  async canActivate(route: ActivatedRouteSnapshot) {

    const expectedRole = route.data.expectedRole;
    const token = localStorage.getItem('token') || "";

    let decodetoken: any = {};
    decodetoken = decode(token);

    // console.log('AUXILIO ', decodetoken);
    // console.log('tipo de usuario : ' + decodetoken.permissions);
    // console.log('guardian : ' + expectedRole);

    let parts: any[] = expectedRole.split(',');

    // console.log("consola log parts: ", parts)

    let exp = (decodetoken.permissions).includes(parts[0]) + "" //RUTA 

    let des = (decodetoken.permissions).includes(parts[1]) + "" //DESARROLLADOR 

    // console.log("Es EXP: " + exp);

    // console.log("Es des: " + des);

    let flag: any = false;

    if (exp == "false" && des == "false") {
      flag = true;
      console.log("bandera es: ", flag)
    }
    
      if (!this.authService.isAuth() || flag == true) {
        console.log('Usuario no autorizado para la vista')


        //Cuando un cliente intenta entrar a algo ajeno.
        if ((decodetoken.permissions + "").match('3')) {
          this.router.navigate(['account']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a tu pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }

        //Cuando intenten entrar a algo a lo que no tienen permiso
        if ((decodetoken.permissions + "").match("walletByWeek")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("coordStatus")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("coordinator")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("projects")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        /////////
        if ((decodetoken.permissions + "").match("customers")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("sales")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("report")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("users")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("setting")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("employees")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("materials")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("payments")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("graficos")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }

        if ((decodetoken.permissions + "").match("flowReport")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("factura")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("facturaGlobal")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("facturaConsulta")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("reportDeparture")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("search")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("cancelled")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("entry")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("wallet")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }

        if ((decodetoken.permissions + "").match("request")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("account")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("pdfmaker")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }
        if ((decodetoken.permissions + "").match("PostalCode")) {
          this.router.navigate(['home']);
          this.sweet.AlertIcon('error', 'No tienes permitido estar aqui', 'Te redigiremos a la pagina principal', '<a href="">¿Porque estoy viendo este error?</a>')
        }

      return false;

    }

    return true;
  }

}