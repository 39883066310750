<mat-tab-group mat-stretch-tabs>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">gavel</mat-icon>
            <span>Ratificación</span>
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">
                    <div class="ratContainer">
                        <div class="waiting">
                            <h3>Clientes para ratificación</h3>
                            <div class="waitingCards">
                                <div *ngIf="customersWaiting.length===0"
                                    style="display: flex; flex-direction: column; align-items: center; margin-top: 20px;">
                                    <h4>Sin contratos pendiendes de ratificación</h4>
                                    <mat-icon>sentiment_very_satisfied</mat-icon>
                                </div>
                                <div class="customerWaiting" *ngFor="let c of customersWaiting">
                                    <div class="info">
                                        <h4>{{c.Inmueble}}</h4>
                                        <p><b>Cliente: </b>{{c.Cliente}}</p>
                                        <p><b>Vendedor: </b>{{c.Vendedor}}</p>
                                        <p><b>Contrato elaborado el día </b>{{c.FechaContrato | date: 'dd-MMM-yyyy'}}
                                        </p>
                                    </div>
                                    <button class="btn btnAction" data-title="Enviada"
                                        (click)="sendRatification(c.Inmueble)">
                                        <mat-icon class="icon">outbox</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="pending">
                            <h3>Ratificaciones pendiendes</h3>
                            <div class="waitingCards">
                                <div *ngIf="customersRatificated.length===0"
                                    style="display: flex; flex-direction: column; align-items: center; margin-top: 20px;">
                                    <h4>Sin contratos en proceso de ratificación</h4>
                                    <mat-icon>sentiment_very_satisfied</mat-icon>
                                </div>
                                <div class="customerWaiting" *ngFor="let c of customersRatificated">
                                    <div class="info">
                                        <h4>{{c.Inmueble}}</h4>
                                        <p><b>Cliente: </b>{{c.Cliente}}</p>
                                        <p><b>Vendedor: </b>{{c.Vendedor}}</p>
                                        <p><b>Contrato elaborado el día </b>{{c.FechaContrato | date: 'dd-MMM-yyyy'}}
                                        </p>
                                    </div>
                                    <button class="btn btnAction" data-title="Recibida"
                                        (click)="receiveRatification(c.Inmueble)">
                                        <mat-icon class="icon">approval</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">how_to_reg</mat-icon>
            <span>Cliente Completo</span>
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">
                    <div class="completeContainer">
                        <h3 style="text-align: center;">Clientes pendientes para firmar</h3>
                        <div *ngIf="ratCustomers.length===0"
                            style="display: flex; flex-direction: column; align-items: center; margin-top: 20px;">
                            <h4>Sin contratos pendientes de firmar</h4>
                            <mat-icon>sentiment_very_satisfied</mat-icon>
                        </div>
                        <div class="cuadricula">
                            <div class="tarjeta" *ngFor="let c of ratCustomers">
                                <p style="text-align: center; font-size: 1rem;"><b>{{c.Inmueble}}</b></p>
                                <p style="text-align: center; font-size: 0.9rem;">Cliente: {{c.Cliente}}</p>
                                <p style="text-align: center; font-size: 0.9rem;">Vendedor: {{c.Vendedor}}</p>
                                <p style="text-align: center; font-size: 0.9rem;">Fecha de contrato: {{c.FechaContrato |
                                    date: 'dd-MMM-yyyy'}}</p>
                                <div class="info">
                                    <button class="btn btnAction" data-title="Cliente completo"
                                        (click)="setCompleteCustomer(c.Inmueble)">
                                        <mat-icon class="icon">draw</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>

</mat-tab-group>