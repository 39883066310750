import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReasonrequisitionService {

  constructor(private http: HttpClient) { }

  insertReason(id:number, reason:string, usr: string): Observable<any>{
    return this.http.post(`${environment.URLServer}/reasonrequi/insertReason`,[id, reason, usr]);
  }
}
