import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

//Importación para utilizar el modelo del catalogo de productos
import { productCatalog } from '../models/productCatalog';

@Injectable({
  providedIn: 'root'
})
export class ProductcatalogService {

  constructor(private http: HttpClient) { }

  //Insertar productos
  insertProduct(pro: productCatalog): Observable<any> {
    return this.http.post(`${environment.URLServer}/productcatalog/insertProd`,pro);
  }

  //Consultar todos los productos
  selectProducts(): Observable<any> {
    return this.http.get(`${environment.URLServer}/productcatalog/selectProd`);
  }

  //Consultar nombres de los productos
  getNamesOfProducts(): Observable<any> {
    return this.http.get(`${environment.URLServer}/productcatalog/getNamesOfProducts`);
  }

  //Buscar un producto del catalogo por ID
  selectProduct(id:Number): Observable<any> {
    return this.http.get(`${environment.URLServer}/productcatalog/searchProd/`+id);
  }

  //Eliminar un producto del catalogo
  deleteProduct(id: Number): Observable<any> {
    return this.http.put(`${environment.URLServer}/productcatalog/deleteProd`,[id]);
  }

  //Actualizar un producto
  updateProduct(id:number, pro: productCatalog): Observable<any> {
    return this.http.put(`${environment.URLServer}/productcatalog/updateProd/${id}`,pro);
  }
}