import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Sale } from '../models/Sale';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SaleService {


  private url = 'http://localhost:3000/api';


  constructor(private http: HttpClient) { }

  //Consultar todos los proyectos
  selectProjects() {
    return this.http.get(`${environment.URLServer}/projects`);
  }

  //Consultar los inmuebles del proyecto específicado
  selectPropertiesProject(nomenclature: string) {
    return this.http.get(`${environment.URLServer}/properties/project/${nomenclature}`);
  }

  //Consultar todas las ventas
  selectSales() {
    return this.http.get(`${environment.URLServer}/sales`);
  }

  //Consultar la venta específicada
  selectSale(codeProperty: string) {
    return this.http.get<Sale>(`${environment.URLServer}/sales/${codeProperty}`);
  }

  //Registrar ventas
  createSale(sale: Sale): Observable<any> {
    return this.http.post(`${environment.URLServer}/sales`, sale);
  }

  //Modificar venta
  updateSale(idSale: number | string, updateSale: Sale): Observable<Sale> {
    return this.http.put(`${environment.URLServer}/sales/${idSale}`, updateSale);
  }

  //Consultar co-propietario del inmueble específicado
  selectCoowner(codeProperty: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/sales/coowner/${codeProperty}`);
  }

  /* ===================== */
  /* Rutas para recisiones */
  /* ===================== */
  selectAllRecessions(): Observable<any> {
    return this.http.get(`${environment.URLServer}/sales/getRecessions/general`);
  }

  getRecessionByProjects(project: Number): Observable<any> {
    return this.http.get(`${environment.URLServer}/sales/getRecessionsByProject/${project}`);
  }

  getRecessionInfo(property: String, customer: Number): Observable<any> {
    return this.http.get(`${environment.URLServer}/sales/getRecessionsInfo/${property}/${customer}`);
  }

  changeSalesReasonBlock(fk_blockReason: Number, idSale: Number): Observable<any> {
    return this.http.put(`${environment.URLServer}/sales/changeSalesReasonBlock/${fk_blockReason}/${idSale}`,{});
  }

  setRecessionAgreement(id: number): Observable<any> {
    return this.http.put(`${environment.URLServer}/sales/setRecessionAgreement/${id}`, 'ola soy una mala practica');
  }

  updateExpSales(contracts:number,id: number): Observable<any> {
    return this.http.put(`${environment.URLServer}/sales/updateExpSales/${contracts}/${id}`, 'ola soy una mala practica x2');
  }

  salesPayN(fk_property:string): Observable<any> {
    return this.http.get(`${environment.URLServer}/sales/salesPayN/${fk_property}`);
  }
  saleInsertTicketPay(NTickets:number,id:string): Observable<any> {
    return this.http.put(`${environment.URLServer}/sales/saleInsertTicketPay/${NTickets}/${id}`,{});
  }

  deleteRecessionAgreement(id:string): Observable<any> {
    return this.http.put(`${environment.URLServer}/sales/deleteRecessionAgreement/${id}`,{});
  }

  updateExpedient(id:string): Observable<any> {
    return this.http.put(`${environment.URLServer}/sales/updateExpedient/${id}`,{});
  }
}
