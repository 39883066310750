import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MapsService } from 'src/app/services/maps.service';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { DialogSketchmapVisualComponent } from '../dialog-sketchmap-visual/dialog-sketchmap-visual.component';

@Component({
  selector: 'app-sketch-maps',
  templateUrl: './sketch-maps.component.html',
  styleUrls: ['./sketch-maps.component.scss']
})
export class SketchMapsComponent implements OnInit {

  propertiesUpdate: any = [];
  propertiesYet: any = [];
  properties: any = [];
  property: any = [];
  selected_project = { name: '', nomenclature: '' };
  selected_projectYet = { name: '', nomenclature: '' };
  selected_projectUpdate = { name: '', nomenclature: '' };
  projects: any = []
  uploadPercent!: Observable<number | undefined>;
  urlImage!: Observable<string | undefined>;
  @ViewChild('linkdelmapa') inputImageUser!: ElementRef

  linkUpdate:any;


  constructor(
    private maps: MapsService,
    private storage: AngularFireStorage,
    private alerta: SweetAlertComponent,
    public dialog: MatDialog,
  ) {
   }

  ngOnInit(): void {
    this.getProject();
  }

  //Consultar todos los inmuebles registrados que ya tienen un mapa de ubicacion
  selectPropertiesReady() {
    this.maps.getReadySketchMap(this.selected_projectYet.nomenclature).subscribe(
      res => {
        this.propertiesYet = res;
      },
      err => console.log(err)
    );
  }


    //Consultar todos los inmuebles registrados que ya tienen un mapa de ubicacion
    selectPropertiesUpdate() {
      this.maps.getReadySketchMap(this.selected_projectYet.nomenclature).subscribe(
        res => {
          this.propertiesUpdate = res;
        },
        err => console.log(err)
      );
    }

    //Consultar todos los inmuebles registrados que no tienen un mapa de ubicacion
    selectProperties() {
      this.maps.getNotSketchMap(this.selected_project.nomenclature).subscribe(
        res => {
          this.properties = res;
          console.log(this.properties)
        },
        err => console.log(err)
      );
    }

  getProject() {
    this.maps.selectAllProjects().subscribe(
      res => {
        this.projects = res;
      },
      err => console.log(err)
    )
  }

  onUpload(event: any, ruta: any, inmueble: any, porcentaje: any) {

    console.log("subir", event.target.files[0],ruta,inmueble);

    const file = event.target.files[0];

    const filePath = `Lotificaciones/`+ruta+ "/lot_"+inmueble

    const ref = this.storage.ref(filePath);
    console.log("Aqui Diego", ref.listAll());

    const task = this.storage.upload(filePath, file)

    this.uploadPercent = task.percentageChanges()
    porcentaje = task.percentageChanges()

    task.snapshotChanges().pipe(
      finalize(() =>
        this.urlImage = ref.getDownloadURL(),
      )
    ).subscribe();

    this.linkUpdate = this.urlImage


  }

  //insertar el mapa 
  async insertMap(inmueble:any) {
    console.log("Datos a insertar: ",this.inputImageUser.nativeElement.value, inmueble)
    this.maps.insertsketchMap(this.inputImageUser.nativeElement.value, inmueble).subscribe(
      res => {
        this.alerta.AlertIcon("success", "Mapa agregado con exito", "El nuevo mapa se encuentra en la base de datos", "")
      },
      err => { alert("no se agrego el mapa ") }
    )
  }


  visualSkechmap(idinmueble: any, proyecto: any ){
    console.log("inmueble seleccionado: ",idinmueble, proyecto)
    const dialogo1 = this.dialog.open(DialogSketchmapVisualComponent
      , {
        data: {idinmueble,proyecto},
        width: '700px',
      });
  }

  resetIDE(){
    console.log("reset")
    this.uploadPercent = new Observable<number | undefined>()
    this.urlImage = new  Observable<string | undefined>()
    this.linkUpdate = ''
  }


}
