//=========================================================================================================
//Importación de componentes necesarios para esta pantalla
//=========================================================================================================
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


//=========================================================================================================
//Importación de los servicios necesarios para esta pantalla
//=========================================================================================================
import { RequisitionService } from 'src/app/services/requisition.service';
import { DepaproService } from 'src/app/services/depapro.service';
import { BudgetService } from '../../../../services/budget.service';


@Component({
  selector: 'app-budgetdialog',
  templateUrl: './budgetdialog.component.html',
  styleUrls: ['./budgetdialog.component.scss']
})
export class BudgetdialogComponent implements OnInit {
  //Arreglos para almacenar los datos de entrada del dialog
  departures: any = [{descriptions: "Cargando..."}];
  products: any = [];

  //Variable para almacenar la fecha del presupuesto
  date: any;

  //Variable para almacenar el módulo del presupuesto
  module: any;

  //Variable para guardar el número de presupuesto consultado
  budgetNum: any;

  //Variables para guardar la información del proyecto

  //Variable para el nombre del proyecto
  projectName: any;

  //Variable para almacenar el proyecto
  project: any;

  //Variable para almacenar el total del presupuesto
  totalbudget: any;

  constructor(private dialogRef: MatDialogRef<BudgetdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    //=======================================================================================================
    //Declaración de los servicios utilizados
    //=======================================================================================================
    public depaprodService: DepaproService,
    public requisitionService: RequisitionService,
    public budgetService: BudgetService,
  ) { }

  ngOnInit(): void {
    this.getBudgetInfo();
    this.selectListDepaCon(this.data);
  }

  //=======================================================================================================
  //Obtener información especifica del presupuesto para plasmarlo en un principio
  //=======================================================================================================
  async getBudgetInfo() {
    this.budgetService.getBudgetInfo(this.data).subscribe(
      res => {
        this.date = res.budgetdate;
        this.project = res.name;
        this.budgetNum = res.idbudget;
        this.module = res.towernumber;
        this.totalbudget = res.total;
      }
    );
  }
  
//=======================================================================================================
//Obtener la lista de los conceptos relacionados con las partidas
//=======================================================================================================
  async selectListDepaCon(id: number) {
    this.budgetService.getListDepaCon(id).subscribe(
      res => {
        this.departures = res;
        console.log(this.departures);
      }
    );
  }

  async searchProductsFromDeparture(ib: number, id: number, ic: number) {
    this.products = [];
    this.budgetService.searchProductsFromDeparture(ib, id, ic).subscribe(
      res => {
        this.products = res;
      }
    );
  }

}



