import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Customer } from '../../../models/Customer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from 'src/app/services/customer.service';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';

@Component({
  selector: 'app-dialog-customer-form',
  templateUrl: './dialog-customer-form.component.html',
  styleUrls: ['./dialog-customer-form.component.scss']
})
export class DialogCustomerFormComponent implements OnInit {

  customerFormGroup: FormGroup = this._formBuilder.group({
    name: [, Validators.required],
    firstLastName: [, Validators.required],
    secondLastName: [, Validators.required],
    telephone: [,],
    mobile: [, Validators.required],
    email: [, Validators.required],
    birthDate: [, Validators.required],
    birthPlace: [, Validators.required],
    nationality: [, Validators.required],
    CURP: [, Validators.required],
    voterKey: [, Validators.required],
    occupation: [, Validators.required],
    civilStatus: [, Validators.required],
    address: [, Validators.required],
    localidad: [, Validators.required],
    municipality: [, Validators.required],
    state: [, Validators.required],
    postalCode: [, Validators.required],
    RFC: [],
    billing: [0, Validators.required]
  });

  constructor(
    private dialogRef: MatDialogRef<DialogCustomerFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Customer,
    private _formBuilder: FormBuilder,
    private customerService: CustomerService,
    public sweet: SweetAlertComponent
  ) {
    this.dialogRef.disableClose = true;
    this.customer = data;
  }

  customer: any = [];
  civil_status: any = [];

  ngOnInit(): void {
    this.selectCivilStatus();
  }

  //Consultar estado civil
  selectCivilStatus() {
    this.customerService.selectCivilStatus().subscribe(
      res => {
        this.civil_status = res;
      },
      err => console.log(err)
    );
  }

  //Modificar datos del cliente
  updateCustomer(id: number, customer: any){
    if (this.customerFormGroup.invalid){
      return;
    }
    else {
      customer = this.customerFormGroup.value;
      this.customerService.updateCustomer(id,customer).subscribe(
        res => {
          this.sweet.AlertTime("center", "success", "Datos de cliente actualizados con éxito", false, 2000);
          this.dialogRef.close();
        },
        err => {
          console.error(err);
          this.sweet.AlertTime("center", "error", "Error al realizar la operación", false, 2000);
        }
      );
    }
  }

}
