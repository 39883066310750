<div class="container-fluid mt-3">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">

            <div class="userCard">
                <div class="profileContainer">
                    <img *ngIf="userInfo.picture != null && userInfo.picture !=''" [src]="userInfo.picture"
                        style="width: 200px; height: 200px; border-radius: 100%;">
                    <p *ngIf="userInfo.picture == null || userInfo.picture == ''" class="letter">
                        {{userInfo.nombre.substring(0,1)}}</p>
                    <div class="userInfo">
                        <span style="font-size: 2rem;"><b
                                style="color: var(--M19Primary); font-weight: bold;">{{userInfo.nombre}} </b></span>
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span style="font-size: 1.1rem; font-weight: bold;"><b
                                    style="color: var(--M19Primary);"><mat-icon>phone_iphone</mat-icon></b>{{userInfo.mobile==null?
                                'Sin telefono registrado': userInfo.mobile}}</span>
                            <span style="font-size: 1.2rem; font-weight: bold;"><b
                                    style="color: var(--M19Primary);"><mat-icon>alternate_email</mat-icon></b>{{userInfo.email.toLowerCase()}}</span>
                        </div>
                        <span style="font-size: 1.1rem; font-weight: bold; text-align: center;"><b
                                style="color: var(--M19Primary);">Dirección: </b>{{userInfo.address==null? 'Sin
                            dirección
                            registrada': userInfo.address}}</span>
                        <span
                            style="font-size: 1.1rem; font-weight: bold; text-align: center;">De {{userInfo.municipality==null?'':userInfo.municipality}}
                            {{userInfo.state==null?'':userInfo.state}}</span>
                        <span style="font-size: 1.1rem; font-weight: bold; text-align: center;"><b style="color: var(--M19Primary);">Se unió
                                en {{pipe.transform(userInfo.registrationDate,
                                'LLLL')}} de {{pipe.transform(userInfo.registrationDate, 'yyyy')}}</b></span>
                    </div>
                </div>
                <h3
                    style="text-align: center; background-color: var(--M19Primary); border-radius: 0 0 20px 20px; color: white;">
                    <b>{{userInfo.description}}</b><mat-icon>{{userInfo.icon}}</mat-icon>
                </h3>
            </div>
        </mat-card-content>
    </mat-card>
</div>