import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneralReportService {

  private url = 'http://localhost:3000/api';

  constructor(private http: HttpClient) { }



// Comisiones

  getTotalService(date : string): Observable<any>{
    return this.http.get(`${environment.URLServer}/generalReport/getTotalroute/${date}`);
  }

  getTotalperProjectService( nomenclature: string , dateComm : string): Observable<any>{
    return this.http.get(`${environment.URLServer}/generalReport/getTotalperProjectRoute/${nomenclature}/${dateComm}`);
  }

  //Cobranza
  getReportWalletByProjectService( proname: any ): Observable <any> {
    return this.http.post(`${environment.URLServer}/generalReport/reportWalletByProjectRoute`, [proname])
  }

  getTypedColorsService( proname: any ): Observable <any> {
    return this.http.post(`${environment.URLServer}/generalReport/typedColorsRoute/`, [proname])
  }

  //Bloqueados 
  getVoluntaryBlockedService( nomenclature: string ): Observable<any>{
    return this.http.get(`${environment.URLServer}/generalReport/getVoluntaryBlockedRoute/${nomenclature}`);
  }

  getBreachContractService( nomenclature: string ): Observable<any>{
    return this.http.get(`${environment.URLServer}/generalReport/getBreachContractRoute/${nomenclature}`);
  }

  getSalesErrorBlockedService( nomenclature: string ): Observable<any>{
    return this.http.get(`${environment.URLServer}/generalReport/getSalesErrorBlockedRoute/${nomenclature}`);
  }

  getRelocationBlockedService( nomenclature: string ): Observable<any>{
    return this.http.get(`${environment.URLServer}/generalReport/getRelocationBlockedRoute/${nomenclature}`);
  }

  getGeneralManagementBlockedService( nomenclature: string ): Observable<any>{
    return this.http.get(`${environment.URLServer}/generalReport/getGeneralManagementBlockedRoute/${nomenclature}`);
  }


}