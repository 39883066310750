<app-loader [loader]="loader" *ngIf="saving==1"></app-loader>
<mat-tab-group mat-stretch-tabs>
    <!--/////////////////////////-->
    <!-- Registro de requisición -->
    <!--/////////////////////////-->
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">note_add</mat-icon>
            <span>Registrar requisición</span>
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">
                    <div class="contenedorRegistro">
                        <div class="registerFields">
                            <form [formGroup]="requisitionFormGroup" (ngSubmit)="insert()"
                                onKeyPress="if(event.keyCode == 13) event.returnValue = false;">

                                <h5>Información de requisición</h5>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Proyecto</mat-label>
                                        <mat-select [(value)]="selectProjects" formControlName="idProject"
                                            (valueChange)="selectProject($event)" required>
                                            <mat-option *ngFor="let project of projects" [value]="project.idProject">
                                                {{project.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-icon matSuffix>map</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Modulo</mat-label>
                                        <mat-select formControlName="idprojectdet"
                                            (valueChange)="selectDepartures($event);" required>
                                            <mat-option *ngFor="let tower of towers" [value]="tower.idprojectdet">
                                                Modulo {{tower.towernumber}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-icon matSuffix>apartment</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Número de Requisición</mat-label>
                                        <input matInput type="text" formControlName="requisitionnumber" min="1"
                                            maxlength="6"
                                            oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                            onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 72 || event.charCode == 69 || event.charCode == 82)"
                                            required>
                                        <mat-icon matSuffix>numbers</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Fecha de requisición</mat-label>
                                        <input matInput type="date" formControlName="requisitiondate" required>
                                        <mat-icon matSuffix>calendar_month</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Autoriza</mat-label>
                                        <input matInput type="text" formControlName="auth" required>
                                        <mat-icon matSuffix>person</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Revisa</mat-label>
                                        <input matInput type="text" formControlName="review" required>
                                        <mat-icon matSuffix>person</mat-icon>
                                    </mat-form-field>
                                </div>

                                <h5>Información de materiales</h5>

                                <div class="ng-autocomplete">
                                    <ng-autocomplete formControlName="iddeparture" name="busquedaP"
                                        placeholder="Número/Partida" (selected)='selectConcepts($event)'
                                        [data]="departures" [searchKeyword]="keywordP" [itemTemplate]="itemTemplateP"
                                        [notFoundTemplate]="notFoundTemplateP">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplateP let-itemP>
                                        <a [innerHTML]="itemP.departurename"></a>
                                    </ng-template>
                                    <ng-template #notFoundTemplateP let-notFound>
                                        <div>No hay coincidencias de partidas</div>
                                    </ng-template>
                                </div>

                                <div class="ng-autocomplete">
                                    <ng-autocomplete formControlName="idconcept" name="busquedaC"
                                        placeholder="Clave/Concepto" [data]="concepts" [searchKeyword]="keywordC"
                                        [itemTemplate]="itemTemplateC" [notFoundTemplate]="notFoundTemplateP">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplateC let-itemC>
                                        <a [innerHTML]="itemC.value"></a>
                                    </ng-template>
                                    <ng-template #notFoundTemplateC let-notFound>
                                        <div>No hay coincidencias de conceptos</div>
                                    </ng-template>
                                </div>

                                <div class="ng-autocomplete">
                                    <ng-autocomplete formControlName="productname" name="busquedaM"
                                        placeholder="Clave/Material" [data]="products" [searchKeyword]="keywordM"
                                        (selected)='selectProduct($event)' [itemTemplate]="itemTemplateM"
                                        [notFoundTemplate]="notFoundTemplateM">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplateM let-itemM>
                                        <a [innerHTML]="itemM.productname"></a>
                                    </ng-template>
                                    <ng-template #notFoundTemplateM let-notFound>
                                        <div>No hay coincidencias de materiales</div>
                                    </ng-template>
                                </div>

                                <div class="addMaterial" (click)="insertProductDialog()">
                                    Material Nuevo
                                    <mat-icon>add</mat-icon>
                                </div>

                                <br>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Cantidad</mat-label>
                                        <input matInput type="number" formControlName="amount" min="1"
                                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)">
                                        <mat-icon matSuffix>pin</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Unidad</mat-label>
                                        <input matInput type="text" formControlName="unit" readonly>
                                        <mat-icon matSuffix>balance</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Observaciones</mat-label>
                                        <textarea matInput cdkTextareaAutosize formControlName="notes"
                                            cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"></textarea>
                                        <mat-icon matSuffix>edit</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div (click)="addMaterial()" class="addMaterial">
                                    Agregar a requisición
                                    <mat-icon>double_arrow</mat-icon>
                                </div>

                                <button type="submit" class="saveButton" [disabled]="!requisitionFormGroup.valid">
                                    <mat-icon>save</mat-icon>
                                </button>
                            </form>
                        </div>


                        <div class="infoTable">
                            <h5 *ngIf="materials.length>0">Materiales en requisición</h5>
                            <div *ngFor="let mat of materials; index as i" class="materialCard">
                                <div class="cardIzq">
                                    <p><b>Partida</b></p>
                                    <p>{{mat.departurenumber}}</p>
                                </div>
                                <div class="cardDer">
                                    <p><b>Concepto:</b> {{mat.conceptname}}</p>
                                    <p><b>Nombre:</b> {{mat.productname}}</p>
                                    <p><b>Cantidad:</b> {{mat.amount}} {{mat.unit}}</p>
                                    <p><b>Observaciones:</b> {{mat.notes==null?"Sin observaciones":mat.notes==''?"Sin observaciones":mat.notes}}</p>
                                    <button class="btnDelete" mat-icon-button (click)="deleteMaterials(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>

    <!--//////////////////////////////-->
    <!-- Registro de nota de remisión -->
    <!--//////////////////////////////-->
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">receipt</mat-icon>
            <span>Registrar nota de remisión</span>
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">
                    <div class="contenedorRegistro">
                        <div class="registerFields">
                            <form [formGroup]="deliveryFormGroup" (ngSubmit)="insertDelivery()"
                                onKeyPress="if(event.keyCode == 13) event.returnValue = false;">
                                <h5 class="col-12 pb-2">Información de nota de remisión</h5>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Proyecto</mat-label>
                                        <mat-select [(value)]="selectProjects" formControlName="idproject"
                                            (valueChange)="selectProjectR($event)" required>
                                            <mat-option *ngFor="let project of projects" [value]="project.idProject">
                                                {{project.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-icon matSuffix>map</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Modulo</mat-label>
                                        <mat-select formControlName="idprojectdet"
                                            (valueChange)="selectReqList($event);" required>
                                            <mat-option *ngFor="let tower of towersR" [value]="tower.idprojectdet">
                                                Modulo {{tower.towernumber}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-icon matSuffix>apartment</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Requisición</mat-label>
                                        <mat-select [(value)]="selectRequisitions" formControlName="idrequisition"
                                            required>
                                            <mat-option *ngFor="let requisition of requisitions"
                                                [value]="requisition.idrequisition">
                                                {{requisition.nomenclature}}M{{requisition.towernumber}}N{{requisition.requisitionnumber}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-icon matSuffix>list_alt</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Número de folio</mat-label>
                                        <input matInput type="text" formControlName="deliveryfolio" min="1"
                                            onkeypress="return ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 101)"
                                            required>
                                        <mat-icon matSuffix>numbers</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Proveedor</mat-label>
                                        <mat-select [(value)]="selectSuppliers" formControlName="idsupplier" required>
                                            <mat-option *ngFor="let supplier of suppliers"
                                                [value]="supplier.idsupplier">
                                                {{supplier.suppliername}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-icon matSuffix>person</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Número de remisión</mat-label>
                                        <input matInput type="number" formControlName="deliverynumber" min="1"
                                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57)" required>
                                        <mat-icon matSuffix>numbers</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div class="registerRow">
                                    <div class="addMaterial" (click)="insertSupplierDialog()">
                                        Proveedor nuevo
                                        <mat-icon>add</mat-icon>
                                    </div>
                                </div>

                                <br>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Fecha de remisión</mat-label>
                                        <input matInput type="date" formControlName="deliverydate" required>
                                        <mat-icon matSuffix>calendar_month</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Recibido por</mat-label>
                                        <input matInput type="text" formControlName="receiver" required>
                                        <mat-icon matSuffix>person</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Observaciones</mat-label>
                                        <textarea matInput cdkTextareaAutosize formControlName="notes"
                                            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
                                        <mat-icon matSuffix>edit</mat-icon>
                                    </mat-form-field>
                                </div>

                                <h5 class="col-12 pb-2">Productos recibidos</h5>

                                <div class="registerRow">
                                    <div class="ng-autocomplete">
                                        <ng-autocomplete formControlName="productname" name="busquedaM"
                                            placeholder="id/Material" [data]="products" [searchKeyword]="keywordM"
                                            (selected)='selectProduct2($event)' [itemTemplate]="itemTemplateM"
                                            [notFoundTemplate]="notFoundTemplateM">
                                        </ng-autocomplete>
                                        <ng-template #itemTemplateM let-itemM>
                                            <a [innerHTML]="itemM.productname"></a>
                                        </ng-template>
                                        <ng-template #notFoundTemplateM let-notFound>
                                            <div>No hay coincidencias de materiales</div>
                                        </ng-template>
                                    </div>
                                </div>

                                <div class="addMaterial" (click)="insertProductDialog()">
                                    Material Nuevo
                                    <mat-icon>add</mat-icon>
                                </div>

                                <br>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Cantidad</mat-label>
                                        <input matInput type="number" formControlName="amount" min="1"
                                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)">
                                        <mat-icon matSuffix>pin</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Unidad</mat-label>
                                        <input matInput type="text" formControlName="unit" readonly>
                                        <mat-icon matSuffix>balance</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Costo Unitario</mat-label>
                                        <input matInput type="number" min="1" formControlName="unitcost"
                                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 42 || event.charCode == 43 || event.charCode == 45 || event.charCode == 46 || event.charCode == 47)">
                                        <mat-icon matSuffix>paid</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div (click)="addMaterial2()" class="addMaterial">
                                    Agregar a remisión
                                    <mat-icon>double_arrow</mat-icon>
                                </div>

                                <div class="col-12 text-right mt-3">
                                    <button class="saveButton" type="submit" [disabled]="!deliveryFormGroup.valid">
                                        <mat-icon>save</mat-icon>
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div class="infoTable">
                            <h5 *ngIf="materialsD.length>0">Materiales en remisión</h5>
                            <div *ngFor="let mat of materialsD; index as i" class="materialCard">
                                <div class="cardIzq">
                                    <p><b>Material</b></p>
                                    <p>#{{i+1}}</p>
                                </div>
                                <div class="cardDer">
                                    <p><b>Cantidad:</b> {{mat.split("~")[2]}} {{mat.split("~")[3]}}</p>
                                    <p><b>Nombre:</b> {{mat.split("~")[1]}}</p>
                                    <p><b>Costo unitario: </b>${{mat.split("~")[4]}}</p>
                                    <p><b>Subtotal: </b>${{(mat.split("~")[2]*mat.split("~")[4]).toFixed(2)}}</p>
                                    <button class="btnDelete" mat-icon-button (click)="deleteMaterials2(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>

    <!--/////////////////////-->
    <!-- Registro de factura -->
    <!--/////////////////////-->
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">class</mat-icon>
            <span>Registrar Factura</span>
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">
                    <div class="contenedorRegistro">
                        <div class="registerFields">
                            <form [formGroup]="invoiceFormGroup" (ngSubmit)="insertInvoice()"
                                onKeyPress="if(event.keyCode == 13) event.returnValue = false;">

                                <h5 class="col-12 pb-2">Información general de factura</h5>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Remisión</mat-label>
                                        <mat-select [(value)]="selectDelivery" formControlName="deliveryfolio"
                                            (valueChange)="deliverySelected = $event" required>
                                            <mat-option *ngFor="let delivery of deliveries"
                                                [value]="delivery.deliveryfolio">
                                                Folio {{delivery.deliveryfolio}} Número {{delivery.deliverynumber}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-icon matSuffix>numbers</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Proveedor</mat-label>
                                        <mat-select [(value)]="selectSuppliers" formControlName="idsupplier" required>
                                            <mat-option *ngFor="let supplier of suppliers"
                                                [value]="supplier.idsupplier">
                                                {{supplier.suppliername}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-icon matSuffix>person</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div class="addMaterial" (click)="insertSupplierDialog()">
                                    Agregar nuevo proveedor
                                    <mat-icon>add</mat-icon>
                                </div>

                                <br>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Número de factura</mat-label>
                                        <input matInput type="text" formControlName="invoicenumber" required>
                                        <mat-icon matSuffix>numbers</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Fecha de factura</mat-label>
                                        <input matInput type="date" formControlName="invoicedate" required>
                                        <mat-icon matSuffix>calendar_month</mat-icon>
                                    </mat-form-field>
                                </div>

                                <h5 class="col-12 pb-2">Productos facturados</h5>

                                <div class="registerRow">
                                    <div class="ng-autocomplete">
                                        <ng-autocomplete formControlName="productname" name="busquedaM"
                                            placeholder="id/Material" [data]="products" [searchKeyword]="keywordM"
                                            (selected)='selectProduct3($event)' [itemTemplate]="itemTemplateM"
                                            [notFoundTemplate]="notFoundTemplateM">
                                        </ng-autocomplete>
                                        <ng-template #itemTemplateM let-itemM>
                                            <a [innerHTML]="itemM.productname"></a>
                                        </ng-template>
                                        <ng-template #notFoundTemplateM let-notFound>
                                            <div>No hay coincidencias de materiales</div>
                                        </ng-template>
                                    </div>
                                </div>

                                <div class="addMaterial" (click)="insertProductDialog()">
                                    Material Nuevo
                                    <mat-icon>add</mat-icon>
                                </div>

                                <br>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Cantidad</mat-label>
                                        <input matInput type="number" formControlName="amount" min="1"
                                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)">
                                        <mat-icon matSuffix>pin</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Unidad</mat-label>
                                        <input matInput type="text" formControlName="unit" readonly>
                                        <mat-icon matSuffix>balance</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Costo Unitario</mat-label>
                                        <input matInput type="number" min="1" formControlName="unitcost"
                                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)">
                                        <mat-icon matSuffix>paid</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div (click)="addMaterial3()" class="addMaterial">
                                    Agregar a factura
                                    <mat-icon>double_arrow</mat-icon>
                                </div>

                                <br>

                                <button class="saveButton" type="submit" [disabled]="!invoiceFormGroup.valid">
                                    <mat-icon>save</mat-icon>
                                </button>

                                <div class="payResume">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Subtotal</mat-label>
                                        <input matInput type="number" formControlName="subtotal" readonly>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>IVA</mat-label>
                                        <input matInput type="text" formControlName="iva" readonly>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Total</mat-label>
                                        <input matInput type="number" formControlName="total" readonly>
                                    </mat-form-field>
                                </div>
                            </form>

                            <div (click)="copyMaterials()" *ngIf="deliverySelected !== ''" class="copyButton">
                                <mat-icon>control_point_duplicate</mat-icon>Espejear Materiales
                            </div>
                        </div>

                        <div class="infoTable">
                            <h5 *ngIf="materialsF.length>0">Materiales en factura</h5>
                            <div *ngFor="let mat of materialsF; index as i" class="materialCard">
                                <div class="cardIzq">
                                    <p><b>Material</b></p>
                                    <p>#{{i+1}}</p>
                                </div>
                                <div class="cardDer">
                                    <p><b>Cantidad:</b> {{mat.split("~")[2]}} {{mat.split("~")[3]}}</p>
                                    <p><b>Nombre:</b> {{mat.split("~")[1]}}</p>
                                    <p><b>Costo unitario: </b>${{mat.split("~")[4]}}</p>
                                    <p><b>Subtotal: </b>${{(mat.split("~")[2]*mat.split("~")[4]).toFixed(2)}}</p>
                                    <button class="btnDelete" mat-icon-button (click)="deleteMaterials3(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

    </mat-tab>

</mat-tab-group>