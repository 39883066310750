import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { SalesService } from '../../services/sales.service';
import { Payment } from '../../models/Payment';
import { PaymentService } from '../../services/payment.service';
import { ProjectService } from 'src/app/services/project.service';
import { Router } from '@angular/router';
import { TotalPagadosService } from '../../services/totalPagados.service';
import { ReportService } from '../../services/report.service';

import { BlockSaleDialogComponent } from './block-sale-dialog/block-sale-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-graficos',
  templateUrl: './graficos.component.html',
  styleUrls: ['./graficos.component.scss']
})
export class GraficosComponent implements OnInit {


  //Nomenclatura inmueble
  M = "Manzana No."
  L = "Lote No."
  T = "Torre No."
  D = "Departamento No."

  //Manejo de información res API, arreglos con carga de datos de las consultas a los servicios que conectan con la API
  restantes: any = [];
  sales: any = []
  projects: any = [];
  montos: any = [];
  montosListado: any = [];
  cartera: any = [];
  carteraInmu: any = [];
  cartDes: any = [];

  //Banderas de impresión de datos particulares de acuerdo al modulo
  inmobiliaria = true
  VP = false
  X = false
  desVP() {
    return this.VP = true, this.inmobiliaria = false, this.X = false
  }

  desX() {
    return this.X = true, this.VP = false
  }
  inmu() {
    return this.inmobiliaria = true, this.VP = false, this.X = false
  }
  


  // Configuraciones de graficos
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
      display: true,
      fullWidth: true
    },
    showLines: true,
    tooltips: {
      enabled: true,
      caretSize: 0,
      displayColors: true,      
      titleFontSize:20,
      bodyFontSize:20
    },
    spanGaps: true,
    plugins: {
      datalabels: {
        formatter: (value: any, ctx: any) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    },

  };
  public piePruebaOptions: ChartDataSets = {
    showLine: true
  }
  public pieChartLabels: Label[] = [['Pagados'], ['Valor total del desarrollo'], ['Retraso']];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgb(12,149,182)', 'rgb(220, 146, 24)', 'rgb(197, 34, 15)'],
    },
  ];

  //Grafico y control de pagos
  situacion = ''
  pagEsp = 3
  pagRea = 21
  limite = -4
  tolerancia = -1
  tolerancia2 = -2
  tolerancia3 = -3

  limiteRe = 4
  toleranciaRe = 1
  tolerancia2Re = 2
  tolerancia3Re = 3

  //Objeto de servicio pagos
  payments: any = [];
  payment: Payment = {
    id: 0,
    fechaPago: '',
    cantidadAbono: 0,
    estadoPago: '',
    metodoPago: '',
    folioPago: 0,
    concepto: '',
    fkCliente: ''
  };



  constructor(private salesServices: SalesService, private reportService: PaymentService,private reportServices: ReportService, private dialog: MatDialog,
    private projectService: ProjectService, private totalPagados: TotalPagadosService, private router: Router) { }

  //Metodo de carga de metodos al momento de la ejecución
  ngOnInit(): void {
    this.selectProjects()
    this.selectTotales()
    this.selectTotalesListado()

  }

  //Consulta de datos desde el servicio que consulta a la API
  selectProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
        console.log(res)
      },
      err => console.log(err)
    );
  }
  selectSalesDesarrollo(desarrollo: any) {

    this.salesServices.selectSalesDesarrollo(desarrollo).subscribe(
      res => {
        this.sales = res;

      },
      err => console.log(err)
    );
  }
  selectSalesInmobiliaria() {
    this.salesServices.selectSalesInmobiliaria().subscribe(
      res => {


        this.sales = res;
      },
      err => console.log(err)
    );
  }
  selectSales(codInmueble: any) {
    this.salesServices.selectSales(codInmueble).subscribe(
      res => {

        this.sales = res;

      },
      err => console.log(err)
    );
  }
  slectPayments(cliente: string) {
    this.reportService.selectPayments(cliente).subscribe(
      res => {

        this.payments = res;
      },
      err => console.log(err)
    );
  }

  selectTotales() {
    this.totalPagados.totalPagados().subscribe(
      res => {
        console.log(res)
        this.montos = res;
      },
      err => console.log(err)
    );
  }
  selectTotalesListado() {
    this.totalPagados.totalPagadosListado().subscribe(
      res => {

        this.montosListado = res;
      },
      err => console.log(err)
    );
  }
  selectTotalesDesarrollo(liDes: any) {
    this.totalPagados.selectPagosDesarrollo(liDes).subscribe(
      res => {

        this.montos = res;
      },
      err => console.log(err)
    );
  }

  cancelPayment(id: any) {

    delete this.payment.id;
    delete this.payment.fechaPago
    delete this.payment.estadoPago
    this.reportService.updatePaymentCanceled(id, this.payment)
      .subscribe(
        res => {

          this.router.navigate(['payments'])
        },
        err => console.error(err)
      );
  }

  deletePayment(id: any) {

    this.reportService.deletePayment(id).subscribe(data => {
      this.slectPayments(id);
    }, error => {
      console.log(error);
      this.router.navigate(['payments'])
    })
  }
  //Consulta listado inmuebles

  reporteInmuCartera(carInmu:string) {
    this.reportServices.reporteCarteraInmueble(carInmu).subscribe(
      res => {        
        
        this.carteraInmu = res;
      },
      err => console.log(err)
    );
  }
  reporteDesCartera(carDes:string) {
    this.reportServices.reporteCarteraDesarrollo(carDes).subscribe(
      res => {        
        
        this.cartDes = res;
      },
      err => console.log(err)
    );
  }

  blockSale(inmueble: number, retraso: number) {
    this.dialog.open(BlockSaleDialogComponent, {
      data: {"inmueble": inmueble, "retraso": retraso},
      width: "100%"
    });
  }
}

