<div class="row mt-3">
    <div class="ng-autocomplete" style="padding-left: 1cm; padding-top: 1cm; padding-bottom: 1cm;">
        <ng-autocomplete name="busqueda" placeholder="Inmueble/Nombre cliente" [data]="sal" [searchKeyword]="keyword"
        (selected)="onKeypressEvent($event)" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>
        <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
        </ng-template>
    </div>
    <div style="padding-top: 1.3cm;">
        <span>
            <mat-icon>search</mat-icon>
        </span>
    </div>
</div>

<div >
    <div class="mat-card">
        <mat-card>
            <mat-card-content *ngFor="let venta of carteraInmu">
                <table style="width:100%">
                    <thead>
                        <tr>
                            <th><h5>Desarrollo:</h5> <h5 class="_h5">{{venta.name}}</h5></th>
                            <th><h5>Cliente:</h5> <h5 class="_h5">{{venta.clientes}}</h5></th>
                            <th><h5>Inmueble:</h5> <h5 class="_h5">{{venta.codeProperty}}</h5></th>
                        </tr>
                    </thead>


                    <thead *ngFor="let ventas of salesCoo">
                        <tr>
                            <h5 *ngIf="ventas.coopropietario!=null">Coopropietario:</h5>
                            <h5 class="_h5" *ngIf="ventas.coopropietario!=null">{{ventas.coopropietario}}</h5>
                        </tr>
                    </thead>

                    <thead>
                        <tr>
                            <th><h5>Fecha de contrato:</h5> <h5 class="_h5">{{venta.fechaDato | date: 'dd-MM-yyyy'}}</h5></th>
                            <th> <h5 *ngIf="venta.mobile!=null">Celular:</h5>  <h5 class="_h5">{{venta.mobile}}</h5></th>
                            <th> <h5>Correo electrónico:</h5> <h5 class="_h5">{{venta.email}}</h5></th>
                        </tr>
                    </thead>

                    <thead>
                        <tr>
                            <th><h5>Valor total:</h5>  <h5 class="_h5"> {{venta.valor | currency:'CAD' : '$ '}}</h5></th>
                        </tr>
                    </thead>
                    
                    <thead>
                        <tr>
                            <th><h5>Dirección fiscal:</h5>  <h5 class="_h5"> {{venta.fiscalAddress}}</h5></th>
                            <th><h5>Código postal:</h5>  <h5 class="_h5"> {{venta.fiscalpc}}</h5></th>
                            <th><h5>Regimen fiscal:</h5>  <h5 class="_h5"> {{venta.fiscalRegime}}</h5></th>
                        </tr>
                    </thead>

                    <thead>
                        <tr>
                            <th><h5>Método de facturación: <mat-icon class="editBtn" *ngIf="permissions().match('446576656c6f706572')" (click)="editInvoiceMethod(venta.fkinvoiceType, venta.fiscalFolio, venta.codeProperty)">edit</mat-icon></h5> <h5 class="_h5"> {{venta.fk_invoiceType}}</h5></th>
                            <th><h5>Folio fiscal:</h5> <h5 class="_h5"> {{venta.fiscalFolio}}</h5></th>
                        </tr>
                    </thead>
                </table>

                <table class="table table-hover mt-3">
                    <thead>
                        <tr align="center">
                            <td>Pagos realizados</td>
                            <td>Estado de pagos</td>
                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr align="center">
                            <td> <a>{{venta.mPagadas+'/'+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(0)}}</a>
                            </td>

                            <td>
                                {{venta.retraso.toFixed(2)}}
                            </td>
                        </tr>

                    </tbody>
                </table>

            </mat-card-content>
            <mat-card-footer *ngIf="desCancRecibo">
                <h5>Razón de cancelación</h5>
                <div class="form-group">
                    <textarea class="form-control" name="razones" [(ngModel)]="payment.reason" [(ngModel)]="razon"
                        id="exampleFormControlTextarea1" rows="3"></textarea>
                </div>
            </mat-card-footer>

        </mat-card>
    </div>

    <div >
        <mat-card>
            <mat-card-content>
                <table class="table table-striped animated fadeIn faster">
                    <thead class="thead-m19">
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">Folio</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Método de pago</th>
                            <th scope="col">Concepto</th>
                            <th scope="col">Saldo Anterior</th>
                            <th scope="col">Saldo Insoluto</th>
                            <th style="text-align: center;" scope="col">Factura Realizada</th>
                            <th style="text-align: center;" scope="col">Cancelar factura</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let payment of payments; let i=index;">
                            <th>{{i+1}}</th>
                            <th>{{payment.folioPago}}</th>
                            <td>{{payment.fechaPago | date: 'yyyy-MM-dd'}}</td>
                            <td>{{(payment.cantidadAbono | currency:'CAD' : '$ ')}}</td>
                            <td>{{payment.metodoPago}}</td>
                            <td>{{payment.concepto}}</td>
                            <td>{{payAfter[i] | currency:'CAD' : '$ '}}</td>
                            <td>{{payBefore[i] | currency:'CAD' : '$ '}}</td>
                            <th style="text-align: center;">
                                <mat-icon  title="Factura realizada" style="color:rgb(0, 155, 64)" *ngIf="payment.invoiceStatus=='Aprobada'"> verified</mat-icon>
                                <mat-icon  title="Factura Pendiente" style="color:rgb(255, 51, 64)" *ngIf="payment.invoiceStatus=='Pendiente'"> new_releases</mat-icon>
                                <mat-icon  title="Factura no existente" style="color:rgb(255, 242, 0)" *ngIf="payment.invoiceStatus==null"> warning</mat-icon>
                            </th>
                            <td><mat-icon class="deleteBtn" *ngIf="payment.idFacturado != null && payment.invoiceStatus == 'Aprobada'" (click)="setWaiting(payment.idFacturado,payment.id, 1)">backspace</mat-icon></td>
                        </tr>
                    </tbody>
                </table>
            </mat-card-content>
        </mat-card>
    </div>
</div>