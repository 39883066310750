import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Property } from '../models/Property';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {


  private url = 'http://localhost:3000/api';


  constructor(private http: HttpClient) { }

  //Consultar todos los inmuebles
  selectProperties() {
    return this.http.get(`${environment.URLServer}/properties`);
  }

  //Consultar el inmueble específicado
  selectProperty(codeProperty: string) {
    return this.http.get(`${environment.URLServer}/properties/property/${codeProperty}`);
  }

  //Consultar un inmueble especifico (campos especificos)
  _selectProperty(codeProperty: string) {
    return this.http.get(`${environment.URLServer}/properties/_property/${codeProperty}`);
  }

  //Consultar los inmuebles disponibles del proyecto específicado
  selectPropertiesProject(fk_project: string) {
    return this.http.get(`${environment.URLServer}/properties/projects/${fk_project}`);
  }

  //Consultar los inmuebles del cliente específicado
  selectPropertiesCustomer(customer: any) {
    return this.http.get(`${environment.URLServer}/properties/properties_customer/${customer}`);
  }

  //Registrar inmueble
  createProperty(property: Property) {
    return this.http.post(`${environment.URLServer}/properties`, property);
  }

  //Modificar inmueble
  updateProperty(codeProperty: string, update: Property): Observable<Property> {
    return this.http.put(`${environment.URLServer}/properties/update/${codeProperty}`, update);
  }

  //Modificar inmueble
  updatePropertyStatus(codeProperty: string, update: Property): Observable<Property> {
    return this.http.put(`${environment.URLServer}/properties/${codeProperty}`, update);
  }

  //Eliminar inmueble
  deleteProperty(codeProperty: string) {
    return this.http.delete(`${environment.URLServer}/properties/${codeProperty}`);
  }

  /*REPORTE DE INMUEBLES*/
  project_disponibility() {
    return this.http.get(`${environment.URLServer}/properties/project_disponibility`)
  }

  property_disponibility(IDproperty: number) {
    return this.http.get(`${environment.URLServer}/properties/property_disponibility/${IDproperty}`)

  }

  /*COTIZADOR */
  getPropertiesList(id: number) {
    return this.http.get(`${environment.URLServer}/properties/getPropertiesList/${id}`)
  }

  getPropertyData(id: number) {
    return this.http.get(`${environment.URLServer}/properties/getPropertyData/${id}`)
  }
}
