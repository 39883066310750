<h1>{{data.codeProperty}}</h1>

<mat-dialog-content>
    <table class="table table-striped mt-3 animated fadeIn faster">
        <tr>
            <th>Proyecto</th>
            <td>{{data.project}}</td>
        </tr>
        <tr *ngIf="data.amount != null">
            <th>Valor total</th>
            <td>{{data.amount | currency:'CAD':'$ '}}</td>
        </tr>
        <tr *ngIf="data.advancePayment != null">
            <th>Anticipo</th>
            <td>{{data.advancePayment | currency:'CAD':'$ '}}</td>
        </tr>
        <tr *ngIf="data.monthlyPayment != null">
            <th>Pago mensual</th>
            <td>{{data.monthlyPayment | currency:'CAD':'$ '}}</td>
        </tr>
        <tr>
            <th>Superficie</th>
            <td>{{data.area}} m<sup>2</sup></td>
        </tr>
        <tr>
            <th>Estatus</th>
            <td>{{data.estatus}}</td>
        </tr>
        <tr>
            <th>Coordenada X</th>
            <td>{{data.coordinateX}}</td>
        </tr>
        <tr>
            <th>Coordenada Y</th>
            <td>{{data.coordinateY}}</td>
        </tr>
        <tr>
            <th>Colindancia noreste</th>
            <td>{{data.northeasBorder}}</td>
        </tr>
        <tr>
            <th>Colindancia suroeste</th>
            <td>{{data.southwestBorder}}</td>
        </tr>
        <tr>
            <th>Colindancia noroeste</th>
            <td>{{data.northwestBorder}}</td>
        </tr>
        <tr>
            <th>Colindancia sureste</th>
            <td>{{data.southeastBorder}}</td>
        </tr>
        <tr *ngIf="data.description != null">
            <th>Notas</th>
            <td>{{data.description}}</td>
        </tr>
    </table>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>