import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';
import { DepartureService } from 'src/app/services/departure.service';
import { DeparturerunService } from 'src/app/services/departurerun.service';

import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';

@Component({
  selector: 'app-departurecontrol',
  templateUrl: './departurecontrol.component.html',
  styleUrls: ['./departurecontrol.component.scss']
})
export class DeparturecontrolComponent implements OnInit {
  bannerTitle: BannerTitle = {
    title: "Apertura de partidas",
    icon: "fact_check",
    position: "center",
    background: "var(--M19Secundary)"
  }

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  filterDeparture = '';

  projects: any = [];
  modules: any = [];
  departures: any = [];
  departuresActive: any = [];

  idprojectdet: any = "";

  constructor(
    public projectService: ProjectService,
    public projectdetService: ProjectdetailsService,
    public departureService: DepartureService,
    public departurerunService: DeparturerunService
  ) { }

  ngOnInit(): void {
    this.getProjects();
    this.selectDepartures();
  }

  async getProjects(){
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      }
    );
  }

  async getModulesOfProject(id:number){
    this.idprojectdet = '';
    this.projectdetService.selectDetailsById(id).subscribe(
      res => {
        this.modules = res;
      }
    );
  }

  async enableDeparture(id:number){
    this.departurerunService.setDepartureOn({"idprojectdet": this.idprojectdet, "iddeparture": id, "useremail": this.getUser()}).subscribe(
      res => {
        this.getHistory(this.idprojectdet);
      }
    );
  }

  async disableDeparture(id:number){
    this.departurerunService.setDepartureOff(id).subscribe(
      res => {
        this.getHistory(this.idprojectdet);
      }
    );
  }

  async selectDepartures(){
    this.departureService.selectDepartures().subscribe(
      res => {
        this.departures = res;
      }
    );
  }

  async getHistory(id:number){
    this.idprojectdet = id;
    this.departurerunService.selectHistory(id).subscribe(
      res => {
        this.departuresActive = res;
      }
    );
  }

  //==========================================================================================================
  //Recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.email
  }

}
