import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators } from '@angular/forms';
import { CustomerService } from 'src/app/services/customer.service';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-registeruser',
  templateUrl: './dialog-registeruser.component.html',
  styleUrls: ['./dialog-registeruser.component.scss']
})
export class DialogRegisteruserComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DialogRegisteruserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customerService: CustomerService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public sweet: SweetAlertComponent,
    private router: Router

  ) {
  }

  ngOnInit(): void {
    this.selectCivilStatus()
  }

  customers: any = [];
  customer: any = [];
  civil_status: any = [];

  customerFormGroup: FormGroup = this._formBuilder.group({
    idUser: [],
    name: [, Validators.required],
    firstLastName: [, Validators.required],
    secondLastName: [, Validators.required],
    telephone: [,],
    mobile: [, Validators.required],
    email: [, Validators.required],
    birthDate: [, Validators.required],
    birthPlace: [, Validators.required],
    nationality: [, Validators.required],
    CURP: [, Validators.required],
    voterKey: [, Validators.required],
    occupation: [, Validators.required],
    civilStatus: [, Validators.required],
    address: [, Validators.required],
    localidad: [, Validators.required],
    municipality: [, Validators.required],
    state: [, Validators.required],
    postalCode: [, Validators.required],
    fk_typeUser: [3],
    RFC: [],
    billing: [0, Validators.required],
    precustomer:[1]
  });



  //Registrar un cliente
  createCustomer() {
    if (this.customerFormGroup.invalid) {
      return;
    }
    else {
      this.customerService.createCustomer(this.customerFormGroup.value)
        .subscribe(
          res => {
            this.customer = res
            localStorage.setItem('Venta digital: ',this.customer)
            console.log(res,"RES")
            console.log(this.customer, "CUSTOMER")
            this.sweet.AlertTime("center", "success", "Cliente registrado con éxito", false, 2000);
          },
          err => {
            console.error(err)
          }
        );
    }
  }

    //Consultar estado civil
    selectCivilStatus() {
      this.customerService.selectCivilStatus().subscribe(
        res => {
          this.civil_status = res;
        },
        err => console.log(err)
      );
    }
 

}


