<h3>Agregando nota a {{data.fkProperty}}</h3>

<mat-dialog-content>
    <app-loader [loader]="loader" *ngIf="saving==1"></app-loader>

    <span style="font-size: 0.8rem;">Hola <b style="color: var(--M19Primary);">{{user}}</b></span>
    <mat-form-field appearance="outline" class="col-md-12">
        <mat-label>Nota</mat-label>
        <form [formGroup]="notesFormGroup">
            <textarea matInput formControlName="noteDes" type="text" name="notas" placeholder="El cliente dió promesa de pago para el dia..."></textarea>
        </form>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end" style="gap: 10px;">
    <button mat-dialog-close class="m19AltBtn">Cerrar</button>
    <button class="m19Btn" (click)='createNote()' [disabled]="!notesFormGroup.valid || saving==1">Registrar nota</button>
</mat-dialog-actions>