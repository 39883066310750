<div class="row mt-2">
    <div class="ng-autocomplete" style="padding-left: 1.7cm; padding-top: 1.1cm; padding-bottom: 1.5cm;">
        <ng-autocomplete name="busqueda" placeholder="Inmueble/Nombre cliente" [data]="listUsers"
            [searchKeyword]="keyword" (keyup.enter)="onKeypressEvent($event)" [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
        </ng-template>
    </div>
    <div style="padding-left: .2cm;padding-top: 1.3cm;">
        <span>
            <mat-icon>search</mat-icon>
        </span>
    </div>
</div>

<div class="row _card">
    <div class="col-md-4">
        <mat-card>
            <mat-card-content *ngFor="let venta of carteraInmu">
                <table>
                    <thead>
                        <tr>
                            <h5>Desarrollo:</h5>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <h5 class="_h5">{{venta.name}}</h5>
                        </tr>
                    </tbody>

                    <table *ngIf="venta.codeProperty.substr(2,1)=='M'">
                        <tr>
                            <th>
                                <h5>{{M}}</h5>
                            </th>
                            <th>
                                <h5 class="_h5">{{venta.codeProperty.substr(3,2)}}</h5>
                            </th>
                            <th></th>
                            <th>
                                <h5>{{L}}</h5>
                            </th>
                            <th>
                                <h5 class="_h5">{{venta.codeProperty.substr(6,2)}}</h5>
                            </th>
                        </tr>
                    </table>

                    <table *ngIf="venta.codeProperty.substr(2,1)=='T'">
                        <tr>
                            <th>
                                <h5>{{T}}</h5>
                            </th>
                            <th>
                                <h5 class="_h5">{{venta.codeProperty.substr(3,2)}}</h5>
                            </th>
                            <th></th>
                            <th>
                                <h5>{{D}}</h5>
                            </th>
                            <th>
                                <h5 class="_h5">{{venta.codeProperty.substr(6,2)}}</h5>
                            </th>
                        </tr>
                    </table>

                    <thead>
                        <tr>
                            <h5>Cliente:</h5>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <h5 class="_h5">{{venta.clientes}}</h5>
                        </tr>
                    </tbody>

                    <thead *ngFor="let ventas of salesCoo">
                        <tr>
                            <h5 *ngIf="ventas.coopropietario!=null">Coopropietario:</h5>
                        </tr>
                    </thead>
                    <tbody *ngFor="let ventas of salesCoo">
                        <tr>
                            <h5 class="_h5" *ngIf="ventas.coopropietario!=null">{{venta.coopropietario}}</h5>
                        </tr>
                    </tbody>

                    <thead>
                        <tr>
                            <h5>Vendedor:</h5>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <h5 class="_h5">{{venta.usuarios}}</h5>
                        </tr>
                    </tbody>

                    <thead>
                        <tr>
                            <h5>RFC:</h5>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <h5 class="_h5">{{venta.RFC}}</h5>
                        </tr>
                    </tbody>

                    <thead>
                        <tr>
                            <h5 *ngIf="venta.mobile!=null">Celular:</h5>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <h5 class="_h5">{{venta.mobile}}</h5>
                        </tr>
                    </tbody>

                    <thead>
                        <tr>
                            <h5>Correo electrónico:</h5>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <h5 class="_h5">{{venta.email}}</h5>
                        </tr>
                    </tbody>

                    <thead>
                        <tr>
                            <h5>Fecha de contrato:</h5>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <h5 class="_h5">{{venta.fechaDato | date: 'dd-MM-yyyy'}}</h5>
                        </tr>
                    </tbody>
                </table>

                <table>
                    <tr>
                        <th>
                            <h5>Valor total:</h5>
                        </th>
                        <th>
                            <h5 class="_h5"> {{venta.valor | currency:'CAD' : '$ '}}</h5>
                        </th>
                    </tr>
                </table>

                <div class="row mt-2" style="padding-left: .4cm;">
                    <div style="padding-right: 1cm;">
                        <span class="input-group-text" id="entradaPagado"
                            style="background-color: brown; border-radius:.3cm; color: aliceblue; height: 1cm; width: auto;">
                            <b> Abonado</b>
                        </span>
                        <div>
                            <h5> {{venta.abonado | currency:'CAD' : '$ '}} </h5>
                        </div>
                    </div>
                    <div>
                        <span class="input-group-text" id="entradaPagado"
                            style="background-color: brown; border-radius:.3cm; color: aliceblue; height: 1cm; width: auto;"><b>Adeudo</b></span>
                        <div>
                            <h5> {{venta.valor-venta.abonado | currency:'CAD' : '$ '}} </h5>
                        </div>
                    </div>
                </div>

                <table class="table table-hover mt-3">
                    <thead>
                        <tr>
                            <td>Pagos realizados</td>
                        </tr>
                    </thead>
                    <tbody *ngFor="let pago of totales">
                        <tr>
                            <td> <a>{{venta.mPagadas+'/'+(venta.valor-venta.advancePayment)/venta.monthlyPayment}}</a>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-8">
        <mat-card>
            <mat-card-title>
                <h5>Global</h5>
            </mat-card-title>
            <mat-card-content>
                <table class="table table-striped animated fadeIn faster">
                    <thead class="thead-m19">
                        <tr>
                            <th scope="col">Fecha</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Folio</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let invoiceGlobal of global;">
                            <td>{{invoiceGlobal.registrationDate | date: 'dd-MM-yyyy'}}</td>
                            <td>{{(invoiceGlobal.amountInvoice | currency:'CAD' : '$ ')}}</td>
                            <th style="color: brown;">{{invoiceGlobal.accountingFolio}}</th>
                        </tr>
                    </tbody>
                </table>
            </mat-card-content>
        </mat-card>
        <br>
        <mat-card>
            <mat-card-title>
                <h5>Complementos</h5>
            </mat-card-title>
            <mat-card-content>
                <table class="table table-striped animated fadeIn faster">
                    <thead class="thead-m19">
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Tipo Pago</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Folio recibo</th>
                            <th scope="col">Folio factura</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let invoiceComplemento of complemento; let i=index">
                            <th style="color: brown;">{{i+1}}</th>
                            <td>{{invoiceComplemento.registrationDate | date: 'dd/MM/yyyy'}}</td>
                            <td>{{invoiceComplemento.paymentType}}</td>
                            <td>{{(invoiceComplemento.amountInvoice | currency:'CAD' : '$ ')}}</td>
                            <th>{{invoiceComplemento.folInvoice}}</th>
                            <th *ngFor="let invoiceGlobal of global;" style="color: brown;">
                                {{invoiceComplemento.accountingFolio+"/"+invoiceGlobal.accountingFolio}}</th>
                        </tr>
                    </tbody>
                </table>
            </mat-card-content>
        </mat-card>
    </div>
</div>