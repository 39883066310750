<mat-card [hidden]="dataInformation.option == 'cancelar' " style="overflow: scroll;">
    <mat-card-title>
        <div>
            <app-banner-title [banner]="bannerTitleReprint"></app-banner-title>
        </div>
    </mat-card-title>

    <mat-card-content>

        <table class="table table-striped mt-3 animated fadeIn faster"
            style="text-align:center; height: 100; align-content: border= 1 ;">
            <thead class="thead-m19">
                <tr>

                    <th scope="col" align="center"> Folio </th>
                    <th scope="col" align="center"> Propiedad </th>
                    <th scope="col" align="center"> Fecha pago </th>
                    <th scope="col" align="center"> paymentAmount </th>
                    <th scope="col" align="center"> paymentConcept </th>
                    <th scope="col" align="center"> Imprimir </th>

                </tr>
            </thead>

            <tbody>

                <tr *ngFor="let c of comisiones;">

                    <td> {{c.folio}} </td>
                    <td> {{c.fk_property}} </td>
                    <td style="font-weight: bolder;"> {{c.fechapago | date: 'dd-MM-yyyy'}} </td>
                    <td> {{c.paymentAmount}} </td>
                    <td> {{c.paymentConcept}} </td>

                    <td>
                        <mat-icon class="btn-m19-info" (click)="reimprimir(c)" data-title="Reimprimir"> print </mat-icon>
                    </td>

                </tr>

            </tbody>

        </table>


        <br>
        <br>
        <div class="col-md-12" style="display:flex; text-align:center; width: 100%;">
            <button class="m19Btn" style="width: 100%;"  (click)="close()" > Cerrar  </button>
        </div>
    </mat-card-content>

</mat-card>


<mat-card [hidden]="dataInformation.option == 'reimprimir' " style="overflow: scroll;">
    <mat-card-title>
        <div>
            <app-banner-title [banner]="bannerTitleCancel"></app-banner-title>
        </div>
    </mat-card-title>

    <mat-card-content style="overflow: scroll;">

        <table class="table table-striped mt-3 animated fadeIn faster"
            style="text-align:center; height: 100; align-content: border= 1; ">
            <thead class="thead-m19">
                <tr>
                    <th scope="col" align="center"> Folio </th>
                    <th scope="col" align="center"> Propiedad </th>
                    <th scope="col" align="center"> Fecha pago </th>
                    <th scope="col" align="center"> paymentAmount </th>
                    <th scope="col" align="center"> paymentConcept </th>
                    <th scope="col" align="center"> Borrar </th>
                </tr>
            </thead>

            <tbody>

                <tr *ngFor="let c of comisiones;">

                    <td> {{c.folio}} </td>
                    <td> {{c.fk_property}} </td>
                    <td style="font-weight: bolder;" > {{c.fechapago | date: 'dd-MM-yyyy'}} </td>
                    <td> {{c.paymentAmount}} </td>
                    <td> {{c.paymentConcept}} </td>

                    <td>
                        <mat-icon class="btn-m19-table" (click)="cancelar(c);" data-title="Eliminar"> delete
                        </mat-icon>
                    </td>

                </tr>

            </tbody>

        </table>

        <div class="col-md-12" style="display:flex; text-align:center; width: 100%;">
            <button class="m19Btn" style="width: 100%;"  (click)="close()" > Cerrar  </button>
        </div>


        <div style="margin-top: 50px;" [hidden]="hid == true">

            <form [formGroup]="comissionCancelFormGroup">
          
            <mat-form-field appearance="outline" class="col-md-3">
                <mat-label>  Folio </mat-label>
                <input matInput type="text" [value]="dat.folio" placeholder="Folio"  readonly>
                <mat-icon matSuffix> tag </mat-icon>
            </mat-form-field>

            
            <mat-form-field appearance="outline" class="col-md-3">
                <mat-label>  Propiedad </mat-label>
                <input matInput type="text" [value]="dat.fk_property"  placeholder="Propiedad"  readonly>
                <mat-icon matSuffix> apartment </mat-icon>
            </mat-form-field>

            
            <mat-form-field appearance="outline" class="col-md-3">
                <mat-label>  Cantidad </mat-label>
                <input matInput type="text" [value]="dat.paymentAmount" placeholder="Cantidad" readonly >
                <mat-icon matSuffix> paid </mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-3">
                <mat-label>  Fecha Pago </mat-label>
                <input matInput type="text" [value]="pipe.transform(dat.fechapago , ('dd-MM-yyyy'))" placeholder="Fecha Pago" readonly >
                <mat-icon matSuffix>  calendar_today </mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-3">
                <mat-label> Concepto </mat-label>
                <input matInput type="text" [value]="dat.paymentConcept" placeholder="Concepto" readonly >
                <mat-icon matSuffix> feed </mat-icon>
            </mat-form-field>
 
            <mat-form-field appearance="outline" class="col-md-9">
                <mat-label>  Motivo de cancelación </mat-label>
                <input matInput type="text" formControlName="modificationNotes" placeholder="Motivo de cancelación" required >
                <mat-icon matSuffix> block </mat-icon>
            </mat-form-field>

            <div class="col-md-12" style="display:flex; text-align:center; width: 100%;">
                 <button class="m19Btn" style="width: 100%;" type="submit" (click)="resetField()" [disabled]="!comissionCancelFormGroup.valid"> Dar de baja el folio </button>
            </div>

        </form>
        </div>

    </mat-card-content>

</mat-card>