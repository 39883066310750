//Importaciones de componentes
import { Component, OnInit } from '@angular/core';
import { Chart, ChartOptions } from 'chart.js';
import { MatDialog } from '@angular/material/dialog';

//Importación de servicios
import { PieceworkService } from 'src/app/services/piecework.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';

//Importación de componentes
import { DetailOverageComponent } from './detail-overage/detail-overage.component';

@Component({
  selector: 'app-pieceworkreport',
  templateUrl: './pieceworkreport.component.html',
  styleUrls: ['./pieceworkreport.component.scss']
})

export class PieceworkreportComponent implements OnInit {
  //Arreglos para almacenar datos traídos de la BD
  projects: any = [];
  modules: any = [];
  pieceworks: any = [];
  modulesdetail: any = [];
  pieceworksdetail: any =[];

  totals: any = [];

  //Arreglo de los datos de pago por proyecto
  infoP: any = { idProject: '', name: '', Total: '', Excedente: '' };

  //Arreglo de los datos de pago por modulo
  infoM: any = { idprojectdet: '', towernumber: '', Total: '', Excedente: '' };

  //Bandera para saber si se debe desplegar la gráfica o no
  projectDet: any = false;

  moduleDet: any = false;

  constructor(
    //Declaración de servicios utilizados
    private pieceworkService: PieceworkService,
    private projectService: ProjectService,
    private projectdetailService: ProjectdetailsService,

    //======================================================================
    //Declaración del componente gráfico utilizado para cuadro de dialogo
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getProjects();
    this.getTotalProjects();
  }

  async getTotalProjects() {
    this.pieceworkService.TotalProjects().subscribe(
      res => {
        this.totals = res;
        let values = [];
        let labels = [];
        for (let t of this.totals) {
          values.push(t.Total);
          labels.push(t.name);
        }
        this.chartConfig("comparativeChart", "doughnut", values, labels);
      }
    );
  }

  //Función de configuración del gráfico
  async chartConfig(canva: string, type: string, values: any, labels: any) {
    //Referencia al objeto canva HTML al que se carga el gráfico
    const canvas = document.getElementById(canva) as HTMLCanvasElement;

    //Configuración de fuente de la gráfica
    Chart.defaults.global.defaultFontFamily = "Arial";
    Chart.defaults.global.defaultFontSize = 14;

    //Asignación de información
    const graphData = {
      labels: labels,
      datasets: [{
        data: values,
        backgroundColor: 'rgb(255, 147, 147, 0.5)',
        hoverBackgroundColor: 'rgb(211, 62, 62)',
        borderColor: 'rgb(211, 62, 62)',
        borderWidth: 2
      }]
    };

    //Configuración básica de gráfico
    const chartOptions: ChartOptions = {
      responsive: true,
      legend: {
        display: false
      }
    };

    //Generación del gráfico
    const graph = new Chart(canvas, {
      type: type,
      data: graphData,
      options: chartOptions
    });
  }

  color_aleatorio() {
    let hexadecimal = new Array("0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F")
    let color_aleatorio = "#";
    for (let i = 0; i < 6; i++) {
      let posarray = Math.floor(Math.random() * (hexadecimal.length));
      color_aleatorio += hexadecimal[posarray]
    }
    return color_aleatorio
  }


  //Función para traer el catálogo de proyectos registrados en la BD
  async getProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      }
    );
  }

  //Función para traer los totales de gastos por proyecto
   async getTotalProject(id: number) {
    this.pieceworkService.getTotalByProject(id).subscribe(
      res => {
        if (res === null) {
          this.infoP = { idProject: '', name: '', Total: '' };
          return;
        }
        this.infoP = res;
        console.log(this.infoP)
      }
    );
  }

   //Función para traer los totales de gastos por proyecto
   async getTotalModule(id: number) {
    this.pieceworkService.getTotalByModule(id).subscribe(
      res => {
        if (res === null) {
          this.infoM = { idprojectdet: '', name: '', towernumber: '', Total: '' };
          return;
        }
        this.infoM = res;
        console.log(this.infoM)
      }
    );
  }

  //Función para traer los modulos de determinado proyecto
  async getModules(id: number) {
    this.projectdetailService.selectDetailsById(id).subscribe(
      res => {
        this.modules = res;
      }
    );
  }

  //Función para alternar entre desplegar o no la gráfica
  async desgloceDatosProject(id:number) {
    this.projectDet = !this.projectDet;
    this.pieceworkService.getTotalByModules(id).subscribe(
      res => {
        this.modulesdetail = res;
        console.log(this.modulesdetail);
        if (this.projectDet==true) {
          let values = [];
          let labels = [];
          for (let m of this.modulesdetail) {
            values.push(m.Total);
            labels.push("Módulo "+m.towernumber);
          }
          this.chartConfig("pieceworkChart", "horizontalBar", values, labels);
        }
      }
    );
  }

   //Función para alternar entre desplegar o no la gráfica
   async desgloceDatosModule(id:number) {
    this.moduleDet = !this.moduleDet;
    this.pieceworkService.getTotalByPieceworks(id).subscribe(
      res => {
        this.pieceworksdetail = res;
        console.log(this.pieceworksdetail)
        if (this.moduleDet==true) {
          let values = [];
          let labels = [];
          for (let p of this.pieceworksdetail) {
            values.push(p.Total);
            labels.push(p.descriptions);
            
          }
          this.chartConfig("piecework2Chart", "bar", values, labels);
        }
      }
    );
  }

  //Función para abrir ventana modal del detalle de excesos
  async seeOverageDetail(id: number) {
    this.dialog.open(DetailOverageComponent, {
      data: id,
      width: "100%"
    });
  }
}
