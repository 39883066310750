<app-loader [loader]="loader" *ngIf="searching==1"></app-loader>
<mat-dialog-content>
    <div class="leadsContainer__view">

        <h5 class="titleText" style="text-align: center;">LISTA DE LEADS</h5>
        <div class="leadsContainer__view__prospects">
            <p *ngIf="prospects.length==0" style="text-align: center; font-size: 1.5rem;">Sin registros
                <mat-icon>sentiment_dissatisfied</mat-icon>
            </p>
            <div *ngFor="let case of prospects"
                [ngClass]="{'leadsContainer__view__prospects__case--assigned': case.secondResponsable != null, 'leadsContainer__view__prospects__case--unassigned': case.secondResponsable == null}">
                <div (click)="details(case.idlead, case.secondResponsable)" style="cursor: pointer;">
                    <p style="color: var(--subtitleTextColor);"><b style="color: var(--M19Primary);">Prospecto:</b>
                        {{case.name}}
                        {{case.firstLastName}}
                        {{case.secondLastName}}</p>
                    <p style="color: var(--subtitleTextColor);"><b style="color: var(--M19Primary);">Interesado
                            en</b> {{case.interest}}</p>
                    <p style="color: var(--subtitleTextColor);"><b>{{case.secondResponsable != null?
                            'Asignado a ' + case.secondResponsable : 'Sin asignar'}}</b></p>
                    <p style="color: var(--subtitleTextColor);"><b style="color: var(--M19Primary);">Registrado
                            por</b> {{case.responsable}}</p>
                    <p style="color: var(--subtitleTextColor);"><b style="color: var(--M19Primary);">El
                            día
                            {{pipe.transform(case.registrationdate, 'longDate')}}</b></p>
                    <p style="color: var(--subtitleTextColor);"><b
                            style="color: var(--M19PrimaryHover); font-weight: bolder;">El limite de
                            atención es el
                            día
                            {{pipe.transform(case.limitAttention, 'longDate')}}</b></p>
                </div>
                <div style="display: flex; gap: 20px;">
                    <button class="m19AltBtn action" (click)="addComment(case.idlead)"><span
                            class="description">Comentar</span><mat-icon>forum</mat-icon></button>
                    <button class="m19AltBtn action" (click)="seeTimeline(case.idlead)"><span
                            class="description">Historia</span><mat-icon>timeline</mat-icon></button>
                    <button class="m19AltBtn action" *ngIf="case.secondResponsable!=null"
                        (click)="giveMoreTime(case)"><span
                            class="description">Prórroga</span><mat-icon>timer</mat-icon></button>
                    <button class="m19AltBtn action" *ngIf="case.secondResponsable!=null"
                        (click)="reassignLead(case)"><span
                            class="description">Reasignar</span><mat-icon>change_circle</mat-icon></button>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>