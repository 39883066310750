import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LenderService } from 'src/app/services/lender.service';
import { ProspectClientService } from 'src/app/services/prospect-client.service';



@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {
  
  isLinear = true;

  personalInformationGroup: FormGroup = this._formBuilder.group({
    idRequest: [],
    DateRequest: [Date],

    lenderExists: false,
    idLender: null,

    firstName:  ['', Validators.required],
    FirstLastName:  ['', Validators.required],
    SecondLastName:  ['', Validators.required],

    nationality:  ['', Validators.required],

    birthDate:  ['', Validators.required],
    birthPlace:  ['', Validators.required],

    maritalStatus:  ['', Validators.required],
    occupation:  ['', Validators.required],
    CURP:  ['', Validators.required],
    electorKey:  ['', Validators.required],
  });

  residenceGroup: FormGroup = this._formBuilder.group({
    address: ['', Validators.required],
    location: ['', Validators.required],
    CP:  ['', Validators.required],
    municipality: ['', Validators.required],
    state: ['', Validators.required],
  });


  propertyData: FormGroup = this._formBuilder.group({
    idProject: ['', Validators.required],
    block:  ['', Validators.required],
    batch:  ['', Validators.required],
  });

  contactData: FormGroup = this._formBuilder.group({
    mobileNumber:  ['', Validators.required],
    email:  ['', Validators.required],
  });

  constructor(
    private lenderService: LenderService, 
    private PropspectClientService: ProspectClientService, 
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
  }

  createProspect(){

  }

}
