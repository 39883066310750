//=========================================================================================================
//Importación de componentes necesarios para esta pantalla
//=========================================================================================================
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PieceworkService } from 'src/app/services/piecework.service';

@Component({
  selector: 'app-detail-overage',
  templateUrl: './detail-overage.component.html',
  styleUrls: ['./detail-overage.component.scss']
})
export class DetailOverageComponent implements OnInit {

  info: any = [];

  constructor(
    private dialogRef: MatDialogRef<DetailOverageComponent>,
    private pieceworkService: PieceworkService,

    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    /*this.pieceworkService.getPieceworkOverage(this.data).subscribe(
      res => {
        this.info = res;
      }
    );*/
  }

}
