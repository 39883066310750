<!------------------------------------ PRESUPUESTO DE MATERIALES ---------------------------->
<mat-tab-group mat-stretch-tabs>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">note_add</mat-icon>
            <span>Registro de presupuestos de materiales</span>
        </ng-template>
        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">
                    <div class="contenedorRegistro">
                        <div class="registerFields">
                            <form [formGroup]="budgetFormGroup" (ngSubmit)="insert()"
                                onKeyPress="if(event.keyCode == 13) event.returnValue = false;">
                                <h5>Información de presupuesto</h5>
                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Proyecto</mat-label>
                                        <mat-select [(value)]="selectProjects" formControlName="idProject"
                                            (valueChange)="selectProject($event)" required>
                                            <mat-option *ngFor="let project of projects" [value]="project.idProject">
                                                {{project.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-icon matSuffix>map</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Modulo</mat-label>
                                        <mat-select formControlName="idprojectdet" required>
                                            <mat-option *ngFor="let tower of towers" [value]="tower.idprojectdet">
                                                Modulo {{tower.towernumber}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-icon matSuffix>apartment</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Fecha de presupuesto</mat-label>
                                        <input matInput type="date" formControlName="budgetdate" required>
                                        <mat-icon matSuffix>calendar_month</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Analista</mat-label>
                                        <input matInput type="text" id="analyst" formControlName="analyst">
                                        <mat-icon matSuffix>person</mat-icon>
                                    </mat-form-field>
                                </div>

                                <mat-form-field appearance="outline" [hidden]="true" readonly>
                                    <mat-label>Responsable </mat-label>
                                    <input matInput type="text" formControlName="responsable" readonly>
                                </mat-form-field>

                                <h5>Información de materiales</h5>

                                <div class="ng-autocomplete">
                                    <ng-autocomplete formControlName="iddeparture" name="busquedaP"
                                        placeholder="id/Partida" (selected)='selectConcepts($event)' [data]="departures"
                                        [searchKeyword]="keywordP" [itemTemplate]="itemTemplateP"
                                        [notFoundTemplate]="notFoundTemplateP">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplateP let-itemP>
                                        <a [innerHTML]="itemP.departurename"></a>
                                    </ng-template>
                                    <ng-template #notFoundTemplateP let-notFound>
                                        <div>No hay coincidencias de partidas</div>
                                    </ng-template>
                                </div>

                                <div class="ng-autocomplete">
                                    <ng-autocomplete formControlName="idconcept" name="busquedaC" placeholder="Concepto"
                                        [data]="concepts" [searchKeyword]="keywordC" [itemTemplate]="itemTemplateC"
                                        [notFoundTemplate]="notFoundTemplateP">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplateC let-itemC>
                                        <a [innerHTML]="itemC.descriptions"></a>
                                    </ng-template>
                                    <ng-template #notFoundTemplateC let-notFound>
                                        <div>No hay coincidencias de conceptos</div>
                                    </ng-template>
                                </div>

                                <div class="ng-autocomplete">
                                    <ng-autocomplete formControlName="productname" name="busquedaM"
                                        placeholder="id/Material" [data]="products" [searchKeyword]="keywordM"
                                        (selected)='selectProduct($event)' [itemTemplate]="itemTemplateM"
                                        [notFoundTemplate]="notFoundTemplateM">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplateM let-itemM>
                                        <a [innerHTML]="itemM.productname"></a>
                                    </ng-template>
                                    <ng-template #notFoundTemplateM let-notFound>
                                        <div>No hay coincidencias de materiales</div>
                                    </ng-template>
                                </div>
                                <div class="addMaterial" (click)="insertProductDialog()">
                                    Registrar nuevo material
                                    <mat-icon>add</mat-icon>
                                </div>
                                <br>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Unidad</mat-label>
                                        <input matInput type="text" formControlName="unit" readonly>
                                        <mat-icon matSuffix>balance</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Costo</mat-label>
                                        <input matInput type="number" id="costo" formControlName="unitcost" min="1"
                                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57  || event.charCode == 46)"
                                            (input)="recalculateTotal()">
                                        <mat-icon matSuffix>attach_money</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Cantidad</mat-label>
                                        <input matInput type="number" formControlName="amount" min="1"
                                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)"
                                            (input)="recalculateTotal()">
                                        <mat-icon matSuffix>pin</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Subtotal</mat-label>
                                        <input matInput type="number" formControlName="totalp" readonly>
                                        <mat-icon matSuffix>paid</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div (click)="addMaterial()" class="addMaterial">
                                    Agregar a presupuesto
                                    <mat-icon>double_arrow</mat-icon>
                                </div>

                                <div class="col-12 text-right mt-3">
                                    <mat-form-field appearance="outline" class="col-md-3" style="width: 110px;">
                                        <mat-label>Total</mat-label>
                                        <input matInput type="number" id="costo" formControlName="total" readonly>
                                    </mat-form-field>
                                    <!-- Botón para guardar un presupuesto -->
                                    <button class="btn btn-m19" type="submit" [disabled]="!budgetFormGroup.valid">
                                        <mat-icon>save</mat-icon>
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div class="infoTable">
                            <h5 *ngIf="materials.length>0">Materiales en presupuesto</h5>
                            <div *ngFor="let mat of materials; index as i;" class="materialCard">
                                <div class="cardIzq">
                                    <p><b>Partida</b></p>
                                    <p>#{{mat.split("~")[8]}}</p>
                                </div>
                                <div class="cardDer">
                                    <p><b>Concepto:</b> {{mat.split("~")[2]}}</p>
                                    <p><b>Nombre:</b> {{mat.split("~")[4]}}</p>
                                    <p><b>Costo unitario: </b>${{mat.split("~")[5]}}</p>
                                    <p><b>Cantidad: </b>{{mat.split("~")[6]}} {{mat.split("~")[9]}}</p>
                                    <p><b>Subtotal: </b>${{mat.split("~")[7]}}</p>
                                    <!-- Botón para eliminar un producto de un presupuesto -->
                                    <button class="btnDelete" mat-icon-button (click)="deleteMaterials(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>
    <mat-tab>

        <!------------------------------------ PRESUPUESTO DE DESTAJOS ---------------------------->
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">engineering</mat-icon>
            <span>Registro de presupuesto de destajos</span>
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">
                    <div class="contenedorRegistro">
                        <div class="registerFields">
                            <h5 class="col-12 pb-2">Registro del presupuesto de destajos</h5>
                            <form class="row mt-3" [formGroup]="budgetpieceFormGroup" (ngSubmit)="pinsert()"
                                onKeyPress="if(event.keyCode == 13) event.returnValue = false;">
                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Proyecto</mat-label>
                                        <mat-select [(value)]="selectProjects" formControlName="idProject"
                                            (valueChange)="pselectProject($event)" required>
                                            <mat-option *ngFor="let project of projects" [value]="project.idProject">
                                                {{project.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-icon matSuffix>map</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Modulo</mat-label>
                                        <mat-select formControlName="idprojectdet" required>
                                            <mat-option *ngFor="let tower of towers" [value]="tower.idprojectdet">
                                                Modulo {{tower.towernumber}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-icon matSuffix>apartment</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Fecha de presupuesto</mat-label>
                                        <input matInput type="date" formControlName="budgetdate" required>
                                        <mat-icon matSuffix>calendar_month</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Analista</mat-label>
                                        <input matInput type="text" id="analyst" formControlName="analyst">
                                        <mat-icon matSuffix>person</mat-icon>
                                    </mat-form-field>
                                </div>
                                <mat-form-field appearance="outline" [hidden]="true" readonly>
                                    <mat-label>Responsable </mat-label>
                                    <input matInput type="text" formControlName="responsable" readonly>
                                </mat-form-field>

                                <h5>Información general de los presupuestos</h5>

                                <div class="ng-autocomplete">
                                    <ng-autocomplete formControlName="iddeparture" name="busquedaP"
                                        placeholder="id/Partida" [data]="departures" [searchKeyword]="keywordP"
                                        [itemTemplate]="itemTemplateP" [notFoundTemplate]="notFoundTemplateP">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplateP let-itemP>
                                        <a [innerHTML]="itemP.departurename"></a>
                                    </ng-template>
                                    <ng-template #notFoundTemplateP let-notFound>
                                        <div>No hay coincidencias de partidas</div>
                                    </ng-template>
                                </div>


                                <div class="ng-autocomplete">
                                    <ng-autocomplete formControlName="typepw" name="busquedaPi" placeholder="Destajo"
                                        [data]="pieceworks" [searchKeyword]="keywordPi"
                                        (selected)='selectPiecework($event)' [itemTemplate]="itemTemplatePi"
                                        [notFoundTemplate]="notFoundTemplatePi">
                                    </ng-autocomplete>
                                    <ng-template #itemTemplatePi let-itemPi>
                                        <a [innerHTML]="itemPi.pieceworkname"></a>
                                    </ng-template>
                                    <ng-template #notFoundTemplatePi let-notFound>
                                        <div>No hay coincidencias de destajos</div>
                                    </ng-template>
                                </div>


                                <br>
                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Unidad</mat-label>
                                        <input matInput type="text" formControlName="unit" readonly>
                                        <mat-icon matSuffix>balance</mat-icon>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Costo</mat-label>
                                        <input matInput type="number" id="costo" formControlName="unitcost" min="1"
                                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57  || event.charCode == 46)"
                                            (input)="precalculateTotal()">
                                        <mat-icon matSuffix>attach_money</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="registerRow">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Cantidad</mat-label>
                                        <input matInput type="number" formControlName="amount" min="1"
                                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)"
                                            (input)="precalculateTotal()">
                                        <mat-icon matSuffix>pin</mat-icon>
                                    </mat-form-field>


                                    <mat-form-field appearance="outline">
                                        <mat-label>Subtotal</mat-label>
                                        <input matInput type="number" formControlName="totalp" readonly>
                                        <mat-icon matSuffix>paid</mat-icon>
                                    </mat-form-field>

                                </div>
                                <div (click)="addPiecework()" class="addMaterial">
                                    Agregar a presupuesto
                                    <mat-icon style="color:red;">double_arrow</mat-icon>
                                </div>

                                <div class="col-12 text-right mt-3">
                                    <mat-form-field appearance="outline" class="col-md-3" style="width: 110px;">
                                        <mat-label>Total</mat-label>
                                        <input matInput type="number" id="costo" formControlName="total" min="1"
                                            onkeypress="return (event.charCode >= 48 && event.charCode <= 57  || event.charCode == 46)"
                                            readonly>
                                    </mat-form-field>
                                    <button class="btn btn-m19" type="submit" [disabled]="!budgetpieceFormGroup.valid">
                                        <mat-icon>save</mat-icon>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="infoTable">
                            <h5 *ngIf="pieces.length>0">Destajos en presupuesto</h5>
                            <div *ngFor="let mat of pieces; index as i;" class="materialCard">
                                <div class="cardIzq">
                                    <p><b>Partida</b></p>
                                    <p>#{{mat.split("~")[6]}}</p>
                                </div>
                                <div class="cardDer">
                                    <p><b>Destajo:</b> {{mat.split("~")[2]}}</p>
                                    <p><b>Costo unitario:</b> {{mat.split("~")[3]}}</p>
                                    <p><b>Cantidad: </b>${{mat.split("~")[4]}}</p>
                                    <p><b>Unidad: </b>{{mat.split("~")[7]}} {{mat.split("~")[9]}}</p>
                                    <p><b>Subtotal: </b>${{mat.split("~")[5]}}</p>
                                    <!-- Botón para eliminar un producto de un presupuesto -->
                                    <button class="btnDelete" mat-icon-button (click)="deletepieces(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>
</mat-tab-group>