import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequidepaService {

  constructor(private http: HttpClient) { }

  //Consultar todas las relaciones requisición - partida
  selectRequiDepa(): Observable<any> {
    return this.http.get(`${environment.URLServer}/requi_depa/getAll`);
  }

  //Consultar todas las relaciones requisición - partida
  selectDepaByReq(id:Number): Observable<any> {
    return this.http.get(`${environment.URLServer}/requi_depa/selectByReq/`+id);
  }

  //Consultar todas las relaciones requisición - partida
  insertRequiDepa(idr:Number, idd:Number, user:String): Observable<any> {
    return this.http.post(`${environment.URLServer}/requi_depa/insert`, [idr,idd,user]);
  }
}