<div class="reqContenedor" (scroll)="onScroll()">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">

            <div class="buttonsBelt">
                <button *ngFor="let p of projects" class="m19Btn projectBtn"
                    (click)="projectSelected = p.idProject; filterByProject(p.idProject); projectName = p.name;">
                    {{p.name}} <mat-icon>apartment</mat-icon>
                </button>
            </div>

            <div class="buttonsBelt">
                <button *ngFor="let m of modules" class="m19AltBtn towerBtn"
                    (click)="moduleSelected = m.idprojectdet; filterByModule(m.idprojectdet); module = m.towernumber;">
                    {{m.towernumber}} <mat-icon>apartment</mat-icon>
                </button>
            </div>

            <div *ngIf="requisitions=='Sin seleccionar' ||  requisitions.length==0"
                style="display: flex; flex-direction: column; height: 40vh; justify-content: center; align-items: center; text-align: center;">
                <h2 *ngIf="requisitions == 'Sin seleccionar'">Seleccione un
                    proyecto para visualizar sus requisiciones</h2>
                <p *ngIf="requisitions == 'Sin seleccionar'">
                    <mat-icon style="font-size: 2rem;">search</mat-icon>
                </p>
                <h2 *ngIf="requisitions.length==0">Este proyecto no tiene
                    requisiciones registradas</h2>
                <p *ngIf="requisitions.length==0">
                    <mat-icon style="font-size: 2rem;">sentiment_very_dissatisfied</mat-icon>
                </p>
            </div>

            <br>

            <div class="dataBelt" *ngIf="requisitions!='Sin seleccionar' &&  requisitions.length>0">
                <span>{{projectName}} <mat-icon style="color:#D33E3E;">apartment</mat-icon></span>
                <span>MODULO: {{module}}<mat-icon style="color:#D33E3E;">house</mat-icon></span>
                <span>CANT. REQ.: {{requisitions.length}} <mat-icon style="color:#D33E3E;">numbers</mat-icon></span>
            </div>

            <mat-form-field *ngIf="requisitions.length!=0 && requisitions!='Sin seleccionar'" style="width: 100%;">
                <mat-label>Buscar requisición</mat-label>
                <input matInput type="text" placeholder="Ingresar el número de requisición"
                    [(ngModel)]="filterRequisitions" name="filterRequisitions">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>

            <div class="reqContainer" *ngIf="requisitions.length!=0 && requisitions!='Sin seleccionar'">
                <div class="reqContainer__requisitionCard"
                    *ngFor="let requisition of requisitions  | filterRequisitions: filterRequisitions">
                    <span
                        style="border-left: solid 5px var(--M19Primary); font-size: 1rem; padding: 3px; font-weight: bold;">
                        {{this.pipe.transform(requisition.requisitiondate,
                        'longDate')}}</span>
                    <div class="reqContainer__requisitionCard__reqInfo">
                        <span><b
                                style="color: var(--M19PrimaryHover); font-size: 1rem;">#{{requisition.requisitionnumber}}</b></span>
                        <span
                            style="font-size: 1rem; padding: 3px; font-weight: bold; color: var(--M19Secundary);">{{requisition.deliveries>0?'Contiene
                            ' + requisition.deliveries + ' remisiones' : 'Sin remisiones'}}</span>
                    </div>

                    <div class="reqContainer__requisitionCard__reqResponsables">
                        <span style="font-size: 1rem;"><b style="color: var(--M19Primary);">Registró</b>
                            {{requisition.responsable}}</span>
                        <span style="font-size: 1rem;"><b style="color: var(--M19Primary);">Autoriza</b>
                            {{requisition.auth}}</span>
                    </div>

                    <div class="reqContainer__requisitionCard__reqActions">
                        <button class="m19InvBtn" data-title="Ver detalle"
                            (click)="selectDeparture(requisition.idrequisition)">
                            <mat-icon>info</mat-icon>
                        </button>

                        <button class="m19InvBtn" data-title="Nota de remisión"
                            (click)="selectDelivery(requisition.idrequisition)">
                            <mat-icon>receipt_long</mat-icon>
                        </button>

                        <button
                            *ngIf="permissions().match('RequisitionEdit') || permissions().match('446576656c6f706572')"
                            class="m19InvBtn" data-title="Editar"
                            (click)="updateRequisition(requisition.idrequisition,requisition.idproyecto)">
                            <mat-icon>edit</mat-icon>
                        </button>

                        <button
                            *ngIf="permissions().match('RequisitionDelete') || permissions().match('446576656c6f706572')"
                            class="m19InvBtn" data-title="Eliminar"
                            (click)="deleteRequisition(requisition.idrequisition)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>

                    <div class="reqContainer__requisitionCard__reqStatus">
                        <div
                            [ngClass]="{'reqContainer__requisitionCard__reqStatus--approved': requisition.approve=='SI', 'reqContainer__requisitionCard__reqStatus--rejected': requisition.approve=='NO', 'reqContainer__requisitionCard__reqStatus--pending': requisition.approve=='NA'}">
                            <span>{{requisition.approve=='SI'? 'Aprobada' : requisition.approve=='NO'? 'Rechazada' :
                                requisition.approve=='NA'? 'Pendiente' : 'Error'}}</span>
                            <mat-icon>{{requisition.approve=='SI'? 'verified' : requisition.approve=='NO'? 'cancel' :
                                requisition.approve=='NA'? 'hourglass_empty' : 'help'}}</mat-icon>
                        </div>
                    </div>
                </div>
            </div>

        </mat-card-content>
    </mat-card>
    <button [ngClass]="{'goTopBtn--show': showButton, 'goTopBtn--hide': !showButton}"
        (click)="scrollToTop()"><mat-icon>expand_less</mat-icon></button>
</div>