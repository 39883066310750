import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class WalletV2Service {

  constructor(private http: HttpClient) { }

  getWalletPerProyectService(nameProject : string) : Observable <any> {
    return this.http.post(`${environment.URLServer}/walletV2/carteraProyecto/` , {nameProject})
  }

  setAgreement(code: string, note: any): Observable <any> {
    return this.http.put(`${environment.URLServer}/walletV2/carteraConvenio/${code}`, note);
  }
  
  getNotesReport(): Observable <any> {
    return this.http.get(`${environment.URLServer}/walletV2/carteraNotes`);
  }

}