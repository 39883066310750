<body>
    <div class="tabAndForm">
        <form class="formulario" [formGroup]="consultForm" (ngSubmit)="filtrar()"
            onKeyPress="if(event.keyCode == 13) event.returnValue = false;">

            <h5 style="text-align: left; width: 100%;">Filtrar (Opcional)</h5>
            <hr size="8px" noshade="noshade" color="#d33e3e" style="width: 100%; margin-top: 0%;">
            <div style="display: flex; flex-direction: row; width: 100%; gap: 10px;">
                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Proyecto (El módulo es necesario)</mat-label>
                    <mat-select formControlName="idProject" (valueChange)="selectProject($event)">
                        <mat-option *ngFor="let project of projects" [value]="project.idProject">
                            {{project.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 100px;">
                    <mat-label>Módulo</mat-label>
                    <mat-select formControlName="fk_idprojectDet">
                        <mat-option *ngFor="let tower of towersOfProject" [value]="tower.idprojectdet">
                            {{tower.towernumber}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Folio (Opcional)</mat-label>
                <input matInput type="number" formControlName="folio" minlength="2" maxlength="6">
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Desde el día: (Opcional)</mat-label>
                <input matInput type="date" formControlName="tokenDate">
            </mat-form-field>
            <h5 style="text-align: left; width: 100%;"><mat-icon>info</mat-icon>   Información general</h5>
            <hr size="8px" noshade="noshade" color="#d33e3e" style="width: 100%; margin-top: 0%;">
            <div class="textInfo">
                <p>Proyecto: <b style="color:brown;">{{infoGeneral.project}}</b></p>
                <p>Módulo: <b style="color:brown;">{{infoGeneral.module}}</b></p>
                <p>Folio: <b style="color:brown;">{{infoGeneral.folio}}</b></p>
                <p>Fecha: <b style="color:brown;">{{infoGeneral.Fecha}}</b></p>
            </div>
            <hr size="8px" noshade="noshade" color="#d33e3e" style="width: 100%; margin-top: 0%;">
            <div class="selectores">
                <button class="botonSelector" type="submit">
                    <p class="textoDelBoton">
                        <mat-label>Aplicar filtro</mat-label>
                        <mat-icon>search</mat-icon>
                    </p>
                </button>
                <div class="botonSelector" (click)="getAllConsumerToken()">
                    <p class="textoDelBoton">
                        <mat-label>Limpiar filtros</mat-label>
                        <mat-icon>select_all</mat-icon>
                    </p>
                </div>
            </div>
        </form>

        <div class="divTabla">
            <h5 style="text-align: left; width: 100%;">Lista de vales de consumo</h5>
            <hr size="8px" noshade="noshade" color="#d33e3e" style="width: 100%; margin-top: 0%;">
            <p class="warning" [hidden]="consumerTokens.length!=0"> No hay información que mostrar, limpie o aplique filtros distintos</p>
            <div class="onlyTable" [hidden]="consumerTokens.length==0">
                <table class="table table-striped mt-3 animated fadeIn faster">
                    <thead class="thead-m19">
                        <tr style="text-align:center;">
                            <th scope="col">Proyecto</th>
                            <th scope="col">Modulo</th>
                            <th scope="col">Folio</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Detalles</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ct of consumerTokens" style="text-align:center;">
                            <td>{{ct.projectname}}</td>
                            <td>{{ct.towernumber}}</td>
                            <td>{{ct.folio}}</td>
                            <td>{{pipe.transform(ct.tokenDate,'longDate')}}</td>
                            <td class="button-row">
                                <button class="btn btn-m19-table" title="Ver detalle" mat-icon-button
                                    (click)="consumerTokenInfo(ct.idConsumerToken)">
                                    <mat-icon>info</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</body>