import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Payment } from '../models/Payment';
import { Observable } from 'rxjs';
import { invoiceReport } from '../models/invoiceReport';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private url = 'http://localhost:3000/api';


  constructor(private http: HttpClient) { }

  selectPayments(cliente: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/${cliente}`);
  }

  countPayments(): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/contador`);
  }
  sumTotal(code: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/totales/${code}`);
  }
  sumTotalGeneral(code: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/totalGeneral`);
  }
  selectPayment(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/${id}`);
  }

  getResume(prop: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/getResume/${prop}`);
  }

  createPayment(payment: Payment) {
    return this.http.post(`${environment.URLServer}/payments`, payment);
  }
  updatePayment(id: string, update: Payment): Observable<Payment> {
    return this.http.put(`${environment.URLServer}/payments/${id}`, update);
  }
  updatePaymentCanceled(id: string, update: Payment): Observable<Payment> {
    return this.http.put(`${environment.URLServer}/payments/${id}`, update);
  }

  deletePayment(id: string): Observable<any> {
    return this.http.delete(`${environment.URLServer}/payments/${id}`);
  }

  loginPayment(username: string, password: string): Observable<any> {
    return this.http.post<Payment>(`${environment.URLServer}/login`, { username, password });
  }

  getTicket(id: any): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/getTicket/${id}`);
  }

  verifyPayment(fechaPago: any, cantidadAbono: any, folioPago: any, fkCliente: any): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/verifyPayment/${fechaPago}/${cantidadAbono}/${folioPago}/${fkCliente}`);
  }

  calculateRest(fkCliente: string, fechaPago: any): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/calculateRest/${fkCliente}/${fechaPago}`);
  }

  createRatPayment(obj: any): Observable<any> {
    return this.http.post(`${environment.URLServer}/payments/createRatPayment`, obj);
  }

  setFolioPaymentNC(id: number, folio: string): Observable<any> {
    return this.http.put(`${environment.URLServer}/payments/setFolioPaymentNC/${id}`, [folio]);
  }

  getTotalsNCPayments(inm: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/getTotalsNCPayments/${inm}`);
  }

  getPayments(cliente: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/getPayments/${cliente}`);
  }

  getPaymentsR(inmueble: String, cliente: Number): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/getPaymentsR/${inmueble}/${cliente}`);
  }

  getCreditPayments(inmueble: String): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/getCreditPayments/${inmueble}`);
  }

  getCreditNRPayments(inmueble: String): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/getCreditNRPayments/${inmueble}`);
  }

  getIndividualCreditPayments(inmueble: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/getIndividualCreditPayments/${inmueble}`);
  }

  writePaymentLog(log: any): Observable<any> {
    return this.http.post(`${environment.URLServer}/payments/writePaymentLog`, log);
  }

  getPaymentLog(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/getPaymentLog/${id}`);
  }

  getTransferDaily(date: Date): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/getTransferValidateCountDaily/${date}`);
  }
  getTransferRange(initial: string, final: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/getTransferValidateCountRange/${initial}/${final}`);
  }

  getAllPayments(cliente: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/getAllPayments/${cliente}`);
  }

  getPaymentTotal(property: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/getPaymentTotal/${property}`);
  }
  
  getPaymentTotalRes(property: string, customer: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/getPaymentTotalRes/${property}/${customer}`);
  }

  getCreditNoteTotal(property: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/payments/getCreditNoteTotal/${property}`);
  }
}