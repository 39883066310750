import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import * as $ from 'jquery';
import { DatePipe } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import decode from 'jwt-decode';

import { ConvertNumber } from 'src/app/utils/Misc/ConvertNumber';
import { MonthName } from 'src/app/utils/Misc/MonthName';

import { AdministrationService } from 'src/app/services/administration.service';

import { CommentsViewComponent } from '../comments-view/comments-view.component';
import { NegotiationDetailComponent } from '../negotiation-detail/negotiation-detail.component';
import { ScheduleViewComponent } from './schedule-view/schedule-view.component';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-payback-scheduling',
  templateUrl: './payback-scheduling.component.html',
  styleUrls: ['./payback-scheduling.component.scss']
})
export class PaybackSchedulingComponent implements OnInit {

  constructor(
    private negotiationService: AdministrationService,
    private dialog: MatDialog
  ) { }

  decode: any = {};
  filterNegotiations = '';
  filterSchedule = '';
  filterFinished = '';

  convertNumber = new ConvertNumber();
  monthName = new MonthName();

  pipe = new DatePipe('es-MX');
  cbrLogo: any = "https://firebasestorage.googleapis.com/v0/b/m19inmobiliaria-f6068.appspot.com/o/logos%2FConstructora%2FASESORIA%2C%20SUPERVISI%C3%93N%20Y%20CONTRUCCI%C3%93N.png?alt=media&token=356f1765-4f7e-48af-9fb9-bc6584b0b09a";

  negotiations: any = [];
  openNegotiations: any = [];
  scheduledNegotiations: any = [];

  today: Date = new Date();
  receiptData: any = {};
  receiptProperties: any = [];

  ngOnInit(): void {
    this.checkScreenWidth();
    this.getOpenNegotiation();
    this.getScheduledNegotiation();
    registerLocaleData(localeEsMx, 'es-MX');
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    if (window.innerWidth < 600) {
      this.hideTab("scheduled");
      this.hideTab("ended");
    }
  }

  checkScreenWidth() {
    if (window.innerWidth < 600) {
      this.hideTab("scheduled");
      this.hideTab("ended");
    }
  }

  getOpenNegotiation() {
    this.negotiationService.getOpenNegotiations().subscribe(
      res => {
        this.negotiations = res.data;

        this.getUniqueNegotiations(this.negotiations);
      }
    );
  }

  getScheduledNegotiation() {
    this.negotiationService.getScheduleNegotiations().subscribe(
      res => {
        this.scheduledNegotiations = res.data;
      }
    );
  }

  getUniqueNegotiations(neg: any) {
    neg.forEach((n: any) => {
      if (this.openNegotiations.findIndex((nu: any) => n.PKNegotiation === nu.PKNegotiation) === -1) {
        this.openNegotiations.push(n);
      }
    });
  }

  showTab(tab: string) {
    $("#" + tab + "Tab").css("flex-grow", "1");
    $("#" + tab + "Tab").css("background-color", "var(--bg-color)");

    $("#" + tab + "Tab").children().css("display", "flex");
    $("#" + tab + "Tab").children().eq(0).css("display", "none");

    $("#" + tab + "Btn").css("display", "none");
  }

  hideTab(tab: string) {
    $("#" + tab + "Tab").css("flex-grow", "0.03");
    $("#" + tab + "Tab").css("width", "auto");
    $("#" + tab + "Tab").css("background-color", tab == "scheduled" ? "var(--primaryGreen)" : "var(--primaryBlue)");

    $("#" + tab + "Tab").children().css("display", "none");
    $("#" + tab + "Tab").children().eq(0).css("display", "block");
  }

  printPaybackReceipt(PKNegotiation: number, PKPaybackScheduled: number, PaybackAmount: number, Customer: string) {
    console.log("A", PKNegotiation);
    Swal.fire({
      title: '¿Quieres generar este pago?',
      text: "¡Se registrará como pagado y se generará el correspondiente recibo!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, espera',
      confirmButtonText: 'Sí, realizalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.receiptData = {};

        this.negotiationService.makePaybackRecord(PKNegotiation, PKPaybackScheduled, PaybackAmount, this.getResponsable()).subscribe(
          res => {
            console.log("Res", res.data);
            this.receiptData = res.data;

            this.receiptData.NegotiationDate = new Date(this.receiptData.NegotiationDate);

            this.getScheduledNegotiation();
          }
        );

        setTimeout(() => {
          const name = "Recibo de devolución de " + Customer.toUpperCase();
          let printContents, popupWin;
          printContents = document.getElementById('paybackReceipt')!.innerHTML;
          popupWin = window.open(' ', '', '');
          popupWin!.document.open();
          popupWin!.document.write(`
          <html>
            <head>
              <title>${name}</title>
              <style>
              * {
              margin: 0;
              padding: 0;
              -webkit-print-color-adjust: exact;
              color-adjust: exact;
              font-family: Verdana, Geneva, Tahoma, sans-serif;
              }

              .receiptContainer {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 20px;
                height: 100vh;
              }

              .receiptContainer .receiptHeader {
                display: flex;
                flex-direction: column;
                gap: 30px;
              }
              
              .receiptContainer .receiptHeader .titles {
                  position: relative;
                  display: flex;
                  justify-content: space-between;
                  border-bottom: 2px solid black;
              }
              
              .receiptContainer .receiptHeader .titles::after {
                  position: absolute;
                  content: "";
                  bottom: -5px;
                  right: CALC(50% - 150px);
                  width: 300px;
                  height: 10px;
                  background-color: #996600;
              }
              
              .receiptContainer .receiptHeader .details .receiptDate{
                  text-align: right;
              }
              
              .receiptContainer .receiptHeader .details .receiptNumber{
                  text-align: left;
              }
              
              .receiptContainer .receiptBody{
                  display: flex;
                  flex-direction: column;
                  gap: 30px;
                  justify-content: space-between;
              }
              
              .receiptContainer .signs{
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  gap: 20px;
              }
              
              .receiptContainer .signs .customer{
                  text-align: center;
              }

              .receiptContainer .signs .representative{
                text-align: center;
              }
              
              .receiptContainer .receiptFooter {
                  display: flex;
                  height: 20px;
              }
              </style>
            </head>
            <body onload="window.print();window.close()">
            ${printContents}
            </body>
          </html>`
          );

          popupWin!.document.close();
        }, 1000);
      }
    })
  }

  openDetailsView(PKNegotiation: number) {
    this.dialog.open(NegotiationDetailComponent, {
      width: '100%',
      data: PKNegotiation
    });
  }

  openCommentsView(PKNegotiation: number) {
    this.dialog.open(CommentsViewComponent, {
      width: '100%',
      height: '90vh',
      panelClass: 'dialog-responsive',
      data: PKNegotiation
    });
  }

  openScheduleView(PKNegotiation: number, Customer: string) {
    const scheduling = this.dialog.open(ScheduleViewComponent, {
      width: '100%',
      data: { PKNegotiation: PKNegotiation, Customer: Customer }
    });

    scheduling.afterClosed().subscribe(
      res => {
        this.getScheduledNegotiation();
      }
    );
  }

  getResponsable() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.fkuser;
  }
}