import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { budgetpiece } from '../models/BudgetPiece';
import { budget } from '../models/Budget';

@Injectable({
  providedIn: 'root'
})
export class BudgetPieceworkService {
  private url = 'http://localhost:3000/api';
  constructor(private http: HttpClient) { }

  // //Registrar requisiciones
  // insertRequisition(req:requisition): Observable<any> {
  //   return this.http.post(`${environment.URLServer}/requisitions/insert`,req);
  // }

  //Registrar presupuestos
  insertBudget(bud: budgetpiece): Observable<any> {
    return this.http.post(`${environment.URLServer}/budget_piece/insertbudget`, bud);
  }


  //Registrar productos en los presupuestos
  insertPieceworksBudget(idbudget: Number, iddeparture: Number, fk_idconcept: Number, amount: Number, unitcost: Number, total: Number, email: String): Observable<any> {
    return this.http.post(`${environment.URLServer}/budget_piece/insertpieceworks`, [idbudget, iddeparture, fk_idconcept, amount, unitcost, total, email]);
  }

  //Consultar todos los presupuestos
  selectBudgetsP(): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget_piece/getAllbudgets`);
  }

  //Seleccionar presupuestos de destajos por proyectos
  selectBudgetByProject(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget_piece/getBudgetByProject/${id}`);
  }

  //Eliminar un presupuesto
  deleteBudget(id: Number): Observable<any> {
    return this.http.delete(`${environment.URLServer}/budget_piece/deleteBudget/` + id);
  }

  //Consultar un presupuesto
  getBudgetInfo(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget_piece/getBudgetInfo/` + id);
  }

  //Consultar materiales en un presupuesto
  getPieceInBudget(idbudget: any): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget_piece/getpieceInBud/` + idbudget);
  }

  getListDepaPiece(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget_piece/getListDepaPiece/${id}`);
  }

  //Actualizar un presupuesto
  updateBudget(bud: budget): Observable<any> {
    return this.http.put(`${environment.URLServer}/budget_piece/updateBudget`, bud);
  }

  //Eliminar destajos de un presupuesto
  deleteAllByBud(id: Number): Observable<any> {
    return this.http.delete(`${environment.URLServer}/budget_piece/deleteAllByBud/` + id);
  }

  // Totales de todos los proyectos
  getTotalProjectsPiece(): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget_piece/getTotalProjectsPiece`);
  }

  //Total del proyecto
  getTotalProjectPiece(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget_piece/getTotalProjectPiece/${id}`);
  }

  getTotalProjectBudget(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget_piece/getTotalProjectByBudget/${id}`);
  }

  // Total del modulo
  getTotalModule(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget_piece/getTotalModule/${id}`);
  }

  // Total de la partida
  getModule(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget_piece/getModule/${id}`);
  }

    // Partidas
    getDeparture(id: number): Observable<any> {
      return this.http.get(`${environment.URLServer}/budget_piece/getDeparture/${id}`);
    }

  //LISTA COMPLETA DE PARTIDAS Y CONCEPTOS POR MODULO
  getDepCon(idprojectdet:number){
    return this.http.get(`${environment.URLServer}/budget_piece/getDepCon/${idprojectdet}`)
  }

   // Totales de todos los proyectos
   getTotalProjects(): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget_piece/getTotalProjects`);
  }


}
