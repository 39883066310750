import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { consumerToken } from '../models/ConsumerToken';
@Injectable({
  providedIn: 'root'
})
export class ConsumerTokenService {

  constructor(private http: HttpClient) { }

  insertConsumerToken(ct:consumerToken): Observable<any> {
    return this.http.post(`${environment.URLServer}/consumerToken/insertConsumerToken/`,ct);
  }

  insertProductConsumerToken(fk_idConsumerToken:number,fk_idproduct:number,amount:number): Observable<any> {
  return this.http.post(`${environment.URLServer}/consumerToken/insertProductConsumerToken/`,[fk_idConsumerToken,fk_idproduct,amount]);
  }

  getAllConsumerToken():Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/getAllConsumerToken/`);
  }

  getAllProductsFromConsumerToken(id:number):Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/getAllProductsFromConsumerToken/`+id);
  }

  getConsumerToken(id:number):Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/getConsumerToken/`+id);
  }

  getFilterConsumerToken1(folio:string,fk_idprojectDet:string,tokenDate:string):Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/getFilterConsumerToken1/`+folio+`/`+fk_idprojectDet+`/`+tokenDate);
  }
  getFilterConsumerToken2(folio:string):Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/getFilterConsumerToken2/`+folio);
  }
  getFilterConsumerToken3(folio:string,fk_idprojectDet:string):Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/getFilterConsumerToken3/`+folio+`/`+fk_idprojectDet);
  }
  getFilterConsumerToken4(tokenDate:string):Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/getFilterConsumerToken4/`+tokenDate);
  }//
  getFilterConsumerToken5(folio:string,tokenDate:string):Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/getFilterConsumerToken5/`+folio+`/`+tokenDate);
  }
  getFilterConsumerToken6(fk_idprojectDet:string,tokenDate:string):Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/getFilterConsumerToken6/`+fk_idprojectDet+`/`+tokenDate);
  }
  getFilterConsumerToken7(fk_idprojectDet:string):Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/getFilterConsumerToken7/`+fk_idprojectDet);
  }
  updateConsumerToken(ct:consumerToken){
    return this.http.put(`${environment.URLServer}/consumerToken/updateConsumerToken/`,ct);
  }
  deleteConsumerTokenProduct(fk_idConsumerToken:number):Observable<any>{
    return this.http.delete(`${environment.URLServer}/consumerToken/deleteConsumerTokenProduct/`+fk_idConsumerToken);
  }
  deleteConsumerToken(idConsumerToken:number):Observable<any>{
    return this.http.delete(`${environment.URLServer}/consumerToken/deleteConsumerToken/`+idConsumerToken);
  }
  auditEditionConsumerToken(idConsumerToken:number):Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/auditEditionConsumerToken/`+idConsumerToken);
  }
  //=================validaciones
  folioValidate(fk_idprojectDet:number,folio:string):Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/folioValidate/`+fk_idprojectDet+`/`+folio);
  }
  serverDate():Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/serverDate/`);
  }
  consumerTokenlastFolio():Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/consumerTokenlastFolio/`);
  }

  //==================REPORTES
  getMaterialTotalByProject(idProject:number):Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/getMaterialTotalByProject/`+idProject);
  }
  getConsumedMaterialByProject(idProject:number):Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/getConsumedMaterialByProject/`+idProject);
  }

  getMaterialTotalByMod(idprojectdet:number):Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/getMaterialTotalByMod/`+idprojectdet);
  }
  getConsumedMaterialByMod(fk_idproduct:number):Observable<any>{
    return this.http.get(`${environment.URLServer}/consumerToken/getConsumedMaterialByMod/`+fk_idproduct);
  }
}