//=========================================================================================================
//Importación de componentes necesarios para esta pantalla
//=========================================================================================================
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//=========================================================================================================
//Importación de los servicios necesarios para esta pantalla
//=========================================================================================================
import { PieceworkService } from 'src/app/services/piecework.service';

import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dialog-pieceworks',
  templateUrl: './dialog-pieceworks.component.html',
  styleUrls: ['./dialog-pieceworks.component.scss']
})
export class DialogPieceworksComponent implements OnInit {
  cbrLogo: any = "https://firebasestorage.googleapis.com/v0/b/m19inmobiliaria-f6068.appspot.com/o/logos%2FConstructora%2FASESORIA%2C%20SUPERVISI%C3%93N%20Y%20CONTRUCCI%C3%93N.png?alt=media&token=356f1765-4f7e-48af-9fb9-bc6584b0b09a";

  pipe = new DatePipe('es-MX');
  fechaHoy = new Date();

  pieceInfo: any = [];
  pieceDeparture: any = [];
  pieceConcepts: any = [];
  pieceEmployee: any = [];

  totalConceptos: number = 0;
  totalEmpleados: number = 0;
  employeesList: string = "";

  constructor(
    private dialogRef: MatDialogRef<DialogPieceworksComponent>,
    private pieceworkService: PieceworkService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getPieceworkInfo();
    this.getPieceworksDepartures();
    this.getPieceworkContent();
    this.getPieceworkEmployess();

    registerLocaleData(localeEsMx, 'es-MX'); //REGISTRAR LOCAL PARA ESPAÑOL
  }

  getPieceworkInfo() {
    this.pieceworkService.getPieceworkInfo(this.data).subscribe(
      res => {
        this.pieceInfo = res;
        console.log(this.pieceInfo);
      }
    );
  }

  getPieceworksDepartures() {
    this.pieceworkService.getPieceworksDepartures(this.data).subscribe(
      res => {
        this.pieceDeparture = res;
      }
    );
  }

  getPieceworkContent() {
    this.pieceworkService.getPieceworkContent(this.data).subscribe(
      res => {
        this.pieceConcepts = res;
        console.log(this.pieceConcepts);
        for(let con of this.pieceConcepts) {
          this.totalConceptos = this.totalConceptos + (con.amount*con.unitcost);
          console.log(this.totalConceptos)
        }
      }
    );
  }

  getPieceworkEmployess() {
    this.pieceworkService.getPieceworkEmployee(this.data).subscribe(
      res => {
        this.pieceEmployee = res;
        for(let i = 0; i < this.pieceEmployee.length; i++) {
          this.totalEmpleados = this.totalEmpleados + this.pieceEmployee[i].employeesalary;
          if(this.pieceEmployee.length > 0) {
            if(i===0) {
              this.employeesList = this.pieceEmployee[i].employeename;
            } else if(i > 0 && i < this.pieceEmployee.length) {
              this.employeesList = this.employeesList + "/" + this.pieceEmployee[i].employeename;
            }
          } else {
            this.employeesList = "Sin empleados asignados";
          }
        }
      }
    );
  }

  getDaysWorked(position: number) {
    let att = this.pieceEmployee[position].attendanceLD.split("-");
    let td = 0;
    for(let a of att) {
      td = td + parseInt(a);
    }
    return td;
  }

  printSheet(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlSheet')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
    <html>
      <head>
        <title>Papeleta de Destajo</title>
        <style>
          table {
            border-collapse: collapse;
            font-size: 0.8rem;
          }
          
          .principalTable, .contentHeader, .contentTable, .employeeTable, .signsTable {
            width: 100%;
          }
        
          .principalTable th, .principalTable td, .contentHeader td, .contentTable td, .employeeTable td {
              text-align: center;
              border: solid black 2px;
              padding: 5px;
          }
          
          .signsTable td {
              text-align: center;
              border: solid black 2px;
              padding: 0 50px 50px 50px;
          }
        </style>
      </head>
      <body onload="window.print();window.close()">${printContents}</body>
    </html>`
    );
    popupWin!.document.close();
  }
  
  printAttendance(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlAttendance')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
    <html>
      <head>
        <title>LISTA DE ASISTENCIA</title>
      
        <style>
          @media print{@page {size: landscape}}
          body {
            font-family: Roboto, "Helvetica Neue", sans-serif;
          }

          table {
            border-collapse: collapse;
          }

          .attendanceHeader, .attendanceContent {
            width: 100%;
          }
          
          .attendanceHeader td, .attendanceContent td {
              border: solid black 2px;
              text-align: center;
              padding: 5px;
          }
        </style>
      </head>
      <body onload="window.print();window.close()">${printContents}</body>
    </html>`
    );
    popupWin!.document.close();
  }
}