<div class="container-fluid mt-3">
    <mat-card class="card-form">

        <form class="form-inline">
            <input class="form-control mr-sm-2" type="search" placeholder="No. Folio" aria-label="Search" [(ngModel)]="folioCli" name="correo">
            <button class="btn btn-outline-danger my-2 my-sm-0" type="submit" (click)='selectFolios(folioCli)'>Buscar</button>
        </form>
        <br>
        <mat-card-content class="justify-align-center">
            <table class="table table-striped animated fadeIn faster">
                <thead class="thead-m19">
                    <tr>
                        <th scope="col">No.</th>
                        <th scope="col">Folio</th>
                        <th scope="col">Fecha de pago</th>
                        <th scope="col">Cantidad ($)</th>
                        <th scope="col">Método de pago</th>
                        <th scope="col">Inmueble</th>
                        <th scope="col">Acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let foli of folios; let i=index; let a">
                        <th>{{i+1}}</th>
                        <th scope="row">{{foli.folioPago}}</th>
                        <td>{{foli.fechaPago | date: 'dd-MM-yyyy'}}</td>
                        <td>{{(foli.cantidadAbono | currency:'CAD' : '$ ')}}</td>
                        <td>{{foli.metodoPago}}</td>
                        <td>{{foli.fkCliente}}</td>
                        <td class="button-row">
                            <button class="btn" mat-icon-button onclick="cancelarPago()">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </mat-card-content>
    </mat-card>
</div>