<mat-dialog-content>
    <h4 style="color: var(--M19Primary); font-weight: bold;">Reasignación de lead</h4>

    <p>Está a punto de reasignar este lead previamente asignado a <b style="color: var(--M19Primary);">{{data.secondResponsable}}</b> para continuar seleccione un coordinador a quién reasignar este prospecto seguido de el motivo de este reajuste:</p>

    <form  [formGroup]="reassignFormGroup" (ngSubmit)="reassignLead()"
    onKeyPress="if(event.keyCode == 13) event.returnValue = false;" style="display: flex; flex-direction: column; align-items: center;">
    <mat-form-field appearance="outline">
        <mat-label>Coordinador</mat-label>
        <mat-select [(value)]="getCoordinators" formControlName="secRes" required>
            <mat-option *ngFor="let coord of coordsList" [value]="coord.idcoord">
                {{coord.coordName}}
            </mat-option>
        </mat-select>
        <mat-icon matSuffix>map</mat-icon>
    </mat-form-field>
    
        <mat-form-field appearance="outline">
            <mat-label>Motivo de reasignación</mat-label>
            <textarea matInput cdkTextareaAutosize formControlName="reason"
                cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" placeholder="El coordinador argumenta que..."></textarea>
            <mat-icon matSuffix>edit</mat-icon>
        </mat-form-field>

        <button class="m19Btn">Reasignar</button>
    </form>
</mat-dialog-content>