<!-- Si no hay notas de remisión registradas para una requisición se visualiza el siguiente mensaje -->
<app-loader [loader]="loader" *ngIf="loading==1"></app-loader>

<section class="emptyRes" *ngIf="deliveryList.length==0">
    <h3 class="message">No hay notas de remisión registradas para esta requisición</h3>
    <mat-icon>error</mat-icon>
</section>

<!-- Si la cantidad de notas registradas es mayor a 0 se visualiza el siguiente bloque de código -->
<mat-dialog-content *ngIf="deliveryList.length>0">
    <h3 style="text-align: center; color: var(--M19Primary);"><b>Notas de remisión</b></h3>
    <mat-accordion>
        <mat-expansion-panel *ngFor="let d of deliveryList" hideToggle>
            <mat-expansion-panel-header style="height: fit-content; padding: 10px 5px 10px 5px;"
                (click)="getDeliveryInfo(d.deliveryfolio); getDeliveryProducts(d.deliveryfolio);">
                <mat-panel-title style="width: 50%;">
                    <span><b>
                            Folio:</b> {{d.deliveryfolio}}
                        <br><b>Número:</b> {{d.deliverynumber}}
                    </span>
                </mat-panel-title>
                <mat-panel-description style="width: 50%;">
                    <span><b>
                            Con fecha del día {{pipe.transform(d.deliverydate, 'longDate')}}
                        </b><br><b>Con {{d.invAmount}} facturas</b> 
                    </span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <br>
            <div style="display: flex; flex-wrap: wrap ;justify-content: space-between;">
                <p><b style="color: var(--M19Primary);">Registrada el día</b>
                    {{pipe.transform(deliveryInfo.creationdate, 'longDate')}}</p>
                <p><b style="color: var(--M19Primary);">Captura realizada por</b> {{deliveryInfo.responsable}}</p>
                <p><b style="color: var(--M19Primary);">Recibió:</b> {{deliveryInfo.receiver}}</p>
                <p><b style="color: var(--M19Primary);">Proveedor:</b> {{deliveryInfo.suppliername}}</p>
            </div>

            <table class="m19Table">
                <thead>
                    <tr>
                        <!-- Se definen los encabezados de cada columna de la tabla -->
                        <th style="text-align: center;">#</th>
                        <th>Nombre de producto</th>
                        <th style="text-align: center;">Cantidad</th>
                        <th style="text-align: center;">Unidad</th>
                        <th style="text-align: center;">Costo Unitario</th>
                        <th style="text-align: center;">Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let dp of deliveryProducts; index as i">
                        <td style="text-align: center;">{{i+1}}</td>
                        <td>{{dp.productname}}</td>
                        <td style="text-align: center;">
                            {{dp.amount}}</td>
                        <td style="text-align: center;">
                            {{dp.unit}}</td>
                        <td style="text-align: center;">
                            {{dp.unitcost | currency:'CAD' : '$ '}}</td>
                        <td style="text-align: center;">
                            {{dp.subtotal | currency:'CAD' : '$ '}}</td>
                    </tr>
                </tbody>
            </table>

            <p><b style="color: var(--M19Primary);">Observaciones:</b> {{deliveryInfo.notes}}</p>

            <div style="display: flex; justify-content: flex-end; gap: 30px;">
                <!-- Botón para visualizar el desgloce de facturas de una nota de remisión -->
                <div class="actionBtn" (click)="selectInvoice(d.deliveryfolio)"
                    style="display:flex; flex-direction:column; align-items:center; cursor:pointer;">
                    <button title="Factura" mat-icon-button>
                        <mat-icon>fact_check</mat-icon>
                    </button>
                    <mat-label>Facturas</mat-label>
                </div>
                <div class="actionBtn" (click)="editDelivery(d.deliveryfolio)"
                    style="display:flex; flex-direction:column; align-items:center; cursor:pointer;">
                    <button title="Factura" mat-icon-button>
                        <mat-icon>edit</mat-icon>
                    </button>
                    <mat-label>Modificar</mat-label>
                </div>
                <div class="actionBtn" (click)="deleteDelivery(d.deliveryfolio)"
                    style="display:flex; flex-direction:column; align-items:center; cursor:pointer;">
                    <button title="Factura" mat-icon-button>
                        <mat-icon>delete</mat-icon>
                    </button>
                    <mat-label>Eliminar</mat-label>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</mat-dialog-content>

<!-- Botón para cerrar el dialog -->
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>