import { Component, OnInit } from '@angular/core';
import decode from 'jwt-decode';
import { DialogUpdateViewComponent } from '../dialogUpdate/dialog-update-view/dialog-update-view.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/services/project.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { ControComissionsService } from 'src/app/services/contro-comissions.service'
import { getMatInputUnsupportedTypeError } from '@angular/material/input';

@Component({
  selector: 'app-view-comission',
  templateUrl: './view-comission.component.html',
  styleUrls: ['./view-comission.component.scss']
})
export class ViewComissionComponent implements OnInit {

  //=========================================================================================================
  //==================================== DECLARACION DE VARIABLES ===========================================
  //====================================== METODOS PRIMER MAT-TAB ===========================================
  //======================================= TODAS LAS COMISIONES ============================================
  //=========================================================================================================

  

  //========================================= AUTOCOMPLETE FK_PROPERTIES ====================================
    keyword = 'fk_property';
    propertyList: any = [];
    idInmuSearch: any;
  //=========================================================================================================

  xd: any=[];
  getAll: any = []  ;
  getAll2: any = []  ;

  dati : any = [];

  cont: any = 0;
  //========================================== PAYMENT_COMISSIONS =================================================
  comissions: any = [];    
  payments   : any = [];    
  upComissionByID: any = []; 

  lisCommisions: any = [];   
  lisPaymentsCustomers: any = [];   

  listpru: any = [];  
  listpru2: any = [];  
   
  furnitureAndPaymentsComissions: any = ['',['','']];
  furnitureAndPaymentsCustomers: any = ['',['']];


  InmuByProjectAndEmployee: any = [];

  dimensiones : any = [];
  dimensioness : any = [];

  totalPagos = 0;

  //================================================ PROJECTS =====================================================
  projects: any = [];
  projects2: any = [];
  projectByID: any = [];
  auxProj: string = "";
  opcionSeleccionadoP: string = '0';

  //================================================ EMPLOYEES =====================================================
  employees: any = [];
  employees2: any = [];
  employeeByID: any = [];
  auxEmplo: any = [];
  opcionSeleccionadoE: string = '';


  //========================================= AUTOCOMPLETE CUSTOMERS ==============================================
  keywordCustomer ='name';
  paymentsCustomers: any = [];
  idCustomerSearch: any;
  custi = "";

  


  decode: any = {};
  decode2: any = "";
  //=========================================================================================================
  //==================================== DECLARACION DE FORMS ===============================================
  //=========================================================================================================




  paymentComFormGroup: FormGroup = this._formBuilder.group({
    paymentDate: [null, Validators.required],
    registrationDate: [null, Validators.required],
    paymentAmount: [null, [Validators.required, Validators.min(0)]],
    paymentStatus: ['Aprobado', Validators.required],
    folio: [null, [Validators.required, Validators.min(0)]],
    paymentConcept: ['', Validators.required],
    fk_salesCoordinator: [null, Validators.required],
    fk_payer: [1004, Validators.required],
    fk_property: [null, Validators.required]
  })

  Concepto = [
    { tipo: 'Mensualidad' },
    { tipo: 'Anticipo' }
  ];




  //=========================================================================================================
  //========================================== CONSTRUCTOR ======================================================
  //=========================================================================================================

  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public sweet: SweetAlertComponent,
    public projectService: ProjectService,
    public employeeService: EmployeeService,
    public controlComissionService: ControComissionsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getInmueblesProp();
    this.selectProjects();
    this.selectSellers();
    this.selectAllCustomers();
    this.asigName();
    this.getAllcomissions()
    
    
    
  }

  //=========================================================================================================
  //====================================== METODOS PRIMER MAT-TAB ===========================================
  //======================================= TODAS LAS COMISIONES ============================================
  //=========================================================================================================

    //================================= obtiene y muestra todos los inmuebles en el input =====================
    async getInmueblesProp() {
      this.controlComissionService.getAllProperty().subscribe(
        res => {
          //  console.log(res);
          this.propertyList = res;
  
        },
        err => console.log(err)
      );
    }
  


  //======================== Obtiene y alamacena todas las comisiones en un arreglo =========================
  async getAllcomissions() {
    this.controlComissionService.getAllComissions().subscribe(
      res => {
      //  console.log(res);
        this.comissions = res;

      },
      err => console.log(err)
    );
  }

  async getAllPaymentsCustomers() {
    this.controlComissionService.getAllPaymentsCustomers().subscribe(
      res => {
        console.log(res);
        this.payments = res;

      },
      err => console.log(err)
    );
  }


  //========================== Selecciona un inmuebles seleccionado desde el input ========================
  async selectPropertyByID(codIn: any) {
    this.controlComissionService.getPropertySelected(codIn).subscribe(
      data => {
        //       console.log(data);
        this.comissions = data;
      },
      err => console.log(err)
    );

  }
  //============================== Selecciona un folio al dar clic en editar ===================================
  async selectOneFol(id: number) {
    this.controlComissionService.selectOneFolio(id).subscribe(
      res => {
        this.upComissionByID = res;
        this.dialog.open(DialogUpdateViewComponent, {
          width: '50%',
          data: res
        });
        
      },
      err => console.log(err)
    )
  }
  //=================== Abre el dialog y manda la informacion conforme al id que se selecciono ===============
  async updateConceptPayments(idfolio: number ) {

    this.controlComissionService.selectOneFolio(idfolio).subscribe(
      res => {
        this.upComissionByID = res;
        let dialogRef = this.dialog.open(DialogUpdateViewComponent, {
          width: '70%',
          data: res
        });

        dialogRef.afterClosed().subscribe(
          res=>{
  
            this.getAllcomissions();
          }
          );
      
      },
      err => console.log(err)
    )
  }

  

  //=========================================================================================================
  //==================================== METODOS SEGUNDO MAT-TAB ============================================
  //====================================== COMISIONES PAGADAS ===============================================
  //=========================================================================================================

  //Consultar todos los proyectos
  async selectProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
        this.projects2 = res;
      },
      err => console.log(err)
    );
  }

  async selectProjectByID(nomen: any) {
    this.projectService.selectProject(nomen).subscribe(
      res => {
        //  console.log(res)
        this.auxProj = res;

      },
      err => console.log(err)
    )
  }

  async selectSellers() {
    this.employeeService.selectEmployeeWorker().subscribe(
      res => {
        this.employees = res;
        this.employees2 = res;
      },
      err => console.log(err)
    );
  }

  async selectSellerByID(id: number) {
    this.employeeService.selectEmployeeById(id).subscribe(
      res => {
        //  console.log(res);
        this.auxEmplo = res;
      }
    )
  }

 async selectInmuByProjectAndEmployee(namePro: string, nom: string) {
    this.controlComissionService.selectByProjectAndId(namePro, nom).subscribe(
      ress => {

        this.getAll = ress;
        this.getAll2 = ress;


          for (let i in this.getAll) {

            this.controlComissionService.selectPayByFornitureComissions(this.getAll[i].depa).subscribe(
              
              res => {          
      
                let cadena =  [];
                let relleno = [];
      
                this.InmuByProjectAndEmployee = res; 

                for (let p of this.InmuByProjectAndEmployee) {
                    cadena.push((p.fechapago.substring(0, 10)));
                    relleno.push((p.fechapago.substring(0, 10)));
                }

                for(let i = this.InmuByProjectAndEmployee.length; i < 10; i++){
                  relleno.push("NULL");
                }



                this.dimensiones.push([this.getAll[i].depa, relleno])
                this.furnitureAndPaymentsComissions.push(this.getAll[i].depa, cadena); 
              },
              err => console.log(err)
            )
      
            this.listpru = [];
            //this.totalPagos = 0;
          }


      },
      err => console.log(err)
    )
  }

  async selectPayments() {
    this.controlComissionService.selectPaymentDate().subscribe(
      res => {
        this.lisCommisions = res;
          console.log(res);
      }
    )
  }

  async selectPaymentsCustomers() {
    this.controlComissionService.selectPaymentCustomerDate().subscribe(
      res => {
        this.lisPaymentsCustomers = res;
          console.log(res);
      }
    )
  }

  async selectPayInmu(nom: string) {

    this.controlComissionService.selectPayByFornitureComissions(nom).subscribe(
      res => {
        this.listpru = res;
        console.log(this.listpru);
      },
      err => console.log(err)
    )
  }

  async selectPayInmuPayments(nom: string) {

    this.controlComissionService.selectPayByForniturePayments(nom).subscribe(
      res => {
        this.listpru2 = res;
        console.log(this.listpru2);
      },
      err => console.log(err)
    )
  }


 async mergePaymentsComissionOnProperties() {
   
    this.furnitureAndPaymentsComissions = [];

    this.selectInmuByProjectAndEmployee(this.opcionSeleccionadoE , this.opcionSeleccionadoP);

    
  }

  async mergePaymentsCustomerOnProperties() {
   
    this.furnitureAndPaymentsCustomers = [];

    this.mergePaymentsCustomersOnProperties(this.opcionSeleccionadoE , this.opcionSeleccionadoP);

    
  }


  mergePaymentsCustomersOnProperties(id: string, nom: string ){
    this.controlComissionService.selectByProjectAndId(id, nom).subscribe(
      ress => {

        this.getAll = ress;
        this.getAll2 = ress;

          for (let i in this.getAll) {

            this.controlComissionService.selectPayByForniturePayments(this.getAll[i].depa).subscribe(
              
              res => {          
      
                let cadena =  [];
                let relleno = [];
      
                this.InmuByProjectAndEmployee = res; 

                for (let p of this.InmuByProjectAndEmployee) {
                    cadena.push((p.fechapago.substring(0, 10)));
                    relleno.push((p.fechapago.substring(0, 10)));
                }

                for(let i = this.InmuByProjectAndEmployee.length; i < 10; i++){
                  relleno.push("NULL");
                }



                this.dimensioness.push([this.getAll[i].depa, relleno])
                this.furnitureAndPaymentsComissions.push(this.getAll[i].depa, cadena); 

              },
              err => console.log(err)
            )
      
            this.listpru = [];
            //this.totalPagos = 0;
          }
  

        console.log(this.getAll)
        console.log(this.getAll2)

      },
      err => console.log(err)
    )

  }


  //=========================================================================================================
  //======================================= METODOS TERCER VENTANA ==========================================
  //========================================== PAGOS DE CLIENTES ============================================
  //=========================================================================================================
  
  async selectCustomerPaymentsByID(id : number){
    this.controlComissionService.selectCustomerInformation(id).subscribe(
      res => {
        this.xd= res;
        console.log(this.xd)
      },
      err => console.log(err)
    )
  }

  async selectAllCustomers(){
    this.controlComissionService.selectAllCustoms().subscribe(
      res => {
        this.paymentsCustomers = res;
       // console.log(this.paymentsCustomers)
      },
      err => console.log(err)
      
    )
  }
  

  //===============================================================================================================================================================================================================
  //========================================= EVENTOS KEYPRESS ====================================================================================================================================================
  //===============================================================================================================================================================================================================

  onKeypressEventSearchByID(eventFC: any) {
    this.idInmuSearch = eventFC

    let cadInmue: any = {};
    cadInmue = this.idInmuSearch.fk_property;

    if (cadInmue == ' ' || cadInmue == null || cadInmue == "") {
      console.log("Se selecciono un campo vacio por favor selecciones uno valido")
    } else {
      this.selectPropertyByID(cadInmue);
    }
  }

  onKeypressEventSearchCustomer(eventFC: any) {
    this.idCustomerSearch = eventFC

    let cadCust: any = {};
    cadCust = this.idCustomerSearch.idUser;

    if (cadCust == ' ' || cadCust == null || cadCust == "") {
      console.log("No se esta seleccionando un campo")
    } else {

      this.selectCustomerPaymentsByID(cadCust);
    }
  }

  async asigName(){
    this.decode2 = this.getUser()
    console.log(this.decode2)
  }

  getUser() {
    let uwu;
    this.decode = decode(localStorage.getItem("token") || "" )
    //let aux = this.decode['cliente']
    //console.log("Soy el cliente con nombre: → " , this.decode.cliente)  forma 1 de obtener el cliente
    //console.log("Soy el cliente con nombre: → ", this.decode['cliente'])
    // console.log("Soy el cliente con nombre: → " , aux)   → forma 3 de obtener el cliente logueado
    uwu = this.decode.cliente
    //console.log("uwu: → " , uwu) 
    return uwu
  }

}//Fin del componente 