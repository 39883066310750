import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-cancelled-search',
  templateUrl: './cancelled-search.component.html',
  styleUrls: ['./cancelled-search.component.scss']
})
export class CancelledSearchComponent implements OnInit {
  //Manejo folios cancelados
  cancelados: any = [];
  codigoInm=""
  folio:any=""
  //Autocompletado
  keyword = 'name';  

  sal: any=[];  
  folioApr:any=[];
  folioCa:any=[];
  folioBusqueda: any;

  constructor(private reportServ: ReportService) { }

  ngOnInit(): void {
    this.folioCan();
  }
  slectListadoCancelados() {
    this.reportServ.listadoCancelados().subscribe(
      res => {
        console.log(res);
        this.cancelados = res;
      },
      err => console.log(err)
    );
  }
  selectFolioCancelado(folCan:number) {
    this.reportServ.selectFolioCancelado(folCan).subscribe(
      data => {
        console.log(data);
        this.cancelados = data;
      },
      err => console.log(err)
    );

  }

  onKeypressEventFolioCancelado(eventFC: any){

    this.folioBusqueda=eventFC

    let cadFol:any = {}; 
    cadFol = this.folioBusqueda.name;       
    
    if(cadFol==' '|| cadFol==null||cadFol==""){
      console.log("Se requiere consultar cliente")
    }else{
    this.codigoInm=cadFol    
    this.selectFolioCancelado(cadFol);    
    }
  }

  folioCan() {
    this.reportServ.folioCancelado().subscribe(
      res => {             
        console.log(res);           
        this.folioCa = res;              
      },
      err => console.log(err)
    );
  }

}
