import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { invoiceReportService } from 'src/app/services/invoice.service';
import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';
import decode from 'jwt-decode';
import { DatePipe } from '@angular/common';

import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { MatDialog } from '@angular/material/dialog';
import { DialogInvoiceComponent } from '../dialog-invoice/dialog-invoice.component';

@Component({
  selector: 'app-general-reports',
  templateUrl: './general-reports.component.html',
  styleUrls: ['./general-reports.component.scss']
})

export class GeneralReportsComponent implements OnInit{

  @Output() pendientes : any = [];
  @Output() revisadas : any = [];
  @Output() emitidas : any = [];


  public range = new FormGroup({
    start: new FormControl('', Validators.required),
    end: new FormControl('', Validators.required)
  });

  bannerTitle: BannerTitle = {
    title: "Reporte de facturacion",
    icon: "attachment",
    position: "center",
    background: "var(--M19Secundary)"
  }

  decodetoken: any = {};
  user: any;

  pipe = new DatePipe('es-MX');
  fechaHoy = new Date();

  
  constructor(
    private invoiceService: invoiceReportService,
  ) { }

  ngOnInit(): void {
    registerLocaleData(localeEsMx, 'es-MX'); //REGISTRAR LOCAL PARA ESPAÑOL

    this.selectUser()
  }

  public generateReport() {
    const start = this.range.value.start ;
    const end = this.range.value.end;

    this.invoiceService.getInvoiceSlopeRANGE({invoiceStatus: "Pendiente", start: start, end: end}).subscribe(
      res => {
        this.pendientes = res
        this.invoiceService.getInvoiceSlopeRANGE({invoiceStatus: "Revision", start: start, end: end}).subscribe(
          res => {
            this.revisadas = res
            this.invoiceService.getInvoiceSlopeRANGE({invoiceStatus: "Aprobada", start: start, end: end}).subscribe(
              res => {
                this.emitidas = res
              },
              err => console.error(err)
            )
          },
          err => console.error(err)
        )
    
      },
      err => console.error(err)
    )


  }

  selectUser(){
    const token = localStorage.getItem('token') || "";
    this.decodetoken = decode(token);
    this.user = this.decodetoken.cliente;
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlData')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
      <html>
        <head>
          <title>${this.fechaHoy}</title>
          <style>
            * {
              margin: 0;
              padding: 0;
              -webkit-print-color-adjust: exact; 
              color-adjust: exact;
            }
            body {
              font-family: Roboto, "Helvetica Neue", sans-serif;
            }

            .statementHeader {
              display: flex;
              border: solid #D33E3E 5px;
              padding: 30px;
              border-radius: 20px 20px 0 0;
              justify-content: space-between;
              margin-botton: 30px;
            }
            
            .m19infoContainer {
                display: flex;
                flex-direction: column;
                width: 45%;
                gap: 10px;
                font-size: 0.9rem;
                margin: 0;
                padding: 0;
            }
            
            .principalInfo {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 45%;
                font-size: 0.9rem;
                margin: 0;
                padding: 0;
            }
            
            img {
                width: 30%;
                margin-bottom: 5px;
                border: none;
            }
            
            h4 {
                padding: 10px;
                background-color: #D33E3E;
                color: white;
                font-weight: bold;
                text-align: center;
                border-radius: 20px 20px 0 0;
                margin-bottom: 0;
            }

            .financialStatus {
              display: flex;
              justify-content: space-between;
              padding: 30px 30px 30px 30px;
              border-top: solid #D33E3E 5px;
              border-left: solid #D33E3E 5px;
              border-right: solid #D33E3E 5px;
            }

            .saleStatus {
              display: flex;
              justify-content: space-between;
              padding: 30px 30px 30px 30px;
              border-top: solid #D33E3E 5px;
              border-left: solid #D33E3E 5px;
              border-right: solid #D33E3E 5px;
              border-bottom: solid #D33E3E 5px;
            }
            
            .aditionalInfo {
              width: 100%;
              display: flex;
              justify-content: space-between;
              font-size: 0.8rem;
            }

            .debtInfo {
              display: flex;
              flex-direction: column;
              width: 40%;
            }

            .totalAmount {
              display: flex;
              justify-content: space-evenly;
              background-color: #5E4F4F;
              color: white;
              font-size: 1rem;
              font-weight: bold;
              padding: 10px;
            }

            .totalAmount p {
              margin: 0;
            }

            .infoColumn {
              display: flex;
              flex-direction: column;
              gap: 10px;
              margin-top: 10px;
            }
            
            .paymentsInfo {
                width: 100%;
            }

            .paymentsSection {
              break-before: page;
            }

            table {
              border-collapse: collapse;
              border: solid #5E4F4F 5px;
            }
            
            thead {
                background-color: #5E4F4F;
                color: white;
                font-weight: bold;
                font-size: 1rem;
                text-align: center;
            }
            
            tbody {
                text-align: center;
                font-size: 0.8rem;
            }
            
            tbody tr:nth-child(even) {
                background-color: #ebebeb;
            }

            td, th {
              padding: 10px;
            }

            .firmas{
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-evenly;
              align-content: center;
            }

            .firma{
              background-color: #5E4F4F;
              color: white;
              font-weight: bold;
              font-size: 1rem;
              text-align: center;
              width:30%;
            }
          </style>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin!.document.close();
  }







}
