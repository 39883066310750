import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RecessionsService } from 'src/app/services/recessions.service';

@Component({
  selector: 'app-make-recessions',
  templateUrl: './make-recessions.component.html',
  styleUrls: ['./make-recessions.component.scss']
})
export class MakeRecessionsComponent implements OnInit {

  constructor(
    private recessionService: RecessionsService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    
  }

}