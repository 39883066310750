<mat-card>
    <mat-card-content>
        <form class="row mt-3" [formGroup]="pieceworkFormGroup" (ngSubmit)="updatePiecework()" onKeyPress="if(event.keyCode == 13) event.returnValue = false;" style="display: flex; flex-direction: column;">
            <h5 class="col-12 pb-2">Modificar Destajo</h5>

            <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Descripción</mat-label>
                <input matInput type="text" formControlName="description" required>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Unidad</mat-label>
                <input matInput type="text" formControlName="unit" required>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Costo</mat-label>
                <input matInput type="number" formControlName="cost" required>
            </mat-form-field>

            <div class="col-12 text-right mt-3">
                <button class="btn btn-m19" type="submit" [disabled]="!pieceworkFormGroup.valid">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
