import { DatePipe, registerLocaleData } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import jwtDecode from 'jwt-decode';
import { ControComissionsService } from 'src/app/services/contro-comissions.service';
import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';
import { PdfMakerComponent } from 'src/app/utils/pdf-maker/pdf-maker.component';
import Swal from 'sweetalert2';
import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL


@Component({
  selector: 'app-ticket-options',
  templateUrl: './ticket-options.component.html',
  styleUrls: ['./ticket-options.component.scss']
})
export class TicketOptionsComponent implements OnInit {

  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public controlComissionService: ControComissionsService,
    private ticketComissiones: PdfMakerComponent,
    private _formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<TicketOptionsComponent>
  ) {
    this.dataInformation = data;
    this.dialogRef.disableClose = true;
  }


  dataInformation: any = [];
  comisiones: any = [];
  user: any = []

  hid = true;
  folio = 0;
  dat : any = [];
 
  fechaActual: any | null;
  
  //Variables para usar fechas en horario Local es-MX
    pipe = new DatePipe('es-MX');
    currentDate = new Date();


  bannerTitleCancel: BannerTitle = {
    title: "Seleccione el ticket que desea cancelar",
    icon: "block",
    position: "center",
    background: "var(--M19Secundary)"
  }

  bannerTitleReprint: BannerTitle = {
    title: "Seleccione el ticket que desea reimprimir",
    icon: "print",
    position: "center",
    background: "var(--M19Secundary)"
  }


  comissionCancelFormGroup: FormGroup = this._formBuilder.group({
    userModification: [this.getUser(), Validators.required],
    modificationNotes: [, Validators.required],
    modificationDate: [, Validators.required]
  });

  ngOnInit(): void {
    this.comisionesPorPropiedad();
    registerLocaleData(localeEsMx, 'es-MX');
    this.comissionCancelFormGroup.controls['modificationDate'].setValue(this.pipe.transform(this.currentDate, ('dd-MM-yyyy')));

  }

  public async comisionesPorPropiedad() {

    this.controlComissionService.selectPayByFornitureComissions(this.dataInformation.propiedad).subscribe(
      res => {
        this.comisiones = res;
      }
    )

  } 

  public async reimprimir(c: any) {

    this.ticketComissiones.ticketGeneratorComissiones(
      c.fechapago.substring(0, 10),
      c.folio,
      this.dataInformation.proyecto,
      c.fk_property.substring(3, 5), //Manzana
      c.fk_property.substring(6, c.fk_property.length), //Lote
      this.dataInformation.vendedor.substring(7, this.data.vendedor.length),
      "Marco Antonio Flores Rivas",
      c.paymentAmount,
      c.paymentConcept
    );

  }


  async resetField(){

    Swal.fire({
      title: '  <FONT COLOR="#000000"> <strong> ¡Cancelar Recibo! </strong> </FONT> ',
      width: "auto",
      icon: 'warning',
      iconColor: '#D33E3E',

      showConfirmButton: true,
      confirmButtonColor: '#0719FA',

      showCancelButton: true,
      cancelButtonColor: " #D33E3E",


      html: 'Estas seguro?' +
        '<br> Al cancelar el recibo no podras recuperarlo',

      confirmButtonText: 'Si Cancelalo!',
      cancelButtonText: `Cancelar`

    }).then((result) => {
      if (result.isConfirmed) {

        this.controlComissionService.cancelTicketComissionService(this.comissionCancelFormGroup.value.modificationNotes , this.comissionCancelFormGroup.value.userModification , this.folio).subscribe();
        Swal.fire("Recibo Cancelado", "El recibo se cancelo correctamente" , "success")
 
        this.hid = true;
        this.comissionCancelFormGroup.controls['modificationNotes'].setValue(null);
        this.comisionesPorPropiedad();
        
      } else {
        result.dismiss
      }
    }
    )

  }

  async close(){
    this.dialogRef.close()
  }

  public async cancelar(inf: any) {
    this.folio = inf.folio;
    this.dat = inf;
    this.hid = false;
    
  }

  getUser() {
    this.user = jwtDecode(localStorage.getItem("token") || "")
    return this.user['cliente']
  }

}
