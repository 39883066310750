<mat-tab-group mat-stretch-tabs>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">highlight_off</mat-icon>
            Contratos rescindidos
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">
                    
                </mat-card-content>
            </mat-card>
        </div>

    </mat-tab>
</mat-tab-group>