<mat-tab-group mat-stretch-tabs>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="tab-icon">person_add</mat-icon>
            <span>Nuevo empleado</span>
        </ng-template>

        <div class="container-fluid mt-3">
            <mat-card class="card-form">
                <mat-card-content class="justify-align-center">
                    <form class="row mt-3" >
                        <h5 class="col-12 pb-2">Información general</h5>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Nombre(s)</mat-label>
                            <input matInput type="text" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Apellido paterno</mat-label>
                            <input matInput type="text" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Apellido materno</mat-label>
                            <input matInput type="text" required>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Teléfono fijo</mat-label>
                            <input matInput type="tel" >
                            <mat-icon matSuffix>call</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Celular</mat-label>
                            <input matInput type="tel" >
                            <mat-icon matSuffix>phone_iphone</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Correo electrónico</mat-label>
                            <input matInput type="email" >
                            <mat-icon matSuffix>email</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Dirección</mat-label>
                            <input matInput type="text" >
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-4">
                            <mat-label>Colonia</mat-label>
                            <input matInput type="text" >
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Ciudad o municipio</mat-label>
                            <input matInput type="text" >
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-3">
                            <mat-label>Estado</mat-label>
                            <input matInput type="text" >
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-2">
                            <mat-label>Código postal</mat-label>
                            <input matInput type="text" >
                        </mat-form-field>

                        <div class="col-12 text-right mt-3">
                            <button class="btn btn-m19" type="submit">Registrar empleado</button>
                        </div>

                    </form>
                </mat-card-content>
            </mat-card>
        </div>

    </mat-tab>
</mat-tab-group>