import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//LOGIN
import { LoginComponent } from "./components/login/login.component";
//PROYECT
import { ProjectComponent } from './components/project/project.component';
//CUSTOMER
import { CustomerComponent } from './components/customer/customer.component';
//SALE
import { SaleComponent } from './components/sale/sale.component';
//RECESSION
import { RecessionComponent } from './components/sale/recession/recession.component';
//REPORT
import { ReportComponent } from './components/search/report/report.component';
//USER
import { UserComponent } from './components/user/user.component';
//SETTING
import { SettingComponent } from './components/setting/setting.component';
import { PaymentMethodComponent } from './components/setting/payment-method/payment-method.component';
import { TypeUserComponent } from './components/setting/type-user/type-user.component';
//EMPLOYEE
import { EmployeeComponent } from './components/employee/employee.component';
//PAYMENTS
import { PaymentsComponent } from './components/payments/payments.component';
//GRAFICOS
import { GraficosComponent } from './components/graficos/graficos.component';
//FLOWREPORT
import { FlowReportComponent } from './components/flow-report/flow-report.component';
//FACTURADO
import { FacturasComponent } from './components/invoice/facturas/facturas.component';
//FACTURA-GLOBAL
import { FacturaGlobalComponent } from './components/invoice/factura-global/factura-global.component';
//CONSULTA-FACTURA
import { ConsultaFacturaComponent } from './components/invoice/consulta-factura/consulta-factura.component';
//SEARCH
import { SearchComponent } from './components/search/search.component';
//SEARCH-CANCELLED
import { CancelledSearchComponent } from './components/search/cancelled-search/cancelled-search.component';
//ENTRY
import { EntryComponent } from './components/entry/entry.component';
//WALLET
import { WalletComponent } from './components/wallet/wallet.component';
//ACCOUNTSTATUS
import { AccountstatusComponent } from './components/accountstatus/accountstatus.component';
//COORDINATOR-PAYMENT
import { CoordinatorPaymentComponent } from './components/commission/coordinator-payment/coordinator-payment.component';
//COORDINATOR-STATUS
import { CoordinatorStatusComponent } from './components/commission/coordinator-status/coordinator-status.component';

//WALLETBYWEK
import { WalletByWeekComponent } from './components/wallet-by-week/wallet-by-week.component';
//COORDINATOR-STATUS-ADMIN
import { AccountstatuscoordadminComponent } from './components/accountstatuscoordadmin/accountstatuscoordadmin.component';
import { RequestComponent } from './components/request/request.component';

import { AuthGuard } from './guards/auth.guard';
import { RoleGuard } from './guards/role.guard';
import { HomePageComponent } from './components/commons/home/home-page/home-page.component'
import { PdfMakerComponent } from './utils/pdf-maker/pdf-maker.component';
import { PostalCodeGeneratorComponent } from './utils/postal-code-generator/postal-code-generator.component';

import { InvoiceComponent } from './components/invoice/invoice.component';
import { MapsGenerateComponent } from './components/maps/maps-generate/maps-generate.component';
import { MapsEditComponent } from './components/maps/maps-edit/maps-edit.component';
import { MapsConsultComponent } from './components/maps/maps-consult/maps-consult.component';
import { UpdateMapToFirebaseComponent } from './components/maps/update-map-to-firebase/update-map-to-firebase.component';
import { SketchMapsComponent } from './components/maps/sketch-maps/sketch-maps.component';
import { ProyectsDetailsComponent } from './components/proyects-details/proyects-details.component';

//Requisiciones
import { RequisitionconsultComponent } from './components/requisition/requisitionconsult/requisitionconsult.component';
import { RequisitionregisterComponent } from './components/requisition/requisitionregister/requisitionregister.component';
import { RequisitioncontrolComponent } from './components/requisition/requisitioncontrol/requisitioncontrol.component';
import { RequisitionapproveComponent } from './components/requisition/requisitionapprove/requisitionapprove.component';
import { DeparturecontrolComponent } from './components/requisition/departurecontrol/departurecontrol.component';
import { InvoicereportComponent } from './components/requisition/reports/invoicereport/invoicereport.component';

//Vales de consumo (Registro, consulta, edición y reporte)
import { ConsumerTokenRegisterComponent } from './components/consumerToken/consumer-token-register/consumer-token-register.component';
import { ConsumerTokenConsultMainComponent } from './components/consumerToken/consumerTokenConsult/consumer-token-consult-main/consumer-token-consult-main.component';
import { ConsumerTokenModifyMainComponent } from './components/consumerToken/consumerTokenModify/consumer-token-modify-main/consumer-token-modify-main.component';
import { ConsumerTokenReportMainComponent } from './components/consumerToken/consumerTokenReport/consumer-token-report-main/consumer-token-report-main.component';

//Piecework
import { PieceworkregisterComponent } from './components/piecework/pieceworkregister/pieceworkregister.component';
import { PieceworkcontrolComponent } from './components/piecework/pieceworkcontrol/pieceworkcontrol.component';
import { PieceworkconsultComponent } from './components/piecework/pieceworkconsult/pieceworkconsult.component';
import { PieceworkreportComponent } from './components/piecework/pieceworkreport/pieceworkreport.component';
import { PieceworkeditComponent } from './components/piecework/pieceworkedit/pieceworkedit.component';

import { RequestDOCComponent } from './utils/request-doc/request-doc.component';
import { MapsforSalesComponent } from './components/maps/mapsfor-sales/mapsfor-sales.component';
import { EditSalesComponent } from './components/maps/edit-sales/edit-sales.component';
import { ViewRequestComponent } from './components/administration/view-request/view-request.component';
import { GeneralRequestComponent } from './components/administration/general-request/general-request.component';
import { ViewComissionComponent } from './components/controlComissions/view-comission/view-comission.component';
import { ControlRequestComponent } from './components/administration/control-request/control-request.component';
import { BudgetregisterComponent } from './components/budget/budgetregister/budgetregister.component';
import { BudgetconsultComponent } from './components/budget/budgetconsult/budgetconsult.component';
import { BudgetapproveComponent } from './components/budget/budgetapprove/budgetapprove.component';
import { BudgetreportComponent } from './components/budget/budgetreport/budgetreport.component';
import { BudgetreportPieceComponent } from './components/budget_piecework/budgetreport-piece/budgetreport-piece.component';
import { StatusPaymentsComponent } from './components/invoice/status-payments/status-payments.component';
import { GenerateAndCalculateCommisionsComponent } from './components/controlComissions/generate-and-calculate-commisions/generate-and-calculate-commisions.component';
import { ReportStatusCustomerComponent } from './components/controlComissions/ReportStatusCustomer/report-status-customer/report-status-customer.component';
// import { BudgetregisterpieceworkComponent } from './components/budget_piecework/budgetregisterpiecework/budgetregisterpiecework.component';
import { ProfileComponent } from './components/user/profile/profile.component';

import { RecessionsConsultComponent } from './components/recessions/recessions-consult/recessions-consult.component';
import { MakeRecessionsComponent } from './components/recessions/make-recessions/make-recessions.component';
import { RecessionsStatusComponent } from './components/recessions/recessions-status/recessions-status.component';

import { CustomerStatusComponent } from './components/administration/customer-status/customer-status.component';
import { CashCutComponent } from './components/controlComissions/cashCut/cash-cut/cash-cut.component';
import { GeneralViewComponent } from './components/generalReport/general-view/general-view.component';
import { RecessionsRequestComponent } from './components/administration/recessions-request/recessions-request.component';
import { DialogViewBlockedComponent } from './components/generalReport/dialogViewBlocked/dialog-view-blocked/dialog-view-blocked.component';
import { PieceworkapproveComponent } from './components/piecework/pieceworkapprove/pieceworkapprove.component';
import { FlowReportV2Component } from './components/flowReportV2/flow-report-v2/flow-report-v2.component';
import { GeneralReportsComponent } from './components/invoice-v2/general-reports/general-reports.component';
import { PendingInvoicesComponent } from './components/invoice-v2/pending-invoices/pending-invoices.component';

import { PrincipalComponent } from './components/leads/principal/principal.component';
import { LeadsAssignedComponent } from './components/leads/leadsAssigned/leads-assigned/leads-assigned.component';
import { MailVerifierComponent } from './components/mailVerifier/mail-verifier/mail-verifier.component';
import { Walletv2Component } from './components/walletV2/walletv2/walletv2.component';
import { CashCutAndCashFlowComponent } from './components/cashCutAndCashFlow/cash-cut-and-cash-flow/cash-cut-and-cash-flow.component';

//Bitácora de obra
import { CLogbookRegisterComponent } from './components/c_logbook/c-logbook-register/c-logbook-register.component';
import { PaymentCommissionsComponent } from './components/paymentCommissions/payment-commissions/payment-commissions.component';
import { CLogbookReportComponent } from './components/c_logbook/c-logbook-report/c-logbook-report.component';

import { UnmadeInvoiceComponent } from './components/accounting/invoices/unmade-invoice/unmade-invoice.component';

import { QuotationFormComponent } from './components/sale/quotation-form/quotation-form.component';

import { InvoiceStatusComponent } from './components/accounting/invoices/invoice-status/invoice-status.component';

import { AccountBalanceComponent } from './components/commons/account-balance/account-balance.component';

import { TransferValidateComponent } from './components/accounting/transfer-validate/transfer-validate.component';

import { NegotiationComponent } from './components/administration/devolutions/negotiation/negotiation.component';

import { PaybackSchedulingComponent } from './components/administration/devolutions/payback-scheduling/payback-scheduling.component';

import { RescissionAgreementComponent } from './components/administration/devolutions/rescission-agreement/rescission-agreement.component';


const routes: Routes = [
  //WALLETBYWEEK
  { path: 'walletByWeek', component: WalletByWeekComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "walletByWeek,446576656c6f706572" } },
  //COORDINATOR-STATUS-ADMIN
  { path: 'coordStatusAdmin', component: AccountstatuscoordadminComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "coordStatusAdmin,446576656c6f706572" } },
  //COORDINATOR-STATUS
  { path: 'walletByWeek', component: WalletByWeekComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "walletByWeek,446576656c6f706572" } },
  //COORDINATOR-PAYMENT
  { path: 'coordStatus', component: CoordinatorStatusComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "coordStatus,446576656c6f706572" } },
  //COORDINATOR-PAYMENT
  { path: 'coordinator', component: CoordinatorPaymentComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "coordinator,446576656c6f706572" } },
  //VIEWALLCOMISSIONS
  { path: 'viewAllComissions', component: ViewComissionComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "viewAllComissions,446576656c6f706572" } },
  //LOGIN
  { path: 'login', component: LoginComponent },
  //HOME
  { path: 'home', component: HomePageComponent, canActivate: [AuthGuard] },
  //PROYECT
  { path: 'projects', component: ProjectComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "projects,446576656c6f706572" } },
  //CUSTOMER
  { path: 'customers', component: CustomerComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "customers,446576656c6f706572" } },
  //SALE
  { path: 'sales', component: SaleComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "sales,446576656c6f706572" } },
  //RECESSION
  { path: 'recessions', component: RecessionComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "recessions,446576656c6f706572" } },
  //REPORT
  { path: 'report/:correo', component: ReportComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "report,446576656c6f706572" } },
  { path: 'report', component: ReportComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "report,446576656c6f706572" } },
  //USER
  { path: 'users', component: UserComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "users,446576656c6f706572" } },
  //SETTING
  { path: 'setting', component: SettingComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "setting,446576656c6f706572" } },
  //SETTING
  { path: 'payment_method', component: PaymentMethodComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "payment_method,446576656c6f706572" } },
  { path: 'type_user', component: TypeUserComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "type_user,446576656c6f706572" } },
  //EMPLOYEE
  { path: 'employees', component: EmployeeComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "employees,446576656c6f706572" } },
  //PAYMENTS
  { path: 'payments', component: PaymentsComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "payments,446576656c6f706572" } },
  { path: 'payments/:cliente', component: PaymentsComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "payments,446576656c6f706572" } },
  //GRAFICOS
  { path: 'graficos', component: GraficosComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "graficos,446576656c6f706572" } },
  { path: 'graficos/:reload', component: GraficosComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "graficos,446576656c6f706572" } },
  //FlowReport
  { path: 'flowReport', component: FlowReportComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "flowReport,446576656c6f706572" } },
  //FACTURADO
  { path: 'factura', component: FacturasComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "factura,446576656c6f706572" } },
  //FACTURA-GLOBAL
  { path: 'facturaGlobal', component: FacturaGlobalComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "facturaGlobal,446576656c6f706572" } },
  //CONSULTA-FACTURA
  { path: 'facturaConsulta', component: ConsultaFacturaComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "facturaConsulta,446576656c6f706572" } },
  //SEARCH
  { path: 'search', component: SearchComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "search,446576656c6f706572" } },
  //CANCELLED SEARCH
  { path: 'cancelled', component: CancelledSearchComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "cancelled,446576656c6f706572" } },
  //ENTRY
  { path: 'entry', component: EntryComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "entry,446576656c6f706572" } },
  //WALLET
  { path: 'wallet', component: WalletComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "wallet,446576656c6f706572" } },
  //ACCOUNTSTATUS
  //PROSPECTOS A CLIENTES
  { path: 'request', component: RequestComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "request,446576656c6f706572" } },

  { path: 'account', component: AccountstatusComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "3,446576656c6f706572" } },

  //GENERADOR DE PDF INTERFAZ INTERNA
  { path: 'pdfmaker', component: PdfMakerComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "pdfmaker,446576656c6f706572" } },

  //consulta de datos
  { path: 'PostalCode', component: PostalCodeGeneratorComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "PostalCode,446576656c6f706572" } },

  //Requisiciones
  { path: 'RequisitionConsult', component: RequisitionconsultComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "RequisitionConsult,446576656c6f706572" } },
  { path: 'RequisitionRegister', component: RequisitionregisterComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "RequisitionRegister,446576656c6f706572" } },
  { path: 'RequisitionControl', component: RequisitioncontrolComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "RequisitionControl,446576656c6f706572" } },
  { path: 'RequisitionApprove', component: RequisitionapproveComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "RequisitionApprove,446576656c6f706572" } },
  { path: 'DepartureControl', component: DeparturecontrolComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "DepartureControl,446576656c6f706572" } },
  { path: 'InvoiceReport', component: InvoicereportComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "InvoiceReport,446576656c6f706572" } },

  //Vale de consumo (Registro, consulta, edición y reporte)
  { path: 'ConsumerTokenRegister', component: ConsumerTokenRegisterComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "ConsumerTokenRegister,446576656c6f706572" } },
  { path: 'ConsumerTokenConsultMainComponent', component: ConsumerTokenConsultMainComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "ConsumerTokenConsultMainComponent,446576656c6f706572" } },
  { path: 'ConsumerTokenModifyMain', component: ConsumerTokenModifyMainComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "ConsumerTokenModifyMain,446576656c6f706572" } },
  { path: 'ConsumerTokenReportMainComponent', component: ConsumerTokenReportMainComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "ConsumerTokenReportMainComponent,446576656c6f706572" } },

  //Destajos
  { path: 'PieceworkRegister', component: PieceworkregisterComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "PieceworkRegister,446576656c6f706572" } },
  { path: 'PieceworkControl', component: PieceworkcontrolComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "PieceworkControl,446576656c6f706572" } },
  { path: 'PieceworkConsult', component: PieceworkconsultComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "PieceworkConsult,446576656c6f706572" } },
  { path: 'PieceworkReport', component: PieceworkreportComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "PieceworkReport,446576656c6f706572" } },
  { path: 'PieceworkEdit', component: PieceworkeditComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "PieceworkEdit,446576656c6f706572" } },
  { path: 'PieceworkApprove', component: PieceworkapproveComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "PieceworkApprove,446576656c6f706572" } },
  //Presupuestos
  { path: 'BudgetRegister', component: BudgetregisterComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "BudgetRegister,446576656c6f706572" } },
  { path: 'BudgetConsult', component: BudgetconsultComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "BudgetControl,446576656c6f706572" } },
  { path: 'BudgetApprove', component: BudgetapproveComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "BudgetApprove,446576656c6f706572" } },
  { path: 'BudgetReport', component: BudgetreportComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "BudgetReport,446576656c6f706572" } },
  { path: 'BudgetReportPiece', component: BudgetreportPieceComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "BudgetReportPiece,446576656c6f706572" } },

  //Presupuestos de destajos
  // { path: 'BudgetRegisterPiecework', component: BudgetregisterpieceworkComponent, canActivate: [RoleGuard,AuthGuard], data: {expectedRole: "BudgetRegisterPiecework,446576656c6f706572"}},
  // { path: 'BudgetConsultPiecework', component: BudgetconsultpieceComponent, canActivate: [RoleGuard,AuthGuard], data: {expectedRole: "BudgetConsultPiecework,446576656c6f706572"}},

  { path: 'mapsgenerate', component: MapsGenerateComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "mapsgenerate,446576656c6f706572" } },

  //consulta de datos}
  { path: 'mapsediting', component: MapsEditComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "mapsediting,446576656c6f706572" } },

  //consulta de datos}
  { path: 'mapsconsult', component: MapsConsultComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "mapsconsult,446576656c6f706572" } },

  //consulta de datos}
  { path: 'updatemap', component: UpdateMapToFirebaseComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "updatemap,446576656c6f706572" } },

  //mini croquis de todos los lotes
  { path: 'sketchMaps', component: SketchMapsComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "sketchMaps,446576656c6f706572" } },

  { path: 'requestDoc', component: RequestDOCComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "requestDoc,446576656c6f706572" } },
  { path: 'mapsforsales', component: MapsforSalesComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "mapsforsales,446576656c6f706572" } },
  { path: 'editSales', component: EditSalesComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "editSales,446576656c6f706572" } },
  { path: 'view-request', component: ViewRequestComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "view-request,446576656c6f706572" } },
  { path: 'general-request', component: GeneralRequestComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "general-request,446576656c6f706572" } },
  { path: 'control-request', component: ControlRequestComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "control-request,446576656c6f706572" } },
  { path: 'recession-request', component: RecessionsRequestComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "recession-request,446576656c6f706572" } },

  { path: 'statuspayments', component: StatusPaymentsComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "statusinvoice,446576656c6f706572" } },
  { path: 'GenerateAndCalculateCommisionsComponent', component: GenerateAndCalculateCommisionsComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "GenerateAndCalculateCommisionsComponent,446576656c6f706572" } },
  { path: 'RSC', component: ReportStatusCustomerComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "ReportStatusCustomer,446576656c6f706572" } },

  { path: 'MakeRecessions', component: MakeRecessionsComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "MakeRecessions,446576656c6f706572" } },
  { path: 'RecessionsConsult', component: RecessionsConsultComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "RecessionsConsult,446576656c6f706572" } },
  { path: 'RecessionsStatus', component: RecessionsStatusComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "RecessionsStatus,446576656c6f706572" } },


  { path: 'ViewReportGeneral', component: GeneralViewComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "ReportGeneral,446576656c6f706572" } },

  //Ruta  ↑                                                                                            Nombre del permiso ↑  o este otro ↑
  { path: 'DialogBlocked', component: DialogViewBlockedComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "DialogBlocked,446576656c6f706572" } },
  { path: 'InvoiceSlopeRANGE', component: GeneralReportsComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "InvoiceReport,446576656c6f706572" } },
  { path: 'pendingInvoice', component: PendingInvoicesComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "InvoiceReport,446576656c6f706572" } },
  { path: 'leadsPrincipal', component: PrincipalComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "leadsPrincipal,446576656c6f706572" } },
  { path: 'leadsAssigned', component: LeadsAssignedComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "leadsAssigned,446576656c6f706572" } },
  { path: 'mailVerify', component: MailVerifierComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "email,446576656c6f706572" } },
  { path: 'walletV2', component: Walletv2Component, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "walletV2,446576656c6f706572" } },

  { path: 'ReportCashCut', component: CashCutComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "cashCut,446576656c6f706572" } },
  { path: 'cashCut', component: CashCutAndCashFlowComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "cashCut,446576656c6f706572" } },


  { path: 'CustomerStatus', component: CustomerStatusComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "customerStatus,446576656c6f706572" } },
  { path: 'Profile', component: ProfileComponent },
  { path: 'ReportCashCut', component: CashCutComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "cashCut,446576656c6f706572" } },
  { path: 'ViewReportGeneral', component: GeneralViewComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "ReportGeneral,446576656c6f706572" } },
  //Ruta  ↑                                                                                            Nombre del permiso ↑  o este otro ↑
  { path: 'flowReportV2', component: FlowReportV2Component, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "flowReportV2,446576656c6f706572" } },


  //C_Logbook
  { path: 'CLogbookRegister', component: CLogbookRegisterComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "CLogbookRegister,446576656c6f706572" } },
  { path: 'CLogbookReport', component: CLogbookReportComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "CLogbookReport,446576656c6f706572" } },
  { path: 'paymentsCommissions', component: PaymentCommissionsComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "paymentCommissions,446576656c6f706572" } },

  { path: 'unmadeInvoice', component: UnmadeInvoiceComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "unmadeInvoice,446576656c6f706572" } },
  { path: 'quotationForm', component: QuotationFormComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "quotationForm,446576656c6f706572" } },
  { path: 'invoiceStatus', component: InvoiceStatusComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "invoiceStatus,446576656c6f706572" } },
  { path: 'accountBalance', component: AccountBalanceComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "accountBalance,446576656c6f706572" } },
  { path: 'transferValidate', component: TransferValidateComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "transferValidate,446576656c6f706572" } },
  { path: 'negotiation', component: NegotiationComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "negotiation,446576656c6f706572" } },
  { path: 'scheduling', component: PaybackSchedulingComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "scheduling,446576656c6f706572" } },
  { path: 'RescissionAgreement', component: RescissionAgreementComponent, canActivate: [RoleGuard, AuthGuard], data: { expectedRole: "RescissionAgreement,446576656c6f706572" } },

  //REDIRIGIR CUANDO SEA DIFERENTE A LAS ANTERIORES
  { path: '**', pathMatch: 'full', redirectTo: 'home' }

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
