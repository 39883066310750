<div class="container-fluid mt-3">
    <mat-card>
        <mat-card-content>
            <form class="row mt-3">

                <h5 class="col-12 pb-2">Nuevo tipo de usuario</h5>

            </form>
        </mat-card-content>
    </mat-card>
</div>