import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Lender } from '../models/lender';

@Injectable({
  providedIn: 'root'
})
export class LenderService {

  constructor(private http: HttpClient) { }

  //Seleccionar todos los lenders
  selectUsers() {
    return this.http.get(`${environment.URLServer}/lender`);
  }

  //Consultar un prestanombres en específico
  selectUser(id: number) {
    return this.http.get(`${environment.URLServer}/lender/${id}`);
  }

  //Registrar un lender
  createlender(lender: Lender) {
    return this.http.post(`${environment.URLServer}/lender`, lender);
  }

  //Modificar un lender
  updateUser(id: string, update: Lender): Observable<Lender> {
    return this.http.put(`${environment.URLServer}/lender/${id}`, update);
  }

  //Eliminar un lender
  deleteUser(id: number) {
    return this.http.delete(`${environment.URLServer}/lender/${id}`);
  }
}
