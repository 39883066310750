<ng-template mat-tab-label>
    <mat-icon class="tab-icon">person_search</mat-icon>
    Consultar clientes
</ng-template>

<div class="container-fluid mt-3">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">

            <h5>Consultar clientes</h5>

            <input type="text" style = 'width:100%; font-size: 120%;' class="forms-control" placeholder="Ingresa el nombre del cliente" [(ngModel)]="filterCustomer"
                name="filterCustomer" />


            <table class="table table-striped mt-3 animated fadeIn faster">
                <thead class="thead-m19">
                    <tr>
                        <th scope="col">Nombre completo</th>
                        <th scope="col">Celular</th>
                        <th scope="col">Correo electrónico</th>
                        <th scope="col">Acciones</th>
                    </tr>
                </thead>
                <tbody> 
                    <tr *ngFor="let customer of customers | filterCustomer: filterCustomer">
                        <td>{{customer.name}} {{customer.firstLastName}} {{customer.secondLastName}}</td>
                        <td>{{customer.mobile}}</td>
                        <td>{{customer.email}}</td>
                        <td class="button-row">
                            <button class="btn btn-m19-table" mat-icon-button (click)="selectCustomer(customer.idUser)">
                                <mat-icon>note_add</mat-icon>
                            </button>

                        </td>
                    </tr>
                </tbody>
            </table>

        </mat-card-content>
    </mat-card>
</div>