import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import decode from 'jwt-decode';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/services/user.service';

import { SharedServiceService } from 'src/app/shared/shared-service.service';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent implements OnInit {

  @Input('size') size: any;
  @Output('onMenu') menuClicked = new EventEmitter();

  decodetoken: any = {};
  user: any;
  iduser: number;
  header: any;

  today: Date = new Date();

  page: string = "";
  show: boolean = false;

  constructor(
    public router: Router,
    private userService: UserService,
    public sweet: SweetAlertComponent,
    public sharedService: SharedServiceService
  ) {
    this.sharedService.page = localStorage.getItem("page");
  }

  ngOnInit(): void {
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: any) {
    if (!event.target.closest('.profileData')) {
      this.show = false;
    } else {
      this.show = true;
    }
  }

  getPicture() {
    const token = localStorage.getItem('token') || "";

    let decodetoken: any = {};
    decodetoken = decode(token);
    return decodetoken.picture;
  }

  selectUser() {
    const token = localStorage.getItem('token') || "";

    this.decodetoken = decode(token);
    this.user = this.decodetoken.cliente;
  }

  public onMenu() {
    this.menuClicked.emit();
  }

  menuItem() {
    if (localStorage.getItem('token')) {
      this.selectUser()
      return true;
    }
    return false;
  }

  logOut() {
    Swal.fire({
      title: '¿Quieres cerrar sesión?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, espera',
      confirmButtonText: 'Cerrar sesión'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('token');
        localStorage.removeItem("christmasView");
        localStorage.removeItem("newyearView");
        this.router.navigate(['login']);
        this.sweet.AlertTime('center', 'success', 'Nos vemos!', false, 2000)
      }
    })
  }
}