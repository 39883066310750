import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class CLogbookService {
  serverDate: string;
  storageRef: any;
  constructor(private http: HttpClient) { this.storageRef = firebase.app("clogbook").storage().ref(); }

  async uploadImage(name: string, imgBase64: any) {
    let index = 0
    let links: any = []
    for await (let img64 of imgBase64) {
      try {
        let respuesta = await this.storageRef.child("SfaL9wO6v8U1uh5s9K6Fp7z/" + name + '_img_' + index).putString(img64, 'data_url');
        links[index] = await respuesta.ref.getDownloadURL();
        index++;
      } catch (err) {
        console.log(err);
        return 'null';
      }
    }
    return await links;
  }

  async uploadPdf(name: string, pdf64: any) {
    if (pdf64) {
      let index = 0
      let links: any = []
      for await (let p64 of pdf64) {
        try {
          let respuesta = await this.storageRef.child("SfaL9wO6v8U1uh5s9K6Fp7z/" + name + '_pdf_' + index).putString(p64, 'data_url');
          links[index] = await respuesta.ref.getDownloadURL();
          index++;
        } catch (err) {
          console.log(err);
          return 'null';
        }
      }
      return await links;
    } else {
      return null;
    }
  }

  insertCLogbook(cLogbook: any): Observable<any> {
    return this.http.post(`${environment.URLServer}/cLogbook/insertCLogbook/`, cLogbook);
  }

  insertCLogbook_Docs(cLogbook: any): Observable<any> {
    return this.http.post(`${environment.URLServer}/cLogbook/insertCLogbook_Docs/`, cLogbook);
  }

  insertCLogbook_Log(cLogbook: any): Observable<any> {
    return this.http.post(`${environment.URLServer}/cLogbook/insertCLogbook_Log/`, cLogbook);
  }

  insertclogbookDepartures(cLogbook: any): Observable<any> {
    return this.http.post(`${environment.URLServer}/cLogbook/insertclogbookDepartures/`, cLogbook);
  }

  /////////////////////////////// APARTADO DE PENDIENTES DE CIERRE /////////////////////////////
  getPendingtoCloseService(): Observable<any> {
    return this.http.get(`${environment.URLServer}/cLogbook/getPendingtoCloseRoute/`)
  }

  getPenDepartureList(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/cLogbook/getPenDepartureList/${id}`)
  }

  getEvidenceService(NoLog: number): Observable<any> {
    return this.http.post(`${environment.URLServer}/cLogbook/getEvidenceIMGRoute/`, { NoLog });
  }

  updateCLogbookService(work: number, remarks: string, idclog: number): Observable<any> {
    return this.http.put(`${environment.URLServer}/cLogbook/putpdateLogRoute/`, { work, remarks, idclog });
  }

  deleteCLogbookService(idclog: number, user: number): Observable<any> {
    return this.http.put(`${environment.URLServer}/cLogbook/delClogbookRoute/`, { idclog, user });
  }


  getDaysInMonth(date: String): Observable<any> {
    return this.http.get(`${environment.URLServer}/cLogbook/getDaysInMonth/${date}`);
  }

  getColors(date: String, pro: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/cLogbook/getColors/${date}/${pro}`);
  }

  getLogbookPerDay(date: String, pro: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/cLogbook/getLogbookPerDay/${date}/${pro}`);
  }

  getEvidences(date: String, pro: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/cLogbook/getEvidences/${date}/${pro}`);
  }

  printLogbookUnique(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/cLogbook/printLogbookUnique/${id}`);
  }

  printEvidences(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/cLogbook/printEvidences/${id}`);
  }

  getHistoryReportService(start : string , end: string , proj:string): Observable<any> {
    return this.http.post(`${environment.URLServer}/cLogbook/getHistoryReportService/`, {start , end, proj});
  }

  getHistoryReporImagesService(idImage :number): Observable<any> {
    return this.http.post(`${environment.URLServer}/cLogbook/getHistoryReportImagesRoutes/`, {idImage});
  }

  

}
