import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';

import { UtilsService } from 'src/app/services/utils.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogCustomerComponent } from './dialog-customer/dialog-customer.component';
import { SweetAlertComponent } from '../../utils/sweet-alert/sweet-alert.component';
import { Router } from '@angular/router';
import { DialogCustomerFormComponent } from './dialog-customer-form/dialog-customer-form.component';

import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  @ViewChild('Neighborhood') neighborhood: ElementRef;
  @ViewChild('Municipality') municipality: ElementRef;
  @ViewChild('State') state: ElementRef;

  neighborhoods: any[];
  countries: string[];

  filteredHoods: string[];
  filteredCountries: string[];

  filterCustomer = '';
  customers: any = [];
  customer: any = [];
  civil_status: any = [];

  customerBanner: BannerTitle = {
    title: "Registro de cliente",
    icon: "person_add",
    position: "center",
    background: "var(--M19Secundary)"
  }

  customerConsultBanner: BannerTitle = {
    title: "Consulta de clientes",
    icon: "person_search",
    position: "center",
    background: "var(--M19Secundary)"
  }

  customerFormGroup: FormGroup = this._formBuilder.group({
    idUser: [],
    name: [, Validators.required],
    firstLastName: [, Validators.required],
    secondLastName: [, Validators.required],
    gender: [, Validators.required],
    telephone: [,],
    mobile: [, Validators.required],
    email: [, Validators.required],
    birthDate: [, Validators.required],
    birthPlace: [, Validators.required],
    nationality: [, Validators.required],
    CURP: [, Validators.required],
    voterKey: [, Validators.required],
    occupation: [, Validators.required],
    civilStatus: [, Validators.required],
    address: [, Validators.required],
    localidad: [, Validators.required],
    municipality: [, Validators.required],
    state: [, Validators.required],
    postalCode: [, Validators.required],
    fk_typeUser: [3],
    RFC: [],
    billing: [0, Validators.required],
    precustomer: [0]
  });

  constructor(
    private customerService: CustomerService,
    private utilsService: UtilsService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public sweet: SweetAlertComponent,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.selectCustomers();
    this.selectCivilStatus();

    this.utilsService.getCountries().subscribe(
      res => {
        this.countries = res;
      }
    );
  }

  onNationalityChange(value: string) {
    this.filteredCountries = this.filterCountry(value);
  }

  onNeighborhoodChange(value: string) {
    this.filteredHoods = this.filterHood(value);
  }

  private filterCountry(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.countries.filter(option => option.toLowerCase().includes(filterValue));
  }

  private filterHood(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.neighborhoods.filter(option => option.toLowerCase().includes(filterValue));
  }

  //Consultar todos los clientes registrados
  selectCustomers() {
    this.customerService.selectCustomers().subscribe(
      res => {
        this.customers = res;
      },
      err => console.log(err)
    );
  }

  //Consultar cliente especifico
  selectCustomer(id: number) {
    this.customerService.selectCustomer(id).subscribe(
      res => {
        this.customer = res;
        this.dialog.open(DialogCustomerComponent, {
          width: '50%',
          data: res
        });
      },
      err => console.log(err)
    )
  }

  //Registrar un cliente
  createCustomer() {
    // if (this.customerFormGroup.invalid) {
    //   return;
    // }
    // else {
    //   this.customerService.createCustomer(this.customerFormGroup.value)
    //     .subscribe(
    //       res => {
    //         this.customer = res;
    //         this.sweet.AlertTime("center", "success", "Cliente registrado con éxito", false, 2000);
    //         this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['customers']));
    //       },
    //       err => {
    //         console.error(err);
    //       }
    //     );
    // }

    console.log("CLIENTE: ", this.customerFormGroup.value);
  }

  //Modificar cliente
  updateCustomer(id: number) {
    this.customerService.selectCustomer(id).subscribe(
      res => {
        this.customer = res;
        console.log(res)
        this.dialog.open(DialogCustomerFormComponent, {
          width: '70%',
          data: res
        });
      },
      err => console.log(err)
    )
  }

  //Consultar estado civil
  selectCivilStatus() {
    this.customerService.selectCivilStatus().subscribe(
      res => {
        this.civil_status = res;
      },
      err => console.log(err)
    );
  }

  checkField(field: HTMLInputElement) {
    field.value = this.correctText(field.value);
  }

  toUpperCase(field: HTMLInputElement) {
    field.value = field.value.toUpperCase();
  }

  correctText(txt: string): string {
    return txt
      .toLowerCase()
      .replace(/\s+/g, ' ')
      .trim()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  getPCData(pc: string) {
    if (pc.length == 5) {
      this.neighborhoods = [];
      this.filteredHoods = [];

      this.customerFormGroup.patchValue({
        neighborhood: "",
        municipality: "",
        state: ""
      });

      this.utilsService.getPCData(pc).subscribe(
        res => {
          this.neighborhoods = res.codigo_postal.colonias;
          this.customerFormGroup.patchValue({
            municipality: this.correctText(res.codigo_postal.municipio),
            state: this.correctText(res.codigo_postal.estado)
          });
        },
        error => {
          this.sweet.AlertIcon("error", "Código Postal Inexistente", "El código postal no está registrado en la base de datos mexicana.", "");
        }
      );
    }
  }
}