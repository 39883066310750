import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Payment } from '../../models/Payment';
import { ReportService } from 'src/app/services/report.service';
import { PaymentService } from 'src/app/services/payment.service';
import { TotalPagadosService } from 'src/app/services/totalPagados.service';
import { Router } from '@angular/router';
import { noteReport } from 'src/app/models/Note';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import  decode  from 'jwt-decode'
import { MatDialog } from '@angular/material/dialog';
import { DialogWalletComponent } from './dialog-wallet/dialog-wallet.component';
import { DialogNotesComponent } from './dialog-notes/dialog-notes.component';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {

  //Manejo de información res API, arreglos con carga de datos de las consultas a los servicios que conectan con la API
  flujo:any=[]
  ingreso:any=[]
  flujoTotal:any=[]
  ingresoTotal:any=[]
  carteraR:any=[]
  carteraA:any=[]
  carteraV:any=[]
  carteraT:any=[]
  carteraG:any=[]
  cartera:any=[]
  carteraListado:any=[]
  proyecto:any=[]
  carteraInmu:any=[]
  coordListado:any=[]
  reportListBack:any=[]
  montos:any=[];
  montosListado:any=[];
  reportDevBack:any=[]
  reportDevBackList:any=[]

  //Notas
  active_note = ''

  //Reporte pagos
  permissions(){
    const token = localStorage.getItem('token') || "" ; 
    
    let decodetoken:any = {};
    decodetoken = decode(token);

    return decodetoken.permissions;
  }

  //Banderas de impresión de datos particulares de acuerdo al modulo
  TarCar=true
  TarEst=false
  ListM = false
  ListG = true
  VP = false
  X = false

  desCar(){
    return this.TarEst=true,this.TarCar=false
  }
  desEst(){
    return this.TarEst=false,this.TarCar=true
  }
  desG() {
    return this.VP = false, this.X = true
  }
  desX() {
    return this.X = false, this.VP = true, this.ListG=false, this.ListM=true
  }
  inmu() {
    return this.X = false,this.VP=true
  }

  //Nomenclatura inmueble
  M="Manzana No."
  L="Lote No."
  T="Torre No."
  D="Departamento No."
 
  //Grafico y control de pagos
  graficoActual=true
  graficoAnual=false
  limite = -4
  tolerancia = -1
  tolerancia2 = -2
  tolerancia3 = -3

  limiteRe = 4
  toleranciaRe = 1
  tolerancia2Re = 2
  tolerancia3Re = 3


    // Configuraciones de graficos
    public pieChartOptions: ChartOptions = {
      responsive: true,
      legend: {
        position: 'top',
        display:true,
        fullWidth:true
      },
      showLines:true,
      tooltips:{
        enabled:true,
        caretSize:0,
        displayColors:true,
        titleFontSize:20,
        bodyFontSize:20
      },
      spanGaps:true,
      plugins: {
        datalabels: {
          formatter: (value: any, ctx: any) => {
            const label = ctx.chart.data.labels[ctx.dataIndex];
            return label;
          },
        },
      }
    };
    public piePruebaOptions: ChartDataSets={
      showLine:true
    }
    public pieChartLabels: Label[] = [['Rescisión'], ['Emergente 2-3 atrasos'],['Un solo atraso']];  
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = true;
    public pieChartPlugins = [pluginDataLabels];
    public pieChartColors = [
      {
        backgroundColor: ['rgb(211, 25, 16 )', 'rgb(242, 228, 12)','rgb(39, 162, 11 )'],
      },
    ];
    //Ventana reporte pagos

      //barras
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = ['Flujo actual'];
  public barchartLAbelAnual: Label[]=['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octumbre','Noviembre','Diciembre'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  
    
  //Manejo de fechas
  n = new Date();
  //Año
  y = this.n.getFullYear();
  //Mes
  m = this.n.getMonth() + 1;
  //Día
  d = this.n.getDate();

  h = this.n.toLocaleTimeString()  
  salida = ""
  anios(){
    return this.y
  }
  mess(){
    return this.m
  }
  dias(){
    return this.d
  }
  salidaFecha() {
    if (this.m < 9 && this.d < 9) {
      this.salida = this.y + '-' + '0' + this.m + '-' + '0' + this.d + ' ' + this.h;
    } else if (this.d < 9 && this.m > 9) {
      this.salida = this.y + '-' + this.m + '-' + '0' + this.d + ' ' + this.h;
    } else if (this.d > 9 && this.m < 9) {
      this.salida = this.y + '-' + '0' + this.m + '-' + this.d + ' ' + this.h;
    } else {
      this.salida = this.y + '-' + this.m + '-' + this.d + ' ' + this.h;
    }
    return this.salida
  }

  //Notas
  descripcion="";
  notas: any = [];
  firstNote: any =[];
  endNote:any = []
  notes: any = [];

  nota: noteReport = {
    id:0,
    noteDes:'',
    registrationDate:this.salidaFecha(),
    idUser:0,
    fkPropertyNote:''
  };
  //Objeto de servicio pagos
  payments: any = [];
  payment: Payment= {
    id: 0,
    fechaPago: '',
    cantidadAbono: 0,
    estadoPago: '',
    metodoPago: '',
    folioPago: 0,
    concepto: '',
    fkCliente: ''
  };

  constructor(
    public modal:NgbModal,
    private toastr: ToastrService,
    private reportService: ReportService,
    private reportServices: PaymentService,
    private totalPagados: TotalPagadosService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  //Metodo de carga de metodos al momento de la ejecución
  ngOnInit(): void {
    this.reporteCarteraListado()
    this.selectTotalesListado();    
    this.selectAtrasosDes();
    this.selectAtrasosDesList();
    this.reporteCarteraProyecto(); 
    this.listCoordinador();
  }

  //Alertas de aprobación de notas
  showToastr(){    
    this.toastr.success('Nota agregada con exito')
  }
  showToastrDan(){    
    this.toastr.error('Error: Nota no agragada')
  }
  //NOTAS
  createNote(id:any) {        
    this.nota.fkPropertyNote=id
    this.reportService.createNotes(this.nota)
      .subscribe(
        res => {           
          this.showToastr()                 
          this.router.navigate(['/wallet'])
        },
        err => this.showToastrDan
      );
  }

  //Consulta de datos desde el servicio que consulta a la API
  selectFirstNote(first:any) {
    this.reportService.noteFirst(first).subscribe(
      res => {                
        
        this.firstNote = res;
        
      },
      err => console.log(err)
    );
  }
  selectEndNote(end:any) {
    this.reportService.noteNew(end).subscribe(
      res => {

        this.endNote = res;

      },
      err => console.log(err)
    );
  }

  selectPropertyNote(codeProperty: any){
    this.reportService.notes(codeProperty).subscribe(
      res => {
        this.notes = res;
        let info = {"notes": res, "fkProperty": codeProperty}
        //localStorage.setItem('property note',codeProperty)
        this.dialog.open(DialogNotesComponent, {
          width: '500px',
          data: info
        });
      },
      err => console.log(err)
    )
  }

  selectNotes(codeProperty: any){
    this.reportService.notes(codeProperty).subscribe(
      res => {
        this.notes = res
        localStorage.setItem('property',codeProperty)
        this.dialog.open(DialogWalletComponent, {
          width: '700px',
          data: res
        })
      },
      err => console.log(err)
    )
  }

  //Consulta estado de cuenta
  selectTotalesListado() {
    this.totalPagados.totalPagadosListado().subscribe(
      res => {
        
        this.montosListado = res;
      },
      err => console.log(err)
    );
  }
  //Grafico cartera
  reporteCarteraListado() {
    this.reportService.reporteCartera().subscribe(
      res => {
        
        this.carteraListado = res;
      },
      err => console.log(err)
    );
  }
  reporteCartera(inmDesC:string) {
    this.reportService.reporteCarteraDesarrollo(inmDesC).subscribe(
      res => {        
        this.cartera = res;
      },
      err => console.log(err)
    );
  }
  reporteCarteraProyecto() {
    this.reportService.reporteProyecto().subscribe(
      res => {       
        
        this.proyecto = res;
      },
      err => console.log(err)
    );
  }
  
  reporteInmuCartera(carInmu:string) {
    this.reportService.reporteCarteraInmueble(carInmu).subscribe(
      res => {
        
        
        this.carteraInmu = res;
      },
      err => console.log(err)
    );
  }
    //Listado coordinadores
    listCoordinador() {
      this.reportService.listadoCoordinadorP().subscribe(
        res => {        
          this.coordListado = res;        
        },
        err => console.log(err)
      );
    }
  
    //Cartera por coordinador
    reporteCarteraCoordinador(coordinadorCartera:string) {
      this.reportService.reporteCarteraCoordinador(coordinadorCartera).subscribe(
        res => {        
          this.carteraListado = res;
        },
        err => console.log(err)
      );
    }
  
    //-------------------------

  carteraRoja() {
    this.reportService.carteraR().subscribe(
      res => {
        
        this.carteraR = res;
      },
      err => console.log(err)
    );
  }
  carteraAmarilla() {
    this.reportService.carteraA().subscribe(
      res => {
        
        this.carteraA = res;
      },
      err => console.log(err)
    );
  }
  carteraVerde() {
    this.reportService.carteraV().subscribe(
      res => {
        
        this.carteraV = res;
      },
      err => console.log(err)
    );
  }
  carteraTotal() {
    this.reportService.carteraT().subscribe(
      res => {
        
        this.carteraT = res;
      },
      err => console.log(err)
    );
  }
  carteraGrafico() {
    this.reportService.carteraG().subscribe(
      res => {
        
        this.carteraG = res;
      },
      err => console.log(err)
    );
  }
  
  //Manejo de reporte de atrasos
  selectListadoAtrasos(repDesCar:string) {
    this.reportService.reporteDesListAtrasos(repDesCar).subscribe(
      res => {
        
        
        this.reportListBack = res;
      },
      err => console.log(err)
    );
  }
  selectAtrasosDes() {
    this.reportService.reporteDesAtra().subscribe(
      res => {
              
        this.reportDevBack = res;
        
      },
      err => console.log(err)
    );
  }
  selectAtrasosDesList() {
    this.reportService.reporteDesarrolloAtrasosCartera().subscribe(
      res => {
              
        this.reportDevBackList = res;
        
      },
      err => console.log(err)
    );
  }
  //Manejo de reporte de pagos 
  slectPayments(cliente:string) {
    this.reportServices.selectPayments(cliente).subscribe(
      res => {
        this.active_note = cliente
        localStorage.setItem('property',this.active_note)
        this.payments = res;
      },
      err => console.log(err)
    );
  }
  selectTotales() {
    this.totalPagados.totalPagados().subscribe(
      res => {
        
        this.montos = res;
      },
      err => console.log(err)
    );
  }
  cancelPayment(id: any) {
    
    delete this.payment.id;
    delete this.payment.fechaPago
    delete this.payment.estadoPago
    this.reportServices.updatePaymentCanceled(id, this.payment)
      .subscribe(
        res => {
          
          this.router.navigate(['payments'])
        },
        err => console.error(err)
      );
  }

  deletePayment(id: any) {
    
    this.reportServices.deletePayment(id).subscribe(data => {
    this.slectPayments(id);
    }, error => {
      console.log(error);
      this.router.navigate(['payments'])
    })
  }

}

function data(data: any) {
  throw new Error('Function not implemented.');
}
