<div class="container-fluid mt-3" *ngIf="formularioActivo">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">

            <table class="m19Table">
                <thead>
                    <tr>
                        <th scope="col" style="text-align: center;">#</th>
                        <th scope="col">Inmueble</th>
                        <th scope="col">Cliente</th>
                        <th scope="col">RFC</th>
                        <th scope="col">Fecha de pago</th>
                        <th scope="col">Método de pago</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">Folio</th>
                        <th scope="col">Estado</th>
                        <th scope="col">Método de facturacion</th>
                        <th scope="col" style="text-align: center;">Acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let invoices of invoice; let i=index">
                        <th scope="row" style="text-align: center;">{{i+1}}</th>
                        <th>{{invoices.fkProperty}}</th>
                        <td>{{invoices.UserName}}</td>
                        <td>{{invoices.RFC}}</td>
                        <td>{{invoices.fechaPago | date: 'dd-MM-yyyy'}}</td>
                        <td>{{invoices.paymentType}}</td>
                        <td style="white-space: nowrap;">{{(invoices.amountInvoice | currency:'CAD' : '$ ')}}</td>
                        <td>{{invoices.folInvoice}}</td>
                        <td>{{invoices.invoiceStatus}}</td>
                        <td [ngStyle]="{'color': invoices.invoiceType=='SIN ASIGNAR'?'var(--M19Primary)':'black'}">
                            <b>{{invoices.invoiceType}}</b>
                        </td>
                        <td style="text-align: center;">
                            <mat-icon class="m19IconBtn"
                                (click)="selectID(invoices.id);conID(invoices.fkProperty);apagarFormulario()">file_download_done</mat-icon>
                        </td>
                    </tr>
                </tbody>
            </table>
        </mat-card-content>
    </mat-card>
</div>


<div class="container-fluid mt-3" *ngIf="formularioApagado">
    <mat-card class="card-form">
        <mat-card-title *ngFor="let con of actualConteo">
            <h5>{{"Complemento No."+con.conteoComplementos}}</h5>
        </mat-card-title>

        <mat-card-content class="justify-align-center">
            <table class="table table-striped animated fadeIn faster">
                <thead class="thead-m19">
                    <tr>
                        <th scope="col">No.</th>
                        <th scope="col">Inmueble</th>
                        <th scope="col">Cliente</th>
                        <th scope="col">RFC</th>
                        <th scope="col">Fecha de pago</th>
                        <th scope="col">Método de pago</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">Folio de recibo</th>
                        <th scope="col">Folio de factura</th>
                        <th scope="col">Acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let invoID of facturaID; let i=index">
                        <th scope="row">{{i+1}}</th>
                        <th>{{invoID.fkProperty}}</th>
                        <td>{{invoID.UserName}}</td>
                        <td>{{invoID.RFC}}</td>
                        <td>{{invoID.registrationDate | date: 'dd-MM-yyyy'}}</td>
                        <td>{{invoID.paymentType}}</td>
                        <td>{{invoID.amountInvoice | currency:'CAD' : '$ '}}</td>
                        <td>{{invoID.folInvoice}}</td>
                        <td>
                            <input style="width: 4cm; height: 1cm; text-align: center;" type="number" id="folioInvoice"
                                placeholder="0" name="invoice" [(ngModel)]="invReport.accountingFolio" required>
                        </td>
                        <td>
                            <button class="btn btn-m19" (click)="aprobarFactura(invoID.id);activarFormulario()">
                                Aprobar
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </mat-card-content>
    </mat-card>
</div>