import { Component, OnInit } from '@angular/core';

import { Payment } from '../../../models/Payment';
import { PaymentService } from '../../../services/payment.service';
import { LogosService } from 'src/app/services/logos.service';

import { paymentMetode } from '../../../models/paymentMetode';
import { Router } from '@angular/router';
import { ReportService } from '../../../services/report.service';
import { Report } from 'src/app/models/Report';
import { SalesService } from 'src/app/services/sales.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import decode from 'jwt-decode'
import { PdfMakerComponent } from 'src/app/utils/pdf-maker/pdf-maker.component';

import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { DatePipe } from '@angular/common';

import { CreditPaymentsComponent } from './credit-payments/credit-payments.component';
import { MatDialog } from '@angular/material/dialog';

import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';

@Component({
  selector: 'app-report;[ngVar]',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  bannerTitle: BannerTitle = {
    title: "Estado de cuenta",
    icon: "groups",
    position: "center",
    background: "var(--M19Secundary)"
  }

  logos: any = [];

  pipe = new DatePipe('es-MX');

  responsable: any = [];

  fechaHoy = new Date();

  creditPayments: any = [];

  codigoInm = ""
  folio: any = ""
  acumulador = 0
  conInmueble: any;
  saleInfo: any = '';

  anticipoC: any = 0;
  mensualidadC: any = 0;
  anticipoNR: any = 0;
  mensualidadNR: any = 0;

  //Nomenclatura inmueble
  M = "Manzana"
  L = "Lote"
  T = "Torre"
  D = "Departamento"

  permissions() {
    const token = localStorage.getItem('token') || "";

    let decodetoken: any = {};
    decodetoken = decode(token);

    return decodetoken.permissions;
  }

  //Manejo de fechas
  n = new Date();
  //Año
  y = this.n.getFullYear();
  //Mes
  m = this.n.getMonth() + 1;
  //Día
  d = this.n.getDate();

  h = this.n.toLocaleTimeString()
  salida = ""
  anios() {
    return this.y
  }
  mess() {
    return this.m
  }
  dias() {
    return this.d
  }
  salidaFecha() {
    if (this.m < 9 && this.d < 9) {
      this.salida = this.y + '-' + '0' + this.m + '-' + '0' + this.d + ' ' + this.h;
    } else if (this.d < 9 && this.m > 9) {
      this.salida = this.y + '-' + this.m + '-' + '0' + this.d + ' ' + this.h;
    } else if (this.d > 9 && this.m < 9) {
      this.salida = this.y + '-' + '0' + this.m + '-' + this.d + ' ' + this.h;
    } else {
      this.salida = this.y + '-' + this.m + '-' + this.d + ' ' + this.h;
    }
    return this.salida
  }
  fechaVenta: string = ""
  prueba = ""

  dia: number | any = 0
  mes: number | any = 0
  año: number | any = 0

  //Grafico y control de pagos
  limite = -4
  tolerancia = -1
  tolerancia2 = -2
  tolerancia3 = -3

  numeracion: any = []

  //ConsultaEspecial
  reportes: any = []
  reporte: Report | any;

  //Manejo de residuos
  residuos: any = [];
  residuo: Payment | undefined;

  //Ticket
  ticketget: any | undefined;

  //Manejo tabla Pagos
  carteraInmu: any = [];
  payments: any = [];
  payment: Payment = {
    modificationDate: this.salidaFecha(),
    estadoPago: 'Cancelado',
    reason: ''
  };
  //Bandera tarjeta inserccion consulta
  desCancRecibo = false
  formularioActivo = true
  formularioApagado = false

  cancelarPago(id: any) {
    this.cancelarPagos(id);
    return this.formularioActivo = false, this.formularioApagado = true;
  }
  nuevaCancelacion() {
    return this.formularioActivo = true, this.formularioApagado = false;
  }
  ShowSelected(cod: any) {
    cod = document.getElementById("producto");
    return cod;
  }
  //Manejo de cuentas
  cuentas: any = [];

  //Manejo de contadores
  totales: any = [];
  total: Payment = {
    Abonado: 0
  };
  //Manejo de metodos de pagos
  metode: any = [];

  PaymentMetode: paymentMetode = {
    id: 0,
    formaPago: ''
  };

  //Manejo de ventas
  sales: any = []
  salesCoo: any = []

  constructor(
    public modal: NgbModal,
    private reportServices: ReportService,
    private reportService: PaymentService,
    private toastr: ToastrService,
    private paymentService: PaymentService,
    private logoService: LogosService,
    private reportServ: ReportService,
    private router: Router,
    private salesServices: SalesService,
    public pdfTicket: PdfMakerComponent,
    public sweet: SweetAlertComponent,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.saleCode();
    this.salidaFecha();
    this.getLogos();

    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
  }

  //Notas

  habilitarDescripcion() {
    if (this.desCancRecibo == true) {
      return this.desCancRecibo = false
    } else {
      return this.desCancRecibo = true
    }
  }
  showToastr() {
    this.toastr.success('Nota agregada con exito')
  }
  showToastrDan() {
    this.toastr.error('Error: Pago no cancelado')
  }

  slectPayments(cliente: string) {
    this.reportService.selectPayments(cliente).subscribe(
      res => {
        this.payments = res;
      },
      err => console.log(err)
    );
  }

  reporteInmuCartera(carInmu: string) {
    this.reportServices.reporteCarteraInmueble(carInmu).subscribe(
      res => {

        this.carteraInmu = res;
        console.log("reportInmuCartera", this.carteraInmu);
      },
      err => console.log(err)
    );
  }

  selectSalesCoo(codInmuebleCoo: any) {
    this.salesServices.selectSalesCoo(codInmuebleCoo).subscribe(
      res => {
        this.salesCoo = res;
        console.log(this.salesCoo);
      },
      err => console.log(err)
    );
  }
  sumTotales(code: string) {
    this.reportService.sumTotal(code).subscribe(
      data => {
        this.totales = data;
      },
      err => console.log(err)
    );

  }
  cancelPayment(id: any) {
    delete this.payment.id;
    delete this.payment.fechaPago
    delete this.payment.estadoPago
    this.reportService.updatePaymentCanceled(id, this.payment)
      .subscribe(
        res => {
          this.showToastr()
          this.router.navigate(['payments'])
        },
        err => this.showToastrDan()
      );
  }

  deletePayment(id: any) {

    this.reportService.deletePayment(id).subscribe(data => {
      this.sumTotales(this.codigoInm.split(" ")[0]);
      this.slectPayments(this.codigoInm.split(" ")[0]);
    }, error => {
      console.log(error);
      this.router.navigate(['payments'])
    })
  }
  //Autocompletado
  keyword = 'name';

  sal: any = [];
  folioApr: any = [];
  folioCa: any = [];

  cadenaInmueble: string = "";
  saleCode() {
    this.reportServ.saleCode().subscribe(
      res => {

        this.sal = res;
      },
      err => console.log(err)
    );
  }
  razon = "";

  cancelarPagos(id: any) {

    this.paymentService.updatePaymentCanceled(id, this.payment).subscribe(data => {

      this.showToastr();

      this.sumTotales(this.codigoInm.split(" ")[0]);
      this.slectPayments(this.codigoInm.split(" ")[0]);
      this.selectSalesCoo(this.codigoInm.split(" ")[0]);

      this.payment.estadoPago = "Cancelado";

      this.payment.modificationDate = this.salidaFecha();
      this.payment.reason = this.razon;

      this.responsable = decode(localStorage.getItem("token") || "");

      const log = {
        "action": 5,
        "notes": this.payment.reason,
        "payment": id,
        "responsable": this.responsable.fkuser
      };

      this.paymentService.writePaymentLog(log).subscribe(
        res => {
          this.sweet.AlertTime("center", "success", "Pago Cancelado", false, 2000);
        }
      );

    }, error => {
      this.showToastrDan()
      this.router.navigate(['report'])
    })
  }

  onKeypressEventSale(eventInm: any) {
    this.conInmueble = eventInm

    let cadInm: any = {};
    cadInm = this.conInmueble.name;

    if (cadInm == ' ' || cadInm == null || cadInm == "") {
      console.log("Se requiere consultar inmueble")
    } else {
      this.codigoInm = this.codigoInm.split(" ")[0]; //Pasar valor a variable
    }
  }

  onKeypressEvent(event: any) {
    this.conInmueble = event

    let cad: any = {};
    cad = this.conInmueble.name;

    if (event == ' ' || event == null || event == "") {
      console.log("Se requiere consultar cliente")
    } else {
      this.codigoInm = cad.split(" ")[0];
      this.reporteInmuCartera(this.codigoInm);
      this.getCreditPayments(this.codigoInm);
      this.getCreditNRPayments(this.codigoInm);
      this.getCreditPaymentsList(this.codigoInm);
      this.slectPayments(this.codigoInm);
      this.sumTotales(this.codigoInm);
      this.selectSalesCoo(this.codigoInm);
    }
  }

  getLogos() {
    this.logoService.getAllIcons().subscribe(
      res => {
        this.logos = res;
      }
    );
  }

  searchLogo(name: number) {
    try {
      let res = (this.logos.find((logo: { fk_projects: number; }) => logo.fk_projects == name)).UrlLogo;
      return res;
    } catch (error) {
      return '';
    }
  }

  async getCreditPaymentsList(inmueble: string) {
    this.reportService.getIndividualCreditPayments(inmueble).subscribe(
      res => {
        this.creditPayments = res;
      }
    );
  }

  async getIndividualCreditPayments(inmueble: string, cliente: string, cordinator: string) {
    this.paymentService.getIndividualCreditPayments(inmueble).subscribe(
      res => {
        let data = { "payments": res, "customer": cliente, "cordinator": cordinator };
        this.dialog.open(CreditPaymentsComponent, {
          width: '100%',
          data: data
        });
      }
    );
  }

  async getCreditPayments(inmueble: String) {
    this.anticipoC = 0;
    this.mensualidadC = 0;

    this.paymentService.getCreditPayments(inmueble).subscribe(
      res => {
        this.anticipoC = res.Anticipo;
        this.mensualidadC = res.Mensualidad;
      }
    );
  }

  async getCreditNRPayments(inmueble: String) {
    this.anticipoNR = 0;
    this.mensualidadNR = 0;

    this.paymentService.getCreditNRPayments(inmueble).subscribe(
      res => {
        this.anticipoNR = res.Anticipo;
        this.mensualidadNR = res.Mensualidad;
      }
    );
  }


  rePrintTicket(ticket: any) {
    console.log("ticket: ", ticket)

    this.paymentService.getTicket(ticket).subscribe(data => {
      this.ticketget = data;
      console.log("pago obtenido", data)
      this.paymentService.calculateRest(this.ticketget.Inmueble, this.ticketget.Fecha).subscribe(
        res => {
          this.paymentService.getTotalsNCPayments(this.ticketget.Inmueble).subscribe(
            res2 => {
              this.pdfTicket.ticketGenerator(
                this.ticketget.Fecha,
                this.ticketget.Venderdor,
                ticket,
                this.ticketget.Inmueble.substring(2, 3),
                this.ticketget.Inmueble.substring(5, 6),
                this.ticketget.Inmueble.substring(3, 5),
                this.ticketget.Inmueble.substring(6, 8),
                this.ticketget.Concepto,
                this.ticketget.Formadepago,
                this.ticketget.Valortotalinmueble,
                this.ticketget.Abono,
                //this.ticketget.Restante,
                res[0].restante,
                res[0].pagosRealizados, //Pagos realizados
                this.ticketget.Mensualidadesapagar,
                this.ticketget.Inmueble.substring(0, 2),
                this.ticketget.Cliente,
                this.ticketget.useremail,
                res2.NC,
                res2.NCNR,
                this.ticketget.Valortotalinmueble - res[0].restante,
                (res[0].desfase > 0 ? (res[0].desfase + ' pendiente(s)') : 'Al corriente'),
                res[0].proxPay
              );
            }
          );
        }
      )
    }
    )
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('htmlData')!.innerHTML;
    popupWin = window.open('', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
      <html>
        <head>
          <title>${this.carteraInmu[0].codeProperty} ${this.carteraInmu[0].clientes}</title>
          <style>
            * {
              margin: 0;
              padding: 0;
              -webkit-print-color-adjust: exact;
              color-adjust: exact;
            }
            body {
              font-family: Roboto, "Helvetica Neue", sans-serif;
            }

            .statementHeader {
              display: flex;
              border: solid #D33E3E 5px;
              padding: 30px;
              border-radius: 20px 20px 0 0;
              justify-content: space-between;
            }

            .m19infoContainer {
                display: flex;
                flex-direction: column;
                width: 45%;
                gap: 10px;
                font-size: 0.9rem;
                margin: 0;
                padding: 0;
            }

            .principalInfo {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 45%;
                font-size: 0.9rem;
                margin: 0;
                padding: 0;
            }

            img {
                width: 30%;
                margin-bottom: 5px;
                border: none;
            }

            h4 {
                padding: 10px;
                background-color: #D33E3E;
                color: white;
                font-weight: bold;
                text-align: center;
                border-radius: 20px 20px 0 0;
                margin-bottom: 0;
            }

            .financialStatus {
              display: flex;
              justify-content: space-between;
              padding: 30px 30px 30px 30px;
              border-top: solid #D33E3E 5px;
              border-left: solid #D33E3E 5px;
              border-right: solid #D33E3E 5px;
            }

            .saleStatus {
              display: flex;
              justify-content: space-between;
              padding: 30px 30px 30px 30px;
              border-top: solid #D33E3E 5px;
              border-left: solid #D33E3E 5px;
              border-right: solid #D33E3E 5px;
              border-bottom: solid #D33E3E 5px;
            }

            .aditionalInfo {
              width: 100%;
              display: flex;
              justify-content: space-between;
              font-size: 0.8rem;
            }

            .debtInfo {
              display: flex;
              flex-direction: column;
              width: 40%;
            }

            .totalAmount {
              display: flex;
              justify-content: space-evenly;
              background-color: #5E4F4F;
              color: white;
              font-size: 1rem;
              font-weight: bold;
              padding: 10px;
            }

            .totalAmount p {
              margin: 0;
            }

            .infoColumn {
              display: flex;
              flex-direction: column;
              gap: 10px;
              margin-top: 10px;
            }

            .paymentsInfo {
                width: 100%;
            }

            .paymentsSection {
              break-before: page;
            }

            table {
              border-collapse: collapse;
              border: solid #5E4F4F 5px;
            }

            thead {
                background-color: #5E4F4F;
                color: white;
                font-weight: bold;
                font-size: 1rem;
                text-align: center;
            }

            tbody {
                text-align: center;
                font-size: 0.8rem;
            }

            tbody tr:nth-child(even) {
                background-color: #ebebeb;
            }

            td, th {
              padding: 10px;
            }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin!.document.close();
  }
}