<html>
<mat-dialog-content>
    <h1 style="text-align: center;">Ventana de edición de vale de consumo</h1>

    <div>
        <table style="width: 100%;">
            <tbody>
                <tr style="height: 50px; text-align: center; border-top: 2px rgb(117, 0, 0) solid;">
                    <td colspan="4"> <b>Folio: {{this.consumerToken[0].folio}}</b> -
                        {{this.pipe.transform(this.consumerToken[0].tokenDate,'longDate')}} - Proyecto:
                        {{this.consumerToken[0].projectname}} - Módulo: {{this.consumerToken[0].towernumber}}</td>
                </tr>
                <tr style="border-bottom: 2px rgb(117, 0, 0) solid;">
                    <td colspan="2" style="text-align: left;"> Area que requiere:
                        {{this.consumerToken[0].towernumber}}</td>
                    <td colspan="2" style="text-align: right; font-style: italic;"> (Este vale de consumo está
                        siendo editado por:
                        {{this.consumerToken[0].empleado}}).</td>
                </tr>
                <tr>
                    <td><br></td>
                </tr>
            </tbody>
        </table>
    </div>
    <form [formGroup]="consumerTokenFormGroup" (ngSubmit)="updateConsumerToken()">
        <div style="display: flex; width: 100%; justify-content: center; align-items: center; height: 63.5px;">
            <div style="width: 70%;">
                <ng-autocomplete formControlName="idproduct" placeholder="Seleccione material"
                    (selected)='materialSeleccionado($event)' [data]="products" [searchKeyword]="productname"
                    [itemTemplate]="productTemplate" [notFoundTemplate]="notFoundTemplateP">
                </ng-autocomplete>
                <ng-template #productTemplate let-item>
                    <a [innerHTML]=item.productname></a>
                </ng-template>
                <ng-template #notFoundTemplateP let-notFound>
                    <div>No hay coincidencias de vales</div>
                </ng-template>
            </div>
            <mat-form-field appearance="outline" style="width: 10%;">
                <mat-label>Cantidad</mat-label>
                <input matInput type="number" formControlName="amount" min="1">
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 10%; margin-left: 10px;">
                <mat-label>Unidad</mat-label>
                <input matInput type="text" formControlName="unit" readonly>
            </mat-form-field>

            <div (click)="addMaterial()" mat-icon-button class="addMaterial">
                <mat-icon style="color:white;">arrow_downward</mat-icon>
            </div>
        </div>
        <table class="table table-striped mt-3 animated fadeIn faster">
            <thead class="thead-m19">
                <tr class="tableHead">
                    <th>Nombre de producto</th>
                    <th>Cantidad</th>
                    <th>Unidad</th>
                    <th>Editar</th>
                    <th>Eliminar</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let mat of materials; index as i" class="tableHead">
                    <td>{{mat.producto}}</td>
                    <td>{{mat.amount}}</td>
                    <td>{{mat.unit}}</td>
                    <td>
                        <!-- Botón para editar una requisición -->
                        <div class="btn btn-m19-table" title="Editar" mat-icon-button (click)="editProduct(i)">
                            <mat-icon>arrow_upward</mat-icon>
                        </div>
                    </td>
                    <td>
                        <!-- Botón para editar una requisición -->
                        <div class="btn btn-m19-table" title="Eliminar" mat-icon-button
                            (click)="this.materials.splice(i,1);">
                            <mat-icon>remove</mat-icon>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div style="width:100%; display: flex; height:63.5px;">
            <mat-form-field appearance="outline" style="width:70%; margin-right: 10px;">
                <mat-label>Razón de modificación</mat-label>
                <textarea matInput cdkTextareaAutosize formControlName="noteMod" cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
            <p
                style="color:red;font-style: italic;font-size: small; width: 25%; margin-left: 10px; margin-right: 10px;">
                *Se debe agregar una razón de modificación para poder guardar o cierre para cancelar</p>
            <button controlName="boton" class="btn btn-m19" type="submit" [disabled]="!consumerTokenFormGroup.valid || this.flagSubmit">
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>

</html>