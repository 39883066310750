import { ChangeDetectionStrategy, Component, Input, ChangeDetectorRef } from '@angular/core';
import { loader } from './loader.model';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {
  fecha = new Date();
  slowLoading: boolean = false;
  @Input() loader: loader

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.loader = {"message": "Cargando"}
    setTimeout(() => {
      this.slowLoading = true;
      this.changeDetectorRef.detectChanges();
    }, 10000);
  }
}