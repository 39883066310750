<div class="mapCont">
    <div> <!--1770px-->
        <!-- <button (click)="alternarVista()">Vista en IOS</button> -->
        <canvas id="canvasId" #canvasRef>
        </canvas>
    </div>

    <div class="options" [class.optionsOpened]="optionsOpened" [class.optionsClosed]="!optionsOpened">
        <span class="options__control">
            <mat-icon (click)="changeOptionsState()">{{optionsOpened?'close':'expand_less'}}</mat-icon>
        </span>
        <div class="options__detail">
            <p class="title">Proyecto</p>
            <span class="projFace">
                <img [src]="projectDetail.UrlLogo">
                <p class="info">{{projectDetail.name}} - {{projectDetail.nomenclature}}</p>
            </span>

            <hr>

            <p class="title">Dirección</p>
            <p class="info">{{projectDetail.address}},
                {{projectDetail.localidad==projectDetail.municipality?'':projectDetail.localidad+','}}
                {{projectDetail.municipality}}, {{projectDetail.state}}</p>

            <br>

            <a [href]="projectDetail.location" target="_blank"
                *ngIf="projectDetail.location != null else nolocation"><mat-icon>location_on</mat-icon>Ver en Google
                Maps</a>

            <ng-template #nolocation>
                <p>Este proyecto no tiene ubicación registrada.</p>
            </ng-template>
        </div>

        <div class="options__buttons">
            <button class="m19Btn" (click)="generatePDF()">Imprimir Mapa<mat-icon>print</mat-icon></button>
            <button class="m19Btn" (click)="selectSale()">Cambiar de Proyecto<mat-icon>change_circle</mat-icon></button>
        </div>
    </div>

    <app-loader [loader]="loader" *ngIf="loading==1"></app-loader>
</div>