import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DepaproService {

  private url = 'http://localhost:3000/api';

  constructor(private http: HttpClient) { }

  //Consultar todos los productos asignados a partidas
  selectProducts(): Observable<any>{
    return this.http.get(`${environment.URLServer}/depa_prod/selectAll`);
  }

  selectDeparturesByReq(id:Number): Observable<any>{
    return this.http.get(`${environment.URLServer}/depa_prod/selectDeparturesByReq/`+id);
  }

  selectProductsByReq(id:Number): Observable<any>{
    return this.http.get(`${environment.URLServer}/depa_prod/selectProductsByReq/`+id);
  }

  insertProducts(req:Number, id:Number, con:Number, pro:Number,can:Number,not:String,user:String): Observable<any>{
    return this.http.post(`${environment.URLServer}/depa_prod/insert/`,[req,id,con,pro,can,not,user]);
  }

  deleteAllByReq(id:number){
    return this.http.delete(`${environment.URLServer}/depa_prod/deleteAllByReq/`+id);
  }
}