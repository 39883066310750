<div class="container-fluid mt-3">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">

            <div class="searchBar">
                <div class="ng-autocomplete">
                    <ng-autocomplete name="busqueda" placeholder="Inmueble/Nombre cliente" [data]="sal"
                        [searchKeyword]="keyword" (selected)="onKeypressEvent($event)" [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
                    <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.name"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                        <div>Ningún cliente coincide con la busqueda</div>
                    </ng-template>
                </div>

                <button *ngIf="carteraInmu.length > 0" (click)="print()" class="printButton">
                    IMPRIMIR ESTADO DE CUENTA<mat-icon>print</mat-icon>
                </button>
            </div>

            <div id="htmlData" class="statementContainer" [hidden]="true">
                <div *ngIf="carteraInmu.length > 0">
                    <div class="statementHeader">
                        <div class="m19infoContainer">
                            <img src="./assets/iconoM.png" alt="M19Logo">
                            <p><b>M19 INMOBILIARIA <br> TU HOGAR, NUESTRO SUEÑO.</b></p>
                            <p><b>CALLE PORTUGAL #67 A <br> CD. DEL VALLE, TEPIC NAYARIT, 63157.</b></p>
                        </div>
                        <div class="principalInfo">
                            <p style="border-bottom: solid black 3px;"><b>ESTADO DE CUENTA</b></p>

                            <p><b>INMUEBLE:</b> {{carteraInmu[0].codeProperty}}</p>

                            <p><b>CLIENTE:</b> {{carteraInmu[0].clientes.toUpperCase()}}</p>

                            <p><b>CORREO:</b> {{carteraInmu[0].email}}</p>

                            <p *ngIf="carteraInmu[0].mobile!=null"><b>CELULAR:</b> {{carteraInmu[0].mobile}}</p>

                            <p><b>RFC:</b> {{carteraInmu[0].RFC==null?'SIN REGISTRAR':carteraInmu[0].RFC}}</p>

                            <p><b>FECHA DE EMISIÓN:</b> {{pipe.transform(fechaHoy,
                                'longDate')!.toUpperCase()}}</p>
                        </div>
                    </div>

                    <br>

                    <h4>INFORMACIÓN DE LA VENTA</h4>
                    <div class="financialStatus saleStatus">
                        <div class="aditionalInfo">
                            <p>
                                <b>FECHA DE CONTRATO:</b><br>{{pipe.transform(carteraInmu[0].fechaDato,
                                'longDate')!.toUpperCase()}}
                            </p>
                            <p>
                                <b>DESARROLLO:</b><br>{{carteraInmu[0].name.toUpperCase()}}
                            </p>
                            <p>
                                <b>COORDINADOR DE VENTA:</b><br>{{carteraInmu[0].usuarios.toUpperCase()}}
                            </p>
                            <p>
                                <b>VENDEDOR:</b><br>{{carteraInmu[0].vendedores==null?'SIN
                                ASIGNAR':carteraInmu[0].vendedores.toUpperCase()}}
                            </p>
                        </div>
                    </div>

                    <br>

                    <h4>INFORMACIÓN FINANCIERA</h4>
                    <div class="financialStatus">
                        <div class="debtInfo">
                            <p style="border-bottom: solid black 3px;"><b>INFORMACION ORDINAL</b></p>

                            <div class="infoColumn">
                                <p><b>VALOR TOTAL:</b> {{carteraInmu[0].valor | currency:'CAD' : '$ '}}</p>
                                <p><b>MENSUALIDAD:</b> {{carteraInmu[0].monthlyPayment | currency: 'CAD' : '$'}}</p>

                                <p><b>PAGOS REALIZADOS:</b>
                                    {{carteraInmu[0].mPagadas+'/'+((carteraInmu[0].valor-carteraInmu[0].advancePayment)/carteraInmu[0].monthlyPayment).toFixed(0)}}
                                </p>

                                <p><b>PAGOS ESPERADOS:</b>
                                    {{carteraInmu[0].meses+'/'+((carteraInmu[0].valor-carteraInmu[0].advancePayment)/carteraInmu[0].monthlyPayment).toFixed(0)}}
                                </p>

                                <p><b>BALANCE DE PAGOS:</b> {{carteraInmu[0].retraso.toFixed(2)}}</p>
                            </div>
                        </div>

                        <div class="creditInfo">
                            <p style="border-bottom: solid black 3px;"><b>INFORMACION DE CRÉDITO</b></p>

                            <div class="infoColumn">
                                <p><b>ANTICIPO DE CRÉDITO:</b> {{anticipoC | currency: 'CAD' : '$'}}</p>
                                <p><b>MENSUALIDADES DE CRÉDITO:</b> {{mensualidadC | currency: 'CAD' : '$'}}</p>

                                <p><b>ANTICIPO NO REEMBOLSABLE:</b> {{anticipoNR | currency: 'CAD' : '$'}}</p>
                                <p><b>MENSUALIDADES NO REEMBOLSABLES:</b> {{mensualidadNR | currency: 'CAD' : '$'}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="totalAmount">
                        <p><b>TOTAL ABONADO:</b> {{carteraInmu[0].abonado + (anticipoC + anticipoNR + mensualidadC +
                            mensualidadNR) | currency:'CAD' : '$ '}}</p>
                        <p><b>TOTAL ADEUDO:</b> {{carteraInmu[0].valor-carteraInmu[0].abonado - (anticipoC + anticipoNR
                            + mensualidadC + mensualidadNR) | currency:'CAD' : '$ '}}</p>
                    </div>

                    <br>

                    <h4 class="paymentsSection">HISTORIAL DE PAGOS ORDINALES</h4>
                    <table class="paymentsInfo">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Fecha</th>
                                <th>Concepto</th>
                                <th>Cantidad</th>
                                <th>Método de pago</th>
                                <th>Folio</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let payment of payments; let i=index;">
                                <td>{{i+1}}</td>
                                <td>{{payment.fechaPago | date: 'dd-MM-yyyy'}}</td>
                                <td>{{payment.concepto}}</td>
                                <td>{{(payment.cantidadAbono | currency:'CAD' : '$ ')}}</td>
                                <td>{{payment.metodoPago}}</td>
                                <td>{{payment.folioPago}}</td>
                            </tr>
                        </tbody>
                    </table>

                    <br>
                    <br *ngIf="payments.length>=23"><br *ngIf="payments.length>=23"><br *ngIf="payments.length>=23">

                    <h4 *ngIf="creditPayments.length > 0">HISTORIAL DE PAGOS DE CRÉDITO</h4>
                    <table *ngIf="creditPayments.length > 0" class="paymentsInfo">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Fecha</th>
                                <th>Concepto</th>
                                <th>Cantidad</th>
                                <th>Método de pago</th>
                                <th>Folio</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let payment of creditPayments; let i=index;">
                                <td>{{i+1}}</td>
                                <td>{{payment.fechaPago | date: 'dd-MM-yyyy'}}</td>
                                <td>{{payment.concepto}}</td>
                                <td>{{(payment.cantidadAbono | currency:'CAD' : '$ ')}}</td>
                                <td>{{payment.metodoPago}}</td>
                                <td>{{payment.folioPago}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <p *ngIf="carteraInmu.length==0" style="text-align: center; margin-top: 20px;"><img class="isotipoLogo"
                    src="assets/isotipo.png" alt="M19 Isotipo"></p>

            <div class="accountStatus" *ngIf="carteraInmu.length>0">
                <div class="saleDescription">
                    <div *ngFor="let venta of carteraInmu" class="leftContainer">
                        <div
                            style="width:100%; display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                            <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-start;">
                                <h5
                                    style="width: 100%; margin: 0; padding: 2px 10px 2px 10px; color: white; text-align: center; background-color: var(--M19Primary); border-radius: 10px 10px 0 0;">
                                    <mat-icon>apartment</mat-icon>Desarrollo
                                </h5>
                                <h5 style="width: 100%; margin: 0; text-align: center;">{{venta.name}} -
                                    {{venta.codeProperty}}</h5>
                            </div>
                        </div>

                        <div class="dataRow">
                            <mat-icon class="iconData">person</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>Cliente</b></span>
                                <span
                                    style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.clientes}}</b></span>
                            </div>
                        </div>

                        <div class="dataRow">
                            <mat-icon class="iconData">alternate_email</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>Correo</b></span>
                                <span style="font-size: 1.1rem; color: var(--M19Primary); word-break: break-all;"><b>{{venta.email}}</b></span>
                            </div>
                        </div>

                        <div class="dataRow">
                            <mat-icon class="iconData">call</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>Teléfono</b></span>
                                <span
                                    style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.mobile==null?"Sin especificar":venta.mobile}}</b></span>
                            </div>
                        </div>

                        <div class="dataRow" *ngFor="let ventas of salesCoo" ngIf="ventas.coopropietario!=null">
                            <mat-icon class="iconData">person</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>Coopropietario</b></span>
                                <span
                                    style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.coopropietario}}</b></span>
                            </div>
                        </div>

                        <div class="dataRow">
                            <mat-icon class="iconData">support_agent</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>Coordinador</b></span>
                                <span
                                    style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.usuarios}}</b></span>
                            </div>
                        </div>

                        <div class="dataRow">
                            <mat-icon class="iconData">support_agent</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>Vendedor</b></span>
                                <span
                                    style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.vendedores==null?"Sin especificar":venta.vendedores}}</b></span>
                            </div>
                        </div>

                        <div class="dataRow">
                            <mat-icon class="iconData">event</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>Fecha de contrato</b></span>
                                <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.fechaDato | date :
                                        'dd-MM-YYYY'}}</b></span>
                            </div>
                        </div>

                        <div class="dataRow">
                            <mat-icon class="iconData">update</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>Fecha de primer pago</b></span>
                                <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.firstPayDate !=
                                        null? (venta.firstPayDate | date:
                                        'dd-MM-yyyy') : "Sin especificar"}}</b></span>
                            </div>
                        </div>

                        <h3>Balance</h3>

                        <div class="moneyBalance">
                            <div class="balanceRow">
                                <span class="balanceTitle">Valor total</span>
                                <span class="balanceData">{{venta.valor | currency:'CAD' : '$ '}}</span>
                            </div>
                            <div class="balanceRow">
                                <span class="balanceSubtitle">Pagos Normales</span>
                                <span class="balanceData">{{venta.abonado | currency:'CAD' : '$ '}}</span>
                            </div>
                            <div class="balanceRow">
                                <span class="balanceSubtitle">Nota de Crédito</span>
                                <span class="balanceData">{{(anticipoC + mensualidadC) | currency:'CAD' : '$ '}}</span>
                            </div>
                            <div class="balanceRow">
                                <span class="balanceSubtitle">Nota de Crédito No Reembolsable</span>
                                <span class="balanceData">{{(anticipoNR + mensualidadNR) | currency:'CAD' : '$ '}}</span>
                            </div>
                            <div class="balanceRow">
                                <span class="balanceTitle">Adeudo</span>
                                <span class="balanceData">{{venta.valor-venta.abonado-(mensualidadC+mensualidadNR)-(anticipoC+anticipoNR) | currency:'CAD' : '$ '}}</span>
                            </div>
                        </div>

                        <button class="m19AltBtn"
                            (click)="getIndividualCreditPayments(venta.codeProperty, venta.clientes, venta.usuarios)">Ver
                            recibos de crédito <mat-icon>receipt</mat-icon></button>

                        <table class="table table-hover mt-3">
                            <thead>
                                <tr align="center">
                                    <td>Pagos realizados</td>
                                    <td>Pagos esperados</td>
                                    <td>Estado de pagos</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr align="center">
                                    <td> <a>{{venta.mPagadas+' de
                                            '+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(0)}}</a>
                                    </td>

                                    <td>
                                        <div style="display: flex; flex-direction: column;">
                                            <div>
                                                {{venta.meses+' de
                                                '+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(0)}}
                                            </div>
                                            <div *ngIf="venta.retraso >= 0;"
                                                style="width: 100%; height: 10px; border-radius: 5px; background-color: var(--primaryBlue);">
                                            </div>
                                            <div *ngIf="venta.retraso < 0 && venta.retraso > -2"
                                                style="width: 100%; height: 10px; border-radius: 5px; background-color: var(--primaryGreen);">
                                            </div>
                                            <div *ngIf="venta.retraso <= -2 && venta.retraso > -4"
                                                style="width: 100%; height: 10px; border-radius: 5px; background-color: var(--primaryYellow);">
                                            </div>
                                            <div *ngIf="venta.retraso <= -4"
                                                style="width: 100%; height: 10px; border-radius: 5px; background-color: var(--primaryRed);">
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {{venta.retraso.toFixed(2)}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <button *ngIf="permissions().match('cancelFolioC') || permissions().match('446576656c6f706572')"
                            type="button" class="m19Btn" (click)="habilitarDescripcion()">Cancelar
                            recibo <mat-icon>cancel</mat-icon></button>
                    </div>
                    <div *ngIf="desCancRecibo">
                        <h5>Razón de cancelación</h5>
                        <div class="form-group">
                            <textarea class="form-control" name="razones" [(ngModel)]="payment.reason"
                                [(ngModel)]="razon" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                    </div>
                </div>

                <div style="width: 65%;">
                    <table class="m19Table">
                        <thead>
                            <tr>
                                <th style="text-align: right;">#</th>
                                <th style="text-align: right">Folio</th>
                                <th style="text-align: center;">Fecha</th>
                                <th style="text-align: right">Cantidad</th>
                                <th>Método</th>
                                <th>Concepto</th>
                                <th></th>
                                <th *ngIf="desCancRecibo"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let payment of payments; let i=index;">
                                <th style="text-align: right;">{{i+1}}</th>
                                <th style="text-align: right">{{payment.folioPago}}</th>
                                <td style="text-align: center;">{{pipe.transform(payment.fechaPago,'longDate')}}</td>
                                <td style="text-align: right">{{(payment.cantidadAbono | currency:'CAD' : '$ ')}}</td>
                                <td>{{payment.metodoPago}} <mat-icon style="scale: 0.9;" [ngStyle]="{color: payment.validate=='NA'?'var(--primaryBlue)':payment.validate=='NO'?'var(--primaryRed)': 'var(--primaryGreen)'}" *ngIf="payment.metodoPago=='Transferencia'">adjust</mat-icon></td>
                                <td>{{payment.concepto}}</td>
                                <td class="button-row" style="text-align: center;">
                                    <button class="btn btn-m19-table" *ngIf="(payment.folioPago).includes('e')"
                                        mat-icon-button (click)="rePrintTicket(payment.folioPago)">
                                        <mat-icon>receipt</mat-icon>
                                    </button>
                                </td>
                                <td class="button-row" *ngIf="desCancRecibo">
                                    <button class="btn btn-m19-table" mat-icon-button
                                        (click)="cancelarPagos(payment.id)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>