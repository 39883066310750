<div class="container-fluid mt-3">
    <mat-card>
        <mat-card-content>

            <div style="display: flex; justify-content: space-between;">
                <div style="width: 45%; height: 60vh; overflow: scroll;">
                    <table class="table table-striped mt-3 animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr style="text-align:center;">
                                <!-- Se definen los encabezados de cada columna de la tabla -->
                                <th scope="col">Proyecto</th>
                                <th scope="col">Costo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let t of totals">
                                <td>{{t.name}}</td>
                                <td>${{t.Total}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div style="width: 45%;">
                    <h5 style="text-align:center; border-bottom: solid rgb(71, 71, 71) 1.5px;">Comparativa de
                        totales
                    </h5>
                    <canvas id="comparativeChart"></canvas>
                </div>
            </div>

            <div class="graphContainer" [hidden]=!projectDet *ngIf="modules!=0 && infoP.Total!==0">
                <h5 style="text-align:center; border-bottom: solid rgb(71, 71, 71) 1.5px;">Gasto por destajos de
                    módulos en proyecto "{{infoP.name}}"</h5>
                <canvas id="pieceworkChart"></canvas>
            </div>

            <div class="graphContainer" [hidden]=!moduleDet *ngIf="modules!=0">
                <h5 style="text-align:center; border-bottom: solid rgb(71, 71, 71) 1.5px;">Gasto por tipo de
                    destajo en proyecto "{{infoP.name}}"</h5>
                <canvas id="piecework2Chart"></canvas>
            </div>

            <div class="beltInfo">
                <p class="beltTitle">
                    <mat-icon>info</mat-icon>DETALLES
                </p>
                <div class="infoContainer">
                    <p *ngIf="infoP.idProject==''" style="font-size: 1.3rem;">Seleccione un proyecto para visualizar la
                        información</p>
                    <div class="infoProject"
                        *ngIf="infoP.idProject!=='' && infoP.Total!=='NA' && modules!=0 && infoM.Total!==0 && infoP.Total!==0 else wgraph">
                        <h3 style="border-bottom: solid brown 1.5px;">Información general</h3>
                        <p><b>PROYECTO:</b> {{infoP.name}}</p>
                        <div style="display: flex; gap: 10px;">
                            <p><b>TOTAL:</b> ${{infoP.Total}} <button (click)="desgloceDatosProject(infoP.idProject)"
                                    class="btnDetail">
                                    <mat-icon *ngIf="!projectDet">signal_cellular_alt</mat-icon>
                                    <mat-icon *ngIf="projectDet">arrow_drop_up</mat-icon>
                                </button></p>
                        </div>
                    </div>

                    <p *ngIf="infoM.idprojectdet=='' && infoM.Total!==0" style="font-size: 1.3rem;">Seleccione un modulo
                        para visualizar la información</p>
                    <ng-template #wgraph>
                        <h3 style="border-bottom: solid brown 1.5px;">Información general</h3>
                        <p style="font-size: 1.3rem;"><b>PROYECTO:</b> {{infoP.name}}</p>
                        <div style="display: flex; gap: 10px;">
                            <p style="font-size: 1.3rem;"><b>TOTAL:</b> {{infoP.Total=='NA'?'No hay
                                factura':'$'+infoP.Total}}
                        </div>
                    </ng-template>

                    <div class="infoModule"
                        *ngIf="infoM.idprojectdet!==''&& infoP.Total!=='NA' && infoM.Total!=='NA' && infoM.Total!==0 && infoP.Total!==0 && modules!=0">
                        <p><b>MODULO:</b> {{infoM.towernumber}}
                        <div style="display: flex;  gap: 10px;">
                            <p><b>TOTAL:</b> ${{infoM.Total}}
                                <button (click)="desgloceDatosModule(infoM.idprojectdet)" class="btnDetail">
                                    <mat-icon *ngIf="!moduleDet">signal_cellular_alt</mat-icon>
                                    <mat-icon *ngIf="moduleDet">arrow_drop_up</mat-icon>
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="beltButtons">
                <p class="beltTitle">
                    <mat-icon>filter_alt</mat-icon>OPCIONES
                </p>
                <div class="btnContainer">
                    <div class="btnProject">
                        <button *ngFor="let p of projects" (click)="getTotalProject(p.idProject)"
                            (click)="getModules(p.idProject)">
                            {{p.name}}
                            <mat-icon>apartment</mat-icon>
                        </button>
                    </div>

                    <h4 *ngIf="infoP.idProject==''" style="text-align: center;">Seleccione un proyecto para ver sus
                        modulos</h4>
                    <div class="btnModule" *ngIf="infoP.idProject!==''">
                        <h4 style="text-align: center;" [hidden]="!(modules.length==0)">Este proyecto no tiene módulos,
                            por favor, seleccione otro.</h4>
                        <button *ngFor="let m of modules" (click)="getTotalModule(m.idprojectdet)">
                            {{m.towernumber}}
                            <mat-icon>apartment</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>