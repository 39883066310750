<mat-dialog-content>
    <h4 style="text-align: center; border-bottom: solid var(--M19Primary) 2px; margin-bottom: 30px;">INFORMACIÓN GENERAL DE
        REQUISICÍON</h4>
    <form [formGroup]="requisitionFormGroup" (ngSubmit)="updateRequisition()">
        <div class="formFieldReq">
            <div class="registerRow">
                <mat-form-field appearance="outline">
                    <mat-label>Modulo</mat-label>
                    <mat-select formControlName="idprojectdet" (valueChange)="selectDepartures($event)" required>
                        <mat-option *ngFor="let tower of towers" [value]="tower.idprojectdet" (input)="compareValues()">
                            Modulo {{tower.towernumber}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Número de requisición</mat-label>
                    <input matInput type="text" formControlName="requisitionnumber" (input)="compareValues()" required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Fecha de requisición</mat-label>
                    <input matInput type="date" formControlName="requisitiondate" (input)="compareValues()" required>
                </mat-form-field>
            </div>

            <div class="registerRow">
                <mat-form-field appearance="outline">
                    <mat-label>Autoriza</mat-label>
                    <input matInput type="text" formControlName="auth" (input)="compareValues()">
                </mat-form-field>

                <mat-form-field *ngIf="modification==true" appearance="outline">
                    <mat-label>Razón de modificación</mat-label>
                    <textarea matInput cdkTextareaAutosize formControlName="reason" cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5"></textarea>
                </mat-form-field>
            </div>

            <mat-form-field [hidden]="true">
                <mat-label>idrequisition</mat-label>
                <input matInput type="text" formControlName="idrequisition" readonly required>
            </mat-form-field>
        </div>

        <div class="col-12 text-right mt-3">
            <button type="submit" class="saveButton" [disabled]="!requisitionFormGroup.valid">
                <mat-icon>save</mat-icon>
            </button>
        </div>

        <div style="display: flex;">
            <mat-form-field appearance="outline" class="col-md-4" [hidden]="true">
                <mat-label>iddeparture</mat-label>
                <input matInput type="number" formControlName="iddeparture" readonly>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-4" [hidden]="true">
                <mat-label>departurename</mat-label>
                <input matInput type="text" formControlName="departurename" readonly>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-4" [hidden]="true">
                <mat-label>departurenumber</mat-label>
                <input matInput type="text" formControlName="departurenumber" readonly>
            </mat-form-field>
            <div class="ng-autocomplete">
                <ng-autocomplete formControlName="departure" name="busquedaP" placeholder="id/Partida"
                    [data]="departures" [searchKeyword]="keywordP" [itemTemplate]="itemTemplateP"
                    (selected)='selectDeparture($event)' [notFoundTemplate]="notFoundTemplateP">
                </ng-autocomplete>
                <ng-template #itemTemplateP let-itemP>
                    <a [innerHTML]="itemP.departurename"></a>
                </ng-template>
                <ng-template #notFoundTemplateP let-notFound>
                    <div [innerHTML]="notFound"></div>
                </ng-template>
            </div>

            <mat-form-field appearance="outline" class="col-md-4" [hidden]="true">
                <mat-label>idconcept</mat-label>
                <input matInput type="text" formControlName="idconcept" readonly>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-4" [hidden]="true">
                <mat-label>nombreconcepto</mat-label>
                <input matInput type="text" formControlName="conceptname" readonly>
            </mat-form-field>
            <div class="ng-autocomplete">
                <ng-autocomplete formControlName="concept" name="busquedaC" placeholder="id/Concepto" [data]="concepts"
                    [searchKeyword]="keywordC" [itemTemplate]="itemTemplateC" (selected)='selectConcept($event)'
                    [notFoundTemplate]="notFoundTemplateP">
                </ng-autocomplete>
                <ng-template #itemTemplateC let-itemC>
                    <a [innerHTML]="itemC.descriptions"></a>
                </ng-template>
                <ng-template #notFoundTemplateC let-notFound>
                    <div>No hay coincidencias de conceptos</div>
                </ng-template>
            </div>

            <mat-form-field appearance="outline" class="col-md-4" [hidden]="true">
                <mat-label>idproduct</mat-label>
                <input matInput type="number" formControlName="idproduct" readonly>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-4" [hidden]="true">
                <mat-label>productname</mat-label>
                <input matInput type="text" formControlName="productname" readonly>
            </mat-form-field>

            <div class="ng-autocomplete">
                <ng-autocomplete formControlName="product" name="busquedaM" placeholder="id/Material" [data]="products"
                    [searchKeyword]="keywordM" (selected)='selectProduct($event)' [itemTemplate]="itemTemplateM"
                    [notFoundTemplate]="notFoundTemplateM">
                </ng-autocomplete>
                <ng-template #itemTemplateM let-itemM>
                    <a [innerHTML]="itemM.productname"></a>
                </ng-template>
                <ng-template #notFoundTemplateM let-notFound>
                    <div [innerHTML]="notFound"></div>
                </ng-template>
            </div>
        </div>

        <div class="registerRow">
            <mat-form-field appearance="outline">
                <mat-label>Cantidad</mat-label>
                <input matInput type="number" formControlName="amount" min="1">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Unidad</mat-label>
                <input matInput type="text" formControlName="unit" readonly>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Observaciones</mat-label>
                <textarea matInput cdkTextareaAutosize formControlName="notes" cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
        </div>
        <div (click)="addMaterial()" class="addMaterial">
            <mat-icon style="color:white;">arrow_downward</mat-icon>
        </div>
    </form>

    <table class="table table-striped mt-3 animated fadeIn faster">
        <thead class="thead-m19">
            <tr class="tableHead">
                <th>Número de partida</th>
                <th>Concepto</th>
                <th>Nombre de producto</th>
                <th>Cantidad</th>
                <th>Unidad</th>
                <th>Editar</th>
                <th>Quitar</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let mat of materials; index as i" class="tableHead">
                <td>{{mat.departurenumber}}</td>
                <td>{{mat.conceptName}}</td>
                <td>{{mat.productname}}</td>
                <td>{{mat.amount}}</td>
                <td>{{mat.unit}}</td>
                <td>
                    <!-- Botón para editar una requisición -->
                    <button class="actionAltBtn" title="Editar" mat-icon-button (click)="editProduct(i)">
                        <mat-icon>arrow_upward</mat-icon>
                    </button>
                </td>
                <td>
                    <!-- Botón para editar una requisición -->
                    <button class="actionAltBtn" title="Quitar" mat-icon-button (click)="deleteProduct(i)">
                        <mat-icon>remove</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</mat-dialog-content>