import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Customer } from 'src/app/models/Customer';
import { MapsService } from 'src/app/services/maps.service';
import { PdfMakerComponent } from 'src/app/utils/pdf-maker/pdf-maker.component';
import { FinishedSaleComponent } from '../finished-sale/finished-sale.component';
import decode from 'jwt-decode'
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dialog-sale-confirm',
  templateUrl: './dialog-sale-confirm.component.html',
  styleUrls: ['./dialog-sale-confirm.component.scss']
})
export class DialogSaleConfirmComponent implements OnInit {

  property = localStorage.getItem('InmuebleDigital')
  customer = localStorage.getItem('Venta digital: ')
  CustomerInfo: any = [{ CURP: "", votarKey: "", RFC: "", mobile: "", email: "", birthDay: "", birthPlace: "", nationality: "", occupation: "", civilStatus: "", address: "", localidad: "", municipality: "", state: "", postalCode: "" }];
  propertyInfo: any = [{ name: "", amount: "", northeasBorder: "", northwestBorder: "", southeastBorder: "", southwestBorder: "" }];
  fechaApartir: any;
  sellerId: any = "";
  sellerCoord: any = "";
  decodetoken: any;
  pipe = new DatePipe('en-US');
  todayWithPipe: any;

  dateApartirde = localStorage.getItem('dateApartirde') || "";

  dateApartirdeWithPipe: any = '';

  customerFormGroup: FormGroup = this._formBuilder.group({
    datebefore: [, Validators.required],
  });



  constructor(
    private dialogRef: MatDialogRef<DialogSaleConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Customer,
    private mapsService: MapsService,
    public dialog: MatDialog,
    public pdfmaker: PdfMakerComponent,
    private _formBuilder: FormBuilder,


  ) { }

  ngOnInit(): void {
    this.propertyInfos()
    this.CustomerInfos()
    this.getSellerId()

    //    this.todayWithPipe = this.pipe.transform(Date.now(), 'yyyy-MM-dd');
    localStorage.setItem('todayWithPipe', this.todayWithPipe = this.pipe.transform(Date.now(), 'yyyy-MM-dd') || "")

    //    this.dateApartirdeWithPipe = this.pipe.transform(this.dateApartirde, 'dd-MM-yyyy');
    localStorage.setItem('dateApartirdeWithPipe', this.dateApartirdeWithPipe = this.pipe.transform(this.customerFormGroup.value.datebefore, 'dd-MM-yyyy') || "")
  }



  async propertyInfos() {
    this.mapsService.getPropertyInfo(this.property || "").subscribe(
      propertyget => {
        this.propertyInfo = propertyget
      }
    )
  }

  async CustomerInfos() {
    this.mapsService.getCustomerInfo(this.customer || "").subscribe(
      customerGet => {
        this.CustomerInfo = customerGet
        console.log(this.CustomerInfo)
      }
    )
  }

  async generateRequest() {
    //const hoy = moment().format("L"); 
    this.pdfmaker.genereteRequestAllComponents("hoy", "X", this.propertyInfo.name, this.propertyInfo.codeProperty.substring(3, 5),
      this.propertyInfo.codeProperty.substring(6, 8), this.CustomerInfo.nationality, this.CustomerInfo.complete_name,
      this.CustomerInfo.civilStatus, this.CustomerInfo.birthDate, this.CustomerInfo.occupation, this.CustomerInfo.brithPlace,
      this.CustomerInfo.address, this.CustomerInfo.postalCode, this.CustomerInfo.localidad, this.CustomerInfo.state,
      this.CustomerInfo.municipality, this.CustomerInfo.mobile, this.CustomerInfo.voterKey, this.CustomerInfo.CURP,
      this.CustomerInfo.email, this.CustomerInfo.RFC, this.propertyInfo.codeProperty, this.propertyInfo.amount,
      this.propertyInfo.advancePayment, this.propertyInfo.monthlyPayment, "", "", this.fechaApartir,
      this.propertyInfo.northeasBorder, this.propertyInfo.northwestBorder, this.propertyInfo.southeastBorder,
      this.propertyInfo.southwestBorder, this.propertyInfo.sketchMap)

  }


  printer() {
    const printContent = document.getElementById("htmlData");
    const WindowPrt = window.open('', '', '');
    WindowPrt!.document.write(printContent!.innerHTML);
    WindowPrt!.document.close();
    WindowPrt!.focus();
    WindowPrt!.print();
    WindowPrt!.close();
  }


  ConfirmarVenta() {
    localStorage.setItem('dateApartirde', this.customerFormGroup.value.datebefore);
    const dialogo1 = this.dialog.open(FinishedSaleComponent
      , {
        width: '600px',
      });

      dialogo1.afterClosed().subscribe(
        res => {
          this.dialogRef.close();
        }
      );
  }

  valuechange() {
    this.ngOnInit();
  }

  getSellerId() {
    this.decodetoken = decode(localStorage.getItem('token') || '');
    console.log("quien vendio?: ", this.decodetoken.email)
    this.mapsService.getSellerId(this.decodetoken.email).subscribe(
      res => {
        console.log('Id de quien vendio: ', res.idEmployee)
        this.sellerId = res.idEmployee;
        this.mapsService.getSalesCoordinator(this.sellerId).subscribe(
          res2 => {
            this.sellerCoord = res2.idcoord;
            localStorage.setItem('sellerId', this.sellerId);
            localStorage.setItem('coordId', this.sellerCoord);
          }
        );
      }
    )
  }
}
