<app-loader [loader]="loader" *ngIf="adminService.isLoading;"></app-loader>

<div class="modal-content">
  <div class="scrollable-content">
    <div id="rescissionFormat">
      <div class="rescissionFormat">
        <h1><b>CONVENIO RESCISORIO</b></h1>
        <span style="position: fixed; top: 0; right: 0; color: gray;">{{folio}}</span>

        <p class="headResc">Que celebran por una parte el <b>ING. CARLOS BETANCOURT RODRIGUEZ</b> quien se identifica
          con su
          credencial de elector expedida por el Instituto Nacional Electoral, con clave de elector
          <b>BTRDCR76010414H800</b>
          y {{agreementData.gender=='M'?'el':agreementData.gender=='F'?'la':'le'}} C.
          <b>{{agreementData.customerName}}
          </b> quien se identifica con su credencial de elector expedida por el Instituto Nacional Electoral, con clave
          de
          elector <b>{{agreementData.voterKey}}</b>
          los cuales están de acuerdo en que quede sujeto a las disposiciones que establecen las siguientes:
          <br><br>
        </p>
        <h1><b>ANTECEDENTES:</b></h1>
        <p class="antResc"><b>PRIMERA:</b> Manifiestan las partes, que con fecha
          {{agreementData.contractDate}}, celebraron un
          <b>CONTRATO
            DE PROMESA DE COMPRAVENTA</b> sobre {{agreementData.codeProperty}} en el desarrollo inmobiliario denominado
          <b>“{{agreementData.projectName}}”</b>.
        </p>
        <p><b>SEGUNDA:</b> Que las partes convinieron dentro del contrato referenciado, como motivo de
          rescisión las
          siguientes:<br></p>

        <div>
          <p>• Que <b>“EL PROMITENTE COMPRADOR”</b> incumpla en {{agreementData.monthRecession.substring(0,1)}}
            {{convertNumberToText((agreementData.monthRecession.substring(0,1)).toString()).toLowerCase()}} o más pagos
            de las
            mensualidades pactadas en el
            contrato.
            <br>• Por convenio entre <b>“LAS PARTES”</b>.
            <br><br>
          </p>
        </div>

        <h1><b>CLAUSULAS:</b></h1>
        <p><b>PRIMERA. - DEL OBJETO DEL CONVENIO:</b> El objeto del presente convenio es dar por terminada la relación
          contractual entre las partes sobre la promesa de compraventa celebrada el
          {{agreementData.contractDate}}, sobre {{agreementData.codeProperty}} en el desarrollo inmobiliario denominado
          <b>“{{agreementData.projectName.toUpperCase()}}”</b>.
        </p>
        <p><b>SEGUNDA. - DE LA RESCISION:</b> Acuerdan las partes el rescindir en su totalidad el contrato de promesa de
          compraventa referenciado en el apartado de antecedentes.</p>


        <p *ngIf="paymentData.penalizedBalance==0;else penalizacionAplciada"><b>TERCERA. - DE LA PENALIZACIÓN:</b>
          Acuerdan
          las partes que la presente rescisión <b>NO</b> generará penalización
          alguna a las partes. </p>

        <ng-template #penalizacionAplciada>
          <p><b>TERCERA. - DE LA PENALIZACIÓN:</b> Acuerdan las partes que la presente rescisión generará una
            penalización
            consistente en <b>{{paymentData.penalizedBalance
              | currency: '$ '}} ({{convertNumberToText(paymentData.penalizedBalance.toString())}} PESOS 00/100
              M.N.)</b>
            que se habrá de descontar del saldo con el que cuenta
            {{agreementData.gender=='M'?'el':agreementData.gender=='F'?'la':'le'}} C.
            <b>{{agreementData.customerName}}
            </b>.
          </p>
        </ng-template>

        <p *ngIf="agreementData.creditBalance > 0; Else NoCredit;"><b>CUARTA. - DE LA DEVOLUCIÓN DEL SALDO:</b> El
          <b>ING. CARLOS BETANCOURT RODRÍGUEZ</b> habrá de realizar la
          devolución del saldo con el que cuenta {{agreementData.gender=='M'?'el':agreementData.gender=='F'?'la':'le'}}
          C.
          <b>{{agreementData.customerName}}</b>, es decir, la cantidad de
          <b>{{agreementData.creditBalance
            | currency: '$ '}} ({{convertNumberToText(agreementData.creditBalance.toString())}} PESOS 00/100 M.N.)</b>
          mediante pagos parciales {{paymentData.frequency.toLowerCase()}} de <b>{{paymentData.memo
            | currency: '$ '}} ({{convertNumberToText(paymentData.memo.toString())}} PESOS 00/100 M.N.)</b> durante
          {{(agreementData.creditBalance/paymentData.memo).toFixed(0)}}
          {{paymentData.frequency === 'Semanales'? 'semanas': paymentData.frequency === 'Quincenales'? 'quincenas':
          paymentData.frequency === 'Mensuales'? 'meses': 'ERROR'}}
          {{paymentData.bankAccount===""?'':' a la cuenta con clabe interbancaria
          '}}<b>{{paymentData.bankAccount.length>0?paymentData.bankAccount:""}}</b>, siendo el
          primero de ellos dentro de <b>{{paymentData.firstPay}}
            {{convertNumberToText(paymentData.firstPay.toString()).toLowerCase()}} días
            hábiles</b>, hasta completar la cantidad señalada en el presente convenio.
        </p>

        <ng-template #NoCredit>
          <p><b>CUARTA. - DEL FINIQUITO DE CONVENIO:</b> Acuerdan <b>"LAS PARTES"</b> que por no contar con saldo a
            favor después de aplicada la penalización señalada en el presente convenio, dan por finiquitado en su
            totalidad el presente convenio.</p>
        </ng-template>

        <p><b>QUINTA. - DE LA CONFIDENCIALIDAD</b>: Las partes reconocen que como consecuencia de la celebración del
          presente <b>CONVENIO DE RESCISIÓN</b> se generó un esquema de cumplimiento individual en base a los términos
          pactados, por consecuencia convienen en <b>NO REVELAR</b>, durante la vigencia del presente convenio e
          inclusive
          por un periodo de <b>1 AÑO POSTERIOR A SU TERMINACIÓN</b> dicha información, la cual será catalogada como
          <b>INFORMACIÓN CONFIDENCIAL</b>; Cada una de <b>LAS PARTES</b> será responsable del incumplimiento de esta
          obligación por
          parte de los terceros que debido a la relación que con éstos guarden las partes, tengan acceso a la
          información confidencial, debiendo <b>LAS PARTES</b> hacer extensivo el deber de confidencialidad contenido en
          el
          presente convenio a dichas personas. Como consecuencia del incumplimiento de la presente clausula y
          divulgación de la información contenida en el presente convenio, la parte que divulgue dicha información será
          acreedora al <b>PAGO DE DAÑOS Y PERJUICIOS</b> y una pena convencional por la cantidad del monto
          total del convenio.
        </p>

        <p><b>SEXTA. - DE LA JURISDICCIÓN:</b> Para todo lo relacionado con la interpretación, ejecución y cumplimiento
          del
          presente
          convenio, <b>“LAS PARTES”</b> convienen en someterse a las leyes y tribunales competentes del municipio de
          Tepic
          del
          estado de Nayarit, renunciando a cualquier otro fuero que pudiera corresponderles por razón de domicilio
          presente o futuro.</p>

        <p>El presente Convenio empezará a surtir sus efectos a partir de la fecha de su firma.</p>
        <p>Leído que fue el presente instrumento y enteradas las partes de su contenido y alcance, se firma en la ciudad
          de
          Tepic, Nayarit, el día {{(today).getDate()}}
          {{convertNumberToText(((today).getDate()).toString()).toLowerCase()}}
          del mes de {{monthName[today.getMonth()].toLowerCase()}} del año
          {{today.getFullYear()}}
          {{convertNumberToText((today.getFullYear()).toString()).toLowerCase()}}.
          <br><br><br><br><br><br><br>
        </p>
        <div class="signs">
          <p>
            <b>C. {{agreementData.customerName}}</b>
            <br>
            "EL PROMITENTE COMPRADOR"
          </p>

          <p>
            <b>ING. CARLOS BETANCOURT RODRIGUEZ</b>
            <br>
            "EL DESARROLLADOR"
          </p>
        </div>
      </div>
    </div>

    <div>
      <button (click)="pruebaImpresion()">Generar convenio</button>
    </div>

  </div>
</div>
