import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import { ProjectdetailsService } from 'src/app/services/projectdetails.service';
import { ProductcatalogService } from 'src/app/services/productcatalog.service';
import { ConsumerTokenService } from 'src/app/services/consumer-token.service';
import { ProjectService } from 'src/app/services/project.service';
import { DatePipe } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL


import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-consumer-token-modify-edit-dialog',
  templateUrl: './consumer-token-modify-edit-dialog.component.html',
  styleUrls: ['./consumer-token-modify-edit-dialog.component.scss']
})
export class ConsumerTokenModifyEditDialogComponent implements OnInit {
  towers: any;
  productname = 'productname';
  consumerToken: any = [{
    folio: 'Cargando...',
    projectname: 'Cargando...',
    towernumber: 'Cargando...',
    empleado: 'Cargando...'
  }]       //Contiene la información del vale de consumo en formato JSON
  projectDetailByID: any = [];
  projects: any = [];
  MaterialIngresado: any;      //Contiene el JSON del material que se va a agregar
  autocomplete: any;           //Mapeo de los nombres de los materiales
flagSubmit:boolean=false;       //Bandera para bloquear el botón de submit en cuanto se confirme la función


  materials: any = [];


  //Objeto para almacenar los materiales registrados en la BD
  products: any = [];

  actualDate: any;

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};
  pipe = new DatePipe('es-MX');


  constructor(
    private projectDetailService: ProjectdetailsService,
    private ProductcatalogService: ProductcatalogService,
    private consumerTokenService: ConsumerTokenService,
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ProjectService: ProjectService,
    private MatDialogRef: MatDialogRef<ConsumerTokenModifyEditDialogComponent>
  ) { }

  ngOnInit(): void {
    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
    this.selectProjects();//Esta función inicia una cadena de recuperación de datos en orden
    this.initProducts();
    this.getUser();
    console.log('Id del vale de consumo: ' + this.data)
  }

  consumerTokenFormGroup: FormGroup = this._formBuilder.group({
    noteMod: [, Validators.required],
    idProject: [],
    fk_idprojectDet: [],
    folio: [],
    tokenDate: [],
    amount: [],
    auth: [],
    unit: [],
    idproduct: [],
    useremail: this.getUser(),
    worker: [],
    rmaster: [],
    warehouseman: [],
    requiredArea: [],
    userEmailMod: this.getUser(),
    idConsumerToken: this.data
  });

  async updateConsumerToken() {
    Swal.fire({
      title: 'Verifique los datos',
      text: "Está a punto de guardarse el vale de consumo con los datos que hay en la tabla",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, espera',
      confirmButtonText: 'Sí, guárdala!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.flagSubmit=true;
        this.consumerTokenService.updateConsumerToken(this.consumerTokenFormGroup.value).subscribe(
          res => {
            console.log('valor del formulario')
            console.log(this.consumerTokenFormGroup.value);
            this.consumerTokenService.deleteConsumerTokenProduct(this.data).subscribe(
              res => {
                for (let mat of this.materials) {
                  this.consumerTokenService.insertProductConsumerToken(mat.fk_idConsumerToken, mat.fk_idproduct, mat.amount).subscribe(res => { console.log('Vale de consumo: ' + mat.fk_idConsumerToken + '\nId del producto: ' + mat.fk_idproduct + '\nCantidad del producto: ' + mat.amount) })//Producto id, cantidad
                }
                this.sweet.AlertTime("center", "success", "Este vale de consumo se actualizó correctamente", false, 2000);
                this.MatDialogRef.close();
              }
            )
          }
        );
      }
    });
  }

  async initProducts() { //recupera lista materiales
    this.ProductcatalogService.getNamesOfProducts().subscribe(
      res => {
        this.products = res;
      }
    );
  }

  prueba(a: any) {
    console.log(a);
  }

  async getConsumerTokenData() {
    this.consumerTokenService.getConsumerToken(this.data).subscribe(
      res => {
        res[0].empleado = res[0].empleado.substring(0, res[0].empleado.length - 1)
        this.consumerToken = res;
        this.consumerTokenFormGroup.controls['idProject'].setValue(res[0].idProject);
        this.consumerTokenFormGroup.controls['folio'].setValue(res[0].folio);
        this.consumerTokenFormGroup.controls['tokenDate'].setValue(this.pipe.transform(res[0].tokenDate, 'yyyy-MM-dd'));
        console.log(this.pipe.transform(res[0].tokenDate, 'yyyy-MM-dd'))
        this.consumerTokenFormGroup.controls['requiredArea'].setValue(res[0].requiredArea);
        this.consumerTokenFormGroup.controls['auth'].setValue(res[0].empleado);
        //---
        this.consumerTokenService.getAllProductsFromConsumerToken(this.data).subscribe(
          res => {
            this.materials = res;
            console.log('Materiales');
            console.log(res);
          }
        );
        this.selectProject(res[0].idProject);
      }
    )
  }


  async selectProject(id: number) {//Al seleccionar un proyecto (En .html, campo "idProject"), se recuperan todos los datos de projectDetail de ese proyecto (Todos los módulos de ese proyecto)
    this.consumerTokenFormGroup.controls['fk_idprojectDet'].setValue(''); //En caso de que cambie el proyecto, se borra el contenido del campo HTML "fk_idprojectDet"
    console.log('ID del proyecto: ' + id)
    this.projectDetailService.selectDetailsById(id).subscribe(
      res => {
        //Recuperamos la información de torres del proyecto
        this.projectDetailByID = res;
        this.consumerTokenFormGroup.controls['fk_idprojectDet'].setValue(res[0].idprojectdet);

        console.log("Todos los projectDetail de ese proyecto");
        console.log(res);
      }
    );
  };

  async materialSeleccionado(event: any) {//Recupera el item seleccionado en el ngautocomplete "idproduct", obtiene el ID y lo almacena en variable
    this.ProductcatalogService.selectProduct(parseInt(event.productname.split('~')[0])).subscribe(
      res => {
        this.consumerTokenFormGroup.controls['unit'].setValue(res.unit);
        console.log('evento del ng autocomplete')
        console.log(event);
        this.MaterialIngresado = event;
      }
    )
  }

  async selectProjects() { //Recupera la lista de proyectos de la tabla projects
    this.ProjectService.selectProjects().subscribe(
      res => {
        this.projects = res;
        this.getConsumerTokenData();
      },
      err => console.log(err)
    );
  };

  async addMaterial() {
    if (this.consumerTokenFormGroup.controls['idproduct'].value == null || this.consumerTokenFormGroup.controls['idproduct'].value == ''
      || this.consumerTokenFormGroup.controls['amount'].value == null || this.consumerTokenFormGroup.controls['amount'].value == ''
      || this.consumerTokenFormGroup.controls['unit'].value == null || this.consumerTokenFormGroup.controls['unit'].value == '') {
      this.sweet.AlertTime("center", "error", "Por favor complete los campos producto y cantidad correctamente.", false, 2000);
    } else {
      console.log('material ingresado variable');
      console.log(this.MaterialIngresado);
      this.materials.push({
        'fk_idproduct': this.MaterialIngresado.idProduct,
        'amount': this.consumerTokenFormGroup.controls['amount'].value,
        'fk_idConsumerToken': this.data,
        'producto': this.MaterialIngresado.productname.split(' ~ ')[1],
        'unit': this.consumerTokenFormGroup.controls['unit'].value
      }
      )
      this.consumerTokenFormGroup.controls['idproduct'].setValue("");
      this.consumerTokenFormGroup.controls['amount'].setValue("");
      this.consumerTokenFormGroup.controls['unit'].setValue("");
      console.log('Materiales 2da actualización')
      console.log(this.materials);
    }
  }
  editProduct(i: number) {
    if (!(this.consumerTokenFormGroup.controls['idproduct'].value == null || this.consumerTokenFormGroup.controls['idproduct'].value == '')
      && !(this.consumerTokenFormGroup.controls['amount'].value == null || this.consumerTokenFormGroup.controls['amount'].value == '')
      && !(this.consumerTokenFormGroup.controls['unit'].value == null || this.consumerTokenFormGroup.controls['unit'].value == '')) {
        Swal.fire({
          title: 'ATENCION',
          text: "Aún se encuentra editando un material, ¿Desea guardar ese material antes de continuar con el seleccionado?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'No, quiero continuar editando el material anterior',
          confirmButtonText: 'Si, guarda el material y continuemos'
        }).then((result) => {
          if (result.isConfirmed) {
            this.addMaterial();
            this.MaterialIngresado = {
              'idProduct': this.materials[i].fk_idproduct,
              'productname': this.materials[i].fk_idproduct + ' ~ ' + this.materials[i].producto
            }
            this.consumerTokenFormGroup.controls['idproduct'].setValue(this.MaterialIngresado);
            this.consumerTokenFormGroup.controls['amount'].setValue(this.materials[i].amount);
            this.consumerTokenFormGroup.controls['unit'].setValue(this.materials[i].unit);
            this.materials.splice(i, 1);
          }
        });
      }else if(!(this.consumerTokenFormGroup.controls['idproduct'].value == null || this.consumerTokenFormGroup.controls['idproduct'].value == '')
      || !(this.consumerTokenFormGroup.controls['amount'].value == null || this.consumerTokenFormGroup.controls['amount'].value == '')
      || !(this.consumerTokenFormGroup.controls['unit'].value == null || this.consumerTokenFormGroup.controls['unit'].value == '')){
        Swal.fire({
          title: 'Aviso',
          text: "Hay un material incompleto en los campos de edición, ¿Desea descartar los campos y continuar con el material seleccionado?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'No, aún sigo editando el material anterior',
          confirmButtonText: 'Si, descarta los campos y permiteme editar el nuevo material'
        }).then((result) => {
          if (result.isConfirmed) {
            this.MaterialIngresado = {
              'idProduct': this.materials[i].fk_idproduct,
              'productname': this.materials[i].fk_idproduct + ' ~ ' + this.materials[i].producto
            }
            this.consumerTokenFormGroup.controls['idproduct'].setValue(this.MaterialIngresado);
            this.consumerTokenFormGroup.controls['amount'].setValue(this.materials[i].amount);
            this.consumerTokenFormGroup.controls['unit'].setValue(this.materials[i].unit);
            this.materials.splice(i, 1);
          }
        }); 
      }else{
        this.MaterialIngresado = {
          'idProduct': this.materials[i].fk_idproduct,
          'productname': this.materials[i].fk_idproduct + ' ~ ' + this.materials[i].producto
        }
        this.consumerTokenFormGroup.controls['idproduct'].setValue(this.MaterialIngresado);
        this.consumerTokenFormGroup.controls['amount'].setValue(this.materials[i].amount);
        this.consumerTokenFormGroup.controls['unit'].setValue(this.materials[i].unit);
        this.materials.splice(i, 1);
      }
    }

    getUser() {
      this.decode = decode(localStorage.getItem("token") || "")
      return this.decode.email
    }
  }