import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { comissionReportService } from 'src/app/services/comission.service';
import { ControComissionsService } from 'src/app/services/contro-comissions.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { DatePipe } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL

@Component({
  selector: 'app-coordinator-payment',
  templateUrl: './coordinator-payment.component.html',
  styleUrls: ['./coordinator-payment.component.scss'],

})

export class CoordinatorPaymentComponent implements OnInit {
  
  date = new FormControl(new Date());
  serializedDate = new FormControl(new Date().toISOString());


  //Nomenclatura inmueble
  M = "Manzana"
  L = "Lote"
  T = "Torre"
  D = "Departamento"

  //Manejo de información res API, arreglos con carga de datos de las consultas a los servicios que conectan con la API
  sal: any = [];
  codInm: any = [];
    
  //Variables locales
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  codigoInm: string | any = null
  vendedorCod: number | any = null
  codCoord: any;
  conInmueble: any;  

  //Bandera tarjeta inserccion consulta
  nuevoSaldo = false
  creaPago = false
  ocultaEntrada = true
  formularioActivo = true
  formularioApagado = false

  //Control estado de pagos
  limite = -4
  tolerancia = -1
  tolerancia2 = -2
  tolerancia3 = -3

  //API cancelacion de pagos
  review_btn = false;

  //Calculo de fecha
  n = new Date();
  //Año
  y = this.n.getFullYear();
  //Mes
  m = this.n.getMonth() + 1;
  //Día
  d = this.n.getDate();

  h = this.n.toLocaleTimeString()



  salida = ""
  salidaFecha() {
    if (this.m < 9 && this.d < 9) {
      this.salida = this.y + '-' + '0' + this.m + '-' + '0' + this.d + ' ' + this.h;
    } else if (this.d < 9 && this.m > 9) {
      this.salida = this.y + '-' + this.m + '-' + '0' + this.d + ' ' + this.h;
    } else if (this.d > 9 && this.m < 9) {
      this.salida = this.y + '-' + '0' + this.m + '-' + this.d + ' ' + this.h;
    } else {
      this.salida = this.y + '-' + this.m + '-' + this.d + ' ' + this.h;
    }
    //console.log(this.salida);
    return this.salida

  }
  anios() {
    return this.y
  }
  mess() {
    return this.m
  }
  dias() {
    return this.d
  }

  fechaA = "";

  fechaActual() {
    if (this.m < 9 && this.d < 9) {
      this.fechaA = this.y + '-' + '0' + this.m + '-' + '0' + this.d ;
    } else if (this.d < 9 && this.m > 9) {
      this.fechaA = this.y + '-' + this.m + '-' + '0' + this.d ;
    } else if (this.d > 9 && this.m < 9) {
      this.fechaA = this.y + '-' + '0' + this.m + '-' + this.d ;
    } else {
      this.fechaA = this.y + '-' + this.m + '-' + this.d ;
    }
    //console.log(this.salida);
    
    return this.fechaA

  }

 


  //Autocompletado libreria, variables requeridas
  keyword = 'name';
  palClav = 'fk_property';
  cadenaInmueble: any = "";



  //Validaciones formulario pagos
  cantidadDefault = 1000;

  paymentComFormGroup: FormGroup = this._formBuilder.group({
    paymentDate: [null, Validators.required],
    registrationDate: [this.salidaFecha(), Validators.required],
    paymentAmount: [null, [Validators.required, Validators.min(0)]],
    paymentStatus: ['Aprobado', Validators.required],
    folio: [null, [Validators.required, Validators.min(0)]],
    paymentConcept: ['', Validators.required],
    fk_salesCoordinator: [this.vendedorCod, Validators.required],
    fk_payer: [1004, Validators.required],
    fk_property: [this.codigoInm, Validators.required]
  })

  Concepto = [
    { tipo: 'Mensualidad' },
    { tipo: 'Anticipo' }
  ];

  conc = "Mensualidad"

  pipe = new DatePipe('es-MX');


  fkCliente: any | string = ""

  sales: any = [];
  //Manejo tabla Pagos  
  saleCoord: string | any = [];

  constructor(    
    private router: Router, 
    private _formBuilder: FormBuilder, 
    private comissions: comissionReportService,
    private controlComissions: ControComissionsService,
    public sweet: SweetAlertComponent
  ) { }

  ngOnInit(): void {
    this.saleCode();
    this.salidaFecha();
    this.saleCoord = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );

    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL

    this.fechaActual();

   // console.log("Data formulario → " , this.paymentComFormGroup.controls["paymentDate"].setValue(this.pipe.transform(this.n.toISOString(),('dd-mm-yyyy'))))
   // console.log(this.paymentComFormGroup.controls['paymentDate'].setValue(this.pipe.transform(this.n.toISOString(),('MM-dd-yyyy'))))
   // console.log("Pipe transform 1 ", this.pipe.transform(this.n.toISOString(),('dd-MM-yyyy')))
   // console.log("Pipe transform → ", this.pipe.transform(this.n.toISOString(),('MM/dd/yyyy')))
  }

  //Consulta de datos desde el servicio que consulta a la API

  saleCode() {
    this.comissions.coordList().subscribe(
      res => {

        this.sal = res;
      },
      err => console.log(err)
    );
  }

  onKeypressEvent(event: any) {
    this.codCoord = event
    let cad: any = {};
    cad = this.codCoord.name;
    if (cad == ' ' || cad == null || cad == "") {
      console.log("Se requiere consultar coordinador")
    } else {
      this.vendedorCod = cad.substr(0, 4);
      this.selectSale(cad.substr(0, 4));
    }
  }

  onKeypressEventSale(eventInm: any) {
    this.conInmueble = eventInm
    let cadInm: any = {};
    cadInm = this.conInmueble.name;
    if (cadInm == ' ' || cadInm == null || cadInm == "") {
      console.log("Se requiere consultar inmueble")
    } else {
      this.codigoInm = cadInm.substr(0, 8); //Pasar valor a variable        
    }
  }

  selectSale(sale: number) {
    this.comissions.reporteCoordSales(sale).subscribe(
      res => {
        this.saleCoord = res;
      },
      err => console.log(err)
    );
    }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

//Creación de pagos de comisión.
  createComissionPayment() {
    this.comissions.createComissionPayment(this.paymentComFormGroup.value).subscribe(
      res => {
        //this.sweet.AlertTime("center", "success", "Pago registrado con éxito", false, 2000);
        if(res.code == 'ER_DUP_ENTRY' ){
          this.sweet.Toast("error","Folio duplicado \n [ verifique la información y registre nuevamente ]")
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['coordinator']));  
        }else{
          this.sweet.Toast("success","Pago registrado con éxito")
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['coordinator']));
        }

      },
      err => {
        console.error(err)
      }
    )
  }
}
