<mat-dialog-content>

    <mat-divider></mat-divider>

    <div style="padding-top: 1%; padding-bottom: 1% ;">
        <h5 class="col-12">Modificar concepto de pago</h5>
    </div>

    <mat-divider> </mat-divider>

    <form class="row" [formGroup]="payComFormGroup">


        <div class="col-12" style="display: flex; justify-content: space-around; padding-top: 1%;">

            <mat-form-field appearance="outline"  class="col-md-2" style="text-align: center;">
                <mat-label>Folio</mat-label>
                <input matInput type="text" formControlName="folio" [(ngModel)]="paydata.folio"  required readonly>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-3" style="text-align: center;">
                <mat-label>Inmueble</mat-label>
                <input matInput type="text" formControlName="fk_property" [(ngModel)]="paydata.fk_property" required
                    readonly>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-3">
                <mat-label>Cantidad</mat-label>
                <input matInput type="text" formControlName="paymentAmount" [(ngModel)]="paydata.paymentAmount" required
                    readonly>

            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-4" style="text-align: right;">
                <mat-label>Fecha de Pago</mat-label>
                <input matInput type="text" formControlName="paymentDate" [(ngModel)]="paydata.paymentDate" required
                    readonly>
            </mat-form-field>

        </div>

        <div class="col-12" style="display: flex; justify-content: space-around;">

            <mat-form-field appearance="outline" class="col-md-3" required>
                <mat-label>Concepto de pago</mat-label>
                <mat-select formControlName="paymentConcept" [(ngModel)]="paydata.paymentConcept" required>
                    <mat-option *ngFor="let p of concepto" [value]="p.tipo">
                        {{p.tipo}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-9" focused Required>
                <mat-label>Motivo de modificacion</mat-label>
                <input matInput type="textarea" formControlName="modificationNotes"
                    [(ngModel)]="paydata.modificationNotes" required autofocus>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-2" focused hidden>
                <mat-label>Usuario que modifica </mat-label>
                <input matInput type="text" formControlName="userModification" [(ngModel)]="decode2" required>
            </mat-form-field>

        </div>

    </form>

</mat-dialog-content>



<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-button class="btn btn-m19" (click)="updatePayConcept(paydata.folio , paydata);">Guardar</button>
</mat-dialog-actions>