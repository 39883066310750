import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { budget } from '../models/Budget';

@Injectable({
  providedIn: 'root'
})
export class BudgetService {
  private url = 'http://localhost:3000/api';
  constructor(private http: HttpClient) { }

  // //Registrar requisiciones
  // insertRequisition(req:requisition): Observable<any> {
  //   return this.http.post(`${environment.URLServer}/requisitions/insert`,req);
  // }

  //Registrar presupuestos
  insertBudget(bud:budget): Observable<any> {
    return this.http.post(`${environment.URLServer}/budget/insertbudget`,bud);
  }

  //Consultar todos los presupuestos
  selectBudget(): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget/getAllbudgets`);
  }

  //Consultar un presupuesto
  getBudgetInfo(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget/getBudgetInfo/`+id);
  }


  //Registrar productos en los presupuestos
  insertProductsBudget(idbudget:Number, iddeparture: Number, idconcept:Number, idproduct: Number, amount:Number, unitcost:Number, total:Number, email:String): Observable<any> {
    return this.http.post(`${environment.URLServer}/budget/insertproducts`,[idbudget,iddeparture,idconcept,idproduct,amount,unitcost,total, email]);
  }

   //Eliminar un presupuesto
   deleteBudget(id:Number): Observable<any> {
    return this.http.delete(`${environment.URLServer}/budget/deleteBudget/`+id);
  }

  //Consultar el registro del responsable
  getRegister(email:any): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget/getRegister/`+email);
  }

  getListDepaCon(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget/getListDepaConcept/${id}`);
  }

  //Consultar materiales en un presupuesto
  getProdInBudget(idbudget:any): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget/getprodInBud/`+idbudget);
  }

  //Actualizar un presupuesto
  updateBudget(bud: budget): Observable<any> {
    return this.http.put(`${environment.URLServer}/budget/updateBudget`,bud);
  }

  //Eliminar materiales de un presupuesto
  deleteAllByBud(id:Number): Observable<any> {
    return this.http.delete(`${environment.URLServer}/budget/deleteAllByBud/`+id);
  }

  selectApprovedBudget(): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget/getApprovedBudgets`);
  }

  selectAwaitBudget(): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget/getAwaitBudgets`);
  }

  selectRejectBudget(): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget/getRejectBudgets`);
  }

  approveBudget(id:number): Observable<any> {
    return this.http.put(`${environment.URLServer}/budget/approveBudget/${id}`,[]);
  }

  rejectBudget(id:number): Observable<any> {
    return this.http.put(`${environment.URLServer}/budget/rejectBudget/${id}`,[]);
  }
  //Total del proyecto
  getTotalProject(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget/getTotalProject/${id}`);
  }

  getTotalProjectBudget(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget/getTotalProjectByBudget/${id}`);
  }

  // Total del modulo
  getTotalModule(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget/getTotalModule/${id}`);
  }

   // Total de la partida
   getTotalDeparture(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget/getTotalDeparture/${id}`);
  }

   // Partidas
   getDeparture(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget/getDeparture/${id}`);
  }

   // Total de la partida
   getModule(id: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget/getModule/${id}`);
  }

  // Totales de todos los proyectos
  getTotalProjects(): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget/getTotalProjects`);
  }

  selectBudgetByProject(id:number): Observable<any> {
    return this.http.get(`${environment.URLServer}/budget/getBudgetByProject/${id}`);
  }

  searchProductsFromDeparture(ib: number, id: number, ic: number) {
    return this.http.get(`${environment.URLServer}/budget/searchProductsFromDeparture/${ib}/${id}/${ic}`);
  }

   
}
