<app-loader [loader]="loader" *ngIf="isLoading"></app-loader>

<mat-tab-group mat-stretch-tabs>

  <!------------------------------------------------------------------------------------->
  <!-- Registro de bitácora ------------------------------------------------------------->
  <!------------------------------------------------------------------------------------->
  <mat-tab>
    <ng-template mat-tab-label>
      <span>NUEVO REGISTRO</span>
      <mat-icon class="tab-icon">note_add</mat-icon>
    </ng-template>
    <div class="container-fluid mt-3">
      <mat-card class="card-form">
        <mat-card-content class="justify-align-center">
          <!--<div class="row" style="flex-wrap: wrap; width:100%">-->
          <form class="row" style="flex-wrap: wrap; width:100%; gap: 15px;" [formGroup]="logbookForm"
            (ngSubmit)="submitFormLogbook()" onKeyPress="if(event.keyCode == 13) event.returnValue = false;">
            <div class="column" style="min-width: 540px; flex-grow:3;width: 50%;">
              <span class="logbookSpan"><span class="superWord">BITACORA </span> DÍA {{this.serverDate}} <div
                  [class]="(selectedDate)?'m19Btn':'m19AltBtn'"
                  (click)="(selectedDate)? defaultServerDate():picker.open()"> <mat-icon>date_range</mat-icon> </div>
              </span>
              <hr width="100%" size="20px" color="#FF0000">
              <span class="logbookName superWord">ELABORA: {{this.userName}}</span>
              <div class="row">
                <!--COMPONENTE OCULTO DE SELECCION DE FECHA-->
                <mat-form-field appearance="outline" [hidden]="true">
                  <mat-label>Fecha de apertura</mat-label>
                  <input matInput [matDatepicker]="picker" (dateChange)="onDateChange($event)">
                  <mat-hint>mm/dd/aaaa</mat-hint>
                  <mat-datepicker touchUi #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="row">
                <!--COMBOBOX DE PROYECTO-->
                <mat-form-field appearance="outline" class="component">
                  <mat-label>PROYECTO</mat-label>
                  <mat-select formControlName="fk_idProject" (valueChange)="selectedProject($event)" required>
                    <mat-option *ngFor="let project of _selectProjects" [value]="project.idProject">
                      {{project.name}}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>map</mat-icon>
                </mat-form-field>
                <!--COMBOBOX DE MODULO-->
                <mat-form-field appearance="outline" class="component">
                  <mat-label>MÓDULO</mat-label>
                  <mat-select formControlName="fk_idprojectdet" (valueChange)="loadOpenDeparture($event)" required>
                    <mat-option *ngFor="let tower of _selectDetailsById" [value]="tower.idprojectdet">
                      Modulo {{tower.towernumber}}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>apartment</mat-icon>
                </mat-form-field>
              </div>
              <div class="row">
                <!--INPUT DE RESIDENTE DE OBRA-->
                <mat-form-field appearance="outline" class="component">
                  <mat-label>RESIDENTE DE OBRA</mat-label>
                  <mat-select formControlName="fk_idEmployee" required>
                    <mat-option *ngFor="let lr of _clogbook_responsable" [value]="lr.idEmployee">
                      {{lr.nameEmployee}}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>person_search</mat-icon>
                </mat-form-field>
                <!--INPUT NUMÉRICO MANO DE OBRA-->
                <mat-form-field appearance="outline" class="component">
                  <mat-label>MANO DE OBRA</mat-label>
                  <input matInput type="number" formControlName="workmanship" min="1"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)">
                  <mat-icon matSuffix>pin</mat-icon>
                </mat-form-field>
              </div>
              <div class="column" style="width:100%">
                <!--OBSERVACIONES-->
                <span>OBSERVACIONES</span>
                <mat-form-field appearance="outline">
                  <textarea matInput max="1000" placeholder="Escribe las observaciones aquí..." cdkTextareaAutosize
                    formControlName="remarks" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="25"></textarea>
                </mat-form-field>
              </div>
              <div class="column" style="width:100%">
                <span class="titleEvidence">EVIDENCIAS</span>
                <div class="evidence">
                  <div class="reelImage">
                    <div class="imgContainer" *ngFor="let img of img64;index as i">
                      <img [src]="img" alt="archivo {{i}}">
                      <div class="deleteButton" (click)="this.img64.splice(i, 1);">
                        <mat-icon>delete</mat-icon>
                      </div>
                    </div>
                  </div>
                  <div class="addButton" (click)="fileInputImg.click()">
                    <input hidden (change)="LoadImg($event)" #fileInputImg type="file" accept=".png,.jpg,.jpeg" multiple
                      [disabled]="isDisabled">
                    <mat-icon>add</mat-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="column" style="flex-grow: 2;width: 38%; gap:20px">
              <div class="divDeparture">
                <span *ngIf="this._selectHistory.length==0" class="noDepMessage">Seleccione un módulo para elegir
                  partidas</span>
                <div *ngIf="this._selectHistory.length!=0" class="row">
                  <div class="component">
                    <!--SELECTOR DE PARTIDAS-->
                    <ng-autocomplete formControlName="fk_iddeparture" name="busquedaP" placeholder="PARTIDA"
                      (selected)='departureSelected($event)' [data]="_selectHistory" [searchKeyword]="'departurename'"
                      (value)="$event" [itemTemplate]="itemTemplateP" [notFoundTemplate]="notFoundTemplateP">
                    </ng-autocomplete>
                    <ng-template #itemTemplateP let-itemP>
                      <a [innerHTML]="itemP.departurename"></a>
                    </ng-template>
                    <ng-template #notFoundTemplateP let-notFound>
                      <div>No hay coincidencias de partidas, no se ha elegido un módulo válido o no está abierta la
                        partida</div>
                    </ng-template>
                  </div>
                  <!--BOTON PARA AGREGAR PARTIDA-->
                </div>
                <hr *ngIf="this._selectHistory.length!=0" width="100%" size="20px" color="#FF0000">
                <div *ngIf="this._selectHistory.length!=0" class="departureList">
                  <div *ngFor="let dep of actualDeparture; index as i" class="departureCard">
                    <div class="column" style="width:10%;">
                      <span class="elementDepartureCard" style="font-size:1rem">Partida</span>
                      <span class="elementDepartureCard" style="font-size: 1.5rem;">{{dep.departurenumber}}</span>
                    </div>
                    <div class="column" style="width:80%;">
                      <span class="elementDepartureCard"
                        style="height: 100%; font-size: 1.2rem;">{{dep.descriptions}}</span>
                    </div>
                    <div class="column" style="width:10%">
                      <mat-icon (click)="this.actualDeparture.splice(i, 1);"
                        class="elementDepartureCard">delete</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column" style="width:100%">
                <span class="titleEvidence">CERTIFICADOS</span>
                <div class="evidence">
                  <div class="reelImage">
                    <div class="imgContainer" *ngFor="let pdf of pdf64;index as i">
                      <div class="pdfIcon">
                        <mat-icon>picture_as_pdf</mat-icon>
                      </div>
                      <div class="deleteButton" (click)="this.pdf64.splice(i, 1);">
                        <mat-icon>delete</mat-icon>
                      </div>
                    </div>
                  </div>
                  <div class="addButton" (click)="fileInputPdf.click()">
                    <input hidden (change)="LoadImg($event)" #fileInputPdf type="file" accept=".pdf" multiple
                      [disabled]="isDisabled">
                    <mat-icon>add</mat-icon>
                  </div>
                </div>
              </div>
              <div class="column saveEdit" style="width:100%">
                <button class="m19Btn" type="submit" [disabled]="isDisabled" (click)="uploadImages(img64,false)">
                  GUARDAR BORRADOR <mat-icon>edit</mat-icon>
                </button>
                <button class="m19AltBtn" type="submit" [disabled]="isDisabled" (click)="uploadImages(img64,true)">
                  CERRAR BITÁCORA <mat-icon>save</mat-icon>
                </button>
              </div>
            </div>
          </form>
          <!--</div>-->
        </mat-card-content>
      </mat-card>
    </div>
  </mat-tab>

  <!-------------------------------------------------------------------------------------->
  <!-------------------------------- PENDIENTES DE CIERRE --------------------------------->
  <mat-tab>
    <ng-template mat-tab-label>
      PENDIENTES DE CIERRE <mat-icon> settings </mat-icon>
    </ng-template>


    <div class="container-fluid mt-3">
      <mat-card class="card-form">
        <mat-card-content class="justify-align-center">

          <div class="containerLogGeneral">
            <div class="containerLogGeneral__containerInformationLog">
              <div class="containerLogGeneral__containerInformationLog__containerCard" *ngFor="let pen of pendientes"
                (click)="dataTemporal(pen)">

                <div style="display:flex; flex-direction:row; align-items: end;" *ngIf="pen.towernumber.length == 1 ">
                  <h3> {{pen.name.toUpperCase()}} </h3>
                  <h4 style="margin-left: 10px;"> <b> MODULO </b> </h4>
                  <h4 style="margin-left: 10px;"> <b style="color: var(--M19Primary)"> TORRE {{pen.towernumber}} </b>
                  </h4>
                </div>

                <div style="display:flex; flex-direction:row; align-items: end;" *ngIf="pen.towernumber.length > 1 ">
                  <h3> {{pen.name.toUpperCase()}} </h3>
                  <h4 style="margin-left: 10px;"> <b> MODULO </b> </h4>
                  <h4 style="margin-left: 10px;"> <b style="color:var(--M19Primary)"> {{pen.towernumber}} </b> </h4>
                </div>
                <br>
                <p>
                  {{pen.remarks}}
                </p>
                <br>


                <div class="containerLogGeneral__containerInformationLog__containerCard__footerCard">

                  <div
                    style="display: flex; width: 70%; text-align: left; display: flex; flex-direction: row; flex-wrap: wrap;">
                    <h6> Creada por: {{pen.employee}} </h6>
                  </div>

                  <div style="display: flex; width: 30%; justify-content: space-around;">

                    <!-- <mat-icon (click)="mensaje2()" style=" cursor:pointer; "> attach_file </mat-icon> -->
                    <mat-icon (click)="deletecLog(pen.idclogbook)" style=" cursor:pointer; "> delete </mat-icon>
                  </div>

                </div>
              </div>

            </div>

            <div class="containerLogGeneral__containerSummary" *ngIf="this.temporal.employee != 'SIN ASIGNAR' ">

              <div class="containerLogGeneral__containerSummary__information">
                <h4> BITACORA DIA: {{pipe.transform(this.temporal.datelog ,'EEEE d MMMM y')!.toUpperCase()}} </h4>
                <br>

                <p style="text-align: right;">
                  Hora de registro: {{pipe.transform(this.temporal.datelog ,'hh:mm aaaa')!.toUpperCase()}}
                </p>

                <p class="containerLogGeneral__containerSummary__information__classp"
                  *ngIf="this.temporal.towernumber.length == 1 "> {{this.temporal.name.toUpperCase()}}, MODULO TORRE
                  {{this.temporal.towernumber}} </p>
                <p class="containerLogGeneral__containerSummary__information__classp"
                  *ngIf="this.temporal.towernumber.length > 1 "> {{this.temporal.name.toUpperCase()}}, MODULO
                  {{this.temporal.towernumber}} </p>
                <br>

                <form class="row" style="flex-wrap: wrap; width:100%; flex-direction: column;"
                  [formGroup]="logbookPForm">

                  <p style="color: var(--M19Primary);">Partidas</p>
                  
                  <ul>
                    <li *ngFor="let d of depList">{{d.departurenumber}} {{d.descriptions}}</li>
                  </ul>

                  <p *ngIf="this.temporal.workmanship == 1 ">
                    CON
                    <mat-form-field appearance="outline" class="col-md-2" style="text-align: center;">
                      <input matInput type="number" formControlName="workmanship" min="1"
                        [value]="this.temporal.workmanship"
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)">
                    </mat-form-field>
                    TRABAJADOR
                  </p>

                  <p *ngIf="this.temporal.workmanship > 1 ">
                    {{this.temporal.descriptions}} CON
                    <mat-form-field appearance="outline" class="col-md-2" style="text-align: center;">
                      <input matInput type="number" formControlName="workmanship" min="1"
                        [value]="this.temporal.workmanship"
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46)">
                    </mat-form-field>
                    TRABAJADORES
                  </p>

                  <br>
                  <p>
                    <mat-form-field appearance="outline" class="col-md-12">
                      <textarea matInput max="1000" placeholder="Escribe las observaciones aquí..." cdkTextareaAutosize
                        formControlName="remarks" [value]="this.temporal.remarks" cdkAutosizeMinRows="5"
                        cdkAutosizeMaxRows="25"></textarea>
                    </mat-form-field>
                  </p>

                </form>
                <br>

                <!-- <div class="containerLogGeneral__containerSummary__images" *ngFor="let im of evidence">
                  <img style="display: flex; flex-direction: row;" [src]="im.docurl" [alt]="im.docname" (click)="pruebafuncion()">
              </div> -->

                <!-- The Modal -->
                <div id="myModal" class="modal">
                  <span class="close" (click)="closeFunction()">&times; </span>
                  <img class="modal-content" id="img01">
                  <div id="caption"> {{this.title}} </div>
                </div>

                <div class="contenedorImagenes">
                  <img id="myImg" *ngFor="let im of evidence; let i=index" [src]="im.docurl" [alt]="im.docname">
                </div>

                <div style="display:flex; flex-direction: row-reverse; ">
                  <button style="border-radius:50%; flex-direction:row-reverse; background-color: var(--M19Primary);
                        color: white; outline: none; border: none; padding:20px; margin-right: 20px;
                        margin-top:15px; margin-bottom: 10px;
                        padding: 10px;">
                    <mat-icon (click)="saveLogbook(this.temporal.idclogbook)" style=" cursor:pointer; "> save
                    </mat-icon>

                  </button>
                </div>
              </div>

            </div>

            <div class="containerLogGeneral__windowShow" *ngIf="this.temporal.employee == 'SIN ASIGNAR' ">
              <h1> Seleccione una tarjeta para obtener resultados </h1>
              <mat-icon style="margin-top: 15px; scale:2"> search </mat-icon>
            </div>

          </div>
        </mat-card-content>
      </mat-card>
    </div>

  </mat-tab>

  <!-------------------------------------------------------------------------------------->
  <!--------------------------------- RESUMEN --------------------------------------------->
  <!-------------------------------------------------------------------------------------->
  <mat-tab>
    <ng-template mat-tab-label>HISTORIAL<mat-icon> timeline </mat-icon>
    </ng-template>
    <div class="container-fluid mt-3">
      <mat-card class="card-form">
        <mat-card-content class="justify-align-center">
          <div class="filters">
            <mat-form-field appearance="outline">
              <mat-label>Proyecto</mat-label>
              <mat-select [(value)]="selectProjects"
                (valueChange)="project.id=($event).split('-')[0]; project.name=($event).split('-')[1]; checkDate(0,'');"
                required>
                <mat-option *ngFor="let project of projects" [value]="project.idProject+'-'+project.name">
                  {{project.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Mes</mat-label>
              <mat-select (valueChange)="checkDate($event, 'month')" required>
                <mat-option *ngFor="let month of months" [value]="month.num">
                  {{month.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Año</mat-label>
              <mat-select (valueChange)="checkDate($event, 'year')" required>
                <mat-option [value]="2023">
                  2023
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="dateFilter">
            <div *ngFor="let n of days"
              [ngClass]="getColor(n)==1 ? 'day--yellow' : getColor(n)==2?'day--orange': getColor(n)==3?'day--green':getColor(n)==4?'day--blue':'day--red'"
              (click)="getLogbookPerDay(n)">
              {{n}}
            </div>
          </div>

          <br>

          <div *ngIf="logbook.length===0"
            style="display: flex; flex-direction: column; align-items: center; margin-top: 20px;">
            <h4>Sin bitacoras registradas</h4>
            <mat-icon>close</mat-icon>
          </div>

          <div class="visor" *ngFor="let l of logbook; index as i;">
            <div class="infoContent">
              <div class="info">
                <h2 style="border-left: solid 3px var(--M19Primary); padding-left: 5px;">BITACORA DIA
                  {{(pipe.transform(l.datelog, "fullDate")).toUpperCase()}}</h2>
                <h4>{{l.name}}, MODULO {{l.towernumber}}</h4>
                <span>{{l.descriptions}}</span>
                <br>
                <p>{{l.remarks}}</p>
              </div>
              <button class="m19Btn" (click)="printLogbookUnique(l.idclogbook)">IMPRIMIR
                BITACORA<mat-icon>print</mat-icon></button>
            </div>

            <div class="evidenceHistory">
              <h2>EVIDENCIAS</h2>
              <div class="evidenceContent">
                <div class="evidenceVisor">
                  <ng-container *ngFor="let e of evidences" style="width: fit-content; max-width: 100%;">
                    <img *ngIf="e.fk_idclogbook == l.idclogbook" [src]="e.docurl" style="margin: 5px; width: 100%;">
                  </ng-container>
                </div>
                <button class="m19Btn" (click)="printEvidences(l.idclogbook, l.descriptions)">IMPRIMIR
                  EVIDENCIAS<mat-icon>print</mat-icon></button>
              </div>
            </div>
          </div>

          <div id="logbookDoc" [hidden]="true">
            <table class="controlPDF">
              <thead>
                <tr>
                  <td rowspan="2" colspan="2" style="padding: 10px;"><img src="../../../../assets/isotipo.png"
                      style="display: block; width: 130px; margin: auto;"></td>
                  <td colspan="2" style="text-align: center; font-weight: bold;">GENERADOR DE CONTROL DE OBRA</td>
                  <td colspan="2"><b>PERIODO</b><br>{{day+"/"+month+"/"+year}}</td>
                </tr>
                <tr>
                  <td colspan="2"><b>PROYECTO</b><br>{{project.name}}</td>
                  <td colspan="2"><b>FECHA DE EST</b><br>{{originDate.split("T")[0]}}</td>
                </tr>
                <tr>
                  <td style="text-align: center; font-weight: bold;">CONCEPTO</td>
                  <td style="text-align: center; font-weight: bold;">TRAMO</td>
                  <td style="text-align: center; font-weight: bold;">M.O.</td>
                  <td style="text-align: center; font-weight: bold;">HORA</td>
                  <td style="text-align: center; font-weight: bold;">FECHA</td>
                  <td style="text-align: center; font-weight: bold;">OBSERVACIONES</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let l of printlog">
                  <td>{{l.descriptions}}</td>
                  <td>{{l.towernumber}}</td>
                  <td style="white-space: nowrap;">{{l.workmanship}} TRABAJADORES</td>
                  <td>{{l.datelog.split(" ")[1]}}</td>
                  <td style="white-space: nowrap;">{{l.datelog.split(" ")[0]}}</td>
                  <td>{{l.remarks}}</td>
                </tr>
              </tbody>
            </table>

            <br>

            <table class="signArea">
              <tr>
                <td style="padding-bottom: 40px;">AREA TECNICA</td>
                <td style="padding-bottom: 40px;">RESIDENCIA EDIFICACION</td>
                <td style="padding-bottom: 40px;">RESIDENCIA DE URBANIZACION</td>
                <td style="padding-bottom: 40px;">AREA DE CONTROL Y SUPERVISION</td>
              </tr>
              <tr>
                <td style="text-align: center;">ING. GERMAN LUNA GAMBOA</td>
                <td style="text-align: center;">ING. JUAN CARLOS GONZALEZ ESCOBEDO</td>
                <td style="text-align: center;">ARQ. HECTOR ARELLANO</td>
                <td style="text-align: center;">ING. CARLOS BETANCOURT GONZALEZ</td>
              </tr>
            </table>
          </div>

          <div id="evidencesDoc" [hidden]="true">
            <table class="controlPDF">
              <thead>
                <tr>
                  <td colspan="1" style="padding: 10px;"><img src="../../../../assets/isotipo.png"
                      style="display: block; width: 130px; margin: auto;"></td>
                  <td colspan="2" style="text-align: center; font-weight: bold;"><b>PROYECTO</b><br>{{project.name}}
                  </td>
                  <td colspan="3"><b>PERIODO</b><br>{{day+"/"+month+"/"+year}}</td>
                </tr>
                <tr>
                  <td colspan="1"><b>REPORTE FOTOGRÁFICO</b></td>
                  <td colspan="2"><b>CONCEPTO</b><br>{{evidenceConcept}}</td>
                  <td colspan="3"><b>FECHA DE EST</b><br>{{originDate.split("T")[0]}}</td>
                </tr>
              </thead>
            </table>

            <br>

            <div
              style="display: flex; flex-wrap: wrap; box-sizing: border-box; justify-content: center; align-items: center; width: 100%; border: solid 1px black; gap: 10px; padding: 10px;">
              <img *ngFor="let e of printevid" [src]="e.docurl" style="width: 25%; margin: auto;">
            </div>

            <table class="signArea" style="margin-top: 20px;">
              <tr>
                <td style="padding-bottom: 40px;">AREA TECNICA</td>
                <td style="padding-bottom: 40px;">RESIDENCIA EDIFICACION</td>
                <td style="padding-bottom: 40px;">RESIDENCIA DE URBANIZACION</td>
                <td style="padding-bottom: 40px;">AREA DE CONTROL Y SUPERVISION</td>
              </tr>
              <tr>
                <td style="text-align: center;">ING. GERMAN LUNA GAMBOA</td>
                <td style="text-align: center;">ING. JUAN CARLOS GONZALEZ ESCOBEDO</td>
                <td style="text-align: center;">ARQ. HECTOR ARELLANO</td>
                <td style="text-align: center;">ING. CARLOS BETANCOURT GONZALEZ</td>
              </tr>
            </table>
          </div>

          <button class="m19InvBtn" style="position: fixed; right: 0; bottom: 0;" (click)="printLogbookAllDay()"
            *ngIf="printReady==1 && logbook.length!==0">IMPRIMIR TODO EL DÍA</button>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-tab>

</mat-tab-group>