import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { budgetCommissions } from '../models/BudgetCommissions';
import { CashCut } from '../models/CashCut';
import { CashOutFlow } from '../models/CashOutFlows';
import { Comission } from '../models/comission';
import { ControlComission } from '../models/ControlComission';

import { User } from '../models/User';


// Aqui se pueden consumir las rutas desde insomnia para comprobar que funcionen correctamente 


@Injectable({
  providedIn: 'root'
})
export class ControComissionsService {

  private url = 'http://localhost:3000/api';

  constructor(private http: HttpClient) { }

  //Consultar todas las comissiones 
  getAllComissions(): Observable<any> {
    return this.http.get(`${environment.URLServer}/getAllcomissions`);
  }

  getAllProperty(): Observable<any> {
    return this.http.get(`${environment.URLServer}/controlComission/getAllInmuebles`);
  }

  getAllPaymentsCustomers(): Observable<any> {
    return this.http.get(`${environment.URLServer}/controlComission/getAllPaymentsCustomers`);
  }


  getPropertySelected(proSel: any): Observable<any> {
    return this.http.get(`${environment.URLServer}/controlComission/selectInmuByID/${proSel}`);
  }

  getOneProperty(proSel: any): Observable<any> {
    return this.http.get(`${environment.URLServer}/controlComission/selectOneInmu/${proSel}`);
  }

  selectOneFolio(id: Number): Observable<any> {
    return this.http.get(`${environment.URLServer}/controlComission/selectOneFolio/${id}`);
    //return this.http.get(`${environment.URLServer}/controlComission/selectOneFolio/`+id);  //Es la misma
  }

  //Actualiza los conceptos de pago agregando un campo donde se inserta quien hizo esa modificacion y el porque 
  updateDataConceptByFolio(folio: number, updateConComission: ControlComission): Observable<ControlComission> {
    return this.http.put(`${environment.URLServer}/controlComission/updateDataConcept/${folio}`, updateConComission);
  }

  //Actualiza los conceptos de pago agregando un campo donde se inserta quien hizo esa modificacion y el porque 
  cancelTicketComissionService(modificationNotes: string, userModification: string, folio: number): Observable<ControlComission> {
    return this.http.put(`${environment.URLServer}/controlComission/cancelarComission/`, {modificationNotes, userModification ,folio});
  }


  //Actualiza los conceptos de pago agregando un campo donde se inserta quien hizo esa modificacion y el porque 
  selectByProjectAndId(namePro: string, nom: string): Observable<any> {
    //console.log(namePro)
    //console.log(nom)
    return this.http.get(`${environment.URLServer}/controlComission/selectByProjectAndId/${namePro}/${nom}`);
  }

    //Obtiene todos los clientes por proyecto
    selectByProjectGeneral(namePro: string): Observable<any> {
      console.log(namePro)
      return this.http.get(`${environment.URLServer}/controlComission/selectByProjectGeneral/${namePro}`);
    }


  selectPayByFornitureComissions(prop: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/controlComission/selectPayByFornitureComissions/${prop}`);

  } 

  selectPayByForniturePayments(prop: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/controlComission/selectPayByForniturePayments/${prop}`);

  }

  selectPaymentDate(): Observable<any> {
    return this.http.get(`${environment.URLServer}/controlComission/selectPay`);
  }

  selectPaymentCustomerDate(): Observable<any> {
    return this.http.get(`${environment.URLServer}/controlComission/selectPayCustomer`);
  }

  //=============================================================================================================
  //========================================= SEGUNDA VENTANA ===================================================
  //=============================================================================================================
  selectCustomerInformation(idUser: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/controlComission/selectCustomerInformation/${idUser}`);
  }

  selectAllCustoms() {
    return this.http.get(`${environment.URLServer}/controlComission/selectAllCustomers/`);
  }


  //Actualiza los conceptos de pago agregando un campo donde se inserta quien hizo esa modificacion y el porque 
  getLastFolio(): Observable<any> {
    return this.http.get(`${environment.URLServer}/controlComission/getLastFolio`);
  }


  //=============================================================================================================
  //========================================= SEGUNDA VENTANA ===================================================
  //=============================================================================================================

  loginUser(username: string): Observable<any> {
    return this.http.post<User>(`${environment.URLServer}/login`, { username });
  }

  //Insertar un proveedor
  getAllBudgetCommissions(): Observable<any> {
    return this.http.get(`${environment.URLServer}/controlComission/getAllBudgetCommissions`);
  }

  //Insertar un proveedor
  getAllCustomee(p: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/controlComission/getAllCustomees/${p}`);
  }


  //Obtiene todos los clientes clasificados por su inmueble
  getAllCustomeesGroup(): Observable<any> {
    return this.http.get(`${environment.URLServer}/controlComission/getAllCustomersGroup`);
  }

  getProjectByname(name: String): Observable<any> {
    return this.http.get(`${environment.URLServer}/controlComission/SelectProjectByName/${name}`);
  }

  getAllCoords(): Observable<any> {
    return this.http.get(`${environment.URLServer}/controlComission/getAllCoords/`);
  }


  createComissionPayment(comRep: Comission): Observable<any> {
    return this.http.post(`${environment.URLServer}/controlComission/insertCommission/`, comRep);
  }

  //Insertar un proveedor
  insertBudgetCommission(budcom: budgetCommissions): Observable<any> {
    return this.http.post(`${environment.URLServer}/controlComission/insertBudgetCommission`, budcom);
  }

  //Ruta para obtener el registro que posteriormente se modificara


  getOneRegisterForUpdateService(idbudSer: number): Observable<any> {
    return this.http.get(`${environment.URLServer}/controlComission/getOneRegister/${idbudSer}`);
  }


  //-------------------------------------------- CASH CUT ROUTES --------------------------------------------

  getPaymentsByCashService(curDate :string): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getPaymentsByCashandDateController/${curDate}`);
  }

  getPaymentsByDepositService(curDate :string): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getPaymentsByDepositAndDateController/${curDate}`);
  }

  getPaymentsByTranserService(curDate :string): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getPaymentsByTransferAndDateController/${curDate}`);
  }

  getPaymentsByCreditCardService(curDate :string): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getPaymentsByCreditCardAndDateController/${curDate}`);
  }

  getPaymentsByDebitCardService(curDate :string): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getPaymentsByDebitCardAndDateController/${curDate}`);
  }

  getPaymentsByCheckService(curDate :string): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getPaymentsByCheckAndDateController/${curDate}`);
  }

//-------------------------------------------- INGRESOS Y EGRESOS POR FECHA RUTAS (SERVICIOS) --------------------------------------------
  getAllPaymentByDateService(curDate: string): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getPaymentsByDateController/${curDate}`);
  }

  InsertCashOutService(data: CashOutFlow): Observable<any> {
    return this.http.post(`${environment.URLServer}/controlComission/insertCashOut/`, data);
  }

  getAllOutputsByDateService(regDate: string ): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getAllOutputsByDate/${regDate}`);
  }
  
  getAllEntriesByDateService(regDate: string ): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getAllEntriesByDate/${regDate}`);
  }


  InsertCashCutService(data: CashCut): Observable<any> {
    return this.http.post(`${environment.URLServer}/controlComission/insertCashCut/`, data);
  }



  //-------------------------------------------- INGRESOS Y EGRESOS POR RANGOS RUTAS (SERVICIOS) --------------------------------------------

  getEntriesByDateRangeCashService(inicio :string, fin :string): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getEntriesByDateRangeCashRoute/${inicio}/${fin}`);
  }

  getEntriesByDateRangeTransferService(inicio :string, fin :string): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getEntriesByDateRangeTransferRoute/${inicio}/${fin}`);
  }

  getEntriesByDateRangeDepositService(inicio :string, fin :string): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getEntriesByDateRangeDepositRoute/${inicio}/${fin}`);
  }

  getEntriesByDateRangeTDCService(inicio :string, fin :string): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getEntriesByDateRangeTDCRoute/${inicio}/${fin}`);
  }

  getEntriesByDateRangeTDDService(inicio :string, fin :string): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getEntriesByDateRangeTDDRoute/${inicio}/${fin}`);
  }

  getEntriesByDateRangeCheckService(inicio :string, fin :string): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getAllEntriesByDateRangeCheckRoute/${inicio}/${fin}`);
  }

  getAllEntriesByDateRangeService(inicio :string, fin :string): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getAllEntriesByDateRangeRoute/${inicio}/${fin}`);
  }

  getAllOutputsByDateRangeService(inicio :string, fin :string): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getAllOutputsByRangeDateRoute/${inicio}/${fin}`);
  }

  deleteTicketCashOutService(id: string): Observable<any> {
    return this.http.delete(`${environment.URLServer}/controlComission/deleteTicketRoute/${id}`);
  }


  getAllEmployeesService(): Observable<any>{
    return this.http.get(`${environment.URLServer}/controlComission/getAllEmployees/`);
  }

}
