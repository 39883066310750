import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { RecessionsService } from 'src/app/services/recessions.service';
import { ProjectService } from 'src/app/services/project.service';
import { GenerateRecessionRequestComponent } from './generate-recession-request/generate-recession-request.component';

import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { DatePipe } from '@angular/common';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-recessions-request',
  templateUrl: './recessions-request.component.html',
  styleUrls: ['./recessions-request.component.scss']
})
export class RecessionsRequestComponent implements OnInit {

  customersGroups: any = ['Sin seleccionar'];
  allrequest: any = [];
  projects: any = [];
  selectedProject: number = 0;
  mode: string = '';
  pipe = new DatePipe('es-MX');

  constructor(
    private recessionService: RecessionsService,
    private projectService: ProjectService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getProjects();
    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
  }

  getProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      }
    );
  }

  getCustomersWithBlock(id: number) {
    this.selectedProject = id;
    this.recessionService.getCustomersWithBlock(id).subscribe(
      res => {
        this.customersGroups = res;
      }
    );
  }

  getPropertiesWithBlockByUser(user: number, project: number, status: string) {
    this.allrequest = [];
    this.mode = status;
    if (status == 'sales') {
      this.recessionService.getPropertiesWithBlockByCustomer(user, project).subscribe(
        res => {
          this.allrequest = res;
          console.log(this.allrequest);
        }
      );
    } else if (status == 'recessions') {
      this.recessionService.getPropertiesWithBlockByCustomerR(user, project).subscribe(
        res => {
          this.allrequest = res;
          console.log(this.allrequest);
        }
      );
    }
  }

  generateRequest(user: number, status: string) {
    let info = {
      "user": user,
      "project": this.selectedProject,
      "type": status
    };
    this.dialog.open(GenerateRecessionRequestComponent, {
      data: info,
      width: "100%",
      height: "70%"
    });
  }

  signed(property: String) {
    Swal.fire({
      title: `¿Quieres solicitar la rescisión para la unidad ${property}?`,
      text: "Se enviarán los datos para que juridico pueda hacer el convenio de rescisión de esta venta, se recomienda ponerse en contacto para ajustar detalles.",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, espera',
      confirmButtonText: 'Sí, solicitala!'
    }).then((result) => {
      if (result.isConfirmed) {

      }
    })
  }

}
