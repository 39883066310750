import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Payment } from '../../../models/Payment';
import { PaymentService } from '../../../services/payment.service';
import { paymentMetode } from '../../../models/paymentMetode';
import { Router } from '@angular/router';
import { ReportService } from '../../../services/report.service';
import { Report } from 'src/app/models/Report';
import { SalesService } from 'src/app/services/sales.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PdfMakerComponent } from 'src/app/utils/pdf-maker/pdf-maker.component';
import { invoiceReportService } from 'src/app/services/invoice.service';

import Swal from 'sweetalert2';
import decode from 'jwt-decode';

import { EditInvoiceMethodComponent } from './edit-invoice-method/edit-invoice-method.component';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';

@Component({
  selector: 'app-status-payments',
  templateUrl: './status-payments.component.html',
  styleUrls: ['./status-payments.component.scss']
})
export class StatusPaymentsComponent implements OnInit {

  codigoInm = ""
  folio: any = ""
  acumulador = 0
  conInmueble: any;

  decode: any = [];
  selectedCustomer: any;

  //Nomenclatura inmueble
  M = "Manzana No."
  L = "Lote No."
  T = "Torre No."
  D = "Departamento No."

  permissions() {
    const token = localStorage.getItem('token') || "";

    let decodetoken: any = {};
    decodetoken = decode(token);

    return decodetoken.permissions;
  }


  //Manejo de fechas
  n = new Date();
  //Año
  y = this.n.getFullYear();
  //Mes
  m = this.n.getMonth() + 1;
  //Día
  d = this.n.getDate();

  h = this.n.toLocaleTimeString()
  salida = ""
  anios() {
    return this.y
  }
  mess() {
    return this.m
  }
  dias() {
    return this.d
  }
  salidaFecha() {
    if (this.m < 9 && this.d < 9) {
      this.salida = this.y + '-' + '0' + this.m + '-' + '0' + this.d + ' ' + this.h;
    } else if (this.d < 9 && this.m > 9) {
      this.salida = this.y + '-' + this.m + '-' + '0' + this.d + ' ' + this.h;
    } else if (this.d > 9 && this.m < 9) {
      this.salida = this.y + '-' + '0' + this.m + '-' + this.d + ' ' + this.h;
    } else {
      this.salida = this.y + '-' + this.m + '-' + this.d + ' ' + this.h;
    }
    return this.salida
  }
  fechaVenta: string = ""
  prueba = ""

  dia: number | any = 0
  mes: number | any = 0
  año: number | any = 0

  //Grafico y control de pagos
  limite = -4
  tolerancia = -1
  tolerancia2 = -2
  tolerancia3 = -3

  numeracion: any = []

  //ConsultaEspecial
  reportes: any = []
  reporte: Report | any;

  //Manejo de residuos
  residuos: any = [];
  residuo: Payment | undefined;

  //Ticket
  ticketget: any | undefined;


  //Manejo tabla Pagos
  carteraInmu: any = [];
  payments: any = [];
  payment: Payment = {
    modificationDate: this.salidaFecha(),
    estadoPago: 'Cancelado',
    reason: ''
  };
  //Bandera tarjeta inserccion consulta
  desCancRecibo = false
  formularioActivo = true
  formularioApagado = false

  cancelarPago(id: any) {
    this.cancelarPagos(id);
    return this.formularioActivo = false, this.formularioApagado = true;
  }
  nuevaCancelacion() {
    return this.formularioActivo = true, this.formularioApagado = false;
  }
  ShowSelected(cod: any) {
    cod = document.getElementById("producto");
    return cod;
  }
  //Manejo de cuentas
  cuentas: any = [];

  //Manejo de contadores
  totales: any = [];
  total: Payment = {
    Abonado: 0
  };
  //Manejo de metodos de pagos
  metode: any = [];

  PaymentMetode: paymentMetode = {
    id: 0,
    formaPago: ''
  };


  //Manejo de ventas
  sales: any = []
  salesCoo: any = []
  payAfter: any = [];
  payBefore: any = [];

  constructor(public modal: NgbModal, public sweet: SweetAlertComponent, private invoiceService: invoiceReportService, private reportServices: ReportService, private reportService: PaymentService, private toastr: ToastrService, private paymentService: PaymentService, private reportServ: ReportService, private router: Router, private salesServices: SalesService, public pdfTicket: PdfMakerComponent, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.decode = decode(localStorage.getItem("token") || "");
    this.saleCode();
    this.salidaFecha();
  }
  //Notas

  habilitarDescripcion() {
    if (this.desCancRecibo == true) {
      return this.desCancRecibo = false
    } else {
      return this.desCancRecibo = true
    }
  }

  showToastr() {
    this.toastr.success('Nota agregada con exito')
  }

  showToastrDan() {
    this.toastr.error('Error: Pago no cancelado')
  }


  slectPayments(cliente: string) {
    this.invoiceService.paymentsInvoices(cliente).subscribe(
      res => {
        this.selectedCustomer = cliente;
        this.payments = res;
        this.valuesFromInvoice()
      },
      err => console.log(err)
    );
  }

  async setWaiting(idf: string, idp: number, state: number) {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'Escribe algunos detalles relacionados al motivo de esta acción',
      inputLabel: 'Motivo de la cancelación de este movimiento',
      html: "<b>¿Está seguro de querer deshacer el marcado como realizado?</b><br>" +
        "Este movimiento marcará como pendiente esta factura <br>" +
        "Usuario que solicita la cancelación: <b>" + this.decode.cliente + "</b><br>" +
        "Para continuar con la cancelación, escriba los detalles y presione 'Continuar'",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      customClass: {
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn",
        actions: "sweetActions"
      },
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === '') {
            resolve('Escribe los detalles para continuar')
          } else {
            resolve('');
          }
        })
      },
      showCancelButton: true
    })
    if (text) {
      this.invoiceService.setInvoiced(idp, state).subscribe(
        res => {
          const log = {
            "action": 6,
            "notes": text,
            "payment": idp,
            "responsable": this.decode.fkuser
          };

          this.paymentService.writePaymentLog(log).subscribe(
            res => {
              this.sweet.AlertTime("center", "success", "Pago desmarcado como facturado", false, 2000);
              this.invoiceService.setInvoiceWaiting(idf).subscribe(res => {
                this.slectPayments(this.selectedCustomer);
              });

            }
          );
        }
      );
    } else {
      this.sweet.AlertTime("center", "error", "Por favor capture un motivo para realizar esta acción", false, 2000);
    }
  }

  valuesFromInvoice() {
    this.payAfter = []
    this.payBefore = []

    let valorTotal = this.carteraInmu[0].valor;

    for (let i of this.payments) {
      this.payAfter.push(valorTotal);
      valorTotal = valorTotal - i.cantidadAbono;
      this.payBefore.push(valorTotal);
    }
  }

  reporteInmuCartera(carInmu: string, cad: any) {
    this.reportServices.reporteCarteraInmueble(carInmu).subscribe(
      res => {
        this.carteraInmu = res;
        console.log("Aqui", this.carteraInmu);
        this.slectPayments(cad.substr(0, 8));
      },
      err => console.log(err)
    );
  }

  async selectSalesCoo(codInmuebleCoo: any) {
    this.salesServices.selectSalesCoo(codInmuebleCoo).subscribe(
      res => {
        this.salesCoo = res;
      },
      err => console.log(err)
    );
  }

  sumTotales(code: string) {
    this.reportService.sumTotal(code).subscribe(
      data => {
        this.totales = data;
      },
      err => console.log(err)
    );

  }

  cancelPayment(id: any) {
    delete this.payment.id;
    delete this.payment.fechaPago
    delete this.payment.estadoPago
    this.reportService.updatePaymentCanceled(id, this.payment)
      .subscribe(
        res => {
          this.showToastr()
          this.router.navigate(['payments'])
        },
        err => this.showToastrDan()
      );
  }

  deletePayment(id: any) {
    this.reportService.deletePayment(id).subscribe(data => {
      this.sumTotales(this.codigoInm.substr(0, 8));
      this.slectPayments(this.codigoInm.substr(0, 8));
    }, error => {
      console.log(error);
      this.router.navigate(['payments'])
    })
  }
  //Autocompletado
  keyword = 'name';

  sal: any = [];
  folioApr: any = [];
  folioCa: any = [];

  cadenaInmueble: string = "";
  //CAMBIAR POR OTRO
  saleCode() {
    this.invoiceService.invoiceReportList().subscribe(
      res => {

        this.sal = res;
      },
      err => console.log(err)
    );
  }

  razon = "";

  cancelarPagos(id: any) {

    this.paymentService.updatePaymentCanceled(id, this.payment).subscribe(data => {

      this.showToastr();

      this.sumTotales(this.codigoInm.substr(0, 8));
      this.slectPayments(this.codigoInm.substr(0, 8));
      this.selectSalesCoo(this.codigoInm.substr(0, 8));

      this.payment.estadoPago = "Cancelado";

      this.payment.modificationDate = this.salidaFecha();
      this.payment.reason = this.razon;

    }, error => {
      this.showToastrDan()
      this.router.navigate(['report'])
    })
  }


  onKeypressEventSale(eventInm: any) {
    this.conInmueble = eventInm

    let cadInm: any = {};
    cadInm = this.conInmueble.name;

    if (cadInm == ' ' || cadInm == null || cadInm == "") {
      console.log("Se requiere consultar inmueble")
    } else {
      this.codigoInm = cadInm.substr(0, 8); //Pasar valor a variable
      this.payAfter = []
      this.payBefore = []
    }
  }

  onKeypressEvent(event: any) {
    this.payAfter = []
    this.payBefore = []
    this.conInmueble = event

    let cad: any = {};
    cad = this.conInmueble.name;

    if (event == ' ' || event == null || event == "") {
      console.log("Se requiere consultar cliente")
    } else {
      this.codigoInm = cad.substr(0, 8);
      this.reporteInmuCartera(cad.substr(0, 8), cad.substr(0, 8));
      this.sumTotales(cad.substr(0, 8));
      this.selectSalesCoo(cad.substr(0, 8));

    }

  }

  rePrintTicket(ticket: any) {
    console.log("ticket: ", ticket)

    this.paymentService.getTicket(ticket).subscribe(data => {
      this.ticketget = data;
      console.log("pago obtenido", data)
      this.paymentService.calculateRest(this.ticketget.Inmueble, this.ticketget.Fecha).subscribe(
        res => {
          this.paymentService.getTotalsNCPayments(this.ticketget.Inmueble).subscribe(
            res2 => {
              this.pdfTicket.ticketGenerator(
                this.ticketget.Fecha,
                this.ticketget.Venderdor,
                ticket,
                this.ticketget.Inmueble.substring(2, 3),
                this.ticketget.Inmueble.substring(5, 6),
                this.ticketget.Inmueble.substring(3, 5),
                this.ticketget.Inmueble.substring(6, 8),
                this.ticketget.Concepto,
                this.ticketget.Formadepago,
                this.ticketget.Valortotalinmueble,
                this.ticketget.Abono,
                //this.ticketget.Restante,
                res[0].restante,
                res[0].pagosRealizados, //Pagos realizados
                this.ticketget.Mensualidadesapagar,
                this.ticketget.Inmueble.substring(0, 2),
                this.ticketget.Cliente,
                this.ticketget.Responsable,
                res2.NC,
                res2.NCNR,
                this.ticketget.Valortotalinmueble - res[0].restante,
                (res[0].desfase > 0 ? (res[0].desfase + ' pendiente(s)') : 'Al corriente'),
                res[0].proxPay
              );
            }
          );
        }
      )
    }
    )
  }

  editInvoiceMethod(type: number, fiscalFolio: string, property: string) {
    let data = { "fkType": type, "fiscalFolio": fiscalFolio, "property": property };
    let edit = this.dialog.open(EditInvoiceMethodComponent, {
      data: data,
      width: "40%"
    });

    edit.afterClosed().subscribe(
      res => {
        this.reportServices.reporteCarteraInmueble(property).subscribe(
          res => {
            this.carteraInmu = res;
          },
          err => console.log(err)
        );
      }
    );
  }

}
