<div class="container-fluid mt-3">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">

            <mat-form-field appearance="outline" class="filter">
                <mat-label>Buscar inmueble</mat-label>
                <input matInput type="text" placeholder="Ingresar el código de inmueble rescindido"
                    [(ngModel)]="filterRecessions" name="filterRecessions">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>

            <table class="table table-striped mt-3 animated fadeIn faster">
                <thead class="thead-m19">
                    <tr>
                        <th scope="col">Inmueble</th>
                        <th scope="col">Cliente</th>
                        <th scope="col">Coordinador</th>
                        <th scope="col">Fecha de rescisión</th>
                        <th scope="col">Razón</th>
                        <th scope="col">Abonado</th>
                        <th scope="col">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let recession of recessions | filterRecessions: filterRecessions">
                        <td>{{recession.fk_property}}</td>
                        <td>{{recession.customer}}</td>
                        <td>{{recession.seller}}</td>
                        <td>{{recession.dateRecession | date:'dd-MMM-yyyy'}}</td>
                        <td>{{recession.reasonRecession}}</td>
                        <td>$ {{recession.abonado}}</td>
                        <td class="button-row">
                            <button class="btn btn-m19-table" mat-icon-button data-title="Ver detalles"
                                (click)="selectRecession(recession.idRecession)">
                                <mat-icon>visibility</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </mat-card-content>
    </mat-card>
</div>