<mat-dialog-content>
    <h1 style="text-align:center">Solicitud de venta</h1>

    <mat-accordion>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span>
                        <b>
                            Solicitud
                        </b>
                    </span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div id="htmlData" class="container">
                <div class="row">
                    <div>

                        <img style=" display: block; margin-left: auto;   margin-right: auto;  width: 10%;"
                            class="center" alt="Logo" [src]="logo">

                        <div style="width: 100%; border: solid 1px black; text-align: center; font-size: 0.8rem;">
                            SOLICITUD DE ELABORACION DE CONTRATO
                        </div>

                        <br>

                        <div style="display: flex; justify-content: space-between; height: 15px; font-size: 0.8rem;">
                            <div style="width: 200px; border: solid black 1px; vertical-align: center;">
                                FECHA: {{sale.registrationDate | date: 'dd-MM-yyyy'}}
                            </div>
                            <p style="margin-top: -2px;">
                                Por conducto de representante
                            </p>
                            <div style="width: 100px; border: solid black 1px; ">
                                SI
                            </div>
                            <div style="width: 100px; border: solid black 1px; ">
                                NO
                            </div>
                        </div>

                        <br>

                        <table
                            style="border: 1px solid black; border-collapse: collapse; width: 100%; font-size: 0.8rem;">
                            <tr style="text-align: center;">
                                <td colspan="2">DATOS DEL INMUEBLE</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black; border-collapse: collapse;">DESARROLLO</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">{{propertyInfo.name}}
                                </td>
                            </tr>

                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">MANZANA</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{propertyInfo.codeProperty.substring(3, 5)}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black; border-collapse: collapse;">LOTE</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{propertyInfo.codeProperty.substring(6, 8)}}</td>
                            </tr>
                        </table>

                        <br>

                        <table
                            style="border: 1px solid black; border-collapse: collapse; width: 100%; font-size: 0.8rem;">
                            <tr style="text-align: center;">
                                <td colspan="4">DATOS DEL CLIENTE</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black; border-collapse: collapse;">NOMBRE COMPLETO</td>
                                <td colspan="3" style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.complete_name}}
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">NACIONALIDAD</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.nationality}}</td>
                                <td style="border: 1px solid black; border-collapse: collapse;">ESTADO CIVIL</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.civilStatus}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">FECHA DE NACIMIENTO
                                </td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.birthDate}}</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">OCUPACIÓN
                                </td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.occupation}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black; border-collapse: collapse;">LUGAR DE NACIMIENTO</td>
                                <td colspan="3" style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.birthPlace}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">DOMICILIO</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.address}}</td>
                                <td style="border: 1px solid black; border-collapse: collapse;">C.P.</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.postalCode}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">COLONIA</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.localidad}}</td>
                                <td style="border: 1px solid black; border-collapse: collapse;">ESTADO</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">{{CustomerInfo.state}}
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">MUNICIPIO</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.municipality}}</td>
                                <td style="border: 1px solid black; border-collapse: collapse;">CELULAR</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">{{CustomerInfo.mobile}}
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">CLAVE DE ELECTOR</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.voterKey}}</td>
                                <td style="border: 1px solid black; border-collapse: collapse;">CURP</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">{{CustomerInfo.CURP}}
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">CORREO</td>
                                <td colspan="3" style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.email}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">RFC</td>
                                <td colspan="3" style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.RFC}}
                                </td>
                            </tr>
                        </table>

                        <br>

                        <table
                            style="border: 1px solid black; border-collapse: collapse; width: 100%; font-size: 0.8rem;">
                            <tr style="text-align: center;">
                                <td colspan="4" style="border: 1px solid black; border-collapse: collapse;">DATOS DEL
                                    CONTRATO</td>
                            </tr>
                            <tr style="text-align: center;">
                                <td colspan="4" style="border: 1px solid black; border-collapse: collapse;">IMPORTES
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black; border-collapse: collapse;">INMUEBLE</td>
                                <td colspan="1" style="border: 1px solid black;  border-collapse: collapse;">
                                    {{propertyInfo.codeProperty}}</td>
                                <td style="border: 1px solid black; border-collapse: collapse;">SUPERFICIE</td>
                                <td colspan="1" style="border: 1px solid black;  border-collapse: collapse;">
                                    {{propertyInfo.area}}m2</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">IMPORTE TOTAL</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{propertyInfo.amount.toFixed(0)}}
                                </td>
                                <td style="border: 1px solid black; border-collapse: collapse;">FECHA</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">{{sale.registrationDate
                                    | date: 'dd-MM-yyyy'}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">ANTICIPO</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{propertyInfo.advancePayment}}</td>
                                <td style="border: 1px solid black; border-collapse: collapse;">REQUIERE FACTURA</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{CustomerInfo.billing==0?"NO":CustomerInfo.billing==1?"SI":"NA"}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">MENSUALIDADES</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{propertyInfo.monthlyPayment}}</td>
                                <td style="border: 1px solid black; border-collapse: collapse;">A PARTIR DE LA FECHA
                                </td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{sale.firstPayDate | date: "dd-MM-yyyy"}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">ANUALIDAD</td>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{propertyInfo.annualityPayment}}</td>
                            </tr>
                        </table>

                        <br>

                        <table
                            style="border: 1px solid black; border-collapse: collapse; width: 100%; font-size: 0.8rem;">
                            <tr style="text-align: center;">
                                <td style="border: 1px solid black; border-collapse: collapse;" colspan="2">COLINDANCIAS
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black; border-collapse: collapse;">
                                    {{propertyInfo.northeasBorder}}</td>
                                <td style="width: 25%" rowspan="4">
                                    <img style=" display: block; width: 100%;" alt="Logo"
                                        src="{{propertyInfo.sketchMap}}">
                                </td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black;  border-collapse: collapse;">
                                    {{propertyInfo.northwestBorder}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black; border-collapse: collapse;">
                                    {{propertyInfo.southeastBorder}}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid black; border-collapse: collapse;">
                                    {{propertyInfo.southwestBorder}}</td>
                            </tr>
                        </table>
                        <div style="border: solid 1px black;">
                            <p style="font-size: 0.8rem;">A PARTIR DE LA FIRMA DE ESTA SOLICITUD SE DA POR ENTERADO QUE LOS DATOS QUE AQUI SE
                                PLASMAN SON CORRECTOS. EN CASO DE CANCELAR SU PARTICIPACION EN EL PROYECTO ANTES DE
                                HABER REALIZADO LA FIRMA DE SU CONTRATO EL VALOR ENTREGADO COMO ENGANCHE NO SERÁ REEMBOLSADO.</p>
                        </div>
                        <div style="display: flex; width: 100%; padding-top: 40px;">
                            <p style="width: 50%; text-align: center;">{{CustomerInfo.complete_name}}<br><b>Cliente</b>
                            </p>
                            <p style="width: 50%; text-align: center;">{{sale.name + ' ' + sale.firstLastName + ' ' +
                                sale.secondLastName}}<br><b>Vendedor</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <br>

    <div style="display: flex; justify-content: center;">
        <button (click)="print()">DESCARGAR SOLICITUD</button>
    </div>