import { Component, OnInit } from '@angular/core';

//=========================================================================================================
//Importación de servicios necesarios para esta página
//=========================================================================================================
import { BudgetService } from 'src/app/services/budget.service';
import { ProjectService } from 'src/app/services/project.service';

//=========================================================================================================
//Importación de librerías para componentes visuales
//=========================================================================================================
import { BudgetupdateconsultComponent } from './budgetupdateconsult/budgetupdateconsult.component';
import { BudgetdialogComponent } from './budgetdialog/budgetdialog.component';
import { MatDialog } from '@angular/material/dialog';
import { InfbudgetpieceComponent } from '../../budget_piecework/budgetconsultpiece/infbudgetpiece/infbudgetpiece.component';
import { BudgetupdatepieceComponent } from '../../budget_piecework/budgetconsultpiece/budgetupdatepiece/budgetupdatepiece.component';
import { BudgetPieceworkService } from 'src/app/services/budget-piecework.service';

import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import Swal from 'sweetalert2';

@Component({
  selector: 'app-budgetconsult',
  templateUrl: './budgetconsult.component.html',
  styleUrls: ['./budgetconsult.component.scss']
})

export class BudgetconsultComponent implements OnInit {
  materialsBanner: BannerTitle = {
    title: "Presupuestos de materiales",
    icon: "account_balance_wallet",
    position: "center",
    background: "var(--M19Secundary)"
  }

  pieceworkBanner: BannerTitle = {
    title: "Presupuestos de destajos",
    icon: "account_balance_wallet",
    position: "center",
    background: "var(--M19Secundary)"
  }

  //Variable para almacenar el presupuesto
  budgets: any = 'vacio';

  //Variable para almacenar el presupuesto
  pbudgets: any = [];

  //Variable para almacenar las partidas
  departures: any = [];

  //Variable que almacena los proyectos
  projects: any = [];

  constructor(
    //===================================================
    //Declaración de servicios
    public budgetService: BudgetService,
    public dialog: MatDialog,
    public projectService: ProjectService,
    public budgetpieceworkService: BudgetPieceworkService,

  ) { }

  ngOnInit(): void {
    this.getProjects();
  }


  //=========================================================================================================
  //Función para obtener todos los presupuestos de la base de datos
  //=========================================================================================================
  async getAll() {
    this.budgetService.selectBudget().subscribe(
      res => {
        this.budgets = res;
      },
      err => console.log(err)
    )
  }

  //=========================================================================================================
  //Función que llama un cuadro de dialogo de confirmación para borrar presupuesto por su ID
  //=========================================================================================================
  async deleteBudget(id: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, mejor no',
      confirmButtonText: 'Sí, elimínalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'Este presupuesto fue eliminado.',
          'success'
        )
        this.budgetService.deleteBudget(id).subscribe(
          res => {
            this.getAll();
          }
        )
      }
    })
  }

  //=========================================================================================================
  //Función para elegir las partidas de un presupuesto por su ID
  //=========================================================================================================
  async selectBudgetUnique(id: number) {
    const dialogo = this.dialog.open(BudgetdialogComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '100%',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: id
    });

    dialogo.afterClosed().subscribe(result => {
      this.ngOnInit();
    })
  }

  //=========================================================================================================
  //Función para abrir el dialogo para actualizar el presupuesto
  //=========================================================================================================
  async updateBudget(idb: any, idp: any, idP: any) {
    let info = { 'idbudget': idb, 'idproject': idp, 'idProject': idP };
    let dialogRef = this.dialog.open(BudgetupdateconsultComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '100%',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: info
    });

    dialogRef.afterClosed().subscribe(
      res => {
        this.getAll();
      }
    );
  }

  //=========================================================================================================
  //Función para seleccionar los proyectos
  //=========================================================================================================
  async getProjects(){
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      }
    );
  }

  //=========================================================================================================
  //Función para filtrar los presupuestos por proyecto
  //=========================================================================================================
  async filterByProject(id:number) {
    this.budgetService.selectBudgetByProject(id).subscribe(
      res => {
        this.budgets = res;
      }
    );
  }

  //=========================================================================================================
  //                                PRESUPUESTO DE DESTAJOS
  //=========================================================================================================

  //=========================================================================================================
   //Función para obtener todos los presupuestos de la base de datos
   //=========================================================================================================
   async pgetAll() {
    this.budgetpieceworkService.selectBudgetsP().subscribe(
      res => {
        this.pbudgets = res;
      },
      err => console.log(err)
    )
  }
  //=========================================================================================================
  //Función que llama un cuadro de dialogo de confirmación para borrar presupuesto por su ID
  //=========================================================================================================
  async pdeleteBudget(id: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, mejor no',
      confirmButtonText: 'Sí, elimínalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'Este presupuesto fue eliminado.',
          'success'
        )
        this.budgetpieceworkService.deleteBudget(id).subscribe(
          res => {
            this.pgetAll();
          }
        )
      }
    })
  }

  //=========================================================================================================
  //Función para elegir las partidas de un presupuesto por su ID
  //=========================================================================================================
  async pselectBudgetUnique(id: number) {
    const dialogo = this.dialog.open(InfbudgetpieceComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '100%',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: id,
    });

    dialogo.afterClosed().subscribe(result => {
      this.ngOnInit()
    })
  }


  /*//=========================================================================================================
  //Función para abrir el dialogo para actualizar el presupuesto
  //=========================================================================================================
  async updateBudget(idb: any, idp: any, idP: any) {
    let info = { 'idbudget': idb, 'idproject': idp, 'idProject': idP };
    let dialogRef = this.dialog.open(BudgetupdateconsultComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '100%',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: info
    });

    dialogRef.afterClosed().subscribe(
      res => {
        this.getAll();
      }
    );
  }*/

  //=========================================================================================================
   //Función para filtrar los presupuestos por proyecto
   //=========================================================================================================
   async pfilterByProject(id:number) {
    this.budgetpieceworkService.selectBudgetByProject(id).subscribe(
      res => {
        this.pbudgets = res;
      }
    );
  }

  //=========================================================================================================
  //Función para abrir el dialogo para actualizar el presupuesto
  //=========================================================================================================
  async updateBudgetPiece(idb: any, idp: any, idP: any) {
    let info = { 'idbudget': idb, 'idproject': idp, 'idProject': idP };
    let dialogRef = this.dialog.open(BudgetupdatepieceComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '100%',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: info
    });

    dialogRef.afterClosed().subscribe(
      res => {
        this.pgetAll();
      }
    );
  }
}