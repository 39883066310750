import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { invoiceReport } from '../models/invoiceReport';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class invoiceReportService {

  constructor(private http: HttpClient) { }

  createInvoiceReport(invRep: invoiceReport): Observable<any> {
    return this.http.post(`${environment.URLServer}/invoiceReport/`, invRep);
  }

  invoiceReport(): Observable<any> {
    return this.http.get(`${environment.URLServer}/invoiceReport/invRe`);
  }

  getPendingInvoice(): Observable<any> {
    return this.http.get(`${environment.URLServer}/invoiceReport/getPendingInvoice`);
  }

  invoiceReportList(): Observable<any> {
    return this.http.get(`${environment.URLServer}/invoiceReport/List`);
  }

  updateInvoiceCanceled(id: string, update: invoiceReport): Observable<invoiceReport> {
    return this.http.put(`${environment.URLServer}/invoiceReport/${id}`, update);
  }

  selectComple(invInm: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/invoiceReport/complemento/${invInm}`);
  }

  selectGlo(invInmGl: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/invoiceReport/global/${invInmGl}`);
  }

  selectID(invInmID: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/invoiceReport/resID/${invInmID}`);
  }

  conCo(InmCon: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/invoiceReport/conCom/${InmCon}`);
  }

  paymentsInvoices(pay: string): Observable<any> {
    return this.http.get(`${environment.URLServer}/invoiceReport/payInv/${pay}`);
  }

  setInvoiceWaiting(id: string): Observable<any> {
    return this.http.put(`${environment.URLServer}/invoiceReport/setInvoiceWaiting/${id}`, []);
  }

  getInvoiceMethods(): Observable<any> {
    return this.http.get(`${environment.URLServer}/invoiceReport/getAllInvoiceMethods`);
  }

  updateInvoideMethod(type: number, fiscalFolio: string, property: String): Observable<any> {
    return this.http.put(`${environment.URLServer}/invoiceReport/updateInvoiceMethod/${property}`, [type, fiscalFolio]);
  }

  getInvoiceSlopeRANGE(consulting: any): Observable<any> {
    return this.http.post(`${environment.URLServer}/invoiceReport/getInvoiceSlopeRANGE`, consulting);
  }

  getInvoiceData(consulting: any): Observable<any> {
    return this.http.post(`${environment.URLServer}/invoiceReport/getInvoiceData`, consulting);
  }

  setInvoiced(idp: number, state: number): Observable<any> {
    return this.http.put(`${environment.URLServer}/invoiceReport/setPayInvoiced/${idp}/${state}`, []);
  }

  getInvoiceState(): Observable<any> {
    return this.http.get(`${environment.URLServer}/invoiceReport/getPaymentStates`);
  }

  getInvoiceList(state: any): Observable<any> {
    return this.http.get(`${environment.URLServer}/invoiceReport/getInvoiceList/${state}`);
  }
  
  getInvoiceRevList(): Observable<any> {
    return this.http.get(`${environment.URLServer}/invoiceReport/getInvoiceRevList`);
  }

  getCustomerToInvoice(): Observable<any> {
    return this.http.get(`${environment.URLServer}/invoiceReport/getCustomersToInvoice`);
  }

  getPaymentsToInvoice(code: String): Observable<any> {
    return this.http.get(`${environment.URLServer}/invoiceReport/getPaymentsToInvoice/${code}`);
  }
}