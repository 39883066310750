<mat-card>
    <mat-card-content>
        <h5>Comentario de prospecto</h5>
        <form [formGroup]="commentsFormGroup" style="width: 100%;"
            onKeyPress="if(event.keyCode == 13) event.returnValue = false;">
            <mat-form-field style="width: 100%;">
                <mat-label>Dejar un comentario...</mat-label>
                <textarea matInput placeholder="Ej. No respondió..." rows="3" formControlName="comments"></textarea>
            </mat-form-field>
            <button class="m19Btn" (click)="writeComment()" [disabled]="!commentsFormGroup.valid">Comentar</button>
        </form>
    </mat-card-content>
</mat-card>