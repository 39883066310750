import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
import { AuthService } from 'src/app/services/auth.service';
import decode from 'jwt-decode'
import { SweetAlertComponent } from '../../utils/sweet-alert/sweet-alert.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginFormGroup: FormGroup = this._formBuilder.group({
    email: ['', Validators.required],
    password: ['', Validators.required]
  }
  )

  shwPwd = false;
  setShowPsd :boolean

  constructor(
    private authService: AuthService,
    private router: Router,
    public sweet: SweetAlertComponent,
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

  changeStatus(event : boolean){
    this.shwPwd  = event
  }

  async logIn() {

    let user = {
      email: this.loginFormGroup.get('email')?.value,
      password: this.loginFormGroup.get('password')?.value,
    }

    let decodetoken: any = {};


    this.authService.singin(user).subscribe((res: any) => {

      if(res == "Usuario o clave incorrectos"){
        localStorage.removeItem("token")
        this.sweet.AlertTime('center', 'error', 'Usuario o contraseña invalida, por favor verifique su información', false, 3000)
      }else{
        localStorage.setItem('token', res.token);
        decodetoken = decode(res.token);
      }

      //cuando inicie sesion un cliente
      if ((decodetoken.permissions + "").match("3")) {
        this.router.navigate(['account']);
        //this.sweet.AlertIcon('success','Inicio de sesion exitoso','Bienvenido a tu portal de M19 Inmobiliaria','')
        this.sweet.AlertTime('center', 'success', 'Bienvenido a tu portal de M19 Inmobiliaria', false, 2000)
      }

      //cuando inicie sesion un coordinador
      if ((decodetoken.permissions + "").match("coordStatus")) {
        this.router.navigate(['coordStatus']);
        this.sweet.AlertIcon('success','Inicio de sesion exitoso','Bienvenido a tu portal de M19 Inmobiliaria','')
        //this.sweet.AlertTime('center', 'success', 'Bienvenido a tu portal de M19 Inmobiliaria', false, 2000)
      }

      //cuando inicie sesion un coordinador
      if ((decodetoken.permissions + "").match("coordStatus")) {
        this.router.navigate(['coordStatus']);
        this.sweet.AlertIcon('success','Inicio de sesion exitoso','Bienvenido a tu portal de M19 Inmobiliaria','')
        //this.sweet.AlertTime('center', 'success', 'Bienvenido a tu portal de M19 Inmobiliaria', false, 2000)
      }


      //Cuando inicia sesion un desarrollador
      else if (decodetoken.permissions.match("446576656c6f706572")) {
        this.router.navigate(['home']);
        //this.sweet.AlertIcon('success', 'Inicio de sesion exitoso', 'Bienvenido desarrollador: ' + decodetoken.cliente, '')
        this.sweet.AlertTime('center', 'success', 'Bienvenido desarrollador: ' + decodetoken.cliente, false, 2000)
      }

      else if (decodetoken.permissions.match("contador")) {
        this.router.navigate(['home']);
        //this.sweet.AlertIcon('success', 'Inicio de sesion exitoso', 'Bienvenido desarrollador: ' + decodetoken.cliente, '')
        this.sweet.AlertTime('center', 'success', 'Bienvenido CONTADOR: ' + decodetoken.cliente, false, 2000)
      }

      else {
        this.router.navigate(['home']);
        //this.sweet.AlertIcon('success', 'Inicio de sesion exitoso', 'Bienvenido: ' + decodetoken.cliente, '')
        console.log(decodetoken.permissions)
        this.sweet.AlertTime('center', 'success', 'Inicio de sesion exitoso: ' + decodetoken.cliente, false, 2000)
      }

    })
  }

}

