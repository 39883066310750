import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapsService } from 'src/app/services/maps.service';
import { Pointofmap } from '../pointofmap';

@Component({
  selector: 'app-dialog-maps-not-register',
  templateUrl: './dialog-maps-not-register.component.html',
  styleUrls: ['./dialog-maps-not-register.component.scss']
})
export class DialogMapsNotRegisterComponent implements OnInit {
  public projects: any = []
  public nameProject: any = ""
  inmueble = {codeProperty: ""};
  linkMap = "";
  public pointes: Array<Pointofmap> = [];

  
  constructor(   
    private dialogRef: MatDialogRef<DialogMapsNotRegisterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_DIALOG_DATA) public pointe: Array<Pointofmap>,
    private getProjects: MapsService

) { 
  dialogRef.disableClose = true;
}

  ngOnInit(): void {
    this.getProject();
  }

  getProject() {
    this.getProjects.selectNotRegister(localStorage.getItem('selectednomenclature')||"").subscribe(
      res => {
        this.projects = res;
      },
      err => console.log(err)
    )
  }

  setProject() {
    localStorage.setItem('inmueble', this.inmueble.codeProperty);
  }


}
