import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TypeUserService } from '../../../services/type-user.service';
import { SweetAlertComponent } from '../../../utils/sweet-alert/sweet-alert.component';

@Component({
  selector: 'app-type-user',
  templateUrl: './type-user.component.html',
  styleUrls: ['./type-user.component.scss']
})
export class TypeUserComponent implements OnInit {

  type_user: any = [];

  typeUserFormGroup: FormGroup = this._formBuilder.group({
    idTypeUser: [],
    description: ['', Validators.required]
  })

  constructor(
    private _formBuilder: FormBuilder,
    private typeUserService: TypeUserService,
    public sweet: SweetAlertComponent,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.selectTypeUsers();
  }

  selectTypeUsers() {
      this.typeUserService.selectTypeUser().subscribe(
        res => {
          this.type_user = res;
        },
        err => console.log(err)
      );
  }

  createTypeUser() {
    if (this.typeUserFormGroup.invalid) {
      return;
    }
    else {
      this.typeUserService.createTypeUser(this.typeUserFormGroup.value)
        .subscribe(
          res => {
            console.log(res);
            this.sweet.AlertTime("center", "success", "Tipo de usuario registrado con éxito", false, 2000);
            this.selectTypeUsers();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['setting']));
          },
          err => {
            console.error(err);
            this.sweet.AlertTime("center", "error", "Tipo de usuario no registrado", false, 2000);
          }
        );
    }
  }

  deleteTypeUser(id: number){
    this.typeUserService.deleteTypeUser(id).subscribe(
      res => {
        console.log(res);
        this.sweet.AlertTime("center", "success", "Tipo de usuario eliminado con éxito", false, 2000);
        this.selectTypeUsers();
      },
      err => {
        console.error(err);
        this.sweet.AlertTime("center", "error", "No se puede eliminar el tipo de usuario", false, 2000);
      }
    )
  }

}
