//=========================================================================================================
//Importación de componentes necesarios para esta pantalla
//=========================================================================================================
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormBuilder, FormGroup } from '@angular/forms';

import { invoiceReportService } from 'src/app/services/invoice.service';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-invoice-method',
  templateUrl: './edit-invoice-method.component.html',
  styleUrls: ['./edit-invoice-method.component.scss']
})
export class EditInvoiceMethodComponent implements OnInit {

  methods: any = [];

  type: number = 0;

  constructor(
    private dialogRef: MatDialogRef<EditInvoiceMethodComponent>,
    private _formBuilder: FormBuilder,
    private invoiceService: invoiceReportService,
    public sweet: SweetAlertComponent,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  //==========================================================================================================
  //Objeto constructor del formulario utilizado en la pantalla de requisición, se agrega el código 
  //", Validators.required" para señalar que es un valor que no debe estar vacío
  //==========================================================================================================
  methodFormGroup: FormGroup = this._formBuilder.group({
    fk_invoiceType: [],
    fiscalFolio: []
  });

  ngOnInit(): void {
    this.getAllMethods();
    this.methodFormGroup.controls['fk_invoiceType'].setValue(this.data.fkType);
    this.methodFormGroup.controls['fiscalFolio'].setValue(this.data.fiscalFolio);
    this.type = this.data.fkType;
  }

  getAllMethods() {
    this.invoiceService.getInvoiceMethods().subscribe(
      res => {
        this.methods = res;
      }
    );
  }

  checkMethod(value: number) {
    this.type = value;
    if (value != 4 && this.data.fiscalFolio != null) {
      this.methodFormGroup.controls["fiscalFolio"].setValue("");
    } else {
      this.methodFormGroup.controls["fiscalFolio"].setValue(this.data.fiscalFolio);
    }
  }

  setMethod() {
    if (this.methodFormGroup.controls['fk_invoiceType'].value === null || this.methodFormGroup.controls['fk_invoiceType'].value === 0) {
      this.sweet.AlertTime("center", "error", "Por favor seleccione una opción para el método de facturación.", false, 2000);
    } else {
      Swal.fire({
        title: '¿Quieres guardar tus cambios ahora?',
        text: "¡Se asignará el método de facturación elegido a esteinmueble!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No, espera',
        confirmButtonText: 'Sí, guárdalo!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.invoiceService.updateInvoideMethod(this.methodFormGroup.controls['fk_invoiceType'].value, this.methodFormGroup.controls['fiscalFolio'].value, this.data.property).subscribe(
            res => {
              this.sweet.AlertTime("center", "success", "Método asignado a esta propiedad exitosamente.", false, 2000);
              this.dialogRef.close();
            }
          );
        }
      })
    }
  }
}