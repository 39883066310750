import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SaleService } from 'src/app/services/sale.service';
import { ProjectService } from 'src/app/services/project.service';
import { DetailRecessionsComponent } from './detail-recessions/detail-recessions.component';

@Component({
  selector: 'app-recession',
  templateUrl: './recession.component.html',
  styleUrls: ['./recession.component.scss']
})
export class RecessionComponent implements OnInit {

  filterRecessions = '';

  projects: any = [];
  recessions: any =  ['Sin seleccionar'];

  constructor(
    private projectService: ProjectService,
    private saleService: SaleService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getProjects();
  }

  async getProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      }
    );
  }
  
  async getAllRecessions() {
    this.saleService.selectAllRecessions().subscribe(
      res => {
        this.recessions = res;
        this.filterRecessions = '';
      }
    );
  }

  async filterByProject(project:Number) {
    this.saleService.getRecessionByProjects(project).subscribe(
      res => {
        this.recessions = res;
        this.filterRecessions = '';
      }
    );
  }

  async selectRecession(property: String, customer: Number) {
    this.dialog.open(DetailRecessionsComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '1800px',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: {"property": property, "customer": customer}
    });
  }

}
