<div class="invContainer">
    <mat-card class="card-form" style="min-height: 90vh;">
        <mat-card-content class="justify-align-center">

            <div class="searchBar">
                <div class="ng-autocomplete">
                    <ng-autocomplete name="busqueda" placeholder="Inmueble/Nombre cliente" [data]="sal"
                        [searchKeyword]="keyword" (selected)="onKeypressEvent($event)" [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
                    <ng-template #itemTemplate let-item>
                        <div class="customer-item">
                            <div class="image-container">
                              <img [src]="item.logo" alt="projectImage">
                            </div>
                  
                            <div class="details-container">
                              <div class="name" [innerHTML]="item.name"></div>
                              <div class="status"
                                [ngStyle]="{'color': item.status=='ACTIVO'? 'VAR(--primaryGreen)' : 'VAR(--primaryRed)'}">
                                <b>{{item.status}}</b>
                              </div>
                            </div>
                          </div>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>
                </div>
            </div>

            <p *ngIf="carteraInmu.length==0" style="text-align: center; margin-top: 20px;"><img class="isotipoLogo"
                    src="assets/isotipo.png" alt="M19 Isotipo"></p>

            <div *ngIf="carteraInmu.length>0">
                <div *ngFor="let venta of carteraInmu">
                    <div class="dataRow">
                        <div class="customerData">
                            <mat-icon class="iconData">apartment</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>{{venta.name}}</b></span>
                                <span
                                    style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.codeProperty}}</b></span>
                            </div>
                        </div>
                        <div class="customerData">
                            <mat-icon class="iconData">paid</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>Valor Total</b></span>
                                <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.valor |
                                        currency:'CAD' : '$ '}}</b></span>
                            </div>
                        </div>
                    </div>

                    <div class="dataRow">
                        <div class="customerData">
                            <mat-icon class="iconData">person</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>Cliente</b></span>
                                <span
                                    style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.clientes}}</b></span>
                            </div>
                        </div>
                        <div class="customerData">
                            <mat-icon class="iconData">alternate_email</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>Correo</b></span>
                                <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.email}}</b></span>
                            </div>
                        </div>
                    </div>

                    <div class="dataRow">
                        <div class="customerData">
                            <mat-icon class="iconData">call</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>Teléfono</b></span>
                                <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.mobile==null?"Sin
                                        especificar":venta.mobile}}</b></span>
                            </div>
                        </div>
                        <div class="customerData">
                            <mat-icon class="iconData">markunread_mailbox</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>Código Postal</b></span>
                                <span
                                    style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.fiscalpc}}</b></span>
                            </div>
                        </div>
                    </div>

                    <div class="dataRow">
                        <div class="customerData">
                            <mat-icon class="iconData">pin_drop</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>Dirección Fiscal</b></span>
                                <span
                                    style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.fiscalAddress}}</b></span>
                            </div>
                        </div>
                        <div class="customerData">
                            <mat-icon class="iconData">gavel</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>Regimen Fiscal</b></span>
                                <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.fiscalRegime==null?"Sin
                                        especificar":venta.fiscalRegime}}</b></span>
                            </div>
                        </div>
                    </div>

                    <div class="dataRow">
                        <div class="customerData">
                            <mat-icon class="iconData">style</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>Método de Facturación</b> <mat-icon
                                        class="actionBtn" *ngIf="permissions().match('446576656c6f706572')"
                                        (click)="editInvoiceMethod(venta.fkinvoiceType, venta.fiscalFolio, venta.codeProperty)">edit</mat-icon></span>
                                <span
                                    style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.fk_invoiceType}}</b></span>
                            </div>
                        </div>
                        <div class="customerData">
                            <mat-icon class="iconData">pin</mat-icon>
                            <div class="infoBlock">
                                <span style="font-size: 1.1rem;"><b>Folio Fiscal</b></span>
                                <span style="font-size: 1.1rem; color: var(--M19Primary);"><b>{{venta.fiscalFolio==''?'Sin
                                        Registro':venta.fiscalFolio}}</b></span>
                            </div>
                        </div>
                    </div>

                    <table class="table table-hover mt-3">
                        <thead>
                            <tr align="center">
                                <td>Pagos realizados</td>
                                <td>Estado de pagos</td>

                            </tr>
                        </thead>
                        <tbody>
                            <tr align="center">
                                <td> <a>{{venta.mPagadas+'/'+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(0)}}</a>
                                </td>

                                <td>
                                    {{venta.retraso.toFixed(2)}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <div>
                    <mat-card>
                        <mat-card-content>
                            <table class="table table-striped animated fadeIn faster">
                                <thead class="thead-m19">
                                    <tr>
                                        <th scope="col">No.</th>
                                        <th scope="col">Folio</th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Cantidad</th>
                                        <th scope="col">Método de pago</th>
                                        <th scope="col">Concepto</th>
                                        <th scope="col">Saldo Anterior</th>
                                        <th scope="col">Saldo Insoluto</th>
                                        <th style="text-align: center;" scope="col">Estado</th>
                                        <th style="text-align: center;" scope="col">Deshacer</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let payment of payments; let i=index;">
                                        <td>{{i+1}}</td>
                                        <td>{{payment.folioPago}}</td>
                                        <td>{{payment.fechaPago | date: 'yyyy-MM-dd'}}</td>
                                        <td>{{(payment.cantidadAbono | currency:'CAD' : '$ ')}}</td>
                                        <td>{{payment.metodoPago}}</td>
                                        <td>{{payment.concepto}}</td>
                                        <td>{{payAfter[i] | currency:'CAD' : '$ '}}</td>
                                        <td>{{payBefore[i] | currency:'CAD' : '$ '}}</td>
                                        <td style="text-align: center;">
                                            <div title="Factura Realizada" style="display: flex; justify-content: center; align-items: center; background-color:rgb(0, 155, 64)"
                                                *ngIf="payment.fkInvState == 2"> <mat-icon style="color: white;">done</mat-icon></div>
                                            <div title="Factura Pendiente" style="display: flex; justify-content: center; align-items: center; background-color:rgb(255, 51, 64)"
                                                *ngIf="payment.fkInvState == 1"> <mat-icon style="color: white;">close</mat-icon></div>
                                            <mat-icon title="Factura No Existente" style="color:rgb(255, 242, 0)"
                                                *ngIf="payment.fkInvState == 5"> warning</mat-icon>
                                        </td>
                                        <td style="text-align: center;"><mat-icon class="deleteBtn"
                                                *ngIf="payment.fkInvState == 2"
                                                (click)="setWaiting(payment.id, 1)">cancel</mat-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>