import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerTitleComponent } from './banner-title/banner-title.component';
import { MatIconModule } from '@angular/material/icon';
import { LoaderComponent } from './loader/loader.component';



@NgModule({
  declarations: [
    BannerTitleComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    BannerTitleComponent,
    LoaderComponent
  ]
})
export class UiModule { }
