import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { noteReport } from 'src/app/models/Note';
import { ReportService } from 'src/app/services/report.service';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { DialogWalletComponent } from '../dialog-wallet/dialog-wallet.component';
import { loader } from 'src/app/ui/loader/loader.model';
import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { DatePipe } from '@angular/common';

import Swal from 'sweetalert2';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';

@Component({
  selector: 'app-dialog-notes',
  templateUrl: './dialog-notes.component.html',
  styleUrls: ['./dialog-notes.component.scss']
})
export class DialogNotesComponent implements OnInit {
  loader: loader = {
    message: "Registrando nota..."
  }
  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  pipe = new DatePipe('es-MX');

  note: any = { id: 0, noteDes: "", registrationDate: "0000-00-00", responsable: "", idUser: 0, fkPropertyNote: "" };

  user: String = "";

  saving: number = 0;

  constructor(
    private reportService: ReportService,
    private _formBuilder: FormBuilder,

    public sweet: SweetAlertComponent,

    private dialogRef: MatDialogRef<DialogWalletComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  notesFormGroup: FormGroup = this._formBuilder.group({
    id: [],
    noteDes: [, Validators.required],
    responsable: [this.getUser()],
    fkPropertyNote: [this.data]
  });

  ngOnInit(): void {
    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
    this.user = this.getUser();
  }

  createNote() {
    this.saving = 1;
    this.reportService.createNotes(this.notesFormGroup.value).subscribe(
      res => {
        this.reportService.getOneNote(res).subscribe(
          res2 => {
            this.note = res2;
            if (this.note.id == 0) {
              Swal.fire({
                title: 'Se ha detectado un error, comprueba tu conexión a internet y por favor comprueba el registro de tu nota.',
                width: '50%',
                showConfirmButton: true,
                confirmButtonColor: "#D33E3E",
                confirmButtonText: "De acuerdo"
              })
            } else {
              Swal.fire({
                title: 'Nota registrada con éxito',
                width: '50%',
                html: "<div style='display: flex;flex-direction: column;gap: 10px;'>"
                  + "<div style='display: flex;flex-direction: column;padding: 10px;border-left: solid var(--M19Primary) 2px;background: linear-gradient(0deg, rgba(236,240,245,1) 0%, rgba(255,255,255,1) 65%);'>"
                  + "<span style='display: flex;'><b>El día " + this.pipe.transform(this.note.registrationDate, "longDate") + "</b></span>"
                  + "<span style='display: flex;'><b style='color: var(--M19Primary);gap:5px;'>" + this.note.responsable + " </b> dijo:</span>"
                  + "<span style='display: flex;font-style: italic;'>\"" + this.note.noteDes + "\"</span> </div> </div>",
                showConfirmButton: true,
                confirmButtonColor: "#D33E3E",
                confirmButtonText: "Perfecto!"
              })
              this.saving = 0;
              localStorage.removeItem('property')
              this.dialogRef.close();
            }
          }
        );
      }
    );
  }

  //==========================================================================================================
  //Recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.cliente
  }
}
