import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { Pointofmap } from '../pointofmap';
import { DialogMapsSelectComponent } from '../dialog-maps-select/dialog-maps-select.component';

import {
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogMapsNotRegisterComponent } from '../dialog-maps-not-register/dialog-maps-not-register.component';
import { MapsService } from 'src/app/services/maps.service';
import { ToastrService } from 'ngx-toastr';
import { loader } from 'src/app/ui/loader/loader.model';

@Component({
  selector: 'app-maps-generate',
  templateUrl: './maps-generate.component.html',
  styleUrls: ['./maps-generate.component.scss']
})


export class MapsGenerateComponent implements OnInit, AfterViewInit {


  //obtener referencia directa a el canvas del html
  @ViewChild('canvasRef', { static: false }) canvasRef: any

  //Valores estaticos del tamaño del canvas, siendo los valores DEFAULT de Autocad.
  public width = 10399;
  public height = 7083;

  loader: loader = {
    message: "Cargando mapa para punteo..."
  }

  loading = 0;

  //genera el contexto para poderse adaptar al navegador en curso. 
  private cx!: CanvasRenderingContext2D;

  //aqui guardamos los puntos que se van a mostrar.

  //vandera para saber si esta permitido o no dibujar
  public isAvailabe: boolean = false;

  //Variable que renderiza imagen y canvas
  public renderis = 2;

  //Valores dinamicos del tamaño del canvas, siendo los valores obtenidos de la imagen de Autocad.
  public widthOB!: number;
  public heightOB!: number;

  //objeto de tipo punto
  public pointe: Pointofmap = {};

  //arreglo  de tipo pointofmap
  public pointes: Array<Pointofmap> = [];

  //obejto seleccionado 
  public pointSelected: Pointofmap = {};

  //tamaño del radio del objeto
  public radius = 15;

  //contador para el nombre (PRUEBAS)
  public cont = 0;
  letters = '0123456789ABCDEF';
  color = '#';



  //Este listener es el encargado de obtener los valores de DONDE SE DA CLICK EL MOUSE  
  @HostListener('click', ['$event'])
  onClick = (e: any) => {

    console.log("ARREGLO DE PUNTOS: ", this.pointes)
    if (e.target.id === 'canvasId') {

      if (this.isTouched(e) == true) {
        alert("El punto seleccionado es: " + JSON.stringify(this.pointSelected))

      } else {

        Swal.fire({
          title: "¿Deseas registrar un inmueble?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Guardar',
          denyButtonText: `Deshacer`,
        }).then((result) => {
          if (result.isConfirmed) {
            //Swal.fire('guardado', '', 'success');
            this.selectProperty(e)

          } if (result.isDenied) {
            Swal.fire('No guardado', '', 'error');

          }
        })
      }
    }



  }

  getRandomColor() {
    this.color = '#'; // <-----------
    for (var i = 0; i < 6; i++) {
      this.color += this.letters[Math.floor(Math.random() * 16)];
    }
    return this.color
  }

  //funcion que permite obtener las coordnadas realas del contexto de nuestro canvas
  private write(res: { clientX: number; clientY: number; }): any {
    const canvas = this.canvasRef.nativeElement;
    const rect = canvas.getBoundingClientRect();

    const prevPos = {
      x: res.clientX - rect.left,
      y: res.clientY - rect.top
    };

    console.log("click: ", prevPos)
    this.writeSingle(prevPos)
    return prevPos;
  }

  //funcion encargada de guardar las coordenadas del circulo en un arreglo a su vez evalua y empieza el proceso de pintado
  private writeSingle(prevPos: { x: number; y: number; }) {

    //CONTADOR PROVISIONAL
    this.cont = this.cont + 1;

    //AGREGAR AL ARREGLO PARA MOSTRARLO
    this.pointes.push({
      Xmap: prevPos.x, Ymap: prevPos.y, codeProperty: localStorage.getItem('inmueble') || "" + this.cont + "L" + this.cont, fk_salesCatalog: this.getRandomColor(), 15: this.radius
    });

    //INSERTAR EN LA BASE DE DATOS
    this.insertXYMap(prevPos.x, prevPos.y, localStorage.getItem('inmueble') || "")

    if (this.pointes.length > 0) {
      const prevPost = this.pointes[this.pointes.length - 1]
      this.drawOnCanvas(prevPost)
    }
  }

  //funcion encargada de evaluar que el contexto exista para pintar sobre el la funcion de drawCircle.
  drawOnCanvas(point: Pointofmap) {
    if (!this.cx) {
      return
    }
    this.drawCircle(point)
  }

  constructor(public sweet: SweetAlertComponent,
    public dialog: MatDialog,
    private mapsService: MapsService,
    private toastr: ToastrService

  ) {
  }


  ngOnInit(): void {
  }

  ngAfterContentInit() {
    localStorage.removeItem('PointsOfMap');
    localStorage.removeItem('selectednomenclature');
    localStorage.removeItem('selectedName');
    localStorage.removeItem('inmueble');
    localStorage.removeItem('linkMap');

    this.selectSale();
  }

  //como el contexto solo existe cuando se ejecuta por el oninit, necesitamos hacerlo despues de la vista
  //implementando el afterViewInit
  ngAfterViewInit(): void {

    this.getValues();

    setTimeout(() => {
      this.render();
    }, 1000);

    setTimeout(() => {
      this.mapst("");
    }, 1200);

  }



  //ocupamos renderizar el nuevo contexto para que obtenga los nuevos valores
  //obtenemos el contexto, le asignamos los valores que necesitamos y mandamos a llamar.
  private render() {
    const canvasEl = this.canvasRef.nativeElement;
    this.cx = canvasEl.getContext('2d');
    canvasEl.width = this.widthOB / this.renderis;
    canvasEl.height = this.heightOB / this.renderis;

    this.cx.lineWidth = 3;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = '#000';
  }

  //funcion que pone de fondo el mapa que recibe como parametro (se encuentra estatico en este momento)
  private mapst(link: any) {
    //obtenemos el contexto
    const canvasEl = this.canvasRef.nativeElement;
    var ctx = canvasEl.getContext("2d");

    //renderizar a un tercio la escala de la resolucin maxima
    let x = this.widthOB / this.renderis
    let y = this.heightOB / this.renderis


    //declaramos la variable que almacenara la imagen
    var img = new Image();
    img.src = link + ""
    //cargamos la imagen, como no sabemos si ya se encuentra descargada, usamos onload
    img.onload = function () {
      ctx.drawImage(img, 0, 0, (x), (y));

    }

    setTimeout(() => {
      this.writeCircules();
    }, 1500);

  }


  //Funcion que dibuja circulos en las cordendas X y Y descritas.
  private drawCircle(point: Pointofmap) {
    const canvasEl = this.canvasRef.nativeElement;

    if (canvasEl.getContext) {

      var ctx = canvasEl.getContext('2d');

      ctx.beginPath();

      ctx.arc(point.Xmap, point.Ymap, this.radius, 0, Math.PI * 2, true); // Círculo externo
      ctx.fillStyle = "blue"; //asignar color

      ctx.fill(); //mandar a llamar el color

      this.property(point.Xmap, point.Ymap, point.codeProperty + "")
    }
  }


  private property(x: number, y: number, property: string) {
    const canvasEl = this.canvasRef.nativeElement;

    const ctx = canvasEl.getContext('2d');

    ctx.font = '15px serif';
    ctx.fillStyle = 'black'
    ctx.fillText(property, x - 30, y + 30, 140);
  }

  //funcion que obtiene los valores de la imagen obtenida
  private getValues() {
    var img = new Image();
    img.src = localStorage.getItem('linkMap') || ""
    img.onload = () => {

      this.widthOB = img.width;
      this.heightOB = img.height;

      if (this.widthOB == 0 || this.heightOB == 0) {
        this.getValues();
      } else {
        setTimeout(() => {
          this.transforJSONtoPOINT();
        }, 1000);

        setTimeout(() => {
          this.render();
        }, 900);

        setTimeout(() => {
          this.mapst(localStorage.getItem('linkMap') || "");
        }, 1000);
      }
    };
  }


  // funcion que evalua si estoy dando click o no al punto a travez de matematicas.
  private isTouched(res: { clientX: number; clientY: number; }): boolean {
    const canvas = this.canvasRef.nativeElement;

    const rect = canvas.getBoundingClientRect();

    const prevPos = {
      x: res.clientX - rect.left,
      y: res.clientY - rect.top
    };

    for (var i = 0; i < this.pointes.length; i++) {
      var dx = Math.abs(prevPos.x - this.pointes[i].Xmap);
      var dy = Math.abs(prevPos.y - this.pointes[i].Ymap);

      var distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

      if (
        distance <= this.radius
      ) {
        this.pointSelected = this.pointes[i];
        console.log("punto seleccionado es", this.pointSelected)
        return true;
      }
    }
    return false;
  }

  //Seleccionamos el imueble que se va a registrar con las coordenadas
  selectProperty(res: { clientX: number; clientY: number; }) {

    const dialogo1 = this.dialog.open(DialogMapsNotRegisterComponent
      , {
        width: '600px',
      });

    dialogo1.afterClosed().subscribe(result => {
      console.log("result: ", result);
      if (result) {
        console.log("INMUEBLE SELECCIONADO");
        this.write(res)
      }

    }
    );



  }

  //Metodo para insertar en la base de datos
  insertXYMap(Xmap: any, Ymap: any, codeProperty: any) {
    this.mapsService.insertXYMap(Xmap, Ymap, codeProperty).subscribe(
      res => {
        this.toastr.success('Inmueble intertado con exito')
      },
      err => {
        this.toastr.error('Error: no se registro el inmueble seleccionado')

      }
    )
  }


  //Seleccionar el mapa a vizualiar
  selectSale() {
    const dialogo1 = this.dialog.open(DialogMapsSelectComponent
      , {
        width: '600px',
      });

    dialogo1.afterClosed().subscribe(result => {
      console.log("result: ", result);
      if (result) {
        setTimeout(() => {
          this.inicializar();
          console.log("inicializo");
        }, 500);

      }

    }
    );
  }

  public inicializar() {
    this.loading = 1;
    setTimeout(() => {
      this.getValues();
    }, 900);
  }

  //Obtiene el arreglo Points generado por el dialog de seleccionar
  private transforJSONtoPOINT() {
    this.pointes = JSON.parse(localStorage.getItem('PointsOfMap') || "")
  }

  //funcion encargada de Dibujar las coordenadas del circulo de un arreglo a su vez evalua y empieza el proceso de pintado
  private writeCircules() {
    for (var i = 0; i < this.pointes.length; i++) {
      const prevPost = this.pointes[i]
      console.log("Identificador", prevPost)
      this.drawOnCanvas(prevPost)
    }
    this.loading = 0;
  }
}