import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer } from '../models/Customer';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  //Seleccionar todos los clientes
  selectCustomers() {
    return this.http.get(`${environment.URLServer}/customers`);
  }

  //Consultar un cliente en específico
  selectCustomer(id: number) {
    return this.http.get<Customer>(`${environment.URLServer}/customers/${id}`);
  }

  //Registrar cliente
  createCustomer(customer: Customer) {
    return this.http.post(`${environment.URLServer}/customers`, customer);
  }

  //Modificar cliente
  updateCustomer(id:number, customer: Customer): Observable <Customer> {
    return this.http.put(`${environment.URLServer}/customers/${id}`, customer);
  }

  //Eliminar cliente
  deleteCustomer(id: number) {
    return this.http.delete(`${environment.URLServer}/customers/${id}`);
  }

  //Seleccionar estados civil
  selectCivilStatus() {
    return this.http.get(`${environment.URLServer}/customers/status/civil_status`);
  }

}