import { AfterContentInit, AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MapsService } from 'src/app/services/maps.service';
import { DialogMapsSelectComponent } from '../dialog-maps-select/dialog-maps-select.component';
import { DialogRegisteruserComponent } from '../dialog-registeruser/dialog-registeruser.component';
import { DialogSelectedTypeUserComponent } from '../dialog-selected-type-user/dialog-selected-type-user.component';
import { Pointofmap } from '../pointofmap';

@Component({
  selector: 'app-maps-consult',
  templateUrl: './maps-consult.component.html',
  styleUrls: ['./maps-consult.component.scss']
})
export class MapsConsultComponent implements OnInit, AfterViewInit {

  //obtener referencia directa a el canvas del html
  @ViewChild('canvasRef', { static: false }) canvasRef: any


  generate = false;
  step = 0;

  colors: any = [];

  //genera el contexto para poderse adaptar al navegador en curso. 
  private cx!: CanvasRenderingContext2D;

  //vandera para saber si esta permitido o no dibujar
  public isAvailabe: boolean = false;

  //Variable que renderiza imagen y canvas
  public renderis = 2;

  //Valores dinamicos del tamaño del canvas, siendo los valores obtenidos de la imagen de Autocad.
  public widthOB!: number;
  public heightOB!: number;

  //objeto de tipo punto
  public pointe: Pointofmap = {};

  //arreglo  de tipo pointofmap
  public pointes: Array<Pointofmap> = [];

  //obejto seleccionado 
  public pointSelected: Pointofmap = {};

  //tamaño del radio del objeto
  public radius = 15;

  //contador para el nombre (PRUEBAS)
  public cont = 0;
  letters = '0123456789ABCDEF';
  color = '#';

  //Link para almacenar la imagen seleccionada.
  linkMap = "";


   //Este listener es el encargado de obtener los valores de DONDE SE DA CLICK EL MOUSE  
   @HostListener('click', ['$event'])
   onClick = (e: any) => {
 
     console.log("ARREGLO DE PUNTOS: ", this.pointes)
     if (e.target.id === 'canvasId') {
 
       if (this.isTouched(e) == true) {
      //   this.registerNewUser();

       } 
     }
 
   }


  constructor(
    public dialog: MatDialog,
    private MapsService: MapsService,
    private router: Router

  ) { }


  ngAfterContentInit(): void {
    localStorage.removeItem('PointsOfMap');
    localStorage.removeItem('selectednomenclature');
    localStorage.removeItem('selectedName');
    localStorage.removeItem('inmueble');
    localStorage.removeItem('linkMap');

    this.selectSale();
    this.headerMaps()
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.getColorCode();
  }

  //Registrar al pre cliente.
  registerNewUser(){
    const dialog2 = this.dialog.open(DialogSelectedTypeUserComponent, {width: '300px',height: '150px'})
  }

  async getColorCode() {
    this.MapsService.getColorCode().subscribe(
      res => {
        this.colors = res;
        console.log(this.colors);
      }
    );
  }

  //Seleccionamos el mapa a vizualizar.
  selectSale() {
    const dialogo1 = this.dialog.open(DialogMapsSelectComponent
      , {
        width: '600px',
      });

    dialogo1.afterClosed().subscribe(result => {
      console.log("result: ", result);
      if (result) {
        setTimeout(() => {
          this.inicializar();
          console.log("inicializo");
        }, 500);

      }

    }
    );
  }

  public inicializar() {
    this.generate = true;
    this.step = 1;
    setTimeout(() => {
      this.getValues();
    }, 900);

  }


  //funcion que obtiene los valores de la imagen obtenida
  private getValues() {
    var img = new Image();
    img.src = localStorage.getItem('linkMap') || ""
    img.onload = () => {
      this.widthOB = img.width;
      this.heightOB = img.height;
      console.log("Ancho: ", this.widthOB);
      console.log("Alto: ", this.heightOB);
      if(this.widthOB == 0 || this.heightOB == 0){
        this.getValues();
      } else {
        setTimeout(() => {
          this.transforJSONtoPOINT();
        }, 900);

        setTimeout(() => {
          this.render();
        }, 900);
    
        setTimeout(() => {
          this.mapst(localStorage.getItem('linkMap') || "");
        }, 1000);
      }
    };
  }

  //ocupamos renderizar el nuevo contexto para que obtenga los nuevos valores
  //obtenemos el contexto, le asignamos los valores que necesitamos y mandamos a llamar.
  private render() {
    const canvasEl = this.canvasRef.nativeElement;
    this.cx = canvasEl.getContext('2d');
    canvasEl.width = this.widthOB / this.renderis;
    canvasEl.height = this.heightOB / this.renderis;

    this.cx.lineWidth = 3;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = '#000';
  }

  //funcion que pone de fondo el mapa que recibe como parametro (se encuentra estatico en este momento)
  private mapst(link: any) {
    //obtenemos el contexto
    const canvasEl = this.canvasRef.nativeElement;
    var ctx = canvasEl.getContext("2d");

    //renderizar a un tercio la escala de la resolucin maxima
    let x = this.widthOB / this.renderis
    let y = this.heightOB / this.renderis

    //declaramos la variable que almacenara la imagen
    var img = new Image();
    img.src = link + ""
    //cargamos la imagen, como no sabemos si ya se encuentra descargada, usamos onload
    img.onload = function () {
      ctx.drawImage(img, 0, 0, (x), (y));
    }
    setTimeout(() => {
      this.writeCircules();
    }, 1500);

  }


  //refresca la pagina 
  refresh(): void { window.location.reload(); }

  //funcion encargada de evaluar que el contexto exista para pintar sobre el la funcion de drawCircle.
  drawOnCanvas(point: Pointofmap) {
    if (!this.cx) {
      console.log("NO EXISTE EL CONTEXTO")
      return
    }
    this.drawCircle(point)
  }

  //Funcion que dibuja circulos en las cordendas X y Y descritas.
  private drawCircle(point: Pointofmap) {
    console.log("SI EXISTE EL CONTEXTO")

    const canvasEl = this.canvasRef.nativeElement;

    if (canvasEl.getContext) {

      var ctx = canvasEl.getContext('2d');

      ctx.beginPath();

      ctx.arc(point.Xmap, point.Ymap, this.radius, 0, Math.PI * 2, true); // Círculo externo

      ctx.fillStyle = point.HTMLColors; //asignar color

      ctx.fill(); //mandar a llamar el color

      this.property(point.Xmap, point.Ymap, point.codeProperty + "")
      this.generate = false;
      this.step = 0;
    }
    this.generate = false;
    this.step = 0;
  }

  private property(x: number, y: number, property: string) {
    const canvasEl = this.canvasRef.nativeElement;

    const ctx = canvasEl.getContext('2d');

    ctx.font = '15px serif';
    ctx.fillStyle = 'black'
    ctx.fillText(property, x - 30, y + 30, 140);
  }

  private transforJSONtoPOINT() {
    this.pointes = JSON.parse(localStorage.getItem('PointsOfMap') || "")
  }

  //funcion encargada de Dibujar las coordenadas del circulo de un arreglo a su vez evalua y empieza el proceso de pintado
  private writeCircules() {
      this.step = 2;
      for (var i = 0; i < this.pointes.length; i++){
        const prevPost = this.pointes[i]
        console.log("Identificador", prevPost)
        this.drawOnCanvas(prevPost)
      }
    }
  
  // funcion que evalua si estoy dando click o no al punto a travez de matematicas.
  private isTouched(res: { clientX: number; clientY: number; }): boolean {
    const canvas = this.canvasRef.nativeElement;

    const rect = canvas.getBoundingClientRect();

    const prevPos = {
      x: res.clientX - rect.left,
      y: res.clientY - rect.top
    };

    for (var i = 0; i < this.pointes.length; i++) {
      var dx = Math.abs(prevPos.x - this.pointes[i].Xmap);
      var dy = Math.abs(prevPos.y - this.pointes[i].Ymap);

      var distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

      if (
        distance <= this.radius
      ) {
        this.pointSelected = this.pointes[i];
        console.log("punto seleccionado es", this.pointSelected)
        localStorage.setItem("InmuebleDigital",this.pointSelected.codeProperty || "")
        return true;
      }
    }
    return false;
  }

  headerMaps(){
    console.log(this.router.url);
  }


}
