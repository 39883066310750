<script src='build/pdfmake.min.js'></script>
<script src='build/vfs_fonts.js'></script>


<div *ngIf="ocultaEntrada">

    <div id="htmlData" style="margin: 0; padding: 0;" [hidden]="true">
        <div style="display: flex; flex-direction: column; align-items: center;">
            <img src="./assets/imagotipo.png" alt="Icono de M19" width="50%">
            <p style="font-size: 0.8rem; text-align: center;">COMPROBANTE DE RATIFICACIÓN</p>
        </div>

        <div>
            <p style="font-size: 0.7rem;">{{paymentFormGroup.controls['fechaPago'].value === null? '' : pipe.transform(paymentFormGroup.controls['fechaPago'].value, 'longDate')!.toUpperCase()}}</p>
            <p style="font-size: 0.7rem; text-align: justify;">POR MEDIO DEL PRESENTE SE HACE CONSTAR QUE RECIBIMOS DE {{saleInfo.customer === ''? '' :(saleInfo.customer).toUpperCase()}} LA CANTIDAD DE ${{paymentFormGroup.controls['cantidadAbono'].value}} POR EL PAGO DE RATIFICACIÓN NOTARIAL DEL CONTRATO DEL {{saleInfo.fk_property.substring(5,6) == 'L'? 'LOTE ' + saleInfo.fk_property.substring(6,8) : 'DEPARTAMENTO ' +
                saleInfo.fk_property.substring(6,8)}} DE LA {{saleInfo.fk_property.substring(2,3) == 'M'? 'MANZANA ' +
                saleInfo.fk_property.substring(3,5) :
                'TORRE ' + saleInfo.fk_property.substring(3,5)}} DEL DESARROLLO {{saleInfo.name.toUpperCase()}}.
            </p>
        </div>

        <div>
            <p style="margin-top: 50px; font-size: 0.6rem; text-align: center; border-top: solid black 1px;">RECIBIÓ <br> {{getUser().toUpperCase()}}</p>
        </div>
    </div>

    <div class="container-fluid">
        <mat-card class="card-form">
            <mat-card-content class="justify-align-center">
                <app-loader [loader]="loader" *ngIf="saving==1"></app-loader>
                <div class="contenedorRegistro">
                    <div class="registerFields">
                        <form [formGroup]="paymentFormGroup" (ngSubmit)="createPayment();">
                            <h5>Registro de pagos</h5>

                            <div class="ng-autocomplete">
                                <ng-autocomplete name="busqueda" placeholder="Inmueble/Nombre cliente" [data]="sal"
                                    [searchKeyword]="keyword" (selected)="onKeypressEvent($event)"
                                    [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                                </ng-autocomplete>
                                <ng-template #itemTemplate let-item>
                                    <a [innerHTML]="item.name"></a>
                                </ng-template>
                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                            </div>

                            <div class="registerRow">
                                <mat-form-field appearance="outline">
                                    <mat-label>Inmueble</mat-label>
                                    <input matInput type="text" formControlName="fkCliente" required
                                        [(ngModel)]="codigoInm" readonly>
                                    <mat-icon matSuffix>roofing</mat-icon>
                                </mat-form-field>

                                <mat-form-field appearance="outline" *ngFor="let car of carteraInmu">
                                    <mat-label>Vendedor</mat-label>
                                    <input matInput type="text" value={{car.usuarios}} readonly>
                                    <mat-icon matSuffix>person</mat-icon>
                                </mat-form-field>
                            </div>

                            <div class="registerRow">
                                <mat-form-field appearance="outline">
                                    <mat-label>Fecha</mat-label>
                                    <input matInput type="date" formControlName="fechaPago" required>
                                    <mat-icon matSuffix>calendar_month</mat-icon>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>Cantidad</mat-label>
                                    <input matInput type="number" formControlName="cantidadAbono"
                                        [(ngModel)]="abonoPago" [(ngModel)]="invReport.amountInvoice">
                                    <mat-icon matSuffix>paid</mat-icon>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>Método de pago</mat-label>
                                    <mat-select formControlName="metodoPago" (valueChange)="folioRequired($event)"
                                        required>
                                        <mat-option *ngFor="let metPago of metode" [value]="metPago.formaPago">
                                            {{metPago.formaPago}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>wallet</mat-icon>
                                </mat-form-field>
                            </div>

                            <div class="registerRow">
                                <mat-form-field appearance="outline">
                                    <mat-label>Recuperación por:</mat-label>
                                    <mat-select formControlName="paymentType">
                                        <mat-option *ngFor="let tipoRec of recuperacion" [value]="tipoRec.tipo">
                                            {{tipoRec.descripcion}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>checklist</mat-icon>
                                </mat-form-field>

                                <mat-form-field appearance="outline" *ngIf="formularioActivo">
                                    <mat-label>Concepto de pago</mat-label>
                                    <mat-select formControlName="concepto" required>
                                        <mat-option *ngFor="let conPag of concepto" [value]="conPag.tipo">
                                            {{conPag.tipo}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>draw</mat-icon>
                                </mat-form-field>

                                <mat-form-field appearance="outline" *ngIf="showFolio">
                                    <mat-label>Folio del deposito/Transferencia</mat-label>
                                    <input matInput type="text" formControlName="folio" maxlength="45">
                                    <mat-icon matSuffix>pin</mat-icon>
                                </mat-form-field>
                            </div>

                            <button class="m19Btn" type="submit" [disabled]="!paymentFormGroup.valid || saving==1">
                                Registrar pago
                                <mat-icon>sell</mat-icon>
                            </button>
                        </form>
                    </div>

                    <div class="lastInfo" *ngIf="resume.lastPay != ''">
                        <span style="font-size: 1rem; font-weight: bold;"><b style="color: var(--M19Primary); font-weight: bold; font-size: 1rem;">Último pago:</b> {{resume.lastPay | date: "dd-MM-YYYY"}}</span>
                        <span style="font-size: 1rem; font-weight: bold;"><b style="color: var(--M19Primary); font-weight: bold; font-size: 1rem;">Balance:</b> {{resume.balance}}</span>
                        <span style="font-size: 1rem; font-weight: bold;"><b style="color: var(--M19Primary); font-weight: bold; font-size: 1rem;">Mensualidad:</b> {{resume.monthlyPayment | currency: 'CAD': '$'}}</span>
                    </div>
                </div>

            </mat-card-content>
        </mat-card>
    </div>
</div>

<div *ngIf="creaPago">

    <div class="row _card">
        <div class="col-md-4">
            <mat-card>
                <mat-card-content *ngFor="let venta of carteraInmu">
                    <button class="btn btn-success" style="width: 100%;"
                        (click)="nuevoAbono();activarFormulario();">Nuevo abono</button>
                    <p></p>
                    <table>
                        <thead>
                            <tr>
                                <h5>Desarrollo:</h5>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <h5 class="_h5">{{venta.name}}</h5>
                            </tr>
                        </tbody>

                        <thead>
                            <tr>
                                <h5>Cliente:</h5>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <h5 class="_h5">{{venta.clientes}}</h5>
                            </tr>
                        </tbody>

                        <thead *ngFor="let ventas of salesCoo">
                            <tr>
                                <h5 *ngIf="ventas.coopropietario!=null">Coopropietario:</h5>
                            </tr>
                        </thead>
                        <tbody *ngFor="let ventas of salesCoo">
                            <tr>
                                <h5 class="_h5" *ngIf="ventas.coopropietario!=null">{{ventas.coopropietario}}</h5>
                            </tr>
                        </tbody>

                        <thead>
                            <tr>
                                <h5>Coordinador de ventas:</h5>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <h5 class="_h5">{{venta.usuarios}}</h5>
                            </tr>
                        </tbody>

                        <thead *ngIf="venta.vendedores != null">
                            <tr>
                                <h5>Vendedor:</h5>
                            </tr>
                        </thead>
                        <tbody *ngIf="venta.vendedores != null">
                            <tr>
                                <h5 class="_h5">{{venta.vendedores}}</h5>
                            </tr>
                        </tbody>

                        <thead>
                            <tr>
                                <h5>Fecha de contrato:</h5>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <h5 class="_h5">{{venta.fechaDato | date: 'dd-MM-yyyy'}}</h5>
                            </tr>
                        </tbody>

                        <thead>
                            <tr>
                                <h5 *ngIf="venta.mobile!=null">Celular:</h5>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <h5 class="_h5">{{venta.mobile}}</h5>
                            </tr>
                        </tbody>

                        <thead>
                            <tr>
                                <h5>Correo electrónico:</h5>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <h5 class="_h5">{{venta.email}}</h5>
                            </tr>
                        </tbody>
                    </table>

                    <table *ngIf="venta.codeProperty.substr(2,1)=='M'">
                        <tr>
                            <th>
                                <h5>{{M}}</h5>
                            </th>
                            <th>
                                <h5 class="_h5">{{venta.codeProperty.substr(3,2)}}</h5>
                            </th>
                            <th></th>
                            <th>
                                <h5>{{L}}</h5>
                            </th>
                            <th>
                                <h5 class="_h5">{{venta.codeProperty.substr(6,2)}}</h5>
                            </th>
                        </tr>
                    </table>

                    <table *ngIf="venta.codeProperty.substr(2,1)=='T'">
                        <tr>
                            <th>
                                <h5>{{T}}</h5>
                            </th>
                            <th>
                                <h5 class="_h5">{{venta.codeProperty.substr(3,2)}}</h5>
                            </th>
                            <th></th>
                            <th>
                                <h5>{{D}}</h5>
                            </th>
                            <th>
                                <h5 class="_h5">{{venta.codeProperty.substr(6,2)}}</h5>
                            </th>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <th>
                                <h5>Valor total:</h5>
                            </th>
                            <th>
                                <h5 class="_h5"> {{venta.valor | currency:'CAD' : '$ '}}</h5>
                            </th>
                        </tr>
                    </table>

                    <div class="row mt-2" style="padding-left: .4cm;">
                        <div style="padding-right: 1cm;">
                            <span class="input-group-text" id="entradaPagado"
                                style="background-color: brown; border-radius:.3cm; color: aliceblue; height: 1cm; width: auto;">
                                <b> Abonado</b>
                            </span>
                            <div>
                                <h5> {{venta.abonado | currency:'CAD' : '$ '}} </h5>
                            </div>
                        </div>
                        <div>
                            <span class="input-group-text" id="entradaPagado"
                                style="background-color: brown; border-radius:.3cm; color: aliceblue; height: 1cm; width: auto;"><b>Adeudo</b></span>
                            <div>
                                <h5> {{venta.valor-venta.abonado | currency:'CAD' : '$ '}} </h5>
                            </div>
                        </div>
                    </div>

                    <table class="table">
                        <thead class="thead-dark">
                            <tr align="center">
                                <td>Pagos realizados</td>
                                <td>Pagos esperados</td>
                                <td>Estado de pagos</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr align="center">
                                <td>
                                    <a>{{venta.mPagadas+'/'+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(1)}}</a>
                                </td>
                                <td>
                                    <a>
                                        <div>
                                            {{venta.meses+'/'+((venta.valor-venta.advancePayment)/venta.monthlyPayment).toFixed(1)}}
                                        </div>
                                        <div *ngIf="venta.retraso>=0;">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                    role="progressbar" style="width: 100%"></div>
                                            </div>
                                        </div>
                                        <div *ngIf="venta.retraso < 0 && venta.retraso > tolerancia2" ngElse="warning2">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                                    role="progressbar" style="width: 100%"></div>
                                            </div>
                                        </div>
                                        <div *ngIf="venta.retraso <= tolerancia2 && venta.retraso >tolerancia3"
                                            ngElse="warning3">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                                    role="progressbar" style="width: 100%"></div>
                                            </div>
                                        </div>
                                        <div *ngIf="venta.retraso <= tolerancia3 && venta.retraso >limite"
                                            ngElse="danger">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped bg-warning"
                                                    role="progressbar" style="width: 100%"></div>
                                            </div>
                                        </div>
                                        <div *ngIf="venta.retraso <=limite">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped bg-danger"
                                                    role="progressbar" style="width: 100%"></div>
                                            </div>
                                        </div>
                                    </a>
                                </td>
                                <td>
                                    {{venta.retraso.toFixed(1)}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>


            </mat-card>
        </div>

        <div class="col-md-8">
            <mat-card>
                <mat-card-content>
                    <table class="table table-striped animated fadeIn faster">
                        <thead class="thead-m19">
                            <tr>
                                <th scope="col">No.</th>
                                <th scope="col">Folio</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Cantidad</th>
                                <th scope="col">Método de pago</th>
                                <th scope="col">Concepto</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let payment of payments; let i=index;">
                                <th>{{i+1}}</th>
                                <th>{{payment.folioPago}}</th>
                                <td>{{payment.fechaPago | date: 'dd-MM-yyyy'}}</td>
                                <td>{{(payment.cantidadAbono | currency:'CAD' : '$ ')}}</td>
                                <td>{{payment.metodoPago}}</td>
                                <td>{{payment.concepto}}</td>
                            </tr>
                        </tbody>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
