import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//=========================================================================================================
//Importación de servicios necesarios para esta página
//=========================================================================================================
import { RequisitionService } from 'src/app/services/requisition.service';
import { DepartureService } from 'src/app/services/departure.service';
import { DepaproService } from 'src/app/services/depapro.service';
import { RequidepaService } from 'src/app/services/requidepa.service';
import { ProductcatalogService } from 'src/app/services/productcatalog.service';
import { ProjectService } from 'src/app/services/project.service';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';
import { DeliverynoteService } from 'src/app/services/deliverynote.service';
import { DeliveryinvoiceService } from 'src/app/services/deliveryinvoice.service';
import { ConceptsService } from 'src/app/services/concepts.service';

//=========================================================================================================
//Importación de librerías para componentes visuales
//=========================================================================================================
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SweetAlertComponent } from '../../../utils/sweet-alert/sweet-alert.component';
import { MatListModule } from '@angular/material/list';
import { DialogInsertproductsComponent } from './dialog-insertproducts/dialog-insertproducts.component';
import { DialogInsertsupplierComponent } from './dialog-insertsupplier/dialog-insertsupplier.component';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';
import Swal from 'sweetalert2';
import { loader } from 'src/app/ui/loader/loader.model';

@Component({
  selector: 'app-requisitionregister',
  templateUrl: './requisitionregister.component.html',
  styleUrls: ['./requisitionregister.component.scss']
})

export class RequisitionregisterComponent implements OnInit {
  loader: loader = {
    message: "Cargando la información..."
  }

  saving = 0;

  deliverySelected: any = '';

  //Variable para el ultimo id insertado
  lastIDRequisition: any = [];

  //Variable para el ultimo id insertado de factura
  lastIDInvoice: any = [];

  //Variable para almacenar las requisiciones de la BD
  requisitions: any = [];

  //Variable para almacenar las partidas de la BD
  departures: any = [];

  concepts: any = [];

  //Variable para almacenar las remisiones de la BD
  deliveries: any = [];

  //Objeto para almacenar los proyectos
  projects: any = [];

  //Objeto para almacenar las torres del proyecto seleccionado
  towers: any = [];

  //Objeto para almacenar las torres del proyecto seleccionado
  towersR: any = [];

  //Objeto de materiales para agregar a requisición, remisión y factura
  materials: any = [];
  materialsD: any = [];
  materialsF: any = [];

  //Objeto para almacenar los materiales registrados en la BD
  products: any = [];

  //Objeto para almacenar el material seleccionado en el formulario
  product: any = [];

  //Objeto para almacenar los proveedores del catalogo
  suppliers: any = [];

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  //Objeto para almacenar el subtotal de la factura
  subt: any = 0;

  iva: any = 0.16;

  //Objeto para almacenar el total de la factura
  total: any = 0;

  keywordP = 'departurename';
  keywordM = 'productname';
  keywordC = 'value';


  constructor(
    //===================================================
    //Declaración de servicios
    public requisitionService: RequisitionService,
    public departureService: DepartureService,
    public depaprodService: DepaproService,
    public requiDepaService: RequidepaService,
    public projectService: ProjectService,
    public productCatalogService: ProductcatalogService,
    public suppliersService: SuppliersService,
    public projectDetailService: ProjectdetailsService,
    public deliveryNoteService: DeliverynoteService,
    public deliveryInvoiceService: DeliveryinvoiceService,
    public concetpsService: ConceptsService,

    //===================================================
    //Declaración de complementos visuales
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    public list: MatListModule,
    public dialog: MatDialog,
    private router: Router
  ) { }

  //Ejecutador inicial de componentes
  ngOnInit(): void {
    this.requisitionFormGroup.controls['requisitiondate'].setValue((new Date()).toISOString().substring(0, 10));

    //Obtener todos los proyectos
    this.selectProjects();

    //Obtener la información del usuario loggeado
    this.getUser();

    //Obtener todos los productos
    this.selectProducts();

    //Obtener todos los proveedores
    this.selectSuppliers();

    //Obtener todas las notas de remisión
    this.selectDelivery();
  }

  //==========================================================================================================
  //Objeto constructor del formulario utilizado en la pantalla de requisición, se agrega el código 
  //", Validators.required" para señalar que es un valor que no debe estar vacío
  //==========================================================================================================
  requisitionFormGroup: FormGroup = this._formBuilder.group({
    idrequisition: [],
    idProject: [, Validators.required],
    idprojectdet: [, Validators.required],
    requisitionnumber: [, Validators.pattern(/^((HER)?[0-9]+)$/)],
    requisitiondate: [, Validators.required],
    idconcept: [],
    iddeparture: [],
    productname: [],
    amount: [],
    unit: [],
    auth: ['Ing. German Luna Gamboa'],
    review: ['Ing. Carlos Betancourt Gonzalez'],
    notes: [],
    useremail: [this.getUser()]
  });

  //==========================================================================================================
  //Objeto constructor del formulario utilizado en la pantalla de remisión, se agrega el código 
  //", Validators.required" para señalar que es un valor que no debe estar vacío
  //==========================================================================================================
  deliveryFormGroup: FormGroup = this._formBuilder.group({
    deliveryfolio: [],
    idrequisition: [, Validators.required],
    idsupplier: [, Validators.required],
    idproject: [, Validators.required],
    idprojectdet: [, Validators.required],
    deliverynumber: [, Validators.required],
    receiver: [, Validators.required],
    notes: [],
    deliverydate: [, Validators.required],
    productname: [],
    amount: [],
    unit: [],
    unitcost: [],
    useremail: [this.getUser()]
  });

  //==========================================================================================================
  //Objeto constructor del formulario utilizado en la pantalla de factura, se agrega el código 
  //", Validators.required" para señalar que es un valor que no debe estar vacío
  //==========================================================================================================
  invoiceFormGroup: FormGroup = this._formBuilder.group({
    iddelinvoice: [],
    deliveryfolio: [, Validators.required],
    idsupplier: [, Validators.required],
    invoicenumber: [, Validators.required],
    invoicedate: [, Validators.required],
    subtotal: [this.subt],
    iva: [this.iva],
    total: [this.total],
    productname: [],
    amount: [],
    unit: [],
    unitcost: [],
    useremail: [this.getUser()]
  });

  //==========================================================================================================
  //Función para insertar una requisición en la base de datos
  //==========================================================================================================
  async insert() {
    //Se verifica que se haya registrado al menos un producto en la requisición
    if (this.materials.length == 0) {
      this.sweet.AlertTime("center", "error", "Por favor al menos registre una partida con materiales a esta requisición.", false, 2000);
      return;
    }

    //Se verifica que se haya ingresado un número de requisición mayor a 0
    if (this.requisitionFormGroup.controls['requisitionnumber'].value == '0' || this.requisitionFormGroup.controls['requisitionnumber'].value == '00' || this.requisitionFormGroup.controls['requisitionnumber'].value == '000') {
      this.sweet.AlertTime("center", "error", "Por favor inserte un número válido para la requisición entre 1 y 999", false, 2000);
      return;
    }

    //Si la información actual del formulario no es valida según el constructor de arriba se genera un retorno
    if (this.requisitionFormGroup.invalid) {
      return;
      //Si la información es correcta se comienza el proceso de registro de la requisición
    } else {
      Swal.fire({
        title: '¿Quieres guardar tus cambios ahora?',
        text: "¡Esta requisición se guardara como está actualmente!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No, espera',
        confirmButtonText: 'Sí, guárdala!'
      }).then((result) => {
        if (result.isConfirmed) {
          //Se manda a llamar el servicio de requisition
          this.requisitionService.insertRequisition(this.requisitionFormGroup.value)
            .subscribe(
              res => {
                //Se recupera el ID generado en la BD para la requisición nueva registrada
                this.lastIDRequisition = res;

                //Se pasa a registrar la relación de partida y productos en la BD
                this.insertProducts();
              },
              err => console.error(err)
            );
        }
      })
    }
  }

  //==========================================================================================================
  //Función para insertar una nota de remisión en la base de datos
  //==========================================================================================================
  async insertDelivery() {
    //Se verifica que se haya registrado al menos un producto en la nota de remisión
    if (this.materialsD.length == 0) {
      this.sweet.AlertTime("center", "error", "Por favor al menos registre un producto en la lista de materiales.", false, 2000);
      return;
    }

    //Se verifica que se haya ingresado un número mayor a 0 para el número de remisión
    if (this.deliveryFormGroup.controls['deliverynumber'].value <= 0) {
      this.sweet.AlertTime("center", "error", "Por favor inserte un número válido para la nota de remisión mayor a 0", false, 2000);
      return;
    }

    //Se verifica que se haya ingresado un número mayor a 0 para el número de folio de la remisión
    if (this.deliveryFormGroup.controls['deliveryfolio'].value <= 0) {
      this.sweet.AlertTime("center", "error", "Por favor inserte un folio válido para la nota de remisión mayor a 0", false, 2000);
      return;
    }

    //Si la información actual del formulario no es valida según el constructor de arriba se genera un retorno
    if (this.deliveryFormGroup.invalid) {
      return;
      //Si la información es correcta se comienza el proceso de registro de la remisión
    } else {
      Swal.fire({
        title: '¿Quieres guardar tus cambios ahora?',
        text: "¡Esta nota de remisión se guardara como está actualmente!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No, espera',
        confirmButtonText: 'Sí, guárdala!'
      }).then((result) => {
        if (result.isConfirmed) {
          //Se manda a llamar el servicio de deliveryNote
          this.deliveryNoteService.insertDelivery(this.deliveryFormGroup.value)
            .subscribe(
              res => {
                console.log(res);
                if (res.title === "error") {
                  switch (res.msg) {
                    case "ER_DUP_ENTRY":
                      this.sweet.AlertTime("center", "error", `Número de folio "${this.deliveryFormGroup.controls["deliveryfolio"].value}" duplicado.`, false, 2000);
                      break;
                    default:
                      this.sweet.AlertTime("center", "error", `Algo ha salido mal.`, false, 2500);
                      console.log(res.detail);
                      break;
                  }
                  return;
                }
                this.insertProductsDelivery();
              },
              err => console.error(err)
            );
        }
      })
    }
  }

  //==========================================================================================================
  //Función para insertar una factura en la base de datos
  //==========================================================================================================
  async insertInvoice() {
    //Se valida que se haya registrado al menos un material en la factura
    if (this.materialsF.length == 0) {
      this.sweet.AlertTime("center", "error", "Por favor al menos registre un producto en la lista de materiales.", false, 2000);
      return;
    }

    //Se valida que el número de factura sea mayor a 0
    if (this.invoiceFormGroup.controls['invoicenumber'].value < 0) {
      this.sweet.AlertTime("center", "error", "Por favor inserte un número válido para la factura mayor a 0", false, 2000);
      return;
    }

    //Si la información actual del formulario no es valida según el constructor de arriba se genera un retorno
    if (this.invoiceFormGroup.invalid) {
      return;
      //Si la información es correcta se comienza el proceso de registro de la remisión
    } else {
      Swal.fire({
        title: '¿Quieres guardar tus cambios ahora?',
        text: "¡Esta factura se guardara como está actualmente!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No, espera',
        confirmButtonText: 'Sí, guárdala!'
      }).then((result) => {
        if (result.isConfirmed) {
          //Se manda a llamar el servicio de deliveryInvoice
          this.deliveryInvoiceService.insertInvoice(this.invoiceFormGroup.value).subscribe(
            res => {
              //Se recupera el ID ingresado en la base de datos para la factura nueva
              this.lastIDInvoice = res;

              //Se manda a llamar la función para insertar los productos de la factura
              this.insertProductsF();
            }
          );
        }
      })
    }
  }

  //==========================================================================================================
  //Función para insertar los productos de las partidas
  //==========================================================================================================
  async insertProducts() {
    //Si la información actual del formulario no es valida según el constructor de arriba se genera un retorno
    if (this.requisitionFormGroup.invalid) {
      return;
      //Si la información es correcta se comienza el proceso de registro de la relación partida-productos
    } else {
      //Este ciclo for recorre el arreglo de materiales generados en el registro de productos
      for (let p of this.materials) {
        //Se manda a llamar al servicio de departureproducts
        this.depaprodService.insertProducts(this.lastIDRequisition, p.iddeparture, p.idconcept, p.idproduct, p.amount, p.notes, this.decode.email)
          .subscribe(
            res => {
              //Generación de alerta sobre registro exitoso de una requisición completa
              this.sweet.AlertTime("center", "success", "Requisición registrada con éxito", false, 2000);

              //Se genera un refresh de la página de registro
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['RequisitionRegister']));
            },
            err => console.error(err)
          );
      }
    }
  }

  //==========================================================================================================
  //Función para insertar los productos de la nota de remisión
  //==========================================================================================================
  async insertProductsDelivery() {
    //Si la información actual del formulario no es valida según el constructor de arriba se genera un retorno
    if (this.deliveryFormGroup.invalid) {
      return;
      //Si la información es correcta se comienza el proceso de registro de nota de remisión
    } else {
      //Este ciclo for recorre el arreglo de materiales generados en el registro de productos
      for (let p of this.materialsD) {
        //En la lista del formulario se registra la información separada por el simbolo '~'
        //iddeparture ~ idproduct ~ productname ~ amount ~ unit
        let prod = p.split("~");

        //Se manda a llamar al servicio de deliveryNote
        this.deliveryNoteService.insertProducts(this.deliveryFormGroup.controls['deliveryfolio'].value, prod[0], prod[2], prod[4], this.decode.email)
          .subscribe(
            res => {
              //Generación de alerta sobre registro exitoso de una nota de remisión completa
              this.sweet.AlertTime("center", "success", "Nota de remisión registrada con éxito", false, 2000);

              //Se genera un refresh de la página de registro
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['RequisitionRegister']));
            },
            err => console.error(err)
          );

      }
    }
  }

  //==========================================================================================================
  //Función para insertar los productos de la factura
  //==========================================================================================================
  async insertProductsF() {
    //Si la información actual del formulario no es valida según el constructor de arriba se genera un retorno
    if (this.invoiceFormGroup.invalid) {
      return;
      //Si la información es correcta se comienza el proceso de registro de nota de remisión
    } else {
      //Este ciclo for recorre el arreglo de materiales generados en el registro de productos
      for (let p of this.materialsF) {
        //En la lista del formulario se registra la información separada por el simbolo '~'
        //iddeparture ~ idproduct ~ productname ~ amount ~ unit
        let prod = p.split("~");

        //Se manda a llamar al servicio de deliveryNote
        this.deliveryInvoiceService.insertInvoiceProducts(this.lastIDInvoice, prod[0], prod[2], prod[4], this.decode.email)
          .subscribe(
            res => {
              //Generación de alerta sobre registro exitoso de una nota de remisión completa
              this.sweet.AlertTime("center", "success", "Factura registrada con éxito", false, 2000);

              //Se genera un refresh de la página de registro
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['RequisitionRegister']));
            },
            err => console.error(err)
          );

      }
    }
  }

  //==========================================================================================================
  //Función para buscar las partidas registradas en la base de datos
  //==========================================================================================================
  async selectDepartures(id: number) {
    this.requisitionFormGroup.controls['iddeparture'].setValue('');
    this.requisitionFormGroup.controls['idconcept'].setValue('');
    this.departureService.getNamesOfDepartures(id).subscribe(
      res => {
        //Se recuperan todas las partidas en la variable departures
        this.departures = res;
      },
      err => console.log(err)
    );
  }

  //==========================================================================================================
  //Función para buscar los proyectos registrados en la base de datos
  //==========================================================================================================
  async selectProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        //Se recuperan todos los proyectos en la variable projects
        this.projects = res;
      },
      err => console.log(err)
    );
  }

  async selectConcepts(id: any) {
    this.requisitionFormGroup.controls['idconcept'].setValue('');
    this.concetpsService.selectAllConcepts().subscribe(
      res => {
        this.concepts = res;
        console.log(this.concepts[0].value);
      }
    );
    // this.concetpsService.selectConcepts(id.iddeparture).subscribe(
    //   res => {
    //     this.concepts = res;
    //   }
    // );
  }

  //==========================================================================================================
  //Función para buscar los materiales registrados en el catalogo de la BD
  //==========================================================================================================
  async selectProducts() {
    this.productCatalogService.getNamesOfProducts()
      .subscribe(
        res => {
          //Se recuperan los productos en la variable products
          this.products = res;
        },
        err => console.log(err)
      );
  }

  //==========================================================================================================
  //Función para buscar las notas de remisión registradas en el catalogo de la BD
  //==========================================================================================================
  async selectDelivery() {
    this.deliveryNoteService.selectDeliveries()
      .subscribe(
        res => {
          //Se recuperan los productos en la variable products
          this.deliveries = res;
        },
        err => console.log(err)
      );
  }

  //==========================================================================================================
  //Función para buscar un material por ID según la selección del select del formulario
  //==========================================================================================================
  async selectProduct(id: any) {
    //El objeto select tiene de valor el ID y el nombre separados por coma
    //idproduct , productname
    let ide = id.productname.split("-");

    this.productCatalogService.selectProduct(parseInt(ide[0]))
      .subscribe(
        res => {
          //Recuperamos el valor en la variable product
          this.product = res;

          //Asignamos al objeto del formulario con controlName "unit" el valor recuperado de la consulta
          this.requisitionFormGroup.controls['unit'].setValue(this.product.unit);
        }
      );
  }

  //==========================================================================================================
  //Función para buscar un material por ID según la selección del select del formulario
  //==========================================================================================================
  async selectProduct2(id: any) {
    //El objeto select tiene de valor el ID y el nombre separados por coma
    //idproduct , productname
    let ide = id.productname.split("-");

    this.productCatalogService.selectProduct(parseInt(ide[0]))
      .subscribe(
        res => {
          //Recuperamos el valor en la variable product
          this.product = res;

          //Asignamos al objeto del formulario con controlName "unit" el valor recuperado de la consulta
          this.deliveryFormGroup.controls['unit'].setValue(this.product.unit);
        }
      );
  }

  //==========================================================================================================
  //Función para buscar un material por ID según la selección del select del formulario
  //==========================================================================================================
  async selectProduct3(id: any) {
    //El objeto select tiene de valor el ID y el nombre separados por coma
    //idproduct , productname
    let ide = id.productname.split("-");

    this.productCatalogService.selectProduct(parseInt(ide[0]))
      .subscribe(
        res => {
          //Recuperamos el valor en la variable product
          this.product = res;

          //Asignamos al objeto del formulario con controlName "unit" el valor recuperado de la consulta
          this.invoiceFormGroup.controls['unit'].setValue(this.product.unit);
        }
      );
  }

  //==========================================================================================================
  //Función para consultar las torres del proyecto seleccionado en el formulario
  //==========================================================================================================
  async selectProject(id: number) {
    //Colocamos en blanco por default el combobox de modulo antes de asignar los correspondientes
    this.requisitionFormGroup.controls['idprojectdet'].setValue('');
    this.requisitionFormGroup.controls['iddeparture'].setValue('');
    this.requisitionFormGroup.controls['idconcept'].setValue('');

    this.projectDetailService.selectDetailsById(id).subscribe(
      res => {
        //Recuperamos la información de torres del proyecto
        this.towers = res;
      }
    );
  }

  //==========================================================================================================
  //Función para consultar las torres del proyecto seleccionado en el formulario
  //==========================================================================================================
  async selectProjectR(id: number) {
    //Colocamos en blanco por default el combobox de modulo antes de asignar los correspondientes
    this.deliveryFormGroup.controls['idprojectdet'].setValue('');

    this.projectDetailService.selectDetailsById(id).subscribe(
      res => {
        //Recuperamos la información de torres del proyecto
        this.towersR = res;
      }
    );
  }

  async selectReqList(id: any) {
    this.requisitionService.getReqListByModule(id).subscribe(
      res => {
        this.requisitions = res;
      }
    );
  }

  //==========================================================================================================
  //Recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.email
  }

  //==========================================================================================================
  //Función para agregar materiales de requisición a una lista temporal
  //==========================================================================================================
  async addMaterial() {
    if (this.requisitionFormGroup.controls['iddeparture'].value == null || this.requisitionFormGroup.controls['iddeparture'].value == ''
      || this.requisitionFormGroup.controls['productname'].value == null || this.requisitionFormGroup.controls['productname'].value == ''
      || this.requisitionFormGroup.controls['amount'].value == null || this.requisitionFormGroup.controls['amount'].value == ''
      || this.requisitionFormGroup.controls['unit'].value == null || this.requisitionFormGroup.controls['unit'].value == ''
      || this.requisitionFormGroup.controls['idconcept'].value == null || this.requisitionFormGroup.controls['idconcept'].value == ''
      || this.requisitionFormGroup.controls['auth'].value == null || this.requisitionFormGroup.controls['auth'].value == '') {
      this.sweet.AlertTime("center", "error", "Por favor complete los campos de partida, producto y cantidad correctamente.", false, 2000);
    } else {
      let addMaterial = this.requisitionFormGroup.value;
      if (addMaterial !== '') {
        try {
          let name = addMaterial.productname.productname.split("~");
          let dep = addMaterial.iddeparture.departurename.split("~");

          let add = {
            "iddeparture": addMaterial.iddeparture.iddeparture,
            "idproduct": name[0],
            "productname": name[1],
            "amount": addMaterial.amount,
            "unit": addMaterial.unit,
            "departurenumber": dep[0],
            "idconcept": addMaterial.idconcept.idconcept,
            "conceptname": addMaterial.idconcept.value,
            "notes": addMaterial.notes
          }

          this.materials.push(add);

          //Se resetea la información de los campos de texto para productos
          // this.requisitionFormGroup.controls['iddeparture'].setValue('');
          // this.requisitionFormGroup.controls['idconcept'].setValue('');
          this.requisitionFormGroup.controls['productname'].setValue('');
          this.requisitionFormGroup.controls['amount'].setValue('');
          this.requisitionFormGroup.controls['unit'].setValue('');
          this.requisitionFormGroup.controls['notes'].setValue('');
        } catch {
          this.sweet.AlertTime("center", "error", "Se detectó un error en el formulario de productos.", false, 2000);
        }
      }
    }
  }

  //==========================================================================================================
  //Función para agregar materiales de remisión a una lista temporal
  //==========================================================================================================
  async addMaterial2() {
    if (this.deliveryFormGroup.controls['unitcost'].value == null || this.deliveryFormGroup.controls['unitcost'].value == ''
      || this.deliveryFormGroup.controls['productname'].value == null || this.deliveryFormGroup.controls['productname'].value == ''
      || this.deliveryFormGroup.controls['amount'].value == null || this.deliveryFormGroup.controls['amount'].value == '') {
      this.sweet.AlertTime("center", "error", "Por favor complete los campos de producto, cantidad y costo unitario correctamente.", false, 2000);
    } else {
      let addMaterial = this.deliveryFormGroup.value;
      if (addMaterial !== '') {
        try {
          let name = addMaterial.productname.productname.split("~");
          this.materialsD.push(name[0] + '~' + name[1] + '~' + addMaterial.amount + '~' + addMaterial.unit + '~' + addMaterial.unitcost);

          //Se resetea la información de los campos de texto para productos
          this.deliveryFormGroup.controls['productname'].setValue('');
          this.deliveryFormGroup.controls['amount'].setValue('');
          this.deliveryFormGroup.controls['unit'].setValue('');
          this.deliveryFormGroup.controls['unitcost'].setValue('');
        } catch {
          this.sweet.AlertTime("center", "error", "Se detectó un error en el formulario de productos.", false, 2000);
        }
      }
    }
  }

  //==========================================================================================================
  //Función para agregar materiales de factura a una lista temporal
  //==========================================================================================================
  async addMaterial3() {
    if (this.invoiceFormGroup.controls['unitcost'].value == null || this.invoiceFormGroup.controls['unitcost'].value == ''
      || this.invoiceFormGroup.controls['productname'].value == null || this.invoiceFormGroup.controls['productname'].value == ''
      || this.invoiceFormGroup.controls['amount'].value == null || this.invoiceFormGroup.controls['amount'].value == '') {
      this.sweet.AlertTime("center", "error", "Por favor complete los campos de producto, cantidad y costo unitario correctamente.", false, 2000);
    } else {
      let addMaterial = this.invoiceFormGroup.value;
      if (addMaterial !== '') {
        try {
          let name = addMaterial.productname.productname.split("~");
          this.materialsF.push(name[0] + '~' + name[1] + '~' + addMaterial.amount + '~' + addMaterial.unit + '~' + addMaterial.unitcost);

          //Se resetea la información de los campos de texto para productos
          this.invoiceFormGroup.controls['productname'].setValue('');
          this.invoiceFormGroup.controls['amount'].setValue('');
          this.invoiceFormGroup.controls['unit'].setValue('');
          this.invoiceFormGroup.controls['unitcost'].setValue('');

          //Se calcula el subtotal de la factura
          this.subt = this.subt + addMaterial.unitcost * addMaterial.amount;

          //Se calcula el total de la factura
          this.total = this.subt + (this.subt * this.iva);

          //Se coloca los valores de subtotal y total en los campos correspondientes
          this.invoiceFormGroup.controls['subtotal'].setValue(this.subt.toFixed(2));
          this.invoiceFormGroup.controls['total'].setValue(this.total.toFixed(2));
        } catch {
          this.sweet.AlertTime("center", "error", "Se detectó un error en el formulario de productos.", false, 2000);
        }
      }
    }
  }

  //==========================================================================================================
  //Función para borrar materiales de requisición de la lista temporal
  //==========================================================================================================
  async deleteMaterials(i: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, elimínalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'El producto se quitó de la requisición.',
          'success'
        )
        //Se crea una variable temporal para almacenar los materiales que permanecerían en la lista
        const materialsT = Array();
        let indice = 0;

        //Se recorre la lista de materiales con un ciclo for
        for (const mat of this.materials) {
          //Se compara el indice que se quiere eliminar con el de la lista y si no coincide se almacena en la lista
          //temporal
          if (indice !== i) {
            materialsT.push(mat);
          }
          indice++;
        }
        //Se pasa el valor de la lista temporal a la lista original de materiales
        this.materials = materialsT;
      }
    })
  }

  //==========================================================================================================
  //Función para borrar materiales de remisión de la lista temporal
  //==========================================================================================================
  async deleteMaterials2(i: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, elimínalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'El producto se quitó de la remisión.',
          'success'
        )
        //Se crea una variable temporal para almacenar los materiales que permanecerían en la lista
        const materialsT = Array();
        let indice = 0;

        //Se recorre la lista de materiales con un ciclo for
        for (const mat of this.materialsD) {
          //Se compara el indice que se quiere eliminar con el de la lista y si no coincide se almacena en la lista
          //temporal
          if (indice !== i) {
            materialsT.push(mat);
          }
          indice++;
        }
        //Se pasa el valor de la lista temporal a la lista original de materiales
        this.materialsD = materialsT;
      }
    })
  }

  //==========================================================================================================
  //Función para borrar materiales de factura de la lista temporal
  //==========================================================================================================
  async deleteMaterials3(i: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, elimínalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'El producto se quitó de la factura.',
          'success'
        )
        //Se crea una variable temporal para almacenar los materiales que permanecerían en la lista
        const materialsT = Array();
        let indice = 0;

        //Se recorre la lista de materiales con un ciclo for
        for (const mat of this.materialsF) {
          //Se compara el indice que se quiere eliminar con el de la lista y si no coincide se almacena en la lista
          //temporal
          if (indice !== i) {
            materialsT.push(mat);
          }

          if (indice == i) {
            //Se recalcula el subtotal de la factura y se asigna al campo correspondiente
            this.subt = this.subt - mat.split('~')[4] * mat.split('~')[2];
            this.invoiceFormGroup.controls['subtotal'].setValue(this.subt.toFixed(2));

            //Se recalcula el total de la factura y se asigna al campo correspondiente
            this.total = this.subt + (this.subt * this.iva);
            this.invoiceFormGroup.controls['total'].setValue(this.total.toFixed(2));
          }

          indice++;
        }
        //Se pasa el valor de la lista temporal a la lista original de materiales
        this.materialsF = materialsT;
      }
    })
  }

  //=========================================================================================================
  //Función para consultar todos los proveedores del catalogo
  //=========================================================================================================
  async selectSuppliers() {
    this.suppliersService.selectSuppliers().subscribe(
      res => {
        //Se recupera la información de la consulta en la variable de proveedores
        this.suppliers = res;
      }
    );
  }

  //=========================================================================================================
  //Función para consultar todas las requisiciones registradas en la BD
  //=========================================================================================================
  async selectRequisitions() {
    this.requisitionService.selectRequisitions().subscribe(
      res => {
        //Se recupera la información de la consulta en la variable de requisiciones
        this.requisitions = res;
      }
    );
  }


  //=========================================================================================================
  //Función para abrir un dialog para registro de producto
  //=========================================================================================================
  async insertProductDialog() {
    const dialogRef = this.dialog.open(DialogInsertproductsComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '500px'
    });

    //===================================================================================================
    //Hacer referencia al evento en el que se cierra el dialog
    //===================================================================================================
    dialogRef.afterClosed().subscribe(
      res => {
        this.selectProducts();
      }
    );
  }

  //=========================================================================================================
  //Función para abrir un dialog para registro de producto
  //=========================================================================================================
  async insertSupplierDialog() {
    const dialogRef = this.dialog.open(DialogInsertsupplierComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '500px'
    });

    //===================================================================================================
    //Hacer referencia al evento en el que se cierra el dialog
    //===================================================================================================
    dialogRef.afterClosed().subscribe(
      res => {
        this.selectSuppliers();
      }
    );
  }

  async copyMaterials() {
    this.deliveryNoteService.selectDeliveryProductsByFolio(this.deliverySelected).subscribe(
      res => {
        this.subt = 0;
        this.total = 0;
        this.materialsF = [];
        for (let m of res) {
          this.materialsF.push(m.idproduct + '~' + m.productname + '~' + m.amount + '~' + m.unit + '~' + m.unitcost);
          this.subt = this.subt + m.unitcost * m.amount;
          this.total = this.subt + (this.subt * this.iva);
        }
        this.invoiceFormGroup.controls['subtotal'].setValue(this.subt.toFixed(2));
        this.invoiceFormGroup.controls['total'].setValue(this.total.toFixed(2));
      }
    );
  }
}