import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ProyectsDetailsService } from 'src/app/services/proyects-details.service';

@Component({
  selector: 'app-proyects-details',
  templateUrl: './proyects-details.component.html',
  styleUrls: ['./proyects-details.component.scss']
})
export class ProyectsDetailsComponent implements OnInit {

  proyectsDetails: any | undefined;


  constructor(private proyectdetails: ProyectsDetailsService) { }

  ngOnInit(): void {
    this.getNotLink();
  }


  getNotLink(): Promise<any>{
    this.proyectdetails.getNotLink().subscribe(
      res => {
        this.proyectsDetails = res
        console.log("Adentro: ",res)
      }
    )
    console.log("Afuera ",this.proyectsDetails)
    return this.proyectsDetails
  }






}
