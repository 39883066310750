export class MonthName {
    private monthName = [
        "ENERO",
        "FEBRERO",
        "MARZO",
        "ABRIL",
        "MAYO",
        "JUNIO",
        "JULIO",
        "AGOSTO",
        "SEPTIEMBRE",
        "OCTUBRE",
        "NOVIEMBRE",
        "DICIEMBRE"
    ];

    getMonthName(monthNumber: number) {
        return monthNumber > 0 && monthNumber < 13 ? this.monthName[monthNumber - 1] : "Número invalido";
    }
}