import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CashCut } from '../models/CashCut';
import { CashOutFlow } from '../models/CashOutFlows';

@Injectable({
  providedIn: 'root'
})
export class CashCutService{

    constructor(private http: HttpClient) { }

    //CAJA 
    getCurrentPaymentsService(curDate: string): Observable<any>{
      return this.http.post(`${environment.URLServer}/cashCut/currentPaymentsRoutes/` , {curDate});
    }

    getCurrentCashOutflowsService(curDate: string): Observable<any>{
      return this.http.post(`${environment.URLServer}/cashCut/currentCashOutFlowaRoutes/` , {curDate})
    }

    InsertCashCutService(datinsertData: CashCut): Observable<any> {
      return this.http.post(`${environment.URLServer}/cashCut/insertCashCutRoutes/`, datinsertData);
    }

    getLastCashCutService(): Observable <any>{
      return this.http.get(`${environment.URLServer}/cashCut/getLastCashCutRoutes/`);
    }

    closeCashCutService(update: CashCut): Observable<any> {
      return this.http.put(`${environment.URLServer}/cashCut/closeCashCutRoutes/`, {update});
    }
  
    collectionCostsService(): Observable <any>{
      return this.http.get(`${environment.URLServer}/cashCut/collectionCostsRoutes/`);
    }

    collectionCostsByDateService(date : string): Observable <any>{
      return this.http.post(`${environment.URLServer}/cashCut/collectionCostsByDateRoutes/`, {date});
    }

    //SALIDAS DE EFECTIVO
    getAllEmployeesForCashoutFlowService(): Observable<any>{
      return this.http.get(`${environment.URLServer}/cashCut/getAllEmployeesForCashoutFlowsRoutes/`);
    }

    InsertCashOutService(data: CashOutFlow): Observable<any> {
      return this.http.post(`${environment.URLServer}/cashCut/insertCashOutRoutes/`, data);
    }


    //INGRESOS Y EGRESOS
    getAllPaymentsByDateService(dateSelec: string): Observable<any>{
      return this.http.post(`${environment.URLServer}/cashCut/getAllPaymentByDateRoutes/` , {dateSelec});
    }

    getAllCashOutflowsByDateService(dateSelec: string ): Observable<any>{
      return this.http.post(`${environment.URLServer}/cashCut/getAllCashOutflowsByDateRoutes/` , {dateSelec});
    }

    getAllPaymentsByRangeDateService( start: string , end: string ): Observable <any> {
      return this.http.post(`${environment.URLServer}/cashCut/getAllPaymentsByRangeDateRoutes/`, {start , end})
    }

    getAllCashOutflowsByRangeDateService( start: string , end: string ): Observable <any> {
      return this.http.post(`${environment.URLServer}/cashCut/getAllCashOutflowsByRangeDateRoutes/`, {start , end})
    }

    cancelCashoutFlowService( idCashOut: string ): Observable <any> {
      return this.http.put(`${environment.URLServer}/cashCut/putCancelCashFlowOutRoutes/`, {idCashOut})
    }

    getRatificationPayments(date : string): Observable <any>{
      return this.http.post(`${environment.URLServer}/cashCut/getratificationPaymentsByDateRoutes`, {date})
    }

    //Projects 
    selectProjects() {
      return this.http.get(`${environment.URLServer}/projects`);
    }

} 