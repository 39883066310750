import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//=========================================================================================================
//Importación de servicios necesarios para esta página
//=========================================================================================================
import { ProjectService } from 'src/app/services/project.service';
import { DepaPieceService } from 'src/app/services/depa-piece.service';
import { BudgetService } from '../../../services/budget.service';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';
import { ConceptsService } from 'src/app/services/concepts.service';
import { DepartureService } from 'src/app/services/departure.service';
import { ProductcatalogService } from 'src/app/services/productcatalog.service';
import { BudgetPieceworkService } from 'src/app/services/budget-piecework.service';
import { PieceworkService } from 'src/app/services/piecework.service';
//=========================================================================================================
//Importación de librerías para componentes visuales
//=========================================================================================================
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SweetAlertComponent } from '../../../utils/sweet-alert/sweet-alert.component';
import { MatListModule } from '@angular/material/list';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';
import Swal from 'sweetalert2';
import { DialogInsertproductsComponent } from '../../requisition/requisitionregister/dialog-insertproducts/dialog-insertproducts.component';


@Component({
  selector: 'app-budgetregister',
  templateUrl: './budgetregister.component.html',
  styleUrls: ['./budgetregister.component.scss']
})
export class BudgetregisterComponent implements OnInit {

  //Objeto para almacenar los proyectos
  projects: any = [];
  //Objeto para almacenar la transformación del correo
  decode: any = [];
  //Objeto para almacenar los presupuestos
  budgets: any = [];
  //Objeto para almacenar las torres
  towers: any = [];
  //Objeto para almacenar los conceptos
  concepts: any = [];
  //Objeto para almacenar los productos
  products: any = [];
  //Variable para almacenar el subtotal del presupuesto
  subtotal: number = 0;
  //Variable para disminuir el costo de los materiales que se eliminen del presupuesto
  subtotalresta: number = 0;
  //Variable para almacenar la suma de todos los costos de los materiales
  totalp: number = 0;
  //Objeto para almacenar el total
  total: any = [];
  //Palabras clave de partidas, conceptos y materiales sirven de llave para utilizarlas en el front
  keywordP = 'departurename';
  keywordM = 'productname';
  keywordC = 'descriptions';
  keywordPi = 'pieceworkname';
  //Objeto para almacenar partidas
  departures: any = [];
  //Objeto para almacenar un producto
  product: any = [];
  //Objeto para almacenar materiales
  materials: any = [];
  //Objeto para almacenar el id
  id: any = [];
  //Objeto para almacenar el responsable
  responsable: any = [];
  //Variable para el ultimo id insertado
  lastIDBudget: any = [];
 //Ojeto que almacena el destajo
 piecework: any = [];
 //Objeto que almacena los destajos
 pieces: any = [];
 //Objeto que almacena al responsable
  pieceworks: any=[];
//Objeto que contiene las requisiciones encontradas en la BD
budgetsselect: any = ['Sin seleccionar'];
  constructor(
    //===================================================
    //Declaración de servicios
    public projectService: ProjectService,
    public depapieceService: DepaPieceService,
    public budgetService: BudgetService,
    public projectDetailService: ProjectdetailsService,
    public concetpsService: ConceptsService,
    public departureService: DepartureService,
    public productCatalogService: ProductcatalogService,
    public budgetpieceworkService: BudgetPieceworkService,
    public pieceworkService: PieceworkService,
    //===================================================
    //Declaración de complementos visuales
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    public list: MatListModule,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    //Obtener todos los proyectos
    this.selectProjects();
    //Obtener la información del usuario loggeado
    this.getUser();
    //Obtener todas las partidas
    this.selectDepartures();
    //Obtener los productos
    this.selectProducts();
    //Volver a calcular el total de costos de todos los materiales
    this.recalculateTotal();
    //Función para insertar el nombre de la persona responsable de realizar el presupuesto
    this.insertResponsable();
    //Obtener los destajos
    this.selectPieceworks();
    //Recalcular el total una vez indicada la cantidad de ese mismo destajo
    this.precalculateTotal();
    //Insertar el nombre del responsable
    this.pinsertResponsable();
  }

  //==========================================================================================================
  //Objeto constructor del formulario utilizado en la pantalla de presupuesto, se agrega el código
  //", Validators.required" para señalar que es un valor que no debe estar vacío
  //==========================================================================================================
  budgetFormGroup: FormGroup = this._formBuilder.group({
    idProject: [, Validators.required],
    idprojectdet: [, Validators.required],
    budgetdate: [, Validators.required],
    iddeparture: [],
    idconcept: [],
    idproduct: [],
    productname: [],
    unit: [],
    unitcost: [],
    amount: [],
    total: [],
    totalp: [this.totalp],
    analyst: [],
    responsable: [],
    approved: [],
    useremail: [this.getUser()]
  });

  //==========================================================================================================
  //                            REGISTRO DE PRESUPUESTO DE REQUISICIONES
  //==========================================================================================================

  //==========================================================================================================
  //Función para buscar los proyectos registrados en la base de datos
  //==========================================================================================================
  async selectProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        //Se recuperan todos los proyectos en la variable projects
        this.projects = res;
      },
      err => console.log(err)
    );
  }

  //==========================================================================================================
  //Recalcular el total por material
  //==========================================================================================================
  async recalculateTotal() {
    this.budgetFormGroup.controls['totalp'].setValue(this.budgetFormGroup.controls['unitcost'].value * this.budgetFormGroup.controls['amount'].value);
    this.totalp = this.budgetFormGroup.controls['totalp'].value;
  }

  //==========================================================================================================
  //Función para calcular el total de costos
  //==========================================================================================================
  async recalculateTotalSuma() {
    this.subtotal = parseInt(this.budgetFormGroup.controls['totalp'].value) + this.subtotal;
    this.budgetFormGroup.controls['total'].setValue(this.subtotal);
  }


  //==========================================================================================================
  //Función para insertar un presupuesto en la base de datos
  //==========================================================================================================
  async insert() {
    //Se manda a llamar el servicio de presupesto
    this.budgetService.insertBudget(this.budgetFormGroup.value)
      .subscribe(
        res => {
          //Se recupera el ID generado en la BD para el presupuesto nuevo registrado
          this.lastIDBudget = res

          //Se pasa a registrar la relación de partida y productos en la BD
          this.insertProducts();
        },
        err => console.error(err)
      );
  }

  //==========================================================================================================
  //Función para insertar un responsable en la tabla presupuestos
  //==========================================================================================================
  async insertResponsable() {
    this.budgetService.getRegister(this.getUser())
      .subscribe(
        res => {

          this.responsable = res
          console.log(this.responsable)
          this.budgetFormGroup.controls['responsable'].setValue(this.responsable.creator);
        },
        err => console.error(err)
      );
  }

  //==========================================================================================================
  //Función para insertar los productos de las partidas
  //==========================================================================================================
  async insertProducts() {
    //Si la información actual del formulario no es valida según el constructor de arriba se genera un retorno
    if (this.budgetFormGroup.invalid) {
      return;
      //Si la información es correcta se comienza el proceso de registro de la relación partida-productos
    } else {
      //Este ciclo for recorre el arreglo de materiales generados en el registro de productos
      for (let p of this.materials) {
        //En la lista del formulario se registra la información separada por el simbolo '~'
        //iddeparture ~ idproduct ~ productname ~ amount ~ unit
        let prod = p.split("~");

        this.budgetService.insertProductsBudget(this.lastIDBudget, prod[0], prod[1], prod[3], prod[6], prod[5], prod[7], this.decode.email)
          .subscribe(
            res => {
              //Generación de alerta sobre registro exitoso de un presupuesto completo
              this.sweet.AlertTime("center", "success", "Presupuesto registrado con éxito", false, 2000);
              //Se genera un refresh de la página de registro
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['BudgetRegister']));
            },
            err => console.error(err)
          );
      }
    }
  }

  //==========================================================================================================
  //Función para consultar las torres del proyecto seleccionado en el formulario
  //==========================================================================================================
  async selectProject(id: number) {
    //Colocamos en blanco por default el combobox de modulo antes de asignar los correspondientes
    this.budgetFormGroup.controls['idprojectdet'].setValue('');

    this.projectDetailService.selectDetailsById(id).subscribe(
      res => {
        //Recuperamos la información de torres del proyecto
        this.towers = res;
      }
    );
  }
  //==========================================================================================================
  //Función para buscar los conceptos por partidas
  //==========================================================================================================
  async selectConcepts(id: any) {
    this.concetpsService.selectConcepts(id.iddeparture).subscribe(
      res => {
        this.concepts = res;
      }
    );
  }

  //==========================================================================================================
  //Función para buscar las partidas registradas en la base de datos
  //==========================================================================================================
  async selectDepartures() {
    this.departureService.getDepartureList().subscribe(
      res => {
        //Se recuperan todas las partidas en la variable departures
        this.departures = res;
        console.log(this.departures[0].departurename);
      },
      err => console.log(err)
    );
  }

  //==========================================================================================================
  //Función para buscar un material por ID según la selección del select del formulario
  //==========================================================================================================
  async selectProduct(id: any) {
    //El objeto select tiene de valor el ID y el nombre separados por coma
    //idproduct , productname
    let ide = id.productname.split("-");

    this.productCatalogService.selectProduct(parseInt(ide[0]))
      .subscribe(
        res => {
          //Recuperamos el valor en la variable product
          this.product = res;

          //Asignamos al objeto del formulario con controlName "unit" el valor recuperado de la consulta
          this.budgetFormGroup.controls['unit'].setValue(this.product.unit);
        }
      );
  }

  //==========================================================================================================
  //Función para buscar los materiales registrados en el catalogo de la BD
  //==========================================================================================================
  async selectProducts() {
    this.productCatalogService.getNamesOfProducts()
      .subscribe(
        res => {
          //Se recuperan los productos en la variable products
          this.products = res;
        },
        err => console.log(err)
      );
  }

  //==========================================================================================================
  //Función para buscar los presupuestos registrados en el catalogo de la BD
  //==========================================================================================================
  async selectBudgets() {
    this.budgetService.selectBudget()
      .subscribe(
        res => {
          //Se recuperan los productos en la variable products
          this.budgets = res;
        },
        err => console.log(err)
      );
  }
  //=========================================================================================================
  //Función para abrir un dialog para registro de producto
  //=========================================================================================================
  async insertProductDialog() {
    const dialogRef = this.dialog.open(DialogInsertproductsComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '500px'
    });

    //===================================================================================================
    //Hacer referencia al evento en el que se cierra el dialog
    //===================================================================================================
    dialogRef.afterClosed().subscribe(
      res => {
        this.selectProducts();
      }
    );
  }

  //==========================================================================================================
  //Función para agregar materiales de presupuesto a una lista temporal
  //==========================================================================================================
  async addMaterial() {
    if (this.budgetFormGroup.controls['iddeparture'].value == null || this.budgetFormGroup.controls['iddeparture'].value == ''
      || this.budgetFormGroup.controls['productname'].value == null || this.budgetFormGroup.controls['productname'].value == ''
      || this.budgetFormGroup.controls['amount'].value == null || this.budgetFormGroup.controls['amount'].value == ''
      || this.budgetFormGroup.controls['unit'].value == null || this.budgetFormGroup.controls['unit'].value == '') {
      this.sweet.AlertTime("center", "error", "Por favor complete los campos de partida, producto y cantidad correctamente.", false, 2000);
    } else {
      let addMaterial = this.budgetFormGroup.value;
      if (addMaterial !== '') {
        let name = addMaterial.productname.productname.split("~");
        let dep = addMaterial.iddeparture.departurename.split("~");
        console.log(addMaterial.idconcept.idconcept);
        console.log(addMaterial.idconcept.descriptions);

        this.materials.push(addMaterial.iddeparture.iddeparture + '~' + addMaterial.idconcept.idconcept + '~' + addMaterial.idconcept.descriptions + '~' + name[0] + '~' + name[1] + '~' + addMaterial.unitcost + '~' + addMaterial.amount + '~' + addMaterial.totalp + '~' + dep[0] + '~' + addMaterial.unit);
        this.recalculateTotalSuma();

        //Se resetea la información de los campos de texto para productos
        this.budgetFormGroup.controls['iddeparture'].setValue('');
        this.budgetFormGroup.controls['idconcept'].setValue('');
        this.budgetFormGroup.controls['productname'].setValue('');
        this.budgetFormGroup.controls['amount'].setValue('');
        this.budgetFormGroup.controls['unit'].setValue('');
        this.budgetFormGroup.controls['unitcost'].setValue('');
        this.budgetFormGroup.controls['totalp'].setValue('');

      }
    }
  }

  //==========================================================================================================
  //Función para borrar materiales de presupuesto de la lista temporal
  //==========================================================================================================
  async deleteMaterials(i: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, elimínalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'El producto se quitó de la requisición.',
          'success'
        )
        //Se crea una variable temporal para almacenar los materiales que permanecerían en la lista
        const materialsT = Array();
        let indice = 0;

        //Se recorre la lista de materiales con un ciclo for
        for (const mat of this.materials) {
          //Se compara el indice que se quiere eliminar con el de la lista y si no coincide se almacena en la lista
          //temporal
          if (indice !== i) {
            materialsT.push(mat);
          }

          if (indice == i) {
            this.subtotal = this.subtotal - mat.split('~')[7];
            this.budgetFormGroup.controls['total'].setValue(this.subtotal);
          }
          indice++;
        }
        //Se pasa el valor de la lista temporal a la lista original de materiales
        this.materials = materialsT;


      }
    })
  }

  //==========================================================================================================
  //Función para recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.email
  }


  //==========================================================================================================
  //                            REGISTRO DE PRESUPUESTO DE DESTAJOS
  //==========================================================================================================

  //==========================================================================================================
  //Objeto constructor del formulario utilizado en la pantalla de presupuesto, se agrega el código
  //", Validators.required" para señalar que es un valor que no debe estar vacío
  //==========================================================================================================
  budgetpieceFormGroup: FormGroup = this._formBuilder.group({
    idProject: [, Validators.required],
    idprojectdet: [, Validators.required],
    budgetdate: [, Validators.required],
    iddeparture: [],
    idpiecework: [],
    typepw: [],
    unit: [],
    namepw: [],
    unitcost: [],
    amount: [],
    total: [],
    totalp: [this.totalp],
    analyst: [],
    responsable: [],
    approved: [],
    useremail: [this.getUser()]
  });

  //==========================================================================================================
  //Función para recalcular el total obtenido de la multiplicación
  //==========================================================================================================
  async precalculateTotal() {
    this.budgetpieceFormGroup.controls['totalp'].setValue(this.budgetpieceFormGroup.controls['unitcost'].value * this.budgetpieceFormGroup.controls['amount'].value);
    this.totalp = this.budgetpieceFormGroup.controls['totalp'].value;
  }

  //==========================================================================================================
  //Función para recalcular el total haciendo la suma de los costos totales de los destajos
  //==========================================================================================================
  async precalculateTotalSuma() {
    this.subtotal = parseInt(this.budgetpieceFormGroup.controls['totalp'].value) + this.subtotal;
    this.budgetpieceFormGroup.controls['total'].setValue(this.subtotal);
  }


  //==========================================================================================================
  //Función para insertar un presupuesto en la base de datos
  //==========================================================================================================
  async pinsert() {
    //Se manda a llamar el servicio de presupesto
    this.budgetpieceworkService.insertBudget(this.budgetpieceFormGroup.value)
      .subscribe(
        res => {
          //Se recupera el ID generado en la BD para el presupuesto nuevo registrado
          this.lastIDBudget = res

          //Se pasa a registrar la relación de partida y productos en la BD
          this.insertPieceworks();
        },
        err => console.error(err)
      );
  }

  //==========================================================================================================
  //Función para insertar un responsable en la tabla presupuestos
  //==========================================================================================================
  async pinsertResponsable() {
    this.budgetService.getRegister(this.getUser())
      .subscribe(
        res => {

          this.responsable = res
          console.log(this.responsable)
          this.budgetpieceFormGroup.controls['responsable'].setValue(this.responsable.creator);
        },
        err => console.error(err)
      );
  }

  //==========================================================================================================
  //Función para insertar los productos de las partidas
  //==========================================================================================================
  async insertPieceworks() {
    //Si la información actual del formulario no es valida según el constructor de arriba se genera un retorno
    if (this.budgetpieceFormGroup.invalid) {
      return;
      //Si la información es correcta se comienza el proceso de registro de la relación partida-productos
    } else {
      //Este ciclo for recorre el arreglo de materiales generados en el registro de productos
      for (let p of this.pieces) {
        console.log("Estos son los destajos"+this.pieces);
        //En la lista del formulario se registra la información separada por el simbolo '~'
        //iddeparture ~ idproduct ~ productname ~ amount ~ unit

        let prod = p.split("~");
        //0.-iddeparture 1.-name[0] 2.-name[1] 3.-unitcost 4.-amount + 5.-totalp 6.- dep[0] 7.-addMaterial.unit
        this.budgetpieceworkService.insertPieceworksBudget(this.lastIDBudget, prod[0], prod[1], prod[4], prod[3], prod[5], this.decode.email)
          .subscribe(
            res => {
              //Generación de alerta sobre registro exitoso de un presupuesto completo
              this.sweet.AlertTime("center", "success", "Presupuesto registrado con éxito", false, 2000);
              //Se genera un refresh de la página de registro
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['BudgetRegisterPiecework']));
            },
            err => console.error(err)
          );
      }
    }
  }

  //==========================================================================================================
  //Función para consultar las torres del proyecto seleccionado en el formulario
  //==========================================================================================================
  async pselectProject(id: number) {
    //Colocamos en blanco por default el combobox de modulo antes de asignar los correspondientes
    this.budgetpieceFormGroup.controls['idprojectdet'].setValue('');

    this.projectDetailService.selectDetailsById(id).subscribe(
      res => {
        //Recuperamos la información de torres del proyecto
        this.towers = res;
      }
    );
  }

  //==========================================================================================================
  //Función para buscar un destajo por ID según la selección del select del formulario
  //==========================================================================================================
  async selectPiecework(id: any) {
    //El objeto select tiene de valor el ID y el nombre separados por coma
    //
    let ide = id.pieceworkname.split("-")
    console.log("Objeto del ide"+ide);
    this.pieceworkService.selectPiecework(parseInt(ide[0])).subscribe(
        res => {
          //Recuperamos el valor en la variable product
          this.piecework = res;
          console.log(this.piecework);
          //Asignamos al objeto del formulario con controlName "unit" el valor recuperado de la consulta
          this.budgetpieceFormGroup.controls['unit'].setValue(this.piecework.unit);
        }
      );
  }

  //==========================================================================================================
  //Función para buscar los materiales registrados en el catalogo de la BD
  //==========================================================================================================
  async selectPieceworks() {
    this.pieceworkService.getNamesOfPieceworks()
      .subscribe(
        res => {
          //Se recuperan los productos en la variable destajos
          this.pieceworks = res;
          console.log(this.pieceworks);
        },
        err => console.log(err)
      );
  }

  //==========================================================================================================
  //Función para agregar destajos de presupuesto a una lista temporal
  //==========================================================================================================
  async addPiecework() {
    if (this.budgetpieceFormGroup.controls['iddeparture'].value == null || this.budgetpieceFormGroup.controls['iddeparture'].value == ''
      || this.budgetpieceFormGroup.controls['typepw'].value == null || this.budgetpieceFormGroup.controls['typepw'].value == ''
      || this.budgetpieceFormGroup.controls['amount'].value == null || this.budgetpieceFormGroup.controls['amount'].value == ''
      || this.budgetpieceFormGroup.controls['unit'].value == null || this.budgetpieceFormGroup.controls['unit'].value == '') {
      this.sweet.AlertTime("center", "error", "Por favor complete los campos de partida, destajo y cantidad correctamente.", false, 2000);
    } else {
      let addPiecework = this.budgetpieceFormGroup.value;
      if (addPiecework !== '') {
        console.log(addPiecework.typepw.idconcept);
        console.log(addPiecework.typepw.descriptions);
        let namepw = addPiecework.typepw.pieceworkname.split("~");
        let dep = addPiecework.iddeparture.departurename.split("~");
        //let pie = addPiecework.idconcept.descriptions.split("~");
        this.pieces.push(addPiecework.iddeparture.iddeparture + '~' + namepw[0] + '~' + namepw[1] + '~' + addPiecework.unitcost + '~' + addPiecework.amount + '~' + addPiecework.totalp + '~' + dep[0] + '~' + addPiecework.unit);
        this.precalculateTotalSuma();

        //Se resetea la información de los campos de texto para productos
        this.budgetpieceFormGroup.controls['iddeparture'].setValue('');
        this.budgetpieceFormGroup.controls['typepw'].setValue('');
        this.budgetpieceFormGroup.controls['amount'].setValue('');
        this.budgetpieceFormGroup.controls['unit'].setValue('');
        this.budgetpieceFormGroup.controls['unitcost'].setValue('');
        this.budgetpieceFormGroup.controls['totalp'].setValue('');
      }
      console.log('Objeto del presupuesto completo'+this.pieces);
    }
  }

  //==========================================================================================================
  //Función para borrar destajos de presupuesto de la lista temporal
  //==========================================================================================================
  async deletepieces(i: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, elimínalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'El producto se quitó de la requisición.',
          'success'
        )
        //Se crea una variable temporal para almacenar los destajos que permanecerían en la lista
        const piecesT = Array();
        let indice = 0;

        //Se recorre la lista de destajos con un ciclo for
        for (const mat of this.pieces) {
          //Se compara el indice que se quiere eliminar con el de la lista y si no coincide se almacena en la lista
          //temporal
          if (indice !== i) {
            piecesT.push(mat);
          }

          if (indice == i) {
            this.subtotal = this.subtotal - mat.split('~')[7];
            this.budgetpieceFormGroup.controls['total'].setValue(this.subtotal);
          }
          indice++;
        }
        //Se pasa el valor de la lista temporal a la lista original de destajos
        this.pieces = piecesT;


      }
    })
  }
}
