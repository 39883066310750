<div class="container">
  <div class="row">
    <div id="htmlData">


      <img style=" display: block; margin-left: auto;   margin-right: auto;  width: 20%;" class="center" width="20%"
        alt="Logo" src="../assets/isotipo.png">

      <br>
      <br>

      <div style="width: 100%; border: solid 1px black; text-align: center;">
        SOLICITUD DE ELABORACION DE CONTRATO
      </div>

      <br>

      <div style="display: flex; justify-content: space-between; height: 30px;">
        <div style="width: 200px; border: solid black 1px; vertical-align: center;">
          FECHA:
        </div>
        <p style="margin-top: -2px;">
          Por conducto de representante
        </p>
        <div style="width: 100px; border: solid black 1px; ">
          SI
        </div>
        <div style="width: 100px; border: solid black 1px; ">
          NO
        </div>
      </div>


      <br>
      <br>


      <table style="border: 1px solid black; border-collapse: collapse; width: 100%;">
        <tr style="text-align: center;">
          <td colspan="2">DATOS DEL INMUEBLE</td>
        </tr>
        <tr>
          <td style="border: 1px solid black; border-collapse: collapse;">DESARROLLO</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">CASA BLANCA</td>
        </tr>

        <tr>
          <td style="border: 1px solid black;  border-collapse: collapse;">MANZANA</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">14</td>
        </tr>
        <tr>
          <td style="border: 1px solid black; border-collapse: collapse;">LOTE</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">01</td>
        </tr>
      </table>


      <br>
      <br>


      <table style="border: 1px solid black; border-collapse: collapse; width: 100%;">
        <tr style="text-align: center;">
          <td colspan="4">DATOS DEL CLIENTE</td>
        </tr>
        <tr>
          <td style="border: 1px solid black; border-collapse: collapse;">NOMBRE COMPLETO</td>
          <td colspan="3" style="border: 1px solid black;  border-collapse: collapse;">RAMIREZ SANDOVAL MARCO ANTONIO
          </td>
        </tr>
        <tr>
          <td style="border: 1px solid black;  border-collapse: collapse;">NACIONALIDAD</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">MEXICANA</td>
          <td style="border: 1px solid black; border-collapse: collapse;">ESTADO CIVIL</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">CASADO</td>
        </tr>
        <tr>
          <td style="border: 1px solid black; border-collapse: collapse;">LUGAR DE NACIMIENTO</td>
          <td colspan="3" style="border: 1px solid black;  border-collapse: collapse;">TEPIC, NAYARIT, MEXICO</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;  border-collapse: collapse;">DOMICILIO</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">AV. DE LA CULTURA 141</td>
          <td style="border: 1px solid black; border-collapse: collapse;">C.P.</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">63157</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;  border-collapse: collapse;">COLONIA</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">CD DEL VALLE</td>
          <td style="border: 1px solid black; border-collapse: collapse;">ESTADO</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">NAYARIT</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;  border-collapse: collapse;">MUNICIPIO</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">TEPIC</td>
          <td style="border: 1px solid black; border-collapse: collapse;">CELULAR</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">3111503919</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;  border-collapse: collapse;">CLAVE DE ELECTOR</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">12345678910111213</td>
          <td style="border: 1px solid black; border-collapse: collapse;">CURP</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">RASM980816HMNTR07</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;  border-collapse: collapse;">CORREO</td>
          <td colspan="3" style="border: 1px solid black;  border-collapse: collapse;">MAANRAMIREZSA@ITTEPIC.EDU.MX</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;  border-collapse: collapse;">RFC</td>
          <td colspan="3" style="border: 1px solid black;  border-collapse: collapse;">MNRS07164H007</td>
        </tr>
      </table>


      <br>
      <br>

      <table style="border: 1px solid black; border-collapse: collapse; width: 100%;">
        <tr style="text-align: center;">
          <td colspan="4" style="border: 1px solid black; border-collapse: collapse;">DATOS DEL CONTRATO</td>
        </tr>
        <tr style="text-align: center;">
          <td colspan="4" style="border: 1px solid black; border-collapse: collapse;">IMPORTES</td>
        </tr>
        <tr>
          <td style="border: 1px solid black; border-collapse: collapse;">INMUEBLE</td>
          <td colspan="3" style="border: 1px solid black;  border-collapse: collapse;">CBM14L01</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;  border-collapse: collapse;">IMPORTE TOTAL</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">300000</td>
          <td style="border: 1px solid black; border-collapse: collapse;">FECHA</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">16/08/2022</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;  border-collapse: collapse;">ANTICIPO</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">5000</td>
          <td style="border: 1px solid black; border-collapse: collapse;">FECHA</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">16/08/2022</td>
        </tr>
        <tr>
          <td style="border: 1px solid black;  border-collapse: collapse;">MENSUALIDADES</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">2000</td>
          <td style="border: 1px solid black; border-collapse: collapse;">A PARTIR DE LA FECHA</td>
          <td style="border: 1px solid black;  border-collapse: collapse;">16/09/2022</td>
        </tr>
      </table>


      <br>
      <br>


      <table style="border: 1px solid black; border-collapse: collapse; width: 100%;">
        <tr style="text-align: center;">
          <td  style="border: 1px solid black; border-collapse: collapse;" colspan="2">COLINDANCIAS</td>
        </tr>
        <tr>
          <td style="border: 1px solid black; border-collapse: collapse;">21.73 METROS CON LOTE MARCADO
            ECONOMICAMENTE CON EL NUMERO 10</td>
          <td style="width: 40%" rowspan="4"> 
            <img style=" display: block; width: 100%;" 
            alt="Logo" src="https://firebasestorage.googleapis.com/v0/b/m19inmobiliaria-f6068.appspot.com/o/Lotificaciones%2FCasa%20Blanca%2FCBM11L5.png?alt=media&token=ac7f766e-81ed-458c-a3c1-cc13098efbad">
          </td>
        </tr>
        <tr>
          <td style="border: 1px solid black;  border-collapse: collapse;">21.73 METROS CON LOTE MARCADO
            ECONOMICAMENTE CON EL NUMERO 10</td>
        </tr>
        <tr>
          <td style="border: 1px solid black; border-collapse: collapse;">21.73 METROS CON LOTE MARCADO
            ECONOMICAMENTE CON EL NUMERO 10</td>
        </tr>
        <tr>
          <td style="border: 1px solid black; border-collapse: collapse;">21.73 METROS CON LOTE MARCADO
            ECONOMICAMENTE CON EL NUMERO 10</td>
        </tr>
      </table>





    </div>
  </div>
</div>

<br>
<br>

<div>
  <button class="btn col col-lg-4 btn-danger btn-block ml-4" (click)="printer()">Download PDF</button>
</div> 