import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TotalPagadosService{

    private url = 'http://localhost:3000/api';


    constructor(private http: HttpClient) { }

    totalPagados(): Observable<any>{
        return this.http.get(`${environment.URLServer}/totalPagados`);
      }
      selectFolio(folio:number): Observable<any>{
        return this.http.get(`${environment.URLServer}/totalPagados/folios/${folio}`);
      }
    totalPagadosListado(): Observable<any>{
        return this.http.get(`${environment.URLServer}/totalPagados/listado/`);
      }
    selectPagosDesarrollo(liDes:number): Observable<any>{
        return this.http.get(`${environment.URLServer}/totalPagados/listado/${liDes}`);
      }
}