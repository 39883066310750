import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { reasonpiecework } from '../models/ReasonPiecework';

@Injectable({
  providedIn: 'root'
})
export class ReasonpieceworkService {

  constructor(private http: HttpClient) { }

  //Registrar razón
  insertReason(id:number, rea: string, use:string): Observable<any> {
    return this.http.post(`${environment.URLServer}/reasonpiece/insertReason`,[id, rea, use]);
  }
}