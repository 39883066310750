import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import decode from 'jwt-decode'
import { AuthService } from 'src/app/services/auth.service';

import { SharedServiceService } from 'src/app/shared/shared-service.service';

import Swal from 'sweetalert2';

@Component({
    selector: 'app-sidebar-content',
    templateUrl: './sidebar-content.component.html',
    styleUrls: ['./sidebar-content.component.scss']
})
export class SidebarContentComponent implements OnInit {

    panelOpenState: boolean = false;
    actualDate = new Date();

    html: string = `
  <style>
      .container {
          width: 800px;
          height: 420px;
          padding: 10px;
          margin: 0 auto;
          position: relative;
      }
      .balloon {
          width: 738px;
          margin: 0 auto;
          padding-top: 30px;
          position: relative;
      }
      .balloon > div {
          width: 104px;
          height: 140px;
          background: rgba(182, 15, 97, 0.9);
          border-radius: 0;
          border-radius: 80% 80% 80% 80%;
          margin: 0 auto;
          position: absolute;
          padding: 10px;
          box-shadow: inset 17px 7px 10px rgba(182, 15, 97, 0.9);
          -webkit-transform-origin: bottom center;
      }
      .balloon > div:nth-child(1) {
          background: #D33E3E;
          left: 0;
          box-shadow: inset 10px 10px 10px rgba(135, 11, 72, 0.9);
          -webkit-animation: balloon1 6s ease-in-out infinite;
          -moz-animation: balloon1 6s ease-in-out infinite;
          -o-animation: balloon1 6s ease-in-out infinite;
          animation: balloon1 6s ease-in-out infinite;
      }
      .balloon > div:nth-child(1):before {
          color: rgba(182, 15, 97, 0.9);
      }
      .balloon > div:nth-child(2) {
          background: rgba(242, 112, 45, 0.9);
          left: 120px;
          box-shadow: inset 10px 10px 10px rgba(222, 85, 14, 0.9);
          -webkit-animation: balloon2 6s ease-in-out infinite;
          -moz-animation: balloon2 6s ease-in-out infinite;
          -o-animation: balloon2 6s ease-in-out infinite;
          animation: balloon2 6s ease-in-out infinite;
      }
      .balloon > div:nth-child(2):before {
          color: rgba(242, 112, 45, 0.9);
      }
      .balloon > div:nth-child(3) {
          background: rgba(45, 181, 167, 0.9);
          left: 240px;
          box-shadow: inset 10px 10px 10px rgba(35, 140, 129, 0.9);
          -webkit-animation: balloon4 6s ease-in-out infinite;
          -moz-animation: balloon4 6s ease-in-out infinite;
          -o-animation: balloon4 6s ease-in-out infinite;
          animation: balloon4 6s ease-in-out infinite;
      }
      .balloon > div:nth-child(3):before {
          color: rgba(45, 181, 167, 0.9);
      }
      .balloon > div:nth-child(4) {
          background: rgba(190, 61, 244, 0.9);
          left: 360px;
          box-shadow: inset 10px 10px 10px rgba(173, 14, 240, 0.9);
          -webkit-animation: balloon1 5s ease-in-out infinite;
          -moz-animation: balloon1 5s ease-in-out infinite;
          -o-animation: balloon1 5s ease-in-out infinite;
          animation: balloon1 5s ease-in-out infinite;
      }
      .balloon > div:nth-child(4):before {
          color: rgba(190, 61, 244, 0.9);
      }
      .balloon > div:nth-child(5) {
          background: rgba(180, 224, 67, 0.9);
          left: 480px;
          box-shadow: inset 10px 10px 10px rgba(158, 206, 34, 0.9);
          -webkit-animation: balloon3 5s ease-in-out infinite;
          -moz-animation: balloon3 5s ease-in-out infinite;
          -o-animation: balloon3 5s ease-in-out infinite;
          animation: balloon3 5s ease-in-out infinite;
      }
      .balloon > div:nth-child(5):before {
          color: rgba(180, 224, 67, 0.9);
      }
      .balloon > div:nth-child(6) {
          background: rgba(242, 194, 58, 0.9);
          left: 600px;
          box-shadow: inset 10px 10px 10px rgba(234, 177, 15, 0.9);
          -webkit-animation: balloon2 3s ease-in-out infinite;
          -moz-animation: balloon2 3s ease-in-out infinite;
          -o-animation: balloon2 3s ease-in-out infinite;
          animation: balloon2 3s ease-in-out infinite;
      }
      .balloon > div:nth-child(6):before {
          color: rgba(242, 194, 58, 0.9);
      }
      .balloon > div:before {
          color: rgba(182, 15, 97, 0.9);
          position: absolute;
          bottom: -11px;
          left: 52px;
          content:"▲";
          font-size: 1em;
      }
      span {
          font-size: 3em;
          color: white;
          position: relative;
          top: 30px;
          left: 15%;
          margin-left: -27px;
      }
      /*BALLOON 1 4*/
      @-webkit-keyframes balloon1 {
          0%, 100% {
              -webkit-transform: translateY(0) rotate(-6deg);
          }
          50% {
              -webkit-transform: translateY(-20px) rotate(8deg);
          }
      }
      @-moz-keyframes balloon1 {
          0%, 100% {
              -moz-transform: translateY(0) rotate(-6deg);
          }
          50% {
              -moz-transform: translateY(-20px) rotate(8deg);
          }
      }
      @-o-keyframes balloon1 {
          0%, 100% {
              -o-transform: translateY(0) rotate(-6deg);
          }
          50% {
              -o-transform: translateY(-20px) rotate(8deg);
          }
      }
      @keyframes balloon1 {
          0%, 100% {
              transform: translateY(0) rotate(-6deg);
          }
          50% {
              transform: translateY(-20px) rotate(8deg);
          }
      }
      /* BAllOON 2 5*/
      @-webkit-keyframes balloon2 {
          0%, 100% {
              -webkit-transform: translateY(0) rotate(6eg);
          }
          50% {
              -webkit-transform: translateY(-30px) rotate(-8deg);
          }
      }
      @-moz-keyframes balloon2 {
          0%, 100% {
              -moz-transform: translateY(0) rotate(6deg);
          }
          50% {
              -moz-transform: translateY(-30px) rotate(-8deg);
          }
      }
      @-o-keyframes balloon2 {
          0%, 100% {
              -o-transform: translateY(0) rotate(6deg);
          }
          50% {
              -o-transform: translateY(-30px) rotate(-8deg);
          }
      }
      @keyframes balloon2 {
          0%, 100% {
              transform: translateY(0) rotate(6deg);
          }
          50% {
              transform: translateY(-30px) rotate(-8deg);
          }
      }
      /* BAllOON 0*/
      @-webkit-keyframes balloon3 {
          0%, 100% {
              -webkit-transform: translate(0, -10px) rotate(6eg);
          }
          50% {
              -webkit-transform: translate(-20px, 30px) rotate(-8deg);
          }
      }
      @-moz-keyframes balloon3 {
          0%, 100% {
              -moz-transform: translate(0, -10px) rotate(6eg);
          }
          50% {
              -moz-transform: translate(-20px, 30px) rotate(-8deg);
          }
      }
      @-o-keyframes balloon3 {
          0%, 100% {
              -o-transform: translate(0, -10px) rotate(6eg);
          }
          50% {
              -o-transform: translate(-20px, 30px) rotate(-8deg);
          }
      }
      @keyframes balloon3 {
          0%, 100% {
              transform: translate(0, -10px) rotate(6eg);
          }
          50% {
              transform: translate(-20px, 30px) rotate(-8deg);
          }
      }
      /* BAllOON 3*/
      @-webkit-keyframes balloon4 {
          0%, 100% {
              -webkit-transform: translate(10px, -10px) rotate(-8eg);
          }
          50% {
              -webkit-transform: translate(-15px, 20px) rotate(10deg);
          }
      }
      @-moz-keyframes balloon4 {
          0%, 100% {
              -moz-transform: translate(10px, -10px) rotate(-8eg);
          }
          50% {
              -moz-transform: translate(-15px, 10px) rotate(10deg);
          }
      }
      @-o-keyframes balloon4 {
          0%, 100% {
              -o-transform: translate(10px, -10px) rotate(-8eg);
          }
          50% {
              -o-transform: translate(-15px, 10px) rotate(10deg);
          }
      }
      @keyframes balloon4 {
          0%, 100% {
              transform: translate(10px, -10px) rotate(-8eg);
          }
          50% {
              transform: translate(-15px, 10px) rotate(10deg);
          }
      }
      h1 {
          position: relative;
          top: 200px;
          text-align: center;
          color: white;
          font-size: 1em;
      }
  </style>
  <script>
      window.open = function() {};
      window.print = function() {};
      // Support hover state for mobile.
      if (false) {
          window.ontouchstart = function() {};
      }
  </script>
  <script type="text/javascript" src="chrome-extension://bfbmjmiodbnnpllbbbfblcplfjjepjdn/js/injected.js"></script>

  <div class="container">
      <div class="balloon">
          <div><span>M19</span>
          </div>
          <div><span>` + (this.actualDate.getFullYear() - 2020) + `</span>
          </div>
          <div><span>A</span>
          </div>
          <div><span>Ñ</span>
          </div>
          <div><span>O</span>
          </div>
          <div><span>S</span>
          </div>
      </div>
       <h1><b style='color: #D33E3E;'>M19 Inmobiliaria</b> se encuentra cumpliendo su aniversario número ` + (this.actualDate.getFullYear() - 2020) + `, estamos orgullosos de seguir formando parte de la vida de cientos de familias. <br><br>¡Gracias por formar parte de este gran proyecto!
       <br><em>"Tu hogar, nuestro sueño"</em>
       </h1>

  </div>
  <script>
      
  </script>
  <script>
      if (document.location.search.match(/type=embed/gi)) {
          window.parent.postMessage('resize', "*");
      }
  </script>`;

    htmlNewLocation: string = `
  <style>
  .container {
      width: 100%;
      height: 420px;
      padding: 10px;
      margin: 0 auto;
      position: relative;
      overflow: scroll;
  }

  .container .alertIcon {
    width: 20%;
  }

  .attention {
    color: red;
  }

  .message {
    color: white;
    text-align: justify;
  }

  </style>

    <div class="container">
        <img class ="alertIcon" src = "../../../../assets/alertIcon.png" alt="Warning">

        <h1 class="attention">¡Que no te tome desprevenido!</h1>
        <br>
        <p class="message">
            Nuestras oficinas han cambiado de ubicación, te esperamos
            en Portugal 67-A, entre las calles Nápoles y Roma en ciudad del valle,
            ahora esta será la ubicación donde puedes realizar tus pagos y consultar
            información relacionada a tu inmueble.
        </p>

        <span class="message">
            M19 Inmobiliaria agradece tu preferencia.
            <br>
            Tu hogar, nuestro sueño.
        </span>
        <br>
        <a href="https://www.google.com/maps/d/u/3/edit?hl=es-419&mid=1AQ83bmu6AFan9O1r1SQL7xhq0100uOc&ll=21.487827387532256%2C-104.88719766994058&z=20" target="_blank">Ver ubicación en el mapa</a>
    </div>
  `;

    fecha = new Date();

    currentLogoIndex: number = 0;
    logoPaths: any = ["../assets/iconoMBlanco.png", '../assets/altaresIcon.png', '../assets/xaltaIcon.png', '../assets/livertiIcon.png', '../assets/boscoIcon.png', '../assets/xalterIcon.png'];
    currentLogo: string = this.logoPaths[this.currentLogoIndex];

    constructor(public router: Router, private authService: AuthService, private sharedService: SharedServiceService, private changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        if (localStorage.getItem("christmasView") != "1" && (((this.fecha.getMonth() + 1) == 12) && (this.fecha.getDate() == 22 || this.fecha.getDate() == 23 || this.fecha.getDate() == 24 || this.fecha.getDate() == 25))) {
            localStorage.setItem("christmasView", "0");
            Swal.fire({
                imageUrl: '../assets/felizNavidad.jpg',
                background: 'rgba(0,0,0,0)',
                backdrop: `rgba(103,0,0,0.8) url(../assets/nieve.gif)`,
                confirmButtonColor: '#009216',
                confirmButtonText: 'Continuar!'
            })
            localStorage.setItem("christmasView", "1");
        }

        if (localStorage.getItem("newyearView") != "1" && (((this.fecha.getMonth() + 1) == 12) && (this.fecha.getDate() == 28 || this.fecha.getDate() == 29 || this.fecha.getDate() == 30 || this.fecha.getDate() == 31))) {
            localStorage.setItem("newyearView", "0");
            Swal.fire({
                html: '<img class="newYearLogo" src="../assets/logoCromado.png"><p style="color: white; font-size: 1.5rem; font-weight: bold; text-shadow: 10px 10px 10px gray;">Te desea un</p><p style="color: white; font-size: 2rem; font-weight: bold; color: white; text-shadow: 10px 10px 10px red;">Feliz y prospero año nuevo</p>',
                width: '100%',
                backdrop: 'rgba(0,0,0,0.8) url(../assets/fireworks.gif)',
                background: 'rgba(0,0,0,0)',
                confirmButtonText: '🥂',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'newYearBtn'
                }
            })
            localStorage.setItem("newyearView", "1");
        }

        if ((((this.fecha.getMonth() + 1) == 6))) {
            localStorage.setItem("anniversary2023", "0");
            Swal.fire({
                html: this.html,
                width: '100%',
                background: 'rgba(0,0,0,0.5)',
                confirmButtonText: '🎉',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'yearBtn'
                }
            })
            localStorage.setItem("anniversary2023", "0");
        }

        if (this.fecha.getMonth() + 1 == 6) {
            setInterval(() => {
                this.currentLogoIndex = (this.currentLogoIndex + 1) % this.logoPaths.length;
                this.currentLogo = this.logoPaths[this.currentLogoIndex];
                this.changeDetectorRef.detectChanges();
            }, 2000);
        }

        // if (this.fecha.getMonth() + 1 == 11) {
        //     localStorage.setItem("reubication", "0");
        //     Swal.fire({
        //         html: this.htmlNewLocation,
        //         width: '100%',
        //         background: 'rgba(0,0,0,0.5)',
        //         confirmButtonText: '👍',
        //         buttonsStyling: false,
        //         customClass: {
        //             confirmButton: 'yearBtn'
        //         }
        //     })
        //     // localStorage.setItem("anniversary2023", "1");
        // }
    }

    setPage(page: string) {
        this.sharedService.page = page;
        localStorage.setItem("page", this.sharedService.page);
    }

    permissions() {
        const token = localStorage.getItem('token') || "";

        let decodetoken: any = {};
        decodetoken = decode(token);
        return decodetoken.permissions;
    }

    menuItem() {
        if (localStorage.getItem('token')) {
            return true;
        }
        return false;
    }

    //Inicio de sesion dinamico
    userPermissions() {
        const token = localStorage.getItem('token') || "";
        let decodetoken: any = {};
        decodetoken = decode(token);
        let user = {
            email: decodetoken.email,
            password: decodetoken.password,
        }
        this.logIn(user)
    }

    async logIn(user: any) {
        let decodetoken: any = {};
        this.authService.singin(user).subscribe((res: any) => {
            localStorage.setItem('token', res.token);
            decodetoken = decode(res.token);
        })
    }

    togglePanel(value: boolean) {
        this.panelOpenState = value;
    }
}