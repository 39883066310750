<mat-dialog-content>
<h1 style="text-align:center">Confirmacion de venta</h1>

<h2>Favor de revisar que los datos del cliente, el inmueble y la solicitud sean correctos: </h2>


<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <b>
                    <span>
                        Cliente
                    </span>
                </b>
            </mat-panel-title>

        </mat-expansion-panel-header>


        <table class="table table-striped mt-3 animated fadeIn faster">
            <tr>
                <th>CURP</th>
                <td> {{CustomerInfo.CURP}}</td>
            </tr>
            <tr>
                <th>Clave de elector</th>
                <td> {{CustomerInfo.voterKey}}</td>
            </tr>
            <tr *ngIf="CustomerInfo.RFC != null">
                <th>RFC</th>
                <td>{{CustomerInfo.RFC}}</td>
            </tr>
            <tr>
                <th>Celular</th>
                <td>{{CustomerInfo.mobile}}</td>
            </tr>
            <tr *ngIf="CustomerInfo.email != null">
                <th>Correo electrónico</th>
                <td>{{CustomerInfo.email}}</td>
            </tr>
            <tr *ngIf="CustomerInfo.birthDate != null">
                <th>Fecha de nacimiento</th>
                <td>{{CustomerInfo.birthDate}}</td>
            </tr>
            <tr>
                <th>Lugar de nacimiento</th>
                <td>{{CustomerInfo.birthPlace}}</td>
            </tr>
            <tr>
                <th>Nacionalidad</th>
                <td>{{CustomerInfo.nationality}}</td>
            </tr>
            <tr>
                <th>Ocupación</th>
                <td>{{CustomerInfo.occupation}}</td>
            </tr>
            <tr>
                <th>Estado civil</th>
                <td>{{CustomerInfo.civilStatus}}</td>
            </tr>
            <tr>
                <th>Dirección</th>
                <td>{{CustomerInfo.address}}</td>
            </tr>
            <tr>
                <th>Localidad</th>
                <td>{{CustomerInfo.localidad}}</td>
            </tr>
            <tr>
                <th>Municipio</th>
                <td>{{CustomerInfo.municipality}}</td>
            </tr>
            <tr>
                <th>Estado</th>
                <td>{{CustomerInfo.state}}</td>
            </tr>
            <tr>
                <th>Código postal</th>
                <td>{{CustomerInfo.postalCode}}</td>
            </tr>
        </table>

    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <b>
                    <span>
                        Inmueble
                    </span>
                </b>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <table class="table table-striped mt-3 animated fadeIn faster">
            <tr>
                <th>Proyecto</th>
                <td>{{propertyInfo.name}}</td>
            </tr>
            <tr>
                <th>Inmueble</th>
                <td>{{property}}</td>
            </tr>
            <tr>
                <th>Monto del inmueble</th>
                <td>$ {{propertyInfo.amount.toFixed(0)}}</td>
            </tr>
            <tr>
                <th>Colindancia 1</th>
                <td>{{propertyInfo.northeasBorder}}</td>
            </tr>
            <tr>
                <th>Colindancia 2</th>
                <td>{{propertyInfo.northwestBorder}}</td>
            </tr>
            <tr>
                <th>Colindancia 3</th>
                <td>{{propertyInfo.southeastBorder}} </td>
            </tr>
            <tr>
                <th>Colindancia 4</th>
                <td>{{propertyInfo.southwestBorder}}</td>
            </tr>
        </table>
    </mat-expansion-panel>
 
</mat-accordion>


<br>
<br>

<h5>Fecha de pago a partir de: </h5>
<h6>(Recuerda que es importante seleccionar la fecha en la que el cliente dara su primer pago.
    En caso de no estar especificada, seleccionar la fecha del dia de hoy.)
</h6>

<mat-form-field [formGroup]="customerFormGroup" appearance="outline" style="align-self: center;" (change)="valuechange()">
    <mat-label>Fecha de pago a partir del dia: </mat-label>
    <input matInput type="date" formControlName="datebefore" required>
</mat-form-field>
<br>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" [disabled]="!customerFormGroup.valid" (click)="ConfirmarVenta()">Confirmar</button>
    <button mat-button mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
</mat-dialog-content>