<h4>Generar tabla de comisiones para: </h4>
<mat-form-field appearance="fill">

    <mat-label>Proyecto</mat-label>

    <mat-select #projectSelected [(ngModel)]="nameProject" (valueChange)="nameProject = $event" required>
        <mat-option *ngFor="let project of projects" [value]="project.name">
            {{project.name}}
        </mat-option>
    </mat-select>

</mat-form-field>

<p [hidden]="nameProject == ''"> Proyecto → {{ nameProject }} </p>

<div style=" display: flex; justify-content: flex-end; ">
    <button [mat-dialog-close]="true" (click)="selectProject(); " [disabled]="nameProject == ''" mat-stroked-button color="warn"> Ok </button>
</div>