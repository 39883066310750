import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { paymentMetode } from '../models/paymentMetode';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentMetodeService {

  private url = 'http://localhost:3000/api';


  constructor(private http: HttpClient) { }

  selectMetodePayments(): Observable<any> {
    return this.http.get(`${environment.URLServer}/paymentsMetode`);
  }

  createMetodePayment(paymentMetode: paymentMetode): Observable<any> {
    return this.http.post(`${environment.URLServer}/paymentsMetode`, paymentMetode);
  }
  
  updateMetodePayments(id: number, updateMethode: paymentMetode) {
    return this.http.put(`${environment.URLServer}/paymentsMetode/${id}`,updateMethode);
  }

  deleteMetodePayment(id: number): Observable<any> {
    return this.http.delete(`${environment.URLServer}/paymentsMetode/${id}`);
  }


}