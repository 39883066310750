<mat-accordion>
    <mat-expansion-panel hideToggle (click)="ingresos(); ingresosTotal()">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <span>Tabulado de ingreso mensual</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <table class="table table-striped animated fadeIn faster _reports">
            <thead *ngFor="let ingresosTotal of ingresoTotal">
                <tr align="center">
                    <th colspan="4">
                        <h5 class="thead">{{ingresosTotal.hoy | date: 'dd-MM-yyyy'}}</h5>
                    </th>
                </tr>
            </thead>
            <thead align="center" class="thead-m19">
                <tr>
                    <th scope="col">Desarrollo</th>
                    <th scope="col">Ingreso actual</th>
                    <th scope="col">Ingreso mensualidad</th>
                    <th scope="col">Ingreso anticipo</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let ingresos of ingreso" align="center">
                    <th scope="row" style="color: brown;">{{ingresos.name}}</th>
                    <td>{{ingresos.flujoreal | currency:'CAD' : '$ '}}</td>
                    <td>{{ingresos.Mensualidad | currency:'CAD' : '$ '}}</td>
                    <td>{{ingresos.anticipo | currency:'CAD' : '$ '}}</td>
                </tr>
            </tbody>
        </table>
        <br>
        <table class="table table-entry-report table-striped animated fadeIn faster _reports">
            <thead class="thead-m19">
                <tr align="center">
                    <th colspan="2">Total ingreso actual</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let ingresosTotal of ingresoTotal" align="center">
                    <th scope="row" style="color: brown;">M19 Inmobiliaria</th>
                    <td>{{ingresosTotal.totalIngreso | currency:'CAD' : '$ '}}</td>
                </tr>
            </tbody>
        </table>
    </mat-expansion-panel>

    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header (click)="ingresosTotalAnual();ingresosTotalAnualPasado()">
            <mat-panel-title>
                <span>Gráfico de ingreso anual</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngFor="let ingTotalAnual of ingresoTotalAnual">
            <div *ngFor="let ingTotalAnualPas of ingresoTotalAnualPasado">
                <canvas baseChart
                    [datasets]="[{ data: [ingTotalAnualPas.Enero,ingTotalAnualPas.Febrero,ingTotalAnualPas.Marzo,ingTotalAnualPas.Abril,ingTotalAnualPas.Mayo,ingTotalAnualPas.Junio,
                    ingTotalAnualPas.Julio,ingTotalAnualPas.Agosto,ingTotalAnualPas.Septiembre,ingTotalAnualPas.Octubre,ingTotalAnualPas.Noviembre,ingTotalAnualPas.Diciembre], label: 'Año anterior'},
                  { data: [ingTotalAnual.Enero,ingTotalAnual.Febrero,ingTotalAnual.Marzo,ingTotalAnual.Abril,ingTotalAnual.Mayo,ingTotalAnual.Junio,
                    ingTotalAnual.Julio,ingTotalAnual.Agosto,ingTotalAnual.Septiembre,ingTotalAnual.Octubre,ingTotalAnual.Noviembre,ingTotalAnual.Diciembre], label: 'Año actual'}]"
                    [labels]="barChartLabels" [options]="barChartOptions" [legend]="barChartLegend"
                    [chartType]="barChartType" (chartHover)="chartHovered($event)"></canvas>
            </div>

            <div class="col-12 text-center mt-3">
                <button type="button" class="btn btn-m19" (click)="randomize()">Cambiar tipo de gráfico</button>
            </div>
           
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header (click)="ingresosDiarios();">
            <mat-panel-title>
                <span>Gráfico de ingreso diario</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <canvas *ngFor="let flujorepdiario of ingresoDiario" baseChart
            [datasets]="[{ data: [flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos], label: 'Promedio diario' },
                                { data: [flujorepdiario.DIN1,flujorepdiario.DIN2,flujorepdiario.DIN3,flujorepdiario.DIN4,flujorepdiario.DIN5,flujorepdiario.DIN6,flujorepdiario.DIN7,flujorepdiario.DIN8,flujorepdiario.DIN9,flujorepdiario.DIN10,flujorepdiario.DIN11,flujorepdiario.DIN12,flujorepdiario.DIN13,flujorepdiario.DIN14,flujorepdiario.DIN15,flujorepdiario.DIN16,flujorepdiario.DIN17,flujorepdiario.DIN18,flujorepdiario.DIN19,flujorepdiario.DIN20,flujorepdiario.DIN21,flujorepdiario.DIN22,flujorepdiario.DIN23,flujorepdiario.DIN24,flujorepdiario.DIN25,flujorepdiario.DIN26,flujorepdiario.DIN27,flujorepdiario.DIN28,flujorepdiario.DIN29,flujorepdiario.DIN30,flujorepdiario.DIN31], label: 'Ingreso diario'}]"
            [labels]="barchartLabelDiario" [options]="barChartOptions" [legend]="barChartLegend"
            [chartType]="barChartType">
        </canvas>
    </mat-expansion-panel>

    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <span>Tabulado de ingreso por rango de fecha</span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>Mes a consultar</mat-label>
            <mat-select [(ngModel)]="selecMes" name="meses" onchange="onChange()">
                <mat-option *ngFor="let centro of mesesIngreso" value="{{centro}}">
                    {{centro}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button class="btn btn-m19" (click)="seleccionMes();tituloMes();">
            <mat-icon>search</mat-icon>
        </button>
        <br>
        <table class="table table-striped animated fadeIn faster _reports" *ngIf="selecMes">
            <thead>
                <tr>
                    <th colspan="4">
                        <h5>Mes consultado: {{tMes || selecMes}}</h5>
                    </th>
                </tr>
            </thead>
            <thead class="thead-m19">
                <tr align="center">
                    <th scope="col">Desarrollo</th>
                    <th scope="col">Ingreso actual</th>
                    <th scope="col">Ingreso mensualidad</th>
                    <th scope="col">Ingreso anticipo</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let ingresosD of ingresoDes" align="center">
                    <th scope="row" style="color: brown;">{{ingresosD.name}}</th>
                    <td>{{ingresosD.flujoreal | currency:'CAD' : '$ '}}</td>
                    <td>{{ingresosD.Mensualidad | currency:'CAD' : '$ '}}</td>
                    <td>{{ingresosD.anticipo | currency:'CAD' : '$ '}}</td>
                </tr>
            </tbody>
        </table>
        <br>
        <table class="table table-entry-report table-striped animated fadeIn faster _reports" *ngIf="selecMes">
            <thead class="thead-m19">
                <tr align="center">
                    <th colspan="2">Total ingreso actual</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let ingresosTot of ingresoT" align="center">
                    <th scope="row" style="color: brown;">M19 Inmobiliaria</th>
                    <td>{{ingresosTot.totalIngreso | currency:'CAD' : '$ '}}</td>
                </tr>
            </tbody>
        </table>
    </mat-expansion-panel>
</mat-accordion>

<!--ngb-accordion activeIds="tabulado">
    <ngb-panel id="tabulado">
        <ng-template ngbPanelTitle>
            <span style="color: brown;"> <b>Tabulado ingreso Mensual</b> </span>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row mt-2">
                <div class="container" style="padding-top: 2cm;">
                    <div>
                        <div class="card" style="padding-left: 1cm;">
                            <div class="card-body">
                                <h2 *ngFor="let ingresosTotal of ingresoTotal"
                                    style="padding-left: 11cm; color: brown; font-size: x-large;">
                                    <b>{{ingresosTotal.hoy | date: 'dd/MM/yyyy'}}</b>
                                </h2>
                                <div class="row mt-2" style="padding-left: 1cm;">
                                    <div>
                                        <table class="table table-hover mt-3"
                                            style=" padding-right: 2cm; font-size: x-large;">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Desarrollo</th>
                                                    <th scope="col">Ingreso actual</th>
                                                    <th scope="col">Ingreso Mensualidad</th>
                                                    <th scope="col">Ingreso Anticipo</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let ingresos of ingreso">
                                                <tr>
                                                    <th scope="row" style="color: brown;">
                                                        {{ingresos.name}}
                                                    </th>
                                                    <td style="text-align: center;">
                                                        {{ingresos.flujoreal | currency:'CAD' : '$ '}}
                                                    </td>
                                                    <td style="text-align: center;">
                                                        {{ingresos.Mensualidad | currency:'CAD' : '$ '}}
                                                    </td>
                                                    <td style="text-align: center;">
                                                        {{ingresos.anticipo | currency:'CAD' : '$ '}}
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div style="padding-top: 1cm;">
                                        <h2 style="color: brown;font-size: xx-large;"> <b> Totales </b></h2>

                                        <table class="table table-hover mt-3" style="width: 11cm; font-size: x-large;">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Desarrollo</th>
                                                    <th scope="col">Total ingreso actual</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let ingresosTotal of ingresoTotal">
                                                    <th scope="row" style="color: rgb(144, 12, 63);">
                                                        <b>M19</b>
                                                    </th>
                                                    <td align="center">
                                                        {{ingresosTotal.totalIngreso | currency:'CAD' : '$ '}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
    <ngb-panel id="graficoAnual">
        <ng-template ngbPanelTitle>
            <span> <b>Grafico ingreso Anual</b> </span>
        </ng-template>
        <ng-template ngbPanelContent>


            <div *ngFor="let ingTotalAnual of ingresoTotalAnual">
                <div>
                    <div *ngFor="let ingTotalAnualPas of ingresoTotalAnualPasado">
                        <div style="display: block">
                            <canvas baseChart
                                [datasets]="[{ data: [ingTotalAnualPas.Enero,ingTotalAnualPas.Febrero,ingTotalAnualPas.Marzo,ingTotalAnualPas.Abril,ingTotalAnualPas.Mayo,ingTotalAnualPas.Junio,
                        ingTotalAnualPas.Julio,ingTotalAnualPas.Agosto,ingTotalAnualPas.Septiembre,ingTotalAnualPas.Octubre,ingTotalAnualPas.Noviembre,ingTotalAnualPas.Diciembre], label: 'Año anterior'},
                      { data: [ingTotalAnual.Enero,ingTotalAnual.Febrero,ingTotalAnual.Marzo,ingTotalAnual.Abril,ingTotalAnual.Mayo,ingTotalAnual.Junio,
                        ingTotalAnual.Julio,ingTotalAnual.Agosto,ingTotalAnual.Septiembre,ingTotalAnual.Octubre,ingTotalAnual.Noviembre,ingTotalAnual.Diciembre], label: 'Año actual'}]"
                                [labels]="barChartLabels" [options]="barChartOptions" [legend]="barChartLegend"
                                [chartType]="barChartType" (chartHover)="chartHovered($event)"></canvas>
                        </div>
                        <button type="button" class="btn btn-dark" (click)="randomize()">Cambiar tipo de
                            gráfico</button>
                    </div>
                </div>
            </div>

        </ng-template>
    </ngb-panel>

    <ngb-panel id="graficoDiario">
        <ng-template ngbPanelTitle>
            <span> <b>Grafico de ingreso diario</b> </span>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="card" style="padding-left: 3cm;">
                <div class="card-body" style="width: 30cm; height: auto;">
                    <div style="height: auto; width: 42cm;">
                        <div>
                            <div style="display: block">
                                <canvas *ngFor="let flujorepdiario of ingresoDiario" baseChart [datasets]="[{ data: [flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos,flujorepdiario.cActivos], label: 'Promedio diario' },
                                { data: [flujorepdiario.DIN1,flujorepdiario.DIN2,flujorepdiario.DIN3,flujorepdiario.DIN4,flujorepdiario.DIN5,flujorepdiario.DIN6,flujorepdiario.DIN7,flujorepdiario.DIN8,flujorepdiario.DIN9,flujorepdiario.DIN10,flujorepdiario.DIN11,flujorepdiario.DIN12,flujorepdiario.DIN13,flujorepdiario.DIN14,flujorepdiario.DIN15,flujorepdiario.DIN16,flujorepdiario.DIN17,flujorepdiario.DIN18,flujorepdiario.DIN19,flujorepdiario.DIN20,flujorepdiario.DIN21,flujorepdiario.DIN22,flujorepdiario.DIN23,flujorepdiario.DIN24,flujorepdiario.DIN25,flujorepdiario.DIN26,flujorepdiario.DIN27,flujorepdiario.DIN28,flujorepdiario.DIN29,flujorepdiario.DIN30,flujorepdiario.DIN31], label: 'Ingreso diario'},                                
                                        { data: [0], label: '',backgroundColor:'rgb(255, 255, 255 )' }]"
                                    [labels]="barchartLabelDiario" [options]="barChartOptions" [legend]="barChartLegend"
                                    [chartType]="barChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
    <ngb-panel id="rangoIngreso">
        <ng-template ngbPanelTitle>
            <span> <b>Tabulado de ingreso por rango de fecha</b> </span>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row mt-2">
                <div class="container" style="padding-top: 2cm;">
                    <div>
                        <div class="card" style="padding-left: 1cm;">
                            <div class="card-body" style="width: auto; ">


                                <div class="row mt-2">
                                    <span class="input-group-text"
                                        style="color: white; height: 1cm; background-color: brown;"
                                        id="mesTres">Seleccione mes: </span>
                                    <select class="custom-select-md" [(ngModel)]="selecMes" name="meses"
                                        style="width: 7cm; border-color:rgb(238, 238, 238); height: 1cm; border-radius:.5cm;"
                                        id="listCenTra" onchange="onChange()">
                                        <option>--Seleccione mes del año--</option>
                                        <option value="{{centro}}" *ngFor="let centro of mesesIngreso;">{{centro}}
                                        </option>
                                    </select>
                                    <button class="btn btn-primary" (click)="seleccionMes();tituloMes()"
                                        style="height: 1cm;">Actualizar</button>
                                    <h1 style="padding-left: 3cm;"> <b>{{tMes}}</b> </h1>
                                </div>

                                <div class="row mt-2" style="padding-left: 1cm; padding-top: 1cm;">
                                    <div>
                                        <table class="table table-hover mt-3"
                                            style=" padding-right: 2cm; font-size: x-large;">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Desarrollo</th>
                                                    <th scope="col">Ingreso actual</th>
                                                    <th scope="col">Ingreso Mensualidad</th>
                                                    <th scope="col">Ingreso Anticipo</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let ingresosD of ingresoDes">
                                                <tr>
                                                    <th scope="row" style="color: brown;">
                                                        {{ingresosD.name}}
                                                    </th>
                                                    <td style="text-align: center;">
                                                        {{ingresosD.flujoreal | currency:'CAD' : '$ '}}
                                                    </td>
                                                    <td style="text-align: center;">
                                                        {{ingresosD.Mensualidad | currency:'CAD' : '$ '}}
                                                    </td>
                                                    <td style="text-align: center;">
                                                        {{ingresosD.anticipo | currency:'CAD' : '$ '}}
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div style="padding-left: 2cm;">
                                        <h2 style="color: brown;font-size: xx-large;"> <b> Totales </b></h2>

                                        <table class="table table-hover mt-3" style="width: 11cm; font-size: x-large;">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Desarrollo</th>
                                                    <th scope="col">Total ingreso actual</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let ingresosTot of ingresoT">
                                                    <th scope="row" style="color: rgb(144, 12, 63);">
                                                        <b>M19</b>
                                                    </th>
                                                    <td align="center">
                                                        {{ingresosTot.totalIngreso | currency:'CAD' : '$ '}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion-->