import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdministrationService } from 'src/app/services/administration.service';

import { ConvertNumber } from 'src/app/utils/Misc/ConvertNumber';
import { MonthName } from 'src/app/utils/Misc/MonthName';
import { OrdinalNumber } from 'src/app/utils/Misc/OrdinalNumber';

import decode from 'jwt-decode';
import Swal from 'sweetalert2';

import { SharedServiceService } from 'src/app/shared/shared-service.service';

import localeEsMx from '@angular/common/locales/es-MX';
import { DatePipe, registerLocaleData } from '@angular/common';

import { SweetAlertComponent } from '../../../utils/sweet-alert/sweet-alert.component';
import { loader } from 'src/app/ui/loader/loader.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-request',
  templateUrl: './view-request.component.html',
  styleUrls: ['./view-request.component.scss']
})

export class ViewRequestComponent implements OnInit {

  loader: loader = {
    message: "Generando contrato..."
  }

  today: Date = new Date();
  
  pipe = new DatePipe('es-MX');

  keyword = 'customerName';

  convertNumber = new ConvertNumber();
  monthName = new MonthName();
  ordinalNumber = new OrdinalNumber();

  saving: boolean = false;
  decode: any = {};

  allrequest: any = [];
  propertyRequest: any = [];
  customerRequest: any = [];
  customersGroups: any = [];

  withoutContract: any[] = [];
  withContract: any[] = [];
  contractsByFolio: any[] = [];

  customerSelected: string = "";
  folioSelected: string = "";
  projectSelected: string = "";

  salesInNewContract: any[] = [];
  saleTotalAmount: number = 0;
  saleTotalAdvance: number = 0;
  saleTotalMonthly: number = 0;
  saleTotalAnnuality: number = 0;
  
  projectsWithout: any[] = [];
  projectsWith: any[] = [];
  
  contractData: any = {
    fechaDato: new Date('1998-10-21'),
    fk_property: 'ALM00L00',
    fk_customer: 0,
    registrationDate: new Date('1998-10-21'),
    firstPayDate: new Date('1998-10-21'),
    idProperty: 0,
    codeProperty: 'ALM00L00',
    projectName: '',
    manzana: '00',
    lote: '00',
    amount: 0,
    advancePayment: 0,
    monthlyPayment: 0,
    annualityPayment: 0,
    area: 0,
    northeasBorder: '',
    southwestBorder: '',
    northwestBorder: '',
    southeastBorder: '',
    fk_project: 'AL',
    idUser: 0,
    customerName: '',
    gender: 'M',
    phone: '',
    email: '',
    birthDate: new Date('1998-10-21'),
    birthPlace: '',
    nationality: '',
    CURP: '',
    voterKey: '',
    occupation: '',
    civilStatus: '',
    address: '',
    localidad: '',
    municipality: '',
    state: '',
    postalCode: '',
    RFC: ''
  };

  salesList: [];

  constructor(
    private adminService: AdministrationService,
    private sharedService: SharedServiceService,
    private router: Router,
    public sweet: SweetAlertComponent,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getAllSales();
    registerLocaleData(localeEsMx, 'es-MX');
  }

  pushSaleInContract(sale: any, event) {
    if (this.folioSelected !== "") {
      this.salesInNewContract = [];
      this.folioSelected = "";
    }

    if (this.projectSelected !== "" && (sale.name !== this.projectSelected)) {
      this.sweet.AlertTime("center", "error", "Los contratos solo pueden tener inmuebles del mismo desarollo", false, 2000);
      return;
    }

    this.projectSelected = sale.name;

    let btnAdd = document.getElementById(event.id);
    let btnDel = document.getElementById(event.id + "Delete");

    btnAdd.style.display = "none";
    btnDel.style.display = "block";

    this.salesInNewContract.push(sale);
    this.salesInNewContract = this.salesInNewContract.sort();
    this.calculateTotalAmount();
  }

  deleteSaleInContract(sale: any) {
    this.salesInNewContract = this.salesInNewContract.filter(item => item.idSale != sale.idSale);

    if (this.salesInNewContract.length == 0) {
      this.projectSelected = "";
    }
    this.calculateTotalAmount();
  }

  calculateMonthlyAndAnnuality(month, annual, amount) {
    let i = 1;
    let monthlyPayments = 0;
    let annualityPayments = 0;
    let extraPayment = 0;

    while (amount > 0) {
      if (i % 12 === 0) {
        if (amount >= annual) {
          amount -= annual;
          annualityPayments++;
          i++;
        } else {
          extraPayment = amount;
          amount = 0;
          i++;
        }
      } else {
        if (amount >= month) {
          amount -= month;
          monthlyPayments++;
          i++;
        } else {
          extraPayment = amount;
          amount = 0;
          i++;
        }
      }
    }

    document.getElementById("monthlyPayments").innerHTML = monthlyPayments.toString();
    document.getElementById("annualityPayments").innerHTML = annualityPayments.toString();
    if(extraPayment > 0) {
      document.getElementById("extraPayment").innerHTML = "y un último pago mensual de <b>$" + extraPayment + ".00</b>" + "(" + this.convertNumber.numberToText(extraPayment.toString()) + " 00/100 M.N.)";
    }
  }

  showDetails() {
    let detailsBtn = document.getElementById("detailsBtn");
    let detailsView = document.getElementById("detailsView");

    detailsBtn.style.right = "-100vw";
    detailsView.style.right = "0";
  }

  hideDetails() {
    let detailsBtn = document.getElementById("detailsBtn");
    let detailsView = document.getElementById("detailsView");

    detailsBtn.style.right = "10px";
    detailsView.style.right = "-100vw";
  }

  saleDeleted(event) {
    let btnDel = document.getElementById(event.id);
    let btnAdd = document.getElementById((event.id).replace("Delete", ""));

    btnDel.style.display = "none";
    btnAdd.style.display = "block";
  }

  calculateTotalAmount() {
    this.saleTotalAmount = 0;
    this.saleTotalAdvance = 0;
    this.saleTotalMonthly = 0;
    this.saleTotalAnnuality = 0;

    this.salesInNewContract.forEach(item => {
      this.saleTotalAmount += Math.round(item.amount);
      this.saleTotalAdvance += Math.round(item.advancePayment);
      this.saleTotalMonthly += Math.round(item.monthlyPayment);
      this.saleTotalAnnuality += Math.round(item.annualityPayment);
    });

    this.calculateMonthlyAndAnnuality(this.saleTotalMonthly, this.saleTotalAnnuality, this.saleTotalAmount - this.saleTotalAdvance);
  }

  clearContract() {
    this.salesInNewContract = [];
    this.saleTotalAmount = 0;
    this.saleTotalAdvance = 0;
    this.saleTotalMonthly = 0;

    // this.contractData = {};
    this.withContract = [];
    this.withoutContract = [];
    this.folioSelected = "";
    this.projectSelected = "";
  }

  getAllSales() {
    this.adminService.getAllSalesList().subscribe(
      res => {
        this.salesList = res.data;
      }
    );
  }

  generateContractOptions(selectedSale: any) {
    this.clearContract();
    this.customerSelected = selectedSale.idUser;
    this.adminService.getCustomerSalesWithoutContract(selectedSale.idUser).subscribe(
      byCustomer => {
        this.withoutContract = byCustomer.data;
        console.log(this.withoutContract);
        this.projectsWithout = this.withoutContract.map(item => item.name)
          .filter((value, index, self) => self.indexOf(value) === index);

        this.adminService.getCustomerSalesWithContract(selectedSale.idUser).subscribe(
          byFolio => {
            this.withContract = byFolio.data;
            console.log(this.withContract);
            this.projectsWith = this.withContract.map(item => item.name)
              .filter((value, index, self) => self.indexOf(value) === index);

            this.contractsByFolio = this.withContract.map(item => item.idContract)
              .filter((value, index, self) => self.indexOf(value) === index);
          }
        );
      }
    );
  }

  generateContract() {
    this.saving = true;
    this.adminService.getCustomerSalesData(this.salesInNewContract[0].idUser, this.salesInNewContract[0].codeProperty).subscribe(
      async res => {
        this.contractData = res.data;

        this.contractData.fechaDato = new Date(this.contractData.fechaDato);

        this.calculateTotalAmount();
        this.calculateMonthlyAndAnnuality(this.saleTotalMonthly, this.saleTotalAnnuality, this.saleTotalAmount - this.saleTotalAdvance);

        let propertiesInContract = "";

        this.salesInNewContract.forEach((value, index) => {
          if (index == 0) {
            propertiesInContract += '"' + value.codeProperty + '"';
          } else {
            propertiesInContract += ',"' + value.codeProperty + '"';
          }
          index++;
        });

        let responsible = this.getUser();
        let fileName = propertiesInContract.replace(/"/g,"").replace(","," - ") + " " + this.contractData.customerName;

        if (this.folioSelected == "") {
          this.adminService.getFolioForContract(propertiesInContract, responsible).subscribe(
            folio => {
              document.getElementById("contractFolio").innerText = folio.data;

              setTimeout(() => {
                this.saving = false;
                this.printContract(this.contractData, fileName);
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['view-request']));
              }, 1000);
            }
          );
        } else {
          document.getElementById("contractFolio").innerText = this.folioSelected;
          setTimeout(() => {
            this.saving = false;
            this.printContract(this.contractData, fileName);
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['view-request']));
          }, 1000);
        }
      }
    );
  }

  generateContractByFolio(folio: string) {
    this.adminService.getCustomerWithContractData(folio).subscribe(
      res => {
        res.data.forEach((item) => {
          item.fechaDato = new Date(item.fechaDato);
        });

        this.salesInNewContract = res.data;
        this.folioSelected = folio;

        this.calculateTotalAmount();
      }
    );
  }

  async contratoRealizado(prop: any) {
    let { value: cantidad } = await Swal.fire({
      title: "Confirmación de contrato elaborado",
      html: "Ingrese el número de mensualidades para rescindir este inmueble",
      input: "number",
      inputPlaceholder: 'Meses para rescisión',
      inputValue: 2,
      inputValidator: (value) => {
        if (parseInt(value) < 2) {
          return 'El número minimo permitido es 2 meses'
        }
        else if (parseInt(value) == 0) {
          return 'No ha especificado los meses para rescisión'
        } else if (value == '') {
          return 'Por favor ingrese un valor válido'
        }
      },
      showCancelButton: true,
      customClass: {
        actions: 'sweetActions',
        confirmButton: 'm19AltBtn',
        cancelButton: 'm19Btn'
      },
      buttonsStyling: false,
      confirmButtonText: 'Marcar como registrado',
      cancelButtonText: 'Cancelar'
    })

    if (cantidad) {
      this.adminService.updateSaleStatus(4, prop).subscribe(
        res => {
          this.adminService.updateContractDate(prop, parseInt(cantidad)).subscribe(
            res => {
              this.sweet.AlertTime("center", "success", "Contrato creado", false, 2000);
            }
          );
        }
      );
    }
  }

  printContract(sale: any, name: string) {
    let backgroundLogo = this.sharedService.projectLogos.filter(item => { return item.projectName == sale.projectName });

    let printContents, popupWin;
    printContents = document.getElementById('contractFormat')!.innerHTML;
    popupWin = window.open(' ', '', '');
    popupWin!.document.open();
    popupWin!.document.write(`
      <html>
        <head>
          <title>${name}</title>
          <style>
          * {
          margin: 0;
          padding: 0;
          -webkit-print-color-adjust: exact;
          color-adjust: exact;
          font-family: Verdana, Geneva, Tahoma, sans-serif;
          }

          body {
            counter-reset: page 1;
          }

          @page {
            size: letter;
            margin: 2.2cm 2.7cm 2.2cm 2.7cm;
          }
          
          @page {
            counter-increment: page;
          }

          @page {
            @bottom-center {
              content: "Pagina: " counter(page);
            }
          }
      
          .contractFormat {
            font-family: Verdana, Geneva, Tahoma, sans-serif;
          }
      
          .contractFormat h1 {
            font-size: 1rem;
            text-align: center;
          }
      
          .contractFormat h2 {
              font-size: 1rem;
          }
      
          .contractFormat p {
              text-align: justify;
          }

          .contractFormat .custom-list {
            list-style-type: lower-alpha;
            list-style-position: inside;
          }
      
          .contractFormat .signs {
            display: flex;
            justify-content: space-evenly;
            gap: 20px;
          }
      
          .contractFormat .signs > p {
            flex-grow: 1;
            flex-basis: 0;
            max-width: 200px;
            text-align: center;
            font-size: 0.7rem;
            border-top: solid 1px black;
          }

          .contractFormat .images {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;
          }

          .contractFormat .images .sketchMapIMG {
            flex-grow: 1;
            flex-basis: 0;
            min-width: 200px;
            max-width: 200px;
          }

          body::after {
              content: "";
              width: 70%;
              height: 70%;
              background-image: url(${backgroundLogo[0].logo});
              background-size: contain;
              background-repeat: no-repeat;
              position: fixed;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              opacity: 0.3;
              z-index: -1;
          }
          </style>
        </head>
        <body onload="window.print();window.close()">
        ${printContents}
        </body>
      </html>`
    );

    popupWin!.document.close();
  }

  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.fkuser
  }
}
