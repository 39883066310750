import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PaymentsCommissionsService {

  constructor(private http: HttpClient) { }

  getCommissionsService(nameProject: string): Observable<any>{
    return this.http.post(`${environment.URLServer}/paymentsCommissions/getCommissionByProjectRoutes/` , [nameProject]);
  }

  getCommissionsPaidService(nameProject: any): Observable<any>{
    return this.http.post(`${environment.URLServer}/paymentsCommissions/getCommissionsPaidRoutes/` , {nameProject});
  }


}
