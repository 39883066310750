import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectdetailsService } from 'src/app/services/projectdetails.service';
import { ConsumerTokenService } from 'src/app/services/consumer-token.service';
import { ConsumerTokenConsultInfoDialogComponent } from '../consumer-token-consult-info-dialog/consumer-token-consult-info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-consumer-token-consult-main',
  templateUrl: './consumer-token-consult-main.component.html',
  styleUrls: ['./consumer-token-consult-main.component.scss']
})
export class ConsumerTokenConsultMainComponent implements OnInit {

  constructor(
    private _formBuilder: FormBuilder,
    private projectService: ProjectService,
    private projectDetailService: ProjectdetailsService,
    private consumerTokenService: ConsumerTokenService,
    public dialog: MatDialog,
    public sweet: SweetAlertComponent,
  ) { }

  ngOnInit(): void {
    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
    this.selectProjects();
    //this.getAllConsumerToken();
    console.log(this.consumerTokens);
  }

  //Variables
  pipe = new DatePipe('es-MX');
  projects: any = [];
  towersOfProject: any = [];
  consumerTokenID: any = [];          //ID del vale de consumo al que se le presionó la opción de "info" en el front
  productsOfConsumerToken: any = [];
  filter: any = [];                   //Vales de consumo filtrados
  noFilter: any = [];                 //TODOS los vales de consumo
  consumerTokens: any = [];           //Los vales de consumo que se muestran en tabla
  filtroFolio: any;
  filtroPD: any;
  filtroDate: any;
  infoGeneral = {
    project: 'No seleccionado',
    module: 'No seleccionado',
    folio: 'No seleccionado',
    Fecha: 'No seleccionado'
  };


  //FORMULARIO
  consultForm: FormGroup = this._formBuilder.group({
    idProject: [],
    fk_idprojectDet: [],
    folio: [],
    tokenDate: []
  });

  async selectProjects() { //Recupera la lista de proyectos de la tabla projects
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      },
      err => console.log(err)
    );
  };

  async selectProject(id: number) {//Al seleccionar un proyecto (En HTML, campo "idProject"), se recuperan todos los datos de projectDetail de ese proyecto (Todos los módulos de ese proyecto)
    this.consultForm.controls['fk_idprojectDet'].setValue(''); //En caso de que cambie el proyecto, se borra el contenido del campo HTML "fk_idprojectDet"
    this.projectDetailService.selectDetailsById(id).subscribe(
      res => {
        //Recuperamos la información de torres del proyecto
        this.towersOfProject = res;
      }
    );
  };
  async getAllConsumerToken() {
    this.consumerTokenService.getAllConsumerToken().subscribe(
      res => {
        this.consumerTokens = res;
        this.noFilter = this.consumerTokens;
        if(res.length!=0){
          this.infoGeneral = {
            project: 'Todos',
            module: 'Todos',
            folio: 'Todos',
            Fecha: 'Todos'
          };
        }
        this.consultForm.controls['idProject'].setValue(null);
        this.consultForm.controls['fk_idprojectDet'].setValue(null);
        this.consultForm.controls['folio'].setValue(null);
        this.consultForm.controls['tokenDate'].setValue(null);
      }
    )
  }
  async consumerTokenInfo(id: number) {
    this.consumerTokenService.getAllProductsFromConsumerToken(id).subscribe(
      res => {
        //Se recupera el valor retornado por la consulta que esta función ejecuta que contiene las partidas de la requisición
        this.productsOfConsumerToken = res;
        //Se apertura un cuadro de dialogo para visualizar los datos detallados de la requisición y partidas
        this.dialog.open(ConsumerTokenConsultInfoDialogComponent, {
          //Se define el ancho del cuadro de dialogo
          width: '100%',
          //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
          data: this.productsOfConsumerToken
        });
        console.log(this.productsOfConsumerToken);
      }

    )
  }
  async filtrar() {
    var fk_idprojectDet = this.consultForm.controls['fk_idprojectDet'].value;
    console.log(this.consultForm.controls['fk_idprojectDet'].value);
    var folio = this.consultForm.controls['folio'].value;
    console.log(this.consultForm.controls['folio'].value)
    var tokenDate = this.consultForm.controls['tokenDate'].value;
    console.log(this.consultForm.controls['tokenDate'].value);
    if (!(this.consultForm.controls['idProject'].value == null || this.consultForm.controls['idProject'].value == "") && (this.consultForm.controls['fk_idprojectDet'].value == null || this.consultForm.controls['fk_idprojectDet'].value == "")) {
      this.sweet.AlertTime("center", "error", "Debe de seleccionar módulo", false, 2000);
    }
    else if (!(this.consultForm.controls['fk_idprojectDet'].value == null || this.consultForm.controls['fk_idprojectDet'].value == "") &&
      !(this.consultForm.controls['folio'].value == null || this.consultForm.controls['folio'].value == "") && !(this.consultForm.controls['tokenDate'].value == null || this.consultForm.controls['tokenDate'].value == "")) {
      this.consumerTokenService.getFilterConsumerToken1(folio, fk_idprojectDet, tokenDate).subscribe(
        res => {
          this.consumerTokens = res;
          console.log("Todos los filtros: " + res);
          if (res.length != 0) {
            this.ajustarInfo(true,true,true,true);
          } else {
            this.ajustarInfo(false,false,false,false);
          }
        }
      )
    } else if ((this.consultForm.controls['fk_idprojectDet'].value == null ||//SI SOLO FOLIO ESTÁ LLENO
      this.consultForm.controls['fk_idprojectDet'].value == "") && (
        this.consultForm.controls['tokenDate'].value == null ||
        this.consultForm.controls['tokenDate'].value == "") &&
      !(this.consultForm.controls['folio'].value == null || this.consultForm.controls['folio'].value == "")) {
      this.consumerTokenService.getFilterConsumerToken2(folio).subscribe(
        res => {
          this.consumerTokens = res;
          console.log("Solo folio: " + res);
          if (res.length != 0) {
            this.ajustarInfo(false,false,true,false);
          } else {
            this.ajustarInfo(false,false,false,false);
          }
        }
      )
    } else if ((this.consultForm.controls['tokenDate'].value == null ||//SI FOLIO Y MODULO
      this.consultForm.controls['tokenDate'].value == "") &&
      !(this.consultForm.controls['folio'].value == null || this.consultForm.controls['folio'].value == "") && !(
        this.consultForm.controls['fk_idprojectDet'].value == null ||
        this.consultForm.controls['fk_idprojectDet'].value == "")) {
      this.consumerTokenService.getFilterConsumerToken3(folio, fk_idprojectDet).subscribe(
        res => {
          this.consumerTokens = res;
          console.log("folio y modulo: " + res);
          if (res.length != 0) {
            this.ajustarInfo(true,true,true,false);
          } else {
            this.ajustarInfo(false,false,false,false);
          }
        }
      )
    } else if ((this.consultForm.controls['fk_idprojectDet'].value == null ||//SI SOLO FECHA ESTÁ LLENO
      this.consultForm.controls['fk_idprojectDet'].value == "") && (
        this.consultForm.controls['folio'].value == null ||
        this.consultForm.controls['folio'].value == "") &&
      !(this.consultForm.controls['tokenDate'].value == null || this.consultForm.controls['tokenDate'].value == "")) {
      this.consumerTokenService.getFilterConsumerToken4(tokenDate).subscribe(
        res => {
          this.consumerTokens = res;
          console.log("Solo solo fecha: " + res);
          if (res.length != 0) {
            this.ajustarInfo(false,false,false,true);
          } else {
            this.ajustarInfo(false,false,false,false);
          }
        }
      )//
    } else if ((this.consultForm.controls['fk_idprojectDet'].value == null ||//SI FOLIO Y FECHA ESTAN LLENOS
      this.consultForm.controls['fk_idprojectDet'].value == "") &&
      !(this.consultForm.controls['folio'].value == null || this.consultForm.controls['folio'].value == "") && !(
        this.consultForm.controls['tokenDate'].value == null ||
        this.consultForm.controls['tokenDate'].value == "")) {
      this.consumerTokenService.getFilterConsumerToken5(folio, tokenDate).subscribe(
        res => {
          this.consumerTokens = res;
          console.log("Solo Folio y Fecha: " + res);
          if (res.length != 0) {
            this.ajustarInfo(false,false,true,true);
          } else {
            this.ajustarInfo(false,false,false,false);
          }
        }
      )
    } else if ((this.consultForm.controls['folio'].value == null ||//SI MODULO Y FECHA
      this.consultForm.controls['folio'].value == "") &&
      !(this.consultForm.controls['fk_idprojectDet'].value == null || this.consultForm.controls['fk_idprojectDet'].value == "") && !(
        this.consultForm.controls['tokenDate'].value == null ||
        this.consultForm.controls['tokenDate'].value == "")) {
      this.consumerTokenService.getFilterConsumerToken6(fk_idprojectDet, tokenDate).subscribe(
        res => {
          this.consumerTokens = res;
          console.log("Solo Modulo y fecha: " + res);
          if (res.length != 0) {
            this.ajustarInfo(true,true,false,true);
          } else {
            this.ajustarInfo(false,false,false,false);
          }
        }
      )
    } else if ((this.consultForm.controls['folio'].value == null ||//SI SOLO MODULO
      this.consultForm.controls['folio'].value == "") && (this.consultForm.controls['tokenDate'].value == null ||
        this.consultForm.controls['tokenDate'].value == "") &&
      !(this.consultForm.controls['fk_idprojectDet'].value == null || this.consultForm.controls['fk_idprojectDet'].value == "")) {
      this.consumerTokenService.getFilterConsumerToken7(fk_idprojectDet).subscribe(
        res => {
          this.consumerTokens = res;
          console.log("Solo Modulo: " + res);
          if (res.length != 0) {
            this.ajustarInfo(true,true,false,false);
          } else {
            this.ajustarInfo(false,false,false,false);
          }
        }
      )
    } else {
      this.sweet.AlertTime("center", "error", "No hay filtros por aplicar", false, 2000);
      this.consumerTokens = [];
      this.infoGeneral = {
        project: 'No seleccionado',
        module: 'No seleccionado',
        folio: 'No seleccionado',
        Fecha: 'No seleccionado'
      };
    }
  }

  async ajustarInfo(a: boolean, b: boolean, c: boolean, d: boolean) {
    if (a) {
      this.infoGeneral.project = this.consultForm.controls['idProject'].value;
    } else {
      this.infoGeneral.project = 'No seleccionado'
    }

    if (b) {
      this.infoGeneral.module = this.consultForm.controls['fk_idprojectDet'].value;
    } else {
      this.infoGeneral.module = 'No seleccionado'
    }
    
    if (c) {
      this.infoGeneral.folio = this.consultForm.controls['folio'].value;
    } else {
      this.infoGeneral.folio = 'No seleccionado'
    }

    if (d) {
      this.infoGeneral.Fecha = this.consultForm.controls['tokenDate'].value;
    } else {
      this.infoGeneral.Fecha = 'No seleccionado'
    }
  }
}
