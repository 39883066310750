import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RecessionsService } from 'src/app/services/recessions.service';

@Component({
  selector: 'app-recessions-consult',
  templateUrl: './recessions-consult.component.html',
  styleUrls: ['./recessions-consult.component.scss']
})
export class RecessionsConsultComponent implements OnInit {

  filterRecessions = '';
  recessions: any = [];
  recession: any = [];

  constructor(
    private recessionService: RecessionsService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.selectRecessions();
  }

  //Consultar rescisión de contrato
  selectRecessions() {
    this.recessionService.getRecessions().subscribe(
      res => {
        this.recessions = res;
      }
    );
  }

  //Consultar rescisión de contrato específicado
  selectRecession(idRecession: number) {

  }
}
