//=========================================================================================================
//Importación de los componentes necesarios para esta página
//=========================================================================================================
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';
import { DepartureUpdateComponent } from './departure-update/departure-update.component';
import { MaterialUpdateComponent } from './material-update/material-update.component';
import { SupplierUpdateComponent } from './supplier-update/supplier-update.component';
import { ConceptUpdateComponent } from './concept-update/concept-update.component';

//=========================================================================================================
//Importación de los servicios necesarios para esta página
//=========================================================================================================
import { DepartureService } from 'src/app/services/departure.service';
import { ProductcatalogService } from 'src/app/services/productcatalog.service';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { ConceptsService } from 'src/app/services/concepts.service';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-requisitioncontrol',
  templateUrl: './requisitioncontrol.component.html',
  styleUrls: ['./requisitioncontrol.component.scss']
})
export class RequisitioncontrolComponent implements OnInit {

  keywordP = 'departurename';

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  //Objeto de formulario de partidas presupuestales
  departuresFormGroup: FormGroup = this._formBuilder.group({
    iddeparture: [],
    departurenumber: ['', Validators.required],
    descriptions: ['', Validators.required],
    useremail: [this.getUser()]
  });

  //Objeto de formulario de partidas presupuestales
  conceptFormGroup: FormGroup = this._formBuilder.group({
    idconcept: [],
    conceptKey: ['', Validators.required],
    descriptions: ['', Validators.required],
    useremail: [this.getUser()]
  });

  //Objeto de formulario de productos
  productsFormGroup: FormGroup = this._formBuilder.group({
    idproduct: [],
    productname: ['', Validators.required],
    unit: ['', Validators.required],
    useremail: [this.getUser()]
  });

  //Objeto de formulario de proveedores
  suppliersFormGroup: FormGroup = this._formBuilder.group({
    idproduct: [],
    suppliername: ['', Validators.required],
    RFC: ['', Validators.required],
    direction: ['', Validators.required],
    phone: ['', Validators.required],
    useremail: [this.getUser()]
  });

  //Objeto que almacena todas las partidas registradas en la BD
  departures: any = [];
  concepts: any = [];

  departureList: any = [];

  //Objeto que almacena todos los productos registrados en la BD
  products: any = [];

  //Objeto que almacena todos los proveedores registrados en la BD
  suppliers: any = [];

  constructor(
    //=======================================================================================================
    //Declaración de los servicios utilizados
    public departureService: DepartureService,
    public productCatalogService: ProductcatalogService,
    public supplierService: SuppliersService,
    public conceptService: ConceptsService,

    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    //Se obtienen todas las partidas
    this.selectDepartures();

    //Se obtienen todos los productos
    this.selectProducts();

    //Se obtienen todos los proveedores
    this.selectSuppliers();

    this.selectConcepts();
  }

  //=======================================================================================
  //Función para obtener todas las partidas registradas en la BD
  //=======================================================================================
  async selectDepartures() {
    this.departureService.selectDepartures().subscribe(
      res => {
        this.departures = res;
      }
    );
  }

  //=======================================================================================
  //Función para registrar una partida nueva en la BD
  //=======================================================================================
  async insertDeparture() {
    if (this.departuresFormGroup.invalid) {
      return;
    } else {
      this.departureService.insertDeparture(this.departuresFormGroup.value).subscribe(
        res => {
          this.sweet.AlertTime("center", "success", "Partida registrada con éxito", false, 2000);
          this.selectDepartures();
          this.departuresFormGroup.controls['departurenumer'].setValue('');
          this.departuresFormGroup.controls['descriptions'].setValue('');
        },
        err => {
          console.error(err);
        }
      );
    }
  }

  //=======================================================================================
  //Función para borrar una partida de la BD
  //=======================================================================================
  async deleteDeparture(id: Number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, mejor no',
      confirmButtonText: 'Sí, elimínala!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminada!',
          'Esta partida fue eliminado del catálogo.',
          'success'
        )
        this.departureService.deleteDeparture(id).subscribe(
          res => {
            this.selectDepartures()
          }
        )
      }
    })
  }

  async selectConcepts() {
    this.conceptService.selectAllConcepts().subscribe(
      res => {
        this.concepts = res;
      }
    );
  }

  async insertConcept() {
    this.conceptService.insertConcept(this.conceptFormGroup.value).subscribe(
      res => {
        this.sweet.AlertTime("center", "success", "Concepto registrado con éxito", false, 2000);
        this.selectConcepts();
        this.conceptFormGroup.controls['conceptKey'].setValue('');
        this.conceptFormGroup.controls['descriptions'].setValue('');
      }
    );
  }

  async setIDDeparture(v:any) {
    this.conceptFormGroup.controls['iddeparture'].setValue(v.iddeparture);
  }

  async deleteConcept(id: number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, mejor no',
      confirmButtonText: 'Sí, elimínala!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminada!',
          'Este concepto fue eliminado del catálogo.',
          'success'
        )
        this.conceptService.deleteConcept(id).subscribe(
          res => {
            this.selectConcepts();
          }
        );
      }
    })
  }

  //=======================================================================================
  //Función para obtener todos los productos registrados en la BD
  //=======================================================================================
  async selectProducts() {
    this.productCatalogService.selectProducts().subscribe(
      res => {
        this.products = res;
      }
    );
  }

  //=======================================================================================
  //Función para insertar un nuevo producto al catalogo de materiales
  //=======================================================================================
  async insertProduct() {
    if (this.productsFormGroup.invalid) {
      return;
    } else {
      this.productCatalogService.insertProduct(this.productsFormGroup.value).subscribe(
        res => {
          this.sweet.AlertTime("center", "success", "Material registrado con éxito", false, 2000);
          this.selectProducts();
          this.productsFormGroup.controls['productname'].setValue('');
          this.productsFormGroup.controls['unit'].setValue('');
        }
      );
    }
  }

  //=======================================================================================
  //Función para eliminar un producto del catalogo de productos
  //=======================================================================================
  async deleteProduct(id:Number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, mejor no',
      confirmButtonText: 'Sí, elimínalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'Este material fue eliminado del catálogo.',
          'success'
        )
        this.productCatalogService.deleteProduct(id).subscribe(
          res => {
            this.selectProducts()
          }
        )
      }
    })
  }

  //=======================================================================================
  //Función para insertar un nuevo proveedor al catalogo de proveedores
  //=======================================================================================
  async insertSupplier() {
    if (this.suppliersFormGroup.invalid) {
      return;
    } else {
      this.supplierService.insertSupplier(this.suppliersFormGroup.value).subscribe(
        res => {
          this.sweet.AlertTime("center", "success", "Proveedor registrado con éxito", false, 2000);
          this.selectSuppliers();
          this.suppliersFormGroup.controls['suppliername'].setValue('');
          this.suppliersFormGroup.controls['RFC'].setValue('');
          this.suppliersFormGroup.controls['direction'].setValue('');
          this.suppliersFormGroup.controls['phone'].setValue('');
        }
      );
    }
  }

  //=======================================================================================
  //Función para consultar el catalogo de proveedores
  //=======================================================================================
  async selectSuppliers() {
    this.supplierService.selectSuppliers().subscribe(
      res => {
        this.suppliers = res;
      }
    );
  }

  //=======================================================================================
  //Función para eliminar un proveedor del catalogo de proveedores
  //=======================================================================================
  async deleteSupplier(id:Number) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podras revertir esta acción!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, mejor no',
      confirmButtonText: 'Sí, elimínalo!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Eliminado!',
          'Este proveedor fue eliminado del catálogo.',
          'success'
        )
        this.supplierService.deleteSupplier(id).subscribe(
          res => {
            this.selectSuppliers()
          }
        )
      }
    })
  }

  async editDeparture(id:number){
    let dialogRef = this.dialog.open(DepartureUpdateComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '500px',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: id
    });

    dialogRef.afterClosed().subscribe(
      res => {
        this.selectDepartures();
      }
    );
  }

  async editConcept(id:number){
    let dialogRef = this.dialog.open(ConceptUpdateComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '500px',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: id
    });

    dialogRef.afterClosed().subscribe(
      res => {
        this.selectConcepts();
      }
    );
  }

  async editMaterial(id:number){
    let dialogRef = this.dialog.open(MaterialUpdateComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '500px',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: id
    });

    dialogRef.afterClosed().subscribe(
      res => {
        this.selectProducts();
      }
    );
  }

  async editSupplier(id:number){
    let dialogRef = this.dialog.open(SupplierUpdateComponent, {
      //Se define el ancho del cuadro de dialogo
      width: '500px',
      //Se definen los datos que tendrá pasandole la variable que contiene las partidas encontradas
      data: id
    });

    dialogRef.afterClosed().subscribe(
      res => {
        this.selectSuppliers();
      }
    );
  }

  //==========================================================================================================
  //Recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.email
  }

}
