<div class="invContainer" (scroll)="onScroll()">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">
            <app-loader [loader]="loader" *ngIf="saving==1"></app-loader>
            <div class="mainCont">
                <div class="mainCont__control">
                    <!-- <div class="mainCont__control__fitler">
                        <div class="mainCont__control__filter__state">
                            <button class="m19AltBtn"
                                (click)="getInvoiceList('fkInvState'); stateShown='Totales'">Todos</button>
                            <button class="m19AltBtn"
                                (click)="getInvoiceList(1); stateShown='Pendientes'">Pendientes</button>
                            <button class="m19AltBtn"
                                (click)="getInvoiceList(2); stateShown='Realizadas'">Realizados</button>
                            <button class="m19AltBtn"
                                (click)="getInvoiceList(3); stateShown='Anuladas'">Anulados</button>
                        </div>
                        <mat-form-field *ngIf="invList.length!=0" style="width: 100%;">
                            <mat-label>Buscar pagos</mat-label>
                            <input matInput type="text" placeholder="Ingresar el nombre del cliente"
                                [(ngModel)]="filterInvoices" name="filterRequisitions">
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                    </div> -->

                    <div class="mainCont__control__counters">
                        <span class="dataItem">Facturas {{stateShown}} : {{invList.length}} <mat-icon
                                style="color:#D33E3E;">apartment</mat-icon></span>
                    </div>

                    <div class="mainCont__control__invCont">
                        <p *ngIf="invList.length==0" style="text-align: center;">Sin Resultados
                            <mat-icon>task_alt</mat-icon>
                        </p>
                        <div class="mainCont__control__invCont__invCard"
                            *ngFor="let inv of invList  | filterInvoices: filterInvoices">
                            <span class="invoiceIcon">
                                <mat-icon *ngIf="inv.metodoPago == 'Efectivo'">payments</mat-icon>
                                <mat-icon *ngIf="inv.metodoPago == 'Cheque'">account_balance_wallet</mat-icon>
                                <mat-icon *ngIf="inv.metodoPago == 'Deposito'">monetization_on</mat-icon>
                                <mat-icon *ngIf="inv.metodoPago == 'Transferencia'">swap_horizontal_circle</mat-icon>
                                <mat-icon *ngIf="inv.metodoPago == 'Deposito en garantía'">local_atm</mat-icon>
                                <mat-icon
                                    *ngIf="inv.metodoPago == 'Tarjeta de debito'  || inv.metodoPago == 'Tarjeta de credito'">credit_card</mat-icon>
                            </span>
                            <div class="invoiceDetail">
                                <span style="display: flex; justify-content: space-between; color: var(--M19Primary);">
                                    <b>{{inv.metodoPago}}</b>
                                    <b style="color: var(--primaryGreen);">{{inv.cantidadAbono | currency :
                                        'CAD' : '$ '}}</b>
                                    <b style="color: var(--M19Primary);">{{inv.fkInvState}}</b>
                                </span>
                                <span style="display: flex; justify-content: space-between;">
                                    <b>{{inv.customer}}</b>
                                    <b>{{inv.RFC}}</b>
                                </span>
                                <span style="display: flex; justify-content: space-between;">
                                    <b>{{inv.fkCliente}}</b>
                                    <b>{{inv.description}}</b>
                                </span>
                                <span style="display: flex; justify-content: space-between;">
                                    {{pipe.transform(inv.fechaPago,'mediumDate')}}
                                    <b style="color: gray;">Folio: {{inv.folioPago}}</b>
                                </span>
                            </div>
                            <div class="invoiceAction">
                                <mat-icon class="actionAltBtn" data-title="Detalles"
                                    (click)="getHistory(inv.idPayment)">info</mat-icon>
                                <mat-icon class="actionAltBtn" data-title="Marcar Facturado"
                                    *ngIf="inv.fkInvState == 'En Proceso'"
                                    (click)="setInvoiceMade(inv.idPayment, 2)">check_circle</mat-icon>
                                <mat-icon class="actionAltBtn" data-title="Desmarcar Facturado"
                                    *ngIf="inv.fkInvState == 'Facturado'"
                                    (click)="setInvoiceUnmade(inv.idPayment, 1)">backspace</mat-icon>
                                <mat-icon class="actionAltBtn" data-title="Anular Factura"
                                    *ngIf="inv.fkInvState == 'Facturado'"
                                    (click)="setInvoiceCancel(inv.idPayment, 3)">do_not_disturb_off</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mainCont__revision">
                    <h3>Revisión</h3>
                    <p>Pagos facturados que fueron cancelados:</p>
                    <p *ngIf="invRevList.length==0" style="text-align: center;">Sin Revisiones
                        <mat-icon>task_alt</mat-icon>
                    </p>
                    <div class="mainCont__revision__invCont">
                        <div class="mainCont__revision__invCont__invCard" *ngFor="let inv of invRevList">
                            <div class="invoiceDetail">
                                <span
                                    style="display: flex; justify-content: space-between; color: var(--M19Primary);"><b>{{inv.metodoPago}}</b>
                                    <b style="color: var(--primaryGreen);">{{inv.cantidadAbono | currency :
                                        'CAD' : '$ '}}</b></span>
                                <span style="display: flex; justify-content: space-between;">
                                    <b>{{inv.customer}}</b>
                                    <b>{{inv.fkCliente}}</b>
                                </span>
                                <span style="display: flex; justify-content: space-between;">
                                    <b>{{inv.RFC}}</b>
                                </span>
                                <span
                                    style="display: flex; justify-content: space-between;">{{pipe.transform(inv.fechaPago,'mediumDate')}}
                                    <b style="color: gray;">Folio: {{inv.folioPago}}</b>
                                    <mat-icon class="actionAltBtn" data-title="Detalles"
                                        (click)="getHistory(inv.idPayment)">info</mat-icon>
                                    <mat-icon class="actionAltBtn" data-title="Anular Factura"
                                        *ngIf="inv.fkInvState == 'Facturado'"
                                        (click)="setInvoiceCancel(inv.idPayment, 3)">do_not_disturb_off</mat-icon>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </mat-card-content>
    </mat-card>
    <button [ngClass]="{'goTopBtn--show': showButton, 'goTopBtn--hide': !showButton}"
        (click)="scrollToTop()"><mat-icon>expand_less</mat-icon></button>
</div>