<mat-card>
    <div>
        <canvas id="canvasId" #canvasRef></canvas>
    </div>

    <div class="loadingContainer" *ngIf="generate==true">
        <div class="loading">
            <div class="loader loader--style2" title="1">
                <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" height="100%"
                    viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path fill="#000"
                        d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
                        <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25"
                            to="360 25 25" dur="0.6s" repeatCount="indefinite" />
                    </path>
                </svg>
            </div>
            <p *ngIf="step==1">Cargando plano...</p>
            <p *ngIf="step==2">Generando puntos en mapa...</p>
        </div>
    </div>

    <div class="colorCode">
        <p>Código de color</p>
        <div class="colorsScroll">
            <table>
                <tr *ngFor="let color of colors" style="height: 4.5vw;">
                    <td><div [ngStyle]="{'background-color': color.HTMLColors}" style='width: 4vw; height: 4vw; border-radius: 100%;'></div></td>
                    <td>{{color.value}}</td>
                </tr>
            </table>
        </div>
    </div>
</mat-card>