import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PdfMakeWrapper, Txt } from 'pdfmake-wrapper';
import { invoiceReport } from 'src/app/models/invoiceReport';
import { invoiceReportService } from 'src/app/services/invoice.service';

@Component({
  selector: 'app-factura-global',
  templateUrl: './factura-global.component.html',
  styleUrls: ['./factura-global.component.scss']
})
export class FacturaGlobalComponent implements OnInit {


  //Variable que captura el ID del inmueble
  codigoInm = ""
  conInmueble:any;

  //Generar PDF
  public generarPDF(): void {
    const pdf = new PdfMakeWrapper();    
    pdf.add(new Txt('Lo consegui!').bold().italics().end);
    pdf.create().download();
  }

  //Calculo de fecha
  n = new Date();
  //Año
  y = this.n.getFullYear();
  //Mes
  m = this.n.getMonth() + 1;
  //Día
  d = this.n.getDate();

  h = this.n.toLocaleTimeString()

  salida = ""
  salidaFecha() {
    if (this.m < 9 && this.d < 9) {
      this.salida = this.y + '-' + '0' + this.m + '-' + '0' + this.d + ' ' + this.h;
    } else if (this.d < 9 && this.m > 9) {
      this.salida = this.y + '-' + this.m + '-' + '0' + this.d + ' ' + this.h;
    } else if (this.d > 9 && this.m < 9) {
      this.salida = this.y + '-' + '0' + this.m + '-' + this.d + ' ' + this.h;
    } else {
      this.salida = this.y + '-' + this.m + '-' + this.d + ' ' + this.h;
    }
    //console.log(this.salida);
    return this.salida   
  }
 

  //Control de usuarios que requieren factura, arreglo
  listUsers:any=[]

  //Configuraciones de autocompletado
  keyword = 'name';

  onKeypressEvent(event: any) {
    this.conInmueble=event

    let cad:any = {};
    cad = this.conInmueble.name;

    if(event==' '||event==null||event==""){
      console.log("Se requiere consultar cliente")
    }else {
      this.codigoInm = cad.substr(0, 8);      
      this.invReport.fkProperty = cad.substr(0, 8);
      this.invReport.RFC = cad.substr(-13);
      this.invReport.UserName = cad.substr(9, 22);      
    }
  }

  //Objeto de servicio factura
  invReport: invoiceReport = {
    id: 0,
    RFC: '',
    UserName: '',
    invoiceType: 'Global',
    registrationDate: this.salidaFecha(),
    modificationDate: null,
    paymentType: '',
    invoiceStatus: 'Aprobada',
    folInvoice: null,
    amountInvoice: null,
    fkProperty: '',
    accountingFolio:null
  };

  //Constructor y metodos principales


  constructor(private invoiceService: invoiceReportService, private router: Router) {
  }

  //Metodo de carga de metodos al momento de la ejecución
  ngOnInit(): void {     
    this.selectInvoiceUsers();
  }
  selectInvoiceUsers() {    
    this.invoiceService.invoiceReportList()
      .subscribe(
        res => {                    
          this.listUsers = res;
        },
        err => console.error(err)
      );
  }

  //Consulta de datos desde el servicio que consulta a la API
  createInvoiceReport() {
    this.invoiceService.createInvoiceReport(this.invReport)
      .subscribe(        
        res => {
          console.log("bien")        
          this.router.navigate(['/facturaGlobal'])
        },
        err => console.error(err)
      );
  }

}

