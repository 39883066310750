import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SweetAlertComponent } from '../../../../utils/sweet-alert/sweet-alert.component';

import { ProductcatalogService } from 'src/app/services/productcatalog.service';

//=========================================================================================================
//Importación de metodo de decodificación
//=========================================================================================================
import decode from 'jwt-decode';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-insertproducts',
  templateUrl: './dialog-insertproducts.component.html',
  styleUrls: ['./dialog-insertproducts.component.scss']
})
export class DialogInsertproductsComponent implements OnInit {

  //Objeto para recuperar la información del usuario logeado
  decode: any = {};

  constructor(
    private dialogRef: MatDialogRef<DialogInsertproductsComponent>,
    public productCatalogService: ProductcatalogService,
    private _formBuilder: FormBuilder,
    public sweet: SweetAlertComponent,
    private router: Router
  ) { }

  //Objeto de formulario de productos
  productsFormGroup: FormGroup = this._formBuilder.group({
    idproduct: [],
    productname: ['', Validators.required],
    unit: ['', Validators.required],
    useremail: [this.getUser()]
  });

  ngOnInit(): void {
  }

  //=======================================================================================
  //Función para insertar un nuevo producto al catalogo de materiales
  //=======================================================================================
  async insertProduct() {
    if (this.productsFormGroup.invalid) {
      return;
    } else {
      Swal.fire({
        title: '¿Quieres guardar tus cambios ahora?',
        text: "¡Este producto se guardara como está actualmente!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, guárdalo!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.productCatalogService.insertProduct(this.productsFormGroup.value).subscribe(
            res => {
              this.sweet.AlertTime("center", "success", "Material registrado con éxito", false, 2000);
              this.dialogRef.close(res);
            }
          );
        }
      })
    }
  }

  //==========================================================================================================
  //Recuperar el correo del usuario que está haciendo uso de la app al momento del registro
  //==========================================================================================================
  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.email
  }

}
