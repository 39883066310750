import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import Swal from 'sweetalert2';
import decode from 'jwt-decode';

import { AdministrationService } from 'src/app/services/administration.service';

@Component({
  selector: 'app-schedule-view',
  templateUrl: './schedule-view.component.html',
  styleUrls: ['./schedule-view.component.scss']
})
export class ScheduleViewComponent implements OnInit {
  decode: any = {};

  constructor(
    public negotiationService: AdministrationService,
    private dialogRef: MatDialogRef<ScheduleViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private _formBuilder: FormBuilder
  ) { }

  scheduleFormGroup: FormGroup = this._formBuilder.group({
    FKNegotiation: [this.data.PKNegotiation],
    PaybackDate: [, Validators.required],
    PaybackAmount: [, Validators.required],
    Responsible: [this.getUser()]
  });

  ngOnInit(): void {
    this.getUser();
  }

  schedulePayback() {
    Swal.fire({
      title: '¿Quieres agendar este cliente?',
      text: "¡Se registrará como pendiente de pago!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No, espera',
      confirmButtonText: 'Sí, agendalo!',
      buttonsStyling: false,
      customClass: {
        actions: 'sweetActions',
        confirmButton: 'm19AltBtn',
        cancelButton: 'm19Btn'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.negotiationService.schedulePayback(this.scheduleFormGroup.value).subscribe(
          res => {
            this.dialogRef.close();
          }
        );
      }
    })
  }

  getUser() {
    this.decode = decode(localStorage.getItem("token") || "")
    return this.decode.fkuser;
  }

}
