import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';

import { AdministrationService } from 'src/app/services/administration.service';
import { ControComissionsService } from 'src/app/services/contro-comissions.service';
import { BannerTitle } from 'src/app/ui/banner-title/banner-title.model';

import { DatePipe } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL

@Component({
  selector: 'app-report-status-customer',
  templateUrl: './report-status-customer.component.html',
  styleUrls: ['./report-status-customer.component.scss']
})
export class ReportStatusCustomerComponent implements OnInit {

  bannerTitle: BannerTitle = {
    title: "Estado General de los Clientes",
    icon: "groups",
    position: "center",
    background: "var(--M19Secundary)"
  }

  counters: any = {
    "block": 0,
    "direction": 0,
    "payment": 0,
    "contract": 0,
    "ratification": 0,
    "complete": 0,
    "contractComplete": 0
  };

  //Variables
  projects: any = [];
  customers: any = "Sin seleccionar";
  pipe = new DatePipe('es-MX');

  constructor(
    public projectService: ProjectService,
    private administrationService: AdministrationService,
    private controlCommissionService: ControComissionsService
  ) { }

  ngOnInit(): void {
    this.getProjects();
    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
  }

  //Recupera la lista de proyectos de la tabla projects
  async getProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      },
      err => console.log(err)
    );
  };

  async filterByProject(project: number) {
    this.controlCommissionService.getAllCustomee(project).subscribe(
      res => {
        this.customers = res;
        this.counters = {
          "block": 0,
          "direction": 0,
          "payment": 0,
          "contract": 0,
          "ratification": 0,
          "complete": 0,
          "contractComplete": 0
        }

        for (let c of this.customers) {
          if (c.fk_status === 2) {
            if (c.fk_blockReason === 6) {
              this.counters.direction++;
            } else {
              this.counters.block++;
            }
          }
          if (c.Anticipo === 'Full') {
            this.counters.payment++;
          }
          if (c.Contrato === 'Done') {
            this.counters.contract++;
          }
          if (c.ratificationState == 'Enviado') {
            this.counters.ratification++;
          }
          if (c.fk_salesCatalog == 6 || c.fk_salesCatalog == 10 || c.fk_salesCatalog == 11 || c.fk_salesCatalog == 12) {
            this.counters.complete++;
          }
        }

        this.administrationService.getCompleteContractsByProject(project).subscribe(
          res => {
            this.counters.contractComplete = res.contracts;
          }
        );
      }
    );
  }
}