<div>

    <div style="display:flex ; flex-direction:row">

        <div class="col-md-4">
            <mat-card>

                <mat-card-title>
                    <h3> Proyecto: {{customersBlocked.proje}} </h3>
                    <h3> Motivo: {{customersBlocked.reason}} </h3>
                    <h3> Cantidad: {{customersBlocked.block.length}} </h3>
                </mat-card-title>

                <mat-card-content>

                </mat-card-content>
            </mat-card>
        </div>


        <div class="col-md-8">
            <mat-card>

                <mat-card-content style="height: 60vh; overflow: scroll;">
                    <table class="table table-striped animated fadeIn faster" style="overflow: scroll;">
                        <thead class="thead-m19">

                            <tr align="center">
                                <th colspan="5">
                                    <h5> Listado de clientes </h5>
                                </th>
                            </tr>
                        </thead>
                        <thead>
                            <tr>
                                <th scope="col" style="color: rgb(38, 5, 116); text-align: center; align-items:center">
                                    id venta </th>
                                <th scope="col" style="color: rgb(38, 5, 116); text-align: center; align-items:center">
                                    Fecha de contrato </th>
                                <th scope="col" style="color: rgb(38, 5, 116); text-align: center; align-items:center">
                                    Cliente </th>
                                <th scope="col" style="color: rgb(38, 5, 116); text-align: center; align-items:center">
                                    Propiedad </th>
                                <th scope="col" style="color: rgb(38, 5, 116); text-align: center; align-items:center">
                                    Fecha de bloqueo </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let custBloq of customersBlocked.block">

                                <th> {{custBloq.idSale}} </th>

                                <td >
                                    <ng-container *ngIf="custBloq.contractDate == null ; then sinfecCont else fecCon">       </ng-container>
                             
                                    <ng-template #fecCon>
                                        <p> {{custBloq.contractDate}} </p>
                                    </ng-template>

                                    <ng-template #sinfecCont>
                                        <p> No se registro la fecha de contrato </p>
                                    </ng-template>

                                </td>

                                <td> {{custBloq.nombre}} </td>

                                <td> {{custBloq.fk_property}} </td>

                                <td>

                                    <ng-container *ngIf="custBloq.blockDate != null ; then conFecha else sinFecha">      </ng-container>
                              
                                    <ng-template #conFecha>
                                        <p> {{custBloq.blockDate | date: 'dd-MM-yyyy'}} </p>
                                    </ng-template>

                                    <ng-template #sinFecha>
                                        <p> No se registro fecha de bloqueo </p>
                                    </ng-template>

                                </td>

                            </tr>
                        </tbody>
                    </table>

                </mat-card-content>

            </mat-card>

        </div>


    </div>

    <div style="display:flex ; flex-direction:row">

        <mat-card class="card-form">

            <mat-card-content class="justify-align-center">

                <h1 style="align-items: center; text-align:center">  Clientes bloqueados </h1>

            </mat-card-content>

        </mat-card>

    </div>

</div>