<h1>{{data.idEmployee}}. {{data.name}} {{data.firstLastName}} {{data.secondLastName}}</h1>

<mat-divider></mat-divider>

<mat-dialog-content>
    <table class="table table-striped mt-3 animated fadeIn faster">
        <tr *ngIf="data.telephone != null">
            <th>Teléfono fijo</th>
            <td>{{data.telephone}}</td>
        </tr>
        <tr>
            <th>Celular</th>
            <td>{{data.mobile}}</td>
        </tr>
        <tr *ngIf="data.email != null">
            <th>Correo electrónico</th>
            <td>{{data.email}}</td>
        </tr>
        <tr>
            <th>Dirección</th>
            <td>{{data.address}}</td>
        </tr>
        <tr>
            <th>Colonia o localidad</th>
            <td>{{data.localidad}}</td>
        </tr>
        <tr>
            <th>Municipio</th>
            <td>{{data.municipality}}</td>
        </tr>
        <tr>
            <th>Estado</th>
            <td>{{data.state}}</td>
        </tr>
        <tr>
            <th>Código postal</th>
            <td>{{data.postalCode}}</td>
        </tr>
        <tr>
            <th>Estatus</th>
            <td *ngIf="data.estatus == '1'">Activo</td>
            <td *ngIf="data.estatus == '0'">Inactivo</td>
        </tr>
        <tr>
            <th>Fecha de registro</th>
            <td>{{data.registrationDate | date: 'dd-MMM-yyyy hh:mm:ss'}}</td>
        </tr>
    </table>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>