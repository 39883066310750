import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DatePipe } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';

import Swal from 'sweetalert2';
import { loader } from 'src/app/ui/loader/loader.model';

import { AdministrationService } from 'src/app/services/administration.service';

@Component({
  selector: 'app-negotiation-detail',
  templateUrl: './negotiation-detail.component.html',
  styleUrls: ['./negotiation-detail.component.scss']
})
export class NegotiationDetailComponent implements OnInit {

  pipe = new DatePipe('es-MX');

  loader: loader = {
    message: "Preparando Información"
  }

  negotiation: any = {};
  unitsList: any[] = [];
  paybacks: any[] = [];

  constructor(
    public negotiationService: AdministrationService,
    private dialogRef: MatDialogRef<NegotiationDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) { }

  ngOnInit(): void {
    this.getNegotiationDetail(this.data);
    this.getNegotiationPaybacks(this.data);
    registerLocaleData(localeEsMx, 'es-MX');
  }

  getNegotiationDetail(PKNegotiation: number): void {
    this.negotiationService.getNegotiationByPK(PKNegotiation).subscribe(
      res => {
        this.unitsList = res.data;
        this.negotiation = this.unitsList[0];
      }
    );
  }

  getNegotiationPaybacks(PKNegotiation: number): void {
    this.negotiationService.getNegotiationPaybacks(PKNegotiation).subscribe(
      res => {
        this.paybacks = res.data;
      }
    );
  }

  printReceipt(PKPaybackRecord: number): void {
    Swal.fire({
      title: '¿Quieres reimprimir el recibo de devolución?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
      buttonsStyling: false,
      customClass: {
        actions: "sweetActions",
        confirmButton: "m19AltBtn",
        cancelButton: "m19Btn"
      }
    }).then((result) => {
      if (result.isConfirmed) {

      }
    })
  }
}