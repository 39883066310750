import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL
import { DatePipe } from '@angular/common';
import { RecessionsService } from 'src/app/services/recessions.service';
import { SaleService } from 'src/app/services/sale.service';
import { GenerateRecessionRequestComponent } from '../../administration/recessions-request/generate-recession-request/generate-recession-request.component';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

//INTERFACE
interface ContractsNumber {
  numberOfContracts: number;
  contractsDescription?: string;
}

@Component({
  selector: 'app-recession-file',
  templateUrl: './recession-file.component.html',
  styleUrls: ['./recession-file.component.scss'],
})
export class RecessionFileComponent implements OnInit {

  /*====================================================================================
  ======================================VARIABLES=======================================
  ====================================================================================*/
  pipe = new DatePipe('es-MX'); //Variable para conversiones de formatos de numeros
  contracts: ContractsNumber[] = [ //Contratos en expediente, utilizado en mat-select->Número de contratos
    { numberOfContracts: 0, contractsDescription: 'Sin contratos en expediente' },
    { numberOfContracts: 1, contractsDescription: "1 original" },
    { numberOfContracts: 2, contractsDescription: "2 originales" },
    { numberOfContracts: 3, contractsDescription: "3 originales" },
  ]
  contractsDefault = this.customer.expContract;
  subExpInit: any = [{ index: 0, name: 'Act. Nac.', value: false },
  { index: 1, name: 'CURP', value: false },
  { index: 2, name: 'Com. Dom.', value: false },
  { index: 3, name: 'Const. Sit. Fis.', value: false }];

  iconDetail: any = [
    { type: 'verified', color: '#02bd54' },
    { type: 'warning', color: '#F8BA43' },
    { type: 'error', color: '#D94555' }
  ]

  statusValidator: any = [//Contiene la variable inicial que indica el estado de cada paso en el proceso de recisión
    { name: 'Expediente inicial', status: this.iconDetail[2].type, color: this.iconDetail[2].color, dataTitle: 'Sin descripción' },
    { name: 'Solicitud de recision', status: this.iconDetail[2].type, color: this.iconDetail[2].color, dataTitle: 'Sin descripción' },
    { name: 'Convenio de recision', status: this.iconDetail[2].type, color: this.iconDetail[2].color, dataTitle: 'Sin descripción' },
    { name: 'Contratos originales', status: this.iconDetail[2].type, color: this.iconDetail[2].color, dataTitle: 'Sin descripción' },
    { name: 'Recibos', status: this.iconDetail[2].type, color: this.iconDetail[2].color, dataTitle: 'Sin descripción' }
  ]

  ExpedienteInicial: any = {//ELEMENTO DE ACTUALIZACION PARA EL REGISTRO DE EXPEDIENTE INICIAL
    expBirth: 0,
    expCURP: 0,
    expAddress: 0,
    exptaxStatus: 0
  }
  ValidateStatus: number = 0;

  NTickets: number = 0;//Numero de tickets que tiene el expediente sobre el cliente

  phaseRecession = 0;//Fase 1: Crear solicitud; Fase 2: Cancelar solicitud o Elaborar convenio; Fase 3: Cancelar convenio

  constructor(
    @Inject(MAT_DIALOG_DATA) public customer: any,
    public dialog: MatDialog,
    public userService: UserService,
    private recessionsService: RecessionsService,
    private saleService: SaleService,
    private cd: ChangeDetectorRef
  ) {
    setTimeout(() => {
      registerLocaleData(localeEsMx, 'es-MX'); //REGISTRAR LOCAL PARA ESPAÑOL
      this.expInitial(customer.statusExp);
      this.expContractsValidator(customer.expContract);
      this.NTickets = customer.expTickets;
      this.nTickets(this.NTickets);
      (this.customer.recessionCreateDate != null) ? this.agreementValidator(true) : this.agreementValidator(false);
    })
  }

  ngOnInit(): void {
    console.log('Valor del contrato:', this.contracts[1]);
    console.log('objeto recibido', this.customer);
    console.log('subExpInit', this.subExpInit);
    console.log('Color', this.statusValidator[0].color);
    console.log('Tickets en bd', this.customer.expTickets);
  }
  public async nTickets(a: any) {
    if (a == "" || a == null || a == undefined) { a = 0 }
    console.log(a);
    console.log(this.customer.tickets, (a == this.customer.tickets));
    this.NTickets = a;
    console.log('Valor inicial de NTickets', this.NTickets)
    if (this.NTickets == this.customer.tickets) {
      this.statusValidator[4] = {
        status: this.iconDetail[0].type,
        color: this.iconDetail[0].color,
        dataTitle: 'Todos los recibos validados',
      }
    } else if (this.NTickets <= 0 || this.NTickets > this.customer.tickets) {
      this.statusValidator[4] = {
        status: this.iconDetail[2].type,
        color: this.iconDetail[2].color,
        dataTitle: 'Recibos de pago no válidos',
      }
    } else {
      this.statusValidator[4] = {
        status: this.iconDetail[1].type,
        color: this.iconDetail[1].color,
        dataTitle: 'Recibos de pago parcialmente validados',
      }
    }
  }
  async genSolicitud(data: number) {
    const dialogo = this.dialog.open(GenerateRecessionRequestComponent, {
      width: '100%',
      data: data
    });
  }
  async expInitial(event: any) {
    this.ExpedienteInicial.expBirth = this.ExpedienteInicial.expCURP = this.ExpedienteInicial.expAddress = this.ExpedienteInicial.exptaxStatus = 0;
    let cadenaComp = ""
    event.forEach(element => { //CUANDO HAYA UN CAMBIO:Recorre el arreglo que contiene el valor del select de options para el exediente inicial y lo prepara en un objeto para la inserción
      if (element == 0) { this.ExpedienteInicial.expBirth = 1; cadenaComp += "0" }
      if (element == 1) { this.ExpedienteInicial.expCURP = 1; cadenaComp += "1" }
      if (element == 2) { this.ExpedienteInicial.expAddress = 1; cadenaComp += "2" }
      if (element == 3) { this.ExpedienteInicial.exptaxStatus = 1; cadenaComp += "3" }
    });
    if (cadenaComp == "0123") {
      this.statusValidator[0] = {
        status: this.iconDetail[0].type,
        color: this.iconDetail[0].color,
        dataTitle: 'Expediente completo :)'
      }
    }
    else if (cadenaComp == "") {
      this.statusValidator[0] = {
        status: this.iconDetail[2].type,
        color: this.iconDetail[2].color,
        dataTitle: 'Sin expediente'
      }
    } else {
      this.statusValidator[0] = {
        status: this.iconDetail[1].type,
        color: this.iconDetail[1].color,
        dataTitle: '[ADVERTENCIA]: Expediente incompleto'
      }
    }
    console.log('Objeto de actualizacion de expediente', this.ExpedienteInicial);
  }
  async saveButton() {
    Swal.fire({
      icon: 'warning',
      title: '¿Guardar cambios?',
      html: 'Los cambios se guardarán para editar mas tarde pero no se finalizará el expediente, ¿desea guardar cambios?',
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      denyButtonText: `Cancelar`,
      confirmButtonColor: '#4c9141',
    }).then((result) => {
      if (result.isConfirmed) {
        this.saveChanges()
      }
    })
  }
  async saveChanges() {
    this.userService.putExpedient(this.customer.idUser, this.ExpedienteInicial).subscribe(
      res => {
        console.log('expediente actualizado');
        if (this.customer.status == "RECESSIONS") {
          this.recessionsService.updateExpRecessions(this.contractsDefault, this.customer.id).subscribe(
            res => {
              console.log("Actualizado el numero de contratos en recisiones a ", this.contractsDefault)
              this.recessionsService.recessionInsertTicketPay(this.NTickets, this.customer.id).subscribe(
                res => {
                  console.log('Actualizado el numero de tickets en recesiones a', this.NTickets)
                  Swal.fire('¡Cambios guardados!', '', 'success')
                  this.dialog.closeAll();
                }
              )
            }
          )
        } else {
          this.saleService.updateExpSales(this.contractsDefault, this.customer.id).subscribe(
            res => {
              console.log("Actualizado el numero de contratos en SALES a ", this.contractsDefault)
              this.saleService.saleInsertTicketPay(this.NTickets, this.customer.id).subscribe(
                res => {
                  console.log('Actualizado el numero de tickets en SALES a', this.NTickets)
                  Swal.fire('¡Cambios guardados!', '', 'success')
                  this.dialog.closeAll();
                }
              )
            }
          )
        }
      }
    );
  }
  async recessionDocument(customer: any) {
    Swal.fire({
      icon: 'warning',
      title: 'ADVERTENCIA',
      html: 'Inmueble: ' + customer.fk_property + '.<br>Cliente: ' + customer.fk_customer + '.<br>Fecha de elaboración de solicitud de recisión: ' + customer.recessionRequestDate + ' <br><br><h4>Usted está a punto de marcar como generado y entregado el convenio rescisorio, ¿Desea continuar?</h4><br> Se recomienda que solo personal autorizado por área jurídica modifique esta propiedad, el uso incorrecto de esta función puede causar inconsistencia en los datos',
      showDenyButton: true,
      denyButtonText: `No, no continuar`,
      confirmButtonText: 'Lo entiendo y deseo continuar',
      confirmButtonColor: '#4c9141',
    }).then((result) => {
      if (result.isConfirmed) {
        if (customer.status == "SALES") {
          this.saleService.setRecessionAgreement(customer.id).subscribe(
            res => {
              console.log('Realizado en sales', customer);
              this.agreementValidator(true);
            }
          )
        } else {
          this.recessionsService.setRecessionAgreement(customer.id).subscribe(
            res => {
              console.log('Realizado en recessions', customer);
              this.agreementValidator(true);
            }
          )
        }
        Swal.fire('¡Guardado!', '', 'success')
      } else if (result.isDenied) {
        Swal.fire({
          icon: 'info',
          title: 'No se realizaron cambios',
          showConfirmButton: false,
          timer: 3000
        })
      }
    })
  }
  async cancel() {
    Swal.fire({
      icon: 'warning',
      title: '¿Descartar cambios?',
      html: 'Si cierra la ventana sin guardar cambios, los cambios se perderán, ¿Desea continuar?',
      showDenyButton: true,
      denyButtonText: `No, no cerrar la ventana`,
      confirmButtonText: 'Salir y descartar cambios',
      confirmButtonColor: '#4c9141',
    }).then((result) => {
      if (result.isConfirmed) {
        this.dialog.closeAll();
      }
    })
  }
  async expContractsValidator(event: any) {
    console.log(event)
    if (event == 3) {
      this.statusValidator[3] = {
        status: this.iconDetail[0].type,
        color: this.iconDetail[0].color,
        dataTitle: 'Contratos completos :)',
      }
    } else if (event == 0) {
      this.statusValidator[3] = {
        status: this.iconDetail[2].type,
        color: this.iconDetail[2].color,
        dataTitle: 'SIN contratos en expediente',
      }
    } else {
      this.statusValidator[3] = {
        status: this.iconDetail[1].type,
        color: this.iconDetail[1].color,
        dataTitle: 'Copias de contrato incompletas',
      }
    }
  }
  async agreementValidator(selector: boolean) {
    if (selector) {
      this.statusValidator[2] = {
        status: this.iconDetail[0].type,
        color: this.iconDetail[0].color,
        dataTitle: 'Convenio entregado a administración',
      }
    } else {
      this.statusValidator[2] = {
        status: this.iconDetail[2].type,
        color: this.iconDetail[2].color,
        dataTitle: 'Convenio sin entregar a administración',
      }
    }
  }
  async cancelAgreement(customer: any) {//Cancela el convenio de recisión
    Swal.fire({
      icon: 'warning',
      title: 'ADVERTENCIA',
      html: 'Está por cancelarse de forma anormal el convenio de recisión, esta función solo debería utilizarse por personal de area jurídica o gerencia a cargo del proceso de recisión, ¿Desea cancelar el convenio de recisión?',
      showDenyButton: true,
      denyButtonText: `No, no cancelar`,
      confirmButtonText: 'Cancelar convenio de recisión',
      confirmButtonColor: '#4c9141',
    }).then((result) => {
      if (result.isConfirmed) {
        if (customer.status == "RECESSIONS") {
          this.recessionsService.deleteRecessionAgreement(customer.id).subscribe(
            res => {
              console.log('Convenio eliminado de recisiones');
              this.agreementValidator(false);
              Swal.fire('¡Convenio cancelado!', '', 'success')
            }
          )
        } else {
          this.saleService.deleteRecessionAgreement(customer.id).subscribe(
            res => {
              console.log('Convenio eliminado de sales');
              this.agreementValidator(false);
              Swal.fire('¡Convenio cancelado!', '', 'success')
            }
          )
        }
      }
    })
  }

  async validateRecession() {
    //Aqui se van a implementar todo el procedimiento para validar un registro listo para insertar
    //ValidateStatus es la variable que indicará que se tiene que hacer en cada caso
    //ValidateStatus=0 es error de control
    //ValidateStatus=1 es todo listo para finalizar recisión
  }

  async recessionComplete() {
    this.ValidateStatus = 1
    if (this.ValidateStatus == 1) {
      Swal.fire({
        icon: 'warning',
        title: '¿Guardar cambios?',
        html: 'Se finalizará el expediente, se completará y archivará esta recisión, ¿Desea continuar?',
        showCancelButton: true,
        confirmButtonText: 'Finalizar y archivar',
        denyButtonText: `Cancelar`,
        confirmButtonColor: '#4c9141',
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.customer.status == "RECESSIONS") {
            this.recessionsService.updateExpedient(this.customer.id).subscribe(
              res=>{
                console.log('Expediente finalizado en recesiones')
                this.dialog.closeAll();
              }
            )
          }else{
            this.saleService.updateExpedient(this.customer.id).subscribe(
              res=>{
                console.log('Expediente finalizado en sales')
                this.dialog.closeAll();
              }
            )
          }
        }
      })
    }
  }
}
