<!-- Información de partidas y material asignado -->
<app-loader [loader]="loader" *ngIf="loading==1"></app-loader>
<mat-dialog-content>
    <div id="htmlData" [hidden]="true">
        <div class="requisitionHeader">
            <div class="titles">
                <div class="main">
                    <p class="header">ASESORIA, SUPERVISIÓN Y CONSTRUCCIÓN ÁREA TÉCNICA</p>
                    <div class="logos">
                        <img src="./assets/iconoM.png" alt="" width="100px" />
                        <img [src]="cbrLogo" alt="" width="150px" />
                    </div>
                </div>

                <div class="projectData">
                    <p><img [src]="logo.UrlLogo" alt="" width="100px" /></p>
                </div>
            </div>
        </div>

        <div class="requisitionInfo">
            <p><b>ORDEN DE COMPRA</b></p>
            <p><b>OBRA {{printReq[0].projectname.toUpperCase()}}</b></p>
            <p><b>MODULO: {{printReq[0].towernumber}}. REQUISICIÓN NÚMERO {{printReq[0].requisitionnumber}}</b></p>
            <p><b>FECHA: {{printReq[0].requisitiondate | date: 'dd/MM/yyyy'}}</b></p>
        </div>

        <table class="materials">
            <thead>
                <tr style="text-align: center;">
                    <th scope="col">Cantidad</th>
                    <th scope="col">Unidad</th>
                    <th scope="col">Descripción del producto</th>
                    <th scope="col">Clave</th>
                    <th scope="col">Descripción</th>
                    <th scope="col">Observaciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let d of printReq;">
                    <td style="text-align: center;">{{d.amount}}</td>
                    <td style="text-align: center;">{{d.unit}}</td>
                    <td>{{d.productname}}</td>
                    <td style="text-align: center;">{{d.departurenumber}}</td>
                    <td>{{d.departurename}}</td>
                    <td>{{d.notes}}</td>
                </tr>
            </tbody>
        </table>
        <div class="firmas">
            <p class="elabora">{{printReq[0].responsable}}<br><b>Elaboró</b></p>
            <p class="aprueba">{{printReq[0].auth}}<br><b>Aprobó</b></p>
        </div>
    </div>

    <!-- <button (click)="getRequisitionToPrint(departures[0].idrequisition)" class="printButton"
        *ngIf="departures.length > 0 && departures[0].approve=='SI'">
        <mat-icon>print</mat-icon>
    </button> -->

    <button (click)="getRequisitionToPrint(departures[0].idrequisition)" class="printButton">
        <mat-icon>print</mat-icon>
    </button>

    <!-- <span *ngIf="departures[0].approve!='SI'"
        style="color: var(--M19Primary); font-weight: bold;">{{departures[0].approve=='NA'?'AUTORIZACIÓN
        PENDIENTE':departures[0].approve=='NO'?'REQUISICIÓN RECHAZADA':'ESTADO DESCONOCIDO'}}</span> -->

    <!-- Condicional, si el tamaño del arreglo que almacena las partidas es mayor a 0 se imprimen sus partidas -->
    <div *ngIf="departures.length > 0">
        <table style=" width: 100%;">
            <thead style="text-align: center; font-size: 1.5rem;">
                <tr>
                    <th colspan="2" style="border-bottom: solid var(--M19Primary);">RESÚMEN DE REQUISICIÓN</th>
                </tr>
            </thead>
            <tbody>
                <tr style="text-align: center; height: 50px;">
                    <td class="datos">FECHA: {{departures[0].requisitiondate | date: 'dd-MMM-yyyy'}}.</td>
                    <td class="datos">REQUISICIÓN: No. {{departures[0].requisitionnumber}}
                </tr>
                <tr style="text-align: center;">
                    <td class="datos">OBRA: {{departures[0].projectname}}.</td>
                    <td class="datos">MODULO: {{departures[0].towernumber}}.</td>
                </tr>
            </tbody>
        </table>

        <br>

        <!-- ngFor para recorrer todas las partidas conseguidas -->
        <mat-accordion>
            <mat-expansion-panel *ngFor="let d of departures" hideToggle>
                <mat-expansion-panel-header style="height: fit-content;"
                    (click)="getProductsFromDeparture(d.idrequisition,d.iddeparture, d.idconcept)">
                    <mat-panel-title style="width: 50%;"><b>
                            <span>{{"Partida " + d.departurenumber}}: {{d.departurename}}</span>
                        </b></mat-panel-title>
                    <mat-panel-description style="width: 50%;"><b>
                            <span>{{d.conceptname}}</span>
                        </b></mat-panel-description>
                </mat-expansion-panel-header>
                <table class="m19Table">
                    <thead>
                        <tr style="text-align: center;">
                            <th scope="col">#</th>
                            <th scope="col">Descripción del producto</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Unidad</th>
                            <th scope="col">Observaciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="text-align: center;" *ngFor="let p of products; index as i">
                            <td>{{i+1}}</td>
                            <td>{{p.productname}}</td>
                            <td>{{p.amount}}</td>
                            <td>{{p.unit}}</td>
                            <td>{{p.notes==null?'Sin comentarios':p.notes==''?'Sin comentarios':p.notes}}</td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</mat-dialog-content>

<!-- Si el tamaño del arreglo de partidas es igual a 0 entonces no se tienen partidas registradas de esa requisición -->
<h3 *ngIf="departures.length == 0">{{"Esta requisición no tiene partidas registradas"}}.</h3>

<!-- Botón para cerrar el dialog -->
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>