<div class="login-page">
  <div class="form">

    <img width="60%" alt="Logo" src="../assets/isotipo.png" style="filter: opacity(0.8); margin-bottom: 50px;">

    <form [formGroup]="loginFormGroup" (ngSubmit)="logIn();">

      <mat-form-field appearance="outline" class="col-md-12">
        <mat-label> Correo </mat-label>
        <input matInput type="email" placeholder="Usuario" id="email" formControlName="email">
        <mat-icon matSuffix> alternate_email </mat-icon>
      </mat-form-field>

      <div style="display: flex; flex-direction: row;">
        <mat-form-field appearance="outline" class="col-md-12">
          <mat-label> Contraseña </mat-label>
          <input matInput type="{{shwPwd ? 'text':'password'}}" placeholder="Contraseña" id=password
            formControlName="password">
          <mat-icon matSuffix (click)="changeStatus(!shwPwd)" style="cursor:pointer;" *ngIf="shwPwd">visibility_on</mat-icon>
          <mat-icon matSuffix (click)="changeStatus(!shwPwd)" style="cursor:pointer;" *ngIf="shwPwd == false">visibility_off</mat-icon>
        </mat-form-field>
      </div>

      <button class="m19Btn" type="submit" [disabled]="!loginFormGroup.valid">
        Ingresar
      </button>
    </form>

  </div>
  <div class="rightSide">
    <div class="rightContainer">
      <h1>
        <span class="first">Tu hogar,</span><br><span class="second">nuestro sueño.</span>
      </h1>
    </div>
  </div>
</div>
