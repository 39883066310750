import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MapsService } from 'src/app/services/maps.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SweetAlertComponent } from 'src/app/utils/sweet-alert/sweet-alert.component';

@Component({
  selector: 'app-update-map-to-firebase',
  templateUrl: './update-map-to-firebase.component.html',
  styleUrls: ['./update-map-to-firebase.component.scss']
})
export class UpdateMapToFirebaseComponent implements OnInit {
  public projects: any = []
  selected_project = { name: '', nomenclature: '' };
  linkMap = "";
  proyect = '';
  isLinear = true;

  step1: FormGroup = this._formBuilder.group({
    idProject: ['', Validators.required]
  });


  uploadPercent!: Observable<number | undefined>;
  urlImage!: Observable<string | undefined>;

  constructor(
    private getProjects: MapsService,
    private storage: AngularFireStorage,
    private _formBuilder: FormBuilder,
    private alerta: SweetAlertComponent
  ) {
  }

  @ViewChild('linkdelmapa') inputImageUser!: ElementRef

  ngOnInit(): void {
    this.getProject();
  }

  getProject() {
    this.getProjects.selectAllProjects().subscribe(
      res => {
        this.projects = res;
      },
      err => console.log(err)
    )
  }

  //SELECCIONAR UN LINK EN ESPECIAL
  selectLink(id: string) {
    this.getProjects.selectLink(id).subscribe(
      res => {
        this.linkMap = res.imgMap;
        localStorage.setItem('linkMapToUpdate', this.linkMap)
      },
      err => console.log(err)
    );
  }

  setProject() {
    localStorage.setItem('selectedNameToUpdate', this.selected_project.name);
    localStorage.setItem('selectednomenclatureToUpdate', this.selected_project.nomenclature);
    this.selectLink(localStorage.getItem('selectedNameToUpdate') || "")
  }


  onUpload(event: any) {
    //console.log("subir", event.target.files[0]);
    const id = Math.random().toString(36).substring(2);

    const file = event.target.files[0];

    const filePath = `mapas/maps_` + localStorage.getItem('selectedNameToUpdate')

    const ref = this.storage.ref(filePath);

    const task = this.storage.upload(filePath, file)

    this.uploadPercent = task.percentageChanges()

    task.snapshotChanges().pipe(
      finalize(() =>
        this.urlImage = ref.getDownloadURL()
      )
    ).subscribe();

  }


  //insertar el mapa
  async insertMap() {
    this.getProjects.insertNewMap(this.inputImageUser.nativeElement.value, localStorage.getItem('selectednomenclatureToUpdate')).subscribe(
      res => {
        this.alerta.AlertIcon("success", "Mapa agregado con exito", "El nuevo mapa se encuentra en la base de datos", "")
        location.reload();
      },
      err => { alert("no se agrego el mapa ") }
    )
  }




}