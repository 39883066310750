import { Component, OnInit } from '@angular/core';

import { ProjectService } from 'src/app/services/project.service';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-flow-report-v2',
  templateUrl: './flow-report-v2.component.html',
  styleUrls: ['./flow-report-v2.component.scss']
})

export class FlowReportV2Component implements OnInit {
  displayedColumns: string[] = ['activos', 'flujoEsp', 'contratosPago', 'ingresoFlujo', 'avance'];
  dataSourceWeek1: any;
  dataSourceWeek2: any;
  dataSourceWeek3: any;
  dataSourceWeek4: any;
  projects: any = [];
  today: Date = new Date();

  projectRange = [];
  projectFilter = "";
  searchMonth: string = "";
  month = "Actual";
  mesesFlujo: any = ["Actual", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  reportData: any = {"firstWeek": [], "secondWeek": [], "thirdWeek": [], "fourthWeek": []};

  constructor(
    public projectService: ProjectService,
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.selectProjects();
  }

  setNewLimit() {
    this.getReportData();
  }

  setRange() {
    this.projectFilter = this.projectRange.map(p => `'${p}'`).join(', ');

    this.getReportData();
  }

  getLimit() {
    return this.today.getFullYear() + "-" + (this.today.getMonth()+1)
  }

  async selectProjects() {
    this.projectService.selectProjects().subscribe(
      res => {
        this.projects = res;
      },
      err => console.log(err)
    );
  }

  getReportData() {
    const data = {
      "initDate": this.searchMonth,
      "limitDate": this.today.getFullYear() + "-" + (this.today.getMonth()+1<10? "0" + (this.today.getMonth()+1) : (this.today.getMonth()+1)) + "-" + (this.today.getDate()<10?"0"+this.today.getDate() : this.today.getDate()),
      "projectRange": this.projectFilter
    };

    if(data.projectRange != "" && data.initDate != "") {
      this.reportService.getWeekReport(data).subscribe(
        res => {
          this.dataSourceWeek1 = res.firstWeek;
          this.dataSourceWeek2 = res.secondWeek;
          this.dataSourceWeek3 = res.thirdWeek;
          this.dataSourceWeek4 = res.fourthWeek;
          this.reportData = res;
        }
      );
    }
  }

  verifyDate(day: number) {
    let year = parseInt(this.searchMonth.split("-")[0]);
    let month = parseInt(this.searchMonth.split("-")[1]);

    if(this.today.getFullYear() == year) {
      if((this.today.getMonth()+1) == month) {
        if(this.today.getDate() >= day) {
          return true;
        } else {
          return false;
        }
      } else if((this.today.getMonth()+1) > month) {
        return true;
      } else {
        return false;
      }
    } else if(this.today.getFullYear() > year) {
      return true;
    } else {
      return false;
    }
  }

}