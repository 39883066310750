<div class="container-fluid mt-3">
    <mat-card class="card-form">
        <mat-card-content class="justify-align-center">

            <div class="contenedor">

                <div class="projectButtons">
                    <button *ngFor="let p of projects" class="m19AltBtn" (click)="filterByProject(p.idProject)">
                        {{p.name}}
                        <mat-icon>apartment</mat-icon>
                    </button>
                </div>
                
                <br>

                <div *ngIf="customers.length==0 || customers=='Sin seleccionar'"
                    style="display: flex; flex-direction: column; height: 40vh; justify-content: center; align-items: center;">
                    <h2 *ngIf="customers == 'Sin seleccionar'">Seleccione un
                        proyecto para visualizar sus ventas</h2>
                    <p *ngIf="customers == 'Sin seleccionar'">
                        <mat-icon style="font-size: 2rem;">search</mat-icon>
                    </p>
                    <h2 *ngIf="customers.length==0">Este proyecto no tiene
                        ventas registradas</h2>
                    <p *ngIf="customers.length==0">
                        <mat-icon style="font-size: 2rem;">sentiment_very_dissatisfied</mat-icon>
                    </p>
                </div>

                <div class="dataBelt" *ngIf="customers != 'Sin seleccionar' && customers.length>0">
                    <span class="dataItem">{{customers[0].name.toUpperCase()}} <mat-icon style="color:#D33E3E;">apartment</mat-icon></span>
                    <span class="dataItem">UNIDADES OCUPADAS: {{customers.length}} <mat-icon style="color:#D33E3E;">sell</mat-icon></span>
                    <span class="dataItem">POR DIRECCIÓN: {{counters.direction}} <mat-icon style="color:#D33E3E;">work</mat-icon></span>
                    <span class="dataItem">BLOQUEADAS: {{counters.block}} <mat-icon style="color:#D33E3E;">block</mat-icon></span>
                    <span class="dataItem">LOTES CON ANTICIPO: {{counters.payment}} <mat-icon style="color:#D33E3E;">payments</mat-icon></span>
                    <span class="dataItem">LOTES CON CONTRATO: {{counters.contract}} <mat-icon style="color:#D33E3E;">gavel</mat-icon></span>
                    <span class="dataItem">CONTRATOS FOLIADOS: {{counters.contractComplete}} <mat-icon style="color:#D33E3E;">how_to_reg</mat-icon></span>
                    <span class="dataItem">CLIENTES COMPLETOS: {{counters.complete}} <mat-icon style="color:#D33E3E;">how_to_reg</mat-icon></span>
                </div>

                <br>

                <table style="width: 100%;" class="tableHeader" *ngIf="customers != 'Sin seleccionar' && customers.length>0">
                    <tbody>
                        <tr>
                            <th style="width: 20%">Cliente</th>
                            <th style="width: 20%">Coordinador</th>
                            <th style="width: 10%;">Inmueble</th>
                            <th style="width: 10%;">Vendido</th>
                            <th style="width: 8%;">Solicitud</th>
                            <th style="width: 8%;">Anticipo</th>
                            <th style="width: 8%;">Contrato</th>
                            <th style="width: 8%;">Ratificación</th>
                            <th style="width: 8%;">Cliente Completo</th>
                        </tr>
                    </tbody>
                </table>

                <table style="width: 100%;">
                    <tbody>
                        <!-- let customersGroup of customersGroups; -->
                        <tr *ngFor="let c of customers">
                            <td style="width: 20%;" [ngStyle]="{'color': c.fk_status == 2?'var(--primaryRed)':'black', 'font-weight': c.fk_status == 2?'bold':'normal'}">
                                {{c.customerName}}
                            </td>

                            <td style="width: 20%">
                                {{c.coordinatorName}}
                            </td>

                            <td style="width: 10%;">
                                {{c.fk_property}}
                            </td>

                            <td style="width: 10%;">
                                {{pipe.transform(c.fechaDato,'mediumDate')}}
                            </td>

                            <!--///////////////////////////////////////////  Solicitud de contrato    //////////////////////////////////////////////////-->
                            <td style="width: 8%;">
                                <mat-icon *ngIf="c.fk_salesCatalog >= 0 " data-title="Solicitud elaborada"
                                    style="color: #02bd54;">
                                    verified
                                </mat-icon>
                            </td>

                            <!--//////////////////////////////////////////// ANTICIPO //////////////////////////////////////////////////-->

                            <td style="width: 8%;">
                                <mat-icon *ngIf="c.Anticipo === 'Full'" data-title="Anticipo recibido"
                                    style="color: #02bd54;">
                                    verified
                                </mat-icon>

                                <mat-icon *ngIf="c.Anticipo === 'Mid'" data-title="Anticipo incompleto"
                                    style="color: #F8BA43;">
                                    warning
                                </mat-icon>

                                <mat-icon *ngIf="c.Anticipo === 'NaN'" data-title="Sin anticipo previo"
                                    style="color: #D94555;">
                                    do_not_disturb_on
                                </mat-icon>
                            </td>

                            <!--//////////////////////////////////// ELABORACION DE CONTRATO ////////////////////////////////////////////-->

                            <td style="width: 8%;">
                                <mat-icon *ngIf="c.Contrato === 'Done'" data-title="Contrato Elaborado"
                                    style="color: #02bd54;">
                                    verified
                                </mat-icon>

                                <mat-icon *ngIf="c.Contrato === 'NaN'" data-title="Elaboracion de contrato pendiente"
                                    style="color: #D94555;">
                                    do_not_disturb_on
                                </mat-icon>
                            </td>

                            <!--//////////////////////////////////////// RATIFICACION ////////////////////////////////////////////////-->

                            <td style="width: 8%;">
                                <mat-icon *ngIf="c.ratificationState == 'Recibido' " data-title="Ratificado"
                                    style="color: #02bd54;">
                                    verified
                                </mat-icon>

                                <mat-icon *ngIf="c.ratificationState == 'Espera' " data-title="Esperando ratificación"
                                    style="color: #D94555;">
                                    do_not_disturb_on
                                </mat-icon>

                                <mat-icon *ngIf="c.ratificationState == 'Enviado' " data-title="Enviado a ratificar"
                                    style="color: #0259bd;">
                                    error
                                </mat-icon>
                            </td>

                            <!--///////////////////////////////////////// CLIENTE COMPLETO //////////////////////////////////////////-->
                            <td style="width: 8%;">
                                <mat-icon
                                    *ngIf="c.fk_salesCatalog == 6 || c.fk_salesCatalog == 10 || c.fk_salesCatalog == 11|| c.fk_salesCatalog == 12"
                                    [attr.data-title]="'El cliente se encuentra completo' + (c.completeDate==null?' sin fecha':' desde '+c.completeDate)" style="color: #02bd54;">
                                    verified
                                </mat-icon>

                                <mat-icon *ngIf="c.fk_salesCatalog != 6 && c.fk_salesCatalog != 10 && c.fk_salesCatalog != 11 && c.fk_salesCatalog != 12" data-title="Aun no es un cliente completo"
                                    style="color: #D94555;">
                                    do_not_disturb_on
                                </mat-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </mat-card-content>
    </mat-card>
</div>