import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LeadsService } from 'src/app/services/leads.service';

import { DatePipe } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX'; //LOCAL DE ESPAÑOL
import { registerLocaleData } from '@angular/common';   //LIBRERIA QUE INCLUYE FUNCIÓN PARA REGISTRAR EL LOCAL DE ESPAÑOL

@Component({
  selector: 'app-lead-time-line',
  templateUrl: './lead-time-line.component.html',
  styleUrls: ['./lead-time-line.component.scss']
})
export class LeadTimeLineComponent implements OnInit {

  actions: any[];
  pipe = new DatePipe('es-MX');

  constructor(
    private leadService: LeadsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    registerLocaleData(localeEsMx, 'es-MX');//REGISTRAR LOCAL PARA ESPAÑOL
    this.leadService.leadTimeline(this.data).subscribe(
      res => {
        this.actions = res;
      }
    );
  }

}
