<div class="conteiner-fluid mt-3">
    <mat-card class='card-form'>
        <mat-card-content class="justify-align-content">
            <div class="projectButtons">
                <button *ngFor="let p of projects" class="m19AltBtn" (click)="getCustomersWithBlock(p.idProject)">
                    {{p.name}}
                    <mat-icon>apartment</mat-icon>
                </button>
            </div>

            <div *ngIf="customersGroups.length==0"
                style="display: flex; flex-direction: column; align-items: center; width: 100%;">
                <br>
                <h3>No tienes solicitudes pendientes</h3>
                <p>
                    <mat-icon style="font-size: 2rem;">sentiment_very_satisfied</mat-icon>
                </p>
            </div>

            <div *ngIf="customersGroups=='Sin seleccionar'"
                style="display: flex; flex-direction: column; align-items: center; width: 100%;">
                <br>
                <h3>Seleccione un proyecto para visualizar sus procesos</h3>
                <p>
                    <mat-icon style="font-size: 2rem;">search</mat-icon>
                </p>
            </div>

            <br>
            <!-- Si la cantidad de bloqueos registradas es mayor a 0 se visualiza el siguiente bloque de código -->
            <table *ngIf="customersGroups.length>0 && customersGroups!='Sin seleccionar'" style="width: 100%;"
                class="tableHeader">
                <tbody>
                    <tr>
                        <th style="width: 10%; padding: 10px;"># de cliente</th>
                        <th style="width: 90%; padding: 10px;">Cliente</th>
                    </tr>
                </tbody>
            </table>
            <mat-accordion *ngIf="customersGroups!='Sin seleccionar'">
                <mat-expansion-panel *ngFor="let customersGroup of customersGroups" hideToggle>
                    <mat-expansion-panel-header
                        (click)="getPropertiesWithBlockByUser(customersGroup.idUser, selectedProject, customersGroup.status)">
                        <mat-panel-title style="width: 10%;">
                            <span
                                style="display: flex; align-items: center; text-align: center; width: 100%; font-weight: bold;">
                                <div style="width: 100%; display: flex; justify-content: center;">
                                    {{customersGroup.idUser}}
                                </div>
                            </span>
                        </mat-panel-title>
                        <mat-panel-description style="width: 90%;"><b>
                                <span>{{customersGroup.fk_customer}}</span>
                                <br>
                                <span style="color: var(--M19Primary);">Tiene {{customersGroup.amount}}
                                    unidad{{customersGroup.amount>1?'es':''}} en estado de
                                    {{customersGroup.status=='sales'?'activo': 'rescindido'}}</span>
                            </b></mat-panel-description>
                    </mat-expansion-panel-header>


                    <table class="m19Table">
                        <thead>
                            <tr>
                                <!-- Se definen los encabezados de cada columna de la tabla -->
                                <th scope="col">Número de operación</th>
                                <th scope="col">Proyecto</th>
                                <th scope="col" style="text-align: center;">Inmueble</th>
                                <th scope="col" style="text-align: center;">Fecha de contrato</th>
                                <th scope="col" style="text-align: center;">Motivo de bloqueo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let allreques of allrequest">
                                <td>{{allreques.hasOwnProperty('idSale')?allreques.idSale:allreques.idRecession}}</td>
                                <td>{{allreques.name}}</td>
                                <td style="text-align: center;">{{allreques.fk_property}}</td>
                                <td style="text-align: center;">{{this.pipe.transform(allreques.fechaDato, 'longDate')}}
                                </td>
                                <td style="text-align: center;">
                                    <mat-icon data-title="SIN REGISTRO" *ngIf="allreques.fk_reasonBlock =='NaN'"
                                        style="color: #666666; cursor: help;">help</mat-icon>
                                    <mat-icon data-title="VOLUNTARIO" *ngIf="allreques.fk_reasonBlock == 'Voluntaria'"
                                        style="color: #02bd54; cursor: help;">settings_accessibility</mat-icon>
                                    <mat-icon data-title="FALTA DE PAGO"
                                        *ngIf="allreques.fk_reasonBlock == 'Incumplimiento de pago'"
                                        style="color: #D94555; cursor: help;">money_off</mat-icon>
                                    <mat-icon data-title="REUBICACION" *ngIf="allreques.fk_reasonBlock == 'Reubicacion'"
                                        style="color: #F8BA43; cursor: help;">edit_location</mat-icon>
                                    <mat-icon data-title="DIRECCION" *ngIf="allreques.fk_reasonBlock == 'Direccion'"
                                        style="color: #0263bd; cursor: help;">work</mat-icon>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btnAction" (click)="generateRequest(customersGroup.idUser, mode)">Generar solicitud
                        <mat-icon>edit_square</mat-icon></button>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-card-content>
    </mat-card>
</div>