import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Customer } from 'src/app/models/Customer';
import { DialogSaleConfirmComponent } from '../dialog-sale-confirm/dialog-sale-confirm.component';

@Component({
  selector: 'app-dialog-digital-customer',
  templateUrl: './dialog-digital-customer.component.html',
  styleUrls: ['./dialog-digital-customer.component.scss']
})
export class DialogDigitalCustomerComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DialogDigitalCustomerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Customer,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }


  OpenDialogConfirm(iduser: any){
    localStorage.setItem('Venta digital: ', iduser)
    const dialogo1 = this.dialog.open(DialogSaleConfirmComponent
      , {
        width: '600px',
      });
  }

}
