import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DeliveryNote } from '../models/DeliveryNote';

@Injectable({
  providedIn: 'root'
})
export class DeliverynoteService {

  private url = 'http://localhost:3000/api';

  constructor(private http: HttpClient) { }

  selectDeliveries(): Observable<any>{
    return this.http.get(`${environment.URLServer}/deliverynote/selectAllDelivery`);
  }

  selectDeliveryInfo(df: String): Observable<any>{
    return this.http.get(`${environment.URLServer}/deliverynote/getInfo/${df}`);
  }

  selectDelivery(id:Number): Observable<any>{
    return this.http.get(`${environment.URLServer}/deliverynote/selectDelivery/`+id);
  }

  selectDeliveryProducts(fol: String): Observable<any>{
    return this.http.get(`${environment.URLServer}/deliverynote/selectDeliveryProducts/`+fol);
  }
  
  selectDeliveryProductsByFolio(df:Number): Observable<any>{
    return this.http.get(`${environment.URLServer}/deliverynote/selectDeliveryProductsByFolio/`+df);
  }

  insertDelivery(deli: DeliveryNote): Observable<any>{
    return this.http.post(`${environment.URLServer}/deliverynote/insert`,deli);
  }

  insertProducts(fol:Number, id:Number, amo:Number, ucos:Number, user:String): Observable<any>{
    return this.http.post(`${environment.URLServer}/deliverynote/insertproducts`, [fol, id, amo, ucos, user]);
  }

  deleteProductsByFolio(fol:number): Observable<any>{
    return this.http.delete(`${environment.URLServer}/deliverynote/deleteproductsbyfolio/${fol}`);
  }

  deleteDelivery(id:number): Observable<any>{
    return this.http.delete(`${environment.URLServer}/deliverynote/deleteDelivery/`+id);
  }

  updateDelivery(df:number, deli:DeliveryNote): Observable<any>{
    return this.http.put(`${environment.URLServer}/deliverynote/updateDelivery/${df}`,deli);
  }
}