import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-postal-code-generator',
  templateUrl: './postal-code-generator.component.html',
  styleUrls: ['./postal-code-generator.component.scss']
})
export class PostalCodeGeneratorComponent implements OnInit {

 APIURL = 'https://sepomex.razektheone.com/codigo_postal'

 headers = new HttpHeaders({
    'Apikey' : '1fe2b7e599a82c103959e066346f0d6c3ddef8cb'
  })

 
  constructor(
    private http: HttpClient 
    ) { }

  ngOnInit(): void {
  }

  //consultar un codigo postal de prueba
    GETPostalCodePrueba(){
     let params = new HttpParams().set('cp','63157')
        this.http.get(`${this.APIURL}`,{headers :this.headers, params: params}).subscribe(
          res => console.log(JSON.stringify(res))
        )  
    }


  //consultar un codigo postal con paramatros
  GETPostalCode(postalCode: any){
    let params = new HttpParams().set('cp','63157')
    this.http.get(`${this.APIURL}`,{headers :this.headers, params: params}).subscribe(
      res => console.log(JSON.stringify(res))
    )    
  }


}
