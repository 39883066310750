<!-- Información de partidas y material asignado -->
<mat-dialog-content>
    <!-- Condicional, si el número de partidas es mayor a cero se imprime la información -->
    <html>
    <div *ngIf="departures.length>0">
        <table style=" width: 100%;">
            <thead style="text-align: center; font-size: 1.5rem;">
                <tr>
                    <th colspan="3" style="border-bottom: solid black; align-content: center; ">RESÚMEN DE PRESUPUESTO
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr style="text-align: center; height: 50px;">
                    <td>FECHA: {{date | date: 'dd-MMM-yyyy'}}.</td>
                    <td>PRESUPUESTO: No. {{budgetNum}}.</td>
                </tr>
                <tr style="text-align: center;">
                    <td>OBRA: {{project}}.</td>
                    <td>MODULO: {{module}}.</td>
                    <td style="color: green; ">TOTAL: ${{totalbudget}}.</td>
                </tr>
            </tbody>
        </table>

        <br>

        <!-- ngFor para recorrer todas las partidas conseguidas -->
        <mat-accordion *ngIf="departures[0].descriptions!='Cargando...'">
            <mat-expansion-panel *ngFor="let departure of departures" hideToggle>
                <mat-expansion-panel-header class="budgetData"
                    (click)="searchProductsFromDeparture(departure.idbudget, departure.iddeparture, departure.idconcept);">
                    <mat-panel-title class="departureInfo"><span>
                            {{"Partida número " + departure.departurenumber + ": " +departure.departurename}}
                        </span></mat-panel-title>
                    <mat-panel-description class="conceptInfo"><span>
                            {{departure.descriptions}}
                        </span></mat-panel-description>
                </mat-expansion-panel-header>
                <table class="table table-striped mt-3 animated fadeIn faster">
                    <thead class="thead-m19">
                        <tr style="text-align: center;">
                            <th scope="col">Descripción del producto</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Unidad</th>
                            <th scope="col">Precio Unitario</th>
                            <th scope="col">Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="text-align: center;" *ngFor="let product of products">
                            <td>{{product.productname}}</td>
                            <td>{{product.amount}}</td>
                            <td>{{product.unit}}</td>
                            <td>{{product.unitcost}}</td>
                            <td>{{product.productTotal}}</td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    </html>
</mat-dialog-content>

<!-- Si el número de requisición es nulo entonces no se tienen partidas registradas de esa requisición -->
<h3 *ngIf="departures[0].descriptions=='Cargando...'">{{departures[0].descriptions}}.</h3>

<!-- Botón para cerrar el dialog -->
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
